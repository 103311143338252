/// <reference types="@welldone-software/why-did-you-render" />
/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import React from 'react';

/* --------------------------------- CUSTOM --------------------------------- */
import { DEBUG_LOGGING_ENABLED, HOOK_LOGGING_ENABLED, RENDER_DEBUGGING_ENABLED } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
const initAppDebug = async () => {
  try {
    // eslint-disable-next-line no-console
    console.log('🐞 App Debugging', { HOOK_LOGGING_ENABLED, RENDER_DEBUGGING_ENABLED, DEBUG_LOGGING_ENABLED });

    /**
     * Render debugging
     * @example
     *
     * window.localStorage.setItem('AppDebuggingEnabled', 'true')
     */
    if (RENDER_DEBUGGING_ENABLED) {
      const whyDidYouRender = (await import('@welldone-software/why-did-you-render')).default;
      whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackHooks: true,
      });
    }

    /**
     * Enables hook logging
     * @example
     * // To enable debug logging in the browser
     * window.localStorage.setItem('HookLoggingEnabled', 'true')
     */
    setUseWhatChange({ active: HOOK_LOGGING_ENABLED });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('🐞 App Debugging Error', error);
  }
};

void initAppDebug();
