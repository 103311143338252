/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';

/* --------------------------------- CUSTOM --------------------------------- */
import { showToastNotification } from 'src/modules/toastNotification';
import { LiveUpdateMessage } from 'src/stores/LiveUpdate.store';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export interface ToastNotificationStoreState {
  toastId: ReturnType<typeof showToastNotification> | undefined;
  toastMessage?: LiveUpdateMessage;
}

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const defaultToastNotificationStoreState: ToastNotificationStoreState = {
  toastId: undefined,
  toastMessage: undefined,
};

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class ToastNotificationStore implements ToastNotificationStoreState {
  toastId = defaultToastNotificationStoreState.toastId;
  toastMessage = defaultToastNotificationStoreState.toastMessage;

  setToastId = (toastId: ReturnType<typeof showToastNotification> | undefined) => {
    this.toastId = toastId;
  };

  setToastMessage = (toastMessage?: LiveUpdateMessage) => {
    this.toastMessage = toastMessage;
  };

  showToast = (message: LiveUpdateMessage) => {
    if (this.toastId) this.dismissToast();
    this.toastMessage = message;
  };

  dismissToast = () => {
    this.toastMessage = undefined;
    if (!this.toastId) return;

    toast.dismiss(this.toastId);
    this.toastId = undefined;
  };

  constructor() {
    makeAutoObservable(this);
  }
}
