/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { Theme, useTheme } from '@mui/material/styles';
import { createMakeAndWithStyles, CSSObject } from 'tss-react';

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/**
 * Recommended use of makeStyles with MUI
 * @see https://www.npmjs.com/package/tss-react
 */
export const { makeStyles, withStyles } = createMakeAndWithStyles({ useTheme });

/**
 * Typography workaround for the stricter type safety of tss-react
 * @see https://github.com/garronej/tss-react/issues/50
 * @param theme The current MUI theme
 * @param key The key of the typography
 * @returns The CSSObject of the typography
 */
export const typography = (theme: Theme, key: keyof Theme['typography']) => theme.typography[key] as CSSObject;
