export const RED_CARDS = [
  '🃁',
  '🃂',
  '🃃',
  '🃄',
  '🃅',
  '🃆',
  '🃇',
  '🃈',
  '🃉',
  '🃊',
  '🃋',
  // Remove knight card
  //'🃌',
  '🃍',
  '🃎',
  '🂱',
  '🂲',
  '🂳',
  '🂴',
  '🂵',
  '🂶',
  '🂷',
  '🂸',
  '🂹',
  '🂺',
  '🂻',
  // Remove knight card
  //'🂼',
  '🂽',
  '🂾',
];
export const BLACK_CARDS = [
  '🂡',
  '🂢',
  '🂣',
  '🂤',
  '🂥',
  '🂦',
  '🂧',
  '🂨',
  '🂩',
  '🂪',
  '🂫',
  // Remove knight card
  //'🂬',
  '🂭',
  '🂮',
  '🃑',
  '🃒',
  '🃓',
  '🃔',
  '🃕',
  '🃖',
  '🃗',
  '🃘',
  '🃙',
  '🃚',
  '🃛',
  // Remove knight card
  //'🃜',
  '🃝',
  '🃞',
];

export const PLAYING_CARDS = [...RED_CARDS, ...BLACK_CARDS];
