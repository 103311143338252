/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { Backdrop, Grid, Typography } from '@mui/material';
import React from 'react';
import { keyframes } from 'tss-react';

/* --------------------------------- CUSTOM --------------------------------- */
import { WARMLY_CIRCLE_ICON_SMALL } from 'src/utils/constants';
import { makeStyles } from 'src/utils/styles';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
interface LoadingLogoBackdropProps {
  open: boolean;
  loadingMessage?: string;
}

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles()(() => {
  const gradient = 'linear-gradient(#fff 0 0) 50%';
  return {
    logoAnimated: {
      height: 50,
      animationName: `${keyframes({
        '0%': {
          transform: 'rotate(0)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      })}`,
      animationDuration: '1.4s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
      animationFillMode: 'both',
    },
    zoomLoader: {
      width: '25px',
      aspectRatio: '1',
      display: 'grid',
      mask: 'conic-gradient(from 22deg, #0003, #000)',
      animationName: `${keyframes({
        from: {
          transform: 'rotate(0turn)',
        },
        to: {
          transform: 'rotate(1turn)',
        },
      })}`,
      animationDuration: '1s',
      animationTimingFunction: 'steps(8)',
      animationIterationCount: 'infinite',
      '&, &:before': {
        background: `${gradient} / 34% 8% space no-repeat, ${gradient} / 8% 34% no-repeat space`,
      },
      '&:before': {
        content: '""',
        transform: 'rotate(45deg)',
      },
    },
  };
});

export const ZoomSpinner: React.FC = () => {
  const { classes } = useStyles();
  return <div className={classes.zoomLoader}></div>;
};

export const LogoSpinner = (): JSX.Element => {
  const { classes } = useStyles();
  return <img src={WARMLY_CIRCLE_ICON_SMALL} className={classes.logoAnimated} alt="loading..." />;
};

const LoadingLogoBackdrop: React.FC<LoadingLogoBackdropProps> = ({
  open,
  loadingMessage = 'Loading your Warmly Network...',
}) => {
  return (
    <Backdrop open={open} style={{ zIndex: 100 }}>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs>
          <LogoSpinner />
        </Grid>
        {loadingMessage && (
          <Grid item>
            <Typography variant="h6">{loadingMessage}</Typography>
          </Grid>
        )}
      </Grid>
    </Backdrop>
  );
};

export default LoadingLogoBackdrop;
