/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import { useNavigate } from 'react-router-dom';

/* --------------------------------- CUSTOM --------------------------------- */
import { TabItem } from 'src/models/TabItem';
import { NavigationPath } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export const AllProfileTabs = ['nametag', 'videoOff', 'fullProfile', 'calendarSignature'] as const;
export type ProfileSettingsTab = typeof AllProfileTabs[number];

interface TabState {
  activeProfileTab: ProfileSettingsTab;
  previewExpanded: boolean;
  previewLoading: boolean;
  previewHeight: number;
}

export const initialTabState: TabState = {
  activeProfileTab: 'nametag',
  previewExpanded: false,
  previewLoading: true,
  previewHeight: 0,
};

type GoToTabProps = {
  path: NavigationPath.Profile;
  navigate: ReturnType<typeof useNavigate>;
  activeTab?: TabState['activeProfileTab'];
};

type GetActiveTabProps = {
  activeTabQueryParam: string;
  allTabs: Record<ProfileSettingsTab, TabItem>;
};

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/**
 * Pushes active tab query param onto history.
 * @param path Navigation path
 * @param navigate navigate function
 * @param activeTab activeTab to navigate to
 */
export const goToTab = ({ path, navigate, activeTab }: GoToTabProps) => {
  const queryParams = Object.entries({ activeTab })
    .filter((entry) => !!entry[1])
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  navigate(`${path}${queryParams ? `?${queryParams}` : ''}`);
};

/**
 * Returns the query param value from the allTabs string array or undefined if not found.
 * @param activeTabQueryParam "activeTab" query param string value
 * @param allTabs tab string const array
 * @returns allTabs string matching query param
 */
export const getActiveQueryTab = ({ activeTabQueryParam, allTabs }: GetActiveTabProps) => {
  const activeQueryTab =
    activeTabQueryParam && Object.keys(allTabs).find((tab) => tab.toLowerCase() === activeTabQueryParam.toLowerCase());
  return activeQueryTab || undefined;
};

/* -------------------------------------------------------------------------- */
/*                              STORE DEFINITION                              */
/* -------------------------------------------------------------------------- */
class TabStore implements TabState {
  /* ---------------------------- MEMBER VARIABLES ---------------------------- */
  previewExpanded = initialTabState.previewExpanded;
  activeProfileTab = initialTabState.activeProfileTab;
  previewLoading = initialTabState.previewLoading;
  previewHeight = initialTabState.previewHeight;

  /* --------------------------------- ACTIONS -------------------------------- */
  setPreviewHeight = (height: number) => (this.previewHeight = height);
  togglePreviewExpanded = () => (this.previewExpanded = !this.previewExpanded);
  setPreviewLoading = (previewLoading: boolean) => (this.previewLoading = previewLoading);
  setActiveProfileTab = (activeProfileTab: TabState['activeProfileTab']) => {
    if (this.activeProfileTab !== activeProfileTab) {
      this.activeProfileTab = activeProfileTab;
      this.previewExpanded = false;
      this.previewLoading = true;
    }
  };

  /* ----------------------------- COMPUTED FIELDS ---------------------------- */
  get showPreview() {
    return this.activeProfileTab === 'nametag' || this.activeProfileTab === 'videoOff';
  }

  /* ------------------------------- CONSTRUCTOR ------------------------------ */
  /**
   * @param disablePersistence if true does not persist the store
   */
  constructor(disablePersistence: boolean) {
    makeAutoObservable(this);
    !disablePersistence &&
      makePersistable(this, {
        name: 'TabStore',
        properties: ['activeProfileTab'],
        storage: window.localStorage,
      });
  }

  resetTab = () => {
    for (const key of Object.keys(initialTabState)) {
      this[key] = initialTabState[key];
    }
    clearPersistedStore(this);
  };
}

export default TabStore;
