/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const FONT_FAMILIES: Record<FontFamilyKey, string> = {
  lato: 'Lato',
  bebasNeue: 'Bebas Neue',
  nunito: 'Nunito',
  robotoSlab: 'Roboto Slab',
} as const;

export const AllFontFamilyKeys = ['lato', 'bebasNeue', 'nunito', 'robotoSlab'] as const;
export type FontFamilyKey = typeof AllFontFamilyKeys[number];

export const AllBackgroundThemes = ['light', 'dark'] as const;
export type BackgroundTheme = typeof AllBackgroundThemes[number];

export const AllColorOptions = ['none', 'logo', 'custom'] as const;
export type ColorOption = typeof AllColorOptions[number];

// Fonts force loaded for background rendering
const loadedFonts: string[] = [];

export const DEFAULT_FONT_FAMILY_KEY: FontFamilyKey = 'lato';
export const DEFAULT_THEME: BackgroundTheme = 'dark';
export const DEFAULT_SIGNATURE_TOGGLE = true;

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/**
 * Forces the browser to load the provided font
 * @param font Font string in the format `${weight} ${size}px ${family}`
 */
export const loadFont = (font: string): Promise<void> => {
  return new Promise((resolve) => {
    if (loadedFonts.indexOf(font) !== -1) {
      resolve();
    } else {
      // Force the browser to load the font since the font will not be loaded
      // unless it's used. Fonts in the background are not used in the app.
      document.fonts.load(font).then((): void => {
        // Add to in-memory cache
        loadedFonts.push(font);
        resolve();
      });
    }
  });
};
