import { Theme } from '@mui/material';
import { differenceInMinutes, isAfter, isFuture, isPast, isToday } from 'date-fns';
import { uniq } from 'lodash-es';

import { ZoomEventFragment, ZoomUserFragment } from 'src/graphql';
import { MeetingDetailsByWeekday, Weekday } from 'src/utils/constants';
import { getIsCoworker, ProfileViewData } from 'src/utils/profile/profile';
import { COLORS } from 'src/utils/theme';
import { getDateTimeFromUnixTimestamp, getDayName } from 'src/utils/time/time';

/**
 * Gets whether the meeting should display running late
 *
 * Running late is only shown when:
 * 1) Meeting has a valid start and end time, and
 * 2) Meeting starts today (i.e., before midnight local time), and
 * 3) Meeting has not yet ended
 * @param meeting The meeting to check
 * @returns Whether the meeting should display running late
 */
export const getIsRunningLate = (meeting: MeetingDetails): boolean => {
  const startMs = meeting.when.start?.timeMs;
  const endMs = meeting.when.end?.timeMs;
  // Don't check meeting with invalid end time
  if (!startMs || !endMs) {
    return false;
  }
  return isToday(startMs) && isFuture(endMs);
};

export const getMeetingDetails = (e: ZoomEventFragment, participants: ProfileViewData[] = []): MeetingDetails => {
  return {
    id: e.id,
    title: e.summary || '',
    location: e.location || '',
    description: e.description || '',
    when: {
      start: e.start ? getDateTimeFromUnixTimestamp(e.start) : undefined,
      end: e.end ? getDateTimeFromUnixTimestamp(e.end) : undefined,
    },
    participants,
    participantsCount: e.zoomEventParticipants_aggregate.aggregate?.count || participants.length,
    conferenceData: e.conferenceData as ConferenceData,
  };
};

/**
 * Gets the flat schedule meeting list to be used in meeting selector
 * @param schedule The full meeting schedule representing meeting details by each week day
 * @returns The flat array of meetings
 */
export const getFlatSchedule = (schedule: MeetingDetailsByWeekday): MeetingDetails[] => {
  const flatSchedule: MeetingDetails[] = [];
  Object.entries(schedule).forEach(([_dayName, meetings]) => flatSchedule.push(...meetings));
  return flatSchedule;
};

/**
 * Determines whether a given meeting is considered as the next meeting following the current meeting
 * Note that there could be multiple "next meetings" if they start at the same time, which is why
 * we also check for meeting start time in this function
 * @param meeting The meeting to be evaluated for whether it is the next meeting
 * @param schedule The full meeting schedule representing meeting details by each week day
 * @returns Whether the meeting is considered as the upcoming / next meeting
 */
export const isNextMeeting = (
  meeting: MeetingDetails,
  schedule: MeetingDetailsByWeekday,
  currentMeeting?: MeetingDetails
): boolean => {
  const currentDate = new Date();

  if (!meeting.when.end || !meeting.when.start || new Date(meeting.when.end.timeMs) < currentDate) {
    // If start and end times are unknown, we do not assume this is the next meeting
    // If the meeting has already ended, we do not assume this is the next meeting
    return false;
  }

  // If we don't know the current meeting (should generally not happen), then we
  // assume there is no next meeting
  if (!currentMeeting) {
    return false;
  }

  const currentMeetingStartTime =
    (currentMeeting.when.start?.timeMs && new Date(currentMeeting.when.start.timeMs)) || new Date();

  const meetingDayName = getDayName(currentMeetingStartTime) as Weekday;

  const todayMeetings = schedule[meetingDayName];

  // Note that the array of meetings in the schedule must be in chronological order
  // for this to work properly
  const currentMeetingIndex = todayMeetings.findIndex((m) => m.id === currentMeeting.id);
  const nextMeeting = todayMeetings[currentMeetingIndex + 1];

  // If no next meeting for today (or if it doesn't have a valid start time), return false
  if (!nextMeeting || !nextMeeting.when.start?.timeMs) {
    return false;
  }

  const meetingStartDate = new Date(meeting.when.start.timeMs);
  const nextMeetingStartDate = new Date(nextMeeting.when.start.timeMs);

  // If meeting is same as nextMeeting OR if it starts the roughly the same time as the next meeting
  // (i.e., within 5 minutes)
  return !!(meeting.id === nextMeeting.id || Math.abs(differenceInMinutes(meetingStartDate, nextMeetingStartDate)) < 5);
};

/**
 * Get the company name from the provided URL
 * Pop off last piece of company logo URL
 * @param url The URL of the company logo
 * @returns The company name
 */
export const getCompanyLogoName = (url: string | undefined | null): string | undefined =>
  url ? url.split('/').pop() : undefined;

/**
 * Gets the company logos from the meeting that don't match the logo of the provided profile
 * @param profile The profile of the current user
 * @param meetingParticipants The meeting participants
 * @returns The company logos from the meeting that don't match the logo of the provided profile
 */
export const getMeetingExternalLogos = (
  profile?: ProfileViewData,
  meetingParticipants?: ProfileViewData[]
): string[] => {
  if (!profile) {
    return [];
  }
  const profileCompany = getCompanyLogoName(profile.logoUrl);
  // Get unique company logos different from the current user
  return meetingParticipants
    ? uniq(
        meetingParticipants
          .map((p) => p.logoUrl || '')
          .filter((c) => {
            const logoName = getCompanyLogoName(c);
            return logoName && logoName !== profileCompany;
          })
      )
    : [];
};

/**
 * Returns the current meeting time string
 * @param meeting The meeting of the current user
 * @returns Formatted current meeting time
 */
export const getMeetingTimeString = (meeting: MeetingDetails): string => {
  const start = meeting.when.start;
  const end = meeting.when.end;
  return `${start?.time}${start?.amPm !== end?.amPm ? `${start?.amPm}` : ''} - ${end?.time}${end?.amPm}`;
};

/**
 * Gets row item hover CSS
 * @param theme The MUI theme
 * @returns The CSS map for item hover CSS
 */
export const itemHoverCss = (theme: Theme): MuiClassNameMap => ({
  padding: '16px 16px 8px',
  margin: `4px 0 4px 8px`,
  borderWidth: '2px 2px 2px 8px',
  borderStyle: 'solid',
  borderColor: 'rgba(112, 0, 255, 0.5)',
  boxShadow: theme.shadows[4],
  borderRadius: 4,
  cursor: 'pointer',
});

export const itemActiveCss = {
  margin: `4px 0 4px 8px`,
  background: COLORS.BRAND_BG,
  borderRadius: 4,
  borderLeft: `8px solid ${COLORS.BRAND_PRIMARY}`,
};

/**
 * Returns the current meeting based on the following waterfall:
 *
 * 1) event id = `currentEventId`
 * 2) event zoom UUID = `zoomMeetingUuid`,  if no match for 1)
 * 3) the first meeting that has not yet ended, if no match for 1) or 2)
 * @param params.zoomEvents The zoomEvents to be converted to meeting details
 * @param params.currentZoomEventId ID of the current zoomEvent
 * @param params.zoomMeetingUuid UUID of the zoom meeting, obtained from the zoomSdk methods
 * @returns The current meeting's zoomEvent
 */
export const getCurrentMeetingZoomEvent = ({
  zoomEvents,
  currentZoomEventId,
  zoomMeetingUuid,
}: {
  zoomEvents?: ZoomEventFragment[];
  currentZoomEventId?: number;
  zoomMeetingUuid?: string;
}): ZoomEventFragment | undefined => {
  if (!zoomEvents?.length) {
    return undefined;
  }

  // Check for meeting from meeting ID in path
  if (currentZoomEventId) {
    const meetingFromPath = zoomEvents.find((zoomEvent) => {
      return zoomEvent.id === currentZoomEventId;
    });
    if (meetingFromPath) {
      return meetingFromPath;
    }
  }

  // Check for active Zoom meeting
  if (zoomMeetingUuid) {
    const currentZoomEventByZoomUuid = zoomEvents.find((zoomEvent) => {
      const zoomEventMeetingUuid = (zoomEvent.conferenceData as ConferenceData)?.parameters?.addOnParameters?.parameters
        ?.meetingUuid;

      return zoomEventMeetingUuid === zoomMeetingUuid;
    });

    if (currentZoomEventByZoomUuid) {
      return currentZoomEventByZoomUuid;
    }
  }

  // Check for meeting based on meeting time
  let currentZoomEventByMeetingTime: ZoomEventFragment | undefined = undefined;

  for (const zoomEvent of zoomEvents) {
    const eventStartDate = zoomEvent.start ? new Date(zoomEvent.start) : undefined;
    const eventEndDate = zoomEvent.end ? new Date(zoomEvent.end) : undefined;

    if (
      // The current event must end in the future
      eventEndDate &&
      isFuture(eventEndDate) &&
      // The current meeting must not be a solo meeting
      zoomEvent.zoomEventParticipants_aggregate.aggregate?.count &&
      zoomEvent.zoomEventParticipants_aggregate.aggregate.count > 1
    ) {
      if (!currentZoomEventByMeetingTime) {
        // If no potential currentZoomEvent detected yet, set the current meeting to the first non-solo event that ends in the future
        currentZoomEventByMeetingTime = zoomEvent;
      } else if (
        eventStartDate &&
        isPast(eventStartDate) &&
        currentZoomEventByMeetingTime.start &&
        isAfter(eventStartDate, new Date(currentZoomEventByMeetingTime.start))
      ) {
        // If an existing potential currentZoomEvent has already been detected,
        // check if this zoomEvent is a more likely potential currentZoomEvent based on if:
        // 1. This zoomEvent has already started, AND
        // 2. This zoomEvent start date is after the existing potential currentZoomEvent
        currentZoomEventByMeetingTime = zoomEvent;
      }
    }
  }

  return currentZoomEventByMeetingTime;
};

/**
 * Gets the external meeting participants (based on non-matching domain to user) given a meeting's participants and the user
 * @param user The current user
 * @param participants Participants for the meeting
 * @returns The external meeting participants for the meeting
 */
export const getMeetingExternalParticipants = (
  user: ZoomUserFragment,
  participants?: ProfileViewData[]
): ProfileViewData[] => (participants || []).filter((p) => !getIsCoworker(user, p));
