import type { User } from 'firebase/auth';

import { firebaseAuth } from 'src/config/firebase';
import { LocalStorageItem } from 'src/utils/constants';

/**
 * Gets the firebase auth token for the current user
 * @returns The firebase auth token
 */
export const getAuthToken = async (): Promise<string | undefined> => {
  try {
    return await firebaseAuth?.currentUser?.getIdToken();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error encountered calling firebase getIdToken', e);
  }
};

export const authUserHasCustomClaims = async (authUser: User): Promise<boolean> => {
  const idTokenResult = await authUser.getIdTokenResult();
  const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

  return Boolean(hasuraClaim);
};

export const getLogoutParams = (): { email?: string; action?: string } => {
  if (!window.localStorage.getItem(LocalStorageItem.LogoutParams)) return {};

  try {
    let buffer = window.localStorage.getItem(LocalStorageItem.LogoutParams) as string;
    buffer = buffer.substring(1);

    const params = new URLSearchParams(buffer);

    return {
      email: params.get('e') || undefined,
      action: params.get('act') || undefined,
    };
  } catch {
    return {};
  }
};
