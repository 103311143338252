import { configure } from 'mobx';
import { createContext, PropsWithChildren } from 'react';

import RootStore from 'src/stores/Root.store';
import { CURRENT_ENVIRONMENT, Environment } from 'src/utils/constants';

/**
 * We expose the store instance as a singleton so it can be accessed by Storybook/Jest testing,
 * but it should not be directly used by the React app.
 *
 * To access the store in the React app, we should rely on the `useStores` custom hook which accesses the store
 * via context.
 */
export const store = new RootStore();

export const StoreContext = createContext(store);

/**
 * In addition to initializing the MobX store instance, we also set up certain MobX
 * configurations to help with debugging and linting in dev/staging.
 *
 * 🚨 By default these debugging settings are disabled, but if you are having trouble with MobX observables/React renderings
 * enabling them could help you to track down potential causes.
 *
 * For more details: https://mobx.js.org/configuration.html#observablerequiresreaction-boolean
 *
 * Note that MobX does have an ESLint plugin: https://github.com/mobxjs/mobx/blob/main/packages/eslint-plugin-mobx/README.md
 * But as of Feb 2022, it's not very good and could result in a lot of false positives (e.g., requiring all components to be wrapped
 * in `observer()` regardless of whether the component needs it or not), therefore we do not recommending it for now.
 */
if (CURRENT_ENVIRONMENT !== Environment.Production) {
  configure({
    /**
     * For checking whether we unnecessarily wrap React components with observer
     */
    reactionRequiresObservable: false,
    /**
     * Check whether you are using observables without a "MobX context".
     * Helps to find any missing observer wrappers, for example in React components.
     */
    observableRequiresReaction: false,
  });
}

const StoreProvider: React.FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export default StoreProvider;
