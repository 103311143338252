/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren } from 'react';
import type { ConnectionParamsOptions } from 'subscriptions-transport-ws';

/* --------------------------------- CUSTOM --------------------------------- */
import { firebaseAuth } from 'src/config/firebase';
import {
  ZoomConnectionRequest,
  ZoomEventFragment,
  ZoomEventParticipantWithContacts,
  ZoomUserConnectionRequest,
  ZoomUserNetwork,
} from 'src/graphql';
import { useLogout } from 'src/hooks/useLogout';
import { CURRENT_ENVIRONMENT, Environment, HASURA_URI, HASURA_WS, zoomApiUrl } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
const connectToDevTools = CURRENT_ENVIRONMENT !== Environment.Production;

/**
 * Creating custom merge functions for arrays seems to be required to suppress an
 * Apollo Client warning that occurs when an item is removed from an array.
 * Some of the arrays listed below are setup correctly with an ID, as outlined in the
 * Apollo Cache docs, and some aren't, but the warning occurs for both.
 * Requiring these custom merge functions might have to do with our usage of
 * subscribeToMore and updateQuery, where we return a new array on subscription
 * message received.
 * https://github.com/apollographql/apollo-client/issues/6868
 * https://github.com/apollographql/apollo-client/issues/6451
 */
const CACHE_CONFIG = {
  typePolicies: {
    Query: {
      fields: {
        zoomEvent: {
          merge(_existing: ZoomEventFragment[], incoming: ZoomEventFragment[]) {
            return incoming;
          },
        },
        zoomConnectionRequest: {
          merge(_existing: ZoomConnectionRequest[], incoming: ZoomConnectionRequest[]) {
            return incoming;
          },
        },
        zoomUserNetwork: {
          merge(_existing: ZoomUserNetwork[], incoming: ZoomUserNetwork[]) {
            return incoming;
          },
        },
        zoomUserConnectionRequest: {
          merge(_existing: ZoomUserConnectionRequest[], incoming: ZoomUserConnectionRequest[]) {
            return incoming;
          },
        },
        zoomEventParticipantWithContacts_sorted: {
          merge(_existing: ZoomEventParticipantWithContacts[], incoming: ZoomEventParticipantWithContacts[]) {
            return incoming;
          },
        },
      },
    },
    Subscription: {
      fields: {
        zoomEvent: {
          merge(_existing: ZoomEventFragment[], incoming: ZoomEventFragment[]) {
            return incoming;
          },
        },
        zoomConnectionRequest: {
          merge(_existing: ZoomConnectionRequest[], incoming: ZoomConnectionRequest[]) {
            return incoming;
          },
        },
        zoomUserNetwork: {
          merge(_existing: ZoomUserNetwork[], incoming: ZoomUserNetwork[]) {
            return incoming;
          },
        },
        zoomUserConnectionRequest: {
          merge(_existing: ZoomUserConnectionRequest[], incoming: ZoomUserConnectionRequest[]) {
            return incoming;
          },
        },
        zoomEventParticipantWithContacts_sorted: {
          merge(_existing: ZoomEventParticipantWithContacts[], incoming: ZoomEventParticipantWithContacts[]) {
            return incoming;
          },
        },
      },
    },
  },
};

/* -------------------------------------------------------------------------- */
/*                                APOLLO LINKS                                */
/* -------------------------------------------------------------------------- */
const backendLink = createUploadLink({ uri: zoomApiUrl.graphql });
const hasuraLink = new HttpLink({
  uri: HASURA_URI,
});

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'backend',
  backendLink,
  hasuraLink
);

const wsLink = new WebSocketLink({
  uri: HASURA_WS,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async (): Promise<ConnectionParamsOptions> => {
      const token = await firebaseAuth.currentUser?.getIdToken();
      return {
        headers: {
          ...(token && { authorization: `Bearer ${token}` }),
        },
      };
    },
  },
});

// https://www.apollographql.com/docs/react/data/subscriptions/#3-use-different-transports-for-different-operations
const link = new RetryLink().split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authLink = setContext(async (_, { headers }) => {
  // reload the user to get the latest claims, this is required to check if the token has expired
  await firebaseAuth.currentUser?.reload();
  // return the headers to the context so link can read them
  const token = await firebaseAuth.currentUser?.getIdToken();

  return {
    headers: {
      ...(token && { authorization: `Bearer ${token}` }),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.warn(`[Network error]: ${networkError}`);
  }
});

/* -------------------------------------------------------------------------- */
/*                          APOLLO CLIENT DEFINITION                          */
/* -------------------------------------------------------------------------- */
export const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(link),
  cache: new InMemoryCache(CACHE_CONFIG),
  connectToDevTools,
});

/* -------------------------------------------------------------------------- */
/*                            COMPONENT DEFINITION                            */
/* -------------------------------------------------------------------------- */
const ApolloClientProvider: React.FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const { logOut } = useLogout();

  // Sets the link to allow reset via logout.
  apolloClient.setLink(
    authLink
      .concat(
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ extensions, message, locations, path }) => {
              // eslint-disable-next-line no-console
              console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
              if (extensions?.code === 'UNAUTHENTICATED') {
                logOut();
              }
            });
          }

          if (networkError) {
            // eslint-disable-next-line no-console
            console.warn(`[Network error]: ${networkError}`);
          }
        })
      )
      .concat(link)
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default observer(ApolloClientProvider);
