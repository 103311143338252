/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const NAME_PLACEHOLDER = 'Add your name';
export const PRONOUNS_PLACEHOLDER = 'Add your pronouns';
export const TITLE_PLACEHOLDER = 'Add your title';
export const COMPANY_PLACEHOLDER = 'Add your company';
export const PRONUNCIATION_PLACEHOLDER = 'Add how to say your name';
export const LOCATION_PLACEHOLDER = 'Add your location';
export const BIO_PLACEHOLDER = 'Add a bio. E.g. Father of 2. Amateur chess player. Ted Lasso fan.';
export const LINKEDIN_PLACEHOLDER = 'Add your LinkedIn';
