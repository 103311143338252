import { ProfileViewData } from 'src/utils/profile/profile';

export enum ProfileFieldVisibility {
  /**
   * 🚨 PRIVATE is currently not used
   * Visible only the zoomUser (self)
   *
   * This is the same as `null`: meaning, if there is no setting for a given profile field,
   * we conservatively assume that the field should be private
   */
  Private = 'PRIVATE',
  /**
   * Visible to all visitors, including non-logged in users
   */
  Public = 'PUBLIC',
  /**
   * Labeled as "People I've met"
   * Visible only to logged in users who are also in the zoomUser's network (defined as
   * they have had at least on meeting together in the past)
   */
  Network = 'NETWORK',
  /**
   * 🚨 CONNECTED is currently not used
   *
   * Labeled as "People I'm keeping in touch with"
   * Visible only to contacts that the user has connected with using the keep in touch feature
   */
  Connected = 'CONNECTED',
}

/**
 * We currently only allow two of the four possible visibility settings: Public or Network
 */
export type ValidProfileVisibilitySetting = ProfileFieldVisibility.Public | ProfileFieldVisibility.Network;

export enum ZoomUserProfileVisibilityField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Username = 'username',
  FirstNamePronunciation = 'firstNamePronunciation',
  LastNamePronunciation = 'lastNamePronunciation',
  Pronouns = 'pronouns',
  PhotoUrl = 'photoUrl',
  Headline = 'headline',
  Title = 'title',
  Department = 'department',
  StartDate = 'startDate',
  Location = 'location',
  LinkedInUrl = 'linkedInUrl',
  TwitterUrl = 'twitterUrl',
  ScheduleMeetingUrl = 'scheduleMeetingUrl',
  Company = 'company',
  Ask = 'ask',
  Strengths = 'strengths',
}

// We use `Partial` typing here because profileVisibility is stored as a `jsonb` in Postgres,
// so we cannot guarantee its schema shape (e.g., it's possible for it to be accidentally manually modified to be missing a field)
export type ZoomUserProfileVisibility = Partial<Record<ZoomUserProfileVisibilityField, ValidProfileVisibilitySetting>>;

export const defaultProfileVisibilityByField: Record<ZoomUserProfileVisibilityField, ValidProfileVisibilitySetting> = {
  [ZoomUserProfileVisibilityField.FirstName]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.LastName]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.Email]: ProfileFieldVisibility.Network,
  [ZoomUserProfileVisibilityField.Username]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.FirstNamePronunciation]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.LastNamePronunciation]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.Pronouns]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.PhotoUrl]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.Headline]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.Title]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.Department]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.StartDate]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.Strengths]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.Location]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.LinkedInUrl]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.TwitterUrl]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.ScheduleMeetingUrl]: ProfileFieldVisibility.Network,
  [ZoomUserProfileVisibilityField.Company]: ProfileFieldVisibility.Public,
  [ZoomUserProfileVisibilityField.Ask]: ProfileFieldVisibility.Network,
};

const _filterTopLevelValues = (
  profileField: ZoomUserProfileVisibilityField,
  filteredProfileViewData: ProfileViewData
) => {
  if (profileField === 'company') {
    filteredProfileViewData['zoomCompanyId'] = undefined;
    filteredProfileViewData['zoomCompany'] = undefined;
    filteredProfileViewData['company'] = undefined;
    filteredProfileViewData['companyName'] = undefined;
    filteredProfileViewData['companyWebsiteDomain'] = undefined;
    filteredProfileViewData['companyEstimatedSize'] = undefined;
    filteredProfileViewData['companyCity'] = undefined;
    filteredProfileViewData['companyState'] = undefined;
    filteredProfileViewData['companyLinkedInUrl'] = undefined;
    filteredProfileViewData['companyCrunchbaseUrl'] = undefined;
    filteredProfileViewData['companySeoDescription'] = undefined;
    filteredProfileViewData['companyDescription'] = undefined;
    filteredProfileViewData['logoUrl'] = undefined;
  } else if (profileField === 'location') {
    filteredProfileViewData['city'] = undefined;
    filteredProfileViewData['state'] = undefined;
    filteredProfileViewData['country'] = undefined;
    filteredProfileViewData['location'] = undefined;
    filteredProfileViewData['locationText'] = undefined;
  } else {
    filteredProfileViewData[profileField] = '';
  }
};

/**
 * Takes a profileViewData removes all non-PUBLIC fields based on profile visibility settings
 *
 * @param params.profileViewData The profileViewData from which to get the public data
 * @param params.profileVisibility The visibility setting for profile fields
 * @returns The profileViewData with only the PUBLIC fields data
 */
export const getPublicOnlyProfileViewData = ({
  profileViewData,
  profileVisibility,
}: {
  profileViewData: ProfileViewData;
  profileVisibility: ZoomUserProfileVisibility;
}): ProfileViewData => {
  const publicOnlyProfileViewData = { ...profileViewData };

  for (const profileField of Object.keys(profileVisibility) as ZoomUserProfileVisibilityField[]) {
    if (profileVisibility[profileField] !== ProfileFieldVisibility.Public) {
      _filterTopLevelValues(profileField, publicOnlyProfileViewData);
    }
  }

  return publicOnlyProfileViewData;
};
