const TypeformUrl = {
  base: `https://getwarmly.typeform.com`,
};

export const TypeformSurveyUrl = {
  PmfInProductSurvey: `${TypeformUrl.base}/to/q2XP083C`,
};

export const TypeformSurvey = {
  PmfInProductSurvey: 'PMF In-Product Survey',
};
