export const FORM_INPUT_DEBOUNCE_TIMEOUT = 1000;

/**
 * Timeout in milliseconds for debouncing the html2canvas render.
 */
export const CANVAS_RENDER_DEBOUNCE_FAST = 250;
export const CANVAS_RENDER_DEBOUNCE_MILLISECONDS = CANVAS_RENDER_DEBOUNCE_FAST + 250;
export const CANVAS_RENDER_DEBOUNCE_SLOW_MILLISECONDS = CANVAS_RENDER_DEBOUNCE_MILLISECONDS + 1000;

export const CANVAS_RENDER_TEXT_DEBOUNCE = 1000;
