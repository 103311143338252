export interface OpenUrl {
  url?: string | null;
  newWindow?: boolean;
  height?: number;
  width?: number;
  isResizable?: boolean;
  proxy?: boolean;
  popup?: Window | null;
  isDownload?: boolean;
}

/**
 * Opens a popup, by default a new tab, a new window if height is provided
 * @param params.url The URL to open
 * @param params.height The height of the new window
 * @param params.width The width of the new window
 * @param params.isResizable Whether the new window is resizable
 * @param params.popup The async popup to use if needed
 * @param params.isDownload Whether the popup is being used for a download
 * @returns The popup window object
 */
export const openPopup = ({ url, height, width, isResizable, popup, isDownload }: OpenUrl): Window | null => {
  if (!url) {
    return null;
  }
  if (popup) {
    popup.location.href = url;
    if (isDownload) {
      // Close tab in 3 second if the tab doesn't have focus
      // Helps when Chrome auto-downloads from tab without focus
      window.setTimeout((): void => {
        popup.close();
      }, 3000);
      // Close tab if it had focus and loses focus
      // Helps when FireFox shows save confirmation dialog
      popup.onblur = (): void => {
        popup.close();
      };
    }
    return popup;
  } else {
    // Passing in height means that it's a new window instead of a new tab
    const settings = height ? `height=${height},width=${width},resizable=${isResizable ? 'yes' : 'no'},noreferrer` : '';
    return window.open(url, '_blank', settings);
  }
};
