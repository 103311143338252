/**
 * The type of the conference entry point.
 * Possible values are:
 *
 * "video" - joining a conference over HTTP. A conference can have zero or one video entry point.
 *
 * "phone" - joining a conference by dialing a phone number. A conference can have zero or more phone entry points.
 *
 * "sip" - joining a conference over SIP. A conference can have zero or one sip entry point.
 *
 * "more" - further conference joining instructions, for example additional phone numbers.
 *
 *  A conference can have zero or one more entry point. A conference with only a more entry point is not a valid conference.
 * {@link https://developers.google.com/calendar/v3/reference/events#resource-representations|Calendar API Docs}
 */
export enum ConferenceDataEntryPointType {
  Video = 'video',
  Phone = 'phone',
  Sip = 'sip',
  More = 'more',
}

export enum ConferenceProvider {
  Zoom = 'zoom',
  GoogleMeet = 'google-meet',
  MicrosoftTeams = 'microsoft-teams',
  Other = 'other',
}

export enum GoogleCalendarEventStatus {
  Confirmed = 'confirmed',
  Tentative = 'tentative',
  Canceled = 'cancelled', // It appears that Google Calendar uses the British spelling for "canceled"
}

export enum GoogleScopeName {
  GmailSend = 'gmail_send',
  CalendarEvents = 'calendar_events',
}

export type MicrosoftScope = 'User.Read' | 'profile' | 'email' | 'Calendars.ReadWrite' | 'offline_access' | 'openid';

export const GoogleScopeEndpoint: Record<GoogleScopeName, string> = {
  [GoogleScopeName.GmailSend]: 'https://www.googleapis.com/auth/gmail.send',
  [GoogleScopeName.CalendarEvents]: 'https://www.googleapis.com/auth/calendar.events',
};
