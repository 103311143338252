/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { isBefore } from 'date-fns';
import { makeAutoObservable } from 'mobx';

/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomEventFragment } from 'src/graphql';
import { conformSchedule } from 'src/utils/conformSchedule';
import { MeetingDetailsByWeekday, Weekday } from 'src/utils/constants';
import { getDayTitle } from 'src/utils/time/time';

interface MeetingsStoreState {
  /**
   * Whether the data is still loading via Hasura
   */
  loadingZoomEvents: boolean;
  /**
   * The events returned via Hasura
   */
  zoomEventsFromQuery: ZoomEventFragment[];
  /**
   * The schedule of the current user
   */
  meetingDetailsByWeekday?: MeetingDetailsByWeekday;
  /**
   * The current meeting of the user - either selected by user
   * or auto-detected via meeting ID (from URL params or Zoom's getMeetingUuid API)
   *
   * The order of priority is:
   * 1. zoomMeeting ID from URL params (can be changed by user selection)
   * 2. zoom's meeting UUID from zoom SDK
   * 3. Auto-selected using our app's logic (based on event start/end time)
   */
  currentMeeting: MeetingDetails | undefined;
}

const defaultMeetingsStoreState: MeetingsStoreState = {
  loadingZoomEvents: true,
  zoomEventsFromQuery: [],
  meetingDetailsByWeekday: undefined,
  currentMeeting: undefined,
};

/**
 * Meetings related data and methods
 */
class MeetingsStore implements MeetingsStoreState {
  loadingZoomEvents = defaultMeetingsStoreState.loadingZoomEvents;
  zoomEventsFromQuery = defaultMeetingsStoreState.zoomEventsFromQuery;
  meetingDetailsByWeekday = defaultMeetingsStoreState.meetingDetailsByWeekday;
  currentMeeting = defaultMeetingsStoreState.currentMeeting;

  get hasMeetings() {
    return Boolean(this.zoomEventsFromQuery.length);
  }

  get todaysMeetings() {
    const filter = (record: [string, MeetingDetails[]]) => {
      const allowed = ['today'];
      return allowed.includes(getDayTitle(record[0] as Weekday));
    };

    return this.meetingDetailsByWeekday ? Object.entries(this.meetingDetailsByWeekday).filter(filter) : [];
  }

  get todaysUpcomingMeetings() {
    const filter = (record: [string, MeetingDetails[]]) => {
      const allowed = ['today'];
      return allowed.includes(getDayTitle(record[0] as Weekday));
    };

    const filtered = this.meetingDetailsByWeekday ? Object.entries(this.meetingDetailsByWeekday).filter(filter) : [];
    return ((filtered || [])?.[0]?.[1] || [])
      .filter((m) => {
        if (!m.when.end?.time) return true;
        return !isBefore(new Date(m.when.end?.timeMs), new Date());
      })
      .slice(0, 5);
  }

  get hasFutureMeeting() {
    return Boolean(
      this.zoomEventsFromQuery.find((zoomEvent) => {
        const eventStartDate = zoomEvent.start ? new Date(zoomEvent.start) : undefined;
        return !!(eventStartDate && eventStartDate > new Date());
      })
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Sets `loadingZoomEvents`
   * @param loadingZoomEvents Whether our app is loading zoomEvents
   */
  setIsLoadingZoomEvents = (loadingZoomEvents: boolean) => {
    this.loadingZoomEvents = loadingZoomEvents;
  };

  /**
   * Sets `zoomEventsFromQuery`
   * This will automatically update `meetingDetailsByWeekday` based on the zoomEvents
   * @param zoomEventsFromQuery zoomEvents from Apollo GraphQL query
   */
  setZoomEventsFromQuery = (zoomEventsFromQuery: ZoomEventFragment[]) => {
    this.zoomEventsFromQuery = zoomEventsFromQuery;
    this.meetingDetailsByWeekday = conformSchedule(zoomEventsFromQuery);
  };

  /**
   * Sets `currentMeeting`
   * @param currentMeeting The current meeting (shown/selected in the Meetings page)
   */
  setCurrentMeeting = (currentMeeting: MeetingDetails) => {
    this.currentMeeting = currentMeeting;
  };

  /**
   * Resets the MeetingsStore to its default state (e.g., used when user logs out)
   */
  resetMeetingsStoreState = () => {
    this.loadingZoomEvents = defaultMeetingsStoreState.loadingZoomEvents;
    this.zoomEventsFromQuery = defaultMeetingsStoreState.zoomEventsFromQuery;
    this.meetingDetailsByWeekday = defaultMeetingsStoreState.meetingDetailsByWeekday;
  };
}

export default MeetingsStore;
