/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import SSOLoginStore from 'src/components/SSOLogin/SSOLogin.store';
import HomePageStore from 'src/pages/Home/Home.store';
import AdminStore from 'src/stores/Admin.store';
import BackgroundStore from 'src/stores/Background.store';
import ConversationStarterStore from 'src/stores/ConversationStarter.store';
import CustomBannerStore from 'src/stores/CustomBanner.store';
import LayersStore from 'src/stores/Layers.store';
import LiveUpdateStore from 'src/stores/LiveUpdate.store';
import MeetingsStore from 'src/stores/Meetings.store';
import NametagBuilderStore from 'src/stores/NametagBuilder.store';
import SettingsStore from 'src/stores/Settings.store';
import TabStore from 'src/stores/Tab.store';
import TheOrgStore from 'src/stores/TheOrg.store';
import ToastNotificationStore from 'src/stores/ToastNotification.store';
import UserStore from 'src/stores/User.store';
import UserCalendarStore from 'src/stores/UserCalendar.store';
import WidgetStore from 'src/stores/Widget.store';
import { AllWidgetKeys, ConcreteWidgetType } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                              STORE DEFINITION                              */
/* -------------------------------------------------------------------------- */
/**
 * The root store that combines all MobX stores
 */
class RootStore {
  adminStore: AdminStore;
  userStore: UserStore;
  userCalendarStore: UserCalendarStore;
  meetingsStore: MeetingsStore;
  backgroundStore: BackgroundStore;
  customBannerStore: CustomBannerStore;
  theOrgStore: TheOrgStore;
  ssoLoginStore: SSOLoginStore;
  conversationStarterStore: ConversationStarterStore;
  tabStore: TabStore;
  settingsStore: SettingsStore;
  layersStore: LayersStore;
  homePageStore: HomePageStore;
  nametagBuilderStore: NametagBuilderStore;
  liveUpdateStore: LiveUpdateStore;
  widgetsStore: ConcreteWidgetType;
  toastNotificationStore: ToastNotificationStore;

  /**
   * @param disablePersistance if true does not persist the stores
   */
  constructor(disablePersistance = false) {
    this.adminStore = new AdminStore(disablePersistance);
    this.userStore = new UserStore(this);
    this.userCalendarStore = new UserCalendarStore(this);
    this.meetingsStore = new MeetingsStore();
    this.backgroundStore = new BackgroundStore(disablePersistance);
    this.customBannerStore = new CustomBannerStore();
    this.theOrgStore = new TheOrgStore(disablePersistance);
    this.ssoLoginStore = new SSOLoginStore();
    this.conversationStarterStore = new ConversationStarterStore(disablePersistance);
    this.tabStore = new TabStore(disablePersistance);
    this.settingsStore = new SettingsStore();
    this.homePageStore = new HomePageStore(this);
    this.nametagBuilderStore = new NametagBuilderStore();
    this.layersStore = new LayersStore(disablePersistance);
    this.liveUpdateStore = new LiveUpdateStore();
    this.toastNotificationStore = new ToastNotificationStore();

    this.widgetsStore = {};

    for (const key of AllWidgetKeys) {
      if (key !== 'none') {
        this.widgetsStore[key] = new WidgetStore(key) as never;
      }
    }
  }
}

export default RootStore;
