/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';

/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomUserFragment } from 'src/graphql';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export const NametagTabs = ['Personal', 'Work', 'Socials', 'Settings', 'Background'] as const;
export type NametagTab = typeof NametagTabs[number];
export const BackgroundFilters = [
  'All',
  'My Backgrounds',
  'Organization Approved',
  'Shared Backgrounds',
  'Holiday',
  'AI Background',
] as const;
export type BackgroundFilter = typeof BackgroundFilters[number];
const NametagFields: Partial<Record<NametagTab, Array<keyof ZoomUserFragment>>> = {
  Personal: [
    'firstName',
    'lastName',
    'pronouns',
    'photoUrl',
    'firstNamePronunciation',
    'lastNamePronunciation',
    'headline',
    'location',
  ],
  Work: ['companyName', 'companyLogoUrl', 'title', 'startDate', 'strengths'],
  Socials: ['linkedInUrl', 'username', 'twitterUrl', 'calendarEnrichmentStatus'],
};

/* -------------------------------------------------------------------------- */
/*                                    STATE                                   */
/* -------------------------------------------------------------------------- */
export interface NametagBuilderState {
  selectedTab: NametagTab;
  selectedBackgroundFilter: BackgroundFilter;
  expanded: boolean;
}

const initialState: NametagBuilderState = {
  selectedTab: 'Personal',
  selectedBackgroundFilter: 'All',
  expanded: false,
};

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class NametagBuilderStore implements NametagBuilderState {
  /* ---------------------------- MEMBER VARIABLES ---------------------------- */
  selectedTab = initialState.selectedTab;
  selectedBackgroundFilter = initialState.selectedBackgroundFilter;
  expanded = initialState.expanded;

  /* --------------------------------- ACTIONS -------------------------------- */
  setSelectedTab = (selectedTab: NametagBuilderState['selectedTab']) => (this.selectedTab = selectedTab);

  setSelectedBackgroundFilter = (selectedBackgroundFilter: NametagBuilderState['selectedBackgroundFilter']) =>
    (this.selectedBackgroundFilter = selectedBackgroundFilter);

  selectNametagField = (field: keyof ZoomUserFragment) => {
    let tab: NametagTab | undefined = undefined;
    Object.entries(NametagFields).forEach(([key, fields], i) => {
      const _key = key as NametagTab;
      if (fields.includes(field)) tab = _key;
    });

    if (tab) {
      this.setSelectedTab(tab);
      setTimeout(() => document.getElementById(`${field}-profile-form-field`)?.focus(), 100);
    }
  };

  setExpanded = (expanded: boolean) => (this.expanded = expanded);

  /* ------------------------------- CONSTRUCTOR ------------------------------ */
  constructor() {
    makeAutoObservable(this);
  }
}
