/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

/* --------------------------------- CUSTOM --------------------------------- */
import { QueryZoomSettingsObjectsQuery, ZoomGroupFragment, ZoomSettingsFragment } from 'src/graphql';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export interface AdminStoreState {
  groups: ZoomGroupFragment[];
  groupSettings: ZoomSettingsFragment[];
  orgSettings: ZoomSettingsFragment[];
  auditLogId: string | null;
}

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const localDefaultAdminStoreState: AdminStoreState = {
  groups: [],
  groupSettings: [],
  orgSettings: [],
  auditLogId: null,
};

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class AdminStore implements AdminStoreState {
  groups = localDefaultAdminStoreState.groups;
  groupSettings = localDefaultAdminStoreState.groupSettings;
  orgSettings = localDefaultAdminStoreState.orgSettings;
  auditLogId = localDefaultAdminStoreState.auditLogId;

  setGroups = (groups: ZoomGroupFragment[]) => {
    this.groups = groups;
  };

  setGroupSettings = (data: QueryZoomSettingsObjectsQuery) => {
    this.groupSettings = [...data?.zoomSettings.filter((x) => x.identifier === 'group')];
  };

  setOrgSettings = (data: QueryZoomSettingsObjectsQuery) => {
    this.orgSettings = [...data?.zoomSettings.filter((x) => x.identifier === 'org')];
  };

  setAuditLogId = (id: string) => {
    this.auditLogId = id;
  };

  constructor(disablePersistence: boolean) {
    makeAutoObservable(this);
    !disablePersistence &&
      makePersistable(this, {
        name: 'AdminStore',
        properties: ['groups', 'groupSettings', 'orgSettings'],
        storage: window.localStorage,
      });
  }
}
