/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { PropsWithChildren, Suspense } from 'react';

/* --------------------------------- CUSTOM --------------------------------- */
import { PreloaderInstance } from 'src/main/Preloader';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
interface WithSuspenseProps {
  forceShow?: boolean;
  isLayersLoader?: boolean;
}

/* -------------------------------------------------------------------------- */
/*                            COMPONENT DEFINITION                            */
/* -------------------------------------------------------------------------- */
const WithSuspense: React.FC<PropsWithChildren<WithSuspenseProps>> = ({
  children,
  forceShow = true,
  isLayersLoader,
}) => {
  /* --------------------------- RENDERED COMPONENT --------------------------- */
  return (
    <Suspense
      fallback={
        <PreloaderInstance
          forceShow={forceShow}
          isZoomSpinner={isLayersLoader}
          message={isLayersLoader ? 'Loading...' : undefined}
        />
      }
    >
      {children}
    </Suspense>
  );
};

export default WithSuspense;
