import brokenCloudsDay from 'src/assets/weatherIcons/brokenCloudsDay.svg';
import brokenCloudsNight from 'src/assets/weatherIcons/brokenCloudsNight.svg';
import clearskyDay from 'src/assets/weatherIcons/clearskyDay.svg';
import clearskyNight from 'src/assets/weatherIcons/clearskyNight.svg';
import fewCloudsDay from 'src/assets/weatherIcons/fewCloudsDay.svg';
import fewCloudsNight from 'src/assets/weatherIcons/fewCloudsNight.svg';
import mistDay from 'src/assets/weatherIcons/mistDay.svg';
import mistNight from 'src/assets/weatherIcons/mistNight.svg';
import rainDay from 'src/assets/weatherIcons/rainDay.svg';
import rainNight from 'src/assets/weatherIcons/rainNight.svg';
import scatteredShowersDay from 'src/assets/weatherIcons/scatteredShowersDay.svg';
import scatteredShowersNight from 'src/assets/weatherIcons/scatteredShowersNight.svg';
import showerRainDay from 'src/assets/weatherIcons/showerRainDay.svg';
import showerRainNight from 'src/assets/weatherIcons/showerRainNight.svg';
import snowDay from 'src/assets/weatherIcons/snowDay.svg';
import snowNight from 'src/assets/weatherIcons/snowNight.svg';
import thunderstormDay from 'src/assets/weatherIcons/thunderstormDay.svg';
import thunderstormNight from 'src/assets/weatherIcons/thunderstormNight.svg';

export {
  brokenCloudsDay,
  brokenCloudsNight,
  clearskyDay,
  clearskyNight,
  fewCloudsDay,
  fewCloudsNight,
  mistDay,
  mistNight,
  rainDay,
  rainNight,
  scatteredShowersDay,
  scatteredShowersNight,
  showerRainDay,
  showerRainNight,
  snowDay,
  snowNight,
  thunderstormDay,
  thunderstormNight,
};

/**
 * Enum key/name is based on weather icon description
 * Enum value is based on weather icon code
 * {@link https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2}
 */
export enum OpenWeatherIconCode {
  ClearSkyDay = '01d',
  FewCloudsDay = '02d',
  ScatteredCloudsDay = '03d',
  BrokenCloudsDay = '04d',
  ShowerRainDay = '09d',
  RainDay = '10d',
  ThunderstormDay = '11d',
  SnowDay = '13d',
  MistDay = '50d',
  ClearSkyNight = '01n',
  FewCloudsNight = '02n',
  ScatteredCloudsNight = '03n',
  BrokenCloudsNight = '04n',
  ShowerRainNight = '09n',
  RainNight = '10n',
  ThunderstormNight = '11n',
  SnowNight = '13n',
  MistNight = '50n',
}

export enum TemperatureScale {
  Celsius = 'celsius',
  Fahrenheit = 'fahrenheit',
}
