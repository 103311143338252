/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

/* --------------------------------- CUSTOM --------------------------------- */
import { BackgroundType, WIDGET_KEY } from 'src/utils/constants';
import { LiveReloadType } from 'src/utils/constants/livereload';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export interface LiveUpdateStoreState {
  reloadType: LiveReloadType;
  liveMessage?: LiveUpdateMessage;
  shouldLiveUpdateBackground: boolean;
  shouldLiveUpdateForeground: boolean;
  shouldRestartWidgets: boolean;
  isStartingLayers: boolean;
  isRunningRenderingContext: boolean;
  updateCount: number;
  pendingFlagRefresh: boolean;
  pendingBoostStart: WIDGET_KEY;
  onReloadFinish?: () => void;

  canvasState: {
    nametag: { enabled: boolean };
    background: { type: BackgroundType; imgUrl: string };
  };
}

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const defaultLiveUpdateStoreState: LiveUpdateStoreState = {
  reloadType: 'none',
  liveMessage: undefined,
  shouldLiveUpdateBackground: false,
  shouldLiveUpdateForeground: false,
  shouldRestartWidgets: false,
  isStartingLayers: false,
  isRunningRenderingContext: false,
  updateCount: 0,
  pendingFlagRefresh: false,
  pendingBoostStart: 'none',
  onReloadFinish: undefined,
  canvasState: { nametag: { enabled: false }, background: { type: 'none', imgUrl: '' } },
};

export enum LiveUpdateMessage {
  TurnOnVideo = 'Turn on your video to see nametag',
  RemovingMagic = 'Removing nametag',
  MagicRemoved = 'Nametag removed!',
  MagicAdded = 'Nametag added!',
  AddingMagic = '✨Adding Nametag',
  CantAddMagic = "Can't add nametag",
  LoadingBoosts = 'Loading Meeting Boosts',
}

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class LiveUpdateStore implements LiveUpdateStoreState {
  reloadType = defaultLiveUpdateStoreState.reloadType;
  liveMessage = defaultLiveUpdateStoreState.liveMessage;
  shouldLiveUpdateBackground = defaultLiveUpdateStoreState.shouldLiveUpdateBackground;
  shouldLiveUpdateForeground = defaultLiveUpdateStoreState.shouldLiveUpdateForeground;
  shouldRestartWidgets = defaultLiveUpdateStoreState.shouldRestartWidgets;
  isStartingLayers = defaultLiveUpdateStoreState.isStartingLayers;
  isRunningRenderingContext = defaultLiveUpdateStoreState.isRunningRenderingContext;
  updateCount = defaultLiveUpdateStoreState.updateCount;

  pendingFlagRefresh = defaultLiveUpdateStoreState.pendingFlagRefresh;
  pendingBoostStart = defaultLiveUpdateStoreState.pendingBoostStart;

  onReloadFinish = defaultLiveUpdateStoreState.onReloadFinish;

  canvasState = defaultLiveUpdateStoreState.canvasState;

  get isUpdating() {
    return this.reloadType !== 'none';
  }

  setLiveMessage = (liveMessage?: LiveUpdateMessage) => {
    this.liveMessage = liveMessage;
  };

  setShouldLiveUpdateBackground = (shouldLiveUpdateBackground: boolean) => {
    this.shouldLiveUpdateBackground = shouldLiveUpdateBackground;
  };

  setShouldLiveUpdateForeground = (shouldLiveUpdateForeground: boolean) => {
    this.shouldLiveUpdateForeground = shouldLiveUpdateForeground;
  };

  setShouldRestartWidgets = (shouldRestartWidgets: boolean) => {
    this.shouldRestartWidgets = shouldRestartWidgets;
  };

  setIsStartingLayers = (isStartingLayers: boolean) => {
    this.isStartingLayers = isStartingLayers;
  };

  setIsRunningRenderingContext = (isRunningRenderingContext: boolean) => {
    this.isRunningRenderingContext = isRunningRenderingContext;
  };

  /**
   * Used to set reloadType.  If force is false, then setting reloadType is ignored unless force is true.
   * @param type LiveReloadType
   * @param force true to force setting 'foreground' reloadType
   * @returns void
   */
  reload: {
    (reloadType: 'nametag', force: boolean): void;
    (reloadType: Exclude<LiveReloadType, 'nametag'>): void;
  } = (type: LiveReloadType, force?: boolean) => {
    if (!force && type === 'nametag') {
      return;
    }
    this.reloadType = type;
  };

  reloadSlide = (callback: () => void) => {
    this.onReloadFinish = callback;
    this.reloadType = 'slide';
  };

  reloadNametag = (callback: () => void) => {
    this.onReloadFinish = callback;
    this.reloadType = 'nametag';
  };

  setCanvasState = (state: LiveUpdateStoreState['canvasState']) => {
    this.canvasState = state;
  };

  setPendingFlagRefresh = (pendingFlagRefresh: boolean) => {
    this.pendingFlagRefresh = pendingFlagRefresh;
  };

  setPendingBoostStart = (key: WIDGET_KEY) => {
    this.pendingBoostStart = key;
  };

  incrementUpdateCount = () => {
    this.updateCount += 1;
  };

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'LiveUpdate', properties: ['updateCount'], storage: window.localStorage });
  }

  resetStoreState = () => {
    this.updateCount = defaultLiveUpdateStoreState.updateCount;
  };
}
