import {
  ObjectWithId,
  generateOptimisticResponseForMutation,
  generateUpdateFunctionForMutation,
  stripInsertInputClientFields,
} from 'graphql-codegen-hasura-core';
import {
  QueryHookOptions,
  useQuery,
  LazyQueryHookOptions,
  useLazyQuery,
  MutationHookOptions,
  useMutation,
  QueryLazyOptions,
  MutationFunctionOptions,
  LazyQueryResult,
  MutationTuple,
  FetchResult,
  SubscriptionResult,
  SubscriptionHookOptions,
  useSubscription,
  ApolloError,
  SubscribeToMoreOptions,
} from '@apollo/client';
import { ZoomContactFragment } from '../';
import { QueryZoomContactByIdQuery } from '../';
import { QueryZoomContactByIdQueryVariables } from '../';
import { QueryZoomContactByIdDocument } from '../';
import { QueryZoomContactObjectsQuery } from '../';
import { QueryZoomContactObjectsDocument } from '../';
import { QueryZoomContactObjectsQueryVariables } from '../';
import { SubscribeToZoomContactByIdSubscription } from '../';
import { SubscribeToZoomContactByIdSubscriptionVariables } from '../';
import { SubscribeToZoomContactByIdDocument } from '../';
import { SubscribeToZoomContactObjectsSubscription } from '../';
import { SubscribeToZoomContactObjectsDocument } from '../';
import { SubscribeToZoomContactObjectsSubscriptionVariables } from '../';
import { ZoomContact_Insert_Input } from '../';
import { ZoomContact_On_Conflict } from '../';
import { InsertZoomContactMutation } from '../';
import { InsertZoomContactWithOnConflictMutation } from '../';
import { InsertZoomContactMutationVariables } from '../';
import { InsertZoomContactWithOnConflictMutationVariables } from '../';
import { InsertZoomContactDocument } from '../';
import { InsertZoomContactWithOnConflictDocument } from '../';
import { ZoomContact_Set_Input } from '../';
import { UpdateZoomContactByIdMutation } from '../';
import { UpdateZoomContactByIdMutationVariables } from '../';
import { UpdateZoomContactByIdDocument } from '../';
import { UpdateZoomContactMutation } from '../';
import { UpdateZoomContactMutationVariables } from '../';
import { UpdateZoomContactDocument } from '../';
import { RemoveZoomContactModelMutation } from '../';
import { RemoveZoomContactModelMutationVariables } from '../';
import { RemoveZoomContactModelDocument } from '../';
import { RemoveZoomContactModelByIdMutation } from '../';
import { RemoveZoomContactModelByIdMutationVariables } from '../';
import { RemoveZoomContactModelByIdDocument } from '../';
import { ZoomCompanyFragment } from '../';
import { QueryZoomCompanyByIdQuery } from '../';
import { QueryZoomCompanyByIdQueryVariables } from '../';
import { QueryZoomCompanyByIdDocument } from '../';
import { QueryZoomCompanyObjectsQuery } from '../';
import { QueryZoomCompanyObjectsDocument } from '../';
import { QueryZoomCompanyObjectsQueryVariables } from '../';
import { SubscribeToZoomCompanyByIdSubscription } from '../';
import { SubscribeToZoomCompanyByIdSubscriptionVariables } from '../';
import { SubscribeToZoomCompanyByIdDocument } from '../';
import { SubscribeToZoomCompanyObjectsSubscription } from '../';
import { SubscribeToZoomCompanyObjectsDocument } from '../';
import { SubscribeToZoomCompanyObjectsSubscriptionVariables } from '../';
import { ZoomCompany_Insert_Input } from '../';
import { ZoomCompany_On_Conflict } from '../';
import { InsertZoomCompanyMutation } from '../';
import { InsertZoomCompanyWithOnConflictMutation } from '../';
import { InsertZoomCompanyMutationVariables } from '../';
import { InsertZoomCompanyWithOnConflictMutationVariables } from '../';
import { InsertZoomCompanyDocument } from '../';
import { InsertZoomCompanyWithOnConflictDocument } from '../';
import { ZoomCompany_Set_Input } from '../';
import { UpdateZoomCompanyByIdMutation } from '../';
import { UpdateZoomCompanyByIdMutationVariables } from '../';
import { UpdateZoomCompanyByIdDocument } from '../';
import { UpdateZoomCompanyMutation } from '../';
import { UpdateZoomCompanyMutationVariables } from '../';
import { UpdateZoomCompanyDocument } from '../';
import { RemoveZoomCompanyModelMutation } from '../';
import { RemoveZoomCompanyModelMutationVariables } from '../';
import { RemoveZoomCompanyModelDocument } from '../';
import { RemoveZoomCompanyModelByIdMutation } from '../';
import { RemoveZoomCompanyModelByIdMutationVariables } from '../';
import { RemoveZoomCompanyModelByIdDocument } from '../';
import { ZoomGroupFragment } from '../';
import { QueryZoomGroupByIdQuery } from '../';
import { QueryZoomGroupByIdQueryVariables } from '../';
import { QueryZoomGroupByIdDocument } from '../';
import { QueryZoomGroupObjectsQuery } from '../';
import { QueryZoomGroupObjectsDocument } from '../';
import { QueryZoomGroupObjectsQueryVariables } from '../';
import { SubscribeToZoomGroupByIdSubscription } from '../';
import { SubscribeToZoomGroupByIdSubscriptionVariables } from '../';
import { SubscribeToZoomGroupByIdDocument } from '../';
import { SubscribeToZoomGroupObjectsSubscription } from '../';
import { SubscribeToZoomGroupObjectsDocument } from '../';
import { SubscribeToZoomGroupObjectsSubscriptionVariables } from '../';
import { ZoomGroup_Insert_Input } from '../';
import { ZoomGroup_On_Conflict } from '../';
import { InsertZoomGroupMutation } from '../';
import { InsertZoomGroupWithOnConflictMutation } from '../';
import { InsertZoomGroupMutationVariables } from '../';
import { InsertZoomGroupWithOnConflictMutationVariables } from '../';
import { InsertZoomGroupDocument } from '../';
import { InsertZoomGroupWithOnConflictDocument } from '../';
import { ZoomGroup_Set_Input } from '../';
import { UpdateZoomGroupByIdMutation } from '../';
import { UpdateZoomGroupByIdMutationVariables } from '../';
import { UpdateZoomGroupByIdDocument } from '../';
import { UpdateZoomGroupMutation } from '../';
import { UpdateZoomGroupMutationVariables } from '../';
import { UpdateZoomGroupDocument } from '../';
import { RemoveZoomGroupModelMutation } from '../';
import { RemoveZoomGroupModelMutationVariables } from '../';
import { RemoveZoomGroupModelDocument } from '../';
import { RemoveZoomGroupModelByIdMutation } from '../';
import { RemoveZoomGroupModelByIdMutationVariables } from '../';
import { RemoveZoomGroupModelByIdDocument } from '../';
import { ZoomContactWithCompanyFragment } from '../';
import { QueryZoomContactWithCompanyByIdQuery } from '../';
import { QueryZoomContactWithCompanyByIdQueryVariables } from '../';
import { QueryZoomContactWithCompanyByIdDocument } from '../';
import { QueryZoomContactWithCompanyObjectsQuery } from '../';
import { QueryZoomContactWithCompanyObjectsDocument } from '../';
import { QueryZoomContactWithCompanyObjectsQueryVariables } from '../';
import { SubscribeToZoomContactWithCompanyByIdSubscription } from '../';
import { SubscribeToZoomContactWithCompanyByIdSubscriptionVariables } from '../';
import { SubscribeToZoomContactWithCompanyByIdDocument } from '../';
import { SubscribeToZoomContactWithCompanyObjectsSubscription } from '../';
import { SubscribeToZoomContactWithCompanyObjectsDocument } from '../';
import { SubscribeToZoomContactWithCompanyObjectsSubscriptionVariables } from '../';
import { InsertZoomContactWithCompanyMutation } from '../';
import { InsertZoomContactWithCompanyWithOnConflictMutation } from '../';
import { InsertZoomContactWithCompanyMutationVariables } from '../';
import { InsertZoomContactWithCompanyWithOnConflictMutationVariables } from '../';
import { InsertZoomContactWithCompanyDocument } from '../';
import { InsertZoomContactWithCompanyWithOnConflictDocument } from '../';
import { UpdateZoomContactWithCompanyByIdMutation } from '../';
import { UpdateZoomContactWithCompanyByIdMutationVariables } from '../';
import { UpdateZoomContactWithCompanyByIdDocument } from '../';
import { UpdateZoomContactWithCompanyMutation } from '../';
import { UpdateZoomContactWithCompanyMutationVariables } from '../';
import { UpdateZoomContactWithCompanyDocument } from '../';
import { ZoomUserActionsFragment } from '../';
import { QueryZoomUserActionsByIdQuery } from '../';
import { QueryZoomUserActionsByIdQueryVariables } from '../';
import { QueryZoomUserActionsByIdDocument } from '../';
import { QueryZoomUserActionsObjectsQuery } from '../';
import { QueryZoomUserActionsObjectsDocument } from '../';
import { QueryZoomUserActionsObjectsQueryVariables } from '../';
import { SubscribeToZoomUserActionsByIdSubscription } from '../';
import { SubscribeToZoomUserActionsByIdSubscriptionVariables } from '../';
import { SubscribeToZoomUserActionsByIdDocument } from '../';
import { SubscribeToZoomUserActionsObjectsSubscription } from '../';
import { SubscribeToZoomUserActionsObjectsDocument } from '../';
import { SubscribeToZoomUserActionsObjectsSubscriptionVariables } from '../';
import { ZoomUserActions_Insert_Input } from '../';
import { ZoomUserActions_On_Conflict } from '../';
import { InsertZoomUserActionsMutation } from '../';
import { InsertZoomUserActionsWithOnConflictMutation } from '../';
import { InsertZoomUserActionsMutationVariables } from '../';
import { InsertZoomUserActionsWithOnConflictMutationVariables } from '../';
import { InsertZoomUserActionsDocument } from '../';
import { InsertZoomUserActionsWithOnConflictDocument } from '../';
import { ZoomUserActions_Set_Input } from '../';
import { UpdateZoomUserActionsByIdMutation } from '../';
import { UpdateZoomUserActionsByIdMutationVariables } from '../';
import { UpdateZoomUserActionsByIdDocument } from '../';
import { UpdateZoomUserActionsMutation } from '../';
import { UpdateZoomUserActionsMutationVariables } from '../';
import { UpdateZoomUserActionsDocument } from '../';
import { RemoveZoomUserActionsModelMutation } from '../';
import { RemoveZoomUserActionsModelMutationVariables } from '../';
import { RemoveZoomUserActionsModelDocument } from '../';
import { RemoveZoomUserActionsModelByIdMutation } from '../';
import { RemoveZoomUserActionsModelByIdMutationVariables } from '../';
import { RemoveZoomUserActionsModelByIdDocument } from '../';
import { ZoomGroupMembershipFragment } from '../';
import { QueryZoomGroupMembershipByIdQuery } from '../';
import { QueryZoomGroupMembershipByIdQueryVariables } from '../';
import { QueryZoomGroupMembershipByIdDocument } from '../';
import { QueryZoomGroupMembershipObjectsQuery } from '../';
import { QueryZoomGroupMembershipObjectsDocument } from '../';
import { QueryZoomGroupMembershipObjectsQueryVariables } from '../';
import { SubscribeToZoomGroupMembershipByIdSubscription } from '../';
import { SubscribeToZoomGroupMembershipByIdSubscriptionVariables } from '../';
import { SubscribeToZoomGroupMembershipByIdDocument } from '../';
import { SubscribeToZoomGroupMembershipObjectsSubscription } from '../';
import { SubscribeToZoomGroupMembershipObjectsDocument } from '../';
import { SubscribeToZoomGroupMembershipObjectsSubscriptionVariables } from '../';
import { ZoomGroupMembership_Insert_Input } from '../';
import { ZoomGroupMembership_On_Conflict } from '../';
import { InsertZoomGroupMembershipMutation } from '../';
import { InsertZoomGroupMembershipWithOnConflictMutation } from '../';
import { InsertZoomGroupMembershipMutationVariables } from '../';
import { InsertZoomGroupMembershipWithOnConflictMutationVariables } from '../';
import { InsertZoomGroupMembershipDocument } from '../';
import { InsertZoomGroupMembershipWithOnConflictDocument } from '../';
import { ZoomGroupMembership_Set_Input } from '../';
import { UpdateZoomGroupMembershipByIdMutation } from '../';
import { UpdateZoomGroupMembershipByIdMutationVariables } from '../';
import { UpdateZoomGroupMembershipByIdDocument } from '../';
import { UpdateZoomGroupMembershipMutation } from '../';
import { UpdateZoomGroupMembershipMutationVariables } from '../';
import { UpdateZoomGroupMembershipDocument } from '../';
import { RemoveZoomGroupMembershipModelMutation } from '../';
import { RemoveZoomGroupMembershipModelMutationVariables } from '../';
import { RemoveZoomGroupMembershipModelDocument } from '../';
import { RemoveZoomGroupMembershipModelByIdMutation } from '../';
import { RemoveZoomGroupMembershipModelByIdMutationVariables } from '../';
import { RemoveZoomGroupMembershipModelByIdDocument } from '../';
import { ZoomUserFragment } from '../';
import { QueryZoomUserByIdQuery } from '../';
import { QueryZoomUserByIdQueryVariables } from '../';
import { QueryZoomUserByIdDocument } from '../';
import { QueryZoomUserObjectsQuery } from '../';
import { QueryZoomUserObjectsDocument } from '../';
import { QueryZoomUserObjectsQueryVariables } from '../';
import { SubscribeToZoomUserByIdSubscription } from '../';
import { SubscribeToZoomUserByIdSubscriptionVariables } from '../';
import { SubscribeToZoomUserByIdDocument } from '../';
import { SubscribeToZoomUserObjectsSubscription } from '../';
import { SubscribeToZoomUserObjectsDocument } from '../';
import { SubscribeToZoomUserObjectsSubscriptionVariables } from '../';
import { ZoomUser_Insert_Input } from '../';
import { ZoomUser_On_Conflict } from '../';
import { InsertZoomUserMutation } from '../';
import { InsertZoomUserWithOnConflictMutation } from '../';
import { InsertZoomUserMutationVariables } from '../';
import { InsertZoomUserWithOnConflictMutationVariables } from '../';
import { InsertZoomUserDocument } from '../';
import { InsertZoomUserWithOnConflictDocument } from '../';
import { ZoomUser_Set_Input } from '../';
import { UpdateZoomUserByIdMutation } from '../';
import { UpdateZoomUserByIdMutationVariables } from '../';
import { UpdateZoomUserByIdDocument } from '../';
import { UpdateZoomUserMutation } from '../';
import { UpdateZoomUserMutationVariables } from '../';
import { UpdateZoomUserDocument } from '../';
import { RemoveZoomUserModelMutation } from '../';
import { RemoveZoomUserModelMutationVariables } from '../';
import { RemoveZoomUserModelDocument } from '../';
import { RemoveZoomUserModelByIdMutation } from '../';
import { RemoveZoomUserModelByIdMutationVariables } from '../';
import { RemoveZoomUserModelByIdDocument } from '../';
import { ZoomContactNotesFragment } from '../';
import { QueryZoomContactNotesByIdQuery } from '../';
import { QueryZoomContactNotesByIdQueryVariables } from '../';
import { QueryZoomContactNotesByIdDocument } from '../';
import { QueryZoomContactNotesObjectsQuery } from '../';
import { QueryZoomContactNotesObjectsDocument } from '../';
import { QueryZoomContactNotesObjectsQueryVariables } from '../';
import { SubscribeToZoomContactNotesByIdSubscription } from '../';
import { SubscribeToZoomContactNotesByIdSubscriptionVariables } from '../';
import { SubscribeToZoomContactNotesByIdDocument } from '../';
import { SubscribeToZoomContactNotesObjectsSubscription } from '../';
import { SubscribeToZoomContactNotesObjectsDocument } from '../';
import { SubscribeToZoomContactNotesObjectsSubscriptionVariables } from '../';
import { ZoomContactNotes_Insert_Input } from '../';
import { ZoomContactNotes_On_Conflict } from '../';
import { InsertZoomContactNotesMutation } from '../';
import { InsertZoomContactNotesWithOnConflictMutation } from '../';
import { InsertZoomContactNotesMutationVariables } from '../';
import { InsertZoomContactNotesWithOnConflictMutationVariables } from '../';
import { InsertZoomContactNotesDocument } from '../';
import { InsertZoomContactNotesWithOnConflictDocument } from '../';
import { ZoomContactNotes_Set_Input } from '../';
import { UpdateZoomContactNotesByIdMutation } from '../';
import { UpdateZoomContactNotesByIdMutationVariables } from '../';
import { UpdateZoomContactNotesByIdDocument } from '../';
import { UpdateZoomContactNotesMutation } from '../';
import { UpdateZoomContactNotesMutationVariables } from '../';
import { UpdateZoomContactNotesDocument } from '../';
import { RemoveZoomContactNotesModelMutation } from '../';
import { RemoveZoomContactNotesModelMutationVariables } from '../';
import { RemoveZoomContactNotesModelDocument } from '../';
import { RemoveZoomContactNotesModelByIdMutation } from '../';
import { RemoveZoomContactNotesModelByIdMutationVariables } from '../';
import { RemoveZoomContactNotesModelByIdDocument } from '../';
import { GoogleChannelFragment } from '../';
import { QueryGoogleChannelByIdQuery } from '../';
import { QueryGoogleChannelByIdQueryVariables } from '../';
import { QueryGoogleChannelByIdDocument } from '../';
import { QueryGoogleChannelObjectsQuery } from '../';
import { QueryGoogleChannelObjectsDocument } from '../';
import { QueryGoogleChannelObjectsQueryVariables } from '../';
import { SubscribeToGoogleChannelByIdSubscription } from '../';
import { SubscribeToGoogleChannelByIdSubscriptionVariables } from '../';
import { SubscribeToGoogleChannelByIdDocument } from '../';
import { SubscribeToGoogleChannelObjectsSubscription } from '../';
import { SubscribeToGoogleChannelObjectsDocument } from '../';
import { SubscribeToGoogleChannelObjectsSubscriptionVariables } from '../';
import { GoogleChannel_Insert_Input } from '../';
import { GoogleChannel_On_Conflict } from '../';
import { InsertGoogleChannelMutation } from '../';
import { InsertGoogleChannelWithOnConflictMutation } from '../';
import { InsertGoogleChannelMutationVariables } from '../';
import { InsertGoogleChannelWithOnConflictMutationVariables } from '../';
import { InsertGoogleChannelDocument } from '../';
import { InsertGoogleChannelWithOnConflictDocument } from '../';
import { GoogleChannel_Set_Input } from '../';
import { UpdateGoogleChannelByIdMutation } from '../';
import { UpdateGoogleChannelByIdMutationVariables } from '../';
import { UpdateGoogleChannelByIdDocument } from '../';
import { UpdateGoogleChannelMutation } from '../';
import { UpdateGoogleChannelMutationVariables } from '../';
import { UpdateGoogleChannelDocument } from '../';
import { RemoveGoogleChannelModelMutation } from '../';
import { RemoveGoogleChannelModelMutationVariables } from '../';
import { RemoveGoogleChannelModelDocument } from '../';
import { RemoveGoogleChannelModelByIdMutation } from '../';
import { RemoveGoogleChannelModelByIdMutationVariables } from '../';
import { RemoveGoogleChannelModelByIdDocument } from '../';
import { ZoomEmailHistoryFragment } from '../';
import { QueryZoomEmailHistoryByIdQuery } from '../';
import { QueryZoomEmailHistoryByIdQueryVariables } from '../';
import { QueryZoomEmailHistoryByIdDocument } from '../';
import { QueryZoomEmailHistoryObjectsQuery } from '../';
import { QueryZoomEmailHistoryObjectsDocument } from '../';
import { QueryZoomEmailHistoryObjectsQueryVariables } from '../';
import { SubscribeToZoomEmailHistoryByIdSubscription } from '../';
import { SubscribeToZoomEmailHistoryByIdSubscriptionVariables } from '../';
import { SubscribeToZoomEmailHistoryByIdDocument } from '../';
import { SubscribeToZoomEmailHistoryObjectsSubscription } from '../';
import { SubscribeToZoomEmailHistoryObjectsDocument } from '../';
import { SubscribeToZoomEmailHistoryObjectsSubscriptionVariables } from '../';
import { ZoomEmailHistory_Insert_Input } from '../';
import { ZoomEmailHistory_On_Conflict } from '../';
import { InsertZoomEmailHistoryMutation } from '../';
import { InsertZoomEmailHistoryWithOnConflictMutation } from '../';
import { InsertZoomEmailHistoryMutationVariables } from '../';
import { InsertZoomEmailHistoryWithOnConflictMutationVariables } from '../';
import { InsertZoomEmailHistoryDocument } from '../';
import { InsertZoomEmailHistoryWithOnConflictDocument } from '../';
import { ZoomEmailHistory_Set_Input } from '../';
import { UpdateZoomEmailHistoryByIdMutation } from '../';
import { UpdateZoomEmailHistoryByIdMutationVariables } from '../';
import { UpdateZoomEmailHistoryByIdDocument } from '../';
import { UpdateZoomEmailHistoryMutation } from '../';
import { UpdateZoomEmailHistoryMutationVariables } from '../';
import { UpdateZoomEmailHistoryDocument } from '../';
import { RemoveZoomEmailHistoryModelMutation } from '../';
import { RemoveZoomEmailHistoryModelMutationVariables } from '../';
import { RemoveZoomEmailHistoryModelDocument } from '../';
import { RemoveZoomEmailHistoryModelByIdMutation } from '../';
import { RemoveZoomEmailHistoryModelByIdMutationVariables } from '../';
import { RemoveZoomEmailHistoryModelByIdDocument } from '../';
import { ZoomCustomBackgroundFragment } from '../';
import { QueryZoomCustomBackgroundByIdQuery } from '../';
import { QueryZoomCustomBackgroundByIdQueryVariables } from '../';
import { QueryZoomCustomBackgroundByIdDocument } from '../';
import { QueryZoomCustomBackgroundObjectsQuery } from '../';
import { QueryZoomCustomBackgroundObjectsDocument } from '../';
import { QueryZoomCustomBackgroundObjectsQueryVariables } from '../';
import { SubscribeToZoomCustomBackgroundByIdSubscription } from '../';
import { SubscribeToZoomCustomBackgroundByIdSubscriptionVariables } from '../';
import { SubscribeToZoomCustomBackgroundByIdDocument } from '../';
import { SubscribeToZoomCustomBackgroundObjectsSubscription } from '../';
import { SubscribeToZoomCustomBackgroundObjectsDocument } from '../';
import { SubscribeToZoomCustomBackgroundObjectsSubscriptionVariables } from '../';
import { ZoomCustomBackground_Insert_Input } from '../';
import { ZoomCustomBackground_On_Conflict } from '../';
import { InsertZoomCustomBackgroundMutation } from '../';
import { InsertZoomCustomBackgroundWithOnConflictMutation } from '../';
import { InsertZoomCustomBackgroundMutationVariables } from '../';
import { InsertZoomCustomBackgroundWithOnConflictMutationVariables } from '../';
import { InsertZoomCustomBackgroundDocument } from '../';
import { InsertZoomCustomBackgroundWithOnConflictDocument } from '../';
import { ZoomCustomBackground_Set_Input } from '../';
import { UpdateZoomCustomBackgroundByIdMutation } from '../';
import { UpdateZoomCustomBackgroundByIdMutationVariables } from '../';
import { UpdateZoomCustomBackgroundByIdDocument } from '../';
import { UpdateZoomCustomBackgroundMutation } from '../';
import { UpdateZoomCustomBackgroundMutationVariables } from '../';
import { UpdateZoomCustomBackgroundDocument } from '../';
import { RemoveZoomCustomBackgroundModelMutation } from '../';
import { RemoveZoomCustomBackgroundModelMutationVariables } from '../';
import { RemoveZoomCustomBackgroundModelDocument } from '../';
import { RemoveZoomCustomBackgroundModelByIdMutation } from '../';
import { RemoveZoomCustomBackgroundModelByIdMutationVariables } from '../';
import { RemoveZoomCustomBackgroundModelByIdDocument } from '../';
import { ZoomGlobalCustomBackgroundFragment } from '../';
import { QueryZoomGlobalCustomBackgroundByIdQuery } from '../';
import { QueryZoomGlobalCustomBackgroundByIdQueryVariables } from '../';
import { QueryZoomGlobalCustomBackgroundByIdDocument } from '../';
import { QueryZoomGlobalCustomBackgroundObjectsQuery } from '../';
import { QueryZoomGlobalCustomBackgroundObjectsDocument } from '../';
import { QueryZoomGlobalCustomBackgroundObjectsQueryVariables } from '../';
import { SubscribeToZoomGlobalCustomBackgroundByIdSubscription } from '../';
import { SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionVariables } from '../';
import { SubscribeToZoomGlobalCustomBackgroundByIdDocument } from '../';
import { SubscribeToZoomGlobalCustomBackgroundObjectsSubscription } from '../';
import { SubscribeToZoomGlobalCustomBackgroundObjectsDocument } from '../';
import { SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionVariables } from '../';
import { InsertZoomGlobalCustomBackgroundMutation } from '../';
import { InsertZoomGlobalCustomBackgroundWithOnConflictMutation } from '../';
import { InsertZoomGlobalCustomBackgroundMutationVariables } from '../';
import { InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables } from '../';
import { InsertZoomGlobalCustomBackgroundDocument } from '../';
import { InsertZoomGlobalCustomBackgroundWithOnConflictDocument } from '../';
import { UpdateZoomGlobalCustomBackgroundByIdMutation } from '../';
import { UpdateZoomGlobalCustomBackgroundByIdMutationVariables } from '../';
import { UpdateZoomGlobalCustomBackgroundByIdDocument } from '../';
import { UpdateZoomGlobalCustomBackgroundMutation } from '../';
import { UpdateZoomGlobalCustomBackgroundMutationVariables } from '../';
import { UpdateZoomGlobalCustomBackgroundDocument } from '../';
import { ZoomConnectionRequestFragment } from '../';
import { QueryZoomConnectionRequestByIdQuery } from '../';
import { QueryZoomConnectionRequestByIdQueryVariables } from '../';
import { QueryZoomConnectionRequestByIdDocument } from '../';
import { QueryZoomConnectionRequestObjectsQuery } from '../';
import { QueryZoomConnectionRequestObjectsDocument } from '../';
import { QueryZoomConnectionRequestObjectsQueryVariables } from '../';
import { SubscribeToZoomConnectionRequestByIdSubscription } from '../';
import { SubscribeToZoomConnectionRequestByIdSubscriptionVariables } from '../';
import { SubscribeToZoomConnectionRequestByIdDocument } from '../';
import { SubscribeToZoomConnectionRequestObjectsSubscription } from '../';
import { SubscribeToZoomConnectionRequestObjectsDocument } from '../';
import { SubscribeToZoomConnectionRequestObjectsSubscriptionVariables } from '../';
import { ZoomConnectionRequest_Insert_Input } from '../';
import { ZoomConnectionRequest_On_Conflict } from '../';
import { InsertZoomConnectionRequestMutation } from '../';
import { InsertZoomConnectionRequestWithOnConflictMutation } from '../';
import { InsertZoomConnectionRequestMutationVariables } from '../';
import { InsertZoomConnectionRequestWithOnConflictMutationVariables } from '../';
import { InsertZoomConnectionRequestDocument } from '../';
import { InsertZoomConnectionRequestWithOnConflictDocument } from '../';
import { ZoomConnectionRequest_Set_Input } from '../';
import { UpdateZoomConnectionRequestByIdMutation } from '../';
import { UpdateZoomConnectionRequestByIdMutationVariables } from '../';
import { UpdateZoomConnectionRequestByIdDocument } from '../';
import { UpdateZoomConnectionRequestMutation } from '../';
import { UpdateZoomConnectionRequestMutationVariables } from '../';
import { UpdateZoomConnectionRequestDocument } from '../';
import { RemoveZoomConnectionRequestModelMutation } from '../';
import { RemoveZoomConnectionRequestModelMutationVariables } from '../';
import { RemoveZoomConnectionRequestModelDocument } from '../';
import { RemoveZoomConnectionRequestModelByIdMutation } from '../';
import { RemoveZoomConnectionRequestModelByIdMutationVariables } from '../';
import { RemoveZoomConnectionRequestModelByIdDocument } from '../';
import { ZoomConnectionRequestReminderFragment } from '../';
import { QueryZoomConnectionRequestReminderByIdQuery } from '../';
import { QueryZoomConnectionRequestReminderByIdQueryVariables } from '../';
import { QueryZoomConnectionRequestReminderByIdDocument } from '../';
import { QueryZoomConnectionRequestReminderObjectsQuery } from '../';
import { QueryZoomConnectionRequestReminderObjectsDocument } from '../';
import { QueryZoomConnectionRequestReminderObjectsQueryVariables } from '../';
import { SubscribeToZoomConnectionRequestReminderByIdSubscription } from '../';
import { SubscribeToZoomConnectionRequestReminderByIdSubscriptionVariables } from '../';
import { SubscribeToZoomConnectionRequestReminderByIdDocument } from '../';
import { SubscribeToZoomConnectionRequestReminderObjectsSubscription } from '../';
import { SubscribeToZoomConnectionRequestReminderObjectsDocument } from '../';
import { SubscribeToZoomConnectionRequestReminderObjectsSubscriptionVariables } from '../';
import { ZoomConnectionRequestReminder_Insert_Input } from '../';
import { ZoomConnectionRequestReminder_On_Conflict } from '../';
import { InsertZoomConnectionRequestReminderMutation } from '../';
import { InsertZoomConnectionRequestReminderWithOnConflictMutation } from '../';
import { InsertZoomConnectionRequestReminderMutationVariables } from '../';
import { InsertZoomConnectionRequestReminderWithOnConflictMutationVariables } from '../';
import { InsertZoomConnectionRequestReminderDocument } from '../';
import { InsertZoomConnectionRequestReminderWithOnConflictDocument } from '../';
import { ZoomConnectionRequestReminder_Set_Input } from '../';
import { UpdateZoomConnectionRequestReminderByIdMutation } from '../';
import { UpdateZoomConnectionRequestReminderByIdMutationVariables } from '../';
import { UpdateZoomConnectionRequestReminderByIdDocument } from '../';
import { UpdateZoomConnectionRequestReminderMutation } from '../';
import { UpdateZoomConnectionRequestReminderMutationVariables } from '../';
import { UpdateZoomConnectionRequestReminderDocument } from '../';
import { RemoveZoomConnectionRequestReminderModelMutation } from '../';
import { RemoveZoomConnectionRequestReminderModelMutationVariables } from '../';
import { RemoveZoomConnectionRequestReminderModelDocument } from '../';
import { RemoveZoomConnectionRequestReminderModelByIdMutation } from '../';
import { RemoveZoomConnectionRequestReminderModelByIdMutationVariables } from '../';
import { RemoveZoomConnectionRequestReminderModelByIdDocument } from '../';
import { ZoomApprovedDomainFragment } from '../';
import { QueryZoomApprovedDomainByIdQuery } from '../';
import { QueryZoomApprovedDomainByIdQueryVariables } from '../';
import { QueryZoomApprovedDomainByIdDocument } from '../';
import { QueryZoomApprovedDomainObjectsQuery } from '../';
import { QueryZoomApprovedDomainObjectsDocument } from '../';
import { QueryZoomApprovedDomainObjectsQueryVariables } from '../';
import { SubscribeToZoomApprovedDomainByIdSubscription } from '../';
import { SubscribeToZoomApprovedDomainByIdSubscriptionVariables } from '../';
import { SubscribeToZoomApprovedDomainByIdDocument } from '../';
import { SubscribeToZoomApprovedDomainObjectsSubscription } from '../';
import { SubscribeToZoomApprovedDomainObjectsDocument } from '../';
import { SubscribeToZoomApprovedDomainObjectsSubscriptionVariables } from '../';
import { ZoomApprovedDomain_Insert_Input } from '../';
import { ZoomApprovedDomain_On_Conflict } from '../';
import { InsertZoomApprovedDomainMutation } from '../';
import { InsertZoomApprovedDomainWithOnConflictMutation } from '../';
import { InsertZoomApprovedDomainMutationVariables } from '../';
import { InsertZoomApprovedDomainWithOnConflictMutationVariables } from '../';
import { InsertZoomApprovedDomainDocument } from '../';
import { InsertZoomApprovedDomainWithOnConflictDocument } from '../';
import { ZoomApprovedDomain_Set_Input } from '../';
import { UpdateZoomApprovedDomainByIdMutation } from '../';
import { UpdateZoomApprovedDomainByIdMutationVariables } from '../';
import { UpdateZoomApprovedDomainByIdDocument } from '../';
import { UpdateZoomApprovedDomainMutation } from '../';
import { UpdateZoomApprovedDomainMutationVariables } from '../';
import { UpdateZoomApprovedDomainDocument } from '../';
import { RemoveZoomApprovedDomainModelMutation } from '../';
import { RemoveZoomApprovedDomainModelMutationVariables } from '../';
import { RemoveZoomApprovedDomainModelDocument } from '../';
import { RemoveZoomApprovedDomainModelByIdMutation } from '../';
import { RemoveZoomApprovedDomainModelByIdMutationVariables } from '../';
import { RemoveZoomApprovedDomainModelByIdDocument } from '../';
import { ZoomConversationStarterAnswerFragment } from '../';
import { QueryZoomConversationStarterAnswerByIdQuery } from '../';
import { QueryZoomConversationStarterAnswerByIdQueryVariables } from '../';
import { QueryZoomConversationStarterAnswerByIdDocument } from '../';
import { QueryZoomConversationStarterAnswerObjectsQuery } from '../';
import { QueryZoomConversationStarterAnswerObjectsDocument } from '../';
import { QueryZoomConversationStarterAnswerObjectsQueryVariables } from '../';
import { SubscribeToZoomConversationStarterAnswerByIdSubscription } from '../';
import { SubscribeToZoomConversationStarterAnswerByIdSubscriptionVariables } from '../';
import { SubscribeToZoomConversationStarterAnswerByIdDocument } from '../';
import { SubscribeToZoomConversationStarterAnswerObjectsSubscription } from '../';
import { SubscribeToZoomConversationStarterAnswerObjectsDocument } from '../';
import { SubscribeToZoomConversationStarterAnswerObjectsSubscriptionVariables } from '../';
import { ZoomConversationStarterAnswer_Insert_Input } from '../';
import { ZoomConversationStarterAnswer_On_Conflict } from '../';
import { InsertZoomConversationStarterAnswerMutation } from '../';
import { InsertZoomConversationStarterAnswerWithOnConflictMutation } from '../';
import { InsertZoomConversationStarterAnswerMutationVariables } from '../';
import { InsertZoomConversationStarterAnswerWithOnConflictMutationVariables } from '../';
import { InsertZoomConversationStarterAnswerDocument } from '../';
import { InsertZoomConversationStarterAnswerWithOnConflictDocument } from '../';
import { ZoomConversationStarterAnswer_Set_Input } from '../';
import { UpdateZoomConversationStarterAnswerByIdMutation } from '../';
import { UpdateZoomConversationStarterAnswerByIdMutationVariables } from '../';
import { UpdateZoomConversationStarterAnswerByIdDocument } from '../';
import { UpdateZoomConversationStarterAnswerMutation } from '../';
import { UpdateZoomConversationStarterAnswerMutationVariables } from '../';
import { UpdateZoomConversationStarterAnswerDocument } from '../';
import { RemoveZoomConversationStarterAnswerModelMutation } from '../';
import { RemoveZoomConversationStarterAnswerModelMutationVariables } from '../';
import { RemoveZoomConversationStarterAnswerModelDocument } from '../';
import { RemoveZoomConversationStarterAnswerModelByIdMutation } from '../';
import { RemoveZoomConversationStarterAnswerModelByIdMutationVariables } from '../';
import { RemoveZoomConversationStarterAnswerModelByIdDocument } from '../';
import { ZoomConversationStarterPromptFragment } from '../';
import { QueryZoomConversationStarterPromptByIdQuery } from '../';
import { QueryZoomConversationStarterPromptByIdQueryVariables } from '../';
import { QueryZoomConversationStarterPromptByIdDocument } from '../';
import { QueryZoomConversationStarterPromptObjectsQuery } from '../';
import { QueryZoomConversationStarterPromptObjectsDocument } from '../';
import { QueryZoomConversationStarterPromptObjectsQueryVariables } from '../';
import { SubscribeToZoomConversationStarterPromptByIdSubscription } from '../';
import { SubscribeToZoomConversationStarterPromptByIdSubscriptionVariables } from '../';
import { SubscribeToZoomConversationStarterPromptByIdDocument } from '../';
import { SubscribeToZoomConversationStarterPromptObjectsSubscription } from '../';
import { SubscribeToZoomConversationStarterPromptObjectsDocument } from '../';
import { SubscribeToZoomConversationStarterPromptObjectsSubscriptionVariables } from '../';
import { ZoomConversationStarterPrompt_Insert_Input } from '../';
import { ZoomConversationStarterPrompt_On_Conflict } from '../';
import { InsertZoomConversationStarterPromptMutation } from '../';
import { InsertZoomConversationStarterPromptWithOnConflictMutation } from '../';
import { InsertZoomConversationStarterPromptMutationVariables } from '../';
import { InsertZoomConversationStarterPromptWithOnConflictMutationVariables } from '../';
import { InsertZoomConversationStarterPromptDocument } from '../';
import { InsertZoomConversationStarterPromptWithOnConflictDocument } from '../';
import { ZoomConversationStarterPrompt_Set_Input } from '../';
import { UpdateZoomConversationStarterPromptByIdMutation } from '../';
import { UpdateZoomConversationStarterPromptByIdMutationVariables } from '../';
import { UpdateZoomConversationStarterPromptByIdDocument } from '../';
import { UpdateZoomConversationStarterPromptMutation } from '../';
import { UpdateZoomConversationStarterPromptMutationVariables } from '../';
import { UpdateZoomConversationStarterPromptDocument } from '../';
import { RemoveZoomConversationStarterPromptModelMutation } from '../';
import { RemoveZoomConversationStarterPromptModelMutationVariables } from '../';
import { RemoveZoomConversationStarterPromptModelDocument } from '../';
import { RemoveZoomConversationStarterPromptModelByIdMutation } from '../';
import { RemoveZoomConversationStarterPromptModelByIdMutationVariables } from '../';
import { RemoveZoomConversationStarterPromptModelByIdDocument } from '../';
import { ContentFragment } from '../';
import { QueryContentByIdQuery } from '../';
import { QueryContentByIdQueryVariables } from '../';
import { QueryContentByIdDocument } from '../';
import { QueryContentObjectsQuery } from '../';
import { QueryContentObjectsDocument } from '../';
import { QueryContentObjectsQueryVariables } from '../';
import { SubscribeToContentByIdSubscription } from '../';
import { SubscribeToContentByIdSubscriptionVariables } from '../';
import { SubscribeToContentByIdDocument } from '../';
import { SubscribeToContentObjectsSubscription } from '../';
import { SubscribeToContentObjectsDocument } from '../';
import { SubscribeToContentObjectsSubscriptionVariables } from '../';
import { Content_Insert_Input } from '../';
import { Content_On_Conflict } from '../';
import { InsertContentMutation } from '../';
import { InsertContentWithOnConflictMutation } from '../';
import { InsertContentMutationVariables } from '../';
import { InsertContentWithOnConflictMutationVariables } from '../';
import { InsertContentDocument } from '../';
import { InsertContentWithOnConflictDocument } from '../';
import { Content_Set_Input } from '../';
import { UpdateContentByIdMutation } from '../';
import { UpdateContentByIdMutationVariables } from '../';
import { UpdateContentByIdDocument } from '../';
import { UpdateContentMutation } from '../';
import { UpdateContentMutationVariables } from '../';
import { UpdateContentDocument } from '../';
import { RemoveContentModelMutation } from '../';
import { RemoveContentModelMutationVariables } from '../';
import { RemoveContentModelDocument } from '../';
import { RemoveContentModelByIdMutation } from '../';
import { RemoveContentModelByIdMutationVariables } from '../';
import { RemoveContentModelByIdDocument } from '../';
import { ZoomSettingsFragment } from '../';
import { QueryZoomSettingsByIdQuery } from '../';
import { QueryZoomSettingsByIdQueryVariables } from '../';
import { QueryZoomSettingsByIdDocument } from '../';
import { QueryZoomSettingsObjectsQuery } from '../';
import { QueryZoomSettingsObjectsDocument } from '../';
import { QueryZoomSettingsObjectsQueryVariables } from '../';
import { SubscribeToZoomSettingsByIdSubscription } from '../';
import { SubscribeToZoomSettingsByIdSubscriptionVariables } from '../';
import { SubscribeToZoomSettingsByIdDocument } from '../';
import { SubscribeToZoomSettingsObjectsSubscription } from '../';
import { SubscribeToZoomSettingsObjectsDocument } from '../';
import { SubscribeToZoomSettingsObjectsSubscriptionVariables } from '../';
import { ZoomSettings_Insert_Input } from '../';
import { ZoomSettings_On_Conflict } from '../';
import { InsertZoomSettingsMutation } from '../';
import { InsertZoomSettingsWithOnConflictMutation } from '../';
import { InsertZoomSettingsMutationVariables } from '../';
import { InsertZoomSettingsWithOnConflictMutationVariables } from '../';
import { InsertZoomSettingsDocument } from '../';
import { InsertZoomSettingsWithOnConflictDocument } from '../';
import { ZoomSettings_Set_Input } from '../';
import { UpdateZoomSettingsByIdMutation } from '../';
import { UpdateZoomSettingsByIdMutationVariables } from '../';
import { UpdateZoomSettingsByIdDocument } from '../';
import { UpdateZoomSettingsMutation } from '../';
import { UpdateZoomSettingsMutationVariables } from '../';
import { UpdateZoomSettingsDocument } from '../';
import { RemoveZoomSettingsModelMutation } from '../';
import { RemoveZoomSettingsModelMutationVariables } from '../';
import { RemoveZoomSettingsModelDocument } from '../';
import { RemoveZoomSettingsModelByIdMutation } from '../';
import { RemoveZoomSettingsModelByIdMutationVariables } from '../';
import { RemoveZoomSettingsModelByIdDocument } from '../';
import { ZoomPresentationFragment } from '../';
import { QueryZoomPresentationByIdQuery } from '../';
import { QueryZoomPresentationByIdQueryVariables } from '../';
import { QueryZoomPresentationByIdDocument } from '../';
import { QueryZoomPresentationObjectsQuery } from '../';
import { QueryZoomPresentationObjectsDocument } from '../';
import { QueryZoomPresentationObjectsQueryVariables } from '../';
import { SubscribeToZoomPresentationByIdSubscription } from '../';
import { SubscribeToZoomPresentationByIdSubscriptionVariables } from '../';
import { SubscribeToZoomPresentationByIdDocument } from '../';
import { SubscribeToZoomPresentationObjectsSubscription } from '../';
import { SubscribeToZoomPresentationObjectsDocument } from '../';
import { SubscribeToZoomPresentationObjectsSubscriptionVariables } from '../';
import { ZoomPresentation_Insert_Input } from '../';
import { ZoomPresentation_On_Conflict } from '../';
import { InsertZoomPresentationMutation } from '../';
import { InsertZoomPresentationWithOnConflictMutation } from '../';
import { InsertZoomPresentationMutationVariables } from '../';
import { InsertZoomPresentationWithOnConflictMutationVariables } from '../';
import { InsertZoomPresentationDocument } from '../';
import { InsertZoomPresentationWithOnConflictDocument } from '../';
import { ZoomPresentation_Set_Input } from '../';
import { UpdateZoomPresentationByIdMutation } from '../';
import { UpdateZoomPresentationByIdMutationVariables } from '../';
import { UpdateZoomPresentationByIdDocument } from '../';
import { UpdateZoomPresentationMutation } from '../';
import { UpdateZoomPresentationMutationVariables } from '../';
import { UpdateZoomPresentationDocument } from '../';
import { RemoveZoomPresentationModelMutation } from '../';
import { RemoveZoomPresentationModelMutationVariables } from '../';
import { RemoveZoomPresentationModelDocument } from '../';
import { RemoveZoomPresentationModelByIdMutation } from '../';
import { RemoveZoomPresentationModelByIdMutationVariables } from '../';
import { RemoveZoomPresentationModelByIdDocument } from '../';
import { GrowthUserReferralContactFragment } from '../';
import { QueryGrowthUserReferralContactByIdQuery } from '../';
import { QueryGrowthUserReferralContactByIdQueryVariables } from '../';
import { QueryGrowthUserReferralContactByIdDocument } from '../';
import { QueryGrowthUserReferralContactObjectsQuery } from '../';
import { QueryGrowthUserReferralContactObjectsDocument } from '../';
import { QueryGrowthUserReferralContactObjectsQueryVariables } from '../';
import { SubscribeToGrowthUserReferralContactByIdSubscription } from '../';
import { SubscribeToGrowthUserReferralContactByIdSubscriptionVariables } from '../';
import { SubscribeToGrowthUserReferralContactByIdDocument } from '../';
import { SubscribeToGrowthUserReferralContactObjectsSubscription } from '../';
import { SubscribeToGrowthUserReferralContactObjectsDocument } from '../';
import { SubscribeToGrowthUserReferralContactObjectsSubscriptionVariables } from '../';
import { GrowthUserReferralContact_Insert_Input } from '../';
import { GrowthUserReferralContact_On_Conflict } from '../';
import { InsertGrowthUserReferralContactMutation } from '../';
import { InsertGrowthUserReferralContactWithOnConflictMutation } from '../';
import { InsertGrowthUserReferralContactMutationVariables } from '../';
import { InsertGrowthUserReferralContactWithOnConflictMutationVariables } from '../';
import { InsertGrowthUserReferralContactDocument } from '../';
import { InsertGrowthUserReferralContactWithOnConflictDocument } from '../';
import { GrowthUserReferralContact_Set_Input } from '../';
import { UpdateGrowthUserReferralContactByIdMutation } from '../';
import { UpdateGrowthUserReferralContactByIdMutationVariables } from '../';
import { UpdateGrowthUserReferralContactByIdDocument } from '../';
import { UpdateGrowthUserReferralContactMutation } from '../';
import { UpdateGrowthUserReferralContactMutationVariables } from '../';
import { UpdateGrowthUserReferralContactDocument } from '../';
import { RemoveGrowthUserReferralContactModelMutation } from '../';
import { RemoveGrowthUserReferralContactModelMutationVariables } from '../';
import { RemoveGrowthUserReferralContactModelDocument } from '../';
import { RemoveGrowthUserReferralContactModelByIdMutation } from '../';
import { RemoveGrowthUserReferralContactModelByIdMutationVariables } from '../';
import { RemoveGrowthUserReferralContactModelByIdDocument } from '../';
import { LayersEventsFragment } from '../';
import { QueryLayersEventsByIdQuery } from '../';
import { QueryLayersEventsByIdQueryVariables } from '../';
import { QueryLayersEventsByIdDocument } from '../';
import { QueryLayersEventsObjectsQuery } from '../';
import { QueryLayersEventsObjectsDocument } from '../';
import { QueryLayersEventsObjectsQueryVariables } from '../';
import { SubscribeToLayersEventsByIdSubscription } from '../';
import { SubscribeToLayersEventsByIdSubscriptionVariables } from '../';
import { SubscribeToLayersEventsByIdDocument } from '../';
import { SubscribeToLayersEventsObjectsSubscription } from '../';
import { SubscribeToLayersEventsObjectsDocument } from '../';
import { SubscribeToLayersEventsObjectsSubscriptionVariables } from '../';
import { ZoomLayersEvents_Insert_Input } from '../';
import { ZoomLayersEvents_On_Conflict } from '../';
import { InsertLayersEventsMutation } from '../';
import { InsertLayersEventsWithOnConflictMutation } from '../';
import { InsertLayersEventsMutationVariables } from '../';
import { InsertLayersEventsWithOnConflictMutationVariables } from '../';
import { InsertLayersEventsDocument } from '../';
import { InsertLayersEventsWithOnConflictDocument } from '../';
import { ZoomLayersEvents_Set_Input } from '../';
import { UpdateLayersEventsByIdMutation } from '../';
import { UpdateLayersEventsByIdMutationVariables } from '../';
import { UpdateLayersEventsByIdDocument } from '../';
import { UpdateLayersEventsMutation } from '../';
import { UpdateLayersEventsMutationVariables } from '../';
import { UpdateLayersEventsDocument } from '../';
import { RemoveZoomLayersEventsModelMutation } from '../';
import { RemoveZoomLayersEventsModelMutationVariables } from '../';
import { RemoveZoomLayersEventsModelDocument } from '../';
import { RemoveZoomLayersEventsModelByIdMutation } from '../';
import { RemoveZoomLayersEventsModelByIdMutationVariables } from '../';
import { RemoveZoomLayersEventsModelByIdDocument } from '../';

// GLOBAL TYPES
//------------------------------------------------
export type RemoveEntitiesQueryHookResultEx = { affected_rows: number };

// zoomContact REACT
//------------------------------------------------

export type ZoomContactByIdHookResultEx = { zoomContact: ZoomContactFragment | null | undefined };
export type ZoomContactObjectsHookResultEx = { objects: ZoomContactFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomContactByIdResult = LazyQueryResult<QueryZoomContactByIdQuery, QueryZoomContactByIdQueryVariables>;
type QueryZoomContactByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomContactByIdQuery,
          QueryZoomContactByIdQueryVariables,
          QueryZoomContactByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomContactByIdResultEx = Omit<QueryZoomContactByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomContactByIdSubScribeToMore;
} & ZoomContactByIdHookResultEx;

// Function
function useQueryZoomContactById({
  zoomContactId,
  options,
}: {
  zoomContactId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomContactByIdQuery, QueryZoomContactByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomContactByIdResultEx {
  const _query: QueryZoomContactByIdResult = useQuery<QueryZoomContactByIdQuery, QueryZoomContactByIdQueryVariables>(
    QueryZoomContactByIdDocument,
    { variables: { zoomContactId }, ...options }
  );

  const typedSubscribeToMore: QueryZoomContactByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomContactByIdDocument,
      variables: { zoomContactId } as QueryZoomContactByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomContact: query?.data?.zoomContact_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomContactByIdFn = (
  query: QueryZoomContactByIdQuery | null | undefined
) => ZoomContactFragment | null | undefined;
type QueryZoomContactByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomContactByIdQueryVariables> | undefined) => void,
  QueryZoomContactByIdResult
];
type QueryZoomContactByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomContactByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomContactByIdLazyReturn = [QueryZoomContactByIdWrappedLazyFn, QueryZoomContactByIdResultEx];

// Function
function useQueryZoomContactByIdLazy({
  zoomContactId,
  options,
}: {
  zoomContactId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomContactByIdQuery, QueryZoomContactByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomContactByIdLazyReturn {
  const lazyQuery: QueryZoomContactByIdLazyFn = useLazyQuery<
    QueryZoomContactByIdQuery,
    QueryZoomContactByIdQueryVariables
  >(QueryZoomContactByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomContactById: PickQueryZoomContactByIdFn = (query) => {
    return query && query.zoomContact_by_pk;
  };
  const wrappedLazyQuery: QueryZoomContactByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomContactByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomContactByIdDocument,
        variables: { zoomContactId } as QueryZoomContactByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomContact: pickQueryZoomContactById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomContactObjectsResult = LazyQueryResult<
  QueryZoomContactObjectsQuery,
  QueryZoomContactObjectsQueryVariables
>;
type QueryZoomContactObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomContactObjectsQuery,
          QueryZoomContactObjectsQueryVariables,
          QueryZoomContactObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomContactObjectsResultEx = Omit<QueryZoomContactObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomContactObjectsSubScribeToMore;
} & ZoomContactObjectsHookResultEx;

// Function
function useQueryZoomContactObjects(
  options: Omit<QueryHookOptions<QueryZoomContactObjectsQuery, QueryZoomContactObjectsQueryVariables>, 'query'>
): QueryZoomContactObjectsResultEx {
  const _query: QueryZoomContactObjectsResult = useQuery<
    QueryZoomContactObjectsQuery,
    QueryZoomContactObjectsQueryVariables
  >(QueryZoomContactObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomContactObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomContactObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomContact || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomContactObjectsFn = (query: QueryZoomContactObjectsQuery | null | undefined) => ZoomContactFragment[];
type QueryZoomContactObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomContactObjectsQueryVariables> | undefined) => void,
  QueryZoomContactObjectsResult
];
type QueryZoomContactObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryZoomContactObjectsQueryVariables>) => void;
export type QueryZoomContactObjectsLazyReturn = [QueryZoomContactObjectsWrappedLazyFn, QueryZoomContactObjectsResultEx];

// Function
function useQueryZoomContactObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryZoomContactObjectsQuery, QueryZoomContactObjectsQueryVariables>, 'query'>
): QueryZoomContactObjectsLazyReturn {
  const lazyQuery: QueryZoomContactObjectsLazyFn = useLazyQuery<
    QueryZoomContactObjectsQuery,
    QueryZoomContactObjectsQueryVariables
  >(QueryZoomContactObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomContactObjectsFn = (query: QueryZoomContactObjectsQuery | null | undefined) => {
    return query?.zoomContact || [];
  };
  const wrappedLazyQuery: QueryZoomContactObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomContactObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomContactObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomContactByIdResult = {
  variables?: SubscribeToZoomContactByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomContactByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomContactByIdResultEx = SubscribeToZoomContactByIdResult & ZoomContactByIdHookResultEx;

// Function
function useSubscribeToZoomContactById({
  zoomContactId,
  options,
}: {
  zoomContactId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToZoomContactByIdSubscription, SubscribeToZoomContactByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToZoomContactByIdResultEx {
  const subscription: SubscribeToZoomContactByIdResult = useSubscription<
    SubscribeToZoomContactByIdSubscription,
    SubscribeToZoomContactByIdSubscriptionVariables
  >(SubscribeToZoomContactByIdDocument, { variables: { zoomContactId }, ...options });
  return { ...subscription, zoomContact: subscription?.data?.zoomContact_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomContactObjectsResult = {
  variables?: SubscribeToZoomContactObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomContactObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomContactObjectsResultEx = SubscribeToZoomContactObjectsResult &
  ZoomContactObjectsHookResultEx;

// Function
function useSubscribeToZoomContactObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomContactObjectsSubscription,
      SubscribeToZoomContactObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomContactObjectsResultEx {
  const subscription: SubscribeToZoomContactObjectsResult = useSubscription<
    SubscribeToZoomContactObjectsSubscription,
    SubscribeToZoomContactObjectsSubscriptionVariables
  >(SubscribeToZoomContactObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomContact || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomContactMutationResult = FetchResult<InsertZoomContactMutation, Record<string, any>, Record<string, any>>;
export type InsertZoomContactMutationResultEx = InsertZoomContactMutationResult & ZoomContactByIdHookResultEx;

type PickInsertZoomContactFn = (
  mutation: InsertZoomContactMutation | null | undefined
) => ZoomContactFragment | null | undefined;
type InsertZoomContactLazyMutationFn = MutationTuple<InsertZoomContactMutation, InsertZoomContactMutationVariables>;
type InsertZoomContactWrappedLazyMutationFn = ({
  zoomContact,
  autoOptimisticResponse,
  options,
}: {
  zoomContact: ZoomContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertZoomContactMutation, InsertZoomContactMutationVariables>, 'variables'>;
}) => Promise<InsertZoomContactMutationResultEx>;
export type InsertZoomContactLazyMutationReturn = [
  InsertZoomContactWrappedLazyMutationFn,
  InsertZoomContactMutationResultEx
];

// Function
function useInsertZoomContact(
  options?: Omit<
    MutationHookOptions<InsertZoomContactMutation, InsertZoomContactMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomContactLazyMutationReturn {
  const lazyMutation: InsertZoomContactLazyMutationFn = useMutation<
    InsertZoomContactMutation,
    InsertZoomContactMutationVariables
  >(InsertZoomContactDocument, options);
  const pickZoomContact: PickInsertZoomContactFn = (mutation) => {
    return mutation?.insert_zoomContact?.returning && mutation?.insert_zoomContact?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomContactWrappedLazyMutationFn = async ({
    zoomContact,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomContact });
    const mutationOptions: MutationFunctionOptions<InsertZoomContactMutation, InsertZoomContactMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomContact'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomContactMutation>({
        operationType: 'insert',
        entityName: 'zoomContact',
        objects: [objectForInsert as ZoomContact_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomContact: pickZoomContact(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomContact: pickZoomContact(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomContactWithOnConflictMutationResult = FetchResult<
  InsertZoomContactWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomContactWithOnConflictMutationResultEx = InsertZoomContactWithOnConflictMutationResult &
  ZoomContactByIdHookResultEx;

type InsertZoomContactWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomContactWithOnConflictMutation,
  InsertZoomContactWithOnConflictMutationVariables
>;
type InsertZoomContactWithOnConflictWrappedLazyMutationFn = ({
  zoomContact,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomContact: ZoomContact_Insert_Input;
  onConflict: ZoomContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomContactWithOnConflictMutation, InsertZoomContactWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomContactWithOnConflictMutationResultEx>;
export type InsertZoomContactWithOnConflictLazyMutationReturn = [
  InsertZoomContactWithOnConflictWrappedLazyMutationFn,
  InsertZoomContactWithOnConflictMutationResultEx
];

// Function
function useInsertZoomContactWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertZoomContactWithOnConflictMutation, InsertZoomContactWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomContactWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomContactWithOnConflictLazyMutationFn = useMutation<
    InsertZoomContactWithOnConflictMutation,
    InsertZoomContactWithOnConflictMutationVariables
  >(InsertZoomContactWithOnConflictDocument, options);
  const pickZoomContact: PickInsertZoomContactFn = (
    mutation: InsertZoomContactWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomContact?.returning && mutation.insert_zoomContact.returning[0];
  };

  const wrappedLazyMutation: InsertZoomContactWithOnConflictWrappedLazyMutationFn = async ({
    zoomContact,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomContact });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomContactWithOnConflictMutation,
      InsertZoomContactWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomContact'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomContactWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomContact',
          objects: [objectForInsert as ZoomContact_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomContact: pickZoomContact(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomContact: pickZoomContact(lazyMutation[1].data) }];
}

// Types
type InsertZoomContactObjectsMutationResult = FetchResult<
  InsertZoomContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomContactObjectsMutationResultEx = InsertZoomContactMutationResult & ZoomContactObjectsHookResultEx;

type PickInsertZoomContactObjectsFn = (mutation: InsertZoomContactMutation | null | undefined) => ZoomContactFragment[];
type InsertZoomContactObjectsLazyMutationFn = MutationTuple<
  InsertZoomContactMutation,
  InsertZoomContactMutationVariables
>;
type InsertZoomContactObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomContactMutation, InsertZoomContactMutationVariables>
) => Promise<InsertZoomContactObjectsMutationResultEx>;
export type InsertZoomContactObjectsLazyMutationReturn = [
  InsertZoomContactObjectsWrappedLazyMutationFn,
  InsertZoomContactObjectsMutationResultEx
];

// Function
function useInsertZoomContactObjects(
  options?: Omit<MutationHookOptions<InsertZoomContactMutation, InsertZoomContactMutationVariables>, 'mutation'>
): InsertZoomContactObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomContactObjectsLazyMutationFn = useMutation<
    InsertZoomContactMutation,
    InsertZoomContactMutationVariables
  >(InsertZoomContactDocument, options);
  const pickObjects: PickInsertZoomContactObjectsFn = (mutation: InsertZoomContactMutation | null | undefined) => {
    return mutation?.insert_zoomContact?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomContactObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomContactObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomContactByIdMutationResult = FetchResult<
  UpdateZoomContactByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomContactByIdMutationResultEx = UpdateZoomContactByIdMutationResult & ZoomContactByIdHookResultEx;

type PickUpdateZoomContactByIdFn = (
  mutation: UpdateZoomContactByIdMutation | null | undefined
) => ZoomContactFragment | null | undefined;
type UpdateZoomContactByIdLazyMutationFn = MutationTuple<
  UpdateZoomContactByIdMutation,
  UpdateZoomContactByIdMutationVariables
>;
type UpdateZoomContactByIdWrappedLazyMutationFn = ({
  zoomContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomContactId: string;
  set: ZoomContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomContactByIdMutation, UpdateZoomContactByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomContactByIdMutationResultEx>;
export type UpdateZoomContactByIdLazyMutationReturn = [
  UpdateZoomContactByIdWrappedLazyMutationFn,
  UpdateZoomContactByIdMutationResultEx
];

function useUpdateZoomContactById(
  options?: Omit<
    MutationHookOptions<UpdateZoomContactByIdMutation, UpdateZoomContactByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomContactByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomContactByIdLazyMutationFn = useMutation<
    UpdateZoomContactByIdMutation,
    UpdateZoomContactByIdMutationVariables
  >(UpdateZoomContactByIdDocument, options);

  const pickZoomContact: PickUpdateZoomContactByIdFn = (mutation) => {
    return mutation?.update_zoomContact?.returning && mutation.update_zoomContact!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomContactByIdWrappedLazyMutationFn = async ({
    zoomContactId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomContactByIdMutation,
      UpdateZoomContactByIdMutationVariables
    > = { variables: { id: zoomContactId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomContactByIdMutation>({
        operationType: 'update',
        entityName: 'zoomContact',
        objects: [{ id: zoomContactId, ...set }],
      });
    }

    const fetchResult: UpdateZoomContactByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomContactId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomContact: pickZoomContact(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomContact: pickZoomContact(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomContactObjectsMutationResult = FetchResult<
  UpdateZoomContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomContactObjectsMutationResultEx = UpdateZoomContactObjectsMutationResult &
  ZoomContactObjectsHookResultEx;

// Function
type PickUpdateZoomContactObjectsFn = (mutation: UpdateZoomContactMutation | null | undefined) => ZoomContactFragment[];
type UpdateZoomContactObjectsLazyMutationFn = MutationTuple<
  UpdateZoomContactMutation,
  UpdateZoomContactMutationVariables
>;
type UpdateZoomContactObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomContactMutation, UpdateZoomContactMutationVariables>
) => Promise<UpdateZoomContactObjectsMutationResultEx>;
export type UpdateZoomContactObjectsLazyMutationReturn = [
  UpdateZoomContactObjectsWrappedLazyMutationFn,
  UpdateZoomContactObjectsMutationResultEx
];

function useUpdateZoomContactObjects(
  options?: Omit<MutationHookOptions<UpdateZoomContactMutation, UpdateZoomContactMutationVariables>, 'mutation'>
): UpdateZoomContactObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomContactObjectsLazyMutationFn = useMutation<
    UpdateZoomContactMutation,
    UpdateZoomContactMutationVariables
  >(UpdateZoomContactDocument, options);

  const pickObjects: PickUpdateZoomContactObjectsFn = (mutation: UpdateZoomContactMutation | null | undefined) => {
    return mutation?.update_zoomContact?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomContactObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomContactObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomContactModelByIdFetchResult = FetchResult<
  RemoveZoomContactModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomContactModelByIdMutationResultEx = RemoveZoomContactModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomContactModelFn = (mutation: RemoveZoomContactModelByIdMutation | null | undefined) => number;
type RemoveZoomContactModelLazyMutationFn = MutationTuple<
  RemoveZoomContactModelByIdMutation,
  RemoveZoomContactModelByIdMutationVariables
>;
type RemoveZoomContactModelWrappedLazyMutationFn = ({
  zoomContactId,
  autoOptimisticResponse,
  options,
}: {
  zoomContactId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomContactModelByIdMutation, RemoveZoomContactModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomContactModelByIdMutationResultEx>;
export type RemoveZoomContactModelLazyMutationReturn = [
  RemoveZoomContactModelWrappedLazyMutationFn,
  RemoveZoomContactModelByIdMutationResultEx
];

function useRemoveZoomContactModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomContactModelByIdMutation, RemoveZoomContactModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomContactModelLazyMutationFn = useMutation<
    RemoveZoomContactModelByIdMutation,
    RemoveZoomContactModelByIdMutationVariables
  >(RemoveZoomContactModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomContactModelFn = (
    mutation: RemoveZoomContactModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomContact?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomContactModelWrappedLazyMutationFn = async ({
    zoomContactId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomContactModelMutation,
      RemoveZoomContactModelByIdMutationVariables
    > = { variables: { id: zoomContactId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomContactModelMutation>({
        operationType: 'delete',
        entityName: 'zoomContact',
        objects: [{ id: zoomContactId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomContactModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomContact',
        entityId: zoomContactId,
      });
    }

    const fetchResult: RemoveZoomContactModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomContactModelObjectsMutationResult = FetchResult<
  RemoveZoomContactModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomContactModelObjectsMutationResultEx = RemoveZoomContactModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomContactModelObjectsFn = (mutation: RemoveZoomContactModelMutation | null | undefined) => number;
type RemoveZoomContactModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomContactModelMutation,
  RemoveZoomContactModelMutationVariables
>;
type RemoveZoomContactModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomContactModelMutation, RemoveZoomContactModelMutationVariables>
) => Promise<RemoveZoomContactModelObjectsMutationResultEx>;
export type RemoveZoomContactModelObjectsLazyMutationReturn = [
  RemoveZoomContactModelObjectsWrappedLazyMutationFn,
  RemoveZoomContactModelObjectsMutationResultEx
];

function useRemoveZoomContactModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomContactModelMutation, RemoveZoomContactModelMutationVariables>,
    'mutation'
  >
): RemoveZoomContactModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomContactModelMutation, RemoveZoomContactModelMutationVariables>(
    RemoveZoomContactModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomContactModelObjectsFn = (
    mutation: RemoveZoomContactModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomContact?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomContactModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomContactModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomContact Fragment Helper Object
//------------------------------------------------

export const ZoomContactFragmentGQLHooks = {
  useQueryById: useQueryZoomContactById,
  useQueryByIdLazy: useQueryZoomContactByIdLazy,
  useQueryObjects: useQueryZoomContactObjects,
  useQueryObjectsLazy: useQueryZoomContactObjectsLazy,
  useSubscribeToById: useSubscribeToZoomContactById,
  useSubscribeToObjects: useSubscribeToZoomContactObjects,
  useInsert: useInsertZoomContact,
  useInsertWithOnConflict: useInsertZoomContactWithOnConflict,
  useInsertObjects: useInsertZoomContactObjects,
  useUpdateById: useUpdateZoomContactById,
  useUpdateObjects: useUpdateZoomContactObjects,
};

// zoomContact MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomContactModelGQLHooks = {
  useRemoveById: useRemoveZoomContactModelById,
  useRemoveObjects: useRemoveZoomContactModelObjects,
};

// zoomCompany REACT
//------------------------------------------------

export type ZoomCompanyByIdHookResultEx = { zoomCompany: ZoomCompanyFragment | null | undefined };
export type ZoomCompanyObjectsHookResultEx = { objects: ZoomCompanyFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomCompanyByIdResult = LazyQueryResult<QueryZoomCompanyByIdQuery, QueryZoomCompanyByIdQueryVariables>;
type QueryZoomCompanyByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomCompanyByIdQuery,
          QueryZoomCompanyByIdQueryVariables,
          QueryZoomCompanyByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomCompanyByIdResultEx = Omit<QueryZoomCompanyByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomCompanyByIdSubScribeToMore;
} & ZoomCompanyByIdHookResultEx;

// Function
function useQueryZoomCompanyById({
  zoomCompanyId,
  options,
}: {
  zoomCompanyId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomCompanyByIdQuery, QueryZoomCompanyByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomCompanyByIdResultEx {
  const _query: QueryZoomCompanyByIdResult = useQuery<QueryZoomCompanyByIdQuery, QueryZoomCompanyByIdQueryVariables>(
    QueryZoomCompanyByIdDocument,
    { variables: { zoomCompanyId }, ...options }
  );

  const typedSubscribeToMore: QueryZoomCompanyByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomCompanyByIdDocument,
      variables: { zoomCompanyId } as QueryZoomCompanyByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomCompany: query?.data?.zoomCompany_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomCompanyByIdFn = (
  query: QueryZoomCompanyByIdQuery | null | undefined
) => ZoomCompanyFragment | null | undefined;
type QueryZoomCompanyByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomCompanyByIdQueryVariables> | undefined) => void,
  QueryZoomCompanyByIdResult
];
type QueryZoomCompanyByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomCompanyByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomCompanyByIdLazyReturn = [QueryZoomCompanyByIdWrappedLazyFn, QueryZoomCompanyByIdResultEx];

// Function
function useQueryZoomCompanyByIdLazy({
  zoomCompanyId,
  options,
}: {
  zoomCompanyId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomCompanyByIdQuery, QueryZoomCompanyByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomCompanyByIdLazyReturn {
  const lazyQuery: QueryZoomCompanyByIdLazyFn = useLazyQuery<
    QueryZoomCompanyByIdQuery,
    QueryZoomCompanyByIdQueryVariables
  >(QueryZoomCompanyByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomCompanyById: PickQueryZoomCompanyByIdFn = (query) => {
    return query && query.zoomCompany_by_pk;
  };
  const wrappedLazyQuery: QueryZoomCompanyByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomCompanyByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomCompanyByIdDocument,
        variables: { zoomCompanyId } as QueryZoomCompanyByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomCompany: pickQueryZoomCompanyById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomCompanyObjectsResult = LazyQueryResult<
  QueryZoomCompanyObjectsQuery,
  QueryZoomCompanyObjectsQueryVariables
>;
type QueryZoomCompanyObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomCompanyObjectsQuery,
          QueryZoomCompanyObjectsQueryVariables,
          QueryZoomCompanyObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomCompanyObjectsResultEx = Omit<QueryZoomCompanyObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomCompanyObjectsSubScribeToMore;
} & ZoomCompanyObjectsHookResultEx;

// Function
function useQueryZoomCompanyObjects(
  options: Omit<QueryHookOptions<QueryZoomCompanyObjectsQuery, QueryZoomCompanyObjectsQueryVariables>, 'query'>
): QueryZoomCompanyObjectsResultEx {
  const _query: QueryZoomCompanyObjectsResult = useQuery<
    QueryZoomCompanyObjectsQuery,
    QueryZoomCompanyObjectsQueryVariables
  >(QueryZoomCompanyObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomCompanyObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomCompanyObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomCompany || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomCompanyObjectsFn = (query: QueryZoomCompanyObjectsQuery | null | undefined) => ZoomCompanyFragment[];
type QueryZoomCompanyObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomCompanyObjectsQueryVariables> | undefined) => void,
  QueryZoomCompanyObjectsResult
];
type QueryZoomCompanyObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryZoomCompanyObjectsQueryVariables>) => void;
export type QueryZoomCompanyObjectsLazyReturn = [QueryZoomCompanyObjectsWrappedLazyFn, QueryZoomCompanyObjectsResultEx];

// Function
function useQueryZoomCompanyObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryZoomCompanyObjectsQuery, QueryZoomCompanyObjectsQueryVariables>, 'query'>
): QueryZoomCompanyObjectsLazyReturn {
  const lazyQuery: QueryZoomCompanyObjectsLazyFn = useLazyQuery<
    QueryZoomCompanyObjectsQuery,
    QueryZoomCompanyObjectsQueryVariables
  >(QueryZoomCompanyObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomCompanyObjectsFn = (query: QueryZoomCompanyObjectsQuery | null | undefined) => {
    return query?.zoomCompany || [];
  };
  const wrappedLazyQuery: QueryZoomCompanyObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomCompanyObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomCompanyObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomCompanyByIdResult = {
  variables?: SubscribeToZoomCompanyByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomCompanyByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomCompanyByIdResultEx = SubscribeToZoomCompanyByIdResult & ZoomCompanyByIdHookResultEx;

// Function
function useSubscribeToZoomCompanyById({
  zoomCompanyId,
  options,
}: {
  zoomCompanyId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToZoomCompanyByIdSubscription, SubscribeToZoomCompanyByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToZoomCompanyByIdResultEx {
  const subscription: SubscribeToZoomCompanyByIdResult = useSubscription<
    SubscribeToZoomCompanyByIdSubscription,
    SubscribeToZoomCompanyByIdSubscriptionVariables
  >(SubscribeToZoomCompanyByIdDocument, { variables: { zoomCompanyId }, ...options });
  return { ...subscription, zoomCompany: subscription?.data?.zoomCompany_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomCompanyObjectsResult = {
  variables?: SubscribeToZoomCompanyObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomCompanyObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomCompanyObjectsResultEx = SubscribeToZoomCompanyObjectsResult &
  ZoomCompanyObjectsHookResultEx;

// Function
function useSubscribeToZoomCompanyObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomCompanyObjectsSubscription,
      SubscribeToZoomCompanyObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomCompanyObjectsResultEx {
  const subscription: SubscribeToZoomCompanyObjectsResult = useSubscription<
    SubscribeToZoomCompanyObjectsSubscription,
    SubscribeToZoomCompanyObjectsSubscriptionVariables
  >(SubscribeToZoomCompanyObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomCompany || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomCompanyMutationResult = FetchResult<InsertZoomCompanyMutation, Record<string, any>, Record<string, any>>;
export type InsertZoomCompanyMutationResultEx = InsertZoomCompanyMutationResult & ZoomCompanyByIdHookResultEx;

type PickInsertZoomCompanyFn = (
  mutation: InsertZoomCompanyMutation | null | undefined
) => ZoomCompanyFragment | null | undefined;
type InsertZoomCompanyLazyMutationFn = MutationTuple<InsertZoomCompanyMutation, InsertZoomCompanyMutationVariables>;
type InsertZoomCompanyWrappedLazyMutationFn = ({
  zoomCompany,
  autoOptimisticResponse,
  options,
}: {
  zoomCompany: ZoomCompany_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertZoomCompanyMutation, InsertZoomCompanyMutationVariables>, 'variables'>;
}) => Promise<InsertZoomCompanyMutationResultEx>;
export type InsertZoomCompanyLazyMutationReturn = [
  InsertZoomCompanyWrappedLazyMutationFn,
  InsertZoomCompanyMutationResultEx
];

// Function
function useInsertZoomCompany(
  options?: Omit<
    MutationHookOptions<InsertZoomCompanyMutation, InsertZoomCompanyMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomCompanyLazyMutationReturn {
  const lazyMutation: InsertZoomCompanyLazyMutationFn = useMutation<
    InsertZoomCompanyMutation,
    InsertZoomCompanyMutationVariables
  >(InsertZoomCompanyDocument, options);
  const pickZoomCompany: PickInsertZoomCompanyFn = (mutation) => {
    return mutation?.insert_zoomCompany?.returning && mutation?.insert_zoomCompany?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomCompanyWrappedLazyMutationFn = async ({
    zoomCompany,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomCompany });
    const mutationOptions: MutationFunctionOptions<InsertZoomCompanyMutation, InsertZoomCompanyMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomCompany'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomCompanyMutation>({
        operationType: 'insert',
        entityName: 'zoomCompany',
        objects: [objectForInsert as ZoomCompany_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomCompany: pickZoomCompany(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomCompany: pickZoomCompany(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomCompanyWithOnConflictMutationResult = FetchResult<
  InsertZoomCompanyWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomCompanyWithOnConflictMutationResultEx = InsertZoomCompanyWithOnConflictMutationResult &
  ZoomCompanyByIdHookResultEx;

type InsertZoomCompanyWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomCompanyWithOnConflictMutation,
  InsertZoomCompanyWithOnConflictMutationVariables
>;
type InsertZoomCompanyWithOnConflictWrappedLazyMutationFn = ({
  zoomCompany,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomCompany: ZoomCompany_Insert_Input;
  onConflict: ZoomCompany_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomCompanyWithOnConflictMutation, InsertZoomCompanyWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomCompanyWithOnConflictMutationResultEx>;
export type InsertZoomCompanyWithOnConflictLazyMutationReturn = [
  InsertZoomCompanyWithOnConflictWrappedLazyMutationFn,
  InsertZoomCompanyWithOnConflictMutationResultEx
];

// Function
function useInsertZoomCompanyWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertZoomCompanyWithOnConflictMutation, InsertZoomCompanyWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomCompanyWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomCompanyWithOnConflictLazyMutationFn = useMutation<
    InsertZoomCompanyWithOnConflictMutation,
    InsertZoomCompanyWithOnConflictMutationVariables
  >(InsertZoomCompanyWithOnConflictDocument, options);
  const pickZoomCompany: PickInsertZoomCompanyFn = (
    mutation: InsertZoomCompanyWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomCompany?.returning && mutation.insert_zoomCompany.returning[0];
  };

  const wrappedLazyMutation: InsertZoomCompanyWithOnConflictWrappedLazyMutationFn = async ({
    zoomCompany,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomCompany });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomCompanyWithOnConflictMutation,
      InsertZoomCompanyWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomCompany'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomCompanyWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomCompany',
          objects: [objectForInsert as ZoomCompany_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomCompany: pickZoomCompany(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomCompany: pickZoomCompany(lazyMutation[1].data) }];
}

// Types
type InsertZoomCompanyObjectsMutationResult = FetchResult<
  InsertZoomCompanyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomCompanyObjectsMutationResultEx = InsertZoomCompanyMutationResult & ZoomCompanyObjectsHookResultEx;

type PickInsertZoomCompanyObjectsFn = (mutation: InsertZoomCompanyMutation | null | undefined) => ZoomCompanyFragment[];
type InsertZoomCompanyObjectsLazyMutationFn = MutationTuple<
  InsertZoomCompanyMutation,
  InsertZoomCompanyMutationVariables
>;
type InsertZoomCompanyObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomCompanyMutation, InsertZoomCompanyMutationVariables>
) => Promise<InsertZoomCompanyObjectsMutationResultEx>;
export type InsertZoomCompanyObjectsLazyMutationReturn = [
  InsertZoomCompanyObjectsWrappedLazyMutationFn,
  InsertZoomCompanyObjectsMutationResultEx
];

// Function
function useInsertZoomCompanyObjects(
  options?: Omit<MutationHookOptions<InsertZoomCompanyMutation, InsertZoomCompanyMutationVariables>, 'mutation'>
): InsertZoomCompanyObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomCompanyObjectsLazyMutationFn = useMutation<
    InsertZoomCompanyMutation,
    InsertZoomCompanyMutationVariables
  >(InsertZoomCompanyDocument, options);
  const pickObjects: PickInsertZoomCompanyObjectsFn = (mutation: InsertZoomCompanyMutation | null | undefined) => {
    return mutation?.insert_zoomCompany?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomCompanyObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomCompanyObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomCompanyByIdMutationResult = FetchResult<
  UpdateZoomCompanyByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomCompanyByIdMutationResultEx = UpdateZoomCompanyByIdMutationResult & ZoomCompanyByIdHookResultEx;

type PickUpdateZoomCompanyByIdFn = (
  mutation: UpdateZoomCompanyByIdMutation | null | undefined
) => ZoomCompanyFragment | null | undefined;
type UpdateZoomCompanyByIdLazyMutationFn = MutationTuple<
  UpdateZoomCompanyByIdMutation,
  UpdateZoomCompanyByIdMutationVariables
>;
type UpdateZoomCompanyByIdWrappedLazyMutationFn = ({
  zoomCompanyId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomCompanyId: string;
  set: ZoomCompany_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomCompanyByIdMutation, UpdateZoomCompanyByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomCompanyByIdMutationResultEx>;
export type UpdateZoomCompanyByIdLazyMutationReturn = [
  UpdateZoomCompanyByIdWrappedLazyMutationFn,
  UpdateZoomCompanyByIdMutationResultEx
];

function useUpdateZoomCompanyById(
  options?: Omit<
    MutationHookOptions<UpdateZoomCompanyByIdMutation, UpdateZoomCompanyByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomCompanyByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomCompanyByIdLazyMutationFn = useMutation<
    UpdateZoomCompanyByIdMutation,
    UpdateZoomCompanyByIdMutationVariables
  >(UpdateZoomCompanyByIdDocument, options);

  const pickZoomCompany: PickUpdateZoomCompanyByIdFn = (mutation) => {
    return mutation?.update_zoomCompany?.returning && mutation.update_zoomCompany!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomCompanyByIdWrappedLazyMutationFn = async ({
    zoomCompanyId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomCompanyByIdMutation,
      UpdateZoomCompanyByIdMutationVariables
    > = { variables: { id: zoomCompanyId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomCompanyByIdMutation>({
        operationType: 'update',
        entityName: 'zoomCompany',
        objects: [{ id: zoomCompanyId, ...set }],
      });
    }

    const fetchResult: UpdateZoomCompanyByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomCompanyId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomCompany: pickZoomCompany(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomCompany: pickZoomCompany(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomCompanyObjectsMutationResult = FetchResult<
  UpdateZoomCompanyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomCompanyObjectsMutationResultEx = UpdateZoomCompanyObjectsMutationResult &
  ZoomCompanyObjectsHookResultEx;

// Function
type PickUpdateZoomCompanyObjectsFn = (mutation: UpdateZoomCompanyMutation | null | undefined) => ZoomCompanyFragment[];
type UpdateZoomCompanyObjectsLazyMutationFn = MutationTuple<
  UpdateZoomCompanyMutation,
  UpdateZoomCompanyMutationVariables
>;
type UpdateZoomCompanyObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomCompanyMutation, UpdateZoomCompanyMutationVariables>
) => Promise<UpdateZoomCompanyObjectsMutationResultEx>;
export type UpdateZoomCompanyObjectsLazyMutationReturn = [
  UpdateZoomCompanyObjectsWrappedLazyMutationFn,
  UpdateZoomCompanyObjectsMutationResultEx
];

function useUpdateZoomCompanyObjects(
  options?: Omit<MutationHookOptions<UpdateZoomCompanyMutation, UpdateZoomCompanyMutationVariables>, 'mutation'>
): UpdateZoomCompanyObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomCompanyObjectsLazyMutationFn = useMutation<
    UpdateZoomCompanyMutation,
    UpdateZoomCompanyMutationVariables
  >(UpdateZoomCompanyDocument, options);

  const pickObjects: PickUpdateZoomCompanyObjectsFn = (mutation: UpdateZoomCompanyMutation | null | undefined) => {
    return mutation?.update_zoomCompany?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomCompanyObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomCompanyObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomCompanyModelByIdFetchResult = FetchResult<
  RemoveZoomCompanyModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomCompanyModelByIdMutationResultEx = RemoveZoomCompanyModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomCompanyModelFn = (mutation: RemoveZoomCompanyModelByIdMutation | null | undefined) => number;
type RemoveZoomCompanyModelLazyMutationFn = MutationTuple<
  RemoveZoomCompanyModelByIdMutation,
  RemoveZoomCompanyModelByIdMutationVariables
>;
type RemoveZoomCompanyModelWrappedLazyMutationFn = ({
  zoomCompanyId,
  autoOptimisticResponse,
  options,
}: {
  zoomCompanyId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomCompanyModelByIdMutation, RemoveZoomCompanyModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomCompanyModelByIdMutationResultEx>;
export type RemoveZoomCompanyModelLazyMutationReturn = [
  RemoveZoomCompanyModelWrappedLazyMutationFn,
  RemoveZoomCompanyModelByIdMutationResultEx
];

function useRemoveZoomCompanyModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomCompanyModelByIdMutation, RemoveZoomCompanyModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomCompanyModelLazyMutationFn = useMutation<
    RemoveZoomCompanyModelByIdMutation,
    RemoveZoomCompanyModelByIdMutationVariables
  >(RemoveZoomCompanyModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomCompanyModelFn = (
    mutation: RemoveZoomCompanyModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomCompany?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomCompanyModelWrappedLazyMutationFn = async ({
    zoomCompanyId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomCompanyModelMutation,
      RemoveZoomCompanyModelByIdMutationVariables
    > = { variables: { id: zoomCompanyId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomCompanyModelMutation>({
        operationType: 'delete',
        entityName: 'zoomCompany',
        objects: [{ id: zoomCompanyId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomCompanyModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomCompany',
        entityId: zoomCompanyId,
      });
    }

    const fetchResult: RemoveZoomCompanyModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomCompanyModelObjectsMutationResult = FetchResult<
  RemoveZoomCompanyModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomCompanyModelObjectsMutationResultEx = RemoveZoomCompanyModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomCompanyModelObjectsFn = (mutation: RemoveZoomCompanyModelMutation | null | undefined) => number;
type RemoveZoomCompanyModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomCompanyModelMutation,
  RemoveZoomCompanyModelMutationVariables
>;
type RemoveZoomCompanyModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomCompanyModelMutation, RemoveZoomCompanyModelMutationVariables>
) => Promise<RemoveZoomCompanyModelObjectsMutationResultEx>;
export type RemoveZoomCompanyModelObjectsLazyMutationReturn = [
  RemoveZoomCompanyModelObjectsWrappedLazyMutationFn,
  RemoveZoomCompanyModelObjectsMutationResultEx
];

function useRemoveZoomCompanyModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomCompanyModelMutation, RemoveZoomCompanyModelMutationVariables>,
    'mutation'
  >
): RemoveZoomCompanyModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomCompanyModelMutation, RemoveZoomCompanyModelMutationVariables>(
    RemoveZoomCompanyModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomCompanyModelObjectsFn = (
    mutation: RemoveZoomCompanyModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomCompany?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomCompanyModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomCompanyModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomCompany Fragment Helper Object
//------------------------------------------------

export const ZoomCompanyFragmentGQLHooks = {
  useQueryById: useQueryZoomCompanyById,
  useQueryByIdLazy: useQueryZoomCompanyByIdLazy,
  useQueryObjects: useQueryZoomCompanyObjects,
  useQueryObjectsLazy: useQueryZoomCompanyObjectsLazy,
  useSubscribeToById: useSubscribeToZoomCompanyById,
  useSubscribeToObjects: useSubscribeToZoomCompanyObjects,
  useInsert: useInsertZoomCompany,
  useInsertWithOnConflict: useInsertZoomCompanyWithOnConflict,
  useInsertObjects: useInsertZoomCompanyObjects,
  useUpdateById: useUpdateZoomCompanyById,
  useUpdateObjects: useUpdateZoomCompanyObjects,
};

// zoomCompany MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomCompanyModelGQLHooks = {
  useRemoveById: useRemoveZoomCompanyModelById,
  useRemoveObjects: useRemoveZoomCompanyModelObjects,
};

// zoomGroup REACT
//------------------------------------------------

export type ZoomGroupByIdHookResultEx = { zoomGroup: ZoomGroupFragment | null | undefined };
export type ZoomGroupObjectsHookResultEx = { objects: ZoomGroupFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomGroupByIdResult = LazyQueryResult<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>;
type QueryZoomGroupByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables, QueryZoomGroupByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomGroupByIdResultEx = Omit<QueryZoomGroupByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomGroupByIdSubScribeToMore;
} & ZoomGroupByIdHookResultEx;

// Function
function useQueryZoomGroupById({
  zoomGroupId,
  options,
}: {
  zoomGroupId: string;
  options?: Omit<QueryHookOptions<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>, 'query' | 'variables'>;
}): QueryZoomGroupByIdResultEx {
  const _query: QueryZoomGroupByIdResult = useQuery<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>(
    QueryZoomGroupByIdDocument,
    { variables: { zoomGroupId }, ...options }
  );

  const typedSubscribeToMore: QueryZoomGroupByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomGroupByIdDocument,
      variables: { zoomGroupId } as QueryZoomGroupByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomGroup: query?.data?.zoomGroup_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomGroupByIdFn = (
  query: QueryZoomGroupByIdQuery | null | undefined
) => ZoomGroupFragment | null | undefined;
type QueryZoomGroupByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomGroupByIdQueryVariables> | undefined) => void,
  QueryZoomGroupByIdResult
];
type QueryZoomGroupByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomGroupByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomGroupByIdLazyReturn = [QueryZoomGroupByIdWrappedLazyFn, QueryZoomGroupByIdResultEx];

// Function
function useQueryZoomGroupByIdLazy({
  zoomGroupId,
  options,
}: {
  zoomGroupId: string;
  options?: Omit<QueryHookOptions<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>, 'query' | 'variables'>;
}): QueryZoomGroupByIdLazyReturn {
  const lazyQuery: QueryZoomGroupByIdLazyFn = useLazyQuery<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>(
    QueryZoomGroupByIdDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickQueryZoomGroupById: PickQueryZoomGroupByIdFn = (query) => {
    return query && query.zoomGroup_by_pk;
  };
  const wrappedLazyQuery: QueryZoomGroupByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomGroupByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomGroupByIdDocument,
        variables: { zoomGroupId } as QueryZoomGroupByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, zoomGroup: pickQueryZoomGroupById(lazyQuery[1].data) },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomGroupObjectsResult = LazyQueryResult<
  QueryZoomGroupObjectsQuery,
  QueryZoomGroupObjectsQueryVariables
>;
type QueryZoomGroupObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomGroupObjectsQuery,
          QueryZoomGroupObjectsQueryVariables,
          QueryZoomGroupObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomGroupObjectsResultEx = Omit<QueryZoomGroupObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomGroupObjectsSubScribeToMore;
} & ZoomGroupObjectsHookResultEx;

// Function
function useQueryZoomGroupObjects(
  options: Omit<QueryHookOptions<QueryZoomGroupObjectsQuery, QueryZoomGroupObjectsQueryVariables>, 'query'>
): QueryZoomGroupObjectsResultEx {
  const _query: QueryZoomGroupObjectsResult = useQuery<QueryZoomGroupObjectsQuery, QueryZoomGroupObjectsQueryVariables>(
    QueryZoomGroupObjectsDocument,
    options
  );

  const typedSubscribeToMore: QueryZoomGroupObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomGroupObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomGroup || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomGroupObjectsFn = (query: QueryZoomGroupObjectsQuery | null | undefined) => ZoomGroupFragment[];
type QueryZoomGroupObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomGroupObjectsQueryVariables> | undefined) => void,
  QueryZoomGroupObjectsResult
];
type QueryZoomGroupObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryZoomGroupObjectsQueryVariables>) => void;
export type QueryZoomGroupObjectsLazyReturn = [QueryZoomGroupObjectsWrappedLazyFn, QueryZoomGroupObjectsResultEx];

// Function
function useQueryZoomGroupObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryZoomGroupObjectsQuery, QueryZoomGroupObjectsQueryVariables>, 'query'>
): QueryZoomGroupObjectsLazyReturn {
  const lazyQuery: QueryZoomGroupObjectsLazyFn = useLazyQuery<
    QueryZoomGroupObjectsQuery,
    QueryZoomGroupObjectsQueryVariables
  >(QueryZoomGroupObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomGroupObjectsFn = (query: QueryZoomGroupObjectsQuery | null | undefined) => {
    return query?.zoomGroup || [];
  };
  const wrappedLazyQuery: QueryZoomGroupObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomGroupObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomGroupObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomGroupByIdResult = {
  variables?: SubscribeToZoomGroupByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomGroupByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomGroupByIdResultEx = SubscribeToZoomGroupByIdResult & ZoomGroupByIdHookResultEx;

// Function
function useSubscribeToZoomGroupById({
  zoomGroupId,
  options,
}: {
  zoomGroupId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToZoomGroupByIdSubscription, SubscribeToZoomGroupByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToZoomGroupByIdResultEx {
  const subscription: SubscribeToZoomGroupByIdResult = useSubscription<
    SubscribeToZoomGroupByIdSubscription,
    SubscribeToZoomGroupByIdSubscriptionVariables
  >(SubscribeToZoomGroupByIdDocument, { variables: { zoomGroupId }, ...options });
  return { ...subscription, zoomGroup: subscription?.data?.zoomGroup_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomGroupObjectsResult = {
  variables?: SubscribeToZoomGroupObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomGroupObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomGroupObjectsResultEx = SubscribeToZoomGroupObjectsResult & ZoomGroupObjectsHookResultEx;

// Function
function useSubscribeToZoomGroupObjects(
  options: Omit<
    SubscriptionHookOptions<SubscribeToZoomGroupObjectsSubscription, SubscribeToZoomGroupObjectsSubscriptionVariables>,
    'query'
  >
): SubscribeToZoomGroupObjectsResultEx {
  const subscription: SubscribeToZoomGroupObjectsResult = useSubscription<
    SubscribeToZoomGroupObjectsSubscription,
    SubscribeToZoomGroupObjectsSubscriptionVariables
  >(SubscribeToZoomGroupObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomGroup || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomGroupMutationResult = FetchResult<InsertZoomGroupMutation, Record<string, any>, Record<string, any>>;
export type InsertZoomGroupMutationResultEx = InsertZoomGroupMutationResult & ZoomGroupByIdHookResultEx;

type PickInsertZoomGroupFn = (
  mutation: InsertZoomGroupMutation | null | undefined
) => ZoomGroupFragment | null | undefined;
type InsertZoomGroupLazyMutationFn = MutationTuple<InsertZoomGroupMutation, InsertZoomGroupMutationVariables>;
type InsertZoomGroupWrappedLazyMutationFn = ({
  zoomGroup,
  autoOptimisticResponse,
  options,
}: {
  zoomGroup: ZoomGroup_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertZoomGroupMutation, InsertZoomGroupMutationVariables>, 'variables'>;
}) => Promise<InsertZoomGroupMutationResultEx>;
export type InsertZoomGroupLazyMutationReturn = [InsertZoomGroupWrappedLazyMutationFn, InsertZoomGroupMutationResultEx];

// Function
function useInsertZoomGroup(
  options?: Omit<
    MutationHookOptions<InsertZoomGroupMutation, InsertZoomGroupMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomGroupLazyMutationReturn {
  const lazyMutation: InsertZoomGroupLazyMutationFn = useMutation<
    InsertZoomGroupMutation,
    InsertZoomGroupMutationVariables
  >(InsertZoomGroupDocument, options);
  const pickZoomGroup: PickInsertZoomGroupFn = (mutation) => {
    return mutation?.insert_zoomGroup?.returning && mutation?.insert_zoomGroup?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomGroupWrappedLazyMutationFn = async ({
    zoomGroup,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomGroup });
    const mutationOptions: MutationFunctionOptions<InsertZoomGroupMutation, InsertZoomGroupMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomGroup'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomGroupMutation>({
        operationType: 'insert',
        entityName: 'zoomGroup',
        objects: [objectForInsert as ZoomGroup_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomGroup: pickZoomGroup(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomGroup: pickZoomGroup(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomGroupWithOnConflictMutationResult = FetchResult<
  InsertZoomGroupWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomGroupWithOnConflictMutationResultEx = InsertZoomGroupWithOnConflictMutationResult &
  ZoomGroupByIdHookResultEx;

type InsertZoomGroupWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomGroupWithOnConflictMutation,
  InsertZoomGroupWithOnConflictMutationVariables
>;
type InsertZoomGroupWithOnConflictWrappedLazyMutationFn = ({
  zoomGroup,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomGroup: ZoomGroup_Insert_Input;
  onConflict: ZoomGroup_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomGroupWithOnConflictMutation, InsertZoomGroupWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomGroupWithOnConflictMutationResultEx>;
export type InsertZoomGroupWithOnConflictLazyMutationReturn = [
  InsertZoomGroupWithOnConflictWrappedLazyMutationFn,
  InsertZoomGroupWithOnConflictMutationResultEx
];

// Function
function useInsertZoomGroupWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertZoomGroupWithOnConflictMutation, InsertZoomGroupWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomGroupWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomGroupWithOnConflictLazyMutationFn = useMutation<
    InsertZoomGroupWithOnConflictMutation,
    InsertZoomGroupWithOnConflictMutationVariables
  >(InsertZoomGroupWithOnConflictDocument, options);
  const pickZoomGroup: PickInsertZoomGroupFn = (mutation: InsertZoomGroupWithOnConflictMutation | null | undefined) => {
    return mutation?.insert_zoomGroup?.returning && mutation.insert_zoomGroup.returning[0];
  };

  const wrappedLazyMutation: InsertZoomGroupWithOnConflictWrappedLazyMutationFn = async ({
    zoomGroup,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomGroup });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomGroupWithOnConflictMutation,
      InsertZoomGroupWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomGroup'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomGroupWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'zoomGroup',
          objects: [objectForInsert as ZoomGroup_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomGroup: pickZoomGroup(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomGroup: pickZoomGroup(lazyMutation[1].data) }];
}

// Types
type InsertZoomGroupObjectsMutationResult = FetchResult<
  InsertZoomGroupMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomGroupObjectsMutationResultEx = InsertZoomGroupMutationResult & ZoomGroupObjectsHookResultEx;

type PickInsertZoomGroupObjectsFn = (mutation: InsertZoomGroupMutation | null | undefined) => ZoomGroupFragment[];
type InsertZoomGroupObjectsLazyMutationFn = MutationTuple<InsertZoomGroupMutation, InsertZoomGroupMutationVariables>;
type InsertZoomGroupObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomGroupMutation, InsertZoomGroupMutationVariables>
) => Promise<InsertZoomGroupObjectsMutationResultEx>;
export type InsertZoomGroupObjectsLazyMutationReturn = [
  InsertZoomGroupObjectsWrappedLazyMutationFn,
  InsertZoomGroupObjectsMutationResultEx
];

// Function
function useInsertZoomGroupObjects(
  options?: Omit<MutationHookOptions<InsertZoomGroupMutation, InsertZoomGroupMutationVariables>, 'mutation'>
): InsertZoomGroupObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomGroupObjectsLazyMutationFn = useMutation<
    InsertZoomGroupMutation,
    InsertZoomGroupMutationVariables
  >(InsertZoomGroupDocument, options);
  const pickObjects: PickInsertZoomGroupObjectsFn = (mutation: InsertZoomGroupMutation | null | undefined) => {
    return mutation?.insert_zoomGroup?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomGroupObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomGroupObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomGroupByIdMutationResult = FetchResult<
  UpdateZoomGroupByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomGroupByIdMutationResultEx = UpdateZoomGroupByIdMutationResult & ZoomGroupByIdHookResultEx;

type PickUpdateZoomGroupByIdFn = (
  mutation: UpdateZoomGroupByIdMutation | null | undefined
) => ZoomGroupFragment | null | undefined;
type UpdateZoomGroupByIdLazyMutationFn = MutationTuple<
  UpdateZoomGroupByIdMutation,
  UpdateZoomGroupByIdMutationVariables
>;
type UpdateZoomGroupByIdWrappedLazyMutationFn = ({
  zoomGroupId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomGroupId: string;
  set: ZoomGroup_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomGroupByIdMutation, UpdateZoomGroupByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomGroupByIdMutationResultEx>;
export type UpdateZoomGroupByIdLazyMutationReturn = [
  UpdateZoomGroupByIdWrappedLazyMutationFn,
  UpdateZoomGroupByIdMutationResultEx
];

function useUpdateZoomGroupById(
  options?: Omit<
    MutationHookOptions<UpdateZoomGroupByIdMutation, UpdateZoomGroupByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomGroupByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomGroupByIdLazyMutationFn = useMutation<
    UpdateZoomGroupByIdMutation,
    UpdateZoomGroupByIdMutationVariables
  >(UpdateZoomGroupByIdDocument, options);

  const pickZoomGroup: PickUpdateZoomGroupByIdFn = (mutation) => {
    return mutation?.update_zoomGroup?.returning && mutation.update_zoomGroup!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomGroupByIdWrappedLazyMutationFn = async ({
    zoomGroupId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<UpdateZoomGroupByIdMutation, UpdateZoomGroupByIdMutationVariables> =
      { variables: { id: zoomGroupId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomGroupByIdMutation>({
        operationType: 'update',
        entityName: 'zoomGroup',
        objects: [{ id: zoomGroupId, ...set }],
      });
    }

    const fetchResult: UpdateZoomGroupByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomGroupId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomGroup: pickZoomGroup(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomGroup: pickZoomGroup(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomGroupObjectsMutationResult = FetchResult<
  UpdateZoomGroupMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomGroupObjectsMutationResultEx = UpdateZoomGroupObjectsMutationResult &
  ZoomGroupObjectsHookResultEx;

// Function
type PickUpdateZoomGroupObjectsFn = (mutation: UpdateZoomGroupMutation | null | undefined) => ZoomGroupFragment[];
type UpdateZoomGroupObjectsLazyMutationFn = MutationTuple<UpdateZoomGroupMutation, UpdateZoomGroupMutationVariables>;
type UpdateZoomGroupObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomGroupMutation, UpdateZoomGroupMutationVariables>
) => Promise<UpdateZoomGroupObjectsMutationResultEx>;
export type UpdateZoomGroupObjectsLazyMutationReturn = [
  UpdateZoomGroupObjectsWrappedLazyMutationFn,
  UpdateZoomGroupObjectsMutationResultEx
];

function useUpdateZoomGroupObjects(
  options?: Omit<MutationHookOptions<UpdateZoomGroupMutation, UpdateZoomGroupMutationVariables>, 'mutation'>
): UpdateZoomGroupObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomGroupObjectsLazyMutationFn = useMutation<
    UpdateZoomGroupMutation,
    UpdateZoomGroupMutationVariables
  >(UpdateZoomGroupDocument, options);

  const pickObjects: PickUpdateZoomGroupObjectsFn = (mutation: UpdateZoomGroupMutation | null | undefined) => {
    return mutation?.update_zoomGroup?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomGroupObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomGroupObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomGroupModelByIdFetchResult = FetchResult<
  RemoveZoomGroupModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomGroupModelByIdMutationResultEx = RemoveZoomGroupModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomGroupModelFn = (mutation: RemoveZoomGroupModelByIdMutation | null | undefined) => number;
type RemoveZoomGroupModelLazyMutationFn = MutationTuple<
  RemoveZoomGroupModelByIdMutation,
  RemoveZoomGroupModelByIdMutationVariables
>;
type RemoveZoomGroupModelWrappedLazyMutationFn = ({
  zoomGroupId,
  autoOptimisticResponse,
  options,
}: {
  zoomGroupId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomGroupModelByIdMutation, RemoveZoomGroupModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomGroupModelByIdMutationResultEx>;
export type RemoveZoomGroupModelLazyMutationReturn = [
  RemoveZoomGroupModelWrappedLazyMutationFn,
  RemoveZoomGroupModelByIdMutationResultEx
];

function useRemoveZoomGroupModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomGroupModelByIdMutation, RemoveZoomGroupModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomGroupModelLazyMutationFn = useMutation<
    RemoveZoomGroupModelByIdMutation,
    RemoveZoomGroupModelByIdMutationVariables
  >(RemoveZoomGroupModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomGroupModelFn = (
    mutation: RemoveZoomGroupModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomGroup?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomGroupModelWrappedLazyMutationFn = async ({
    zoomGroupId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomGroupModelMutation,
      RemoveZoomGroupModelByIdMutationVariables
    > = { variables: { id: zoomGroupId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomGroupModelMutation>({
        operationType: 'delete',
        entityName: 'zoomGroup',
        objects: [{ id: zoomGroupId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomGroupModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomGroup',
        entityId: zoomGroupId,
      });
    }

    const fetchResult: RemoveZoomGroupModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomGroupModelObjectsMutationResult = FetchResult<
  RemoveZoomGroupModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomGroupModelObjectsMutationResultEx = RemoveZoomGroupModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomGroupModelObjectsFn = (mutation: RemoveZoomGroupModelMutation | null | undefined) => number;
type RemoveZoomGroupModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomGroupModelMutation,
  RemoveZoomGroupModelMutationVariables
>;
type RemoveZoomGroupModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomGroupModelMutation, RemoveZoomGroupModelMutationVariables>
) => Promise<RemoveZoomGroupModelObjectsMutationResultEx>;
export type RemoveZoomGroupModelObjectsLazyMutationReturn = [
  RemoveZoomGroupModelObjectsWrappedLazyMutationFn,
  RemoveZoomGroupModelObjectsMutationResultEx
];

function useRemoveZoomGroupModelObjects(
  options?: Omit<MutationHookOptions<RemoveZoomGroupModelMutation, RemoveZoomGroupModelMutationVariables>, 'mutation'>
): RemoveZoomGroupModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomGroupModelMutation, RemoveZoomGroupModelMutationVariables>(
    RemoveZoomGroupModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomGroupModelObjectsFn = (
    mutation: RemoveZoomGroupModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomGroup?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomGroupModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomGroupModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomGroup Fragment Helper Object
//------------------------------------------------

export const ZoomGroupFragmentGQLHooks = {
  useQueryById: useQueryZoomGroupById,
  useQueryByIdLazy: useQueryZoomGroupByIdLazy,
  useQueryObjects: useQueryZoomGroupObjects,
  useQueryObjectsLazy: useQueryZoomGroupObjectsLazy,
  useSubscribeToById: useSubscribeToZoomGroupById,
  useSubscribeToObjects: useSubscribeToZoomGroupObjects,
  useInsert: useInsertZoomGroup,
  useInsertWithOnConflict: useInsertZoomGroupWithOnConflict,
  useInsertObjects: useInsertZoomGroupObjects,
  useUpdateById: useUpdateZoomGroupById,
  useUpdateObjects: useUpdateZoomGroupObjects,
};

// zoomGroup MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomGroupModelGQLHooks = {
  useRemoveById: useRemoveZoomGroupModelById,
  useRemoveObjects: useRemoveZoomGroupModelObjects,
};

// zoomContact REACT
//------------------------------------------------

export type ZoomContactWithCompanyByIdHookResultEx = {
  zoomContactWithCompany: ZoomContactWithCompanyFragment | null | undefined;
};
export type ZoomContactWithCompanyObjectsHookResultEx = { objects: ZoomContactWithCompanyFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomContactWithCompanyByIdResult = LazyQueryResult<
  QueryZoomContactWithCompanyByIdQuery,
  QueryZoomContactWithCompanyByIdQueryVariables
>;
type QueryZoomContactWithCompanyByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomContactWithCompanyByIdQuery,
          QueryZoomContactWithCompanyByIdQueryVariables,
          QueryZoomContactWithCompanyByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomContactWithCompanyByIdResultEx = Omit<QueryZoomContactWithCompanyByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomContactWithCompanyByIdSubScribeToMore;
} & ZoomContactWithCompanyByIdHookResultEx;

// Function
function useQueryZoomContactWithCompanyById({
  zoomContactId,
  options,
}: {
  zoomContactId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomContactWithCompanyByIdQuery, QueryZoomContactWithCompanyByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomContactWithCompanyByIdResultEx {
  const _query: QueryZoomContactWithCompanyByIdResult = useQuery<
    QueryZoomContactWithCompanyByIdQuery,
    QueryZoomContactWithCompanyByIdQueryVariables
  >(QueryZoomContactWithCompanyByIdDocument, { variables: { zoomContactId }, ...options });

  const typedSubscribeToMore: QueryZoomContactWithCompanyByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomContactWithCompanyByIdDocument,
      variables: { zoomContactId } as QueryZoomContactWithCompanyByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomContactWithCompany: query?.data?.zoomContact_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomContactWithCompanyByIdFn = (
  query: QueryZoomContactWithCompanyByIdQuery | null | undefined
) => ZoomContactWithCompanyFragment | null | undefined;
type QueryZoomContactWithCompanyByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomContactWithCompanyByIdQueryVariables> | undefined) => void,
  QueryZoomContactWithCompanyByIdResult
];
type QueryZoomContactWithCompanyByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomContactWithCompanyByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomContactWithCompanyByIdLazyReturn = [
  QueryZoomContactWithCompanyByIdWrappedLazyFn,
  QueryZoomContactWithCompanyByIdResultEx
];

// Function
function useQueryZoomContactWithCompanyByIdLazy({
  zoomContactId,
  options,
}: {
  zoomContactId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomContactWithCompanyByIdQuery, QueryZoomContactWithCompanyByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomContactWithCompanyByIdLazyReturn {
  const lazyQuery: QueryZoomContactWithCompanyByIdLazyFn = useLazyQuery<
    QueryZoomContactWithCompanyByIdQuery,
    QueryZoomContactWithCompanyByIdQueryVariables
  >(QueryZoomContactWithCompanyByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomContactWithCompanyById: PickQueryZoomContactWithCompanyByIdFn = (query) => {
    return query && query.zoomContact_by_pk;
  };
  const wrappedLazyQuery: QueryZoomContactWithCompanyByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomContactWithCompanyByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomContactWithCompanyByIdDocument,
        variables: { zoomContactId } as QueryZoomContactWithCompanyByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomContactWithCompany: pickQueryZoomContactWithCompanyById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomContactWithCompanyObjectsResult = LazyQueryResult<
  QueryZoomContactWithCompanyObjectsQuery,
  QueryZoomContactWithCompanyObjectsQueryVariables
>;
type QueryZoomContactWithCompanyObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomContactWithCompanyObjectsQuery,
          QueryZoomContactWithCompanyObjectsQueryVariables,
          QueryZoomContactWithCompanyObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomContactWithCompanyObjectsResultEx = Omit<
  QueryZoomContactWithCompanyObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QueryZoomContactWithCompanyObjectsSubScribeToMore } & ZoomContactWithCompanyObjectsHookResultEx;

// Function
function useQueryZoomContactWithCompanyObjects(
  options: Omit<
    QueryHookOptions<QueryZoomContactWithCompanyObjectsQuery, QueryZoomContactWithCompanyObjectsQueryVariables>,
    'query'
  >
): QueryZoomContactWithCompanyObjectsResultEx {
  const _query: QueryZoomContactWithCompanyObjectsResult = useQuery<
    QueryZoomContactWithCompanyObjectsQuery,
    QueryZoomContactWithCompanyObjectsQueryVariables
  >(QueryZoomContactWithCompanyObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomContactWithCompanyObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomContactWithCompanyObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomContact || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomContactWithCompanyObjectsFn = (
  query: QueryZoomContactWithCompanyObjectsQuery | null | undefined
) => ZoomContactWithCompanyFragment[];
type QueryZoomContactWithCompanyObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomContactWithCompanyObjectsQueryVariables> | undefined) => void,
  QueryZoomContactWithCompanyObjectsResult
];
type QueryZoomContactWithCompanyObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomContactWithCompanyObjectsQueryVariables>
) => void;
export type QueryZoomContactWithCompanyObjectsLazyReturn = [
  QueryZoomContactWithCompanyObjectsWrappedLazyFn,
  QueryZoomContactWithCompanyObjectsResultEx
];

// Function
function useQueryZoomContactWithCompanyObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomContactWithCompanyObjectsQuery, QueryZoomContactWithCompanyObjectsQueryVariables>,
    'query'
  >
): QueryZoomContactWithCompanyObjectsLazyReturn {
  const lazyQuery: QueryZoomContactWithCompanyObjectsLazyFn = useLazyQuery<
    QueryZoomContactWithCompanyObjectsQuery,
    QueryZoomContactWithCompanyObjectsQueryVariables
  >(QueryZoomContactWithCompanyObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomContactWithCompanyObjectsFn = (
    query: QueryZoomContactWithCompanyObjectsQuery | null | undefined
  ) => {
    return query?.zoomContact || [];
  };
  const wrappedLazyQuery: QueryZoomContactWithCompanyObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomContactWithCompanyObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomContactWithCompanyObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomContactWithCompanyByIdResult = {
  variables?: SubscribeToZoomContactWithCompanyByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomContactWithCompanyByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomContactWithCompanyByIdResultEx = SubscribeToZoomContactWithCompanyByIdResult &
  ZoomContactWithCompanyByIdHookResultEx;

// Function
function useSubscribeToZoomContactWithCompanyById({
  zoomContactId,
  options,
}: {
  zoomContactId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomContactWithCompanyByIdSubscription,
      SubscribeToZoomContactWithCompanyByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomContactWithCompanyByIdResultEx {
  const subscription: SubscribeToZoomContactWithCompanyByIdResult = useSubscription<
    SubscribeToZoomContactWithCompanyByIdSubscription,
    SubscribeToZoomContactWithCompanyByIdSubscriptionVariables
  >(SubscribeToZoomContactWithCompanyByIdDocument, { variables: { zoomContactId }, ...options });
  return { ...subscription, zoomContactWithCompany: subscription?.data?.zoomContact_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomContactWithCompanyObjectsResult = {
  variables?: SubscribeToZoomContactWithCompanyObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomContactWithCompanyObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomContactWithCompanyObjectsResultEx = SubscribeToZoomContactWithCompanyObjectsResult &
  ZoomContactWithCompanyObjectsHookResultEx;

// Function
function useSubscribeToZoomContactWithCompanyObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomContactWithCompanyObjectsSubscription,
      SubscribeToZoomContactWithCompanyObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomContactWithCompanyObjectsResultEx {
  const subscription: SubscribeToZoomContactWithCompanyObjectsResult = useSubscription<
    SubscribeToZoomContactWithCompanyObjectsSubscription,
    SubscribeToZoomContactWithCompanyObjectsSubscriptionVariables
  >(SubscribeToZoomContactWithCompanyObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomContact || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomContactWithCompanyMutationResult = FetchResult<
  InsertZoomContactWithCompanyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomContactWithCompanyMutationResultEx = InsertZoomContactWithCompanyMutationResult &
  ZoomContactWithCompanyByIdHookResultEx;

type PickInsertZoomContactWithCompanyFn = (
  mutation: InsertZoomContactWithCompanyMutation | null | undefined
) => ZoomContactWithCompanyFragment | null | undefined;
type InsertZoomContactWithCompanyLazyMutationFn = MutationTuple<
  InsertZoomContactWithCompanyMutation,
  InsertZoomContactWithCompanyMutationVariables
>;
type InsertZoomContactWithCompanyWrappedLazyMutationFn = ({
  zoomContact,
  autoOptimisticResponse,
  options,
}: {
  zoomContact: ZoomContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomContactWithCompanyMutation, InsertZoomContactWithCompanyMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomContactWithCompanyMutationResultEx>;
export type InsertZoomContactWithCompanyLazyMutationReturn = [
  InsertZoomContactWithCompanyWrappedLazyMutationFn,
  InsertZoomContactWithCompanyMutationResultEx
];

// Function
function useInsertZoomContactWithCompany(
  options?: Omit<
    MutationHookOptions<InsertZoomContactWithCompanyMutation, InsertZoomContactWithCompanyMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomContactWithCompanyLazyMutationReturn {
  const lazyMutation: InsertZoomContactWithCompanyLazyMutationFn = useMutation<
    InsertZoomContactWithCompanyMutation,
    InsertZoomContactWithCompanyMutationVariables
  >(InsertZoomContactWithCompanyDocument, options);
  const pickZoomContactWithCompany: PickInsertZoomContactWithCompanyFn = (mutation) => {
    return mutation?.insert_zoomContact?.returning && mutation?.insert_zoomContact?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomContactWithCompanyWrappedLazyMutationFn = async ({
    zoomContact,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomContact });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomContactWithCompanyMutation,
      InsertZoomContactWithCompanyMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomContact'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomContactWithCompanyMutation>({
        operationType: 'insert',
        entityName: 'zoomContact',
        objects: [objectForInsert as ZoomContact_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomContactWithCompany: pickZoomContactWithCompany(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomContactWithCompany: pickZoomContactWithCompany(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomContactWithCompanyWithOnConflictMutationResult = FetchResult<
  InsertZoomContactWithCompanyWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomContactWithCompanyWithOnConflictMutationResultEx =
  InsertZoomContactWithCompanyWithOnConflictMutationResult & ZoomContactWithCompanyByIdHookResultEx;

type InsertZoomContactWithCompanyWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomContactWithCompanyWithOnConflictMutation,
  InsertZoomContactWithCompanyWithOnConflictMutationVariables
>;
type InsertZoomContactWithCompanyWithOnConflictWrappedLazyMutationFn = ({
  zoomContact,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomContact: ZoomContact_Insert_Input;
  onConflict: ZoomContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomContactWithCompanyWithOnConflictMutation,
      InsertZoomContactWithCompanyWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomContactWithCompanyWithOnConflictMutationResultEx>;
export type InsertZoomContactWithCompanyWithOnConflictLazyMutationReturn = [
  InsertZoomContactWithCompanyWithOnConflictWrappedLazyMutationFn,
  InsertZoomContactWithCompanyWithOnConflictMutationResultEx
];

// Function
function useInsertZoomContactWithCompanyWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomContactWithCompanyWithOnConflictMutation,
      InsertZoomContactWithCompanyWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomContactWithCompanyWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomContactWithCompanyWithOnConflictLazyMutationFn = useMutation<
    InsertZoomContactWithCompanyWithOnConflictMutation,
    InsertZoomContactWithCompanyWithOnConflictMutationVariables
  >(InsertZoomContactWithCompanyWithOnConflictDocument, options);
  const pickZoomContactWithCompany: PickInsertZoomContactWithCompanyFn = (
    mutation: InsertZoomContactWithCompanyWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomContact?.returning && mutation.insert_zoomContact.returning[0];
  };

  const wrappedLazyMutation: InsertZoomContactWithCompanyWithOnConflictWrappedLazyMutationFn = async ({
    zoomContact,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomContact });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomContactWithCompanyWithOnConflictMutation,
      InsertZoomContactWithCompanyWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomContact'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomContactWithCompanyWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomContact',
          objects: [objectForInsert as ZoomContact_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomContactWithCompany: pickZoomContactWithCompany(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomContactWithCompany: pickZoomContactWithCompany(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomContactWithCompanyObjectsMutationResult = FetchResult<
  InsertZoomContactWithCompanyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomContactWithCompanyObjectsMutationResultEx = InsertZoomContactWithCompanyMutationResult &
  ZoomContactWithCompanyObjectsHookResultEx;

type PickInsertZoomContactWithCompanyObjectsFn = (
  mutation: InsertZoomContactWithCompanyMutation | null | undefined
) => ZoomContactWithCompanyFragment[];
type InsertZoomContactWithCompanyObjectsLazyMutationFn = MutationTuple<
  InsertZoomContactWithCompanyMutation,
  InsertZoomContactWithCompanyMutationVariables
>;
type InsertZoomContactWithCompanyObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomContactWithCompanyMutation, InsertZoomContactWithCompanyMutationVariables>
) => Promise<InsertZoomContactWithCompanyObjectsMutationResultEx>;
export type InsertZoomContactWithCompanyObjectsLazyMutationReturn = [
  InsertZoomContactWithCompanyObjectsWrappedLazyMutationFn,
  InsertZoomContactWithCompanyObjectsMutationResultEx
];

// Function
function useInsertZoomContactWithCompanyObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomContactWithCompanyMutation, InsertZoomContactWithCompanyMutationVariables>,
    'mutation'
  >
): InsertZoomContactWithCompanyObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomContactWithCompanyObjectsLazyMutationFn = useMutation<
    InsertZoomContactWithCompanyMutation,
    InsertZoomContactWithCompanyMutationVariables
  >(InsertZoomContactWithCompanyDocument, options);
  const pickObjects: PickInsertZoomContactWithCompanyObjectsFn = (
    mutation: InsertZoomContactWithCompanyMutation | null | undefined
  ) => {
    return mutation?.insert_zoomContact?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomContactWithCompanyObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomContactWithCompanyObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomContactWithCompanyByIdMutationResult = FetchResult<
  UpdateZoomContactWithCompanyByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomContactWithCompanyByIdMutationResultEx = UpdateZoomContactWithCompanyByIdMutationResult &
  ZoomContactWithCompanyByIdHookResultEx;

type PickUpdateZoomContactWithCompanyByIdFn = (
  mutation: UpdateZoomContactWithCompanyByIdMutation | null | undefined
) => ZoomContactWithCompanyFragment | null | undefined;
type UpdateZoomContactWithCompanyByIdLazyMutationFn = MutationTuple<
  UpdateZoomContactWithCompanyByIdMutation,
  UpdateZoomContactWithCompanyByIdMutationVariables
>;
type UpdateZoomContactWithCompanyByIdWrappedLazyMutationFn = ({
  zoomContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomContactId: string;
  set: ZoomContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateZoomContactWithCompanyByIdMutation,
      UpdateZoomContactWithCompanyByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateZoomContactWithCompanyByIdMutationResultEx>;
export type UpdateZoomContactWithCompanyByIdLazyMutationReturn = [
  UpdateZoomContactWithCompanyByIdWrappedLazyMutationFn,
  UpdateZoomContactWithCompanyByIdMutationResultEx
];

function useUpdateZoomContactWithCompanyById(
  options?: Omit<
    MutationHookOptions<UpdateZoomContactWithCompanyByIdMutation, UpdateZoomContactWithCompanyByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomContactWithCompanyByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomContactWithCompanyByIdLazyMutationFn = useMutation<
    UpdateZoomContactWithCompanyByIdMutation,
    UpdateZoomContactWithCompanyByIdMutationVariables
  >(UpdateZoomContactWithCompanyByIdDocument, options);

  const pickZoomContactWithCompany: PickUpdateZoomContactWithCompanyByIdFn = (mutation) => {
    return mutation?.update_zoomContact?.returning && mutation.update_zoomContact!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomContactWithCompanyByIdWrappedLazyMutationFn = async ({
    zoomContactId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomContactWithCompanyByIdMutation,
      UpdateZoomContactWithCompanyByIdMutationVariables
    > = { variables: { id: zoomContactId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<UpdateZoomContactWithCompanyByIdMutation>({
          operationType: 'update',
          entityName: 'zoomContact',
          objects: [{ id: zoomContactId, ...set }],
        });
    }

    const fetchResult: UpdateZoomContactWithCompanyByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomContactId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomContactWithCompany: pickZoomContactWithCompany(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomContactWithCompany: pickZoomContactWithCompany(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomContactWithCompanyObjectsMutationResult = FetchResult<
  UpdateZoomContactWithCompanyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomContactWithCompanyObjectsMutationResultEx = UpdateZoomContactWithCompanyObjectsMutationResult &
  ZoomContactWithCompanyObjectsHookResultEx;

// Function
type PickUpdateZoomContactWithCompanyObjectsFn = (
  mutation: UpdateZoomContactWithCompanyMutation | null | undefined
) => ZoomContactWithCompanyFragment[];
type UpdateZoomContactWithCompanyObjectsLazyMutationFn = MutationTuple<
  UpdateZoomContactWithCompanyMutation,
  UpdateZoomContactWithCompanyMutationVariables
>;
type UpdateZoomContactWithCompanyObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomContactWithCompanyMutation, UpdateZoomContactWithCompanyMutationVariables>
) => Promise<UpdateZoomContactWithCompanyObjectsMutationResultEx>;
export type UpdateZoomContactWithCompanyObjectsLazyMutationReturn = [
  UpdateZoomContactWithCompanyObjectsWrappedLazyMutationFn,
  UpdateZoomContactWithCompanyObjectsMutationResultEx
];

function useUpdateZoomContactWithCompanyObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomContactWithCompanyMutation, UpdateZoomContactWithCompanyMutationVariables>,
    'mutation'
  >
): UpdateZoomContactWithCompanyObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomContactWithCompanyObjectsLazyMutationFn = useMutation<
    UpdateZoomContactWithCompanyMutation,
    UpdateZoomContactWithCompanyMutationVariables
  >(UpdateZoomContactWithCompanyDocument, options);

  const pickObjects: PickUpdateZoomContactWithCompanyObjectsFn = (
    mutation: UpdateZoomContactWithCompanyMutation | null | undefined
  ) => {
    return mutation?.update_zoomContact?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomContactWithCompanyObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomContactWithCompanyObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

// ZoomContactWithCompany Fragment Helper Object
//------------------------------------------------

export const ZoomContactWithCompanyFragmentGQLHooks = {
  useQueryById: useQueryZoomContactWithCompanyById,
  useQueryByIdLazy: useQueryZoomContactWithCompanyByIdLazy,
  useQueryObjects: useQueryZoomContactWithCompanyObjects,
  useQueryObjectsLazy: useQueryZoomContactWithCompanyObjectsLazy,
  useSubscribeToById: useSubscribeToZoomContactWithCompanyById,
  useSubscribeToObjects: useSubscribeToZoomContactWithCompanyObjects,
  useInsert: useInsertZoomContactWithCompany,
  useInsertWithOnConflict: useInsertZoomContactWithCompanyWithOnConflict,
  useInsertObjects: useInsertZoomContactWithCompanyObjects,
  useUpdateById: useUpdateZoomContactWithCompanyById,
  useUpdateObjects: useUpdateZoomContactWithCompanyObjects,
};

// zoomUserActions REACT
//------------------------------------------------

export type ZoomUserActionsByIdHookResultEx = { zoomUserActions: ZoomUserActionsFragment | null | undefined };
export type ZoomUserActionsObjectsHookResultEx = { objects: ZoomUserActionsFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomUserActionsByIdResult = LazyQueryResult<
  QueryZoomUserActionsByIdQuery,
  QueryZoomUserActionsByIdQueryVariables
>;
type QueryZoomUserActionsByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomUserActionsByIdQuery,
          QueryZoomUserActionsByIdQueryVariables,
          QueryZoomUserActionsByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomUserActionsByIdResultEx = Omit<QueryZoomUserActionsByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomUserActionsByIdSubScribeToMore;
} & ZoomUserActionsByIdHookResultEx;

// Function
function useQueryZoomUserActionsById({
  zoomUserActionsId,
  options,
}: {
  zoomUserActionsId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomUserActionsByIdQuery, QueryZoomUserActionsByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomUserActionsByIdResultEx {
  const _query: QueryZoomUserActionsByIdResult = useQuery<
    QueryZoomUserActionsByIdQuery,
    QueryZoomUserActionsByIdQueryVariables
  >(QueryZoomUserActionsByIdDocument, { variables: { zoomUserActionsId }, ...options });

  const typedSubscribeToMore: QueryZoomUserActionsByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomUserActionsByIdDocument,
      variables: { zoomUserActionsId } as QueryZoomUserActionsByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomUserActions: query?.data?.zoomUserActions_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomUserActionsByIdFn = (
  query: QueryZoomUserActionsByIdQuery | null | undefined
) => ZoomUserActionsFragment | null | undefined;
type QueryZoomUserActionsByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomUserActionsByIdQueryVariables> | undefined) => void,
  QueryZoomUserActionsByIdResult
];
type QueryZoomUserActionsByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomUserActionsByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomUserActionsByIdLazyReturn = [
  QueryZoomUserActionsByIdWrappedLazyFn,
  QueryZoomUserActionsByIdResultEx
];

// Function
function useQueryZoomUserActionsByIdLazy({
  zoomUserActionsId,
  options,
}: {
  zoomUserActionsId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomUserActionsByIdQuery, QueryZoomUserActionsByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomUserActionsByIdLazyReturn {
  const lazyQuery: QueryZoomUserActionsByIdLazyFn = useLazyQuery<
    QueryZoomUserActionsByIdQuery,
    QueryZoomUserActionsByIdQueryVariables
  >(QueryZoomUserActionsByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomUserActionsById: PickQueryZoomUserActionsByIdFn = (query) => {
    return query && query.zoomUserActions_by_pk;
  };
  const wrappedLazyQuery: QueryZoomUserActionsByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomUserActionsByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomUserActionsByIdDocument,
        variables: { zoomUserActionsId } as QueryZoomUserActionsByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomUserActions: pickQueryZoomUserActionsById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomUserActionsObjectsResult = LazyQueryResult<
  QueryZoomUserActionsObjectsQuery,
  QueryZoomUserActionsObjectsQueryVariables
>;
type QueryZoomUserActionsObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomUserActionsObjectsQuery,
          QueryZoomUserActionsObjectsQueryVariables,
          QueryZoomUserActionsObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomUserActionsObjectsResultEx = Omit<QueryZoomUserActionsObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomUserActionsObjectsSubScribeToMore;
} & ZoomUserActionsObjectsHookResultEx;

// Function
function useQueryZoomUserActionsObjects(
  options: Omit<QueryHookOptions<QueryZoomUserActionsObjectsQuery, QueryZoomUserActionsObjectsQueryVariables>, 'query'>
): QueryZoomUserActionsObjectsResultEx {
  const _query: QueryZoomUserActionsObjectsResult = useQuery<
    QueryZoomUserActionsObjectsQuery,
    QueryZoomUserActionsObjectsQueryVariables
  >(QueryZoomUserActionsObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomUserActionsObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomUserActionsObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomUserActions || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomUserActionsObjectsFn = (
  query: QueryZoomUserActionsObjectsQuery | null | undefined
) => ZoomUserActionsFragment[];
type QueryZoomUserActionsObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomUserActionsObjectsQueryVariables> | undefined) => void,
  QueryZoomUserActionsObjectsResult
];
type QueryZoomUserActionsObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomUserActionsObjectsQueryVariables>
) => void;
export type QueryZoomUserActionsObjectsLazyReturn = [
  QueryZoomUserActionsObjectsWrappedLazyFn,
  QueryZoomUserActionsObjectsResultEx
];

// Function
function useQueryZoomUserActionsObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomUserActionsObjectsQuery, QueryZoomUserActionsObjectsQueryVariables>,
    'query'
  >
): QueryZoomUserActionsObjectsLazyReturn {
  const lazyQuery: QueryZoomUserActionsObjectsLazyFn = useLazyQuery<
    QueryZoomUserActionsObjectsQuery,
    QueryZoomUserActionsObjectsQueryVariables
  >(QueryZoomUserActionsObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomUserActionsObjectsFn = (
    query: QueryZoomUserActionsObjectsQuery | null | undefined
  ) => {
    return query?.zoomUserActions || [];
  };
  const wrappedLazyQuery: QueryZoomUserActionsObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomUserActionsObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomUserActionsObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomUserActionsByIdResult = {
  variables?: SubscribeToZoomUserActionsByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomUserActionsByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomUserActionsByIdResultEx = SubscribeToZoomUserActionsByIdResult &
  ZoomUserActionsByIdHookResultEx;

// Function
function useSubscribeToZoomUserActionsById({
  zoomUserActionsId,
  options,
}: {
  zoomUserActionsId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomUserActionsByIdSubscription,
      SubscribeToZoomUserActionsByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomUserActionsByIdResultEx {
  const subscription: SubscribeToZoomUserActionsByIdResult = useSubscription<
    SubscribeToZoomUserActionsByIdSubscription,
    SubscribeToZoomUserActionsByIdSubscriptionVariables
  >(SubscribeToZoomUserActionsByIdDocument, { variables: { zoomUserActionsId }, ...options });
  return { ...subscription, zoomUserActions: subscription?.data?.zoomUserActions_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomUserActionsObjectsResult = {
  variables?: SubscribeToZoomUserActionsObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomUserActionsObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomUserActionsObjectsResultEx = SubscribeToZoomUserActionsObjectsResult &
  ZoomUserActionsObjectsHookResultEx;

// Function
function useSubscribeToZoomUserActionsObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomUserActionsObjectsSubscription,
      SubscribeToZoomUserActionsObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomUserActionsObjectsResultEx {
  const subscription: SubscribeToZoomUserActionsObjectsResult = useSubscription<
    SubscribeToZoomUserActionsObjectsSubscription,
    SubscribeToZoomUserActionsObjectsSubscriptionVariables
  >(SubscribeToZoomUserActionsObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomUserActions || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomUserActionsMutationResult = FetchResult<
  InsertZoomUserActionsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomUserActionsMutationResultEx = InsertZoomUserActionsMutationResult &
  ZoomUserActionsByIdHookResultEx;

type PickInsertZoomUserActionsFn = (
  mutation: InsertZoomUserActionsMutation | null | undefined
) => ZoomUserActionsFragment | null | undefined;
type InsertZoomUserActionsLazyMutationFn = MutationTuple<
  InsertZoomUserActionsMutation,
  InsertZoomUserActionsMutationVariables
>;
type InsertZoomUserActionsWrappedLazyMutationFn = ({
  zoomUserActions,
  autoOptimisticResponse,
  options,
}: {
  zoomUserActions: ZoomUserActions_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomUserActionsMutation, InsertZoomUserActionsMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomUserActionsMutationResultEx>;
export type InsertZoomUserActionsLazyMutationReturn = [
  InsertZoomUserActionsWrappedLazyMutationFn,
  InsertZoomUserActionsMutationResultEx
];

// Function
function useInsertZoomUserActions(
  options?: Omit<
    MutationHookOptions<InsertZoomUserActionsMutation, InsertZoomUserActionsMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomUserActionsLazyMutationReturn {
  const lazyMutation: InsertZoomUserActionsLazyMutationFn = useMutation<
    InsertZoomUserActionsMutation,
    InsertZoomUserActionsMutationVariables
  >(InsertZoomUserActionsDocument, options);
  const pickZoomUserActions: PickInsertZoomUserActionsFn = (mutation) => {
    return mutation?.insert_zoomUserActions?.returning && mutation?.insert_zoomUserActions?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomUserActionsWrappedLazyMutationFn = async ({
    zoomUserActions,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomUserActions });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomUserActionsMutation,
      InsertZoomUserActionsMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomUserActions'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomUserActionsMutation>({
        operationType: 'insert',
        entityName: 'zoomUserActions',
        objects: [objectForInsert as ZoomUserActions_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomUserActions: pickZoomUserActions(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomUserActions: pickZoomUserActions(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomUserActionsWithOnConflictMutationResult = FetchResult<
  InsertZoomUserActionsWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomUserActionsWithOnConflictMutationResultEx = InsertZoomUserActionsWithOnConflictMutationResult &
  ZoomUserActionsByIdHookResultEx;

type InsertZoomUserActionsWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomUserActionsWithOnConflictMutation,
  InsertZoomUserActionsWithOnConflictMutationVariables
>;
type InsertZoomUserActionsWithOnConflictWrappedLazyMutationFn = ({
  zoomUserActions,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomUserActions: ZoomUserActions_Insert_Input;
  onConflict: ZoomUserActions_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomUserActionsWithOnConflictMutation,
      InsertZoomUserActionsWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomUserActionsWithOnConflictMutationResultEx>;
export type InsertZoomUserActionsWithOnConflictLazyMutationReturn = [
  InsertZoomUserActionsWithOnConflictWrappedLazyMutationFn,
  InsertZoomUserActionsWithOnConflictMutationResultEx
];

// Function
function useInsertZoomUserActionsWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomUserActionsWithOnConflictMutation,
      InsertZoomUserActionsWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomUserActionsWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomUserActionsWithOnConflictLazyMutationFn = useMutation<
    InsertZoomUserActionsWithOnConflictMutation,
    InsertZoomUserActionsWithOnConflictMutationVariables
  >(InsertZoomUserActionsWithOnConflictDocument, options);
  const pickZoomUserActions: PickInsertZoomUserActionsFn = (
    mutation: InsertZoomUserActionsWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomUserActions?.returning && mutation.insert_zoomUserActions.returning[0];
  };

  const wrappedLazyMutation: InsertZoomUserActionsWithOnConflictWrappedLazyMutationFn = async ({
    zoomUserActions,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomUserActions });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomUserActionsWithOnConflictMutation,
      InsertZoomUserActionsWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomUserActions'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomUserActionsWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomUserActions',
          objects: [objectForInsert as ZoomUserActions_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomUserActions: pickZoomUserActions(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomUserActions: pickZoomUserActions(lazyMutation[1].data) }];
}

// Types
type InsertZoomUserActionsObjectsMutationResult = FetchResult<
  InsertZoomUserActionsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomUserActionsObjectsMutationResultEx = InsertZoomUserActionsMutationResult &
  ZoomUserActionsObjectsHookResultEx;

type PickInsertZoomUserActionsObjectsFn = (
  mutation: InsertZoomUserActionsMutation | null | undefined
) => ZoomUserActionsFragment[];
type InsertZoomUserActionsObjectsLazyMutationFn = MutationTuple<
  InsertZoomUserActionsMutation,
  InsertZoomUserActionsMutationVariables
>;
type InsertZoomUserActionsObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomUserActionsMutation, InsertZoomUserActionsMutationVariables>
) => Promise<InsertZoomUserActionsObjectsMutationResultEx>;
export type InsertZoomUserActionsObjectsLazyMutationReturn = [
  InsertZoomUserActionsObjectsWrappedLazyMutationFn,
  InsertZoomUserActionsObjectsMutationResultEx
];

// Function
function useInsertZoomUserActionsObjects(
  options?: Omit<MutationHookOptions<InsertZoomUserActionsMutation, InsertZoomUserActionsMutationVariables>, 'mutation'>
): InsertZoomUserActionsObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomUserActionsObjectsLazyMutationFn = useMutation<
    InsertZoomUserActionsMutation,
    InsertZoomUserActionsMutationVariables
  >(InsertZoomUserActionsDocument, options);
  const pickObjects: PickInsertZoomUserActionsObjectsFn = (
    mutation: InsertZoomUserActionsMutation | null | undefined
  ) => {
    return mutation?.insert_zoomUserActions?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomUserActionsObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomUserActionsObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomUserActionsByIdMutationResult = FetchResult<
  UpdateZoomUserActionsByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomUserActionsByIdMutationResultEx = UpdateZoomUserActionsByIdMutationResult &
  ZoomUserActionsByIdHookResultEx;

type PickUpdateZoomUserActionsByIdFn = (
  mutation: UpdateZoomUserActionsByIdMutation | null | undefined
) => ZoomUserActionsFragment | null | undefined;
type UpdateZoomUserActionsByIdLazyMutationFn = MutationTuple<
  UpdateZoomUserActionsByIdMutation,
  UpdateZoomUserActionsByIdMutationVariables
>;
type UpdateZoomUserActionsByIdWrappedLazyMutationFn = ({
  zoomUserActionsId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomUserActionsId: string;
  set: ZoomUserActions_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomUserActionsByIdMutation, UpdateZoomUserActionsByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomUserActionsByIdMutationResultEx>;
export type UpdateZoomUserActionsByIdLazyMutationReturn = [
  UpdateZoomUserActionsByIdWrappedLazyMutationFn,
  UpdateZoomUserActionsByIdMutationResultEx
];

function useUpdateZoomUserActionsById(
  options?: Omit<
    MutationHookOptions<UpdateZoomUserActionsByIdMutation, UpdateZoomUserActionsByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomUserActionsByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomUserActionsByIdLazyMutationFn = useMutation<
    UpdateZoomUserActionsByIdMutation,
    UpdateZoomUserActionsByIdMutationVariables
  >(UpdateZoomUserActionsByIdDocument, options);

  const pickZoomUserActions: PickUpdateZoomUserActionsByIdFn = (mutation) => {
    return mutation?.update_zoomUserActions?.returning && mutation.update_zoomUserActions!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomUserActionsByIdWrappedLazyMutationFn = async ({
    zoomUserActionsId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomUserActionsByIdMutation,
      UpdateZoomUserActionsByIdMutationVariables
    > = { variables: { id: zoomUserActionsId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomUserActionsByIdMutation>({
        operationType: 'update',
        entityName: 'zoomUserActions',
        objects: [{ id: zoomUserActionsId, ...set }],
      });
    }

    const fetchResult: UpdateZoomUserActionsByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomUserActionsId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomUserActions: pickZoomUserActions(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomUserActions: pickZoomUserActions(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomUserActionsObjectsMutationResult = FetchResult<
  UpdateZoomUserActionsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomUserActionsObjectsMutationResultEx = UpdateZoomUserActionsObjectsMutationResult &
  ZoomUserActionsObjectsHookResultEx;

// Function
type PickUpdateZoomUserActionsObjectsFn = (
  mutation: UpdateZoomUserActionsMutation | null | undefined
) => ZoomUserActionsFragment[];
type UpdateZoomUserActionsObjectsLazyMutationFn = MutationTuple<
  UpdateZoomUserActionsMutation,
  UpdateZoomUserActionsMutationVariables
>;
type UpdateZoomUserActionsObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomUserActionsMutation, UpdateZoomUserActionsMutationVariables>
) => Promise<UpdateZoomUserActionsObjectsMutationResultEx>;
export type UpdateZoomUserActionsObjectsLazyMutationReturn = [
  UpdateZoomUserActionsObjectsWrappedLazyMutationFn,
  UpdateZoomUserActionsObjectsMutationResultEx
];

function useUpdateZoomUserActionsObjects(
  options?: Omit<MutationHookOptions<UpdateZoomUserActionsMutation, UpdateZoomUserActionsMutationVariables>, 'mutation'>
): UpdateZoomUserActionsObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomUserActionsObjectsLazyMutationFn = useMutation<
    UpdateZoomUserActionsMutation,
    UpdateZoomUserActionsMutationVariables
  >(UpdateZoomUserActionsDocument, options);

  const pickObjects: PickUpdateZoomUserActionsObjectsFn = (
    mutation: UpdateZoomUserActionsMutation | null | undefined
  ) => {
    return mutation?.update_zoomUserActions?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomUserActionsObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomUserActionsObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomUserActionsModelByIdFetchResult = FetchResult<
  RemoveZoomUserActionsModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomUserActionsModelByIdMutationResultEx = RemoveZoomUserActionsModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomUserActionsModelFn = (mutation: RemoveZoomUserActionsModelByIdMutation | null | undefined) => number;
type RemoveZoomUserActionsModelLazyMutationFn = MutationTuple<
  RemoveZoomUserActionsModelByIdMutation,
  RemoveZoomUserActionsModelByIdMutationVariables
>;
type RemoveZoomUserActionsModelWrappedLazyMutationFn = ({
  zoomUserActionsId,
  autoOptimisticResponse,
  options,
}: {
  zoomUserActionsId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomUserActionsModelByIdMutation, RemoveZoomUserActionsModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomUserActionsModelByIdMutationResultEx>;
export type RemoveZoomUserActionsModelLazyMutationReturn = [
  RemoveZoomUserActionsModelWrappedLazyMutationFn,
  RemoveZoomUserActionsModelByIdMutationResultEx
];

function useRemoveZoomUserActionsModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomUserActionsModelByIdMutation, RemoveZoomUserActionsModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomUserActionsModelLazyMutationFn = useMutation<
    RemoveZoomUserActionsModelByIdMutation,
    RemoveZoomUserActionsModelByIdMutationVariables
  >(RemoveZoomUserActionsModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomUserActionsModelFn = (
    mutation: RemoveZoomUserActionsModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomUserActions?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomUserActionsModelWrappedLazyMutationFn = async ({
    zoomUserActionsId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomUserActionsModelMutation,
      RemoveZoomUserActionsModelByIdMutationVariables
    > = { variables: { id: zoomUserActionsId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomUserActionsModelMutation>({
        operationType: 'delete',
        entityName: 'zoomUserActions',
        objects: [{ id: zoomUserActionsId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomUserActionsModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomUserActions',
        entityId: zoomUserActionsId,
      });
    }

    const fetchResult: RemoveZoomUserActionsModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomUserActionsModelObjectsMutationResult = FetchResult<
  RemoveZoomUserActionsModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomUserActionsModelObjectsMutationResultEx = RemoveZoomUserActionsModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomUserActionsModelObjectsFn = (
  mutation: RemoveZoomUserActionsModelMutation | null | undefined
) => number;
type RemoveZoomUserActionsModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomUserActionsModelMutation,
  RemoveZoomUserActionsModelMutationVariables
>;
type RemoveZoomUserActionsModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomUserActionsModelMutation, RemoveZoomUserActionsModelMutationVariables>
) => Promise<RemoveZoomUserActionsModelObjectsMutationResultEx>;
export type RemoveZoomUserActionsModelObjectsLazyMutationReturn = [
  RemoveZoomUserActionsModelObjectsWrappedLazyMutationFn,
  RemoveZoomUserActionsModelObjectsMutationResultEx
];

function useRemoveZoomUserActionsModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomUserActionsModelMutation, RemoveZoomUserActionsModelMutationVariables>,
    'mutation'
  >
): RemoveZoomUserActionsModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomUserActionsModelMutation, RemoveZoomUserActionsModelMutationVariables>(
    RemoveZoomUserActionsModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomUserActionsModelObjectsFn = (
    mutation: RemoveZoomUserActionsModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomUserActions?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomUserActionsModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomUserActionsModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomUserActions Fragment Helper Object
//------------------------------------------------

export const ZoomUserActionsFragmentGQLHooks = {
  useQueryById: useQueryZoomUserActionsById,
  useQueryByIdLazy: useQueryZoomUserActionsByIdLazy,
  useQueryObjects: useQueryZoomUserActionsObjects,
  useQueryObjectsLazy: useQueryZoomUserActionsObjectsLazy,
  useSubscribeToById: useSubscribeToZoomUserActionsById,
  useSubscribeToObjects: useSubscribeToZoomUserActionsObjects,
  useInsert: useInsertZoomUserActions,
  useInsertWithOnConflict: useInsertZoomUserActionsWithOnConflict,
  useInsertObjects: useInsertZoomUserActionsObjects,
  useUpdateById: useUpdateZoomUserActionsById,
  useUpdateObjects: useUpdateZoomUserActionsObjects,
};

// zoomUserActions MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomUserActionsModelGQLHooks = {
  useRemoveById: useRemoveZoomUserActionsModelById,
  useRemoveObjects: useRemoveZoomUserActionsModelObjects,
};

// zoomGroupMembership REACT
//------------------------------------------------

export type ZoomGroupMembershipByIdHookResultEx = {
  zoomGroupMembership: ZoomGroupMembershipFragment | null | undefined;
};
export type ZoomGroupMembershipObjectsHookResultEx = { objects: ZoomGroupMembershipFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomGroupMembershipByIdResult = LazyQueryResult<
  QueryZoomGroupMembershipByIdQuery,
  QueryZoomGroupMembershipByIdQueryVariables
>;
type QueryZoomGroupMembershipByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomGroupMembershipByIdQuery,
          QueryZoomGroupMembershipByIdQueryVariables,
          QueryZoomGroupMembershipByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomGroupMembershipByIdResultEx = Omit<QueryZoomGroupMembershipByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomGroupMembershipByIdSubScribeToMore;
} & ZoomGroupMembershipByIdHookResultEx;

// Function
function useQueryZoomGroupMembershipById({
  zoomGroupMembershipId,
  options,
}: {
  zoomGroupMembershipId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomGroupMembershipByIdQuery, QueryZoomGroupMembershipByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomGroupMembershipByIdResultEx {
  const _query: QueryZoomGroupMembershipByIdResult = useQuery<
    QueryZoomGroupMembershipByIdQuery,
    QueryZoomGroupMembershipByIdQueryVariables
  >(QueryZoomGroupMembershipByIdDocument, { variables: { zoomGroupMembershipId }, ...options });

  const typedSubscribeToMore: QueryZoomGroupMembershipByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomGroupMembershipByIdDocument,
      variables: { zoomGroupMembershipId } as QueryZoomGroupMembershipByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    zoomGroupMembership: query?.data?.zoomGroupMembership_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomGroupMembershipByIdFn = (
  query: QueryZoomGroupMembershipByIdQuery | null | undefined
) => ZoomGroupMembershipFragment | null | undefined;
type QueryZoomGroupMembershipByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomGroupMembershipByIdQueryVariables> | undefined) => void,
  QueryZoomGroupMembershipByIdResult
];
type QueryZoomGroupMembershipByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomGroupMembershipByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomGroupMembershipByIdLazyReturn = [
  QueryZoomGroupMembershipByIdWrappedLazyFn,
  QueryZoomGroupMembershipByIdResultEx
];

// Function
function useQueryZoomGroupMembershipByIdLazy({
  zoomGroupMembershipId,
  options,
}: {
  zoomGroupMembershipId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomGroupMembershipByIdQuery, QueryZoomGroupMembershipByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomGroupMembershipByIdLazyReturn {
  const lazyQuery: QueryZoomGroupMembershipByIdLazyFn = useLazyQuery<
    QueryZoomGroupMembershipByIdQuery,
    QueryZoomGroupMembershipByIdQueryVariables
  >(QueryZoomGroupMembershipByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomGroupMembershipById: PickQueryZoomGroupMembershipByIdFn = (query) => {
    return query && query.zoomGroupMembership_by_pk;
  };
  const wrappedLazyQuery: QueryZoomGroupMembershipByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomGroupMembershipByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomGroupMembershipByIdDocument,
        variables: { zoomGroupMembershipId } as QueryZoomGroupMembershipByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomGroupMembership: pickQueryZoomGroupMembershipById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomGroupMembershipObjectsResult = LazyQueryResult<
  QueryZoomGroupMembershipObjectsQuery,
  QueryZoomGroupMembershipObjectsQueryVariables
>;
type QueryZoomGroupMembershipObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomGroupMembershipObjectsQuery,
          QueryZoomGroupMembershipObjectsQueryVariables,
          QueryZoomGroupMembershipObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomGroupMembershipObjectsResultEx = Omit<QueryZoomGroupMembershipObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomGroupMembershipObjectsSubScribeToMore;
} & ZoomGroupMembershipObjectsHookResultEx;

// Function
function useQueryZoomGroupMembershipObjects(
  options: Omit<
    QueryHookOptions<QueryZoomGroupMembershipObjectsQuery, QueryZoomGroupMembershipObjectsQueryVariables>,
    'query'
  >
): QueryZoomGroupMembershipObjectsResultEx {
  const _query: QueryZoomGroupMembershipObjectsResult = useQuery<
    QueryZoomGroupMembershipObjectsQuery,
    QueryZoomGroupMembershipObjectsQueryVariables
  >(QueryZoomGroupMembershipObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomGroupMembershipObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomGroupMembershipObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomGroupMembership || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomGroupMembershipObjectsFn = (
  query: QueryZoomGroupMembershipObjectsQuery | null | undefined
) => ZoomGroupMembershipFragment[];
type QueryZoomGroupMembershipObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomGroupMembershipObjectsQueryVariables> | undefined) => void,
  QueryZoomGroupMembershipObjectsResult
];
type QueryZoomGroupMembershipObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomGroupMembershipObjectsQueryVariables>
) => void;
export type QueryZoomGroupMembershipObjectsLazyReturn = [
  QueryZoomGroupMembershipObjectsWrappedLazyFn,
  QueryZoomGroupMembershipObjectsResultEx
];

// Function
function useQueryZoomGroupMembershipObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomGroupMembershipObjectsQuery, QueryZoomGroupMembershipObjectsQueryVariables>,
    'query'
  >
): QueryZoomGroupMembershipObjectsLazyReturn {
  const lazyQuery: QueryZoomGroupMembershipObjectsLazyFn = useLazyQuery<
    QueryZoomGroupMembershipObjectsQuery,
    QueryZoomGroupMembershipObjectsQueryVariables
  >(QueryZoomGroupMembershipObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomGroupMembershipObjectsFn = (
    query: QueryZoomGroupMembershipObjectsQuery | null | undefined
  ) => {
    return query?.zoomGroupMembership || [];
  };
  const wrappedLazyQuery: QueryZoomGroupMembershipObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomGroupMembershipObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomGroupMembershipObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomGroupMembershipByIdResult = {
  variables?: SubscribeToZoomGroupMembershipByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomGroupMembershipByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomGroupMembershipByIdResultEx = SubscribeToZoomGroupMembershipByIdResult &
  ZoomGroupMembershipByIdHookResultEx;

// Function
function useSubscribeToZoomGroupMembershipById({
  zoomGroupMembershipId,
  options,
}: {
  zoomGroupMembershipId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomGroupMembershipByIdSubscription,
      SubscribeToZoomGroupMembershipByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomGroupMembershipByIdResultEx {
  const subscription: SubscribeToZoomGroupMembershipByIdResult = useSubscription<
    SubscribeToZoomGroupMembershipByIdSubscription,
    SubscribeToZoomGroupMembershipByIdSubscriptionVariables
  >(SubscribeToZoomGroupMembershipByIdDocument, { variables: { zoomGroupMembershipId }, ...options });
  return { ...subscription, zoomGroupMembership: subscription?.data?.zoomGroupMembership_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomGroupMembershipObjectsResult = {
  variables?: SubscribeToZoomGroupMembershipObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomGroupMembershipObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomGroupMembershipObjectsResultEx = SubscribeToZoomGroupMembershipObjectsResult &
  ZoomGroupMembershipObjectsHookResultEx;

// Function
function useSubscribeToZoomGroupMembershipObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomGroupMembershipObjectsSubscription,
      SubscribeToZoomGroupMembershipObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomGroupMembershipObjectsResultEx {
  const subscription: SubscribeToZoomGroupMembershipObjectsResult = useSubscription<
    SubscribeToZoomGroupMembershipObjectsSubscription,
    SubscribeToZoomGroupMembershipObjectsSubscriptionVariables
  >(SubscribeToZoomGroupMembershipObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomGroupMembership || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomGroupMembershipMutationResult = FetchResult<
  InsertZoomGroupMembershipMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomGroupMembershipMutationResultEx = InsertZoomGroupMembershipMutationResult &
  ZoomGroupMembershipByIdHookResultEx;

type PickInsertZoomGroupMembershipFn = (
  mutation: InsertZoomGroupMembershipMutation | null | undefined
) => ZoomGroupMembershipFragment | null | undefined;
type InsertZoomGroupMembershipLazyMutationFn = MutationTuple<
  InsertZoomGroupMembershipMutation,
  InsertZoomGroupMembershipMutationVariables
>;
type InsertZoomGroupMembershipWrappedLazyMutationFn = ({
  zoomGroupMembership,
  autoOptimisticResponse,
  options,
}: {
  zoomGroupMembership: ZoomGroupMembership_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomGroupMembershipMutation, InsertZoomGroupMembershipMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomGroupMembershipMutationResultEx>;
export type InsertZoomGroupMembershipLazyMutationReturn = [
  InsertZoomGroupMembershipWrappedLazyMutationFn,
  InsertZoomGroupMembershipMutationResultEx
];

// Function
function useInsertZoomGroupMembership(
  options?: Omit<
    MutationHookOptions<InsertZoomGroupMembershipMutation, InsertZoomGroupMembershipMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomGroupMembershipLazyMutationReturn {
  const lazyMutation: InsertZoomGroupMembershipLazyMutationFn = useMutation<
    InsertZoomGroupMembershipMutation,
    InsertZoomGroupMembershipMutationVariables
  >(InsertZoomGroupMembershipDocument, options);
  const pickZoomGroupMembership: PickInsertZoomGroupMembershipFn = (mutation) => {
    return mutation?.insert_zoomGroupMembership?.returning && mutation?.insert_zoomGroupMembership?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomGroupMembershipWrappedLazyMutationFn = async ({
    zoomGroupMembership,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomGroupMembership });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomGroupMembershipMutation,
      InsertZoomGroupMembershipMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomGroupMembership'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomGroupMembershipMutation>({
        operationType: 'insert',
        entityName: 'zoomGroupMembership',
        objects: [objectForInsert as ZoomGroupMembership_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomGroupMembership: pickZoomGroupMembership(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomGroupMembership: pickZoomGroupMembership(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomGroupMembershipWithOnConflictMutationResult = FetchResult<
  InsertZoomGroupMembershipWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomGroupMembershipWithOnConflictMutationResultEx =
  InsertZoomGroupMembershipWithOnConflictMutationResult & ZoomGroupMembershipByIdHookResultEx;

type InsertZoomGroupMembershipWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomGroupMembershipWithOnConflictMutation,
  InsertZoomGroupMembershipWithOnConflictMutationVariables
>;
type InsertZoomGroupMembershipWithOnConflictWrappedLazyMutationFn = ({
  zoomGroupMembership,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomGroupMembership: ZoomGroupMembership_Insert_Input;
  onConflict: ZoomGroupMembership_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomGroupMembershipWithOnConflictMutation,
      InsertZoomGroupMembershipWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomGroupMembershipWithOnConflictMutationResultEx>;
export type InsertZoomGroupMembershipWithOnConflictLazyMutationReturn = [
  InsertZoomGroupMembershipWithOnConflictWrappedLazyMutationFn,
  InsertZoomGroupMembershipWithOnConflictMutationResultEx
];

// Function
function useInsertZoomGroupMembershipWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomGroupMembershipWithOnConflictMutation,
      InsertZoomGroupMembershipWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomGroupMembershipWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomGroupMembershipWithOnConflictLazyMutationFn = useMutation<
    InsertZoomGroupMembershipWithOnConflictMutation,
    InsertZoomGroupMembershipWithOnConflictMutationVariables
  >(InsertZoomGroupMembershipWithOnConflictDocument, options);
  const pickZoomGroupMembership: PickInsertZoomGroupMembershipFn = (
    mutation: InsertZoomGroupMembershipWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomGroupMembership?.returning && mutation.insert_zoomGroupMembership.returning[0];
  };

  const wrappedLazyMutation: InsertZoomGroupMembershipWithOnConflictWrappedLazyMutationFn = async ({
    zoomGroupMembership,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomGroupMembership });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomGroupMembershipWithOnConflictMutation,
      InsertZoomGroupMembershipWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomGroupMembership'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomGroupMembershipWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomGroupMembership',
          objects: [objectForInsert as ZoomGroupMembership_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomGroupMembership: pickZoomGroupMembership(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomGroupMembership: pickZoomGroupMembership(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomGroupMembershipObjectsMutationResult = FetchResult<
  InsertZoomGroupMembershipMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomGroupMembershipObjectsMutationResultEx = InsertZoomGroupMembershipMutationResult &
  ZoomGroupMembershipObjectsHookResultEx;

type PickInsertZoomGroupMembershipObjectsFn = (
  mutation: InsertZoomGroupMembershipMutation | null | undefined
) => ZoomGroupMembershipFragment[];
type InsertZoomGroupMembershipObjectsLazyMutationFn = MutationTuple<
  InsertZoomGroupMembershipMutation,
  InsertZoomGroupMembershipMutationVariables
>;
type InsertZoomGroupMembershipObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomGroupMembershipMutation, InsertZoomGroupMembershipMutationVariables>
) => Promise<InsertZoomGroupMembershipObjectsMutationResultEx>;
export type InsertZoomGroupMembershipObjectsLazyMutationReturn = [
  InsertZoomGroupMembershipObjectsWrappedLazyMutationFn,
  InsertZoomGroupMembershipObjectsMutationResultEx
];

// Function
function useInsertZoomGroupMembershipObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomGroupMembershipMutation, InsertZoomGroupMembershipMutationVariables>,
    'mutation'
  >
): InsertZoomGroupMembershipObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomGroupMembershipObjectsLazyMutationFn = useMutation<
    InsertZoomGroupMembershipMutation,
    InsertZoomGroupMembershipMutationVariables
  >(InsertZoomGroupMembershipDocument, options);
  const pickObjects: PickInsertZoomGroupMembershipObjectsFn = (
    mutation: InsertZoomGroupMembershipMutation | null | undefined
  ) => {
    return mutation?.insert_zoomGroupMembership?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomGroupMembershipObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomGroupMembershipObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomGroupMembershipByIdMutationResult = FetchResult<
  UpdateZoomGroupMembershipByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomGroupMembershipByIdMutationResultEx = UpdateZoomGroupMembershipByIdMutationResult &
  ZoomGroupMembershipByIdHookResultEx;

type PickUpdateZoomGroupMembershipByIdFn = (
  mutation: UpdateZoomGroupMembershipByIdMutation | null | undefined
) => ZoomGroupMembershipFragment | null | undefined;
type UpdateZoomGroupMembershipByIdLazyMutationFn = MutationTuple<
  UpdateZoomGroupMembershipByIdMutation,
  UpdateZoomGroupMembershipByIdMutationVariables
>;
type UpdateZoomGroupMembershipByIdWrappedLazyMutationFn = ({
  zoomGroupMembershipId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomGroupMembershipId: string;
  set: ZoomGroupMembership_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomGroupMembershipByIdMutation, UpdateZoomGroupMembershipByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomGroupMembershipByIdMutationResultEx>;
export type UpdateZoomGroupMembershipByIdLazyMutationReturn = [
  UpdateZoomGroupMembershipByIdWrappedLazyMutationFn,
  UpdateZoomGroupMembershipByIdMutationResultEx
];

function useUpdateZoomGroupMembershipById(
  options?: Omit<
    MutationHookOptions<UpdateZoomGroupMembershipByIdMutation, UpdateZoomGroupMembershipByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomGroupMembershipByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomGroupMembershipByIdLazyMutationFn = useMutation<
    UpdateZoomGroupMembershipByIdMutation,
    UpdateZoomGroupMembershipByIdMutationVariables
  >(UpdateZoomGroupMembershipByIdDocument, options);

  const pickZoomGroupMembership: PickUpdateZoomGroupMembershipByIdFn = (mutation) => {
    return mutation?.update_zoomGroupMembership?.returning && mutation.update_zoomGroupMembership!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomGroupMembershipByIdWrappedLazyMutationFn = async ({
    zoomGroupMembershipId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomGroupMembershipByIdMutation,
      UpdateZoomGroupMembershipByIdMutationVariables
    > = { variables: { id: zoomGroupMembershipId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomGroupMembershipByIdMutation>(
        { operationType: 'update', entityName: 'zoomGroupMembership', objects: [{ id: zoomGroupMembershipId, ...set }] }
      );
    }

    const fetchResult: UpdateZoomGroupMembershipByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomGroupMembershipId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomGroupMembership: pickZoomGroupMembership(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomGroupMembership: pickZoomGroupMembership(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomGroupMembershipObjectsMutationResult = FetchResult<
  UpdateZoomGroupMembershipMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomGroupMembershipObjectsMutationResultEx = UpdateZoomGroupMembershipObjectsMutationResult &
  ZoomGroupMembershipObjectsHookResultEx;

// Function
type PickUpdateZoomGroupMembershipObjectsFn = (
  mutation: UpdateZoomGroupMembershipMutation | null | undefined
) => ZoomGroupMembershipFragment[];
type UpdateZoomGroupMembershipObjectsLazyMutationFn = MutationTuple<
  UpdateZoomGroupMembershipMutation,
  UpdateZoomGroupMembershipMutationVariables
>;
type UpdateZoomGroupMembershipObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomGroupMembershipMutation, UpdateZoomGroupMembershipMutationVariables>
) => Promise<UpdateZoomGroupMembershipObjectsMutationResultEx>;
export type UpdateZoomGroupMembershipObjectsLazyMutationReturn = [
  UpdateZoomGroupMembershipObjectsWrappedLazyMutationFn,
  UpdateZoomGroupMembershipObjectsMutationResultEx
];

function useUpdateZoomGroupMembershipObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomGroupMembershipMutation, UpdateZoomGroupMembershipMutationVariables>,
    'mutation'
  >
): UpdateZoomGroupMembershipObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomGroupMembershipObjectsLazyMutationFn = useMutation<
    UpdateZoomGroupMembershipMutation,
    UpdateZoomGroupMembershipMutationVariables
  >(UpdateZoomGroupMembershipDocument, options);

  const pickObjects: PickUpdateZoomGroupMembershipObjectsFn = (
    mutation: UpdateZoomGroupMembershipMutation | null | undefined
  ) => {
    return mutation?.update_zoomGroupMembership?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomGroupMembershipObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomGroupMembershipObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomGroupMembershipModelByIdFetchResult = FetchResult<
  RemoveZoomGroupMembershipModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomGroupMembershipModelByIdMutationResultEx = RemoveZoomGroupMembershipModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomGroupMembershipModelFn = (
  mutation: RemoveZoomGroupMembershipModelByIdMutation | null | undefined
) => number;
type RemoveZoomGroupMembershipModelLazyMutationFn = MutationTuple<
  RemoveZoomGroupMembershipModelByIdMutation,
  RemoveZoomGroupMembershipModelByIdMutationVariables
>;
type RemoveZoomGroupMembershipModelWrappedLazyMutationFn = ({
  zoomGroupMembershipId,
  autoOptimisticResponse,
  options,
}: {
  zoomGroupMembershipId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveZoomGroupMembershipModelByIdMutation,
      RemoveZoomGroupMembershipModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveZoomGroupMembershipModelByIdMutationResultEx>;
export type RemoveZoomGroupMembershipModelLazyMutationReturn = [
  RemoveZoomGroupMembershipModelWrappedLazyMutationFn,
  RemoveZoomGroupMembershipModelByIdMutationResultEx
];

function useRemoveZoomGroupMembershipModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomGroupMembershipModelByIdMutation,
      RemoveZoomGroupMembershipModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomGroupMembershipModelLazyMutationFn = useMutation<
    RemoveZoomGroupMembershipModelByIdMutation,
    RemoveZoomGroupMembershipModelByIdMutationVariables
  >(RemoveZoomGroupMembershipModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomGroupMembershipModelFn = (
    mutation: RemoveZoomGroupMembershipModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomGroupMembership?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomGroupMembershipModelWrappedLazyMutationFn = async ({
    zoomGroupMembershipId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomGroupMembershipModelMutation,
      RemoveZoomGroupMembershipModelByIdMutationVariables
    > = { variables: { id: zoomGroupMembershipId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<RemoveZoomGroupMembershipModelMutation>({
          operationType: 'delete',
          entityName: 'zoomGroupMembership',
          objects: [{ id: zoomGroupMembershipId }],
        });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomGroupMembershipModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomGroupMembership',
        entityId: zoomGroupMembershipId,
      });
    }

    const fetchResult: RemoveZoomGroupMembershipModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomGroupMembershipModelObjectsMutationResult = FetchResult<
  RemoveZoomGroupMembershipModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomGroupMembershipModelObjectsMutationResultEx =
  RemoveZoomGroupMembershipModelObjectsMutationResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomGroupMembershipModelObjectsFn = (
  mutation: RemoveZoomGroupMembershipModelMutation | null | undefined
) => number;
type RemoveZoomGroupMembershipModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomGroupMembershipModelMutation,
  RemoveZoomGroupMembershipModelMutationVariables
>;
type RemoveZoomGroupMembershipModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveZoomGroupMembershipModelMutation,
    RemoveZoomGroupMembershipModelMutationVariables
  >
) => Promise<RemoveZoomGroupMembershipModelObjectsMutationResultEx>;
export type RemoveZoomGroupMembershipModelObjectsLazyMutationReturn = [
  RemoveZoomGroupMembershipModelObjectsWrappedLazyMutationFn,
  RemoveZoomGroupMembershipModelObjectsMutationResultEx
];

function useRemoveZoomGroupMembershipModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomGroupMembershipModelMutation, RemoveZoomGroupMembershipModelMutationVariables>,
    'mutation'
  >
): RemoveZoomGroupMembershipModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveZoomGroupMembershipModelMutation,
    RemoveZoomGroupMembershipModelMutationVariables
  >(RemoveZoomGroupMembershipModelDocument, options);

  const pickAffectedRows: PickRemoveZoomGroupMembershipModelObjectsFn = (
    mutation: RemoveZoomGroupMembershipModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomGroupMembership?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomGroupMembershipModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomGroupMembershipModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomGroupMembership Fragment Helper Object
//------------------------------------------------

export const ZoomGroupMembershipFragmentGQLHooks = {
  useQueryById: useQueryZoomGroupMembershipById,
  useQueryByIdLazy: useQueryZoomGroupMembershipByIdLazy,
  useQueryObjects: useQueryZoomGroupMembershipObjects,
  useQueryObjectsLazy: useQueryZoomGroupMembershipObjectsLazy,
  useSubscribeToById: useSubscribeToZoomGroupMembershipById,
  useSubscribeToObjects: useSubscribeToZoomGroupMembershipObjects,
  useInsert: useInsertZoomGroupMembership,
  useInsertWithOnConflict: useInsertZoomGroupMembershipWithOnConflict,
  useInsertObjects: useInsertZoomGroupMembershipObjects,
  useUpdateById: useUpdateZoomGroupMembershipById,
  useUpdateObjects: useUpdateZoomGroupMembershipObjects,
};

// zoomGroupMembership MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomGroupMembershipModelGQLHooks = {
  useRemoveById: useRemoveZoomGroupMembershipModelById,
  useRemoveObjects: useRemoveZoomGroupMembershipModelObjects,
};

// zoomUser REACT
//------------------------------------------------

export type ZoomUserByIdHookResultEx = { zoomUser: ZoomUserFragment | null | undefined };
export type ZoomUserObjectsHookResultEx = { objects: ZoomUserFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomUserByIdResult = LazyQueryResult<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>;
type QueryZoomUserByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables, QueryZoomUserByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomUserByIdResultEx = Omit<QueryZoomUserByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomUserByIdSubScribeToMore;
} & ZoomUserByIdHookResultEx;

// Function
function useQueryZoomUserById({
  zoomUserId,
  options,
}: {
  zoomUserId: string;
  options?: Omit<QueryHookOptions<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>, 'query' | 'variables'>;
}): QueryZoomUserByIdResultEx {
  const _query: QueryZoomUserByIdResult = useQuery<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>(
    QueryZoomUserByIdDocument,
    { variables: { zoomUserId }, ...options }
  );

  const typedSubscribeToMore: QueryZoomUserByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomUserByIdDocument,
      variables: { zoomUserId } as QueryZoomUserByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomUser: query?.data?.zoomUser_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomUserByIdFn = (
  query: QueryZoomUserByIdQuery | null | undefined
) => ZoomUserFragment | null | undefined;
type QueryZoomUserByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomUserByIdQueryVariables> | undefined) => void,
  QueryZoomUserByIdResult
];
type QueryZoomUserByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomUserByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomUserByIdLazyReturn = [QueryZoomUserByIdWrappedLazyFn, QueryZoomUserByIdResultEx];

// Function
function useQueryZoomUserByIdLazy({
  zoomUserId,
  options,
}: {
  zoomUserId: string;
  options?: Omit<QueryHookOptions<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>, 'query' | 'variables'>;
}): QueryZoomUserByIdLazyReturn {
  const lazyQuery: QueryZoomUserByIdLazyFn = useLazyQuery<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>(
    QueryZoomUserByIdDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickQueryZoomUserById: PickQueryZoomUserByIdFn = (query) => {
    return query && query.zoomUser_by_pk;
  };
  const wrappedLazyQuery: QueryZoomUserByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomUserByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomUserByIdDocument,
        variables: { zoomUserId } as QueryZoomUserByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, zoomUser: pickQueryZoomUserById(lazyQuery[1].data) },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomUserObjectsResult = LazyQueryResult<QueryZoomUserObjectsQuery, QueryZoomUserObjectsQueryVariables>;
type QueryZoomUserObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomUserObjectsQuery,
          QueryZoomUserObjectsQueryVariables,
          QueryZoomUserObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomUserObjectsResultEx = Omit<QueryZoomUserObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomUserObjectsSubScribeToMore;
} & ZoomUserObjectsHookResultEx;

// Function
function useQueryZoomUserObjects(
  options: Omit<QueryHookOptions<QueryZoomUserObjectsQuery, QueryZoomUserObjectsQueryVariables>, 'query'>
): QueryZoomUserObjectsResultEx {
  const _query: QueryZoomUserObjectsResult = useQuery<QueryZoomUserObjectsQuery, QueryZoomUserObjectsQueryVariables>(
    QueryZoomUserObjectsDocument,
    options
  );

  const typedSubscribeToMore: QueryZoomUserObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomUserObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomUser || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomUserObjectsFn = (query: QueryZoomUserObjectsQuery | null | undefined) => ZoomUserFragment[];
type QueryZoomUserObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomUserObjectsQueryVariables> | undefined) => void,
  QueryZoomUserObjectsResult
];
type QueryZoomUserObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryZoomUserObjectsQueryVariables>) => void;
export type QueryZoomUserObjectsLazyReturn = [QueryZoomUserObjectsWrappedLazyFn, QueryZoomUserObjectsResultEx];

// Function
function useQueryZoomUserObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryZoomUserObjectsQuery, QueryZoomUserObjectsQueryVariables>, 'query'>
): QueryZoomUserObjectsLazyReturn {
  const lazyQuery: QueryZoomUserObjectsLazyFn = useLazyQuery<
    QueryZoomUserObjectsQuery,
    QueryZoomUserObjectsQueryVariables
  >(QueryZoomUserObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomUserObjectsFn = (query: QueryZoomUserObjectsQuery | null | undefined) => {
    return query?.zoomUser || [];
  };
  const wrappedLazyQuery: QueryZoomUserObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomUserObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomUserObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomUserByIdResult = {
  variables?: SubscribeToZoomUserByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomUserByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomUserByIdResultEx = SubscribeToZoomUserByIdResult & ZoomUserByIdHookResultEx;

// Function
function useSubscribeToZoomUserById({
  zoomUserId,
  options,
}: {
  zoomUserId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToZoomUserByIdSubscription, SubscribeToZoomUserByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToZoomUserByIdResultEx {
  const subscription: SubscribeToZoomUserByIdResult = useSubscription<
    SubscribeToZoomUserByIdSubscription,
    SubscribeToZoomUserByIdSubscriptionVariables
  >(SubscribeToZoomUserByIdDocument, { variables: { zoomUserId }, ...options });
  return { ...subscription, zoomUser: subscription?.data?.zoomUser_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomUserObjectsResult = {
  variables?: SubscribeToZoomUserObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomUserObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomUserObjectsResultEx = SubscribeToZoomUserObjectsResult & ZoomUserObjectsHookResultEx;

// Function
function useSubscribeToZoomUserObjects(
  options: Omit<
    SubscriptionHookOptions<SubscribeToZoomUserObjectsSubscription, SubscribeToZoomUserObjectsSubscriptionVariables>,
    'query'
  >
): SubscribeToZoomUserObjectsResultEx {
  const subscription: SubscribeToZoomUserObjectsResult = useSubscription<
    SubscribeToZoomUserObjectsSubscription,
    SubscribeToZoomUserObjectsSubscriptionVariables
  >(SubscribeToZoomUserObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomUser || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomUserMutationResult = FetchResult<InsertZoomUserMutation, Record<string, any>, Record<string, any>>;
export type InsertZoomUserMutationResultEx = InsertZoomUserMutationResult & ZoomUserByIdHookResultEx;

type PickInsertZoomUserFn = (
  mutation: InsertZoomUserMutation | null | undefined
) => ZoomUserFragment | null | undefined;
type InsertZoomUserLazyMutationFn = MutationTuple<InsertZoomUserMutation, InsertZoomUserMutationVariables>;
type InsertZoomUserWrappedLazyMutationFn = ({
  zoomUser,
  autoOptimisticResponse,
  options,
}: {
  zoomUser: ZoomUser_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertZoomUserMutation, InsertZoomUserMutationVariables>, 'variables'>;
}) => Promise<InsertZoomUserMutationResultEx>;
export type InsertZoomUserLazyMutationReturn = [InsertZoomUserWrappedLazyMutationFn, InsertZoomUserMutationResultEx];

// Function
function useInsertZoomUser(
  options?: Omit<MutationHookOptions<InsertZoomUserMutation, InsertZoomUserMutationVariables>, 'mutation' | 'variables'>
): InsertZoomUserLazyMutationReturn {
  const lazyMutation: InsertZoomUserLazyMutationFn = useMutation<
    InsertZoomUserMutation,
    InsertZoomUserMutationVariables
  >(InsertZoomUserDocument, options);
  const pickZoomUser: PickInsertZoomUserFn = (mutation) => {
    return mutation?.insert_zoomUser?.returning && mutation?.insert_zoomUser?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomUserWrappedLazyMutationFn = async ({
    zoomUser,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomUser });
    const mutationOptions: MutationFunctionOptions<InsertZoomUserMutation, InsertZoomUserMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomUser'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomUserMutation>({
        operationType: 'insert',
        entityName: 'zoomUser',
        objects: [objectForInsert as ZoomUser_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomUser: pickZoomUser(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomUser: pickZoomUser(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomUserWithOnConflictMutationResult = FetchResult<
  InsertZoomUserWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomUserWithOnConflictMutationResultEx = InsertZoomUserWithOnConflictMutationResult &
  ZoomUserByIdHookResultEx;

type InsertZoomUserWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomUserWithOnConflictMutation,
  InsertZoomUserWithOnConflictMutationVariables
>;
type InsertZoomUserWithOnConflictWrappedLazyMutationFn = ({
  zoomUser,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomUser: ZoomUser_Insert_Input;
  onConflict: ZoomUser_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomUserWithOnConflictMutation, InsertZoomUserWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomUserWithOnConflictMutationResultEx>;
export type InsertZoomUserWithOnConflictLazyMutationReturn = [
  InsertZoomUserWithOnConflictWrappedLazyMutationFn,
  InsertZoomUserWithOnConflictMutationResultEx
];

// Function
function useInsertZoomUserWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertZoomUserWithOnConflictMutation, InsertZoomUserWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomUserWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomUserWithOnConflictLazyMutationFn = useMutation<
    InsertZoomUserWithOnConflictMutation,
    InsertZoomUserWithOnConflictMutationVariables
  >(InsertZoomUserWithOnConflictDocument, options);
  const pickZoomUser: PickInsertZoomUserFn = (mutation: InsertZoomUserWithOnConflictMutation | null | undefined) => {
    return mutation?.insert_zoomUser?.returning && mutation.insert_zoomUser.returning[0];
  };

  const wrappedLazyMutation: InsertZoomUserWithOnConflictWrappedLazyMutationFn = async ({
    zoomUser,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomUser });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomUserWithOnConflictMutation,
      InsertZoomUserWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomUser'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomUserWithOnConflictMutation>({
        operationType: 'insert',
        entityName: 'zoomUser',
        objects: [objectForInsert as ZoomUser_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomUser: pickZoomUser(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomUser: pickZoomUser(lazyMutation[1].data) }];
}

// Types
type InsertZoomUserObjectsMutationResult = FetchResult<
  InsertZoomUserMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomUserObjectsMutationResultEx = InsertZoomUserMutationResult & ZoomUserObjectsHookResultEx;

type PickInsertZoomUserObjectsFn = (mutation: InsertZoomUserMutation | null | undefined) => ZoomUserFragment[];
type InsertZoomUserObjectsLazyMutationFn = MutationTuple<InsertZoomUserMutation, InsertZoomUserMutationVariables>;
type InsertZoomUserObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomUserMutation, InsertZoomUserMutationVariables>
) => Promise<InsertZoomUserObjectsMutationResultEx>;
export type InsertZoomUserObjectsLazyMutationReturn = [
  InsertZoomUserObjectsWrappedLazyMutationFn,
  InsertZoomUserObjectsMutationResultEx
];

// Function
function useInsertZoomUserObjects(
  options?: Omit<MutationHookOptions<InsertZoomUserMutation, InsertZoomUserMutationVariables>, 'mutation'>
): InsertZoomUserObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomUserObjectsLazyMutationFn = useMutation<
    InsertZoomUserMutation,
    InsertZoomUserMutationVariables
  >(InsertZoomUserDocument, options);
  const pickObjects: PickInsertZoomUserObjectsFn = (mutation: InsertZoomUserMutation | null | undefined) => {
    return mutation?.insert_zoomUser?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomUserObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomUserObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomUserByIdMutationResult = FetchResult<
  UpdateZoomUserByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomUserByIdMutationResultEx = UpdateZoomUserByIdMutationResult & ZoomUserByIdHookResultEx;

type PickUpdateZoomUserByIdFn = (
  mutation: UpdateZoomUserByIdMutation | null | undefined
) => ZoomUserFragment | null | undefined;
type UpdateZoomUserByIdLazyMutationFn = MutationTuple<UpdateZoomUserByIdMutation, UpdateZoomUserByIdMutationVariables>;
type UpdateZoomUserByIdWrappedLazyMutationFn = ({
  zoomUserId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomUserId: string;
  set: ZoomUser_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<UpdateZoomUserByIdMutation, UpdateZoomUserByIdMutationVariables>, 'variables'>;
}) => Promise<UpdateZoomUserByIdMutationResultEx>;
export type UpdateZoomUserByIdLazyMutationReturn = [
  UpdateZoomUserByIdWrappedLazyMutationFn,
  UpdateZoomUserByIdMutationResultEx
];

function useUpdateZoomUserById(
  options?: Omit<
    MutationHookOptions<UpdateZoomUserByIdMutation, UpdateZoomUserByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomUserByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomUserByIdLazyMutationFn = useMutation<
    UpdateZoomUserByIdMutation,
    UpdateZoomUserByIdMutationVariables
  >(UpdateZoomUserByIdDocument, options);

  const pickZoomUser: PickUpdateZoomUserByIdFn = (mutation) => {
    return mutation?.update_zoomUser?.returning && mutation.update_zoomUser!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomUserByIdWrappedLazyMutationFn = async ({
    zoomUserId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<UpdateZoomUserByIdMutation, UpdateZoomUserByIdMutationVariables> = {
      variables: { id: zoomUserId, set },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomUserByIdMutation>({
        operationType: 'update',
        entityName: 'zoomUser',
        objects: [{ id: zoomUserId, ...set }],
      });
    }

    const fetchResult: UpdateZoomUserByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomUserId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomUser: pickZoomUser(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomUser: pickZoomUser(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomUserObjectsMutationResult = FetchResult<
  UpdateZoomUserMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomUserObjectsMutationResultEx = UpdateZoomUserObjectsMutationResult & ZoomUserObjectsHookResultEx;

// Function
type PickUpdateZoomUserObjectsFn = (mutation: UpdateZoomUserMutation | null | undefined) => ZoomUserFragment[];
type UpdateZoomUserObjectsLazyMutationFn = MutationTuple<UpdateZoomUserMutation, UpdateZoomUserMutationVariables>;
type UpdateZoomUserObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomUserMutation, UpdateZoomUserMutationVariables>
) => Promise<UpdateZoomUserObjectsMutationResultEx>;
export type UpdateZoomUserObjectsLazyMutationReturn = [
  UpdateZoomUserObjectsWrappedLazyMutationFn,
  UpdateZoomUserObjectsMutationResultEx
];

function useUpdateZoomUserObjects(
  options?: Omit<MutationHookOptions<UpdateZoomUserMutation, UpdateZoomUserMutationVariables>, 'mutation'>
): UpdateZoomUserObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomUserObjectsLazyMutationFn = useMutation<
    UpdateZoomUserMutation,
    UpdateZoomUserMutationVariables
  >(UpdateZoomUserDocument, options);

  const pickObjects: PickUpdateZoomUserObjectsFn = (mutation: UpdateZoomUserMutation | null | undefined) => {
    return mutation?.update_zoomUser?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomUserObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomUserObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomUserModelByIdFetchResult = FetchResult<
  RemoveZoomUserModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomUserModelByIdMutationResultEx = RemoveZoomUserModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomUserModelFn = (mutation: RemoveZoomUserModelByIdMutation | null | undefined) => number;
type RemoveZoomUserModelLazyMutationFn = MutationTuple<
  RemoveZoomUserModelByIdMutation,
  RemoveZoomUserModelByIdMutationVariables
>;
type RemoveZoomUserModelWrappedLazyMutationFn = ({
  zoomUserId,
  autoOptimisticResponse,
  options,
}: {
  zoomUserId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomUserModelByIdMutation, RemoveZoomUserModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomUserModelByIdMutationResultEx>;
export type RemoveZoomUserModelLazyMutationReturn = [
  RemoveZoomUserModelWrappedLazyMutationFn,
  RemoveZoomUserModelByIdMutationResultEx
];

function useRemoveZoomUserModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomUserModelByIdMutation, RemoveZoomUserModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomUserModelLazyMutationFn = useMutation<
    RemoveZoomUserModelByIdMutation,
    RemoveZoomUserModelByIdMutationVariables
  >(RemoveZoomUserModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomUserModelFn = (
    mutation: RemoveZoomUserModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomUser?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomUserModelWrappedLazyMutationFn = async ({
    zoomUserId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomUserModelMutation,
      RemoveZoomUserModelByIdMutationVariables
    > = { variables: { id: zoomUserId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomUserModelMutation>({
        operationType: 'delete',
        entityName: 'zoomUser',
        objects: [{ id: zoomUserId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomUserModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomUser',
        entityId: zoomUserId,
      });
    }

    const fetchResult: RemoveZoomUserModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomUserModelObjectsMutationResult = FetchResult<
  RemoveZoomUserModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomUserModelObjectsMutationResultEx = RemoveZoomUserModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomUserModelObjectsFn = (mutation: RemoveZoomUserModelMutation | null | undefined) => number;
type RemoveZoomUserModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomUserModelMutation,
  RemoveZoomUserModelMutationVariables
>;
type RemoveZoomUserModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomUserModelMutation, RemoveZoomUserModelMutationVariables>
) => Promise<RemoveZoomUserModelObjectsMutationResultEx>;
export type RemoveZoomUserModelObjectsLazyMutationReturn = [
  RemoveZoomUserModelObjectsWrappedLazyMutationFn,
  RemoveZoomUserModelObjectsMutationResultEx
];

function useRemoveZoomUserModelObjects(
  options?: Omit<MutationHookOptions<RemoveZoomUserModelMutation, RemoveZoomUserModelMutationVariables>, 'mutation'>
): RemoveZoomUserModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomUserModelMutation, RemoveZoomUserModelMutationVariables>(
    RemoveZoomUserModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomUserModelObjectsFn = (
    mutation: RemoveZoomUserModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomUser?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomUserModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomUserModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomUser Fragment Helper Object
//------------------------------------------------

export const ZoomUserFragmentGQLHooks = {
  useQueryById: useQueryZoomUserById,
  useQueryByIdLazy: useQueryZoomUserByIdLazy,
  useQueryObjects: useQueryZoomUserObjects,
  useQueryObjectsLazy: useQueryZoomUserObjectsLazy,
  useSubscribeToById: useSubscribeToZoomUserById,
  useSubscribeToObjects: useSubscribeToZoomUserObjects,
  useInsert: useInsertZoomUser,
  useInsertWithOnConflict: useInsertZoomUserWithOnConflict,
  useInsertObjects: useInsertZoomUserObjects,
  useUpdateById: useUpdateZoomUserById,
  useUpdateObjects: useUpdateZoomUserObjects,
};

// zoomUser MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomUserModelGQLHooks = {
  useRemoveById: useRemoveZoomUserModelById,
  useRemoveObjects: useRemoveZoomUserModelObjects,
};

// zoomContactNotes REACT
//------------------------------------------------

export type ZoomContactNotesByIdHookResultEx = { zoomContactNotes: ZoomContactNotesFragment | null | undefined };
export type ZoomContactNotesObjectsHookResultEx = { objects: ZoomContactNotesFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomContactNotesByIdResult = LazyQueryResult<
  QueryZoomContactNotesByIdQuery,
  QueryZoomContactNotesByIdQueryVariables
>;
type QueryZoomContactNotesByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomContactNotesByIdQuery,
          QueryZoomContactNotesByIdQueryVariables,
          QueryZoomContactNotesByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomContactNotesByIdResultEx = Omit<QueryZoomContactNotesByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomContactNotesByIdSubScribeToMore;
} & ZoomContactNotesByIdHookResultEx;

// Function
function useQueryZoomContactNotesById({
  zoomContactNotesId,
  options,
}: {
  zoomContactNotesId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomContactNotesByIdQuery, QueryZoomContactNotesByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomContactNotesByIdResultEx {
  const _query: QueryZoomContactNotesByIdResult = useQuery<
    QueryZoomContactNotesByIdQuery,
    QueryZoomContactNotesByIdQueryVariables
  >(QueryZoomContactNotesByIdDocument, { variables: { zoomContactNotesId }, ...options });

  const typedSubscribeToMore: QueryZoomContactNotesByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomContactNotesByIdDocument,
      variables: { zoomContactNotesId } as QueryZoomContactNotesByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomContactNotes: query?.data?.zoomContactNotes_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomContactNotesByIdFn = (
  query: QueryZoomContactNotesByIdQuery | null | undefined
) => ZoomContactNotesFragment | null | undefined;
type QueryZoomContactNotesByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomContactNotesByIdQueryVariables> | undefined) => void,
  QueryZoomContactNotesByIdResult
];
type QueryZoomContactNotesByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomContactNotesByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomContactNotesByIdLazyReturn = [
  QueryZoomContactNotesByIdWrappedLazyFn,
  QueryZoomContactNotesByIdResultEx
];

// Function
function useQueryZoomContactNotesByIdLazy({
  zoomContactNotesId,
  options,
}: {
  zoomContactNotesId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomContactNotesByIdQuery, QueryZoomContactNotesByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomContactNotesByIdLazyReturn {
  const lazyQuery: QueryZoomContactNotesByIdLazyFn = useLazyQuery<
    QueryZoomContactNotesByIdQuery,
    QueryZoomContactNotesByIdQueryVariables
  >(QueryZoomContactNotesByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomContactNotesById: PickQueryZoomContactNotesByIdFn = (query) => {
    return query && query.zoomContactNotes_by_pk;
  };
  const wrappedLazyQuery: QueryZoomContactNotesByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomContactNotesByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomContactNotesByIdDocument,
        variables: { zoomContactNotesId } as QueryZoomContactNotesByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomContactNotes: pickQueryZoomContactNotesById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomContactNotesObjectsResult = LazyQueryResult<
  QueryZoomContactNotesObjectsQuery,
  QueryZoomContactNotesObjectsQueryVariables
>;
type QueryZoomContactNotesObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomContactNotesObjectsQuery,
          QueryZoomContactNotesObjectsQueryVariables,
          QueryZoomContactNotesObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomContactNotesObjectsResultEx = Omit<QueryZoomContactNotesObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomContactNotesObjectsSubScribeToMore;
} & ZoomContactNotesObjectsHookResultEx;

// Function
function useQueryZoomContactNotesObjects(
  options: Omit<
    QueryHookOptions<QueryZoomContactNotesObjectsQuery, QueryZoomContactNotesObjectsQueryVariables>,
    'query'
  >
): QueryZoomContactNotesObjectsResultEx {
  const _query: QueryZoomContactNotesObjectsResult = useQuery<
    QueryZoomContactNotesObjectsQuery,
    QueryZoomContactNotesObjectsQueryVariables
  >(QueryZoomContactNotesObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomContactNotesObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomContactNotesObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomContactNotes || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomContactNotesObjectsFn = (
  query: QueryZoomContactNotesObjectsQuery | null | undefined
) => ZoomContactNotesFragment[];
type QueryZoomContactNotesObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomContactNotesObjectsQueryVariables> | undefined) => void,
  QueryZoomContactNotesObjectsResult
];
type QueryZoomContactNotesObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomContactNotesObjectsQueryVariables>
) => void;
export type QueryZoomContactNotesObjectsLazyReturn = [
  QueryZoomContactNotesObjectsWrappedLazyFn,
  QueryZoomContactNotesObjectsResultEx
];

// Function
function useQueryZoomContactNotesObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomContactNotesObjectsQuery, QueryZoomContactNotesObjectsQueryVariables>,
    'query'
  >
): QueryZoomContactNotesObjectsLazyReturn {
  const lazyQuery: QueryZoomContactNotesObjectsLazyFn = useLazyQuery<
    QueryZoomContactNotesObjectsQuery,
    QueryZoomContactNotesObjectsQueryVariables
  >(QueryZoomContactNotesObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomContactNotesObjectsFn = (
    query: QueryZoomContactNotesObjectsQuery | null | undefined
  ) => {
    return query?.zoomContactNotes || [];
  };
  const wrappedLazyQuery: QueryZoomContactNotesObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomContactNotesObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomContactNotesObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomContactNotesByIdResult = {
  variables?: SubscribeToZoomContactNotesByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomContactNotesByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomContactNotesByIdResultEx = SubscribeToZoomContactNotesByIdResult &
  ZoomContactNotesByIdHookResultEx;

// Function
function useSubscribeToZoomContactNotesById({
  zoomContactNotesId,
  options,
}: {
  zoomContactNotesId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomContactNotesByIdSubscription,
      SubscribeToZoomContactNotesByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomContactNotesByIdResultEx {
  const subscription: SubscribeToZoomContactNotesByIdResult = useSubscription<
    SubscribeToZoomContactNotesByIdSubscription,
    SubscribeToZoomContactNotesByIdSubscriptionVariables
  >(SubscribeToZoomContactNotesByIdDocument, { variables: { zoomContactNotesId }, ...options });
  return { ...subscription, zoomContactNotes: subscription?.data?.zoomContactNotes_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomContactNotesObjectsResult = {
  variables?: SubscribeToZoomContactNotesObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomContactNotesObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomContactNotesObjectsResultEx = SubscribeToZoomContactNotesObjectsResult &
  ZoomContactNotesObjectsHookResultEx;

// Function
function useSubscribeToZoomContactNotesObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomContactNotesObjectsSubscription,
      SubscribeToZoomContactNotesObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomContactNotesObjectsResultEx {
  const subscription: SubscribeToZoomContactNotesObjectsResult = useSubscription<
    SubscribeToZoomContactNotesObjectsSubscription,
    SubscribeToZoomContactNotesObjectsSubscriptionVariables
  >(SubscribeToZoomContactNotesObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomContactNotes || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomContactNotesMutationResult = FetchResult<
  InsertZoomContactNotesMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomContactNotesMutationResultEx = InsertZoomContactNotesMutationResult &
  ZoomContactNotesByIdHookResultEx;

type PickInsertZoomContactNotesFn = (
  mutation: InsertZoomContactNotesMutation | null | undefined
) => ZoomContactNotesFragment | null | undefined;
type InsertZoomContactNotesLazyMutationFn = MutationTuple<
  InsertZoomContactNotesMutation,
  InsertZoomContactNotesMutationVariables
>;
type InsertZoomContactNotesWrappedLazyMutationFn = ({
  zoomContactNotes,
  autoOptimisticResponse,
  options,
}: {
  zoomContactNotes: ZoomContactNotes_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomContactNotesMutation, InsertZoomContactNotesMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomContactNotesMutationResultEx>;
export type InsertZoomContactNotesLazyMutationReturn = [
  InsertZoomContactNotesWrappedLazyMutationFn,
  InsertZoomContactNotesMutationResultEx
];

// Function
function useInsertZoomContactNotes(
  options?: Omit<
    MutationHookOptions<InsertZoomContactNotesMutation, InsertZoomContactNotesMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomContactNotesLazyMutationReturn {
  const lazyMutation: InsertZoomContactNotesLazyMutationFn = useMutation<
    InsertZoomContactNotesMutation,
    InsertZoomContactNotesMutationVariables
  >(InsertZoomContactNotesDocument, options);
  const pickZoomContactNotes: PickInsertZoomContactNotesFn = (mutation) => {
    return mutation?.insert_zoomContactNotes?.returning && mutation?.insert_zoomContactNotes?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomContactNotesWrappedLazyMutationFn = async ({
    zoomContactNotes,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomContactNotes });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomContactNotesMutation,
      InsertZoomContactNotesMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomContactNotes'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomContactNotesMutation>({
        operationType: 'insert',
        entityName: 'zoomContactNotes',
        objects: [objectForInsert as ZoomContactNotes_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomContactNotes: pickZoomContactNotes(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomContactNotes: pickZoomContactNotes(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomContactNotesWithOnConflictMutationResult = FetchResult<
  InsertZoomContactNotesWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomContactNotesWithOnConflictMutationResultEx = InsertZoomContactNotesWithOnConflictMutationResult &
  ZoomContactNotesByIdHookResultEx;

type InsertZoomContactNotesWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomContactNotesWithOnConflictMutation,
  InsertZoomContactNotesWithOnConflictMutationVariables
>;
type InsertZoomContactNotesWithOnConflictWrappedLazyMutationFn = ({
  zoomContactNotes,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomContactNotes: ZoomContactNotes_Insert_Input;
  onConflict: ZoomContactNotes_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomContactNotesWithOnConflictMutation,
      InsertZoomContactNotesWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomContactNotesWithOnConflictMutationResultEx>;
export type InsertZoomContactNotesWithOnConflictLazyMutationReturn = [
  InsertZoomContactNotesWithOnConflictWrappedLazyMutationFn,
  InsertZoomContactNotesWithOnConflictMutationResultEx
];

// Function
function useInsertZoomContactNotesWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomContactNotesWithOnConflictMutation,
      InsertZoomContactNotesWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomContactNotesWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomContactNotesWithOnConflictLazyMutationFn = useMutation<
    InsertZoomContactNotesWithOnConflictMutation,
    InsertZoomContactNotesWithOnConflictMutationVariables
  >(InsertZoomContactNotesWithOnConflictDocument, options);
  const pickZoomContactNotes: PickInsertZoomContactNotesFn = (
    mutation: InsertZoomContactNotesWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomContactNotes?.returning && mutation.insert_zoomContactNotes.returning[0];
  };

  const wrappedLazyMutation: InsertZoomContactNotesWithOnConflictWrappedLazyMutationFn = async ({
    zoomContactNotes,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomContactNotes });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomContactNotesWithOnConflictMutation,
      InsertZoomContactNotesWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomContactNotes'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomContactNotesWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomContactNotes',
          objects: [objectForInsert as ZoomContactNotes_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomContactNotes: pickZoomContactNotes(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomContactNotes: pickZoomContactNotes(lazyMutation[1].data) }];
}

// Types
type InsertZoomContactNotesObjectsMutationResult = FetchResult<
  InsertZoomContactNotesMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomContactNotesObjectsMutationResultEx = InsertZoomContactNotesMutationResult &
  ZoomContactNotesObjectsHookResultEx;

type PickInsertZoomContactNotesObjectsFn = (
  mutation: InsertZoomContactNotesMutation | null | undefined
) => ZoomContactNotesFragment[];
type InsertZoomContactNotesObjectsLazyMutationFn = MutationTuple<
  InsertZoomContactNotesMutation,
  InsertZoomContactNotesMutationVariables
>;
type InsertZoomContactNotesObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomContactNotesMutation, InsertZoomContactNotesMutationVariables>
) => Promise<InsertZoomContactNotesObjectsMutationResultEx>;
export type InsertZoomContactNotesObjectsLazyMutationReturn = [
  InsertZoomContactNotesObjectsWrappedLazyMutationFn,
  InsertZoomContactNotesObjectsMutationResultEx
];

// Function
function useInsertZoomContactNotesObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomContactNotesMutation, InsertZoomContactNotesMutationVariables>,
    'mutation'
  >
): InsertZoomContactNotesObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomContactNotesObjectsLazyMutationFn = useMutation<
    InsertZoomContactNotesMutation,
    InsertZoomContactNotesMutationVariables
  >(InsertZoomContactNotesDocument, options);
  const pickObjects: PickInsertZoomContactNotesObjectsFn = (
    mutation: InsertZoomContactNotesMutation | null | undefined
  ) => {
    return mutation?.insert_zoomContactNotes?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomContactNotesObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomContactNotesObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomContactNotesByIdMutationResult = FetchResult<
  UpdateZoomContactNotesByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomContactNotesByIdMutationResultEx = UpdateZoomContactNotesByIdMutationResult &
  ZoomContactNotesByIdHookResultEx;

type PickUpdateZoomContactNotesByIdFn = (
  mutation: UpdateZoomContactNotesByIdMutation | null | undefined
) => ZoomContactNotesFragment | null | undefined;
type UpdateZoomContactNotesByIdLazyMutationFn = MutationTuple<
  UpdateZoomContactNotesByIdMutation,
  UpdateZoomContactNotesByIdMutationVariables
>;
type UpdateZoomContactNotesByIdWrappedLazyMutationFn = ({
  zoomContactNotesId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomContactNotesId: string;
  set: ZoomContactNotes_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomContactNotesByIdMutation, UpdateZoomContactNotesByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomContactNotesByIdMutationResultEx>;
export type UpdateZoomContactNotesByIdLazyMutationReturn = [
  UpdateZoomContactNotesByIdWrappedLazyMutationFn,
  UpdateZoomContactNotesByIdMutationResultEx
];

function useUpdateZoomContactNotesById(
  options?: Omit<
    MutationHookOptions<UpdateZoomContactNotesByIdMutation, UpdateZoomContactNotesByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomContactNotesByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomContactNotesByIdLazyMutationFn = useMutation<
    UpdateZoomContactNotesByIdMutation,
    UpdateZoomContactNotesByIdMutationVariables
  >(UpdateZoomContactNotesByIdDocument, options);

  const pickZoomContactNotes: PickUpdateZoomContactNotesByIdFn = (mutation) => {
    return mutation?.update_zoomContactNotes?.returning && mutation.update_zoomContactNotes!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomContactNotesByIdWrappedLazyMutationFn = async ({
    zoomContactNotesId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomContactNotesByIdMutation,
      UpdateZoomContactNotesByIdMutationVariables
    > = { variables: { id: zoomContactNotesId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomContactNotesByIdMutation>({
        operationType: 'update',
        entityName: 'zoomContactNotes',
        objects: [{ id: zoomContactNotesId, ...set }],
      });
    }

    const fetchResult: UpdateZoomContactNotesByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomContactNotesId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomContactNotes: pickZoomContactNotes(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomContactNotes: pickZoomContactNotes(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomContactNotesObjectsMutationResult = FetchResult<
  UpdateZoomContactNotesMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomContactNotesObjectsMutationResultEx = UpdateZoomContactNotesObjectsMutationResult &
  ZoomContactNotesObjectsHookResultEx;

// Function
type PickUpdateZoomContactNotesObjectsFn = (
  mutation: UpdateZoomContactNotesMutation | null | undefined
) => ZoomContactNotesFragment[];
type UpdateZoomContactNotesObjectsLazyMutationFn = MutationTuple<
  UpdateZoomContactNotesMutation,
  UpdateZoomContactNotesMutationVariables
>;
type UpdateZoomContactNotesObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomContactNotesMutation, UpdateZoomContactNotesMutationVariables>
) => Promise<UpdateZoomContactNotesObjectsMutationResultEx>;
export type UpdateZoomContactNotesObjectsLazyMutationReturn = [
  UpdateZoomContactNotesObjectsWrappedLazyMutationFn,
  UpdateZoomContactNotesObjectsMutationResultEx
];

function useUpdateZoomContactNotesObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomContactNotesMutation, UpdateZoomContactNotesMutationVariables>,
    'mutation'
  >
): UpdateZoomContactNotesObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomContactNotesObjectsLazyMutationFn = useMutation<
    UpdateZoomContactNotesMutation,
    UpdateZoomContactNotesMutationVariables
  >(UpdateZoomContactNotesDocument, options);

  const pickObjects: PickUpdateZoomContactNotesObjectsFn = (
    mutation: UpdateZoomContactNotesMutation | null | undefined
  ) => {
    return mutation?.update_zoomContactNotes?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomContactNotesObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomContactNotesObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomContactNotesModelByIdFetchResult = FetchResult<
  RemoveZoomContactNotesModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomContactNotesModelByIdMutationResultEx = RemoveZoomContactNotesModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomContactNotesModelFn = (
  mutation: RemoveZoomContactNotesModelByIdMutation | null | undefined
) => number;
type RemoveZoomContactNotesModelLazyMutationFn = MutationTuple<
  RemoveZoomContactNotesModelByIdMutation,
  RemoveZoomContactNotesModelByIdMutationVariables
>;
type RemoveZoomContactNotesModelWrappedLazyMutationFn = ({
  zoomContactNotesId,
  autoOptimisticResponse,
  options,
}: {
  zoomContactNotesId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomContactNotesModelByIdMutation, RemoveZoomContactNotesModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomContactNotesModelByIdMutationResultEx>;
export type RemoveZoomContactNotesModelLazyMutationReturn = [
  RemoveZoomContactNotesModelWrappedLazyMutationFn,
  RemoveZoomContactNotesModelByIdMutationResultEx
];

function useRemoveZoomContactNotesModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomContactNotesModelByIdMutation, RemoveZoomContactNotesModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomContactNotesModelLazyMutationFn = useMutation<
    RemoveZoomContactNotesModelByIdMutation,
    RemoveZoomContactNotesModelByIdMutationVariables
  >(RemoveZoomContactNotesModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomContactNotesModelFn = (
    mutation: RemoveZoomContactNotesModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomContactNotes?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomContactNotesModelWrappedLazyMutationFn = async ({
    zoomContactNotesId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomContactNotesModelMutation,
      RemoveZoomContactNotesModelByIdMutationVariables
    > = { variables: { id: zoomContactNotesId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomContactNotesModelMutation>({
        operationType: 'delete',
        entityName: 'zoomContactNotes',
        objects: [{ id: zoomContactNotesId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomContactNotesModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomContactNotes',
        entityId: zoomContactNotesId,
      });
    }

    const fetchResult: RemoveZoomContactNotesModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomContactNotesModelObjectsMutationResult = FetchResult<
  RemoveZoomContactNotesModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomContactNotesModelObjectsMutationResultEx = RemoveZoomContactNotesModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomContactNotesModelObjectsFn = (
  mutation: RemoveZoomContactNotesModelMutation | null | undefined
) => number;
type RemoveZoomContactNotesModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomContactNotesModelMutation,
  RemoveZoomContactNotesModelMutationVariables
>;
type RemoveZoomContactNotesModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomContactNotesModelMutation, RemoveZoomContactNotesModelMutationVariables>
) => Promise<RemoveZoomContactNotesModelObjectsMutationResultEx>;
export type RemoveZoomContactNotesModelObjectsLazyMutationReturn = [
  RemoveZoomContactNotesModelObjectsWrappedLazyMutationFn,
  RemoveZoomContactNotesModelObjectsMutationResultEx
];

function useRemoveZoomContactNotesModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomContactNotesModelMutation, RemoveZoomContactNotesModelMutationVariables>,
    'mutation'
  >
): RemoveZoomContactNotesModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomContactNotesModelMutation, RemoveZoomContactNotesModelMutationVariables>(
    RemoveZoomContactNotesModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomContactNotesModelObjectsFn = (
    mutation: RemoveZoomContactNotesModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomContactNotes?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomContactNotesModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomContactNotesModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomContactNotes Fragment Helper Object
//------------------------------------------------

export const ZoomContactNotesFragmentGQLHooks = {
  useQueryById: useQueryZoomContactNotesById,
  useQueryByIdLazy: useQueryZoomContactNotesByIdLazy,
  useQueryObjects: useQueryZoomContactNotesObjects,
  useQueryObjectsLazy: useQueryZoomContactNotesObjectsLazy,
  useSubscribeToById: useSubscribeToZoomContactNotesById,
  useSubscribeToObjects: useSubscribeToZoomContactNotesObjects,
  useInsert: useInsertZoomContactNotes,
  useInsertWithOnConflict: useInsertZoomContactNotesWithOnConflict,
  useInsertObjects: useInsertZoomContactNotesObjects,
  useUpdateById: useUpdateZoomContactNotesById,
  useUpdateObjects: useUpdateZoomContactNotesObjects,
};

// zoomContactNotes MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomContactNotesModelGQLHooks = {
  useRemoveById: useRemoveZoomContactNotesModelById,
  useRemoveObjects: useRemoveZoomContactNotesModelObjects,
};

// googleChannel REACT
//------------------------------------------------

export type GoogleChannelByIdHookResultEx = { googleChannel: GoogleChannelFragment | null | undefined };
export type GoogleChannelObjectsHookResultEx = { objects: GoogleChannelFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryGoogleChannelByIdResult = LazyQueryResult<QueryGoogleChannelByIdQuery, QueryGoogleChannelByIdQueryVariables>;
type QueryGoogleChannelByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryGoogleChannelByIdQuery,
          QueryGoogleChannelByIdQueryVariables,
          QueryGoogleChannelByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryGoogleChannelByIdResultEx = Omit<QueryGoogleChannelByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryGoogleChannelByIdSubScribeToMore;
} & GoogleChannelByIdHookResultEx;

// Function
function useQueryGoogleChannelById({
  googleChannelId,
  options,
}: {
  googleChannelId: number;
  options?: Omit<
    QueryHookOptions<QueryGoogleChannelByIdQuery, QueryGoogleChannelByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryGoogleChannelByIdResultEx {
  const _query: QueryGoogleChannelByIdResult = useQuery<
    QueryGoogleChannelByIdQuery,
    QueryGoogleChannelByIdQueryVariables
  >(QueryGoogleChannelByIdDocument, { variables: { googleChannelId }, ...options });

  const typedSubscribeToMore: QueryGoogleChannelByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryGoogleChannelByIdDocument,
      variables: { googleChannelId } as QueryGoogleChannelByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, googleChannel: query?.data?.googleChannel_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryGoogleChannelByIdFn = (
  query: QueryGoogleChannelByIdQuery | null | undefined
) => GoogleChannelFragment | null | undefined;
type QueryGoogleChannelByIdLazyFn = [
  (options?: QueryLazyOptions<QueryGoogleChannelByIdQueryVariables> | undefined) => void,
  QueryGoogleChannelByIdResult
];
type QueryGoogleChannelByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryGoogleChannelByIdQueryVariables>, 'variables'>
) => void;
export type QueryGoogleChannelByIdLazyReturn = [QueryGoogleChannelByIdWrappedLazyFn, QueryGoogleChannelByIdResultEx];

// Function
function useQueryGoogleChannelByIdLazy({
  googleChannelId,
  options,
}: {
  googleChannelId: number;
  options?: Omit<
    QueryHookOptions<QueryGoogleChannelByIdQuery, QueryGoogleChannelByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryGoogleChannelByIdLazyReturn {
  const lazyQuery: QueryGoogleChannelByIdLazyFn = useLazyQuery<
    QueryGoogleChannelByIdQuery,
    QueryGoogleChannelByIdQueryVariables
  >(QueryGoogleChannelByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryGoogleChannelById: PickQueryGoogleChannelByIdFn = (query) => {
    return query && query.googleChannel_by_pk;
  };
  const wrappedLazyQuery: QueryGoogleChannelByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryGoogleChannelByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryGoogleChannelByIdDocument,
        variables: { googleChannelId } as QueryGoogleChannelByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      googleChannel: pickQueryGoogleChannelById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryGoogleChannelObjectsResult = LazyQueryResult<
  QueryGoogleChannelObjectsQuery,
  QueryGoogleChannelObjectsQueryVariables
>;
type QueryGoogleChannelObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryGoogleChannelObjectsQuery,
          QueryGoogleChannelObjectsQueryVariables,
          QueryGoogleChannelObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryGoogleChannelObjectsResultEx = Omit<QueryGoogleChannelObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryGoogleChannelObjectsSubScribeToMore;
} & GoogleChannelObjectsHookResultEx;

// Function
function useQueryGoogleChannelObjects(
  options: Omit<QueryHookOptions<QueryGoogleChannelObjectsQuery, QueryGoogleChannelObjectsQueryVariables>, 'query'>
): QueryGoogleChannelObjectsResultEx {
  const _query: QueryGoogleChannelObjectsResult = useQuery<
    QueryGoogleChannelObjectsQuery,
    QueryGoogleChannelObjectsQueryVariables
  >(QueryGoogleChannelObjectsDocument, options);

  const typedSubscribeToMore: QueryGoogleChannelObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryGoogleChannelObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.googleChannel || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryGoogleChannelObjectsFn = (
  query: QueryGoogleChannelObjectsQuery | null | undefined
) => GoogleChannelFragment[];
type QueryGoogleChannelObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryGoogleChannelObjectsQueryVariables> | undefined) => void,
  QueryGoogleChannelObjectsResult
];
type QueryGoogleChannelObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryGoogleChannelObjectsQueryVariables>
) => void;
export type QueryGoogleChannelObjectsLazyReturn = [
  QueryGoogleChannelObjectsWrappedLazyFn,
  QueryGoogleChannelObjectsResultEx
];

// Function
function useQueryGoogleChannelObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryGoogleChannelObjectsQuery, QueryGoogleChannelObjectsQueryVariables>, 'query'>
): QueryGoogleChannelObjectsLazyReturn {
  const lazyQuery: QueryGoogleChannelObjectsLazyFn = useLazyQuery<
    QueryGoogleChannelObjectsQuery,
    QueryGoogleChannelObjectsQueryVariables
  >(QueryGoogleChannelObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryGoogleChannelObjectsFn = (query: QueryGoogleChannelObjectsQuery | null | undefined) => {
    return query?.googleChannel || [];
  };
  const wrappedLazyQuery: QueryGoogleChannelObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryGoogleChannelObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryGoogleChannelObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToGoogleChannelByIdResult = {
  variables?: SubscribeToGoogleChannelByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToGoogleChannelByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToGoogleChannelByIdResultEx = SubscribeToGoogleChannelByIdResult & GoogleChannelByIdHookResultEx;

// Function
function useSubscribeToGoogleChannelById({
  googleChannelId,
  options,
}: {
  googleChannelId: number;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToGoogleChannelByIdSubscription,
      SubscribeToGoogleChannelByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToGoogleChannelByIdResultEx {
  const subscription: SubscribeToGoogleChannelByIdResult = useSubscription<
    SubscribeToGoogleChannelByIdSubscription,
    SubscribeToGoogleChannelByIdSubscriptionVariables
  >(SubscribeToGoogleChannelByIdDocument, { variables: { googleChannelId }, ...options });
  return { ...subscription, googleChannel: subscription?.data?.googleChannel_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToGoogleChannelObjectsResult = {
  variables?: SubscribeToGoogleChannelObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToGoogleChannelObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToGoogleChannelObjectsResultEx = SubscribeToGoogleChannelObjectsResult &
  GoogleChannelObjectsHookResultEx;

// Function
function useSubscribeToGoogleChannelObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToGoogleChannelObjectsSubscription,
      SubscribeToGoogleChannelObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToGoogleChannelObjectsResultEx {
  const subscription: SubscribeToGoogleChannelObjectsResult = useSubscription<
    SubscribeToGoogleChannelObjectsSubscription,
    SubscribeToGoogleChannelObjectsSubscriptionVariables
  >(SubscribeToGoogleChannelObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.googleChannel || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertGoogleChannelMutationResult = FetchResult<
  InsertGoogleChannelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertGoogleChannelMutationResultEx = InsertGoogleChannelMutationResult & GoogleChannelByIdHookResultEx;

type PickInsertGoogleChannelFn = (
  mutation: InsertGoogleChannelMutation | null | undefined
) => GoogleChannelFragment | null | undefined;
type InsertGoogleChannelLazyMutationFn = MutationTuple<
  InsertGoogleChannelMutation,
  InsertGoogleChannelMutationVariables
>;
type InsertGoogleChannelWrappedLazyMutationFn = ({
  googleChannel,
  autoOptimisticResponse,
  options,
}: {
  googleChannel: GoogleChannel_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertGoogleChannelMutation, InsertGoogleChannelMutationVariables>,
    'variables'
  >;
}) => Promise<InsertGoogleChannelMutationResultEx>;
export type InsertGoogleChannelLazyMutationReturn = [
  InsertGoogleChannelWrappedLazyMutationFn,
  InsertGoogleChannelMutationResultEx
];

// Function
function useInsertGoogleChannel(
  options?: Omit<
    MutationHookOptions<InsertGoogleChannelMutation, InsertGoogleChannelMutationVariables>,
    'mutation' | 'variables'
  >
): InsertGoogleChannelLazyMutationReturn {
  const lazyMutation: InsertGoogleChannelLazyMutationFn = useMutation<
    InsertGoogleChannelMutation,
    InsertGoogleChannelMutationVariables
  >(InsertGoogleChannelDocument, options);
  const pickGoogleChannel: PickInsertGoogleChannelFn = (mutation) => {
    return mutation?.insert_googleChannel?.returning && mutation?.insert_googleChannel?.returning[0];
  };

  const wrappedLazyMutation: InsertGoogleChannelWrappedLazyMutationFn = async ({
    googleChannel,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: googleChannel });
    const mutationOptions: MutationFunctionOptions<InsertGoogleChannelMutation, InsertGoogleChannelMutationVariables> =
      { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'googleChannel'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertGoogleChannelMutation>({
        operationType: 'insert',
        entityName: 'googleChannel',
        objects: [objectForInsert as GoogleChannel_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, googleChannel: pickGoogleChannel(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], googleChannel: pickGoogleChannel(lazyMutation[1].data) }];
}

//
//

// Types
type InsertGoogleChannelWithOnConflictMutationResult = FetchResult<
  InsertGoogleChannelWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertGoogleChannelWithOnConflictMutationResultEx = InsertGoogleChannelWithOnConflictMutationResult &
  GoogleChannelByIdHookResultEx;

type InsertGoogleChannelWithOnConflictLazyMutationFn = MutationTuple<
  InsertGoogleChannelWithOnConflictMutation,
  InsertGoogleChannelWithOnConflictMutationVariables
>;
type InsertGoogleChannelWithOnConflictWrappedLazyMutationFn = ({
  googleChannel,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  googleChannel: GoogleChannel_Insert_Input;
  onConflict: GoogleChannel_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertGoogleChannelWithOnConflictMutation,
      InsertGoogleChannelWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertGoogleChannelWithOnConflictMutationResultEx>;
export type InsertGoogleChannelWithOnConflictLazyMutationReturn = [
  InsertGoogleChannelWithOnConflictWrappedLazyMutationFn,
  InsertGoogleChannelWithOnConflictMutationResultEx
];

// Function
function useInsertGoogleChannelWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertGoogleChannelWithOnConflictMutation, InsertGoogleChannelWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertGoogleChannelWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertGoogleChannelWithOnConflictLazyMutationFn = useMutation<
    InsertGoogleChannelWithOnConflictMutation,
    InsertGoogleChannelWithOnConflictMutationVariables
  >(InsertGoogleChannelWithOnConflictDocument, options);
  const pickGoogleChannel: PickInsertGoogleChannelFn = (
    mutation: InsertGoogleChannelWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_googleChannel?.returning && mutation.insert_googleChannel.returning[0];
  };

  const wrappedLazyMutation: InsertGoogleChannelWithOnConflictWrappedLazyMutationFn = async ({
    googleChannel,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: googleChannel });
    const mutationOptions: MutationFunctionOptions<
      InsertGoogleChannelWithOnConflictMutation,
      InsertGoogleChannelWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'googleChannel'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertGoogleChannelWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'googleChannel',
          objects: [objectForInsert as GoogleChannel_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, googleChannel: pickGoogleChannel(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], googleChannel: pickGoogleChannel(lazyMutation[1].data) }];
}

// Types
type InsertGoogleChannelObjectsMutationResult = FetchResult<
  InsertGoogleChannelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertGoogleChannelObjectsMutationResultEx = InsertGoogleChannelMutationResult &
  GoogleChannelObjectsHookResultEx;

type PickInsertGoogleChannelObjectsFn = (
  mutation: InsertGoogleChannelMutation | null | undefined
) => GoogleChannelFragment[];
type InsertGoogleChannelObjectsLazyMutationFn = MutationTuple<
  InsertGoogleChannelMutation,
  InsertGoogleChannelMutationVariables
>;
type InsertGoogleChannelObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertGoogleChannelMutation, InsertGoogleChannelMutationVariables>
) => Promise<InsertGoogleChannelObjectsMutationResultEx>;
export type InsertGoogleChannelObjectsLazyMutationReturn = [
  InsertGoogleChannelObjectsWrappedLazyMutationFn,
  InsertGoogleChannelObjectsMutationResultEx
];

// Function
function useInsertGoogleChannelObjects(
  options?: Omit<MutationHookOptions<InsertGoogleChannelMutation, InsertGoogleChannelMutationVariables>, 'mutation'>
): InsertGoogleChannelObjectsLazyMutationReturn {
  const lazyMutation: InsertGoogleChannelObjectsLazyMutationFn = useMutation<
    InsertGoogleChannelMutation,
    InsertGoogleChannelMutationVariables
  >(InsertGoogleChannelDocument, options);
  const pickObjects: PickInsertGoogleChannelObjectsFn = (mutation: InsertGoogleChannelMutation | null | undefined) => {
    return mutation?.insert_googleChannel?.returning || [];
  };

  const wrappedLazyMutation: InsertGoogleChannelObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertGoogleChannelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateGoogleChannelByIdMutationResult = FetchResult<
  UpdateGoogleChannelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateGoogleChannelByIdMutationResultEx = UpdateGoogleChannelByIdMutationResult &
  GoogleChannelByIdHookResultEx;

type PickUpdateGoogleChannelByIdFn = (
  mutation: UpdateGoogleChannelByIdMutation | null | undefined
) => GoogleChannelFragment | null | undefined;
type UpdateGoogleChannelByIdLazyMutationFn = MutationTuple<
  UpdateGoogleChannelByIdMutation,
  UpdateGoogleChannelByIdMutationVariables
>;
type UpdateGoogleChannelByIdWrappedLazyMutationFn = ({
  googleChannelId,
  set,
  autoOptimisticResponse,
  options,
}: {
  googleChannelId: number;
  set: GoogleChannel_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateGoogleChannelByIdMutation, UpdateGoogleChannelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateGoogleChannelByIdMutationResultEx>;
export type UpdateGoogleChannelByIdLazyMutationReturn = [
  UpdateGoogleChannelByIdWrappedLazyMutationFn,
  UpdateGoogleChannelByIdMutationResultEx
];

function useUpdateGoogleChannelById(
  options?: Omit<
    MutationHookOptions<UpdateGoogleChannelByIdMutation, UpdateGoogleChannelByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateGoogleChannelByIdLazyMutationReturn {
  const lazyMutation: UpdateGoogleChannelByIdLazyMutationFn = useMutation<
    UpdateGoogleChannelByIdMutation,
    UpdateGoogleChannelByIdMutationVariables
  >(UpdateGoogleChannelByIdDocument, options);

  const pickGoogleChannel: PickUpdateGoogleChannelByIdFn = (mutation) => {
    return mutation?.update_googleChannel?.returning && mutation.update_googleChannel!.returning[0];
  };

  const wrappedLazyMutation: UpdateGoogleChannelByIdWrappedLazyMutationFn = async ({
    googleChannelId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateGoogleChannelByIdMutation,
      UpdateGoogleChannelByIdMutationVariables
    > = { variables: { id: googleChannelId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateGoogleChannelByIdMutation>({
        operationType: 'update',
        entityName: 'googleChannel',
        objects: [{ id: googleChannelId, ...set }],
      });
    }

    const fetchResult: UpdateGoogleChannelByIdMutationResult = await lazyMutation[0]({
      variables: { id: googleChannelId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, googleChannel: pickGoogleChannel(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], googleChannel: pickGoogleChannel(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateGoogleChannelObjectsMutationResult = FetchResult<
  UpdateGoogleChannelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateGoogleChannelObjectsMutationResultEx = UpdateGoogleChannelObjectsMutationResult &
  GoogleChannelObjectsHookResultEx;

// Function
type PickUpdateGoogleChannelObjectsFn = (
  mutation: UpdateGoogleChannelMutation | null | undefined
) => GoogleChannelFragment[];
type UpdateGoogleChannelObjectsLazyMutationFn = MutationTuple<
  UpdateGoogleChannelMutation,
  UpdateGoogleChannelMutationVariables
>;
type UpdateGoogleChannelObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateGoogleChannelMutation, UpdateGoogleChannelMutationVariables>
) => Promise<UpdateGoogleChannelObjectsMutationResultEx>;
export type UpdateGoogleChannelObjectsLazyMutationReturn = [
  UpdateGoogleChannelObjectsWrappedLazyMutationFn,
  UpdateGoogleChannelObjectsMutationResultEx
];

function useUpdateGoogleChannelObjects(
  options?: Omit<MutationHookOptions<UpdateGoogleChannelMutation, UpdateGoogleChannelMutationVariables>, 'mutation'>
): UpdateGoogleChannelObjectsLazyMutationReturn {
  const lazyMutation: UpdateGoogleChannelObjectsLazyMutationFn = useMutation<
    UpdateGoogleChannelMutation,
    UpdateGoogleChannelMutationVariables
  >(UpdateGoogleChannelDocument, options);

  const pickObjects: PickUpdateGoogleChannelObjectsFn = (mutation: UpdateGoogleChannelMutation | null | undefined) => {
    return mutation?.update_googleChannel?.returning || [];
  };

  const wrappedLazyMutation: UpdateGoogleChannelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateGoogleChannelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveGoogleChannelModelByIdFetchResult = FetchResult<
  RemoveGoogleChannelModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveGoogleChannelModelByIdMutationResultEx = RemoveGoogleChannelModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveGoogleChannelModelFn = (mutation: RemoveGoogleChannelModelByIdMutation | null | undefined) => number;
type RemoveGoogleChannelModelLazyMutationFn = MutationTuple<
  RemoveGoogleChannelModelByIdMutation,
  RemoveGoogleChannelModelByIdMutationVariables
>;
type RemoveGoogleChannelModelWrappedLazyMutationFn = ({
  googleChannelId,
  autoOptimisticResponse,
  options,
}: {
  googleChannelId: number;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveGoogleChannelModelByIdMutation, RemoveGoogleChannelModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveGoogleChannelModelByIdMutationResultEx>;
export type RemoveGoogleChannelModelLazyMutationReturn = [
  RemoveGoogleChannelModelWrappedLazyMutationFn,
  RemoveGoogleChannelModelByIdMutationResultEx
];

function useRemoveGoogleChannelModelById(
  options?: Omit<
    MutationHookOptions<RemoveGoogleChannelModelByIdMutation, RemoveGoogleChannelModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveGoogleChannelModelLazyMutationFn = useMutation<
    RemoveGoogleChannelModelByIdMutation,
    RemoveGoogleChannelModelByIdMutationVariables
  >(RemoveGoogleChannelModelByIdDocument, options);

  const pickAffectedRows: PickRemoveGoogleChannelModelFn = (
    mutation: RemoveGoogleChannelModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_googleChannel?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveGoogleChannelModelWrappedLazyMutationFn = async ({
    googleChannelId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveGoogleChannelModelMutation,
      RemoveGoogleChannelModelByIdMutationVariables
    > = { variables: { id: googleChannelId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveGoogleChannelModelMutation>({
        operationType: 'delete',
        entityName: 'googleChannel',
        objects: [{ id: googleChannelId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveGoogleChannelModelByIdMutation>({
        operationType: 'delete',
        entityName: 'googleChannel',
        entityId: googleChannelId,
      });
    }

    const fetchResult: RemoveGoogleChannelModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveGoogleChannelModelObjectsMutationResult = FetchResult<
  RemoveGoogleChannelModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveGoogleChannelModelObjectsMutationResultEx = RemoveGoogleChannelModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveGoogleChannelModelObjectsFn = (mutation: RemoveGoogleChannelModelMutation | null | undefined) => number;
type RemoveGoogleChannelModelObjectsLazyMutationFn = MutationTuple<
  RemoveGoogleChannelModelMutation,
  RemoveGoogleChannelModelMutationVariables
>;
type RemoveGoogleChannelModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveGoogleChannelModelMutation, RemoveGoogleChannelModelMutationVariables>
) => Promise<RemoveGoogleChannelModelObjectsMutationResultEx>;
export type RemoveGoogleChannelModelObjectsLazyMutationReturn = [
  RemoveGoogleChannelModelObjectsWrappedLazyMutationFn,
  RemoveGoogleChannelModelObjectsMutationResultEx
];

function useRemoveGoogleChannelModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveGoogleChannelModelMutation, RemoveGoogleChannelModelMutationVariables>,
    'mutation'
  >
): RemoveGoogleChannelModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveGoogleChannelModelMutation, RemoveGoogleChannelModelMutationVariables>(
    RemoveGoogleChannelModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveGoogleChannelModelObjectsFn = (
    mutation: RemoveGoogleChannelModelMutation | null | undefined
  ) => {
    return mutation?.delete_googleChannel?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveGoogleChannelModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveGoogleChannelModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// GoogleChannel Fragment Helper Object
//------------------------------------------------

export const GoogleChannelFragmentGQLHooks = {
  useQueryById: useQueryGoogleChannelById,
  useQueryByIdLazy: useQueryGoogleChannelByIdLazy,
  useQueryObjects: useQueryGoogleChannelObjects,
  useQueryObjectsLazy: useQueryGoogleChannelObjectsLazy,
  useSubscribeToById: useSubscribeToGoogleChannelById,
  useSubscribeToObjects: useSubscribeToGoogleChannelObjects,
  useInsert: useInsertGoogleChannel,
  useInsertWithOnConflict: useInsertGoogleChannelWithOnConflict,
  useInsertObjects: useInsertGoogleChannelObjects,
  useUpdateById: useUpdateGoogleChannelById,
  useUpdateObjects: useUpdateGoogleChannelObjects,
};

// googleChannel MODEL HOOKS OBJECT
//------------------------------------------------

export const GoogleChannelModelGQLHooks = {
  useRemoveById: useRemoveGoogleChannelModelById,
  useRemoveObjects: useRemoveGoogleChannelModelObjects,
};

// zoomEmailHistory REACT
//------------------------------------------------

export type ZoomEmailHistoryByIdHookResultEx = { zoomEmailHistory: ZoomEmailHistoryFragment | null | undefined };
export type ZoomEmailHistoryObjectsHookResultEx = { objects: ZoomEmailHistoryFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomEmailHistoryByIdResult = LazyQueryResult<
  QueryZoomEmailHistoryByIdQuery,
  QueryZoomEmailHistoryByIdQueryVariables
>;
type QueryZoomEmailHistoryByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomEmailHistoryByIdQuery,
          QueryZoomEmailHistoryByIdQueryVariables,
          QueryZoomEmailHistoryByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomEmailHistoryByIdResultEx = Omit<QueryZoomEmailHistoryByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomEmailHistoryByIdSubScribeToMore;
} & ZoomEmailHistoryByIdHookResultEx;

// Function
function useQueryZoomEmailHistoryById({
  zoomEmailHistoryId,
  options,
}: {
  zoomEmailHistoryId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomEmailHistoryByIdQuery, QueryZoomEmailHistoryByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomEmailHistoryByIdResultEx {
  const _query: QueryZoomEmailHistoryByIdResult = useQuery<
    QueryZoomEmailHistoryByIdQuery,
    QueryZoomEmailHistoryByIdQueryVariables
  >(QueryZoomEmailHistoryByIdDocument, { variables: { zoomEmailHistoryId }, ...options });

  const typedSubscribeToMore: QueryZoomEmailHistoryByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomEmailHistoryByIdDocument,
      variables: { zoomEmailHistoryId } as QueryZoomEmailHistoryByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomEmailHistory: query?.data?.zoomEmailHistory_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomEmailHistoryByIdFn = (
  query: QueryZoomEmailHistoryByIdQuery | null | undefined
) => ZoomEmailHistoryFragment | null | undefined;
type QueryZoomEmailHistoryByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomEmailHistoryByIdQueryVariables> | undefined) => void,
  QueryZoomEmailHistoryByIdResult
];
type QueryZoomEmailHistoryByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomEmailHistoryByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomEmailHistoryByIdLazyReturn = [
  QueryZoomEmailHistoryByIdWrappedLazyFn,
  QueryZoomEmailHistoryByIdResultEx
];

// Function
function useQueryZoomEmailHistoryByIdLazy({
  zoomEmailHistoryId,
  options,
}: {
  zoomEmailHistoryId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomEmailHistoryByIdQuery, QueryZoomEmailHistoryByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomEmailHistoryByIdLazyReturn {
  const lazyQuery: QueryZoomEmailHistoryByIdLazyFn = useLazyQuery<
    QueryZoomEmailHistoryByIdQuery,
    QueryZoomEmailHistoryByIdQueryVariables
  >(QueryZoomEmailHistoryByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomEmailHistoryById: PickQueryZoomEmailHistoryByIdFn = (query) => {
    return query && query.zoomEmailHistory_by_pk;
  };
  const wrappedLazyQuery: QueryZoomEmailHistoryByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomEmailHistoryByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomEmailHistoryByIdDocument,
        variables: { zoomEmailHistoryId } as QueryZoomEmailHistoryByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomEmailHistory: pickQueryZoomEmailHistoryById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomEmailHistoryObjectsResult = LazyQueryResult<
  QueryZoomEmailHistoryObjectsQuery,
  QueryZoomEmailHistoryObjectsQueryVariables
>;
type QueryZoomEmailHistoryObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomEmailHistoryObjectsQuery,
          QueryZoomEmailHistoryObjectsQueryVariables,
          QueryZoomEmailHistoryObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomEmailHistoryObjectsResultEx = Omit<QueryZoomEmailHistoryObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomEmailHistoryObjectsSubScribeToMore;
} & ZoomEmailHistoryObjectsHookResultEx;

// Function
function useQueryZoomEmailHistoryObjects(
  options: Omit<
    QueryHookOptions<QueryZoomEmailHistoryObjectsQuery, QueryZoomEmailHistoryObjectsQueryVariables>,
    'query'
  >
): QueryZoomEmailHistoryObjectsResultEx {
  const _query: QueryZoomEmailHistoryObjectsResult = useQuery<
    QueryZoomEmailHistoryObjectsQuery,
    QueryZoomEmailHistoryObjectsQueryVariables
  >(QueryZoomEmailHistoryObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomEmailHistoryObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomEmailHistoryObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomEmailHistory || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomEmailHistoryObjectsFn = (
  query: QueryZoomEmailHistoryObjectsQuery | null | undefined
) => ZoomEmailHistoryFragment[];
type QueryZoomEmailHistoryObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomEmailHistoryObjectsQueryVariables> | undefined) => void,
  QueryZoomEmailHistoryObjectsResult
];
type QueryZoomEmailHistoryObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomEmailHistoryObjectsQueryVariables>
) => void;
export type QueryZoomEmailHistoryObjectsLazyReturn = [
  QueryZoomEmailHistoryObjectsWrappedLazyFn,
  QueryZoomEmailHistoryObjectsResultEx
];

// Function
function useQueryZoomEmailHistoryObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomEmailHistoryObjectsQuery, QueryZoomEmailHistoryObjectsQueryVariables>,
    'query'
  >
): QueryZoomEmailHistoryObjectsLazyReturn {
  const lazyQuery: QueryZoomEmailHistoryObjectsLazyFn = useLazyQuery<
    QueryZoomEmailHistoryObjectsQuery,
    QueryZoomEmailHistoryObjectsQueryVariables
  >(QueryZoomEmailHistoryObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomEmailHistoryObjectsFn = (
    query: QueryZoomEmailHistoryObjectsQuery | null | undefined
  ) => {
    return query?.zoomEmailHistory || [];
  };
  const wrappedLazyQuery: QueryZoomEmailHistoryObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomEmailHistoryObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomEmailHistoryObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomEmailHistoryByIdResult = {
  variables?: SubscribeToZoomEmailHistoryByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomEmailHistoryByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomEmailHistoryByIdResultEx = SubscribeToZoomEmailHistoryByIdResult &
  ZoomEmailHistoryByIdHookResultEx;

// Function
function useSubscribeToZoomEmailHistoryById({
  zoomEmailHistoryId,
  options,
}: {
  zoomEmailHistoryId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomEmailHistoryByIdSubscription,
      SubscribeToZoomEmailHistoryByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomEmailHistoryByIdResultEx {
  const subscription: SubscribeToZoomEmailHistoryByIdResult = useSubscription<
    SubscribeToZoomEmailHistoryByIdSubscription,
    SubscribeToZoomEmailHistoryByIdSubscriptionVariables
  >(SubscribeToZoomEmailHistoryByIdDocument, { variables: { zoomEmailHistoryId }, ...options });
  return { ...subscription, zoomEmailHistory: subscription?.data?.zoomEmailHistory_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomEmailHistoryObjectsResult = {
  variables?: SubscribeToZoomEmailHistoryObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomEmailHistoryObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomEmailHistoryObjectsResultEx = SubscribeToZoomEmailHistoryObjectsResult &
  ZoomEmailHistoryObjectsHookResultEx;

// Function
function useSubscribeToZoomEmailHistoryObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomEmailHistoryObjectsSubscription,
      SubscribeToZoomEmailHistoryObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomEmailHistoryObjectsResultEx {
  const subscription: SubscribeToZoomEmailHistoryObjectsResult = useSubscription<
    SubscribeToZoomEmailHistoryObjectsSubscription,
    SubscribeToZoomEmailHistoryObjectsSubscriptionVariables
  >(SubscribeToZoomEmailHistoryObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomEmailHistory || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomEmailHistoryMutationResult = FetchResult<
  InsertZoomEmailHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomEmailHistoryMutationResultEx = InsertZoomEmailHistoryMutationResult &
  ZoomEmailHistoryByIdHookResultEx;

type PickInsertZoomEmailHistoryFn = (
  mutation: InsertZoomEmailHistoryMutation | null | undefined
) => ZoomEmailHistoryFragment | null | undefined;
type InsertZoomEmailHistoryLazyMutationFn = MutationTuple<
  InsertZoomEmailHistoryMutation,
  InsertZoomEmailHistoryMutationVariables
>;
type InsertZoomEmailHistoryWrappedLazyMutationFn = ({
  zoomEmailHistory,
  autoOptimisticResponse,
  options,
}: {
  zoomEmailHistory: ZoomEmailHistory_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomEmailHistoryMutation, InsertZoomEmailHistoryMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomEmailHistoryMutationResultEx>;
export type InsertZoomEmailHistoryLazyMutationReturn = [
  InsertZoomEmailHistoryWrappedLazyMutationFn,
  InsertZoomEmailHistoryMutationResultEx
];

// Function
function useInsertZoomEmailHistory(
  options?: Omit<
    MutationHookOptions<InsertZoomEmailHistoryMutation, InsertZoomEmailHistoryMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomEmailHistoryLazyMutationReturn {
  const lazyMutation: InsertZoomEmailHistoryLazyMutationFn = useMutation<
    InsertZoomEmailHistoryMutation,
    InsertZoomEmailHistoryMutationVariables
  >(InsertZoomEmailHistoryDocument, options);
  const pickZoomEmailHistory: PickInsertZoomEmailHistoryFn = (mutation) => {
    return mutation?.insert_zoomEmailHistory?.returning && mutation?.insert_zoomEmailHistory?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomEmailHistoryWrappedLazyMutationFn = async ({
    zoomEmailHistory,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomEmailHistory });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomEmailHistoryMutation,
      InsertZoomEmailHistoryMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomEmailHistory'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomEmailHistoryMutation>({
        operationType: 'insert',
        entityName: 'zoomEmailHistory',
        objects: [objectForInsert as ZoomEmailHistory_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomEmailHistory: pickZoomEmailHistory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomEmailHistory: pickZoomEmailHistory(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomEmailHistoryWithOnConflictMutationResult = FetchResult<
  InsertZoomEmailHistoryWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomEmailHistoryWithOnConflictMutationResultEx = InsertZoomEmailHistoryWithOnConflictMutationResult &
  ZoomEmailHistoryByIdHookResultEx;

type InsertZoomEmailHistoryWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomEmailHistoryWithOnConflictMutation,
  InsertZoomEmailHistoryWithOnConflictMutationVariables
>;
type InsertZoomEmailHistoryWithOnConflictWrappedLazyMutationFn = ({
  zoomEmailHistory,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomEmailHistory: ZoomEmailHistory_Insert_Input;
  onConflict: ZoomEmailHistory_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomEmailHistoryWithOnConflictMutation,
      InsertZoomEmailHistoryWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomEmailHistoryWithOnConflictMutationResultEx>;
export type InsertZoomEmailHistoryWithOnConflictLazyMutationReturn = [
  InsertZoomEmailHistoryWithOnConflictWrappedLazyMutationFn,
  InsertZoomEmailHistoryWithOnConflictMutationResultEx
];

// Function
function useInsertZoomEmailHistoryWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomEmailHistoryWithOnConflictMutation,
      InsertZoomEmailHistoryWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomEmailHistoryWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomEmailHistoryWithOnConflictLazyMutationFn = useMutation<
    InsertZoomEmailHistoryWithOnConflictMutation,
    InsertZoomEmailHistoryWithOnConflictMutationVariables
  >(InsertZoomEmailHistoryWithOnConflictDocument, options);
  const pickZoomEmailHistory: PickInsertZoomEmailHistoryFn = (
    mutation: InsertZoomEmailHistoryWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomEmailHistory?.returning && mutation.insert_zoomEmailHistory.returning[0];
  };

  const wrappedLazyMutation: InsertZoomEmailHistoryWithOnConflictWrappedLazyMutationFn = async ({
    zoomEmailHistory,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomEmailHistory });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomEmailHistoryWithOnConflictMutation,
      InsertZoomEmailHistoryWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomEmailHistory'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomEmailHistoryWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomEmailHistory',
          objects: [objectForInsert as ZoomEmailHistory_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomEmailHistory: pickZoomEmailHistory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomEmailHistory: pickZoomEmailHistory(lazyMutation[1].data) }];
}

// Types
type InsertZoomEmailHistoryObjectsMutationResult = FetchResult<
  InsertZoomEmailHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomEmailHistoryObjectsMutationResultEx = InsertZoomEmailHistoryMutationResult &
  ZoomEmailHistoryObjectsHookResultEx;

type PickInsertZoomEmailHistoryObjectsFn = (
  mutation: InsertZoomEmailHistoryMutation | null | undefined
) => ZoomEmailHistoryFragment[];
type InsertZoomEmailHistoryObjectsLazyMutationFn = MutationTuple<
  InsertZoomEmailHistoryMutation,
  InsertZoomEmailHistoryMutationVariables
>;
type InsertZoomEmailHistoryObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomEmailHistoryMutation, InsertZoomEmailHistoryMutationVariables>
) => Promise<InsertZoomEmailHistoryObjectsMutationResultEx>;
export type InsertZoomEmailHistoryObjectsLazyMutationReturn = [
  InsertZoomEmailHistoryObjectsWrappedLazyMutationFn,
  InsertZoomEmailHistoryObjectsMutationResultEx
];

// Function
function useInsertZoomEmailHistoryObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomEmailHistoryMutation, InsertZoomEmailHistoryMutationVariables>,
    'mutation'
  >
): InsertZoomEmailHistoryObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomEmailHistoryObjectsLazyMutationFn = useMutation<
    InsertZoomEmailHistoryMutation,
    InsertZoomEmailHistoryMutationVariables
  >(InsertZoomEmailHistoryDocument, options);
  const pickObjects: PickInsertZoomEmailHistoryObjectsFn = (
    mutation: InsertZoomEmailHistoryMutation | null | undefined
  ) => {
    return mutation?.insert_zoomEmailHistory?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomEmailHistoryObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomEmailHistoryObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomEmailHistoryByIdMutationResult = FetchResult<
  UpdateZoomEmailHistoryByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomEmailHistoryByIdMutationResultEx = UpdateZoomEmailHistoryByIdMutationResult &
  ZoomEmailHistoryByIdHookResultEx;

type PickUpdateZoomEmailHistoryByIdFn = (
  mutation: UpdateZoomEmailHistoryByIdMutation | null | undefined
) => ZoomEmailHistoryFragment | null | undefined;
type UpdateZoomEmailHistoryByIdLazyMutationFn = MutationTuple<
  UpdateZoomEmailHistoryByIdMutation,
  UpdateZoomEmailHistoryByIdMutationVariables
>;
type UpdateZoomEmailHistoryByIdWrappedLazyMutationFn = ({
  zoomEmailHistoryId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomEmailHistoryId: string;
  set: ZoomEmailHistory_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomEmailHistoryByIdMutation, UpdateZoomEmailHistoryByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomEmailHistoryByIdMutationResultEx>;
export type UpdateZoomEmailHistoryByIdLazyMutationReturn = [
  UpdateZoomEmailHistoryByIdWrappedLazyMutationFn,
  UpdateZoomEmailHistoryByIdMutationResultEx
];

function useUpdateZoomEmailHistoryById(
  options?: Omit<
    MutationHookOptions<UpdateZoomEmailHistoryByIdMutation, UpdateZoomEmailHistoryByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomEmailHistoryByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomEmailHistoryByIdLazyMutationFn = useMutation<
    UpdateZoomEmailHistoryByIdMutation,
    UpdateZoomEmailHistoryByIdMutationVariables
  >(UpdateZoomEmailHistoryByIdDocument, options);

  const pickZoomEmailHistory: PickUpdateZoomEmailHistoryByIdFn = (mutation) => {
    return mutation?.update_zoomEmailHistory?.returning && mutation.update_zoomEmailHistory!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomEmailHistoryByIdWrappedLazyMutationFn = async ({
    zoomEmailHistoryId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomEmailHistoryByIdMutation,
      UpdateZoomEmailHistoryByIdMutationVariables
    > = { variables: { id: zoomEmailHistoryId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomEmailHistoryByIdMutation>({
        operationType: 'update',
        entityName: 'zoomEmailHistory',
        objects: [{ id: zoomEmailHistoryId, ...set }],
      });
    }

    const fetchResult: UpdateZoomEmailHistoryByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomEmailHistoryId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomEmailHistory: pickZoomEmailHistory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomEmailHistory: pickZoomEmailHistory(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomEmailHistoryObjectsMutationResult = FetchResult<
  UpdateZoomEmailHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomEmailHistoryObjectsMutationResultEx = UpdateZoomEmailHistoryObjectsMutationResult &
  ZoomEmailHistoryObjectsHookResultEx;

// Function
type PickUpdateZoomEmailHistoryObjectsFn = (
  mutation: UpdateZoomEmailHistoryMutation | null | undefined
) => ZoomEmailHistoryFragment[];
type UpdateZoomEmailHistoryObjectsLazyMutationFn = MutationTuple<
  UpdateZoomEmailHistoryMutation,
  UpdateZoomEmailHistoryMutationVariables
>;
type UpdateZoomEmailHistoryObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomEmailHistoryMutation, UpdateZoomEmailHistoryMutationVariables>
) => Promise<UpdateZoomEmailHistoryObjectsMutationResultEx>;
export type UpdateZoomEmailHistoryObjectsLazyMutationReturn = [
  UpdateZoomEmailHistoryObjectsWrappedLazyMutationFn,
  UpdateZoomEmailHistoryObjectsMutationResultEx
];

function useUpdateZoomEmailHistoryObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomEmailHistoryMutation, UpdateZoomEmailHistoryMutationVariables>,
    'mutation'
  >
): UpdateZoomEmailHistoryObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomEmailHistoryObjectsLazyMutationFn = useMutation<
    UpdateZoomEmailHistoryMutation,
    UpdateZoomEmailHistoryMutationVariables
  >(UpdateZoomEmailHistoryDocument, options);

  const pickObjects: PickUpdateZoomEmailHistoryObjectsFn = (
    mutation: UpdateZoomEmailHistoryMutation | null | undefined
  ) => {
    return mutation?.update_zoomEmailHistory?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomEmailHistoryObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomEmailHistoryObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomEmailHistoryModelByIdFetchResult = FetchResult<
  RemoveZoomEmailHistoryModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomEmailHistoryModelByIdMutationResultEx = RemoveZoomEmailHistoryModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomEmailHistoryModelFn = (
  mutation: RemoveZoomEmailHistoryModelByIdMutation | null | undefined
) => number;
type RemoveZoomEmailHistoryModelLazyMutationFn = MutationTuple<
  RemoveZoomEmailHistoryModelByIdMutation,
  RemoveZoomEmailHistoryModelByIdMutationVariables
>;
type RemoveZoomEmailHistoryModelWrappedLazyMutationFn = ({
  zoomEmailHistoryId,
  autoOptimisticResponse,
  options,
}: {
  zoomEmailHistoryId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomEmailHistoryModelByIdMutation, RemoveZoomEmailHistoryModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomEmailHistoryModelByIdMutationResultEx>;
export type RemoveZoomEmailHistoryModelLazyMutationReturn = [
  RemoveZoomEmailHistoryModelWrappedLazyMutationFn,
  RemoveZoomEmailHistoryModelByIdMutationResultEx
];

function useRemoveZoomEmailHistoryModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomEmailHistoryModelByIdMutation, RemoveZoomEmailHistoryModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomEmailHistoryModelLazyMutationFn = useMutation<
    RemoveZoomEmailHistoryModelByIdMutation,
    RemoveZoomEmailHistoryModelByIdMutationVariables
  >(RemoveZoomEmailHistoryModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomEmailHistoryModelFn = (
    mutation: RemoveZoomEmailHistoryModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomEmailHistory?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomEmailHistoryModelWrappedLazyMutationFn = async ({
    zoomEmailHistoryId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomEmailHistoryModelMutation,
      RemoveZoomEmailHistoryModelByIdMutationVariables
    > = { variables: { id: zoomEmailHistoryId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomEmailHistoryModelMutation>({
        operationType: 'delete',
        entityName: 'zoomEmailHistory',
        objects: [{ id: zoomEmailHistoryId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomEmailHistoryModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomEmailHistory',
        entityId: zoomEmailHistoryId,
      });
    }

    const fetchResult: RemoveZoomEmailHistoryModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomEmailHistoryModelObjectsMutationResult = FetchResult<
  RemoveZoomEmailHistoryModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomEmailHistoryModelObjectsMutationResultEx = RemoveZoomEmailHistoryModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomEmailHistoryModelObjectsFn = (
  mutation: RemoveZoomEmailHistoryModelMutation | null | undefined
) => number;
type RemoveZoomEmailHistoryModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomEmailHistoryModelMutation,
  RemoveZoomEmailHistoryModelMutationVariables
>;
type RemoveZoomEmailHistoryModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomEmailHistoryModelMutation, RemoveZoomEmailHistoryModelMutationVariables>
) => Promise<RemoveZoomEmailHistoryModelObjectsMutationResultEx>;
export type RemoveZoomEmailHistoryModelObjectsLazyMutationReturn = [
  RemoveZoomEmailHistoryModelObjectsWrappedLazyMutationFn,
  RemoveZoomEmailHistoryModelObjectsMutationResultEx
];

function useRemoveZoomEmailHistoryModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomEmailHistoryModelMutation, RemoveZoomEmailHistoryModelMutationVariables>,
    'mutation'
  >
): RemoveZoomEmailHistoryModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomEmailHistoryModelMutation, RemoveZoomEmailHistoryModelMutationVariables>(
    RemoveZoomEmailHistoryModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomEmailHistoryModelObjectsFn = (
    mutation: RemoveZoomEmailHistoryModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomEmailHistory?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomEmailHistoryModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomEmailHistoryModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomEmailHistory Fragment Helper Object
//------------------------------------------------

export const ZoomEmailHistoryFragmentGQLHooks = {
  useQueryById: useQueryZoomEmailHistoryById,
  useQueryByIdLazy: useQueryZoomEmailHistoryByIdLazy,
  useQueryObjects: useQueryZoomEmailHistoryObjects,
  useQueryObjectsLazy: useQueryZoomEmailHistoryObjectsLazy,
  useSubscribeToById: useSubscribeToZoomEmailHistoryById,
  useSubscribeToObjects: useSubscribeToZoomEmailHistoryObjects,
  useInsert: useInsertZoomEmailHistory,
  useInsertWithOnConflict: useInsertZoomEmailHistoryWithOnConflict,
  useInsertObjects: useInsertZoomEmailHistoryObjects,
  useUpdateById: useUpdateZoomEmailHistoryById,
  useUpdateObjects: useUpdateZoomEmailHistoryObjects,
};

// zoomEmailHistory MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomEmailHistoryModelGQLHooks = {
  useRemoveById: useRemoveZoomEmailHistoryModelById,
  useRemoveObjects: useRemoveZoomEmailHistoryModelObjects,
};

// zoomCustomBackground REACT
//------------------------------------------------

export type ZoomCustomBackgroundByIdHookResultEx = {
  zoomCustomBackground: ZoomCustomBackgroundFragment | null | undefined;
};
export type ZoomCustomBackgroundObjectsHookResultEx = { objects: ZoomCustomBackgroundFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomCustomBackgroundByIdResult = LazyQueryResult<
  QueryZoomCustomBackgroundByIdQuery,
  QueryZoomCustomBackgroundByIdQueryVariables
>;
type QueryZoomCustomBackgroundByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomCustomBackgroundByIdQuery,
          QueryZoomCustomBackgroundByIdQueryVariables,
          QueryZoomCustomBackgroundByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomCustomBackgroundByIdResultEx = Omit<QueryZoomCustomBackgroundByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomCustomBackgroundByIdSubScribeToMore;
} & ZoomCustomBackgroundByIdHookResultEx;

// Function
function useQueryZoomCustomBackgroundById({
  zoomCustomBackgroundId,
  options,
}: {
  zoomCustomBackgroundId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomCustomBackgroundByIdQuery, QueryZoomCustomBackgroundByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomCustomBackgroundByIdResultEx {
  const _query: QueryZoomCustomBackgroundByIdResult = useQuery<
    QueryZoomCustomBackgroundByIdQuery,
    QueryZoomCustomBackgroundByIdQueryVariables
  >(QueryZoomCustomBackgroundByIdDocument, { variables: { zoomCustomBackgroundId }, ...options });

  const typedSubscribeToMore: QueryZoomCustomBackgroundByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomCustomBackgroundByIdDocument,
      variables: { zoomCustomBackgroundId } as QueryZoomCustomBackgroundByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    zoomCustomBackground: query?.data?.zoomCustomBackground_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomCustomBackgroundByIdFn = (
  query: QueryZoomCustomBackgroundByIdQuery | null | undefined
) => ZoomCustomBackgroundFragment | null | undefined;
type QueryZoomCustomBackgroundByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomCustomBackgroundByIdQueryVariables> | undefined) => void,
  QueryZoomCustomBackgroundByIdResult
];
type QueryZoomCustomBackgroundByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomCustomBackgroundByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomCustomBackgroundByIdLazyReturn = [
  QueryZoomCustomBackgroundByIdWrappedLazyFn,
  QueryZoomCustomBackgroundByIdResultEx
];

// Function
function useQueryZoomCustomBackgroundByIdLazy({
  zoomCustomBackgroundId,
  options,
}: {
  zoomCustomBackgroundId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomCustomBackgroundByIdQuery, QueryZoomCustomBackgroundByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomCustomBackgroundByIdLazyReturn {
  const lazyQuery: QueryZoomCustomBackgroundByIdLazyFn = useLazyQuery<
    QueryZoomCustomBackgroundByIdQuery,
    QueryZoomCustomBackgroundByIdQueryVariables
  >(QueryZoomCustomBackgroundByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomCustomBackgroundById: PickQueryZoomCustomBackgroundByIdFn = (query) => {
    return query && query.zoomCustomBackground_by_pk;
  };
  const wrappedLazyQuery: QueryZoomCustomBackgroundByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomCustomBackgroundByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomCustomBackgroundByIdDocument,
        variables: { zoomCustomBackgroundId } as QueryZoomCustomBackgroundByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomCustomBackground: pickQueryZoomCustomBackgroundById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomCustomBackgroundObjectsResult = LazyQueryResult<
  QueryZoomCustomBackgroundObjectsQuery,
  QueryZoomCustomBackgroundObjectsQueryVariables
>;
type QueryZoomCustomBackgroundObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomCustomBackgroundObjectsQuery,
          QueryZoomCustomBackgroundObjectsQueryVariables,
          QueryZoomCustomBackgroundObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomCustomBackgroundObjectsResultEx = Omit<
  QueryZoomCustomBackgroundObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QueryZoomCustomBackgroundObjectsSubScribeToMore } & ZoomCustomBackgroundObjectsHookResultEx;

// Function
function useQueryZoomCustomBackgroundObjects(
  options: Omit<
    QueryHookOptions<QueryZoomCustomBackgroundObjectsQuery, QueryZoomCustomBackgroundObjectsQueryVariables>,
    'query'
  >
): QueryZoomCustomBackgroundObjectsResultEx {
  const _query: QueryZoomCustomBackgroundObjectsResult = useQuery<
    QueryZoomCustomBackgroundObjectsQuery,
    QueryZoomCustomBackgroundObjectsQueryVariables
  >(QueryZoomCustomBackgroundObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomCustomBackgroundObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomCustomBackgroundObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomCustomBackground || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomCustomBackgroundObjectsFn = (
  query: QueryZoomCustomBackgroundObjectsQuery | null | undefined
) => ZoomCustomBackgroundFragment[];
type QueryZoomCustomBackgroundObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomCustomBackgroundObjectsQueryVariables> | undefined) => void,
  QueryZoomCustomBackgroundObjectsResult
];
type QueryZoomCustomBackgroundObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomCustomBackgroundObjectsQueryVariables>
) => void;
export type QueryZoomCustomBackgroundObjectsLazyReturn = [
  QueryZoomCustomBackgroundObjectsWrappedLazyFn,
  QueryZoomCustomBackgroundObjectsResultEx
];

// Function
function useQueryZoomCustomBackgroundObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomCustomBackgroundObjectsQuery, QueryZoomCustomBackgroundObjectsQueryVariables>,
    'query'
  >
): QueryZoomCustomBackgroundObjectsLazyReturn {
  const lazyQuery: QueryZoomCustomBackgroundObjectsLazyFn = useLazyQuery<
    QueryZoomCustomBackgroundObjectsQuery,
    QueryZoomCustomBackgroundObjectsQueryVariables
  >(QueryZoomCustomBackgroundObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomCustomBackgroundObjectsFn = (
    query: QueryZoomCustomBackgroundObjectsQuery | null | undefined
  ) => {
    return query?.zoomCustomBackground || [];
  };
  const wrappedLazyQuery: QueryZoomCustomBackgroundObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomCustomBackgroundObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomCustomBackgroundObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomCustomBackgroundByIdResult = {
  variables?: SubscribeToZoomCustomBackgroundByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomCustomBackgroundByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomCustomBackgroundByIdResultEx = SubscribeToZoomCustomBackgroundByIdResult &
  ZoomCustomBackgroundByIdHookResultEx;

// Function
function useSubscribeToZoomCustomBackgroundById({
  zoomCustomBackgroundId,
  options,
}: {
  zoomCustomBackgroundId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomCustomBackgroundByIdSubscription,
      SubscribeToZoomCustomBackgroundByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomCustomBackgroundByIdResultEx {
  const subscription: SubscribeToZoomCustomBackgroundByIdResult = useSubscription<
    SubscribeToZoomCustomBackgroundByIdSubscription,
    SubscribeToZoomCustomBackgroundByIdSubscriptionVariables
  >(SubscribeToZoomCustomBackgroundByIdDocument, { variables: { zoomCustomBackgroundId }, ...options });
  return { ...subscription, zoomCustomBackground: subscription?.data?.zoomCustomBackground_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomCustomBackgroundObjectsResult = {
  variables?: SubscribeToZoomCustomBackgroundObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomCustomBackgroundObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomCustomBackgroundObjectsResultEx = SubscribeToZoomCustomBackgroundObjectsResult &
  ZoomCustomBackgroundObjectsHookResultEx;

// Function
function useSubscribeToZoomCustomBackgroundObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomCustomBackgroundObjectsSubscription,
      SubscribeToZoomCustomBackgroundObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomCustomBackgroundObjectsResultEx {
  const subscription: SubscribeToZoomCustomBackgroundObjectsResult = useSubscription<
    SubscribeToZoomCustomBackgroundObjectsSubscription,
    SubscribeToZoomCustomBackgroundObjectsSubscriptionVariables
  >(SubscribeToZoomCustomBackgroundObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomCustomBackground || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomCustomBackgroundMutationResult = FetchResult<
  InsertZoomCustomBackgroundMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomCustomBackgroundMutationResultEx = InsertZoomCustomBackgroundMutationResult &
  ZoomCustomBackgroundByIdHookResultEx;

type PickInsertZoomCustomBackgroundFn = (
  mutation: InsertZoomCustomBackgroundMutation | null | undefined
) => ZoomCustomBackgroundFragment | null | undefined;
type InsertZoomCustomBackgroundLazyMutationFn = MutationTuple<
  InsertZoomCustomBackgroundMutation,
  InsertZoomCustomBackgroundMutationVariables
>;
type InsertZoomCustomBackgroundWrappedLazyMutationFn = ({
  zoomCustomBackground,
  autoOptimisticResponse,
  options,
}: {
  zoomCustomBackground: ZoomCustomBackground_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomCustomBackgroundMutation, InsertZoomCustomBackgroundMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomCustomBackgroundMutationResultEx>;
export type InsertZoomCustomBackgroundLazyMutationReturn = [
  InsertZoomCustomBackgroundWrappedLazyMutationFn,
  InsertZoomCustomBackgroundMutationResultEx
];

// Function
function useInsertZoomCustomBackground(
  options?: Omit<
    MutationHookOptions<InsertZoomCustomBackgroundMutation, InsertZoomCustomBackgroundMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomCustomBackgroundLazyMutationReturn {
  const lazyMutation: InsertZoomCustomBackgroundLazyMutationFn = useMutation<
    InsertZoomCustomBackgroundMutation,
    InsertZoomCustomBackgroundMutationVariables
  >(InsertZoomCustomBackgroundDocument, options);
  const pickZoomCustomBackground: PickInsertZoomCustomBackgroundFn = (mutation) => {
    return mutation?.insert_zoomCustomBackground?.returning && mutation?.insert_zoomCustomBackground?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomCustomBackgroundWrappedLazyMutationFn = async ({
    zoomCustomBackground,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomCustomBackground });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomCustomBackgroundMutation,
      InsertZoomCustomBackgroundMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomCustomBackground'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomCustomBackgroundMutation>({
        operationType: 'insert',
        entityName: 'zoomCustomBackground',
        objects: [objectForInsert as ZoomCustomBackground_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomCustomBackground: pickZoomCustomBackground(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomCustomBackground: pickZoomCustomBackground(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomCustomBackgroundWithOnConflictMutationResult = FetchResult<
  InsertZoomCustomBackgroundWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomCustomBackgroundWithOnConflictMutationResultEx =
  InsertZoomCustomBackgroundWithOnConflictMutationResult & ZoomCustomBackgroundByIdHookResultEx;

type InsertZoomCustomBackgroundWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomCustomBackgroundWithOnConflictMutation,
  InsertZoomCustomBackgroundWithOnConflictMutationVariables
>;
type InsertZoomCustomBackgroundWithOnConflictWrappedLazyMutationFn = ({
  zoomCustomBackground,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomCustomBackground: ZoomCustomBackground_Insert_Input;
  onConflict: ZoomCustomBackground_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomCustomBackgroundWithOnConflictMutation,
      InsertZoomCustomBackgroundWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomCustomBackgroundWithOnConflictMutationResultEx>;
export type InsertZoomCustomBackgroundWithOnConflictLazyMutationReturn = [
  InsertZoomCustomBackgroundWithOnConflictWrappedLazyMutationFn,
  InsertZoomCustomBackgroundWithOnConflictMutationResultEx
];

// Function
function useInsertZoomCustomBackgroundWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomCustomBackgroundWithOnConflictMutation,
      InsertZoomCustomBackgroundWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomCustomBackgroundWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomCustomBackgroundWithOnConflictLazyMutationFn = useMutation<
    InsertZoomCustomBackgroundWithOnConflictMutation,
    InsertZoomCustomBackgroundWithOnConflictMutationVariables
  >(InsertZoomCustomBackgroundWithOnConflictDocument, options);
  const pickZoomCustomBackground: PickInsertZoomCustomBackgroundFn = (
    mutation: InsertZoomCustomBackgroundWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomCustomBackground?.returning && mutation.insert_zoomCustomBackground.returning[0];
  };

  const wrappedLazyMutation: InsertZoomCustomBackgroundWithOnConflictWrappedLazyMutationFn = async ({
    zoomCustomBackground,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomCustomBackground });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomCustomBackgroundWithOnConflictMutation,
      InsertZoomCustomBackgroundWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomCustomBackground'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomCustomBackgroundWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomCustomBackground',
          objects: [objectForInsert as ZoomCustomBackground_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomCustomBackground: pickZoomCustomBackground(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomCustomBackground: pickZoomCustomBackground(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomCustomBackgroundObjectsMutationResult = FetchResult<
  InsertZoomCustomBackgroundMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomCustomBackgroundObjectsMutationResultEx = InsertZoomCustomBackgroundMutationResult &
  ZoomCustomBackgroundObjectsHookResultEx;

type PickInsertZoomCustomBackgroundObjectsFn = (
  mutation: InsertZoomCustomBackgroundMutation | null | undefined
) => ZoomCustomBackgroundFragment[];
type InsertZoomCustomBackgroundObjectsLazyMutationFn = MutationTuple<
  InsertZoomCustomBackgroundMutation,
  InsertZoomCustomBackgroundMutationVariables
>;
type InsertZoomCustomBackgroundObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomCustomBackgroundMutation, InsertZoomCustomBackgroundMutationVariables>
) => Promise<InsertZoomCustomBackgroundObjectsMutationResultEx>;
export type InsertZoomCustomBackgroundObjectsLazyMutationReturn = [
  InsertZoomCustomBackgroundObjectsWrappedLazyMutationFn,
  InsertZoomCustomBackgroundObjectsMutationResultEx
];

// Function
function useInsertZoomCustomBackgroundObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomCustomBackgroundMutation, InsertZoomCustomBackgroundMutationVariables>,
    'mutation'
  >
): InsertZoomCustomBackgroundObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomCustomBackgroundObjectsLazyMutationFn = useMutation<
    InsertZoomCustomBackgroundMutation,
    InsertZoomCustomBackgroundMutationVariables
  >(InsertZoomCustomBackgroundDocument, options);
  const pickObjects: PickInsertZoomCustomBackgroundObjectsFn = (
    mutation: InsertZoomCustomBackgroundMutation | null | undefined
  ) => {
    return mutation?.insert_zoomCustomBackground?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomCustomBackgroundObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomCustomBackgroundObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomCustomBackgroundByIdMutationResult = FetchResult<
  UpdateZoomCustomBackgroundByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomCustomBackgroundByIdMutationResultEx = UpdateZoomCustomBackgroundByIdMutationResult &
  ZoomCustomBackgroundByIdHookResultEx;

type PickUpdateZoomCustomBackgroundByIdFn = (
  mutation: UpdateZoomCustomBackgroundByIdMutation | null | undefined
) => ZoomCustomBackgroundFragment | null | undefined;
type UpdateZoomCustomBackgroundByIdLazyMutationFn = MutationTuple<
  UpdateZoomCustomBackgroundByIdMutation,
  UpdateZoomCustomBackgroundByIdMutationVariables
>;
type UpdateZoomCustomBackgroundByIdWrappedLazyMutationFn = ({
  zoomCustomBackgroundId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomCustomBackgroundId: string;
  set: ZoomCustomBackground_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomCustomBackgroundByIdMutation, UpdateZoomCustomBackgroundByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomCustomBackgroundByIdMutationResultEx>;
export type UpdateZoomCustomBackgroundByIdLazyMutationReturn = [
  UpdateZoomCustomBackgroundByIdWrappedLazyMutationFn,
  UpdateZoomCustomBackgroundByIdMutationResultEx
];

function useUpdateZoomCustomBackgroundById(
  options?: Omit<
    MutationHookOptions<UpdateZoomCustomBackgroundByIdMutation, UpdateZoomCustomBackgroundByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomCustomBackgroundByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomCustomBackgroundByIdLazyMutationFn = useMutation<
    UpdateZoomCustomBackgroundByIdMutation,
    UpdateZoomCustomBackgroundByIdMutationVariables
  >(UpdateZoomCustomBackgroundByIdDocument, options);

  const pickZoomCustomBackground: PickUpdateZoomCustomBackgroundByIdFn = (mutation) => {
    return mutation?.update_zoomCustomBackground?.returning && mutation.update_zoomCustomBackground!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomCustomBackgroundByIdWrappedLazyMutationFn = async ({
    zoomCustomBackgroundId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomCustomBackgroundByIdMutation,
      UpdateZoomCustomBackgroundByIdMutationVariables
    > = { variables: { id: zoomCustomBackgroundId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<UpdateZoomCustomBackgroundByIdMutation>({
          operationType: 'update',
          entityName: 'zoomCustomBackground',
          objects: [{ id: zoomCustomBackgroundId, ...set }],
        });
    }

    const fetchResult: UpdateZoomCustomBackgroundByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomCustomBackgroundId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomCustomBackground: pickZoomCustomBackground(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomCustomBackground: pickZoomCustomBackground(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomCustomBackgroundObjectsMutationResult = FetchResult<
  UpdateZoomCustomBackgroundMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomCustomBackgroundObjectsMutationResultEx = UpdateZoomCustomBackgroundObjectsMutationResult &
  ZoomCustomBackgroundObjectsHookResultEx;

// Function
type PickUpdateZoomCustomBackgroundObjectsFn = (
  mutation: UpdateZoomCustomBackgroundMutation | null | undefined
) => ZoomCustomBackgroundFragment[];
type UpdateZoomCustomBackgroundObjectsLazyMutationFn = MutationTuple<
  UpdateZoomCustomBackgroundMutation,
  UpdateZoomCustomBackgroundMutationVariables
>;
type UpdateZoomCustomBackgroundObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomCustomBackgroundMutation, UpdateZoomCustomBackgroundMutationVariables>
) => Promise<UpdateZoomCustomBackgroundObjectsMutationResultEx>;
export type UpdateZoomCustomBackgroundObjectsLazyMutationReturn = [
  UpdateZoomCustomBackgroundObjectsWrappedLazyMutationFn,
  UpdateZoomCustomBackgroundObjectsMutationResultEx
];

function useUpdateZoomCustomBackgroundObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomCustomBackgroundMutation, UpdateZoomCustomBackgroundMutationVariables>,
    'mutation'
  >
): UpdateZoomCustomBackgroundObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomCustomBackgroundObjectsLazyMutationFn = useMutation<
    UpdateZoomCustomBackgroundMutation,
    UpdateZoomCustomBackgroundMutationVariables
  >(UpdateZoomCustomBackgroundDocument, options);

  const pickObjects: PickUpdateZoomCustomBackgroundObjectsFn = (
    mutation: UpdateZoomCustomBackgroundMutation | null | undefined
  ) => {
    return mutation?.update_zoomCustomBackground?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomCustomBackgroundObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomCustomBackgroundObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomCustomBackgroundModelByIdFetchResult = FetchResult<
  RemoveZoomCustomBackgroundModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomCustomBackgroundModelByIdMutationResultEx = RemoveZoomCustomBackgroundModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomCustomBackgroundModelFn = (
  mutation: RemoveZoomCustomBackgroundModelByIdMutation | null | undefined
) => number;
type RemoveZoomCustomBackgroundModelLazyMutationFn = MutationTuple<
  RemoveZoomCustomBackgroundModelByIdMutation,
  RemoveZoomCustomBackgroundModelByIdMutationVariables
>;
type RemoveZoomCustomBackgroundModelWrappedLazyMutationFn = ({
  zoomCustomBackgroundId,
  autoOptimisticResponse,
  options,
}: {
  zoomCustomBackgroundId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveZoomCustomBackgroundModelByIdMutation,
      RemoveZoomCustomBackgroundModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveZoomCustomBackgroundModelByIdMutationResultEx>;
export type RemoveZoomCustomBackgroundModelLazyMutationReturn = [
  RemoveZoomCustomBackgroundModelWrappedLazyMutationFn,
  RemoveZoomCustomBackgroundModelByIdMutationResultEx
];

function useRemoveZoomCustomBackgroundModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomCustomBackgroundModelByIdMutation,
      RemoveZoomCustomBackgroundModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomCustomBackgroundModelLazyMutationFn = useMutation<
    RemoveZoomCustomBackgroundModelByIdMutation,
    RemoveZoomCustomBackgroundModelByIdMutationVariables
  >(RemoveZoomCustomBackgroundModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomCustomBackgroundModelFn = (
    mutation: RemoveZoomCustomBackgroundModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomCustomBackground?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomCustomBackgroundModelWrappedLazyMutationFn = async ({
    zoomCustomBackgroundId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomCustomBackgroundModelMutation,
      RemoveZoomCustomBackgroundModelByIdMutationVariables
    > = { variables: { id: zoomCustomBackgroundId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<RemoveZoomCustomBackgroundModelMutation>({
          operationType: 'delete',
          entityName: 'zoomCustomBackground',
          objects: [{ id: zoomCustomBackgroundId }],
        });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomCustomBackgroundModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomCustomBackground',
        entityId: zoomCustomBackgroundId,
      });
    }

    const fetchResult: RemoveZoomCustomBackgroundModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomCustomBackgroundModelObjectsMutationResult = FetchResult<
  RemoveZoomCustomBackgroundModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomCustomBackgroundModelObjectsMutationResultEx =
  RemoveZoomCustomBackgroundModelObjectsMutationResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomCustomBackgroundModelObjectsFn = (
  mutation: RemoveZoomCustomBackgroundModelMutation | null | undefined
) => number;
type RemoveZoomCustomBackgroundModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomCustomBackgroundModelMutation,
  RemoveZoomCustomBackgroundModelMutationVariables
>;
type RemoveZoomCustomBackgroundModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveZoomCustomBackgroundModelMutation,
    RemoveZoomCustomBackgroundModelMutationVariables
  >
) => Promise<RemoveZoomCustomBackgroundModelObjectsMutationResultEx>;
export type RemoveZoomCustomBackgroundModelObjectsLazyMutationReturn = [
  RemoveZoomCustomBackgroundModelObjectsWrappedLazyMutationFn,
  RemoveZoomCustomBackgroundModelObjectsMutationResultEx
];

function useRemoveZoomCustomBackgroundModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomCustomBackgroundModelMutation, RemoveZoomCustomBackgroundModelMutationVariables>,
    'mutation'
  >
): RemoveZoomCustomBackgroundModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveZoomCustomBackgroundModelMutation,
    RemoveZoomCustomBackgroundModelMutationVariables
  >(RemoveZoomCustomBackgroundModelDocument, options);

  const pickAffectedRows: PickRemoveZoomCustomBackgroundModelObjectsFn = (
    mutation: RemoveZoomCustomBackgroundModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomCustomBackground?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomCustomBackgroundModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomCustomBackgroundModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomCustomBackground Fragment Helper Object
//------------------------------------------------

export const ZoomCustomBackgroundFragmentGQLHooks = {
  useQueryById: useQueryZoomCustomBackgroundById,
  useQueryByIdLazy: useQueryZoomCustomBackgroundByIdLazy,
  useQueryObjects: useQueryZoomCustomBackgroundObjects,
  useQueryObjectsLazy: useQueryZoomCustomBackgroundObjectsLazy,
  useSubscribeToById: useSubscribeToZoomCustomBackgroundById,
  useSubscribeToObjects: useSubscribeToZoomCustomBackgroundObjects,
  useInsert: useInsertZoomCustomBackground,
  useInsertWithOnConflict: useInsertZoomCustomBackgroundWithOnConflict,
  useInsertObjects: useInsertZoomCustomBackgroundObjects,
  useUpdateById: useUpdateZoomCustomBackgroundById,
  useUpdateObjects: useUpdateZoomCustomBackgroundObjects,
};

// zoomCustomBackground MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomCustomBackgroundModelGQLHooks = {
  useRemoveById: useRemoveZoomCustomBackgroundModelById,
  useRemoveObjects: useRemoveZoomCustomBackgroundModelObjects,
};

// zoomCustomBackground REACT
//------------------------------------------------

export type ZoomGlobalCustomBackgroundByIdHookResultEx = {
  zoomGlobalCustomBackground: ZoomGlobalCustomBackgroundFragment | null | undefined;
};
export type ZoomGlobalCustomBackgroundObjectsHookResultEx = { objects: ZoomGlobalCustomBackgroundFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomGlobalCustomBackgroundByIdResult = LazyQueryResult<
  QueryZoomGlobalCustomBackgroundByIdQuery,
  QueryZoomGlobalCustomBackgroundByIdQueryVariables
>;
type QueryZoomGlobalCustomBackgroundByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomGlobalCustomBackgroundByIdQuery,
          QueryZoomGlobalCustomBackgroundByIdQueryVariables,
          QueryZoomGlobalCustomBackgroundByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomGlobalCustomBackgroundByIdResultEx = Omit<
  QueryZoomGlobalCustomBackgroundByIdResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryZoomGlobalCustomBackgroundByIdSubScribeToMore;
} & ZoomGlobalCustomBackgroundByIdHookResultEx;

// Function
function useQueryZoomGlobalCustomBackgroundById({
  zoomCustomBackgroundId,
  options,
}: {
  zoomCustomBackgroundId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomGlobalCustomBackgroundByIdQuery, QueryZoomGlobalCustomBackgroundByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomGlobalCustomBackgroundByIdResultEx {
  const _query: QueryZoomGlobalCustomBackgroundByIdResult = useQuery<
    QueryZoomGlobalCustomBackgroundByIdQuery,
    QueryZoomGlobalCustomBackgroundByIdQueryVariables
  >(QueryZoomGlobalCustomBackgroundByIdDocument, { variables: { zoomCustomBackgroundId }, ...options });

  const typedSubscribeToMore: QueryZoomGlobalCustomBackgroundByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomGlobalCustomBackgroundByIdDocument,
      variables: { zoomCustomBackgroundId } as QueryZoomGlobalCustomBackgroundByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    zoomGlobalCustomBackground: query?.data?.zoomCustomBackground_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomGlobalCustomBackgroundByIdFn = (
  query: QueryZoomGlobalCustomBackgroundByIdQuery | null | undefined
) => ZoomGlobalCustomBackgroundFragment | null | undefined;
type QueryZoomGlobalCustomBackgroundByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomGlobalCustomBackgroundByIdQueryVariables> | undefined) => void,
  QueryZoomGlobalCustomBackgroundByIdResult
];
type QueryZoomGlobalCustomBackgroundByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomGlobalCustomBackgroundByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomGlobalCustomBackgroundByIdLazyReturn = [
  QueryZoomGlobalCustomBackgroundByIdWrappedLazyFn,
  QueryZoomGlobalCustomBackgroundByIdResultEx
];

// Function
function useQueryZoomGlobalCustomBackgroundByIdLazy({
  zoomCustomBackgroundId,
  options,
}: {
  zoomCustomBackgroundId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomGlobalCustomBackgroundByIdQuery, QueryZoomGlobalCustomBackgroundByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomGlobalCustomBackgroundByIdLazyReturn {
  const lazyQuery: QueryZoomGlobalCustomBackgroundByIdLazyFn = useLazyQuery<
    QueryZoomGlobalCustomBackgroundByIdQuery,
    QueryZoomGlobalCustomBackgroundByIdQueryVariables
  >(QueryZoomGlobalCustomBackgroundByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomGlobalCustomBackgroundById: PickQueryZoomGlobalCustomBackgroundByIdFn = (query) => {
    return query && query.zoomCustomBackground_by_pk;
  };
  const wrappedLazyQuery: QueryZoomGlobalCustomBackgroundByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomGlobalCustomBackgroundByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomGlobalCustomBackgroundByIdDocument,
        variables: { zoomCustomBackgroundId } as QueryZoomGlobalCustomBackgroundByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomGlobalCustomBackground: pickQueryZoomGlobalCustomBackgroundById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomGlobalCustomBackgroundObjectsResult = LazyQueryResult<
  QueryZoomGlobalCustomBackgroundObjectsQuery,
  QueryZoomGlobalCustomBackgroundObjectsQueryVariables
>;
type QueryZoomGlobalCustomBackgroundObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomGlobalCustomBackgroundObjectsQuery,
          QueryZoomGlobalCustomBackgroundObjectsQueryVariables,
          QueryZoomGlobalCustomBackgroundObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomGlobalCustomBackgroundObjectsResultEx = Omit<
  QueryZoomGlobalCustomBackgroundObjectsResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryZoomGlobalCustomBackgroundObjectsSubScribeToMore;
} & ZoomGlobalCustomBackgroundObjectsHookResultEx;

// Function
function useQueryZoomGlobalCustomBackgroundObjects(
  options: Omit<
    QueryHookOptions<QueryZoomGlobalCustomBackgroundObjectsQuery, QueryZoomGlobalCustomBackgroundObjectsQueryVariables>,
    'query'
  >
): QueryZoomGlobalCustomBackgroundObjectsResultEx {
  const _query: QueryZoomGlobalCustomBackgroundObjectsResult = useQuery<
    QueryZoomGlobalCustomBackgroundObjectsQuery,
    QueryZoomGlobalCustomBackgroundObjectsQueryVariables
  >(QueryZoomGlobalCustomBackgroundObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomGlobalCustomBackgroundObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomGlobalCustomBackgroundObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomCustomBackground || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomGlobalCustomBackgroundObjectsFn = (
  query: QueryZoomGlobalCustomBackgroundObjectsQuery | null | undefined
) => ZoomGlobalCustomBackgroundFragment[];
type QueryZoomGlobalCustomBackgroundObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomGlobalCustomBackgroundObjectsQueryVariables> | undefined) => void,
  QueryZoomGlobalCustomBackgroundObjectsResult
];
type QueryZoomGlobalCustomBackgroundObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomGlobalCustomBackgroundObjectsQueryVariables>
) => void;
export type QueryZoomGlobalCustomBackgroundObjectsLazyReturn = [
  QueryZoomGlobalCustomBackgroundObjectsWrappedLazyFn,
  QueryZoomGlobalCustomBackgroundObjectsResultEx
];

// Function
function useQueryZoomGlobalCustomBackgroundObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<
      QueryZoomGlobalCustomBackgroundObjectsQuery,
      QueryZoomGlobalCustomBackgroundObjectsQueryVariables
    >,
    'query'
  >
): QueryZoomGlobalCustomBackgroundObjectsLazyReturn {
  const lazyQuery: QueryZoomGlobalCustomBackgroundObjectsLazyFn = useLazyQuery<
    QueryZoomGlobalCustomBackgroundObjectsQuery,
    QueryZoomGlobalCustomBackgroundObjectsQueryVariables
  >(QueryZoomGlobalCustomBackgroundObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomGlobalCustomBackgroundObjectsFn = (
    query: QueryZoomGlobalCustomBackgroundObjectsQuery | null | undefined
  ) => {
    return query?.zoomCustomBackground || [];
  };
  const wrappedLazyQuery: QueryZoomGlobalCustomBackgroundObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomGlobalCustomBackgroundObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomGlobalCustomBackgroundObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomGlobalCustomBackgroundByIdResult = {
  variables?: SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomGlobalCustomBackgroundByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomGlobalCustomBackgroundByIdResultEx = SubscribeToZoomGlobalCustomBackgroundByIdResult &
  ZoomGlobalCustomBackgroundByIdHookResultEx;

// Function
function useSubscribeToZoomGlobalCustomBackgroundById({
  zoomCustomBackgroundId,
  options,
}: {
  zoomCustomBackgroundId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomGlobalCustomBackgroundByIdSubscription,
      SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomGlobalCustomBackgroundByIdResultEx {
  const subscription: SubscribeToZoomGlobalCustomBackgroundByIdResult = useSubscription<
    SubscribeToZoomGlobalCustomBackgroundByIdSubscription,
    SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionVariables
  >(SubscribeToZoomGlobalCustomBackgroundByIdDocument, { variables: { zoomCustomBackgroundId }, ...options });
  return { ...subscription, zoomGlobalCustomBackground: subscription?.data?.zoomCustomBackground_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomGlobalCustomBackgroundObjectsResult = {
  variables?: SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomGlobalCustomBackgroundObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomGlobalCustomBackgroundObjectsResultEx = SubscribeToZoomGlobalCustomBackgroundObjectsResult &
  ZoomGlobalCustomBackgroundObjectsHookResultEx;

// Function
function useSubscribeToZoomGlobalCustomBackgroundObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomGlobalCustomBackgroundObjectsSubscription,
      SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomGlobalCustomBackgroundObjectsResultEx {
  const subscription: SubscribeToZoomGlobalCustomBackgroundObjectsResult = useSubscription<
    SubscribeToZoomGlobalCustomBackgroundObjectsSubscription,
    SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionVariables
  >(SubscribeToZoomGlobalCustomBackgroundObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomCustomBackground || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomGlobalCustomBackgroundMutationResult = FetchResult<
  InsertZoomGlobalCustomBackgroundMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomGlobalCustomBackgroundMutationResultEx = InsertZoomGlobalCustomBackgroundMutationResult &
  ZoomGlobalCustomBackgroundByIdHookResultEx;

type PickInsertZoomGlobalCustomBackgroundFn = (
  mutation: InsertZoomGlobalCustomBackgroundMutation | null | undefined
) => ZoomGlobalCustomBackgroundFragment | null | undefined;
type InsertZoomGlobalCustomBackgroundLazyMutationFn = MutationTuple<
  InsertZoomGlobalCustomBackgroundMutation,
  InsertZoomGlobalCustomBackgroundMutationVariables
>;
type InsertZoomGlobalCustomBackgroundWrappedLazyMutationFn = ({
  zoomCustomBackground,
  autoOptimisticResponse,
  options,
}: {
  zoomCustomBackground: ZoomCustomBackground_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomGlobalCustomBackgroundMutation,
      InsertZoomGlobalCustomBackgroundMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomGlobalCustomBackgroundMutationResultEx>;
export type InsertZoomGlobalCustomBackgroundLazyMutationReturn = [
  InsertZoomGlobalCustomBackgroundWrappedLazyMutationFn,
  InsertZoomGlobalCustomBackgroundMutationResultEx
];

// Function
function useInsertZoomGlobalCustomBackground(
  options?: Omit<
    MutationHookOptions<InsertZoomGlobalCustomBackgroundMutation, InsertZoomGlobalCustomBackgroundMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomGlobalCustomBackgroundLazyMutationReturn {
  const lazyMutation: InsertZoomGlobalCustomBackgroundLazyMutationFn = useMutation<
    InsertZoomGlobalCustomBackgroundMutation,
    InsertZoomGlobalCustomBackgroundMutationVariables
  >(InsertZoomGlobalCustomBackgroundDocument, options);
  const pickZoomGlobalCustomBackground: PickInsertZoomGlobalCustomBackgroundFn = (mutation) => {
    return mutation?.insert_zoomCustomBackground?.returning && mutation?.insert_zoomCustomBackground?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomGlobalCustomBackgroundWrappedLazyMutationFn = async ({
    zoomCustomBackground,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomCustomBackground });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomGlobalCustomBackgroundMutation,
      InsertZoomGlobalCustomBackgroundMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomCustomBackground'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomGlobalCustomBackgroundMutation>({
          operationType: 'insert',
          entityName: 'zoomCustomBackground',
          objects: [objectForInsert as ZoomCustomBackground_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomGlobalCustomBackground: pickZoomGlobalCustomBackground(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomGlobalCustomBackground: pickZoomGlobalCustomBackground(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomGlobalCustomBackgroundWithOnConflictMutationResult = FetchResult<
  InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomGlobalCustomBackgroundWithOnConflictMutationResultEx =
  InsertZoomGlobalCustomBackgroundWithOnConflictMutationResult & ZoomGlobalCustomBackgroundByIdHookResultEx;

type InsertZoomGlobalCustomBackgroundWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
  InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
>;
type InsertZoomGlobalCustomBackgroundWithOnConflictWrappedLazyMutationFn = ({
  zoomCustomBackground,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomCustomBackground: ZoomCustomBackground_Insert_Input;
  onConflict: ZoomCustomBackground_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
      InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomGlobalCustomBackgroundWithOnConflictMutationResultEx>;
export type InsertZoomGlobalCustomBackgroundWithOnConflictLazyMutationReturn = [
  InsertZoomGlobalCustomBackgroundWithOnConflictWrappedLazyMutationFn,
  InsertZoomGlobalCustomBackgroundWithOnConflictMutationResultEx
];

// Function
function useInsertZoomGlobalCustomBackgroundWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
      InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomGlobalCustomBackgroundWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomGlobalCustomBackgroundWithOnConflictLazyMutationFn = useMutation<
    InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
    InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
  >(InsertZoomGlobalCustomBackgroundWithOnConflictDocument, options);
  const pickZoomGlobalCustomBackground: PickInsertZoomGlobalCustomBackgroundFn = (
    mutation: InsertZoomGlobalCustomBackgroundWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomCustomBackground?.returning && mutation.insert_zoomCustomBackground.returning[0];
  };

  const wrappedLazyMutation: InsertZoomGlobalCustomBackgroundWithOnConflictWrappedLazyMutationFn = async ({
    zoomCustomBackground,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomCustomBackground });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
      InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomCustomBackground'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomGlobalCustomBackgroundWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomCustomBackground',
          objects: [objectForInsert as ZoomCustomBackground_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomGlobalCustomBackground: pickZoomGlobalCustomBackground(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomGlobalCustomBackground: pickZoomGlobalCustomBackground(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomGlobalCustomBackgroundObjectsMutationResult = FetchResult<
  InsertZoomGlobalCustomBackgroundMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomGlobalCustomBackgroundObjectsMutationResultEx = InsertZoomGlobalCustomBackgroundMutationResult &
  ZoomGlobalCustomBackgroundObjectsHookResultEx;

type PickInsertZoomGlobalCustomBackgroundObjectsFn = (
  mutation: InsertZoomGlobalCustomBackgroundMutation | null | undefined
) => ZoomGlobalCustomBackgroundFragment[];
type InsertZoomGlobalCustomBackgroundObjectsLazyMutationFn = MutationTuple<
  InsertZoomGlobalCustomBackgroundMutation,
  InsertZoomGlobalCustomBackgroundMutationVariables
>;
type InsertZoomGlobalCustomBackgroundObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    InsertZoomGlobalCustomBackgroundMutation,
    InsertZoomGlobalCustomBackgroundMutationVariables
  >
) => Promise<InsertZoomGlobalCustomBackgroundObjectsMutationResultEx>;
export type InsertZoomGlobalCustomBackgroundObjectsLazyMutationReturn = [
  InsertZoomGlobalCustomBackgroundObjectsWrappedLazyMutationFn,
  InsertZoomGlobalCustomBackgroundObjectsMutationResultEx
];

// Function
function useInsertZoomGlobalCustomBackgroundObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomGlobalCustomBackgroundMutation, InsertZoomGlobalCustomBackgroundMutationVariables>,
    'mutation'
  >
): InsertZoomGlobalCustomBackgroundObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomGlobalCustomBackgroundObjectsLazyMutationFn = useMutation<
    InsertZoomGlobalCustomBackgroundMutation,
    InsertZoomGlobalCustomBackgroundMutationVariables
  >(InsertZoomGlobalCustomBackgroundDocument, options);
  const pickObjects: PickInsertZoomGlobalCustomBackgroundObjectsFn = (
    mutation: InsertZoomGlobalCustomBackgroundMutation | null | undefined
  ) => {
    return mutation?.insert_zoomCustomBackground?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomGlobalCustomBackgroundObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomGlobalCustomBackgroundObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomGlobalCustomBackgroundByIdMutationResult = FetchResult<
  UpdateZoomGlobalCustomBackgroundByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomGlobalCustomBackgroundByIdMutationResultEx = UpdateZoomGlobalCustomBackgroundByIdMutationResult &
  ZoomGlobalCustomBackgroundByIdHookResultEx;

type PickUpdateZoomGlobalCustomBackgroundByIdFn = (
  mutation: UpdateZoomGlobalCustomBackgroundByIdMutation | null | undefined
) => ZoomGlobalCustomBackgroundFragment | null | undefined;
type UpdateZoomGlobalCustomBackgroundByIdLazyMutationFn = MutationTuple<
  UpdateZoomGlobalCustomBackgroundByIdMutation,
  UpdateZoomGlobalCustomBackgroundByIdMutationVariables
>;
type UpdateZoomGlobalCustomBackgroundByIdWrappedLazyMutationFn = ({
  zoomCustomBackgroundId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomCustomBackgroundId: string;
  set: ZoomCustomBackground_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateZoomGlobalCustomBackgroundByIdMutation,
      UpdateZoomGlobalCustomBackgroundByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateZoomGlobalCustomBackgroundByIdMutationResultEx>;
export type UpdateZoomGlobalCustomBackgroundByIdLazyMutationReturn = [
  UpdateZoomGlobalCustomBackgroundByIdWrappedLazyMutationFn,
  UpdateZoomGlobalCustomBackgroundByIdMutationResultEx
];

function useUpdateZoomGlobalCustomBackgroundById(
  options?: Omit<
    MutationHookOptions<
      UpdateZoomGlobalCustomBackgroundByIdMutation,
      UpdateZoomGlobalCustomBackgroundByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
): UpdateZoomGlobalCustomBackgroundByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomGlobalCustomBackgroundByIdLazyMutationFn = useMutation<
    UpdateZoomGlobalCustomBackgroundByIdMutation,
    UpdateZoomGlobalCustomBackgroundByIdMutationVariables
  >(UpdateZoomGlobalCustomBackgroundByIdDocument, options);

  const pickZoomGlobalCustomBackground: PickUpdateZoomGlobalCustomBackgroundByIdFn = (mutation) => {
    return mutation?.update_zoomCustomBackground?.returning && mutation.update_zoomCustomBackground!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomGlobalCustomBackgroundByIdWrappedLazyMutationFn = async ({
    zoomCustomBackgroundId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomGlobalCustomBackgroundByIdMutation,
      UpdateZoomGlobalCustomBackgroundByIdMutationVariables
    > = { variables: { id: zoomCustomBackgroundId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<UpdateZoomGlobalCustomBackgroundByIdMutation>({
          operationType: 'update',
          entityName: 'zoomCustomBackground',
          objects: [{ id: zoomCustomBackgroundId, ...set }],
        });
    }

    const fetchResult: UpdateZoomGlobalCustomBackgroundByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomCustomBackgroundId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomGlobalCustomBackground: pickZoomGlobalCustomBackground(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomGlobalCustomBackground: pickZoomGlobalCustomBackground(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomGlobalCustomBackgroundObjectsMutationResult = FetchResult<
  UpdateZoomGlobalCustomBackgroundMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomGlobalCustomBackgroundObjectsMutationResultEx =
  UpdateZoomGlobalCustomBackgroundObjectsMutationResult & ZoomGlobalCustomBackgroundObjectsHookResultEx;

// Function
type PickUpdateZoomGlobalCustomBackgroundObjectsFn = (
  mutation: UpdateZoomGlobalCustomBackgroundMutation | null | undefined
) => ZoomGlobalCustomBackgroundFragment[];
type UpdateZoomGlobalCustomBackgroundObjectsLazyMutationFn = MutationTuple<
  UpdateZoomGlobalCustomBackgroundMutation,
  UpdateZoomGlobalCustomBackgroundMutationVariables
>;
type UpdateZoomGlobalCustomBackgroundObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    UpdateZoomGlobalCustomBackgroundMutation,
    UpdateZoomGlobalCustomBackgroundMutationVariables
  >
) => Promise<UpdateZoomGlobalCustomBackgroundObjectsMutationResultEx>;
export type UpdateZoomGlobalCustomBackgroundObjectsLazyMutationReturn = [
  UpdateZoomGlobalCustomBackgroundObjectsWrappedLazyMutationFn,
  UpdateZoomGlobalCustomBackgroundObjectsMutationResultEx
];

function useUpdateZoomGlobalCustomBackgroundObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomGlobalCustomBackgroundMutation, UpdateZoomGlobalCustomBackgroundMutationVariables>,
    'mutation'
  >
): UpdateZoomGlobalCustomBackgroundObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomGlobalCustomBackgroundObjectsLazyMutationFn = useMutation<
    UpdateZoomGlobalCustomBackgroundMutation,
    UpdateZoomGlobalCustomBackgroundMutationVariables
  >(UpdateZoomGlobalCustomBackgroundDocument, options);

  const pickObjects: PickUpdateZoomGlobalCustomBackgroundObjectsFn = (
    mutation: UpdateZoomGlobalCustomBackgroundMutation | null | undefined
  ) => {
    return mutation?.update_zoomCustomBackground?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomGlobalCustomBackgroundObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomGlobalCustomBackgroundObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

// ZoomGlobalCustomBackground Fragment Helper Object
//------------------------------------------------

export const ZoomGlobalCustomBackgroundFragmentGQLHooks = {
  useQueryById: useQueryZoomGlobalCustomBackgroundById,
  useQueryByIdLazy: useQueryZoomGlobalCustomBackgroundByIdLazy,
  useQueryObjects: useQueryZoomGlobalCustomBackgroundObjects,
  useQueryObjectsLazy: useQueryZoomGlobalCustomBackgroundObjectsLazy,
  useSubscribeToById: useSubscribeToZoomGlobalCustomBackgroundById,
  useSubscribeToObjects: useSubscribeToZoomGlobalCustomBackgroundObjects,
  useInsert: useInsertZoomGlobalCustomBackground,
  useInsertWithOnConflict: useInsertZoomGlobalCustomBackgroundWithOnConflict,
  useInsertObjects: useInsertZoomGlobalCustomBackgroundObjects,
  useUpdateById: useUpdateZoomGlobalCustomBackgroundById,
  useUpdateObjects: useUpdateZoomGlobalCustomBackgroundObjects,
};

// zoomConnectionRequest REACT
//------------------------------------------------

export type ZoomConnectionRequestByIdHookResultEx = {
  zoomConnectionRequest: ZoomConnectionRequestFragment | null | undefined;
};
export type ZoomConnectionRequestObjectsHookResultEx = { objects: ZoomConnectionRequestFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomConnectionRequestByIdResult = LazyQueryResult<
  QueryZoomConnectionRequestByIdQuery,
  QueryZoomConnectionRequestByIdQueryVariables
>;
type QueryZoomConnectionRequestByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomConnectionRequestByIdQuery,
          QueryZoomConnectionRequestByIdQueryVariables,
          QueryZoomConnectionRequestByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomConnectionRequestByIdResultEx = Omit<QueryZoomConnectionRequestByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomConnectionRequestByIdSubScribeToMore;
} & ZoomConnectionRequestByIdHookResultEx;

// Function
function useQueryZoomConnectionRequestById({
  zoomConnectionRequestId,
  options,
}: {
  zoomConnectionRequestId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomConnectionRequestByIdQuery, QueryZoomConnectionRequestByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomConnectionRequestByIdResultEx {
  const _query: QueryZoomConnectionRequestByIdResult = useQuery<
    QueryZoomConnectionRequestByIdQuery,
    QueryZoomConnectionRequestByIdQueryVariables
  >(QueryZoomConnectionRequestByIdDocument, { variables: { zoomConnectionRequestId }, ...options });

  const typedSubscribeToMore: QueryZoomConnectionRequestByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomConnectionRequestByIdDocument,
      variables: { zoomConnectionRequestId } as QueryZoomConnectionRequestByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    zoomConnectionRequest: query?.data?.zoomConnectionRequest_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomConnectionRequestByIdFn = (
  query: QueryZoomConnectionRequestByIdQuery | null | undefined
) => ZoomConnectionRequestFragment | null | undefined;
type QueryZoomConnectionRequestByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomConnectionRequestByIdQueryVariables> | undefined) => void,
  QueryZoomConnectionRequestByIdResult
];
type QueryZoomConnectionRequestByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomConnectionRequestByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomConnectionRequestByIdLazyReturn = [
  QueryZoomConnectionRequestByIdWrappedLazyFn,
  QueryZoomConnectionRequestByIdResultEx
];

// Function
function useQueryZoomConnectionRequestByIdLazy({
  zoomConnectionRequestId,
  options,
}: {
  zoomConnectionRequestId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomConnectionRequestByIdQuery, QueryZoomConnectionRequestByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomConnectionRequestByIdLazyReturn {
  const lazyQuery: QueryZoomConnectionRequestByIdLazyFn = useLazyQuery<
    QueryZoomConnectionRequestByIdQuery,
    QueryZoomConnectionRequestByIdQueryVariables
  >(QueryZoomConnectionRequestByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomConnectionRequestById: PickQueryZoomConnectionRequestByIdFn = (query) => {
    return query && query.zoomConnectionRequest_by_pk;
  };
  const wrappedLazyQuery: QueryZoomConnectionRequestByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomConnectionRequestByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomConnectionRequestByIdDocument,
        variables: { zoomConnectionRequestId } as QueryZoomConnectionRequestByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomConnectionRequest: pickQueryZoomConnectionRequestById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomConnectionRequestObjectsResult = LazyQueryResult<
  QueryZoomConnectionRequestObjectsQuery,
  QueryZoomConnectionRequestObjectsQueryVariables
>;
type QueryZoomConnectionRequestObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomConnectionRequestObjectsQuery,
          QueryZoomConnectionRequestObjectsQueryVariables,
          QueryZoomConnectionRequestObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomConnectionRequestObjectsResultEx = Omit<
  QueryZoomConnectionRequestObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QueryZoomConnectionRequestObjectsSubScribeToMore } & ZoomConnectionRequestObjectsHookResultEx;

// Function
function useQueryZoomConnectionRequestObjects(
  options: Omit<
    QueryHookOptions<QueryZoomConnectionRequestObjectsQuery, QueryZoomConnectionRequestObjectsQueryVariables>,
    'query'
  >
): QueryZoomConnectionRequestObjectsResultEx {
  const _query: QueryZoomConnectionRequestObjectsResult = useQuery<
    QueryZoomConnectionRequestObjectsQuery,
    QueryZoomConnectionRequestObjectsQueryVariables
  >(QueryZoomConnectionRequestObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomConnectionRequestObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomConnectionRequestObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomConnectionRequest || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomConnectionRequestObjectsFn = (
  query: QueryZoomConnectionRequestObjectsQuery | null | undefined
) => ZoomConnectionRequestFragment[];
type QueryZoomConnectionRequestObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomConnectionRequestObjectsQueryVariables> | undefined) => void,
  QueryZoomConnectionRequestObjectsResult
];
type QueryZoomConnectionRequestObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomConnectionRequestObjectsQueryVariables>
) => void;
export type QueryZoomConnectionRequestObjectsLazyReturn = [
  QueryZoomConnectionRequestObjectsWrappedLazyFn,
  QueryZoomConnectionRequestObjectsResultEx
];

// Function
function useQueryZoomConnectionRequestObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomConnectionRequestObjectsQuery, QueryZoomConnectionRequestObjectsQueryVariables>,
    'query'
  >
): QueryZoomConnectionRequestObjectsLazyReturn {
  const lazyQuery: QueryZoomConnectionRequestObjectsLazyFn = useLazyQuery<
    QueryZoomConnectionRequestObjectsQuery,
    QueryZoomConnectionRequestObjectsQueryVariables
  >(QueryZoomConnectionRequestObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomConnectionRequestObjectsFn = (
    query: QueryZoomConnectionRequestObjectsQuery | null | undefined
  ) => {
    return query?.zoomConnectionRequest || [];
  };
  const wrappedLazyQuery: QueryZoomConnectionRequestObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomConnectionRequestObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomConnectionRequestObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomConnectionRequestByIdResult = {
  variables?: SubscribeToZoomConnectionRequestByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomConnectionRequestByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomConnectionRequestByIdResultEx = SubscribeToZoomConnectionRequestByIdResult &
  ZoomConnectionRequestByIdHookResultEx;

// Function
function useSubscribeToZoomConnectionRequestById({
  zoomConnectionRequestId,
  options,
}: {
  zoomConnectionRequestId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomConnectionRequestByIdSubscription,
      SubscribeToZoomConnectionRequestByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomConnectionRequestByIdResultEx {
  const subscription: SubscribeToZoomConnectionRequestByIdResult = useSubscription<
    SubscribeToZoomConnectionRequestByIdSubscription,
    SubscribeToZoomConnectionRequestByIdSubscriptionVariables
  >(SubscribeToZoomConnectionRequestByIdDocument, { variables: { zoomConnectionRequestId }, ...options });
  return { ...subscription, zoomConnectionRequest: subscription?.data?.zoomConnectionRequest_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomConnectionRequestObjectsResult = {
  variables?: SubscribeToZoomConnectionRequestObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomConnectionRequestObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomConnectionRequestObjectsResultEx = SubscribeToZoomConnectionRequestObjectsResult &
  ZoomConnectionRequestObjectsHookResultEx;

// Function
function useSubscribeToZoomConnectionRequestObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomConnectionRequestObjectsSubscription,
      SubscribeToZoomConnectionRequestObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomConnectionRequestObjectsResultEx {
  const subscription: SubscribeToZoomConnectionRequestObjectsResult = useSubscription<
    SubscribeToZoomConnectionRequestObjectsSubscription,
    SubscribeToZoomConnectionRequestObjectsSubscriptionVariables
  >(SubscribeToZoomConnectionRequestObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomConnectionRequest || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomConnectionRequestMutationResult = FetchResult<
  InsertZoomConnectionRequestMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConnectionRequestMutationResultEx = InsertZoomConnectionRequestMutationResult &
  ZoomConnectionRequestByIdHookResultEx;

type PickInsertZoomConnectionRequestFn = (
  mutation: InsertZoomConnectionRequestMutation | null | undefined
) => ZoomConnectionRequestFragment | null | undefined;
type InsertZoomConnectionRequestLazyMutationFn = MutationTuple<
  InsertZoomConnectionRequestMutation,
  InsertZoomConnectionRequestMutationVariables
>;
type InsertZoomConnectionRequestWrappedLazyMutationFn = ({
  zoomConnectionRequest,
  autoOptimisticResponse,
  options,
}: {
  zoomConnectionRequest: ZoomConnectionRequest_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomConnectionRequestMutation, InsertZoomConnectionRequestMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomConnectionRequestMutationResultEx>;
export type InsertZoomConnectionRequestLazyMutationReturn = [
  InsertZoomConnectionRequestWrappedLazyMutationFn,
  InsertZoomConnectionRequestMutationResultEx
];

// Function
function useInsertZoomConnectionRequest(
  options?: Omit<
    MutationHookOptions<InsertZoomConnectionRequestMutation, InsertZoomConnectionRequestMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomConnectionRequestLazyMutationReturn {
  const lazyMutation: InsertZoomConnectionRequestLazyMutationFn = useMutation<
    InsertZoomConnectionRequestMutation,
    InsertZoomConnectionRequestMutationVariables
  >(InsertZoomConnectionRequestDocument, options);
  const pickZoomConnectionRequest: PickInsertZoomConnectionRequestFn = (mutation) => {
    return mutation?.insert_zoomConnectionRequest?.returning && mutation?.insert_zoomConnectionRequest?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomConnectionRequestWrappedLazyMutationFn = async ({
    zoomConnectionRequest,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomConnectionRequest });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomConnectionRequestMutation,
      InsertZoomConnectionRequestMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomConnectionRequest'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomConnectionRequestMutation>({
        operationType: 'insert',
        entityName: 'zoomConnectionRequest',
        objects: [objectForInsert as ZoomConnectionRequest_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomConnectionRequest: pickZoomConnectionRequest(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConnectionRequest: pickZoomConnectionRequest(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomConnectionRequestWithOnConflictMutationResult = FetchResult<
  InsertZoomConnectionRequestWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConnectionRequestWithOnConflictMutationResultEx =
  InsertZoomConnectionRequestWithOnConflictMutationResult & ZoomConnectionRequestByIdHookResultEx;

type InsertZoomConnectionRequestWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomConnectionRequestWithOnConflictMutation,
  InsertZoomConnectionRequestWithOnConflictMutationVariables
>;
type InsertZoomConnectionRequestWithOnConflictWrappedLazyMutationFn = ({
  zoomConnectionRequest,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomConnectionRequest: ZoomConnectionRequest_Insert_Input;
  onConflict: ZoomConnectionRequest_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomConnectionRequestWithOnConflictMutation,
      InsertZoomConnectionRequestWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomConnectionRequestWithOnConflictMutationResultEx>;
export type InsertZoomConnectionRequestWithOnConflictLazyMutationReturn = [
  InsertZoomConnectionRequestWithOnConflictWrappedLazyMutationFn,
  InsertZoomConnectionRequestWithOnConflictMutationResultEx
];

// Function
function useInsertZoomConnectionRequestWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConnectionRequestWithOnConflictMutation,
      InsertZoomConnectionRequestWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomConnectionRequestWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomConnectionRequestWithOnConflictLazyMutationFn = useMutation<
    InsertZoomConnectionRequestWithOnConflictMutation,
    InsertZoomConnectionRequestWithOnConflictMutationVariables
  >(InsertZoomConnectionRequestWithOnConflictDocument, options);
  const pickZoomConnectionRequest: PickInsertZoomConnectionRequestFn = (
    mutation: InsertZoomConnectionRequestWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomConnectionRequest?.returning && mutation.insert_zoomConnectionRequest.returning[0];
  };

  const wrappedLazyMutation: InsertZoomConnectionRequestWithOnConflictWrappedLazyMutationFn = async ({
    zoomConnectionRequest,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomConnectionRequest });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomConnectionRequestWithOnConflictMutation,
      InsertZoomConnectionRequestWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomConnectionRequest'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomConnectionRequestWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomConnectionRequest',
          objects: [objectForInsert as ZoomConnectionRequest_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomConnectionRequest: pickZoomConnectionRequest(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConnectionRequest: pickZoomConnectionRequest(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomConnectionRequestObjectsMutationResult = FetchResult<
  InsertZoomConnectionRequestMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConnectionRequestObjectsMutationResultEx = InsertZoomConnectionRequestMutationResult &
  ZoomConnectionRequestObjectsHookResultEx;

type PickInsertZoomConnectionRequestObjectsFn = (
  mutation: InsertZoomConnectionRequestMutation | null | undefined
) => ZoomConnectionRequestFragment[];
type InsertZoomConnectionRequestObjectsLazyMutationFn = MutationTuple<
  InsertZoomConnectionRequestMutation,
  InsertZoomConnectionRequestMutationVariables
>;
type InsertZoomConnectionRequestObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomConnectionRequestMutation, InsertZoomConnectionRequestMutationVariables>
) => Promise<InsertZoomConnectionRequestObjectsMutationResultEx>;
export type InsertZoomConnectionRequestObjectsLazyMutationReturn = [
  InsertZoomConnectionRequestObjectsWrappedLazyMutationFn,
  InsertZoomConnectionRequestObjectsMutationResultEx
];

// Function
function useInsertZoomConnectionRequestObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomConnectionRequestMutation, InsertZoomConnectionRequestMutationVariables>,
    'mutation'
  >
): InsertZoomConnectionRequestObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomConnectionRequestObjectsLazyMutationFn = useMutation<
    InsertZoomConnectionRequestMutation,
    InsertZoomConnectionRequestMutationVariables
  >(InsertZoomConnectionRequestDocument, options);
  const pickObjects: PickInsertZoomConnectionRequestObjectsFn = (
    mutation: InsertZoomConnectionRequestMutation | null | undefined
  ) => {
    return mutation?.insert_zoomConnectionRequest?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomConnectionRequestObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomConnectionRequestObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomConnectionRequestByIdMutationResult = FetchResult<
  UpdateZoomConnectionRequestByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomConnectionRequestByIdMutationResultEx = UpdateZoomConnectionRequestByIdMutationResult &
  ZoomConnectionRequestByIdHookResultEx;

type PickUpdateZoomConnectionRequestByIdFn = (
  mutation: UpdateZoomConnectionRequestByIdMutation | null | undefined
) => ZoomConnectionRequestFragment | null | undefined;
type UpdateZoomConnectionRequestByIdLazyMutationFn = MutationTuple<
  UpdateZoomConnectionRequestByIdMutation,
  UpdateZoomConnectionRequestByIdMutationVariables
>;
type UpdateZoomConnectionRequestByIdWrappedLazyMutationFn = ({
  zoomConnectionRequestId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomConnectionRequestId: string;
  set: ZoomConnectionRequest_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomConnectionRequestByIdMutation, UpdateZoomConnectionRequestByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomConnectionRequestByIdMutationResultEx>;
export type UpdateZoomConnectionRequestByIdLazyMutationReturn = [
  UpdateZoomConnectionRequestByIdWrappedLazyMutationFn,
  UpdateZoomConnectionRequestByIdMutationResultEx
];

function useUpdateZoomConnectionRequestById(
  options?: Omit<
    MutationHookOptions<UpdateZoomConnectionRequestByIdMutation, UpdateZoomConnectionRequestByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomConnectionRequestByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomConnectionRequestByIdLazyMutationFn = useMutation<
    UpdateZoomConnectionRequestByIdMutation,
    UpdateZoomConnectionRequestByIdMutationVariables
  >(UpdateZoomConnectionRequestByIdDocument, options);

  const pickZoomConnectionRequest: PickUpdateZoomConnectionRequestByIdFn = (mutation) => {
    return mutation?.update_zoomConnectionRequest?.returning && mutation.update_zoomConnectionRequest!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomConnectionRequestByIdWrappedLazyMutationFn = async ({
    zoomConnectionRequestId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomConnectionRequestByIdMutation,
      UpdateZoomConnectionRequestByIdMutationVariables
    > = { variables: { id: zoomConnectionRequestId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<UpdateZoomConnectionRequestByIdMutation>({
          operationType: 'update',
          entityName: 'zoomConnectionRequest',
          objects: [{ id: zoomConnectionRequestId, ...set }],
        });
    }

    const fetchResult: UpdateZoomConnectionRequestByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomConnectionRequestId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomConnectionRequest: pickZoomConnectionRequest(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConnectionRequest: pickZoomConnectionRequest(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomConnectionRequestObjectsMutationResult = FetchResult<
  UpdateZoomConnectionRequestMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomConnectionRequestObjectsMutationResultEx = UpdateZoomConnectionRequestObjectsMutationResult &
  ZoomConnectionRequestObjectsHookResultEx;

// Function
type PickUpdateZoomConnectionRequestObjectsFn = (
  mutation: UpdateZoomConnectionRequestMutation | null | undefined
) => ZoomConnectionRequestFragment[];
type UpdateZoomConnectionRequestObjectsLazyMutationFn = MutationTuple<
  UpdateZoomConnectionRequestMutation,
  UpdateZoomConnectionRequestMutationVariables
>;
type UpdateZoomConnectionRequestObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomConnectionRequestMutation, UpdateZoomConnectionRequestMutationVariables>
) => Promise<UpdateZoomConnectionRequestObjectsMutationResultEx>;
export type UpdateZoomConnectionRequestObjectsLazyMutationReturn = [
  UpdateZoomConnectionRequestObjectsWrappedLazyMutationFn,
  UpdateZoomConnectionRequestObjectsMutationResultEx
];

function useUpdateZoomConnectionRequestObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomConnectionRequestMutation, UpdateZoomConnectionRequestMutationVariables>,
    'mutation'
  >
): UpdateZoomConnectionRequestObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomConnectionRequestObjectsLazyMutationFn = useMutation<
    UpdateZoomConnectionRequestMutation,
    UpdateZoomConnectionRequestMutationVariables
  >(UpdateZoomConnectionRequestDocument, options);

  const pickObjects: PickUpdateZoomConnectionRequestObjectsFn = (
    mutation: UpdateZoomConnectionRequestMutation | null | undefined
  ) => {
    return mutation?.update_zoomConnectionRequest?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomConnectionRequestObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomConnectionRequestObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomConnectionRequestModelByIdFetchResult = FetchResult<
  RemoveZoomConnectionRequestModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomConnectionRequestModelByIdMutationResultEx = RemoveZoomConnectionRequestModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomConnectionRequestModelFn = (
  mutation: RemoveZoomConnectionRequestModelByIdMutation | null | undefined
) => number;
type RemoveZoomConnectionRequestModelLazyMutationFn = MutationTuple<
  RemoveZoomConnectionRequestModelByIdMutation,
  RemoveZoomConnectionRequestModelByIdMutationVariables
>;
type RemoveZoomConnectionRequestModelWrappedLazyMutationFn = ({
  zoomConnectionRequestId,
  autoOptimisticResponse,
  options,
}: {
  zoomConnectionRequestId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveZoomConnectionRequestModelByIdMutation,
      RemoveZoomConnectionRequestModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveZoomConnectionRequestModelByIdMutationResultEx>;
export type RemoveZoomConnectionRequestModelLazyMutationReturn = [
  RemoveZoomConnectionRequestModelWrappedLazyMutationFn,
  RemoveZoomConnectionRequestModelByIdMutationResultEx
];

function useRemoveZoomConnectionRequestModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomConnectionRequestModelByIdMutation,
      RemoveZoomConnectionRequestModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomConnectionRequestModelLazyMutationFn = useMutation<
    RemoveZoomConnectionRequestModelByIdMutation,
    RemoveZoomConnectionRequestModelByIdMutationVariables
  >(RemoveZoomConnectionRequestModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomConnectionRequestModelFn = (
    mutation: RemoveZoomConnectionRequestModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomConnectionRequest?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomConnectionRequestModelWrappedLazyMutationFn = async ({
    zoomConnectionRequestId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomConnectionRequestModelMutation,
      RemoveZoomConnectionRequestModelByIdMutationVariables
    > = { variables: { id: zoomConnectionRequestId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<RemoveZoomConnectionRequestModelMutation>({
          operationType: 'delete',
          entityName: 'zoomConnectionRequest',
          objects: [{ id: zoomConnectionRequestId }],
        });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomConnectionRequestModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomConnectionRequest',
        entityId: zoomConnectionRequestId,
      });
    }

    const fetchResult: RemoveZoomConnectionRequestModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomConnectionRequestModelObjectsMutationResult = FetchResult<
  RemoveZoomConnectionRequestModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomConnectionRequestModelObjectsMutationResultEx =
  RemoveZoomConnectionRequestModelObjectsMutationResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomConnectionRequestModelObjectsFn = (
  mutation: RemoveZoomConnectionRequestModelMutation | null | undefined
) => number;
type RemoveZoomConnectionRequestModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomConnectionRequestModelMutation,
  RemoveZoomConnectionRequestModelMutationVariables
>;
type RemoveZoomConnectionRequestModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveZoomConnectionRequestModelMutation,
    RemoveZoomConnectionRequestModelMutationVariables
  >
) => Promise<RemoveZoomConnectionRequestModelObjectsMutationResultEx>;
export type RemoveZoomConnectionRequestModelObjectsLazyMutationReturn = [
  RemoveZoomConnectionRequestModelObjectsWrappedLazyMutationFn,
  RemoveZoomConnectionRequestModelObjectsMutationResultEx
];

function useRemoveZoomConnectionRequestModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomConnectionRequestModelMutation, RemoveZoomConnectionRequestModelMutationVariables>,
    'mutation'
  >
): RemoveZoomConnectionRequestModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveZoomConnectionRequestModelMutation,
    RemoveZoomConnectionRequestModelMutationVariables
  >(RemoveZoomConnectionRequestModelDocument, options);

  const pickAffectedRows: PickRemoveZoomConnectionRequestModelObjectsFn = (
    mutation: RemoveZoomConnectionRequestModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomConnectionRequest?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomConnectionRequestModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomConnectionRequestModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomConnectionRequest Fragment Helper Object
//------------------------------------------------

export const ZoomConnectionRequestFragmentGQLHooks = {
  useQueryById: useQueryZoomConnectionRequestById,
  useQueryByIdLazy: useQueryZoomConnectionRequestByIdLazy,
  useQueryObjects: useQueryZoomConnectionRequestObjects,
  useQueryObjectsLazy: useQueryZoomConnectionRequestObjectsLazy,
  useSubscribeToById: useSubscribeToZoomConnectionRequestById,
  useSubscribeToObjects: useSubscribeToZoomConnectionRequestObjects,
  useInsert: useInsertZoomConnectionRequest,
  useInsertWithOnConflict: useInsertZoomConnectionRequestWithOnConflict,
  useInsertObjects: useInsertZoomConnectionRequestObjects,
  useUpdateById: useUpdateZoomConnectionRequestById,
  useUpdateObjects: useUpdateZoomConnectionRequestObjects,
};

// zoomConnectionRequest MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomConnectionRequestModelGQLHooks = {
  useRemoveById: useRemoveZoomConnectionRequestModelById,
  useRemoveObjects: useRemoveZoomConnectionRequestModelObjects,
};

// zoomConnectionRequestReminder REACT
//------------------------------------------------

export type ZoomConnectionRequestReminderByIdHookResultEx = {
  zoomConnectionRequestReminder: ZoomConnectionRequestReminderFragment | null | undefined;
};
export type ZoomConnectionRequestReminderObjectsHookResultEx = { objects: ZoomConnectionRequestReminderFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomConnectionRequestReminderByIdResult = LazyQueryResult<
  QueryZoomConnectionRequestReminderByIdQuery,
  QueryZoomConnectionRequestReminderByIdQueryVariables
>;
type QueryZoomConnectionRequestReminderByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomConnectionRequestReminderByIdQuery,
          QueryZoomConnectionRequestReminderByIdQueryVariables,
          QueryZoomConnectionRequestReminderByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomConnectionRequestReminderByIdResultEx = Omit<
  QueryZoomConnectionRequestReminderByIdResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryZoomConnectionRequestReminderByIdSubScribeToMore;
} & ZoomConnectionRequestReminderByIdHookResultEx;

// Function
function useQueryZoomConnectionRequestReminderById({
  zoomConnectionRequestReminderId,
  options,
}: {
  zoomConnectionRequestReminderId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomConnectionRequestReminderByIdQuery, QueryZoomConnectionRequestReminderByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomConnectionRequestReminderByIdResultEx {
  const _query: QueryZoomConnectionRequestReminderByIdResult = useQuery<
    QueryZoomConnectionRequestReminderByIdQuery,
    QueryZoomConnectionRequestReminderByIdQueryVariables
  >(QueryZoomConnectionRequestReminderByIdDocument, { variables: { zoomConnectionRequestReminderId }, ...options });

  const typedSubscribeToMore: QueryZoomConnectionRequestReminderByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomConnectionRequestReminderByIdDocument,
      variables: { zoomConnectionRequestReminderId } as QueryZoomConnectionRequestReminderByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    zoomConnectionRequestReminder: query?.data?.zoomConnectionRequestReminder_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomConnectionRequestReminderByIdFn = (
  query: QueryZoomConnectionRequestReminderByIdQuery | null | undefined
) => ZoomConnectionRequestReminderFragment | null | undefined;
type QueryZoomConnectionRequestReminderByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomConnectionRequestReminderByIdQueryVariables> | undefined) => void,
  QueryZoomConnectionRequestReminderByIdResult
];
type QueryZoomConnectionRequestReminderByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomConnectionRequestReminderByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomConnectionRequestReminderByIdLazyReturn = [
  QueryZoomConnectionRequestReminderByIdWrappedLazyFn,
  QueryZoomConnectionRequestReminderByIdResultEx
];

// Function
function useQueryZoomConnectionRequestReminderByIdLazy({
  zoomConnectionRequestReminderId,
  options,
}: {
  zoomConnectionRequestReminderId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomConnectionRequestReminderByIdQuery, QueryZoomConnectionRequestReminderByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomConnectionRequestReminderByIdLazyReturn {
  const lazyQuery: QueryZoomConnectionRequestReminderByIdLazyFn = useLazyQuery<
    QueryZoomConnectionRequestReminderByIdQuery,
    QueryZoomConnectionRequestReminderByIdQueryVariables
  >(QueryZoomConnectionRequestReminderByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomConnectionRequestReminderById: PickQueryZoomConnectionRequestReminderByIdFn = (query) => {
    return query && query.zoomConnectionRequestReminder_by_pk;
  };
  const wrappedLazyQuery: QueryZoomConnectionRequestReminderByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomConnectionRequestReminderByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomConnectionRequestReminderByIdDocument,
        variables: { zoomConnectionRequestReminderId } as QueryZoomConnectionRequestReminderByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomConnectionRequestReminder: pickQueryZoomConnectionRequestReminderById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomConnectionRequestReminderObjectsResult = LazyQueryResult<
  QueryZoomConnectionRequestReminderObjectsQuery,
  QueryZoomConnectionRequestReminderObjectsQueryVariables
>;
type QueryZoomConnectionRequestReminderObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomConnectionRequestReminderObjectsQuery,
          QueryZoomConnectionRequestReminderObjectsQueryVariables,
          QueryZoomConnectionRequestReminderObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomConnectionRequestReminderObjectsResultEx = Omit<
  QueryZoomConnectionRequestReminderObjectsResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryZoomConnectionRequestReminderObjectsSubScribeToMore;
} & ZoomConnectionRequestReminderObjectsHookResultEx;

// Function
function useQueryZoomConnectionRequestReminderObjects(
  options: Omit<
    QueryHookOptions<
      QueryZoomConnectionRequestReminderObjectsQuery,
      QueryZoomConnectionRequestReminderObjectsQueryVariables
    >,
    'query'
  >
): QueryZoomConnectionRequestReminderObjectsResultEx {
  const _query: QueryZoomConnectionRequestReminderObjectsResult = useQuery<
    QueryZoomConnectionRequestReminderObjectsQuery,
    QueryZoomConnectionRequestReminderObjectsQueryVariables
  >(QueryZoomConnectionRequestReminderObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomConnectionRequestReminderObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomConnectionRequestReminderObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomConnectionRequestReminder || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomConnectionRequestReminderObjectsFn = (
  query: QueryZoomConnectionRequestReminderObjectsQuery | null | undefined
) => ZoomConnectionRequestReminderFragment[];
type QueryZoomConnectionRequestReminderObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomConnectionRequestReminderObjectsQueryVariables> | undefined) => void,
  QueryZoomConnectionRequestReminderObjectsResult
];
type QueryZoomConnectionRequestReminderObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomConnectionRequestReminderObjectsQueryVariables>
) => void;
export type QueryZoomConnectionRequestReminderObjectsLazyReturn = [
  QueryZoomConnectionRequestReminderObjectsWrappedLazyFn,
  QueryZoomConnectionRequestReminderObjectsResultEx
];

// Function
function useQueryZoomConnectionRequestReminderObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<
      QueryZoomConnectionRequestReminderObjectsQuery,
      QueryZoomConnectionRequestReminderObjectsQueryVariables
    >,
    'query'
  >
): QueryZoomConnectionRequestReminderObjectsLazyReturn {
  const lazyQuery: QueryZoomConnectionRequestReminderObjectsLazyFn = useLazyQuery<
    QueryZoomConnectionRequestReminderObjectsQuery,
    QueryZoomConnectionRequestReminderObjectsQueryVariables
  >(QueryZoomConnectionRequestReminderObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomConnectionRequestReminderObjectsFn = (
    query: QueryZoomConnectionRequestReminderObjectsQuery | null | undefined
  ) => {
    return query?.zoomConnectionRequestReminder || [];
  };
  const wrappedLazyQuery: QueryZoomConnectionRequestReminderObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomConnectionRequestReminderObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomConnectionRequestReminderObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomConnectionRequestReminderByIdResult = {
  variables?: SubscribeToZoomConnectionRequestReminderByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomConnectionRequestReminderByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomConnectionRequestReminderByIdResultEx = SubscribeToZoomConnectionRequestReminderByIdResult &
  ZoomConnectionRequestReminderByIdHookResultEx;

// Function
function useSubscribeToZoomConnectionRequestReminderById({
  zoomConnectionRequestReminderId,
  options,
}: {
  zoomConnectionRequestReminderId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomConnectionRequestReminderByIdSubscription,
      SubscribeToZoomConnectionRequestReminderByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomConnectionRequestReminderByIdResultEx {
  const subscription: SubscribeToZoomConnectionRequestReminderByIdResult = useSubscription<
    SubscribeToZoomConnectionRequestReminderByIdSubscription,
    SubscribeToZoomConnectionRequestReminderByIdSubscriptionVariables
  >(SubscribeToZoomConnectionRequestReminderByIdDocument, {
    variables: { zoomConnectionRequestReminderId },
    ...options,
  });
  return { ...subscription, zoomConnectionRequestReminder: subscription?.data?.zoomConnectionRequestReminder_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomConnectionRequestReminderObjectsResult = {
  variables?: SubscribeToZoomConnectionRequestReminderObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomConnectionRequestReminderObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomConnectionRequestReminderObjectsResultEx =
  SubscribeToZoomConnectionRequestReminderObjectsResult & ZoomConnectionRequestReminderObjectsHookResultEx;

// Function
function useSubscribeToZoomConnectionRequestReminderObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomConnectionRequestReminderObjectsSubscription,
      SubscribeToZoomConnectionRequestReminderObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomConnectionRequestReminderObjectsResultEx {
  const subscription: SubscribeToZoomConnectionRequestReminderObjectsResult = useSubscription<
    SubscribeToZoomConnectionRequestReminderObjectsSubscription,
    SubscribeToZoomConnectionRequestReminderObjectsSubscriptionVariables
  >(SubscribeToZoomConnectionRequestReminderObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomConnectionRequestReminder || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomConnectionRequestReminderMutationResult = FetchResult<
  InsertZoomConnectionRequestReminderMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConnectionRequestReminderMutationResultEx = InsertZoomConnectionRequestReminderMutationResult &
  ZoomConnectionRequestReminderByIdHookResultEx;

type PickInsertZoomConnectionRequestReminderFn = (
  mutation: InsertZoomConnectionRequestReminderMutation | null | undefined
) => ZoomConnectionRequestReminderFragment | null | undefined;
type InsertZoomConnectionRequestReminderLazyMutationFn = MutationTuple<
  InsertZoomConnectionRequestReminderMutation,
  InsertZoomConnectionRequestReminderMutationVariables
>;
type InsertZoomConnectionRequestReminderWrappedLazyMutationFn = ({
  zoomConnectionRequestReminder,
  autoOptimisticResponse,
  options,
}: {
  zoomConnectionRequestReminder: ZoomConnectionRequestReminder_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomConnectionRequestReminderMutation,
      InsertZoomConnectionRequestReminderMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomConnectionRequestReminderMutationResultEx>;
export type InsertZoomConnectionRequestReminderLazyMutationReturn = [
  InsertZoomConnectionRequestReminderWrappedLazyMutationFn,
  InsertZoomConnectionRequestReminderMutationResultEx
];

// Function
function useInsertZoomConnectionRequestReminder(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConnectionRequestReminderMutation,
      InsertZoomConnectionRequestReminderMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomConnectionRequestReminderLazyMutationReturn {
  const lazyMutation: InsertZoomConnectionRequestReminderLazyMutationFn = useMutation<
    InsertZoomConnectionRequestReminderMutation,
    InsertZoomConnectionRequestReminderMutationVariables
  >(InsertZoomConnectionRequestReminderDocument, options);
  const pickZoomConnectionRequestReminder: PickInsertZoomConnectionRequestReminderFn = (mutation) => {
    return (
      mutation?.insert_zoomConnectionRequestReminder?.returning &&
      mutation?.insert_zoomConnectionRequestReminder?.returning[0]
    );
  };

  const wrappedLazyMutation: InsertZoomConnectionRequestReminderWrappedLazyMutationFn = async ({
    zoomConnectionRequestReminder,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomConnectionRequestReminder });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomConnectionRequestReminderMutation,
      InsertZoomConnectionRequestReminderMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomConnectionRequestReminder'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomConnectionRequestReminderMutation>({
          operationType: 'insert',
          entityName: 'zoomConnectionRequestReminder',
          objects: [objectForInsert as ZoomConnectionRequestReminder_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomConnectionRequestReminder: pickZoomConnectionRequestReminder(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConnectionRequestReminder: pickZoomConnectionRequestReminder(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomConnectionRequestReminderWithOnConflictMutationResult = FetchResult<
  InsertZoomConnectionRequestReminderWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConnectionRequestReminderWithOnConflictMutationResultEx =
  InsertZoomConnectionRequestReminderWithOnConflictMutationResult & ZoomConnectionRequestReminderByIdHookResultEx;

type InsertZoomConnectionRequestReminderWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomConnectionRequestReminderWithOnConflictMutation,
  InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
>;
type InsertZoomConnectionRequestReminderWithOnConflictWrappedLazyMutationFn = ({
  zoomConnectionRequestReminder,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomConnectionRequestReminder: ZoomConnectionRequestReminder_Insert_Input;
  onConflict: ZoomConnectionRequestReminder_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomConnectionRequestReminderWithOnConflictMutation,
      InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomConnectionRequestReminderWithOnConflictMutationResultEx>;
export type InsertZoomConnectionRequestReminderWithOnConflictLazyMutationReturn = [
  InsertZoomConnectionRequestReminderWithOnConflictWrappedLazyMutationFn,
  InsertZoomConnectionRequestReminderWithOnConflictMutationResultEx
];

// Function
function useInsertZoomConnectionRequestReminderWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConnectionRequestReminderWithOnConflictMutation,
      InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomConnectionRequestReminderWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomConnectionRequestReminderWithOnConflictLazyMutationFn = useMutation<
    InsertZoomConnectionRequestReminderWithOnConflictMutation,
    InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
  >(InsertZoomConnectionRequestReminderWithOnConflictDocument, options);
  const pickZoomConnectionRequestReminder: PickInsertZoomConnectionRequestReminderFn = (
    mutation: InsertZoomConnectionRequestReminderWithOnConflictMutation | null | undefined
  ) => {
    return (
      mutation?.insert_zoomConnectionRequestReminder?.returning &&
      mutation.insert_zoomConnectionRequestReminder.returning[0]
    );
  };

  const wrappedLazyMutation: InsertZoomConnectionRequestReminderWithOnConflictWrappedLazyMutationFn = async ({
    zoomConnectionRequestReminder,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomConnectionRequestReminder });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomConnectionRequestReminderWithOnConflictMutation,
      InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomConnectionRequestReminder'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomConnectionRequestReminderWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomConnectionRequestReminder',
          objects: [objectForInsert as ZoomConnectionRequestReminder_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomConnectionRequestReminder: pickZoomConnectionRequestReminder(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConnectionRequestReminder: pickZoomConnectionRequestReminder(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomConnectionRequestReminderObjectsMutationResult = FetchResult<
  InsertZoomConnectionRequestReminderMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConnectionRequestReminderObjectsMutationResultEx =
  InsertZoomConnectionRequestReminderMutationResult & ZoomConnectionRequestReminderObjectsHookResultEx;

type PickInsertZoomConnectionRequestReminderObjectsFn = (
  mutation: InsertZoomConnectionRequestReminderMutation | null | undefined
) => ZoomConnectionRequestReminderFragment[];
type InsertZoomConnectionRequestReminderObjectsLazyMutationFn = MutationTuple<
  InsertZoomConnectionRequestReminderMutation,
  InsertZoomConnectionRequestReminderMutationVariables
>;
type InsertZoomConnectionRequestReminderObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    InsertZoomConnectionRequestReminderMutation,
    InsertZoomConnectionRequestReminderMutationVariables
  >
) => Promise<InsertZoomConnectionRequestReminderObjectsMutationResultEx>;
export type InsertZoomConnectionRequestReminderObjectsLazyMutationReturn = [
  InsertZoomConnectionRequestReminderObjectsWrappedLazyMutationFn,
  InsertZoomConnectionRequestReminderObjectsMutationResultEx
];

// Function
function useInsertZoomConnectionRequestReminderObjects(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConnectionRequestReminderMutation,
      InsertZoomConnectionRequestReminderMutationVariables
    >,
    'mutation'
  >
): InsertZoomConnectionRequestReminderObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomConnectionRequestReminderObjectsLazyMutationFn = useMutation<
    InsertZoomConnectionRequestReminderMutation,
    InsertZoomConnectionRequestReminderMutationVariables
  >(InsertZoomConnectionRequestReminderDocument, options);
  const pickObjects: PickInsertZoomConnectionRequestReminderObjectsFn = (
    mutation: InsertZoomConnectionRequestReminderMutation | null | undefined
  ) => {
    return mutation?.insert_zoomConnectionRequestReminder?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomConnectionRequestReminderObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomConnectionRequestReminderObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomConnectionRequestReminderByIdMutationResult = FetchResult<
  UpdateZoomConnectionRequestReminderByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomConnectionRequestReminderByIdMutationResultEx =
  UpdateZoomConnectionRequestReminderByIdMutationResult & ZoomConnectionRequestReminderByIdHookResultEx;

type PickUpdateZoomConnectionRequestReminderByIdFn = (
  mutation: UpdateZoomConnectionRequestReminderByIdMutation | null | undefined
) => ZoomConnectionRequestReminderFragment | null | undefined;
type UpdateZoomConnectionRequestReminderByIdLazyMutationFn = MutationTuple<
  UpdateZoomConnectionRequestReminderByIdMutation,
  UpdateZoomConnectionRequestReminderByIdMutationVariables
>;
type UpdateZoomConnectionRequestReminderByIdWrappedLazyMutationFn = ({
  zoomConnectionRequestReminderId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomConnectionRequestReminderId: string;
  set: ZoomConnectionRequestReminder_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateZoomConnectionRequestReminderByIdMutation,
      UpdateZoomConnectionRequestReminderByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateZoomConnectionRequestReminderByIdMutationResultEx>;
export type UpdateZoomConnectionRequestReminderByIdLazyMutationReturn = [
  UpdateZoomConnectionRequestReminderByIdWrappedLazyMutationFn,
  UpdateZoomConnectionRequestReminderByIdMutationResultEx
];

function useUpdateZoomConnectionRequestReminderById(
  options?: Omit<
    MutationHookOptions<
      UpdateZoomConnectionRequestReminderByIdMutation,
      UpdateZoomConnectionRequestReminderByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
): UpdateZoomConnectionRequestReminderByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomConnectionRequestReminderByIdLazyMutationFn = useMutation<
    UpdateZoomConnectionRequestReminderByIdMutation,
    UpdateZoomConnectionRequestReminderByIdMutationVariables
  >(UpdateZoomConnectionRequestReminderByIdDocument, options);

  const pickZoomConnectionRequestReminder: PickUpdateZoomConnectionRequestReminderByIdFn = (mutation) => {
    return (
      mutation?.update_zoomConnectionRequestReminder?.returning &&
      mutation.update_zoomConnectionRequestReminder!.returning[0]
    );
  };

  const wrappedLazyMutation: UpdateZoomConnectionRequestReminderByIdWrappedLazyMutationFn = async ({
    zoomConnectionRequestReminderId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomConnectionRequestReminderByIdMutation,
      UpdateZoomConnectionRequestReminderByIdMutationVariables
    > = { variables: { id: zoomConnectionRequestReminderId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<UpdateZoomConnectionRequestReminderByIdMutation>({
          operationType: 'update',
          entityName: 'zoomConnectionRequestReminder',
          objects: [{ id: zoomConnectionRequestReminderId, ...set }],
        });
    }

    const fetchResult: UpdateZoomConnectionRequestReminderByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomConnectionRequestReminderId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomConnectionRequestReminder: pickZoomConnectionRequestReminder(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConnectionRequestReminder: pickZoomConnectionRequestReminder(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomConnectionRequestReminderObjectsMutationResult = FetchResult<
  UpdateZoomConnectionRequestReminderMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomConnectionRequestReminderObjectsMutationResultEx =
  UpdateZoomConnectionRequestReminderObjectsMutationResult & ZoomConnectionRequestReminderObjectsHookResultEx;

// Function
type PickUpdateZoomConnectionRequestReminderObjectsFn = (
  mutation: UpdateZoomConnectionRequestReminderMutation | null | undefined
) => ZoomConnectionRequestReminderFragment[];
type UpdateZoomConnectionRequestReminderObjectsLazyMutationFn = MutationTuple<
  UpdateZoomConnectionRequestReminderMutation,
  UpdateZoomConnectionRequestReminderMutationVariables
>;
type UpdateZoomConnectionRequestReminderObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    UpdateZoomConnectionRequestReminderMutation,
    UpdateZoomConnectionRequestReminderMutationVariables
  >
) => Promise<UpdateZoomConnectionRequestReminderObjectsMutationResultEx>;
export type UpdateZoomConnectionRequestReminderObjectsLazyMutationReturn = [
  UpdateZoomConnectionRequestReminderObjectsWrappedLazyMutationFn,
  UpdateZoomConnectionRequestReminderObjectsMutationResultEx
];

function useUpdateZoomConnectionRequestReminderObjects(
  options?: Omit<
    MutationHookOptions<
      UpdateZoomConnectionRequestReminderMutation,
      UpdateZoomConnectionRequestReminderMutationVariables
    >,
    'mutation'
  >
): UpdateZoomConnectionRequestReminderObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomConnectionRequestReminderObjectsLazyMutationFn = useMutation<
    UpdateZoomConnectionRequestReminderMutation,
    UpdateZoomConnectionRequestReminderMutationVariables
  >(UpdateZoomConnectionRequestReminderDocument, options);

  const pickObjects: PickUpdateZoomConnectionRequestReminderObjectsFn = (
    mutation: UpdateZoomConnectionRequestReminderMutation | null | undefined
  ) => {
    return mutation?.update_zoomConnectionRequestReminder?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomConnectionRequestReminderObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomConnectionRequestReminderObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomConnectionRequestReminderModelByIdFetchResult = FetchResult<
  RemoveZoomConnectionRequestReminderModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomConnectionRequestReminderModelByIdMutationResultEx =
  RemoveZoomConnectionRequestReminderModelByIdFetchResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomConnectionRequestReminderModelFn = (
  mutation: RemoveZoomConnectionRequestReminderModelByIdMutation | null | undefined
) => number;
type RemoveZoomConnectionRequestReminderModelLazyMutationFn = MutationTuple<
  RemoveZoomConnectionRequestReminderModelByIdMutation,
  RemoveZoomConnectionRequestReminderModelByIdMutationVariables
>;
type RemoveZoomConnectionRequestReminderModelWrappedLazyMutationFn = ({
  zoomConnectionRequestReminderId,
  autoOptimisticResponse,
  options,
}: {
  zoomConnectionRequestReminderId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveZoomConnectionRequestReminderModelByIdMutation,
      RemoveZoomConnectionRequestReminderModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveZoomConnectionRequestReminderModelByIdMutationResultEx>;
export type RemoveZoomConnectionRequestReminderModelLazyMutationReturn = [
  RemoveZoomConnectionRequestReminderModelWrappedLazyMutationFn,
  RemoveZoomConnectionRequestReminderModelByIdMutationResultEx
];

function useRemoveZoomConnectionRequestReminderModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomConnectionRequestReminderModelByIdMutation,
      RemoveZoomConnectionRequestReminderModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomConnectionRequestReminderModelLazyMutationFn = useMutation<
    RemoveZoomConnectionRequestReminderModelByIdMutation,
    RemoveZoomConnectionRequestReminderModelByIdMutationVariables
  >(RemoveZoomConnectionRequestReminderModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomConnectionRequestReminderModelFn = (
    mutation: RemoveZoomConnectionRequestReminderModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomConnectionRequestReminder?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomConnectionRequestReminderModelWrappedLazyMutationFn = async ({
    zoomConnectionRequestReminderId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomConnectionRequestReminderModelMutation,
      RemoveZoomConnectionRequestReminderModelByIdMutationVariables
    > = { variables: { id: zoomConnectionRequestReminderId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<RemoveZoomConnectionRequestReminderModelMutation>({
          operationType: 'delete',
          entityName: 'zoomConnectionRequestReminder',
          objects: [{ id: zoomConnectionRequestReminderId }],
        });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomConnectionRequestReminderModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomConnectionRequestReminder',
        entityId: zoomConnectionRequestReminderId,
      });
    }

    const fetchResult: RemoveZoomConnectionRequestReminderModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomConnectionRequestReminderModelObjectsMutationResult = FetchResult<
  RemoveZoomConnectionRequestReminderModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomConnectionRequestReminderModelObjectsMutationResultEx =
  RemoveZoomConnectionRequestReminderModelObjectsMutationResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomConnectionRequestReminderModelObjectsFn = (
  mutation: RemoveZoomConnectionRequestReminderModelMutation | null | undefined
) => number;
type RemoveZoomConnectionRequestReminderModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomConnectionRequestReminderModelMutation,
  RemoveZoomConnectionRequestReminderModelMutationVariables
>;
type RemoveZoomConnectionRequestReminderModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveZoomConnectionRequestReminderModelMutation,
    RemoveZoomConnectionRequestReminderModelMutationVariables
  >
) => Promise<RemoveZoomConnectionRequestReminderModelObjectsMutationResultEx>;
export type RemoveZoomConnectionRequestReminderModelObjectsLazyMutationReturn = [
  RemoveZoomConnectionRequestReminderModelObjectsWrappedLazyMutationFn,
  RemoveZoomConnectionRequestReminderModelObjectsMutationResultEx
];

function useRemoveZoomConnectionRequestReminderModelObjects(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomConnectionRequestReminderModelMutation,
      RemoveZoomConnectionRequestReminderModelMutationVariables
    >,
    'mutation'
  >
): RemoveZoomConnectionRequestReminderModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveZoomConnectionRequestReminderModelMutation,
    RemoveZoomConnectionRequestReminderModelMutationVariables
  >(RemoveZoomConnectionRequestReminderModelDocument, options);

  const pickAffectedRows: PickRemoveZoomConnectionRequestReminderModelObjectsFn = (
    mutation: RemoveZoomConnectionRequestReminderModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomConnectionRequestReminder?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomConnectionRequestReminderModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomConnectionRequestReminderModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomConnectionRequestReminder Fragment Helper Object
//------------------------------------------------

export const ZoomConnectionRequestReminderFragmentGQLHooks = {
  useQueryById: useQueryZoomConnectionRequestReminderById,
  useQueryByIdLazy: useQueryZoomConnectionRequestReminderByIdLazy,
  useQueryObjects: useQueryZoomConnectionRequestReminderObjects,
  useQueryObjectsLazy: useQueryZoomConnectionRequestReminderObjectsLazy,
  useSubscribeToById: useSubscribeToZoomConnectionRequestReminderById,
  useSubscribeToObjects: useSubscribeToZoomConnectionRequestReminderObjects,
  useInsert: useInsertZoomConnectionRequestReminder,
  useInsertWithOnConflict: useInsertZoomConnectionRequestReminderWithOnConflict,
  useInsertObjects: useInsertZoomConnectionRequestReminderObjects,
  useUpdateById: useUpdateZoomConnectionRequestReminderById,
  useUpdateObjects: useUpdateZoomConnectionRequestReminderObjects,
};

// zoomConnectionRequestReminder MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomConnectionRequestReminderModelGQLHooks = {
  useRemoveById: useRemoveZoomConnectionRequestReminderModelById,
  useRemoveObjects: useRemoveZoomConnectionRequestReminderModelObjects,
};

// zoomApprovedDomain REACT
//------------------------------------------------

export type ZoomApprovedDomainByIdHookResultEx = { zoomApprovedDomain: ZoomApprovedDomainFragment | null | undefined };
export type ZoomApprovedDomainObjectsHookResultEx = { objects: ZoomApprovedDomainFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomApprovedDomainByIdResult = LazyQueryResult<
  QueryZoomApprovedDomainByIdQuery,
  QueryZoomApprovedDomainByIdQueryVariables
>;
type QueryZoomApprovedDomainByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomApprovedDomainByIdQuery,
          QueryZoomApprovedDomainByIdQueryVariables,
          QueryZoomApprovedDomainByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomApprovedDomainByIdResultEx = Omit<QueryZoomApprovedDomainByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomApprovedDomainByIdSubScribeToMore;
} & ZoomApprovedDomainByIdHookResultEx;

// Function
function useQueryZoomApprovedDomainById({
  zoomApprovedDomainId,
  options,
}: {
  zoomApprovedDomainId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomApprovedDomainByIdQuery, QueryZoomApprovedDomainByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomApprovedDomainByIdResultEx {
  const _query: QueryZoomApprovedDomainByIdResult = useQuery<
    QueryZoomApprovedDomainByIdQuery,
    QueryZoomApprovedDomainByIdQueryVariables
  >(QueryZoomApprovedDomainByIdDocument, { variables: { zoomApprovedDomainId }, ...options });

  const typedSubscribeToMore: QueryZoomApprovedDomainByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomApprovedDomainByIdDocument,
      variables: { zoomApprovedDomainId } as QueryZoomApprovedDomainByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomApprovedDomain: query?.data?.zoomApprovedDomain_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomApprovedDomainByIdFn = (
  query: QueryZoomApprovedDomainByIdQuery | null | undefined
) => ZoomApprovedDomainFragment | null | undefined;
type QueryZoomApprovedDomainByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomApprovedDomainByIdQueryVariables> | undefined) => void,
  QueryZoomApprovedDomainByIdResult
];
type QueryZoomApprovedDomainByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomApprovedDomainByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomApprovedDomainByIdLazyReturn = [
  QueryZoomApprovedDomainByIdWrappedLazyFn,
  QueryZoomApprovedDomainByIdResultEx
];

// Function
function useQueryZoomApprovedDomainByIdLazy({
  zoomApprovedDomainId,
  options,
}: {
  zoomApprovedDomainId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomApprovedDomainByIdQuery, QueryZoomApprovedDomainByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomApprovedDomainByIdLazyReturn {
  const lazyQuery: QueryZoomApprovedDomainByIdLazyFn = useLazyQuery<
    QueryZoomApprovedDomainByIdQuery,
    QueryZoomApprovedDomainByIdQueryVariables
  >(QueryZoomApprovedDomainByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomApprovedDomainById: PickQueryZoomApprovedDomainByIdFn = (query) => {
    return query && query.zoomApprovedDomain_by_pk;
  };
  const wrappedLazyQuery: QueryZoomApprovedDomainByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomApprovedDomainByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomApprovedDomainByIdDocument,
        variables: { zoomApprovedDomainId } as QueryZoomApprovedDomainByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomApprovedDomain: pickQueryZoomApprovedDomainById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomApprovedDomainObjectsResult = LazyQueryResult<
  QueryZoomApprovedDomainObjectsQuery,
  QueryZoomApprovedDomainObjectsQueryVariables
>;
type QueryZoomApprovedDomainObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomApprovedDomainObjectsQuery,
          QueryZoomApprovedDomainObjectsQueryVariables,
          QueryZoomApprovedDomainObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomApprovedDomainObjectsResultEx = Omit<QueryZoomApprovedDomainObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomApprovedDomainObjectsSubScribeToMore;
} & ZoomApprovedDomainObjectsHookResultEx;

// Function
function useQueryZoomApprovedDomainObjects(
  options: Omit<
    QueryHookOptions<QueryZoomApprovedDomainObjectsQuery, QueryZoomApprovedDomainObjectsQueryVariables>,
    'query'
  >
): QueryZoomApprovedDomainObjectsResultEx {
  const _query: QueryZoomApprovedDomainObjectsResult = useQuery<
    QueryZoomApprovedDomainObjectsQuery,
    QueryZoomApprovedDomainObjectsQueryVariables
  >(QueryZoomApprovedDomainObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomApprovedDomainObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomApprovedDomainObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomApprovedDomain || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomApprovedDomainObjectsFn = (
  query: QueryZoomApprovedDomainObjectsQuery | null | undefined
) => ZoomApprovedDomainFragment[];
type QueryZoomApprovedDomainObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomApprovedDomainObjectsQueryVariables> | undefined) => void,
  QueryZoomApprovedDomainObjectsResult
];
type QueryZoomApprovedDomainObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomApprovedDomainObjectsQueryVariables>
) => void;
export type QueryZoomApprovedDomainObjectsLazyReturn = [
  QueryZoomApprovedDomainObjectsWrappedLazyFn,
  QueryZoomApprovedDomainObjectsResultEx
];

// Function
function useQueryZoomApprovedDomainObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomApprovedDomainObjectsQuery, QueryZoomApprovedDomainObjectsQueryVariables>,
    'query'
  >
): QueryZoomApprovedDomainObjectsLazyReturn {
  const lazyQuery: QueryZoomApprovedDomainObjectsLazyFn = useLazyQuery<
    QueryZoomApprovedDomainObjectsQuery,
    QueryZoomApprovedDomainObjectsQueryVariables
  >(QueryZoomApprovedDomainObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomApprovedDomainObjectsFn = (
    query: QueryZoomApprovedDomainObjectsQuery | null | undefined
  ) => {
    return query?.zoomApprovedDomain || [];
  };
  const wrappedLazyQuery: QueryZoomApprovedDomainObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomApprovedDomainObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomApprovedDomainObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomApprovedDomainByIdResult = {
  variables?: SubscribeToZoomApprovedDomainByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomApprovedDomainByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomApprovedDomainByIdResultEx = SubscribeToZoomApprovedDomainByIdResult &
  ZoomApprovedDomainByIdHookResultEx;

// Function
function useSubscribeToZoomApprovedDomainById({
  zoomApprovedDomainId,
  options,
}: {
  zoomApprovedDomainId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomApprovedDomainByIdSubscription,
      SubscribeToZoomApprovedDomainByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomApprovedDomainByIdResultEx {
  const subscription: SubscribeToZoomApprovedDomainByIdResult = useSubscription<
    SubscribeToZoomApprovedDomainByIdSubscription,
    SubscribeToZoomApprovedDomainByIdSubscriptionVariables
  >(SubscribeToZoomApprovedDomainByIdDocument, { variables: { zoomApprovedDomainId }, ...options });
  return { ...subscription, zoomApprovedDomain: subscription?.data?.zoomApprovedDomain_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomApprovedDomainObjectsResult = {
  variables?: SubscribeToZoomApprovedDomainObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomApprovedDomainObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomApprovedDomainObjectsResultEx = SubscribeToZoomApprovedDomainObjectsResult &
  ZoomApprovedDomainObjectsHookResultEx;

// Function
function useSubscribeToZoomApprovedDomainObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomApprovedDomainObjectsSubscription,
      SubscribeToZoomApprovedDomainObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomApprovedDomainObjectsResultEx {
  const subscription: SubscribeToZoomApprovedDomainObjectsResult = useSubscription<
    SubscribeToZoomApprovedDomainObjectsSubscription,
    SubscribeToZoomApprovedDomainObjectsSubscriptionVariables
  >(SubscribeToZoomApprovedDomainObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomApprovedDomain || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomApprovedDomainMutationResult = FetchResult<
  InsertZoomApprovedDomainMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomApprovedDomainMutationResultEx = InsertZoomApprovedDomainMutationResult &
  ZoomApprovedDomainByIdHookResultEx;

type PickInsertZoomApprovedDomainFn = (
  mutation: InsertZoomApprovedDomainMutation | null | undefined
) => ZoomApprovedDomainFragment | null | undefined;
type InsertZoomApprovedDomainLazyMutationFn = MutationTuple<
  InsertZoomApprovedDomainMutation,
  InsertZoomApprovedDomainMutationVariables
>;
type InsertZoomApprovedDomainWrappedLazyMutationFn = ({
  zoomApprovedDomain,
  autoOptimisticResponse,
  options,
}: {
  zoomApprovedDomain: ZoomApprovedDomain_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomApprovedDomainMutation, InsertZoomApprovedDomainMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomApprovedDomainMutationResultEx>;
export type InsertZoomApprovedDomainLazyMutationReturn = [
  InsertZoomApprovedDomainWrappedLazyMutationFn,
  InsertZoomApprovedDomainMutationResultEx
];

// Function
function useInsertZoomApprovedDomain(
  options?: Omit<
    MutationHookOptions<InsertZoomApprovedDomainMutation, InsertZoomApprovedDomainMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomApprovedDomainLazyMutationReturn {
  const lazyMutation: InsertZoomApprovedDomainLazyMutationFn = useMutation<
    InsertZoomApprovedDomainMutation,
    InsertZoomApprovedDomainMutationVariables
  >(InsertZoomApprovedDomainDocument, options);
  const pickZoomApprovedDomain: PickInsertZoomApprovedDomainFn = (mutation) => {
    return mutation?.insert_zoomApprovedDomain?.returning && mutation?.insert_zoomApprovedDomain?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomApprovedDomainWrappedLazyMutationFn = async ({
    zoomApprovedDomain,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomApprovedDomain });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomApprovedDomainMutation,
      InsertZoomApprovedDomainMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomApprovedDomain'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomApprovedDomainMutation>({
        operationType: 'insert',
        entityName: 'zoomApprovedDomain',
        objects: [objectForInsert as ZoomApprovedDomain_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomApprovedDomain: pickZoomApprovedDomain(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomApprovedDomain: pickZoomApprovedDomain(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomApprovedDomainWithOnConflictMutationResult = FetchResult<
  InsertZoomApprovedDomainWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomApprovedDomainWithOnConflictMutationResultEx =
  InsertZoomApprovedDomainWithOnConflictMutationResult & ZoomApprovedDomainByIdHookResultEx;

type InsertZoomApprovedDomainWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomApprovedDomainWithOnConflictMutation,
  InsertZoomApprovedDomainWithOnConflictMutationVariables
>;
type InsertZoomApprovedDomainWithOnConflictWrappedLazyMutationFn = ({
  zoomApprovedDomain,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomApprovedDomain: ZoomApprovedDomain_Insert_Input;
  onConflict: ZoomApprovedDomain_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomApprovedDomainWithOnConflictMutation,
      InsertZoomApprovedDomainWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomApprovedDomainWithOnConflictMutationResultEx>;
export type InsertZoomApprovedDomainWithOnConflictLazyMutationReturn = [
  InsertZoomApprovedDomainWithOnConflictWrappedLazyMutationFn,
  InsertZoomApprovedDomainWithOnConflictMutationResultEx
];

// Function
function useInsertZoomApprovedDomainWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomApprovedDomainWithOnConflictMutation,
      InsertZoomApprovedDomainWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomApprovedDomainWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomApprovedDomainWithOnConflictLazyMutationFn = useMutation<
    InsertZoomApprovedDomainWithOnConflictMutation,
    InsertZoomApprovedDomainWithOnConflictMutationVariables
  >(InsertZoomApprovedDomainWithOnConflictDocument, options);
  const pickZoomApprovedDomain: PickInsertZoomApprovedDomainFn = (
    mutation: InsertZoomApprovedDomainWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomApprovedDomain?.returning && mutation.insert_zoomApprovedDomain.returning[0];
  };

  const wrappedLazyMutation: InsertZoomApprovedDomainWithOnConflictWrappedLazyMutationFn = async ({
    zoomApprovedDomain,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomApprovedDomain });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomApprovedDomainWithOnConflictMutation,
      InsertZoomApprovedDomainWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomApprovedDomain'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomApprovedDomainWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomApprovedDomain',
          objects: [objectForInsert as ZoomApprovedDomain_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomApprovedDomain: pickZoomApprovedDomain(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomApprovedDomain: pickZoomApprovedDomain(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomApprovedDomainObjectsMutationResult = FetchResult<
  InsertZoomApprovedDomainMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomApprovedDomainObjectsMutationResultEx = InsertZoomApprovedDomainMutationResult &
  ZoomApprovedDomainObjectsHookResultEx;

type PickInsertZoomApprovedDomainObjectsFn = (
  mutation: InsertZoomApprovedDomainMutation | null | undefined
) => ZoomApprovedDomainFragment[];
type InsertZoomApprovedDomainObjectsLazyMutationFn = MutationTuple<
  InsertZoomApprovedDomainMutation,
  InsertZoomApprovedDomainMutationVariables
>;
type InsertZoomApprovedDomainObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomApprovedDomainMutation, InsertZoomApprovedDomainMutationVariables>
) => Promise<InsertZoomApprovedDomainObjectsMutationResultEx>;
export type InsertZoomApprovedDomainObjectsLazyMutationReturn = [
  InsertZoomApprovedDomainObjectsWrappedLazyMutationFn,
  InsertZoomApprovedDomainObjectsMutationResultEx
];

// Function
function useInsertZoomApprovedDomainObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomApprovedDomainMutation, InsertZoomApprovedDomainMutationVariables>,
    'mutation'
  >
): InsertZoomApprovedDomainObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomApprovedDomainObjectsLazyMutationFn = useMutation<
    InsertZoomApprovedDomainMutation,
    InsertZoomApprovedDomainMutationVariables
  >(InsertZoomApprovedDomainDocument, options);
  const pickObjects: PickInsertZoomApprovedDomainObjectsFn = (
    mutation: InsertZoomApprovedDomainMutation | null | undefined
  ) => {
    return mutation?.insert_zoomApprovedDomain?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomApprovedDomainObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomApprovedDomainObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomApprovedDomainByIdMutationResult = FetchResult<
  UpdateZoomApprovedDomainByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomApprovedDomainByIdMutationResultEx = UpdateZoomApprovedDomainByIdMutationResult &
  ZoomApprovedDomainByIdHookResultEx;

type PickUpdateZoomApprovedDomainByIdFn = (
  mutation: UpdateZoomApprovedDomainByIdMutation | null | undefined
) => ZoomApprovedDomainFragment | null | undefined;
type UpdateZoomApprovedDomainByIdLazyMutationFn = MutationTuple<
  UpdateZoomApprovedDomainByIdMutation,
  UpdateZoomApprovedDomainByIdMutationVariables
>;
type UpdateZoomApprovedDomainByIdWrappedLazyMutationFn = ({
  zoomApprovedDomainId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomApprovedDomainId: string;
  set: ZoomApprovedDomain_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomApprovedDomainByIdMutation, UpdateZoomApprovedDomainByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomApprovedDomainByIdMutationResultEx>;
export type UpdateZoomApprovedDomainByIdLazyMutationReturn = [
  UpdateZoomApprovedDomainByIdWrappedLazyMutationFn,
  UpdateZoomApprovedDomainByIdMutationResultEx
];

function useUpdateZoomApprovedDomainById(
  options?: Omit<
    MutationHookOptions<UpdateZoomApprovedDomainByIdMutation, UpdateZoomApprovedDomainByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomApprovedDomainByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomApprovedDomainByIdLazyMutationFn = useMutation<
    UpdateZoomApprovedDomainByIdMutation,
    UpdateZoomApprovedDomainByIdMutationVariables
  >(UpdateZoomApprovedDomainByIdDocument, options);

  const pickZoomApprovedDomain: PickUpdateZoomApprovedDomainByIdFn = (mutation) => {
    return mutation?.update_zoomApprovedDomain?.returning && mutation.update_zoomApprovedDomain!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomApprovedDomainByIdWrappedLazyMutationFn = async ({
    zoomApprovedDomainId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomApprovedDomainByIdMutation,
      UpdateZoomApprovedDomainByIdMutationVariables
    > = { variables: { id: zoomApprovedDomainId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomApprovedDomainByIdMutation>({
        operationType: 'update',
        entityName: 'zoomApprovedDomain',
        objects: [{ id: zoomApprovedDomainId, ...set }],
      });
    }

    const fetchResult: UpdateZoomApprovedDomainByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomApprovedDomainId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomApprovedDomain: pickZoomApprovedDomain(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomApprovedDomain: pickZoomApprovedDomain(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomApprovedDomainObjectsMutationResult = FetchResult<
  UpdateZoomApprovedDomainMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomApprovedDomainObjectsMutationResultEx = UpdateZoomApprovedDomainObjectsMutationResult &
  ZoomApprovedDomainObjectsHookResultEx;

// Function
type PickUpdateZoomApprovedDomainObjectsFn = (
  mutation: UpdateZoomApprovedDomainMutation | null | undefined
) => ZoomApprovedDomainFragment[];
type UpdateZoomApprovedDomainObjectsLazyMutationFn = MutationTuple<
  UpdateZoomApprovedDomainMutation,
  UpdateZoomApprovedDomainMutationVariables
>;
type UpdateZoomApprovedDomainObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomApprovedDomainMutation, UpdateZoomApprovedDomainMutationVariables>
) => Promise<UpdateZoomApprovedDomainObjectsMutationResultEx>;
export type UpdateZoomApprovedDomainObjectsLazyMutationReturn = [
  UpdateZoomApprovedDomainObjectsWrappedLazyMutationFn,
  UpdateZoomApprovedDomainObjectsMutationResultEx
];

function useUpdateZoomApprovedDomainObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomApprovedDomainMutation, UpdateZoomApprovedDomainMutationVariables>,
    'mutation'
  >
): UpdateZoomApprovedDomainObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomApprovedDomainObjectsLazyMutationFn = useMutation<
    UpdateZoomApprovedDomainMutation,
    UpdateZoomApprovedDomainMutationVariables
  >(UpdateZoomApprovedDomainDocument, options);

  const pickObjects: PickUpdateZoomApprovedDomainObjectsFn = (
    mutation: UpdateZoomApprovedDomainMutation | null | undefined
  ) => {
    return mutation?.update_zoomApprovedDomain?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomApprovedDomainObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomApprovedDomainObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomApprovedDomainModelByIdFetchResult = FetchResult<
  RemoveZoomApprovedDomainModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomApprovedDomainModelByIdMutationResultEx = RemoveZoomApprovedDomainModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomApprovedDomainModelFn = (
  mutation: RemoveZoomApprovedDomainModelByIdMutation | null | undefined
) => number;
type RemoveZoomApprovedDomainModelLazyMutationFn = MutationTuple<
  RemoveZoomApprovedDomainModelByIdMutation,
  RemoveZoomApprovedDomainModelByIdMutationVariables
>;
type RemoveZoomApprovedDomainModelWrappedLazyMutationFn = ({
  zoomApprovedDomainId,
  autoOptimisticResponse,
  options,
}: {
  zoomApprovedDomainId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveZoomApprovedDomainModelByIdMutation,
      RemoveZoomApprovedDomainModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveZoomApprovedDomainModelByIdMutationResultEx>;
export type RemoveZoomApprovedDomainModelLazyMutationReturn = [
  RemoveZoomApprovedDomainModelWrappedLazyMutationFn,
  RemoveZoomApprovedDomainModelByIdMutationResultEx
];

function useRemoveZoomApprovedDomainModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomApprovedDomainModelByIdMutation, RemoveZoomApprovedDomainModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomApprovedDomainModelLazyMutationFn = useMutation<
    RemoveZoomApprovedDomainModelByIdMutation,
    RemoveZoomApprovedDomainModelByIdMutationVariables
  >(RemoveZoomApprovedDomainModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomApprovedDomainModelFn = (
    mutation: RemoveZoomApprovedDomainModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomApprovedDomain?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomApprovedDomainModelWrappedLazyMutationFn = async ({
    zoomApprovedDomainId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomApprovedDomainModelMutation,
      RemoveZoomApprovedDomainModelByIdMutationVariables
    > = { variables: { id: zoomApprovedDomainId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomApprovedDomainModelMutation>(
        { operationType: 'delete', entityName: 'zoomApprovedDomain', objects: [{ id: zoomApprovedDomainId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomApprovedDomainModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomApprovedDomain',
        entityId: zoomApprovedDomainId,
      });
    }

    const fetchResult: RemoveZoomApprovedDomainModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomApprovedDomainModelObjectsMutationResult = FetchResult<
  RemoveZoomApprovedDomainModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomApprovedDomainModelObjectsMutationResultEx = RemoveZoomApprovedDomainModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomApprovedDomainModelObjectsFn = (
  mutation: RemoveZoomApprovedDomainModelMutation | null | undefined
) => number;
type RemoveZoomApprovedDomainModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomApprovedDomainModelMutation,
  RemoveZoomApprovedDomainModelMutationVariables
>;
type RemoveZoomApprovedDomainModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveZoomApprovedDomainModelMutation,
    RemoveZoomApprovedDomainModelMutationVariables
  >
) => Promise<RemoveZoomApprovedDomainModelObjectsMutationResultEx>;
export type RemoveZoomApprovedDomainModelObjectsLazyMutationReturn = [
  RemoveZoomApprovedDomainModelObjectsWrappedLazyMutationFn,
  RemoveZoomApprovedDomainModelObjectsMutationResultEx
];

function useRemoveZoomApprovedDomainModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomApprovedDomainModelMutation, RemoveZoomApprovedDomainModelMutationVariables>,
    'mutation'
  >
): RemoveZoomApprovedDomainModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveZoomApprovedDomainModelMutation,
    RemoveZoomApprovedDomainModelMutationVariables
  >(RemoveZoomApprovedDomainModelDocument, options);

  const pickAffectedRows: PickRemoveZoomApprovedDomainModelObjectsFn = (
    mutation: RemoveZoomApprovedDomainModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomApprovedDomain?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomApprovedDomainModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomApprovedDomainModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomApprovedDomain Fragment Helper Object
//------------------------------------------------

export const ZoomApprovedDomainFragmentGQLHooks = {
  useQueryById: useQueryZoomApprovedDomainById,
  useQueryByIdLazy: useQueryZoomApprovedDomainByIdLazy,
  useQueryObjects: useQueryZoomApprovedDomainObjects,
  useQueryObjectsLazy: useQueryZoomApprovedDomainObjectsLazy,
  useSubscribeToById: useSubscribeToZoomApprovedDomainById,
  useSubscribeToObjects: useSubscribeToZoomApprovedDomainObjects,
  useInsert: useInsertZoomApprovedDomain,
  useInsertWithOnConflict: useInsertZoomApprovedDomainWithOnConflict,
  useInsertObjects: useInsertZoomApprovedDomainObjects,
  useUpdateById: useUpdateZoomApprovedDomainById,
  useUpdateObjects: useUpdateZoomApprovedDomainObjects,
};

// zoomApprovedDomain MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomApprovedDomainModelGQLHooks = {
  useRemoveById: useRemoveZoomApprovedDomainModelById,
  useRemoveObjects: useRemoveZoomApprovedDomainModelObjects,
};

// zoomConversationStarterAnswer REACT
//------------------------------------------------

export type ZoomConversationStarterAnswerByIdHookResultEx = {
  zoomConversationStarterAnswer: ZoomConversationStarterAnswerFragment | null | undefined;
};
export type ZoomConversationStarterAnswerObjectsHookResultEx = { objects: ZoomConversationStarterAnswerFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomConversationStarterAnswerByIdResult = LazyQueryResult<
  QueryZoomConversationStarterAnswerByIdQuery,
  QueryZoomConversationStarterAnswerByIdQueryVariables
>;
type QueryZoomConversationStarterAnswerByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomConversationStarterAnswerByIdQuery,
          QueryZoomConversationStarterAnswerByIdQueryVariables,
          QueryZoomConversationStarterAnswerByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomConversationStarterAnswerByIdResultEx = Omit<
  QueryZoomConversationStarterAnswerByIdResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryZoomConversationStarterAnswerByIdSubScribeToMore;
} & ZoomConversationStarterAnswerByIdHookResultEx;

// Function
function useQueryZoomConversationStarterAnswerById({
  zoomConversationStarterAnswerId,
  options,
}: {
  zoomConversationStarterAnswerId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomConversationStarterAnswerByIdQuery, QueryZoomConversationStarterAnswerByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomConversationStarterAnswerByIdResultEx {
  const _query: QueryZoomConversationStarterAnswerByIdResult = useQuery<
    QueryZoomConversationStarterAnswerByIdQuery,
    QueryZoomConversationStarterAnswerByIdQueryVariables
  >(QueryZoomConversationStarterAnswerByIdDocument, { variables: { zoomConversationStarterAnswerId }, ...options });

  const typedSubscribeToMore: QueryZoomConversationStarterAnswerByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomConversationStarterAnswerByIdDocument,
      variables: { zoomConversationStarterAnswerId } as QueryZoomConversationStarterAnswerByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    zoomConversationStarterAnswer: query?.data?.zoomConversationStarterAnswer_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomConversationStarterAnswerByIdFn = (
  query: QueryZoomConversationStarterAnswerByIdQuery | null | undefined
) => ZoomConversationStarterAnswerFragment | null | undefined;
type QueryZoomConversationStarterAnswerByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomConversationStarterAnswerByIdQueryVariables> | undefined) => void,
  QueryZoomConversationStarterAnswerByIdResult
];
type QueryZoomConversationStarterAnswerByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomConversationStarterAnswerByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomConversationStarterAnswerByIdLazyReturn = [
  QueryZoomConversationStarterAnswerByIdWrappedLazyFn,
  QueryZoomConversationStarterAnswerByIdResultEx
];

// Function
function useQueryZoomConversationStarterAnswerByIdLazy({
  zoomConversationStarterAnswerId,
  options,
}: {
  zoomConversationStarterAnswerId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomConversationStarterAnswerByIdQuery, QueryZoomConversationStarterAnswerByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomConversationStarterAnswerByIdLazyReturn {
  const lazyQuery: QueryZoomConversationStarterAnswerByIdLazyFn = useLazyQuery<
    QueryZoomConversationStarterAnswerByIdQuery,
    QueryZoomConversationStarterAnswerByIdQueryVariables
  >(QueryZoomConversationStarterAnswerByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomConversationStarterAnswerById: PickQueryZoomConversationStarterAnswerByIdFn = (query) => {
    return query && query.zoomConversationStarterAnswer_by_pk;
  };
  const wrappedLazyQuery: QueryZoomConversationStarterAnswerByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomConversationStarterAnswerByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomConversationStarterAnswerByIdDocument,
        variables: { zoomConversationStarterAnswerId } as QueryZoomConversationStarterAnswerByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomConversationStarterAnswer: pickQueryZoomConversationStarterAnswerById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomConversationStarterAnswerObjectsResult = LazyQueryResult<
  QueryZoomConversationStarterAnswerObjectsQuery,
  QueryZoomConversationStarterAnswerObjectsQueryVariables
>;
type QueryZoomConversationStarterAnswerObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomConversationStarterAnswerObjectsQuery,
          QueryZoomConversationStarterAnswerObjectsQueryVariables,
          QueryZoomConversationStarterAnswerObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomConversationStarterAnswerObjectsResultEx = Omit<
  QueryZoomConversationStarterAnswerObjectsResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryZoomConversationStarterAnswerObjectsSubScribeToMore;
} & ZoomConversationStarterAnswerObjectsHookResultEx;

// Function
function useQueryZoomConversationStarterAnswerObjects(
  options: Omit<
    QueryHookOptions<
      QueryZoomConversationStarterAnswerObjectsQuery,
      QueryZoomConversationStarterAnswerObjectsQueryVariables
    >,
    'query'
  >
): QueryZoomConversationStarterAnswerObjectsResultEx {
  const _query: QueryZoomConversationStarterAnswerObjectsResult = useQuery<
    QueryZoomConversationStarterAnswerObjectsQuery,
    QueryZoomConversationStarterAnswerObjectsQueryVariables
  >(QueryZoomConversationStarterAnswerObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomConversationStarterAnswerObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomConversationStarterAnswerObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomConversationStarterAnswer || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomConversationStarterAnswerObjectsFn = (
  query: QueryZoomConversationStarterAnswerObjectsQuery | null | undefined
) => ZoomConversationStarterAnswerFragment[];
type QueryZoomConversationStarterAnswerObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomConversationStarterAnswerObjectsQueryVariables> | undefined) => void,
  QueryZoomConversationStarterAnswerObjectsResult
];
type QueryZoomConversationStarterAnswerObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomConversationStarterAnswerObjectsQueryVariables>
) => void;
export type QueryZoomConversationStarterAnswerObjectsLazyReturn = [
  QueryZoomConversationStarterAnswerObjectsWrappedLazyFn,
  QueryZoomConversationStarterAnswerObjectsResultEx
];

// Function
function useQueryZoomConversationStarterAnswerObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<
      QueryZoomConversationStarterAnswerObjectsQuery,
      QueryZoomConversationStarterAnswerObjectsQueryVariables
    >,
    'query'
  >
): QueryZoomConversationStarterAnswerObjectsLazyReturn {
  const lazyQuery: QueryZoomConversationStarterAnswerObjectsLazyFn = useLazyQuery<
    QueryZoomConversationStarterAnswerObjectsQuery,
    QueryZoomConversationStarterAnswerObjectsQueryVariables
  >(QueryZoomConversationStarterAnswerObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomConversationStarterAnswerObjectsFn = (
    query: QueryZoomConversationStarterAnswerObjectsQuery | null | undefined
  ) => {
    return query?.zoomConversationStarterAnswer || [];
  };
  const wrappedLazyQuery: QueryZoomConversationStarterAnswerObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomConversationStarterAnswerObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomConversationStarterAnswerObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomConversationStarterAnswerByIdResult = {
  variables?: SubscribeToZoomConversationStarterAnswerByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomConversationStarterAnswerByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomConversationStarterAnswerByIdResultEx = SubscribeToZoomConversationStarterAnswerByIdResult &
  ZoomConversationStarterAnswerByIdHookResultEx;

// Function
function useSubscribeToZoomConversationStarterAnswerById({
  zoomConversationStarterAnswerId,
  options,
}: {
  zoomConversationStarterAnswerId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomConversationStarterAnswerByIdSubscription,
      SubscribeToZoomConversationStarterAnswerByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomConversationStarterAnswerByIdResultEx {
  const subscription: SubscribeToZoomConversationStarterAnswerByIdResult = useSubscription<
    SubscribeToZoomConversationStarterAnswerByIdSubscription,
    SubscribeToZoomConversationStarterAnswerByIdSubscriptionVariables
  >(SubscribeToZoomConversationStarterAnswerByIdDocument, {
    variables: { zoomConversationStarterAnswerId },
    ...options,
  });
  return { ...subscription, zoomConversationStarterAnswer: subscription?.data?.zoomConversationStarterAnswer_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomConversationStarterAnswerObjectsResult = {
  variables?: SubscribeToZoomConversationStarterAnswerObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomConversationStarterAnswerObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomConversationStarterAnswerObjectsResultEx =
  SubscribeToZoomConversationStarterAnswerObjectsResult & ZoomConversationStarterAnswerObjectsHookResultEx;

// Function
function useSubscribeToZoomConversationStarterAnswerObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomConversationStarterAnswerObjectsSubscription,
      SubscribeToZoomConversationStarterAnswerObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomConversationStarterAnswerObjectsResultEx {
  const subscription: SubscribeToZoomConversationStarterAnswerObjectsResult = useSubscription<
    SubscribeToZoomConversationStarterAnswerObjectsSubscription,
    SubscribeToZoomConversationStarterAnswerObjectsSubscriptionVariables
  >(SubscribeToZoomConversationStarterAnswerObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomConversationStarterAnswer || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomConversationStarterAnswerMutationResult = FetchResult<
  InsertZoomConversationStarterAnswerMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConversationStarterAnswerMutationResultEx = InsertZoomConversationStarterAnswerMutationResult &
  ZoomConversationStarterAnswerByIdHookResultEx;

type PickInsertZoomConversationStarterAnswerFn = (
  mutation: InsertZoomConversationStarterAnswerMutation | null | undefined
) => ZoomConversationStarterAnswerFragment | null | undefined;
type InsertZoomConversationStarterAnswerLazyMutationFn = MutationTuple<
  InsertZoomConversationStarterAnswerMutation,
  InsertZoomConversationStarterAnswerMutationVariables
>;
type InsertZoomConversationStarterAnswerWrappedLazyMutationFn = ({
  zoomConversationStarterAnswer,
  autoOptimisticResponse,
  options,
}: {
  zoomConversationStarterAnswer: ZoomConversationStarterAnswer_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomConversationStarterAnswerMutation,
      InsertZoomConversationStarterAnswerMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomConversationStarterAnswerMutationResultEx>;
export type InsertZoomConversationStarterAnswerLazyMutationReturn = [
  InsertZoomConversationStarterAnswerWrappedLazyMutationFn,
  InsertZoomConversationStarterAnswerMutationResultEx
];

// Function
function useInsertZoomConversationStarterAnswer(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConversationStarterAnswerMutation,
      InsertZoomConversationStarterAnswerMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomConversationStarterAnswerLazyMutationReturn {
  const lazyMutation: InsertZoomConversationStarterAnswerLazyMutationFn = useMutation<
    InsertZoomConversationStarterAnswerMutation,
    InsertZoomConversationStarterAnswerMutationVariables
  >(InsertZoomConversationStarterAnswerDocument, options);
  const pickZoomConversationStarterAnswer: PickInsertZoomConversationStarterAnswerFn = (mutation) => {
    return (
      mutation?.insert_zoomConversationStarterAnswer?.returning &&
      mutation?.insert_zoomConversationStarterAnswer?.returning[0]
    );
  };

  const wrappedLazyMutation: InsertZoomConversationStarterAnswerWrappedLazyMutationFn = async ({
    zoomConversationStarterAnswer,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomConversationStarterAnswer });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomConversationStarterAnswerMutation,
      InsertZoomConversationStarterAnswerMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomConversationStarterAnswer'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomConversationStarterAnswerMutation>({
          operationType: 'insert',
          entityName: 'zoomConversationStarterAnswer',
          objects: [objectForInsert as ZoomConversationStarterAnswer_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomConversationStarterAnswer: pickZoomConversationStarterAnswer(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConversationStarterAnswer: pickZoomConversationStarterAnswer(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomConversationStarterAnswerWithOnConflictMutationResult = FetchResult<
  InsertZoomConversationStarterAnswerWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConversationStarterAnswerWithOnConflictMutationResultEx =
  InsertZoomConversationStarterAnswerWithOnConflictMutationResult & ZoomConversationStarterAnswerByIdHookResultEx;

type InsertZoomConversationStarterAnswerWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomConversationStarterAnswerWithOnConflictMutation,
  InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
>;
type InsertZoomConversationStarterAnswerWithOnConflictWrappedLazyMutationFn = ({
  zoomConversationStarterAnswer,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomConversationStarterAnswer: ZoomConversationStarterAnswer_Insert_Input;
  onConflict: ZoomConversationStarterAnswer_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomConversationStarterAnswerWithOnConflictMutation,
      InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomConversationStarterAnswerWithOnConflictMutationResultEx>;
export type InsertZoomConversationStarterAnswerWithOnConflictLazyMutationReturn = [
  InsertZoomConversationStarterAnswerWithOnConflictWrappedLazyMutationFn,
  InsertZoomConversationStarterAnswerWithOnConflictMutationResultEx
];

// Function
function useInsertZoomConversationStarterAnswerWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConversationStarterAnswerWithOnConflictMutation,
      InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomConversationStarterAnswerWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomConversationStarterAnswerWithOnConflictLazyMutationFn = useMutation<
    InsertZoomConversationStarterAnswerWithOnConflictMutation,
    InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
  >(InsertZoomConversationStarterAnswerWithOnConflictDocument, options);
  const pickZoomConversationStarterAnswer: PickInsertZoomConversationStarterAnswerFn = (
    mutation: InsertZoomConversationStarterAnswerWithOnConflictMutation | null | undefined
  ) => {
    return (
      mutation?.insert_zoomConversationStarterAnswer?.returning &&
      mutation.insert_zoomConversationStarterAnswer.returning[0]
    );
  };

  const wrappedLazyMutation: InsertZoomConversationStarterAnswerWithOnConflictWrappedLazyMutationFn = async ({
    zoomConversationStarterAnswer,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomConversationStarterAnswer });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomConversationStarterAnswerWithOnConflictMutation,
      InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomConversationStarterAnswer'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomConversationStarterAnswerWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomConversationStarterAnswer',
          objects: [objectForInsert as ZoomConversationStarterAnswer_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomConversationStarterAnswer: pickZoomConversationStarterAnswer(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConversationStarterAnswer: pickZoomConversationStarterAnswer(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomConversationStarterAnswerObjectsMutationResult = FetchResult<
  InsertZoomConversationStarterAnswerMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConversationStarterAnswerObjectsMutationResultEx =
  InsertZoomConversationStarterAnswerMutationResult & ZoomConversationStarterAnswerObjectsHookResultEx;

type PickInsertZoomConversationStarterAnswerObjectsFn = (
  mutation: InsertZoomConversationStarterAnswerMutation | null | undefined
) => ZoomConversationStarterAnswerFragment[];
type InsertZoomConversationStarterAnswerObjectsLazyMutationFn = MutationTuple<
  InsertZoomConversationStarterAnswerMutation,
  InsertZoomConversationStarterAnswerMutationVariables
>;
type InsertZoomConversationStarterAnswerObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    InsertZoomConversationStarterAnswerMutation,
    InsertZoomConversationStarterAnswerMutationVariables
  >
) => Promise<InsertZoomConversationStarterAnswerObjectsMutationResultEx>;
export type InsertZoomConversationStarterAnswerObjectsLazyMutationReturn = [
  InsertZoomConversationStarterAnswerObjectsWrappedLazyMutationFn,
  InsertZoomConversationStarterAnswerObjectsMutationResultEx
];

// Function
function useInsertZoomConversationStarterAnswerObjects(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConversationStarterAnswerMutation,
      InsertZoomConversationStarterAnswerMutationVariables
    >,
    'mutation'
  >
): InsertZoomConversationStarterAnswerObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomConversationStarterAnswerObjectsLazyMutationFn = useMutation<
    InsertZoomConversationStarterAnswerMutation,
    InsertZoomConversationStarterAnswerMutationVariables
  >(InsertZoomConversationStarterAnswerDocument, options);
  const pickObjects: PickInsertZoomConversationStarterAnswerObjectsFn = (
    mutation: InsertZoomConversationStarterAnswerMutation | null | undefined
  ) => {
    return mutation?.insert_zoomConversationStarterAnswer?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomConversationStarterAnswerObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomConversationStarterAnswerObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomConversationStarterAnswerByIdMutationResult = FetchResult<
  UpdateZoomConversationStarterAnswerByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomConversationStarterAnswerByIdMutationResultEx =
  UpdateZoomConversationStarterAnswerByIdMutationResult & ZoomConversationStarterAnswerByIdHookResultEx;

type PickUpdateZoomConversationStarterAnswerByIdFn = (
  mutation: UpdateZoomConversationStarterAnswerByIdMutation | null | undefined
) => ZoomConversationStarterAnswerFragment | null | undefined;
type UpdateZoomConversationStarterAnswerByIdLazyMutationFn = MutationTuple<
  UpdateZoomConversationStarterAnswerByIdMutation,
  UpdateZoomConversationStarterAnswerByIdMutationVariables
>;
type UpdateZoomConversationStarterAnswerByIdWrappedLazyMutationFn = ({
  zoomConversationStarterAnswerId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomConversationStarterAnswerId: string;
  set: ZoomConversationStarterAnswer_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateZoomConversationStarterAnswerByIdMutation,
      UpdateZoomConversationStarterAnswerByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateZoomConversationStarterAnswerByIdMutationResultEx>;
export type UpdateZoomConversationStarterAnswerByIdLazyMutationReturn = [
  UpdateZoomConversationStarterAnswerByIdWrappedLazyMutationFn,
  UpdateZoomConversationStarterAnswerByIdMutationResultEx
];

function useUpdateZoomConversationStarterAnswerById(
  options?: Omit<
    MutationHookOptions<
      UpdateZoomConversationStarterAnswerByIdMutation,
      UpdateZoomConversationStarterAnswerByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
): UpdateZoomConversationStarterAnswerByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomConversationStarterAnswerByIdLazyMutationFn = useMutation<
    UpdateZoomConversationStarterAnswerByIdMutation,
    UpdateZoomConversationStarterAnswerByIdMutationVariables
  >(UpdateZoomConversationStarterAnswerByIdDocument, options);

  const pickZoomConversationStarterAnswer: PickUpdateZoomConversationStarterAnswerByIdFn = (mutation) => {
    return (
      mutation?.update_zoomConversationStarterAnswer?.returning &&
      mutation.update_zoomConversationStarterAnswer!.returning[0]
    );
  };

  const wrappedLazyMutation: UpdateZoomConversationStarterAnswerByIdWrappedLazyMutationFn = async ({
    zoomConversationStarterAnswerId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomConversationStarterAnswerByIdMutation,
      UpdateZoomConversationStarterAnswerByIdMutationVariables
    > = { variables: { id: zoomConversationStarterAnswerId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<UpdateZoomConversationStarterAnswerByIdMutation>({
          operationType: 'update',
          entityName: 'zoomConversationStarterAnswer',
          objects: [{ id: zoomConversationStarterAnswerId, ...set }],
        });
    }

    const fetchResult: UpdateZoomConversationStarterAnswerByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomConversationStarterAnswerId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomConversationStarterAnswer: pickZoomConversationStarterAnswer(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConversationStarterAnswer: pickZoomConversationStarterAnswer(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomConversationStarterAnswerObjectsMutationResult = FetchResult<
  UpdateZoomConversationStarterAnswerMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomConversationStarterAnswerObjectsMutationResultEx =
  UpdateZoomConversationStarterAnswerObjectsMutationResult & ZoomConversationStarterAnswerObjectsHookResultEx;

// Function
type PickUpdateZoomConversationStarterAnswerObjectsFn = (
  mutation: UpdateZoomConversationStarterAnswerMutation | null | undefined
) => ZoomConversationStarterAnswerFragment[];
type UpdateZoomConversationStarterAnswerObjectsLazyMutationFn = MutationTuple<
  UpdateZoomConversationStarterAnswerMutation,
  UpdateZoomConversationStarterAnswerMutationVariables
>;
type UpdateZoomConversationStarterAnswerObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    UpdateZoomConversationStarterAnswerMutation,
    UpdateZoomConversationStarterAnswerMutationVariables
  >
) => Promise<UpdateZoomConversationStarterAnswerObjectsMutationResultEx>;
export type UpdateZoomConversationStarterAnswerObjectsLazyMutationReturn = [
  UpdateZoomConversationStarterAnswerObjectsWrappedLazyMutationFn,
  UpdateZoomConversationStarterAnswerObjectsMutationResultEx
];

function useUpdateZoomConversationStarterAnswerObjects(
  options?: Omit<
    MutationHookOptions<
      UpdateZoomConversationStarterAnswerMutation,
      UpdateZoomConversationStarterAnswerMutationVariables
    >,
    'mutation'
  >
): UpdateZoomConversationStarterAnswerObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomConversationStarterAnswerObjectsLazyMutationFn = useMutation<
    UpdateZoomConversationStarterAnswerMutation,
    UpdateZoomConversationStarterAnswerMutationVariables
  >(UpdateZoomConversationStarterAnswerDocument, options);

  const pickObjects: PickUpdateZoomConversationStarterAnswerObjectsFn = (
    mutation: UpdateZoomConversationStarterAnswerMutation | null | undefined
  ) => {
    return mutation?.update_zoomConversationStarterAnswer?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomConversationStarterAnswerObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomConversationStarterAnswerObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomConversationStarterAnswerModelByIdFetchResult = FetchResult<
  RemoveZoomConversationStarterAnswerModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomConversationStarterAnswerModelByIdMutationResultEx =
  RemoveZoomConversationStarterAnswerModelByIdFetchResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomConversationStarterAnswerModelFn = (
  mutation: RemoveZoomConversationStarterAnswerModelByIdMutation | null | undefined
) => number;
type RemoveZoomConversationStarterAnswerModelLazyMutationFn = MutationTuple<
  RemoveZoomConversationStarterAnswerModelByIdMutation,
  RemoveZoomConversationStarterAnswerModelByIdMutationVariables
>;
type RemoveZoomConversationStarterAnswerModelWrappedLazyMutationFn = ({
  zoomConversationStarterAnswerId,
  autoOptimisticResponse,
  options,
}: {
  zoomConversationStarterAnswerId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveZoomConversationStarterAnswerModelByIdMutation,
      RemoveZoomConversationStarterAnswerModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveZoomConversationStarterAnswerModelByIdMutationResultEx>;
export type RemoveZoomConversationStarterAnswerModelLazyMutationReturn = [
  RemoveZoomConversationStarterAnswerModelWrappedLazyMutationFn,
  RemoveZoomConversationStarterAnswerModelByIdMutationResultEx
];

function useRemoveZoomConversationStarterAnswerModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomConversationStarterAnswerModelByIdMutation,
      RemoveZoomConversationStarterAnswerModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomConversationStarterAnswerModelLazyMutationFn = useMutation<
    RemoveZoomConversationStarterAnswerModelByIdMutation,
    RemoveZoomConversationStarterAnswerModelByIdMutationVariables
  >(RemoveZoomConversationStarterAnswerModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomConversationStarterAnswerModelFn = (
    mutation: RemoveZoomConversationStarterAnswerModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomConversationStarterAnswer?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomConversationStarterAnswerModelWrappedLazyMutationFn = async ({
    zoomConversationStarterAnswerId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomConversationStarterAnswerModelMutation,
      RemoveZoomConversationStarterAnswerModelByIdMutationVariables
    > = { variables: { id: zoomConversationStarterAnswerId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<RemoveZoomConversationStarterAnswerModelMutation>({
          operationType: 'delete',
          entityName: 'zoomConversationStarterAnswer',
          objects: [{ id: zoomConversationStarterAnswerId }],
        });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomConversationStarterAnswerModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomConversationStarterAnswer',
        entityId: zoomConversationStarterAnswerId,
      });
    }

    const fetchResult: RemoveZoomConversationStarterAnswerModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomConversationStarterAnswerModelObjectsMutationResult = FetchResult<
  RemoveZoomConversationStarterAnswerModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomConversationStarterAnswerModelObjectsMutationResultEx =
  RemoveZoomConversationStarterAnswerModelObjectsMutationResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomConversationStarterAnswerModelObjectsFn = (
  mutation: RemoveZoomConversationStarterAnswerModelMutation | null | undefined
) => number;
type RemoveZoomConversationStarterAnswerModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomConversationStarterAnswerModelMutation,
  RemoveZoomConversationStarterAnswerModelMutationVariables
>;
type RemoveZoomConversationStarterAnswerModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveZoomConversationStarterAnswerModelMutation,
    RemoveZoomConversationStarterAnswerModelMutationVariables
  >
) => Promise<RemoveZoomConversationStarterAnswerModelObjectsMutationResultEx>;
export type RemoveZoomConversationStarterAnswerModelObjectsLazyMutationReturn = [
  RemoveZoomConversationStarterAnswerModelObjectsWrappedLazyMutationFn,
  RemoveZoomConversationStarterAnswerModelObjectsMutationResultEx
];

function useRemoveZoomConversationStarterAnswerModelObjects(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomConversationStarterAnswerModelMutation,
      RemoveZoomConversationStarterAnswerModelMutationVariables
    >,
    'mutation'
  >
): RemoveZoomConversationStarterAnswerModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveZoomConversationStarterAnswerModelMutation,
    RemoveZoomConversationStarterAnswerModelMutationVariables
  >(RemoveZoomConversationStarterAnswerModelDocument, options);

  const pickAffectedRows: PickRemoveZoomConversationStarterAnswerModelObjectsFn = (
    mutation: RemoveZoomConversationStarterAnswerModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomConversationStarterAnswer?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomConversationStarterAnswerModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomConversationStarterAnswerModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomConversationStarterAnswer Fragment Helper Object
//------------------------------------------------

export const ZoomConversationStarterAnswerFragmentGQLHooks = {
  useQueryById: useQueryZoomConversationStarterAnswerById,
  useQueryByIdLazy: useQueryZoomConversationStarterAnswerByIdLazy,
  useQueryObjects: useQueryZoomConversationStarterAnswerObjects,
  useQueryObjectsLazy: useQueryZoomConversationStarterAnswerObjectsLazy,
  useSubscribeToById: useSubscribeToZoomConversationStarterAnswerById,
  useSubscribeToObjects: useSubscribeToZoomConversationStarterAnswerObjects,
  useInsert: useInsertZoomConversationStarterAnswer,
  useInsertWithOnConflict: useInsertZoomConversationStarterAnswerWithOnConflict,
  useInsertObjects: useInsertZoomConversationStarterAnswerObjects,
  useUpdateById: useUpdateZoomConversationStarterAnswerById,
  useUpdateObjects: useUpdateZoomConversationStarterAnswerObjects,
};

// zoomConversationStarterAnswer MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomConversationStarterAnswerModelGQLHooks = {
  useRemoveById: useRemoveZoomConversationStarterAnswerModelById,
  useRemoveObjects: useRemoveZoomConversationStarterAnswerModelObjects,
};

// zoomConversationStarterPrompt REACT
//------------------------------------------------

export type ZoomConversationStarterPromptByIdHookResultEx = {
  zoomConversationStarterPrompt: ZoomConversationStarterPromptFragment | null | undefined;
};
export type ZoomConversationStarterPromptObjectsHookResultEx = { objects: ZoomConversationStarterPromptFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomConversationStarterPromptByIdResult = LazyQueryResult<
  QueryZoomConversationStarterPromptByIdQuery,
  QueryZoomConversationStarterPromptByIdQueryVariables
>;
type QueryZoomConversationStarterPromptByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomConversationStarterPromptByIdQuery,
          QueryZoomConversationStarterPromptByIdQueryVariables,
          QueryZoomConversationStarterPromptByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomConversationStarterPromptByIdResultEx = Omit<
  QueryZoomConversationStarterPromptByIdResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryZoomConversationStarterPromptByIdSubScribeToMore;
} & ZoomConversationStarterPromptByIdHookResultEx;

// Function
function useQueryZoomConversationStarterPromptById({
  zoomConversationStarterPromptId,
  options,
}: {
  zoomConversationStarterPromptId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomConversationStarterPromptByIdQuery, QueryZoomConversationStarterPromptByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomConversationStarterPromptByIdResultEx {
  const _query: QueryZoomConversationStarterPromptByIdResult = useQuery<
    QueryZoomConversationStarterPromptByIdQuery,
    QueryZoomConversationStarterPromptByIdQueryVariables
  >(QueryZoomConversationStarterPromptByIdDocument, { variables: { zoomConversationStarterPromptId }, ...options });

  const typedSubscribeToMore: QueryZoomConversationStarterPromptByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomConversationStarterPromptByIdDocument,
      variables: { zoomConversationStarterPromptId } as QueryZoomConversationStarterPromptByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    zoomConversationStarterPrompt: query?.data?.zoomConversationStarterPrompt_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomConversationStarterPromptByIdFn = (
  query: QueryZoomConversationStarterPromptByIdQuery | null | undefined
) => ZoomConversationStarterPromptFragment | null | undefined;
type QueryZoomConversationStarterPromptByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomConversationStarterPromptByIdQueryVariables> | undefined) => void,
  QueryZoomConversationStarterPromptByIdResult
];
type QueryZoomConversationStarterPromptByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomConversationStarterPromptByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomConversationStarterPromptByIdLazyReturn = [
  QueryZoomConversationStarterPromptByIdWrappedLazyFn,
  QueryZoomConversationStarterPromptByIdResultEx
];

// Function
function useQueryZoomConversationStarterPromptByIdLazy({
  zoomConversationStarterPromptId,
  options,
}: {
  zoomConversationStarterPromptId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomConversationStarterPromptByIdQuery, QueryZoomConversationStarterPromptByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomConversationStarterPromptByIdLazyReturn {
  const lazyQuery: QueryZoomConversationStarterPromptByIdLazyFn = useLazyQuery<
    QueryZoomConversationStarterPromptByIdQuery,
    QueryZoomConversationStarterPromptByIdQueryVariables
  >(QueryZoomConversationStarterPromptByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomConversationStarterPromptById: PickQueryZoomConversationStarterPromptByIdFn = (query) => {
    return query && query.zoomConversationStarterPrompt_by_pk;
  };
  const wrappedLazyQuery: QueryZoomConversationStarterPromptByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomConversationStarterPromptByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomConversationStarterPromptByIdDocument,
        variables: { zoomConversationStarterPromptId } as QueryZoomConversationStarterPromptByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomConversationStarterPrompt: pickQueryZoomConversationStarterPromptById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomConversationStarterPromptObjectsResult = LazyQueryResult<
  QueryZoomConversationStarterPromptObjectsQuery,
  QueryZoomConversationStarterPromptObjectsQueryVariables
>;
type QueryZoomConversationStarterPromptObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomConversationStarterPromptObjectsQuery,
          QueryZoomConversationStarterPromptObjectsQueryVariables,
          QueryZoomConversationStarterPromptObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomConversationStarterPromptObjectsResultEx = Omit<
  QueryZoomConversationStarterPromptObjectsResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryZoomConversationStarterPromptObjectsSubScribeToMore;
} & ZoomConversationStarterPromptObjectsHookResultEx;

// Function
function useQueryZoomConversationStarterPromptObjects(
  options: Omit<
    QueryHookOptions<
      QueryZoomConversationStarterPromptObjectsQuery,
      QueryZoomConversationStarterPromptObjectsQueryVariables
    >,
    'query'
  >
): QueryZoomConversationStarterPromptObjectsResultEx {
  const _query: QueryZoomConversationStarterPromptObjectsResult = useQuery<
    QueryZoomConversationStarterPromptObjectsQuery,
    QueryZoomConversationStarterPromptObjectsQueryVariables
  >(QueryZoomConversationStarterPromptObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomConversationStarterPromptObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomConversationStarterPromptObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomConversationStarterPrompt || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomConversationStarterPromptObjectsFn = (
  query: QueryZoomConversationStarterPromptObjectsQuery | null | undefined
) => ZoomConversationStarterPromptFragment[];
type QueryZoomConversationStarterPromptObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomConversationStarterPromptObjectsQueryVariables> | undefined) => void,
  QueryZoomConversationStarterPromptObjectsResult
];
type QueryZoomConversationStarterPromptObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomConversationStarterPromptObjectsQueryVariables>
) => void;
export type QueryZoomConversationStarterPromptObjectsLazyReturn = [
  QueryZoomConversationStarterPromptObjectsWrappedLazyFn,
  QueryZoomConversationStarterPromptObjectsResultEx
];

// Function
function useQueryZoomConversationStarterPromptObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<
      QueryZoomConversationStarterPromptObjectsQuery,
      QueryZoomConversationStarterPromptObjectsQueryVariables
    >,
    'query'
  >
): QueryZoomConversationStarterPromptObjectsLazyReturn {
  const lazyQuery: QueryZoomConversationStarterPromptObjectsLazyFn = useLazyQuery<
    QueryZoomConversationStarterPromptObjectsQuery,
    QueryZoomConversationStarterPromptObjectsQueryVariables
  >(QueryZoomConversationStarterPromptObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomConversationStarterPromptObjectsFn = (
    query: QueryZoomConversationStarterPromptObjectsQuery | null | undefined
  ) => {
    return query?.zoomConversationStarterPrompt || [];
  };
  const wrappedLazyQuery: QueryZoomConversationStarterPromptObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomConversationStarterPromptObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomConversationStarterPromptObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomConversationStarterPromptByIdResult = {
  variables?: SubscribeToZoomConversationStarterPromptByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomConversationStarterPromptByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomConversationStarterPromptByIdResultEx = SubscribeToZoomConversationStarterPromptByIdResult &
  ZoomConversationStarterPromptByIdHookResultEx;

// Function
function useSubscribeToZoomConversationStarterPromptById({
  zoomConversationStarterPromptId,
  options,
}: {
  zoomConversationStarterPromptId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomConversationStarterPromptByIdSubscription,
      SubscribeToZoomConversationStarterPromptByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomConversationStarterPromptByIdResultEx {
  const subscription: SubscribeToZoomConversationStarterPromptByIdResult = useSubscription<
    SubscribeToZoomConversationStarterPromptByIdSubscription,
    SubscribeToZoomConversationStarterPromptByIdSubscriptionVariables
  >(SubscribeToZoomConversationStarterPromptByIdDocument, {
    variables: { zoomConversationStarterPromptId },
    ...options,
  });
  return { ...subscription, zoomConversationStarterPrompt: subscription?.data?.zoomConversationStarterPrompt_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomConversationStarterPromptObjectsResult = {
  variables?: SubscribeToZoomConversationStarterPromptObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomConversationStarterPromptObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomConversationStarterPromptObjectsResultEx =
  SubscribeToZoomConversationStarterPromptObjectsResult & ZoomConversationStarterPromptObjectsHookResultEx;

// Function
function useSubscribeToZoomConversationStarterPromptObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomConversationStarterPromptObjectsSubscription,
      SubscribeToZoomConversationStarterPromptObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomConversationStarterPromptObjectsResultEx {
  const subscription: SubscribeToZoomConversationStarterPromptObjectsResult = useSubscription<
    SubscribeToZoomConversationStarterPromptObjectsSubscription,
    SubscribeToZoomConversationStarterPromptObjectsSubscriptionVariables
  >(SubscribeToZoomConversationStarterPromptObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomConversationStarterPrompt || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomConversationStarterPromptMutationResult = FetchResult<
  InsertZoomConversationStarterPromptMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConversationStarterPromptMutationResultEx = InsertZoomConversationStarterPromptMutationResult &
  ZoomConversationStarterPromptByIdHookResultEx;

type PickInsertZoomConversationStarterPromptFn = (
  mutation: InsertZoomConversationStarterPromptMutation | null | undefined
) => ZoomConversationStarterPromptFragment | null | undefined;
type InsertZoomConversationStarterPromptLazyMutationFn = MutationTuple<
  InsertZoomConversationStarterPromptMutation,
  InsertZoomConversationStarterPromptMutationVariables
>;
type InsertZoomConversationStarterPromptWrappedLazyMutationFn = ({
  zoomConversationStarterPrompt,
  autoOptimisticResponse,
  options,
}: {
  zoomConversationStarterPrompt: ZoomConversationStarterPrompt_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomConversationStarterPromptMutation,
      InsertZoomConversationStarterPromptMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomConversationStarterPromptMutationResultEx>;
export type InsertZoomConversationStarterPromptLazyMutationReturn = [
  InsertZoomConversationStarterPromptWrappedLazyMutationFn,
  InsertZoomConversationStarterPromptMutationResultEx
];

// Function
function useInsertZoomConversationStarterPrompt(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConversationStarterPromptMutation,
      InsertZoomConversationStarterPromptMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomConversationStarterPromptLazyMutationReturn {
  const lazyMutation: InsertZoomConversationStarterPromptLazyMutationFn = useMutation<
    InsertZoomConversationStarterPromptMutation,
    InsertZoomConversationStarterPromptMutationVariables
  >(InsertZoomConversationStarterPromptDocument, options);
  const pickZoomConversationStarterPrompt: PickInsertZoomConversationStarterPromptFn = (mutation) => {
    return (
      mutation?.insert_zoomConversationStarterPrompt?.returning &&
      mutation?.insert_zoomConversationStarterPrompt?.returning[0]
    );
  };

  const wrappedLazyMutation: InsertZoomConversationStarterPromptWrappedLazyMutationFn = async ({
    zoomConversationStarterPrompt,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomConversationStarterPrompt });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomConversationStarterPromptMutation,
      InsertZoomConversationStarterPromptMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomConversationStarterPrompt'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomConversationStarterPromptMutation>({
          operationType: 'insert',
          entityName: 'zoomConversationStarterPrompt',
          objects: [objectForInsert as ZoomConversationStarterPrompt_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomConversationStarterPrompt: pickZoomConversationStarterPrompt(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConversationStarterPrompt: pickZoomConversationStarterPrompt(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertZoomConversationStarterPromptWithOnConflictMutationResult = FetchResult<
  InsertZoomConversationStarterPromptWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConversationStarterPromptWithOnConflictMutationResultEx =
  InsertZoomConversationStarterPromptWithOnConflictMutationResult & ZoomConversationStarterPromptByIdHookResultEx;

type InsertZoomConversationStarterPromptWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomConversationStarterPromptWithOnConflictMutation,
  InsertZoomConversationStarterPromptWithOnConflictMutationVariables
>;
type InsertZoomConversationStarterPromptWithOnConflictWrappedLazyMutationFn = ({
  zoomConversationStarterPrompt,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomConversationStarterPrompt: ZoomConversationStarterPrompt_Insert_Input;
  onConflict: ZoomConversationStarterPrompt_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomConversationStarterPromptWithOnConflictMutation,
      InsertZoomConversationStarterPromptWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomConversationStarterPromptWithOnConflictMutationResultEx>;
export type InsertZoomConversationStarterPromptWithOnConflictLazyMutationReturn = [
  InsertZoomConversationStarterPromptWithOnConflictWrappedLazyMutationFn,
  InsertZoomConversationStarterPromptWithOnConflictMutationResultEx
];

// Function
function useInsertZoomConversationStarterPromptWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConversationStarterPromptWithOnConflictMutation,
      InsertZoomConversationStarterPromptWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomConversationStarterPromptWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomConversationStarterPromptWithOnConflictLazyMutationFn = useMutation<
    InsertZoomConversationStarterPromptWithOnConflictMutation,
    InsertZoomConversationStarterPromptWithOnConflictMutationVariables
  >(InsertZoomConversationStarterPromptWithOnConflictDocument, options);
  const pickZoomConversationStarterPrompt: PickInsertZoomConversationStarterPromptFn = (
    mutation: InsertZoomConversationStarterPromptWithOnConflictMutation | null | undefined
  ) => {
    return (
      mutation?.insert_zoomConversationStarterPrompt?.returning &&
      mutation.insert_zoomConversationStarterPrompt.returning[0]
    );
  };

  const wrappedLazyMutation: InsertZoomConversationStarterPromptWithOnConflictWrappedLazyMutationFn = async ({
    zoomConversationStarterPrompt,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomConversationStarterPrompt });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomConversationStarterPromptWithOnConflictMutation,
      InsertZoomConversationStarterPromptWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomConversationStarterPrompt'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomConversationStarterPromptWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomConversationStarterPrompt',
          objects: [objectForInsert as ZoomConversationStarterPrompt_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomConversationStarterPrompt: pickZoomConversationStarterPrompt(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConversationStarterPrompt: pickZoomConversationStarterPrompt(lazyMutation[1].data) },
  ];
}

// Types
type InsertZoomConversationStarterPromptObjectsMutationResult = FetchResult<
  InsertZoomConversationStarterPromptMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomConversationStarterPromptObjectsMutationResultEx =
  InsertZoomConversationStarterPromptMutationResult & ZoomConversationStarterPromptObjectsHookResultEx;

type PickInsertZoomConversationStarterPromptObjectsFn = (
  mutation: InsertZoomConversationStarterPromptMutation | null | undefined
) => ZoomConversationStarterPromptFragment[];
type InsertZoomConversationStarterPromptObjectsLazyMutationFn = MutationTuple<
  InsertZoomConversationStarterPromptMutation,
  InsertZoomConversationStarterPromptMutationVariables
>;
type InsertZoomConversationStarterPromptObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    InsertZoomConversationStarterPromptMutation,
    InsertZoomConversationStarterPromptMutationVariables
  >
) => Promise<InsertZoomConversationStarterPromptObjectsMutationResultEx>;
export type InsertZoomConversationStarterPromptObjectsLazyMutationReturn = [
  InsertZoomConversationStarterPromptObjectsWrappedLazyMutationFn,
  InsertZoomConversationStarterPromptObjectsMutationResultEx
];

// Function
function useInsertZoomConversationStarterPromptObjects(
  options?: Omit<
    MutationHookOptions<
      InsertZoomConversationStarterPromptMutation,
      InsertZoomConversationStarterPromptMutationVariables
    >,
    'mutation'
  >
): InsertZoomConversationStarterPromptObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomConversationStarterPromptObjectsLazyMutationFn = useMutation<
    InsertZoomConversationStarterPromptMutation,
    InsertZoomConversationStarterPromptMutationVariables
  >(InsertZoomConversationStarterPromptDocument, options);
  const pickObjects: PickInsertZoomConversationStarterPromptObjectsFn = (
    mutation: InsertZoomConversationStarterPromptMutation | null | undefined
  ) => {
    return mutation?.insert_zoomConversationStarterPrompt?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomConversationStarterPromptObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomConversationStarterPromptObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomConversationStarterPromptByIdMutationResult = FetchResult<
  UpdateZoomConversationStarterPromptByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomConversationStarterPromptByIdMutationResultEx =
  UpdateZoomConversationStarterPromptByIdMutationResult & ZoomConversationStarterPromptByIdHookResultEx;

type PickUpdateZoomConversationStarterPromptByIdFn = (
  mutation: UpdateZoomConversationStarterPromptByIdMutation | null | undefined
) => ZoomConversationStarterPromptFragment | null | undefined;
type UpdateZoomConversationStarterPromptByIdLazyMutationFn = MutationTuple<
  UpdateZoomConversationStarterPromptByIdMutation,
  UpdateZoomConversationStarterPromptByIdMutationVariables
>;
type UpdateZoomConversationStarterPromptByIdWrappedLazyMutationFn = ({
  zoomConversationStarterPromptId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomConversationStarterPromptId: string;
  set: ZoomConversationStarterPrompt_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateZoomConversationStarterPromptByIdMutation,
      UpdateZoomConversationStarterPromptByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateZoomConversationStarterPromptByIdMutationResultEx>;
export type UpdateZoomConversationStarterPromptByIdLazyMutationReturn = [
  UpdateZoomConversationStarterPromptByIdWrappedLazyMutationFn,
  UpdateZoomConversationStarterPromptByIdMutationResultEx
];

function useUpdateZoomConversationStarterPromptById(
  options?: Omit<
    MutationHookOptions<
      UpdateZoomConversationStarterPromptByIdMutation,
      UpdateZoomConversationStarterPromptByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
): UpdateZoomConversationStarterPromptByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomConversationStarterPromptByIdLazyMutationFn = useMutation<
    UpdateZoomConversationStarterPromptByIdMutation,
    UpdateZoomConversationStarterPromptByIdMutationVariables
  >(UpdateZoomConversationStarterPromptByIdDocument, options);

  const pickZoomConversationStarterPrompt: PickUpdateZoomConversationStarterPromptByIdFn = (mutation) => {
    return (
      mutation?.update_zoomConversationStarterPrompt?.returning &&
      mutation.update_zoomConversationStarterPrompt!.returning[0]
    );
  };

  const wrappedLazyMutation: UpdateZoomConversationStarterPromptByIdWrappedLazyMutationFn = async ({
    zoomConversationStarterPromptId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomConversationStarterPromptByIdMutation,
      UpdateZoomConversationStarterPromptByIdMutationVariables
    > = { variables: { id: zoomConversationStarterPromptId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<UpdateZoomConversationStarterPromptByIdMutation>({
          operationType: 'update',
          entityName: 'zoomConversationStarterPrompt',
          objects: [{ id: zoomConversationStarterPromptId, ...set }],
        });
    }

    const fetchResult: UpdateZoomConversationStarterPromptByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomConversationStarterPromptId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomConversationStarterPrompt: pickZoomConversationStarterPrompt(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], zoomConversationStarterPrompt: pickZoomConversationStarterPrompt(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateZoomConversationStarterPromptObjectsMutationResult = FetchResult<
  UpdateZoomConversationStarterPromptMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomConversationStarterPromptObjectsMutationResultEx =
  UpdateZoomConversationStarterPromptObjectsMutationResult & ZoomConversationStarterPromptObjectsHookResultEx;

// Function
type PickUpdateZoomConversationStarterPromptObjectsFn = (
  mutation: UpdateZoomConversationStarterPromptMutation | null | undefined
) => ZoomConversationStarterPromptFragment[];
type UpdateZoomConversationStarterPromptObjectsLazyMutationFn = MutationTuple<
  UpdateZoomConversationStarterPromptMutation,
  UpdateZoomConversationStarterPromptMutationVariables
>;
type UpdateZoomConversationStarterPromptObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    UpdateZoomConversationStarterPromptMutation,
    UpdateZoomConversationStarterPromptMutationVariables
  >
) => Promise<UpdateZoomConversationStarterPromptObjectsMutationResultEx>;
export type UpdateZoomConversationStarterPromptObjectsLazyMutationReturn = [
  UpdateZoomConversationStarterPromptObjectsWrappedLazyMutationFn,
  UpdateZoomConversationStarterPromptObjectsMutationResultEx
];

function useUpdateZoomConversationStarterPromptObjects(
  options?: Omit<
    MutationHookOptions<
      UpdateZoomConversationStarterPromptMutation,
      UpdateZoomConversationStarterPromptMutationVariables
    >,
    'mutation'
  >
): UpdateZoomConversationStarterPromptObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomConversationStarterPromptObjectsLazyMutationFn = useMutation<
    UpdateZoomConversationStarterPromptMutation,
    UpdateZoomConversationStarterPromptMutationVariables
  >(UpdateZoomConversationStarterPromptDocument, options);

  const pickObjects: PickUpdateZoomConversationStarterPromptObjectsFn = (
    mutation: UpdateZoomConversationStarterPromptMutation | null | undefined
  ) => {
    return mutation?.update_zoomConversationStarterPrompt?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomConversationStarterPromptObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomConversationStarterPromptObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomConversationStarterPromptModelByIdFetchResult = FetchResult<
  RemoveZoomConversationStarterPromptModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomConversationStarterPromptModelByIdMutationResultEx =
  RemoveZoomConversationStarterPromptModelByIdFetchResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomConversationStarterPromptModelFn = (
  mutation: RemoveZoomConversationStarterPromptModelByIdMutation | null | undefined
) => number;
type RemoveZoomConversationStarterPromptModelLazyMutationFn = MutationTuple<
  RemoveZoomConversationStarterPromptModelByIdMutation,
  RemoveZoomConversationStarterPromptModelByIdMutationVariables
>;
type RemoveZoomConversationStarterPromptModelWrappedLazyMutationFn = ({
  zoomConversationStarterPromptId,
  autoOptimisticResponse,
  options,
}: {
  zoomConversationStarterPromptId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveZoomConversationStarterPromptModelByIdMutation,
      RemoveZoomConversationStarterPromptModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveZoomConversationStarterPromptModelByIdMutationResultEx>;
export type RemoveZoomConversationStarterPromptModelLazyMutationReturn = [
  RemoveZoomConversationStarterPromptModelWrappedLazyMutationFn,
  RemoveZoomConversationStarterPromptModelByIdMutationResultEx
];

function useRemoveZoomConversationStarterPromptModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomConversationStarterPromptModelByIdMutation,
      RemoveZoomConversationStarterPromptModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomConversationStarterPromptModelLazyMutationFn = useMutation<
    RemoveZoomConversationStarterPromptModelByIdMutation,
    RemoveZoomConversationStarterPromptModelByIdMutationVariables
  >(RemoveZoomConversationStarterPromptModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomConversationStarterPromptModelFn = (
    mutation: RemoveZoomConversationStarterPromptModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomConversationStarterPrompt?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomConversationStarterPromptModelWrappedLazyMutationFn = async ({
    zoomConversationStarterPromptId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomConversationStarterPromptModelMutation,
      RemoveZoomConversationStarterPromptModelByIdMutationVariables
    > = { variables: { id: zoomConversationStarterPromptId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<RemoveZoomConversationStarterPromptModelMutation>({
          operationType: 'delete',
          entityName: 'zoomConversationStarterPrompt',
          objects: [{ id: zoomConversationStarterPromptId }],
        });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomConversationStarterPromptModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomConversationStarterPrompt',
        entityId: zoomConversationStarterPromptId,
      });
    }

    const fetchResult: RemoveZoomConversationStarterPromptModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomConversationStarterPromptModelObjectsMutationResult = FetchResult<
  RemoveZoomConversationStarterPromptModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomConversationStarterPromptModelObjectsMutationResultEx =
  RemoveZoomConversationStarterPromptModelObjectsMutationResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomConversationStarterPromptModelObjectsFn = (
  mutation: RemoveZoomConversationStarterPromptModelMutation | null | undefined
) => number;
type RemoveZoomConversationStarterPromptModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomConversationStarterPromptModelMutation,
  RemoveZoomConversationStarterPromptModelMutationVariables
>;
type RemoveZoomConversationStarterPromptModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveZoomConversationStarterPromptModelMutation,
    RemoveZoomConversationStarterPromptModelMutationVariables
  >
) => Promise<RemoveZoomConversationStarterPromptModelObjectsMutationResultEx>;
export type RemoveZoomConversationStarterPromptModelObjectsLazyMutationReturn = [
  RemoveZoomConversationStarterPromptModelObjectsWrappedLazyMutationFn,
  RemoveZoomConversationStarterPromptModelObjectsMutationResultEx
];

function useRemoveZoomConversationStarterPromptModelObjects(
  options?: Omit<
    MutationHookOptions<
      RemoveZoomConversationStarterPromptModelMutation,
      RemoveZoomConversationStarterPromptModelMutationVariables
    >,
    'mutation'
  >
): RemoveZoomConversationStarterPromptModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveZoomConversationStarterPromptModelMutation,
    RemoveZoomConversationStarterPromptModelMutationVariables
  >(RemoveZoomConversationStarterPromptModelDocument, options);

  const pickAffectedRows: PickRemoveZoomConversationStarterPromptModelObjectsFn = (
    mutation: RemoveZoomConversationStarterPromptModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomConversationStarterPrompt?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomConversationStarterPromptModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomConversationStarterPromptModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomConversationStarterPrompt Fragment Helper Object
//------------------------------------------------

export const ZoomConversationStarterPromptFragmentGQLHooks = {
  useQueryById: useQueryZoomConversationStarterPromptById,
  useQueryByIdLazy: useQueryZoomConversationStarterPromptByIdLazy,
  useQueryObjects: useQueryZoomConversationStarterPromptObjects,
  useQueryObjectsLazy: useQueryZoomConversationStarterPromptObjectsLazy,
  useSubscribeToById: useSubscribeToZoomConversationStarterPromptById,
  useSubscribeToObjects: useSubscribeToZoomConversationStarterPromptObjects,
  useInsert: useInsertZoomConversationStarterPrompt,
  useInsertWithOnConflict: useInsertZoomConversationStarterPromptWithOnConflict,
  useInsertObjects: useInsertZoomConversationStarterPromptObjects,
  useUpdateById: useUpdateZoomConversationStarterPromptById,
  useUpdateObjects: useUpdateZoomConversationStarterPromptObjects,
};

// zoomConversationStarterPrompt MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomConversationStarterPromptModelGQLHooks = {
  useRemoveById: useRemoveZoomConversationStarterPromptModelById,
  useRemoveObjects: useRemoveZoomConversationStarterPromptModelObjects,
};

// content REACT
//------------------------------------------------

export type ContentByIdHookResultEx = { content: ContentFragment | null | undefined };
export type ContentObjectsHookResultEx = { objects: ContentFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryContentByIdResult = LazyQueryResult<QueryContentByIdQuery, QueryContentByIdQueryVariables>;
type QueryContentByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryContentByIdQuery, QueryContentByIdQueryVariables, QueryContentByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryContentByIdResultEx = Omit<QueryContentByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryContentByIdSubScribeToMore;
} & ContentByIdHookResultEx;

// Function
function useQueryContentById({
  contentId,
  options,
}: {
  contentId: string;
  options?: Omit<QueryHookOptions<QueryContentByIdQuery, QueryContentByIdQueryVariables>, 'query' | 'variables'>;
}): QueryContentByIdResultEx {
  const _query: QueryContentByIdResult = useQuery<QueryContentByIdQuery, QueryContentByIdQueryVariables>(
    QueryContentByIdDocument,
    { variables: { contentId }, ...options }
  );

  const typedSubscribeToMore: QueryContentByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryContentByIdDocument,
      variables: { contentId } as QueryContentByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, content: query?.data?.content_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryContentByIdFn = (query: QueryContentByIdQuery | null | undefined) => ContentFragment | null | undefined;
type QueryContentByIdLazyFn = [
  (options?: QueryLazyOptions<QueryContentByIdQueryVariables> | undefined) => void,
  QueryContentByIdResult
];
type QueryContentByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryContentByIdQueryVariables>, 'variables'>
) => void;
export type QueryContentByIdLazyReturn = [QueryContentByIdWrappedLazyFn, QueryContentByIdResultEx];

// Function
function useQueryContentByIdLazy({
  contentId,
  options,
}: {
  contentId: string;
  options?: Omit<QueryHookOptions<QueryContentByIdQuery, QueryContentByIdQueryVariables>, 'query' | 'variables'>;
}): QueryContentByIdLazyReturn {
  const lazyQuery: QueryContentByIdLazyFn = useLazyQuery<QueryContentByIdQuery, QueryContentByIdQueryVariables>(
    QueryContentByIdDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickQueryContentById: PickQueryContentByIdFn = (query) => {
    return query && query.content_by_pk;
  };
  const wrappedLazyQuery: QueryContentByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryContentByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryContentByIdDocument,
        variables: { contentId } as QueryContentByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, content: pickQueryContentById(lazyQuery[1].data) },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryContentObjectsResult = LazyQueryResult<QueryContentObjectsQuery, QueryContentObjectsQueryVariables>;
type QueryContentObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryContentObjectsQuery, QueryContentObjectsQueryVariables, QueryContentObjectsQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryContentObjectsResultEx = Omit<QueryContentObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryContentObjectsSubScribeToMore;
} & ContentObjectsHookResultEx;

// Function
function useQueryContentObjects(
  options: Omit<QueryHookOptions<QueryContentObjectsQuery, QueryContentObjectsQueryVariables>, 'query'>
): QueryContentObjectsResultEx {
  const _query: QueryContentObjectsResult = useQuery<QueryContentObjectsQuery, QueryContentObjectsQueryVariables>(
    QueryContentObjectsDocument,
    options
  );

  const typedSubscribeToMore: QueryContentObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryContentObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.content || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryContentObjectsFn = (query: QueryContentObjectsQuery | null | undefined) => ContentFragment[];
type QueryContentObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryContentObjectsQueryVariables> | undefined) => void,
  QueryContentObjectsResult
];
type QueryContentObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryContentObjectsQueryVariables>) => void;
export type QueryContentObjectsLazyReturn = [QueryContentObjectsWrappedLazyFn, QueryContentObjectsResultEx];

// Function
function useQueryContentObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryContentObjectsQuery, QueryContentObjectsQueryVariables>, 'query'>
): QueryContentObjectsLazyReturn {
  const lazyQuery: QueryContentObjectsLazyFn = useLazyQuery<
    QueryContentObjectsQuery,
    QueryContentObjectsQueryVariables
  >(QueryContentObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryContentObjectsFn = (query: QueryContentObjectsQuery | null | undefined) => {
    return query?.content || [];
  };
  const wrappedLazyQuery: QueryContentObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryContentObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore && lazyQuery[1].subscribeToMore({ document: QueryContentObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToContentByIdResult = {
  variables?: SubscribeToContentByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToContentByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToContentByIdResultEx = SubscribeToContentByIdResult & ContentByIdHookResultEx;

// Function
function useSubscribeToContentById({
  contentId,
  options,
}: {
  contentId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToContentByIdSubscription, SubscribeToContentByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToContentByIdResultEx {
  const subscription: SubscribeToContentByIdResult = useSubscription<
    SubscribeToContentByIdSubscription,
    SubscribeToContentByIdSubscriptionVariables
  >(SubscribeToContentByIdDocument, { variables: { contentId }, ...options });
  return { ...subscription, content: subscription?.data?.content_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToContentObjectsResult = {
  variables?: SubscribeToContentObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToContentObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToContentObjectsResultEx = SubscribeToContentObjectsResult & ContentObjectsHookResultEx;

// Function
function useSubscribeToContentObjects(
  options: Omit<
    SubscriptionHookOptions<SubscribeToContentObjectsSubscription, SubscribeToContentObjectsSubscriptionVariables>,
    'query'
  >
): SubscribeToContentObjectsResultEx {
  const subscription: SubscribeToContentObjectsResult = useSubscription<
    SubscribeToContentObjectsSubscription,
    SubscribeToContentObjectsSubscriptionVariables
  >(SubscribeToContentObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.content || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertContentMutationResult = FetchResult<InsertContentMutation, Record<string, any>, Record<string, any>>;
export type InsertContentMutationResultEx = InsertContentMutationResult & ContentByIdHookResultEx;

type PickInsertContentFn = (mutation: InsertContentMutation | null | undefined) => ContentFragment | null | undefined;
type InsertContentLazyMutationFn = MutationTuple<InsertContentMutation, InsertContentMutationVariables>;
type InsertContentWrappedLazyMutationFn = ({
  content,
  autoOptimisticResponse,
  options,
}: {
  content: Content_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertContentMutation, InsertContentMutationVariables>, 'variables'>;
}) => Promise<InsertContentMutationResultEx>;
export type InsertContentLazyMutationReturn = [InsertContentWrappedLazyMutationFn, InsertContentMutationResultEx];

// Function
function useInsertContent(
  options?: Omit<MutationHookOptions<InsertContentMutation, InsertContentMutationVariables>, 'mutation' | 'variables'>
): InsertContentLazyMutationReturn {
  const lazyMutation: InsertContentLazyMutationFn = useMutation<InsertContentMutation, InsertContentMutationVariables>(
    InsertContentDocument,
    options
  );
  const pickContent: PickInsertContentFn = (mutation) => {
    return mutation?.insert_content?.returning && mutation?.insert_content?.returning[0];
  };

  const wrappedLazyMutation: InsertContentWrappedLazyMutationFn = async ({
    content,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: content });
    const mutationOptions: MutationFunctionOptions<InsertContentMutation, InsertContentMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'content'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertContentMutation>({
        operationType: 'insert',
        entityName: 'content',
        objects: [objectForInsert as Content_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, content: pickContent(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], content: pickContent(lazyMutation[1].data) }];
}

//
//

// Types
type InsertContentWithOnConflictMutationResult = FetchResult<
  InsertContentWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertContentWithOnConflictMutationResultEx = InsertContentWithOnConflictMutationResult &
  ContentByIdHookResultEx;

type InsertContentWithOnConflictLazyMutationFn = MutationTuple<
  InsertContentWithOnConflictMutation,
  InsertContentWithOnConflictMutationVariables
>;
type InsertContentWithOnConflictWrappedLazyMutationFn = ({
  content,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  content: Content_Insert_Input;
  onConflict: Content_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertContentWithOnConflictMutation, InsertContentWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertContentWithOnConflictMutationResultEx>;
export type InsertContentWithOnConflictLazyMutationReturn = [
  InsertContentWithOnConflictWrappedLazyMutationFn,
  InsertContentWithOnConflictMutationResultEx
];

// Function
function useInsertContentWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertContentWithOnConflictMutation, InsertContentWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertContentWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertContentWithOnConflictLazyMutationFn = useMutation<
    InsertContentWithOnConflictMutation,
    InsertContentWithOnConflictMutationVariables
  >(InsertContentWithOnConflictDocument, options);
  const pickContent: PickInsertContentFn = (mutation: InsertContentWithOnConflictMutation | null | undefined) => {
    return mutation?.insert_content?.returning && mutation.insert_content.returning[0];
  };

  const wrappedLazyMutation: InsertContentWithOnConflictWrappedLazyMutationFn = async ({
    content,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: content });
    const mutationOptions: MutationFunctionOptions<
      InsertContentWithOnConflictMutation,
      InsertContentWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'content'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertContentWithOnConflictMutation>({
        operationType: 'insert',
        entityName: 'content',
        objects: [objectForInsert as Content_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, content: pickContent(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], content: pickContent(lazyMutation[1].data) }];
}

// Types
type InsertContentObjectsMutationResult = FetchResult<InsertContentMutation, Record<string, any>, Record<string, any>>;
export type InsertContentObjectsMutationResultEx = InsertContentMutationResult & ContentObjectsHookResultEx;

type PickInsertContentObjectsFn = (mutation: InsertContentMutation | null | undefined) => ContentFragment[];
type InsertContentObjectsLazyMutationFn = MutationTuple<InsertContentMutation, InsertContentMutationVariables>;
type InsertContentObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertContentMutation, InsertContentMutationVariables>
) => Promise<InsertContentObjectsMutationResultEx>;
export type InsertContentObjectsLazyMutationReturn = [
  InsertContentObjectsWrappedLazyMutationFn,
  InsertContentObjectsMutationResultEx
];

// Function
function useInsertContentObjects(
  options?: Omit<MutationHookOptions<InsertContentMutation, InsertContentMutationVariables>, 'mutation'>
): InsertContentObjectsLazyMutationReturn {
  const lazyMutation: InsertContentObjectsLazyMutationFn = useMutation<
    InsertContentMutation,
    InsertContentMutationVariables
  >(InsertContentDocument, options);
  const pickObjects: PickInsertContentObjectsFn = (mutation: InsertContentMutation | null | undefined) => {
    return mutation?.insert_content?.returning || [];
  };

  const wrappedLazyMutation: InsertContentObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertContentObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateContentByIdMutationResult = FetchResult<UpdateContentByIdMutation, Record<string, any>, Record<string, any>>;
export type UpdateContentByIdMutationResultEx = UpdateContentByIdMutationResult & ContentByIdHookResultEx;

type PickUpdateContentByIdFn = (
  mutation: UpdateContentByIdMutation | null | undefined
) => ContentFragment | null | undefined;
type UpdateContentByIdLazyMutationFn = MutationTuple<UpdateContentByIdMutation, UpdateContentByIdMutationVariables>;
type UpdateContentByIdWrappedLazyMutationFn = ({
  contentId,
  set,
  autoOptimisticResponse,
  options,
}: {
  contentId: string;
  set: Content_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<UpdateContentByIdMutation, UpdateContentByIdMutationVariables>, 'variables'>;
}) => Promise<UpdateContentByIdMutationResultEx>;
export type UpdateContentByIdLazyMutationReturn = [
  UpdateContentByIdWrappedLazyMutationFn,
  UpdateContentByIdMutationResultEx
];

function useUpdateContentById(
  options?: Omit<
    MutationHookOptions<UpdateContentByIdMutation, UpdateContentByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateContentByIdLazyMutationReturn {
  const lazyMutation: UpdateContentByIdLazyMutationFn = useMutation<
    UpdateContentByIdMutation,
    UpdateContentByIdMutationVariables
  >(UpdateContentByIdDocument, options);

  const pickContent: PickUpdateContentByIdFn = (mutation) => {
    return mutation?.update_content?.returning && mutation.update_content!.returning[0];
  };

  const wrappedLazyMutation: UpdateContentByIdWrappedLazyMutationFn = async ({
    contentId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<UpdateContentByIdMutation, UpdateContentByIdMutationVariables> = {
      variables: { id: contentId, set },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateContentByIdMutation>({
        operationType: 'update',
        entityName: 'content',
        objects: [{ id: contentId, ...set }],
      });
    }

    const fetchResult: UpdateContentByIdMutationResult = await lazyMutation[0]({
      variables: { id: contentId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, content: pickContent(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], content: pickContent(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateContentObjectsMutationResult = FetchResult<UpdateContentMutation, Record<string, any>, Record<string, any>>;
export type UpdateContentObjectsMutationResultEx = UpdateContentObjectsMutationResult & ContentObjectsHookResultEx;

// Function
type PickUpdateContentObjectsFn = (mutation: UpdateContentMutation | null | undefined) => ContentFragment[];
type UpdateContentObjectsLazyMutationFn = MutationTuple<UpdateContentMutation, UpdateContentMutationVariables>;
type UpdateContentObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateContentMutation, UpdateContentMutationVariables>
) => Promise<UpdateContentObjectsMutationResultEx>;
export type UpdateContentObjectsLazyMutationReturn = [
  UpdateContentObjectsWrappedLazyMutationFn,
  UpdateContentObjectsMutationResultEx
];

function useUpdateContentObjects(
  options?: Omit<MutationHookOptions<UpdateContentMutation, UpdateContentMutationVariables>, 'mutation'>
): UpdateContentObjectsLazyMutationReturn {
  const lazyMutation: UpdateContentObjectsLazyMutationFn = useMutation<
    UpdateContentMutation,
    UpdateContentMutationVariables
  >(UpdateContentDocument, options);

  const pickObjects: PickUpdateContentObjectsFn = (mutation: UpdateContentMutation | null | undefined) => {
    return mutation?.update_content?.returning || [];
  };

  const wrappedLazyMutation: UpdateContentObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateContentObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveContentModelByIdFetchResult = FetchResult<
  RemoveContentModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveContentModelByIdMutationResultEx = RemoveContentModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveContentModelFn = (mutation: RemoveContentModelByIdMutation | null | undefined) => number;
type RemoveContentModelLazyMutationFn = MutationTuple<
  RemoveContentModelByIdMutation,
  RemoveContentModelByIdMutationVariables
>;
type RemoveContentModelWrappedLazyMutationFn = ({
  contentId,
  autoOptimisticResponse,
  options,
}: {
  contentId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveContentModelByIdMutation, RemoveContentModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveContentModelByIdMutationResultEx>;
export type RemoveContentModelLazyMutationReturn = [
  RemoveContentModelWrappedLazyMutationFn,
  RemoveContentModelByIdMutationResultEx
];

function useRemoveContentModelById(
  options?: Omit<
    MutationHookOptions<RemoveContentModelByIdMutation, RemoveContentModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveContentModelLazyMutationFn = useMutation<
    RemoveContentModelByIdMutation,
    RemoveContentModelByIdMutationVariables
  >(RemoveContentModelByIdDocument, options);

  const pickAffectedRows: PickRemoveContentModelFn = (mutation: RemoveContentModelByIdMutation | null | undefined) => {
    return mutation?.delete_content?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveContentModelWrappedLazyMutationFn = async ({
    contentId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveContentModelMutation,
      RemoveContentModelByIdMutationVariables
    > = { variables: { id: contentId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveContentModelMutation>({
        operationType: 'delete',
        entityName: 'content',
        objects: [{ id: contentId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveContentModelByIdMutation>({
        operationType: 'delete',
        entityName: 'content',
        entityId: contentId,
      });
    }

    const fetchResult: RemoveContentModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveContentModelObjectsMutationResult = FetchResult<
  RemoveContentModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveContentModelObjectsMutationResultEx = RemoveContentModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveContentModelObjectsFn = (mutation: RemoveContentModelMutation | null | undefined) => number;
type RemoveContentModelObjectsLazyMutationFn = MutationTuple<
  RemoveContentModelMutation,
  RemoveContentModelMutationVariables
>;
type RemoveContentModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveContentModelMutation, RemoveContentModelMutationVariables>
) => Promise<RemoveContentModelObjectsMutationResultEx>;
export type RemoveContentModelObjectsLazyMutationReturn = [
  RemoveContentModelObjectsWrappedLazyMutationFn,
  RemoveContentModelObjectsMutationResultEx
];

function useRemoveContentModelObjects(
  options?: Omit<MutationHookOptions<RemoveContentModelMutation, RemoveContentModelMutationVariables>, 'mutation'>
): RemoveContentModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveContentModelMutation, RemoveContentModelMutationVariables>(
    RemoveContentModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveContentModelObjectsFn = (
    mutation: RemoveContentModelMutation | null | undefined
  ) => {
    return mutation?.delete_content?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveContentModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveContentModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// Content Fragment Helper Object
//------------------------------------------------

export const ContentFragmentGQLHooks = {
  useQueryById: useQueryContentById,
  useQueryByIdLazy: useQueryContentByIdLazy,
  useQueryObjects: useQueryContentObjects,
  useQueryObjectsLazy: useQueryContentObjectsLazy,
  useSubscribeToById: useSubscribeToContentById,
  useSubscribeToObjects: useSubscribeToContentObjects,
  useInsert: useInsertContent,
  useInsertWithOnConflict: useInsertContentWithOnConflict,
  useInsertObjects: useInsertContentObjects,
  useUpdateById: useUpdateContentById,
  useUpdateObjects: useUpdateContentObjects,
};

// content MODEL HOOKS OBJECT
//------------------------------------------------

export const ContentModelGQLHooks = {
  useRemoveById: useRemoveContentModelById,
  useRemoveObjects: useRemoveContentModelObjects,
};

// zoomSettings REACT
//------------------------------------------------

export type ZoomSettingsByIdHookResultEx = { zoomSettings: ZoomSettingsFragment | null | undefined };
export type ZoomSettingsObjectsHookResultEx = { objects: ZoomSettingsFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomSettingsByIdResult = LazyQueryResult<QueryZoomSettingsByIdQuery, QueryZoomSettingsByIdQueryVariables>;
type QueryZoomSettingsByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomSettingsByIdQuery,
          QueryZoomSettingsByIdQueryVariables,
          QueryZoomSettingsByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomSettingsByIdResultEx = Omit<QueryZoomSettingsByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomSettingsByIdSubScribeToMore;
} & ZoomSettingsByIdHookResultEx;

// Function
function useQueryZoomSettingsById({
  zoomSettingsId,
  options,
}: {
  zoomSettingsId: number;
  options?: Omit<
    QueryHookOptions<QueryZoomSettingsByIdQuery, QueryZoomSettingsByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomSettingsByIdResultEx {
  const _query: QueryZoomSettingsByIdResult = useQuery<QueryZoomSettingsByIdQuery, QueryZoomSettingsByIdQueryVariables>(
    QueryZoomSettingsByIdDocument,
    { variables: { zoomSettingsId }, ...options }
  );

  const typedSubscribeToMore: QueryZoomSettingsByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomSettingsByIdDocument,
      variables: { zoomSettingsId } as QueryZoomSettingsByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomSettings: query?.data?.zoomSettings_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomSettingsByIdFn = (
  query: QueryZoomSettingsByIdQuery | null | undefined
) => ZoomSettingsFragment | null | undefined;
type QueryZoomSettingsByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomSettingsByIdQueryVariables> | undefined) => void,
  QueryZoomSettingsByIdResult
];
type QueryZoomSettingsByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomSettingsByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomSettingsByIdLazyReturn = [QueryZoomSettingsByIdWrappedLazyFn, QueryZoomSettingsByIdResultEx];

// Function
function useQueryZoomSettingsByIdLazy({
  zoomSettingsId,
  options,
}: {
  zoomSettingsId: number;
  options?: Omit<
    QueryHookOptions<QueryZoomSettingsByIdQuery, QueryZoomSettingsByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomSettingsByIdLazyReturn {
  const lazyQuery: QueryZoomSettingsByIdLazyFn = useLazyQuery<
    QueryZoomSettingsByIdQuery,
    QueryZoomSettingsByIdQueryVariables
  >(QueryZoomSettingsByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomSettingsById: PickQueryZoomSettingsByIdFn = (query) => {
    return query && query.zoomSettings_by_pk;
  };
  const wrappedLazyQuery: QueryZoomSettingsByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomSettingsByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomSettingsByIdDocument,
        variables: { zoomSettingsId } as QueryZoomSettingsByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomSettings: pickQueryZoomSettingsById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomSettingsObjectsResult = LazyQueryResult<
  QueryZoomSettingsObjectsQuery,
  QueryZoomSettingsObjectsQueryVariables
>;
type QueryZoomSettingsObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomSettingsObjectsQuery,
          QueryZoomSettingsObjectsQueryVariables,
          QueryZoomSettingsObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomSettingsObjectsResultEx = Omit<QueryZoomSettingsObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomSettingsObjectsSubScribeToMore;
} & ZoomSettingsObjectsHookResultEx;

// Function
function useQueryZoomSettingsObjects(
  options: Omit<QueryHookOptions<QueryZoomSettingsObjectsQuery, QueryZoomSettingsObjectsQueryVariables>, 'query'>
): QueryZoomSettingsObjectsResultEx {
  const _query: QueryZoomSettingsObjectsResult = useQuery<
    QueryZoomSettingsObjectsQuery,
    QueryZoomSettingsObjectsQueryVariables
  >(QueryZoomSettingsObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomSettingsObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomSettingsObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomSettings || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomSettingsObjectsFn = (
  query: QueryZoomSettingsObjectsQuery | null | undefined
) => ZoomSettingsFragment[];
type QueryZoomSettingsObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomSettingsObjectsQueryVariables> | undefined) => void,
  QueryZoomSettingsObjectsResult
];
type QueryZoomSettingsObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomSettingsObjectsQueryVariables>
) => void;
export type QueryZoomSettingsObjectsLazyReturn = [
  QueryZoomSettingsObjectsWrappedLazyFn,
  QueryZoomSettingsObjectsResultEx
];

// Function
function useQueryZoomSettingsObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryZoomSettingsObjectsQuery, QueryZoomSettingsObjectsQueryVariables>, 'query'>
): QueryZoomSettingsObjectsLazyReturn {
  const lazyQuery: QueryZoomSettingsObjectsLazyFn = useLazyQuery<
    QueryZoomSettingsObjectsQuery,
    QueryZoomSettingsObjectsQueryVariables
  >(QueryZoomSettingsObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomSettingsObjectsFn = (query: QueryZoomSettingsObjectsQuery | null | undefined) => {
    return query?.zoomSettings || [];
  };
  const wrappedLazyQuery: QueryZoomSettingsObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomSettingsObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomSettingsObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomSettingsByIdResult = {
  variables?: SubscribeToZoomSettingsByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomSettingsByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomSettingsByIdResultEx = SubscribeToZoomSettingsByIdResult & ZoomSettingsByIdHookResultEx;

// Function
function useSubscribeToZoomSettingsById({
  zoomSettingsId,
  options,
}: {
  zoomSettingsId: number;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToZoomSettingsByIdSubscription, SubscribeToZoomSettingsByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToZoomSettingsByIdResultEx {
  const subscription: SubscribeToZoomSettingsByIdResult = useSubscription<
    SubscribeToZoomSettingsByIdSubscription,
    SubscribeToZoomSettingsByIdSubscriptionVariables
  >(SubscribeToZoomSettingsByIdDocument, { variables: { zoomSettingsId }, ...options });
  return { ...subscription, zoomSettings: subscription?.data?.zoomSettings_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomSettingsObjectsResult = {
  variables?: SubscribeToZoomSettingsObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomSettingsObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomSettingsObjectsResultEx = SubscribeToZoomSettingsObjectsResult &
  ZoomSettingsObjectsHookResultEx;

// Function
function useSubscribeToZoomSettingsObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomSettingsObjectsSubscription,
      SubscribeToZoomSettingsObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomSettingsObjectsResultEx {
  const subscription: SubscribeToZoomSettingsObjectsResult = useSubscription<
    SubscribeToZoomSettingsObjectsSubscription,
    SubscribeToZoomSettingsObjectsSubscriptionVariables
  >(SubscribeToZoomSettingsObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomSettings || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomSettingsMutationResult = FetchResult<
  InsertZoomSettingsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomSettingsMutationResultEx = InsertZoomSettingsMutationResult & ZoomSettingsByIdHookResultEx;

type PickInsertZoomSettingsFn = (
  mutation: InsertZoomSettingsMutation | null | undefined
) => ZoomSettingsFragment | null | undefined;
type InsertZoomSettingsLazyMutationFn = MutationTuple<InsertZoomSettingsMutation, InsertZoomSettingsMutationVariables>;
type InsertZoomSettingsWrappedLazyMutationFn = ({
  zoomSettings,
  autoOptimisticResponse,
  options,
}: {
  zoomSettings: ZoomSettings_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertZoomSettingsMutation, InsertZoomSettingsMutationVariables>, 'variables'>;
}) => Promise<InsertZoomSettingsMutationResultEx>;
export type InsertZoomSettingsLazyMutationReturn = [
  InsertZoomSettingsWrappedLazyMutationFn,
  InsertZoomSettingsMutationResultEx
];

// Function
function useInsertZoomSettings(
  options?: Omit<
    MutationHookOptions<InsertZoomSettingsMutation, InsertZoomSettingsMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomSettingsLazyMutationReturn {
  const lazyMutation: InsertZoomSettingsLazyMutationFn = useMutation<
    InsertZoomSettingsMutation,
    InsertZoomSettingsMutationVariables
  >(InsertZoomSettingsDocument, options);
  const pickZoomSettings: PickInsertZoomSettingsFn = (mutation) => {
    return mutation?.insert_zoomSettings?.returning && mutation?.insert_zoomSettings?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomSettingsWrappedLazyMutationFn = async ({
    zoomSettings,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomSettings });
    const mutationOptions: MutationFunctionOptions<InsertZoomSettingsMutation, InsertZoomSettingsMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomSettings'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomSettingsMutation>({
        operationType: 'insert',
        entityName: 'zoomSettings',
        objects: [objectForInsert as ZoomSettings_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomSettings: pickZoomSettings(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomSettings: pickZoomSettings(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomSettingsWithOnConflictMutationResult = FetchResult<
  InsertZoomSettingsWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomSettingsWithOnConflictMutationResultEx = InsertZoomSettingsWithOnConflictMutationResult &
  ZoomSettingsByIdHookResultEx;

type InsertZoomSettingsWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomSettingsWithOnConflictMutation,
  InsertZoomSettingsWithOnConflictMutationVariables
>;
type InsertZoomSettingsWithOnConflictWrappedLazyMutationFn = ({
  zoomSettings,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomSettings: ZoomSettings_Insert_Input;
  onConflict: ZoomSettings_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomSettingsWithOnConflictMutation,
      InsertZoomSettingsWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomSettingsWithOnConflictMutationResultEx>;
export type InsertZoomSettingsWithOnConflictLazyMutationReturn = [
  InsertZoomSettingsWithOnConflictWrappedLazyMutationFn,
  InsertZoomSettingsWithOnConflictMutationResultEx
];

// Function
function useInsertZoomSettingsWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertZoomSettingsWithOnConflictMutation, InsertZoomSettingsWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomSettingsWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomSettingsWithOnConflictLazyMutationFn = useMutation<
    InsertZoomSettingsWithOnConflictMutation,
    InsertZoomSettingsWithOnConflictMutationVariables
  >(InsertZoomSettingsWithOnConflictDocument, options);
  const pickZoomSettings: PickInsertZoomSettingsFn = (
    mutation: InsertZoomSettingsWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomSettings?.returning && mutation.insert_zoomSettings.returning[0];
  };

  const wrappedLazyMutation: InsertZoomSettingsWithOnConflictWrappedLazyMutationFn = async ({
    zoomSettings,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomSettings });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomSettingsWithOnConflictMutation,
      InsertZoomSettingsWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomSettings'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomSettingsWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomSettings',
          objects: [objectForInsert as ZoomSettings_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomSettings: pickZoomSettings(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomSettings: pickZoomSettings(lazyMutation[1].data) }];
}

// Types
type InsertZoomSettingsObjectsMutationResult = FetchResult<
  InsertZoomSettingsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomSettingsObjectsMutationResultEx = InsertZoomSettingsMutationResult &
  ZoomSettingsObjectsHookResultEx;

type PickInsertZoomSettingsObjectsFn = (
  mutation: InsertZoomSettingsMutation | null | undefined
) => ZoomSettingsFragment[];
type InsertZoomSettingsObjectsLazyMutationFn = MutationTuple<
  InsertZoomSettingsMutation,
  InsertZoomSettingsMutationVariables
>;
type InsertZoomSettingsObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomSettingsMutation, InsertZoomSettingsMutationVariables>
) => Promise<InsertZoomSettingsObjectsMutationResultEx>;
export type InsertZoomSettingsObjectsLazyMutationReturn = [
  InsertZoomSettingsObjectsWrappedLazyMutationFn,
  InsertZoomSettingsObjectsMutationResultEx
];

// Function
function useInsertZoomSettingsObjects(
  options?: Omit<MutationHookOptions<InsertZoomSettingsMutation, InsertZoomSettingsMutationVariables>, 'mutation'>
): InsertZoomSettingsObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomSettingsObjectsLazyMutationFn = useMutation<
    InsertZoomSettingsMutation,
    InsertZoomSettingsMutationVariables
  >(InsertZoomSettingsDocument, options);
  const pickObjects: PickInsertZoomSettingsObjectsFn = (mutation: InsertZoomSettingsMutation | null | undefined) => {
    return mutation?.insert_zoomSettings?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomSettingsObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomSettingsObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomSettingsByIdMutationResult = FetchResult<
  UpdateZoomSettingsByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomSettingsByIdMutationResultEx = UpdateZoomSettingsByIdMutationResult &
  ZoomSettingsByIdHookResultEx;

type PickUpdateZoomSettingsByIdFn = (
  mutation: UpdateZoomSettingsByIdMutation | null | undefined
) => ZoomSettingsFragment | null | undefined;
type UpdateZoomSettingsByIdLazyMutationFn = MutationTuple<
  UpdateZoomSettingsByIdMutation,
  UpdateZoomSettingsByIdMutationVariables
>;
type UpdateZoomSettingsByIdWrappedLazyMutationFn = ({
  zoomSettingsId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomSettingsId: number;
  set: ZoomSettings_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomSettingsByIdMutation, UpdateZoomSettingsByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomSettingsByIdMutationResultEx>;
export type UpdateZoomSettingsByIdLazyMutationReturn = [
  UpdateZoomSettingsByIdWrappedLazyMutationFn,
  UpdateZoomSettingsByIdMutationResultEx
];

function useUpdateZoomSettingsById(
  options?: Omit<
    MutationHookOptions<UpdateZoomSettingsByIdMutation, UpdateZoomSettingsByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomSettingsByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomSettingsByIdLazyMutationFn = useMutation<
    UpdateZoomSettingsByIdMutation,
    UpdateZoomSettingsByIdMutationVariables
  >(UpdateZoomSettingsByIdDocument, options);

  const pickZoomSettings: PickUpdateZoomSettingsByIdFn = (mutation) => {
    return mutation?.update_zoomSettings?.returning && mutation.update_zoomSettings!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomSettingsByIdWrappedLazyMutationFn = async ({
    zoomSettingsId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomSettingsByIdMutation,
      UpdateZoomSettingsByIdMutationVariables
    > = { variables: { id: zoomSettingsId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomSettingsByIdMutation>({
        operationType: 'update',
        entityName: 'zoomSettings',
        objects: [{ id: zoomSettingsId, ...set }],
      });
    }

    const fetchResult: UpdateZoomSettingsByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomSettingsId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomSettings: pickZoomSettings(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomSettings: pickZoomSettings(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomSettingsObjectsMutationResult = FetchResult<
  UpdateZoomSettingsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomSettingsObjectsMutationResultEx = UpdateZoomSettingsObjectsMutationResult &
  ZoomSettingsObjectsHookResultEx;

// Function
type PickUpdateZoomSettingsObjectsFn = (
  mutation: UpdateZoomSettingsMutation | null | undefined
) => ZoomSettingsFragment[];
type UpdateZoomSettingsObjectsLazyMutationFn = MutationTuple<
  UpdateZoomSettingsMutation,
  UpdateZoomSettingsMutationVariables
>;
type UpdateZoomSettingsObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomSettingsMutation, UpdateZoomSettingsMutationVariables>
) => Promise<UpdateZoomSettingsObjectsMutationResultEx>;
export type UpdateZoomSettingsObjectsLazyMutationReturn = [
  UpdateZoomSettingsObjectsWrappedLazyMutationFn,
  UpdateZoomSettingsObjectsMutationResultEx
];

function useUpdateZoomSettingsObjects(
  options?: Omit<MutationHookOptions<UpdateZoomSettingsMutation, UpdateZoomSettingsMutationVariables>, 'mutation'>
): UpdateZoomSettingsObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomSettingsObjectsLazyMutationFn = useMutation<
    UpdateZoomSettingsMutation,
    UpdateZoomSettingsMutationVariables
  >(UpdateZoomSettingsDocument, options);

  const pickObjects: PickUpdateZoomSettingsObjectsFn = (mutation: UpdateZoomSettingsMutation | null | undefined) => {
    return mutation?.update_zoomSettings?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomSettingsObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomSettingsObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomSettingsModelByIdFetchResult = FetchResult<
  RemoveZoomSettingsModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomSettingsModelByIdMutationResultEx = RemoveZoomSettingsModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomSettingsModelFn = (mutation: RemoveZoomSettingsModelByIdMutation | null | undefined) => number;
type RemoveZoomSettingsModelLazyMutationFn = MutationTuple<
  RemoveZoomSettingsModelByIdMutation,
  RemoveZoomSettingsModelByIdMutationVariables
>;
type RemoveZoomSettingsModelWrappedLazyMutationFn = ({
  zoomSettingsId,
  autoOptimisticResponse,
  options,
}: {
  zoomSettingsId: number;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomSettingsModelByIdMutation, RemoveZoomSettingsModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomSettingsModelByIdMutationResultEx>;
export type RemoveZoomSettingsModelLazyMutationReturn = [
  RemoveZoomSettingsModelWrappedLazyMutationFn,
  RemoveZoomSettingsModelByIdMutationResultEx
];

function useRemoveZoomSettingsModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomSettingsModelByIdMutation, RemoveZoomSettingsModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomSettingsModelLazyMutationFn = useMutation<
    RemoveZoomSettingsModelByIdMutation,
    RemoveZoomSettingsModelByIdMutationVariables
  >(RemoveZoomSettingsModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomSettingsModelFn = (
    mutation: RemoveZoomSettingsModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomSettings?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomSettingsModelWrappedLazyMutationFn = async ({
    zoomSettingsId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomSettingsModelMutation,
      RemoveZoomSettingsModelByIdMutationVariables
    > = { variables: { id: zoomSettingsId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomSettingsModelMutation>({
        operationType: 'delete',
        entityName: 'zoomSettings',
        objects: [{ id: zoomSettingsId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomSettingsModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomSettings',
        entityId: zoomSettingsId,
      });
    }

    const fetchResult: RemoveZoomSettingsModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomSettingsModelObjectsMutationResult = FetchResult<
  RemoveZoomSettingsModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomSettingsModelObjectsMutationResultEx = RemoveZoomSettingsModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomSettingsModelObjectsFn = (mutation: RemoveZoomSettingsModelMutation | null | undefined) => number;
type RemoveZoomSettingsModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomSettingsModelMutation,
  RemoveZoomSettingsModelMutationVariables
>;
type RemoveZoomSettingsModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomSettingsModelMutation, RemoveZoomSettingsModelMutationVariables>
) => Promise<RemoveZoomSettingsModelObjectsMutationResultEx>;
export type RemoveZoomSettingsModelObjectsLazyMutationReturn = [
  RemoveZoomSettingsModelObjectsWrappedLazyMutationFn,
  RemoveZoomSettingsModelObjectsMutationResultEx
];

function useRemoveZoomSettingsModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomSettingsModelMutation, RemoveZoomSettingsModelMutationVariables>,
    'mutation'
  >
): RemoveZoomSettingsModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomSettingsModelMutation, RemoveZoomSettingsModelMutationVariables>(
    RemoveZoomSettingsModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomSettingsModelObjectsFn = (
    mutation: RemoveZoomSettingsModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomSettings?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomSettingsModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomSettingsModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomSettings Fragment Helper Object
//------------------------------------------------

export const ZoomSettingsFragmentGQLHooks = {
  useQueryById: useQueryZoomSettingsById,
  useQueryByIdLazy: useQueryZoomSettingsByIdLazy,
  useQueryObjects: useQueryZoomSettingsObjects,
  useQueryObjectsLazy: useQueryZoomSettingsObjectsLazy,
  useSubscribeToById: useSubscribeToZoomSettingsById,
  useSubscribeToObjects: useSubscribeToZoomSettingsObjects,
  useInsert: useInsertZoomSettings,
  useInsertWithOnConflict: useInsertZoomSettingsWithOnConflict,
  useInsertObjects: useInsertZoomSettingsObjects,
  useUpdateById: useUpdateZoomSettingsById,
  useUpdateObjects: useUpdateZoomSettingsObjects,
};

// zoomSettings MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomSettingsModelGQLHooks = {
  useRemoveById: useRemoveZoomSettingsModelById,
  useRemoveObjects: useRemoveZoomSettingsModelObjects,
};

// zoomPresentation REACT
//------------------------------------------------

export type ZoomPresentationByIdHookResultEx = { zoomPresentation: ZoomPresentationFragment | null | undefined };
export type ZoomPresentationObjectsHookResultEx = { objects: ZoomPresentationFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryZoomPresentationByIdResult = LazyQueryResult<
  QueryZoomPresentationByIdQuery,
  QueryZoomPresentationByIdQueryVariables
>;
type QueryZoomPresentationByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomPresentationByIdQuery,
          QueryZoomPresentationByIdQueryVariables,
          QueryZoomPresentationByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomPresentationByIdResultEx = Omit<QueryZoomPresentationByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomPresentationByIdSubScribeToMore;
} & ZoomPresentationByIdHookResultEx;

// Function
function useQueryZoomPresentationById({
  zoomPresentationId,
  options,
}: {
  zoomPresentationId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomPresentationByIdQuery, QueryZoomPresentationByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomPresentationByIdResultEx {
  const _query: QueryZoomPresentationByIdResult = useQuery<
    QueryZoomPresentationByIdQuery,
    QueryZoomPresentationByIdQueryVariables
  >(QueryZoomPresentationByIdDocument, { variables: { zoomPresentationId }, ...options });

  const typedSubscribeToMore: QueryZoomPresentationByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryZoomPresentationByIdDocument,
      variables: { zoomPresentationId } as QueryZoomPresentationByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, zoomPresentation: query?.data?.zoomPresentation_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryZoomPresentationByIdFn = (
  query: QueryZoomPresentationByIdQuery | null | undefined
) => ZoomPresentationFragment | null | undefined;
type QueryZoomPresentationByIdLazyFn = [
  (options?: QueryLazyOptions<QueryZoomPresentationByIdQueryVariables> | undefined) => void,
  QueryZoomPresentationByIdResult
];
type QueryZoomPresentationByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryZoomPresentationByIdQueryVariables>, 'variables'>
) => void;
export type QueryZoomPresentationByIdLazyReturn = [
  QueryZoomPresentationByIdWrappedLazyFn,
  QueryZoomPresentationByIdResultEx
];

// Function
function useQueryZoomPresentationByIdLazy({
  zoomPresentationId,
  options,
}: {
  zoomPresentationId: string;
  options?: Omit<
    QueryHookOptions<QueryZoomPresentationByIdQuery, QueryZoomPresentationByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryZoomPresentationByIdLazyReturn {
  const lazyQuery: QueryZoomPresentationByIdLazyFn = useLazyQuery<
    QueryZoomPresentationByIdQuery,
    QueryZoomPresentationByIdQueryVariables
  >(QueryZoomPresentationByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryZoomPresentationById: PickQueryZoomPresentationByIdFn = (query) => {
    return query && query.zoomPresentation_by_pk;
  };
  const wrappedLazyQuery: QueryZoomPresentationByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomPresentationByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryZoomPresentationByIdDocument,
        variables: { zoomPresentationId } as QueryZoomPresentationByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      zoomPresentation: pickQueryZoomPresentationById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryZoomPresentationObjectsResult = LazyQueryResult<
  QueryZoomPresentationObjectsQuery,
  QueryZoomPresentationObjectsQueryVariables
>;
type QueryZoomPresentationObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryZoomPresentationObjectsQuery,
          QueryZoomPresentationObjectsQueryVariables,
          QueryZoomPresentationObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryZoomPresentationObjectsResultEx = Omit<QueryZoomPresentationObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryZoomPresentationObjectsSubScribeToMore;
} & ZoomPresentationObjectsHookResultEx;

// Function
function useQueryZoomPresentationObjects(
  options: Omit<
    QueryHookOptions<QueryZoomPresentationObjectsQuery, QueryZoomPresentationObjectsQueryVariables>,
    'query'
  >
): QueryZoomPresentationObjectsResultEx {
  const _query: QueryZoomPresentationObjectsResult = useQuery<
    QueryZoomPresentationObjectsQuery,
    QueryZoomPresentationObjectsQueryVariables
  >(QueryZoomPresentationObjectsDocument, options);

  const typedSubscribeToMore: QueryZoomPresentationObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryZoomPresentationObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomPresentation || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryZoomPresentationObjectsFn = (
  query: QueryZoomPresentationObjectsQuery | null | undefined
) => ZoomPresentationFragment[];
type QueryZoomPresentationObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryZoomPresentationObjectsQueryVariables> | undefined) => void,
  QueryZoomPresentationObjectsResult
];
type QueryZoomPresentationObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryZoomPresentationObjectsQueryVariables>
) => void;
export type QueryZoomPresentationObjectsLazyReturn = [
  QueryZoomPresentationObjectsWrappedLazyFn,
  QueryZoomPresentationObjectsResultEx
];

// Function
function useQueryZoomPresentationObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryZoomPresentationObjectsQuery, QueryZoomPresentationObjectsQueryVariables>,
    'query'
  >
): QueryZoomPresentationObjectsLazyReturn {
  const lazyQuery: QueryZoomPresentationObjectsLazyFn = useLazyQuery<
    QueryZoomPresentationObjectsQuery,
    QueryZoomPresentationObjectsQueryVariables
  >(QueryZoomPresentationObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryZoomPresentationObjectsFn = (
    query: QueryZoomPresentationObjectsQuery | null | undefined
  ) => {
    return query?.zoomPresentation || [];
  };
  const wrappedLazyQuery: QueryZoomPresentationObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryZoomPresentationObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryZoomPresentationObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToZoomPresentationByIdResult = {
  variables?: SubscribeToZoomPresentationByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomPresentationByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomPresentationByIdResultEx = SubscribeToZoomPresentationByIdResult &
  ZoomPresentationByIdHookResultEx;

// Function
function useSubscribeToZoomPresentationById({
  zoomPresentationId,
  options,
}: {
  zoomPresentationId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomPresentationByIdSubscription,
      SubscribeToZoomPresentationByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToZoomPresentationByIdResultEx {
  const subscription: SubscribeToZoomPresentationByIdResult = useSubscription<
    SubscribeToZoomPresentationByIdSubscription,
    SubscribeToZoomPresentationByIdSubscriptionVariables
  >(SubscribeToZoomPresentationByIdDocument, { variables: { zoomPresentationId }, ...options });
  return { ...subscription, zoomPresentation: subscription?.data?.zoomPresentation_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToZoomPresentationObjectsResult = {
  variables?: SubscribeToZoomPresentationObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToZoomPresentationObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToZoomPresentationObjectsResultEx = SubscribeToZoomPresentationObjectsResult &
  ZoomPresentationObjectsHookResultEx;

// Function
function useSubscribeToZoomPresentationObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToZoomPresentationObjectsSubscription,
      SubscribeToZoomPresentationObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToZoomPresentationObjectsResultEx {
  const subscription: SubscribeToZoomPresentationObjectsResult = useSubscription<
    SubscribeToZoomPresentationObjectsSubscription,
    SubscribeToZoomPresentationObjectsSubscriptionVariables
  >(SubscribeToZoomPresentationObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomPresentation || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertZoomPresentationMutationResult = FetchResult<
  InsertZoomPresentationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomPresentationMutationResultEx = InsertZoomPresentationMutationResult &
  ZoomPresentationByIdHookResultEx;

type PickInsertZoomPresentationFn = (
  mutation: InsertZoomPresentationMutation | null | undefined
) => ZoomPresentationFragment | null | undefined;
type InsertZoomPresentationLazyMutationFn = MutationTuple<
  InsertZoomPresentationMutation,
  InsertZoomPresentationMutationVariables
>;
type InsertZoomPresentationWrappedLazyMutationFn = ({
  zoomPresentation,
  autoOptimisticResponse,
  options,
}: {
  zoomPresentation: ZoomPresentation_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertZoomPresentationMutation, InsertZoomPresentationMutationVariables>,
    'variables'
  >;
}) => Promise<InsertZoomPresentationMutationResultEx>;
export type InsertZoomPresentationLazyMutationReturn = [
  InsertZoomPresentationWrappedLazyMutationFn,
  InsertZoomPresentationMutationResultEx
];

// Function
function useInsertZoomPresentation(
  options?: Omit<
    MutationHookOptions<InsertZoomPresentationMutation, InsertZoomPresentationMutationVariables>,
    'mutation' | 'variables'
  >
): InsertZoomPresentationLazyMutationReturn {
  const lazyMutation: InsertZoomPresentationLazyMutationFn = useMutation<
    InsertZoomPresentationMutation,
    InsertZoomPresentationMutationVariables
  >(InsertZoomPresentationDocument, options);
  const pickZoomPresentation: PickInsertZoomPresentationFn = (mutation) => {
    return mutation?.insert_zoomPresentation?.returning && mutation?.insert_zoomPresentation?.returning[0];
  };

  const wrappedLazyMutation: InsertZoomPresentationWrappedLazyMutationFn = async ({
    zoomPresentation,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomPresentation });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomPresentationMutation,
      InsertZoomPresentationMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomPresentation'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertZoomPresentationMutation>({
        operationType: 'insert',
        entityName: 'zoomPresentation',
        objects: [objectForInsert as ZoomPresentation_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomPresentation: pickZoomPresentation(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomPresentation: pickZoomPresentation(lazyMutation[1].data) }];
}

//
//

// Types
type InsertZoomPresentationWithOnConflictMutationResult = FetchResult<
  InsertZoomPresentationWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomPresentationWithOnConflictMutationResultEx = InsertZoomPresentationWithOnConflictMutationResult &
  ZoomPresentationByIdHookResultEx;

type InsertZoomPresentationWithOnConflictLazyMutationFn = MutationTuple<
  InsertZoomPresentationWithOnConflictMutation,
  InsertZoomPresentationWithOnConflictMutationVariables
>;
type InsertZoomPresentationWithOnConflictWrappedLazyMutationFn = ({
  zoomPresentation,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomPresentation: ZoomPresentation_Insert_Input;
  onConflict: ZoomPresentation_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertZoomPresentationWithOnConflictMutation,
      InsertZoomPresentationWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertZoomPresentationWithOnConflictMutationResultEx>;
export type InsertZoomPresentationWithOnConflictLazyMutationReturn = [
  InsertZoomPresentationWithOnConflictWrappedLazyMutationFn,
  InsertZoomPresentationWithOnConflictMutationResultEx
];

// Function
function useInsertZoomPresentationWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertZoomPresentationWithOnConflictMutation,
      InsertZoomPresentationWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertZoomPresentationWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertZoomPresentationWithOnConflictLazyMutationFn = useMutation<
    InsertZoomPresentationWithOnConflictMutation,
    InsertZoomPresentationWithOnConflictMutationVariables
  >(InsertZoomPresentationWithOnConflictDocument, options);
  const pickZoomPresentation: PickInsertZoomPresentationFn = (
    mutation: InsertZoomPresentationWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomPresentation?.returning && mutation.insert_zoomPresentation.returning[0];
  };

  const wrappedLazyMutation: InsertZoomPresentationWithOnConflictWrappedLazyMutationFn = async ({
    zoomPresentation,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomPresentation });
    const mutationOptions: MutationFunctionOptions<
      InsertZoomPresentationWithOnConflictMutation,
      InsertZoomPresentationWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomPresentation'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertZoomPresentationWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomPresentation',
          objects: [objectForInsert as ZoomPresentation_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, zoomPresentation: pickZoomPresentation(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomPresentation: pickZoomPresentation(lazyMutation[1].data) }];
}

// Types
type InsertZoomPresentationObjectsMutationResult = FetchResult<
  InsertZoomPresentationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertZoomPresentationObjectsMutationResultEx = InsertZoomPresentationMutationResult &
  ZoomPresentationObjectsHookResultEx;

type PickInsertZoomPresentationObjectsFn = (
  mutation: InsertZoomPresentationMutation | null | undefined
) => ZoomPresentationFragment[];
type InsertZoomPresentationObjectsLazyMutationFn = MutationTuple<
  InsertZoomPresentationMutation,
  InsertZoomPresentationMutationVariables
>;
type InsertZoomPresentationObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertZoomPresentationMutation, InsertZoomPresentationMutationVariables>
) => Promise<InsertZoomPresentationObjectsMutationResultEx>;
export type InsertZoomPresentationObjectsLazyMutationReturn = [
  InsertZoomPresentationObjectsWrappedLazyMutationFn,
  InsertZoomPresentationObjectsMutationResultEx
];

// Function
function useInsertZoomPresentationObjects(
  options?: Omit<
    MutationHookOptions<InsertZoomPresentationMutation, InsertZoomPresentationMutationVariables>,
    'mutation'
  >
): InsertZoomPresentationObjectsLazyMutationReturn {
  const lazyMutation: InsertZoomPresentationObjectsLazyMutationFn = useMutation<
    InsertZoomPresentationMutation,
    InsertZoomPresentationMutationVariables
  >(InsertZoomPresentationDocument, options);
  const pickObjects: PickInsertZoomPresentationObjectsFn = (
    mutation: InsertZoomPresentationMutation | null | undefined
  ) => {
    return mutation?.insert_zoomPresentation?.returning || [];
  };

  const wrappedLazyMutation: InsertZoomPresentationObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertZoomPresentationObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateZoomPresentationByIdMutationResult = FetchResult<
  UpdateZoomPresentationByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomPresentationByIdMutationResultEx = UpdateZoomPresentationByIdMutationResult &
  ZoomPresentationByIdHookResultEx;

type PickUpdateZoomPresentationByIdFn = (
  mutation: UpdateZoomPresentationByIdMutation | null | undefined
) => ZoomPresentationFragment | null | undefined;
type UpdateZoomPresentationByIdLazyMutationFn = MutationTuple<
  UpdateZoomPresentationByIdMutation,
  UpdateZoomPresentationByIdMutationVariables
>;
type UpdateZoomPresentationByIdWrappedLazyMutationFn = ({
  zoomPresentationId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomPresentationId: string;
  set: ZoomPresentation_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateZoomPresentationByIdMutation, UpdateZoomPresentationByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateZoomPresentationByIdMutationResultEx>;
export type UpdateZoomPresentationByIdLazyMutationReturn = [
  UpdateZoomPresentationByIdWrappedLazyMutationFn,
  UpdateZoomPresentationByIdMutationResultEx
];

function useUpdateZoomPresentationById(
  options?: Omit<
    MutationHookOptions<UpdateZoomPresentationByIdMutation, UpdateZoomPresentationByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateZoomPresentationByIdLazyMutationReturn {
  const lazyMutation: UpdateZoomPresentationByIdLazyMutationFn = useMutation<
    UpdateZoomPresentationByIdMutation,
    UpdateZoomPresentationByIdMutationVariables
  >(UpdateZoomPresentationByIdDocument, options);

  const pickZoomPresentation: PickUpdateZoomPresentationByIdFn = (mutation) => {
    return mutation?.update_zoomPresentation?.returning && mutation.update_zoomPresentation!.returning[0];
  };

  const wrappedLazyMutation: UpdateZoomPresentationByIdWrappedLazyMutationFn = async ({
    zoomPresentationId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateZoomPresentationByIdMutation,
      UpdateZoomPresentationByIdMutationVariables
    > = { variables: { id: zoomPresentationId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateZoomPresentationByIdMutation>({
        operationType: 'update',
        entityName: 'zoomPresentation',
        objects: [{ id: zoomPresentationId, ...set }],
      });
    }

    const fetchResult: UpdateZoomPresentationByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomPresentationId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, zoomPresentation: pickZoomPresentation(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], zoomPresentation: pickZoomPresentation(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateZoomPresentationObjectsMutationResult = FetchResult<
  UpdateZoomPresentationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateZoomPresentationObjectsMutationResultEx = UpdateZoomPresentationObjectsMutationResult &
  ZoomPresentationObjectsHookResultEx;

// Function
type PickUpdateZoomPresentationObjectsFn = (
  mutation: UpdateZoomPresentationMutation | null | undefined
) => ZoomPresentationFragment[];
type UpdateZoomPresentationObjectsLazyMutationFn = MutationTuple<
  UpdateZoomPresentationMutation,
  UpdateZoomPresentationMutationVariables
>;
type UpdateZoomPresentationObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateZoomPresentationMutation, UpdateZoomPresentationMutationVariables>
) => Promise<UpdateZoomPresentationObjectsMutationResultEx>;
export type UpdateZoomPresentationObjectsLazyMutationReturn = [
  UpdateZoomPresentationObjectsWrappedLazyMutationFn,
  UpdateZoomPresentationObjectsMutationResultEx
];

function useUpdateZoomPresentationObjects(
  options?: Omit<
    MutationHookOptions<UpdateZoomPresentationMutation, UpdateZoomPresentationMutationVariables>,
    'mutation'
  >
): UpdateZoomPresentationObjectsLazyMutationReturn {
  const lazyMutation: UpdateZoomPresentationObjectsLazyMutationFn = useMutation<
    UpdateZoomPresentationMutation,
    UpdateZoomPresentationMutationVariables
  >(UpdateZoomPresentationDocument, options);

  const pickObjects: PickUpdateZoomPresentationObjectsFn = (
    mutation: UpdateZoomPresentationMutation | null | undefined
  ) => {
    return mutation?.update_zoomPresentation?.returning || [];
  };

  const wrappedLazyMutation: UpdateZoomPresentationObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateZoomPresentationObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomPresentationModelByIdFetchResult = FetchResult<
  RemoveZoomPresentationModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomPresentationModelByIdMutationResultEx = RemoveZoomPresentationModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomPresentationModelFn = (
  mutation: RemoveZoomPresentationModelByIdMutation | null | undefined
) => number;
type RemoveZoomPresentationModelLazyMutationFn = MutationTuple<
  RemoveZoomPresentationModelByIdMutation,
  RemoveZoomPresentationModelByIdMutationVariables
>;
type RemoveZoomPresentationModelWrappedLazyMutationFn = ({
  zoomPresentationId,
  autoOptimisticResponse,
  options,
}: {
  zoomPresentationId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomPresentationModelByIdMutation, RemoveZoomPresentationModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomPresentationModelByIdMutationResultEx>;
export type RemoveZoomPresentationModelLazyMutationReturn = [
  RemoveZoomPresentationModelWrappedLazyMutationFn,
  RemoveZoomPresentationModelByIdMutationResultEx
];

function useRemoveZoomPresentationModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomPresentationModelByIdMutation, RemoveZoomPresentationModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomPresentationModelLazyMutationFn = useMutation<
    RemoveZoomPresentationModelByIdMutation,
    RemoveZoomPresentationModelByIdMutationVariables
  >(RemoveZoomPresentationModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomPresentationModelFn = (
    mutation: RemoveZoomPresentationModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomPresentation?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomPresentationModelWrappedLazyMutationFn = async ({
    zoomPresentationId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomPresentationModelMutation,
      RemoveZoomPresentationModelByIdMutationVariables
    > = { variables: { id: zoomPresentationId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomPresentationModelMutation>({
        operationType: 'delete',
        entityName: 'zoomPresentation',
        objects: [{ id: zoomPresentationId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomPresentationModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomPresentation',
        entityId: zoomPresentationId,
      });
    }

    const fetchResult: RemoveZoomPresentationModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomPresentationModelObjectsMutationResult = FetchResult<
  RemoveZoomPresentationModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomPresentationModelObjectsMutationResultEx = RemoveZoomPresentationModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomPresentationModelObjectsFn = (
  mutation: RemoveZoomPresentationModelMutation | null | undefined
) => number;
type RemoveZoomPresentationModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomPresentationModelMutation,
  RemoveZoomPresentationModelMutationVariables
>;
type RemoveZoomPresentationModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomPresentationModelMutation, RemoveZoomPresentationModelMutationVariables>
) => Promise<RemoveZoomPresentationModelObjectsMutationResultEx>;
export type RemoveZoomPresentationModelObjectsLazyMutationReturn = [
  RemoveZoomPresentationModelObjectsWrappedLazyMutationFn,
  RemoveZoomPresentationModelObjectsMutationResultEx
];

function useRemoveZoomPresentationModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomPresentationModelMutation, RemoveZoomPresentationModelMutationVariables>,
    'mutation'
  >
): RemoveZoomPresentationModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomPresentationModelMutation, RemoveZoomPresentationModelMutationVariables>(
    RemoveZoomPresentationModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomPresentationModelObjectsFn = (
    mutation: RemoveZoomPresentationModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomPresentation?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomPresentationModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomPresentationModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ZoomPresentation Fragment Helper Object
//------------------------------------------------

export const ZoomPresentationFragmentGQLHooks = {
  useQueryById: useQueryZoomPresentationById,
  useQueryByIdLazy: useQueryZoomPresentationByIdLazy,
  useQueryObjects: useQueryZoomPresentationObjects,
  useQueryObjectsLazy: useQueryZoomPresentationObjectsLazy,
  useSubscribeToById: useSubscribeToZoomPresentationById,
  useSubscribeToObjects: useSubscribeToZoomPresentationObjects,
  useInsert: useInsertZoomPresentation,
  useInsertWithOnConflict: useInsertZoomPresentationWithOnConflict,
  useInsertObjects: useInsertZoomPresentationObjects,
  useUpdateById: useUpdateZoomPresentationById,
  useUpdateObjects: useUpdateZoomPresentationObjects,
};

// zoomPresentation MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomPresentationModelGQLHooks = {
  useRemoveById: useRemoveZoomPresentationModelById,
  useRemoveObjects: useRemoveZoomPresentationModelObjects,
};

// growthUserReferralContact REACT
//------------------------------------------------

export type GrowthUserReferralContactByIdHookResultEx = {
  growthUserReferralContact: GrowthUserReferralContactFragment | null | undefined;
};
export type GrowthUserReferralContactObjectsHookResultEx = { objects: GrowthUserReferralContactFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryGrowthUserReferralContactByIdResult = LazyQueryResult<
  QueryGrowthUserReferralContactByIdQuery,
  QueryGrowthUserReferralContactByIdQueryVariables
>;
type QueryGrowthUserReferralContactByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryGrowthUserReferralContactByIdQuery,
          QueryGrowthUserReferralContactByIdQueryVariables,
          QueryGrowthUserReferralContactByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryGrowthUserReferralContactByIdResultEx = Omit<
  QueryGrowthUserReferralContactByIdResult,
  'subscribeToMore'
> & { subscribeToMore: QueryGrowthUserReferralContactByIdSubScribeToMore } & GrowthUserReferralContactByIdHookResultEx;

// Function
function useQueryGrowthUserReferralContactById({
  growthUserReferralContactId,
  options,
}: {
  growthUserReferralContactId: string;
  options?: Omit<
    QueryHookOptions<QueryGrowthUserReferralContactByIdQuery, QueryGrowthUserReferralContactByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryGrowthUserReferralContactByIdResultEx {
  const _query: QueryGrowthUserReferralContactByIdResult = useQuery<
    QueryGrowthUserReferralContactByIdQuery,
    QueryGrowthUserReferralContactByIdQueryVariables
  >(QueryGrowthUserReferralContactByIdDocument, { variables: { growthUserReferralContactId }, ...options });

  const typedSubscribeToMore: QueryGrowthUserReferralContactByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryGrowthUserReferralContactByIdDocument,
      variables: { growthUserReferralContactId } as QueryGrowthUserReferralContactByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    growthUserReferralContact: query?.data?.growthUserReferralContact_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryGrowthUserReferralContactByIdFn = (
  query: QueryGrowthUserReferralContactByIdQuery | null | undefined
) => GrowthUserReferralContactFragment | null | undefined;
type QueryGrowthUserReferralContactByIdLazyFn = [
  (options?: QueryLazyOptions<QueryGrowthUserReferralContactByIdQueryVariables> | undefined) => void,
  QueryGrowthUserReferralContactByIdResult
];
type QueryGrowthUserReferralContactByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryGrowthUserReferralContactByIdQueryVariables>, 'variables'>
) => void;
export type QueryGrowthUserReferralContactByIdLazyReturn = [
  QueryGrowthUserReferralContactByIdWrappedLazyFn,
  QueryGrowthUserReferralContactByIdResultEx
];

// Function
function useQueryGrowthUserReferralContactByIdLazy({
  growthUserReferralContactId,
  options,
}: {
  growthUserReferralContactId: string;
  options?: Omit<
    QueryHookOptions<QueryGrowthUserReferralContactByIdQuery, QueryGrowthUserReferralContactByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryGrowthUserReferralContactByIdLazyReturn {
  const lazyQuery: QueryGrowthUserReferralContactByIdLazyFn = useLazyQuery<
    QueryGrowthUserReferralContactByIdQuery,
    QueryGrowthUserReferralContactByIdQueryVariables
  >(QueryGrowthUserReferralContactByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryGrowthUserReferralContactById: PickQueryGrowthUserReferralContactByIdFn = (query) => {
    return query && query.growthUserReferralContact_by_pk;
  };
  const wrappedLazyQuery: QueryGrowthUserReferralContactByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryGrowthUserReferralContactByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryGrowthUserReferralContactByIdDocument,
        variables: { growthUserReferralContactId } as QueryGrowthUserReferralContactByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      growthUserReferralContact: pickQueryGrowthUserReferralContactById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryGrowthUserReferralContactObjectsResult = LazyQueryResult<
  QueryGrowthUserReferralContactObjectsQuery,
  QueryGrowthUserReferralContactObjectsQueryVariables
>;
type QueryGrowthUserReferralContactObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryGrowthUserReferralContactObjectsQuery,
          QueryGrowthUserReferralContactObjectsQueryVariables,
          QueryGrowthUserReferralContactObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryGrowthUserReferralContactObjectsResultEx = Omit<
  QueryGrowthUserReferralContactObjectsResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryGrowthUserReferralContactObjectsSubScribeToMore;
} & GrowthUserReferralContactObjectsHookResultEx;

// Function
function useQueryGrowthUserReferralContactObjects(
  options: Omit<
    QueryHookOptions<QueryGrowthUserReferralContactObjectsQuery, QueryGrowthUserReferralContactObjectsQueryVariables>,
    'query'
  >
): QueryGrowthUserReferralContactObjectsResultEx {
  const _query: QueryGrowthUserReferralContactObjectsResult = useQuery<
    QueryGrowthUserReferralContactObjectsQuery,
    QueryGrowthUserReferralContactObjectsQueryVariables
  >(QueryGrowthUserReferralContactObjectsDocument, options);

  const typedSubscribeToMore: QueryGrowthUserReferralContactObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryGrowthUserReferralContactObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.growthUserReferralContact || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryGrowthUserReferralContactObjectsFn = (
  query: QueryGrowthUserReferralContactObjectsQuery | null | undefined
) => GrowthUserReferralContactFragment[];
type QueryGrowthUserReferralContactObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryGrowthUserReferralContactObjectsQueryVariables> | undefined) => void,
  QueryGrowthUserReferralContactObjectsResult
];
type QueryGrowthUserReferralContactObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryGrowthUserReferralContactObjectsQueryVariables>
) => void;
export type QueryGrowthUserReferralContactObjectsLazyReturn = [
  QueryGrowthUserReferralContactObjectsWrappedLazyFn,
  QueryGrowthUserReferralContactObjectsResultEx
];

// Function
function useQueryGrowthUserReferralContactObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<
      QueryGrowthUserReferralContactObjectsQuery,
      QueryGrowthUserReferralContactObjectsQueryVariables
    >,
    'query'
  >
): QueryGrowthUserReferralContactObjectsLazyReturn {
  const lazyQuery: QueryGrowthUserReferralContactObjectsLazyFn = useLazyQuery<
    QueryGrowthUserReferralContactObjectsQuery,
    QueryGrowthUserReferralContactObjectsQueryVariables
  >(QueryGrowthUserReferralContactObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryGrowthUserReferralContactObjectsFn = (
    query: QueryGrowthUserReferralContactObjectsQuery | null | undefined
  ) => {
    return query?.growthUserReferralContact || [];
  };
  const wrappedLazyQuery: QueryGrowthUserReferralContactObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryGrowthUserReferralContactObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryGrowthUserReferralContactObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToGrowthUserReferralContactByIdResult = {
  variables?: SubscribeToGrowthUserReferralContactByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToGrowthUserReferralContactByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToGrowthUserReferralContactByIdResultEx = SubscribeToGrowthUserReferralContactByIdResult &
  GrowthUserReferralContactByIdHookResultEx;

// Function
function useSubscribeToGrowthUserReferralContactById({
  growthUserReferralContactId,
  options,
}: {
  growthUserReferralContactId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToGrowthUserReferralContactByIdSubscription,
      SubscribeToGrowthUserReferralContactByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToGrowthUserReferralContactByIdResultEx {
  const subscription: SubscribeToGrowthUserReferralContactByIdResult = useSubscription<
    SubscribeToGrowthUserReferralContactByIdSubscription,
    SubscribeToGrowthUserReferralContactByIdSubscriptionVariables
  >(SubscribeToGrowthUserReferralContactByIdDocument, { variables: { growthUserReferralContactId }, ...options });
  return { ...subscription, growthUserReferralContact: subscription?.data?.growthUserReferralContact_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToGrowthUserReferralContactObjectsResult = {
  variables?: SubscribeToGrowthUserReferralContactObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToGrowthUserReferralContactObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToGrowthUserReferralContactObjectsResultEx = SubscribeToGrowthUserReferralContactObjectsResult &
  GrowthUserReferralContactObjectsHookResultEx;

// Function
function useSubscribeToGrowthUserReferralContactObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToGrowthUserReferralContactObjectsSubscription,
      SubscribeToGrowthUserReferralContactObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToGrowthUserReferralContactObjectsResultEx {
  const subscription: SubscribeToGrowthUserReferralContactObjectsResult = useSubscription<
    SubscribeToGrowthUserReferralContactObjectsSubscription,
    SubscribeToGrowthUserReferralContactObjectsSubscriptionVariables
  >(SubscribeToGrowthUserReferralContactObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.growthUserReferralContact || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertGrowthUserReferralContactMutationResult = FetchResult<
  InsertGrowthUserReferralContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertGrowthUserReferralContactMutationResultEx = InsertGrowthUserReferralContactMutationResult &
  GrowthUserReferralContactByIdHookResultEx;

type PickInsertGrowthUserReferralContactFn = (
  mutation: InsertGrowthUserReferralContactMutation | null | undefined
) => GrowthUserReferralContactFragment | null | undefined;
type InsertGrowthUserReferralContactLazyMutationFn = MutationTuple<
  InsertGrowthUserReferralContactMutation,
  InsertGrowthUserReferralContactMutationVariables
>;
type InsertGrowthUserReferralContactWrappedLazyMutationFn = ({
  growthUserReferralContact,
  autoOptimisticResponse,
  options,
}: {
  growthUserReferralContact: GrowthUserReferralContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertGrowthUserReferralContactMutation, InsertGrowthUserReferralContactMutationVariables>,
    'variables'
  >;
}) => Promise<InsertGrowthUserReferralContactMutationResultEx>;
export type InsertGrowthUserReferralContactLazyMutationReturn = [
  InsertGrowthUserReferralContactWrappedLazyMutationFn,
  InsertGrowthUserReferralContactMutationResultEx
];

// Function
function useInsertGrowthUserReferralContact(
  options?: Omit<
    MutationHookOptions<InsertGrowthUserReferralContactMutation, InsertGrowthUserReferralContactMutationVariables>,
    'mutation' | 'variables'
  >
): InsertGrowthUserReferralContactLazyMutationReturn {
  const lazyMutation: InsertGrowthUserReferralContactLazyMutationFn = useMutation<
    InsertGrowthUserReferralContactMutation,
    InsertGrowthUserReferralContactMutationVariables
  >(InsertGrowthUserReferralContactDocument, options);
  const pickGrowthUserReferralContact: PickInsertGrowthUserReferralContactFn = (mutation) => {
    return (
      mutation?.insert_growthUserReferralContact?.returning && mutation?.insert_growthUserReferralContact?.returning[0]
    );
  };

  const wrappedLazyMutation: InsertGrowthUserReferralContactWrappedLazyMutationFn = async ({
    growthUserReferralContact,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: growthUserReferralContact });
    const mutationOptions: MutationFunctionOptions<
      InsertGrowthUserReferralContactMutation,
      InsertGrowthUserReferralContactMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'growthUserReferralContact'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertGrowthUserReferralContactMutation>({
          operationType: 'insert',
          entityName: 'growthUserReferralContact',
          objects: [objectForInsert as GrowthUserReferralContact_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, growthUserReferralContact: pickGrowthUserReferralContact(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], growthUserReferralContact: pickGrowthUserReferralContact(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertGrowthUserReferralContactWithOnConflictMutationResult = FetchResult<
  InsertGrowthUserReferralContactWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertGrowthUserReferralContactWithOnConflictMutationResultEx =
  InsertGrowthUserReferralContactWithOnConflictMutationResult & GrowthUserReferralContactByIdHookResultEx;

type InsertGrowthUserReferralContactWithOnConflictLazyMutationFn = MutationTuple<
  InsertGrowthUserReferralContactWithOnConflictMutation,
  InsertGrowthUserReferralContactWithOnConflictMutationVariables
>;
type InsertGrowthUserReferralContactWithOnConflictWrappedLazyMutationFn = ({
  growthUserReferralContact,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  growthUserReferralContact: GrowthUserReferralContact_Insert_Input;
  onConflict: GrowthUserReferralContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertGrowthUserReferralContactWithOnConflictMutation,
      InsertGrowthUserReferralContactWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertGrowthUserReferralContactWithOnConflictMutationResultEx>;
export type InsertGrowthUserReferralContactWithOnConflictLazyMutationReturn = [
  InsertGrowthUserReferralContactWithOnConflictWrappedLazyMutationFn,
  InsertGrowthUserReferralContactWithOnConflictMutationResultEx
];

// Function
function useInsertGrowthUserReferralContactWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertGrowthUserReferralContactWithOnConflictMutation,
      InsertGrowthUserReferralContactWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertGrowthUserReferralContactWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertGrowthUserReferralContactWithOnConflictLazyMutationFn = useMutation<
    InsertGrowthUserReferralContactWithOnConflictMutation,
    InsertGrowthUserReferralContactWithOnConflictMutationVariables
  >(InsertGrowthUserReferralContactWithOnConflictDocument, options);
  const pickGrowthUserReferralContact: PickInsertGrowthUserReferralContactFn = (
    mutation: InsertGrowthUserReferralContactWithOnConflictMutation | null | undefined
  ) => {
    return (
      mutation?.insert_growthUserReferralContact?.returning && mutation.insert_growthUserReferralContact.returning[0]
    );
  };

  const wrappedLazyMutation: InsertGrowthUserReferralContactWithOnConflictWrappedLazyMutationFn = async ({
    growthUserReferralContact,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: growthUserReferralContact });
    const mutationOptions: MutationFunctionOptions<
      InsertGrowthUserReferralContactWithOnConflictMutation,
      InsertGrowthUserReferralContactWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'growthUserReferralContact'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertGrowthUserReferralContactWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'growthUserReferralContact',
          objects: [objectForInsert as GrowthUserReferralContact_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, growthUserReferralContact: pickGrowthUserReferralContact(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], growthUserReferralContact: pickGrowthUserReferralContact(lazyMutation[1].data) },
  ];
}

// Types
type InsertGrowthUserReferralContactObjectsMutationResult = FetchResult<
  InsertGrowthUserReferralContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertGrowthUserReferralContactObjectsMutationResultEx = InsertGrowthUserReferralContactMutationResult &
  GrowthUserReferralContactObjectsHookResultEx;

type PickInsertGrowthUserReferralContactObjectsFn = (
  mutation: InsertGrowthUserReferralContactMutation | null | undefined
) => GrowthUserReferralContactFragment[];
type InsertGrowthUserReferralContactObjectsLazyMutationFn = MutationTuple<
  InsertGrowthUserReferralContactMutation,
  InsertGrowthUserReferralContactMutationVariables
>;
type InsertGrowthUserReferralContactObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    InsertGrowthUserReferralContactMutation,
    InsertGrowthUserReferralContactMutationVariables
  >
) => Promise<InsertGrowthUserReferralContactObjectsMutationResultEx>;
export type InsertGrowthUserReferralContactObjectsLazyMutationReturn = [
  InsertGrowthUserReferralContactObjectsWrappedLazyMutationFn,
  InsertGrowthUserReferralContactObjectsMutationResultEx
];

// Function
function useInsertGrowthUserReferralContactObjects(
  options?: Omit<
    MutationHookOptions<InsertGrowthUserReferralContactMutation, InsertGrowthUserReferralContactMutationVariables>,
    'mutation'
  >
): InsertGrowthUserReferralContactObjectsLazyMutationReturn {
  const lazyMutation: InsertGrowthUserReferralContactObjectsLazyMutationFn = useMutation<
    InsertGrowthUserReferralContactMutation,
    InsertGrowthUserReferralContactMutationVariables
  >(InsertGrowthUserReferralContactDocument, options);
  const pickObjects: PickInsertGrowthUserReferralContactObjectsFn = (
    mutation: InsertGrowthUserReferralContactMutation | null | undefined
  ) => {
    return mutation?.insert_growthUserReferralContact?.returning || [];
  };

  const wrappedLazyMutation: InsertGrowthUserReferralContactObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertGrowthUserReferralContactObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateGrowthUserReferralContactByIdMutationResult = FetchResult<
  UpdateGrowthUserReferralContactByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateGrowthUserReferralContactByIdMutationResultEx = UpdateGrowthUserReferralContactByIdMutationResult &
  GrowthUserReferralContactByIdHookResultEx;

type PickUpdateGrowthUserReferralContactByIdFn = (
  mutation: UpdateGrowthUserReferralContactByIdMutation | null | undefined
) => GrowthUserReferralContactFragment | null | undefined;
type UpdateGrowthUserReferralContactByIdLazyMutationFn = MutationTuple<
  UpdateGrowthUserReferralContactByIdMutation,
  UpdateGrowthUserReferralContactByIdMutationVariables
>;
type UpdateGrowthUserReferralContactByIdWrappedLazyMutationFn = ({
  growthUserReferralContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  growthUserReferralContactId: string;
  set: GrowthUserReferralContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateGrowthUserReferralContactByIdMutation,
      UpdateGrowthUserReferralContactByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateGrowthUserReferralContactByIdMutationResultEx>;
export type UpdateGrowthUserReferralContactByIdLazyMutationReturn = [
  UpdateGrowthUserReferralContactByIdWrappedLazyMutationFn,
  UpdateGrowthUserReferralContactByIdMutationResultEx
];

function useUpdateGrowthUserReferralContactById(
  options?: Omit<
    MutationHookOptions<
      UpdateGrowthUserReferralContactByIdMutation,
      UpdateGrowthUserReferralContactByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
): UpdateGrowthUserReferralContactByIdLazyMutationReturn {
  const lazyMutation: UpdateGrowthUserReferralContactByIdLazyMutationFn = useMutation<
    UpdateGrowthUserReferralContactByIdMutation,
    UpdateGrowthUserReferralContactByIdMutationVariables
  >(UpdateGrowthUserReferralContactByIdDocument, options);

  const pickGrowthUserReferralContact: PickUpdateGrowthUserReferralContactByIdFn = (mutation) => {
    return (
      mutation?.update_growthUserReferralContact?.returning && mutation.update_growthUserReferralContact!.returning[0]
    );
  };

  const wrappedLazyMutation: UpdateGrowthUserReferralContactByIdWrappedLazyMutationFn = async ({
    growthUserReferralContactId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateGrowthUserReferralContactByIdMutation,
      UpdateGrowthUserReferralContactByIdMutationVariables
    > = { variables: { id: growthUserReferralContactId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<UpdateGrowthUserReferralContactByIdMutation>({
          operationType: 'update',
          entityName: 'growthUserReferralContact',
          objects: [{ id: growthUserReferralContactId, ...set }],
        });
    }

    const fetchResult: UpdateGrowthUserReferralContactByIdMutationResult = await lazyMutation[0]({
      variables: { id: growthUserReferralContactId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, growthUserReferralContact: pickGrowthUserReferralContact(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], growthUserReferralContact: pickGrowthUserReferralContact(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateGrowthUserReferralContactObjectsMutationResult = FetchResult<
  UpdateGrowthUserReferralContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateGrowthUserReferralContactObjectsMutationResultEx =
  UpdateGrowthUserReferralContactObjectsMutationResult & GrowthUserReferralContactObjectsHookResultEx;

// Function
type PickUpdateGrowthUserReferralContactObjectsFn = (
  mutation: UpdateGrowthUserReferralContactMutation | null | undefined
) => GrowthUserReferralContactFragment[];
type UpdateGrowthUserReferralContactObjectsLazyMutationFn = MutationTuple<
  UpdateGrowthUserReferralContactMutation,
  UpdateGrowthUserReferralContactMutationVariables
>;
type UpdateGrowthUserReferralContactObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    UpdateGrowthUserReferralContactMutation,
    UpdateGrowthUserReferralContactMutationVariables
  >
) => Promise<UpdateGrowthUserReferralContactObjectsMutationResultEx>;
export type UpdateGrowthUserReferralContactObjectsLazyMutationReturn = [
  UpdateGrowthUserReferralContactObjectsWrappedLazyMutationFn,
  UpdateGrowthUserReferralContactObjectsMutationResultEx
];

function useUpdateGrowthUserReferralContactObjects(
  options?: Omit<
    MutationHookOptions<UpdateGrowthUserReferralContactMutation, UpdateGrowthUserReferralContactMutationVariables>,
    'mutation'
  >
): UpdateGrowthUserReferralContactObjectsLazyMutationReturn {
  const lazyMutation: UpdateGrowthUserReferralContactObjectsLazyMutationFn = useMutation<
    UpdateGrowthUserReferralContactMutation,
    UpdateGrowthUserReferralContactMutationVariables
  >(UpdateGrowthUserReferralContactDocument, options);

  const pickObjects: PickUpdateGrowthUserReferralContactObjectsFn = (
    mutation: UpdateGrowthUserReferralContactMutation | null | undefined
  ) => {
    return mutation?.update_growthUserReferralContact?.returning || [];
  };

  const wrappedLazyMutation: UpdateGrowthUserReferralContactObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateGrowthUserReferralContactObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveGrowthUserReferralContactModelByIdFetchResult = FetchResult<
  RemoveGrowthUserReferralContactModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveGrowthUserReferralContactModelByIdMutationResultEx =
  RemoveGrowthUserReferralContactModelByIdFetchResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveGrowthUserReferralContactModelFn = (
  mutation: RemoveGrowthUserReferralContactModelByIdMutation | null | undefined
) => number;
type RemoveGrowthUserReferralContactModelLazyMutationFn = MutationTuple<
  RemoveGrowthUserReferralContactModelByIdMutation,
  RemoveGrowthUserReferralContactModelByIdMutationVariables
>;
type RemoveGrowthUserReferralContactModelWrappedLazyMutationFn = ({
  growthUserReferralContactId,
  autoOptimisticResponse,
  options,
}: {
  growthUserReferralContactId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveGrowthUserReferralContactModelByIdMutation,
      RemoveGrowthUserReferralContactModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveGrowthUserReferralContactModelByIdMutationResultEx>;
export type RemoveGrowthUserReferralContactModelLazyMutationReturn = [
  RemoveGrowthUserReferralContactModelWrappedLazyMutationFn,
  RemoveGrowthUserReferralContactModelByIdMutationResultEx
];

function useRemoveGrowthUserReferralContactModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveGrowthUserReferralContactModelByIdMutation,
      RemoveGrowthUserReferralContactModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveGrowthUserReferralContactModelLazyMutationFn = useMutation<
    RemoveGrowthUserReferralContactModelByIdMutation,
    RemoveGrowthUserReferralContactModelByIdMutationVariables
  >(RemoveGrowthUserReferralContactModelByIdDocument, options);

  const pickAffectedRows: PickRemoveGrowthUserReferralContactModelFn = (
    mutation: RemoveGrowthUserReferralContactModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_growthUserReferralContact?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveGrowthUserReferralContactModelWrappedLazyMutationFn = async ({
    growthUserReferralContactId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveGrowthUserReferralContactModelMutation,
      RemoveGrowthUserReferralContactModelByIdMutationVariables
    > = { variables: { id: growthUserReferralContactId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<RemoveGrowthUserReferralContactModelMutation>({
          operationType: 'delete',
          entityName: 'growthUserReferralContact',
          objects: [{ id: growthUserReferralContactId }],
        });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveGrowthUserReferralContactModelByIdMutation>({
        operationType: 'delete',
        entityName: 'growthUserReferralContact',
        entityId: growthUserReferralContactId,
      });
    }

    const fetchResult: RemoveGrowthUserReferralContactModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveGrowthUserReferralContactModelObjectsMutationResult = FetchResult<
  RemoveGrowthUserReferralContactModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveGrowthUserReferralContactModelObjectsMutationResultEx =
  RemoveGrowthUserReferralContactModelObjectsMutationResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveGrowthUserReferralContactModelObjectsFn = (
  mutation: RemoveGrowthUserReferralContactModelMutation | null | undefined
) => number;
type RemoveGrowthUserReferralContactModelObjectsLazyMutationFn = MutationTuple<
  RemoveGrowthUserReferralContactModelMutation,
  RemoveGrowthUserReferralContactModelMutationVariables
>;
type RemoveGrowthUserReferralContactModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveGrowthUserReferralContactModelMutation,
    RemoveGrowthUserReferralContactModelMutationVariables
  >
) => Promise<RemoveGrowthUserReferralContactModelObjectsMutationResultEx>;
export type RemoveGrowthUserReferralContactModelObjectsLazyMutationReturn = [
  RemoveGrowthUserReferralContactModelObjectsWrappedLazyMutationFn,
  RemoveGrowthUserReferralContactModelObjectsMutationResultEx
];

function useRemoveGrowthUserReferralContactModelObjects(
  options?: Omit<
    MutationHookOptions<
      RemoveGrowthUserReferralContactModelMutation,
      RemoveGrowthUserReferralContactModelMutationVariables
    >,
    'mutation'
  >
): RemoveGrowthUserReferralContactModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveGrowthUserReferralContactModelMutation,
    RemoveGrowthUserReferralContactModelMutationVariables
  >(RemoveGrowthUserReferralContactModelDocument, options);

  const pickAffectedRows: PickRemoveGrowthUserReferralContactModelObjectsFn = (
    mutation: RemoveGrowthUserReferralContactModelMutation | null | undefined
  ) => {
    return mutation?.delete_growthUserReferralContact?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveGrowthUserReferralContactModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveGrowthUserReferralContactModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// GrowthUserReferralContact Fragment Helper Object
//------------------------------------------------

export const GrowthUserReferralContactFragmentGQLHooks = {
  useQueryById: useQueryGrowthUserReferralContactById,
  useQueryByIdLazy: useQueryGrowthUserReferralContactByIdLazy,
  useQueryObjects: useQueryGrowthUserReferralContactObjects,
  useQueryObjectsLazy: useQueryGrowthUserReferralContactObjectsLazy,
  useSubscribeToById: useSubscribeToGrowthUserReferralContactById,
  useSubscribeToObjects: useSubscribeToGrowthUserReferralContactObjects,
  useInsert: useInsertGrowthUserReferralContact,
  useInsertWithOnConflict: useInsertGrowthUserReferralContactWithOnConflict,
  useInsertObjects: useInsertGrowthUserReferralContactObjects,
  useUpdateById: useUpdateGrowthUserReferralContactById,
  useUpdateObjects: useUpdateGrowthUserReferralContactObjects,
};

// growthUserReferralContact MODEL HOOKS OBJECT
//------------------------------------------------

export const GrowthUserReferralContactModelGQLHooks = {
  useRemoveById: useRemoveGrowthUserReferralContactModelById,
  useRemoveObjects: useRemoveGrowthUserReferralContactModelObjects,
};

// zoomLayersEvents REACT
//------------------------------------------------

export type LayersEventsByIdHookResultEx = { layersEvents: LayersEventsFragment | null | undefined };
export type LayersEventsObjectsHookResultEx = { objects: LayersEventsFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryLayersEventsByIdResult = LazyQueryResult<QueryLayersEventsByIdQuery, QueryLayersEventsByIdQueryVariables>;
type QueryLayersEventsByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryLayersEventsByIdQuery,
          QueryLayersEventsByIdQueryVariables,
          QueryLayersEventsByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryLayersEventsByIdResultEx = Omit<QueryLayersEventsByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryLayersEventsByIdSubScribeToMore;
} & LayersEventsByIdHookResultEx;

// Function
function useQueryLayersEventsById({
  zoomLayersEventsId,
  options,
}: {
  zoomLayersEventsId: string;
  options?: Omit<
    QueryHookOptions<QueryLayersEventsByIdQuery, QueryLayersEventsByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryLayersEventsByIdResultEx {
  const _query: QueryLayersEventsByIdResult = useQuery<QueryLayersEventsByIdQuery, QueryLayersEventsByIdQueryVariables>(
    QueryLayersEventsByIdDocument,
    { variables: { zoomLayersEventsId }, ...options }
  );

  const typedSubscribeToMore: QueryLayersEventsByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryLayersEventsByIdDocument,
      variables: { zoomLayersEventsId } as QueryLayersEventsByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, layersEvents: query?.data?.zoomLayersEvents_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryLayersEventsByIdFn = (
  query: QueryLayersEventsByIdQuery | null | undefined
) => LayersEventsFragment | null | undefined;
type QueryLayersEventsByIdLazyFn = [
  (options?: QueryLazyOptions<QueryLayersEventsByIdQueryVariables> | undefined) => void,
  QueryLayersEventsByIdResult
];
type QueryLayersEventsByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryLayersEventsByIdQueryVariables>, 'variables'>
) => void;
export type QueryLayersEventsByIdLazyReturn = [QueryLayersEventsByIdWrappedLazyFn, QueryLayersEventsByIdResultEx];

// Function
function useQueryLayersEventsByIdLazy({
  zoomLayersEventsId,
  options,
}: {
  zoomLayersEventsId: string;
  options?: Omit<
    QueryHookOptions<QueryLayersEventsByIdQuery, QueryLayersEventsByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryLayersEventsByIdLazyReturn {
  const lazyQuery: QueryLayersEventsByIdLazyFn = useLazyQuery<
    QueryLayersEventsByIdQuery,
    QueryLayersEventsByIdQueryVariables
  >(QueryLayersEventsByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryLayersEventsById: PickQueryLayersEventsByIdFn = (query) => {
    return query && query.zoomLayersEvents_by_pk;
  };
  const wrappedLazyQuery: QueryLayersEventsByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryLayersEventsByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryLayersEventsByIdDocument,
        variables: { zoomLayersEventsId } as QueryLayersEventsByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      layersEvents: pickQueryLayersEventsById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryLayersEventsObjectsResult = LazyQueryResult<
  QueryLayersEventsObjectsQuery,
  QueryLayersEventsObjectsQueryVariables
>;
type QueryLayersEventsObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryLayersEventsObjectsQuery,
          QueryLayersEventsObjectsQueryVariables,
          QueryLayersEventsObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryLayersEventsObjectsResultEx = Omit<QueryLayersEventsObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryLayersEventsObjectsSubScribeToMore;
} & LayersEventsObjectsHookResultEx;

// Function
function useQueryLayersEventsObjects(
  options: Omit<QueryHookOptions<QueryLayersEventsObjectsQuery, QueryLayersEventsObjectsQueryVariables>, 'query'>
): QueryLayersEventsObjectsResultEx {
  const _query: QueryLayersEventsObjectsResult = useQuery<
    QueryLayersEventsObjectsQuery,
    QueryLayersEventsObjectsQueryVariables
  >(QueryLayersEventsObjectsDocument, options);

  const typedSubscribeToMore: QueryLayersEventsObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryLayersEventsObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.zoomLayersEvents || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryLayersEventsObjectsFn = (
  query: QueryLayersEventsObjectsQuery | null | undefined
) => LayersEventsFragment[];
type QueryLayersEventsObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryLayersEventsObjectsQueryVariables> | undefined) => void,
  QueryLayersEventsObjectsResult
];
type QueryLayersEventsObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryLayersEventsObjectsQueryVariables>
) => void;
export type QueryLayersEventsObjectsLazyReturn = [
  QueryLayersEventsObjectsWrappedLazyFn,
  QueryLayersEventsObjectsResultEx
];

// Function
function useQueryLayersEventsObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryLayersEventsObjectsQuery, QueryLayersEventsObjectsQueryVariables>, 'query'>
): QueryLayersEventsObjectsLazyReturn {
  const lazyQuery: QueryLayersEventsObjectsLazyFn = useLazyQuery<
    QueryLayersEventsObjectsQuery,
    QueryLayersEventsObjectsQueryVariables
  >(QueryLayersEventsObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryLayersEventsObjectsFn = (query: QueryLayersEventsObjectsQuery | null | undefined) => {
    return query?.zoomLayersEvents || [];
  };
  const wrappedLazyQuery: QueryLayersEventsObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryLayersEventsObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryLayersEventsObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToLayersEventsByIdResult = {
  variables?: SubscribeToLayersEventsByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToLayersEventsByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToLayersEventsByIdResultEx = SubscribeToLayersEventsByIdResult & LayersEventsByIdHookResultEx;

// Function
function useSubscribeToLayersEventsById({
  zoomLayersEventsId,
  options,
}: {
  zoomLayersEventsId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToLayersEventsByIdSubscription, SubscribeToLayersEventsByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToLayersEventsByIdResultEx {
  const subscription: SubscribeToLayersEventsByIdResult = useSubscription<
    SubscribeToLayersEventsByIdSubscription,
    SubscribeToLayersEventsByIdSubscriptionVariables
  >(SubscribeToLayersEventsByIdDocument, { variables: { zoomLayersEventsId }, ...options });
  return { ...subscription, layersEvents: subscription?.data?.zoomLayersEvents_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToLayersEventsObjectsResult = {
  variables?: SubscribeToLayersEventsObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToLayersEventsObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToLayersEventsObjectsResultEx = SubscribeToLayersEventsObjectsResult &
  LayersEventsObjectsHookResultEx;

// Function
function useSubscribeToLayersEventsObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToLayersEventsObjectsSubscription,
      SubscribeToLayersEventsObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToLayersEventsObjectsResultEx {
  const subscription: SubscribeToLayersEventsObjectsResult = useSubscription<
    SubscribeToLayersEventsObjectsSubscription,
    SubscribeToLayersEventsObjectsSubscriptionVariables
  >(SubscribeToLayersEventsObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.zoomLayersEvents || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertLayersEventsMutationResult = FetchResult<
  InsertLayersEventsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertLayersEventsMutationResultEx = InsertLayersEventsMutationResult & LayersEventsByIdHookResultEx;

type PickInsertLayersEventsFn = (
  mutation: InsertLayersEventsMutation | null | undefined
) => LayersEventsFragment | null | undefined;
type InsertLayersEventsLazyMutationFn = MutationTuple<InsertLayersEventsMutation, InsertLayersEventsMutationVariables>;
type InsertLayersEventsWrappedLazyMutationFn = ({
  zoomLayersEvents,
  autoOptimisticResponse,
  options,
}: {
  zoomLayersEvents: ZoomLayersEvents_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertLayersEventsMutation, InsertLayersEventsMutationVariables>, 'variables'>;
}) => Promise<InsertLayersEventsMutationResultEx>;
export type InsertLayersEventsLazyMutationReturn = [
  InsertLayersEventsWrappedLazyMutationFn,
  InsertLayersEventsMutationResultEx
];

// Function
function useInsertLayersEvents(
  options?: Omit<
    MutationHookOptions<InsertLayersEventsMutation, InsertLayersEventsMutationVariables>,
    'mutation' | 'variables'
  >
): InsertLayersEventsLazyMutationReturn {
  const lazyMutation: InsertLayersEventsLazyMutationFn = useMutation<
    InsertLayersEventsMutation,
    InsertLayersEventsMutationVariables
  >(InsertLayersEventsDocument, options);
  const pickLayersEvents: PickInsertLayersEventsFn = (mutation) => {
    return mutation?.insert_zoomLayersEvents?.returning && mutation?.insert_zoomLayersEvents?.returning[0];
  };

  const wrappedLazyMutation: InsertLayersEventsWrappedLazyMutationFn = async ({
    zoomLayersEvents,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomLayersEvents });
    const mutationOptions: MutationFunctionOptions<InsertLayersEventsMutation, InsertLayersEventsMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomLayersEvents'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertLayersEventsMutation>({
        operationType: 'insert',
        entityName: 'zoomLayersEvents',
        objects: [objectForInsert as ZoomLayersEvents_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, layersEvents: pickLayersEvents(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], layersEvents: pickLayersEvents(lazyMutation[1].data) }];
}

//
//

// Types
type InsertLayersEventsWithOnConflictMutationResult = FetchResult<
  InsertLayersEventsWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertLayersEventsWithOnConflictMutationResultEx = InsertLayersEventsWithOnConflictMutationResult &
  LayersEventsByIdHookResultEx;

type InsertLayersEventsWithOnConflictLazyMutationFn = MutationTuple<
  InsertLayersEventsWithOnConflictMutation,
  InsertLayersEventsWithOnConflictMutationVariables
>;
type InsertLayersEventsWithOnConflictWrappedLazyMutationFn = ({
  zoomLayersEvents,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  zoomLayersEvents: ZoomLayersEvents_Insert_Input;
  onConflict: ZoomLayersEvents_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertLayersEventsWithOnConflictMutation,
      InsertLayersEventsWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertLayersEventsWithOnConflictMutationResultEx>;
export type InsertLayersEventsWithOnConflictLazyMutationReturn = [
  InsertLayersEventsWithOnConflictWrappedLazyMutationFn,
  InsertLayersEventsWithOnConflictMutationResultEx
];

// Function
function useInsertLayersEventsWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertLayersEventsWithOnConflictMutation, InsertLayersEventsWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertLayersEventsWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertLayersEventsWithOnConflictLazyMutationFn = useMutation<
    InsertLayersEventsWithOnConflictMutation,
    InsertLayersEventsWithOnConflictMutationVariables
  >(InsertLayersEventsWithOnConflictDocument, options);
  const pickLayersEvents: PickInsertLayersEventsFn = (
    mutation: InsertLayersEventsWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_zoomLayersEvents?.returning && mutation.insert_zoomLayersEvents.returning[0];
  };

  const wrappedLazyMutation: InsertLayersEventsWithOnConflictWrappedLazyMutationFn = async ({
    zoomLayersEvents,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: zoomLayersEvents });
    const mutationOptions: MutationFunctionOptions<
      InsertLayersEventsWithOnConflictMutation,
      InsertLayersEventsWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'zoomLayersEvents'`);
      mutationOptions.optimisticResponse =
        generateOptimisticResponseForMutation<InsertLayersEventsWithOnConflictMutation>({
          operationType: 'insert',
          entityName: 'zoomLayersEvents',
          objects: [objectForInsert as ZoomLayersEvents_Insert_Input & ObjectWithId],
        });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, layersEvents: pickLayersEvents(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], layersEvents: pickLayersEvents(lazyMutation[1].data) }];
}

// Types
type InsertLayersEventsObjectsMutationResult = FetchResult<
  InsertLayersEventsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertLayersEventsObjectsMutationResultEx = InsertLayersEventsMutationResult &
  LayersEventsObjectsHookResultEx;

type PickInsertLayersEventsObjectsFn = (
  mutation: InsertLayersEventsMutation | null | undefined
) => LayersEventsFragment[];
type InsertLayersEventsObjectsLazyMutationFn = MutationTuple<
  InsertLayersEventsMutation,
  InsertLayersEventsMutationVariables
>;
type InsertLayersEventsObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertLayersEventsMutation, InsertLayersEventsMutationVariables>
) => Promise<InsertLayersEventsObjectsMutationResultEx>;
export type InsertLayersEventsObjectsLazyMutationReturn = [
  InsertLayersEventsObjectsWrappedLazyMutationFn,
  InsertLayersEventsObjectsMutationResultEx
];

// Function
function useInsertLayersEventsObjects(
  options?: Omit<MutationHookOptions<InsertLayersEventsMutation, InsertLayersEventsMutationVariables>, 'mutation'>
): InsertLayersEventsObjectsLazyMutationReturn {
  const lazyMutation: InsertLayersEventsObjectsLazyMutationFn = useMutation<
    InsertLayersEventsMutation,
    InsertLayersEventsMutationVariables
  >(InsertLayersEventsDocument, options);
  const pickObjects: PickInsertLayersEventsObjectsFn = (mutation: InsertLayersEventsMutation | null | undefined) => {
    return mutation?.insert_zoomLayersEvents?.returning || [];
  };

  const wrappedLazyMutation: InsertLayersEventsObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects) {
      const o = Array.isArray(options.variables.objects) ? options.variables.objects : [options.variables.objects];
      o.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
    }
    const fetchResult: InsertLayersEventsObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateLayersEventsByIdMutationResult = FetchResult<
  UpdateLayersEventsByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateLayersEventsByIdMutationResultEx = UpdateLayersEventsByIdMutationResult &
  LayersEventsByIdHookResultEx;

type PickUpdateLayersEventsByIdFn = (
  mutation: UpdateLayersEventsByIdMutation | null | undefined
) => LayersEventsFragment | null | undefined;
type UpdateLayersEventsByIdLazyMutationFn = MutationTuple<
  UpdateLayersEventsByIdMutation,
  UpdateLayersEventsByIdMutationVariables
>;
type UpdateLayersEventsByIdWrappedLazyMutationFn = ({
  zoomLayersEventsId,
  set,
  autoOptimisticResponse,
  options,
}: {
  zoomLayersEventsId: string;
  set: ZoomLayersEvents_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateLayersEventsByIdMutation, UpdateLayersEventsByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateLayersEventsByIdMutationResultEx>;
export type UpdateLayersEventsByIdLazyMutationReturn = [
  UpdateLayersEventsByIdWrappedLazyMutationFn,
  UpdateLayersEventsByIdMutationResultEx
];

function useUpdateLayersEventsById(
  options?: Omit<
    MutationHookOptions<UpdateLayersEventsByIdMutation, UpdateLayersEventsByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateLayersEventsByIdLazyMutationReturn {
  const lazyMutation: UpdateLayersEventsByIdLazyMutationFn = useMutation<
    UpdateLayersEventsByIdMutation,
    UpdateLayersEventsByIdMutationVariables
  >(UpdateLayersEventsByIdDocument, options);

  const pickLayersEvents: PickUpdateLayersEventsByIdFn = (mutation) => {
    return mutation?.update_zoomLayersEvents?.returning && mutation.update_zoomLayersEvents!.returning[0];
  };

  const wrappedLazyMutation: UpdateLayersEventsByIdWrappedLazyMutationFn = async ({
    zoomLayersEventsId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateLayersEventsByIdMutation,
      UpdateLayersEventsByIdMutationVariables
    > = { variables: { id: zoomLayersEventsId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateLayersEventsByIdMutation>({
        operationType: 'update',
        entityName: 'zoomLayersEvents',
        objects: [{ id: zoomLayersEventsId, ...set }],
      });
    }

    const fetchResult: UpdateLayersEventsByIdMutationResult = await lazyMutation[0]({
      variables: { id: zoomLayersEventsId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, layersEvents: pickLayersEvents(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], layersEvents: pickLayersEvents(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateLayersEventsObjectsMutationResult = FetchResult<
  UpdateLayersEventsMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateLayersEventsObjectsMutationResultEx = UpdateLayersEventsObjectsMutationResult &
  LayersEventsObjectsHookResultEx;

// Function
type PickUpdateLayersEventsObjectsFn = (
  mutation: UpdateLayersEventsMutation | null | undefined
) => LayersEventsFragment[];
type UpdateLayersEventsObjectsLazyMutationFn = MutationTuple<
  UpdateLayersEventsMutation,
  UpdateLayersEventsMutationVariables
>;
type UpdateLayersEventsObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateLayersEventsMutation, UpdateLayersEventsMutationVariables>
) => Promise<UpdateLayersEventsObjectsMutationResultEx>;
export type UpdateLayersEventsObjectsLazyMutationReturn = [
  UpdateLayersEventsObjectsWrappedLazyMutationFn,
  UpdateLayersEventsObjectsMutationResultEx
];

function useUpdateLayersEventsObjects(
  options?: Omit<MutationHookOptions<UpdateLayersEventsMutation, UpdateLayersEventsMutationVariables>, 'mutation'>
): UpdateLayersEventsObjectsLazyMutationReturn {
  const lazyMutation: UpdateLayersEventsObjectsLazyMutationFn = useMutation<
    UpdateLayersEventsMutation,
    UpdateLayersEventsMutationVariables
  >(UpdateLayersEventsDocument, options);

  const pickObjects: PickUpdateLayersEventsObjectsFn = (mutation: UpdateLayersEventsMutation | null | undefined) => {
    return mutation?.update_zoomLayersEvents?.returning || [];
  };

  const wrappedLazyMutation: UpdateLayersEventsObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateLayersEventsObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveZoomLayersEventsModelByIdFetchResult = FetchResult<
  RemoveZoomLayersEventsModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomLayersEventsModelByIdMutationResultEx = RemoveZoomLayersEventsModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomLayersEventsModelFn = (
  mutation: RemoveZoomLayersEventsModelByIdMutation | null | undefined
) => number;
type RemoveZoomLayersEventsModelLazyMutationFn = MutationTuple<
  RemoveZoomLayersEventsModelByIdMutation,
  RemoveZoomLayersEventsModelByIdMutationVariables
>;
type RemoveZoomLayersEventsModelWrappedLazyMutationFn = ({
  zoomLayersEventsId,
  autoOptimisticResponse,
  options,
}: {
  zoomLayersEventsId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveZoomLayersEventsModelByIdMutation, RemoveZoomLayersEventsModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveZoomLayersEventsModelByIdMutationResultEx>;
export type RemoveZoomLayersEventsModelLazyMutationReturn = [
  RemoveZoomLayersEventsModelWrappedLazyMutationFn,
  RemoveZoomLayersEventsModelByIdMutationResultEx
];

function useRemoveZoomLayersEventsModelById(
  options?: Omit<
    MutationHookOptions<RemoveZoomLayersEventsModelByIdMutation, RemoveZoomLayersEventsModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveZoomLayersEventsModelLazyMutationFn = useMutation<
    RemoveZoomLayersEventsModelByIdMutation,
    RemoveZoomLayersEventsModelByIdMutationVariables
  >(RemoveZoomLayersEventsModelByIdDocument, options);

  const pickAffectedRows: PickRemoveZoomLayersEventsModelFn = (
    mutation: RemoveZoomLayersEventsModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_zoomLayersEvents?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomLayersEventsModelWrappedLazyMutationFn = async ({
    zoomLayersEventsId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveZoomLayersEventsModelMutation,
      RemoveZoomLayersEventsModelByIdMutationVariables
    > = { variables: { id: zoomLayersEventsId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveZoomLayersEventsModelMutation>({
        operationType: 'delete',
        entityName: 'zoomLayersEvents',
        objects: [{ id: zoomLayersEventsId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveZoomLayersEventsModelByIdMutation>({
        operationType: 'delete',
        entityName: 'zoomLayersEvents',
        entityId: zoomLayersEventsId,
      });
    }

    const fetchResult: RemoveZoomLayersEventsModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveZoomLayersEventsModelObjectsMutationResult = FetchResult<
  RemoveZoomLayersEventsModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveZoomLayersEventsModelObjectsMutationResultEx = RemoveZoomLayersEventsModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveZoomLayersEventsModelObjectsFn = (
  mutation: RemoveZoomLayersEventsModelMutation | null | undefined
) => number;
type RemoveZoomLayersEventsModelObjectsLazyMutationFn = MutationTuple<
  RemoveZoomLayersEventsModelMutation,
  RemoveZoomLayersEventsModelMutationVariables
>;
type RemoveZoomLayersEventsModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveZoomLayersEventsModelMutation, RemoveZoomLayersEventsModelMutationVariables>
) => Promise<RemoveZoomLayersEventsModelObjectsMutationResultEx>;
export type RemoveZoomLayersEventsModelObjectsLazyMutationReturn = [
  RemoveZoomLayersEventsModelObjectsWrappedLazyMutationFn,
  RemoveZoomLayersEventsModelObjectsMutationResultEx
];

function useRemoveZoomLayersEventsModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveZoomLayersEventsModelMutation, RemoveZoomLayersEventsModelMutationVariables>,
    'mutation'
  >
): RemoveZoomLayersEventsModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveZoomLayersEventsModelMutation, RemoveZoomLayersEventsModelMutationVariables>(
    RemoveZoomLayersEventsModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveZoomLayersEventsModelObjectsFn = (
    mutation: RemoveZoomLayersEventsModelMutation | null | undefined
  ) => {
    return mutation?.delete_zoomLayersEvents?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveZoomLayersEventsModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveZoomLayersEventsModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// LayersEvents Fragment Helper Object
//------------------------------------------------

export const LayersEventsFragmentGQLHooks = {
  useQueryById: useQueryLayersEventsById,
  useQueryByIdLazy: useQueryLayersEventsByIdLazy,
  useQueryObjects: useQueryLayersEventsObjects,
  useQueryObjectsLazy: useQueryLayersEventsObjectsLazy,
  useSubscribeToById: useSubscribeToLayersEventsById,
  useSubscribeToObjects: useSubscribeToLayersEventsObjects,
  useInsert: useInsertLayersEvents,
  useInsertWithOnConflict: useInsertLayersEventsWithOnConflict,
  useInsertObjects: useInsertLayersEventsObjects,
  useUpdateById: useUpdateLayersEventsById,
  useUpdateObjects: useUpdateLayersEventsObjects,
};

// zoomLayersEvents MODEL HOOKS OBJECT
//------------------------------------------------

export const ZoomLayersEventsModelGQLHooks = {
  useRemoveById: useRemoveZoomLayersEventsModelById,
  useRemoveObjects: useRemoveZoomLayersEventsModelObjects,
};

/*
 * COMBINED HOOKS OBJECT
 */
export const GQLHooks = {
  Fragments: {
    ZoomContact: ZoomContactFragmentGQLHooks,
    ZoomCompany: ZoomCompanyFragmentGQLHooks,
    ZoomGroup: ZoomGroupFragmentGQLHooks,
    ZoomContactWithCompany: ZoomContactWithCompanyFragmentGQLHooks,
    ZoomUserActions: ZoomUserActionsFragmentGQLHooks,
    ZoomGroupMembership: ZoomGroupMembershipFragmentGQLHooks,
    ZoomUser: ZoomUserFragmentGQLHooks,
    ZoomContactNotes: ZoomContactNotesFragmentGQLHooks,
    GoogleChannel: GoogleChannelFragmentGQLHooks,
    ZoomEmailHistory: ZoomEmailHistoryFragmentGQLHooks,
    ZoomCustomBackground: ZoomCustomBackgroundFragmentGQLHooks,
    ZoomGlobalCustomBackground: ZoomGlobalCustomBackgroundFragmentGQLHooks,
    ZoomConnectionRequest: ZoomConnectionRequestFragmentGQLHooks,
    ZoomConnectionRequestReminder: ZoomConnectionRequestReminderFragmentGQLHooks,
    ZoomApprovedDomain: ZoomApprovedDomainFragmentGQLHooks,
    ZoomConversationStarterAnswer: ZoomConversationStarterAnswerFragmentGQLHooks,
    ZoomConversationStarterPrompt: ZoomConversationStarterPromptFragmentGQLHooks,
    Content: ContentFragmentGQLHooks,
    ZoomSettings: ZoomSettingsFragmentGQLHooks,
    ZoomPresentation: ZoomPresentationFragmentGQLHooks,
    GrowthUserReferralContact: GrowthUserReferralContactFragmentGQLHooks,
    LayersEvents: LayersEventsFragmentGQLHooks,
  },
  Models: {
    ZoomContact: ZoomContactModelGQLHooks,
    ZoomCompany: ZoomCompanyModelGQLHooks,
    ZoomGroup: ZoomGroupModelGQLHooks,
    ZoomUserActions: ZoomUserActionsModelGQLHooks,
    ZoomGroupMembership: ZoomGroupMembershipModelGQLHooks,
    ZoomUser: ZoomUserModelGQLHooks,
    ZoomContactNotes: ZoomContactNotesModelGQLHooks,
    GoogleChannel: GoogleChannelModelGQLHooks,
    ZoomEmailHistory: ZoomEmailHistoryModelGQLHooks,
    ZoomCustomBackground: ZoomCustomBackgroundModelGQLHooks,
    ZoomConnectionRequest: ZoomConnectionRequestModelGQLHooks,
    ZoomConnectionRequestReminder: ZoomConnectionRequestReminderModelGQLHooks,
    ZoomApprovedDomain: ZoomApprovedDomainModelGQLHooks,
    ZoomConversationStarterAnswer: ZoomConversationStarterAnswerModelGQLHooks,
    ZoomConversationStarterPrompt: ZoomConversationStarterPromptModelGQLHooks,
    Content: ContentModelGQLHooks,
    ZoomSettings: ZoomSettingsModelGQLHooks,
    ZoomPresentation: ZoomPresentationModelGQLHooks,
    GrowthUserReferralContact: GrowthUserReferralContactModelGQLHooks,
    ZoomLayersEvents: ZoomLayersEventsModelGQLHooks,
  },
};
