/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export interface LayersStoreState {
  lastUpdate: number;
  immersiveLayersEnabled: boolean;
  showConfetti: boolean;
  showDebug: boolean;
  showEvents: boolean;
  showFireworks: boolean;
  showNametag: boolean;
  showWidgets: boolean;
  showReactions: boolean;
  showSnowfall: boolean;
  selectedCameraLayersToggle: boolean;
  cameraLayersEnabled: boolean;
  wasManualToggle: boolean;
  hasLayersError: boolean;
}

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const defaultLayersStoreState: LayersStoreState = {
  lastUpdate: Date.now(),
  cameraLayersEnabled: false,
  immersiveLayersEnabled: false,
  showConfetti: false,
  showDebug: false,
  showEvents: false,
  showFireworks: false,
  showNametag: true,
  showWidgets: true,
  showReactions: false,
  showSnowfall: false,
  selectedCameraLayersToggle: true,
  hasLayersError: false,
  wasManualToggle: false,
};

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class LayersStore implements LayersStoreState {
  lastUpdate: number = defaultLayersStoreState.lastUpdate;
  cameraLayersEnabled: boolean = defaultLayersStoreState.cameraLayersEnabled;
  immersiveLayersEnabled: boolean = defaultLayersStoreState.immersiveLayersEnabled;
  showConfetti: boolean = defaultLayersStoreState.showConfetti;
  showDebug: boolean = defaultLayersStoreState.showDebug;
  showEvents: boolean = defaultLayersStoreState.showEvents;
  showFireworks: boolean = defaultLayersStoreState.showFireworks;
  showNametag: boolean = defaultLayersStoreState.showNametag;
  showWidgets: boolean = defaultLayersStoreState.showWidgets;
  showReactions: boolean = defaultLayersStoreState.showReactions;
  showSnowfall: boolean = defaultLayersStoreState.showSnowfall;
  selectedCameraLayersToggle: boolean = defaultLayersStoreState.selectedCameraLayersToggle;
  hasLayersError: boolean = defaultLayersStoreState.hasLayersError;
  wasManualToggle: boolean = defaultLayersStoreState.wasManualToggle;

  setCameraLayersEnabled = (cameraLayersEnabled: boolean) => {
    this.cameraLayersEnabled = cameraLayersEnabled;
    this.lastUpdate = Date.now();
  };

  setImmersiveLayersEnabled = (immersiveLayersEnabled: boolean) => {
    this.immersiveLayersEnabled = immersiveLayersEnabled;
  };

  setShowConfetti = (showConfetti: boolean) => {
    this.showConfetti = showConfetti;
  };

  /**
   * @example
   * Console command to show on camera layer:
   * ```
   * zoomSdk.postMessage({debug: true})
   * ```
   */
  setShowDebug = (showDebug: boolean) => {
    this.showDebug = showDebug;
  };

  /**
   * @example
   * Console command to show on camera layer:
   * ```
   * zoomSdk.postMessage({events: true})
   * ```
   */
  setShowEvents = (showEvents: boolean) => {
    this.showEvents = showEvents;
  };

  setShowFireworks = (showFireworks: boolean) => {
    this.showFireworks = showFireworks;
  };

  /**
   * @example
   * Console command to show on camera layer:
   * ```
   * zoomSdk.postMessage({nametag: true})
   * ```
   */
  setShowNametag = (showNametag: boolean) => {
    this.showNametag = showNametag;
  };

  setShowWidgets = (showWidgets: boolean) => {
    this.showWidgets = showWidgets;
  };

  /**
   * @example
   * Console command to show on camera layer:
   * ```
   * zoomSdk.postMessage({reactions: true})
   * ```
   */
  setShowReactions = (showReactions: boolean) => {
    this.showReactions = showReactions;
  };

  setShowSnowfall = (showSnowfall: boolean) => {
    this.showSnowfall = showSnowfall;
  };

  setSelectedCameraLayersToggle = (selectedCameraLayersToggle: boolean) => {
    this.selectedCameraLayersToggle = selectedCameraLayersToggle;
  };

  setHasLayersError = (hasLayersError: boolean) => {
    this.hasLayersError = hasLayersError;
  };

  setWasManualToggle = (wasManualToggle: boolean) => {
    this.wasManualToggle = wasManualToggle;
  };

  setLayersStore = (layersStore: LayersStoreState) => {
    for (const key of Object.keys(layersStore)) {
      const storeValue = layersStore[key];
      if (typeof storeValue !== 'function') {
        this[key] = layersStore[key];
      }
    }
  };

  constructor(disablePersistence: boolean) {
    makeAutoObservable(this);
    !disablePersistence &&
      makePersistable(this, {
        name: 'LayersStore',
        properties: ['selectedCameraLayersToggle', 'wasManualToggle'],
        storage: window.localStorage,
      });
  }
}
