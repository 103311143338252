/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* --------------------------------- CUSTOM --------------------------------- */
import { RequestStatus } from 'src/components/KeepInTouchButton/KeepInTouchButton';
import { RsvpStatus } from 'src/components/RsvpPill/RsvpPill';
import {
  ZoomCompany,
  ZoomCompanyFragment,
  ZoomContactWithCompanyFragment,
  ZoomGroupFragment,
  ZoomUser_Set_Input,
  ZoomUser_Update_Column,
  ZoomUserFragment,
  ZoomUserNetwork,
} from 'src/graphql';
import {
  BIO_PLACEHOLDER,
  COMPANY_PLACEHOLDER,
  LINKEDIN_PLACEHOLDER,
  LOCATION_PLACEHOLDER,
  NAME_PLACEHOLDER,
  PRONOUNS_PLACEHOLDER,
  PRONUNCIATION_PLACEHOLDER,
  TITLE_PLACEHOLDER,
  UserOnboardType,
} from 'src/utils/constants';
import { parseDomainFromEmail } from 'src/utils/functions/functions';
import { ZoomUserBackgroundVisibility } from 'src/utils/profile/backgroundVisibility';
import { ZoomUserNametagSettings } from 'src/utils/profile/nametagSettings';
import { getProxiedUrl } from 'src/utils/proxy';
import { getLocationStringFromProfileData } from 'src/utils/user';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
// TODO: clean up/improve the typing for ProfileViewData
// e.g. maybe not all fields are needed
export type ProfileViewData = {
  zoomUserId?: string;
  zoomContactId?: string;
  publicProfileDisabledAt?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  firstNamePronunciation?: string;
  lastNamePronunciation?: string;
  pronouns?: string;
  email: string;
  headline?: string;
  ask?: string;
  title?: string;
  department?: string;
  startDate?: string;
  strengths?: string;
  photoUrl?: string;
  city?: string;
  state?: string;
  country?: string;
  location?: ZoomUserLocation | ZoomContactLocation | null;
  linkedInUrl?: string;
  twitterUrl?: string;
  scheduleMeetingUrl?: string;
  updatedAt?: string;
  zoomCompanyId?: string | null;
  zoomCompany?: Partial<ZoomCompany> | null;
  company?: Partial<ZoomCompany> | null;
  companyName?: string;
  companyWebsiteDomain?: string;
  companyEstimatedSize?: string;
  companyCity?: string;
  companyState?: string;
  companyLinkedInUrl?: string;
  companyCrunchbaseUrl?: string;
  companySeoDescription?: string;
  companyDescription?: string;
  companyLogoUrl?: string;
  logoUrl?: string;
  isWarmlyUser?: boolean;
  customBackgroundPhotoUrl?: string;
  defaultCustomBackgroundPhotoUrl?: string;
  rsvpStatus?: RsvpStatus;
  requestStatus?: RequestStatus;
  backgroundVisibility?: ZoomUserBackgroundVisibility;
  locationText?: string;
  addedToVideoConversationStarterPromptId?: string;
  onboardingType?: UserOnboardType;
  lastJoinedMeetingUuid?: string;
  organizationId?: string;
  nametagSettings?: ZoomUserNametagSettings;
};

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/**
 * Gets ProfileViewData from a given zoomUser and zoomContact
 *
 * Note that this should not be used for non-Warmly users (i.e. localStorage profiles)
 *
 * This data should be used for reading
 * @param params.zoomUser The Zoom user
 * @param params.zoomContact The Zoom contact
 * @param params.publicFieldsOnly Whether to show PUBLIC fields only (based on user's profile visibility settings)
 * @returns The ProfileData object
 */
export const getProfileViewData = ({
  zoomUser,
  zoomContact,
  zoomCompany: _zoomCompany,
  zoomGroup,
}: {
  zoomUser?: ZoomUserFragment | null;
  zoomContact?: ZoomContactWithCompanyFragment;
  zoomCompany?: ZoomCompanyFragment;
  zoomGroup?: ZoomGroupFragment;
}): ProfileViewData => {
  const zoomCompany = _zoomCompany || zoomUser?.zoomCompany || zoomContact?.zoomCompany;
  /**
   * Fallback logic: 1) User inputted logo 2) zoomCompany scraped logo (if the zoomUser is associated with a zoomCompanyId)
   */
  const companyLogoUrl = zoomUser?.companyLogoUrl || (zoomUser?.zoomCompanyId ? zoomCompany?.logoUrl : undefined);
  const photoUrl = zoomUser?.photoUrl || (zoomContact?.photoUrl === 'error' ? undefined : zoomContact?.photoUrl);

  const profilePhotoUrlForDisplay = getProxiedUrl(photoUrl);

  const groupLogoUrl = zoomGroup?.logoUrl;

  const logoUrl = groupLogoUrl || companyLogoUrl;

  const logoUrlForDisplay = getProxiedUrl(logoUrl);

  const locationObj = {
    city: zoomUser?.city || zoomContact?.city || undefined,
    state: zoomUser?.state || zoomContact?.state || undefined,
    country: zoomUser?.country || zoomContact?.country || undefined,
    location: (zoomUser?.location || zoomContact?.location) as ZoomUserLocation | null | undefined,
  };
  const locationText = getLocationStringFromProfileData(locationObj) || undefined;

  const profileData: ProfileViewData = {
    /* --------------------------- Basic Profile Data --------------------------- */
    zoomUserId: zoomUser?.id,
    zoomContactId: zoomContact?.id,
    email: (zoomUser?.email || zoomContact?.email) as string, // zoomContact?.email should always be the same as zoomUser.email
    firstName: zoomUser ? zoomUser.firstName : zoomContact?.firstName || undefined,
    lastName: zoomUser ? zoomUser?.lastName : zoomContact?.lastName || undefined,
    firstNamePronunciation: zoomUser?.firstNamePronunciation || undefined,
    lastNamePronunciation: zoomUser?.lastNamePronunciation || undefined,
    username: zoomUser?.username || undefined,
    pronouns: zoomUser?.pronouns || undefined,
    headline: zoomUser?.headline || zoomContact?.headline || undefined,
    ask: zoomUser?.ask || undefined,
    photoUrl: profilePhotoUrlForDisplay || undefined,
    isWarmlyUser: Boolean(zoomUser),
    updatedAt: zoomUser?.profileUpdatedAt || undefined,
    onboardingType: (zoomUser?.onboardingType as UserOnboardType) || UserOnboardType.Default,
    publicProfileDisabledAt: zoomContact?.publicProfileDisabledAt || undefined,
    organizationId: zoomUser?.zoomOrganizationMembership?.organization.id,

    /* -------------------------------- Location -------------------------------- */
    ...locationObj,
    locationText,

    /* --------------------------------- Social --------------------------------- */
    linkedInUrl: zoomUser?.linkedInUrl || zoomContact?.linkedInUrl || undefined,
    twitterUrl: zoomUser?.twitterUrl || zoomContact?.twitterUrl || undefined,
    scheduleMeetingUrl: zoomUser?.scheduleMeetingUrl || undefined,

    /* ------------------------------ Job / Company ----------------------------- */
    title: zoomUser?.title || zoomContact?.title || undefined,
    department: zoomUser?.department || undefined,
    startDate: zoomUser?.startDate || undefined,
    zoomCompanyId: zoomUser?.zoomCompanyId ? undefined : zoomCompany?.id,
    zoomCompany,
    company: zoomCompany,
    companyName: zoomGroup?.displayName || zoomGroup?.name || zoomUser?.companyName || zoomCompany?.name || undefined,
    companyLogoUrl: logoUrlForDisplay || undefined,
    companyWebsiteDomain: zoomUser?.companyWebsiteDomain || zoomCompany?.websiteDomain || undefined,
    companyEstimatedSize: zoomCompany?.estimatedSize || undefined,
    companyCity: zoomCompany?.city || undefined,
    companyState: zoomCompany?.state || undefined,
    companyLinkedInUrl: zoomCompany?.linkedInUrl || undefined,
    companyCrunchbaseUrl: zoomCompany?.crunchbaseUrl || undefined,
    companySeoDescription: zoomCompany?.seoDescription || undefined,
    companyDescription: zoomCompany?.description || undefined,
    logoUrl: logoUrlForDisplay || undefined,
    strengths: zoomUser?.strengths || undefined,

    /* ------------------------------- Backgrounds ------------------------------ */
    customBackgroundPhotoUrl: zoomUser?.customBackgroundPhotoUrl || undefined,
    defaultCustomBackgroundPhotoUrl: zoomUser?.defaultCustomBackgroundPhotoUrl || undefined,
    backgroundVisibility: zoomUser?.backgroundVisibility as ZoomUserBackgroundVisibility,
    addedToVideoConversationStarterPromptId: zoomUser?.addedToVideoConversationStarterPromptId || undefined,

    /* ------------------------------- Settings ------------------------------ */
    nametagSettings: zoomUser?.nametagSettings as ZoomUserNametagSettings,
  };

  return profileData;
};

/**
 * Gets ProfileData from the provided userNetwork
 * @param userNetwork The ZoomUserNetwork to convert
 * @returns The ProfileData object
 */
export const getProfileDataFromUserNetwork = (userNetwork: ZoomUserNetwork): ProfileViewData => {
  const photoUrl = userNetwork.zoomUserPhotoUrl || userNetwork.zoomContactPhotoUrl;
  /**
   * Fallback logic: 1) User inputted logo 2) User inputted company domain but no user inputted logo 3) Scraped logo
   */
  const logoUrl = userNetwork?.zoomUserCompanyLogoUrl
    ? userNetwork?.zoomUserCompanyLogoUrl
    : userNetwork?.zoomUserCompanyWebsiteDomain
    ? undefined
    : userNetwork.zoomUserZoomCompanyLogoUrl || userNetwork.zoomContactZoomCompanyLogoUrl;
  const locationObj = {
    location: (userNetwork.zoomUserLocation || userNetwork.zoomContactLocation) as ZoomUserLocation,
    city: userNetwork.zoomUserCity || userNetwork.zoomContactCity || '',
    state: userNetwork.zoomUserState || userNetwork.zoomContactState || '',
    country: userNetwork.zoomUserCountry || userNetwork.zoomContactCountry || '',
  };
  const locationText = getLocationStringFromProfileData(locationObj);

  const profilePhotoUrlForDisplay = getProxiedUrl(photoUrl);
  const logoUrlForDisplay = getProxiedUrl(logoUrl);

  const companyObj = getCompanyObj(userNetwork);
  return {
    zoomUserId: userNetwork.zoomUserNetworkZoomUserId || '',
    zoomContactId: userNetwork.zoomContactId || '',
    firstName: userNetwork.zoomUserFirstName || userNetwork.zoomContactFirstName || '',
    lastName: userNetwork.zoomUserLastName || userNetwork.zoomContactLastName || '',
    firstNamePronunciation: userNetwork.zoomUserFirstNamePronunciation || '',
    lastNamePronunciation: userNetwork.zoomUserLastNamePronunciation || '',
    pronouns: userNetwork.zoomUserPronouns || '',
    email: userNetwork.email || '',
    headline: userNetwork.zoomUserHeadline || '',
    ask: userNetwork.zoomUserAsk || '',
    title: userNetwork.zoomUserTitle || userNetwork.zoomContactTitle || '',
    startDate: userNetwork.zoomUserStartDate || '',
    strengths: userNetwork.zoomUserStrengths || '',
    photoUrl: profilePhotoUrlForDisplay || '',
    ...locationObj,
    locationText,
    linkedInUrl: userNetwork.zoomUserLinkedInUrl || userNetwork.zoomContactLinkedInUrl || '',
    twitterUrl: userNetwork.zoomUserTwitterUrl || userNetwork.zoomContactTwitterUrl || '',
    scheduleMeetingUrl: userNetwork.zoomUserScheduleMeetingUrl || '',
    zoomCompany: companyObj,
    company: companyObj,
    companyName:
      userNetwork.zoomUserCompanyName ||
      userNetwork.zoomUserZoomCompanyName ||
      userNetwork.zoomContactZoomCompanyName ||
      '',
    companyWebsiteDomain:
      userNetwork.zoomUserCompanyWebsiteDomain ||
      userNetwork.zoomUserZoomCompanyWebsiteDomain ||
      userNetwork.zoomContactZoomCompanyWebsiteDomain ||
      '',
    companyEstimatedSize:
      userNetwork.zoomUserZoomCompanyEstimatedSize || userNetwork.zoomContactZoomCompanyEstimatedSize || '',
    companyCity: userNetwork.zoomUserZoomCompanyCity || userNetwork.zoomContactZoomCompanyCity || '',
    companyState: userNetwork.zoomUserZoomCompanyState || userNetwork.zoomContactZoomCompanyState || '',
    companyLinkedInUrl:
      userNetwork.zoomUserZoomCompanyLinkedInUrl || userNetwork.zoomContactZoomCompanyLinkedInUrl || '',
    companyCrunchbaseUrl:
      userNetwork.zoomUserZoomCompanyCrunchbaseUrl || userNetwork.zoomContactZoomCompanyCrunchbaseUrl || '',
    companySeoDescription:
      userNetwork.zoomUserZoomCompanySeoDescription || userNetwork.zoomContactZoomCompanySeoDescription || '',
    companyDescription:
      userNetwork.zoomUserZoomCompanyDescription || userNetwork.zoomContactZoomCompanyDescription || '',
    logoUrl: logoUrlForDisplay || '',
    companyLogoUrl: logoUrlForDisplay || undefined,
    isWarmlyUser: Boolean(userNetwork.zoomUserNetworkZoomUserId),
    username: userNetwork.username || '',
  };
};

/**
 * Get name given a participant
 * Absent name fall back on email
 * @param profile The participant
 * @returns The full name or email
 */
export const getName = (profile: ProfileViewData): string => {
  return profile.firstName
    ? `${profile.firstName}${profile.lastName ? ` ${profile.lastName}` : ''}`
    : profile.email || '';
};

/**
 * Gets whether the zoomUser's profile needs to be reinjected to calendar events
 * @param updatedProfile The updated profile to check for changes
 * @param currentUserData The existing profile to check against
 * @returns Whether the profile needs to be reinjected
 */
export const getShouldReinjectZoomUserProfile = (
  updatedProfile: ZoomUser_Set_Input,
  currentUserData: ZoomUserFragment
): boolean => {
  // Request reinject for enriched events if one of the following fields are updated
  const profileFieldsRequiringReinjection: ZoomUser_Update_Column[] = [
    ZoomUser_Update_Column.FirstName,
    ZoomUser_Update_Column.LastName,
    ZoomUser_Update_Column.Title,
    ZoomUser_Update_Column.Ask,
    ZoomUser_Update_Column.LinkedInUrl,
  ];

  for (const profileField of profileFieldsRequiringReinjection) {
    if (updatedProfile[profileField] !== currentUserData[profileField]) {
      return true;
    }
  }

  return false;
};

const getCompanyObj = (userNetwork: ZoomUserNetwork): Partial<ZoomCompany> => {
  const logoUrl = userNetwork?.zoomUserCompanyLogoUrl
    ? userNetwork?.zoomUserCompanyLogoUrl
    : userNetwork?.zoomUserCompanyWebsiteDomain
    ? undefined
    : userNetwork.zoomUserZoomCompanyLogoUrl || userNetwork.zoomContactZoomCompanyLogoUrl;
  const logoUrlForDisplay = getProxiedUrl(logoUrl);

  return {
    name:
      userNetwork.zoomUserCompanyName ||
      userNetwork.zoomUserZoomCompanyName ||
      userNetwork.zoomContactZoomCompanyName ||
      '',
    websiteDomain:
      userNetwork.zoomUserCompanyWebsiteDomain ||
      userNetwork.zoomUserZoomCompanyWebsiteDomain ||
      userNetwork.zoomContactZoomCompanyWebsiteDomain ||
      '',
    estimatedSize:
      userNetwork.zoomUserZoomCompanyEstimatedSize || userNetwork.zoomContactZoomCompanyEstimatedSize || '',
    city: userNetwork.zoomUserZoomCompanyCity || userNetwork.zoomContactZoomCompanyCity || '',
    state: userNetwork.zoomUserZoomCompanyState || userNetwork.zoomContactZoomCompanyState || '',
    linkedInUrl: userNetwork.zoomUserZoomCompanyLinkedInUrl || userNetwork.zoomContactZoomCompanyLinkedInUrl || '',
    crunchbaseUrl:
      userNetwork.zoomUserZoomCompanyCrunchbaseUrl || userNetwork.zoomContactZoomCompanyCrunchbaseUrl || '',
    seoDescription:
      userNetwork.zoomUserZoomCompanySeoDescription || userNetwork.zoomContactZoomCompanySeoDescription || '',
    description: userNetwork.zoomUserZoomCompanyDescription || userNetwork.zoomContactZoomCompanyDescription || '',
    logoUrl: logoUrlForDisplay,
  };
};

/**
 * Fill in empty profile fields with placeholder text
 * @param profileViewData The profile
 * @returns The profile with placeholder text in place of empty fields
 */
export const getPlaceholderValues = (profileViewData?: ProfileViewData) => {
  if (!profileViewData) {
    return;
  }

  return {
    ...profileViewData,
    firstName: profileViewData.firstName || profileViewData.lastName ? profileViewData.firstName : NAME_PLACEHOLDER,
    pronouns: profileViewData.pronouns || PRONOUNS_PLACEHOLDER,
    title: profileViewData.title || TITLE_PLACEHOLDER,
    companyName: profileViewData.companyName || COMPANY_PLACEHOLDER,
    firstNamePronunciation:
      profileViewData.firstNamePronunciation || profileViewData.lastNamePronunciation
        ? profileViewData.firstNamePronunciation
        : PRONUNCIATION_PLACEHOLDER,
    locationText: profileViewData.locationText || profileViewData.location?.name || LOCATION_PLACEHOLDER,
    headline: profileViewData.headline || BIO_PLACEHOLDER,
    location: profileViewData.location || {
      description: LOCATION_PLACEHOLDER,
      placeId: '',
      name: LOCATION_PLACEHOLDER,
      addressComponents: [],
      utcOffsetMinutes: 0,
    },
    linkedInUrl: profileViewData.linkedInUrl || LINKEDIN_PLACEHOLDER,
  } as ProfileViewData;
};

/**
 * Calculates whether a user is a coworker based off of org membership and email domain
 * @param user The current user
 * @param profile The zoomUser/zoomContact which to compare to see if they are coworkers
 * @returns boolean indicating whether or not the two people are considered coworkers
 */
export const getIsCoworker = (user: ZoomUserFragment, profile: ProfileViewData) => {
  const org = user?.zoomOrganizationMembership?.organization;
  const ssoDomains = (org?.ssoDomains || []) as string[];
  const otherDomains = (org?.otherDomains || []) as string[];
  const allDomains = [...ssoDomains, ...otherDomains];

  return profile.zoomUserId && profile.organizationId
    ? user.zoomOrganizationMembership?.organization?.id === profile.organizationId
    : allDomains.includes(parseDomainFromEmail(profile.email));
};
