/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomUserFragment } from 'src/graphql';
import { ZoomPersistence } from 'src/modules/zoomSdk';
import {
  BackgroundTheme,
  ColorOption,
  DEFAULT_FONT_FAMILY_KEY,
  DEFAULT_SIGNATURE_TOGGLE,
  DEFAULT_THEME,
  FontFamilyKey,
} from 'src/utils/backgroundAppearance';
import { BackgroundType, NametagLayoutType, ShoutoutImageType, SortByField } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export interface BackgroundStoreState {
  /**
   * The current selected thumbnail image's URL
   */
  selectedThumbnailUrl?: string;
  /**
   * The selected shoutout's custom image
   */
  shoutoutCustomImageUrl?: string;
  /**
   * The selected meeting to be shown in the background (e.g., for shoutout logos)
   */
  selectedMeeting?: MeetingDetails;
  /**
   * The selected shared backgrounds sort field (e.g. "downloadCount", which maps to "Most popular")
   */
  selectedSharedBackgroundsSortField?: SortByField;
  /**
   * The key of the selected font family for rendering the background
   */
  selectedFontFamilyKey?: FontFamilyKey;
  /**
   * The selected theme of the background
   */
  selectedTheme?: BackgroundTheme;
  /**
   * The selected color option of the color picker
   */
  selectedColorOption?: ColorOption;
  /**
   * The selected color of the nametag underlay
   */
  selectedColor?: string;
  /**
   * The gradient option
   */
  selectedGradient?: boolean;
  /**
   * Type of selected shoutout image
   */
  selectedShoutoutImageType?: ShoutoutImageType;
  /**
   * The selected type of background
   */
  selectedBackgroundType?: BackgroundType;
  /**
   * Whether shoutouts should be removed from video
   */
  shouldRemoveShoutouts?: boolean;
  /**
   * Whether the livePreview is enabled
   */
  liveUpdateToggle?: boolean;
  /**
   * Previously selected background type
   */
  prevSelectedBackgroundType?: BackgroundType;
  /**
   * The selected toggle of the nametag tab
   *
   * Whether the signature text is enabled in the background
   * Signature is everything but the shoutout and watermark
   */
  selectedNametagToggle?: boolean;
  /**
   * The selected toggle of the overlays tab
   *
   * Whether the overlays are enabled
   */
  selectedOverlaysToggle?: boolean;
  /**
   * Whether the video is unmirrored
   */
  isVideoUnmirrored?: boolean;
  /**
   * Whether to show the unmirror dialog
   */
  showUnmirrorDialog?: boolean;
  /**
   * Whether the app has attempted to turn on the user's camera
   */
  wasAutoTurnOnCameraAttempted?: boolean;
  /**
   * Whether the unmirror dialog was already shown to the user
   */
  wasUnmirrorDialogShown?: boolean;
  /**
   * The selected toggle of the nametag layout type
   */
  selectedNametagLayout?: NametagLayoutType;
  /**
   * The selected camera resolution
   */
  backgroundTypeInitialized?: boolean;
  isBackgroundImageLoaded?: boolean;
  persistence?: ZoomPersistence;
  strengthsStyle?: 'color' | 'neutral';

  showSurveyDialog?: boolean;
}

export const defaultBackgroundStoreState: BackgroundStoreState = {
  shoutoutCustomImageUrl: undefined,
  selectedMeeting: undefined,
  selectedSharedBackgroundsSortField: SortByField.DownloadCount,
  selectedFontFamilyKey: DEFAULT_FONT_FAMILY_KEY,
  selectedTheme: DEFAULT_THEME,
  selectedColorOption: 'logo',
  selectedColor: '#000000',
  selectedGradient: true,
  selectedShoutoutImageType: 'none',
  selectedBackgroundType: 'none',
  shouldRemoveShoutouts: false,
  prevSelectedBackgroundType: undefined,
  selectedNametagToggle: DEFAULT_SIGNATURE_TOGGLE,
  selectedOverlaysToggle: true,
  isVideoUnmirrored: false,
  showUnmirrorDialog: false,
  showSurveyDialog: false,
  wasAutoTurnOnCameraAttempted: false,
  wasUnmirrorDialogShown: false,
  selectedNametagLayout: 'bottom',
  selectedThumbnailUrl: undefined,
  backgroundTypeInitialized: false,
  isBackgroundImageLoaded: false,
  persistence: 'save',
  strengthsStyle: 'neutral',
};

/* -------------------------------------------------------------------------- */
/*                              STORE DEFINITION                              */
/* -------------------------------------------------------------------------- */
/**
 * Background related data and methods
 */
class BackgroundStore implements BackgroundStoreState {
  selectedThumbnailUrl = defaultBackgroundStoreState.selectedThumbnailUrl;
  shoutoutCustomImageUrl = defaultBackgroundStoreState.shoutoutCustomImageUrl;
  selectedMeeting = defaultBackgroundStoreState.selectedMeeting;
  selectedSharedBackgroundsSortField = defaultBackgroundStoreState.selectedSharedBackgroundsSortField;
  selectedFontFamilyKey = defaultBackgroundStoreState.selectedFontFamilyKey;
  selectedTheme = defaultBackgroundStoreState.selectedTheme;
  selectedColorOption = defaultBackgroundStoreState.selectedColorOption;
  selectedColor = defaultBackgroundStoreState.selectedColor;
  selectedGradient = defaultBackgroundStoreState.selectedGradient;
  selectedShoutoutImageType = defaultBackgroundStoreState.selectedShoutoutImageType;
  selectedBackgroundType = defaultBackgroundStoreState.selectedBackgroundType;
  shouldRemoveShoutouts = defaultBackgroundStoreState.shouldRemoveShoutouts;
  prevSelectedBackgroundType = defaultBackgroundStoreState.prevSelectedBackgroundType;
  selectedNametagToggle = defaultBackgroundStoreState.selectedNametagToggle;
  selectedOverlaysToggle = defaultBackgroundStoreState.selectedOverlaysToggle;
  isVideoUnmirrored = defaultBackgroundStoreState.isVideoUnmirrored;
  showUnmirrorDialog = defaultBackgroundStoreState.showUnmirrorDialog;
  showSurveyDialog = defaultBackgroundStoreState.showSurveyDialog;
  wasAutoTurnOnCameraAttempted = defaultBackgroundStoreState.wasAutoTurnOnCameraAttempted;
  wasUnmirrorDialogShown = defaultBackgroundStoreState.wasUnmirrorDialogShown;
  selectedNametagLayout = defaultBackgroundStoreState.selectedNametagLayout;
  backgroundTypeInitialized = defaultBackgroundStoreState.backgroundTypeInitialized;
  isBackgroundImageLoaded = defaultBackgroundStoreState.isBackgroundImageLoaded;
  persistence = defaultBackgroundStoreState.persistence;
  strengthsStyle = defaultBackgroundStoreState.strengthsStyle;

  setDefaultBackgroundType = (loggedInUser: ZoomUserFragment) => {
    if (
      this.selectedBackgroundType === 'none' &&
      !this.backgroundTypeInitialized &&
      (loggedInUser.customBackgroundPhotoUrl || loggedInUser.defaultCustomBackgroundPhotoUrl)
    ) {
      this.setSelectedBackgroundType('custom');
    }
  };

  /**
   * @param disablePersistence if true does not persist the store
   */
  constructor(disablePersistence: boolean) {
    makeAutoObservable(this);
    !disablePersistence &&
      makePersistable(this, {
        name: 'BackgroundStore',
        properties: [
          'selectedFontFamilyKey',
          'selectedTheme',
          'selectedColorOption',
          'selectedColor',
          'selectedGradient',
          'selectedShoutoutImageType',
          'selectedBackgroundType',
          'shoutoutCustomImageUrl',
          'prevSelectedBackgroundType',
          'selectedNametagToggle',
          'selectedOverlaysToggle',
          'isVideoUnmirrored',
          'wasUnmirrorDialogShown',
          'selectedNametagLayout',
          'selectedMeeting',
          'selectedThumbnailUrl',
          'backgroundTypeInitialized',
          'persistence',
          'strengthsStyle',
        ],
        storage: window.localStorage,
      });
  }

  setSelectedThumbnailUrl = (selectedThumbnailUrl: BackgroundStoreState['selectedThumbnailUrl']) => {
    this.selectedThumbnailUrl = selectedThumbnailUrl;
    if (selectedThumbnailUrl) {
      this.selectedBackgroundType = 'custom';
    }
  };

  setShoutoutCustomImageUrl = (shoutoutCustomImageUrl?: BackgroundStoreState['shoutoutCustomImageUrl']) => {
    if (shoutoutCustomImageUrl) {
      this.selectedShoutoutImageType = 'custom';
    }
    this.shoutoutCustomImageUrl = shoutoutCustomImageUrl;
  };

  setSelectedMeeting = (meeting: MeetingDetails) => {
    this.selectedMeeting = meeting;
  };

  setSelectedSharedBackgroundsSortField = (
    selectedSharedBackgroundsSortField: BackgroundStoreState['selectedSharedBackgroundsSortField']
  ) => {
    this.selectedSharedBackgroundsSortField = selectedSharedBackgroundsSortField;
  };

  setSelectedFontFamilyKey = (selectedFontFamilyKey: BackgroundStoreState['selectedFontFamilyKey']) => {
    this.selectedFontFamilyKey = selectedFontFamilyKey;
  };

  setSelectedTheme = (selectedTheme: BackgroundStoreState['selectedTheme']) => {
    this.selectedTheme = selectedTheme;
  };

  setSelectedColorOption = (option: ColorOption) => {
    this.selectedColorOption = option;
  };

  setSelectedColor = (color: string) => {
    this.selectedColor = color;
  };

  setSelectedGradient = (value: boolean) => {
    this.selectedGradient = value;
  };

  /**
   * Sets the selected shoutout image type (selectedShoutoutImageType).
   * If the image is set to a value other than "custom", the custom image URL is cleared (i.e. setShoutoutCustomImageUrl = undefined).
   * @param selectedShoutoutImageType The selected shoutout image type
   */
  setSelectedShoutoutImageType = (selectedShoutoutImageType: BackgroundStoreState['selectedShoutoutImageType']) => {
    this.selectedShoutoutImageType = selectedShoutoutImageType;
    if (selectedShoutoutImageType !== 'custom') {
      this.shoutoutCustomImageUrl = undefined;
    }
  };

  /**
   * Sets the selected background type (selectedBackgroundType).
   * If the background type is "none" or "blur", the signature type will be set to "foreground" (i.e. selectedSignatureTypeIsBackground = false)
   * If nametag layout is left and and the type is "custom" background signature will be enabled
   * @param selectedBackgroundType The selected background type
   */
  setSelectedBackgroundType = (selectedBackgroundType: BackgroundStoreState['selectedBackgroundType']) => {
    if (this.selectedBackgroundType !== selectedBackgroundType) this.backgroundTypeInitialized = true;

    this.selectedBackgroundType = selectedBackgroundType;
  };

  setShouldRemoveShoutouts = (shouldRemoveShoutouts: BackgroundStoreState['shouldRemoveShoutouts']) => {
    this.shouldRemoveShoutouts = shouldRemoveShoutouts;
  };

  setPrevSelectedBackgroundType = (prevSelectedBackgroundType?: BackgroundStoreState['prevSelectedBackgroundType']) => {
    this.prevSelectedBackgroundType = prevSelectedBackgroundType;
  };

  setSelectedNametagToggle = (selectedNametagToggle: BackgroundStoreState['selectedNametagToggle']) => {
    this.selectedNametagToggle = selectedNametagToggle;
  };

  setSelectedOverlaysToggle = (selectedOverlaysToggle: BackgroundStoreState['selectedOverlaysToggle']) => {
    this.selectedOverlaysToggle = selectedOverlaysToggle;
  };

  setIsVideoUnmirrored = (isVideoUnmirrored: BackgroundStoreState['isVideoUnmirrored']) => {
    this.isVideoUnmirrored = isVideoUnmirrored;
  };

  setWasAutoTurnOnCameraAttempted = (
    wasAutoTurnOnCameraAttempted: BackgroundStoreState['wasAutoTurnOnCameraAttempted']
  ) => {
    this.wasAutoTurnOnCameraAttempted = wasAutoTurnOnCameraAttempted;
  };

  setShowUnmirrorDialog = (showUnmirrorDialog: BackgroundStoreState['showUnmirrorDialog']) => {
    // The dialog is only shown once to the user
    if (showUnmirrorDialog) {
      if (this.wasUnmirrorDialogShown) return;
      this.wasUnmirrorDialogShown = true;
    }
    this.showUnmirrorDialog = showUnmirrorDialog;
  };

  setShowSurveyDialog = (flag: boolean) => {
    this.showSurveyDialog = flag;
  };

  setSelectedNametagLayout = (selectedNametagLayout: BackgroundStoreState['selectedNametagLayout']) => {
    this.selectedNametagLayout = selectedNametagLayout;
  };

  setIsBackgroundImageLoaded = (isBackgroundImageLoaded: BackgroundStoreState['isBackgroundImageLoaded']) => {
    this.isBackgroundImageLoaded = isBackgroundImageLoaded;
  };

  setPersistence = (persistence: BackgroundStoreState['persistence']) => {
    this.persistence = persistence;
  };

  setStrengthsStyle = (strengthsStyle: BackgroundStoreState['strengthsStyle']) => {
    this.strengthsStyle = strengthsStyle;
  };

  /**
   * Resets background store to default state
   */
  resetBackgroundStore = async () => {
    // Clear persisted store in local storage
    await clearPersistedStore(this);
    this.setBackgroundStore(defaultBackgroundStoreState);
  };

  setBackgroundStore = (backgroundStore: BackgroundStoreState) => {
    for (const key of Object.keys(defaultBackgroundStoreState)) {
      const storeValue = backgroundStore[key];
      if (typeof storeValue !== 'function') {
        this[key] = backgroundStore[key];
      }
    }
  };
}

export default BackgroundStore;
