export enum SortByField {
  CreatedAt = 'createdAt',
  DownloadCount = 'downloadCount',
}

export enum FilterChipField {
  allBackgrounds = 'All',
  approvedBackgrounds = 'Organization Approved',
  sharedBackgrounds = 'Shared Backgrounds',
  myBackgrounds = 'My Backgrounds',
}

const sortByFieldText: Record<SortByField, string> = {
  [SortByField.CreatedAt]: 'Most recent',
  [SortByField.DownloadCount]: 'Most popular',
};

export interface SharedBackgroundsSortByField {
  id: SortByField;
  text: string;
}

export const sharedBackgroundsSortByFieldList: SharedBackgroundsSortByField[] = [
  {
    id: SortByField.DownloadCount,
    text: sortByFieldText[SortByField.DownloadCount],
  },
  {
    id: SortByField.CreatedAt,
    text: sortByFieldText[SortByField.CreatedAt],
  },
];

/**
 * The type of image used for the shoutout
 * At the moment, the only types we have are
 * - `companyLogos`: the two company logos (overlapping round logos)
 * - `userLogo`: just the company logo of the current user
 * - `none`: no image
 */
export type ShoutoutImageType = typeof AllShoutoutImageTypes[number];
export const AllShoutoutImageTypes = ['companyLogos', 'userLogo', 'otherLogo', 'none', 'custom'] as const;

export type BackgroundType = typeof AllBackgroundTypes[number];
export const AllBackgroundTypes = ['custom', 'none', 'blur'] as const;

export type NametagLayoutType = typeof AllNametagLayoutType[number];
export const AllNametagLayoutType = ['left', 'bottom'] as const;

export type OverlaySizeType = typeof AllOverlaySizeType[number];
export const AllOverlaySizeType = ['small', 'medium', 'large'] as const;

export interface OverlaySizes {
  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number;
  scaleFactor: number;
}

export const CANVAS_WIDTH = 1920;
export const CANVAS_HEIGHT = 1080;

/**
 * Force a max character count for the shoutout message to avoid excessively long
 * message spilling over the background
 */
export const SHOUTOUT_MESSAGE_MAX_CHARACTERS = 200;

const CANVAS_PADDED_HEIGHT = CANVAS_HEIGHT - 32;

export const OVERLAY_MIN_WIDTH_SMALL = CANVAS_WIDTH / 3;
export const OVERLAY_MIN_WIDTH_MEDIUM = CANVAS_WIDTH / 2;
export const OVERLAY_MIN_WIDTH_LARGE = CANVAS_WIDTH;

export const OVERLAY_MAX_WIDTH_SMALL = CANVAS_WIDTH / 3;
export const OVERLAY_MAX_WIDTH_MEDIUM = CANVAS_WIDTH / 2;
export const OVERLAY_MAX_WIDTH_LARGE = CANVAS_WIDTH;

export const OVERLAY_MIN_HEIGHT_SMALL = CANVAS_HEIGHT / 3;
export const OVERLAY_MIN_HEIGHT_MEDIUM = CANVAS_HEIGHT / 2;
export const OVERLAY_MIN_HEIGHT_LARGE = CANVAS_HEIGHT;

export const OVERLAY_MAX_HEIGHT_SMALL = CANVAS_PADDED_HEIGHT;
export const OVERLAY_MAX_HEIGHT_MEDIUM = CANVAS_PADDED_HEIGHT;
export const OVERLAY_MAX_HEIGHT_LARGE = CANVAS_HEIGHT;
