/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';

/* --------------------------------- CUSTOM --------------------------------- */
import { GoogleFullScope, MicrosoftScope } from 'src/@types/backend';
import RootStore from 'src/stores/Root.store';

/* -------------------------------------------------------------------------- */
/*                              STORE DEFINITION                              */
/* -------------------------------------------------------------------------- */
class UserCalendarStore {
  /* ----------------------------- COMPUTED FIELDS ---------------------------- */
  get hasGrantedCalendarAccess() {
    const user = this.rootStore.userStore.user;
    if (!user) return false;
    const { googleScopes, microsoftScopes, integrationType } = user;
    return integrationType === 'google'
      ? ((googleScopes || []) as GoogleFullScope[]).includes('https://www.googleapis.com/auth/calendar.events')
      : ((microsoftScopes || []) as MicrosoftScope[]).includes('Calendars.ReadWrite');
  }

  get calendarSyncInProgress() {
    const user = this.rootStore.userStore.user;
    return this.hasGrantedCalendarAccess && !user?.calendarLastSyncedAt && user?.integrationType !== 'microsoft';
  }

  /* ------------------------------- CONSTRUCTOR ------------------------------ */
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }
}

export default UserCalendarStore;
