export enum UserType {
  INTERNAL = 'INTERNAL',
  CLIENT = 'CLIENT',
  QA = 'QA',
  APPROVER = 'APPROVER',
  TEST = 'TEST',
  QA_NEW = 'QA_NEW',
  APPROVER_NEW = 'APPROVER_NEW',
}

export enum UserRole {
  INTERNAL = 'INTERNAL',
  CLIENT = 'CLIENT',
  QA = 'QA',
  APPROVER = 'APPROVER',
  TEST = 'TEST',
  QA_NEW = 'QA_NEW',
  APPROVER_NEW = 'APPROVER_NEW',
}

export type MembershipType = 'super' | 'admin' | 'member';

export enum UserQaQualification {
  ENRICH_CONTACT = 'ENRICH_CONTACT',
  APPROVE_ENRICH_CONTACT = 'APPROVE_ENRICH_CONTACT',
  ENRICH_CONTACT_EXPERT = 'ENRICH_CONTACT_EXPERT',
  APPROVE_ENRICH_CONTACT_EXPERT = 'APPROVE_ENRICH_CONTACT_EXPERT',
}

export const MISSING_USER_ID_ERROR_MSG = 'Encountered error trying to get user id';
