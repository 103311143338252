import ADD_ICON from 'src/assets/add.svg';
import BACKGROUND_ADMIN_PORTAL from 'src/assets/admin-portal-background.png';
import BUBBLE_ICON from 'src/assets/background/bubble-icon.png';
import BUBBLE_ICON_DARK from 'src/assets/background/bubble-icon-dark.png';
import CS_ICON from 'src/assets/background/cs-icon.png';
import CS_ICON_DARK from 'src/assets/background/cs-icon-dark.png';
import CS_ICON_LARGE from 'src/assets/background/cs-icon-large.png';
import CS_ICON_LARGE_DARK from 'src/assets/background/cs-icon-large-dark.png';
import PLACE_ICON from 'src/assets/background/place-icon.png';
import PLACE_ICON_DARK from 'src/assets/background/place-icon-dark.png';
import SOUND_ICON from 'src/assets/background/sound-icon.png';
import SOUND_ICON_DARK from 'src/assets/background/sound-icon-dark.png';
import WARMLY_WATERMARK from 'src/assets/background/warmly-watermark.png';
import WARMLY_WATERMARK_DARK from 'src/assets/background/warmly-watermark-dark.png';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY from 'src/assets/background/zoom-custom-background-overlay.png';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_LIGHT from 'src/assets/background/zoom-custom-background-overlay-light.png';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_LIGHT_PREVIEW from 'src/assets/background/zoom-custom-background-overlay-light-preview.png';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_NO_SIGNATURE from 'src/assets/background/zoom-custom-background-overlay-no-signature.png';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_NO_SIGNATURE_LIGHT from 'src/assets/background/zoom-custom-background-overlay-no-signature-light.png';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_NO_SIGNATURE_LIGHT_PREVIEW from 'src/assets/background/zoom-custom-background-overlay-no-signature-light-preview.png';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_NO_SIGNATURE_PREVIEW from 'src/assets/background/zoom-custom-background-overlay-no-signature-preview.png';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_NONE from 'src/assets/background/zoom-custom-background-overlay-none.jpg';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_NONE_PREVIEW from 'src/assets/background/zoom-custom-background-overlay-none-preview.jpg';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_NONE_PREVIEW_SOLID from 'src/assets/background/zoom-custom-background-overlay-none-preview-solid.jpg';
import ZOOM_CUSTOM_BACKGROUND_OVERLAY_PREVIEW from 'src/assets/background/zoom-custom-background-overlay-preview.png';
import BACKGROUND_PERSON_SILHOUETTE from 'src/assets/background-person-silhouette.png';
import BACKGROUND_PERSON_SILHOUETTE_PREVIEW from 'src/assets/background-person-silhouette-preview.png';
import BLUR_BG from 'src/assets/blur-bg.png';
import BRB_COLOR from 'src/assets/brb-color.png';
import BRB_WHITE from 'src/assets/brb-white.png';
import BUTTON_EXPERIENCE_CELEBRATION from 'src/assets/button-experience-celebration.png';
import BUTTON_EXPERIENCE_FIREWORKS from 'src/assets/button-experience-fireworks.png';
import BUTTON_EXPERIENCE_SNOW from 'src/assets/button-experience-snow.png';
import CALENDAR_SIGNATURE_UPSELL from 'src/assets/calendar-signature-upsell.png';
import CALENDAR_SIGNATURE_UPSELL_MICROSOFT from 'src/assets/calendar-signature-upsell-ms.png';
import CARTOON_DOWNLOAD from 'src/assets/cartoon-download.png';
import CARTOON_PERSON_ON_HAMMOCK from 'src/assets/cartoon-person-on-hammock.svg';
import CEO_AVATAR_NO_BORDER from 'src/assets/ceo_avatar_no_border.png';
import CRUNCHBASE_LOGO from 'src/assets/crunchbase-logo.png';
import CTO_AVATAR_NO_BORDER from 'src/assets/cto_avatar_no_border.png';
import DEMO_MEETINGS from 'src/assets/demo-meetings.png';
import DEMO_MEETINGS_MASK from 'src/assets/demo-meetings-mask.png';
import DEMO_PARTICIPANT_PROFILE from 'src/assets/demo-participant-profile.png';
import DEMO_PARTICIPANT_PROFILE_MASK from 'src/assets/demo-participant-profile-mask.png';
import DEMO_PARTICIPANTS from 'src/assets/demo-participants.png';
import DEMO_PARTICIPANTS_MASK from 'src/assets/demo-participants-mask.png';
import ERROR_CAT from 'src/assets/error-cat.png';
import ZOOM_CUSTOM_FOREGROUND_OVERLAY from 'src/assets/foreground/zoom-custom-foreground-overlay.png';
import ZOOM_CUSTOM_FOREGROUND_OVERLAY_BOTTOM from 'src/assets/foreground/zoom-custom-foreground-overlay-bottom.png';
import ZOOM_CUSTOM_FOREGROUND_OVERLAY_BOTTOM_GLASS from 'src/assets/foreground/zoom-custom-foreground-overlay-bottom-glass.png';
import ZOOM_CUSTOM_FOREGROUND_OVERLAY_BOTTOM_LIGHT from 'src/assets/foreground/zoom-custom-foreground-overlay-bottom-light.png';
import ZOOM_CUSTOM_FOREGROUND_OVERLAY_LIGHT from 'src/assets/foreground/zoom-custom-foreground-overlay-light.png';
import POWERED_BY_GIPHY from 'src/assets/giphy/powered-by-giphy.gif';
import GOOGLE_SIGNIN_DARK from 'src/assets/google_signin_dark.png';
import GOOGLE_AUTH_CALENDAR_PERMISSIONS_ERROR from 'src/assets/google-auth-calendar-permissions-error.png';
import HOME_CALENDAR_GIF from 'src/assets/home/calendar.gif';
import HOME_WELCOME_GIF from 'src/assets/home/welcome.gif';
import HOW_IT_WORKS from 'src/assets/how-it-works.gif';
import INFLUENCER_ALLISON_PICKENS from 'src/assets/influencers/allison-pickens-nametag.png';
import INFLUENCER_ALLISON_PICKENS_SM from 'src/assets/influencers/allison-pickens-nametag-sm.jpg';
import INFLUENCER_CHRIS_MESSINA from 'src/assets/influencers/chris-messina-nametag.png';
import INFLUENCER_CHRIS_MESSINA_SM from 'src/assets/influencers/chris-messina-nametag-sm.jpg';
import INFLUENCER_CLARK_VALBERG from 'src/assets/influencers/clark-valberg-nametag.png';
import INFLUENCER_CLARK_VALBERG_SM from 'src/assets/influencers/clark-valberg-nametag-sm.jpg';
import INFLUENCER_DAVID_COHEN from 'src/assets/influencers/david-cohen-nametag.png';
import INFLUENCER_DAVID_COHEN_SM from 'src/assets/influencers/david-cohen-nametag-sm.jpg';
import INFLUENCER_ELIZABETH_WEIL from 'src/assets/influencers/elizabeth-weil-nametag.png';
import INFLUENCER_ELIZABETH_WEIL_SM from 'src/assets/influencers/elizabeth-weil-nametag-sm.jpg';
import INFLUENCER_GUSTAF_ALSTROMER from 'src/assets/influencers/gustaf-alstromer-nametag.png';
import INFLUENCER_GUSTAF_ALSTROMER_SM from 'src/assets/influencers/gustaf-alstromer-nametag-sm.jpg';
import INFLUENCER_HARRY_STEBBINGS from 'src/assets/influencers/harry-stebbings-nametag.png';
import INFLUENCER_HARRY_STEBBINGS_SM from 'src/assets/influencers/harry-stebbings-nametag-sm.jpg';
import INFLUENCER_HUNTER_WALK from 'src/assets/influencers/hunter-walk-nametag.png';
import INFLUENCER_HUNTER_WALK_SM from 'src/assets/influencers/hunter-walk-nametag-sm.jpg';
import INFLUENCER_JACK_ALTMAN from 'src/assets/influencers/jack-altman-nametag.png';
import INFLUENCER_JACK_ALTMAN_SM from 'src/assets/influencers/jack-altman-nametag-sm.jpg';
import INFLUENCER_JAMES_CURRIER from 'src/assets/influencers/james-currier-nametag.png';
import INFLUENCER_JAMES_CURRIER_SM from 'src/assets/influencers/james-currier-nametag-sm.jpg';
import INFLUENCER_JUSTIN_KAN from 'src/assets/influencers/justin-kan-nametag.png';
import INFLUENCER_JUSTIN_KAN_SM from 'src/assets/influencers/justin-kan-nametag-sm.jpg';
import INFLUENCER_MATHILDE_COLLIN from 'src/assets/influencers/mathilde-collin-nametag.png';
import INFLUENCER_MATHILDE_COLLIN_SM from 'src/assets/influencers/mathilde-collin-nametag-sm.jpg';
import INFLUENCER_NAOMI_BAGDONAS from 'src/assets/influencers/naomi-bagdonas-nametag.png';
import INFLUENCER_NAOMI_BAGDONAS_SM from 'src/assets/influencers/naomi-bagdonas-nametag-sm.jpg';
import INFLUENCER_NICK_MEHTA from 'src/assets/influencers/nick-mehta-nametag.png';
import INFLUENCER_NICK_MEHTA_SM from 'src/assets/influencers/nick-mehta-nametag-sm.jpg';
import INFLUENCER_SCOTT_LEESE from 'src/assets/influencers/scott-leese-nametag.png';
import INFLUENCER_SCOTT_LEESE_SM from 'src/assets/influencers/scott-leese-nametag-sm.jpg';
import INFLUENCER_SULTAN_ALMAADEED from 'src/assets/influencers/sultan-almadeed-nametag.png';
import INFLUENCER_SULTAN_ALMAADEED_SM from 'src/assets/influencers/sultan-almadeed-nametag-sm.jpg';
import INSTALL_ZOOM from 'src/assets/install-zoom.jpg';
import LINKEDIN_ROUNDED from 'src/assets/linkedin-rounded.png';
import WARMLY_LOGO from 'src/assets/logo.png';
import LOGO_FALLBACK from 'src/assets/logo-fallback.png';
import LOGO_SOC2 from 'src/assets/logo-soc2.png';
import MAYBE_RSVP_PILL from 'src/assets/maybe-rsvp-pill.svg';
import MAYBE_RSVP_PILL_MINI from 'src/assets/maybe-rsvp-pill-mini.svg';
import MEETING_ATTENDEE_INSIGHTS from 'src/assets/meeting-attendee-insights.gif';
import MEETING_CALENDAR_UPSELL from 'src/assets/meeting-calendar-upsell.png';
import MICROSOFT_SIGNIN_LIGHT from 'src/assets/microsoft_signin_light.svg';
import MICROSOFT_ICON from 'src/assets/microsoft-icon.jpg';
import ERROR_CAT_BUIDER from 'src/assets/nametag-builder/error-cat.png';
import NAMETAG_ERIC from 'src/assets/NametagsForAll/nametag-eric.jpg';
import NAMETAG_ERICA from 'src/assets/NametagsForAll/nametag-erica.jpg';
import NO_RSVP_PILL from 'src/assets/no-rsvp-pill.svg';
import NO_RSVP_PILL_MINI from 'src/assets/no-rsvp-pill-mini.svg';
import ALESSANDRO_ONBOARDING from 'src/assets/onboarding/alessandro-onboarding.jpg';
import BRETT_ONBOARDING from 'src/assets/onboarding/brett-onboarding.jpg';
import DEREK_ONBOARDING from 'src/assets/onboarding/derek-onboarding.jpg';
import LIVE_ONBOARDING from 'src/assets/onboarding/live-onboarding.gif';
import MAX_AUTO_MEETING from 'src/assets/onboarding/max-auto-meeting.jpg';
import MAX_ONBOARDING from 'src/assets/onboarding/max-onboarding.png';
import ONBOARDING_AMANDA from 'src/assets/onboarding/onboarding-amanda.jpeg';
import ONBOARDING_JASON from 'src/assets/onboarding/onboarding-jason.jpg';
import ONBOARDING_KATY from 'src/assets/onboarding/onboarding-katy.jpg';
import RAF_SCREENSHOT from 'src/assets/onboarding/raf-screenshot.png';
import RYAN_ONBOARDING from 'src/assets/onboarding/ryan-onboarding.png';
import SIGN_UP_PHOTO_1 from 'src/assets/onboarding/sign-up-photo-1.jpg';
import SIGN_UP_PHOTO_2 from 'src/assets/onboarding/sign-up-photo-2.jpeg';
import SIGN_UP_PHOTO_3 from 'src/assets/onboarding/sign-up-photo-3.jpeg';
import SIGN_UP_SCREENSHOT from 'src/assets/onboarding/sign-up-screenshot.png';
import SSO_ICON from 'src/assets/onboarding/sso-icon.png';
import TIFF_ONBOARDING from 'src/assets/onboarding/tiff-onboarding.png';
import PEOPLE_INSIGHTS_HERO_IMAGE from 'src/assets/people-insights-hero-image.jpg';
import IMAGE_PERSON_STANDING from 'src/assets/person-standing.svg';
import POINTING_HAND from 'src/assets/pointing-hand.svg';
import SAD_SUN from 'src/assets/sad-sun.png';
import SHOUTOUTS_UPSELL from 'src/assets/shoutouts-upsell.png';
import TEAM_SELLING from 'src/assets/team-selling.png';
import THE_ORG_LOGO_ICON from 'src/assets/theorg-logo-icon-black.svg';
import TRY_MEETING_BOOSTS from 'src/assets/try-meeting-boosts.gif';
import TURN_ON_VIDEO_GIF from 'src/assets/turn-on-video.gif';
import TWITTER_ROUNDED from 'src/assets/twitter-rounded.png';
import VERIFIED_CHECKMARK from 'src/assets/verified-checkmark.svg';
import VIDEO_OFF_SIGNATURE_SETUP_GIF from 'src/assets/video-off-signature-setup.gif';
import ERROR_CAT_BOOSTS from 'src/assets/video-widgets/error-cat-meeting-boosts.png';
import IMAGES_WIDGET_BLOCKER from 'src/assets/video-widgets/images-widget-blocker.gif';
import PARTNERSHIPS_WIDGET_BLOCKER from 'src/assets/video-widgets/partnerships-widget-blocker.gif';
import SLIDES_WIDGET_BLOCKER from 'src/assets/video-widgets/slides-widget-blocker.gif';
import VIDEO_WIDGETS_BLOCKER from 'src/assets/video-widgets/video-widgets-blocker.gif';
import VP_PARTNERSHIPS_AVATAR_NO_BORDER from 'src/assets/vp_partnerships_avatar_no_border.png';
import WARMLY_CIRCLE_ICON from 'src/assets/warmly-circle-icon.png';
import WARMLY_CIRCLE_ICON_MED from 'src/assets/warmly-circle-icon-med.png';
import WARMLY_CIRCLE_ICON_SMALL from 'src/assets/warmly-circle-icon-small.png';
import WARMLY_COMMA_BASIC_LOGO from 'src/assets/warmly-comma-basic-logo.png';
import WARMLY_COMMA_LOGO from 'src/assets/warmly-comma-logo.svg';
import WARMLY_COMMA_WHITE_LOGO from 'src/assets/warmly-comma-white-logo.png';
import WARMLY_LOGO_HASHTAG from 'src/assets/warmly-logo-hashtag.svg';
import WARMLY_LOGO_VERTICAL_COLOR from 'src/assets/warmly-logo-vertical-color.png';
import WARMLY_PURPLE_WHITE_LOGO from 'src/assets/warmly-purple-white-logo.png';
import WARMLY_ROUNDED from 'src/assets/warmly-rounded.png';
import WARMLY_SQUARE_ICON from 'src/assets/warmly-square-icon.png';
import WIDGET_METTINGS_CAROUSEL_1 from 'src/assets/widget-carousel-1.jpg';
import WIDGET_METTINGS_CAROUSEL_2 from 'src/assets/widget-carousel-2.jpg';
import WIDGET_METTINGS_CAROUSEL_3 from 'src/assets/widget-carousel-3.jpg';
import YES_RSVP_PILL from 'src/assets/yes-rsvp-pill.svg';
import YES_RSVP_PILL_MINI from 'src/assets/yes-rsvp-pill-mini.svg';
import YOUTUBE_ICON from 'src/assets/youtube-icon.jpg';
import ZOOM_CHROME from 'src/assets/zoom-chrome.png';
import ZOOM_CHROME_PREVIEW from 'src/assets/zoom-chrome-preview.png';
import ZOOM_DYNAMIC_BACKGROUNDS_EXAMPLE_GIF from 'src/assets/zoom-dynamic-backgrounds-example.gif';
import ZOOM_HERO_IMAGE from 'src/assets/zoom-hero-image.png';
import ZOOM_ICON from 'src/assets/zoom-icon.svg';

/**
 * Used as a fallback when a global background cannot be loaded
 */
const FALLBACK_BG_URL =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014632/warmly_global_backgrounds/bg1_rfyqpa.png';
const HAPPY_HOLIDAYS_BACKGROUND =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014722/warmly_global_backgrounds/happy-holidays-background_cg7bjq.png';
const HANUKKAH_BACKGROUND =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014722/warmly_global_backgrounds/hanukkah-background_brfjtz.png';
const CHRISTMAS_BACKGROUND =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014722/warmly_global_backgrounds/christmas-background_ulubjx.png';
const ZOOM_BG_1 =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014632/warmly_global_backgrounds/bg1_rfyqpa.png';
const ZOOM_BG_2 =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014651/warmly_global_backgrounds/bg2_liem9p.png';
const ZOOM_BG_3 =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014672/warmly_global_backgrounds/bg3_xaukpq.png';
const ZOOM_BG_4 =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014717/warmly_global_backgrounds/bg4_hpjtoe.png';
const ZOOM_BG_5 =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014818/warmly_global_backgrounds/bg5_dxu4zv.png';
const ZOOM_BG_6 =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014717/warmly_global_backgrounds/bg6_nvtxj6.png';
const ZOOM_BG_7 =
  'https://res.cloudinary.com/warmly/image/upload/q_auto,f_auto,fl_lossy/v1642014716/warmly_global_backgrounds/bg7_nrdw29.png';
const ZOOM_CUSTOM_BACKGROUNDS = [ZOOM_BG_1, ZOOM_BG_2, ZOOM_BG_3, ZOOM_BG_4, ZOOM_BG_5, ZOOM_BG_6, ZOOM_BG_7];

const EXTENDED_ZOOM_CUSTOM_BACKGROUNDS = [
  ...ZOOM_CUSTOM_BACKGROUNDS,
  'https://res.cloudinary.com/warmly/image/upload/v1665092980/warmly_global_backgrounds/warmly_bg_9_jjxeqb.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665093005/warmly_global_backgrounds/warmly_bg_23_pmbfmf.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092982/warmly_global_backgrounds/warmly_bg_7_jw9wii.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665093012/warmly_global_backgrounds/warmly_bg_26_aldzwp.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092981/warmly_global_backgrounds/warmly_bg_8_auudqt.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665093012/warmly_global_backgrounds/warmly_bg_20_tniacs.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665093001/warmly_global_backgrounds/warmly_bg_21_rjkz28.png',
  'https://res.cloudinary.com/warmly/image/upload/v1665092978/warmly_global_backgrounds/warmly_bg_6_xle56m.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092995/warmly_global_backgrounds/warmly_bg_12_y0gkbg.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665093010/warmly_global_backgrounds/warmly_bg_3_jnnjyr.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092997/warmly_global_backgrounds/warmly_bg_11_ipeax2.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092982/warmly_global_backgrounds/warmly_bg_10_fzu2dm.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092997/warmly_global_backgrounds/warmly_bg_19_id5miy.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092994/warmly_global_backgrounds/warmly_bg_5_haeivx.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092998/warmly_global_backgrounds/warmly_bg_22_boqshx.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092997/warmly_global_backgrounds/warmly_bg_17_efypxd.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092978/warmly_global_backgrounds/warmly_bg_2_d6fwkh.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092997/warmly_global_backgrounds/warmly_bg_14_l3chqq.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092985/warmly_global_backgrounds/warmly_bg_1_zucifi.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665093000/warmly_global_backgrounds/warmly_bg_27_gcxqf6.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092999/warmly_global_backgrounds/warmly_bg_25_ee2fag.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092997/warmly_global_backgrounds/warmly_bg_18_sj9v4u.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092985/warmly_global_backgrounds/warmly_bg_13_tkcnhd.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092991/warmly_global_backgrounds/warmly_bg_15_zteybh.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092975/warmly_global_backgrounds/warmly_bg_4_m3au7z.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092998/warmly_global_backgrounds/warmly_bg_24_tsxqix.jpg',
  'https://res.cloudinary.com/warmly/image/upload/v1665092995/warmly_global_backgrounds/warmly_bg_16_qdcyyc.jpg',
];

// TODO: remove this code when all users with static backgrounds are using backgrounds hosted on cloudinary
const ZOOM_BG_CLOUDINARY_MAP: Record<string, string> = {
  '/static/media/christmas-background.9c29afb3.png': CHRISTMAS_BACKGROUND,
  '/static/media/halloween-bg1.52d53ea1.jpeg': FALLBACK_BG_URL,
  '/static/media/halloween-bg2.d41d7b7f.jpg': FALLBACK_BG_URL,
  '/static/media/hanukkah-background.d18491f7.png': HANUKKAH_BACKGROUND,
  '/static/media/happy-holidays-background.57998a89.png': HAPPY_HOLIDAYS_BACKGROUND,
  '/static/media/thanksgiving-bg1.d02fd028.png': FALLBACK_BG_URL,
  '/static/media/thanksgiving-bg2.f336a87d.png': FALLBACK_BG_URL,
  '/static/media/zoom-bg1.bfb1a22c.png': ZOOM_BG_1,
  '/static/media/zoom-bg1.ead53e25.png': ZOOM_BG_1,
  '/static/media/zoom-bg2.00cebfdb.png': ZOOM_BG_2,
  '/static/media/zoom-bg2.f110c6a7.png': ZOOM_BG_2,
  '/static/media/zoom-bg3.0aae130d.png': ZOOM_BG_3,
  '/static/media/zoom-bg3.9a91e68e.png': ZOOM_BG_3,
  '/static/media/zoom-bg4.2689eb62.png': ZOOM_BG_4,
  '/static/media/zoom-bg4.970a24dc.png': ZOOM_BG_4,
  '/static/media/zoom-bg5.a1e1a99b.png': ZOOM_BG_5,
  '/static/media/zoom-bg5.a2fdb8f5.ng': ZOOM_BG_5,
  '/static/media/zoom-bg6.cc3377cf.png': ZOOM_BG_6,
  '/static/media/zoom-bg6.faf1473b.png': ZOOM_BG_6,
  '/static/media/zoom-bg7.5a5168b6.png': ZOOM_BG_7,
};

export {
  ADD_ICON,
  ALESSANDRO_ONBOARDING,
  BACKGROUND_ADMIN_PORTAL,
  BACKGROUND_PERSON_SILHOUETTE,
  BACKGROUND_PERSON_SILHOUETTE_PREVIEW,
  BLUR_BG,
  BRB_COLOR,
  BRB_WHITE,
  BRETT_ONBOARDING,
  BUBBLE_ICON,
  BUBBLE_ICON_DARK,
  BUTTON_EXPERIENCE_CELEBRATION,
  BUTTON_EXPERIENCE_FIREWORKS,
  BUTTON_EXPERIENCE_SNOW,
  CALENDAR_SIGNATURE_UPSELL,
  CALENDAR_SIGNATURE_UPSELL_MICROSOFT,
  CARTOON_DOWNLOAD,
  CARTOON_PERSON_ON_HAMMOCK,
  CEO_AVATAR_NO_BORDER,
  CRUNCHBASE_LOGO,
  CS_ICON,
  CS_ICON_DARK,
  CS_ICON_LARGE,
  CS_ICON_LARGE_DARK,
  CTO_AVATAR_NO_BORDER,
  DEMO_MEETINGS,
  DEMO_MEETINGS_MASK,
  DEMO_PARTICIPANT_PROFILE,
  DEMO_PARTICIPANT_PROFILE_MASK,
  DEMO_PARTICIPANTS,
  DEMO_PARTICIPANTS_MASK,
  DEREK_ONBOARDING,
  ERROR_CAT,
  ERROR_CAT_BOOSTS,
  ERROR_CAT_BUIDER,
  EXTENDED_ZOOM_CUSTOM_BACKGROUNDS,
  FALLBACK_BG_URL,
  GOOGLE_AUTH_CALENDAR_PERMISSIONS_ERROR,
  GOOGLE_SIGNIN_DARK,
  HOME_CALENDAR_GIF,
  HOME_WELCOME_GIF,
  HOW_IT_WORKS,
  IMAGE_PERSON_STANDING,
  IMAGES_WIDGET_BLOCKER,
  INFLUENCER_ALLISON_PICKENS,
  INFLUENCER_ALLISON_PICKENS_SM,
  INFLUENCER_CHRIS_MESSINA,
  INFLUENCER_CHRIS_MESSINA_SM,
  INFLUENCER_CLARK_VALBERG,
  INFLUENCER_CLARK_VALBERG_SM,
  INFLUENCER_DAVID_COHEN,
  INFLUENCER_DAVID_COHEN_SM,
  INFLUENCER_ELIZABETH_WEIL,
  INFLUENCER_ELIZABETH_WEIL_SM,
  INFLUENCER_GUSTAF_ALSTROMER,
  INFLUENCER_GUSTAF_ALSTROMER_SM,
  INFLUENCER_HARRY_STEBBINGS,
  INFLUENCER_HARRY_STEBBINGS_SM,
  INFLUENCER_HUNTER_WALK,
  INFLUENCER_HUNTER_WALK_SM,
  INFLUENCER_JACK_ALTMAN,
  INFLUENCER_JACK_ALTMAN_SM,
  INFLUENCER_JAMES_CURRIER,
  INFLUENCER_JAMES_CURRIER_SM,
  INFLUENCER_JUSTIN_KAN,
  INFLUENCER_JUSTIN_KAN_SM,
  INFLUENCER_MATHILDE_COLLIN,
  INFLUENCER_MATHILDE_COLLIN_SM,
  INFLUENCER_NAOMI_BAGDONAS,
  INFLUENCER_NAOMI_BAGDONAS_SM,
  INFLUENCER_NICK_MEHTA,
  INFLUENCER_NICK_MEHTA_SM,
  INFLUENCER_SCOTT_LEESE,
  INFLUENCER_SCOTT_LEESE_SM,
  INFLUENCER_SULTAN_ALMAADEED,
  INFLUENCER_SULTAN_ALMAADEED_SM,
  INSTALL_ZOOM,
  LINKEDIN_ROUNDED,
  LIVE_ONBOARDING,
  LOGO_FALLBACK,
  LOGO_SOC2,
  MAX_AUTO_MEETING,
  MAX_ONBOARDING,
  MAYBE_RSVP_PILL,
  MAYBE_RSVP_PILL_MINI,
  MEETING_ATTENDEE_INSIGHTS,
  MEETING_CALENDAR_UPSELL,
  MICROSOFT_ICON,
  MICROSOFT_SIGNIN_LIGHT,
  NAMETAG_ERIC,
  NAMETAG_ERICA,
  NO_RSVP_PILL,
  NO_RSVP_PILL_MINI,
  ONBOARDING_AMANDA,
  ONBOARDING_JASON,
  ONBOARDING_KATY,
  PARTNERSHIPS_WIDGET_BLOCKER,
  PEOPLE_INSIGHTS_HERO_IMAGE,
  PLACE_ICON,
  PLACE_ICON_DARK,
  POINTING_HAND,
  POWERED_BY_GIPHY,
  RAF_SCREENSHOT,
  RYAN_ONBOARDING,
  SAD_SUN,
  SHOUTOUTS_UPSELL,
  SIGN_UP_PHOTO_1,
  SIGN_UP_PHOTO_2,
  SIGN_UP_PHOTO_3,
  SIGN_UP_SCREENSHOT,
  SLIDES_WIDGET_BLOCKER,
  SOUND_ICON,
  SOUND_ICON_DARK,
  SSO_ICON,
  TEAM_SELLING,
  THE_ORG_LOGO_ICON,
  TIFF_ONBOARDING,
  TRY_MEETING_BOOSTS,
  TURN_ON_VIDEO_GIF,
  TWITTER_ROUNDED,
  VERIFIED_CHECKMARK,
  VIDEO_OFF_SIGNATURE_SETUP_GIF,
  VIDEO_WIDGETS_BLOCKER,
  VP_PARTNERSHIPS_AVATAR_NO_BORDER,
  WARMLY_CIRCLE_ICON,
  WARMLY_CIRCLE_ICON_MED,
  WARMLY_CIRCLE_ICON_SMALL,
  WARMLY_COMMA_BASIC_LOGO,
  WARMLY_COMMA_LOGO,
  WARMLY_COMMA_WHITE_LOGO,
  WARMLY_LOGO,
  WARMLY_LOGO_HASHTAG,
  WARMLY_LOGO_VERTICAL_COLOR,
  WARMLY_PURPLE_WHITE_LOGO,
  WARMLY_ROUNDED,
  WARMLY_SQUARE_ICON,
  WARMLY_WATERMARK,
  WARMLY_WATERMARK_DARK,
  WIDGET_METTINGS_CAROUSEL_1,
  WIDGET_METTINGS_CAROUSEL_2,
  WIDGET_METTINGS_CAROUSEL_3,
  YES_RSVP_PILL,
  YES_RSVP_PILL_MINI,
  YOUTUBE_ICON,
  ZOOM_BG_CLOUDINARY_MAP,
  ZOOM_CHROME,
  ZOOM_CHROME_PREVIEW,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_LIGHT,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_LIGHT_PREVIEW,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_NO_SIGNATURE,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_NO_SIGNATURE_LIGHT,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_NO_SIGNATURE_LIGHT_PREVIEW,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_NO_SIGNATURE_PREVIEW,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_NONE,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_NONE_PREVIEW,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_NONE_PREVIEW_SOLID,
  ZOOM_CUSTOM_BACKGROUND_OVERLAY_PREVIEW,
  ZOOM_CUSTOM_BACKGROUNDS,
  ZOOM_CUSTOM_FOREGROUND_OVERLAY,
  ZOOM_CUSTOM_FOREGROUND_OVERLAY_BOTTOM,
  ZOOM_CUSTOM_FOREGROUND_OVERLAY_BOTTOM_GLASS,
  ZOOM_CUSTOM_FOREGROUND_OVERLAY_BOTTOM_LIGHT,
  ZOOM_CUSTOM_FOREGROUND_OVERLAY_LIGHT,
  ZOOM_DYNAMIC_BACKGROUNDS_EXAMPLE_GIF,
  ZOOM_HERO_IMAGE,
  ZOOM_ICON,
};
