export enum CalendarEnrichmentStatus {
  ALL = 'ALL',
  NONE = 'NONE',
}

enum UserOnboardVersion {
  Jan2021 = 'Jan2021',
}

/**
 * Onboard Type passed as a query parameter before authentication
 */
export enum OnboardingType {
  CalendarSignature = 'calendar',
  Default = 'default',
  NametagsForAll = 'nametags',
}

/**
 * Onboard Type saved to the Database
 */
export enum UserOnboardType {
  CalendarSignature = 'CALENDAR_SIGNATURE',
  Default = 'DEFAULT',
  NametagsForAll = 'NAMETAGS_FOR_ALL',
}

export const UserOnboardTypeMap: Record<OnboardingType, string> = {
  [OnboardingType.CalendarSignature]: UserOnboardType.CalendarSignature,
  [OnboardingType.Default]: UserOnboardType.Default,
  [OnboardingType.NametagsForAll]: UserOnboardType.NametagsForAll,
};

export enum DomainApprovalStatus {
  Approved = 'Approved',
  Unapproved = 'Unapproved',
}

export enum ZoomEventStatus {
  Canceled = 'cancelled',
}

export const CURRENT_ONBOARD_VERSION = UserOnboardVersion.Jan2021;

// To increase obscurity, we do not use semantic words for the actual keys, since the local storage items are easily accessible
// by the users
export enum LocalStorageItem {
  ZoomUserId = 'warmly_GhXJajqpNtnFU8CS',
  ZoomAuthUser = 'warmly_jI9kea0L3Cndy70Bnzxcsjp',
  DeepLinkAction = 'deepLinkAction',
  LastVisitedTab = 'lastVisitedTab',
  ShouldSetVirtualBackground = 'shouldSetVirtualBackground',
  ShouldSetVirtualBackgroundLifespan = 'shouldSetVirtualBackgroundLifespan',
  CalendarAutoPromptHistory = 'calendarAutoPromptHistory',
  LogoutParams = 'logoutParams',
  BoostParams = 'boostParams',
}
