/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const CLIFTON_STRENGTHS = [
  {
    value: 'Analytical',
    group: 'Strategic Thinking',
    color: '#00945d',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Context',
    group: 'Strategic Thinking',
    color: '#00945d',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Futuristic',
    group: 'Strategic Thinking',
    color: '#00945d',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Ideation',
    group: 'Strategic Thinking',
    color: '#00945d',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Input',
    group: 'Strategic Thinking',
    color: '#00945d',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Intellection',
    group: 'Strategic Thinking',
    color: '#00945d',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Learner',
    group: 'Strategic Thinking',
    color: '#00945d',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Strategic',
    group: 'Strategic Thinking',
    color: '#00945d',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Adaptability',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Connectedness',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Developer',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Empathy',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Harmony',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Includer',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Individualization',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Positivity',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Relator',
    group: 'Relationship Building',
    color: '#0070cd',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Activator',
    group: 'Influencing',
    color: '#e97200',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Command',
    group: 'Influencing',
    color: '#e97200',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Communication',
    group: 'Influencing',
    color: '#e97200',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Competition',
    group: 'Influencing',
    color: '#e97200',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Maximizer',
    group: 'Influencing',
    color: '#e97200',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Self-Assurance',
    group: 'Influencing',
    color: '#e97200',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Significance',
    group: 'Influencing',
    color: '#e97200',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Woo',
    group: 'Influencing',
    color: '#e97200',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Achiever',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Arranger',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Belief',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Consistency',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Deliberative',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Discipline',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Focus',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Responsibility',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: '®',
  },
  {
    value: 'Restorative',
    group: 'Executing',
    color: '#7b2481',
    textColor: '#fff',
    symbol: 'TM',
  },
];
