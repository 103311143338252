import WidgetStore from 'src/stores/Widget.store';
import { ShoutoutImageType } from 'src/utils/constants/backgrounds';
import { QueryParam } from 'src/utils/constants/url';
import { ConversationStarterAnswerForPrompt } from 'src/utils/conversationStarters';

export * from 'src/utils/constants/index';

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export const POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY || '';

export const CLOUDINARY_API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY || '';
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const ZOOM_APP_PUBLISHABLE_URL_DEV = process.env.REACT_APP_ZOOM_PUBLISHABLE_URL_DEV || '';
export const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY || '';
export const IS_CI = process.env.REACT_APP_IS_CI === 'true';
export const DEBUG_LOGGING_ENABLED =
  process.env.REACT_APP_DEBUG_LOGGING_ENABLED === 'true' ||
  window.localStorage.getItem('DebugLoggingEnabled') === 'true';
export const HOOK_LOGGING_ENABLED =
  process.env.REACT_APP_HOOK_LOGGING_ENABLED === 'true' || window.localStorage.getItem('HookLoggingEnabled') === 'true';
export const RENDER_DEBUGGING_ENABLED =
  process.env.REACT_APP_RENDER_DEBUGGING_ENABLED === 'true' ||
  window.localStorage.getItem('AppDebuggingEnabled') === 'true';

export const CURRENT_ENVIRONMENT: Environment = process.env.REACT_APP_WARMLY_ENVIRONMENT as Environment;

export const REACT_APP_WARMLY_LOCAL_ENVIRONMENT: Environment = process.env
  .REACT_APP_WARMLY_LOCAL_ENVIRONMENT as Environment;

export const SENTRY_DSN = 'https://19b2abafa6d64934a32e239d769fdc3d@o406756.ingest.sentry.io/5588495';

// Local development uses the staging cloudinary folder
const CLOUDINARY_ENV = CURRENT_ENVIRONMENT === Environment.Production ? Environment.Production : Environment.Staging;
export const PROFILE_PHOTOS_FOLDER = `warmly_profile_photos_${CLOUDINARY_ENV}`;
export const BACKGROUND_PHOTOS_FOLDER = `warmly_background_photos_${CLOUDINARY_ENV}`;
export const CUSTOM_BACKGROUND_PHOTOS_FOLDER = `warmly_custom_background_photos_${CLOUDINARY_ENV}`;
export const AI_BACKGROUND_PHOTOS_FOLDER = `warmly_ai_background_photos_${CLOUDINARY_ENV}`;
export const COMPANY_LOGOS_FOLDER = `warmly_company_logos_${CLOUDINARY_ENV}`;
export const VIDEO_OFF_SIGNATURES_FOLDER = `warmly_video_off_signatures_${CLOUDINARY_ENV}`;
export const SHOUTOUT_PHOTOS_FOLDER = `warmly_shoutout_photos_${CLOUDINARY_ENV}`;
export const SLIDES_FOLDER = `warmly_slides_${CLOUDINARY_ENV}`;
export const CONVERSATION_STARTERS_PHOTOS_FOLDER = `warmly_conversation_starters_photos_${CLOUDINARY_ENV}`;

export enum HTTP_RESPONSE {
  OK = 200,
  NO_CONTENT = 204,
  REDIRECT = 303,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
}

export enum WARMLY_EMAIL {
  CEO = 'max@warmly.ai',
  SUPPORT_GENERAL = 'support@warmly.ai',
  CSM = 'hello@warmly.ai',
  VP_PARTNERSHIPS = 'derek@warmly.ai',
  SALES = 'sales@warmly.ai',
  ZOOM = 'zoom@warmly.ai',
  SECURITY = 'security@warmly.ai',
}

// In local dev, all of our services are proxied through nginx
// The base url is shared between web-dashboard and zoom-app
// but we should never have both dashboards running at the same time
// see the docker-compose and nginx files for details

// The URLs that we use in local dev depends on whether it's running in
// browser (in which case we use localhost:5000) or
// Zoom client (which requires HTTPS, so it would be our ngrok URL)
export const LOCAL_DEV_HOSTNAME = window.location.hostname;
export const LOCAL_DEV_PORT = window.location.port && ':5000';
export const LOCAL_DEV_PROTOCOL = window.location.protocol;
export const LOCAL_DEV_DOMAIN = `${LOCAL_DEV_HOSTNAME}${LOCAL_DEV_PORT}`;
export const LOCAL_DEV_ROOT = `${LOCAL_DEV_PROTOCOL}//${LOCAL_DEV_DOMAIN}`;

export const protocolByEnvironment: Record<Environment, Location['protocol']> = {
  [Environment.Production]: 'https:',
  [Environment.Staging]: 'https:',
  [Environment.Development]: LOCAL_DEV_PROTOCOL,
};

export const baseProxyDomainByEnvironment: Record<Environment, string> = {
  [Environment.Production]: 'p.warmly.ai',
  [Environment.Staging]: 'p.warmly.ai', // The staging app uses the prod nginx reverse proxy (there is no staging version)
  [Environment.Development]: LOCAL_DEV_DOMAIN, // In local dev, the proxy is exposed at the root level by the nginx instance
};

export const zoomAppDashboardUrlByEnvironment: Record<Environment, string> = {
  [Environment.Production]: 'https://rapport.warmly.ai',
  [Environment.Staging]: `https://zoom-staging.getwarmly.com`,
  [Environment.Development]: LOCAL_DEV_ROOT,
};

const zoomApiBaseUrlByEnvironment: Record<Environment, string> = {
  [Environment.Production]: 'https://api.rapport.warmly.ai',
  [Environment.Staging]: 'https://zoom-server-staging.getwarmly.com',
  [Environment.Development]: `${LOCAL_DEV_ROOT}/zoom-api`,
};

const hasuraUriByEnvironment: Record<Environment, string> = {
  [Environment.Production]: 'https://hasura.getwarmly.com/v1/graphql',
  // [Environment.Production]: 'https://hasura.rapport.warmly.ai/v1/graphql',
  [Environment.Staging]: 'https://hasura-dot-warmly-staging.uc.r.appspot.com/v1/graphql',
  [Environment.Development]: `${LOCAL_DEV_ROOT}/hasura/v1/graphql`,
};

const hasuraWsByEnvironment: Record<Environment, string> = {
  [Environment.Production]: 'wss://hasura.getwarmly.com/v1/graphql',
  // [Environment.Production]: 'wss://hasura.rapport.warmly.ai/v1/graphql',
  [Environment.Staging]: 'wss://hasura-dot-warmly-staging.uc.r.appspot.com/v1/graphql',
  [Environment.Development]: `${
    LOCAL_DEV_PROTOCOL === 'https:' ? 'wss' : 'ws'
  }://${LOCAL_DEV_DOMAIN}/hasura/v1/graphql`,
};

const PostHogKeyByEnvironment: Record<Environment, string> = {
  [Environment.Production]: POSTHOG_KEY,
  [Environment.Staging]: POSTHOG_KEY,
  [Environment.Development]: POSTHOG_KEY,
};

export const PosthogKey = PostHogKeyByEnvironment[CURRENT_ENVIRONMENT];
export const PosthogKeyDev = PostHogKeyByEnvironment[CURRENT_ENVIRONMENT];

export const HASURA_URI = hasuraUriByEnvironment[CURRENT_ENVIRONMENT];

export const HASURA_WS = hasuraWsByEnvironment[CURRENT_ENVIRONMENT];

export const ZOOM_DASHBOARD_BASE_URL = zoomAppDashboardUrlByEnvironment[CURRENT_ENVIRONMENT];

const ZOOM_API_BASE_URL = zoomApiBaseUrlByEnvironment[CURRENT_ENVIRONMENT];

export const BASE_PROXY_DOMAIN = baseProxyDomainByEnvironment[CURRENT_ENVIRONMENT];

export const BASE_PROTOCOL = protocolByEnvironment[CURRENT_ENVIRONMENT];

const ZOOM_API_ENDPOINT = {
  GOOGLE_AUTH: '/google-auth',
  MICROSOFT_AUTH: '/microsoft-auth',
  SSO_AUTH: '/sso-auth',
  GOOGLE_INCREMENTAL_AUTH: '/google-incremental-auth',
  CALENDAR_ENRICHMENT_STATUS: '/calendar-enrichment-status',
  COMPANY_GET_ORG_CHART: '/company/the-org',
  ZOOM_USER_STOP_NOTIFICATION_CHANNELS: '/zoom-user/stop-notification-channels',
  ZOOM_USER_RESTART_NOTIFICATION_CHANNELS: '/zoom-user/restart-notification-channels',
  ZOOM_LOGOUT_ALL_USERS: '/zoom-user/logout-all-users',
  ZOOM_USER_ONBOARDING: '/zoom-user/onboarding',
  ZOOM_USER_SEND_EMAIL: '/zoom-user/send-email',
  ZOOM_IMAGES_GENERATE_UPLOAD_SIGNATURE: '/zoom-images/generate-upload-signature',
  ZOOM_USER_RECREATE_ZOOM_TASKS: '/zoom-user/recreate-zoom-tasks',
  ZOOM_USER_UPDATE_ROLE_BUCKETS: '/zoom-user/update-role-buckets',
  ZOOM_USER_REINJECT_CALENDAR_EVENTS: '/zoom-user/reinject-calendar-events',
  ZOOM_USER_DELETE_USER_DATA: '/zoom-user/delete-user-data',
  ZOOM_USER_LOGOUT_USER_BY_ADMIN: '/zoom-user/logout-user-by-admin',
  ZOOM_AUTH: '/zoom-auth/auth',
  PKSE: '/pkse',
  PROXY: '/proxy',
  WEATHER: '/weather',
};

const ZOOM_HOST = 'https://zoom.us';

const ZOOM_EXTERNAL_URL = {
  AUTHORIZE: `${ZOOM_HOST}/oauth/authorize`,
};

const ZOOM_API_ROUTE = {
  ZOOM_AUTH: '/zoom-auth',
};

const ZOOM_AUTH_ENDPOINT = {
  AUTH: '/auth',
};

export const zoomApiUrl = {
  zoom: {
    getMicrosoftAuth: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.MICROSOFT_AUTH,
    getSSOAuth: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.SSO_AUTH,
    getGoogleAuth: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.GOOGLE_AUTH,
    getGoogleIncrementalAuth: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.GOOGLE_INCREMENTAL_AUTH,
    getWeather: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.WEATHER,
    redirectUrl: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_AUTH,
    pkse: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.PKSE,
  },
  company: {
    getTheOrg: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.COMPANY_GET_ORG_CHART,
  },
  zoomUser: {
    stopNotificationChannels: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_STOP_NOTIFICATION_CHANNELS,
    restartNotificationChannels: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_RESTART_NOTIFICATION_CHANNELS,
    logoutAllUsers: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_LOGOUT_ALL_USERS,
    onboarding: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_ONBOARDING,
    sendEmail: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_SEND_EMAIL,
    recreateZoomTasks: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_RECREATE_ZOOM_TASKS,
    updateRoleBuckets: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_UPDATE_ROLE_BUCKETS,
    reinjectCalendarEvents: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_REINJECT_CALENDAR_EVENTS,
    deleteUserData: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_DELETE_USER_DATA,
    logoutUserByAdmin: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_USER_LOGOUT_USER_BY_ADMIN,
  },
  images: {
    generateUploadSignature: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.ZOOM_IMAGES_GENERATE_UPLOAD_SIGNATURE,
  },
  proxy: {
    forwardProxy: ZOOM_API_BASE_URL + ZOOM_API_ENDPOINT.PROXY,
  },
  graphql: ZOOM_API_BASE_URL + '/graphql',
};

/**
 * !IMPORTANT! If changing the following URL, please also update the URL in the e2e constants file.
 */
export enum NavigationPath {
  Main = '/',
  Home = '/home',
  MeetingBoosts = '/video-widgets',
  CompanyProfile = '/company-profile',
  CompanyDirectory = '/company-directory',
  Profile = '/profile',
  Help = '/help',
  Settings = '/settings',
  Login = '/login',
  Logout = '/logout',
  Admin = '/admin',
  DomainAdmin = '/company-admin',
  Downloading = '/downloading',
  ConnectCalendar = '/connect-calendar',
  PreAuthentication = '/pre-authentication',
  UserProfile = '/user',

  OnboardingZoom = '/onboarding-zoom',
  OnboardingInvite = '/onboarding-invite',
  OnboardingSignUp = '/onboarding-sign-up',

  NametagsForAllInvite = '/event',
  CameraContext = '/camera',
  ImmersiveContext = '/immersive',
  NametagBuilder = '/nametag-builder',

  TryWarmly = '/try-warmly',

  Plans = '/plans',

  Rio = 'https://app.getnametags.com/'

}

export const LIVE_UPDATE_EXCLUDE_NAVIGATION_PATHS = [
  NavigationPath.Login,
  NavigationPath.Logout,
  NavigationPath.Downloading,
  NavigationPath.PreAuthentication,
  NavigationPath.OnboardingZoom,
  NavigationPath.OnboardingSignUp,
  NavigationPath.OnboardingInvite,
  NavigationPath.CompanyDirectory,
  NavigationPath.CompanyProfile,
  NavigationPath.UserProfile,
  NavigationPath.NametagsForAllInvite,
  NavigationPath.CameraContext,
  NavigationPath.ImmersiveContext,
  NavigationPath.Settings,
  NavigationPath.Plans,
  NavigationPath.Profile,
  NavigationPath.Help,
  NavigationPath.Admin,
  NavigationPath.TryWarmly,
];

export const LIVE_UPDATE_NAVIGATION_PATHS = [NavigationPath.NametagBuilder];

export enum AdminDashboardNavigationPath {
  Groups = '/groups',
  GroupUsers = '/group-users',
  GroupSettings = '/group-settings',
  CompanySettings = '/company',
  Users = '/users',
  OrgSettings = '/org-settings',
  BrandManagement = '/brand-management',
  AuditLogs = '/audit-logs',
}

export enum WarmlyColor {
  DARK_BLUE = '#293767',
  WARMLY_PURPLE = '#4b5ea3',
  LIGHT_PURPLE = '#8D9CD4',
  SALMON = '#ff8769',
  SALMON_TRANSPARENT = 'rgba(255, 135, 105, 0.4)',
  WHITE = '#ffffff',
  BLACK = '#000000',
  LIGHT_BLUE = '#f4f8f9',
  GRAY = '#808080',
  LIGHT_GRAY = '#f5f5f5',
  DISABLED_GRAY = '#bdbdbd',
  DARK_GRAY = '#414141',
  LINKEDIN_BLUE = '#2867b2',
  TWITTER_BLUE = '#1da1f2',
  SELECTED_BLUE = '#e2edfb',
}

export enum UserRoleChipColor {
  PURPLE = '#4B5EA3',
  LIGHT_GRAY = '#AEB4A9',
  MAROON = '#89043D',
  BROWN = '#6D4C3D',
}

export enum AlertSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

/** Zoom App production publishable url used to either:
 * 1. If users already have our zoom app, then deeplinks users back into the zoom app
 * 2. If users don't have the zoom app, then send user to Zoom app authorization page
 */
const zoomAppPublishableUrlByEnv: Record<Environment, string> = {
  [Environment.Development]: ZOOM_APP_PUBLISHABLE_URL_DEV,
  [Environment.Staging]: 'https://rapport.warmly.ai/staging-app-invite',
  [Environment.Production]: 'https://rapport.warmly.ai/vip-beta',
};

const rioUrlByEnv: Record<Environment, string> = {
  [Environment.Development]: 'http://localhost:5000',
  [Environment.Staging]: 'https://staging.getnametags.com/',
  [Environment.Production]: 'https://app.getnametags.com/',
};

const zoomAppClientIdByEnv: Record<Environment, string> = {
  [Environment.Development]: '',
  [Environment.Staging]: 'k3WjYTSG2MuS11MaCsQ',
  [Environment.Production]: 'BQvXXy6wSfCL6x6D0LVG0Q',
};

const PUBLISHABLE_ZOOM_URL_FULL_LINK = `${ZOOM_EXTERNAL_URL.AUTHORIZE}?response_type=code&client_id=${zoomAppClientIdByEnv[CURRENT_ENVIRONMENT]}&redirect_uri=${ZOOM_API_BASE_URL}${ZOOM_API_ROUTE.ZOOM_AUTH}${ZOOM_AUTH_ENDPOINT.AUTH}`;

const zoomAppFullPublishableUrlByEnv: Record<Environment, string> = {
  [Environment.Development]: ZOOM_APP_PUBLISHABLE_URL_DEV,
  [Environment.Staging]: PUBLISHABLE_ZOOM_URL_FULL_LINK,
  [Environment.Production]: PUBLISHABLE_ZOOM_URL_FULL_LINK,
};

export const ZOOM_PUBLISHABLE_URL = zoomAppPublishableUrlByEnv[CURRENT_ENVIRONMENT];
export const RIO_URL = rioUrlByEnv[CURRENT_ENVIRONMENT];

/**
 * We need to use the full publishable url to pass query parameters when deeplinking the user into the zoom client
 */
export const ZOOM_FULL_PUBLISHABLE_URL = zoomAppFullPublishableUrlByEnv[CURRENT_ENVIRONMENT];

const zoomDeeplinkUrlByEnv: Record<Environment, string> = {
  [Environment.Development]:
    'https://marketplace.zoom.us/zoomapp/BQvXXy6wSfCL6x6D0LVG0Q/context/meeting/target/launch/deeplink',
  [Environment.Staging]:
    'https://marketplace.zoom.us/zoomapp/BQvXXy6wSfCL6x6D0LVG0Q/context/meeting/target/launch/deeplink',
  [Environment.Production]:
    'https://marketplace.zoom.us/zoomapp/BQvXXy6wSfCL6x6D0LVG0Q/context/meeting/target/launch/deeplink',
};
export const ZOOM_DEEPLINK_URL = zoomDeeplinkUrlByEnv[CURRENT_ENVIRONMENT];

export type DeepLinkAction = 'goto' | 'update';

export interface DeepLinkActions extends Partial<Record<DeepLinkAction, string>> {
  goto?: NavigationPath;
  update?: 'true' | 'false';
  query?: Partial<Record<QueryParam, string>>;
}

/**
 * LocalStorage shouldSetVirtualBackground flag lifespan - 1 minute in ms
 */
export const SHOULD_SET_VIRTUAL_BACKGROUND_LIFESPAN = 60000;

export interface AgendaItem {
  content: string;
  completed: boolean;
}

export const AllWidgetKeys = [
  'none',
  'agendaWidget',
  'shoutoutWidget',
  'conversationStartersWidget',
  'imagesWidget',
  'gifsWidget',
  'slidesWidget',
  'logoPlusLogoWidget',
  'videoCanvasExperiencesWidget',
  'matchBackgroundsWidget',
  'qrCodeWidget',
  'clockWidget',
  'customWidget',
  'shuffleWidget',
  'playingCardsWidget',
  'crawlerWidget',
  'meetingAlarmWidget',
  'weatherWidget',
] as const;

export interface WidgetKeys {
  none: undefined;
  agendaWidget: { items: AgendaItem[] };
  shoutoutWidget: string;
  conversationStartersWidget: {
    items: ConversationStarterAnswerForPrompt[];
    item: ConversationStarterAnswerForPrompt | undefined;
  };

  imagesWidget: { selectedShoutoutImageType?: ShoutoutImageType; shoutoutCustomImageUrl?: string };
  gifsWidget: { selectedShoutoutImageType?: ShoutoutImageType; shoutoutCustomImageUrl?: string };

  slidesWidget: { imageUrl?: string };
  qrCodeWidget: { value?: string };

  logoPlusLogoWidget: {
    selectedShoutoutImageType?: ShoutoutImageType;
    shoutoutCustomImageUrl?: string;
    userLogoUrl?: string;
    otherLogoUrl?: string;
  };

  videoCanvasExperiencesWidget: unknown;
  customWidget: string;
  matchBackgroundsWidget: unknown;
  clockWidget: { type: 'digital' | 'analog' | 'both'; position: 'top' | 'bottom'; location: string | undefined };
  shuffleWidget: { value: string; transform: string }[];
  playingCardsWidget: { value: string; transform: string }[];
  crawlerWidget: { title: string; text: string };
  meetingAlarmWidget: { end: number | undefined; duration: number | undefined; position: 'top' | 'bottom' };
  weatherWidget: {
    position: 'top' | 'bottom';
    weatherString: string | undefined;
    weatherIcon: string | undefined;
  };
}

export type ConcreteWidgetType = {
  [Property in keyof WidgetKeys]+?: WidgetStore<WidgetKeys[Property]>;
};

export type WIDGET_KEY = keyof WidgetKeys;
