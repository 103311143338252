/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
interface TheOrgStoreState {
  /**
   * Record of orgs and resulting org URLs
   */
  orgLinks: Record<string, string>;
}

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class TheOrgStore implements TheOrgStoreState {
  orgLinks = {};

  /**
   * @param disablePersistence if true does not persist the store
   */
  constructor(disablePersistence: boolean) {
    makeAutoObservable(this);
    !disablePersistence &&
      makePersistable(this, {
        name: 'TheOrgStore',
        properties: ['orgLinks'],
        storage: window.localStorage,
      });
  }

  addOrgLink = (org: string, url: string) => {
    this.orgLinks[org] = url;
  };
}
