import { RoleBucket, RoleBucketCaseSensitive, RoleBucketEmailDomain, RoleTitle } from 'src/utils/constants';

/**
 * Check whether a person's field string (e.g. title) contains the role bucket titles
 * @param fieldString The person's field string (e.g. title)
 * @param roleBucket The role bucket titles
 * @returns boolean of whether the person's field string contains the role bucket title
 */
const checkFieldStringIncludesRoleBucketTitle = (fieldString: string, roleBucket: string[]): boolean => {
  return roleBucket.some((roleBucketTitle) => fieldString.includes(roleBucketTitle));
};

/**
 * Categorize a person into one of the role Bucket Titles
 * @param title The person's title
 * @param email The person's email
 * @returns The role Bucket Title
 */
export const getRoleBucket = (title?: string | null, email?: string): RoleTitle => {
  if (!title || typeof title !== 'string') return RoleTitle.NoTitle;

  const lowerCaseTitle = title.toLowerCase();

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.founderCeo)) {
    return RoleTitle.FounderCeo;
  }

  if (
    checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.businessDevelopmentSales) ||
    checkFieldStringIncludesRoleBucketTitle(title, RoleBucketCaseSensitive.businessDevelopmentSales)
  ) {
    return RoleTitle.BusinessDevelopmentSales;
  }

  if (
    checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.executive) ||
    checkFieldStringIncludesRoleBucketTitle(title, RoleBucketCaseSensitive.executive)
  ) {
    return RoleTitle.Executive;
  }

  if (
    checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.investor) ||
    (email && checkFieldStringIncludesRoleBucketTitle(email.toLowerCase(), RoleBucketEmailDomain.investor))
  ) {
    return RoleTitle.Investor;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.customerSuccess)) {
    return RoleTitle.CustomerSuccess;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.marketer)) {
    return RoleTitle.Marketing;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.recruiting)) {
    return RoleTitle.Recruiting;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.engineer)) {
    return RoleTitle.Engineer;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.product)) {
    return RoleTitle.Product;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.operations)) {
    return RoleTitle.Operations;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.projectManager)) {
    return RoleTitle.ProjectManager;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.student)) {
    return RoleTitle.Student;
  }

  if (checkFieldStringIncludesRoleBucketTitle(lowerCaseTitle, RoleBucket.events)) {
    return RoleTitle.Event;
  }

  return RoleTitle.Other;
};
