// TODO: Update once free-email-domains types are fixed
// eslint-disable-next-line @typescript-eslint/no-var-requires
const freeEmailDomains = require('free-email-domains') as string[];

/**
 * Determines whether an email domain is from a free email provider
 * @param emailDomain The email domain (e.g., for `user@gmail.com`, the emailDomain should be `gmail.com`)
 * @returns Wether the email domain is that of a free email provider
 */
export const isFreeEmailDomain = (emailDomain: string): boolean => {
  return freeEmailDomains.includes(emailDomain);
};
