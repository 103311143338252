/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import {
  addHours,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  intervalToDuration,
} from 'date-fns';
import { isNil } from 'lodash-es';

/* --------------------------------- CUSTOM --------------------------------- */
import { TimeDiffs } from 'src/hooks/useTimeDiffs';
import { Weekday } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export interface DateTime {
  weekday: string;
  year: string;
  month: string;
  day: string;
  time: string;
  amPm: string;
  timeZone: string;
  timeMs: number;
}

interface TargetDates {
  startOfToday: Date;
  endOfToday: Date;
  endOfSevenDays: Date;
  endOfTwoDays: Date;
  stableCurrentTime: Date;
}

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/**
 * Converts UTC time to local DateTime object
 * @param unixTimestamp The UTC datetime string to convert
 * @returns Converted DateTime object
 */
export const getDateTimeFromUnixTimestamp = (unixTimestamp: string): DateTime => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'long',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  } as Intl.DateTimeFormatOptions;
  const dateTime = new Date(unixTimestamp);
  const dateTimeArray = dateTime.toLocaleString('en-US', options).replace(/,/g, '').split(' ');
  return {
    weekday: dateTimeArray[0],
    month: dateTimeArray[1],
    day: dateTimeArray[2],
    year: dateTimeArray[3],
    time: dateTimeArray[4],
    amPm: dateTimeArray[5]?.toLowerCase() || '',
    timeZone: dateTimeArray.slice(6).join(' '),
    timeMs: dateTime.getTime(),
  } as DateTime;
};

/**
 * Sets the passed in date to the end of same day
 * @param date The date to change to end of same day
 * @returns Same date object but set to the end of same day
 */
export const setToEndOfDay = (date: Date): Date => {
  date.setHours(23, 59, 59, 999);
  return date;
};

/**
 * Gets the millisecond representation of passed in number of days
 * @param days Number of days
 * @returns The millisecond representation of days
 */
export const getDaysMs = (days = 1): number => days * 24 * 60 * 60 * 1000;

/**
 * Gets a Date object for the start of the last 30 days
 * @returns A Date object
 */
export const getStartOfLastThirtyDays = () => {
  const startOfLastThirtyDays = new Date(Date.now() - getDaysMs(30));
  startOfLastThirtyDays.setHours(0, 0, 0, 0);
  return startOfLastThirtyDays;
};

/**
 * Gets target dates (eod + eow)
 * @returns Now and target dates in Date format
 */
export const getTargetDates = (): TargetDates => {
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  const endOfToday = new Date();
  setToEndOfDay(endOfToday);
  const endOfTwoDays = new Date(Date.now() + getDaysMs(1));
  setToEndOfDay(endOfTwoDays);
  const endOfSevenDays = new Date(Date.now() + getDaysMs(6));
  setToEndOfDay(endOfSevenDays);
  const stableCurrentTime = new Date();
  stableCurrentTime.setMilliseconds(0);

  return {
    startOfToday,
    endOfToday,
    endOfTwoDays,
    endOfSevenDays,
    stableCurrentTime,
  };
};

/**
 * Gets the US English name of the day
 * @param date The date from which to get the name
 * @returns The name of the day
 */
export const getDayName = (date: Date): Weekday => {
  return date.toLocaleString('en-us', { weekday: 'long' }).toLowerCase() as Weekday;
};

/**
 * Gets the title of the name including 'today' and 'tomorrow'
 * @param dayName The weekday for which to get the title
 * @returns The title of the day
 */
export const getDayTitle = (dayName: Weekday): Weekday | 'today' | 'tomorrow' => {
  const now = new Date();
  const todayName = getDayName(now);
  const tomorrowName = getDayName(new Date(now.getTime() + getDaysMs()));
  switch (dayName) {
    case todayName:
      return 'today';
    case tomorrowName:
      return 'tomorrow';
    default:
      return dayName;
  }
};

/**
 * Returns string representation of time difference since last meeting with the participant
 * >= 7 days: "# weeks ago"
 * < 7 days: "# days ago"
 * <1 day: "# hours ago"
 * <1 hour: "# minutes ago"
 * @param lastMeetingEndTimeString last meeting with participants' calendar event end time
 * @returns String representation of the time since last meeting with a participant, or undefined
 */
export const getTimeSinceLastMeetingString = (lastMeetingEndTimeString?: string | null): string | undefined => {
  if (!lastMeetingEndTimeString) {
    return;
  }
  const weekInHours = 24 * 7;
  const dayInHours = 24;
  const currentTime = new Date();
  const lastMeetingEndedTime = new Date(lastMeetingEndTimeString);
  const hoursSinceLastMeeting = differenceInHours(currentTime, lastMeetingEndedTime);

  if (hoursSinceLastMeeting >= weekInHours) {
    const weeksSinceLastMeeting = Math.floor(hoursSinceLastMeeting / weekInHours);
    return `${weeksSinceLastMeeting} week${weeksSinceLastMeeting === 1 ? '' : 's'} ago`;
  } else if (hoursSinceLastMeeting < weekInHours && hoursSinceLastMeeting >= dayInHours) {
    const daysSinceLastMeeting = Math.floor(hoursSinceLastMeeting / dayInHours);
    return `${daysSinceLastMeeting} day${daysSinceLastMeeting === 1 ? '' : 's'} ago`;
  } else if (hoursSinceLastMeeting < 1) {
    const minutesSinceLastMeeting = differenceInMinutes(currentTime, lastMeetingEndedTime);
    return `${minutesSinceLastMeeting} minute${minutesSinceLastMeeting === 1 ? '' : 's'} ago`;
  } else {
    return `${hoursSinceLastMeeting} hour${hoursSinceLastMeeting === 1 ? '' : 's'} ago`;
  }
};

/**
 * Returns difference between now and beginning/end of meeting
 * @param params.currentTime The current time of the user
 * @param params.startTimeMs The meeting start time in Unix time (ms)
 * @param params.endTimeMs The meeting end time in Unix time (ms)
 * @returns Time differences from now (untilEnd and untilStart) in seconds
 */
export const getTimeDiffs = ({
  currentTime = Date.now(),
  startTimeMs,
  endTimeMs,
}: {
  currentTime?: number;
  startTimeMs?: number;
  endTimeMs?: number;
}): TimeDiffs | undefined => {
  if (!startTimeMs || !endTimeMs) {
    return;
  }
  const untilStart: number = Math.round((startTimeMs - currentTime) / 1000);
  const untilEnd: number = Math.round((endTimeMs - currentTime) / 1000);
  return { untilEnd, untilStart };
};

/**
 * Returns string representation of meeting time difference. If the meeting
 * is within 24 hours of now, we show more detailed time difference (hours/min/seconds),
 * otherwise we should the difference in days
 *
 * Examples:
 *
 * `in 11h`
 *
 * `10m ago`
 *
 * `30m left`
 *
 * `in 4 days`
 * @param timeDiffs The difference in time
 * @param isCurrentMeeting Whether this meeting is the currently selected meeting
 * @returns Absolute value of meeting time difference in display format
 */
export const getTimeDiffString = (timeDiffs?: TimeDiffs, isCurrentMeeting?: boolean): string | undefined => {
  if (!timeDiffs) {
    return undefined;
  }
  const inFuture = timeDiffs.untilStart > 0;
  const diff = inFuture ? timeDiffs.untilStart : timeDiffs.untilEnd;
  const secsLeft = Math.abs(diff);
  const secs = secsLeft % 60;
  const minsLeft = secsLeft / 60;
  const mins = Math.ceil(minsLeft % 60);
  const hours = Math.floor(minsLeft / 60);

  // If the meeting is within 24 hours of now, we show the more precised time diff
  if (hours < 24) {
    return (
      inFuture || isCurrentMeeting
        ? (inFuture ? 'in ' : '') +
          (hours ? `${hours}h ` : '') +
          (mins > 1 ? `${mins}m ` : '') +
          (!hours && mins <= 1 ? `${secs}s ` : '') +
          (!inFuture ? (diff < 0 ? 'over' : 'left') : '')
        : ''
    ).trim();
  } else {
    // If the meeting is over 24 hours ago/away, we show a more rough time diff (X days away/ago)
    const differenceInDays = differenceInCalendarDays(addHours(new Date(), hours), new Date());
    return (
      inFuture || isCurrentMeeting
        ? (inFuture ? 'in ' : '') +
          (differenceInDays ? `${differenceInDays} ` : '1 ') +
          (differenceInDays <= 1 ? 'day' : 'days') +
          (!inFuture ? (diff < 0 ? 'over' : 'left') : '')
        : ''
    ).trim();
  }
};

/**
 * Gets the "1 yr 1 mo" patterned string
 * @param startDate The start date to use
 * @returns The generated string
 */
export const getYearMonthString = (startDate: Date) => {
  const duration = intervalToDuration({ start: startDate, end: new Date() });
  let years = duration.years || 0;

  // Round days up to the next month
  let months = !isNil(duration.months) && !isNil(duration.days) ? duration.months + (duration.days > 0 ? 1 : 0) : 0;

  // Round up to next year instead of showing "12 mo"
  if (months === 12) {
    ++years;
    months = 0;
  }

  return [years ? `${years} yr` : '', months ? `${months} mo` : ''].join(' ').trim();
};

/**
 * Gets the "Started Jan 2020 (1 yr 1 mo)" patterned string
 * @param startDateIso The ISO of the start date to use
 * @returns The generated string
 */
export const getStartDateString = (startDateIso?: string) => {
  if (!startDateIso) return;
  const startDate = new Date(startDateIso);
  const monthYear = startDate.toLocaleString('en-us', { month: 'short', year: 'numeric' });
  return `Started ${monthYear} (${getYearMonthString(startDate)})`;
};

/**
 * Gets the "1/1/1900" | "Today" | "Yesterday" patterned string
 * @param startDateIso The ISO of the start date to use
 * @returns The generated string
 */
export const getSignedUpString = (startDateIso?: string) => {
  if (!startDateIso) return;
  const signedUp = new Date(startDateIso);

  if (isToday(signedUp)) return 'Today';
  if (isYesterday(signedUp)) return 'Yesterday';

  return signedUp.toLocaleDateString();
};

function isToday(date: Date) {
  const today = new Date();
  return today.toDateString() === date.toDateString();
}

function isYesterday(date: Date) {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toDateString() === date.toDateString();
}
