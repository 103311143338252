/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
import { useNavigate } from 'react-router-dom';

import { useClearDeepLinkActionMutation, useGetDeepLinkActionQuery } from 'src/graphql/main';
import { useStores } from 'src/hooks/useStores';
import { DeepLinkAction, LocalStorageItem, QueryParam } from 'src/utils/constants';

/* -------------------------------- FUNCTIONS ------------------------------- */
// Executes local storage setting actions from query params
export const executeLocalStorageActions = (actionString?: string | null) => {
  const action = actionString ?? window.localStorage.getItem(LocalStorageItem.DeepLinkAction);
  if (!action) return;

  const allActions = action.split(',');
  const splits = allActions.map((action) => action.split(':'));
  const localStorageActions = splits.filter(([key]) => key.includes('ls-'));

  localStorageActions.forEach((qpa) => localStorage.setItem(qpa[0].replace('ls-', ''), qpa[1]));
};

export const convertQuery = (query?: Partial<Record<QueryParam, string>>) =>
  Object.entries(query || {})
    .map(([key, val]) => `q-${key}:${val}`)
    .join(',');

/* -------------------------------------------------------------------------- */
/*                               HOOK DEFINITION                              */
/* -------------------------------------------------------------------------- */
const useDeepLink = () => {
  /* ---------------------------------- HOOKS --------------------------------- */
  /**
   * This hook may be used by logged-in and non-logged-in users
   */
  const {
    userStore: { user },
    liveUpdateStore: { setShouldLiveUpdateBackground, setShouldLiveUpdateForeground },
  } = useStores();
  const navigate = useNavigate();
  const [clearDeepLinkAction] = useClearDeepLinkActionMutation();
  const { loading: deepLinkActionLoading } = useGetDeepLinkActionQuery({
    skip: !user,
    onCompleted: ({ getDeepLinkAction: deepLinkAction }) => {
      if (deepLinkAction) window.localStorage.setItem(LocalStorageItem.DeepLinkAction, deepLinkAction);
    },
  });

  /* -------------------------------- FUNCTIONS ------------------------------- */
  /**
   *  Indicates whether zoom deep link will perform a redirect once executed
   */
  const shouldDeepLinkRedirect = () => window.localStorage.getItem(LocalStorageItem.DeepLinkAction)?.includes('goto');

  /**
   *  Executes the deep link action present in localstorage
   */
  const executeDeepLinkAction = () => {
    const action = window.localStorage.getItem(LocalStorageItem.DeepLinkAction);
    if (!action) return;

    // Remove caches of deep link action to ensure its not executed more than once
    window.localStorage.removeItem(LocalStorageItem.DeepLinkAction);
    clearDeepLinkAction();

    /**
     * Note that since we split actions using `,`, we must ensure that param values should not contain the `,` character in it or
     * it might get treated as a separate action (e.g., any URL that has `,` in it)
     */
    const allActions = action.split(',');

    /**
     * Note that since we split action queriy params using `:`, we must ensure that param values should not contain the `:` character in it or
     * it might get treated as a separate query param (e.g., `https://warmly.ai` will be split when we don't want it to)
     */
    const splits = allActions.map((action) => action.split(':'));
    const queryParamActions = splits.filter(([key]) => key.includes('q-'));
    const queryParams = queryParamActions.map((qpa) => qpa[0].replace('q-', '') + '=' + qpa[1]).join('&');
    const otherActions = splits.filter(([key]) => !key.includes('q-') && !key.includes('ls-'));
    executeLocalStorageActions(action);

    otherActions.forEach(([_key, value]) => {
      const action = _key as DeepLinkAction;
      const param = value;

      switch (action) {
        case 'goto':
          if (param) navigate(`${param}${queryParams.length ? `?${queryParams}` : ''}`);
          break;
        case 'update':
          if (param === 'true') {
            setShouldLiveUpdateBackground(true);
            setShouldLiveUpdateForeground(true);
          }
      }
    });
  };

  return {
    deepLinkActionLoading,
    clearDeepLinkAction,
    shouldDeepLinkRedirect,
    executeDeepLinkAction,
  };
};

export default useDeepLink;
