import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"clientName":"backend"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AppStatus = {
  __typename?: 'AppStatus';
  integrationType?: Maybe<Scalars['String']>;
  sso?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  tokenStatus?: Maybe<Scalars['String']>;
};

export type CanEditCompanyResponse = {
  __typename?: 'CanEditCompanyResponse';
  canEdit: Scalars['Boolean'];
  requiresPermission: Scalars['Boolean'];
};

export type ConnectCalendarResults = {
  __typename?: 'ConnectCalendarResults';
  calendarConnected: Scalars['Boolean'];
  email: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  shouldConnectCalendar: Scalars['Boolean'];
  zoomUserId: Scalars['String'];
};

export type ContactSuggestionOptions = {
  includeZoomUsers?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  searchOrg?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type ContactSuggestionResult = {
  __typename?: 'ContactSuggestionResult';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export enum EnableSettingForOrgIdType {
  AiBackground = 'AiBackground',
  BackgroundUpload = 'BackgroundUpload',
  CalendarIntegration = 'CalendarIntegration',
  CalendarSignatures = 'CalendarSignatures',
  ImageUpload = 'ImageUpload',
  NonWarmlyInjections = 'NonWarmlyInjections',
  PublicProfiles = 'PublicProfiles',
  RecurringEventsInjection = 'RecurringEventsInjection'
}

export type EncodedIdBackfillResult = {
  __typename?: 'EncodedIdBackfillResult';
  backfilled: Scalars['Float'];
  contactsWithoutEncodedId: Scalars['Float'];
  contactsWithoutEncodedIdAfterBackfill: Scalars['Float'];
  notBackfilled: Scalars['Float'];
  totalContactCount: Scalars['Float'];
};

export type EncodedIdBackfillStats = {
  __typename?: 'EncodedIdBackfillStats';
  contactsWithoutEncodedId: Scalars['Float'];
  totalContactCount: Scalars['Float'];
};

export type Feature = {
  __typename?: 'Feature';
  description: Scalars['String'];
  identifier: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type FeatureAccess = {
  __typename?: 'FeatureAccess';
  hasAccess: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
};

export type InviteSuggestionOptions = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type InviteSuggestionResult = {
  __typename?: 'InviteSuggestionResult';
  email: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignToOrganization: Scalars['String'];
  backfillEncodedIds: EncodedIdBackfillResult;
  backfillOrgMembershipAssociations: Scalars['String'];
  backfillUsernames: UsernameBackfillResult;
  clearDeepLinkAction: Scalars['Boolean'];
  connectMicrosoftCalendars: Array<ConnectCalendarResults>;
  createExport: Scalars['String'];
  createStripeSession: StripeSession;
  createStripeSubscription: StripeSubscription;
  deleteSelf?: Maybe<Scalars['String']>;
  deleteUsersForOrg: Scalars['Boolean'];
  generateImage: Scalars['String'];
  getExport: Scalars['String'];
  mergeCompanies: Scalars['String'];
  mergeOrganizations: Scalars['String'];
  resyncMSCalendar: Scalars['String'];
  setDefaultStripePaymentMethod: Scalars['Boolean'];
  setZoomSetting: Scalars['String'];
  setZoomSettings: Scalars['String'];
  submitAuditLogEvent: Scalars['String'];
  toggleMembershipType: Scalars['String'];
  uploadPresentation: Scalars['String'];
  upsertOrgSetting: Scalars['String'];
};


export type MutationAssignToOrganizationArgs = {
  membershipId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationBackfillEncodedIdsArgs = {
  numberToBackfill: Scalars['Float'];
};


export type MutationCreateStripeSessionArgs = {
  stripePriceId: Scalars['String'];
};


export type MutationCreateStripeSubscriptionArgs = {
  stripePriceId: Scalars['String'];
};


export type MutationDeleteUsersForOrgArgs = {
  organizationId: Scalars['String'];
  skipUsers: Array<Scalars['String']>;
};


export type MutationGenerateImageArgs = {
  prompt: Scalars['String'];
  response_format: Scalars['String'];
};


export type MutationGetExportArgs = {
  auditLogId: Scalars['String'];
};


export type MutationMergeCompaniesArgs = {
  companyToMerge: Scalars['String'];
  companyToMergeInto: Scalars['String'];
};


export type MutationMergeOrganizationsArgs = {
  organizationToMerge: Scalars['String'];
  organizationToMergeInto: Scalars['String'];
};


export type MutationResyncMsCalendarArgs = {
  zoomUserId: Scalars['String'];
};


export type MutationSetDefaultStripePaymentMethodArgs = {
  stripePaymentMethodId: Scalars['String'];
};


export type MutationSetZoomSettingArgs = {
  zoomSetting: Scalars['JSON'];
};


export type MutationSetZoomSettingsArgs = {
  zoomSettingRecordUpdates: Scalars['JSON'];
};


export type MutationSubmitAuditLogEventArgs = {
  action: Scalars['String'];
  actor: WorkOsAuditLogActor;
  context: WorkOsAuditLogContext;
  metadata?: InputMaybe<Scalars['JSON']>;
  occurredAt: Scalars['DateTimeISO'];
  targets: Array<WorkOsAuditLogTarget>;
  version?: InputMaybe<Scalars['Float']>;
};


export type MutationToggleMembershipTypeArgs = {
  membershipId: Scalars['String'];
};


export type MutationUploadPresentationArgs = {
  file: Scalars['Upload'];
};


export type MutationUpsertOrgSettingArgs = {
  enabled: Scalars['Boolean'];
  orgId: Scalars['String'];
  type: EnableSettingForOrgIdType;
};

export type Plan = {
  __typename?: 'Plan';
  currency?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  features: Array<Feature>;
  identifier: Scalars['String'];
  interval?: Maybe<Scalars['String']>;
  isUserOnCurrentPlan: Scalars['Boolean'];
  name: Scalars['String'];
  pricingType?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  stripeProductId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Float']>;
};

export type PublicCompanyBackground = {
  __typename?: 'PublicCompanyBackground';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<PublicCompanyUser>;
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PublicCompanyInfo = {
  __typename?: 'PublicCompanyInfo';
  backgrounds: Array<PublicCompanyBackground>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  industry?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  users: Array<PublicCompanyUser>;
  yearFounded?: Maybe<Scalars['String']>;
};

export type PublicCompanyUser = {
  __typename?: 'PublicCompanyUser';
  createdAt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type PublicEvent = {
  __typename?: 'PublicEvent';
  id: Scalars['String'];
  participants?: Maybe<Array<PublicParticipant>>;
  start?: Maybe<Scalars['DateTimeISO']>;
  summary?: Maybe<Scalars['String']>;
};

export type PublicParticipant = {
  __typename?: 'PublicParticipant';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  ask?: Maybe<Scalars['String']>;
  company?: Maybe<PublicUserCompanyInfo>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  isInNetwork?: Maybe<Scalars['Boolean']>;
  isKeepInTouch?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSON']>;
  photoUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type PublicUserCompanyInfo = {
  __typename?: 'PublicUserCompanyInfo';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  industry?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  canEditCompanyDetails: CanEditCompanyResponse;
  contactSuggestions: Array<ContactSuggestionResult>;
  encodedIdBackfillStats: EncodedIdBackfillStats;
  getDeepLinkAction?: Maybe<Scalars['String']>;
  getPronunciation?: Maybe<Scalars['String']>;
  hasAccessVerbose: FeatureAccess;
  inviteSuggestions: Array<InviteSuggestionResult>;
  msUsersWithoutCalendarCount: Scalars['Float'];
  onAppLoaded: AppStatus;
  plans?: Maybe<Array<Plan>>;
  publicCompanyInfo?: Maybe<PublicCompanyInfo>;
  publicEvent?: Maybe<PublicEvent>;
  publicUser?: Maybe<PublicUser>;
  syncConnections: Scalars['String'];
  test?: Maybe<Scalars['String']>;
  usernameBackfillStats: UsernameBackfillStats;
  usernameIsValid?: Maybe<Scalars['Boolean']>;
  usernameSuggestion?: Maybe<Scalars['String']>;
};


export type QueryCanEditCompanyDetailsArgs = {
  companyId: Scalars['String'];
};


export type QueryContactSuggestionsArgs = {
  options?: InputMaybe<ContactSuggestionOptions>;
};


export type QueryGetPronunciationArgs = {
  input: Scalars['String'];
};


export type QueryHasAccessVerboseArgs = {
  featureIdentifier: Scalars['String'];
};


export type QueryInviteSuggestionsArgs = {
  options?: InputMaybe<InviteSuggestionOptions>;
};


export type QueryOnAppLoadedArgs = {
  context: Scalars['String'];
};


export type QueryPublicCompanyInfoArgs = {
  companyId: Scalars['String'];
};


export type QueryPublicEventArgs = {
  eventId?: InputMaybe<Scalars['String']>;
};


export type QueryPublicUserArgs = {
  contactId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type QueryUsernameIsValidArgs = {
  input: UsernameInput;
};

export type StripeSession = {
  __typename?: 'StripeSession';
  redirectUrl?: Maybe<Scalars['String']>;
  stripeCustomerId: Scalars['String'];
  stripeSessionId: Scalars['String'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  clientSecret?: Maybe<Scalars['String']>;
  isStripeSubscriptionActive: Scalars['Boolean'];
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
};

export type UsernameBackfillResult = {
  __typename?: 'UsernameBackfillResult';
  backfilled: Scalars['Float'];
  duplicatedUsernames: Scalars['Float'];
  notBackfilled: Scalars['Float'];
  resolvedDuplicates: Scalars['Float'];
  totalUserCount: Scalars['Float'];
  usersWithoutUsername: Scalars['Float'];
};

export type UsernameBackfillStats = {
  __typename?: 'UsernameBackfillStats';
  duplicatedUsernames: Scalars['Float'];
  totalUserCount: Scalars['Float'];
  usersWithoutUsername: Scalars['Float'];
};

export type UsernameInput = {
  username: Scalars['String'];
};

export type WorkOsAuditLogActor = {
  id: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type WorkOsAuditLogContext = {
  location: Scalars['String'];
  userAgent?: InputMaybe<Scalars['String']>;
};

export type WorkOsAuditLogTarget = {
  id: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type BackfillUsernamesMutationVariables = Exact<{ [key: string]: never; }>;


export type BackfillUsernamesMutation = { __typename?: 'Mutation', backfillUsernames: { __typename?: 'UsernameBackfillResult', totalUserCount: number, usersWithoutUsername: number, backfilled: number, notBackfilled: number, duplicatedUsernames: number, resolvedDuplicates: number } };

export type BackfillOrgMembershipAssociationsMutationVariables = Exact<{ [key: string]: never; }>;


export type BackfillOrgMembershipAssociationsMutation = { __typename?: 'Mutation', backfillOrgMembershipAssociations: string };

export type UsernameBackfillStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type UsernameBackfillStatsQuery = { __typename?: 'Query', usernameBackfillStats: { __typename?: 'UsernameBackfillStats', totalUserCount: number, usersWithoutUsername: number, duplicatedUsernames: number } };

export type MsUsersWithoutCalendarCountQueryVariables = Exact<{ [key: string]: never; }>;


export type MsUsersWithoutCalendarCountQuery = { __typename?: 'Query', msUsersWithoutCalendarCount: number };

export type ConnectMicrosoftCalendarsMutationVariables = Exact<{ [key: string]: never; }>;


export type ConnectMicrosoftCalendarsMutation = { __typename?: 'Mutation', connectMicrosoftCalendars: Array<{ __typename?: 'ConnectCalendarResults', zoomUserId: string, email: string, calendarConnected: boolean, shouldConnectCalendar: boolean, error?: string | undefined }> };

export type ResyncMsCalendarMutationVariables = Exact<{
  zoomUserId: Scalars['String'];
}>;


export type ResyncMsCalendarMutation = { __typename?: 'Mutation', resyncMSCalendar: string };

export type GenerateImageMutationVariables = Exact<{
  prompt: Scalars['String'];
  response_format: Scalars['String'];
}>;


export type GenerateImageMutation = { __typename?: 'Mutation', generateImage: string };

export type SubmitAuditLogEventMutationVariables = Exact<{
  action: Scalars['String'];
  version?: InputMaybe<Scalars['Float']>;
  occurredAt: Scalars['DateTimeISO'];
  actor: WorkOsAuditLogActor;
  targets: Array<WorkOsAuditLogTarget> | WorkOsAuditLogTarget;
  context: WorkOsAuditLogContext;
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type SubmitAuditLogEventMutation = { __typename?: 'Mutation', submitAuditLogEvent: string };

export type GetExportMutationVariables = Exact<{
  auditLogId: Scalars['String'];
}>;


export type GetExportMutation = { __typename?: 'Mutation', getExport: string };

export type CreateExportMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateExportMutation = { __typename?: 'Mutation', createExport: string };

export type PublicCompanyInfoQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type PublicCompanyInfoQuery = { __typename?: 'Query', publicCompanyInfo?: { __typename?: 'PublicCompanyInfo', id: string, name?: string | undefined, logoUrl?: string | undefined, industry?: string | undefined, city?: string | undefined, state?: string | undefined, country?: string | undefined, description?: string | undefined, yearFounded?: string | undefined, estimatedSize?: string | undefined, twitterUrl?: string | undefined, linkedInUrl?: string | undefined, createdAt?: string | undefined, users: Array<{ __typename?: 'PublicCompanyUser', id?: string | undefined, photoUrl?: string | undefined, firstName?: string | undefined, lastName?: string | undefined, title?: string | undefined, createdAt?: string | undefined, username?: string | undefined }>, backgrounds: Array<{ __typename?: 'PublicCompanyBackground', id?: string | undefined, value?: string | undefined, weight?: number | undefined, user?: { __typename?: 'PublicCompanyUser', firstName?: string | undefined, photoUrl?: string | undefined } | undefined }> } | undefined };

export type ClearDeepLinkActionMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearDeepLinkActionMutation = { __typename?: 'Mutation', clearDeepLinkAction: boolean };

export type GetDeepLinkActionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeepLinkActionQuery = { __typename?: 'Query', getDeepLinkAction?: string | undefined };

export type OnAppLoadedQueryVariables = Exact<{
  context: Scalars['String'];
}>;


export type OnAppLoadedQuery = { __typename?: 'Query', onAppLoaded: { __typename?: 'AppStatus', success: boolean, integrationType?: string | undefined, tokenStatus?: string | undefined, sso?: string | undefined } };

export type UpsertOrgSettingMutationVariables = Exact<{
  orgId: Scalars['String'];
  type: EnableSettingForOrgIdType;
  enabled: Scalars['Boolean'];
}>;


export type UpsertOrgSettingMutation = { __typename?: 'Mutation', upsertOrgSetting: string };

export type PlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PlansQuery = { __typename?: 'Query', plans?: Array<{ __typename?: 'Plan', identifier: string, name: string, description: string, currency?: string | undefined, unitAmount?: number | undefined, interval?: string | undefined, pricingType?: string | undefined, isUserOnCurrentPlan: boolean, stripeProductId?: string | undefined, stripePriceId?: string | undefined, features: Array<{ __typename?: 'Feature', name: string, identifier: string, description: string, limit?: number | undefined }> }> | undefined };

export type CreateStripeSubscriptionMutationVariables = Exact<{
  stripePriceId: Scalars['String'];
}>;


export type CreateStripeSubscriptionMutation = { __typename?: 'Mutation', createStripeSubscription: { __typename?: 'StripeSubscription', stripeCustomerId: string, stripeSubscriptionId: string, isStripeSubscriptionActive: boolean, clientSecret?: string | undefined } };

export type CreateStripeSessionMutationVariables = Exact<{
  stripePriceId: Scalars['String'];
}>;


export type CreateStripeSessionMutation = { __typename?: 'Mutation', createStripeSession: { __typename?: 'StripeSession', stripeCustomerId: string, stripeSessionId: string, redirectUrl?: string | undefined } };

export type SetDefaultStripePaymentMethodMutationVariables = Exact<{
  stripePaymentMethodId: Scalars['String'];
}>;


export type SetDefaultStripePaymentMethodMutation = { __typename?: 'Mutation', setDefaultStripePaymentMethod: boolean };

export type UploadPresentationMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadPresentationMutation = { __typename?: 'Mutation', uploadPresentation: string };

export type GetPronunciationQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetPronunciationQuery = { __typename?: 'Query', getPronunciation?: string | undefined };

export type PublicEventQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['String']>;
}>;


export type PublicEventQuery = { __typename?: 'Query', publicEvent?: { __typename?: 'PublicEvent', summary?: string | undefined, start?: any | undefined, id: string, participants?: Array<{ __typename?: 'PublicParticipant', id: string }> | undefined } | undefined };

export type PublicUserQueryVariables = Exact<{
  contactId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
}>;


export type PublicUserQuery = { __typename?: 'Query', publicUser?: { __typename?: 'PublicUser', firstName?: string | undefined, lastName?: string | undefined, email?: string | undefined, linkedInUrl?: string | undefined, title?: string | undefined, department?: string | undefined, photoUrl?: string | undefined, twitterUrl?: string | undefined, headline?: string | undefined, location?: any | undefined, username?: string | undefined, firstNamePronunciation?: string | undefined, lastNamePronunciation?: string | undefined, pronouns?: string | undefined, companyName?: string | undefined, companyLogoUrl?: string | undefined, createdAt?: string | undefined, isInNetwork?: boolean | undefined, isKeepInTouch?: boolean | undefined, defaultCustomBackgroundPhotoUrl?: string | undefined, company?: { __typename?: 'PublicUserCompanyInfo', id: string, name?: string | undefined, logoUrl?: string | undefined, industry?: string | undefined, city?: string | undefined, state?: string | undefined, country?: string | undefined, description?: string | undefined, yearFounded?: string | undefined, estimatedSize?: string | undefined } | undefined } | undefined };

export type ContactSuggestionsQueryVariables = Exact<{
  options?: InputMaybe<ContactSuggestionOptions>;
}>;


export type ContactSuggestionsQuery = { __typename?: 'Query', contactSuggestions: Array<{ __typename?: 'ContactSuggestionResult', email: string, firstName?: string | undefined, lastName?: string | undefined }> };

export type InviteSuggestionsQueryVariables = Exact<{
  options?: InputMaybe<InviteSuggestionOptions>;
}>;


export type InviteSuggestionsQuery = { __typename?: 'Query', inviteSuggestions: Array<{ __typename?: 'InviteSuggestionResult', email: string, photoUrl?: string | undefined }> };

export type CanEditCompanyDetailsQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type CanEditCompanyDetailsQuery = { __typename?: 'Query', canEditCompanyDetails: { __typename?: 'CanEditCompanyResponse', requiresPermission: boolean, canEdit: boolean } };

export type DeleteSelfMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteSelfMutation = { __typename?: 'Mutation', deleteSelf?: string | undefined };

export type HasAccessVerboseQueryVariables = Exact<{
  featureIdentifier: Scalars['String'];
}>;


export type HasAccessVerboseQuery = { __typename?: 'Query', hasAccessVerbose: { __typename?: 'FeatureAccess', hasAccess: boolean, reason?: string | undefined } };

export type UsernameSuggestionQueryVariables = Exact<{ [key: string]: never; }>;


export type UsernameSuggestionQuery = { __typename?: 'Query', usernameSuggestion?: string | undefined };

export type UsernameIsValidQueryVariables = Exact<{
  input: UsernameInput;
}>;


export type UsernameIsValidQuery = { __typename?: 'Query', usernameIsValid?: boolean | undefined };


export const BackfillUsernamesDocument = gql`
    mutation BackfillUsernames {
  backfillUsernames {
    totalUserCount
    usersWithoutUsername
    backfilled
    notBackfilled
    duplicatedUsernames
    resolvedDuplicates
  }
}
    `;
export type BackfillUsernamesMutationFn = Apollo.MutationFunction<BackfillUsernamesMutation, BackfillUsernamesMutationVariables>;

/**
 * __useBackfillUsernamesMutation__
 *
 * To run a mutation, you first call `useBackfillUsernamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackfillUsernamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backfillUsernamesMutation, { data, loading, error }] = useBackfillUsernamesMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackfillUsernamesMutation(baseOptions?: Apollo.MutationHookOptions<BackfillUsernamesMutation, BackfillUsernamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackfillUsernamesMutation, BackfillUsernamesMutationVariables>(BackfillUsernamesDocument, options);
      }
export type BackfillUsernamesMutationHookResult = ReturnType<typeof useBackfillUsernamesMutation>;
export type BackfillUsernamesMutationResult = Apollo.MutationResult<BackfillUsernamesMutation>;
export type BackfillUsernamesMutationOptions = Apollo.BaseMutationOptions<BackfillUsernamesMutation, BackfillUsernamesMutationVariables>;
export const BackfillOrgMembershipAssociationsDocument = gql`
    mutation BackfillOrgMembershipAssociations {
  backfillOrgMembershipAssociations
}
    `;
export type BackfillOrgMembershipAssociationsMutationFn = Apollo.MutationFunction<BackfillOrgMembershipAssociationsMutation, BackfillOrgMembershipAssociationsMutationVariables>;

/**
 * __useBackfillOrgMembershipAssociationsMutation__
 *
 * To run a mutation, you first call `useBackfillOrgMembershipAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackfillOrgMembershipAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backfillOrgMembershipAssociationsMutation, { data, loading, error }] = useBackfillOrgMembershipAssociationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackfillOrgMembershipAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<BackfillOrgMembershipAssociationsMutation, BackfillOrgMembershipAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackfillOrgMembershipAssociationsMutation, BackfillOrgMembershipAssociationsMutationVariables>(BackfillOrgMembershipAssociationsDocument, options);
      }
export type BackfillOrgMembershipAssociationsMutationHookResult = ReturnType<typeof useBackfillOrgMembershipAssociationsMutation>;
export type BackfillOrgMembershipAssociationsMutationResult = Apollo.MutationResult<BackfillOrgMembershipAssociationsMutation>;
export type BackfillOrgMembershipAssociationsMutationOptions = Apollo.BaseMutationOptions<BackfillOrgMembershipAssociationsMutation, BackfillOrgMembershipAssociationsMutationVariables>;
export const UsernameBackfillStatsDocument = gql`
    query UsernameBackfillStats {
  usernameBackfillStats {
    totalUserCount
    usersWithoutUsername
    duplicatedUsernames
  }
}
    `;

/**
 * __useUsernameBackfillStatsQuery__
 *
 * To run a query within a React component, call `useUsernameBackfillStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameBackfillStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameBackfillStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsernameBackfillStatsQuery(baseOptions?: Apollo.QueryHookOptions<UsernameBackfillStatsQuery, UsernameBackfillStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsernameBackfillStatsQuery, UsernameBackfillStatsQueryVariables>(UsernameBackfillStatsDocument, options);
      }
export function useUsernameBackfillStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameBackfillStatsQuery, UsernameBackfillStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsernameBackfillStatsQuery, UsernameBackfillStatsQueryVariables>(UsernameBackfillStatsDocument, options);
        }
export type UsernameBackfillStatsQueryHookResult = ReturnType<typeof useUsernameBackfillStatsQuery>;
export type UsernameBackfillStatsLazyQueryHookResult = ReturnType<typeof useUsernameBackfillStatsLazyQuery>;
export type UsernameBackfillStatsQueryResult = Apollo.QueryResult<UsernameBackfillStatsQuery, UsernameBackfillStatsQueryVariables>;
export const MsUsersWithoutCalendarCountDocument = gql`
    query MsUsersWithoutCalendarCount {
  msUsersWithoutCalendarCount
}
    `;

/**
 * __useMsUsersWithoutCalendarCountQuery__
 *
 * To run a query within a React component, call `useMsUsersWithoutCalendarCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMsUsersWithoutCalendarCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMsUsersWithoutCalendarCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMsUsersWithoutCalendarCountQuery(baseOptions?: Apollo.QueryHookOptions<MsUsersWithoutCalendarCountQuery, MsUsersWithoutCalendarCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MsUsersWithoutCalendarCountQuery, MsUsersWithoutCalendarCountQueryVariables>(MsUsersWithoutCalendarCountDocument, options);
      }
export function useMsUsersWithoutCalendarCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MsUsersWithoutCalendarCountQuery, MsUsersWithoutCalendarCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MsUsersWithoutCalendarCountQuery, MsUsersWithoutCalendarCountQueryVariables>(MsUsersWithoutCalendarCountDocument, options);
        }
export type MsUsersWithoutCalendarCountQueryHookResult = ReturnType<typeof useMsUsersWithoutCalendarCountQuery>;
export type MsUsersWithoutCalendarCountLazyQueryHookResult = ReturnType<typeof useMsUsersWithoutCalendarCountLazyQuery>;
export type MsUsersWithoutCalendarCountQueryResult = Apollo.QueryResult<MsUsersWithoutCalendarCountQuery, MsUsersWithoutCalendarCountQueryVariables>;
export const ConnectMicrosoftCalendarsDocument = gql`
    mutation ConnectMicrosoftCalendars {
  connectMicrosoftCalendars {
    zoomUserId
    email
    calendarConnected
    shouldConnectCalendar
    error
  }
}
    `;
export type ConnectMicrosoftCalendarsMutationFn = Apollo.MutationFunction<ConnectMicrosoftCalendarsMutation, ConnectMicrosoftCalendarsMutationVariables>;

/**
 * __useConnectMicrosoftCalendarsMutation__
 *
 * To run a mutation, you first call `useConnectMicrosoftCalendarsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectMicrosoftCalendarsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectMicrosoftCalendarsMutation, { data, loading, error }] = useConnectMicrosoftCalendarsMutation({
 *   variables: {
 *   },
 * });
 */
export function useConnectMicrosoftCalendarsMutation(baseOptions?: Apollo.MutationHookOptions<ConnectMicrosoftCalendarsMutation, ConnectMicrosoftCalendarsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectMicrosoftCalendarsMutation, ConnectMicrosoftCalendarsMutationVariables>(ConnectMicrosoftCalendarsDocument, options);
      }
export type ConnectMicrosoftCalendarsMutationHookResult = ReturnType<typeof useConnectMicrosoftCalendarsMutation>;
export type ConnectMicrosoftCalendarsMutationResult = Apollo.MutationResult<ConnectMicrosoftCalendarsMutation>;
export type ConnectMicrosoftCalendarsMutationOptions = Apollo.BaseMutationOptions<ConnectMicrosoftCalendarsMutation, ConnectMicrosoftCalendarsMutationVariables>;
export const ResyncMsCalendarDocument = gql`
    mutation ResyncMSCalendar($zoomUserId: String!) {
  resyncMSCalendar(zoomUserId: $zoomUserId)
}
    `;
export type ResyncMsCalendarMutationFn = Apollo.MutationFunction<ResyncMsCalendarMutation, ResyncMsCalendarMutationVariables>;

/**
 * __useResyncMsCalendarMutation__
 *
 * To run a mutation, you first call `useResyncMsCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncMsCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncMsCalendarMutation, { data, loading, error }] = useResyncMsCalendarMutation({
 *   variables: {
 *      zoomUserId: // value for 'zoomUserId'
 *   },
 * });
 */
export function useResyncMsCalendarMutation(baseOptions?: Apollo.MutationHookOptions<ResyncMsCalendarMutation, ResyncMsCalendarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResyncMsCalendarMutation, ResyncMsCalendarMutationVariables>(ResyncMsCalendarDocument, options);
      }
export type ResyncMsCalendarMutationHookResult = ReturnType<typeof useResyncMsCalendarMutation>;
export type ResyncMsCalendarMutationResult = Apollo.MutationResult<ResyncMsCalendarMutation>;
export type ResyncMsCalendarMutationOptions = Apollo.BaseMutationOptions<ResyncMsCalendarMutation, ResyncMsCalendarMutationVariables>;
export const GenerateImageDocument = gql`
    mutation GenerateImage($prompt: String!, $response_format: String!) {
  generateImage(prompt: $prompt, response_format: $response_format)
}
    `;
export type GenerateImageMutationFn = Apollo.MutationFunction<GenerateImageMutation, GenerateImageMutationVariables>;

/**
 * __useGenerateImageMutation__
 *
 * To run a mutation, you first call `useGenerateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateImageMutation, { data, loading, error }] = useGenerateImageMutation({
 *   variables: {
 *      prompt: // value for 'prompt'
 *      response_format: // value for 'response_format'
 *   },
 * });
 */
export function useGenerateImageMutation(baseOptions?: Apollo.MutationHookOptions<GenerateImageMutation, GenerateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateImageMutation, GenerateImageMutationVariables>(GenerateImageDocument, options);
      }
export type GenerateImageMutationHookResult = ReturnType<typeof useGenerateImageMutation>;
export type GenerateImageMutationResult = Apollo.MutationResult<GenerateImageMutation>;
export type GenerateImageMutationOptions = Apollo.BaseMutationOptions<GenerateImageMutation, GenerateImageMutationVariables>;
export const SubmitAuditLogEventDocument = gql`
    mutation SubmitAuditLogEvent($action: String!, $version: Float, $occurredAt: DateTimeISO!, $actor: WorkOsAuditLogActor!, $targets: [WorkOsAuditLogTarget!]!, $context: WorkOsAuditLogContext!, $metadata: JSON) {
  submitAuditLogEvent(
    action: $action
    version: $version
    occurredAt: $occurredAt
    actor: $actor
    targets: $targets
    context: $context
    metadata: $metadata
  )
}
    `;
export type SubmitAuditLogEventMutationFn = Apollo.MutationFunction<SubmitAuditLogEventMutation, SubmitAuditLogEventMutationVariables>;

/**
 * __useSubmitAuditLogEventMutation__
 *
 * To run a mutation, you first call `useSubmitAuditLogEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAuditLogEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAuditLogEventMutation, { data, loading, error }] = useSubmitAuditLogEventMutation({
 *   variables: {
 *      action: // value for 'action'
 *      version: // value for 'version'
 *      occurredAt: // value for 'occurredAt'
 *      actor: // value for 'actor'
 *      targets: // value for 'targets'
 *      context: // value for 'context'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useSubmitAuditLogEventMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAuditLogEventMutation, SubmitAuditLogEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAuditLogEventMutation, SubmitAuditLogEventMutationVariables>(SubmitAuditLogEventDocument, options);
      }
export type SubmitAuditLogEventMutationHookResult = ReturnType<typeof useSubmitAuditLogEventMutation>;
export type SubmitAuditLogEventMutationResult = Apollo.MutationResult<SubmitAuditLogEventMutation>;
export type SubmitAuditLogEventMutationOptions = Apollo.BaseMutationOptions<SubmitAuditLogEventMutation, SubmitAuditLogEventMutationVariables>;
export const GetExportDocument = gql`
    mutation GetExport($auditLogId: String!) {
  getExport(auditLogId: $auditLogId)
}
    `;
export type GetExportMutationFn = Apollo.MutationFunction<GetExportMutation, GetExportMutationVariables>;

/**
 * __useGetExportMutation__
 *
 * To run a mutation, you first call `useGetExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getExportMutation, { data, loading, error }] = useGetExportMutation({
 *   variables: {
 *      auditLogId: // value for 'auditLogId'
 *   },
 * });
 */
export function useGetExportMutation(baseOptions?: Apollo.MutationHookOptions<GetExportMutation, GetExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetExportMutation, GetExportMutationVariables>(GetExportDocument, options);
      }
export type GetExportMutationHookResult = ReturnType<typeof useGetExportMutation>;
export type GetExportMutationResult = Apollo.MutationResult<GetExportMutation>;
export type GetExportMutationOptions = Apollo.BaseMutationOptions<GetExportMutation, GetExportMutationVariables>;
export const CreateExportDocument = gql`
    mutation CreateExport {
  createExport
}
    `;
export type CreateExportMutationFn = Apollo.MutationFunction<CreateExportMutation, CreateExportMutationVariables>;

/**
 * __useCreateExportMutation__
 *
 * To run a mutation, you first call `useCreateExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportMutation, { data, loading, error }] = useCreateExportMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateExportMutation(baseOptions?: Apollo.MutationHookOptions<CreateExportMutation, CreateExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExportMutation, CreateExportMutationVariables>(CreateExportDocument, options);
      }
export type CreateExportMutationHookResult = ReturnType<typeof useCreateExportMutation>;
export type CreateExportMutationResult = Apollo.MutationResult<CreateExportMutation>;
export type CreateExportMutationOptions = Apollo.BaseMutationOptions<CreateExportMutation, CreateExportMutationVariables>;
export const PublicCompanyInfoDocument = gql`
    query PublicCompanyInfo($companyId: String!) {
  publicCompanyInfo(companyId: $companyId) {
    id
    name
    logoUrl
    industry
    city
    state
    country
    description
    yearFounded
    estimatedSize
    twitterUrl
    linkedInUrl
    createdAt
    users {
      id
      photoUrl
      firstName
      lastName
      title
      createdAt
      username
    }
    backgrounds {
      id
      value
      weight
      user {
        firstName
        photoUrl
      }
    }
  }
}
    `;

/**
 * __usePublicCompanyInfoQuery__
 *
 * To run a query within a React component, call `usePublicCompanyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicCompanyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicCompanyInfoQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePublicCompanyInfoQuery(baseOptions: Apollo.QueryHookOptions<PublicCompanyInfoQuery, PublicCompanyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicCompanyInfoQuery, PublicCompanyInfoQueryVariables>(PublicCompanyInfoDocument, options);
      }
export function usePublicCompanyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicCompanyInfoQuery, PublicCompanyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicCompanyInfoQuery, PublicCompanyInfoQueryVariables>(PublicCompanyInfoDocument, options);
        }
export type PublicCompanyInfoQueryHookResult = ReturnType<typeof usePublicCompanyInfoQuery>;
export type PublicCompanyInfoLazyQueryHookResult = ReturnType<typeof usePublicCompanyInfoLazyQuery>;
export type PublicCompanyInfoQueryResult = Apollo.QueryResult<PublicCompanyInfoQuery, PublicCompanyInfoQueryVariables>;
export const ClearDeepLinkActionDocument = gql`
    mutation ClearDeepLinkAction {
  clearDeepLinkAction
}
    `;
export type ClearDeepLinkActionMutationFn = Apollo.MutationFunction<ClearDeepLinkActionMutation, ClearDeepLinkActionMutationVariables>;

/**
 * __useClearDeepLinkActionMutation__
 *
 * To run a mutation, you first call `useClearDeepLinkActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearDeepLinkActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearDeepLinkActionMutation, { data, loading, error }] = useClearDeepLinkActionMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearDeepLinkActionMutation(baseOptions?: Apollo.MutationHookOptions<ClearDeepLinkActionMutation, ClearDeepLinkActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearDeepLinkActionMutation, ClearDeepLinkActionMutationVariables>(ClearDeepLinkActionDocument, options);
      }
export type ClearDeepLinkActionMutationHookResult = ReturnType<typeof useClearDeepLinkActionMutation>;
export type ClearDeepLinkActionMutationResult = Apollo.MutationResult<ClearDeepLinkActionMutation>;
export type ClearDeepLinkActionMutationOptions = Apollo.BaseMutationOptions<ClearDeepLinkActionMutation, ClearDeepLinkActionMutationVariables>;
export const GetDeepLinkActionDocument = gql`
    query GetDeepLinkAction {
  getDeepLinkAction
}
    `;

/**
 * __useGetDeepLinkActionQuery__
 *
 * To run a query within a React component, call `useGetDeepLinkActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeepLinkActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeepLinkActionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeepLinkActionQuery(baseOptions?: Apollo.QueryHookOptions<GetDeepLinkActionQuery, GetDeepLinkActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeepLinkActionQuery, GetDeepLinkActionQueryVariables>(GetDeepLinkActionDocument, options);
      }
export function useGetDeepLinkActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeepLinkActionQuery, GetDeepLinkActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeepLinkActionQuery, GetDeepLinkActionQueryVariables>(GetDeepLinkActionDocument, options);
        }
export type GetDeepLinkActionQueryHookResult = ReturnType<typeof useGetDeepLinkActionQuery>;
export type GetDeepLinkActionLazyQueryHookResult = ReturnType<typeof useGetDeepLinkActionLazyQuery>;
export type GetDeepLinkActionQueryResult = Apollo.QueryResult<GetDeepLinkActionQuery, GetDeepLinkActionQueryVariables>;
export const OnAppLoadedDocument = gql`
    query OnAppLoaded($context: String!) {
  onAppLoaded(context: $context) {
    success
    integrationType
    tokenStatus
    sso
  }
}
    `;

/**
 * __useOnAppLoadedQuery__
 *
 * To run a query within a React component, call `useOnAppLoadedQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnAppLoadedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAppLoadedQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useOnAppLoadedQuery(baseOptions: Apollo.QueryHookOptions<OnAppLoadedQuery, OnAppLoadedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnAppLoadedQuery, OnAppLoadedQueryVariables>(OnAppLoadedDocument, options);
      }
export function useOnAppLoadedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnAppLoadedQuery, OnAppLoadedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnAppLoadedQuery, OnAppLoadedQueryVariables>(OnAppLoadedDocument, options);
        }
export type OnAppLoadedQueryHookResult = ReturnType<typeof useOnAppLoadedQuery>;
export type OnAppLoadedLazyQueryHookResult = ReturnType<typeof useOnAppLoadedLazyQuery>;
export type OnAppLoadedQueryResult = Apollo.QueryResult<OnAppLoadedQuery, OnAppLoadedQueryVariables>;
export const UpsertOrgSettingDocument = gql`
    mutation UpsertOrgSetting($orgId: String!, $type: EnableSettingForOrgIdType!, $enabled: Boolean!) {
  upsertOrgSetting(orgId: $orgId, type: $type, enabled: $enabled)
}
    `;
export type UpsertOrgSettingMutationFn = Apollo.MutationFunction<UpsertOrgSettingMutation, UpsertOrgSettingMutationVariables>;

/**
 * __useUpsertOrgSettingMutation__
 *
 * To run a mutation, you first call `useUpsertOrgSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrgSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrgSettingMutation, { data, loading, error }] = useUpsertOrgSettingMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      type: // value for 'type'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpsertOrgSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOrgSettingMutation, UpsertOrgSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOrgSettingMutation, UpsertOrgSettingMutationVariables>(UpsertOrgSettingDocument, options);
      }
export type UpsertOrgSettingMutationHookResult = ReturnType<typeof useUpsertOrgSettingMutation>;
export type UpsertOrgSettingMutationResult = Apollo.MutationResult<UpsertOrgSettingMutation>;
export type UpsertOrgSettingMutationOptions = Apollo.BaseMutationOptions<UpsertOrgSettingMutation, UpsertOrgSettingMutationVariables>;
export const PlansDocument = gql`
    query Plans {
  plans {
    identifier
    name
    description
    currency
    unitAmount
    interval
    pricingType
    isUserOnCurrentPlan
    stripeProductId
    stripePriceId
    features {
      name
      identifier
      description
      limit
    }
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const CreateStripeSubscriptionDocument = gql`
    mutation CreateStripeSubscription($stripePriceId: String!) {
  createStripeSubscription(stripePriceId: $stripePriceId) {
    stripeCustomerId
    stripeSubscriptionId
    isStripeSubscriptionActive
    clientSecret
  }
}
    `;
export type CreateStripeSubscriptionMutationFn = Apollo.MutationFunction<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>;

/**
 * __useCreateStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSubscriptionMutation, { data, loading, error }] = useCreateStripeSubscriptionMutation({
 *   variables: {
 *      stripePriceId: // value for 'stripePriceId'
 *   },
 * });
 */
export function useCreateStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>(CreateStripeSubscriptionDocument, options);
      }
export type CreateStripeSubscriptionMutationHookResult = ReturnType<typeof useCreateStripeSubscriptionMutation>;
export type CreateStripeSubscriptionMutationResult = Apollo.MutationResult<CreateStripeSubscriptionMutation>;
export type CreateStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateStripeSubscriptionMutation, CreateStripeSubscriptionMutationVariables>;
export const CreateStripeSessionDocument = gql`
    mutation CreateStripeSession($stripePriceId: String!) {
  createStripeSession(stripePriceId: $stripePriceId) {
    stripeCustomerId
    stripeSessionId
    redirectUrl
  }
}
    `;
export type CreateStripeSessionMutationFn = Apollo.MutationFunction<CreateStripeSessionMutation, CreateStripeSessionMutationVariables>;

/**
 * __useCreateStripeSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSessionMutation, { data, loading, error }] = useCreateStripeSessionMutation({
 *   variables: {
 *      stripePriceId: // value for 'stripePriceId'
 *   },
 * });
 */
export function useCreateStripeSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeSessionMutation, CreateStripeSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeSessionMutation, CreateStripeSessionMutationVariables>(CreateStripeSessionDocument, options);
      }
export type CreateStripeSessionMutationHookResult = ReturnType<typeof useCreateStripeSessionMutation>;
export type CreateStripeSessionMutationResult = Apollo.MutationResult<CreateStripeSessionMutation>;
export type CreateStripeSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeSessionMutation, CreateStripeSessionMutationVariables>;
export const SetDefaultStripePaymentMethodDocument = gql`
    mutation SetDefaultStripePaymentMethod($stripePaymentMethodId: String!) {
  setDefaultStripePaymentMethod(stripePaymentMethodId: $stripePaymentMethodId)
}
    `;
export type SetDefaultStripePaymentMethodMutationFn = Apollo.MutationFunction<SetDefaultStripePaymentMethodMutation, SetDefaultStripePaymentMethodMutationVariables>;

/**
 * __useSetDefaultStripePaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetDefaultStripePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultStripePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultStripePaymentMethodMutation, { data, loading, error }] = useSetDefaultStripePaymentMethodMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useSetDefaultStripePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultStripePaymentMethodMutation, SetDefaultStripePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultStripePaymentMethodMutation, SetDefaultStripePaymentMethodMutationVariables>(SetDefaultStripePaymentMethodDocument, options);
      }
export type SetDefaultStripePaymentMethodMutationHookResult = ReturnType<typeof useSetDefaultStripePaymentMethodMutation>;
export type SetDefaultStripePaymentMethodMutationResult = Apollo.MutationResult<SetDefaultStripePaymentMethodMutation>;
export type SetDefaultStripePaymentMethodMutationOptions = Apollo.BaseMutationOptions<SetDefaultStripePaymentMethodMutation, SetDefaultStripePaymentMethodMutationVariables>;
export const UploadPresentationDocument = gql`
    mutation UploadPresentation($file: Upload!) {
  uploadPresentation(file: $file)
}
    `;
export type UploadPresentationMutationFn = Apollo.MutationFunction<UploadPresentationMutation, UploadPresentationMutationVariables>;

/**
 * __useUploadPresentationMutation__
 *
 * To run a mutation, you first call `useUploadPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPresentationMutation, { data, loading, error }] = useUploadPresentationMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadPresentationMutation(baseOptions?: Apollo.MutationHookOptions<UploadPresentationMutation, UploadPresentationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPresentationMutation, UploadPresentationMutationVariables>(UploadPresentationDocument, options);
      }
export type UploadPresentationMutationHookResult = ReturnType<typeof useUploadPresentationMutation>;
export type UploadPresentationMutationResult = Apollo.MutationResult<UploadPresentationMutation>;
export type UploadPresentationMutationOptions = Apollo.BaseMutationOptions<UploadPresentationMutation, UploadPresentationMutationVariables>;
export const GetPronunciationDocument = gql`
    query GetPronunciation($input: String!) {
  getPronunciation(input: $input)
}
    `;

/**
 * __useGetPronunciationQuery__
 *
 * To run a query within a React component, call `useGetPronunciationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPronunciationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPronunciationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPronunciationQuery(baseOptions: Apollo.QueryHookOptions<GetPronunciationQuery, GetPronunciationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPronunciationQuery, GetPronunciationQueryVariables>(GetPronunciationDocument, options);
      }
export function useGetPronunciationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPronunciationQuery, GetPronunciationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPronunciationQuery, GetPronunciationQueryVariables>(GetPronunciationDocument, options);
        }
export type GetPronunciationQueryHookResult = ReturnType<typeof useGetPronunciationQuery>;
export type GetPronunciationLazyQueryHookResult = ReturnType<typeof useGetPronunciationLazyQuery>;
export type GetPronunciationQueryResult = Apollo.QueryResult<GetPronunciationQuery, GetPronunciationQueryVariables>;
export const PublicEventDocument = gql`
    query PublicEvent($eventId: String) {
  publicEvent(eventId: $eventId) {
    summary
    start
    id
    participants {
      id
    }
  }
}
    `;

/**
 * __usePublicEventQuery__
 *
 * To run a query within a React component, call `usePublicEventQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function usePublicEventQuery(baseOptions?: Apollo.QueryHookOptions<PublicEventQuery, PublicEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicEventQuery, PublicEventQueryVariables>(PublicEventDocument, options);
      }
export function usePublicEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicEventQuery, PublicEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicEventQuery, PublicEventQueryVariables>(PublicEventDocument, options);
        }
export type PublicEventQueryHookResult = ReturnType<typeof usePublicEventQuery>;
export type PublicEventLazyQueryHookResult = ReturnType<typeof usePublicEventLazyQuery>;
export type PublicEventQueryResult = Apollo.QueryResult<PublicEventQuery, PublicEventQueryVariables>;
export const PublicUserDocument = gql`
    query PublicUser($contactId: String, $username: String) {
  publicUser(contactId: $contactId, username: $username) {
    firstName
    lastName
    email
    linkedInUrl
    title
    department
    photoUrl
    twitterUrl
    headline
    location
    username
    firstNamePronunciation
    lastNamePronunciation
    pronouns
    companyName
    companyLogoUrl
    createdAt
    company {
      id
      name
      logoUrl
      industry
      city
      state
      country
      description
      yearFounded
      estimatedSize
    }
    isInNetwork
    isKeepInTouch
    defaultCustomBackgroundPhotoUrl
  }
}
    `;

/**
 * __usePublicUserQuery__
 *
 * To run a query within a React component, call `usePublicUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicUserQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      username: // value for 'username'
 *   },
 * });
 */
export function usePublicUserQuery(baseOptions?: Apollo.QueryHookOptions<PublicUserQuery, PublicUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicUserQuery, PublicUserQueryVariables>(PublicUserDocument, options);
      }
export function usePublicUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicUserQuery, PublicUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicUserQuery, PublicUserQueryVariables>(PublicUserDocument, options);
        }
export type PublicUserQueryHookResult = ReturnType<typeof usePublicUserQuery>;
export type PublicUserLazyQueryHookResult = ReturnType<typeof usePublicUserLazyQuery>;
export type PublicUserQueryResult = Apollo.QueryResult<PublicUserQuery, PublicUserQueryVariables>;
export const ContactSuggestionsDocument = gql`
    query ContactSuggestions($options: ContactSuggestionOptions) {
  contactSuggestions(options: $options) {
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useContactSuggestionsQuery__
 *
 * To run a query within a React component, call `useContactSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactSuggestionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useContactSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<ContactSuggestionsQuery, ContactSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactSuggestionsQuery, ContactSuggestionsQueryVariables>(ContactSuggestionsDocument, options);
      }
export function useContactSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactSuggestionsQuery, ContactSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactSuggestionsQuery, ContactSuggestionsQueryVariables>(ContactSuggestionsDocument, options);
        }
export type ContactSuggestionsQueryHookResult = ReturnType<typeof useContactSuggestionsQuery>;
export type ContactSuggestionsLazyQueryHookResult = ReturnType<typeof useContactSuggestionsLazyQuery>;
export type ContactSuggestionsQueryResult = Apollo.QueryResult<ContactSuggestionsQuery, ContactSuggestionsQueryVariables>;
export const InviteSuggestionsDocument = gql`
    query InviteSuggestions($options: InviteSuggestionOptions) {
  inviteSuggestions(options: $options) {
    email
    photoUrl
  }
}
    `;

/**
 * __useInviteSuggestionsQuery__
 *
 * To run a query within a React component, call `useInviteSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteSuggestionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useInviteSuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<InviteSuggestionsQuery, InviteSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteSuggestionsQuery, InviteSuggestionsQueryVariables>(InviteSuggestionsDocument, options);
      }
export function useInviteSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteSuggestionsQuery, InviteSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteSuggestionsQuery, InviteSuggestionsQueryVariables>(InviteSuggestionsDocument, options);
        }
export type InviteSuggestionsQueryHookResult = ReturnType<typeof useInviteSuggestionsQuery>;
export type InviteSuggestionsLazyQueryHookResult = ReturnType<typeof useInviteSuggestionsLazyQuery>;
export type InviteSuggestionsQueryResult = Apollo.QueryResult<InviteSuggestionsQuery, InviteSuggestionsQueryVariables>;
export const CanEditCompanyDetailsDocument = gql`
    query CanEditCompanyDetails($companyId: String!) {
  canEditCompanyDetails(companyId: $companyId) {
    requiresPermission
    canEdit
  }
}
    `;

/**
 * __useCanEditCompanyDetailsQuery__
 *
 * To run a query within a React component, call `useCanEditCompanyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanEditCompanyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanEditCompanyDetailsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCanEditCompanyDetailsQuery(baseOptions: Apollo.QueryHookOptions<CanEditCompanyDetailsQuery, CanEditCompanyDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanEditCompanyDetailsQuery, CanEditCompanyDetailsQueryVariables>(CanEditCompanyDetailsDocument, options);
      }
export function useCanEditCompanyDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanEditCompanyDetailsQuery, CanEditCompanyDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanEditCompanyDetailsQuery, CanEditCompanyDetailsQueryVariables>(CanEditCompanyDetailsDocument, options);
        }
export type CanEditCompanyDetailsQueryHookResult = ReturnType<typeof useCanEditCompanyDetailsQuery>;
export type CanEditCompanyDetailsLazyQueryHookResult = ReturnType<typeof useCanEditCompanyDetailsLazyQuery>;
export type CanEditCompanyDetailsQueryResult = Apollo.QueryResult<CanEditCompanyDetailsQuery, CanEditCompanyDetailsQueryVariables>;
export const DeleteSelfDocument = gql`
    mutation DeleteSelf {
  deleteSelf
}
    `;
export type DeleteSelfMutationFn = Apollo.MutationFunction<DeleteSelfMutation, DeleteSelfMutationVariables>;

/**
 * __useDeleteSelfMutation__
 *
 * To run a mutation, you first call `useDeleteSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSelfMutation, { data, loading, error }] = useDeleteSelfMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteSelfMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSelfMutation, DeleteSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSelfMutation, DeleteSelfMutationVariables>(DeleteSelfDocument, options);
      }
export type DeleteSelfMutationHookResult = ReturnType<typeof useDeleteSelfMutation>;
export type DeleteSelfMutationResult = Apollo.MutationResult<DeleteSelfMutation>;
export type DeleteSelfMutationOptions = Apollo.BaseMutationOptions<DeleteSelfMutation, DeleteSelfMutationVariables>;
export const HasAccessVerboseDocument = gql`
    query HasAccessVerbose($featureIdentifier: String!) {
  hasAccessVerbose(featureIdentifier: $featureIdentifier) {
    hasAccess
    reason
  }
}
    `;

/**
 * __useHasAccessVerboseQuery__
 *
 * To run a query within a React component, call `useHasAccessVerboseQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasAccessVerboseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasAccessVerboseQuery({
 *   variables: {
 *      featureIdentifier: // value for 'featureIdentifier'
 *   },
 * });
 */
export function useHasAccessVerboseQuery(baseOptions: Apollo.QueryHookOptions<HasAccessVerboseQuery, HasAccessVerboseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasAccessVerboseQuery, HasAccessVerboseQueryVariables>(HasAccessVerboseDocument, options);
      }
export function useHasAccessVerboseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasAccessVerboseQuery, HasAccessVerboseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasAccessVerboseQuery, HasAccessVerboseQueryVariables>(HasAccessVerboseDocument, options);
        }
export type HasAccessVerboseQueryHookResult = ReturnType<typeof useHasAccessVerboseQuery>;
export type HasAccessVerboseLazyQueryHookResult = ReturnType<typeof useHasAccessVerboseLazyQuery>;
export type HasAccessVerboseQueryResult = Apollo.QueryResult<HasAccessVerboseQuery, HasAccessVerboseQueryVariables>;
export const UsernameSuggestionDocument = gql`
    query UsernameSuggestion {
  usernameSuggestion
}
    `;

/**
 * __useUsernameSuggestionQuery__
 *
 * To run a query within a React component, call `useUsernameSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameSuggestionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsernameSuggestionQuery(baseOptions?: Apollo.QueryHookOptions<UsernameSuggestionQuery, UsernameSuggestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsernameSuggestionQuery, UsernameSuggestionQueryVariables>(UsernameSuggestionDocument, options);
      }
export function useUsernameSuggestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameSuggestionQuery, UsernameSuggestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsernameSuggestionQuery, UsernameSuggestionQueryVariables>(UsernameSuggestionDocument, options);
        }
export type UsernameSuggestionQueryHookResult = ReturnType<typeof useUsernameSuggestionQuery>;
export type UsernameSuggestionLazyQueryHookResult = ReturnType<typeof useUsernameSuggestionLazyQuery>;
export type UsernameSuggestionQueryResult = Apollo.QueryResult<UsernameSuggestionQuery, UsernameSuggestionQueryVariables>;
export const UsernameIsValidDocument = gql`
    query UsernameIsValid($input: UsernameInput!) {
  usernameIsValid(input: $input)
}
    `;

/**
 * __useUsernameIsValidQuery__
 *
 * To run a query within a React component, call `useUsernameIsValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameIsValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameIsValidQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsernameIsValidQuery(baseOptions: Apollo.QueryHookOptions<UsernameIsValidQuery, UsernameIsValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsernameIsValidQuery, UsernameIsValidQueryVariables>(UsernameIsValidDocument, options);
      }
export function useUsernameIsValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameIsValidQuery, UsernameIsValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsernameIsValidQuery, UsernameIsValidQueryVariables>(UsernameIsValidDocument, options);
        }
export type UsernameIsValidQueryHookResult = ReturnType<typeof useUsernameIsValidQuery>;
export type UsernameIsValidLazyQueryHookResult = ReturnType<typeof useUsernameIsValidLazyQuery>;
export type UsernameIsValidQueryResult = Apollo.QueryResult<UsernameIsValidQuery, UsernameIsValidQueryVariables>;