/* eslint-disable react-hooks/exhaustive-deps */
/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { EffectCallback, useEffect } from 'react';

/* -------------------------------------------------------------------------- */
/*                              HOOK DEFINITIONS                              */
/* -------------------------------------------------------------------------- */
export const useComponentDidMount = (func: EffectCallback) => useEffect(func, []);
export const useComponentWillUnmount = (func: ReturnType<EffectCallback>) => useEffect(() => () => func?.(), []);
