/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';

/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomContactFragment, ZoomUserFragment } from 'src/graphql';
import { getAppContext, getOs } from 'src/modules/analytics';
import { ZoomSdkApiMethodAll } from 'src/modules/zoomSdk';
import { zoomStatus } from 'src/reactiveVars/zoomStatus';
import { getRoleBucket } from 'src/utils/analytics';
import { PosthogCaptureProperties, PosthogEventName } from 'src/utils/constants';

export const usePosthogEvents = () => {
  const posthog = usePostHog();

  const identify = useCallback(
    async (
      user: ZoomUserFragment,
      contact?: ZoomContactFragment,
      properties?: { planTier?: string | undefined; zoomSdkSupportedApis?: ZoomSdkApiMethodAll[] | undefined }
    ) => {
      const buffer = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        role: user.role || '',
        title: user.title || '',
        createdAt: user.createdAt,
        city: user.city || '',
        profileUpdatedAt: user.profileUpdatedAt || '',
        recurringEventsInjectionEnabledAt: user.recurringEventsInjectionEnabledAt || '',
        firstNamePronunciation: user.firstNamePronunciation || '',
        lastNamePronunciation: user.lastNamePronunciation || '',
        pronouns: user.pronouns || '',
        googleScopes: (user.googleScopes as string[]) || [],
        scheduleMeetingUrl: user.scheduleMeetingUrl || '',
        customBackgroundPhotoUrl: user.customBackgroundPhotoUrl || '',
        defaultCustomBackgroundPhotoUrl: user.defaultCustomBackgroundPhotoUrl || '',
        locationUpdatedAt: user.locationUpdatedAt || '',
        companyLogoUrl: user.companyLogoUrl || '',
        companyId: user.zoomCompanyId || '',
        companyName: user.companyName || user?.zoomCompany?.name || '',
        companyDomain: user.companyWebsiteDomain || user?.zoomCompany?.websiteDomain || '',
        headline: user?.headline || '',
        ask: user?.ask || '',
        linkedInUrl: user?.linkedInUrl || '',
        twitterUrl: user?.twitterUrl || '',
        zoomCompanyId: user?.zoomCompanyId || '',
        companyEstimatedSize: user?.zoomCompany?.estimatedSize || '',
        companyIndustry: user?.zoomCompany?.industry || '',
        companyCity: user?.zoomCompany?.city || '',
        companyState: user?.zoomCompany?.state || '',
        companyCountry: user?.zoomCompany?.country || '',
        companyDescription: user?.zoomCompany?.description || '',
        companyTotalFunding: user?.zoomCompany?.totalFunding || '',
        companyYearFounded: user?.zoomCompany?.yearFounded || '',
        companyAlexaRanking: user?.zoomCompany?.alexaRanking || '',
        ...(properties && { ...properties }),
      };

      await posthog.identify(user.id, { ...buffer });

      if (contact) {
        const roleBucket =
          getRoleBucket(user?.title, user.email) ||
          contact?.roleBucket ||
          (contact?.title && getRoleBucket(contact.title, contact.email));

        await posthog.identify(user.id, { roleBucket });
      }
    },
    [posthog]
  );

  const capture = useCallback(
    async (eventName: PosthogEventName | string, properties?: PosthogCaptureProperties) => {
      const isInZoomMeeting = Boolean(zoomStatus()?.isInMeeting);

      const appContext = getAppContext();
      const browserVersion = zoomStatus()?.browserVersion;
      const clientVersion = zoomStatus()?.clientVersion;
      const os = getOs();

      const buffer = {
        ...(properties && { ...properties }),
        app_context: appContext,
        os,
        ...(browserVersion && { browserVersion: browserVersion }),
        ...(clientVersion && { clientVersion: clientVersion }),
        is_in_zoom_meeting: isInZoomMeeting,
      };

      await posthog.capture(eventName, { ...buffer });
    },
    [posthog]
  );

  const group = useCallback(
    (key: string, user: ZoomUserFragment) => {
      if (!user.zoomCompanyId) return;

      const properties = {
        name: user?.zoomCompany?.name || '',
        domain: user?.zoomCompany?.websiteDomain || '',
        estimated_size: user?.zoomCompany?.estimatedSize || '',
        industry: user?.zoomCompany?.industry || '',
      };

      posthog.group('company', user.zoomCompanyId, properties);
    },
    [posthog]
  );

  const pageView = useCallback(async () => {
    await capture('$pageview');
  }, [capture]);

  return {
    group,
    identify,
    capture,
    pageView,
  };
};
