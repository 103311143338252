/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { Fade, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';

/* --------------------------------- CUSTOM --------------------------------- */
import { LogoSpinner, ZoomSpinner } from 'src/components/LoadingLogoBackdrop';
import { useComponentDidMount, useComponentWillUnmount } from 'src/hooks/lifecycleHooks';
import { usePosthogEvents } from 'src/hooks/usePosthogEvents';
import { useStores } from 'src/hooks/useStores';
import { PosthogEventName } from 'src/utils/constants';
import { makeStyles } from 'src/utils/styles';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
interface PreloaderProps {
  message?: string;
}

interface PreloaderInstanceProps extends PreloaderProps {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  style?: React.HtmlHTMLAttributes<HTMLDivElement>['style'];
  forceShow?: boolean;
  isZoomSpinner?: boolean;
}

/* -------------------------------------------------------------------------- */
/*                                   STYLES                                   */
/* -------------------------------------------------------------------------- */
export const useStyles = makeStyles()((_theme) => ({
  fixedContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

/* -------------------------------------------------------------------------- */
/*                            COMPONENT DEFINITION                            */
/* -------------------------------------------------------------------------- */
const Preloader = (_props: PreloaderProps) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const {
    userStore: { setShowPreloader },
  } = useStores();
  const { capture } = usePosthogEvents();
  const mountTime = useRef<Date | null>(null);

  /* --------------------------------- EFFECTS -------------------------------- */
  useComponentDidMount(() => {
    mountTime.current = new Date();
    capture(PosthogEventName.PreloaderShown);
    setShowPreloader(true);
  });

  useComponentWillUnmount(() => {
    if (mountTime.current) {
      const displayDuration = (new Date().getTime() - mountTime.current.getTime()) / 1000;
      // eslint-disable-next-line no-console
      console.log(`Preloader displayed for ${displayDuration.toFixed(2)} seconds`);
    }
    capture(PosthogEventName.PreloaderHidden);
    setShowPreloader(false);
  });

  /* ---------------------------- RENDER COMPONENT ---------------------------- */
  return <></>;
};

/* -------------------------------------------------------------------------- */
/*                            COMPONENT DEFINITION                            */
/* -------------------------------------------------------------------------- */
export const PreloaderInstance = observer((props: PreloaderInstanceProps) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { classes, cx } = useStyles();
  const {
    userStore: { showPreloader },
  } = useStores();

  /* ---------------------------- RENDER COMPONENT ---------------------------- */
  return (
    <Fade in={props.forceShow === true || showPreloader} timeout={500} data-testid="logo-preloader">
      <div className={cx(classes.fixedContainer, props.className)} style={props.style}>
        <div className={classes.container}>
          {props.isZoomSpinner ? <ZoomSpinner /> : <LogoSpinner />}
          {props.message && <Typography variant="h4">{props.message}</Typography>}
        </div>
      </div>
    </Fade>
  );
});

export default Preloader;
