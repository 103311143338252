/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';

/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomUserFragment } from 'src/graphql';
import { getAuthToken } from 'src/modules/auth';
import { isZoom } from 'src/modules/zoomSdk';
import {
  GoogleScopeEndpoint,
  GoogleScopeName,
  LocalStorageItem,
  MicrosoftScope,
  NavigationPath,
  OnboardingType,
  zoomApiUrl,
} from 'src/utils/constants';
import { openUrl } from 'src/utils/functions/functions';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export type AuthType = 'google' | 'microsoft' | 'sso';

interface OnClickAuthLinkOpts {
  onboardingType?: OnboardingType | null;
  includeCalendarScope?: boolean;
  authType?: AuthType;
  email?: string | null;
}

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
export const checkScopeExistsForZoomUser = (
  zoomUser: ZoomUserFragment,
  scope: GoogleScopeName | MicrosoftScope,
  type: AuthType = 'google'
): boolean => {
  const scopes = type === 'google' ? zoomUser.googleScopes : zoomUser.microsoftScopes;
  const scopeToCheck = type === 'google' ? GoogleScopeEndpoint[scope] : scope;
  return scopes && scopes.includes(scopeToCheck);
};

/**
 * Handles when user clicks an auth provider login button. Gets the auth redirect url and opens it in a separate tab
 * @param params.posthogSegment event we want to fire upon completion of this event
 * @param params.onboardingType The onboarding flow the user will go through upon successfully authenticating in Google
 * @param params.authType The platform which the user authenticates against
 * @param params.includeCalendarScope Whether or not to prompt for calendar permissions when re-directing to auth link
 * Used specifically for google verification video so as to not disrupt experience for existing users.
 */
export const onClickAuthLink = async ({
  onboardingType,
  authType = 'google',
  email,
  includeCalendarScope,
}: OnClickAuthLinkOpts): Promise<void> => {
  const localStorageZoomUserId = window.localStorage.getItem(LocalStorageItem.ZoomUserId);
  const authToken = await getAuthToken();
  const baseUrl =
    authType === 'google'
      ? zoomApiUrl.zoom.getGoogleAuth
      : authType === 'microsoft'
      ? zoomApiUrl.zoom.getMicrosoftAuth
      : zoomApiUrl.zoom.getSSOAuth;

  const query = new URLSearchParams({
    ...(localStorageZoomUserId && { 'zoom-user-id': localStorageZoomUserId }),
    ...(onboardingType && { onboardingType }),
    ...(includeCalendarScope && { includeCalendarScope: 'true' }),
    ...(email && { email }),
  }).toString();

  const headers = {
    ...(authToken && { authorization: `Bearer ${authToken}` }),
  };

  const authRedirectUrlEndpoint = `${baseUrl}${query ? '?' + query : ''}`;
  const authUrl = (await axios.get(authRedirectUrlEndpoint, { headers })).data;

  openUrl({ url: authUrl, newWindow: false, proxy: false });
};

/**
 * Determines whether to, and provides functionality to, auto-redirect the user to calendar permissions page
 * @param promptForCalendarType type of calendar permissions to ask for (google/microsoft)
 */
export const autoPromptForCalendarPermissions = (
  promptForCalendarType?: AuthType,
  email?: string | null,
  skipCalendarPromptOnZoomLogin?: boolean | null
) => {
  // Pull list of users we've auto prompted for cal permissions for from local storage
  const autoPromptHistory = JSON.parse(
    window.localStorage.getItem(LocalStorageItem.CalendarAutoPromptHistory) || '[]'
  ) as string[];

  // Prompt the user if we have their email/calendar type and haven't prompted them in the past
  const shouldPrompt =
    !(isZoom && skipCalendarPromptOnZoomLogin) &&
    !!(promptForCalendarType && email) &&
    !autoPromptHistory.includes(email);

  const autoPrompt = (navigate: NavigateFunction) => {
    // Add user to the list of user's we've auto prompted for
    window.localStorage.setItem(
      LocalStorageItem.CalendarAutoPromptHistory,
      JSON.stringify(autoPromptHistory.concat(email || ''))
    );

    navigate(NavigationPath.PreAuthentication);
  };

  return { shouldPrompt, autoPrompt };
};
