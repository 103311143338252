/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ---------------------------------- DEBUG --------------------------------- */
import 'src/appDebug';
/* ----------------------------------- CSS ---------------------------------- */
import 'src/fonts/fonts.css';

/* ------------------------------- THIRD PARTY ------------------------------ */
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

/* --------------------------------- CUSTOM --------------------------------- */
import ErrorBoundaryLogger from 'src/components/ErrorBoundary/ErrorBoundaryLogger';
import StoreProvider from 'src/components/StoreProvider/StoreProvider';
import { useStores } from 'src/hooks/useStores';
import Preloader from 'src/main/Preloader';
import WithSuspense from 'src/main/WithSuspense';
import { logError } from 'src/modules/analytics';
import { initializeZoomSdk, isZoom, ZoomRunningContext } from 'src/modules/zoomSdk';
import * as serviceWorker from 'src/serviceWorker';
import theme from 'src/utils/theme';

const insertionPoint = document.querySelector<HTMLElement>('meta[name="emotion-insertion-point"]') || document.head;
export const muiCache = createCache({ key: 'mui', insertionPoint });

const CameraView = lazy(() => import(/* webpackChunkName: "RenderedClient" */ 'src/CameraView'));
const MainView = lazy(() => import(/* webpackChunkName: "MainView" */ 'src/MainView'));

const ZoomProvider: React.FC = observer(() => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const {
    userStore: {
      setZoomSdkConfig,
      setZoomSdkSupportedApis,
      setIsZoomSdkInitialized,
      shouldInitializeZoomSdk,
      setShouldInitializeZoomSdk,
    },
  } = useStores();

  const [component, setComponent] = useState(isZoom ? 'loading' : 'sidepanel');

  /* -------------------------------- CONSTANTS ------------------------------- */
  useEffect(() => {
    const initializeZoom = async () => {
      const response = await initializeZoomSdk();
      if (!response) {
        if (isZoom) {
          logError('Failed to initialize Zoom SDK');
        }
        setIsZoomSdkInitialized(false);
        return;
      }

      setZoomSdkConfig(response.config);

      const { runningContext } = response.config;
      setZoomSdkSupportedApis(response.supportedApis);

      setIsZoomSdkInitialized(true);

      if (runningContext === ZoomRunningContext.InCamera || runningContext === ZoomRunningContext.InImmersive)
        setComponent('camera');
      else setComponent('sidepanel');
    };

    if (shouldInitializeZoomSdk) {
      setIsZoomSdkInitialized(false);
      setShouldInitializeZoomSdk(false);
      // !IMPORTANT: slow down initialize for unit tests.
      const waitTime = window.localStorage.getItem('mockZoomSdk') === 'true' ? 1000 : 0;
      setTimeout(initializeZoom, waitTime);
    }
  }, [
    setIsZoomSdkInitialized,
    setShouldInitializeZoomSdk,
    setZoomSdkConfig,
    setZoomSdkSupportedApis,
    shouldInitializeZoomSdk,
  ]);

  /* ---------------------------- RENDERED ELEMENTS --------------------------- */

  if (component === 'loading') {
    return <Preloader />;
  } else if (component === 'camera') {
    return (
      <WithSuspense>
        <CameraView />
      </WithSuspense>
    );
  } else if (component === 'sidepanel') {
    return (
      <WithSuspense>
        <MainView />
      </WithSuspense>
    );
  }

  return <div>Unknown state</div>;
});

const Root: React.FC = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <ErrorBoundaryLogger>
          <StoreProvider>
            <ZoomProvider />
          </StoreProvider>
        </ErrorBoundaryLogger>
      </ThemeProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
