/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

/* --------------------------------- CUSTOM --------------------------------- */
import { OverlaySizeType, WIDGET_KEY } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export interface WidgetStoreState<T = unknown> {
  enabled: boolean;
  key: WIDGET_KEY;
  value?: T;
  size: OverlaySizeType;
}

export const defaultWidgetStoreState: WidgetStoreState<unknown> = {
  enabled: false,
  key: 'none',
  value: undefined,
  size: 'small',
};

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class WidgetStore<T = unknown> implements WidgetStoreState<T> {
  enabled = defaultWidgetStoreState.enabled;
  key: WIDGET_KEY;
  value: T | undefined;
  size = defaultWidgetStoreState.size;

  constructor(key: WIDGET_KEY) {
    makeAutoObservable(this);
    this.key = key;

    makePersistable(this, {
      name: `${this.key}Store`,
      properties: ['value', 'size'],
      storage: window.localStorage,
    });
  }

  setEnabled = (value: WidgetStoreState<T>['enabled']) => {
    this.enabled = value;
  };

  setValue = (value?: WidgetStoreState<T>['value']) => {
    if (value) {
      this.setEnabled(true);
    } else {
      this.setEnabled(false);
    }

    this.value = value;
  };

  setSize = (size: WidgetStoreState<T>['size']) => {
    this.size = size;
  };

  setWidgetStore = (widgetStore: WidgetStoreState<T>) => {
    this.setValue(widgetStore.value);
    this.setEnabled(widgetStore.enabled);
    this.setSize(widgetStore.size);
  };

  resetWidgetStore = async () => {
    // Clear persisted store in local storage
    await clearPersistedStore(this);

    this.setEnabled(defaultWidgetStoreState.enabled);
    this.setValue(undefined);
    this.setSize(defaultWidgetStoreState.size);
  };
}
