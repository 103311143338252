export enum FeatureFlag {
  // Posthog feature flags
  EnableSessionRecording = 'enableSessionRecording',
  EnableAuditLogs = 'enableAuditLogs',
  EnableGroups = 'enableGroups',
  DisableCloudinary = 'disableCloudinary',
  EnableBoostsDefault = 'enableBoostsDefault',
  EnableSkinnyView = 'enableSkinnyView',
  EnablePresentations = 'enablePresentations',
  DisableCalendarSignature = 'disableCalendarSignature',
  DisableBoostsOnVDI = 'disableBoostsOnVDI',
  EnabledRio = 'enabledRio',
}

/**
 * Experiment feature flags to test effectiveness of features using percentage rollouts
 */
export enum ExperimentFlagKey {}
