/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
import { isString } from 'lodash-es';

import { LocalStorageProfileData } from 'src/components/auth/ZoomAuthUserRouter';
import { ZoomContactFragment, ZoomUserFragment } from 'src/graphql';
import { UserProfileSettings } from 'src/stores/Settings.store';
import { getDefaultBackgroundVisibility, ZoomUserBackgroundVisibility } from 'src/utils/profile/backgroundVisibility';
import {
  defaultProfileVisibilityByField,
  ZoomUserProfileVisibility,
  ZoomUserProfileVisibilityField,
} from 'src/utils/profile/profileVisibility';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
/**
 * Profile form data should only be used for forms (i.e., the data will be shown/edited in text fields, select boxes, etc.)
 *
 * Note that profile form data should always be string or number, as React/HTML forms
 * are not designed to handle `undefined` and `null` well.
 *
 * Therefore, when constructing the profile data for display, we should
 * coerce undefined/null fields to empty strings
 */
export type ZoomUserProfileFormData = {
  firstName: string;
  lastName: string;
  firstNamePronunciation: string;
  lastNamePronunciation: string;
  pronouns: string;
  headline: string; // "Bio" in profile page
  ask: string;
  title: string;
  linkedInUrl: string;
  twitterUrl: string;
  scheduleMeetingUrl: string;
  profileVisibility: ZoomUserProfileVisibility;
  backgroundVisibility: ZoomUserBackgroundVisibility;
  // These fields aren't controlled directly by the form,
  // but they are included here so we can use FieldPath
  username?: string;
  photoUrl?: string;
  location?: string;
  email?: string;
  company?: string;
  department?: string;
  startDate?: Date | null;
  strengths?: string;
  addedToVideoConversationStarterPromptId?: string | null;
};

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/**
 * Retrieves profile form data for a given zoomUser and their associated zoomContact/localStorage user data
 *
 * Note that this is only used for an existing zoomUser
 *
 * @param params.zoomUser zoomUser to get the profile form data from
 * @param params.zoomContact the zoomContact for the zoomUser
 * @param params.localStorageUser User data from localStorage
 * @returns
 */
export const getProfileFormData = ({
  zoomUser,
  zoomContact,
  localStorageUser,
  userProfileSettings,
}: {
  zoomUser: ZoomUserFragment;
  zoomContact?: ZoomContactFragment;
  localStorageUser?: LocalStorageProfileData;
  userProfileSettings: UserProfileSettings;
}): ZoomUserProfileFormData => {
  const {
    firstName,
    lastName,
    firstNamePronunciation,
    lastNamePronunciation,
    pronouns,
    headline,
    ask,
    title,
    department,
    startDate,
    linkedInUrl,
    twitterUrl,
    scheduleMeetingUrl,
    profileVisibility,
    backgroundVisibility,
    addedToVideoConversationStarterPromptId,
    strengths,
  } = zoomUser;

  return {
    firstName: firstName, // Do not ever use zoomContact first/last names - zoomUser first/lastNames are non-nullable
    lastName: lastName,
    firstNamePronunciation: isString(firstNamePronunciation)
      ? firstNamePronunciation
      : localStorageUser?.firstNamePronunciation || '',
    lastNamePronunciation: isString(lastNamePronunciation)
      ? lastNamePronunciation
      : localStorageUser?.lastNamePronunciation || '',
    pronouns: isString(pronouns) ? pronouns : localStorageUser?.pronouns || '',
    headline: isString(headline) ? headline : localStorageUser?.headline || zoomContact?.headline || '',
    ask: isString(ask) ? ask : localStorageUser?.ask || '',
    title: isString(title) ? title : localStorageUser?.title || '',
    department: isString(department) ? department : localStorageUser?.department || '',
    // The MUI date picker expects "null" for an empty state
    startDate: isString(startDate) ? new Date(startDate) : null,
    linkedInUrl: isString(linkedInUrl) ? linkedInUrl : localStorageUser?.linkedInUrl || zoomContact?.linkedInUrl || '',
    twitterUrl: isString(twitterUrl) ? twitterUrl : localStorageUser?.twitterUrl || zoomContact?.twitterUrl || '',
    scheduleMeetingUrl: isString(scheduleMeetingUrl) ? scheduleMeetingUrl : localStorageUser?.scheduleMeetingUrl || '',
    profileVisibility: { ...defaultProfileVisibilityByField, ...profileVisibility },
    backgroundVisibility: { ...getDefaultBackgroundVisibility(userProfileSettings), ...backgroundVisibility },
    addedToVideoConversationStarterPromptId: isString(addedToVideoConversationStarterPromptId)
      ? addedToVideoConversationStarterPromptId
      : null,
    strengths: strengths || localStorageUser?.strengths || '',
  };
};

/**
 * Ensures that a profile's `profileVisibility` has all the required fields. If any field is missing,
 * we add the default visibility setting for that field.
 *
 * It also removes invalid fields that should not be in `profileVisibility`
 * @param profileVisibility The profileVisibility data to be conformed
 */
export const conformProfileVisibility = (profileVisibility: ZoomUserProfileVisibility) => {
  const conformedProfileVisibility: ZoomUserProfileVisibility = {};

  for (const field of Object.values(ZoomUserProfileVisibilityField)) {
    if (typeof profileVisibility[field] !== 'string') {
      conformedProfileVisibility[field] = defaultProfileVisibilityByField[field];
    } else {
      conformedProfileVisibility[field] = profileVisibility[field];
    }
  }

  return conformedProfileVisibility;
};
