/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { isBefore } from 'date-fns';
import { makeAutoObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomUserActions_Set_Input, ZoomUserFragment } from 'src/graphql';
import { isZoom, launchAppInMeeting, setVideoState } from 'src/modules/zoomSdk';
import { zoomStatus } from 'src/reactiveVars/zoomStatus';
import RootStore from 'src/stores/Root.store';
import { goToTab } from 'src/stores/Tab.store';
import { NavigationPath, QueryParam } from 'src/utils/constants';
import { goToZoom } from 'src/utils/functions/functions';
import { upsertUserActions } from 'src/utils/user';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
const HomePageCardTypes = [
  'enhanceNametag',
  'practiceRoom',
  'welcomeVideo',
  'unmirrorCamera',
  'calSig',
  'installZoom',
  'videoOff',
  'agenda',
  'addBio',
] as const;
export type HomePageCardType = typeof HomePageCardTypes[number];

const HomePageAlertTypes = ['excitingNewsAlert', 'manageOrgAlert', 'needZoomAccessAlert', 'upgradeZoomAlert'] as const;
export type HomePageAlertType = typeof HomePageAlertTypes[number];

const dismissedUpsertKeyMap: Record<HomePageCardType | HomePageAlertType, keyof ZoomUserActions_Set_Input> = {
  enhanceNametag: 'enhanceNametagDismissedAt',
  practiceRoom: 'practiceRoomDismissedAt',
  welcomeVideo: 'welcomeSetupCardDismissedAt',
  unmirrorCamera: 'unmirrorCardDismissedAt',
  calSig: 'calSigDismissedAt',
  installZoom: 'installZoomDismissedAt',
  videoOff: 'videoOffCardDismissedAt',
  agenda: 'agendaCardDismissedAt',
  excitingNewsAlert: 'excitingNewsAlertDismissedAt',
  manageOrgAlert: 'manageOrganizationAlertDismissedAt',
  needZoomAccessAlert: 'needZoomAccessAlertDismissedAt',
  upgradeZoomAlert: 'upgradeZoomAlertDismissedAt',
  addBio: 'addBioDismissedAt',
};

const completedUpsertKeyMap: Record<HomePageCardType | HomePageAlertType, keyof ZoomUserActions_Set_Input | undefined> =
  {
    enhanceNametag: 'enhanceNametagCompletedAt',
    practiceRoom: 'practiceRoomCompletedAt',
    welcomeVideo: undefined,
    unmirrorCamera: undefined,
    calSig: undefined,
    installZoom: undefined,
    videoOff: 'videoOffCardCompletedAt',
    agenda: undefined,
    excitingNewsAlert: undefined,
    manageOrgAlert: undefined,
    needZoomAccessAlert: undefined,
    upgradeZoomAlert: undefined,
    addBio: undefined,
  };

type AlertState = {
  show?: boolean;
  dismissed?: boolean;
};

type CardState = {
  dismissed: boolean;
  completed: boolean;
  initiallyCompleted: boolean;
  dialogShown?: boolean;
  confettiShown?: boolean;
};

type HomePageStoreState = Record<HomePageCardType, CardState> &
  Record<HomePageAlertType, AlertState> & {
    defaultsSet: boolean;
    practiceRoom: CardState & { show: boolean };
    welcomeDialogDismissed: boolean;
  };

const defaultAlertState: AlertState = {
  show: false,
  dismissed: false,
};

const defaultCardState: CardState = {
  completed: false,
  dismissed: false,
  dialogShown: false,
  initiallyCompleted: false,
  confettiShown: false,
};

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const defaultHomeStoreState: HomePageStoreState = {
  defaultsSet: false,
  enhanceNametag: defaultCardState,
  practiceRoom: { ...defaultCardState, show: false },
  excitingNewsAlert: defaultAlertState,
  manageOrgAlert: defaultAlertState,
  needZoomAccessAlert: defaultAlertState,
  welcomeVideo: defaultCardState,
  welcomeDialogDismissed: false,
  unmirrorCamera: defaultCardState,
  calSig: defaultCardState,
  installZoom: defaultCardState,
  videoOff: defaultCardState,
  agenda: defaultCardState,
  upgradeZoomAlert: defaultAlertState,
  addBio: defaultCardState,
};

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class HomePageStore implements HomePageStoreState {
  /* ---------------------------- MEMBER VARIABLES ---------------------------- */
  enhanceNametag = defaultHomeStoreState.enhanceNametag;
  practiceRoom = defaultHomeStoreState.practiceRoom;
  defaultsSet = defaultHomeStoreState.defaultsSet;
  excitingNewsAlert = defaultHomeStoreState.excitingNewsAlert;
  manageOrgAlert = defaultHomeStoreState.manageOrgAlert;
  needZoomAccessAlert = defaultHomeStoreState.needZoomAccessAlert;
  welcomeVideo = defaultHomeStoreState.welcomeVideo;
  welcomeDialogDismissed = defaultHomeStoreState.welcomeDialogDismissed;
  unmirrorCamera = defaultHomeStoreState.unmirrorCamera;
  calSig = defaultHomeStoreState.calSig;
  installZoom = defaultHomeStoreState.installZoom;
  videoOff = defaultHomeStoreState.videoOff;
  agenda = defaultHomeStoreState.agenda;
  upgradeZoomAlert = defaultHomeStoreState.upgradeZoomAlert;
  addBio = defaultHomeStoreState.addBio;

  /* --------------------------------- ACTIONS -------------------------------- */
  onConfettiShown = (key: HomePageCardType) => (this[key].confettiShown = true);

  onShowPracticeRoomClicked = async (navigate: NavigateFunction) => {
    await upsertUserActions(this.rootStore.userStore.loggedInUser, { practiceRoomShownAt: new Date().toISOString() });
    await this.openPracticeRoom(navigate);
  };

  onEnhanceNametagClick = (navigate: NavigateFunction) => {
    navigate(NavigationPath.NametagBuilder);
    this.complete('enhanceNametag');
  };

  onVideoOffNametagClick = (navigate: NavigateFunction) => {
    this.complete('videoOff');
    goToTab({ path: NavigationPath.Profile, navigate, activeTab: 'videoOff' });
  };

  onCalSigClick = (navigate: NavigateFunction) => {
    this.dismiss('calSig');
    goToTab({ path: NavigationPath.Profile, navigate, activeTab: 'calendarSignature' });
  };

  onMeetingBoostsClick = (navigate: NavigateFunction) => {
    this.dismiss('agenda');
    navigate(NavigationPath.MeetingBoosts);
  };

  openPracticeRoom = async (navigate: NavigateFunction) => {
    const isInMeeting = zoomStatus()?.isInMeeting;
    const goto = NavigationPath.NametagBuilder;
    const query = { [QueryParam.ShowPracticeRoom]: '1' };
    if (isZoom) {
      if (isInMeeting) {
        this.practiceRoom.show = true;
        navigate(NavigationPath.NametagBuilder);
        void setVideoState();
        setTimeout(
          () =>
            upsertUserActions(this.rootStore.userStore.loggedInUser, {
              practiceRoomCompletedAt: new Date().toISOString(),
            }),
          2500
        );
      } else {
        try {
          await launchAppInMeeting({ actions: { goto, update: 'true', query } });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    } else {
      goToZoom(goto, query);
    }
  };

  closePracticeRoom = () => {
    this.practiceRoom.show = false;
  };

  onEnhanceDialogDialogShown = () => {
    upsertUserActions(this.rootStore.userStore.loggedInUser, { enhanceNametagDialogShownAt: new Date().toISOString() });
  };

  dismiss = async (key: HomePageCardType) => {
    upsertUserActions(this.rootStore.userStore.loggedInUser, {
      [dismissedUpsertKeyMap[key]]: new Date().toISOString(),
    });
    this[key].dismissed = true;
  };

  complete = (key: HomePageCardType) => {
    const upsertKey = completedUpsertKeyMap[key];
    if (upsertKey) {
      upsertUserActions(this.rootStore.userStore.loggedInUser, {
        [upsertKey]: new Date().toISOString(),
      });
    }
    this[key].completed = true;
  };

  setDefaultsForUser = (actions: ZoomUserFragment['actions']) => {
    this.enhanceNametag = {
      completed: !!actions?.enhanceNametagCompletedAt,
      dismissed: !!actions?.enhanceNametagDismissedAt,
      dialogShown: !!actions?.enhanceNametagDialogShownAt,
      initiallyCompleted: this.defaultsSet
        ? this.enhanceNametag.initiallyCompleted
        : !!actions?.enhanceNametagCompletedAt,
      confettiShown: this.defaultsSet ? this.enhanceNametag.initiallyCompleted : !!actions?.enhanceNametagCompletedAt,
    };

    this.practiceRoom = {
      completed: !!actions?.practiceRoomCompletedAt,
      dismissed: !!actions?.practiceRoomDismissedAt,
      initiallyCompleted: this.defaultsSet ? this.practiceRoom.initiallyCompleted : !!actions?.practiceRoomCompletedAt,
      confettiShown: this.defaultsSet ? this.practiceRoom.initiallyCompleted : !!actions?.practiceRoomCompletedAt,
      show: this.defaultsSet ? this.practiceRoom.show : false,
    };

    const user = this.rootStore.userStore.loggedInUser;

    // Only show "Exciting new features" alert to people who created accounts after Aug 2, 2022
    const userCreatedBeforeLaunchDate = isBefore(new Date(user.createdAt), new Date(2022, 7, 2));

    this.excitingNewsAlert = {
      show: userCreatedBeforeLaunchDate,
      dismissed: this.defaultsSet ? this.excitingNewsAlert.dismissed : !!actions?.excitingNewsAlertDismissedAt,
    };

    this.manageOrgAlert = {
      show: !!user.isOrgAdmin,
      dismissed: this.defaultsSet ? this.manageOrgAlert.dismissed : !!actions?.manageOrganizationAlertDismissedAt,
    };

    this.needZoomAccessAlert = {
      dismissed: this.defaultsSet ? this.needZoomAccessAlert.dismissed : !!actions?.needZoomAccessAlertDismissedAt,
    };

    this.welcomeVideo = {
      completed: this.defaultsSet ? this.welcomeVideo.dismissed : !!actions?.welcomeSetupCardDismissedAt,
      initiallyCompleted: this.defaultsSet ? this.welcomeVideo.dismissed : !!actions?.welcomeSetupCardDismissedAt,
      dismissed: this.defaultsSet ? this.welcomeVideo.dismissed : !!actions?.welcomeSetupCardDismissedAt,
    };

    this.welcomeDialogDismissed = this.defaultsSet ? this.welcomeDialogDismissed : !!actions?.welcomeDialogDismissedAt;

    this.unmirrorCamera = {
      completed: this.defaultsSet ? this.unmirrorCamera.dismissed : !!actions?.unmirrorCardDismissedAt,
      initiallyCompleted: this.defaultsSet ? this.unmirrorCamera.dismissed : !!actions?.unmirrorCardDismissedAt,
      dismissed: this.defaultsSet ? this.unmirrorCamera.dismissed : !!actions?.unmirrorCardDismissedAt,
    };

    this.calSig = {
      completed: this.defaultsSet ? this.calSig.dismissed : !!actions?.calSigDismissedAt,
      initiallyCompleted: this.defaultsSet ? this.calSig.dismissed : !!actions?.calSigDismissedAt,
      dismissed: this.defaultsSet ? this.calSig.dismissed : !!actions?.calSigDismissedAt,
    };

    this.installZoom = {
      completed: this.defaultsSet ? this.installZoom.dismissed : !!actions?.installZoomDismissedAt,
      initiallyCompleted: this.defaultsSet ? this.installZoom.dismissed : !!actions?.installZoomDismissedAt,
      dismissed: this.defaultsSet ? this.installZoom.dismissed : !!actions?.installZoomDismissedAt,
    };

    this.videoOff = {
      completed: !!actions?.videoOffCardCompletedAt,
      dismissed: !!actions?.videoOffCardDismissedAt,
      initiallyCompleted: this.defaultsSet ? this.videoOff.initiallyCompleted : !!actions?.videoOffCardCompletedAt,
      confettiShown: this.defaultsSet ? this.enhanceNametag.initiallyCompleted : !!actions?.videoOffCardCompletedAt,
    };

    this.upgradeZoomAlert = {
      show: this.defaultsSet ? this.upgradeZoomAlert.show : defaultHomeStoreState.upgradeZoomAlert.show,
      dismissed: this.defaultsSet ? this.upgradeZoomAlert.dismissed : !!actions?.upgradeZoomAlertDismissedAt,
    };

    this.addBio = {
      completed: this.defaultsSet ? this.addBio.dismissed : !!actions?.addBioDismissedAt,
      initiallyCompleted: this.defaultsSet ? this.addBio.dismissed : !!actions?.addBioDismissedAt,
      dismissed: this.defaultsSet ? this.addBio.dismissed : !!actions?.addBioDismissedAt,
    };

    this.agenda = {
      completed: this.defaultsSet ? this.calSig.dismissed : !!actions?.calSigDismissedAt,
      initiallyCompleted: this.defaultsSet ? this.calSig.dismissed : !!actions?.calSigDismissedAt,
      dismissed: this.defaultsSet ? this.calSig.dismissed : !!actions?.calSigDismissedAt,
    };

    this.defaultsSet = true;
  };

  dismissAlert = async (key: HomePageAlertType) => {
    upsertUserActions(this.rootStore.userStore.loggedInUser, {
      [dismissedUpsertKeyMap[key]]: new Date().toISOString(),
    });
    this[key].dismissed = true;
  };

  /* ------------------------------- CONSTRUCTOR ------------------------------ */
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }
}
