/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* --------------------------------- CUSTOM --------------------------------- */
import { BASE_PROTOCOL, BASE_PROXY_DOMAIN } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
const proxiedExternalDomain = {
  alphaleads: 'alphaleads-photos.s3.amazonaws.com',
  zenprospects: 'zenprospect-production.s3.amazonaws.com',
  clearbitLogo: 'logo.clearbit.com',
  clearbitPerson: 'person.clearbit.com',
  giphy1: 'media1.giphy.com',
  giphy2: 'media2.giphy.com',
  giphy4: 'media4.giphy.com',
  typeformSurvey: 'getwarmly.typeform.com',
  giphyApi: 'api.giphy.com',
};

const proxiedWarmlyUri = {
  alphaleads: '/alphaleads',
  zenprospects: '/zenprospect',
  clearbitLogo: '/clearbit-logo',
  clearbitPerson: '/clearbit-person',
  giphy: '/giphy',
  typeformSurvey: '/typeform-survey',
  giphyApi: '/giphy-api',
};

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/**
 * Takes a URL and returns the proxied URL that uses our nginx reverse proxy, if it is
 * from a domain that is supported by the reverse proxy.
 *
 * The purpose of the reverse proxy is to allow requesting certain URLs that are not on Zoom's
 * whitelisted domains through our own subdomain.
 *
 * @param url The URL to get the proxied URL from
 * @returns The proxied URL
 */
export const getProxiedUrl = (url?: string | null): string | undefined => {
  if (!url) {
    return;
  }

  try {
    const parsedUrl = new URL(url);

    switch (parsedUrl.hostname.toLowerCase()) {
      case proxiedExternalDomain.alphaleads:
        parsedUrl.pathname = `${proxiedWarmlyUri.alphaleads}${parsedUrl.pathname}`;
        break;
      case proxiedExternalDomain.zenprospects:
        parsedUrl.pathname = `${proxiedWarmlyUri.zenprospects}${parsedUrl.pathname}`;
        break;
      case proxiedExternalDomain.clearbitLogo:
        parsedUrl.pathname = `${proxiedWarmlyUri.clearbitLogo}${parsedUrl.pathname}`;
        break;
      case proxiedExternalDomain.clearbitPerson:
        parsedUrl.pathname = `${proxiedWarmlyUri.clearbitPerson}${parsedUrl.pathname}`;
        break;
      case proxiedExternalDomain.giphy1:
      case proxiedExternalDomain.giphy2:
      case proxiedExternalDomain.giphy4:
        parsedUrl.pathname = `${proxiedWarmlyUri.giphy}${parsedUrl.pathname}`;
        break;
      case proxiedExternalDomain.typeformSurvey:
        parsedUrl.pathname = `${proxiedWarmlyUri.typeformSurvey}${parsedUrl.pathname}`;
        break;
      case proxiedExternalDomain.giphyApi:
        parsedUrl.pathname = `${proxiedWarmlyUri.giphyApi}${parsedUrl.pathname}`;
        break;
      default:
        return url;
    }

    parsedUrl.host = BASE_PROXY_DOMAIN;
    parsedUrl.protocol = BASE_PROTOCOL;
    return parsedUrl.href;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log(ex, { url });
    return url;
  }
};
