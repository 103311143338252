/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';

/* --------------------------------- CUSTOM --------------------------------- */
import { QueryZoomSettingsObjectsQuery, ZoomSettingsFragment } from 'src/graphql';
import { getInheritedSettings } from 'src/hooks/settings/useZoomSettings';
import { WidgetKeys } from 'src/utils/constants';
import { ZoomUserBackgroundVisibility } from 'src/utils/profile/backgroundVisibility';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export type ReadOnlyFieldControlOptions =
  | 'can-edit'
  | 'cannot-edit'
  | 'can-edit-default-off'
  | 'cannot-edit-default-off'
  | 'removed';

export type MeetingBoostControlOptions = 'enabled' | 'disabled';

export type UserProfileSettings = Partial<
  Record<keyof ZoomUserBackgroundVisibility, { editingDisabled?: boolean; defaultOff?: boolean; removed?: boolean }>
>;

export type MeetingBoostSettings = Partial<Record<keyof WidgetKeys, { enabled?: boolean }>>;

export const AllZoomSettingsOmits = [
  '__typename',
  'createdAt',
  'id',
  'identifier',
  'identifierId',
  'updatedAt',
  'createdByUserId',
  'zoomOrganizationMemberships',
  'zoomOrganizationMemberships_aggregate',
] as const;

export type SettingsStoreState = Omit<ZoomSettingsFragment, typeof AllZoomSettingsOmits[number]> & {
  orgSettingsId?: number;
  userProfileSettings: UserProfileSettings;
  meetingBoostSettings: MeetingBoostSettings;

  groupSettings: ZoomSettingsFragment[];
};

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const localDefaultZoomSettings: SettingsStoreState = {
  orgSettingsId: undefined,
  defaultCalendarSignaturesOnForRecurringMeetings: false,
  disableBackgroundUpload: false,
  disableAiBackground: false,
  disableImageUpload: false,
  disableCalendarIntegration: false,
  disableCalendarSignaturesByDefault: false,
  disableNonWarmlyCalendarSignaturesByDefault: false,
  disableCalendarPromptOnZoomLogin: false,
  disablePublicProfiles: false,
  skipExperiments: false,
  userProfileSettings: {},
  meetingBoostSettings: {},
  groupSettings: [],
};

/* -------------------------------------------------------------------------- */
/*                              CLASS DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class SettingsStore implements SettingsStoreState {
  defaultCalendarSignaturesOnForRecurringMeetings =
    localDefaultZoomSettings.defaultCalendarSignaturesOnForRecurringMeetings;
  disableBackgroundUpload = localDefaultZoomSettings.disableBackgroundUpload;
  disableAiBackground = localDefaultZoomSettings.disableAiBackground;
  disableImageUpload = localDefaultZoomSettings.disableImageUpload;
  disableCalendarIntegration = localDefaultZoomSettings.disableCalendarIntegration;
  disableCalendarSignaturesByDefault = localDefaultZoomSettings.disableCalendarSignaturesByDefault;
  disableNonWarmlyCalendarSignaturesByDefault = localDefaultZoomSettings.disableCalendarSignaturesByDefault;
  disableCalendarPromptOnZoomLogin = localDefaultZoomSettings.disableCalendarPromptOnZoomLogin;
  disablePublicProfiles = localDefaultZoomSettings.disablePublicProfiles;
  userProfileSettings = localDefaultZoomSettings.userProfileSettings;
  meetingBoostSettings = localDefaultZoomSettings.meetingBoostSettings;
  orgSettingsId = localDefaultZoomSettings.orgSettingsId;
  skipExperiments = localDefaultZoomSettings.skipExperiments;

  groupSettings = localDefaultZoomSettings.groupSettings;

  setZoomSettings = (data: QueryZoomSettingsObjectsQuery) => {
    const value = data?.zoomSettings;

    const settings = value?.reduce((s, x) => {
      s[x['identifier']] = x;
      return s;
    }, {});

    const userZoomSettings = settings?.['user'];
    const groupZoomSettings = settings?.['group'];
    const orgZoomSettings = settings?.['org'];
    const domainZoomSettings = settings?.['domain'];
    const defaultZoomSettings = settings?.['default'];

    const zoomSettings = getInheritedSettings({
      userZoomSettings,
      groupZoomSettings,
      orgZoomSettings,
      domainZoomSettings,
      defaultZoomSettings,
    });

    if (orgZoomSettings?.id) zoomSettings.orgSettingsId = orgZoomSettings?.id;

    this.setActiveZoomSettings(zoomSettings);
    this.groupSettings = [...data?.zoomSettings.filter((x) => x.identifier === 'group')];
  };

  setActiveZoomSettings = (zoomSettings: SettingsStoreState) => {
    for (const key of Object.keys(zoomSettings)) {
      this[key] = zoomSettings[key];
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}
