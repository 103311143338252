/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

/* --------------------------------- CUSTOM --------------------------------- */
import { ConversationStarterAnswerForPrompt } from 'src/utils/conversationStarters';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export interface ConversationStarterStoreState {
  /**
   * The selected conversation starter
   */
  conversationStarter?: ConversationStarterAnswerForPrompt;
  /**
   * List of ConverationStarterAnswerForPrompt
   */
  conversationStarterAnswerForPrompts: ConversationStarterAnswerForPrompt[];
}

export const defaultConversationStarterStoreState: ConversationStarterStoreState = {
  conversationStarter: undefined,
  conversationStarterAnswerForPrompts: [],
};

/* -------------------------------------------------------------------------- */
/*                              STORE DEFINITION                              */
/* -------------------------------------------------------------------------- */
/**
 * Background related data and methods
 */
class ConversationStarterStore implements ConversationStarterStoreState {
  conversationStarterAnswerForPrompts = defaultConversationStarterStoreState.conversationStarterAnswerForPrompts;

  /**
   * @param disablePersistence if true does not persist the store
   */
  constructor(disablePersistence: boolean) {
    makeAutoObservable(this);
    !disablePersistence &&
      makePersistable(this, {
        name: 'ConversationStarterStore',
        properties: [],
        storage: window.localStorage,
      });
  }

  setConversationStarterAnswerForPrompts = (
    conversationStarterAnswerForPrompts: ConversationStarterAnswerForPrompt[]
  ) => {
    this.conversationStarterAnswerForPrompts = conversationStarterAnswerForPrompts;
  };

  setConversationStarterStore = (conversationStarterStore: ConversationStarterStoreState) => {
    for (const key of Object.keys(defaultConversationStarterStoreState)) {
      const storeValue = conversationStarterStore[key];
      if (typeof storeValue !== 'function') {
        this[key] = conversationStarterStore[key];
      }
    }
  };

  /**
   * Resets background store to default state
   */
  resetConversationStarterStore = async () => {
    // Clear persisted store in local storage
    await clearPersistedStore(this);

    for (const key of Object.keys(defaultConversationStarterStoreState)) {
      this[key] = defaultConversationStarterStoreState[key];
    }
  };
}

export default ConversationStarterStore;
