/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* --------------------------------- CUSTOM --------------------------------- */
import { UserProfileSettings } from 'src/stores/Settings.store';
import { ProfileViewData } from 'src/utils/profile/profile';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export enum ZoomUserBackgroundFieldVisibility {
  Visible = 'VISIBLE',
  Hidden = 'HIDDEN',
}

const fields = [
  'firstName',
  'lastName',
  'firstNamePronunciation',
  'lastNamePronunciation',
  'pronouns',
  'headline',
  'title',
  'department',
  'startDate',
  'linkedInUrl',
  'twitterUrl',
  'username',
  'photoUrl',
  'location',
  'locationName',
  'locationTimezone',
  'locationWeather',
  'companyName',
  'companyLogo',
  'strengths',
] as const;

export type ZoomUserBackgroundVisibilityField = typeof fields[number];

export type ZoomUserBackgroundVisibility = Record<ZoomUserBackgroundVisibilityField, ZoomUserBackgroundFieldVisibility>;

const defaultHiddenFields: ZoomUserBackgroundVisibilityField[] = ['photoUrl', 'companyName'];

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
const _filterTopLevelValues = (
  profileField: ZoomUserBackgroundVisibilityField,
  filteredProfileViewData: ProfileViewData
) => {
  if (profileField === 'location') {
    filteredProfileViewData['city'] = undefined;
    filteredProfileViewData['state'] = undefined;
    filteredProfileViewData['country'] = undefined;
    filteredProfileViewData['location'] = undefined;
    filteredProfileViewData['locationText'] = undefined;
  } else if (profileField === 'locationName') {
    filteredProfileViewData['locationText'] = undefined;
  } else if (profileField === 'companyLogo') {
    filteredProfileViewData['companyLogoUrl'] = undefined;
    filteredProfileViewData['companyLogo'] = undefined;
    if (filteredProfileViewData.company?.logoUrl) {
      filteredProfileViewData.company.logoUrl = undefined;
    }
    if (filteredProfileViewData.zoomCompany?.logoUrl) {
      filteredProfileViewData.zoomCompany.logoUrl = undefined;
    }
  } else if (profileField === 'companyName') {
    filteredProfileViewData['companyName'] = undefined;
    if (filteredProfileViewData.company?.name) {
      filteredProfileViewData.company.name = undefined;
    }
    if (filteredProfileViewData.zoomCompany?.name) {
      filteredProfileViewData.zoomCompany.name = undefined;
    }
  } else {
    filteredProfileViewData[profileField] = '';
  }
};

/**
 * Gets the default background visibility object
 * By default all fields are displayed in the background
 * @returns The default background visibility object
 */
export const getDefaultBackgroundVisibility = (
  userProfileSettings: UserProfileSettings,
  backgroundVisibility?: ZoomUserBackgroundVisibility
) => {
  const visibility = {};
  fields.forEach((f) => {
    const visibilityFlag = backgroundVisibility?.[f];
    if (visibilityFlag) {
      visibility[f] = visibilityFlag;
    } else {
      // Check default visibility
      let visibilityFlag;

      let defaultOffProfileSettings = userProfileSettings[f]?.defaultOff;
      if (
        f === 'companyLogo' &&
        (userProfileSettings.companyLogo?.defaultOff || userProfileSettings.companyName?.defaultOff)
      ) {
        defaultOffProfileSettings = true;
      }
      if (
        f === 'locationName' &&
        (userProfileSettings.location?.defaultOff || userProfileSettings.locationName?.defaultOff)
      ) {
        defaultOffProfileSettings = true;
      }
      if (
        f === 'locationWeather' &&
        (userProfileSettings.location?.defaultOff || userProfileSettings.locationWeather?.defaultOff)
      ) {
        defaultOffProfileSettings = true;
      }
      if (
        f === 'locationTimezone' &&
        (userProfileSettings.location?.defaultOff || userProfileSettings.locationTimezone?.defaultOff)
      ) {
        defaultOffProfileSettings = true;
      }

      if (defaultHiddenFields.includes(f)) {
        visibilityFlag = ZoomUserBackgroundFieldVisibility.Hidden;
      } else if (defaultOffProfileSettings) {
        visibilityFlag = ZoomUserBackgroundFieldVisibility.Hidden;
      } else {
        visibilityFlag = ZoomUserBackgroundFieldVisibility.Visible;
      }

      visibility[f] = visibilityFlag;
    }
  });
  return visibility as ZoomUserBackgroundVisibility;
};

/**
 * Takes a profileViewData removes all HIDDEN fields based on backgroundVisibility visibility settings
 *
 * @param params.profileViewData The profileViewData from which to get the public data
 * @returns The profileViewData with only the VISIBLE fields data
 */
export const getVisibleBackgroundViewData = ({
  profileViewData,
  userProfileSettings,
}: {
  profileViewData: ProfileViewData;
  userProfileSettings: UserProfileSettings;
}): ProfileViewData => {
  const visibilityFlags = getDefaultBackgroundVisibility(userProfileSettings, profileViewData.backgroundVisibility);

  for (const profileField of Object.keys(visibilityFlags) as ZoomUserBackgroundVisibilityField[]) {
    if (visibilityFlags[profileField] === ZoomUserBackgroundFieldVisibility.Hidden) {
      _filterTopLevelValues(profileField, profileViewData);
    }
  }

  return profileViewData;
};

export const getHasLocationName = (profile: ProfileViewData) =>
  profile.backgroundVisibility?.locationName
    ? profile.backgroundVisibility?.locationName === ZoomUserBackgroundFieldVisibility.Visible
    : true;

export const getHasLocationTimezone = (profile: ProfileViewData) =>
  profile.backgroundVisibility?.locationTimezone
    ? profile.backgroundVisibility?.locationTimezone === ZoomUserBackgroundFieldVisibility.Visible
    : true;
