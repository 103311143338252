/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomSdkMedia } from 'src/@types/zoomSdk';
import { PermissionErrorLinkType } from 'src/pages/Error/ConnectCalendar';
import { BackgroundTheme } from 'src/utils/backgroundAppearance';
import {
  BackgroundType,
  NametagLayoutType,
  OverlaySizeType,
  ShoutoutImageType,
  SortByField,
  WIDGET_KEY,
} from 'src/utils/constants';
import { ConversationStarterImageAnswerMediaType } from 'src/utils/conversationStarters';
import {
  ZoomUserBackgroundFieldVisibility,
  ZoomUserBackgroundVisibilityField,
} from 'src/utils/profile/backgroundVisibility';
import { ProfileFieldVisibility, ZoomUserProfileVisibilityField } from 'src/utils/profile/profileVisibility';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
export enum PosthogEventName {
  /**
   * Fires when user clicks "Watch a demo video" for calendar signatures
   */
  CalendarSignaturesWatchADemoVideoLinkClicked = 'Calendar Signatures Watch a Demo Video Link Clicked',
  /**
   * Fires when user clicks calendar signature tool tip on the settings page
   */
  CalendarSignatureHelpToolTipClicked = 'Calendar Signature Help Tool Tip Clicked',
  SignedOut = 'Signed Out',
  ProfileDataUpdated = 'Profile Data Updated',
  ProfileDataSaved = 'Profile Data Saved',
  ProfileDetailsExpanded = 'Profile Details Expanded',
  ProfileDetailsClosed = 'Profile Details Closed',
  MeetingDetailsExpanded = 'Meeting Details Expanded',
  MeetingDetailsBackClicked = 'Meeting Details Back Clicked',
  SharedContextElementClicked = 'Shared Context Element Clicked',
  OrganizationElementClicked = 'Organization Element Clicked',
  LearnMoreElementClicked = 'Learn More Element Clicked',
  PersonalNotesEdited = 'Personal Notes Edited',
  /**
   * Fires when user clicks the toggle to enable/disable calendar signatures
   */
  CalendarEventSignatureInjectionSettingToggled = 'Calendar Event Signature Injection Setting Toggled',
  /**
   * Fires when user clicks the toggle to enable/disable calendar signatures for recurring events
   */
  RecurringCalendarEventSignatureInjectionSettingToggled = 'Recurring Calendar Event Signature Injection Setting Toggled',
  /**
   * Fires when user clicks the toggle to enable/disable only injecting warmly users for calendar signatures
   */
  OnlyInjectWarmlyUsersClicked = 'Only Injecting Warmly Users Setting Clicked',
  /**
   * Fires when user clicks the toggle to enable/disable calendar signatures for all users in their domain
   */
  DomainLevelCalendarEventSignatureInjectionSettingToggled = '(Domain Level) Calendar Event Signature Injection Setting Toggled',
  /**
   * Fires when user clicks the toggle to enable/disable background upload for all users in their domain
   */
  DomainLevelBackgroundUploadSettingToggled = '(Domain Level) Background Upload Setting Toggled',
  DomainLevelAiBackgroundSettingToggled = '(Domain Level) Ai Generated Background Setting Toggled',
  DomainLevelPublicProfilesSettingToggled = '(Domain Level) Public Profiles Setting Toggled',
  /**
   * Fires when user clicks the toggle to enable/disable calendar signatures for recurring events for all users in their domain
   */
  DomainLevelRecurringCalendarEventSignatureInjectionSettingToggled = '(Domain Level) Recurring Calendar Event Signature Injection Setting Toggled',
  /**
   * Fires when user clicks the toggle to enable/disable only injecting warmly users for calendar signatures for all users in their domain
   */
  DomainLevelOnlyInjectWarmlyUsersClicked = '(Domain Level) Only Injecting Warmly Users Setting Clicked',
  RunningLatePromptOpened = 'Running Late Prompt Opened',
  RunningLateMessageSent = 'Running Late Message Sent',
  ZoomInMeetingSendAppInvitation = 'Zoom In-Meeting Send App Invitation',
  ZoomInMeetingShare = 'Zoom In-Meeting Share',
  AboutSectionElementClicked = 'About Section Element Clicked',

  //
  SetCustomZoomBackground = 'Set Custom Zoom Background',
  SetCustomZoomBackgroundAttempted = 'Set Custom Zoom Background Attempted',
  SetCustomZoomBackgroundFailed = 'Set Custom Zoom Background Failed',

  SetCustomZoomForeground = 'Set Custom Zoom Foreground',
  SetCustomZoomForegroundAttempted = 'Set Custom Zoom Foreground Attempted',
  SetCustomZoomForegroundFailed = 'Set Custom Zoom Foreground Failed',

  DownloadCustomZoomBackground = 'Download Custom Zoom Background',
  DownloadCustomZoomBackgroundFromError = 'Download Custom Zoom Background From Error',
  DownloadNametag = 'Download Nametag',

  /**
   * Fires when user sets their foreground name tag without a supported video height or width from the Zoom SDK.
   * Likely cause for this: User is on an external webcam, which Zoom does not handle well when setting a foreground (the dimensions are wrong)
   */
  JoinMeetingButtonClicked = 'Join Meeting Button Clicked',
  CopyMeetingLinkClicked = 'Copy Meeting Link Clicked',
  EditProfileClicked = 'Edit Profile Clicked',
  CustomBackgroundUploaded = 'Custom Background Uploaded',
  CustomBackgroundSelected = 'Custom Background Selected',
  NetworkCardClicked = 'Network Card Clicked',
  NetworkCardCloseClicked = 'Network Card Close Clicked',
  KeepingInTouchButtonClicked = 'Keeping In Touch Button Clicked',
  KeepingInTouchRequestIgnored = 'Keeping In Touch Request Ignored',
  KeepingInTouchVicinityToggled = 'Keeping In Touch Vicinity Toggled',
  KeepingInTouchCadenceChanged = 'Keeping In Touch Cadence Changed',
  KeepingInTouchCanceled = 'Keeping In Touch Canceled',
  KeepingInTouchRequestSent = 'Keeping In Touch Request Sent',
  KeepingInTouchRequestAccepted = 'Keeping In Touch Request Accepted',
  KeepingInTouchMessageEdited = 'Keeping In Touch Message Edited',
  ActionBarClicked = 'Action Bar Clicked',
  NetworkShowMoreClicked = 'Network Show More Clicked',
  NetworkSearched = 'Network Searched',
  GoogleAuthButtonClicked = 'Google Auth Button Clicked',
  /**
   * Fires when user clicks "See details" button on the profile card
   */
  SeeProfileDetailsButtonClicked = 'See Profile Details Button Clicked',
  /**
   * Fires when user clicks "Continue singing in" on the pre-authentication screen
   */
  ContinueSigningInButtonClicked = 'Continue Signing In Button Clicked',
  InfluencerLoadingScreenLoaded = 'Influencer Loading Screen Loaded',
  ShoutoutChanged = 'Shoutout Changed',
  WhereAreYouMessageSent = 'Where Are You Message Sent',
  KeepInTouchRequestSent = 'Keep In Touch Request Sent',
  /**
   * Fires when the user clicks the button to open the bat signal dialog
   */
  WarmlyFollowUpDialogButtonClicked = 'Warmly Follow Up Dialog Button Clicked',
  /**
   * Fires when the user clicks to send a follow up email to Warmly via the bat signal
   */
  WarmlyFollowUpSent = 'Warmly Follow Up Sent',
  /**
   * Fires when the user sends request via custom widget.
   */
  CustomWidgetSent = 'Custom Widget Request Sent',
  /**
   * Fires when the user clicks the copy button for the Warmly one page in the bat signal dialog
   */
  WarmlyOnePageCopied = 'Warmly One Page Copied',
  /**
   * Fires when the user clicks the link for the Warmly one page in the bat signal dialog
   */
  WarmlyOnePageClicked = 'Warmly One Page Clicked',
  /**
   * Fires when the user clicks the Done button in the bat signal dialog
   */
  WarmlyFollowUpDoneClicked = 'Warmly Follow Up Done Clicked',
  UserInviteClicked = 'User Invite Clicked',
  UserInviteClickedByAdmin = 'User Invite Clicked By Admin',
  /**
   * Fires when user clicks a button that routes the user to the publishable url.
   * If the user has the zoom app it deep links the user back into our app in zoom, if not then it sends
   * the user to the publishable url. Currently fires when user clicks the banner and when they click "Open in Zoom" on the last page of onboarding
   * in Web
   */
  PublishableUrlClicked = 'Publishable Url Clicked',
  CustomBannerDismissed = 'Custom Banner Dismissed',
  HomeAlertDismissed = 'Home Alert Dismissed',
  /**
   * Fires when a user clicks the zoomies banner link.
   */
  ZoomiesBannerClicked = 'Zoomies Banner Clicked',
  /**
   * Fires when a user dismisses the zoomies banner.
   */
  ZoomiesBannerDismissed = 'Zoomies Banner Dismissed',
  /**
   * Logs an event when Warmly tries to invoke zoomSdk.config but failed
   * This usually occurs when a user has installed Warmly in Zoom but did not enable Zoom Apps properly,
   * or Zoom App/SDK update broke the current SDK config method
   */
  ZoomSdkConfigurationFailed = 'ZoomSDK config failed',
  /**
   * Fires when user clicks "Skip for now" in /onboarding-profile or "Continue in Web"/"Skip" in /onboarding-backgrounds.
   * In web app the last page of onboarding would say "Continue in Web" but in Zoom the button would say "Skip for now"
   * Has boolean property "isOnboarding", which is set to true if the event is fired during onboarding
   */
  SkipButtonClicked = 'Skip Button Clicked',
  /**
   * Fires when user clicks "Skip" when prompted for calendar permissions on the "/pre-authentication" page
   */
  CalendarPermissionsSkipped = 'Calendar Permissions Skipped',
  /**
   * Fires when user clicks "Send invites and continue"
   */
  UserReferralSendInvitesButtonClicked = 'User Referral Send Invites Button Clicked',
  /**
   * Fires when user clicks "Done" or "Looks good!" on the /onboarding-profile and /onboarding-backgrounds page of onboarding.
   * In the web app, for the last page of onboarding this event does not fire, and instead the button is replaced by the "Open in Zoom" button
   * in which case what fires is "Publishable Url Clicked".
   * Has boolean property "isOnboarding", which is set to true if the event is fired during onboarding
   */
  DoneButtonClicked = 'Done Button Clicked',
  /**
   * Fires when user clicks "Sign in with Micosoft" on the login page
   */
  MicrosoftAuthLinkClicked = 'Microsoft Auth Link Clicked',
  /**
   * Fires when user clicks "Sign in with sso" on the login page
   */
  SignInWithSSOClicked = 'Sign in with SSO Clicked',
  /**
   * Fires when "No Meetings Page" is displayed to user on /meetings
   */
  NoMeetingsPageDisplayed = 'No Meetings Page Displayed',
  /**
   * Fires when permission error page (/connect-calendar) link is clicked. Has a parameter "link_type"
   * that specifies the type of link being clicked (e.g. "Terms of Service", "Privacy Policy")
   */
  PermissionErrorPageLinkClicked = 'Permission Error Page Link Clicked',
  /**
   * Fires when user clicks "Try again and allow access" or the google auth screen shot, both of which
   * route to /login if user is not in Zoom, and /onboarding-demo-meetings if user is in Zoom.
   * Should only fire on the permission error page (/connect-calendar)
   */
  PermissionErrorGoogleAuthButtonClicked = 'Permission Error Google Auth Button Clicked',
  /**
   * Fires when user clicks the Warmly logo that redirects them back to home page from the permission error page
   * For now it returns user back to /onboarding-demo-meetings if user is in zoom skinny view, and /login for the rest
   */
  PermissionErrorLogoClicked = 'Permission Error Logo Clicked',
  /**
   * Fires when user clicks the Warmly logo on the pre authentication page
   * For now it returns user back to /meetings-preview if user is in zoom skinny view, and /login for the rest
   */
  PreAuthenticationLogoClicked = 'Pre Authentication Logo Clicked',
  /**
   * User encounters the "Zoom Authorization expired or invalid" error (this occurs often due to the bugginess of zoomSdk.js)
   */
  ZoomAuthorizationExpiredOrInvalidError = 'Zoom Authorization expired or invalid error',
  /**
   * User encounters the "The API request has exceeded the server rate limit" error (this occurs often due to zoomSdk's rate limit)
   */
  ZoomApiRequestExceededRateLimitError = 'Zoom API request has exceeded the server rate limit',
  /**
   * User encounters the "The Zoom client encountered an error while processing the request!" error on the runRenderingContext method
   */
  ZoomApiRequestUnspecifiedLayersError = 'Zoom API request unspecified layers error',
  /**
   * User encounters the "Your device does not support setting virtual backgrounds" error on the setVirtualBackground method
   */
  ZoomApiVirtualBackgroundNotSupportedError = 'Zoom API request virtual background not supported error',
  /**
   * User encounters the "The native client did not provide a response to the API call" error (this occurs randomly when calling config)
   */
  ZoomConfigNativeClientError = 'Zoom Config Native Client Error',
  /**
   * Fires when a user changes the `onlyInjectWarmlyUsers` option for calendar signatures
   */
  PublicProfileViewed = 'Public profile viewed',
  LoginFromUserProfileButtonClicked = 'Login button on user public profile clicked',
  /**
   * Fires when a user clicks one of the CTA buttons on public profiles at /user
   */
  PublicProfileSocialButtonClicked = 'Social button on user public profile clicked',
  PublicProfileTopGetNametagButtonClicked = 'Top CTA on public profile clicked',
  PublicProfileBottomGetNametagButtonClicked = 'Bottom CTA on public profile clicked',
  PublicProfileDownloadNametagButtonClicked = 'Download Nametag Button on public profile clicked',
  LoginFromCompanyProfileButtonClicked = 'Login button on company public profile clicked',

  /**
   * Fires when a user clicks one of the CTA buttons on company profiles at /company-profile
   */
  CompanyProfileTopGetNametagButtonClicked = 'Top CTA on company profile clicked',
  CompanyProfileJoinCoworkersButtonClicked = 'Join coworkers on company profile clicked',
  CompanyProfileSocialButtonClicked = 'Social button on company profile clicked',

  /**
   * Fires when Warmly Logo is clicked during onboarding
   */
  OnboardingWarmlyLogo = 'Onboarding Warmly Logo Clicked',
  /**
   * Fires when a user uploads a shared custom background
   */
  SharedBackgroundUploaded = 'Shared (company) background uploaded',
  /**
   * Fires when a user shares a company shared background with their teammates via email
   */
  CustomBackgroundShared = 'Shared (company) background shared with teammate(s)',
  /**
   * Fires when a user selects a shared custom background (from the thumbnail list)
   *
   * Note that this does NOT mean that the user has actually downloaded this background
   * or has set it as their Zoom background, it purely registers when they click on the thumbnail
   */
  SharedBackgroundSelected = 'Shared (company) background selected',
  /**
   * Fires when user successfully sends user referral invites
   */
  UserReferralInvitesSent = 'User Referral Invites Sent',
  /**
   * Fires when user clicks "Explore [Pricing] Options" button on Settings page
   */
  ExplorePricingOptionsButtonClicked = 'Explore Pricing Options Button Clicked',
  /**
   * Fires when user clicks "Open Nametag Settings" button on Settings Page
   */
  OpenNametagSettings = 'Open Nametag Settings',
  /**
   * Fires when user clicks Warmly's security email on the settings page
   */
  WarmlySecurityEmailLinkClicked = 'Warmly Security Email Link Clicked',
  /**
   * Fires when user clicks the "View As" feature and selects one of the view as options
   */
  ProfileViewAsClicked = 'Profile View As Clicked',
  /**
   * Fires when user modifies the visibility/privacy setting for one of their profile fields
   */
  ProfileFieldPrivacyChanged = 'Profile Field Visibility/Privacy Setting Changed',
  /**
   * Fires when user modifies the background visibility setting for one of their profile fields
   */
  ProfileFieldBackgroundVisibilityChanged = 'Profile Field Background Visibility Setting Changed',
  /**
   * Fires when user modifies the background appearance settings
   */
  BackgroundAppearanceChanged = 'Background Appearance Changed',
  UpdateZoomCompanyModalCancelClicked = 'Update Zoom Company Modal Cancel Clicked',
  UpdateZoomCompanyModalProceedClicked = 'Update Zoom Company Modal Proceed Clicked',
  UpdateZoomCompanyModalSaveClicked = 'Update Zoom Company Modal Save Clicked',
  UpdateZoomCompanyModalHowToClicked = 'Update Zoom Company Modal How To Clicked',
  /**
   * Fires when a user updates a zoom company
   */
  ZoomCompanyUpdated = 'Zoom Company Updated',
  /**
   * Fires when a user selects from the Zoom shared backgrounds sort by filter drop down in /home
   */
  SelectZoomSharedBackgroundsSortByField = 'Select Zoom Shared Backgrounds Sort By Field',
  /**
   * Fires when user toggles the temperature scale on the settings page
   */
  TemperatureScaleToggled = 'Temperature Scale Toggled',
  /**
   * Fires when the background is successfully removed
   */
  RemoveCustomZoomBackground = 'Background successfully removed',
  /**
   * Fires immediately when the user clicks "Clear all video overlays", doesn't rely on a successful removal
   */
  RemoveCustomZoomBackgroundAttempted = 'Remove background attempted',
  /**
   * Fires when the error toast notification shows upon a user attempting to remove a custom background
   */
  RemoveCustomZoomBackgroundErrorToastNotificationShown = 'Remove Custom Zoom Background Error Toast Notification Shown',
  /**
   * Fires when the error toast notification shows upon a user attempting to set a custom foreground or background
   */
  SetCustomZoomSignatureErrorToastNotificationShown = 'Set Custom Zoom Signature Error Toast Notification Shown',
  /**
   * Fires when the video off signature download button is clicked
   */
  VideoOffSignatureDownloadClicked = 'Video Off Signature Download Clicked',
  /**
   * Fires when the video off signature help link is clicked
   */
  VideoOffSignatureHelpLinkClicked = 'Video Off Signature Help Link Clicked',
  /**
   * Fires when a user clicks on one of the email suggestions we've given them
   */
  ReferralSuggestionAdded = 'Referral suggestion added',
  /**
   * Fires when a user removes one of the suggested emails from the referrals field
   */
  ReferralSuggestionRemoved = 'Referral suggestion removed',
  /**
   * Fires when we auto-populate at least one suggestion into the referrals field
   */
  ReferralSuggestionsPopulated = 'Referral suggestions populated',
  /**
   * Fires when a user clicks the 'Set In Zoom' button from the web
   */
  SetInZoom = 'Set In Zoom',
  /**
   * Fires when a user clicks the 'Set Nametag in Zoom' button from the web
   */
  StartZoom = 'Start Zoom',
  /**
   * Fires when the background type is changed
   */
  BackgroundTypeChanged = 'Background Type Changed',
  /**
   * Fires when a user clicks the "Add to video" button of a conversation starter
   */
  SetConvoStarterAttempted = 'Set Convo Starter Attempted',
  /**
   * Fires when the conversation starter is succesfully added to video
   */
  SetConvoStarter = 'Set Convo Starter',
  /**
   * Fires when a user clicks the "Remove from video" button of a conversation starter
   */
  RemoveConvoStarterAttempted = 'Remove Convo Starter Attempted',
  /**
   * Fires when the conversation starter is succesfully removed from video
   */
  RemoveConvoStarter = 'Remove Convo Starter',
  /**
   * Fires when a user clicks the "Answer" button of a conversation starter
   */
  ConvoStarterAnswerButtonClicked = 'Answer button clicked',
  /**
   * Fires when a user clicks the "Edit Answer" button of a conversation starter
   */
  ConvoStarterEditAnswerButtonClicked = 'Edit answer button clicked',
  /**
   * Fires when the conversation starter text answer is succesfully saved
   */
  ConvoStarterTextAnswerSaved = 'Text answer saved',
  /**
   * Fires when the conversation starter image/emoji answer is succesfully saved
   */
  ConvoStarterImageEmojiAnswerSaved = 'Image/Emoji answer saved',
  /**
   * Fires when the conversation starter image answe is succesfully uploaded
   */
  ConvoStarterImageAnswerUploaded = 'Image answer uploaded',
  /**
   * Fires when the user selects one of their teammates' backgrounds (from the carousel)
   */
  TeammateBackgroundSelected = 'Teammate background selected',
  /**
   * Fires when a different meeting is selected via the meeting selector on the backgrounds page
   */
  ShoutoutMeetingChanged = 'Shoutout Meeting Changed',
  /**
   * Fires when the user clicks the "Edit Info" button in the namecard info section
   */
  EditNameCardButtonInfoClicked = '(Name Card) Edit Info Button Clicked',
  /**
   * Fires when the user clicks the "Customize Appearance" button in the namecard info section
   */
  CustomizeNameCardAppearanceButtonClicked = '(Name Card) Customize Appearance Button Clicked',
  /**
   * Fires when the user clicks one of the onboarding steps, ie "Choose background", "Just the basics", etc.
   */
  OnboardingStepSelected = 'Onboarding Step Selected',
  /**
   * Fires when the user clicks the "Back" button in onboarding
   */
  OnboardingBackClicked = 'Onboarding Back Clicked',
  /**
   * Fires when the user clicks the "Next" button in onboarding
   */
  OnboardingNextClicked = 'Onboarding Next Clicked',
  /**
   * Fires when the user clicks the "Set Zoom Background" button in onboarding
   */
  OnboardingSetClicked = 'Onboarding Set Clicked',
  /**
   * Fires when the user clicks the "Chat with us" link in the onboarding dialog
   */
  OnboardingChatWithUsClicked = 'Onboarding Chat With Us Clicked',
  /**
   * Fires when the user clicks the "Go to Zoom" button in the onboarding dialog
   */
  OnboardingGoToZoomClicked = 'Onboarding Go To Zoom Clicked',
  /**
   * Fires when the user clicks the "approval from your Zoom admin" link in the onboarding dialog
   */
  OnboardingZoomAdminClicked = 'Onboarding Zoom Admin Clicked',
  /**
   * Fires when the user clicks the "forwardable email" link in the onboarding dialog
   */
  OnboardingForwardableEmailClicked = 'Onboarding Forwardable Email Clicked',
  /**
   * Fires when the user clicks the "Check out Warmly for Web" button in the onboarding dialog
   */
  OnboardingGoToWebClicked = 'Onboarding Go To Web Clicked',
  /**
   * Fires when the user closes the onboarding dialog by clicking outside the dialog or hitting ESC
   */
  OnboardingDialogClosed = 'Onboarding Dialog Closed',
  /**
   * Fires when the user expands the nametag preview on the profile settings page (value will equal 'nametag' or 'video-off' depending on which tab they're on)
   */
  PreviewExpanded = 'Nametag Preview Expanded',
  /**
   * Fires when the user clicks a tab on the profile settings page
   */
  ProfileSettingsTabClicked = 'Profile Settings Tab Clicked',
  /**
   * Fires when the refresh button is clicked for match backgrounds
   */
  MatchBackgroundsRefreshClicked = 'Match Backgrounds Refresh Clicked',
  /**
   * Fires when user closes typeform survey modal
   */
  CloseTypeformSurveyModal = 'Close Typeform Survey Modal',
  /**
   * Fires when user opens typeform survey modal
   */
  OpenTypeformSurveyModal = 'Open Typeform Survey Modal',
  /**
   * Fires when public profiles are removed
   */
  PublicProfileRemoved = 'Public Profile Removed',
  /**
   * Fires when public profiles are enabled
   */
  PublicProfileEnabled = 'Public Profile Enabled',
  /**
   * Fires when user is prompted for calendar permissions upon logging in
   */
  PostLoginCalendarPermissionsAutoPrompted = 'Auto Prompted for Calendar Permissions Post Login',
  /**
   * Fires when user clicks the edit your info button on the nametags for all page
   */
  EditMeetingNametagClicked = 'Edit Meeting Nametag Clicked',
  /**
   * Fires when user clicks the download nametag button on the nametags for all page
   */
  DownloadMeetingNametagClicked = 'Download Meeting Nametag Clicked',
  /**
   * Fires when user clicks the create a nametag link on the nametags for all page
   */
  CreateNewMeetingNametagClicked = 'Create New Meeting Nametag Clicked',
  /**
   * Fires when user clicks the powered by warmly banner on the nametags for all page
   */
  PoweredByWarmlyBannerClicked = 'Powered By Warmly Banner Clicked',
  /**
   * Whether the unmirror/mirror video button is clicked
   */
  UnmirrorVideoClicked = 'Unmirror Video Clicked',
  /**
   * Fires when the unmirror dialog is closed
   */
  UnmirrorDialogClosed = 'Unmirror Dialog Closed',
  /**
   * Fires when the nametag tips video is clicked
   */
  NametagTipsVideoClicked = 'Nametag Tips Video Clicked',
  /**
   * Fires when the nametag layout is changed
   */
  NametagLayoutChanged = 'Nametag Layout Changed',
  /**
   * Fires when the overlay size is changed
   */
  OverlaySizeChanged = 'Overlay Size Changed',
  /**
   * Fires when an admin upload a shoutout image
   */
  AdminShoutoutImageUploaded = 'Admin Shoutout Image Uploaded',
  /**
   * Fires when an admin deletes a shoutout image
   */
  AdminShoutoutImageRemoved = 'Admin Shoutout Image Removed',
  /**
   * Fires when an admin upload a background image
   */
  AdminBackgroundImageUploaded = 'Admin Background Image Uploaded',
  /**
   * Fires when an admin deletes a background image
   */
  AdminBackgroundImageRemoved = 'Admin Background Image Removed',
  /**
   * Fires when a user chooses who to schedule an Onboarding call with
   */
  HomeCardEnhanceNametagButtonClicked = 'HomeCardEnhanceNametagButtonClicked',
  HomeCardPracticeRoomButtonClicked = 'HomeCardPracticeRoomButtonClicked',
  HomeCardInviteEmailAdded = 'HomeCardInviteEmailAdded',
  HomeCardInviteButtonClicked = 'HomeCardInviteButtonClicked',
  HomeCardMeetingClicked = 'HomeCardMeetingClicked',
  HomeCardCalSigButtonClicked = 'HomeCardCalSigButtonClicked',
  HomeCardVideoOffNametagButtonClicked = 'HomeCardVideoOffNametagButtonClicked',
  HomeCardAddBioClicked = 'Home Card Add Bio Clicked',
  HomeMessageAdminSettingsClicked = 'HomeMessageAdminSettingsClicked',
  HomeMessageUpgradeZoomClicked = 'Home Message Upgrade Zoom Clicked',
  HomeMessageRequestZoomAccessClicked = 'HomeMessageRequestZoomAccessClicked',
  HomeCardInstallZoomAppButtonClicked = 'HomeCardInstallZoomAppButtonClicked',

  DeleteAccountSubmitted = 'Delete Account Submitted',
  InClientAuthPermissionsRequested = 'In-Client Auth Permissions Requested',
  ZoomEventOnMyMediaChange = 'Zoom Event On My Media Change',

  InClientAuth = 'In-Client Auth',

  /**
   * Fires when a user edits the text field in the on-video message section
   */
  TextGemEdited = 'Text Gem Edited',
  /**
   * Fires when a user selects an image in the special images for your meeting section
   */
  ImageGemSelected = 'Image Gem Selected',
  /**
   * Fires when a user removes an image from the image section in the meeting gems tab
   */
  ImageGemRemoved = 'Image Gem Removed',
  SlideSelected = 'Slide Selected',
  SlideRemoved = 'Slide Removed',

  MeetingBoostSettingChanged = 'Video Widget Setting Changed',

  CameraModeStarted = 'Camera Mode Started',
  CameraModeSet = 'Camera Mode Set',
  CameraModeFailed = 'Camera Mode Failed',

  CanvasExperienceClicked = 'Canvas Experience Clicked',

  SelectPlanClicked = 'Select Plan Clicked',
  SeePlanOptionsClicked = 'See Plan Options Clicked',
  SetNametagClicked = 'Set Nametag Clicked',

  ContainerClicked = 'Container Clicked',
  ButtonClicked = 'Button Clicked',
  LinkClicked = 'Link Clicked',
  TabClicked = 'Tab Clicked',
  NavClicked = 'Nav Clicked',
  GuestModeSetNametag = 'Guest Mode Set Nametag',

  MeetingBoostClicked = 'Video Widget Clicked',
  MeetingBoostSet = 'Video Widget Set',

  PaywallImpression = 'Paywall Impression',
  ModalImpression = 'Modal Impression',
  ToastImpression = 'Toast Impression',
  MessagePopUp = 'Message Pop Up',

  AppLoaded = 'App Loaded',

  ExpiredTokenDetected = 'Expired Token Detected',

  GenerateAIBackground = 'Generate AI Background',

  AuditLogCreateExport = 'Audit Log Create Export',
  AuditLogGetExport = 'Audit Log Get Export',

  // Groups
  ZoomGroupUpdated = 'Zoom Group Updated',
  UpdateZoomGroupModalCancelClicked = 'Update Zoom Group Modal Cancel Clicked',
  UpdateZoomGroupModalProceedClicked = 'Update Zoom Group Modal Proceed Clicked',
  UpdateZoomGroupModalSaveClicked = 'Update Zoom Group Modal Save Clicked',
  UpdateZoomGroupModalHowToClicked = 'Update Zoom Group Modal How To Clicked',

  // Presentations
  UploadPresentationStart = 'Upload Presentation Start',
  UploadPresentationFinish = 'Upload Presentation Finish',

  PreloaderShown = 'Preloader Shown',
  PreloaderHidden = 'Preloader Hidden',
}

export interface PosthogCaptureProperties {
  email?: string;
  user_id?: string;
  first_name?: string;
  last_name?: string;
  auth_type?: string;
  app_context?: string;
  participant_id?: string;
  organization_property?: string;
  organization_value?: string;
  learnmore_property?: string;
  learnmore_value?: string;
  sharedcontext_property?: string;
  sharedcontext_value?: string;
  profile_attribute?: string;
  /**
   * profile form field value. E.g. if profile_attribute equals "title", profile_attribute_value might equal "Cofounder & CEO"
   */
  profile_attribute_value?: string;
  toggle_value?: string;
  meeting_id?: number;
  is_onboarding?: boolean;
  save_type?: string;
  action_type?: string;
  join_meeting_text?: string;
  conference_provider?: string;
  component_context?: string;
  path_name?: string;
  action_bar_property?: string;
  action_bar_value?: string;
  search_value?: string;
  shoutout_type?: string;
  shoutout_message?: string;
  screen_width?: number;
  publishable_url_clicked_context?: string;
  calendar_event_count?: number;
  user_agent?: string;
  link_type?: PermissionErrorLinkType;
  /**
   * Path name for the user selected background image from the custom backgrounds thumbnail list
   */
  background_image_selected?: string;
  /**
   * Whether event is fired from inside a zoom meeting
   */
  is_in_zoom_meeting?: boolean;
  /**
   * For general use for identifying different types of events without having to add keys to this interface
   * ex: { instance_type : 'meeting', instance_id: meeting.id }
   */
  instance_type?: string;
  instance_id?: string;
  /**
   * For Segment "OnboardingNextClicked" or "OnboardingBackClicked" event, the page path
   */
  onboarding_page?: string;
  keep_in_touch_sender?: string;
  keep_in_touch_recipient?: string;
  keep_in_touch_message?: string;
  keep_in_touch_cadence?: number;
  keep_in_touch_vicinity?: boolean;
  loom_video?: string;
  /**
   * Calendar signature help tool tip type
   */
  calendar_signature_help_tool_tip_type?: string;
  /**
   * Number of emails a user refers
   */
  num_referral_invitees?: number;
  profile_view_as_state?: ProfileFieldVisibility;
  profile_privacy_field_state?: ProfileFieldVisibility;
  profile_privacy_field_toggled?: ZoomUserProfileVisibilityField;
  /**
   * The state of the profile background visibility field
   */
  profile_background_visibility_field_state?: ZoomUserBackgroundFieldVisibility;
  /**
   * The profile background visibility field that was toggled
   */
  profile_background_visibility_field_toggled?: ZoomUserBackgroundVisibilityField;
  /**
   * The sort by field when selecting from a dropdown menu
   */
  sort_by_field?: SortByField;
  /**
   * The temperature scale
   */
  temperature_scale?: string;
  /**
   * The selected theme of the background
   */
  theme_selected?: BackgroundTheme;
  /**
   * The selected font family of the background
   */
  font_selected?: string;
  /**
   * Whether the signature type is background (true) or foreground (false)
   */
  signature_is_background?: boolean;
  /**
   * The type of the "foreground" background
   */
  background_type?: BackgroundType;
  signature_error_type?: 'background' | 'foreground';
  /**
   * The added to or removed from video conversation starter prompt
   */
  conversation_starter_prompt?: string;
  /**
   * The saved text answer for a conversation starter prompt
   */
  conversation_starter_text_answer?: string;
  /**
   * The saved image answer for a conversation starter prompt
   */
  conversation_starter_image_answer?: string;
  /**
   * The saved emoji answer for a conversation starter prompt
   */
  conversation_starter_emoji_answer?: string;
  /**
   * The media type of the saved image answer for a conversation starter prompt
   */
  conversation_starter_image_answer_media_type?: ConversationStarterImageAnswerMediaType;
  /*
   * Indicates the section from where a background is shared. Currently this includes teammates' backgrounds (the carousel)
   * or the company shared sections
   */
  shared_background_section?: 'teammates_backgrounds' | 'company_shared';
  /**
   * The title of the selected meeting
   */
  meeting_title?: string;
  /**
   * The value of a text input
   */
  input?: string;
  /**
   * branded gem selected image type
   */
  image_selected?: ShoutoutImageType | string;
  /**
   * giphy url
   */
  gif_selected?: string;
  /**
   * URL of the uploaded gem image
   */
  image_uploaded?: string;
  /**
   * URL of the removed gem image
   */
  image_removed?: string;
  /**
   * Generic string value
   */
  value?: string;
  /**
   * Title of the Typeform survey
   */
  survey_title?: string;
  /**
   * Whether the user toggles the overlays
   */
  overlays_toggled?: boolean;
  /**
   * Whether the user toggles the background
   */
  background_toggled?: boolean;
  /**
   * Whether the user toggles the nametag
   */
  nametag_toggled?: boolean;
  /**
   * Id of the zoom contact
   */
  zoom_contact_id?: string;
  /**
   * Id of the zoom event
   */
  zoom_event_id?: string;
  /**
   * Name of the company
   */
  company_name?: string;
  /**
   * Nametag layout type
   */
  layout_type?: NametagLayoutType;
  /**
   * Overlay size type
   */
  overlay_size?: OverlaySizeType;
  /**
   * Whether the user is an org admin
   */
  isOrgAdmin?: boolean;
  /**
   * An arbitrary, stringified payload
   */
  payload?: string;
  attempts?: number;
  widget?: WIDGET_KEY;

  media?: ZoomSdkMedia;

  canvas_height?: number;
  canvas_width?: number;
  /**
   * Generic identifier value for when we need to pass an id
   */
  identifier?: string;
  guest_mode_data?: Record<string, string | ZoomUserLocation | null | undefined>;

  method?: string;
  error_code?: string;
}

export enum PosthogAboutElement {
  Email = 'Email',
  WarmlyProfile = 'WarmlyProfile',
}

export enum PosthogCalendarSignatureHelpToolTipType {
  EnableCalendarSignature = 'Enable Calendar Signatures',
  SkipNonWarmlyProfiles = 'Skip Non-Warmly Profiles',
  IncludeRecurringMeetings = 'Include Recurring Meetings',
}

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
export const PosthogAppContext = {
  Web: 'Web',
  Zoom: 'Zoom',
  Mobile: 'Mobile',
};

export const PosthogOnboardingSaveType = {
  Save: 'Save',
  Cancel: 'Cancel',
};

export const PosthogOrganizationElement = {
  Domain: 'Domain',
  OrgChart: 'Org Chart',
  News: 'News',
  LinkedIn: 'LinkedIn',
  Crunchbase: 'Crunchbase',
};

export const PosthogSharedContextElement = {
  LinkedIn: 'LinkedIn',
  Gmail: 'Gmail',
};

export const PosthogLearnMoreElement = {
  LinkedIn: 'LinkedIn',
  Twitter: 'Twitter',
};

export const PosthogProfileDataAttributes = {
  ProfilePhoto: 'Profile Photo',
  Company: 'Company',
  Location: 'Location',
  Username: 'Username',
};

export const PosthogToggleValue = {
  On: 'On',
  Off: 'Off',
};

export const PosthogActionType = {
  SetBackground: 'Set Zoom Background',
  DownloadNametag: 'Download Nametag',
  DownloadZoomBackground: 'Download Zoom Background',
  RemoveBackground: 'Remove Zoom Background',
  BlurBackground: 'Blur Zoom Background',
  SetForeground: 'Set Zoom Foreground',
  RemoveForeground: 'Remove Zoom Foreground',
};

export const PosthogComponentContext = {
  MeetingAlert: 'Meeting Alert',
  ActionBar: 'Action Bar',
  CustomBackground: 'Custom Background',
  MeetingParticipant: 'Meeting Participant',
};

export const ActionBarElement = {
  Email: 'Email',
  Calendar: 'Calendar',
  LinkedIn: 'LinkedIn',
  WhereAreYou: 'Where Are You',
};

export const PublishableUrlClickedContext = {
  CustomBanner: 'Custom Banner',
  CustomButton: 'Custom Button',
};
