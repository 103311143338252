/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';
import { z } from 'zod';

/* --------------------------------- CUSTOM --------------------------------- */
import { onClickAuthLink } from 'src/utils/auth';
import { parseDomainFromEmail } from 'src/utils/functions/functions';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
interface SSOLoginStoreState {
  email?: string;
  error?: string;
  ssoLoginSelected: boolean;
  loading: boolean;
}

const initialState: SSOLoginStoreState = {
  ssoLoginSelected: false,
  loading: false,
};

/* -------------------------------------------------------------------------- */
/*                              STORE DEFINITION                              */
/* -------------------------------------------------------------------------- */
class SSOLoginStore implements SSOLoginStoreState {
  /* ---------------------------- MEMBER VARIABLES ---------------------------- */
  email = initialState.email;
  ssoLoginSelected = initialState.ssoLoginSelected;
  loading = initialState.loading;
  error = initialState.error;
  private submitAttempted = false;

  /* --------------------------------- ACTIONS -------------------------------- */
  selectSSOLogin = () => (this.ssoLoginSelected = true);
  cancelSSOLogin = () => (this.ssoLoginSelected = false);
  setEmail = (email: string) => {
    this.submitAttempted = false;
    this.error = undefined;
    this.email = email;
  };

  signIn = async () => {
    try {
      this.submitAttempted = true;
      if (!this.email || !this.emailIsValid) return;
      this.loading = true;
      this.error = initialState.error;
      await onClickAuthLink({
        authType: 'sso',
        email: this.email,
      });
      this.error = initialState.error;
    } catch (e) {
      const error = e as Error;
      const domain = parseDomainFromEmail(this.email || '');
      const errorMessage = error.message.includes(`404`)
        ? `Domain ${domain} is not supported`
        : 'An error occurred connecting to your SSO provider. Please try again.';
      this.error = errorMessage;
    } finally {
      this.loading = false;
    }
  };

  resetSSOLoginStore = () => {
    this.email = initialState.email;
    this.ssoLoginSelected = initialState.ssoLoginSelected;
    this.loading = initialState.loading;
    this.error = initialState.error;
  };

  /* ----------------------------- COMPUTED FIELDS ---------------------------- */
  get emailIsValid() {
    return z.string().email().safeParse(this.email).success;
  }

  get errorMessage() {
    if (this.submitAttempted) {
      if (!this.email) return `Email is required`;
      else if (!this.emailIsValid) return `${this.email} is not a valid email`;
    }
    return this.error;
  }

  get submitDisabled() {
    return this.loading || !this.email || (this.submitAttempted && !this.emailIsValid);
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default SSOLoginStore;
