import {
  InsertZoomUserActionsDocument,
  InsertZoomUserActionsMutation,
  InsertZoomUserActionsMutationVariables,
  QueryZoomUserActionsObjectsDocument,
  QueryZoomUserActionsObjectsQuery,
  QueryZoomUserActionsObjectsQueryVariables,
  UpdateZoomUserActionsDocument,
  UpdateZoomUserActionsMutation,
  UpdateZoomUserActionsMutationVariables,
  ZoomUserActions_Set_Input,
  ZoomUserFragment,
} from 'src/graphql';
import { logError } from 'src/modules/analytics';
import { apolloClient } from 'src/pages/ApolloClientProvider';
import {
  CURRENT_ENVIRONMENT,
  Environment,
  WarmlyUrl,
  ZOOM_BG_CLOUDINARY_MAP,
  ZOOM_CUSTOM_BACKGROUNDS,
  zoomAppDashboardUrlByEnvironment,
} from 'src/utils/constants';
import { parseDomainFromEmail } from 'src/utils/functions/functions';

/* -------------------------------- CONSTANTS ------------------------------- */
/**
 * Potential words and abbreviations for the United States. All in lower case.
 *
 * Used to detect locations that are in the U.S.
 */
const USA_LOCATION_WORDS = ['us', 'usa', 'united states', 'united states of america'];

const ZOOM_EMPLOYEE_EMAIL_DOMAINS = ['zoom.us', 'zoom.com', 'zoomineer.com'];

const WARMLY_INTERNAL_DOMAINS = ['warmly.ai', 'getwarmly.com', 'warmlycomma.com'];

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
/**
 * Returns whether a location string contains US location words
 * @param locationString The location string
 * @returns Boolean for whether the string contains US location word
 */
export const containsUSLocation = (locationString: string): boolean => {
  const locationStringArray = locationString.split(',').map((locText) => locText.trim());
  const lastLocationPart = locationStringArray[locationStringArray.length - 1];

  return USA_LOCATION_WORDS.includes(lastLocationPart.toLowerCase());
};

/**
 * Returns the modified location string from the `location.description` property in zoomUser based on the following rules:
 *
 * - The `locationDescription` must be from the `location` json field, not the legacy `city`/`state`/`country` fields
 * - If the location description suggests that it's a U.S. location and has city + state, we remove the "country" part of the location
 * - If the location description is U.S. location and does NOT contain the state, do not strip out the "country" part
 *
 * For example, "Austin, TX, USA" and "New York, NY, United States" would become "Austin, TX" and "New York, NY", respectively.
 *
 * Whereas if the descriptions were "Austin, USA" or "New York, United States",
 * we would return the strings unmodified.
 *
 * Also, "TX, US" and "South Carolina, United States" would also be returned unmodified.
 *
 * Non-U.S. locations are not modified
 * @param locationDescription The location description string from `zoomUser.location`
 * @returns The location string, or undefined if no location description
 */
export const getLocationStringFromLocationDescription = (locationDescription?: string): string | undefined => {
  if (!locationDescription) {
    return;
  }

  const locationStringArray = locationDescription.split(',').map((locText) => locText.trim());

  if (locationStringArray.length <= 2) {
    return locationDescription;
  } else {
    const lastLocationPart = locationStringArray[locationStringArray.length - 1];

    if (USA_LOCATION_WORDS.includes(lastLocationPart.toLowerCase())) {
      locationStringArray.pop();

      return locationStringArray.join(', ');
    } else {
      return locationDescription;
    }
  }
};

/**
 * Uses the `getLocationStringFromLocationDescription` helper function to extract location string from
 * profileData. Will fall back to the `city`/`state`/`country` fields if the profile does not have a `location` json
 *
 * @param profileData The profileData from which to get the location string
 * @returns The location string (with country omitted if it's a U.S. location that has city & state)
 */
export const getLocationStringFromProfileData = (profileData?: {
  location?: ZoomUserLocation | null;
  city?: string;
  state?: string;
  country?: string;
}): string | undefined => {
  if (!profileData) {
    return;
  }

  if (profileData.location?.description) {
    return getLocationStringFromLocationDescription(profileData.location?.description);
  } else if (profileData.city && profileData.state) {
    return `${profileData.city}, ${profileData.state}`;
  } else if (profileData.city && profileData.country) {
    return `${profileData.city}, ${profileData.country}`;
  } else if (profileData.state && profileData.country) {
    return `${profileData.state}, ${profileData.country}`;
  } else {
    return profileData.city || profileData.state || profileData.country;
  }
};

/**
 * Get the public profile URL for a given user's username.
 *
 * @param username Username of the user
 * @param withHttps Whether to include the http:// or https:// protocol part of the URL
 * @returns The public profile URL
 */
export const getPublicProfileUrl = (username: string, withHttps?: boolean): string => {
  const baseUrl =
    CURRENT_ENVIRONMENT === Environment.Production
      ? WarmlyUrl.MarketingHome
      : zoomAppDashboardUrlByEnvironment[CURRENT_ENVIRONMENT] + '/user';

  return withHttps ? `${baseUrl}/${username}` : `${baseUrl.replace('https://', '').replace('http://', '')}/${username}`;
};

/**
 * Determines whether the email is a Zoom employee's email
 *
 * Used for certain situations where we give Zoom employees special treatment,
 * such as turning off calendar signatures by default
 * @param email Email of the user
 * @returns whether the email belongs to a Zoom employee
 */
export const isZoomEmployee = (email: string): boolean => {
  if (!email) {
    return false;
  }
  const emailDomain = parseDomainFromEmail(email);
  return ZOOM_EMPLOYEE_EMAIL_DOMAINS.includes(emailDomain);
};

/**
 * Determines whether the email is a Warmly internal employee
 * @param email Email of the user
 * @returns whether the email belongs to a Warmly employee
 */
export const isWarmlyInternalEmployee = (email: string): boolean => {
  if (!email) {
    return false;
  }
  const emailDomain = parseDomainFromEmail(email);
  return WARMLY_INTERNAL_DOMAINS.includes(emailDomain);
};

/**
 * Transforms deprecated background urls
 * @param backgroundUrl Url of background to transform
 * @returns non-deprecated background url
 */
export const transformBackgroundUrl = (backgroundUrl?: string | null): string | undefined =>
  backgroundUrl && ZOOM_BG_CLOUDINARY_MAP[backgroundUrl]
    ? ZOOM_BG_CLOUDINARY_MAP[backgroundUrl]
    : backgroundUrl || undefined;

/**
 * Gets a random background url from the ZOOM_CUSTOM_BACKGROUNDS array
 * @returns A random background url
 */
export const getRandomBackground = () => {
  return ZOOM_CUSTOM_BACKGROUNDS[Math.floor(Math.random() * ZOOM_CUSTOM_BACKGROUNDS.length)];
};

export const upsertUserActions = async (user: ZoomUserFragment, set: ZoomUserActions_Set_Input) => {
  let insertActions = !user.actions;
  if (!insertActions) {
    const actionsResponse = await apolloClient.query<
      QueryZoomUserActionsObjectsQuery,
      QueryZoomUserActionsObjectsQueryVariables
    >({
      query: QueryZoomUserActionsObjectsDocument,
      variables: { where: { zoomUserId: { _eq: user.id } } },
      fetchPolicy: 'no-cache',
    });
    insertActions = !actionsResponse.data.zoomUserActions.length;
  }

  if (insertActions) {
    apolloClient.mutate<InsertZoomUserActionsMutation, InsertZoomUserActionsMutationVariables>({
      mutation: InsertZoomUserActionsDocument,
      variables: { objects: [set] },
      refetchQueries: ['queryZoomUserObjects'],
    });
  } else {
    apolloClient.mutate<UpdateZoomUserActionsMutation, UpdateZoomUserActionsMutationVariables>({
      mutation: UpdateZoomUserActionsDocument,
      variables: { set, where: { zoomUserId: { _eq: user.id } } },
    });
  }
};
/**
 * Converts the estimatedCompanySize field in zoomCompany from a string to an integer
 * Handles cases where the string is a range or unbounded (e.g. 10,000+ or 101-250)
 * @param companySizeString The company size string
 * @returns The integer value of the company size. Fall back to 0 to stay conservative.
 */
export const convertCompanySizeEstimateStringToInteger = (companySizeString: string | undefined | null): number => {
  if (!companySizeString) {
    return 0;
  }

  try {
    /**
     * Remove commas in the string. Example case: "10,000" becomes "10000"
     */
    const parsedCompanySizeString = companySizeString.replace(',', '');

    /**
     * Example case: estimatedCompanySize is "10000+" becomes 10000
     */
    const plusSignIndex = parsedCompanySizeString.indexOf('+');
    if (plusSignIndex !== -1) {
      const parsedSubString = parsedCompanySizeString.substring(0, plusSignIndex);

      return parseInt(parsedSubString) || 0;
    }

    /**
     * Example case: estimatedCompanySize is "501-1000" becomes 501
     * We always take the lower bound number to conservatively estimate company size
     */
    const hyphenIndex = parsedCompanySizeString.indexOf('-');
    if (hyphenIndex !== -1) {
      const parsedSubString = parsedCompanySizeString.substring(0, hyphenIndex);

      return parseInt(parsedSubString) || 0;
    }

    return parseInt(parsedCompanySizeString) || 0;
  } catch (err) {
    logError(err);
    return 0;
  }
};

/**
 * Get's the user's display name given the user's first/last name and email
 * @param firstName The user's first name
 * @param lastName The user's last name
 * @param email The user's email
 * @returns The user's display name
 */
export const getUserDisplayName = (firstName?: string, lastName?: string, email?: string) => {
  return `${firstName || ''}${firstName ? ' ' : ''}${lastName || ''}${!firstName && !lastName ? email : ''}`;
};
