/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeAutoObservable } from 'mobx';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
interface CustomBannerStoreState {
  /**
   * The height of the banner container
   */
  bannerHeight: number;
  /**
   * Whether zoomies banner has been dismissed
   */
  zoomiesBannerDismissed: boolean;
  /**
   * The number of banners rendered
   */
  bannerCount: number;
  /**
   * Function to set the banner container height state.
   * @note This does not change the banner container height.
   */
  setBannerHeight: (height: number) => void;
}

/* -------------------------------------------------------------------------- */
/*                              STORE DEFINITION                              */
/* -------------------------------------------------------------------------- */
export default class CustomBannerStore implements CustomBannerStoreState {
  /* ---------------------------- MEMBER VARIABLES ---------------------------- */
  bannerHeight = 0;
  bannerCount = 0;
  zoomiesBannerDismissed = false;

  /* --------------------------------- ACTIONS -------------------------------- */
  setBannerHeight = (height: number) => (this.bannerHeight = height);
  setBannerCount = (bannerCount: number) => (this.bannerCount = bannerCount);
  onEntering = () => this.setBannerCount(this.bannerCount + 1);
  onExited = () => this.setBannerCount(this.bannerCount - 1);
  dismissZoomBanner = () => (this.zoomiesBannerDismissed = true);

  /* ------------------------------- CONSTRUCTOR ------------------------------ */
  constructor() {
    makeAutoObservable(this);
  }
}
