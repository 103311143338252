import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  bigint: number;
  citext: any;
  date: string;
  jsonb: { [key: string]: any };
  timestamp: any;
  timestamptz: string;
  uuid: string;
};

export type AppStatus = {
  __typename?: 'AppStatus';
  integrationType?: Maybe<Scalars['String']>;
  sso?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  tokenStatus?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CanEditCompanyResponse = {
  __typename?: 'CanEditCompanyResponse';
  canEdit: Scalars['Boolean'];
  requiresPermission: Scalars['Boolean'];
};

export type ConnectCalendarResults = {
  __typename?: 'ConnectCalendarResults';
  calendarConnected: Scalars['Boolean'];
  email: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  shouldConnectCalendar: Scalars['Boolean'];
  zoomUserId: Scalars['String'];
};

export type ContactSuggestionOptions = {
  includeZoomUsers?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  searchOrg?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type ContactSuggestionResult = {
  __typename?: 'ContactSuggestionResult';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export enum EnableSettingForOrgIdType {
  AiBackground = 'AiBackground',
  BackgroundUpload = 'BackgroundUpload',
  CalendarIntegration = 'CalendarIntegration',
  CalendarSignatures = 'CalendarSignatures',
  ImageUpload = 'ImageUpload',
  NonWarmlyInjections = 'NonWarmlyInjections',
  PublicProfiles = 'PublicProfiles',
  RecurringEventsInjection = 'RecurringEventsInjection',
}

export type EncodedIdBackfillResult = {
  __typename?: 'EncodedIdBackfillResult';
  backfilled: Scalars['Float'];
  contactsWithoutEncodedId: Scalars['Float'];
  contactsWithoutEncodedIdAfterBackfill: Scalars['Float'];
  notBackfilled: Scalars['Float'];
  totalContactCount: Scalars['Float'];
};

export type EncodedIdBackfillStats = {
  __typename?: 'EncodedIdBackfillStats';
  contactsWithoutEncodedId: Scalars['Float'];
  totalContactCount: Scalars['Float'];
};

export type Feature = {
  __typename?: 'Feature';
  description: Scalars['String'];
  identifier: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type FeatureAccess = {
  __typename?: 'FeatureAccess';
  hasAccess: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type InviteSuggestionOptions = {
  limit?: InputMaybe<Scalars['Float']>;
};

export type InviteSuggestionResult = {
  __typename?: 'InviteSuggestionResult';
  email: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  currency?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  features: Array<Feature>;
  identifier: Scalars['String'];
  interval?: Maybe<Scalars['String']>;
  isUserOnCurrentPlan: Scalars['Boolean'];
  name: Scalars['String'];
  pricingType?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  stripeProductId?: Maybe<Scalars['String']>;
  unitAmount?: Maybe<Scalars['Float']>;
};

export type PublicCompanyBackground = {
  __typename?: 'PublicCompanyBackground';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<PublicCompanyUser>;
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PublicCompanyInfo = {
  __typename?: 'PublicCompanyInfo';
  backgrounds: Array<PublicCompanyBackground>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  industry?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  users: Array<PublicCompanyUser>;
  yearFounded?: Maybe<Scalars['String']>;
};

export type PublicCompanyUser = {
  __typename?: 'PublicCompanyUser';
  createdAt?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type PublicEvent = {
  __typename?: 'PublicEvent';
  id: Scalars['String'];
  participants?: Maybe<Array<PublicParticipant>>;
  start?: Maybe<Scalars['DateTimeISO']>;
  summary?: Maybe<Scalars['String']>;
};

export type PublicParticipant = {
  __typename?: 'PublicParticipant';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  ask?: Maybe<Scalars['String']>;
  company?: Maybe<PublicUserCompanyInfo>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  isInNetwork?: Maybe<Scalars['Boolean']>;
  isKeepInTouch?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSON']>;
  photoUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type PublicUserCompanyInfo = {
  __typename?: 'PublicUserCompanyInfo';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  industry?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type StripeSession = {
  __typename?: 'StripeSession';
  redirectUrl?: Maybe<Scalars['String']>;
  stripeCustomerId: Scalars['String'];
  stripeSessionId: Scalars['String'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  clientSecret?: Maybe<Scalars['String']>;
  isStripeSubscriptionActive: Scalars['Boolean'];
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId: Scalars['String'];
};

export type UsernameBackfillResult = {
  __typename?: 'UsernameBackfillResult';
  backfilled: Scalars['Float'];
  duplicatedUsernames: Scalars['Float'];
  notBackfilled: Scalars['Float'];
  resolvedDuplicates: Scalars['Float'];
  totalUserCount: Scalars['Float'];
  usersWithoutUsername: Scalars['Float'];
};

export type UsernameBackfillStats = {
  __typename?: 'UsernameBackfillStats';
  duplicatedUsernames: Scalars['Float'];
  totalUserCount: Scalars['Float'];
  usersWithoutUsername: Scalars['Float'];
};

export type UsernameInput = {
  username: Scalars['String'];
};

export type WorkOsAuditLogActor = {
  id: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type WorkOsAuditLogContext = {
  location: Scalars['String'];
  userAgent?: InputMaybe<Scalars['String']>;
};

export type WorkOsAuditLogTarget = {
  id: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']>;
};

/** columns and relationships of "client" */
export type Client = {
  __typename?: 'client';
  /** An array relationship */
  clientContacts: Array<ClientContact>;
  /** An aggregate relationship */
  clientContacts_aggregate: ClientContact_Aggregate;
  /** An array relationship */
  clientEmailReports: Array<ClientEmailReport>;
  /** An aggregate relationship */
  clientEmailReports_aggregate: ClientEmailReport_Aggregate;
  /** An array relationship */
  clientNotificationEmails: Array<ClientNotificationEmail>;
  /** An aggregate relationship */
  clientNotificationEmails_aggregate: ClientNotificationEmail_Aggregate;
  codeName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  csvUploads: Array<CsvUpload>;
  /** An aggregate relationship */
  csvUploads_aggregate: CsvUpload_Aggregate;
  firestoreId?: Maybe<Scalars['String']>;
  /** An object relationship */
  hubspotIntegration?: Maybe<HubspotIntegration>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  integrationType?: Maybe<Scalars['String']>;
  /** An object relationship */
  lastCsvUpload?: Maybe<CsvUpload>;
  lastCsvUploadId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  ownerMappingStatus?: Maybe<Scalars['String']>;
  qaStatus?: Maybe<Scalars['String']>;
  qaStatusUpdatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  salesforceIntegration?: Maybe<SalesforceIntegration>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userInvites: Array<UserInvite>;
  /** An aggregate relationship */
  userInvites_aggregate: UserInvite_Aggregate;
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
};

/** columns and relationships of "client" */
export type ClientClientContactsArgs = {
  distinct_on?: InputMaybe<Array<ClientContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContact_Order_By>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContact_Order_By>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientEmailReportsArgs = {
  distinct_on?: InputMaybe<Array<ClientEmailReport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientEmailReport_Order_By>>;
  where?: InputMaybe<ClientEmailReport_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientEmailReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientEmailReport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientEmailReport_Order_By>>;
  where?: InputMaybe<ClientEmailReport_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientNotificationEmailsArgs = {
  distinct_on?: InputMaybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientNotificationEmail_Order_By>>;
  where?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientNotificationEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientNotificationEmail_Order_By>>;
  where?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientCsvUploadsArgs = {
  distinct_on?: InputMaybe<Array<CsvUpload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CsvUpload_Order_By>>;
  where?: InputMaybe<CsvUpload_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientCsvUploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CsvUpload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CsvUpload_Order_By>>;
  where?: InputMaybe<CsvUpload_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientUserInvitesArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientUserInvites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContact = {
  __typename?: 'clientContact';
  clientId: Scalars['uuid'];
  /** An object relationship */
  contactData?: Maybe<ContactData>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  crmCompanyName?: Maybe<Scalars['String']>;
  /** An array relationship */
  crmData: Array<ClientContactCrmData>;
  /** An aggregate relationship */
  crmData_aggregate: ClientContactCrmData_Aggregate;
  crmEmail?: Maybe<Scalars['String']>;
  crmEmailIsValid?: Maybe<Scalars['Boolean']>;
  crmEmailValidatedAt?: Maybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: Maybe<Scalars['String']>;
  crmTitle?: Maybe<Scalars['String']>;
  csvUploadId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  currentJobChange?: Maybe<ClientContactJobChange>;
  currentJobChangeId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  enrichmentHistories: Array<CrmContactEnrichmentHistory>;
  /** An aggregate relationship */
  enrichmentHistories_aggregate: CrmContactEnrichmentHistory_Aggregate;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  jobChanges: Array<ClientContactJobChange>;
  /** An aggregate relationship */
  jobChanges_aggregate: ClientContactJobChange_Aggregate;
  lastName?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  ownerUser?: Maybe<User>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  qaAssignedAt?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceOwnerId?: Maybe<Scalars['String']>;
  serialId: Scalars['Int'];
  setMonitoredAt?: Maybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "clientContact" */
export type ClientContactCrmDataArgs = {
  distinct_on?: InputMaybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactCrmData_Order_By>>;
  where?: InputMaybe<ClientContactCrmData_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactCrmData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactCrmData_Order_By>>;
  where?: InputMaybe<ClientContactCrmData_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactEnrichmentHistoriesArgs = {
  distinct_on?: InputMaybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactEnrichmentHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactJobChangesArgs = {
  distinct_on?: InputMaybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactJobChange_Order_By>>;
  where?: InputMaybe<ClientContactJobChange_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactJobChanges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactJobChange_Order_By>>;
  where?: InputMaybe<ClientContactJobChange_Bool_Exp>;
};

/** columns and relationships of "clientContactCrmData" */
export type ClientContactCrmData = {
  __typename?: 'clientContactCrmData';
  clientContactId: Scalars['uuid'];
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  crmType?: Maybe<Scalars['String']>;
  fieldLabel?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "clientContactCrmData" */
export type ClientContactCrmData_Aggregate = {
  __typename?: 'clientContactCrmData_aggregate';
  aggregate?: Maybe<ClientContactCrmData_Aggregate_Fields>;
  nodes: Array<ClientContactCrmData>;
};

export type ClientContactCrmData_Aggregate_Bool_Exp = {
  count?: InputMaybe<ClientContactCrmData_Aggregate_Bool_Exp_Count>;
};

export type ClientContactCrmData_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ClientContactCrmData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClientContactCrmData_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clientContactCrmData" */
export type ClientContactCrmData_Aggregate_Fields = {
  __typename?: 'clientContactCrmData_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ClientContactCrmData_Max_Fields>;
  min?: Maybe<ClientContactCrmData_Min_Fields>;
};

/** aggregate fields of "clientContactCrmData" */
export type ClientContactCrmData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientContactCrmData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientContactCrmData" */
export type ClientContactCrmData_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ClientContactCrmData_Max_Order_By>;
  min?: InputMaybe<ClientContactCrmData_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clientContactCrmData" */
export type ClientContactCrmData_Arr_Rel_Insert_Input = {
  data: Array<ClientContactCrmData_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClientContactCrmData_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clientContactCrmData". All fields are combined with a logical 'AND'. */
export type ClientContactCrmData_Bool_Exp = {
  _and?: InputMaybe<Array<ClientContactCrmData_Bool_Exp>>;
  _not?: InputMaybe<ClientContactCrmData_Bool_Exp>;
  _or?: InputMaybe<Array<ClientContactCrmData_Bool_Exp>>;
  clientContactId?: InputMaybe<Uuid_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  crmType?: InputMaybe<String_Comparison_Exp>;
  fieldLabel?: InputMaybe<String_Comparison_Exp>;
  fieldName?: InputMaybe<String_Comparison_Exp>;
  fieldValue?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientContactCrmData" */
export enum ClientContactCrmData_Constraint {
  /** unique or primary key constraint on columns "clientContactId", "fieldName", "crmType" */
  ClientContactCrmDataCrmTypeFieldNameClientContactIdKey = 'clientContactCrmData_crmType_fieldName_clientContactId_key',
  /** unique or primary key constraint on columns "id" */
  ClientContactCrmDataPkey = 'clientContactCrmData_pkey',
}

/** input type for inserting data into table "clientContactCrmData" */
export type ClientContactCrmData_Insert_Input = {
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmType?: InputMaybe<Scalars['String']>;
  fieldLabel?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  fieldValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClientContactCrmData_Max_Fields = {
  __typename?: 'clientContactCrmData_max_fields';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmType?: Maybe<Scalars['String']>;
  fieldLabel?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clientContactCrmData" */
export type ClientContactCrmData_Max_Order_By = {
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmType?: InputMaybe<Order_By>;
  fieldLabel?: InputMaybe<Order_By>;
  fieldName?: InputMaybe<Order_By>;
  fieldValue?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ClientContactCrmData_Min_Fields = {
  __typename?: 'clientContactCrmData_min_fields';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmType?: Maybe<Scalars['String']>;
  fieldLabel?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clientContactCrmData" */
export type ClientContactCrmData_Min_Order_By = {
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmType?: InputMaybe<Order_By>;
  fieldLabel?: InputMaybe<Order_By>;
  fieldName?: InputMaybe<Order_By>;
  fieldValue?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clientContactCrmData" */
export type ClientContactCrmData_Mutation_Response = {
  __typename?: 'clientContactCrmData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientContactCrmData>;
};

/** on_conflict condition type for table "clientContactCrmData" */
export type ClientContactCrmData_On_Conflict = {
  constraint: ClientContactCrmData_Constraint;
  update_columns?: Array<ClientContactCrmData_Update_Column>;
  where?: InputMaybe<ClientContactCrmData_Bool_Exp>;
};

/** Ordering options when selecting data from "clientContactCrmData". */
export type ClientContactCrmData_Order_By = {
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmType?: InputMaybe<Order_By>;
  fieldLabel?: InputMaybe<Order_By>;
  fieldName?: InputMaybe<Order_By>;
  fieldValue?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clientContactCrmData */
export type ClientContactCrmData_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientContactCrmData" */
export enum ClientContactCrmData_Select_Column {
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmType = 'crmType',
  /** column name */
  FieldLabel = 'fieldLabel',
  /** column name */
  FieldName = 'fieldName',
  /** column name */
  FieldValue = 'fieldValue',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "clientContactCrmData" */
export type ClientContactCrmData_Set_Input = {
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmType?: InputMaybe<Scalars['String']>;
  fieldLabel?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  fieldValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "clientContactCrmData" */
export type ClientContactCrmData_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientContactCrmData_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientContactCrmData_Stream_Cursor_Value_Input = {
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmType?: InputMaybe<Scalars['String']>;
  fieldLabel?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  fieldValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "clientContactCrmData" */
export enum ClientContactCrmData_Update_Column {
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmType = 'crmType',
  /** column name */
  FieldLabel = 'fieldLabel',
  /** column name */
  FieldName = 'fieldName',
  /** column name */
  FieldValue = 'fieldValue',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ClientContactCrmData_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientContactCrmData_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientContactCrmData_Bool_Exp;
};

/** columns and relationships of "clientContactFilter" */
export type ClientContactFilter = {
  __typename?: 'clientContactFilter';
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  fieldCrmType?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldOperator: Scalars['String'];
  fieldTable: Scalars['String'];
  fieldValue: Scalars['String'];
  id: Scalars['uuid'];
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredAt?: Maybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "clientContactFilter" */
export type ClientContactFilter_Aggregate = {
  __typename?: 'clientContactFilter_aggregate';
  aggregate?: Maybe<ClientContactFilter_Aggregate_Fields>;
  nodes: Array<ClientContactFilter>;
};

/** aggregate fields of "clientContactFilter" */
export type ClientContactFilter_Aggregate_Fields = {
  __typename?: 'clientContactFilter_aggregate_fields';
  avg?: Maybe<ClientContactFilter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ClientContactFilter_Max_Fields>;
  min?: Maybe<ClientContactFilter_Min_Fields>;
  stddev?: Maybe<ClientContactFilter_Stddev_Fields>;
  stddev_pop?: Maybe<ClientContactFilter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClientContactFilter_Stddev_Samp_Fields>;
  sum?: Maybe<ClientContactFilter_Sum_Fields>;
  var_pop?: Maybe<ClientContactFilter_Var_Pop_Fields>;
  var_samp?: Maybe<ClientContactFilter_Var_Samp_Fields>;
  variance?: Maybe<ClientContactFilter_Variance_Fields>;
};

/** aggregate fields of "clientContactFilter" */
export type ClientContactFilter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientContactFilter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ClientContactFilter_Avg_Fields = {
  __typename?: 'clientContactFilter_avg_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "clientContactFilter". All fields are combined with a logical 'AND'. */
export type ClientContactFilter_Bool_Exp = {
  _and?: InputMaybe<Array<ClientContactFilter_Bool_Exp>>;
  _not?: InputMaybe<ClientContactFilter_Bool_Exp>;
  _or?: InputMaybe<Array<ClientContactFilter_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldCrmType?: InputMaybe<String_Comparison_Exp>;
  fieldName?: InputMaybe<String_Comparison_Exp>;
  fieldOperator?: InputMaybe<String_Comparison_Exp>;
  fieldTable?: InputMaybe<String_Comparison_Exp>;
  fieldValue?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastFilteredClientContactCount?: InputMaybe<Int_Comparison_Exp>;
  lastMonitoredAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  lastMonitoredClientContactLimit?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientContactFilter" */
export enum ClientContactFilter_Constraint {
  /** unique or primary key constraint on columns "clientId", "fieldName", "fieldTable", "fieldValue", "fieldCrmType", "fieldOperator" */
  ClientContactFilterClientIdFieldTableFieldNameFieldOperKey = 'clientContactFilter_clientId_fieldTable_fieldName_fieldOper_key',
  /** unique or primary key constraint on columns "id" */
  ClientContactFilterPkey = 'clientContactFilter_pkey',
}

/** input type for incrementing numeric columns in table "clientContactFilter" */
export type ClientContactFilter_Inc_Input = {
  lastFilteredClientContactCount?: InputMaybe<Scalars['Int']>;
  lastMonitoredClientContactLimit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "clientContactFilter" */
export type ClientContactFilter_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fieldCrmType?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  fieldOperator?: InputMaybe<Scalars['String']>;
  fieldTable?: InputMaybe<Scalars['String']>;
  fieldValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: InputMaybe<Scalars['Int']>;
  lastMonitoredAt?: InputMaybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ClientContactFilter_Max_Fields = {
  __typename?: 'clientContactFilter_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fieldCrmType?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldOperator?: Maybe<Scalars['String']>;
  fieldTable?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredAt?: Maybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ClientContactFilter_Min_Fields = {
  __typename?: 'clientContactFilter_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fieldCrmType?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldOperator?: Maybe<Scalars['String']>;
  fieldTable?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredAt?: Maybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "clientContactFilter" */
export type ClientContactFilter_Mutation_Response = {
  __typename?: 'clientContactFilter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientContactFilter>;
};

/** on_conflict condition type for table "clientContactFilter" */
export type ClientContactFilter_On_Conflict = {
  constraint: ClientContactFilter_Constraint;
  update_columns?: Array<ClientContactFilter_Update_Column>;
  where?: InputMaybe<ClientContactFilter_Bool_Exp>;
};

/** Ordering options when selecting data from "clientContactFilter". */
export type ClientContactFilter_Order_By = {
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  fieldCrmType?: InputMaybe<Order_By>;
  fieldName?: InputMaybe<Order_By>;
  fieldOperator?: InputMaybe<Order_By>;
  fieldTable?: InputMaybe<Order_By>;
  fieldValue?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastFilteredClientContactCount?: InputMaybe<Order_By>;
  lastMonitoredAt?: InputMaybe<Order_By>;
  lastMonitoredClientContactLimit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clientContactFilter */
export type ClientContactFilter_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientContactFilter" */
export enum ClientContactFilter_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FieldCrmType = 'fieldCrmType',
  /** column name */
  FieldName = 'fieldName',
  /** column name */
  FieldOperator = 'fieldOperator',
  /** column name */
  FieldTable = 'fieldTable',
  /** column name */
  FieldValue = 'fieldValue',
  /** column name */
  Id = 'id',
  /** column name */
  LastFilteredClientContactCount = 'lastFilteredClientContactCount',
  /** column name */
  LastMonitoredAt = 'lastMonitoredAt',
  /** column name */
  LastMonitoredClientContactLimit = 'lastMonitoredClientContactLimit',
}

/** input type for updating data in table "clientContactFilter" */
export type ClientContactFilter_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fieldCrmType?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  fieldOperator?: InputMaybe<Scalars['String']>;
  fieldTable?: InputMaybe<Scalars['String']>;
  fieldValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: InputMaybe<Scalars['Int']>;
  lastMonitoredAt?: InputMaybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ClientContactFilter_Stddev_Fields = {
  __typename?: 'clientContactFilter_stddev_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClientContactFilter_Stddev_Pop_Fields = {
  __typename?: 'clientContactFilter_stddev_pop_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClientContactFilter_Stddev_Samp_Fields = {
  __typename?: 'clientContactFilter_stddev_samp_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "clientContactFilter" */
export type ClientContactFilter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientContactFilter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientContactFilter_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fieldCrmType?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  fieldOperator?: InputMaybe<Scalars['String']>;
  fieldTable?: InputMaybe<Scalars['String']>;
  fieldValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: InputMaybe<Scalars['Int']>;
  lastMonitoredAt?: InputMaybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ClientContactFilter_Sum_Fields = {
  __typename?: 'clientContactFilter_sum_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** update columns of table "clientContactFilter" */
export enum ClientContactFilter_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FieldCrmType = 'fieldCrmType',
  /** column name */
  FieldName = 'fieldName',
  /** column name */
  FieldOperator = 'fieldOperator',
  /** column name */
  FieldTable = 'fieldTable',
  /** column name */
  FieldValue = 'fieldValue',
  /** column name */
  Id = 'id',
  /** column name */
  LastFilteredClientContactCount = 'lastFilteredClientContactCount',
  /** column name */
  LastMonitoredAt = 'lastMonitoredAt',
  /** column name */
  LastMonitoredClientContactLimit = 'lastMonitoredClientContactLimit',
}

export type ClientContactFilter_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClientContactFilter_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientContactFilter_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientContactFilter_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ClientContactFilter_Var_Pop_Fields = {
  __typename?: 'clientContactFilter_var_pop_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClientContactFilter_Var_Samp_Fields = {
  __typename?: 'clientContactFilter_var_samp_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClientContactFilter_Variance_Fields = {
  __typename?: 'clientContactFilter_variance_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "clientContactJobChange" */
export type ClientContactJobChange = {
  __typename?: 'clientContactJobChange';
  clientAction?: Maybe<Scalars['String']>;
  clientActionUpdatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  clientContact?: Maybe<ClientContact>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  job: Job;
  jobId: Scalars['uuid'];
  /** An object relationship */
  ownerUser?: Maybe<User>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** aggregated selection of "clientContactJobChange" */
export type ClientContactJobChange_Aggregate = {
  __typename?: 'clientContactJobChange_aggregate';
  aggregate?: Maybe<ClientContactJobChange_Aggregate_Fields>;
  nodes: Array<ClientContactJobChange>;
};

export type ClientContactJobChange_Aggregate_Bool_Exp = {
  count?: InputMaybe<ClientContactJobChange_Aggregate_Bool_Exp_Count>;
};

export type ClientContactJobChange_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ClientContactJobChange_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClientContactJobChange_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clientContactJobChange" */
export type ClientContactJobChange_Aggregate_Fields = {
  __typename?: 'clientContactJobChange_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ClientContactJobChange_Max_Fields>;
  min?: Maybe<ClientContactJobChange_Min_Fields>;
};

/** aggregate fields of "clientContactJobChange" */
export type ClientContactJobChange_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientContactJobChange_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientContactJobChange" */
export type ClientContactJobChange_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ClientContactJobChange_Max_Order_By>;
  min?: InputMaybe<ClientContactJobChange_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clientContactJobChange" */
export type ClientContactJobChange_Arr_Rel_Insert_Input = {
  data: Array<ClientContactJobChange_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClientContactJobChange_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clientContactJobChange". All fields are combined with a logical 'AND'. */
export type ClientContactJobChange_Bool_Exp = {
  _and?: InputMaybe<Array<ClientContactJobChange_Bool_Exp>>;
  _not?: InputMaybe<ClientContactJobChange_Bool_Exp>;
  _or?: InputMaybe<Array<ClientContactJobChange_Bool_Exp>>;
  clientAction?: InputMaybe<String_Comparison_Exp>;
  clientActionUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  clientContact?: InputMaybe<ClientContact_Bool_Exp>;
  clientContactId?: InputMaybe<Uuid_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Job_Bool_Exp>;
  jobId?: InputMaybe<Uuid_Comparison_Exp>;
  ownerUser?: InputMaybe<User_Bool_Exp>;
  ownerUserId?: InputMaybe<Uuid_Comparison_Exp>;
  salesforceAccountId?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientContactJobChange" */
export enum ClientContactJobChange_Constraint {
  /** unique or primary key constraint on columns "clientContactId", "jobId" */
  ClientContactJobChangeClientContactIdJobIdKey = 'clientContactJobChange_clientContactId_jobId_key',
  /** unique or primary key constraint on columns "id" */
  ClientContactJobChangePkey = 'clientContactJobChange_pkey',
}

/** input type for inserting data into table "clientContactJobChange" */
export type ClientContactJobChange_Insert_Input = {
  clientAction?: InputMaybe<Scalars['String']>;
  clientActionUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  clientContact?: InputMaybe<ClientContact_Obj_Rel_Insert_Input>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  job?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  jobId?: InputMaybe<Scalars['uuid']>;
  ownerUser?: InputMaybe<User_Obj_Rel_Insert_Input>;
  ownerUserId?: InputMaybe<Scalars['uuid']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClientContactJobChange_Max_Fields = {
  __typename?: 'clientContactJobChange_max_fields';
  clientAction?: Maybe<Scalars['String']>;
  clientActionUpdatedAt?: Maybe<Scalars['timestamptz']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['uuid']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "clientContactJobChange" */
export type ClientContactJobChange_Max_Order_By = {
  clientAction?: InputMaybe<Order_By>;
  clientActionUpdatedAt?: InputMaybe<Order_By>;
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  ownerUserId?: InputMaybe<Order_By>;
  salesforceAccountId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ClientContactJobChange_Min_Fields = {
  __typename?: 'clientContactJobChange_min_fields';
  clientAction?: Maybe<Scalars['String']>;
  clientActionUpdatedAt?: Maybe<Scalars['timestamptz']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['uuid']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "clientContactJobChange" */
export type ClientContactJobChange_Min_Order_By = {
  clientAction?: InputMaybe<Order_By>;
  clientActionUpdatedAt?: InputMaybe<Order_By>;
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobId?: InputMaybe<Order_By>;
  ownerUserId?: InputMaybe<Order_By>;
  salesforceAccountId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clientContactJobChange" */
export type ClientContactJobChange_Mutation_Response = {
  __typename?: 'clientContactJobChange_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientContactJobChange>;
};

/** input type for inserting object relation for remote table "clientContactJobChange" */
export type ClientContactJobChange_Obj_Rel_Insert_Input = {
  data: ClientContactJobChange_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ClientContactJobChange_On_Conflict>;
};

/** on_conflict condition type for table "clientContactJobChange" */
export type ClientContactJobChange_On_Conflict = {
  constraint: ClientContactJobChange_Constraint;
  update_columns?: Array<ClientContactJobChange_Update_Column>;
  where?: InputMaybe<ClientContactJobChange_Bool_Exp>;
};

/** Ordering options when selecting data from "clientContactJobChange". */
export type ClientContactJobChange_Order_By = {
  clientAction?: InputMaybe<Order_By>;
  clientActionUpdatedAt?: InputMaybe<Order_By>;
  clientContact?: InputMaybe<ClientContact_Order_By>;
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Job_Order_By>;
  jobId?: InputMaybe<Order_By>;
  ownerUser?: InputMaybe<User_Order_By>;
  ownerUserId?: InputMaybe<Order_By>;
  salesforceAccountId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clientContactJobChange */
export type ClientContactJobChange_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientContactJobChange" */
export enum ClientContactJobChange_Select_Column {
  /** column name */
  ClientAction = 'clientAction',
  /** column name */
  ClientActionUpdatedAt = 'clientActionUpdatedAt',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobId',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "clientContactJobChange" */
export type ClientContactJobChange_Set_Input = {
  clientAction?: InputMaybe<Scalars['String']>;
  clientActionUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  ownerUserId?: InputMaybe<Scalars['uuid']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "clientContactJobChange" */
export type ClientContactJobChange_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientContactJobChange_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientContactJobChange_Stream_Cursor_Value_Input = {
  clientAction?: InputMaybe<Scalars['String']>;
  clientActionUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobId?: InputMaybe<Scalars['uuid']>;
  ownerUserId?: InputMaybe<Scalars['uuid']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "clientContactJobChange" */
export enum ClientContactJobChange_Update_Column {
  /** column name */
  ClientAction = 'clientAction',
  /** column name */
  ClientActionUpdatedAt = 'clientActionUpdatedAt',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobId',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  Status = 'status',
}

export type ClientContactJobChange_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientContactJobChange_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientContactJobChange_Bool_Exp;
};

/** aggregated selection of "clientContact" */
export type ClientContact_Aggregate = {
  __typename?: 'clientContact_aggregate';
  aggregate?: Maybe<ClientContact_Aggregate_Fields>;
  nodes: Array<ClientContact>;
};

export type ClientContact_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ClientContact_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ClientContact_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ClientContact_Aggregate_Bool_Exp_Count>;
};

export type ClientContact_Aggregate_Bool_Exp_Bool_And = {
  arguments: ClientContact_Select_Column_ClientContact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClientContact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ClientContact_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ClientContact_Select_Column_ClientContact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClientContact_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ClientContact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ClientContact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClientContact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clientContact" */
export type ClientContact_Aggregate_Fields = {
  __typename?: 'clientContact_aggregate_fields';
  avg?: Maybe<ClientContact_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ClientContact_Max_Fields>;
  min?: Maybe<ClientContact_Min_Fields>;
  stddev?: Maybe<ClientContact_Stddev_Fields>;
  stddev_pop?: Maybe<ClientContact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClientContact_Stddev_Samp_Fields>;
  sum?: Maybe<ClientContact_Sum_Fields>;
  var_pop?: Maybe<ClientContact_Var_Pop_Fields>;
  var_samp?: Maybe<ClientContact_Var_Samp_Fields>;
  variance?: Maybe<ClientContact_Variance_Fields>;
};

/** aggregate fields of "clientContact" */
export type ClientContact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientContact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientContact" */
export type ClientContact_Aggregate_Order_By = {
  avg?: InputMaybe<ClientContact_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ClientContact_Max_Order_By>;
  min?: InputMaybe<ClientContact_Min_Order_By>;
  stddev?: InputMaybe<ClientContact_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ClientContact_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ClientContact_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ClientContact_Sum_Order_By>;
  var_pop?: InputMaybe<ClientContact_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ClientContact_Var_Samp_Order_By>;
  variance?: InputMaybe<ClientContact_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "clientContact" */
export type ClientContact_Arr_Rel_Insert_Input = {
  data: Array<ClientContact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClientContact_On_Conflict>;
};

/** aggregate avg on columns */
export type ClientContact_Avg_Fields = {
  __typename?: 'clientContact_avg_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clientContact" */
export type ClientContact_Avg_Order_By = {
  serialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clientContact". All fields are combined with a logical 'AND'. */
export type ClientContact_Bool_Exp = {
  _and?: InputMaybe<Array<ClientContact_Bool_Exp>>;
  _not?: InputMaybe<ClientContact_Bool_Exp>;
  _or?: InputMaybe<Array<ClientContact_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  contactData?: InputMaybe<ContactData_Bool_Exp>;
  contactDataId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  crmCompanyName?: InputMaybe<String_Comparison_Exp>;
  crmData?: InputMaybe<ClientContactCrmData_Bool_Exp>;
  crmData_aggregate?: InputMaybe<ClientContactCrmData_Aggregate_Bool_Exp>;
  crmEmail?: InputMaybe<String_Comparison_Exp>;
  crmEmailIsValid?: InputMaybe<Boolean_Comparison_Exp>;
  crmEmailValidatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  crmLinkedinUrl?: InputMaybe<String_Comparison_Exp>;
  crmTitle?: InputMaybe<String_Comparison_Exp>;
  csvUploadId?: InputMaybe<Uuid_Comparison_Exp>;
  currentJobChange?: InputMaybe<ClientContactJobChange_Bool_Exp>;
  currentJobChangeId?: InputMaybe<Uuid_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  enrichmentHistories?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
  enrichmentHistories_aggregate?: InputMaybe<CrmContactEnrichmentHistory_Aggregate_Bool_Exp>;
  firestoreId?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  hubspotId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  jobChanges?: InputMaybe<ClientContactJobChange_Bool_Exp>;
  jobChanges_aggregate?: InputMaybe<ClientContactJobChange_Aggregate_Bool_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  linkedinId?: InputMaybe<String_Comparison_Exp>;
  linkedinUrl?: InputMaybe<String_Comparison_Exp>;
  ownerUser?: InputMaybe<User_Bool_Exp>;
  ownerUserId?: InputMaybe<Uuid_Comparison_Exp>;
  qaAssignedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  salesforceAccountId?: InputMaybe<String_Comparison_Exp>;
  salesforceId?: InputMaybe<String_Comparison_Exp>;
  salesforceOwnerId?: InputMaybe<String_Comparison_Exp>;
  serialId?: InputMaybe<Int_Comparison_Exp>;
  setMonitoredAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  setMonitoredFilterId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientContact" */
export enum ClientContact_Constraint {
  /** unique or primary key constraint on columns "clientId", "hubspotId" */
  ClientContactClientIdHubspotIdKey = 'clientContact_clientId_hubspotId_key',
  /** unique or primary key constraint on columns "clientId", "salesforceId" */
  ClientContactClientIdSalesforceIdKey = 'clientContact_clientId_salesforceId_key',
  /** unique or primary key constraint on columns "firestoreId" */
  ClientContactFirestoreIdKey = 'clientContact_firestoreId_key',
  /** unique or primary key constraint on columns "id" */
  ClientContactPkey = 'clientContact_pkey',
}

/** input type for incrementing numeric columns in table "clientContact" */
export type ClientContact_Inc_Input = {
  serialId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "clientContact" */
export type ClientContact_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactData?: InputMaybe<ContactData_Obj_Rel_Insert_Input>;
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmCompanyName?: InputMaybe<Scalars['String']>;
  crmData?: InputMaybe<ClientContactCrmData_Arr_Rel_Insert_Input>;
  crmEmail?: InputMaybe<Scalars['String']>;
  crmEmailIsValid?: InputMaybe<Scalars['Boolean']>;
  crmEmailValidatedAt?: InputMaybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: InputMaybe<Scalars['String']>;
  crmTitle?: InputMaybe<Scalars['String']>;
  csvUploadId?: InputMaybe<Scalars['uuid']>;
  currentJobChange?: InputMaybe<ClientContactJobChange_Obj_Rel_Insert_Input>;
  currentJobChangeId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  enrichmentHistories?: InputMaybe<CrmContactEnrichmentHistory_Arr_Rel_Insert_Input>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobChanges?: InputMaybe<ClientContactJobChange_Arr_Rel_Insert_Input>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  ownerUser?: InputMaybe<User_Obj_Rel_Insert_Input>;
  ownerUserId?: InputMaybe<Scalars['uuid']>;
  qaAssignedAt?: InputMaybe<Scalars['timestamptz']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceOwnerId?: InputMaybe<Scalars['String']>;
  serialId?: InputMaybe<Scalars['Int']>;
  setMonitoredAt?: InputMaybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClientContact_Max_Fields = {
  __typename?: 'clientContact_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmCompanyName?: Maybe<Scalars['String']>;
  crmEmail?: Maybe<Scalars['String']>;
  crmEmailValidatedAt?: Maybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: Maybe<Scalars['String']>;
  crmTitle?: Maybe<Scalars['String']>;
  csvUploadId?: Maybe<Scalars['uuid']>;
  currentJobChangeId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  qaAssignedAt?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceOwnerId?: Maybe<Scalars['String']>;
  serialId?: Maybe<Scalars['Int']>;
  setMonitoredAt?: Maybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clientContact" */
export type ClientContact_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmCompanyName?: InputMaybe<Order_By>;
  crmEmail?: InputMaybe<Order_By>;
  crmEmailValidatedAt?: InputMaybe<Order_By>;
  crmLinkedinUrl?: InputMaybe<Order_By>;
  crmTitle?: InputMaybe<Order_By>;
  csvUploadId?: InputMaybe<Order_By>;
  currentJobChangeId?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  hubspotId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  linkedinId?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  ownerUserId?: InputMaybe<Order_By>;
  qaAssignedAt?: InputMaybe<Order_By>;
  salesforceAccountId?: InputMaybe<Order_By>;
  salesforceId?: InputMaybe<Order_By>;
  salesforceOwnerId?: InputMaybe<Order_By>;
  serialId?: InputMaybe<Order_By>;
  setMonitoredAt?: InputMaybe<Order_By>;
  setMonitoredFilterId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ClientContact_Min_Fields = {
  __typename?: 'clientContact_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmCompanyName?: Maybe<Scalars['String']>;
  crmEmail?: Maybe<Scalars['String']>;
  crmEmailValidatedAt?: Maybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: Maybe<Scalars['String']>;
  crmTitle?: Maybe<Scalars['String']>;
  csvUploadId?: Maybe<Scalars['uuid']>;
  currentJobChangeId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  qaAssignedAt?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceOwnerId?: Maybe<Scalars['String']>;
  serialId?: Maybe<Scalars['Int']>;
  setMonitoredAt?: Maybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clientContact" */
export type ClientContact_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmCompanyName?: InputMaybe<Order_By>;
  crmEmail?: InputMaybe<Order_By>;
  crmEmailValidatedAt?: InputMaybe<Order_By>;
  crmLinkedinUrl?: InputMaybe<Order_By>;
  crmTitle?: InputMaybe<Order_By>;
  csvUploadId?: InputMaybe<Order_By>;
  currentJobChangeId?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  hubspotId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  linkedinId?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  ownerUserId?: InputMaybe<Order_By>;
  qaAssignedAt?: InputMaybe<Order_By>;
  salesforceAccountId?: InputMaybe<Order_By>;
  salesforceId?: InputMaybe<Order_By>;
  salesforceOwnerId?: InputMaybe<Order_By>;
  serialId?: InputMaybe<Order_By>;
  setMonitoredAt?: InputMaybe<Order_By>;
  setMonitoredFilterId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clientContact" */
export type ClientContact_Mutation_Response = {
  __typename?: 'clientContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientContact>;
};

/** input type for inserting object relation for remote table "clientContact" */
export type ClientContact_Obj_Rel_Insert_Input = {
  data: ClientContact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ClientContact_On_Conflict>;
};

/** on_conflict condition type for table "clientContact" */
export type ClientContact_On_Conflict = {
  constraint: ClientContact_Constraint;
  update_columns?: Array<ClientContact_Update_Column>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

/** Ordering options when selecting data from "clientContact". */
export type ClientContact_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactData?: InputMaybe<ContactData_Order_By>;
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmCompanyName?: InputMaybe<Order_By>;
  crmData_aggregate?: InputMaybe<ClientContactCrmData_Aggregate_Order_By>;
  crmEmail?: InputMaybe<Order_By>;
  crmEmailIsValid?: InputMaybe<Order_By>;
  crmEmailValidatedAt?: InputMaybe<Order_By>;
  crmLinkedinUrl?: InputMaybe<Order_By>;
  crmTitle?: InputMaybe<Order_By>;
  csvUploadId?: InputMaybe<Order_By>;
  currentJobChange?: InputMaybe<ClientContactJobChange_Order_By>;
  currentJobChangeId?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  enrichmentHistories_aggregate?: InputMaybe<CrmContactEnrichmentHistory_Aggregate_Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  hubspotId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobChanges_aggregate?: InputMaybe<ClientContactJobChange_Aggregate_Order_By>;
  lastName?: InputMaybe<Order_By>;
  linkedinId?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  ownerUser?: InputMaybe<User_Order_By>;
  ownerUserId?: InputMaybe<Order_By>;
  qaAssignedAt?: InputMaybe<Order_By>;
  salesforceAccountId?: InputMaybe<Order_By>;
  salesforceId?: InputMaybe<Order_By>;
  salesforceOwnerId?: InputMaybe<Order_By>;
  serialId?: InputMaybe<Order_By>;
  setMonitoredAt?: InputMaybe<Order_By>;
  setMonitoredFilterId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clientContact */
export type ClientContact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientContact" */
export enum ClientContact_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmCompanyName = 'crmCompanyName',
  /** column name */
  CrmEmail = 'crmEmail',
  /** column name */
  CrmEmailIsValid = 'crmEmailIsValid',
  /** column name */
  CrmEmailValidatedAt = 'crmEmailValidatedAt',
  /** column name */
  CrmLinkedinUrl = 'crmLinkedinUrl',
  /** column name */
  CrmTitle = 'crmTitle',
  /** column name */
  CsvUploadId = 'csvUploadId',
  /** column name */
  CurrentJobChangeId = 'currentJobChangeId',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  QaAssignedAt = 'qaAssignedAt',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceOwnerId = 'salesforceOwnerId',
  /** column name */
  SerialId = 'serialId',
  /** column name */
  SetMonitoredAt = 'setMonitoredAt',
  /** column name */
  SetMonitoredFilterId = 'setMonitoredFilterId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "clientContact_aggregate_bool_exp_bool_and_arguments_columns" columns of table "clientContact" */
export enum ClientContact_Select_Column_ClientContact_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CrmEmailIsValid = 'crmEmailIsValid',
}

/** select "clientContact_aggregate_bool_exp_bool_or_arguments_columns" columns of table "clientContact" */
export enum ClientContact_Select_Column_ClientContact_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CrmEmailIsValid = 'crmEmailIsValid',
}

/** input type for updating data in table "clientContact" */
export type ClientContact_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmCompanyName?: InputMaybe<Scalars['String']>;
  crmEmail?: InputMaybe<Scalars['String']>;
  crmEmailIsValid?: InputMaybe<Scalars['Boolean']>;
  crmEmailValidatedAt?: InputMaybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: InputMaybe<Scalars['String']>;
  crmTitle?: InputMaybe<Scalars['String']>;
  csvUploadId?: InputMaybe<Scalars['uuid']>;
  currentJobChangeId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  ownerUserId?: InputMaybe<Scalars['uuid']>;
  qaAssignedAt?: InputMaybe<Scalars['timestamptz']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceOwnerId?: InputMaybe<Scalars['String']>;
  serialId?: InputMaybe<Scalars['Int']>;
  setMonitoredAt?: InputMaybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ClientContact_Stddev_Fields = {
  __typename?: 'clientContact_stddev_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clientContact" */
export type ClientContact_Stddev_Order_By = {
  serialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ClientContact_Stddev_Pop_Fields = {
  __typename?: 'clientContact_stddev_pop_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clientContact" */
export type ClientContact_Stddev_Pop_Order_By = {
  serialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ClientContact_Stddev_Samp_Fields = {
  __typename?: 'clientContact_stddev_samp_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clientContact" */
export type ClientContact_Stddev_Samp_Order_By = {
  serialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "clientContact" */
export type ClientContact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientContact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientContact_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmCompanyName?: InputMaybe<Scalars['String']>;
  crmEmail?: InputMaybe<Scalars['String']>;
  crmEmailIsValid?: InputMaybe<Scalars['Boolean']>;
  crmEmailValidatedAt?: InputMaybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: InputMaybe<Scalars['String']>;
  crmTitle?: InputMaybe<Scalars['String']>;
  csvUploadId?: InputMaybe<Scalars['uuid']>;
  currentJobChangeId?: InputMaybe<Scalars['uuid']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  ownerUserId?: InputMaybe<Scalars['uuid']>;
  qaAssignedAt?: InputMaybe<Scalars['timestamptz']>;
  salesforceAccountId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceOwnerId?: InputMaybe<Scalars['String']>;
  serialId?: InputMaybe<Scalars['Int']>;
  setMonitoredAt?: InputMaybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ClientContact_Sum_Fields = {
  __typename?: 'clientContact_sum_fields';
  serialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "clientContact" */
export type ClientContact_Sum_Order_By = {
  serialId?: InputMaybe<Order_By>;
};

/** update columns of table "clientContact" */
export enum ClientContact_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmCompanyName = 'crmCompanyName',
  /** column name */
  CrmEmail = 'crmEmail',
  /** column name */
  CrmEmailIsValid = 'crmEmailIsValid',
  /** column name */
  CrmEmailValidatedAt = 'crmEmailValidatedAt',
  /** column name */
  CrmLinkedinUrl = 'crmLinkedinUrl',
  /** column name */
  CrmTitle = 'crmTitle',
  /** column name */
  CsvUploadId = 'csvUploadId',
  /** column name */
  CurrentJobChangeId = 'currentJobChangeId',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  QaAssignedAt = 'qaAssignedAt',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceOwnerId = 'salesforceOwnerId',
  /** column name */
  SerialId = 'serialId',
  /** column name */
  SetMonitoredAt = 'setMonitoredAt',
  /** column name */
  SetMonitoredFilterId = 'setMonitoredFilterId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ClientContact_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClientContact_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientContact_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientContact_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ClientContact_Var_Pop_Fields = {
  __typename?: 'clientContact_var_pop_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clientContact" */
export type ClientContact_Var_Pop_Order_By = {
  serialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ClientContact_Var_Samp_Fields = {
  __typename?: 'clientContact_var_samp_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clientContact" */
export type ClientContact_Var_Samp_Order_By = {
  serialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ClientContact_Variance_Fields = {
  __typename?: 'clientContact_variance_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clientContact" */
export type ClientContact_Variance_Order_By = {
  serialId?: InputMaybe<Order_By>;
};

/** columns and relationships of "clientEmailReport" */
export type ClientEmailReport = {
  __typename?: 'clientEmailReport';
  clientId: Scalars['uuid'];
  emailCategory: Scalars['String'];
  emailHistoryId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  json?: Maybe<Scalars['jsonb']>;
  sentAt: Scalars['timestamptz'];
};

/** columns and relationships of "clientEmailReport" */
export type ClientEmailReportJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "clientEmailReport" */
export type ClientEmailReport_Aggregate = {
  __typename?: 'clientEmailReport_aggregate';
  aggregate?: Maybe<ClientEmailReport_Aggregate_Fields>;
  nodes: Array<ClientEmailReport>;
};

export type ClientEmailReport_Aggregate_Bool_Exp = {
  count?: InputMaybe<ClientEmailReport_Aggregate_Bool_Exp_Count>;
};

export type ClientEmailReport_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ClientEmailReport_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClientEmailReport_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clientEmailReport" */
export type ClientEmailReport_Aggregate_Fields = {
  __typename?: 'clientEmailReport_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ClientEmailReport_Max_Fields>;
  min?: Maybe<ClientEmailReport_Min_Fields>;
};

/** aggregate fields of "clientEmailReport" */
export type ClientEmailReport_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientEmailReport_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientEmailReport" */
export type ClientEmailReport_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ClientEmailReport_Max_Order_By>;
  min?: InputMaybe<ClientEmailReport_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClientEmailReport_Append_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "clientEmailReport" */
export type ClientEmailReport_Arr_Rel_Insert_Input = {
  data: Array<ClientEmailReport_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClientEmailReport_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clientEmailReport". All fields are combined with a logical 'AND'. */
export type ClientEmailReport_Bool_Exp = {
  _and?: InputMaybe<Array<ClientEmailReport_Bool_Exp>>;
  _not?: InputMaybe<ClientEmailReport_Bool_Exp>;
  _or?: InputMaybe<Array<ClientEmailReport_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  emailCategory?: InputMaybe<String_Comparison_Exp>;
  emailHistoryId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  json?: InputMaybe<Jsonb_Comparison_Exp>;
  sentAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientEmailReport" */
export enum ClientEmailReport_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientEmailReportPkey = 'clientEmailReport_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClientEmailReport_Delete_At_Path_Input = {
  json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClientEmailReport_Delete_Elem_Input = {
  json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClientEmailReport_Delete_Key_Input = {
  json?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "clientEmailReport" */
export type ClientEmailReport_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  emailCategory?: InputMaybe<Scalars['String']>;
  emailHistoryId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  sentAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClientEmailReport_Max_Fields = {
  __typename?: 'clientEmailReport_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  emailCategory?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sentAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clientEmailReport" */
export type ClientEmailReport_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  emailCategory?: InputMaybe<Order_By>;
  emailHistoryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sentAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ClientEmailReport_Min_Fields = {
  __typename?: 'clientEmailReport_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  emailCategory?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sentAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clientEmailReport" */
export type ClientEmailReport_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  emailCategory?: InputMaybe<Order_By>;
  emailHistoryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sentAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clientEmailReport" */
export type ClientEmailReport_Mutation_Response = {
  __typename?: 'clientEmailReport_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientEmailReport>;
};

/** on_conflict condition type for table "clientEmailReport" */
export type ClientEmailReport_On_Conflict = {
  constraint: ClientEmailReport_Constraint;
  update_columns?: Array<ClientEmailReport_Update_Column>;
  where?: InputMaybe<ClientEmailReport_Bool_Exp>;
};

/** Ordering options when selecting data from "clientEmailReport". */
export type ClientEmailReport_Order_By = {
  clientId?: InputMaybe<Order_By>;
  emailCategory?: InputMaybe<Order_By>;
  emailHistoryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  json?: InputMaybe<Order_By>;
  sentAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clientEmailReport */
export type ClientEmailReport_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClientEmailReport_Prepend_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "clientEmailReport" */
export enum ClientEmailReport_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  EmailCategory = 'emailCategory',
  /** column name */
  EmailHistoryId = 'emailHistoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  SentAt = 'sentAt',
}

/** input type for updating data in table "clientEmailReport" */
export type ClientEmailReport_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  emailCategory?: InputMaybe<Scalars['String']>;
  emailHistoryId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  sentAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "clientEmailReport" */
export type ClientEmailReport_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientEmailReport_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientEmailReport_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  emailCategory?: InputMaybe<Scalars['String']>;
  emailHistoryId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  sentAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "clientEmailReport" */
export enum ClientEmailReport_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  EmailCategory = 'emailCategory',
  /** column name */
  EmailHistoryId = 'emailHistoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  SentAt = 'sentAt',
}

export type ClientEmailReport_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ClientEmailReport_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ClientEmailReport_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ClientEmailReport_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ClientEmailReport_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ClientEmailReport_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientEmailReport_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientEmailReport_Bool_Exp;
};

/** columns and relationships of "clientNotificationEmail" */
export type ClientNotificationEmail = {
  __typename?: 'clientNotificationEmail';
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
};

/** aggregated selection of "clientNotificationEmail" */
export type ClientNotificationEmail_Aggregate = {
  __typename?: 'clientNotificationEmail_aggregate';
  aggregate?: Maybe<ClientNotificationEmail_Aggregate_Fields>;
  nodes: Array<ClientNotificationEmail>;
};

export type ClientNotificationEmail_Aggregate_Bool_Exp = {
  count?: InputMaybe<ClientNotificationEmail_Aggregate_Bool_Exp_Count>;
};

export type ClientNotificationEmail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ClientNotificationEmail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clientNotificationEmail" */
export type ClientNotificationEmail_Aggregate_Fields = {
  __typename?: 'clientNotificationEmail_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ClientNotificationEmail_Max_Fields>;
  min?: Maybe<ClientNotificationEmail_Min_Fields>;
};

/** aggregate fields of "clientNotificationEmail" */
export type ClientNotificationEmail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientNotificationEmail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientNotificationEmail" */
export type ClientNotificationEmail_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ClientNotificationEmail_Max_Order_By>;
  min?: InputMaybe<ClientNotificationEmail_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clientNotificationEmail" */
export type ClientNotificationEmail_Arr_Rel_Insert_Input = {
  data: Array<ClientNotificationEmail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClientNotificationEmail_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clientNotificationEmail". All fields are combined with a logical 'AND'. */
export type ClientNotificationEmail_Bool_Exp = {
  _and?: InputMaybe<Array<ClientNotificationEmail_Bool_Exp>>;
  _not?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
  _or?: InputMaybe<Array<ClientNotificationEmail_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientNotificationEmail" */
export enum ClientNotificationEmail_Constraint {
  /** unique or primary key constraint on columns "clientId", "email" */
  ClientNotificationEmailClientIdEmailKey = 'clientNotificationEmail_clientId_email_key',
  /** unique or primary key constraint on columns "id" */
  ClientNotificationEmailPkey = 'clientNotificationEmail_pkey',
}

/** input type for inserting data into table "clientNotificationEmail" */
export type ClientNotificationEmail_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClientNotificationEmail_Max_Fields = {
  __typename?: 'clientNotificationEmail_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "clientNotificationEmail" */
export type ClientNotificationEmail_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ClientNotificationEmail_Min_Fields = {
  __typename?: 'clientNotificationEmail_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "clientNotificationEmail" */
export type ClientNotificationEmail_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clientNotificationEmail" */
export type ClientNotificationEmail_Mutation_Response = {
  __typename?: 'clientNotificationEmail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientNotificationEmail>;
};

/** on_conflict condition type for table "clientNotificationEmail" */
export type ClientNotificationEmail_On_Conflict = {
  constraint: ClientNotificationEmail_Constraint;
  update_columns?: Array<ClientNotificationEmail_Update_Column>;
  where?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
};

/** Ordering options when selecting data from "clientNotificationEmail". */
export type ClientNotificationEmail_Order_By = {
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clientNotificationEmail */
export type ClientNotificationEmail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientNotificationEmail" */
export enum ClientNotificationEmail_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "clientNotificationEmail" */
export type ClientNotificationEmail_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "clientNotificationEmail" */
export type ClientNotificationEmail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientNotificationEmail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientNotificationEmail_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "clientNotificationEmail" */
export enum ClientNotificationEmail_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

export type ClientNotificationEmail_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientNotificationEmail_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientNotificationEmail_Bool_Exp;
};

/** columns and relationships of "clientTerritory" */
export type ClientTerritory = {
  __typename?: 'clientTerritory';
  clientId: Scalars['uuid'];
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  territory: Territory;
  territoryId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "clientTerritory" */
export type ClientTerritory_Aggregate = {
  __typename?: 'clientTerritory_aggregate';
  aggregate?: Maybe<ClientTerritory_Aggregate_Fields>;
  nodes: Array<ClientTerritory>;
};

/** aggregate fields of "clientTerritory" */
export type ClientTerritory_Aggregate_Fields = {
  __typename?: 'clientTerritory_aggregate_fields';
  avg?: Maybe<ClientTerritory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ClientTerritory_Max_Fields>;
  min?: Maybe<ClientTerritory_Min_Fields>;
  stddev?: Maybe<ClientTerritory_Stddev_Fields>;
  stddev_pop?: Maybe<ClientTerritory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClientTerritory_Stddev_Samp_Fields>;
  sum?: Maybe<ClientTerritory_Sum_Fields>;
  var_pop?: Maybe<ClientTerritory_Var_Pop_Fields>;
  var_samp?: Maybe<ClientTerritory_Var_Samp_Fields>;
  variance?: Maybe<ClientTerritory_Variance_Fields>;
};

/** aggregate fields of "clientTerritory" */
export type ClientTerritory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientTerritory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ClientTerritory_Avg_Fields = {
  __typename?: 'clientTerritory_avg_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "clientTerritory". All fields are combined with a logical 'AND'. */
export type ClientTerritory_Bool_Exp = {
  _and?: InputMaybe<Array<ClientTerritory_Bool_Exp>>;
  _not?: InputMaybe<ClientTerritory_Bool_Exp>;
  _or?: InputMaybe<Array<ClientTerritory_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  companySizeMax?: InputMaybe<Int_Comparison_Exp>;
  companySizeMin?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  territory?: InputMaybe<Territory_Bool_Exp>;
  territoryId?: InputMaybe<Uuid_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientTerritory" */
export enum ClientTerritory_Constraint {
  /** unique or primary key constraint on columns "clientId", "userId", "territoryId" */
  ClientTerritoryClientIdUserIdTerritoryIdKey = 'clientTerritory_clientId_userId_territoryId_key',
  /** unique or primary key constraint on columns "id" */
  ClientTerritoryPkey = 'clientTerritory_pkey',
}

/** input type for incrementing numeric columns in table "clientTerritory" */
export type ClientTerritory_Inc_Input = {
  companySizeMax?: InputMaybe<Scalars['Int']>;
  companySizeMin?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "clientTerritory" */
export type ClientTerritory_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  companySizeMax?: InputMaybe<Scalars['Int']>;
  companySizeMin?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  territory?: InputMaybe<Territory_Obj_Rel_Insert_Input>;
  territoryId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClientTerritory_Max_Fields = {
  __typename?: 'clientTerritory_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  territoryId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ClientTerritory_Min_Fields = {
  __typename?: 'clientTerritory_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  territoryId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "clientTerritory" */
export type ClientTerritory_Mutation_Response = {
  __typename?: 'clientTerritory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientTerritory>;
};

/** on_conflict condition type for table "clientTerritory" */
export type ClientTerritory_On_Conflict = {
  constraint: ClientTerritory_Constraint;
  update_columns?: Array<ClientTerritory_Update_Column>;
  where?: InputMaybe<ClientTerritory_Bool_Exp>;
};

/** Ordering options when selecting data from "clientTerritory". */
export type ClientTerritory_Order_By = {
  clientId?: InputMaybe<Order_By>;
  companySizeMax?: InputMaybe<Order_By>;
  companySizeMin?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  territory?: InputMaybe<Territory_Order_By>;
  territoryId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clientTerritory */
export type ClientTerritory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientTerritory" */
export enum ClientTerritory_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CompanySizeMax = 'companySizeMax',
  /** column name */
  CompanySizeMin = 'companySizeMin',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TerritoryId = 'territoryId',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "clientTerritory" */
export type ClientTerritory_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  companySizeMax?: InputMaybe<Scalars['Int']>;
  companySizeMin?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  territoryId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ClientTerritory_Stddev_Fields = {
  __typename?: 'clientTerritory_stddev_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ClientTerritory_Stddev_Pop_Fields = {
  __typename?: 'clientTerritory_stddev_pop_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ClientTerritory_Stddev_Samp_Fields = {
  __typename?: 'clientTerritory_stddev_samp_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "clientTerritory" */
export type ClientTerritory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientTerritory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientTerritory_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  companySizeMax?: InputMaybe<Scalars['Int']>;
  companySizeMin?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  territoryId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ClientTerritory_Sum_Fields = {
  __typename?: 'clientTerritory_sum_fields';
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
};

/** update columns of table "clientTerritory" */
export enum ClientTerritory_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CompanySizeMax = 'companySizeMax',
  /** column name */
  CompanySizeMin = 'companySizeMin',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TerritoryId = 'territoryId',
  /** column name */
  UserId = 'userId',
}

export type ClientTerritory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClientTerritory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientTerritory_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientTerritory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ClientTerritory_Var_Pop_Fields = {
  __typename?: 'clientTerritory_var_pop_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ClientTerritory_Var_Samp_Fields = {
  __typename?: 'clientTerritory_var_samp_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ClientTerritory_Variance_Fields = {
  __typename?: 'clientTerritory_variance_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "client" */
export type Client_Aggregate = {
  __typename?: 'client_aggregate';
  aggregate?: Maybe<Client_Aggregate_Fields>;
  nodes: Array<Client>;
};

/** aggregate fields of "client" */
export type Client_Aggregate_Fields = {
  __typename?: 'client_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Client_Max_Fields>;
  min?: Maybe<Client_Min_Fields>;
};

/** aggregate fields of "client" */
export type Client_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'. */
export type Client_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Bool_Exp>>;
  _not?: InputMaybe<Client_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Bool_Exp>>;
  clientContacts?: InputMaybe<ClientContact_Bool_Exp>;
  clientContacts_aggregate?: InputMaybe<ClientContact_Aggregate_Bool_Exp>;
  clientEmailReports?: InputMaybe<ClientEmailReport_Bool_Exp>;
  clientEmailReports_aggregate?: InputMaybe<ClientEmailReport_Aggregate_Bool_Exp>;
  clientNotificationEmails?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
  clientNotificationEmails_aggregate?: InputMaybe<ClientNotificationEmail_Aggregate_Bool_Exp>;
  codeName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  csvUploads?: InputMaybe<CsvUpload_Bool_Exp>;
  csvUploads_aggregate?: InputMaybe<CsvUpload_Aggregate_Bool_Exp>;
  firestoreId?: InputMaybe<String_Comparison_Exp>;
  hubspotIntegration?: InputMaybe<HubspotIntegration_Bool_Exp>;
  hubspotIntegrationId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integrationType?: InputMaybe<String_Comparison_Exp>;
  lastCsvUpload?: InputMaybe<CsvUpload_Bool_Exp>;
  lastCsvUploadId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ownerMappingStatus?: InputMaybe<String_Comparison_Exp>;
  qaStatus?: InputMaybe<String_Comparison_Exp>;
  qaStatusUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  salesforceIntegration?: InputMaybe<SalesforceIntegration_Bool_Exp>;
  salesforceIntegrationId?: InputMaybe<Uuid_Comparison_Exp>;
  subscriptionStatus?: InputMaybe<String_Comparison_Exp>;
  syncStatus?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userInvites?: InputMaybe<UserInvite_Bool_Exp>;
  userInvites_aggregate?: InputMaybe<UserInvite_Aggregate_Bool_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "client" */
export enum Client_Constraint {
  /** unique or primary key constraint on columns "codeName" */
  ClientCodeNameKey = 'client_codeName_key',
  /** unique or primary key constraint on columns "firestoreId" */
  ClientFirestoreIdKey = 'client_firestoreId_key',
  /** unique or primary key constraint on columns "id" */
  ClientPkey = 'client_pkey',
}

/** input type for inserting data into table "client" */
export type Client_Insert_Input = {
  clientContacts?: InputMaybe<ClientContact_Arr_Rel_Insert_Input>;
  clientEmailReports?: InputMaybe<ClientEmailReport_Arr_Rel_Insert_Input>;
  clientNotificationEmails?: InputMaybe<ClientNotificationEmail_Arr_Rel_Insert_Input>;
  codeName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  csvUploads?: InputMaybe<CsvUpload_Arr_Rel_Insert_Input>;
  firestoreId?: InputMaybe<Scalars['String']>;
  hubspotIntegration?: InputMaybe<HubspotIntegration_Obj_Rel_Insert_Input>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Scalars['String']>;
  lastCsvUpload?: InputMaybe<CsvUpload_Obj_Rel_Insert_Input>;
  lastCsvUploadId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerMappingStatus?: InputMaybe<Scalars['String']>;
  qaStatus?: InputMaybe<Scalars['String']>;
  qaStatusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  salesforceIntegration?: InputMaybe<SalesforceIntegration_Obj_Rel_Insert_Input>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  subscriptionStatus?: InputMaybe<Scalars['String']>;
  syncStatus?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userInvites?: InputMaybe<UserInvite_Arr_Rel_Insert_Input>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Client_Max_Fields = {
  __typename?: 'client_max_fields';
  codeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastCsvUploadId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerMappingStatus?: Maybe<Scalars['String']>;
  qaStatus?: Maybe<Scalars['String']>;
  qaStatusUpdatedAt?: Maybe<Scalars['timestamptz']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Client_Min_Fields = {
  __typename?: 'client_min_fields';
  codeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastCsvUploadId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerMappingStatus?: Maybe<Scalars['String']>;
  qaStatus?: Maybe<Scalars['String']>;
  qaStatusUpdatedAt?: Maybe<Scalars['timestamptz']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "client" */
export type Client_Mutation_Response = {
  __typename?: 'client_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client>;
};

/** input type for inserting object relation for remote table "client" */
export type Client_Obj_Rel_Insert_Input = {
  data: Client_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** on_conflict condition type for table "client" */
export type Client_On_Conflict = {
  constraint: Client_Constraint;
  update_columns?: Array<Client_Update_Column>;
  where?: InputMaybe<Client_Bool_Exp>;
};

/** Ordering options when selecting data from "client". */
export type Client_Order_By = {
  clientContacts_aggregate?: InputMaybe<ClientContact_Aggregate_Order_By>;
  clientEmailReports_aggregate?: InputMaybe<ClientEmailReport_Aggregate_Order_By>;
  clientNotificationEmails_aggregate?: InputMaybe<ClientNotificationEmail_Aggregate_Order_By>;
  codeName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  csvUploads_aggregate?: InputMaybe<CsvUpload_Aggregate_Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  hubspotIntegration?: InputMaybe<HubspotIntegration_Order_By>;
  hubspotIntegrationId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integrationType?: InputMaybe<Order_By>;
  lastCsvUpload?: InputMaybe<CsvUpload_Order_By>;
  lastCsvUploadId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ownerMappingStatus?: InputMaybe<Order_By>;
  qaStatus?: InputMaybe<Order_By>;
  qaStatusUpdatedAt?: InputMaybe<Order_By>;
  salesforceIntegration?: InputMaybe<SalesforceIntegration_Order_By>;
  salesforceIntegrationId?: InputMaybe<Order_By>;
  subscriptionStatus?: InputMaybe<Order_By>;
  syncStatus?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userInvites_aggregate?: InputMaybe<UserInvite_Aggregate_Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: client */
export type Client_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "client" */
export enum Client_Select_Column {
  /** column name */
  CodeName = 'codeName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  LastCsvUploadId = 'lastCsvUploadId',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerMappingStatus = 'ownerMappingStatus',
  /** column name */
  QaStatus = 'qaStatus',
  /** column name */
  QaStatusUpdatedAt = 'qaStatusUpdatedAt',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  SubscriptionStatus = 'subscriptionStatus',
  /** column name */
  SyncStatus = 'syncStatus',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "client" */
export type Client_Set_Input = {
  codeName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Scalars['String']>;
  lastCsvUploadId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerMappingStatus?: InputMaybe<Scalars['String']>;
  qaStatus?: InputMaybe<Scalars['String']>;
  qaStatusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  subscriptionStatus?: InputMaybe<Scalars['String']>;
  syncStatus?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "client" */
export type Client_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Stream_Cursor_Value_Input = {
  codeName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Scalars['String']>;
  lastCsvUploadId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerMappingStatus?: InputMaybe<Scalars['String']>;
  qaStatus?: InputMaybe<Scalars['String']>;
  qaStatusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  subscriptionStatus?: InputMaybe<Scalars['String']>;
  syncStatus?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "client" */
export enum Client_Update_Column {
  /** column name */
  CodeName = 'codeName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  LastCsvUploadId = 'lastCsvUploadId',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerMappingStatus = 'ownerMappingStatus',
  /** column name */
  QaStatus = 'qaStatus',
  /** column name */
  QaStatusUpdatedAt = 'qaStatusUpdatedAt',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  SubscriptionStatus = 'subscriptionStatus',
  /** column name */
  SyncStatus = 'syncStatus',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Client_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Bool_Exp;
};

/** columns and relationships of "cloudTask" */
export type CloudTask = {
  __typename?: 'cloudTask';
  createdAt: Scalars['timestamptz'];
  createdByUserId?: Maybe<Scalars['uuid']>;
  createdByZoomUserId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  queueName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  taskData?: Maybe<Scalars['jsonb']>;
  taskIdentifier: Scalars['String'];
  taskName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "cloudTask" */
export type CloudTaskTaskDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "cloudTask" */
export type CloudTask_Aggregate = {
  __typename?: 'cloudTask_aggregate';
  aggregate?: Maybe<CloudTask_Aggregate_Fields>;
  nodes: Array<CloudTask>;
};

/** aggregate fields of "cloudTask" */
export type CloudTask_Aggregate_Fields = {
  __typename?: 'cloudTask_aggregate_fields';
  avg?: Maybe<CloudTask_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CloudTask_Max_Fields>;
  min?: Maybe<CloudTask_Min_Fields>;
  stddev?: Maybe<CloudTask_Stddev_Fields>;
  stddev_pop?: Maybe<CloudTask_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CloudTask_Stddev_Samp_Fields>;
  sum?: Maybe<CloudTask_Sum_Fields>;
  var_pop?: Maybe<CloudTask_Var_Pop_Fields>;
  var_samp?: Maybe<CloudTask_Var_Samp_Fields>;
  variance?: Maybe<CloudTask_Variance_Fields>;
};

/** aggregate fields of "cloudTask" */
export type CloudTask_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CloudTask_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CloudTask_Append_Input = {
  taskData?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type CloudTask_Avg_Fields = {
  __typename?: 'cloudTask_avg_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "cloudTask". All fields are combined with a logical 'AND'. */
export type CloudTask_Bool_Exp = {
  _and?: InputMaybe<Array<CloudTask_Bool_Exp>>;
  _not?: InputMaybe<CloudTask_Bool_Exp>;
  _or?: InputMaybe<Array<CloudTask_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  createdByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  executionTime?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  queueName?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  taskData?: InputMaybe<Jsonb_Comparison_Exp>;
  taskIdentifier?: InputMaybe<String_Comparison_Exp>;
  taskName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cloudTask" */
export enum CloudTask_Constraint {
  /** unique or primary key constraint on columns "id" */
  CloudTaskPkey = 'cloudTask_pkey',
  /** unique or primary key constraint on columns "taskIdentifier" */
  CloudTaskTaskIdentifierKey = 'cloudTask_taskIdentifier_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CloudTask_Delete_At_Path_Input = {
  taskData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CloudTask_Delete_Elem_Input = {
  taskData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CloudTask_Delete_Key_Input = {
  taskData?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "cloudTask" */
export type CloudTask_Inc_Input = {
  executionTime?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "cloudTask" */
export type CloudTask_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  createdByZoomUserId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  executionTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  queueName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  taskData?: InputMaybe<Scalars['jsonb']>;
  taskIdentifier?: InputMaybe<Scalars['String']>;
  taskName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CloudTask_Max_Fields = {
  __typename?: 'cloudTask_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  createdByZoomUserId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskIdentifier?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CloudTask_Min_Fields = {
  __typename?: 'cloudTask_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  createdByZoomUserId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskIdentifier?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "cloudTask" */
export type CloudTask_Mutation_Response = {
  __typename?: 'cloudTask_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CloudTask>;
};

/** on_conflict condition type for table "cloudTask" */
export type CloudTask_On_Conflict = {
  constraint: CloudTask_Constraint;
  update_columns?: Array<CloudTask_Update_Column>;
  where?: InputMaybe<CloudTask_Bool_Exp>;
};

/** Ordering options when selecting data from "cloudTask". */
export type CloudTask_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  createdByZoomUserId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  executionTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  queueName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  taskData?: InputMaybe<Order_By>;
  taskIdentifier?: InputMaybe<Order_By>;
  taskName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cloudTask */
export type CloudTask_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CloudTask_Prepend_Input = {
  taskData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "cloudTask" */
export enum CloudTask_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByUserId = 'createdByUserId',
  /** column name */
  CreatedByZoomUserId = 'createdByZoomUserId',
  /** column name */
  Description = 'description',
  /** column name */
  ExecutionTime = 'executionTime',
  /** column name */
  Id = 'id',
  /** column name */
  QueueName = 'queueName',
  /** column name */
  Status = 'status',
  /** column name */
  TaskData = 'taskData',
  /** column name */
  TaskIdentifier = 'taskIdentifier',
  /** column name */
  TaskName = 'taskName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "cloudTask" */
export type CloudTask_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  createdByZoomUserId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  executionTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  queueName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  taskData?: InputMaybe<Scalars['jsonb']>;
  taskIdentifier?: InputMaybe<Scalars['String']>;
  taskName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CloudTask_Stddev_Fields = {
  __typename?: 'cloudTask_stddev_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CloudTask_Stddev_Pop_Fields = {
  __typename?: 'cloudTask_stddev_pop_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CloudTask_Stddev_Samp_Fields = {
  __typename?: 'cloudTask_stddev_samp_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "cloudTask" */
export type CloudTask_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CloudTask_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CloudTask_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  createdByZoomUserId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  executionTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  queueName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  taskData?: InputMaybe<Scalars['jsonb']>;
  taskIdentifier?: InputMaybe<Scalars['String']>;
  taskName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CloudTask_Sum_Fields = {
  __typename?: 'cloudTask_sum_fields';
  executionTime?: Maybe<Scalars['Int']>;
};

/** update columns of table "cloudTask" */
export enum CloudTask_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByUserId = 'createdByUserId',
  /** column name */
  CreatedByZoomUserId = 'createdByZoomUserId',
  /** column name */
  Description = 'description',
  /** column name */
  ExecutionTime = 'executionTime',
  /** column name */
  Id = 'id',
  /** column name */
  QueueName = 'queueName',
  /** column name */
  Status = 'status',
  /** column name */
  TaskData = 'taskData',
  /** column name */
  TaskIdentifier = 'taskIdentifier',
  /** column name */
  TaskName = 'taskName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CloudTask_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CloudTask_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CloudTask_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CloudTask_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CloudTask_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CloudTask_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CloudTask_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CloudTask_Set_Input>;
  /** filter the rows which have to be updated */
  where: CloudTask_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CloudTask_Var_Pop_Fields = {
  __typename?: 'cloudTask_var_pop_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CloudTask_Var_Samp_Fields = {
  __typename?: 'cloudTask_var_samp_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CloudTask_Variance_Fields = {
  __typename?: 'cloudTask_variance_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  /** An array relationship */
  companyNames: Array<CompanyName>;
  /** An aggregate relationship */
  companyNames_aggregate: CompanyName_Aggregate;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  hqLocation?: Maybe<Scalars['String']>;
  /** An object relationship */
  hqTerritory?: Maybe<Territory>;
  hqTerritoryId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  industry?: Maybe<Scalars['String']>;
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  primaryName?: Maybe<CompanyName>;
  primaryNameId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** columns and relationships of "company" */
export type CompanyCompanyNamesArgs = {
  distinct_on?: InputMaybe<Array<CompanyName_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyName_Order_By>>;
  where?: InputMaybe<CompanyName_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyCompanyNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyName_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyName_Order_By>>;
  where?: InputMaybe<CompanyName_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** columns and relationships of "companyName" */
export type CompanyName = {
  __typename?: 'companyName';
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "companyName" */
export type CompanyName_Aggregate = {
  __typename?: 'companyName_aggregate';
  aggregate?: Maybe<CompanyName_Aggregate_Fields>;
  nodes: Array<CompanyName>;
};

export type CompanyName_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompanyName_Aggregate_Bool_Exp_Count>;
};

export type CompanyName_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompanyName_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompanyName_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "companyName" */
export type CompanyName_Aggregate_Fields = {
  __typename?: 'companyName_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompanyName_Max_Fields>;
  min?: Maybe<CompanyName_Min_Fields>;
};

/** aggregate fields of "companyName" */
export type CompanyName_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompanyName_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "companyName" */
export type CompanyName_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompanyName_Max_Order_By>;
  min?: InputMaybe<CompanyName_Min_Order_By>;
};

/** input type for inserting array relation for remote table "companyName" */
export type CompanyName_Arr_Rel_Insert_Input = {
  data: Array<CompanyName_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompanyName_On_Conflict>;
};

/** Boolean expression to filter rows from the table "companyName". All fields are combined with a logical 'AND'. */
export type CompanyName_Bool_Exp = {
  _and?: InputMaybe<Array<CompanyName_Bool_Exp>>;
  _not?: InputMaybe<CompanyName_Bool_Exp>;
  _or?: InputMaybe<Array<CompanyName_Bool_Exp>>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "companyName" */
export enum CompanyName_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyNamePkey = 'companyName_pkey',
}

/** input type for inserting data into table "companyName" */
export type CompanyName_Insert_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CompanyName_Max_Fields = {
  __typename?: 'companyName_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "companyName" */
export type CompanyName_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompanyName_Min_Fields = {
  __typename?: 'companyName_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "companyName" */
export type CompanyName_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "companyName" */
export type CompanyName_Mutation_Response = {
  __typename?: 'companyName_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompanyName>;
};

/** input type for inserting object relation for remote table "companyName" */
export type CompanyName_Obj_Rel_Insert_Input = {
  data: CompanyName_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CompanyName_On_Conflict>;
};

/** on_conflict condition type for table "companyName" */
export type CompanyName_On_Conflict = {
  constraint: CompanyName_Constraint;
  update_columns?: Array<CompanyName_Update_Column>;
  where?: InputMaybe<CompanyName_Bool_Exp>;
};

/** Ordering options when selecting data from "companyName". */
export type CompanyName_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: companyName */
export type CompanyName_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "companyName" */
export enum CompanyName_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "companyName" */
export type CompanyName_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "companyName" */
export type CompanyName_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompanyName_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompanyName_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "companyName" */
export enum CompanyName_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type CompanyName_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompanyName_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompanyName_Bool_Exp;
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
  __typename?: 'company_avg_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  companyNames?: InputMaybe<CompanyName_Bool_Exp>;
  companyNames_aggregate?: InputMaybe<CompanyName_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  employeeCount?: InputMaybe<Int_Comparison_Exp>;
  estimatedSize?: InputMaybe<String_Comparison_Exp>;
  firestoreId?: InputMaybe<String_Comparison_Exp>;
  hqLocation?: InputMaybe<String_Comparison_Exp>;
  hqTerritory?: InputMaybe<Territory_Bool_Exp>;
  hqTerritoryId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  linkedinId?: InputMaybe<String_Comparison_Exp>;
  linkedinUrl?: InputMaybe<String_Comparison_Exp>;
  logoUrl?: InputMaybe<String_Comparison_Exp>;
  primaryName?: InputMaybe<CompanyName_Bool_Exp>;
  primaryNameId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  websiteDomain?: InputMaybe<String_Comparison_Exp>;
  websiteRootDomain?: InputMaybe<String_Comparison_Exp>;
  websiteUrl?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint on columns "firestoreId" */
  CompanyFirestoreIdKey = 'company_firestoreId_key',
  /** unique or primary key constraint on columns "linkedinId" */
  CompanyLinkedinIdKey = 'company_linkedinId_key',
  /** unique or primary key constraint on columns "id" */
  CompanyPkey = 'company_pkey',
}

/** input type for incrementing numeric columns in table "company" */
export type Company_Inc_Input = {
  employeeCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  companyNames?: InputMaybe<CompanyName_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  employeeCount?: InputMaybe<Scalars['Int']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  hqLocation?: InputMaybe<Scalars['String']>;
  hqTerritory?: InputMaybe<Territory_Obj_Rel_Insert_Input>;
  hqTerritoryId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Scalars['String']>;
  jobs?: InputMaybe<Job_Arr_Rel_Insert_Input>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  primaryName?: InputMaybe<CompanyName_Obj_Rel_Insert_Input>;
  primaryNameId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteRootDomain?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  hqLocation?: Maybe<Scalars['String']>;
  hqTerritoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  primaryNameId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  hqLocation?: Maybe<Scalars['String']>;
  hqTerritoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  primaryNameId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** on_conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  companyNames_aggregate?: InputMaybe<CompanyName_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employeeCount?: InputMaybe<Order_By>;
  estimatedSize?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  hqLocation?: InputMaybe<Order_By>;
  hqTerritory?: InputMaybe<Territory_Order_By>;
  hqTerritoryId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  linkedinId?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  primaryName?: InputMaybe<CompanyName_Order_By>;
  primaryNameId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteDomain?: InputMaybe<Order_By>;
  websiteRootDomain?: InputMaybe<Order_By>;
  websiteUrl?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EmployeeCount = 'employeeCount',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  HqLocation = 'hqLocation',
  /** column name */
  HqTerritoryId = 'hqTerritoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  PrimaryNameId = 'primaryNameId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteRootDomain = 'websiteRootDomain',
  /** column name */
  WebsiteUrl = 'websiteUrl',
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  employeeCount?: InputMaybe<Scalars['Int']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  hqLocation?: InputMaybe<Scalars['String']>;
  hqTerritoryId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Scalars['String']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  primaryNameId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteRootDomain?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
  __typename?: 'company_stddev_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
  __typename?: 'company_stddev_pop_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
  __typename?: 'company_stddev_samp_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company" */
export type Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  employeeCount?: InputMaybe<Scalars['Int']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  hqLocation?: InputMaybe<Scalars['String']>;
  hqTerritoryId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Scalars['String']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  primaryNameId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteRootDomain?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
  __typename?: 'company_sum_fields';
  employeeCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EmployeeCount = 'employeeCount',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  HqLocation = 'hqLocation',
  /** column name */
  HqTerritoryId = 'hqTerritoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  PrimaryNameId = 'primaryNameId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteRootDomain = 'websiteRootDomain',
  /** column name */
  WebsiteUrl = 'websiteUrl',
}

export type Company_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
  __typename?: 'company_var_pop_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
  __typename?: 'company_var_samp_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
  __typename?: 'company_variance_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "contactData" */
export type ContactData = {
  __typename?: 'contactData';
  /** An array relationship */
  clientContacts: Array<ClientContact>;
  /** An aggregate relationship */
  clientContacts_aggregate: ClientContact_Aggregate;
  /** An array relationship */
  contactEmails: Array<ContactEmail>;
  /** An aggregate relationship */
  contactEmails_aggregate: ContactEmail_Aggregate;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  currentJob?: Maybe<Job>;
  currentJobId?: Maybe<Scalars['uuid']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregate relationship */
  jobs_aggregate: Job_Aggregate;
  lastName?: Maybe<Scalars['String']>;
  /** An object relationship */
  lastProxycrawlData?: Maybe<ProxycrawlData>;
  lastProxycrawlDataId?: Maybe<Scalars['uuid']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "contactData" */
export type ContactDataClientContactsArgs = {
  distinct_on?: InputMaybe<Array<ClientContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContact_Order_By>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataClientContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContact_Order_By>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataContactEmailsArgs = {
  distinct_on?: InputMaybe<Array<ContactEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactEmail_Order_By>>;
  where?: InputMaybe<ContactEmail_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataContactEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactEmail_Order_By>>;
  where?: InputMaybe<ContactEmail_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataJobsArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** aggregated selection of "contactData" */
export type ContactData_Aggregate = {
  __typename?: 'contactData_aggregate';
  aggregate?: Maybe<ContactData_Aggregate_Fields>;
  nodes: Array<ContactData>;
};

export type ContactData_Aggregate_Bool_Exp = {
  count?: InputMaybe<ContactData_Aggregate_Bool_Exp_Count>;
};

export type ContactData_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ContactData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContactData_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contactData" */
export type ContactData_Aggregate_Fields = {
  __typename?: 'contactData_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContactData_Max_Fields>;
  min?: Maybe<ContactData_Min_Fields>;
};

/** aggregate fields of "contactData" */
export type ContactData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContactData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contactData" */
export type ContactData_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ContactData_Max_Order_By>;
  min?: InputMaybe<ContactData_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contactData" */
export type ContactData_Arr_Rel_Insert_Input = {
  data: Array<ContactData_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContactData_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contactData". All fields are combined with a logical 'AND'. */
export type ContactData_Bool_Exp = {
  _and?: InputMaybe<Array<ContactData_Bool_Exp>>;
  _not?: InputMaybe<ContactData_Bool_Exp>;
  _or?: InputMaybe<Array<ContactData_Bool_Exp>>;
  clientContacts?: InputMaybe<ClientContact_Bool_Exp>;
  clientContacts_aggregate?: InputMaybe<ClientContact_Aggregate_Bool_Exp>;
  contactEmails?: InputMaybe<ContactEmail_Bool_Exp>;
  contactEmails_aggregate?: InputMaybe<ContactEmail_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currentJob?: InputMaybe<Job_Bool_Exp>;
  currentJobId?: InputMaybe<Uuid_Comparison_Exp>;
  firestoreId?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  jobs?: InputMaybe<Job_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Bool_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  lastProxycrawlData?: InputMaybe<ProxycrawlData_Bool_Exp>;
  lastProxycrawlDataId?: InputMaybe<Uuid_Comparison_Exp>;
  linkedinId?: InputMaybe<String_Comparison_Exp>;
  linkedinUrl?: InputMaybe<String_Comparison_Exp>;
  profilePhotoUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contactData" */
export enum ContactData_Constraint {
  /** unique or primary key constraint on columns "firestoreId" */
  ContactDataFirestoreIdKey = 'contactData_firestoreId_key',
  /** unique or primary key constraint on columns "linkedinId" */
  ContactDataLinkedinIdKey = 'contactData_linkedinId_key',
  /** unique or primary key constraint on columns "id" */
  ContactDataPkey = 'contactData_pkey',
}

/** input type for inserting data into table "contactData" */
export type ContactData_Insert_Input = {
  clientContacts?: InputMaybe<ClientContact_Arr_Rel_Insert_Input>;
  contactEmails?: InputMaybe<ContactEmail_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currentJob?: InputMaybe<Job_Obj_Rel_Insert_Input>;
  currentJobId?: InputMaybe<Scalars['uuid']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobs?: InputMaybe<Job_Arr_Rel_Insert_Input>;
  lastName?: InputMaybe<Scalars['String']>;
  lastProxycrawlData?: InputMaybe<ProxycrawlData_Obj_Rel_Insert_Input>;
  lastProxycrawlDataId?: InputMaybe<Scalars['uuid']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  profilePhotoUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContactData_Max_Fields = {
  __typename?: 'contactData_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentJobId?: Maybe<Scalars['uuid']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastProxycrawlDataId?: Maybe<Scalars['uuid']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contactData" */
export type ContactData_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  currentJobId?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastProxycrawlDataId?: InputMaybe<Order_By>;
  linkedinId?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  profilePhotoUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ContactData_Min_Fields = {
  __typename?: 'contactData_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentJobId?: Maybe<Scalars['uuid']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastProxycrawlDataId?: Maybe<Scalars['uuid']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contactData" */
export type ContactData_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  currentJobId?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastProxycrawlDataId?: InputMaybe<Order_By>;
  linkedinId?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  profilePhotoUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contactData" */
export type ContactData_Mutation_Response = {
  __typename?: 'contactData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContactData>;
};

/** input type for inserting object relation for remote table "contactData" */
export type ContactData_Obj_Rel_Insert_Input = {
  data: ContactData_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ContactData_On_Conflict>;
};

/** on_conflict condition type for table "contactData" */
export type ContactData_On_Conflict = {
  constraint: ContactData_Constraint;
  update_columns?: Array<ContactData_Update_Column>;
  where?: InputMaybe<ContactData_Bool_Exp>;
};

/** Ordering options when selecting data from "contactData". */
export type ContactData_Order_By = {
  clientContacts_aggregate?: InputMaybe<ClientContact_Aggregate_Order_By>;
  contactEmails_aggregate?: InputMaybe<ContactEmail_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentJob?: InputMaybe<Job_Order_By>;
  currentJobId?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Job_Aggregate_Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastProxycrawlData?: InputMaybe<ProxycrawlData_Order_By>;
  lastProxycrawlDataId?: InputMaybe<Order_By>;
  linkedinId?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  profilePhotoUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contactData */
export type ContactData_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contactData" */
export enum ContactData_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentJobId = 'currentJobId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastProxycrawlDataId = 'lastProxycrawlDataId',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  ProfilePhotoUrl = 'profilePhotoUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "contactData" */
export type ContactData_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currentJobId?: InputMaybe<Scalars['uuid']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastProxycrawlDataId?: InputMaybe<Scalars['uuid']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  profilePhotoUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contactData" */
export type ContactData_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContactData_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactData_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currentJobId?: InputMaybe<Scalars['uuid']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastProxycrawlDataId?: InputMaybe<Scalars['uuid']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  profilePhotoUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "contactData" */
export enum ContactData_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentJobId = 'currentJobId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastProxycrawlDataId = 'lastProxycrawlDataId',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  ProfilePhotoUrl = 'profilePhotoUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ContactData_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContactData_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContactData_Bool_Exp;
};

/** columns and relationships of "contactEmail" */
export type ContactEmail = {
  __typename?: 'contactEmail';
  contactDataId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  isValid?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "contactEmail" */
export type ContactEmail_Aggregate = {
  __typename?: 'contactEmail_aggregate';
  aggregate?: Maybe<ContactEmail_Aggregate_Fields>;
  nodes: Array<ContactEmail>;
};

export type ContactEmail_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ContactEmail_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ContactEmail_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ContactEmail_Aggregate_Bool_Exp_Count>;
};

export type ContactEmail_Aggregate_Bool_Exp_Bool_And = {
  arguments: ContactEmail_Select_Column_ContactEmail_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContactEmail_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ContactEmail_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ContactEmail_Select_Column_ContactEmail_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContactEmail_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ContactEmail_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ContactEmail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContactEmail_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contactEmail" */
export type ContactEmail_Aggregate_Fields = {
  __typename?: 'contactEmail_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContactEmail_Max_Fields>;
  min?: Maybe<ContactEmail_Min_Fields>;
};

/** aggregate fields of "contactEmail" */
export type ContactEmail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContactEmail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contactEmail" */
export type ContactEmail_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ContactEmail_Max_Order_By>;
  min?: InputMaybe<ContactEmail_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contactEmail" */
export type ContactEmail_Arr_Rel_Insert_Input = {
  data: Array<ContactEmail_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContactEmail_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contactEmail". All fields are combined with a logical 'AND'. */
export type ContactEmail_Bool_Exp = {
  _and?: InputMaybe<Array<ContactEmail_Bool_Exp>>;
  _not?: InputMaybe<ContactEmail_Bool_Exp>;
  _or?: InputMaybe<Array<ContactEmail_Bool_Exp>>;
  contactDataId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isValid?: InputMaybe<Boolean_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  validatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contactEmail" */
export enum ContactEmail_Constraint {
  /** unique or primary key constraint on columns "contactDataId", "email" */
  ContactEmailEmailContactDataIdKey = 'contactEmail_email_contactDataId_key',
  /** unique or primary key constraint on columns "id" */
  ContactEmailPkey = 'contactEmail_pkey',
}

/** input type for inserting data into table "contactEmail" */
export type ContactEmail_Insert_Input = {
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isValid?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  validatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContactEmail_Max_Fields = {
  __typename?: 'contactEmail_max_fields';
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contactEmail" */
export type ContactEmail_Max_Order_By = {
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  validatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ContactEmail_Min_Fields = {
  __typename?: 'contactEmail_min_fields';
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contactEmail" */
export type ContactEmail_Min_Order_By = {
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  validatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contactEmail" */
export type ContactEmail_Mutation_Response = {
  __typename?: 'contactEmail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContactEmail>;
};

/** on_conflict condition type for table "contactEmail" */
export type ContactEmail_On_Conflict = {
  constraint: ContactEmail_Constraint;
  update_columns?: Array<ContactEmail_Update_Column>;
  where?: InputMaybe<ContactEmail_Bool_Exp>;
};

/** Ordering options when selecting data from "contactEmail". */
export type ContactEmail_Order_By = {
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isValid?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  validatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contactEmail */
export type ContactEmail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contactEmail" */
export enum ContactEmail_Select_Column {
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'isValid',
  /** column name */
  Source = 'source',
  /** column name */
  ValidatedAt = 'validatedAt',
}

/** select "contactEmail_aggregate_bool_exp_bool_and_arguments_columns" columns of table "contactEmail" */
export enum ContactEmail_Select_Column_ContactEmail_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsValid = 'isValid',
}

/** select "contactEmail_aggregate_bool_exp_bool_or_arguments_columns" columns of table "contactEmail" */
export enum ContactEmail_Select_Column_ContactEmail_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsValid = 'isValid',
}

/** input type for updating data in table "contactEmail" */
export type ContactEmail_Set_Input = {
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isValid?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  validatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "contactEmail" */
export type ContactEmail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContactEmail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactEmail_Stream_Cursor_Value_Input = {
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isValid?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  validatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "contactEmail" */
export enum ContactEmail_Update_Column {
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'isValid',
  /** column name */
  Source = 'source',
  /** column name */
  ValidatedAt = 'validatedAt',
}

export type ContactEmail_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContactEmail_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContactEmail_Bool_Exp;
};

/** columns and relationships of "content" */
export type Content = {
  __typename?: 'content';
  createdAt: Scalars['timestamptz'];
  createdByUserId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  identifier: Scalars['citext'];
  identifierId?: Maybe<Scalars['citext']>;
  name: Scalars['String'];
  tags: Scalars['jsonb'];
  thumbnail?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['citext']>;
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
  weight: Scalars['Int'];
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
};

/** columns and relationships of "content" */
export type ContentTagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "content" */
export type Content_Aggregate = {
  __typename?: 'content_aggregate';
  aggregate?: Maybe<Content_Aggregate_Fields>;
  nodes: Array<Content>;
};

/** aggregate fields of "content" */
export type Content_Aggregate_Fields = {
  __typename?: 'content_aggregate_fields';
  avg?: Maybe<Content_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Max_Fields>;
  min?: Maybe<Content_Min_Fields>;
  stddev?: Maybe<Content_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Sum_Fields>;
  var_pop?: Maybe<Content_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Var_Samp_Fields>;
  variance?: Maybe<Content_Variance_Fields>;
};

/** aggregate fields of "content" */
export type Content_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Content_Append_Input = {
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Content_Avg_Fields = {
  __typename?: 'content_avg_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "content". All fields are combined with a logical 'AND'. */
export type Content_Bool_Exp = {
  _and?: InputMaybe<Array<Content_Bool_Exp>>;
  _not?: InputMaybe<Content_Bool_Exp>;
  _or?: InputMaybe<Array<Content_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identifier?: InputMaybe<Citext_Comparison_Exp>;
  identifierId?: InputMaybe<Citext_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Citext_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  weight?: InputMaybe<Int_Comparison_Exp>;
  zoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
};

/** unique or primary key constraints on table "content" */
export enum Content_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentPkey = 'content_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Content_Delete_At_Path_Input = {
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Content_Delete_Elem_Input = {
  tags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Content_Delete_Key_Input = {
  tags?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "content" */
export type Content_Inc_Input = {
  weight?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "content" */
export type Content_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['citext']>;
  identifierId?: InputMaybe<Scalars['citext']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['citext']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
  zoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Content_Max_Fields = {
  __typename?: 'content_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['citext']>;
  identifierId?: Maybe<Scalars['citext']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['citext']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Content_Min_Fields = {
  __typename?: 'content_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['citext']>;
  identifierId?: Maybe<Scalars['citext']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['citext']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "content" */
export type Content_Mutation_Response = {
  __typename?: 'content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content>;
};

/** on_conflict condition type for table "content" */
export type Content_On_Conflict = {
  constraint: Content_Constraint;
  update_columns?: Array<Content_Update_Column>;
  where?: InputMaybe<Content_Bool_Exp>;
};

/** Ordering options when selecting data from "content". */
export type Content_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  identifierId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  weight?: InputMaybe<Order_By>;
  zoomUser?: InputMaybe<ZoomUser_Order_By>;
};

/** primary key columns input for table: content */
export type Content_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Content_Prepend_Input = {
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "content" */
export enum Content_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByUserId = 'createdByUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  IdentifierId = 'identifierId',
  /** column name */
  Name = 'name',
  /** column name */
  Tags = 'tags',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  Weight = 'weight',
}

/** input type for updating data in table "content" */
export type Content_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['citext']>;
  identifierId?: InputMaybe<Scalars['citext']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['citext']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Content_Stddev_Fields = {
  __typename?: 'content_stddev_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Content_Stddev_Pop_Fields = {
  __typename?: 'content_stddev_pop_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Content_Stddev_Samp_Fields = {
  __typename?: 'content_stddev_samp_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "content" */
export type Content_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Content_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Content_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['citext']>;
  identifierId?: InputMaybe<Scalars['citext']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['citext']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Content_Sum_Fields = {
  __typename?: 'content_sum_fields';
  weight?: Maybe<Scalars['Int']>;
};

/** update columns of table "content" */
export enum Content_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByUserId = 'createdByUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  IdentifierId = 'identifierId',
  /** column name */
  Name = 'name',
  /** column name */
  Tags = 'tags',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  Weight = 'weight',
}

export type Content_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Content_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Content_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Content_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Content_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Content_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Content_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Content_Set_Input>;
  /** filter the rows which have to be updated */
  where: Content_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Content_Var_Pop_Fields = {
  __typename?: 'content_var_pop_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Content_Var_Samp_Fields = {
  __typename?: 'content_var_samp_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Content_Variance_Fields = {
  __typename?: 'content_variance_fields';
  weight?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory = {
  __typename?: 'crmContactEnrichmentHistory';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  crmField: Scalars['String'];
  crmType: Scalars['String'];
  enrichmentType: Scalars['String'];
  id: Scalars['uuid'];
  previousValue?: Maybe<Scalars['String']>;
  updatedValue: Scalars['String'];
};

/** aggregated selection of "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Aggregate = {
  __typename?: 'crmContactEnrichmentHistory_aggregate';
  aggregate?: Maybe<CrmContactEnrichmentHistory_Aggregate_Fields>;
  nodes: Array<CrmContactEnrichmentHistory>;
};

export type CrmContactEnrichmentHistory_Aggregate_Bool_Exp = {
  count?: InputMaybe<CrmContactEnrichmentHistory_Aggregate_Bool_Exp_Count>;
};

export type CrmContactEnrichmentHistory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Aggregate_Fields = {
  __typename?: 'crmContactEnrichmentHistory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CrmContactEnrichmentHistory_Max_Fields>;
  min?: Maybe<CrmContactEnrichmentHistory_Min_Fields>;
};

/** aggregate fields of "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CrmContactEnrichmentHistory_Max_Order_By>;
  min?: InputMaybe<CrmContactEnrichmentHistory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Arr_Rel_Insert_Input = {
  data: Array<CrmContactEnrichmentHistory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CrmContactEnrichmentHistory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "crmContactEnrichmentHistory". All fields are combined with a logical 'AND'. */
export type CrmContactEnrichmentHistory_Bool_Exp = {
  _and?: InputMaybe<Array<CrmContactEnrichmentHistory_Bool_Exp>>;
  _not?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
  _or?: InputMaybe<Array<CrmContactEnrichmentHistory_Bool_Exp>>;
  clientContactId?: InputMaybe<Uuid_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  crmField?: InputMaybe<String_Comparison_Exp>;
  crmType?: InputMaybe<String_Comparison_Exp>;
  enrichmentType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  previousValue?: InputMaybe<String_Comparison_Exp>;
  updatedValue?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "crmContactEnrichmentHistory" */
export enum CrmContactEnrichmentHistory_Constraint {
  /** unique or primary key constraint on columns "id" */
  CrmContactEnrichmentHistoryPkey = 'crmContactEnrichmentHistory_pkey',
}

/** input type for inserting data into table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Insert_Input = {
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmField?: InputMaybe<Scalars['String']>;
  crmType?: InputMaybe<Scalars['String']>;
  enrichmentType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  previousValue?: InputMaybe<Scalars['String']>;
  updatedValue?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CrmContactEnrichmentHistory_Max_Fields = {
  __typename?: 'crmContactEnrichmentHistory_max_fields';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmField?: Maybe<Scalars['String']>;
  crmType?: Maybe<Scalars['String']>;
  enrichmentType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  previousValue?: Maybe<Scalars['String']>;
  updatedValue?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Max_Order_By = {
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmField?: InputMaybe<Order_By>;
  crmType?: InputMaybe<Order_By>;
  enrichmentType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previousValue?: InputMaybe<Order_By>;
  updatedValue?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CrmContactEnrichmentHistory_Min_Fields = {
  __typename?: 'crmContactEnrichmentHistory_min_fields';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmField?: Maybe<Scalars['String']>;
  crmType?: Maybe<Scalars['String']>;
  enrichmentType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  previousValue?: Maybe<Scalars['String']>;
  updatedValue?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Min_Order_By = {
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmField?: InputMaybe<Order_By>;
  crmType?: InputMaybe<Order_By>;
  enrichmentType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previousValue?: InputMaybe<Order_By>;
  updatedValue?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Mutation_Response = {
  __typename?: 'crmContactEnrichmentHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CrmContactEnrichmentHistory>;
};

/** on_conflict condition type for table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_On_Conflict = {
  constraint: CrmContactEnrichmentHistory_Constraint;
  update_columns?: Array<CrmContactEnrichmentHistory_Update_Column>;
  where?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "crmContactEnrichmentHistory". */
export type CrmContactEnrichmentHistory_Order_By = {
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crmField?: InputMaybe<Order_By>;
  crmType?: InputMaybe<Order_By>;
  enrichmentType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previousValue?: InputMaybe<Order_By>;
  updatedValue?: InputMaybe<Order_By>;
};

/** primary key columns input for table: crmContactEnrichmentHistory */
export type CrmContactEnrichmentHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "crmContactEnrichmentHistory" */
export enum CrmContactEnrichmentHistory_Select_Column {
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmField = 'crmField',
  /** column name */
  CrmType = 'crmType',
  /** column name */
  EnrichmentType = 'enrichmentType',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousValue = 'previousValue',
  /** column name */
  UpdatedValue = 'updatedValue',
}

/** input type for updating data in table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Set_Input = {
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmField?: InputMaybe<Scalars['String']>;
  crmType?: InputMaybe<Scalars['String']>;
  enrichmentType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  previousValue?: InputMaybe<Scalars['String']>;
  updatedValue?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CrmContactEnrichmentHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CrmContactEnrichmentHistory_Stream_Cursor_Value_Input = {
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crmField?: InputMaybe<Scalars['String']>;
  crmType?: InputMaybe<Scalars['String']>;
  enrichmentType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  previousValue?: InputMaybe<Scalars['String']>;
  updatedValue?: InputMaybe<Scalars['String']>;
};

/** update columns of table "crmContactEnrichmentHistory" */
export enum CrmContactEnrichmentHistory_Update_Column {
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmField = 'crmField',
  /** column name */
  CrmType = 'crmType',
  /** column name */
  EnrichmentType = 'enrichmentType',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousValue = 'previousValue',
  /** column name */
  UpdatedValue = 'updatedValue',
}

export type CrmContactEnrichmentHistory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CrmContactEnrichmentHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: CrmContactEnrichmentHistory_Bool_Exp;
};

/** columns and relationships of "csvUpload" */
export type CsvUpload = {
  __typename?: 'csvUpload';
  clientId: Scalars['uuid'];
  contactCount?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  mapping?: Maybe<Scalars['jsonb']>;
  syncCompletedAt?: Maybe<Scalars['timestamptz']>;
  uploadedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  uploadedByUser?: Maybe<User>;
  uploadedByUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "csvUpload" */
export type CsvUploadMappingArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "csvUpload" */
export type CsvUpload_Aggregate = {
  __typename?: 'csvUpload_aggregate';
  aggregate?: Maybe<CsvUpload_Aggregate_Fields>;
  nodes: Array<CsvUpload>;
};

export type CsvUpload_Aggregate_Bool_Exp = {
  count?: InputMaybe<CsvUpload_Aggregate_Bool_Exp_Count>;
};

export type CsvUpload_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CsvUpload_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CsvUpload_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "csvUpload" */
export type CsvUpload_Aggregate_Fields = {
  __typename?: 'csvUpload_aggregate_fields';
  avg?: Maybe<CsvUpload_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CsvUpload_Max_Fields>;
  min?: Maybe<CsvUpload_Min_Fields>;
  stddev?: Maybe<CsvUpload_Stddev_Fields>;
  stddev_pop?: Maybe<CsvUpload_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CsvUpload_Stddev_Samp_Fields>;
  sum?: Maybe<CsvUpload_Sum_Fields>;
  var_pop?: Maybe<CsvUpload_Var_Pop_Fields>;
  var_samp?: Maybe<CsvUpload_Var_Samp_Fields>;
  variance?: Maybe<CsvUpload_Variance_Fields>;
};

/** aggregate fields of "csvUpload" */
export type CsvUpload_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CsvUpload_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "csvUpload" */
export type CsvUpload_Aggregate_Order_By = {
  avg?: InputMaybe<CsvUpload_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CsvUpload_Max_Order_By>;
  min?: InputMaybe<CsvUpload_Min_Order_By>;
  stddev?: InputMaybe<CsvUpload_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CsvUpload_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CsvUpload_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CsvUpload_Sum_Order_By>;
  var_pop?: InputMaybe<CsvUpload_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CsvUpload_Var_Samp_Order_By>;
  variance?: InputMaybe<CsvUpload_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CsvUpload_Append_Input = {
  mapping?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "csvUpload" */
export type CsvUpload_Arr_Rel_Insert_Input = {
  data: Array<CsvUpload_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CsvUpload_On_Conflict>;
};

/** aggregate avg on columns */
export type CsvUpload_Avg_Fields = {
  __typename?: 'csvUpload_avg_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "csvUpload" */
export type CsvUpload_Avg_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "csvUpload". All fields are combined with a logical 'AND'. */
export type CsvUpload_Bool_Exp = {
  _and?: InputMaybe<Array<CsvUpload_Bool_Exp>>;
  _not?: InputMaybe<CsvUpload_Bool_Exp>;
  _or?: InputMaybe<Array<CsvUpload_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  contactCount?: InputMaybe<Int_Comparison_Exp>;
  fileName?: InputMaybe<String_Comparison_Exp>;
  filePath?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mapping?: InputMaybe<Jsonb_Comparison_Exp>;
  syncCompletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedByUser?: InputMaybe<User_Bool_Exp>;
  uploadedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "csvUpload" */
export enum CsvUpload_Constraint {
  /** unique or primary key constraint on columns "id" */
  CsvUploadPkey = 'csvUpload_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CsvUpload_Delete_At_Path_Input = {
  mapping?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CsvUpload_Delete_Elem_Input = {
  mapping?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CsvUpload_Delete_Key_Input = {
  mapping?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "csvUpload" */
export type CsvUpload_Inc_Input = {
  contactCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "csvUpload" */
export type CsvUpload_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactCount?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  syncCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedByUser?: InputMaybe<User_Obj_Rel_Insert_Input>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CsvUpload_Max_Fields = {
  __typename?: 'csvUpload_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  syncCompletedAt?: Maybe<Scalars['timestamptz']>;
  uploadedAt?: Maybe<Scalars['timestamptz']>;
  uploadedByUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "csvUpload" */
export type CsvUpload_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactCount?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  syncCompletedAt?: InputMaybe<Order_By>;
  uploadedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CsvUpload_Min_Fields = {
  __typename?: 'csvUpload_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  syncCompletedAt?: Maybe<Scalars['timestamptz']>;
  uploadedAt?: Maybe<Scalars['timestamptz']>;
  uploadedByUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "csvUpload" */
export type CsvUpload_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactCount?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  syncCompletedAt?: InputMaybe<Order_By>;
  uploadedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "csvUpload" */
export type CsvUpload_Mutation_Response = {
  __typename?: 'csvUpload_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CsvUpload>;
};

/** input type for inserting object relation for remote table "csvUpload" */
export type CsvUpload_Obj_Rel_Insert_Input = {
  data: CsvUpload_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CsvUpload_On_Conflict>;
};

/** on_conflict condition type for table "csvUpload" */
export type CsvUpload_On_Conflict = {
  constraint: CsvUpload_Constraint;
  update_columns?: Array<CsvUpload_Update_Column>;
  where?: InputMaybe<CsvUpload_Bool_Exp>;
};

/** Ordering options when selecting data from "csvUpload". */
export type CsvUpload_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactCount?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  filePath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mapping?: InputMaybe<Order_By>;
  syncCompletedAt?: InputMaybe<Order_By>;
  uploadedAt?: InputMaybe<Order_By>;
  uploadedByUser?: InputMaybe<User_Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: csvUpload */
export type CsvUpload_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CsvUpload_Prepend_Input = {
  mapping?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "csvUpload" */
export enum CsvUpload_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactCount = 'contactCount',
  /** column name */
  FileName = 'fileName',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  SyncCompletedAt = 'syncCompletedAt',
  /** column name */
  UploadedAt = 'uploadedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId',
}

/** input type for updating data in table "csvUpload" */
export type CsvUpload_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactCount?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  syncCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CsvUpload_Stddev_Fields = {
  __typename?: 'csvUpload_stddev_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "csvUpload" */
export type CsvUpload_Stddev_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CsvUpload_Stddev_Pop_Fields = {
  __typename?: 'csvUpload_stddev_pop_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "csvUpload" */
export type CsvUpload_Stddev_Pop_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CsvUpload_Stddev_Samp_Fields = {
  __typename?: 'csvUpload_stddev_samp_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "csvUpload" */
export type CsvUpload_Stddev_Samp_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "csvUpload" */
export type CsvUpload_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CsvUpload_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CsvUpload_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactCount?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  syncCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CsvUpload_Sum_Fields = {
  __typename?: 'csvUpload_sum_fields';
  contactCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "csvUpload" */
export type CsvUpload_Sum_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** update columns of table "csvUpload" */
export enum CsvUpload_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactCount = 'contactCount',
  /** column name */
  FileName = 'fileName',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  SyncCompletedAt = 'syncCompletedAt',
  /** column name */
  UploadedAt = 'uploadedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId',
}

export type CsvUpload_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CsvUpload_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CsvUpload_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CsvUpload_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CsvUpload_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CsvUpload_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CsvUpload_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CsvUpload_Set_Input>;
  /** filter the rows which have to be updated */
  where: CsvUpload_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CsvUpload_Var_Pop_Fields = {
  __typename?: 'csvUpload_var_pop_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "csvUpload" */
export type CsvUpload_Var_Pop_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CsvUpload_Var_Samp_Fields = {
  __typename?: 'csvUpload_var_samp_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "csvUpload" */
export type CsvUpload_Var_Samp_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CsvUpload_Variance_Fields = {
  __typename?: 'csvUpload_variance_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "csvUpload" */
export type CsvUpload_Variance_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob = {
  __typename?: 'deleteOldZoomEventsJob';
  chunkSize: Scalars['Int'];
  cloudTaskName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  errorMessage?: Maybe<Scalars['String']>;
  fromDaysAgo?: Maybe<Scalars['Int']>;
  fromEventId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_Aggregate = {
  __typename?: 'deleteOldZoomEventsJob_aggregate';
  aggregate?: Maybe<DeleteOldZoomEventsJob_Aggregate_Fields>;
  nodes: Array<DeleteOldZoomEventsJob>;
};

/** aggregate fields of "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_Aggregate_Fields = {
  __typename?: 'deleteOldZoomEventsJob_aggregate_fields';
  avg?: Maybe<DeleteOldZoomEventsJob_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<DeleteOldZoomEventsJob_Max_Fields>;
  min?: Maybe<DeleteOldZoomEventsJob_Min_Fields>;
  stddev?: Maybe<DeleteOldZoomEventsJob_Stddev_Fields>;
  stddev_pop?: Maybe<DeleteOldZoomEventsJob_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<DeleteOldZoomEventsJob_Stddev_Samp_Fields>;
  sum?: Maybe<DeleteOldZoomEventsJob_Sum_Fields>;
  var_pop?: Maybe<DeleteOldZoomEventsJob_Var_Pop_Fields>;
  var_samp?: Maybe<DeleteOldZoomEventsJob_Var_Samp_Fields>;
  variance?: Maybe<DeleteOldZoomEventsJob_Variance_Fields>;
};

/** aggregate fields of "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DeleteOldZoomEventsJob_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DeleteOldZoomEventsJob_Avg_Fields = {
  __typename?: 'deleteOldZoomEventsJob_avg_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  fromDaysAgo?: Maybe<Scalars['Float']>;
  fromEventId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "deleteOldZoomEventsJob". All fields are combined with a logical 'AND'. */
export type DeleteOldZoomEventsJob_Bool_Exp = {
  _and?: InputMaybe<Array<DeleteOldZoomEventsJob_Bool_Exp>>;
  _not?: InputMaybe<DeleteOldZoomEventsJob_Bool_Exp>;
  _or?: InputMaybe<Array<DeleteOldZoomEventsJob_Bool_Exp>>;
  chunkSize?: InputMaybe<Int_Comparison_Exp>;
  cloudTaskName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorMessage?: InputMaybe<String_Comparison_Exp>;
  fromDaysAgo?: InputMaybe<Int_Comparison_Exp>;
  fromEventId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "deleteOldZoomEventsJob" */
export enum DeleteOldZoomEventsJob_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeleteOldZoomEventsJobPkey = 'deleteOldZoomEventsJob_pkey',
}

/** input type for incrementing numeric columns in table "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_Inc_Input = {
  chunkSize?: InputMaybe<Scalars['Int']>;
  fromDaysAgo?: InputMaybe<Scalars['Int']>;
  fromEventId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_Insert_Input = {
  chunkSize?: InputMaybe<Scalars['Int']>;
  cloudTaskName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  fromDaysAgo?: InputMaybe<Scalars['Int']>;
  fromEventId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type DeleteOldZoomEventsJob_Max_Fields = {
  __typename?: 'deleteOldZoomEventsJob_max_fields';
  chunkSize?: Maybe<Scalars['Int']>;
  cloudTaskName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  fromDaysAgo?: Maybe<Scalars['Int']>;
  fromEventId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type DeleteOldZoomEventsJob_Min_Fields = {
  __typename?: 'deleteOldZoomEventsJob_min_fields';
  chunkSize?: Maybe<Scalars['Int']>;
  cloudTaskName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  fromDaysAgo?: Maybe<Scalars['Int']>;
  fromEventId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_Mutation_Response = {
  __typename?: 'deleteOldZoomEventsJob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DeleteOldZoomEventsJob>;
};

/** on_conflict condition type for table "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_On_Conflict = {
  constraint: DeleteOldZoomEventsJob_Constraint;
  update_columns?: Array<DeleteOldZoomEventsJob_Update_Column>;
  where?: InputMaybe<DeleteOldZoomEventsJob_Bool_Exp>;
};

/** Ordering options when selecting data from "deleteOldZoomEventsJob". */
export type DeleteOldZoomEventsJob_Order_By = {
  chunkSize?: InputMaybe<Order_By>;
  cloudTaskName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  fromDaysAgo?: InputMaybe<Order_By>;
  fromEventId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: deleteOldZoomEventsJob */
export type DeleteOldZoomEventsJob_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "deleteOldZoomEventsJob" */
export enum DeleteOldZoomEventsJob_Select_Column {
  /** column name */
  ChunkSize = 'chunkSize',
  /** column name */
  CloudTaskName = 'cloudTaskName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  FromDaysAgo = 'fromDaysAgo',
  /** column name */
  FromEventId = 'fromEventId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_Set_Input = {
  chunkSize?: InputMaybe<Scalars['Int']>;
  cloudTaskName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  fromDaysAgo?: InputMaybe<Scalars['Int']>;
  fromEventId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type DeleteOldZoomEventsJob_Stddev_Fields = {
  __typename?: 'deleteOldZoomEventsJob_stddev_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  fromDaysAgo?: Maybe<Scalars['Float']>;
  fromEventId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type DeleteOldZoomEventsJob_Stddev_Pop_Fields = {
  __typename?: 'deleteOldZoomEventsJob_stddev_pop_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  fromDaysAgo?: Maybe<Scalars['Float']>;
  fromEventId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type DeleteOldZoomEventsJob_Stddev_Samp_Fields = {
  __typename?: 'deleteOldZoomEventsJob_stddev_samp_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  fromDaysAgo?: Maybe<Scalars['Float']>;
  fromEventId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "deleteOldZoomEventsJob" */
export type DeleteOldZoomEventsJob_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DeleteOldZoomEventsJob_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DeleteOldZoomEventsJob_Stream_Cursor_Value_Input = {
  chunkSize?: InputMaybe<Scalars['Int']>;
  cloudTaskName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  fromDaysAgo?: InputMaybe<Scalars['Int']>;
  fromEventId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type DeleteOldZoomEventsJob_Sum_Fields = {
  __typename?: 'deleteOldZoomEventsJob_sum_fields';
  chunkSize?: Maybe<Scalars['Int']>;
  fromDaysAgo?: Maybe<Scalars['Int']>;
  fromEventId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "deleteOldZoomEventsJob" */
export enum DeleteOldZoomEventsJob_Update_Column {
  /** column name */
  ChunkSize = 'chunkSize',
  /** column name */
  CloudTaskName = 'cloudTaskName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  FromDaysAgo = 'fromDaysAgo',
  /** column name */
  FromEventId = 'fromEventId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type DeleteOldZoomEventsJob_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DeleteOldZoomEventsJob_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DeleteOldZoomEventsJob_Set_Input>;
  /** filter the rows which have to be updated */
  where: DeleteOldZoomEventsJob_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DeleteOldZoomEventsJob_Var_Pop_Fields = {
  __typename?: 'deleteOldZoomEventsJob_var_pop_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  fromDaysAgo?: Maybe<Scalars['Float']>;
  fromEventId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type DeleteOldZoomEventsJob_Var_Samp_Fields = {
  __typename?: 'deleteOldZoomEventsJob_var_samp_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  fromDaysAgo?: Maybe<Scalars['Float']>;
  fromEventId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DeleteOldZoomEventsJob_Variance_Fields = {
  __typename?: 'deleteOldZoomEventsJob_variance_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  fromDaysAgo?: Maybe<Scalars['Float']>;
  fromEventId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "emailHistory" */
export type EmailHistory = {
  __typename?: 'emailHistory';
  category: Scalars['String'];
  clientId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailService: Scalars['String'];
  emailServiceMessageId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "emailHistory" */
export type EmailHistory_Aggregate = {
  __typename?: 'emailHistory_aggregate';
  aggregate?: Maybe<EmailHistory_Aggregate_Fields>;
  nodes: Array<EmailHistory>;
};

/** aggregate fields of "emailHistory" */
export type EmailHistory_Aggregate_Fields = {
  __typename?: 'emailHistory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailHistory_Max_Fields>;
  min?: Maybe<EmailHistory_Min_Fields>;
};

/** aggregate fields of "emailHistory" */
export type EmailHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmailHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "emailHistory". All fields are combined with a logical 'AND'. */
export type EmailHistory_Bool_Exp = {
  _and?: InputMaybe<Array<EmailHistory_Bool_Exp>>;
  _not?: InputMaybe<EmailHistory_Bool_Exp>;
  _or?: InputMaybe<Array<EmailHistory_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  emailService?: InputMaybe<String_Comparison_Exp>;
  emailServiceMessageId?: InputMaybe<String_Comparison_Exp>;
  firestoreId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  statusUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "emailHistory" */
export enum EmailHistory_Constraint {
  /** unique or primary key constraint on columns "firestoreId" */
  EmailHistoryFirestoreIdKey = 'emailHistory_firestoreId_key',
  /** unique or primary key constraint on columns "id" */
  EmailHistoryPkey = 'emailHistory_pkey',
}

/** input type for inserting data into table "emailHistory" */
export type EmailHistory_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailService?: InputMaybe<Scalars['String']>;
  emailServiceMessageId?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  statusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmailHistory_Max_Fields = {
  __typename?: 'emailHistory_max_fields';
  category?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type EmailHistory_Min_Fields = {
  __typename?: 'emailHistory_min_fields';
  category?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "emailHistory" */
export type EmailHistory_Mutation_Response = {
  __typename?: 'emailHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailHistory>;
};

/** on_conflict condition type for table "emailHistory" */
export type EmailHistory_On_Conflict = {
  constraint: EmailHistory_Constraint;
  update_columns?: Array<EmailHistory_Update_Column>;
  where?: InputMaybe<EmailHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "emailHistory". */
export type EmailHistory_Order_By = {
  category?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailService?: InputMaybe<Order_By>;
  emailServiceMessageId?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  statusUpdatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emailHistory */
export type EmailHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "emailHistory" */
export enum EmailHistory_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  EmailService = 'emailService',
  /** column name */
  EmailServiceMessageId = 'emailServiceMessageId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusUpdatedAt = 'statusUpdatedAt',
}

/** input type for updating data in table "emailHistory" */
export type EmailHistory_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailService?: InputMaybe<Scalars['String']>;
  emailServiceMessageId?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  statusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "emailHistory" */
export type EmailHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmailHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailHistory_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailService?: InputMaybe<Scalars['String']>;
  emailServiceMessageId?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  statusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "emailHistory" */
export enum EmailHistory_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  EmailService = 'emailService',
  /** column name */
  EmailServiceMessageId = 'emailServiceMessageId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusUpdatedAt = 'statusUpdatedAt',
}

export type EmailHistory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: EmailHistory_Bool_Exp;
};

/** columns and relationships of "googleChannel" */
export type GoogleChannel = {
  __typename?: 'googleChannel';
  channelId: Scalars['String'];
  expiration?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  resourceId: Scalars['String'];
  resourceUri?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId: Scalars['uuid'];
};

/** aggregated selection of "googleChannel" */
export type GoogleChannel_Aggregate = {
  __typename?: 'googleChannel_aggregate';
  aggregate?: Maybe<GoogleChannel_Aggregate_Fields>;
  nodes: Array<GoogleChannel>;
};

/** aggregate fields of "googleChannel" */
export type GoogleChannel_Aggregate_Fields = {
  __typename?: 'googleChannel_aggregate_fields';
  avg?: Maybe<GoogleChannel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GoogleChannel_Max_Fields>;
  min?: Maybe<GoogleChannel_Min_Fields>;
  stddev?: Maybe<GoogleChannel_Stddev_Fields>;
  stddev_pop?: Maybe<GoogleChannel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GoogleChannel_Stddev_Samp_Fields>;
  sum?: Maybe<GoogleChannel_Sum_Fields>;
  var_pop?: Maybe<GoogleChannel_Var_Pop_Fields>;
  var_samp?: Maybe<GoogleChannel_Var_Samp_Fields>;
  variance?: Maybe<GoogleChannel_Variance_Fields>;
};

/** aggregate fields of "googleChannel" */
export type GoogleChannel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GoogleChannel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GoogleChannel_Avg_Fields = {
  __typename?: 'googleChannel_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "googleChannel". All fields are combined with a logical 'AND'. */
export type GoogleChannel_Bool_Exp = {
  _and?: InputMaybe<Array<GoogleChannel_Bool_Exp>>;
  _not?: InputMaybe<GoogleChannel_Bool_Exp>;
  _or?: InputMaybe<Array<GoogleChannel_Bool_Exp>>;
  channelId?: InputMaybe<String_Comparison_Exp>;
  expiration?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  resourceId?: InputMaybe<String_Comparison_Exp>;
  resourceUri?: InputMaybe<String_Comparison_Exp>;
  stoppedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "googleChannel" */
export enum GoogleChannel_Constraint {
  /** unique or primary key constraint on columns "channelId" */
  GoogleChannelChannelIdKey = 'googleChannel_channelId_key',
  /** unique or primary key constraint on columns "id" */
  GoogleChannelPkey = 'googleChannel_pkey',
}

/** input type for incrementing numeric columns in table "googleChannel" */
export type GoogleChannel_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "googleChannel" */
export type GoogleChannel_Insert_Input = {
  channelId?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  resourceId?: InputMaybe<Scalars['String']>;
  resourceUri?: InputMaybe<Scalars['String']>;
  stoppedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GoogleChannel_Max_Fields = {
  __typename?: 'googleChannel_max_fields';
  channelId?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUri?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type GoogleChannel_Min_Fields = {
  __typename?: 'googleChannel_min_fields';
  channelId?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUri?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "googleChannel" */
export type GoogleChannel_Mutation_Response = {
  __typename?: 'googleChannel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoogleChannel>;
};

/** on_conflict condition type for table "googleChannel" */
export type GoogleChannel_On_Conflict = {
  constraint: GoogleChannel_Constraint;
  update_columns?: Array<GoogleChannel_Update_Column>;
  where?: InputMaybe<GoogleChannel_Bool_Exp>;
};

/** Ordering options when selecting data from "googleChannel". */
export type GoogleChannel_Order_By = {
  channelId?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resourceId?: InputMaybe<Order_By>;
  resourceUri?: InputMaybe<Order_By>;
  stoppedAt?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: googleChannel */
export type GoogleChannel_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "googleChannel" */
export enum GoogleChannel_Select_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resourceId',
  /** column name */
  ResourceUri = 'resourceUri',
  /** column name */
  StoppedAt = 'stoppedAt',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "googleChannel" */
export type GoogleChannel_Set_Input = {
  channelId?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  resourceId?: InputMaybe<Scalars['String']>;
  resourceUri?: InputMaybe<Scalars['String']>;
  stoppedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type GoogleChannel_Stddev_Fields = {
  __typename?: 'googleChannel_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GoogleChannel_Stddev_Pop_Fields = {
  __typename?: 'googleChannel_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GoogleChannel_Stddev_Samp_Fields = {
  __typename?: 'googleChannel_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "googleChannel" */
export type GoogleChannel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GoogleChannel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GoogleChannel_Stream_Cursor_Value_Input = {
  channelId?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  resourceId?: InputMaybe<Scalars['String']>;
  resourceUri?: InputMaybe<Scalars['String']>;
  stoppedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type GoogleChannel_Sum_Fields = {
  __typename?: 'googleChannel_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "googleChannel" */
export enum GoogleChannel_Update_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resourceId',
  /** column name */
  ResourceUri = 'resourceUri',
  /** column name */
  StoppedAt = 'stoppedAt',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type GoogleChannel_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GoogleChannel_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GoogleChannel_Set_Input>;
  /** filter the rows which have to be updated */
  where: GoogleChannel_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GoogleChannel_Var_Pop_Fields = {
  __typename?: 'googleChannel_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GoogleChannel_Var_Samp_Fields = {
  __typename?: 'googleChannel_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GoogleChannel_Variance_Fields = {
  __typename?: 'googleChannel_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Collection of IntraCompany Adoption Campaign Contacts */
export type GrowthIntraCompanyAdoptionContact = {
  __typename?: 'growthIntraCompanyAdoptionContact';
  companyName?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lastName?: Maybe<Scalars['String']>;
  zoomContactId: Scalars['uuid'];
  zoomUserId: Scalars['uuid'];
};

/** aggregated selection of "growthIntraCompanyAdoptionContact" */
export type GrowthIntraCompanyAdoptionContact_Aggregate = {
  __typename?: 'growthIntraCompanyAdoptionContact_aggregate';
  aggregate?: Maybe<GrowthIntraCompanyAdoptionContact_Aggregate_Fields>;
  nodes: Array<GrowthIntraCompanyAdoptionContact>;
};

/** aggregate fields of "growthIntraCompanyAdoptionContact" */
export type GrowthIntraCompanyAdoptionContact_Aggregate_Fields = {
  __typename?: 'growthIntraCompanyAdoptionContact_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GrowthIntraCompanyAdoptionContact_Max_Fields>;
  min?: Maybe<GrowthIntraCompanyAdoptionContact_Min_Fields>;
};

/** aggregate fields of "growthIntraCompanyAdoptionContact" */
export type GrowthIntraCompanyAdoptionContact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "growthIntraCompanyAdoptionContact". All fields are combined with a logical 'AND'. */
export type GrowthIntraCompanyAdoptionContact_Bool_Exp = {
  _and?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Bool_Exp>>;
  _not?: InputMaybe<GrowthIntraCompanyAdoptionContact_Bool_Exp>;
  _or?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Bool_Exp>>;
  companyName?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  zoomContactId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "growthIntraCompanyAdoptionContact" */
export enum GrowthIntraCompanyAdoptionContact_Constraint {
  /** unique or primary key constraint on columns "email" */
  GrowthIntraCompanyAdoptionContactEmailKey = 'growthIntraCompanyAdoptionContact_email_key',
  /** unique or primary key constraint on columns "id" */
  GrowthIntraCompanyAdoptionContactPkey = 'growthIntraCompanyAdoptionContact_pkey',
  /** unique or primary key constraint on columns "zoomContactId" */
  GrowthIntraCompanyAdoptionContactZoomContactIdKey = 'growthIntraCompanyAdoptionContact_zoomContactId_key',
}

/** input type for inserting data into table "growthIntraCompanyAdoptionContact" */
export type GrowthIntraCompanyAdoptionContact_Insert_Input = {
  companyName?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GrowthIntraCompanyAdoptionContact_Max_Fields = {
  __typename?: 'growthIntraCompanyAdoptionContact_max_fields';
  companyName?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type GrowthIntraCompanyAdoptionContact_Min_Fields = {
  __typename?: 'growthIntraCompanyAdoptionContact_min_fields';
  companyName?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "growthIntraCompanyAdoptionContact" */
export type GrowthIntraCompanyAdoptionContact_Mutation_Response = {
  __typename?: 'growthIntraCompanyAdoptionContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GrowthIntraCompanyAdoptionContact>;
};

/** on_conflict condition type for table "growthIntraCompanyAdoptionContact" */
export type GrowthIntraCompanyAdoptionContact_On_Conflict = {
  constraint: GrowthIntraCompanyAdoptionContact_Constraint;
  update_columns?: Array<GrowthIntraCompanyAdoptionContact_Update_Column>;
  where?: InputMaybe<GrowthIntraCompanyAdoptionContact_Bool_Exp>;
};

/** Ordering options when selecting data from "growthIntraCompanyAdoptionContact". */
export type GrowthIntraCompanyAdoptionContact_Order_By = {
  companyName?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  zoomContactId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: growthIntraCompanyAdoptionContact */
export type GrowthIntraCompanyAdoptionContact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "growthIntraCompanyAdoptionContact" */
export enum GrowthIntraCompanyAdoptionContact_Select_Column {
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "growthIntraCompanyAdoptionContact" */
export type GrowthIntraCompanyAdoptionContact_Set_Input = {
  companyName?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "growthIntraCompanyAdoptionContact" */
export type GrowthIntraCompanyAdoptionContact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GrowthIntraCompanyAdoptionContact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GrowthIntraCompanyAdoptionContact_Stream_Cursor_Value_Input = {
  companyName?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "growthIntraCompanyAdoptionContact" */
export enum GrowthIntraCompanyAdoptionContact_Update_Column {
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type GrowthIntraCompanyAdoptionContact_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GrowthIntraCompanyAdoptionContact_Set_Input>;
  /** filter the rows which have to be updated */
  where: GrowthIntraCompanyAdoptionContact_Bool_Exp;
};

/** columns and relationships of "growthUserReferralContact" */
export type GrowthUserReferralContact = {
  __typename?: 'growthUserReferralContact';
  createdAt: Scalars['date'];
  id: Scalars['uuid'];
  refereeEmail: Scalars['String'];
  referredOn: Scalars['date'];
  referrerEmail: Scalars['String'];
  referrerFirstName?: Maybe<Scalars['String']>;
  referrerId: Scalars['uuid'];
  referrerLastName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "growthUserReferralContact" */
export type GrowthUserReferralContact_Aggregate = {
  __typename?: 'growthUserReferralContact_aggregate';
  aggregate?: Maybe<GrowthUserReferralContact_Aggregate_Fields>;
  nodes: Array<GrowthUserReferralContact>;
};

/** aggregate fields of "growthUserReferralContact" */
export type GrowthUserReferralContact_Aggregate_Fields = {
  __typename?: 'growthUserReferralContact_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GrowthUserReferralContact_Max_Fields>;
  min?: Maybe<GrowthUserReferralContact_Min_Fields>;
};

/** aggregate fields of "growthUserReferralContact" */
export type GrowthUserReferralContact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GrowthUserReferralContact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "growthUserReferralContact". All fields are combined with a logical 'AND'. */
export type GrowthUserReferralContact_Bool_Exp = {
  _and?: InputMaybe<Array<GrowthUserReferralContact_Bool_Exp>>;
  _not?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
  _or?: InputMaybe<Array<GrowthUserReferralContact_Bool_Exp>>;
  createdAt?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  refereeEmail?: InputMaybe<String_Comparison_Exp>;
  referredOn?: InputMaybe<Date_Comparison_Exp>;
  referrerEmail?: InputMaybe<String_Comparison_Exp>;
  referrerFirstName?: InputMaybe<String_Comparison_Exp>;
  referrerId?: InputMaybe<Uuid_Comparison_Exp>;
  referrerLastName?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "growthUserReferralContact" */
export enum GrowthUserReferralContact_Constraint {
  /** unique or primary key constraint on columns "id" */
  GrowthUserReferralContactPkey = 'growthUserReferralContact_pkey',
}

/** input type for inserting data into table "growthUserReferralContact" */
export type GrowthUserReferralContact_Insert_Input = {
  createdAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  refereeEmail?: InputMaybe<Scalars['String']>;
  referredOn?: InputMaybe<Scalars['date']>;
  referrerEmail?: InputMaybe<Scalars['String']>;
  referrerFirstName?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['uuid']>;
  referrerLastName?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GrowthUserReferralContact_Max_Fields = {
  __typename?: 'growthUserReferralContact_max_fields';
  createdAt?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  refereeEmail?: Maybe<Scalars['String']>;
  referredOn?: Maybe<Scalars['date']>;
  referrerEmail?: Maybe<Scalars['String']>;
  referrerFirstName?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['uuid']>;
  referrerLastName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type GrowthUserReferralContact_Min_Fields = {
  __typename?: 'growthUserReferralContact_min_fields';
  createdAt?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  refereeEmail?: Maybe<Scalars['String']>;
  referredOn?: Maybe<Scalars['date']>;
  referrerEmail?: Maybe<Scalars['String']>;
  referrerFirstName?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['uuid']>;
  referrerLastName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "growthUserReferralContact" */
export type GrowthUserReferralContact_Mutation_Response = {
  __typename?: 'growthUserReferralContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GrowthUserReferralContact>;
};

/** on_conflict condition type for table "growthUserReferralContact" */
export type GrowthUserReferralContact_On_Conflict = {
  constraint: GrowthUserReferralContact_Constraint;
  update_columns?: Array<GrowthUserReferralContact_Update_Column>;
  where?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
};

/** Ordering options when selecting data from "growthUserReferralContact". */
export type GrowthUserReferralContact_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refereeEmail?: InputMaybe<Order_By>;
  referredOn?: InputMaybe<Order_By>;
  referrerEmail?: InputMaybe<Order_By>;
  referrerFirstName?: InputMaybe<Order_By>;
  referrerId?: InputMaybe<Order_By>;
  referrerLastName?: InputMaybe<Order_By>;
};

/** primary key columns input for table: growthUserReferralContact */
export type GrowthUserReferralContact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "growthUserReferralContact" */
export enum GrowthUserReferralContact_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RefereeEmail = 'refereeEmail',
  /** column name */
  ReferredOn = 'referredOn',
  /** column name */
  ReferrerEmail = 'referrerEmail',
  /** column name */
  ReferrerFirstName = 'referrerFirstName',
  /** column name */
  ReferrerId = 'referrerId',
  /** column name */
  ReferrerLastName = 'referrerLastName',
}

/** input type for updating data in table "growthUserReferralContact" */
export type GrowthUserReferralContact_Set_Input = {
  createdAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  refereeEmail?: InputMaybe<Scalars['String']>;
  referredOn?: InputMaybe<Scalars['date']>;
  referrerEmail?: InputMaybe<Scalars['String']>;
  referrerFirstName?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['uuid']>;
  referrerLastName?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "growthUserReferralContact" */
export type GrowthUserReferralContact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GrowthUserReferralContact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GrowthUserReferralContact_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  refereeEmail?: InputMaybe<Scalars['String']>;
  referredOn?: InputMaybe<Scalars['date']>;
  referrerEmail?: InputMaybe<Scalars['String']>;
  referrerFirstName?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['uuid']>;
  referrerLastName?: InputMaybe<Scalars['String']>;
};

/** update columns of table "growthUserReferralContact" */
export enum GrowthUserReferralContact_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RefereeEmail = 'refereeEmail',
  /** column name */
  ReferredOn = 'referredOn',
  /** column name */
  ReferrerEmail = 'referrerEmail',
  /** column name */
  ReferrerFirstName = 'referrerFirstName',
  /** column name */
  ReferrerId = 'referrerId',
  /** column name */
  ReferrerLastName = 'referrerLastName',
}

export type GrowthUserReferralContact_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GrowthUserReferralContact_Set_Input>;
  /** filter the rows which have to be updated */
  where: GrowthUserReferralContact_Bool_Exp;
};

/** columns and relationships of "hubspotContactList" */
export type HubspotContactList = {
  __typename?: 'hubspotContactList';
  clientId: Scalars['uuid'];
  contactCount?: Maybe<Scalars['Int']>;
  hubspotIntegrationId: Scalars['uuid'];
  id: Scalars['uuid'];
  internalListId?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['jsonb']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  listId: Scalars['String'];
  name: Scalars['String'];
};

/** columns and relationships of "hubspotContactList" */
export type HubspotContactListJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact = {
  __typename?: 'hubspotContactListHubspotContact';
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  hubspotContactListId: Scalars['uuid'];
  hubspotId: Scalars['String'];
  id: Scalars['uuid'];
  internalListId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Aggregate = {
  __typename?: 'hubspotContactListHubspotContact_aggregate';
  aggregate?: Maybe<HubspotContactListHubspotContact_Aggregate_Fields>;
  nodes: Array<HubspotContactListHubspotContact>;
};

/** aggregate fields of "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Aggregate_Fields = {
  __typename?: 'hubspotContactListHubspotContact_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<HubspotContactListHubspotContact_Max_Fields>;
  min?: Maybe<HubspotContactListHubspotContact_Min_Fields>;
};

/** aggregate fields of "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hubspotContactListHubspotContact". All fields are combined with a logical 'AND'. */
export type HubspotContactListHubspotContact_Bool_Exp = {
  _and?: InputMaybe<Array<HubspotContactListHubspotContact_Bool_Exp>>;
  _not?: InputMaybe<HubspotContactListHubspotContact_Bool_Exp>;
  _or?: InputMaybe<Array<HubspotContactListHubspotContact_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hubspotContactListId?: InputMaybe<Uuid_Comparison_Exp>;
  hubspotId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internalListId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hubspotContactListHubspotContact" */
export enum HubspotContactListHubspotContact_Constraint {
  /** unique or primary key constraint on columns "clientId", "hubspotContactListId", "hubspotId" */
  HubspotContactListHubspotContactClientIdHubspotContactListId = 'hubspotContactListHubspotContact_clientId_hubspotContactListId_',
  /** unique or primary key constraint on columns "id" */
  HubspotContactListHubspotContactPkey = 'hubspotContactListHubspotContact_pkey',
}

/** input type for inserting data into table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hubspotContactListId?: InputMaybe<Scalars['uuid']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalListId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type HubspotContactListHubspotContact_Max_Fields = {
  __typename?: 'hubspotContactListHubspotContact_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotContactListId?: Maybe<Scalars['uuid']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type HubspotContactListHubspotContact_Min_Fields = {
  __typename?: 'hubspotContactListHubspotContact_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotContactListId?: Maybe<Scalars['uuid']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Mutation_Response = {
  __typename?: 'hubspotContactListHubspotContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HubspotContactListHubspotContact>;
};

/** on_conflict condition type for table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_On_Conflict = {
  constraint: HubspotContactListHubspotContact_Constraint;
  update_columns?: Array<HubspotContactListHubspotContact_Update_Column>;
  where?: InputMaybe<HubspotContactListHubspotContact_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspotContactListHubspotContact". */
export type HubspotContactListHubspotContact_Order_By = {
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  hubspotContactListId?: InputMaybe<Order_By>;
  hubspotId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalListId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspotContactListHubspotContact */
export type HubspotContactListHubspotContact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hubspotContactListHubspotContact" */
export enum HubspotContactListHubspotContact_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HubspotContactListId = 'hubspotContactListId',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalListId = 'internalListId',
}

/** input type for updating data in table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hubspotContactListId?: InputMaybe<Scalars['uuid']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalListId?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HubspotContactListHubspotContact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HubspotContactListHubspotContact_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hubspotContactListId?: InputMaybe<Scalars['uuid']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalListId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "hubspotContactListHubspotContact" */
export enum HubspotContactListHubspotContact_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HubspotContactListId = 'hubspotContactListId',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalListId = 'internalListId',
}

export type HubspotContactListHubspotContact_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HubspotContactListHubspotContact_Set_Input>;
  /** filter the rows which have to be updated */
  where: HubspotContactListHubspotContact_Bool_Exp;
};

/** aggregated selection of "hubspotContactList" */
export type HubspotContactList_Aggregate = {
  __typename?: 'hubspotContactList_aggregate';
  aggregate?: Maybe<HubspotContactList_Aggregate_Fields>;
  nodes: Array<HubspotContactList>;
};

export type HubspotContactList_Aggregate_Bool_Exp = {
  count?: InputMaybe<HubspotContactList_Aggregate_Bool_Exp_Count>;
};

export type HubspotContactList_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<HubspotContactList_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<HubspotContactList_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hubspotContactList" */
export type HubspotContactList_Aggregate_Fields = {
  __typename?: 'hubspotContactList_aggregate_fields';
  avg?: Maybe<HubspotContactList_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<HubspotContactList_Max_Fields>;
  min?: Maybe<HubspotContactList_Min_Fields>;
  stddev?: Maybe<HubspotContactList_Stddev_Fields>;
  stddev_pop?: Maybe<HubspotContactList_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HubspotContactList_Stddev_Samp_Fields>;
  sum?: Maybe<HubspotContactList_Sum_Fields>;
  var_pop?: Maybe<HubspotContactList_Var_Pop_Fields>;
  var_samp?: Maybe<HubspotContactList_Var_Samp_Fields>;
  variance?: Maybe<HubspotContactList_Variance_Fields>;
};

/** aggregate fields of "hubspotContactList" */
export type HubspotContactList_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HubspotContactList_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hubspotContactList" */
export type HubspotContactList_Aggregate_Order_By = {
  avg?: InputMaybe<HubspotContactList_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<HubspotContactList_Max_Order_By>;
  min?: InputMaybe<HubspotContactList_Min_Order_By>;
  stddev?: InputMaybe<HubspotContactList_Stddev_Order_By>;
  stddev_pop?: InputMaybe<HubspotContactList_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<HubspotContactList_Stddev_Samp_Order_By>;
  sum?: InputMaybe<HubspotContactList_Sum_Order_By>;
  var_pop?: InputMaybe<HubspotContactList_Var_Pop_Order_By>;
  var_samp?: InputMaybe<HubspotContactList_Var_Samp_Order_By>;
  variance?: InputMaybe<HubspotContactList_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HubspotContactList_Append_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hubspotContactList" */
export type HubspotContactList_Arr_Rel_Insert_Input = {
  data: Array<HubspotContactList_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<HubspotContactList_On_Conflict>;
};

/** aggregate avg on columns */
export type HubspotContactList_Avg_Fields = {
  __typename?: 'hubspotContactList_avg_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hubspotContactList" */
export type HubspotContactList_Avg_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hubspotContactList". All fields are combined with a logical 'AND'. */
export type HubspotContactList_Bool_Exp = {
  _and?: InputMaybe<Array<HubspotContactList_Bool_Exp>>;
  _not?: InputMaybe<HubspotContactList_Bool_Exp>;
  _or?: InputMaybe<Array<HubspotContactList_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  contactCount?: InputMaybe<Int_Comparison_Exp>;
  hubspotIntegrationId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internalListId?: InputMaybe<String_Comparison_Exp>;
  json?: InputMaybe<Jsonb_Comparison_Exp>;
  lastSyncedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  listId?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hubspotContactList" */
export enum HubspotContactList_Constraint {
  /** unique or primary key constraint on columns "hubspotIntegrationId", "listId" */
  HubspotContactListHubspotIntegrationIdListIdKey = 'hubspotContactList_hubspotIntegrationId_listId_key',
  /** unique or primary key constraint on columns "id" */
  HubspotContactListPkey = 'hubspotContactList_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HubspotContactList_Delete_At_Path_Input = {
  json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HubspotContactList_Delete_Elem_Input = {
  json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HubspotContactList_Delete_Key_Input = {
  json?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hubspotContactList" */
export type HubspotContactList_Inc_Input = {
  contactCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hubspotContactList" */
export type HubspotContactList_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactCount?: InputMaybe<Scalars['Int']>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalListId?: InputMaybe<Scalars['String']>;
  json?: InputMaybe<Scalars['jsonb']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  listId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type HubspotContactList_Max_Fields = {
  __typename?: 'hubspotContactList_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hubspotContactList" */
export type HubspotContactList_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactCount?: InputMaybe<Order_By>;
  hubspotIntegrationId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalListId?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type HubspotContactList_Min_Fields = {
  __typename?: 'hubspotContactList_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hubspotContactList" */
export type HubspotContactList_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactCount?: InputMaybe<Order_By>;
  hubspotIntegrationId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalListId?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hubspotContactList" */
export type HubspotContactList_Mutation_Response = {
  __typename?: 'hubspotContactList_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HubspotContactList>;
};

/** on_conflict condition type for table "hubspotContactList" */
export type HubspotContactList_On_Conflict = {
  constraint: HubspotContactList_Constraint;
  update_columns?: Array<HubspotContactList_Update_Column>;
  where?: InputMaybe<HubspotContactList_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspotContactList". */
export type HubspotContactList_Order_By = {
  clientId?: InputMaybe<Order_By>;
  contactCount?: InputMaybe<Order_By>;
  hubspotIntegrationId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalListId?: InputMaybe<Order_By>;
  json?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspotContactList */
export type HubspotContactList_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HubspotContactList_Prepend_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hubspotContactList" */
export enum HubspotContactList_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactCount = 'contactCount',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalListId = 'internalListId',
  /** column name */
  Json = 'json',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  ListId = 'listId',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "hubspotContactList" */
export type HubspotContactList_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactCount?: InputMaybe<Scalars['Int']>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalListId?: InputMaybe<Scalars['String']>;
  json?: InputMaybe<Scalars['jsonb']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  listId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type HubspotContactList_Stddev_Fields = {
  __typename?: 'hubspotContactList_stddev_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hubspotContactList" */
export type HubspotContactList_Stddev_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type HubspotContactList_Stddev_Pop_Fields = {
  __typename?: 'hubspotContactList_stddev_pop_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hubspotContactList" */
export type HubspotContactList_Stddev_Pop_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type HubspotContactList_Stddev_Samp_Fields = {
  __typename?: 'hubspotContactList_stddev_samp_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hubspotContactList" */
export type HubspotContactList_Stddev_Samp_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "hubspotContactList" */
export type HubspotContactList_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HubspotContactList_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HubspotContactList_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  contactCount?: InputMaybe<Scalars['Int']>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalListId?: InputMaybe<Scalars['String']>;
  json?: InputMaybe<Scalars['jsonb']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  listId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type HubspotContactList_Sum_Fields = {
  __typename?: 'hubspotContactList_sum_fields';
  contactCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hubspotContactList" */
export type HubspotContactList_Sum_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** update columns of table "hubspotContactList" */
export enum HubspotContactList_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactCount = 'contactCount',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalListId = 'internalListId',
  /** column name */
  Json = 'json',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  ListId = 'listId',
  /** column name */
  Name = 'name',
}

export type HubspotContactList_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<HubspotContactList_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<HubspotContactList_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<HubspotContactList_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<HubspotContactList_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<HubspotContactList_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<HubspotContactList_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HubspotContactList_Set_Input>;
  /** filter the rows which have to be updated */
  where: HubspotContactList_Bool_Exp;
};

/** aggregate var_pop on columns */
export type HubspotContactList_Var_Pop_Fields = {
  __typename?: 'hubspotContactList_var_pop_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hubspotContactList" */
export type HubspotContactList_Var_Pop_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type HubspotContactList_Var_Samp_Fields = {
  __typename?: 'hubspotContactList_var_samp_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hubspotContactList" */
export type HubspotContactList_Var_Samp_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type HubspotContactList_Variance_Fields = {
  __typename?: 'hubspotContactList_variance_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hubspotContactList" */
export type HubspotContactList_Variance_Order_By = {
  contactCount?: InputMaybe<Order_By>;
};

/** columns and relationships of "hubspotContactProperty" */
export type HubspotContactProperty = {
  __typename?: 'hubspotContactProperty';
  clientId: Scalars['uuid'];
  hubspotIntegrationId: Scalars['uuid'];
  id: Scalars['uuid'];
  json?: Maybe<Scalars['jsonb']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
};

/** columns and relationships of "hubspotContactProperty" */
export type HubspotContactPropertyJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hubspotContactProperty" */
export type HubspotContactProperty_Aggregate = {
  __typename?: 'hubspotContactProperty_aggregate';
  aggregate?: Maybe<HubspotContactProperty_Aggregate_Fields>;
  nodes: Array<HubspotContactProperty>;
};

export type HubspotContactProperty_Aggregate_Bool_Exp = {
  count?: InputMaybe<HubspotContactProperty_Aggregate_Bool_Exp_Count>;
};

export type HubspotContactProperty_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<HubspotContactProperty_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<HubspotContactProperty_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hubspotContactProperty" */
export type HubspotContactProperty_Aggregate_Fields = {
  __typename?: 'hubspotContactProperty_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<HubspotContactProperty_Max_Fields>;
  min?: Maybe<HubspotContactProperty_Min_Fields>;
};

/** aggregate fields of "hubspotContactProperty" */
export type HubspotContactProperty_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HubspotContactProperty_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hubspotContactProperty" */
export type HubspotContactProperty_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<HubspotContactProperty_Max_Order_By>;
  min?: InputMaybe<HubspotContactProperty_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HubspotContactProperty_Append_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hubspotContactProperty" */
export type HubspotContactProperty_Arr_Rel_Insert_Input = {
  data: Array<HubspotContactProperty_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<HubspotContactProperty_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspotContactProperty". All fields are combined with a logical 'AND'. */
export type HubspotContactProperty_Bool_Exp = {
  _and?: InputMaybe<Array<HubspotContactProperty_Bool_Exp>>;
  _not?: InputMaybe<HubspotContactProperty_Bool_Exp>;
  _or?: InputMaybe<Array<HubspotContactProperty_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  hubspotIntegrationId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  json?: InputMaybe<Jsonb_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  lastSyncedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hubspotContactProperty" */
export enum HubspotContactProperty_Constraint {
  /** unique or primary key constraint on columns "hubspotIntegrationId", "name" */
  HubspotContactPropertyHubspotIntegrationIdNameKey = 'hubspotContactProperty_hubspotIntegrationId_name_key',
  /** unique or primary key constraint on columns "id" */
  HubspotContactPropertyPkey = 'hubspotContactProperty_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HubspotContactProperty_Delete_At_Path_Input = {
  json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HubspotContactProperty_Delete_Elem_Input = {
  json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HubspotContactProperty_Delete_Key_Input = {
  json?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "hubspotContactProperty" */
export type HubspotContactProperty_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  label?: InputMaybe<Scalars['String']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type HubspotContactProperty_Max_Fields = {
  __typename?: 'hubspotContactProperty_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hubspotContactProperty" */
export type HubspotContactProperty_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  hubspotIntegrationId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type HubspotContactProperty_Min_Fields = {
  __typename?: 'hubspotContactProperty_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hubspotContactProperty" */
export type HubspotContactProperty_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  hubspotIntegrationId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hubspotContactProperty" */
export type HubspotContactProperty_Mutation_Response = {
  __typename?: 'hubspotContactProperty_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HubspotContactProperty>;
};

/** on_conflict condition type for table "hubspotContactProperty" */
export type HubspotContactProperty_On_Conflict = {
  constraint: HubspotContactProperty_Constraint;
  update_columns?: Array<HubspotContactProperty_Update_Column>;
  where?: InputMaybe<HubspotContactProperty_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspotContactProperty". */
export type HubspotContactProperty_Order_By = {
  clientId?: InputMaybe<Order_By>;
  hubspotIntegrationId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  json?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspotContactProperty */
export type HubspotContactProperty_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HubspotContactProperty_Prepend_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hubspotContactProperty" */
export enum HubspotContactProperty_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Label = 'label',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "hubspotContactProperty" */
export type HubspotContactProperty_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  label?: InputMaybe<Scalars['String']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "hubspotContactProperty" */
export type HubspotContactProperty_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HubspotContactProperty_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HubspotContactProperty_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  hubspotIntegrationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  label?: InputMaybe<Scalars['String']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "hubspotContactProperty" */
export enum HubspotContactProperty_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Label = 'label',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  Name = 'name',
}

export type HubspotContactProperty_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<HubspotContactProperty_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<HubspotContactProperty_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<HubspotContactProperty_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<HubspotContactProperty_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<HubspotContactProperty_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HubspotContactProperty_Set_Input>;
  /** filter the rows which have to be updated */
  where: HubspotContactProperty_Bool_Exp;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegration = {
  __typename?: 'hubspotIntegration';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpireAt?: Maybe<Scalars['timestamptz']>;
  clientId: Scalars['uuid'];
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  hubspotContactLists: Array<HubspotContactList>;
  /** An aggregate relationship */
  hubspotContactLists_aggregate: HubspotContactList_Aggregate;
  /** An array relationship */
  hubspotContactProperties: Array<HubspotContactProperty>;
  /** An aggregate relationship */
  hubspotContactProperties_aggregate: HubspotContactProperty_Aggregate;
  hubspotId?: Maybe<Scalars['String']>;
  hubspotUserEmail?: Maybe<Scalars['String']>;
  hubspotUserId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  mapping?: Maybe<Scalars['jsonb']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  warmlyContactPropertiesCreatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationHubspotContactListsArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactList_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactList_Order_By>>;
  where?: InputMaybe<HubspotContactList_Bool_Exp>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationHubspotContactLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactList_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactList_Order_By>>;
  where?: InputMaybe<HubspotContactList_Bool_Exp>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationHubspotContactPropertiesArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactProperty_Order_By>>;
  where?: InputMaybe<HubspotContactProperty_Bool_Exp>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationHubspotContactProperties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactProperty_Order_By>>;
  where?: InputMaybe<HubspotContactProperty_Bool_Exp>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationMappingArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hubspotIntegration" */
export type HubspotIntegration_Aggregate = {
  __typename?: 'hubspotIntegration_aggregate';
  aggregate?: Maybe<HubspotIntegration_Aggregate_Fields>;
  nodes: Array<HubspotIntegration>;
};

/** aggregate fields of "hubspotIntegration" */
export type HubspotIntegration_Aggregate_Fields = {
  __typename?: 'hubspotIntegration_aggregate_fields';
  avg?: Maybe<HubspotIntegration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<HubspotIntegration_Max_Fields>;
  min?: Maybe<HubspotIntegration_Min_Fields>;
  stddev?: Maybe<HubspotIntegration_Stddev_Fields>;
  stddev_pop?: Maybe<HubspotIntegration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HubspotIntegration_Stddev_Samp_Fields>;
  sum?: Maybe<HubspotIntegration_Sum_Fields>;
  var_pop?: Maybe<HubspotIntegration_Var_Pop_Fields>;
  var_samp?: Maybe<HubspotIntegration_Var_Samp_Fields>;
  variance?: Maybe<HubspotIntegration_Variance_Fields>;
};

/** aggregate fields of "hubspotIntegration" */
export type HubspotIntegration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<HubspotIntegration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HubspotIntegration_Append_Input = {
  mapping?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type HubspotIntegration_Avg_Fields = {
  __typename?: 'hubspotIntegration_avg_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "hubspotIntegration". All fields are combined with a logical 'AND'. */
export type HubspotIntegration_Bool_Exp = {
  _and?: InputMaybe<Array<HubspotIntegration_Bool_Exp>>;
  _not?: InputMaybe<HubspotIntegration_Bool_Exp>;
  _or?: InputMaybe<Array<HubspotIntegration_Bool_Exp>>;
  accessToken?: InputMaybe<String_Comparison_Exp>;
  accessTokenExpireAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  contactsLastSyncedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  contactsLastSyncedCount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hubspotContactLists?: InputMaybe<HubspotContactList_Bool_Exp>;
  hubspotContactLists_aggregate?: InputMaybe<HubspotContactList_Aggregate_Bool_Exp>;
  hubspotContactProperties?: InputMaybe<HubspotContactProperty_Bool_Exp>;
  hubspotContactProperties_aggregate?: InputMaybe<HubspotContactProperty_Aggregate_Bool_Exp>;
  hubspotId?: InputMaybe<String_Comparison_Exp>;
  hubspotUserEmail?: InputMaybe<String_Comparison_Exp>;
  hubspotUserId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mapping?: InputMaybe<Jsonb_Comparison_Exp>;
  refreshToken?: InputMaybe<String_Comparison_Exp>;
  totalContactCount?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  warmlyContactPropertiesCreatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hubspotIntegration" */
export enum HubspotIntegration_Constraint {
  /** unique or primary key constraint on columns "clientId" */
  HubspotIntegrationClientIdKey = 'hubspotIntegration_clientId_key',
  /** unique or primary key constraint on columns "id" */
  HubspotIntegrationPkey = 'hubspotIntegration_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HubspotIntegration_Delete_At_Path_Input = {
  mapping?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HubspotIntegration_Delete_Elem_Input = {
  mapping?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HubspotIntegration_Delete_Key_Input = {
  mapping?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hubspotIntegration" */
export type HubspotIntegration_Inc_Input = {
  contactsLastSyncedCount?: InputMaybe<Scalars['Int']>;
  totalContactCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hubspotIntegration" */
export type HubspotIntegration_Insert_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  accessTokenExpireAt?: InputMaybe<Scalars['timestamptz']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  contactsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hubspotContactLists?: InputMaybe<HubspotContactList_Arr_Rel_Insert_Input>;
  hubspotContactProperties?: InputMaybe<HubspotContactProperty_Arr_Rel_Insert_Input>;
  hubspotId?: InputMaybe<Scalars['String']>;
  hubspotUserEmail?: InputMaybe<Scalars['String']>;
  hubspotUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  totalContactCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type HubspotIntegration_Max_Fields = {
  __typename?: 'hubspotIntegration_max_fields';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpireAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotId?: Maybe<Scalars['String']>;
  hubspotUserEmail?: Maybe<Scalars['String']>;
  hubspotUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type HubspotIntegration_Min_Fields = {
  __typename?: 'hubspotIntegration_min_fields';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpireAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotId?: Maybe<Scalars['String']>;
  hubspotUserEmail?: Maybe<Scalars['String']>;
  hubspotUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "hubspotIntegration" */
export type HubspotIntegration_Mutation_Response = {
  __typename?: 'hubspotIntegration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HubspotIntegration>;
};

/** input type for inserting object relation for remote table "hubspotIntegration" */
export type HubspotIntegration_Obj_Rel_Insert_Input = {
  data: HubspotIntegration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<HubspotIntegration_On_Conflict>;
};

/** on_conflict condition type for table "hubspotIntegration" */
export type HubspotIntegration_On_Conflict = {
  constraint: HubspotIntegration_Constraint;
  update_columns?: Array<HubspotIntegration_Update_Column>;
  where?: InputMaybe<HubspotIntegration_Bool_Exp>;
};

/** Ordering options when selecting data from "hubspotIntegration". */
export type HubspotIntegration_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  accessTokenExpireAt?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  contactsLastSyncedAt?: InputMaybe<Order_By>;
  contactsLastSyncedCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  hubspotContactLists_aggregate?: InputMaybe<HubspotContactList_Aggregate_Order_By>;
  hubspotContactProperties_aggregate?: InputMaybe<HubspotContactProperty_Aggregate_Order_By>;
  hubspotId?: InputMaybe<Order_By>;
  hubspotUserEmail?: InputMaybe<Order_By>;
  hubspotUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mapping?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  totalContactCount?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  warmlyContactPropertiesCreatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hubspotIntegration */
export type HubspotIntegration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HubspotIntegration_Prepend_Input = {
  mapping?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hubspotIntegration" */
export enum HubspotIntegration_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccessTokenExpireAt = 'accessTokenExpireAt',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactsLastSyncedAt = 'contactsLastSyncedAt',
  /** column name */
  ContactsLastSyncedCount = 'contactsLastSyncedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  HubspotUserEmail = 'hubspotUserEmail',
  /** column name */
  HubspotUserId = 'hubspotUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  TotalContactCount = 'totalContactCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarmlyContactPropertiesCreatedAt = 'warmlyContactPropertiesCreatedAt',
}

/** input type for updating data in table "hubspotIntegration" */
export type HubspotIntegration_Set_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  accessTokenExpireAt?: InputMaybe<Scalars['timestamptz']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  contactsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  hubspotUserEmail?: InputMaybe<Scalars['String']>;
  hubspotUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  totalContactCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type HubspotIntegration_Stddev_Fields = {
  __typename?: 'hubspotIntegration_stddev_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type HubspotIntegration_Stddev_Pop_Fields = {
  __typename?: 'hubspotIntegration_stddev_pop_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type HubspotIntegration_Stddev_Samp_Fields = {
  __typename?: 'hubspotIntegration_stddev_samp_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hubspotIntegration" */
export type HubspotIntegration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: HubspotIntegration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type HubspotIntegration_Stream_Cursor_Value_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  accessTokenExpireAt?: InputMaybe<Scalars['timestamptz']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  contactsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  hubspotUserEmail?: InputMaybe<Scalars['String']>;
  hubspotUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  totalContactCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type HubspotIntegration_Sum_Fields = {
  __typename?: 'hubspotIntegration_sum_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  totalContactCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "hubspotIntegration" */
export enum HubspotIntegration_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccessTokenExpireAt = 'accessTokenExpireAt',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactsLastSyncedAt = 'contactsLastSyncedAt',
  /** column name */
  ContactsLastSyncedCount = 'contactsLastSyncedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  HubspotUserEmail = 'hubspotUserEmail',
  /** column name */
  HubspotUserId = 'hubspotUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  TotalContactCount = 'totalContactCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarmlyContactPropertiesCreatedAt = 'warmlyContactPropertiesCreatedAt',
}

export type HubspotIntegration_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<HubspotIntegration_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<HubspotIntegration_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<HubspotIntegration_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<HubspotIntegration_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<HubspotIntegration_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<HubspotIntegration_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HubspotIntegration_Set_Input>;
  /** filter the rows which have to be updated */
  where: HubspotIntegration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type HubspotIntegration_Var_Pop_Fields = {
  __typename?: 'hubspotIntegration_var_pop_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type HubspotIntegration_Var_Samp_Fields = {
  __typename?: 'hubspotIntegration_var_samp_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type HubspotIntegration_Variance_Fields = {
  __typename?: 'hubspotIntegration_variance_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "job" */
export type Job = {
  __typename?: 'job';
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['uuid']>;
  companyName?: Maybe<Scalars['String']>;
  /** An array relationship */
  contactData: Array<ContactData>;
  contactDataId: Scalars['uuid'];
  /** An aggregate relationship */
  contactData_aggregate: ContactData_Aggregate;
  createdAt: Scalars['timestamptz'];
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endDateText?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  startDate?: Maybe<Scalars['date']>;
  startDateText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** columns and relationships of "job" */
export type JobContactDataArgs = {
  distinct_on?: InputMaybe<Array<ContactData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactData_Order_By>>;
  where?: InputMaybe<ContactData_Bool_Exp>;
};

/** columns and relationships of "job" */
export type JobContactData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactData_Order_By>>;
  where?: InputMaybe<ContactData_Bool_Exp>;
};

/** aggregated selection of "job" */
export type Job_Aggregate = {
  __typename?: 'job_aggregate';
  aggregate?: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
};

export type Job_Aggregate_Bool_Exp = {
  count?: InputMaybe<Job_Aggregate_Bool_Exp_Count>;
};

export type Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "job" */
export type Job_Aggregate_Fields = {
  __typename?: 'job_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Job_Max_Fields>;
  min?: Maybe<Job_Min_Fields>;
};

/** aggregate fields of "job" */
export type Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job" */
export type Job_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Max_Order_By>;
  min?: InputMaybe<Job_Min_Order_By>;
};

/** input type for inserting array relation for remote table "job" */
export type Job_Arr_Rel_Insert_Input = {
  data: Array<Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type Job_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Bool_Exp>>;
  _not?: InputMaybe<Job_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  companyName?: InputMaybe<String_Comparison_Exp>;
  contactData?: InputMaybe<ContactData_Bool_Exp>;
  contactDataId?: InputMaybe<Uuid_Comparison_Exp>;
  contactData_aggregate?: InputMaybe<ContactData_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<String_Comparison_Exp>;
  endDate?: InputMaybe<Date_Comparison_Exp>;
  endDateText?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  startDate?: InputMaybe<Date_Comparison_Exp>;
  startDateText?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job" */
export enum Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobPkey = 'job_pkey',
}

/** input type for inserting data into table "job" */
export type Job_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['uuid']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactData?: InputMaybe<ContactData_Arr_Rel_Insert_Input>;
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['date']>;
  endDateText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['date']>;
  startDateText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Max_Fields = {
  __typename?: 'job_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  companyName?: Maybe<Scalars['String']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endDateText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  startDateText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job" */
export type Job_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  endDateText?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  startDateText?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Min_Fields = {
  __typename?: 'job_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  companyName?: Maybe<Scalars['String']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endDateText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  startDateText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job" */
export type Job_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  endDateText?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  startDateText?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job" */
export type Job_Mutation_Response = {
  __typename?: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job>;
};

/** input type for inserting object relation for remote table "job" */
export type Job_Obj_Rel_Insert_Input = {
  data: Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** on_conflict condition type for table "job" */
export type Job_On_Conflict = {
  constraint: Job_Constraint;
  update_columns?: Array<Job_Update_Column>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** Ordering options when selecting data from "job". */
export type Job_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  contactDataId?: InputMaybe<Order_By>;
  contactData_aggregate?: InputMaybe<ContactData_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  endDateText?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  startDateText?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job */
export type Job_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "job" */
export enum Job_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EndDateText = 'endDateText',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartDateText = 'startDateText',
  /** column name */
  Title = 'title',
}

/** input type for updating data in table "job" */
export type Job_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['date']>;
  endDateText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['date']>;
  startDateText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "job" */
export type Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Job_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['date']>;
  endDateText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['date']>;
  startDateText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "job" */
export enum Job_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EndDateText = 'endDateText',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartDateText = 'startDateText',
  /** column name */
  Title = 'title',
}

export type Job_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Job_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "microsoftSubscription" */
export type MicrosoftSubscription = {
  __typename?: 'microsoftSubscription';
  exp: Scalars['timestamptz'];
  id: Scalars['uuid'];
  notificationUrl: Scalars['String'];
  zoomUserId: Scalars['uuid'];
};

/** aggregated selection of "microsoftSubscription" */
export type MicrosoftSubscription_Aggregate = {
  __typename?: 'microsoftSubscription_aggregate';
  aggregate?: Maybe<MicrosoftSubscription_Aggregate_Fields>;
  nodes: Array<MicrosoftSubscription>;
};

/** aggregate fields of "microsoftSubscription" */
export type MicrosoftSubscription_Aggregate_Fields = {
  __typename?: 'microsoftSubscription_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MicrosoftSubscription_Max_Fields>;
  min?: Maybe<MicrosoftSubscription_Min_Fields>;
};

/** aggregate fields of "microsoftSubscription" */
export type MicrosoftSubscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MicrosoftSubscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "microsoftSubscription". All fields are combined with a logical 'AND'. */
export type MicrosoftSubscription_Bool_Exp = {
  _and?: InputMaybe<Array<MicrosoftSubscription_Bool_Exp>>;
  _not?: InputMaybe<MicrosoftSubscription_Bool_Exp>;
  _or?: InputMaybe<Array<MicrosoftSubscription_Bool_Exp>>;
  exp?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notificationUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "microsoftSubscription" */
export enum MicrosoftSubscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  MicrosoftSubscriptionPkey = 'microsoftSubscription_pkey',
}

/** input type for inserting data into table "microsoftSubscription" */
export type MicrosoftSubscription_Insert_Input = {
  exp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  notificationUrl?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MicrosoftSubscription_Max_Fields = {
  __typename?: 'microsoftSubscription_max_fields';
  exp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  notificationUrl?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MicrosoftSubscription_Min_Fields = {
  __typename?: 'microsoftSubscription_min_fields';
  exp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  notificationUrl?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "microsoftSubscription" */
export type MicrosoftSubscription_Mutation_Response = {
  __typename?: 'microsoftSubscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MicrosoftSubscription>;
};

/** on_conflict condition type for table "microsoftSubscription" */
export type MicrosoftSubscription_On_Conflict = {
  constraint: MicrosoftSubscription_Constraint;
  update_columns?: Array<MicrosoftSubscription_Update_Column>;
  where?: InputMaybe<MicrosoftSubscription_Bool_Exp>;
};

/** Ordering options when selecting data from "microsoftSubscription". */
export type MicrosoftSubscription_Order_By = {
  exp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notificationUrl?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: microsoftSubscription */
export type MicrosoftSubscription_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "microsoftSubscription" */
export enum MicrosoftSubscription_Select_Column {
  /** column name */
  Exp = 'exp',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationUrl = 'notificationUrl',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "microsoftSubscription" */
export type MicrosoftSubscription_Set_Input = {
  exp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  notificationUrl?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "microsoftSubscription" */
export type MicrosoftSubscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MicrosoftSubscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MicrosoftSubscription_Stream_Cursor_Value_Input = {
  exp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  notificationUrl?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "microsoftSubscription" */
export enum MicrosoftSubscription_Update_Column {
  /** column name */
  Exp = 'exp',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationUrl = 'notificationUrl',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type MicrosoftSubscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MicrosoftSubscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: MicrosoftSubscription_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  assignToOrganization: Scalars['String'];
  backfillEncodedIds: EncodedIdBackfillResult;
  backfillOrgMembershipAssociations: Scalars['String'];
  backfillUsernames: UsernameBackfillResult;
  clearDeepLinkAction: Scalars['Boolean'];
  connectMicrosoftCalendars: Array<ConnectCalendarResults>;
  createExport: Scalars['String'];
  createStripeSession: StripeSession;
  createStripeSubscription: StripeSubscription;
  deleteSelf?: Maybe<Scalars['String']>;
  deleteUsersForOrg: Scalars['Boolean'];
  /** delete data from the table: "client" */
  delete_client?: Maybe<Client_Mutation_Response>;
  /** delete data from the table: "clientContact" */
  delete_clientContact?: Maybe<ClientContact_Mutation_Response>;
  /** delete data from the table: "clientContactCrmData" */
  delete_clientContactCrmData?: Maybe<ClientContactCrmData_Mutation_Response>;
  /** delete single row from the table: "clientContactCrmData" */
  delete_clientContactCrmData_by_pk?: Maybe<ClientContactCrmData>;
  /** delete data from the table: "clientContactFilter" */
  delete_clientContactFilter?: Maybe<ClientContactFilter_Mutation_Response>;
  /** delete single row from the table: "clientContactFilter" */
  delete_clientContactFilter_by_pk?: Maybe<ClientContactFilter>;
  /** delete data from the table: "clientContactJobChange" */
  delete_clientContactJobChange?: Maybe<ClientContactJobChange_Mutation_Response>;
  /** delete single row from the table: "clientContactJobChange" */
  delete_clientContactJobChange_by_pk?: Maybe<ClientContactJobChange>;
  /** delete single row from the table: "clientContact" */
  delete_clientContact_by_pk?: Maybe<ClientContact>;
  /** delete data from the table: "clientEmailReport" */
  delete_clientEmailReport?: Maybe<ClientEmailReport_Mutation_Response>;
  /** delete single row from the table: "clientEmailReport" */
  delete_clientEmailReport_by_pk?: Maybe<ClientEmailReport>;
  /** delete data from the table: "clientNotificationEmail" */
  delete_clientNotificationEmail?: Maybe<ClientNotificationEmail_Mutation_Response>;
  /** delete single row from the table: "clientNotificationEmail" */
  delete_clientNotificationEmail_by_pk?: Maybe<ClientNotificationEmail>;
  /** delete data from the table: "clientTerritory" */
  delete_clientTerritory?: Maybe<ClientTerritory_Mutation_Response>;
  /** delete single row from the table: "clientTerritory" */
  delete_clientTerritory_by_pk?: Maybe<ClientTerritory>;
  /** delete single row from the table: "client" */
  delete_client_by_pk?: Maybe<Client>;
  /** delete data from the table: "cloudTask" */
  delete_cloudTask?: Maybe<CloudTask_Mutation_Response>;
  /** delete single row from the table: "cloudTask" */
  delete_cloudTask_by_pk?: Maybe<CloudTask>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete data from the table: "companyName" */
  delete_companyName?: Maybe<CompanyName_Mutation_Response>;
  /** delete single row from the table: "companyName" */
  delete_companyName_by_pk?: Maybe<CompanyName>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "contactData" */
  delete_contactData?: Maybe<ContactData_Mutation_Response>;
  /** delete single row from the table: "contactData" */
  delete_contactData_by_pk?: Maybe<ContactData>;
  /** delete data from the table: "contactEmail" */
  delete_contactEmail?: Maybe<ContactEmail_Mutation_Response>;
  /** delete single row from the table: "contactEmail" */
  delete_contactEmail_by_pk?: Maybe<ContactEmail>;
  /** delete data from the table: "content" */
  delete_content?: Maybe<Content_Mutation_Response>;
  /** delete single row from the table: "content" */
  delete_content_by_pk?: Maybe<Content>;
  /** delete data from the table: "crmContactEnrichmentHistory" */
  delete_crmContactEnrichmentHistory?: Maybe<CrmContactEnrichmentHistory_Mutation_Response>;
  /** delete single row from the table: "crmContactEnrichmentHistory" */
  delete_crmContactEnrichmentHistory_by_pk?: Maybe<CrmContactEnrichmentHistory>;
  /** delete data from the table: "csvUpload" */
  delete_csvUpload?: Maybe<CsvUpload_Mutation_Response>;
  /** delete single row from the table: "csvUpload" */
  delete_csvUpload_by_pk?: Maybe<CsvUpload>;
  /** delete data from the table: "deleteOldZoomEventsJob" */
  delete_deleteOldZoomEventsJob?: Maybe<DeleteOldZoomEventsJob_Mutation_Response>;
  /** delete single row from the table: "deleteOldZoomEventsJob" */
  delete_deleteOldZoomEventsJob_by_pk?: Maybe<DeleteOldZoomEventsJob>;
  /** delete data from the table: "emailHistory" */
  delete_emailHistory?: Maybe<EmailHistory_Mutation_Response>;
  /** delete single row from the table: "emailHistory" */
  delete_emailHistory_by_pk?: Maybe<EmailHistory>;
  /** delete data from the table: "googleChannel" */
  delete_googleChannel?: Maybe<GoogleChannel_Mutation_Response>;
  /** delete single row from the table: "googleChannel" */
  delete_googleChannel_by_pk?: Maybe<GoogleChannel>;
  /** delete data from the table: "growthIntraCompanyAdoptionContact" */
  delete_growthIntraCompanyAdoptionContact?: Maybe<GrowthIntraCompanyAdoptionContact_Mutation_Response>;
  /** delete single row from the table: "growthIntraCompanyAdoptionContact" */
  delete_growthIntraCompanyAdoptionContact_by_pk?: Maybe<GrowthIntraCompanyAdoptionContact>;
  /** delete data from the table: "growthUserReferralContact" */
  delete_growthUserReferralContact?: Maybe<GrowthUserReferralContact_Mutation_Response>;
  /** delete single row from the table: "growthUserReferralContact" */
  delete_growthUserReferralContact_by_pk?: Maybe<GrowthUserReferralContact>;
  /** delete data from the table: "hubspotContactList" */
  delete_hubspotContactList?: Maybe<HubspotContactList_Mutation_Response>;
  /** delete data from the table: "hubspotContactListHubspotContact" */
  delete_hubspotContactListHubspotContact?: Maybe<HubspotContactListHubspotContact_Mutation_Response>;
  /** delete single row from the table: "hubspotContactListHubspotContact" */
  delete_hubspotContactListHubspotContact_by_pk?: Maybe<HubspotContactListHubspotContact>;
  /** delete single row from the table: "hubspotContactList" */
  delete_hubspotContactList_by_pk?: Maybe<HubspotContactList>;
  /** delete data from the table: "hubspotContactProperty" */
  delete_hubspotContactProperty?: Maybe<HubspotContactProperty_Mutation_Response>;
  /** delete single row from the table: "hubspotContactProperty" */
  delete_hubspotContactProperty_by_pk?: Maybe<HubspotContactProperty>;
  /** delete data from the table: "hubspotIntegration" */
  delete_hubspotIntegration?: Maybe<HubspotIntegration_Mutation_Response>;
  /** delete single row from the table: "hubspotIntegration" */
  delete_hubspotIntegration_by_pk?: Maybe<HubspotIntegration>;
  /** delete data from the table: "job" */
  delete_job?: Maybe<Job_Mutation_Response>;
  /** delete single row from the table: "job" */
  delete_job_by_pk?: Maybe<Job>;
  /** delete data from the table: "microsoftSubscription" */
  delete_microsoftSubscription?: Maybe<MicrosoftSubscription_Mutation_Response>;
  /** delete single row from the table: "microsoftSubscription" */
  delete_microsoftSubscription_by_pk?: Maybe<MicrosoftSubscription>;
  /** delete data from the table: "proxycrawlData" */
  delete_proxycrawlData?: Maybe<ProxycrawlData_Mutation_Response>;
  /** delete single row from the table: "proxycrawlData" */
  delete_proxycrawlData_by_pk?: Maybe<ProxycrawlData>;
  /** delete data from the table: "qaQualification" */
  delete_qaQualification?: Maybe<QaQualification_Mutation_Response>;
  /** delete single row from the table: "qaQualification" */
  delete_qaQualification_by_pk?: Maybe<QaQualification>;
  /** delete data from the table: "qaTask" */
  delete_qaTask?: Maybe<QaTask_Mutation_Response>;
  /** delete data from the table: "qaTaskAction" */
  delete_qaTaskAction?: Maybe<QaTaskAction_Mutation_Response>;
  /** delete single row from the table: "qaTaskAction" */
  delete_qaTaskAction_by_pk?: Maybe<QaTaskAction>;
  /** delete single row from the table: "qaTask" */
  delete_qaTask_by_pk?: Maybe<QaTask>;
  /** delete data from the table: "salesforceAccount" */
  delete_salesforceAccount?: Maybe<SalesforceAccount_Mutation_Response>;
  /** delete single row from the table: "salesforceAccount" */
  delete_salesforceAccount_by_pk?: Maybe<SalesforceAccount>;
  /** delete data from the table: "salesforceContactField" */
  delete_salesforceContactField?: Maybe<SalesforceContactField_Mutation_Response>;
  /** delete single row from the table: "salesforceContactField" */
  delete_salesforceContactField_by_pk?: Maybe<SalesforceContactField>;
  /** delete data from the table: "salesforceIntegration" */
  delete_salesforceIntegration?: Maybe<SalesforceIntegration_Mutation_Response>;
  /** delete single row from the table: "salesforceIntegration" */
  delete_salesforceIntegration_by_pk?: Maybe<SalesforceIntegration>;
  /** delete data from the table: "salesforceOpportunity" */
  delete_salesforceOpportunity?: Maybe<SalesforceOpportunity_Mutation_Response>;
  /** delete single row from the table: "salesforceOpportunity" */
  delete_salesforceOpportunity_by_pk?: Maybe<SalesforceOpportunity>;
  /** delete data from the table: "sendgridEvent" */
  delete_sendgridEvent?: Maybe<SendgridEvent_Mutation_Response>;
  /** delete single row from the table: "sendgridEvent" */
  delete_sendgridEvent_by_pk?: Maybe<SendgridEvent>;
  /** delete data from the table: "syncZoomEventsJob" */
  delete_syncZoomEventsJob?: Maybe<SyncZoomEventsJob_Mutation_Response>;
  /** delete single row from the table: "syncZoomEventsJob" */
  delete_syncZoomEventsJob_by_pk?: Maybe<SyncZoomEventsJob>;
  /** delete data from the table: "territory" */
  delete_territory?: Maybe<Territory_Mutation_Response>;
  /** delete data from the table: "territorySearchTerm" */
  delete_territorySearchTerm?: Maybe<TerritorySearchTerm_Mutation_Response>;
  /** delete single row from the table: "territorySearchTerm" */
  delete_territorySearchTerm_by_pk?: Maybe<TerritorySearchTerm>;
  /** delete single row from the table: "territory" */
  delete_territory_by_pk?: Maybe<Territory>;
  /** delete data from the table: "theOrgSearchTerm" */
  delete_theOrgSearchTerm?: Maybe<TheOrgSearchTerm_Mutation_Response>;
  /** delete single row from the table: "theOrgSearchTerm" */
  delete_theOrgSearchTerm_by_pk?: Maybe<TheOrgSearchTerm>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "userInvite" */
  delete_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** delete single row from the table: "userInvite" */
  delete_userInvite_by_pk?: Maybe<UserInvite>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "workOSConnection" */
  delete_workOSConnection?: Maybe<WorkOsConnection_Mutation_Response>;
  /** delete single row from the table: "workOSConnection" */
  delete_workOSConnection_by_pk?: Maybe<WorkOsConnection>;
  /** delete data from the table: "workOSDirectory" */
  delete_workOSDirectory?: Maybe<WorkOsDirectory_Mutation_Response>;
  /** delete single row from the table: "workOSDirectory" */
  delete_workOSDirectory_by_pk?: Maybe<WorkOsDirectory>;
  /** delete data from the table: "workOSGroup" */
  delete_workOSGroup?: Maybe<WorkOsGroup_Mutation_Response>;
  /** delete single row from the table: "workOSGroup" */
  delete_workOSGroup_by_pk?: Maybe<WorkOsGroup>;
  /** delete data from the table: "workOSUser" */
  delete_workOSUser?: Maybe<WorkOsUser_Mutation_Response>;
  /** delete single row from the table: "workOSUser" */
  delete_workOSUser_by_pk?: Maybe<WorkOsUser>;
  /** delete data from the table: "zoomApprovedDomain" */
  delete_zoomApprovedDomain?: Maybe<ZoomApprovedDomain_Mutation_Response>;
  /** delete single row from the table: "zoomApprovedDomain" */
  delete_zoomApprovedDomain_by_pk?: Maybe<ZoomApprovedDomain>;
  /** delete data from the table: "zoomCompany" */
  delete_zoomCompany?: Maybe<ZoomCompany_Mutation_Response>;
  /** delete data from the table: "zoomCompanyMergeResult" */
  delete_zoomCompanyMergeResult?: Maybe<ZoomCompanyMergeResult_Mutation_Response>;
  /** delete single row from the table: "zoomCompanyMergeResult" */
  delete_zoomCompanyMergeResult_by_pk?: Maybe<ZoomCompanyMergeResult>;
  /** delete single row from the table: "zoomCompany" */
  delete_zoomCompany_by_pk?: Maybe<ZoomCompany>;
  /** delete data from the table: "zoomConnectionRequest" */
  delete_zoomConnectionRequest?: Maybe<ZoomConnectionRequest_Mutation_Response>;
  /** delete data from the table: "zoomConnectionRequestReminder" */
  delete_zoomConnectionRequestReminder?: Maybe<ZoomConnectionRequestReminder_Mutation_Response>;
  /** delete single row from the table: "zoomConnectionRequestReminder" */
  delete_zoomConnectionRequestReminder_by_pk?: Maybe<ZoomConnectionRequestReminder>;
  /** delete single row from the table: "zoomConnectionRequest" */
  delete_zoomConnectionRequest_by_pk?: Maybe<ZoomConnectionRequest>;
  /** delete data from the table: "zoomContact" */
  delete_zoomContact?: Maybe<ZoomContact_Mutation_Response>;
  /** delete data from the table: "zoomContactNotes" */
  delete_zoomContactNotes?: Maybe<ZoomContactNotes_Mutation_Response>;
  /** delete single row from the table: "zoomContactNotes" */
  delete_zoomContactNotes_by_pk?: Maybe<ZoomContactNotes>;
  /** delete single row from the table: "zoomContact" */
  delete_zoomContact_by_pk?: Maybe<ZoomContact>;
  /** delete data from the table: "zoomConversationStarterAnswer" */
  delete_zoomConversationStarterAnswer?: Maybe<ZoomConversationStarterAnswer_Mutation_Response>;
  /** delete single row from the table: "zoomConversationStarterAnswer" */
  delete_zoomConversationStarterAnswer_by_pk?: Maybe<ZoomConversationStarterAnswer>;
  /** delete data from the table: "zoomConversationStarterPrompt" */
  delete_zoomConversationStarterPrompt?: Maybe<ZoomConversationStarterPrompt_Mutation_Response>;
  /** delete single row from the table: "zoomConversationStarterPrompt" */
  delete_zoomConversationStarterPrompt_by_pk?: Maybe<ZoomConversationStarterPrompt>;
  /** delete data from the table: "zoomCustomBackground" */
  delete_zoomCustomBackground?: Maybe<ZoomCustomBackground_Mutation_Response>;
  /** delete single row from the table: "zoomCustomBackground" */
  delete_zoomCustomBackground_by_pk?: Maybe<ZoomCustomBackground>;
  /** delete data from the table: "zoomEmailHistory" */
  delete_zoomEmailHistory?: Maybe<ZoomEmailHistory_Mutation_Response>;
  /** delete single row from the table: "zoomEmailHistory" */
  delete_zoomEmailHistory_by_pk?: Maybe<ZoomEmailHistory>;
  /** delete data from the table: "zoomEvent" */
  delete_zoomEvent?: Maybe<ZoomEvent_Mutation_Response>;
  /** delete data from the table: "zoomEventParticipant" */
  delete_zoomEventParticipant?: Maybe<ZoomEventParticipant_Mutation_Response>;
  /** delete single row from the table: "zoomEventParticipant" */
  delete_zoomEventParticipant_by_pk?: Maybe<ZoomEventParticipant>;
  /** delete single row from the table: "zoomEvent" */
  delete_zoomEvent_by_pk?: Maybe<ZoomEvent>;
  /** delete data from the table: "zoomExternalAuthUser" */
  delete_zoomExternalAuthUser?: Maybe<ZoomExternalAuthUser_Mutation_Response>;
  /** delete single row from the table: "zoomExternalAuthUser" */
  delete_zoomExternalAuthUser_by_pk?: Maybe<ZoomExternalAuthUser>;
  /** delete data from the table: "zoomGroup" */
  delete_zoomGroup?: Maybe<ZoomGroup_Mutation_Response>;
  /** delete data from the table: "zoomGroupMembership" */
  delete_zoomGroupMembership?: Maybe<ZoomGroupMembership_Mutation_Response>;
  /** delete single row from the table: "zoomGroupMembership" */
  delete_zoomGroupMembership_by_pk?: Maybe<ZoomGroupMembership>;
  /** delete single row from the table: "zoomGroup" */
  delete_zoomGroup_by_pk?: Maybe<ZoomGroup>;
  /** delete data from the table: "zoomLayersEvents" */
  delete_zoomLayersEvents?: Maybe<ZoomLayersEvents_Mutation_Response>;
  /** delete single row from the table: "zoomLayersEvents" */
  delete_zoomLayersEvents_by_pk?: Maybe<ZoomLayersEvents>;
  /** delete data from the table: "zoomOrganization" */
  delete_zoomOrganization?: Maybe<ZoomOrganization_Mutation_Response>;
  /** delete data from the table: "zoomOrganizationBackfill" */
  delete_zoomOrganizationBackfill?: Maybe<ZoomOrganizationBackfill_Mutation_Response>;
  /** delete data from the table: "zoomOrganizationBackfillResult" */
  delete_zoomOrganizationBackfillResult?: Maybe<ZoomOrganizationBackfillResult_Mutation_Response>;
  /** delete single row from the table: "zoomOrganizationBackfillResult" */
  delete_zoomOrganizationBackfillResult_by_pk?: Maybe<ZoomOrganizationBackfillResult>;
  /** delete single row from the table: "zoomOrganizationBackfill" */
  delete_zoomOrganizationBackfill_by_pk?: Maybe<ZoomOrganizationBackfill>;
  /** delete data from the table: "zoomOrganizationMembership" */
  delete_zoomOrganizationMembership?: Maybe<ZoomOrganizationMembership_Mutation_Response>;
  /** delete data from the table: "zoomOrganizationMembershipView" */
  delete_zoomOrganizationMembershipView?: Maybe<ZoomOrganizationMembershipView_Mutation_Response>;
  /** delete single row from the table: "zoomOrganizationMembership" */
  delete_zoomOrganizationMembership_by_pk?: Maybe<ZoomOrganizationMembership>;
  /** delete single row from the table: "zoomOrganization" */
  delete_zoomOrganization_by_pk?: Maybe<ZoomOrganization>;
  /** delete data from the table: "zoomPlan" */
  delete_zoomPlan?: Maybe<ZoomPlan_Mutation_Response>;
  /** delete single row from the table: "zoomPlan" */
  delete_zoomPlan_by_pk?: Maybe<ZoomPlan>;
  /** delete data from the table: "zoomPresentation" */
  delete_zoomPresentation?: Maybe<ZoomPresentation_Mutation_Response>;
  /** delete single row from the table: "zoomPresentation" */
  delete_zoomPresentation_by_pk?: Maybe<ZoomPresentation>;
  /** delete data from the table: "zoomSettings" */
  delete_zoomSettings?: Maybe<ZoomSettings_Mutation_Response>;
  /** delete single row from the table: "zoomSettings" */
  delete_zoomSettings_by_pk?: Maybe<ZoomSettings>;
  /** delete data from the table: "zoomUser" */
  delete_zoomUser?: Maybe<ZoomUser_Mutation_Response>;
  /** delete data from the table: "zoomUserActions" */
  delete_zoomUserActions?: Maybe<ZoomUserActions_Mutation_Response>;
  /** delete single row from the table: "zoomUserActions" */
  delete_zoomUserActions_by_pk?: Maybe<ZoomUserActions>;
  /** delete single row from the table: "zoomUser" */
  delete_zoomUser_by_pk?: Maybe<ZoomUser>;
  generateImage: Scalars['String'];
  getExport: Scalars['String'];
  /** insert data into the table: "client" */
  insert_client?: Maybe<Client_Mutation_Response>;
  /** insert data into the table: "clientContact" */
  insert_clientContact?: Maybe<ClientContact_Mutation_Response>;
  /** insert data into the table: "clientContactCrmData" */
  insert_clientContactCrmData?: Maybe<ClientContactCrmData_Mutation_Response>;
  /** insert a single row into the table: "clientContactCrmData" */
  insert_clientContactCrmData_one?: Maybe<ClientContactCrmData>;
  /** insert data into the table: "clientContactFilter" */
  insert_clientContactFilter?: Maybe<ClientContactFilter_Mutation_Response>;
  /** insert a single row into the table: "clientContactFilter" */
  insert_clientContactFilter_one?: Maybe<ClientContactFilter>;
  /** insert data into the table: "clientContactJobChange" */
  insert_clientContactJobChange?: Maybe<ClientContactJobChange_Mutation_Response>;
  /** insert a single row into the table: "clientContactJobChange" */
  insert_clientContactJobChange_one?: Maybe<ClientContactJobChange>;
  /** insert a single row into the table: "clientContact" */
  insert_clientContact_one?: Maybe<ClientContact>;
  /** insert data into the table: "clientEmailReport" */
  insert_clientEmailReport?: Maybe<ClientEmailReport_Mutation_Response>;
  /** insert a single row into the table: "clientEmailReport" */
  insert_clientEmailReport_one?: Maybe<ClientEmailReport>;
  /** insert data into the table: "clientNotificationEmail" */
  insert_clientNotificationEmail?: Maybe<ClientNotificationEmail_Mutation_Response>;
  /** insert a single row into the table: "clientNotificationEmail" */
  insert_clientNotificationEmail_one?: Maybe<ClientNotificationEmail>;
  /** insert data into the table: "clientTerritory" */
  insert_clientTerritory?: Maybe<ClientTerritory_Mutation_Response>;
  /** insert a single row into the table: "clientTerritory" */
  insert_clientTerritory_one?: Maybe<ClientTerritory>;
  /** insert a single row into the table: "client" */
  insert_client_one?: Maybe<Client>;
  /** insert data into the table: "cloudTask" */
  insert_cloudTask?: Maybe<CloudTask_Mutation_Response>;
  /** insert a single row into the table: "cloudTask" */
  insert_cloudTask_one?: Maybe<CloudTask>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert data into the table: "companyName" */
  insert_companyName?: Maybe<CompanyName_Mutation_Response>;
  /** insert a single row into the table: "companyName" */
  insert_companyName_one?: Maybe<CompanyName>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "contactData" */
  insert_contactData?: Maybe<ContactData_Mutation_Response>;
  /** insert a single row into the table: "contactData" */
  insert_contactData_one?: Maybe<ContactData>;
  /** insert data into the table: "contactEmail" */
  insert_contactEmail?: Maybe<ContactEmail_Mutation_Response>;
  /** insert a single row into the table: "contactEmail" */
  insert_contactEmail_one?: Maybe<ContactEmail>;
  /** insert data into the table: "content" */
  insert_content?: Maybe<Content_Mutation_Response>;
  /** insert a single row into the table: "content" */
  insert_content_one?: Maybe<Content>;
  /** insert data into the table: "crmContactEnrichmentHistory" */
  insert_crmContactEnrichmentHistory?: Maybe<CrmContactEnrichmentHistory_Mutation_Response>;
  /** insert a single row into the table: "crmContactEnrichmentHistory" */
  insert_crmContactEnrichmentHistory_one?: Maybe<CrmContactEnrichmentHistory>;
  /** insert data into the table: "csvUpload" */
  insert_csvUpload?: Maybe<CsvUpload_Mutation_Response>;
  /** insert a single row into the table: "csvUpload" */
  insert_csvUpload_one?: Maybe<CsvUpload>;
  /** insert data into the table: "deleteOldZoomEventsJob" */
  insert_deleteOldZoomEventsJob?: Maybe<DeleteOldZoomEventsJob_Mutation_Response>;
  /** insert a single row into the table: "deleteOldZoomEventsJob" */
  insert_deleteOldZoomEventsJob_one?: Maybe<DeleteOldZoomEventsJob>;
  /** insert data into the table: "emailHistory" */
  insert_emailHistory?: Maybe<EmailHistory_Mutation_Response>;
  /** insert a single row into the table: "emailHistory" */
  insert_emailHistory_one?: Maybe<EmailHistory>;
  /** insert data into the table: "googleChannel" */
  insert_googleChannel?: Maybe<GoogleChannel_Mutation_Response>;
  /** insert a single row into the table: "googleChannel" */
  insert_googleChannel_one?: Maybe<GoogleChannel>;
  /** insert data into the table: "growthIntraCompanyAdoptionContact" */
  insert_growthIntraCompanyAdoptionContact?: Maybe<GrowthIntraCompanyAdoptionContact_Mutation_Response>;
  /** insert a single row into the table: "growthIntraCompanyAdoptionContact" */
  insert_growthIntraCompanyAdoptionContact_one?: Maybe<GrowthIntraCompanyAdoptionContact>;
  /** insert data into the table: "growthUserReferralContact" */
  insert_growthUserReferralContact?: Maybe<GrowthUserReferralContact_Mutation_Response>;
  /** insert a single row into the table: "growthUserReferralContact" */
  insert_growthUserReferralContact_one?: Maybe<GrowthUserReferralContact>;
  /** insert data into the table: "hubspotContactList" */
  insert_hubspotContactList?: Maybe<HubspotContactList_Mutation_Response>;
  /** insert data into the table: "hubspotContactListHubspotContact" */
  insert_hubspotContactListHubspotContact?: Maybe<HubspotContactListHubspotContact_Mutation_Response>;
  /** insert a single row into the table: "hubspotContactListHubspotContact" */
  insert_hubspotContactListHubspotContact_one?: Maybe<HubspotContactListHubspotContact>;
  /** insert a single row into the table: "hubspotContactList" */
  insert_hubspotContactList_one?: Maybe<HubspotContactList>;
  /** insert data into the table: "hubspotContactProperty" */
  insert_hubspotContactProperty?: Maybe<HubspotContactProperty_Mutation_Response>;
  /** insert a single row into the table: "hubspotContactProperty" */
  insert_hubspotContactProperty_one?: Maybe<HubspotContactProperty>;
  /** insert data into the table: "hubspotIntegration" */
  insert_hubspotIntegration?: Maybe<HubspotIntegration_Mutation_Response>;
  /** insert a single row into the table: "hubspotIntegration" */
  insert_hubspotIntegration_one?: Maybe<HubspotIntegration>;
  /** insert data into the table: "job" */
  insert_job?: Maybe<Job_Mutation_Response>;
  /** insert a single row into the table: "job" */
  insert_job_one?: Maybe<Job>;
  /** insert data into the table: "microsoftSubscription" */
  insert_microsoftSubscription?: Maybe<MicrosoftSubscription_Mutation_Response>;
  /** insert a single row into the table: "microsoftSubscription" */
  insert_microsoftSubscription_one?: Maybe<MicrosoftSubscription>;
  /** insert data into the table: "proxycrawlData" */
  insert_proxycrawlData?: Maybe<ProxycrawlData_Mutation_Response>;
  /** insert a single row into the table: "proxycrawlData" */
  insert_proxycrawlData_one?: Maybe<ProxycrawlData>;
  /** insert data into the table: "qaQualification" */
  insert_qaQualification?: Maybe<QaQualification_Mutation_Response>;
  /** insert a single row into the table: "qaQualification" */
  insert_qaQualification_one?: Maybe<QaQualification>;
  /** insert data into the table: "qaTask" */
  insert_qaTask?: Maybe<QaTask_Mutation_Response>;
  /** insert data into the table: "qaTaskAction" */
  insert_qaTaskAction?: Maybe<QaTaskAction_Mutation_Response>;
  /** insert a single row into the table: "qaTaskAction" */
  insert_qaTaskAction_one?: Maybe<QaTaskAction>;
  /** insert a single row into the table: "qaTask" */
  insert_qaTask_one?: Maybe<QaTask>;
  /** insert data into the table: "salesforceAccount" */
  insert_salesforceAccount?: Maybe<SalesforceAccount_Mutation_Response>;
  /** insert a single row into the table: "salesforceAccount" */
  insert_salesforceAccount_one?: Maybe<SalesforceAccount>;
  /** insert data into the table: "salesforceContactField" */
  insert_salesforceContactField?: Maybe<SalesforceContactField_Mutation_Response>;
  /** insert a single row into the table: "salesforceContactField" */
  insert_salesforceContactField_one?: Maybe<SalesforceContactField>;
  /** insert data into the table: "salesforceIntegration" */
  insert_salesforceIntegration?: Maybe<SalesforceIntegration_Mutation_Response>;
  /** insert a single row into the table: "salesforceIntegration" */
  insert_salesforceIntegration_one?: Maybe<SalesforceIntegration>;
  /** insert data into the table: "salesforceOpportunity" */
  insert_salesforceOpportunity?: Maybe<SalesforceOpportunity_Mutation_Response>;
  /** insert a single row into the table: "salesforceOpportunity" */
  insert_salesforceOpportunity_one?: Maybe<SalesforceOpportunity>;
  /** insert data into the table: "sendgridEvent" */
  insert_sendgridEvent?: Maybe<SendgridEvent_Mutation_Response>;
  /** insert a single row into the table: "sendgridEvent" */
  insert_sendgridEvent_one?: Maybe<SendgridEvent>;
  /** insert data into the table: "syncZoomEventsJob" */
  insert_syncZoomEventsJob?: Maybe<SyncZoomEventsJob_Mutation_Response>;
  /** insert a single row into the table: "syncZoomEventsJob" */
  insert_syncZoomEventsJob_one?: Maybe<SyncZoomEventsJob>;
  /** insert data into the table: "territory" */
  insert_territory?: Maybe<Territory_Mutation_Response>;
  /** insert data into the table: "territorySearchTerm" */
  insert_territorySearchTerm?: Maybe<TerritorySearchTerm_Mutation_Response>;
  /** insert a single row into the table: "territorySearchTerm" */
  insert_territorySearchTerm_one?: Maybe<TerritorySearchTerm>;
  /** insert a single row into the table: "territory" */
  insert_territory_one?: Maybe<Territory>;
  /** insert data into the table: "theOrgSearchTerm" */
  insert_theOrgSearchTerm?: Maybe<TheOrgSearchTerm_Mutation_Response>;
  /** insert a single row into the table: "theOrgSearchTerm" */
  insert_theOrgSearchTerm_one?: Maybe<TheOrgSearchTerm>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "userInvite" */
  insert_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** insert a single row into the table: "userInvite" */
  insert_userInvite_one?: Maybe<UserInvite>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "workOSConnection" */
  insert_workOSConnection?: Maybe<WorkOsConnection_Mutation_Response>;
  /** insert a single row into the table: "workOSConnection" */
  insert_workOSConnection_one?: Maybe<WorkOsConnection>;
  /** insert data into the table: "workOSDirectory" */
  insert_workOSDirectory?: Maybe<WorkOsDirectory_Mutation_Response>;
  /** insert a single row into the table: "workOSDirectory" */
  insert_workOSDirectory_one?: Maybe<WorkOsDirectory>;
  /** insert data into the table: "workOSGroup" */
  insert_workOSGroup?: Maybe<WorkOsGroup_Mutation_Response>;
  /** insert a single row into the table: "workOSGroup" */
  insert_workOSGroup_one?: Maybe<WorkOsGroup>;
  /** insert data into the table: "workOSUser" */
  insert_workOSUser?: Maybe<WorkOsUser_Mutation_Response>;
  /** insert a single row into the table: "workOSUser" */
  insert_workOSUser_one?: Maybe<WorkOsUser>;
  /** insert data into the table: "zoomApprovedDomain" */
  insert_zoomApprovedDomain?: Maybe<ZoomApprovedDomain_Mutation_Response>;
  /** insert a single row into the table: "zoomApprovedDomain" */
  insert_zoomApprovedDomain_one?: Maybe<ZoomApprovedDomain>;
  /** insert data into the table: "zoomCompany" */
  insert_zoomCompany?: Maybe<ZoomCompany_Mutation_Response>;
  /** insert data into the table: "zoomCompanyMergeResult" */
  insert_zoomCompanyMergeResult?: Maybe<ZoomCompanyMergeResult_Mutation_Response>;
  /** insert a single row into the table: "zoomCompanyMergeResult" */
  insert_zoomCompanyMergeResult_one?: Maybe<ZoomCompanyMergeResult>;
  /** insert a single row into the table: "zoomCompany" */
  insert_zoomCompany_one?: Maybe<ZoomCompany>;
  /** insert data into the table: "zoomConnectionRequest" */
  insert_zoomConnectionRequest?: Maybe<ZoomConnectionRequest_Mutation_Response>;
  /** insert data into the table: "zoomConnectionRequestReminder" */
  insert_zoomConnectionRequestReminder?: Maybe<ZoomConnectionRequestReminder_Mutation_Response>;
  /** insert a single row into the table: "zoomConnectionRequestReminder" */
  insert_zoomConnectionRequestReminder_one?: Maybe<ZoomConnectionRequestReminder>;
  /** insert a single row into the table: "zoomConnectionRequest" */
  insert_zoomConnectionRequest_one?: Maybe<ZoomConnectionRequest>;
  /** insert data into the table: "zoomContact" */
  insert_zoomContact?: Maybe<ZoomContact_Mutation_Response>;
  /** insert data into the table: "zoomContactNotes" */
  insert_zoomContactNotes?: Maybe<ZoomContactNotes_Mutation_Response>;
  /** insert a single row into the table: "zoomContactNotes" */
  insert_zoomContactNotes_one?: Maybe<ZoomContactNotes>;
  /** insert a single row into the table: "zoomContact" */
  insert_zoomContact_one?: Maybe<ZoomContact>;
  /** insert data into the table: "zoomConversationStarterAnswer" */
  insert_zoomConversationStarterAnswer?: Maybe<ZoomConversationStarterAnswer_Mutation_Response>;
  /** insert a single row into the table: "zoomConversationStarterAnswer" */
  insert_zoomConversationStarterAnswer_one?: Maybe<ZoomConversationStarterAnswer>;
  /** insert data into the table: "zoomConversationStarterPrompt" */
  insert_zoomConversationStarterPrompt?: Maybe<ZoomConversationStarterPrompt_Mutation_Response>;
  /** insert a single row into the table: "zoomConversationStarterPrompt" */
  insert_zoomConversationStarterPrompt_one?: Maybe<ZoomConversationStarterPrompt>;
  /** insert data into the table: "zoomCustomBackground" */
  insert_zoomCustomBackground?: Maybe<ZoomCustomBackground_Mutation_Response>;
  /** insert a single row into the table: "zoomCustomBackground" */
  insert_zoomCustomBackground_one?: Maybe<ZoomCustomBackground>;
  /** insert data into the table: "zoomEmailHistory" */
  insert_zoomEmailHistory?: Maybe<ZoomEmailHistory_Mutation_Response>;
  /** insert a single row into the table: "zoomEmailHistory" */
  insert_zoomEmailHistory_one?: Maybe<ZoomEmailHistory>;
  /** insert data into the table: "zoomEvent" */
  insert_zoomEvent?: Maybe<ZoomEvent_Mutation_Response>;
  /** insert data into the table: "zoomEventParticipant" */
  insert_zoomEventParticipant?: Maybe<ZoomEventParticipant_Mutation_Response>;
  /** insert a single row into the table: "zoomEventParticipant" */
  insert_zoomEventParticipant_one?: Maybe<ZoomEventParticipant>;
  /** insert a single row into the table: "zoomEvent" */
  insert_zoomEvent_one?: Maybe<ZoomEvent>;
  /** insert data into the table: "zoomExternalAuthUser" */
  insert_zoomExternalAuthUser?: Maybe<ZoomExternalAuthUser_Mutation_Response>;
  /** insert a single row into the table: "zoomExternalAuthUser" */
  insert_zoomExternalAuthUser_one?: Maybe<ZoomExternalAuthUser>;
  /** insert data into the table: "zoomGroup" */
  insert_zoomGroup?: Maybe<ZoomGroup_Mutation_Response>;
  /** insert data into the table: "zoomGroupMembership" */
  insert_zoomGroupMembership?: Maybe<ZoomGroupMembership_Mutation_Response>;
  /** insert a single row into the table: "zoomGroupMembership" */
  insert_zoomGroupMembership_one?: Maybe<ZoomGroupMembership>;
  /** insert a single row into the table: "zoomGroup" */
  insert_zoomGroup_one?: Maybe<ZoomGroup>;
  /** insert data into the table: "zoomLayersEvents" */
  insert_zoomLayersEvents?: Maybe<ZoomLayersEvents_Mutation_Response>;
  /** insert a single row into the table: "zoomLayersEvents" */
  insert_zoomLayersEvents_one?: Maybe<ZoomLayersEvents>;
  /** insert data into the table: "zoomOrganization" */
  insert_zoomOrganization?: Maybe<ZoomOrganization_Mutation_Response>;
  /** insert data into the table: "zoomOrganizationBackfill" */
  insert_zoomOrganizationBackfill?: Maybe<ZoomOrganizationBackfill_Mutation_Response>;
  /** insert data into the table: "zoomOrganizationBackfillResult" */
  insert_zoomOrganizationBackfillResult?: Maybe<ZoomOrganizationBackfillResult_Mutation_Response>;
  /** insert a single row into the table: "zoomOrganizationBackfillResult" */
  insert_zoomOrganizationBackfillResult_one?: Maybe<ZoomOrganizationBackfillResult>;
  /** insert a single row into the table: "zoomOrganizationBackfill" */
  insert_zoomOrganizationBackfill_one?: Maybe<ZoomOrganizationBackfill>;
  /** insert data into the table: "zoomOrganizationMembership" */
  insert_zoomOrganizationMembership?: Maybe<ZoomOrganizationMembership_Mutation_Response>;
  /** insert data into the table: "zoomOrganizationMembershipView" */
  insert_zoomOrganizationMembershipView?: Maybe<ZoomOrganizationMembershipView_Mutation_Response>;
  /** insert a single row into the table: "zoomOrganizationMembershipView" */
  insert_zoomOrganizationMembershipView_one?: Maybe<ZoomOrganizationMembershipView>;
  /** insert a single row into the table: "zoomOrganizationMembership" */
  insert_zoomOrganizationMembership_one?: Maybe<ZoomOrganizationMembership>;
  /** insert a single row into the table: "zoomOrganization" */
  insert_zoomOrganization_one?: Maybe<ZoomOrganization>;
  /** insert data into the table: "zoomPlan" */
  insert_zoomPlan?: Maybe<ZoomPlan_Mutation_Response>;
  /** insert a single row into the table: "zoomPlan" */
  insert_zoomPlan_one?: Maybe<ZoomPlan>;
  /** insert data into the table: "zoomPresentation" */
  insert_zoomPresentation?: Maybe<ZoomPresentation_Mutation_Response>;
  /** insert a single row into the table: "zoomPresentation" */
  insert_zoomPresentation_one?: Maybe<ZoomPresentation>;
  /** insert data into the table: "zoomSettings" */
  insert_zoomSettings?: Maybe<ZoomSettings_Mutation_Response>;
  /** insert a single row into the table: "zoomSettings" */
  insert_zoomSettings_one?: Maybe<ZoomSettings>;
  /** insert data into the table: "zoomUser" */
  insert_zoomUser?: Maybe<ZoomUser_Mutation_Response>;
  /** insert data into the table: "zoomUserActions" */
  insert_zoomUserActions?: Maybe<ZoomUserActions_Mutation_Response>;
  /** insert a single row into the table: "zoomUserActions" */
  insert_zoomUserActions_one?: Maybe<ZoomUserActions>;
  /** insert a single row into the table: "zoomUser" */
  insert_zoomUser_one?: Maybe<ZoomUser>;
  mergeCompanies: Scalars['String'];
  mergeOrganizations: Scalars['String'];
  resyncMSCalendar: Scalars['String'];
  setDefaultStripePaymentMethod: Scalars['Boolean'];
  setZoomSetting: Scalars['String'];
  setZoomSettings: Scalars['String'];
  submitAuditLogEvent: Scalars['String'];
  toggleMembershipType: Scalars['String'];
  /** update data of the table: "client" */
  update_client?: Maybe<Client_Mutation_Response>;
  /** update data of the table: "clientContact" */
  update_clientContact?: Maybe<ClientContact_Mutation_Response>;
  /** update data of the table: "clientContactCrmData" */
  update_clientContactCrmData?: Maybe<ClientContactCrmData_Mutation_Response>;
  /** update single row of the table: "clientContactCrmData" */
  update_clientContactCrmData_by_pk?: Maybe<ClientContactCrmData>;
  /** update multiples rows of table: "clientContactCrmData" */
  update_clientContactCrmData_many?: Maybe<Array<Maybe<ClientContactCrmData_Mutation_Response>>>;
  /** update data of the table: "clientContactFilter" */
  update_clientContactFilter?: Maybe<ClientContactFilter_Mutation_Response>;
  /** update single row of the table: "clientContactFilter" */
  update_clientContactFilter_by_pk?: Maybe<ClientContactFilter>;
  /** update multiples rows of table: "clientContactFilter" */
  update_clientContactFilter_many?: Maybe<Array<Maybe<ClientContactFilter_Mutation_Response>>>;
  /** update data of the table: "clientContactJobChange" */
  update_clientContactJobChange?: Maybe<ClientContactJobChange_Mutation_Response>;
  /** update single row of the table: "clientContactJobChange" */
  update_clientContactJobChange_by_pk?: Maybe<ClientContactJobChange>;
  /** update multiples rows of table: "clientContactJobChange" */
  update_clientContactJobChange_many?: Maybe<Array<Maybe<ClientContactJobChange_Mutation_Response>>>;
  /** update single row of the table: "clientContact" */
  update_clientContact_by_pk?: Maybe<ClientContact>;
  /** update multiples rows of table: "clientContact" */
  update_clientContact_many?: Maybe<Array<Maybe<ClientContact_Mutation_Response>>>;
  /** update data of the table: "clientEmailReport" */
  update_clientEmailReport?: Maybe<ClientEmailReport_Mutation_Response>;
  /** update single row of the table: "clientEmailReport" */
  update_clientEmailReport_by_pk?: Maybe<ClientEmailReport>;
  /** update multiples rows of table: "clientEmailReport" */
  update_clientEmailReport_many?: Maybe<Array<Maybe<ClientEmailReport_Mutation_Response>>>;
  /** update data of the table: "clientNotificationEmail" */
  update_clientNotificationEmail?: Maybe<ClientNotificationEmail_Mutation_Response>;
  /** update single row of the table: "clientNotificationEmail" */
  update_clientNotificationEmail_by_pk?: Maybe<ClientNotificationEmail>;
  /** update multiples rows of table: "clientNotificationEmail" */
  update_clientNotificationEmail_many?: Maybe<Array<Maybe<ClientNotificationEmail_Mutation_Response>>>;
  /** update data of the table: "clientTerritory" */
  update_clientTerritory?: Maybe<ClientTerritory_Mutation_Response>;
  /** update single row of the table: "clientTerritory" */
  update_clientTerritory_by_pk?: Maybe<ClientTerritory>;
  /** update multiples rows of table: "clientTerritory" */
  update_clientTerritory_many?: Maybe<Array<Maybe<ClientTerritory_Mutation_Response>>>;
  /** update single row of the table: "client" */
  update_client_by_pk?: Maybe<Client>;
  /** update multiples rows of table: "client" */
  update_client_many?: Maybe<Array<Maybe<Client_Mutation_Response>>>;
  /** update data of the table: "cloudTask" */
  update_cloudTask?: Maybe<CloudTask_Mutation_Response>;
  /** update single row of the table: "cloudTask" */
  update_cloudTask_by_pk?: Maybe<CloudTask>;
  /** update multiples rows of table: "cloudTask" */
  update_cloudTask_many?: Maybe<Array<Maybe<CloudTask_Mutation_Response>>>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update data of the table: "companyName" */
  update_companyName?: Maybe<CompanyName_Mutation_Response>;
  /** update single row of the table: "companyName" */
  update_companyName_by_pk?: Maybe<CompanyName>;
  /** update multiples rows of table: "companyName" */
  update_companyName_many?: Maybe<Array<Maybe<CompanyName_Mutation_Response>>>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update multiples rows of table: "company" */
  update_company_many?: Maybe<Array<Maybe<Company_Mutation_Response>>>;
  /** update data of the table: "contactData" */
  update_contactData?: Maybe<ContactData_Mutation_Response>;
  /** update single row of the table: "contactData" */
  update_contactData_by_pk?: Maybe<ContactData>;
  /** update multiples rows of table: "contactData" */
  update_contactData_many?: Maybe<Array<Maybe<ContactData_Mutation_Response>>>;
  /** update data of the table: "contactEmail" */
  update_contactEmail?: Maybe<ContactEmail_Mutation_Response>;
  /** update single row of the table: "contactEmail" */
  update_contactEmail_by_pk?: Maybe<ContactEmail>;
  /** update multiples rows of table: "contactEmail" */
  update_contactEmail_many?: Maybe<Array<Maybe<ContactEmail_Mutation_Response>>>;
  /** update data of the table: "content" */
  update_content?: Maybe<Content_Mutation_Response>;
  /** update single row of the table: "content" */
  update_content_by_pk?: Maybe<Content>;
  /** update multiples rows of table: "content" */
  update_content_many?: Maybe<Array<Maybe<Content_Mutation_Response>>>;
  /** update data of the table: "crmContactEnrichmentHistory" */
  update_crmContactEnrichmentHistory?: Maybe<CrmContactEnrichmentHistory_Mutation_Response>;
  /** update single row of the table: "crmContactEnrichmentHistory" */
  update_crmContactEnrichmentHistory_by_pk?: Maybe<CrmContactEnrichmentHistory>;
  /** update multiples rows of table: "crmContactEnrichmentHistory" */
  update_crmContactEnrichmentHistory_many?: Maybe<Array<Maybe<CrmContactEnrichmentHistory_Mutation_Response>>>;
  /** update data of the table: "csvUpload" */
  update_csvUpload?: Maybe<CsvUpload_Mutation_Response>;
  /** update single row of the table: "csvUpload" */
  update_csvUpload_by_pk?: Maybe<CsvUpload>;
  /** update multiples rows of table: "csvUpload" */
  update_csvUpload_many?: Maybe<Array<Maybe<CsvUpload_Mutation_Response>>>;
  /** update data of the table: "deleteOldZoomEventsJob" */
  update_deleteOldZoomEventsJob?: Maybe<DeleteOldZoomEventsJob_Mutation_Response>;
  /** update single row of the table: "deleteOldZoomEventsJob" */
  update_deleteOldZoomEventsJob_by_pk?: Maybe<DeleteOldZoomEventsJob>;
  /** update multiples rows of table: "deleteOldZoomEventsJob" */
  update_deleteOldZoomEventsJob_many?: Maybe<Array<Maybe<DeleteOldZoomEventsJob_Mutation_Response>>>;
  /** update data of the table: "emailHistory" */
  update_emailHistory?: Maybe<EmailHistory_Mutation_Response>;
  /** update single row of the table: "emailHistory" */
  update_emailHistory_by_pk?: Maybe<EmailHistory>;
  /** update multiples rows of table: "emailHistory" */
  update_emailHistory_many?: Maybe<Array<Maybe<EmailHistory_Mutation_Response>>>;
  /** update data of the table: "googleChannel" */
  update_googleChannel?: Maybe<GoogleChannel_Mutation_Response>;
  /** update single row of the table: "googleChannel" */
  update_googleChannel_by_pk?: Maybe<GoogleChannel>;
  /** update multiples rows of table: "googleChannel" */
  update_googleChannel_many?: Maybe<Array<Maybe<GoogleChannel_Mutation_Response>>>;
  /** update data of the table: "growthIntraCompanyAdoptionContact" */
  update_growthIntraCompanyAdoptionContact?: Maybe<GrowthIntraCompanyAdoptionContact_Mutation_Response>;
  /** update single row of the table: "growthIntraCompanyAdoptionContact" */
  update_growthIntraCompanyAdoptionContact_by_pk?: Maybe<GrowthIntraCompanyAdoptionContact>;
  /** update multiples rows of table: "growthIntraCompanyAdoptionContact" */
  update_growthIntraCompanyAdoptionContact_many?: Maybe<
    Array<Maybe<GrowthIntraCompanyAdoptionContact_Mutation_Response>>
  >;
  /** update data of the table: "growthUserReferralContact" */
  update_growthUserReferralContact?: Maybe<GrowthUserReferralContact_Mutation_Response>;
  /** update single row of the table: "growthUserReferralContact" */
  update_growthUserReferralContact_by_pk?: Maybe<GrowthUserReferralContact>;
  /** update multiples rows of table: "growthUserReferralContact" */
  update_growthUserReferralContact_many?: Maybe<Array<Maybe<GrowthUserReferralContact_Mutation_Response>>>;
  /** update data of the table: "hubspotContactList" */
  update_hubspotContactList?: Maybe<HubspotContactList_Mutation_Response>;
  /** update data of the table: "hubspotContactListHubspotContact" */
  update_hubspotContactListHubspotContact?: Maybe<HubspotContactListHubspotContact_Mutation_Response>;
  /** update single row of the table: "hubspotContactListHubspotContact" */
  update_hubspotContactListHubspotContact_by_pk?: Maybe<HubspotContactListHubspotContact>;
  /** update multiples rows of table: "hubspotContactListHubspotContact" */
  update_hubspotContactListHubspotContact_many?: Maybe<
    Array<Maybe<HubspotContactListHubspotContact_Mutation_Response>>
  >;
  /** update single row of the table: "hubspotContactList" */
  update_hubspotContactList_by_pk?: Maybe<HubspotContactList>;
  /** update multiples rows of table: "hubspotContactList" */
  update_hubspotContactList_many?: Maybe<Array<Maybe<HubspotContactList_Mutation_Response>>>;
  /** update data of the table: "hubspotContactProperty" */
  update_hubspotContactProperty?: Maybe<HubspotContactProperty_Mutation_Response>;
  /** update single row of the table: "hubspotContactProperty" */
  update_hubspotContactProperty_by_pk?: Maybe<HubspotContactProperty>;
  /** update multiples rows of table: "hubspotContactProperty" */
  update_hubspotContactProperty_many?: Maybe<Array<Maybe<HubspotContactProperty_Mutation_Response>>>;
  /** update data of the table: "hubspotIntegration" */
  update_hubspotIntegration?: Maybe<HubspotIntegration_Mutation_Response>;
  /** update single row of the table: "hubspotIntegration" */
  update_hubspotIntegration_by_pk?: Maybe<HubspotIntegration>;
  /** update multiples rows of table: "hubspotIntegration" */
  update_hubspotIntegration_many?: Maybe<Array<Maybe<HubspotIntegration_Mutation_Response>>>;
  /** update data of the table: "job" */
  update_job?: Maybe<Job_Mutation_Response>;
  /** update single row of the table: "job" */
  update_job_by_pk?: Maybe<Job>;
  /** update multiples rows of table: "job" */
  update_job_many?: Maybe<Array<Maybe<Job_Mutation_Response>>>;
  /** update data of the table: "microsoftSubscription" */
  update_microsoftSubscription?: Maybe<MicrosoftSubscription_Mutation_Response>;
  /** update single row of the table: "microsoftSubscription" */
  update_microsoftSubscription_by_pk?: Maybe<MicrosoftSubscription>;
  /** update multiples rows of table: "microsoftSubscription" */
  update_microsoftSubscription_many?: Maybe<Array<Maybe<MicrosoftSubscription_Mutation_Response>>>;
  /** update data of the table: "proxycrawlData" */
  update_proxycrawlData?: Maybe<ProxycrawlData_Mutation_Response>;
  /** update single row of the table: "proxycrawlData" */
  update_proxycrawlData_by_pk?: Maybe<ProxycrawlData>;
  /** update multiples rows of table: "proxycrawlData" */
  update_proxycrawlData_many?: Maybe<Array<Maybe<ProxycrawlData_Mutation_Response>>>;
  /** update data of the table: "qaQualification" */
  update_qaQualification?: Maybe<QaQualification_Mutation_Response>;
  /** update single row of the table: "qaQualification" */
  update_qaQualification_by_pk?: Maybe<QaQualification>;
  /** update multiples rows of table: "qaQualification" */
  update_qaQualification_many?: Maybe<Array<Maybe<QaQualification_Mutation_Response>>>;
  /** update data of the table: "qaTask" */
  update_qaTask?: Maybe<QaTask_Mutation_Response>;
  /** update data of the table: "qaTaskAction" */
  update_qaTaskAction?: Maybe<QaTaskAction_Mutation_Response>;
  /** update single row of the table: "qaTaskAction" */
  update_qaTaskAction_by_pk?: Maybe<QaTaskAction>;
  /** update multiples rows of table: "qaTaskAction" */
  update_qaTaskAction_many?: Maybe<Array<Maybe<QaTaskAction_Mutation_Response>>>;
  /** update single row of the table: "qaTask" */
  update_qaTask_by_pk?: Maybe<QaTask>;
  /** update multiples rows of table: "qaTask" */
  update_qaTask_many?: Maybe<Array<Maybe<QaTask_Mutation_Response>>>;
  /** update data of the table: "salesforceAccount" */
  update_salesforceAccount?: Maybe<SalesforceAccount_Mutation_Response>;
  /** update single row of the table: "salesforceAccount" */
  update_salesforceAccount_by_pk?: Maybe<SalesforceAccount>;
  /** update multiples rows of table: "salesforceAccount" */
  update_salesforceAccount_many?: Maybe<Array<Maybe<SalesforceAccount_Mutation_Response>>>;
  /** update data of the table: "salesforceContactField" */
  update_salesforceContactField?: Maybe<SalesforceContactField_Mutation_Response>;
  /** update single row of the table: "salesforceContactField" */
  update_salesforceContactField_by_pk?: Maybe<SalesforceContactField>;
  /** update multiples rows of table: "salesforceContactField" */
  update_salesforceContactField_many?: Maybe<Array<Maybe<SalesforceContactField_Mutation_Response>>>;
  /** update data of the table: "salesforceIntegration" */
  update_salesforceIntegration?: Maybe<SalesforceIntegration_Mutation_Response>;
  /** update single row of the table: "salesforceIntegration" */
  update_salesforceIntegration_by_pk?: Maybe<SalesforceIntegration>;
  /** update multiples rows of table: "salesforceIntegration" */
  update_salesforceIntegration_many?: Maybe<Array<Maybe<SalesforceIntegration_Mutation_Response>>>;
  /** update data of the table: "salesforceOpportunity" */
  update_salesforceOpportunity?: Maybe<SalesforceOpportunity_Mutation_Response>;
  /** update single row of the table: "salesforceOpportunity" */
  update_salesforceOpportunity_by_pk?: Maybe<SalesforceOpportunity>;
  /** update multiples rows of table: "salesforceOpportunity" */
  update_salesforceOpportunity_many?: Maybe<Array<Maybe<SalesforceOpportunity_Mutation_Response>>>;
  /** update data of the table: "sendgridEvent" */
  update_sendgridEvent?: Maybe<SendgridEvent_Mutation_Response>;
  /** update single row of the table: "sendgridEvent" */
  update_sendgridEvent_by_pk?: Maybe<SendgridEvent>;
  /** update multiples rows of table: "sendgridEvent" */
  update_sendgridEvent_many?: Maybe<Array<Maybe<SendgridEvent_Mutation_Response>>>;
  /** update data of the table: "syncZoomEventsJob" */
  update_syncZoomEventsJob?: Maybe<SyncZoomEventsJob_Mutation_Response>;
  /** update single row of the table: "syncZoomEventsJob" */
  update_syncZoomEventsJob_by_pk?: Maybe<SyncZoomEventsJob>;
  /** update multiples rows of table: "syncZoomEventsJob" */
  update_syncZoomEventsJob_many?: Maybe<Array<Maybe<SyncZoomEventsJob_Mutation_Response>>>;
  /** update data of the table: "territory" */
  update_territory?: Maybe<Territory_Mutation_Response>;
  /** update data of the table: "territorySearchTerm" */
  update_territorySearchTerm?: Maybe<TerritorySearchTerm_Mutation_Response>;
  /** update single row of the table: "territorySearchTerm" */
  update_territorySearchTerm_by_pk?: Maybe<TerritorySearchTerm>;
  /** update multiples rows of table: "territorySearchTerm" */
  update_territorySearchTerm_many?: Maybe<Array<Maybe<TerritorySearchTerm_Mutation_Response>>>;
  /** update single row of the table: "territory" */
  update_territory_by_pk?: Maybe<Territory>;
  /** update multiples rows of table: "territory" */
  update_territory_many?: Maybe<Array<Maybe<Territory_Mutation_Response>>>;
  /** update data of the table: "theOrgSearchTerm" */
  update_theOrgSearchTerm?: Maybe<TheOrgSearchTerm_Mutation_Response>;
  /** update single row of the table: "theOrgSearchTerm" */
  update_theOrgSearchTerm_by_pk?: Maybe<TheOrgSearchTerm>;
  /** update multiples rows of table: "theOrgSearchTerm" */
  update_theOrgSearchTerm_many?: Maybe<Array<Maybe<TheOrgSearchTerm_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "userInvite" */
  update_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** update single row of the table: "userInvite" */
  update_userInvite_by_pk?: Maybe<UserInvite>;
  /** update multiples rows of table: "userInvite" */
  update_userInvite_many?: Maybe<Array<Maybe<UserInvite_Mutation_Response>>>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "workOSConnection" */
  update_workOSConnection?: Maybe<WorkOsConnection_Mutation_Response>;
  /** update single row of the table: "workOSConnection" */
  update_workOSConnection_by_pk?: Maybe<WorkOsConnection>;
  /** update multiples rows of table: "workOSConnection" */
  update_workOSConnection_many?: Maybe<Array<Maybe<WorkOsConnection_Mutation_Response>>>;
  /** update data of the table: "workOSDirectory" */
  update_workOSDirectory?: Maybe<WorkOsDirectory_Mutation_Response>;
  /** update single row of the table: "workOSDirectory" */
  update_workOSDirectory_by_pk?: Maybe<WorkOsDirectory>;
  /** update multiples rows of table: "workOSDirectory" */
  update_workOSDirectory_many?: Maybe<Array<Maybe<WorkOsDirectory_Mutation_Response>>>;
  /** update data of the table: "workOSGroup" */
  update_workOSGroup?: Maybe<WorkOsGroup_Mutation_Response>;
  /** update single row of the table: "workOSGroup" */
  update_workOSGroup_by_pk?: Maybe<WorkOsGroup>;
  /** update multiples rows of table: "workOSGroup" */
  update_workOSGroup_many?: Maybe<Array<Maybe<WorkOsGroup_Mutation_Response>>>;
  /** update data of the table: "workOSUser" */
  update_workOSUser?: Maybe<WorkOsUser_Mutation_Response>;
  /** update single row of the table: "workOSUser" */
  update_workOSUser_by_pk?: Maybe<WorkOsUser>;
  /** update multiples rows of table: "workOSUser" */
  update_workOSUser_many?: Maybe<Array<Maybe<WorkOsUser_Mutation_Response>>>;
  /** update data of the table: "zoomApprovedDomain" */
  update_zoomApprovedDomain?: Maybe<ZoomApprovedDomain_Mutation_Response>;
  /** update single row of the table: "zoomApprovedDomain" */
  update_zoomApprovedDomain_by_pk?: Maybe<ZoomApprovedDomain>;
  /** update multiples rows of table: "zoomApprovedDomain" */
  update_zoomApprovedDomain_many?: Maybe<Array<Maybe<ZoomApprovedDomain_Mutation_Response>>>;
  /** update data of the table: "zoomCompany" */
  update_zoomCompany?: Maybe<ZoomCompany_Mutation_Response>;
  /** update data of the table: "zoomCompanyMergeResult" */
  update_zoomCompanyMergeResult?: Maybe<ZoomCompanyMergeResult_Mutation_Response>;
  /** update single row of the table: "zoomCompanyMergeResult" */
  update_zoomCompanyMergeResult_by_pk?: Maybe<ZoomCompanyMergeResult>;
  /** update multiples rows of table: "zoomCompanyMergeResult" */
  update_zoomCompanyMergeResult_many?: Maybe<Array<Maybe<ZoomCompanyMergeResult_Mutation_Response>>>;
  /** update single row of the table: "zoomCompany" */
  update_zoomCompany_by_pk?: Maybe<ZoomCompany>;
  /** update multiples rows of table: "zoomCompany" */
  update_zoomCompany_many?: Maybe<Array<Maybe<ZoomCompany_Mutation_Response>>>;
  /** update data of the table: "zoomConnectionRequest" */
  update_zoomConnectionRequest?: Maybe<ZoomConnectionRequest_Mutation_Response>;
  /** update data of the table: "zoomConnectionRequestReminder" */
  update_zoomConnectionRequestReminder?: Maybe<ZoomConnectionRequestReminder_Mutation_Response>;
  /** update single row of the table: "zoomConnectionRequestReminder" */
  update_zoomConnectionRequestReminder_by_pk?: Maybe<ZoomConnectionRequestReminder>;
  /** update multiples rows of table: "zoomConnectionRequestReminder" */
  update_zoomConnectionRequestReminder_many?: Maybe<Array<Maybe<ZoomConnectionRequestReminder_Mutation_Response>>>;
  /** update single row of the table: "zoomConnectionRequest" */
  update_zoomConnectionRequest_by_pk?: Maybe<ZoomConnectionRequest>;
  /** update multiples rows of table: "zoomConnectionRequest" */
  update_zoomConnectionRequest_many?: Maybe<Array<Maybe<ZoomConnectionRequest_Mutation_Response>>>;
  /** update data of the table: "zoomContact" */
  update_zoomContact?: Maybe<ZoomContact_Mutation_Response>;
  /** update data of the table: "zoomContactNotes" */
  update_zoomContactNotes?: Maybe<ZoomContactNotes_Mutation_Response>;
  /** update single row of the table: "zoomContactNotes" */
  update_zoomContactNotes_by_pk?: Maybe<ZoomContactNotes>;
  /** update multiples rows of table: "zoomContactNotes" */
  update_zoomContactNotes_many?: Maybe<Array<Maybe<ZoomContactNotes_Mutation_Response>>>;
  /** update single row of the table: "zoomContact" */
  update_zoomContact_by_pk?: Maybe<ZoomContact>;
  /** update multiples rows of table: "zoomContact" */
  update_zoomContact_many?: Maybe<Array<Maybe<ZoomContact_Mutation_Response>>>;
  /** update data of the table: "zoomConversationStarterAnswer" */
  update_zoomConversationStarterAnswer?: Maybe<ZoomConversationStarterAnswer_Mutation_Response>;
  /** update single row of the table: "zoomConversationStarterAnswer" */
  update_zoomConversationStarterAnswer_by_pk?: Maybe<ZoomConversationStarterAnswer>;
  /** update multiples rows of table: "zoomConversationStarterAnswer" */
  update_zoomConversationStarterAnswer_many?: Maybe<Array<Maybe<ZoomConversationStarterAnswer_Mutation_Response>>>;
  /** update data of the table: "zoomConversationStarterPrompt" */
  update_zoomConversationStarterPrompt?: Maybe<ZoomConversationStarterPrompt_Mutation_Response>;
  /** update single row of the table: "zoomConversationStarterPrompt" */
  update_zoomConversationStarterPrompt_by_pk?: Maybe<ZoomConversationStarterPrompt>;
  /** update multiples rows of table: "zoomConversationStarterPrompt" */
  update_zoomConversationStarterPrompt_many?: Maybe<Array<Maybe<ZoomConversationStarterPrompt_Mutation_Response>>>;
  /** update data of the table: "zoomCustomBackground" */
  update_zoomCustomBackground?: Maybe<ZoomCustomBackground_Mutation_Response>;
  /** update single row of the table: "zoomCustomBackground" */
  update_zoomCustomBackground_by_pk?: Maybe<ZoomCustomBackground>;
  /** update multiples rows of table: "zoomCustomBackground" */
  update_zoomCustomBackground_many?: Maybe<Array<Maybe<ZoomCustomBackground_Mutation_Response>>>;
  /** update data of the table: "zoomEmailHistory" */
  update_zoomEmailHistory?: Maybe<ZoomEmailHistory_Mutation_Response>;
  /** update single row of the table: "zoomEmailHistory" */
  update_zoomEmailHistory_by_pk?: Maybe<ZoomEmailHistory>;
  /** update multiples rows of table: "zoomEmailHistory" */
  update_zoomEmailHistory_many?: Maybe<Array<Maybe<ZoomEmailHistory_Mutation_Response>>>;
  /** update data of the table: "zoomEvent" */
  update_zoomEvent?: Maybe<ZoomEvent_Mutation_Response>;
  /** update data of the table: "zoomEventParticipant" */
  update_zoomEventParticipant?: Maybe<ZoomEventParticipant_Mutation_Response>;
  /** update single row of the table: "zoomEventParticipant" */
  update_zoomEventParticipant_by_pk?: Maybe<ZoomEventParticipant>;
  /** update multiples rows of table: "zoomEventParticipant" */
  update_zoomEventParticipant_many?: Maybe<Array<Maybe<ZoomEventParticipant_Mutation_Response>>>;
  /** update single row of the table: "zoomEvent" */
  update_zoomEvent_by_pk?: Maybe<ZoomEvent>;
  /** update multiples rows of table: "zoomEvent" */
  update_zoomEvent_many?: Maybe<Array<Maybe<ZoomEvent_Mutation_Response>>>;
  /** update data of the table: "zoomExternalAuthUser" */
  update_zoomExternalAuthUser?: Maybe<ZoomExternalAuthUser_Mutation_Response>;
  /** update single row of the table: "zoomExternalAuthUser" */
  update_zoomExternalAuthUser_by_pk?: Maybe<ZoomExternalAuthUser>;
  /** update multiples rows of table: "zoomExternalAuthUser" */
  update_zoomExternalAuthUser_many?: Maybe<Array<Maybe<ZoomExternalAuthUser_Mutation_Response>>>;
  /** update data of the table: "zoomGroup" */
  update_zoomGroup?: Maybe<ZoomGroup_Mutation_Response>;
  /** update data of the table: "zoomGroupMembership" */
  update_zoomGroupMembership?: Maybe<ZoomGroupMembership_Mutation_Response>;
  /** update single row of the table: "zoomGroupMembership" */
  update_zoomGroupMembership_by_pk?: Maybe<ZoomGroupMembership>;
  /** update multiples rows of table: "zoomGroupMembership" */
  update_zoomGroupMembership_many?: Maybe<Array<Maybe<ZoomGroupMembership_Mutation_Response>>>;
  /** update single row of the table: "zoomGroup" */
  update_zoomGroup_by_pk?: Maybe<ZoomGroup>;
  /** update multiples rows of table: "zoomGroup" */
  update_zoomGroup_many?: Maybe<Array<Maybe<ZoomGroup_Mutation_Response>>>;
  /** update data of the table: "zoomLayersEvents" */
  update_zoomLayersEvents?: Maybe<ZoomLayersEvents_Mutation_Response>;
  /** update single row of the table: "zoomLayersEvents" */
  update_zoomLayersEvents_by_pk?: Maybe<ZoomLayersEvents>;
  /** update multiples rows of table: "zoomLayersEvents" */
  update_zoomLayersEvents_many?: Maybe<Array<Maybe<ZoomLayersEvents_Mutation_Response>>>;
  /** update data of the table: "zoomOrganization" */
  update_zoomOrganization?: Maybe<ZoomOrganization_Mutation_Response>;
  /** update data of the table: "zoomOrganizationBackfill" */
  update_zoomOrganizationBackfill?: Maybe<ZoomOrganizationBackfill_Mutation_Response>;
  /** update data of the table: "zoomOrganizationBackfillResult" */
  update_zoomOrganizationBackfillResult?: Maybe<ZoomOrganizationBackfillResult_Mutation_Response>;
  /** update single row of the table: "zoomOrganizationBackfillResult" */
  update_zoomOrganizationBackfillResult_by_pk?: Maybe<ZoomOrganizationBackfillResult>;
  /** update multiples rows of table: "zoomOrganizationBackfillResult" */
  update_zoomOrganizationBackfillResult_many?: Maybe<Array<Maybe<ZoomOrganizationBackfillResult_Mutation_Response>>>;
  /** update single row of the table: "zoomOrganizationBackfill" */
  update_zoomOrganizationBackfill_by_pk?: Maybe<ZoomOrganizationBackfill>;
  /** update multiples rows of table: "zoomOrganizationBackfill" */
  update_zoomOrganizationBackfill_many?: Maybe<Array<Maybe<ZoomOrganizationBackfill_Mutation_Response>>>;
  /** update data of the table: "zoomOrganizationMembership" */
  update_zoomOrganizationMembership?: Maybe<ZoomOrganizationMembership_Mutation_Response>;
  /** update data of the table: "zoomOrganizationMembershipView" */
  update_zoomOrganizationMembershipView?: Maybe<ZoomOrganizationMembershipView_Mutation_Response>;
  /** update multiples rows of table: "zoomOrganizationMembershipView" */
  update_zoomOrganizationMembershipView_many?: Maybe<Array<Maybe<ZoomOrganizationMembershipView_Mutation_Response>>>;
  /** update single row of the table: "zoomOrganizationMembership" */
  update_zoomOrganizationMembership_by_pk?: Maybe<ZoomOrganizationMembership>;
  /** update multiples rows of table: "zoomOrganizationMembership" */
  update_zoomOrganizationMembership_many?: Maybe<Array<Maybe<ZoomOrganizationMembership_Mutation_Response>>>;
  /** update single row of the table: "zoomOrganization" */
  update_zoomOrganization_by_pk?: Maybe<ZoomOrganization>;
  /** update multiples rows of table: "zoomOrganization" */
  update_zoomOrganization_many?: Maybe<Array<Maybe<ZoomOrganization_Mutation_Response>>>;
  /** update data of the table: "zoomPlan" */
  update_zoomPlan?: Maybe<ZoomPlan_Mutation_Response>;
  /** update single row of the table: "zoomPlan" */
  update_zoomPlan_by_pk?: Maybe<ZoomPlan>;
  /** update multiples rows of table: "zoomPlan" */
  update_zoomPlan_many?: Maybe<Array<Maybe<ZoomPlan_Mutation_Response>>>;
  /** update data of the table: "zoomPresentation" */
  update_zoomPresentation?: Maybe<ZoomPresentation_Mutation_Response>;
  /** update single row of the table: "zoomPresentation" */
  update_zoomPresentation_by_pk?: Maybe<ZoomPresentation>;
  /** update multiples rows of table: "zoomPresentation" */
  update_zoomPresentation_many?: Maybe<Array<Maybe<ZoomPresentation_Mutation_Response>>>;
  /** update data of the table: "zoomSettings" */
  update_zoomSettings?: Maybe<ZoomSettings_Mutation_Response>;
  /** update single row of the table: "zoomSettings" */
  update_zoomSettings_by_pk?: Maybe<ZoomSettings>;
  /** update multiples rows of table: "zoomSettings" */
  update_zoomSettings_many?: Maybe<Array<Maybe<ZoomSettings_Mutation_Response>>>;
  /** update data of the table: "zoomUser" */
  update_zoomUser?: Maybe<ZoomUser_Mutation_Response>;
  /** update data of the table: "zoomUserActions" */
  update_zoomUserActions?: Maybe<ZoomUserActions_Mutation_Response>;
  /** update single row of the table: "zoomUserActions" */
  update_zoomUserActions_by_pk?: Maybe<ZoomUserActions>;
  /** update multiples rows of table: "zoomUserActions" */
  update_zoomUserActions_many?: Maybe<Array<Maybe<ZoomUserActions_Mutation_Response>>>;
  /** update single row of the table: "zoomUser" */
  update_zoomUser_by_pk?: Maybe<ZoomUser>;
  /** update multiples rows of table: "zoomUser" */
  update_zoomUser_many?: Maybe<Array<Maybe<ZoomUser_Mutation_Response>>>;
  uploadPresentation: Scalars['String'];
  upsertOrgSetting: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAssignToOrganizationArgs = {
  membershipId: Scalars['String'];
  organizationId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootBackfillEncodedIdsArgs = {
  numberToBackfill: Scalars['Float'];
};

/** mutation root */
export type Mutation_RootCreateStripeSessionArgs = {
  stripePriceId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateStripeSubscriptionArgs = {
  stripePriceId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteUsersForOrgArgs = {
  organizationId: Scalars['String'];
  skipUsers: Array<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDelete_ClientArgs = {
  where: Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactArgs = {
  where: ClientContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactCrmDataArgs = {
  where: ClientContactCrmData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactCrmData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientContactFilterArgs = {
  where: ClientContactFilter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactFilter_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientContactJobChangeArgs = {
  where: ClientContactJobChange_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactJobChange_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientEmailReportArgs = {
  where: ClientEmailReport_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientEmailReport_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientNotificationEmailArgs = {
  where: ClientNotificationEmail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientNotificationEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientTerritoryArgs = {
  where: ClientTerritory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Client_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CloudTaskArgs = {
  where: CloudTask_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CloudTask_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CompanyNameArgs = {
  where: CompanyName_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CompanyName_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ContactDataArgs = {
  where: ContactData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ContactData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ContactEmailArgs = {
  where: ContactEmail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ContactEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ContentArgs = {
  where: Content_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Content_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CrmContactEnrichmentHistoryArgs = {
  where: CrmContactEnrichmentHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CrmContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CsvUploadArgs = {
  where: CsvUpload_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CsvUpload_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_DeleteOldZoomEventsJobArgs = {
  where: DeleteOldZoomEventsJob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DeleteOldZoomEventsJob_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_EmailHistoryArgs = {
  where: EmailHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_EmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_GoogleChannelArgs = {
  where: GoogleChannel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GoogleChannel_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_GrowthIntraCompanyAdoptionContactArgs = {
  where: GrowthIntraCompanyAdoptionContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GrowthIntraCompanyAdoptionContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_GrowthUserReferralContactArgs = {
  where: GrowthUserReferralContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GrowthUserReferralContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactListArgs = {
  where: HubspotContactList_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactListHubspotContactArgs = {
  where: HubspotContactListHubspotContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactListHubspotContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactList_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactPropertyArgs = {
  where: HubspotContactProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactProperty_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_HubspotIntegrationArgs = {
  where: HubspotIntegration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_HubspotIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_JobArgs = {
  where: Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_MicrosoftSubscriptionArgs = {
  where: MicrosoftSubscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_MicrosoftSubscription_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProxycrawlDataArgs = {
  where: ProxycrawlData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ProxycrawlData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_QaQualificationArgs = {
  where: QaQualification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_QaQualification_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_QaTaskArgs = {
  where: QaTask_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_QaTaskActionArgs = {
  where: QaTaskAction_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_QaTaskAction_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_QaTask_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SalesforceAccountArgs = {
  where: SalesforceAccount_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesforceAccount_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SalesforceContactFieldArgs = {
  where: SalesforceContactField_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesforceContactField_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SalesforceIntegrationArgs = {
  where: SalesforceIntegration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesforceIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SalesforceOpportunityArgs = {
  where: SalesforceOpportunity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesforceOpportunity_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SendgridEventArgs = {
  where: SendgridEvent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SendgridEvent_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SyncZoomEventsJobArgs = {
  where: SyncZoomEventsJob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SyncZoomEventsJob_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_TerritoryArgs = {
  where: Territory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TerritorySearchTermArgs = {
  where: TerritorySearchTerm_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TerritorySearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Territory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TheOrgSearchTermArgs = {
  where: TheOrgSearchTerm_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TheOrgSearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserInviteArgs = {
  where: UserInvite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_WorkOsConnectionArgs = {
  where: WorkOsConnection_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_WorkOsConnection_By_PkArgs = {
  connectionId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_WorkOsDirectoryArgs = {
  where: WorkOsDirectory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_WorkOsDirectory_By_PkArgs = {
  directoryId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_WorkOsGroupArgs = {
  where: WorkOsGroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_WorkOsGroup_By_PkArgs = {
  groupId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_WorkOsUserArgs = {
  where: WorkOsUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_WorkOsUser_By_PkArgs = {
  userId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomApprovedDomainArgs = {
  where: ZoomApprovedDomain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomApprovedDomain_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomCompanyArgs = {
  where: ZoomCompany_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomCompanyMergeResultArgs = {
  where: ZoomCompanyMergeResult_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomCompanyMergeResult_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomConnectionRequestArgs = {
  where: ZoomConnectionRequest_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomConnectionRequestReminderArgs = {
  where: ZoomConnectionRequestReminder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomConnectionRequestReminder_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomConnectionRequest_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactArgs = {
  where: ZoomContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactNotesArgs = {
  where: ZoomContactNotes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactNotes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomConversationStarterAnswerArgs = {
  where: ZoomConversationStarterAnswer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomConversationStarterAnswer_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomConversationStarterPromptArgs = {
  where: ZoomConversationStarterPrompt_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomConversationStarterPrompt_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomCustomBackgroundArgs = {
  where: ZoomCustomBackground_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomCustomBackground_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomEmailHistoryArgs = {
  where: ZoomEmailHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomEventArgs = {
  where: ZoomEvent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomEventParticipantArgs = {
  where: ZoomEventParticipant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomEventParticipant_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomEvent_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomExternalAuthUserArgs = {
  where: ZoomExternalAuthUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomExternalAuthUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomGroupArgs = {
  where: ZoomGroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomGroupMembershipArgs = {
  where: ZoomGroupMembership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomGroupMembership_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomGroup_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomLayersEventsArgs = {
  where: ZoomLayersEvents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomLayersEvents_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganizationArgs = {
  where: ZoomOrganization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganizationBackfillArgs = {
  where: ZoomOrganizationBackfill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganizationBackfillResultArgs = {
  where: ZoomOrganizationBackfillResult_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganizationBackfillResult_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganizationBackfill_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganizationMembershipArgs = {
  where: ZoomOrganizationMembership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganizationMembershipViewArgs = {
  where: ZoomOrganizationMembershipView_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganizationMembership_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomPlanArgs = {
  where: ZoomPlan_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomPlan_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomPresentationArgs = {
  where: ZoomPresentation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomPresentation_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomSettingsArgs = {
  where: ZoomSettings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomSettings_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomUserArgs = {
  where: ZoomUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomUserActionsArgs = {
  where: ZoomUserActions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomUserActions_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootGenerateImageArgs = {
  prompt: Scalars['String'];
  response_format: Scalars['String'];
};

/** mutation root */
export type Mutation_RootGetExportArgs = {
  auditLogId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsert_ClientArgs = {
  objects: Array<Client_Insert_Input>;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactArgs = {
  objects: Array<ClientContact_Insert_Input>;
  on_conflict?: InputMaybe<ClientContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactCrmDataArgs = {
  objects: Array<ClientContactCrmData_Insert_Input>;
  on_conflict?: InputMaybe<ClientContactCrmData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactCrmData_OneArgs = {
  object: ClientContactCrmData_Insert_Input;
  on_conflict?: InputMaybe<ClientContactCrmData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactFilterArgs = {
  objects: Array<ClientContactFilter_Insert_Input>;
  on_conflict?: InputMaybe<ClientContactFilter_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactFilter_OneArgs = {
  object: ClientContactFilter_Insert_Input;
  on_conflict?: InputMaybe<ClientContactFilter_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactJobChangeArgs = {
  objects: Array<ClientContactJobChange_Insert_Input>;
  on_conflict?: InputMaybe<ClientContactJobChange_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactJobChange_OneArgs = {
  object: ClientContactJobChange_Insert_Input;
  on_conflict?: InputMaybe<ClientContactJobChange_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContact_OneArgs = {
  object: ClientContact_Insert_Input;
  on_conflict?: InputMaybe<ClientContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientEmailReportArgs = {
  objects: Array<ClientEmailReport_Insert_Input>;
  on_conflict?: InputMaybe<ClientEmailReport_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientEmailReport_OneArgs = {
  object: ClientEmailReport_Insert_Input;
  on_conflict?: InputMaybe<ClientEmailReport_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientNotificationEmailArgs = {
  objects: Array<ClientNotificationEmail_Insert_Input>;
  on_conflict?: InputMaybe<ClientNotificationEmail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientNotificationEmail_OneArgs = {
  object: ClientNotificationEmail_Insert_Input;
  on_conflict?: InputMaybe<ClientNotificationEmail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientTerritoryArgs = {
  objects: Array<ClientTerritory_Insert_Input>;
  on_conflict?: InputMaybe<ClientTerritory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientTerritory_OneArgs = {
  object: ClientTerritory_Insert_Input;
  on_conflict?: InputMaybe<ClientTerritory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_OneArgs = {
  object: Client_Insert_Input;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CloudTaskArgs = {
  objects: Array<CloudTask_Insert_Input>;
  on_conflict?: InputMaybe<CloudTask_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CloudTask_OneArgs = {
  object: CloudTask_Insert_Input;
  on_conflict?: InputMaybe<CloudTask_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompanyNameArgs = {
  objects: Array<CompanyName_Insert_Input>;
  on_conflict?: InputMaybe<CompanyName_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompanyName_OneArgs = {
  object: CompanyName_Insert_Input;
  on_conflict?: InputMaybe<CompanyName_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactDataArgs = {
  objects: Array<ContactData_Insert_Input>;
  on_conflict?: InputMaybe<ContactData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactData_OneArgs = {
  object: ContactData_Insert_Input;
  on_conflict?: InputMaybe<ContactData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactEmailArgs = {
  objects: Array<ContactEmail_Insert_Input>;
  on_conflict?: InputMaybe<ContactEmail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactEmail_OneArgs = {
  object: ContactEmail_Insert_Input;
  on_conflict?: InputMaybe<ContactEmail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContentArgs = {
  objects: Array<Content_Insert_Input>;
  on_conflict?: InputMaybe<Content_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Content_OneArgs = {
  object: Content_Insert_Input;
  on_conflict?: InputMaybe<Content_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CrmContactEnrichmentHistoryArgs = {
  objects: Array<CrmContactEnrichmentHistory_Insert_Input>;
  on_conflict?: InputMaybe<CrmContactEnrichmentHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CrmContactEnrichmentHistory_OneArgs = {
  object: CrmContactEnrichmentHistory_Insert_Input;
  on_conflict?: InputMaybe<CrmContactEnrichmentHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CsvUploadArgs = {
  objects: Array<CsvUpload_Insert_Input>;
  on_conflict?: InputMaybe<CsvUpload_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CsvUpload_OneArgs = {
  object: CsvUpload_Insert_Input;
  on_conflict?: InputMaybe<CsvUpload_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DeleteOldZoomEventsJobArgs = {
  objects: Array<DeleteOldZoomEventsJob_Insert_Input>;
  on_conflict?: InputMaybe<DeleteOldZoomEventsJob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DeleteOldZoomEventsJob_OneArgs = {
  object: DeleteOldZoomEventsJob_Insert_Input;
  on_conflict?: InputMaybe<DeleteOldZoomEventsJob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EmailHistoryArgs = {
  objects: Array<EmailHistory_Insert_Input>;
  on_conflict?: InputMaybe<EmailHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EmailHistory_OneArgs = {
  object: EmailHistory_Insert_Input;
  on_conflict?: InputMaybe<EmailHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoogleChannelArgs = {
  objects: Array<GoogleChannel_Insert_Input>;
  on_conflict?: InputMaybe<GoogleChannel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoogleChannel_OneArgs = {
  object: GoogleChannel_Insert_Input;
  on_conflict?: InputMaybe<GoogleChannel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GrowthIntraCompanyAdoptionContactArgs = {
  objects: Array<GrowthIntraCompanyAdoptionContact_Insert_Input>;
  on_conflict?: InputMaybe<GrowthIntraCompanyAdoptionContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GrowthIntraCompanyAdoptionContact_OneArgs = {
  object: GrowthIntraCompanyAdoptionContact_Insert_Input;
  on_conflict?: InputMaybe<GrowthIntraCompanyAdoptionContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GrowthUserReferralContactArgs = {
  objects: Array<GrowthUserReferralContact_Insert_Input>;
  on_conflict?: InputMaybe<GrowthUserReferralContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GrowthUserReferralContact_OneArgs = {
  object: GrowthUserReferralContact_Insert_Input;
  on_conflict?: InputMaybe<GrowthUserReferralContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactListArgs = {
  objects: Array<HubspotContactList_Insert_Input>;
  on_conflict?: InputMaybe<HubspotContactList_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactListHubspotContactArgs = {
  objects: Array<HubspotContactListHubspotContact_Insert_Input>;
  on_conflict?: InputMaybe<HubspotContactListHubspotContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactListHubspotContact_OneArgs = {
  object: HubspotContactListHubspotContact_Insert_Input;
  on_conflict?: InputMaybe<HubspotContactListHubspotContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactList_OneArgs = {
  object: HubspotContactList_Insert_Input;
  on_conflict?: InputMaybe<HubspotContactList_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactPropertyArgs = {
  objects: Array<HubspotContactProperty_Insert_Input>;
  on_conflict?: InputMaybe<HubspotContactProperty_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactProperty_OneArgs = {
  object: HubspotContactProperty_Insert_Input;
  on_conflict?: InputMaybe<HubspotContactProperty_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotIntegrationArgs = {
  objects: Array<HubspotIntegration_Insert_Input>;
  on_conflict?: InputMaybe<HubspotIntegration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotIntegration_OneArgs = {
  object: HubspotIntegration_Insert_Input;
  on_conflict?: InputMaybe<HubspotIntegration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_JobArgs = {
  objects: Array<Job_Insert_Input>;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_OneArgs = {
  object: Job_Insert_Input;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MicrosoftSubscriptionArgs = {
  objects: Array<MicrosoftSubscription_Insert_Input>;
  on_conflict?: InputMaybe<MicrosoftSubscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MicrosoftSubscription_OneArgs = {
  object: MicrosoftSubscription_Insert_Input;
  on_conflict?: InputMaybe<MicrosoftSubscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProxycrawlDataArgs = {
  objects: Array<ProxycrawlData_Insert_Input>;
  on_conflict?: InputMaybe<ProxycrawlData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProxycrawlData_OneArgs = {
  object: ProxycrawlData_Insert_Input;
  on_conflict?: InputMaybe<ProxycrawlData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaQualificationArgs = {
  objects: Array<QaQualification_Insert_Input>;
  on_conflict?: InputMaybe<QaQualification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaQualification_OneArgs = {
  object: QaQualification_Insert_Input;
  on_conflict?: InputMaybe<QaQualification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaTaskArgs = {
  objects: Array<QaTask_Insert_Input>;
  on_conflict?: InputMaybe<QaTask_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaTaskActionArgs = {
  objects: Array<QaTaskAction_Insert_Input>;
  on_conflict?: InputMaybe<QaTaskAction_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaTaskAction_OneArgs = {
  object: QaTaskAction_Insert_Input;
  on_conflict?: InputMaybe<QaTaskAction_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaTask_OneArgs = {
  object: QaTask_Insert_Input;
  on_conflict?: InputMaybe<QaTask_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceAccountArgs = {
  objects: Array<SalesforceAccount_Insert_Input>;
  on_conflict?: InputMaybe<SalesforceAccount_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceAccount_OneArgs = {
  object: SalesforceAccount_Insert_Input;
  on_conflict?: InputMaybe<SalesforceAccount_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceContactFieldArgs = {
  objects: Array<SalesforceContactField_Insert_Input>;
  on_conflict?: InputMaybe<SalesforceContactField_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceContactField_OneArgs = {
  object: SalesforceContactField_Insert_Input;
  on_conflict?: InputMaybe<SalesforceContactField_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceIntegrationArgs = {
  objects: Array<SalesforceIntegration_Insert_Input>;
  on_conflict?: InputMaybe<SalesforceIntegration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceIntegration_OneArgs = {
  object: SalesforceIntegration_Insert_Input;
  on_conflict?: InputMaybe<SalesforceIntegration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceOpportunityArgs = {
  objects: Array<SalesforceOpportunity_Insert_Input>;
  on_conflict?: InputMaybe<SalesforceOpportunity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceOpportunity_OneArgs = {
  object: SalesforceOpportunity_Insert_Input;
  on_conflict?: InputMaybe<SalesforceOpportunity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SendgridEventArgs = {
  objects: Array<SendgridEvent_Insert_Input>;
  on_conflict?: InputMaybe<SendgridEvent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SendgridEvent_OneArgs = {
  object: SendgridEvent_Insert_Input;
  on_conflict?: InputMaybe<SendgridEvent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SyncZoomEventsJobArgs = {
  objects: Array<SyncZoomEventsJob_Insert_Input>;
  on_conflict?: InputMaybe<SyncZoomEventsJob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SyncZoomEventsJob_OneArgs = {
  object: SyncZoomEventsJob_Insert_Input;
  on_conflict?: InputMaybe<SyncZoomEventsJob_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TerritoryArgs = {
  objects: Array<Territory_Insert_Input>;
  on_conflict?: InputMaybe<Territory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TerritorySearchTermArgs = {
  objects: Array<TerritorySearchTerm_Insert_Input>;
  on_conflict?: InputMaybe<TerritorySearchTerm_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TerritorySearchTerm_OneArgs = {
  object: TerritorySearchTerm_Insert_Input;
  on_conflict?: InputMaybe<TerritorySearchTerm_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_OneArgs = {
  object: Territory_Insert_Input;
  on_conflict?: InputMaybe<Territory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TheOrgSearchTermArgs = {
  objects: Array<TheOrgSearchTerm_Insert_Input>;
  on_conflict?: InputMaybe<TheOrgSearchTerm_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TheOrgSearchTerm_OneArgs = {
  object: TheOrgSearchTerm_Insert_Input;
  on_conflict?: InputMaybe<TheOrgSearchTerm_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserInviteArgs = {
  objects: Array<UserInvite_Insert_Input>;
  on_conflict?: InputMaybe<UserInvite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserInvite_OneArgs = {
  object: UserInvite_Insert_Input;
  on_conflict?: InputMaybe<UserInvite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkOsConnectionArgs = {
  objects: Array<WorkOsConnection_Insert_Input>;
  on_conflict?: InputMaybe<WorkOsConnection_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkOsConnection_OneArgs = {
  object: WorkOsConnection_Insert_Input;
  on_conflict?: InputMaybe<WorkOsConnection_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkOsDirectoryArgs = {
  objects: Array<WorkOsDirectory_Insert_Input>;
  on_conflict?: InputMaybe<WorkOsDirectory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkOsDirectory_OneArgs = {
  object: WorkOsDirectory_Insert_Input;
  on_conflict?: InputMaybe<WorkOsDirectory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkOsGroupArgs = {
  objects: Array<WorkOsGroup_Insert_Input>;
  on_conflict?: InputMaybe<WorkOsGroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkOsGroup_OneArgs = {
  object: WorkOsGroup_Insert_Input;
  on_conflict?: InputMaybe<WorkOsGroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkOsUserArgs = {
  objects: Array<WorkOsUser_Insert_Input>;
  on_conflict?: InputMaybe<WorkOsUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkOsUser_OneArgs = {
  object: WorkOsUser_Insert_Input;
  on_conflict?: InputMaybe<WorkOsUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomApprovedDomainArgs = {
  objects: Array<ZoomApprovedDomain_Insert_Input>;
  on_conflict?: InputMaybe<ZoomApprovedDomain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomApprovedDomain_OneArgs = {
  object: ZoomApprovedDomain_Insert_Input;
  on_conflict?: InputMaybe<ZoomApprovedDomain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomCompanyArgs = {
  objects: Array<ZoomCompany_Insert_Input>;
  on_conflict?: InputMaybe<ZoomCompany_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomCompanyMergeResultArgs = {
  objects: Array<ZoomCompanyMergeResult_Insert_Input>;
  on_conflict?: InputMaybe<ZoomCompanyMergeResult_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomCompanyMergeResult_OneArgs = {
  object: ZoomCompanyMergeResult_Insert_Input;
  on_conflict?: InputMaybe<ZoomCompanyMergeResult_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomCompany_OneArgs = {
  object: ZoomCompany_Insert_Input;
  on_conflict?: InputMaybe<ZoomCompany_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomConnectionRequestArgs = {
  objects: Array<ZoomConnectionRequest_Insert_Input>;
  on_conflict?: InputMaybe<ZoomConnectionRequest_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomConnectionRequestReminderArgs = {
  objects: Array<ZoomConnectionRequestReminder_Insert_Input>;
  on_conflict?: InputMaybe<ZoomConnectionRequestReminder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomConnectionRequestReminder_OneArgs = {
  object: ZoomConnectionRequestReminder_Insert_Input;
  on_conflict?: InputMaybe<ZoomConnectionRequestReminder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomConnectionRequest_OneArgs = {
  object: ZoomConnectionRequest_Insert_Input;
  on_conflict?: InputMaybe<ZoomConnectionRequest_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactArgs = {
  objects: Array<ZoomContact_Insert_Input>;
  on_conflict?: InputMaybe<ZoomContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactNotesArgs = {
  objects: Array<ZoomContactNotes_Insert_Input>;
  on_conflict?: InputMaybe<ZoomContactNotes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactNotes_OneArgs = {
  object: ZoomContactNotes_Insert_Input;
  on_conflict?: InputMaybe<ZoomContactNotes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContact_OneArgs = {
  object: ZoomContact_Insert_Input;
  on_conflict?: InputMaybe<ZoomContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomConversationStarterAnswerArgs = {
  objects: Array<ZoomConversationStarterAnswer_Insert_Input>;
  on_conflict?: InputMaybe<ZoomConversationStarterAnswer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomConversationStarterAnswer_OneArgs = {
  object: ZoomConversationStarterAnswer_Insert_Input;
  on_conflict?: InputMaybe<ZoomConversationStarterAnswer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomConversationStarterPromptArgs = {
  objects: Array<ZoomConversationStarterPrompt_Insert_Input>;
  on_conflict?: InputMaybe<ZoomConversationStarterPrompt_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomConversationStarterPrompt_OneArgs = {
  object: ZoomConversationStarterPrompt_Insert_Input;
  on_conflict?: InputMaybe<ZoomConversationStarterPrompt_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomCustomBackgroundArgs = {
  objects: Array<ZoomCustomBackground_Insert_Input>;
  on_conflict?: InputMaybe<ZoomCustomBackground_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomCustomBackground_OneArgs = {
  object: ZoomCustomBackground_Insert_Input;
  on_conflict?: InputMaybe<ZoomCustomBackground_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEmailHistoryArgs = {
  objects: Array<ZoomEmailHistory_Insert_Input>;
  on_conflict?: InputMaybe<ZoomEmailHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEmailHistory_OneArgs = {
  object: ZoomEmailHistory_Insert_Input;
  on_conflict?: InputMaybe<ZoomEmailHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEventArgs = {
  objects: Array<ZoomEvent_Insert_Input>;
  on_conflict?: InputMaybe<ZoomEvent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEventParticipantArgs = {
  objects: Array<ZoomEventParticipant_Insert_Input>;
  on_conflict?: InputMaybe<ZoomEventParticipant_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEventParticipant_OneArgs = {
  object: ZoomEventParticipant_Insert_Input;
  on_conflict?: InputMaybe<ZoomEventParticipant_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEvent_OneArgs = {
  object: ZoomEvent_Insert_Input;
  on_conflict?: InputMaybe<ZoomEvent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomExternalAuthUserArgs = {
  objects: Array<ZoomExternalAuthUser_Insert_Input>;
  on_conflict?: InputMaybe<ZoomExternalAuthUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomExternalAuthUser_OneArgs = {
  object: ZoomExternalAuthUser_Insert_Input;
  on_conflict?: InputMaybe<ZoomExternalAuthUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomGroupArgs = {
  objects: Array<ZoomGroup_Insert_Input>;
  on_conflict?: InputMaybe<ZoomGroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomGroupMembershipArgs = {
  objects: Array<ZoomGroupMembership_Insert_Input>;
  on_conflict?: InputMaybe<ZoomGroupMembership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomGroupMembership_OneArgs = {
  object: ZoomGroupMembership_Insert_Input;
  on_conflict?: InputMaybe<ZoomGroupMembership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomGroup_OneArgs = {
  object: ZoomGroup_Insert_Input;
  on_conflict?: InputMaybe<ZoomGroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomLayersEventsArgs = {
  objects: Array<ZoomLayersEvents_Insert_Input>;
  on_conflict?: InputMaybe<ZoomLayersEvents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomLayersEvents_OneArgs = {
  object: ZoomLayersEvents_Insert_Input;
  on_conflict?: InputMaybe<ZoomLayersEvents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationArgs = {
  objects: Array<ZoomOrganization_Insert_Input>;
  on_conflict?: InputMaybe<ZoomOrganization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationBackfillArgs = {
  objects: Array<ZoomOrganizationBackfill_Insert_Input>;
  on_conflict?: InputMaybe<ZoomOrganizationBackfill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationBackfillResultArgs = {
  objects: Array<ZoomOrganizationBackfillResult_Insert_Input>;
  on_conflict?: InputMaybe<ZoomOrganizationBackfillResult_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationBackfillResult_OneArgs = {
  object: ZoomOrganizationBackfillResult_Insert_Input;
  on_conflict?: InputMaybe<ZoomOrganizationBackfillResult_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationBackfill_OneArgs = {
  object: ZoomOrganizationBackfill_Insert_Input;
  on_conflict?: InputMaybe<ZoomOrganizationBackfill_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationMembershipArgs = {
  objects: Array<ZoomOrganizationMembership_Insert_Input>;
  on_conflict?: InputMaybe<ZoomOrganizationMembership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationMembershipViewArgs = {
  objects: Array<ZoomOrganizationMembershipView_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationMembershipView_OneArgs = {
  object: ZoomOrganizationMembershipView_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganizationMembership_OneArgs = {
  object: ZoomOrganizationMembership_Insert_Input;
  on_conflict?: InputMaybe<ZoomOrganizationMembership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomOrganization_OneArgs = {
  object: ZoomOrganization_Insert_Input;
  on_conflict?: InputMaybe<ZoomOrganization_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomPlanArgs = {
  objects: Array<ZoomPlan_Insert_Input>;
  on_conflict?: InputMaybe<ZoomPlan_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomPlan_OneArgs = {
  object: ZoomPlan_Insert_Input;
  on_conflict?: InputMaybe<ZoomPlan_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomPresentationArgs = {
  objects: Array<ZoomPresentation_Insert_Input>;
  on_conflict?: InputMaybe<ZoomPresentation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomPresentation_OneArgs = {
  object: ZoomPresentation_Insert_Input;
  on_conflict?: InputMaybe<ZoomPresentation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomSettingsArgs = {
  objects: Array<ZoomSettings_Insert_Input>;
  on_conflict?: InputMaybe<ZoomSettings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomSettings_OneArgs = {
  object: ZoomSettings_Insert_Input;
  on_conflict?: InputMaybe<ZoomSettings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomUserArgs = {
  objects: Array<ZoomUser_Insert_Input>;
  on_conflict?: InputMaybe<ZoomUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomUserActionsArgs = {
  objects: Array<ZoomUserActions_Insert_Input>;
  on_conflict?: InputMaybe<ZoomUserActions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomUserActions_OneArgs = {
  object: ZoomUserActions_Insert_Input;
  on_conflict?: InputMaybe<ZoomUserActions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomUser_OneArgs = {
  object: ZoomUser_Insert_Input;
  on_conflict?: InputMaybe<ZoomUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootMergeCompaniesArgs = {
  companyToMerge: Scalars['String'];
  companyToMergeInto: Scalars['String'];
};

/** mutation root */
export type Mutation_RootMergeOrganizationsArgs = {
  organizationToMerge: Scalars['String'];
  organizationToMergeInto: Scalars['String'];
};

/** mutation root */
export type Mutation_RootResyncMsCalendarArgs = {
  zoomUserId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSetDefaultStripePaymentMethodArgs = {
  stripePaymentMethodId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSetZoomSettingArgs = {
  zoomSetting: Scalars['JSON'];
};

/** mutation root */
export type Mutation_RootSetZoomSettingsArgs = {
  zoomSettingRecordUpdates: Scalars['JSON'];
};

/** mutation root */
export type Mutation_RootSubmitAuditLogEventArgs = {
  action: Scalars['String'];
  actor: WorkOsAuditLogActor;
  context: WorkOsAuditLogContext;
  metadata?: InputMaybe<Scalars['JSON']>;
  occurredAt: Scalars['DateTimeISO'];
  targets: Array<WorkOsAuditLogTarget>;
  version?: InputMaybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_RootToggleMembershipTypeArgs = {
  membershipId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpdate_ClientArgs = {
  _set?: InputMaybe<Client_Set_Input>;
  where: Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactArgs = {
  _inc?: InputMaybe<ClientContact_Inc_Input>;
  _set?: InputMaybe<ClientContact_Set_Input>;
  where: ClientContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactCrmDataArgs = {
  _set?: InputMaybe<ClientContactCrmData_Set_Input>;
  where: ClientContactCrmData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactCrmData_By_PkArgs = {
  _set?: InputMaybe<ClientContactCrmData_Set_Input>;
  pk_columns: ClientContactCrmData_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactCrmData_ManyArgs = {
  updates: Array<ClientContactCrmData_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactFilterArgs = {
  _inc?: InputMaybe<ClientContactFilter_Inc_Input>;
  _set?: InputMaybe<ClientContactFilter_Set_Input>;
  where: ClientContactFilter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactFilter_By_PkArgs = {
  _inc?: InputMaybe<ClientContactFilter_Inc_Input>;
  _set?: InputMaybe<ClientContactFilter_Set_Input>;
  pk_columns: ClientContactFilter_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactFilter_ManyArgs = {
  updates: Array<ClientContactFilter_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactJobChangeArgs = {
  _set?: InputMaybe<ClientContactJobChange_Set_Input>;
  where: ClientContactJobChange_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactJobChange_By_PkArgs = {
  _set?: InputMaybe<ClientContactJobChange_Set_Input>;
  pk_columns: ClientContactJobChange_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactJobChange_ManyArgs = {
  updates: Array<ClientContactJobChange_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContact_By_PkArgs = {
  _inc?: InputMaybe<ClientContact_Inc_Input>;
  _set?: InputMaybe<ClientContact_Set_Input>;
  pk_columns: ClientContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContact_ManyArgs = {
  updates: Array<ClientContact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ClientEmailReportArgs = {
  _append?: InputMaybe<ClientEmailReport_Append_Input>;
  _delete_at_path?: InputMaybe<ClientEmailReport_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ClientEmailReport_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ClientEmailReport_Delete_Key_Input>;
  _prepend?: InputMaybe<ClientEmailReport_Prepend_Input>;
  _set?: InputMaybe<ClientEmailReport_Set_Input>;
  where: ClientEmailReport_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientEmailReport_By_PkArgs = {
  _append?: InputMaybe<ClientEmailReport_Append_Input>;
  _delete_at_path?: InputMaybe<ClientEmailReport_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ClientEmailReport_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ClientEmailReport_Delete_Key_Input>;
  _prepend?: InputMaybe<ClientEmailReport_Prepend_Input>;
  _set?: InputMaybe<ClientEmailReport_Set_Input>;
  pk_columns: ClientEmailReport_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientEmailReport_ManyArgs = {
  updates: Array<ClientEmailReport_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ClientNotificationEmailArgs = {
  _set?: InputMaybe<ClientNotificationEmail_Set_Input>;
  where: ClientNotificationEmail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientNotificationEmail_By_PkArgs = {
  _set?: InputMaybe<ClientNotificationEmail_Set_Input>;
  pk_columns: ClientNotificationEmail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientNotificationEmail_ManyArgs = {
  updates: Array<ClientNotificationEmail_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ClientTerritoryArgs = {
  _inc?: InputMaybe<ClientTerritory_Inc_Input>;
  _set?: InputMaybe<ClientTerritory_Set_Input>;
  where: ClientTerritory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientTerritory_By_PkArgs = {
  _inc?: InputMaybe<ClientTerritory_Inc_Input>;
  _set?: InputMaybe<ClientTerritory_Set_Input>;
  pk_columns: ClientTerritory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientTerritory_ManyArgs = {
  updates: Array<ClientTerritory_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_By_PkArgs = {
  _set?: InputMaybe<Client_Set_Input>;
  pk_columns: Client_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_ManyArgs = {
  updates: Array<Client_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CloudTaskArgs = {
  _append?: InputMaybe<CloudTask_Append_Input>;
  _delete_at_path?: InputMaybe<CloudTask_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CloudTask_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CloudTask_Delete_Key_Input>;
  _inc?: InputMaybe<CloudTask_Inc_Input>;
  _prepend?: InputMaybe<CloudTask_Prepend_Input>;
  _set?: InputMaybe<CloudTask_Set_Input>;
  where: CloudTask_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CloudTask_By_PkArgs = {
  _append?: InputMaybe<CloudTask_Append_Input>;
  _delete_at_path?: InputMaybe<CloudTask_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CloudTask_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CloudTask_Delete_Key_Input>;
  _inc?: InputMaybe<CloudTask_Inc_Input>;
  _prepend?: InputMaybe<CloudTask_Prepend_Input>;
  _set?: InputMaybe<CloudTask_Set_Input>;
  pk_columns: CloudTask_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CloudTask_ManyArgs = {
  updates: Array<CloudTask_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyNameArgs = {
  _set?: InputMaybe<CompanyName_Set_Input>;
  where: CompanyName_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyName_By_PkArgs = {
  _set?: InputMaybe<CompanyName_Set_Input>;
  pk_columns: CompanyName_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyName_ManyArgs = {
  updates: Array<CompanyName_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Company_ManyArgs = {
  updates: Array<Company_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContactDataArgs = {
  _set?: InputMaybe<ContactData_Set_Input>;
  where: ContactData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ContactData_By_PkArgs = {
  _set?: InputMaybe<ContactData_Set_Input>;
  pk_columns: ContactData_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ContactData_ManyArgs = {
  updates: Array<ContactData_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContactEmailArgs = {
  _set?: InputMaybe<ContactEmail_Set_Input>;
  where: ContactEmail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ContactEmail_By_PkArgs = {
  _set?: InputMaybe<ContactEmail_Set_Input>;
  pk_columns: ContactEmail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ContactEmail_ManyArgs = {
  updates: Array<ContactEmail_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContentArgs = {
  _append?: InputMaybe<Content_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Inc_Input>;
  _prepend?: InputMaybe<Content_Prepend_Input>;
  _set?: InputMaybe<Content_Set_Input>;
  where: Content_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Content_By_PkArgs = {
  _append?: InputMaybe<Content_Append_Input>;
  _delete_at_path?: InputMaybe<Content_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Content_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Content_Delete_Key_Input>;
  _inc?: InputMaybe<Content_Inc_Input>;
  _prepend?: InputMaybe<Content_Prepend_Input>;
  _set?: InputMaybe<Content_Set_Input>;
  pk_columns: Content_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Content_ManyArgs = {
  updates: Array<Content_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CrmContactEnrichmentHistoryArgs = {
  _set?: InputMaybe<CrmContactEnrichmentHistory_Set_Input>;
  where: CrmContactEnrichmentHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CrmContactEnrichmentHistory_By_PkArgs = {
  _set?: InputMaybe<CrmContactEnrichmentHistory_Set_Input>;
  pk_columns: CrmContactEnrichmentHistory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CrmContactEnrichmentHistory_ManyArgs = {
  updates: Array<CrmContactEnrichmentHistory_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CsvUploadArgs = {
  _append?: InputMaybe<CsvUpload_Append_Input>;
  _delete_at_path?: InputMaybe<CsvUpload_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CsvUpload_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CsvUpload_Delete_Key_Input>;
  _inc?: InputMaybe<CsvUpload_Inc_Input>;
  _prepend?: InputMaybe<CsvUpload_Prepend_Input>;
  _set?: InputMaybe<CsvUpload_Set_Input>;
  where: CsvUpload_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CsvUpload_By_PkArgs = {
  _append?: InputMaybe<CsvUpload_Append_Input>;
  _delete_at_path?: InputMaybe<CsvUpload_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CsvUpload_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CsvUpload_Delete_Key_Input>;
  _inc?: InputMaybe<CsvUpload_Inc_Input>;
  _prepend?: InputMaybe<CsvUpload_Prepend_Input>;
  _set?: InputMaybe<CsvUpload_Set_Input>;
  pk_columns: CsvUpload_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CsvUpload_ManyArgs = {
  updates: Array<CsvUpload_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DeleteOldZoomEventsJobArgs = {
  _inc?: InputMaybe<DeleteOldZoomEventsJob_Inc_Input>;
  _set?: InputMaybe<DeleteOldZoomEventsJob_Set_Input>;
  where: DeleteOldZoomEventsJob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_DeleteOldZoomEventsJob_By_PkArgs = {
  _inc?: InputMaybe<DeleteOldZoomEventsJob_Inc_Input>;
  _set?: InputMaybe<DeleteOldZoomEventsJob_Set_Input>;
  pk_columns: DeleteOldZoomEventsJob_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_DeleteOldZoomEventsJob_ManyArgs = {
  updates: Array<DeleteOldZoomEventsJob_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_EmailHistoryArgs = {
  _set?: InputMaybe<EmailHistory_Set_Input>;
  where: EmailHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_EmailHistory_By_PkArgs = {
  _set?: InputMaybe<EmailHistory_Set_Input>;
  pk_columns: EmailHistory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_EmailHistory_ManyArgs = {
  updates: Array<EmailHistory_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GoogleChannelArgs = {
  _inc?: InputMaybe<GoogleChannel_Inc_Input>;
  _set?: InputMaybe<GoogleChannel_Set_Input>;
  where: GoogleChannel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GoogleChannel_By_PkArgs = {
  _inc?: InputMaybe<GoogleChannel_Inc_Input>;
  _set?: InputMaybe<GoogleChannel_Set_Input>;
  pk_columns: GoogleChannel_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GoogleChannel_ManyArgs = {
  updates: Array<GoogleChannel_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GrowthIntraCompanyAdoptionContactArgs = {
  _set?: InputMaybe<GrowthIntraCompanyAdoptionContact_Set_Input>;
  where: GrowthIntraCompanyAdoptionContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GrowthIntraCompanyAdoptionContact_By_PkArgs = {
  _set?: InputMaybe<GrowthIntraCompanyAdoptionContact_Set_Input>;
  pk_columns: GrowthIntraCompanyAdoptionContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GrowthIntraCompanyAdoptionContact_ManyArgs = {
  updates: Array<GrowthIntraCompanyAdoptionContact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GrowthUserReferralContactArgs = {
  _set?: InputMaybe<GrowthUserReferralContact_Set_Input>;
  where: GrowthUserReferralContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GrowthUserReferralContact_By_PkArgs = {
  _set?: InputMaybe<GrowthUserReferralContact_Set_Input>;
  pk_columns: GrowthUserReferralContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GrowthUserReferralContact_ManyArgs = {
  updates: Array<GrowthUserReferralContact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactListArgs = {
  _append?: InputMaybe<HubspotContactList_Append_Input>;
  _delete_at_path?: InputMaybe<HubspotContactList_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<HubspotContactList_Delete_Elem_Input>;
  _delete_key?: InputMaybe<HubspotContactList_Delete_Key_Input>;
  _inc?: InputMaybe<HubspotContactList_Inc_Input>;
  _prepend?: InputMaybe<HubspotContactList_Prepend_Input>;
  _set?: InputMaybe<HubspotContactList_Set_Input>;
  where: HubspotContactList_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactListHubspotContactArgs = {
  _set?: InputMaybe<HubspotContactListHubspotContact_Set_Input>;
  where: HubspotContactListHubspotContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactListHubspotContact_By_PkArgs = {
  _set?: InputMaybe<HubspotContactListHubspotContact_Set_Input>;
  pk_columns: HubspotContactListHubspotContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactListHubspotContact_ManyArgs = {
  updates: Array<HubspotContactListHubspotContact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactList_By_PkArgs = {
  _append?: InputMaybe<HubspotContactList_Append_Input>;
  _delete_at_path?: InputMaybe<HubspotContactList_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<HubspotContactList_Delete_Elem_Input>;
  _delete_key?: InputMaybe<HubspotContactList_Delete_Key_Input>;
  _inc?: InputMaybe<HubspotContactList_Inc_Input>;
  _prepend?: InputMaybe<HubspotContactList_Prepend_Input>;
  _set?: InputMaybe<HubspotContactList_Set_Input>;
  pk_columns: HubspotContactList_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactList_ManyArgs = {
  updates: Array<HubspotContactList_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactPropertyArgs = {
  _append?: InputMaybe<HubspotContactProperty_Append_Input>;
  _delete_at_path?: InputMaybe<HubspotContactProperty_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<HubspotContactProperty_Delete_Elem_Input>;
  _delete_key?: InputMaybe<HubspotContactProperty_Delete_Key_Input>;
  _prepend?: InputMaybe<HubspotContactProperty_Prepend_Input>;
  _set?: InputMaybe<HubspotContactProperty_Set_Input>;
  where: HubspotContactProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactProperty_By_PkArgs = {
  _append?: InputMaybe<HubspotContactProperty_Append_Input>;
  _delete_at_path?: InputMaybe<HubspotContactProperty_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<HubspotContactProperty_Delete_Elem_Input>;
  _delete_key?: InputMaybe<HubspotContactProperty_Delete_Key_Input>;
  _prepend?: InputMaybe<HubspotContactProperty_Prepend_Input>;
  _set?: InputMaybe<HubspotContactProperty_Set_Input>;
  pk_columns: HubspotContactProperty_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactProperty_ManyArgs = {
  updates: Array<HubspotContactProperty_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotIntegrationArgs = {
  _append?: InputMaybe<HubspotIntegration_Append_Input>;
  _delete_at_path?: InputMaybe<HubspotIntegration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<HubspotIntegration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<HubspotIntegration_Delete_Key_Input>;
  _inc?: InputMaybe<HubspotIntegration_Inc_Input>;
  _prepend?: InputMaybe<HubspotIntegration_Prepend_Input>;
  _set?: InputMaybe<HubspotIntegration_Set_Input>;
  where: HubspotIntegration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotIntegration_By_PkArgs = {
  _append?: InputMaybe<HubspotIntegration_Append_Input>;
  _delete_at_path?: InputMaybe<HubspotIntegration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<HubspotIntegration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<HubspotIntegration_Delete_Key_Input>;
  _inc?: InputMaybe<HubspotIntegration_Inc_Input>;
  _prepend?: InputMaybe<HubspotIntegration_Prepend_Input>;
  _set?: InputMaybe<HubspotIntegration_Set_Input>;
  pk_columns: HubspotIntegration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotIntegration_ManyArgs = {
  updates: Array<HubspotIntegration_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_JobArgs = {
  _set?: InputMaybe<Job_Set_Input>;
  where: Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_By_PkArgs = {
  _set?: InputMaybe<Job_Set_Input>;
  pk_columns: Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Job_ManyArgs = {
  updates: Array<Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_MicrosoftSubscriptionArgs = {
  _set?: InputMaybe<MicrosoftSubscription_Set_Input>;
  where: MicrosoftSubscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_MicrosoftSubscription_By_PkArgs = {
  _set?: InputMaybe<MicrosoftSubscription_Set_Input>;
  pk_columns: MicrosoftSubscription_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MicrosoftSubscription_ManyArgs = {
  updates: Array<MicrosoftSubscription_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProxycrawlDataArgs = {
  _append?: InputMaybe<ProxycrawlData_Append_Input>;
  _delete_at_path?: InputMaybe<ProxycrawlData_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ProxycrawlData_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ProxycrawlData_Delete_Key_Input>;
  _prepend?: InputMaybe<ProxycrawlData_Prepend_Input>;
  _set?: InputMaybe<ProxycrawlData_Set_Input>;
  where: ProxycrawlData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ProxycrawlData_By_PkArgs = {
  _append?: InputMaybe<ProxycrawlData_Append_Input>;
  _delete_at_path?: InputMaybe<ProxycrawlData_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ProxycrawlData_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ProxycrawlData_Delete_Key_Input>;
  _prepend?: InputMaybe<ProxycrawlData_Prepend_Input>;
  _set?: InputMaybe<ProxycrawlData_Set_Input>;
  pk_columns: ProxycrawlData_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ProxycrawlData_ManyArgs = {
  updates: Array<ProxycrawlData_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_QaQualificationArgs = {
  _set?: InputMaybe<QaQualification_Set_Input>;
  where: QaQualification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_QaQualification_By_PkArgs = {
  _set?: InputMaybe<QaQualification_Set_Input>;
  pk_columns: QaQualification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_QaQualification_ManyArgs = {
  updates: Array<QaQualification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_QaTaskArgs = {
  _inc?: InputMaybe<QaTask_Inc_Input>;
  _set?: InputMaybe<QaTask_Set_Input>;
  where: QaTask_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_QaTaskActionArgs = {
  _inc?: InputMaybe<QaTaskAction_Inc_Input>;
  _set?: InputMaybe<QaTaskAction_Set_Input>;
  where: QaTaskAction_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_QaTaskAction_By_PkArgs = {
  _inc?: InputMaybe<QaTaskAction_Inc_Input>;
  _set?: InputMaybe<QaTaskAction_Set_Input>;
  pk_columns: QaTaskAction_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_QaTaskAction_ManyArgs = {
  updates: Array<QaTaskAction_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_QaTask_By_PkArgs = {
  _inc?: InputMaybe<QaTask_Inc_Input>;
  _set?: InputMaybe<QaTask_Set_Input>;
  pk_columns: QaTask_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_QaTask_ManyArgs = {
  updates: Array<QaTask_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceAccountArgs = {
  _set?: InputMaybe<SalesforceAccount_Set_Input>;
  where: SalesforceAccount_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceAccount_By_PkArgs = {
  _set?: InputMaybe<SalesforceAccount_Set_Input>;
  pk_columns: SalesforceAccount_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceAccount_ManyArgs = {
  updates: Array<SalesforceAccount_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceContactFieldArgs = {
  _append?: InputMaybe<SalesforceContactField_Append_Input>;
  _delete_at_path?: InputMaybe<SalesforceContactField_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<SalesforceContactField_Delete_Elem_Input>;
  _delete_key?: InputMaybe<SalesforceContactField_Delete_Key_Input>;
  _prepend?: InputMaybe<SalesforceContactField_Prepend_Input>;
  _set?: InputMaybe<SalesforceContactField_Set_Input>;
  where: SalesforceContactField_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceContactField_By_PkArgs = {
  _append?: InputMaybe<SalesforceContactField_Append_Input>;
  _delete_at_path?: InputMaybe<SalesforceContactField_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<SalesforceContactField_Delete_Elem_Input>;
  _delete_key?: InputMaybe<SalesforceContactField_Delete_Key_Input>;
  _prepend?: InputMaybe<SalesforceContactField_Prepend_Input>;
  _set?: InputMaybe<SalesforceContactField_Set_Input>;
  pk_columns: SalesforceContactField_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceContactField_ManyArgs = {
  updates: Array<SalesforceContactField_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceIntegrationArgs = {
  _append?: InputMaybe<SalesforceIntegration_Append_Input>;
  _delete_at_path?: InputMaybe<SalesforceIntegration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<SalesforceIntegration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<SalesforceIntegration_Delete_Key_Input>;
  _inc?: InputMaybe<SalesforceIntegration_Inc_Input>;
  _prepend?: InputMaybe<SalesforceIntegration_Prepend_Input>;
  _set?: InputMaybe<SalesforceIntegration_Set_Input>;
  where: SalesforceIntegration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceIntegration_By_PkArgs = {
  _append?: InputMaybe<SalesforceIntegration_Append_Input>;
  _delete_at_path?: InputMaybe<SalesforceIntegration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<SalesforceIntegration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<SalesforceIntegration_Delete_Key_Input>;
  _inc?: InputMaybe<SalesforceIntegration_Inc_Input>;
  _prepend?: InputMaybe<SalesforceIntegration_Prepend_Input>;
  _set?: InputMaybe<SalesforceIntegration_Set_Input>;
  pk_columns: SalesforceIntegration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceIntegration_ManyArgs = {
  updates: Array<SalesforceIntegration_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceOpportunityArgs = {
  _set?: InputMaybe<SalesforceOpportunity_Set_Input>;
  where: SalesforceOpportunity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceOpportunity_By_PkArgs = {
  _set?: InputMaybe<SalesforceOpportunity_Set_Input>;
  pk_columns: SalesforceOpportunity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceOpportunity_ManyArgs = {
  updates: Array<SalesforceOpportunity_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SendgridEventArgs = {
  _append?: InputMaybe<SendgridEvent_Append_Input>;
  _delete_at_path?: InputMaybe<SendgridEvent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<SendgridEvent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<SendgridEvent_Delete_Key_Input>;
  _prepend?: InputMaybe<SendgridEvent_Prepend_Input>;
  _set?: InputMaybe<SendgridEvent_Set_Input>;
  where: SendgridEvent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SendgridEvent_By_PkArgs = {
  _append?: InputMaybe<SendgridEvent_Append_Input>;
  _delete_at_path?: InputMaybe<SendgridEvent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<SendgridEvent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<SendgridEvent_Delete_Key_Input>;
  _prepend?: InputMaybe<SendgridEvent_Prepend_Input>;
  _set?: InputMaybe<SendgridEvent_Set_Input>;
  pk_columns: SendgridEvent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SendgridEvent_ManyArgs = {
  updates: Array<SendgridEvent_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SyncZoomEventsJobArgs = {
  _inc?: InputMaybe<SyncZoomEventsJob_Inc_Input>;
  _set?: InputMaybe<SyncZoomEventsJob_Set_Input>;
  where: SyncZoomEventsJob_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SyncZoomEventsJob_By_PkArgs = {
  _inc?: InputMaybe<SyncZoomEventsJob_Inc_Input>;
  _set?: InputMaybe<SyncZoomEventsJob_Set_Input>;
  pk_columns: SyncZoomEventsJob_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SyncZoomEventsJob_ManyArgs = {
  updates: Array<SyncZoomEventsJob_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TerritoryArgs = {
  _append?: InputMaybe<Territory_Append_Input>;
  _delete_at_path?: InputMaybe<Territory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Territory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Territory_Delete_Key_Input>;
  _prepend?: InputMaybe<Territory_Prepend_Input>;
  _set?: InputMaybe<Territory_Set_Input>;
  where: Territory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TerritorySearchTermArgs = {
  _set?: InputMaybe<TerritorySearchTerm_Set_Input>;
  where: TerritorySearchTerm_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TerritorySearchTerm_By_PkArgs = {
  _set?: InputMaybe<TerritorySearchTerm_Set_Input>;
  pk_columns: TerritorySearchTerm_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TerritorySearchTerm_ManyArgs = {
  updates: Array<TerritorySearchTerm_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_By_PkArgs = {
  _append?: InputMaybe<Territory_Append_Input>;
  _delete_at_path?: InputMaybe<Territory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Territory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Territory_Delete_Key_Input>;
  _prepend?: InputMaybe<Territory_Prepend_Input>;
  _set?: InputMaybe<Territory_Set_Input>;
  pk_columns: Territory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_ManyArgs = {
  updates: Array<Territory_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TheOrgSearchTermArgs = {
  _append?: InputMaybe<TheOrgSearchTerm_Append_Input>;
  _delete_at_path?: InputMaybe<TheOrgSearchTerm_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<TheOrgSearchTerm_Delete_Elem_Input>;
  _delete_key?: InputMaybe<TheOrgSearchTerm_Delete_Key_Input>;
  _prepend?: InputMaybe<TheOrgSearchTerm_Prepend_Input>;
  _set?: InputMaybe<TheOrgSearchTerm_Set_Input>;
  where: TheOrgSearchTerm_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TheOrgSearchTerm_By_PkArgs = {
  _append?: InputMaybe<TheOrgSearchTerm_Append_Input>;
  _delete_at_path?: InputMaybe<TheOrgSearchTerm_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<TheOrgSearchTerm_Delete_Elem_Input>;
  _delete_key?: InputMaybe<TheOrgSearchTerm_Delete_Key_Input>;
  _prepend?: InputMaybe<TheOrgSearchTerm_Prepend_Input>;
  _set?: InputMaybe<TheOrgSearchTerm_Set_Input>;
  pk_columns: TheOrgSearchTerm_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TheOrgSearchTerm_ManyArgs = {
  updates: Array<TheOrgSearchTerm_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserInviteArgs = {
  _set?: InputMaybe<UserInvite_Set_Input>;
  where: UserInvite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserInvite_By_PkArgs = {
  _set?: InputMaybe<UserInvite_Set_Input>;
  pk_columns: UserInvite_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserInvite_ManyArgs = {
  updates: Array<UserInvite_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsConnectionArgs = {
  _append?: InputMaybe<WorkOsConnection_Append_Input>;
  _delete_at_path?: InputMaybe<WorkOsConnection_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkOsConnection_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkOsConnection_Delete_Key_Input>;
  _prepend?: InputMaybe<WorkOsConnection_Prepend_Input>;
  _set?: InputMaybe<WorkOsConnection_Set_Input>;
  where: WorkOsConnection_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsConnection_By_PkArgs = {
  _append?: InputMaybe<WorkOsConnection_Append_Input>;
  _delete_at_path?: InputMaybe<WorkOsConnection_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkOsConnection_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkOsConnection_Delete_Key_Input>;
  _prepend?: InputMaybe<WorkOsConnection_Prepend_Input>;
  _set?: InputMaybe<WorkOsConnection_Set_Input>;
  pk_columns: WorkOsConnection_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsConnection_ManyArgs = {
  updates: Array<WorkOsConnection_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsDirectoryArgs = {
  _append?: InputMaybe<WorkOsDirectory_Append_Input>;
  _delete_at_path?: InputMaybe<WorkOsDirectory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkOsDirectory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkOsDirectory_Delete_Key_Input>;
  _prepend?: InputMaybe<WorkOsDirectory_Prepend_Input>;
  _set?: InputMaybe<WorkOsDirectory_Set_Input>;
  where: WorkOsDirectory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsDirectory_By_PkArgs = {
  _append?: InputMaybe<WorkOsDirectory_Append_Input>;
  _delete_at_path?: InputMaybe<WorkOsDirectory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkOsDirectory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkOsDirectory_Delete_Key_Input>;
  _prepend?: InputMaybe<WorkOsDirectory_Prepend_Input>;
  _set?: InputMaybe<WorkOsDirectory_Set_Input>;
  pk_columns: WorkOsDirectory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsDirectory_ManyArgs = {
  updates: Array<WorkOsDirectory_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsGroupArgs = {
  _append?: InputMaybe<WorkOsGroup_Append_Input>;
  _delete_at_path?: InputMaybe<WorkOsGroup_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkOsGroup_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkOsGroup_Delete_Key_Input>;
  _prepend?: InputMaybe<WorkOsGroup_Prepend_Input>;
  _set?: InputMaybe<WorkOsGroup_Set_Input>;
  where: WorkOsGroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsGroup_By_PkArgs = {
  _append?: InputMaybe<WorkOsGroup_Append_Input>;
  _delete_at_path?: InputMaybe<WorkOsGroup_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkOsGroup_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkOsGroup_Delete_Key_Input>;
  _prepend?: InputMaybe<WorkOsGroup_Prepend_Input>;
  _set?: InputMaybe<WorkOsGroup_Set_Input>;
  pk_columns: WorkOsGroup_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsGroup_ManyArgs = {
  updates: Array<WorkOsGroup_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsUserArgs = {
  _append?: InputMaybe<WorkOsUser_Append_Input>;
  _delete_at_path?: InputMaybe<WorkOsUser_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkOsUser_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkOsUser_Delete_Key_Input>;
  _prepend?: InputMaybe<WorkOsUser_Prepend_Input>;
  _set?: InputMaybe<WorkOsUser_Set_Input>;
  where: WorkOsUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsUser_By_PkArgs = {
  _append?: InputMaybe<WorkOsUser_Append_Input>;
  _delete_at_path?: InputMaybe<WorkOsUser_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WorkOsUser_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WorkOsUser_Delete_Key_Input>;
  _prepend?: InputMaybe<WorkOsUser_Prepend_Input>;
  _set?: InputMaybe<WorkOsUser_Set_Input>;
  pk_columns: WorkOsUser_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_WorkOsUser_ManyArgs = {
  updates: Array<WorkOsUser_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomApprovedDomainArgs = {
  _set?: InputMaybe<ZoomApprovedDomain_Set_Input>;
  where: ZoomApprovedDomain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomApprovedDomain_By_PkArgs = {
  _set?: InputMaybe<ZoomApprovedDomain_Set_Input>;
  pk_columns: ZoomApprovedDomain_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomApprovedDomain_ManyArgs = {
  updates: Array<ZoomApprovedDomain_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCompanyArgs = {
  _append?: InputMaybe<ZoomCompany_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomCompany_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomCompany_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomCompany_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomCompany_Prepend_Input>;
  _set?: InputMaybe<ZoomCompany_Set_Input>;
  where: ZoomCompany_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCompanyMergeResultArgs = {
  _set?: InputMaybe<ZoomCompanyMergeResult_Set_Input>;
  where: ZoomCompanyMergeResult_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCompanyMergeResult_By_PkArgs = {
  _set?: InputMaybe<ZoomCompanyMergeResult_Set_Input>;
  pk_columns: ZoomCompanyMergeResult_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCompanyMergeResult_ManyArgs = {
  updates: Array<ZoomCompanyMergeResult_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCompany_By_PkArgs = {
  _append?: InputMaybe<ZoomCompany_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomCompany_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomCompany_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomCompany_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomCompany_Prepend_Input>;
  _set?: InputMaybe<ZoomCompany_Set_Input>;
  pk_columns: ZoomCompany_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCompany_ManyArgs = {
  updates: Array<ZoomCompany_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConnectionRequestArgs = {
  _set?: InputMaybe<ZoomConnectionRequest_Set_Input>;
  where: ZoomConnectionRequest_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConnectionRequestReminderArgs = {
  _inc?: InputMaybe<ZoomConnectionRequestReminder_Inc_Input>;
  _set?: InputMaybe<ZoomConnectionRequestReminder_Set_Input>;
  where: ZoomConnectionRequestReminder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConnectionRequestReminder_By_PkArgs = {
  _inc?: InputMaybe<ZoomConnectionRequestReminder_Inc_Input>;
  _set?: InputMaybe<ZoomConnectionRequestReminder_Set_Input>;
  pk_columns: ZoomConnectionRequestReminder_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConnectionRequestReminder_ManyArgs = {
  updates: Array<ZoomConnectionRequestReminder_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConnectionRequest_By_PkArgs = {
  _set?: InputMaybe<ZoomConnectionRequest_Set_Input>;
  pk_columns: ZoomConnectionRequest_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConnectionRequest_ManyArgs = {
  updates: Array<ZoomConnectionRequest_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactArgs = {
  _append?: InputMaybe<ZoomContact_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomContact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomContact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomContact_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomContact_Prepend_Input>;
  _set?: InputMaybe<ZoomContact_Set_Input>;
  where: ZoomContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactNotesArgs = {
  _append?: InputMaybe<ZoomContactNotes_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomContactNotes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomContactNotes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomContactNotes_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomContactNotes_Prepend_Input>;
  _set?: InputMaybe<ZoomContactNotes_Set_Input>;
  where: ZoomContactNotes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactNotes_By_PkArgs = {
  _append?: InputMaybe<ZoomContactNotes_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomContactNotes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomContactNotes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomContactNotes_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomContactNotes_Prepend_Input>;
  _set?: InputMaybe<ZoomContactNotes_Set_Input>;
  pk_columns: ZoomContactNotes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactNotes_ManyArgs = {
  updates: Array<ZoomContactNotes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContact_By_PkArgs = {
  _append?: InputMaybe<ZoomContact_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomContact_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomContact_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomContact_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomContact_Prepend_Input>;
  _set?: InputMaybe<ZoomContact_Set_Input>;
  pk_columns: ZoomContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContact_ManyArgs = {
  updates: Array<ZoomContact_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConversationStarterAnswerArgs = {
  _set?: InputMaybe<ZoomConversationStarterAnswer_Set_Input>;
  where: ZoomConversationStarterAnswer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConversationStarterAnswer_By_PkArgs = {
  _set?: InputMaybe<ZoomConversationStarterAnswer_Set_Input>;
  pk_columns: ZoomConversationStarterAnswer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConversationStarterAnswer_ManyArgs = {
  updates: Array<ZoomConversationStarterAnswer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConversationStarterPromptArgs = {
  _set?: InputMaybe<ZoomConversationStarterPrompt_Set_Input>;
  where: ZoomConversationStarterPrompt_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConversationStarterPrompt_By_PkArgs = {
  _set?: InputMaybe<ZoomConversationStarterPrompt_Set_Input>;
  pk_columns: ZoomConversationStarterPrompt_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomConversationStarterPrompt_ManyArgs = {
  updates: Array<ZoomConversationStarterPrompt_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCustomBackgroundArgs = {
  _inc?: InputMaybe<ZoomCustomBackground_Inc_Input>;
  _set?: InputMaybe<ZoomCustomBackground_Set_Input>;
  where: ZoomCustomBackground_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCustomBackground_By_PkArgs = {
  _inc?: InputMaybe<ZoomCustomBackground_Inc_Input>;
  _set?: InputMaybe<ZoomCustomBackground_Set_Input>;
  pk_columns: ZoomCustomBackground_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCustomBackground_ManyArgs = {
  updates: Array<ZoomCustomBackground_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEmailHistoryArgs = {
  _append?: InputMaybe<ZoomEmailHistory_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomEmailHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomEmailHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomEmailHistory_Delete_Key_Input>;
  _inc?: InputMaybe<ZoomEmailHistory_Inc_Input>;
  _prepend?: InputMaybe<ZoomEmailHistory_Prepend_Input>;
  _set?: InputMaybe<ZoomEmailHistory_Set_Input>;
  where: ZoomEmailHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEmailHistory_By_PkArgs = {
  _append?: InputMaybe<ZoomEmailHistory_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomEmailHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomEmailHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomEmailHistory_Delete_Key_Input>;
  _inc?: InputMaybe<ZoomEmailHistory_Inc_Input>;
  _prepend?: InputMaybe<ZoomEmailHistory_Prepend_Input>;
  _set?: InputMaybe<ZoomEmailHistory_Set_Input>;
  pk_columns: ZoomEmailHistory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEmailHistory_ManyArgs = {
  updates: Array<ZoomEmailHistory_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEventArgs = {
  _append?: InputMaybe<ZoomEvent_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomEvent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomEvent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomEvent_Delete_Key_Input>;
  _inc?: InputMaybe<ZoomEvent_Inc_Input>;
  _prepend?: InputMaybe<ZoomEvent_Prepend_Input>;
  _set?: InputMaybe<ZoomEvent_Set_Input>;
  where: ZoomEvent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEventParticipantArgs = {
  _inc?: InputMaybe<ZoomEventParticipant_Inc_Input>;
  _set?: InputMaybe<ZoomEventParticipant_Set_Input>;
  where: ZoomEventParticipant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEventParticipant_By_PkArgs = {
  _inc?: InputMaybe<ZoomEventParticipant_Inc_Input>;
  _set?: InputMaybe<ZoomEventParticipant_Set_Input>;
  pk_columns: ZoomEventParticipant_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEventParticipant_ManyArgs = {
  updates: Array<ZoomEventParticipant_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEvent_By_PkArgs = {
  _append?: InputMaybe<ZoomEvent_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomEvent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomEvent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomEvent_Delete_Key_Input>;
  _inc?: InputMaybe<ZoomEvent_Inc_Input>;
  _prepend?: InputMaybe<ZoomEvent_Prepend_Input>;
  _set?: InputMaybe<ZoomEvent_Set_Input>;
  pk_columns: ZoomEvent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEvent_ManyArgs = {
  updates: Array<ZoomEvent_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomExternalAuthUserArgs = {
  _append?: InputMaybe<ZoomExternalAuthUser_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomExternalAuthUser_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomExternalAuthUser_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomExternalAuthUser_Delete_Key_Input>;
  _inc?: InputMaybe<ZoomExternalAuthUser_Inc_Input>;
  _prepend?: InputMaybe<ZoomExternalAuthUser_Prepend_Input>;
  _set?: InputMaybe<ZoomExternalAuthUser_Set_Input>;
  where: ZoomExternalAuthUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomExternalAuthUser_By_PkArgs = {
  _append?: InputMaybe<ZoomExternalAuthUser_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomExternalAuthUser_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomExternalAuthUser_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomExternalAuthUser_Delete_Key_Input>;
  _inc?: InputMaybe<ZoomExternalAuthUser_Inc_Input>;
  _prepend?: InputMaybe<ZoomExternalAuthUser_Prepend_Input>;
  _set?: InputMaybe<ZoomExternalAuthUser_Set_Input>;
  pk_columns: ZoomExternalAuthUser_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomExternalAuthUser_ManyArgs = {
  updates: Array<ZoomExternalAuthUser_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomGroupArgs = {
  _set?: InputMaybe<ZoomGroup_Set_Input>;
  where: ZoomGroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomGroupMembershipArgs = {
  _set?: InputMaybe<ZoomGroupMembership_Set_Input>;
  where: ZoomGroupMembership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomGroupMembership_By_PkArgs = {
  _set?: InputMaybe<ZoomGroupMembership_Set_Input>;
  pk_columns: ZoomGroupMembership_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomGroupMembership_ManyArgs = {
  updates: Array<ZoomGroupMembership_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomGroup_By_PkArgs = {
  _set?: InputMaybe<ZoomGroup_Set_Input>;
  pk_columns: ZoomGroup_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomGroup_ManyArgs = {
  updates: Array<ZoomGroup_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomLayersEventsArgs = {
  _append?: InputMaybe<ZoomLayersEvents_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomLayersEvents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomLayersEvents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomLayersEvents_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomLayersEvents_Prepend_Input>;
  _set?: InputMaybe<ZoomLayersEvents_Set_Input>;
  where: ZoomLayersEvents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomLayersEvents_By_PkArgs = {
  _append?: InputMaybe<ZoomLayersEvents_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomLayersEvents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomLayersEvents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomLayersEvents_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomLayersEvents_Prepend_Input>;
  _set?: InputMaybe<ZoomLayersEvents_Set_Input>;
  pk_columns: ZoomLayersEvents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomLayersEvents_ManyArgs = {
  updates: Array<ZoomLayersEvents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationArgs = {
  _append?: InputMaybe<ZoomOrganization_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomOrganization_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomOrganization_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomOrganization_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomOrganization_Prepend_Input>;
  _set?: InputMaybe<ZoomOrganization_Set_Input>;
  where: ZoomOrganization_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationBackfillArgs = {
  _inc?: InputMaybe<ZoomOrganizationBackfill_Inc_Input>;
  _set?: InputMaybe<ZoomOrganizationBackfill_Set_Input>;
  where: ZoomOrganizationBackfill_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationBackfillResultArgs = {
  _set?: InputMaybe<ZoomOrganizationBackfillResult_Set_Input>;
  where: ZoomOrganizationBackfillResult_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationBackfillResult_By_PkArgs = {
  _set?: InputMaybe<ZoomOrganizationBackfillResult_Set_Input>;
  pk_columns: ZoomOrganizationBackfillResult_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationBackfillResult_ManyArgs = {
  updates: Array<ZoomOrganizationBackfillResult_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationBackfill_By_PkArgs = {
  _inc?: InputMaybe<ZoomOrganizationBackfill_Inc_Input>;
  _set?: InputMaybe<ZoomOrganizationBackfill_Set_Input>;
  pk_columns: ZoomOrganizationBackfill_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationBackfill_ManyArgs = {
  updates: Array<ZoomOrganizationBackfill_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationMembershipArgs = {
  _set?: InputMaybe<ZoomOrganizationMembership_Set_Input>;
  where: ZoomOrganizationMembership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationMembershipViewArgs = {
  _set?: InputMaybe<ZoomOrganizationMembershipView_Set_Input>;
  where: ZoomOrganizationMembershipView_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationMembershipView_ManyArgs = {
  updates: Array<ZoomOrganizationMembershipView_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationMembership_By_PkArgs = {
  _set?: InputMaybe<ZoomOrganizationMembership_Set_Input>;
  pk_columns: ZoomOrganizationMembership_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganizationMembership_ManyArgs = {
  updates: Array<ZoomOrganizationMembership_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganization_By_PkArgs = {
  _append?: InputMaybe<ZoomOrganization_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomOrganization_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomOrganization_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomOrganization_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomOrganization_Prepend_Input>;
  _set?: InputMaybe<ZoomOrganization_Set_Input>;
  pk_columns: ZoomOrganization_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomOrganization_ManyArgs = {
  updates: Array<ZoomOrganization_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomPlanArgs = {
  _append?: InputMaybe<ZoomPlan_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomPlan_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomPlan_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomPlan_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomPlan_Prepend_Input>;
  _set?: InputMaybe<ZoomPlan_Set_Input>;
  where: ZoomPlan_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomPlan_By_PkArgs = {
  _append?: InputMaybe<ZoomPlan_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomPlan_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomPlan_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomPlan_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomPlan_Prepend_Input>;
  _set?: InputMaybe<ZoomPlan_Set_Input>;
  pk_columns: ZoomPlan_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomPlan_ManyArgs = {
  updates: Array<ZoomPlan_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomPresentationArgs = {
  _inc?: InputMaybe<ZoomPresentation_Inc_Input>;
  _set?: InputMaybe<ZoomPresentation_Set_Input>;
  where: ZoomPresentation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomPresentation_By_PkArgs = {
  _inc?: InputMaybe<ZoomPresentation_Inc_Input>;
  _set?: InputMaybe<ZoomPresentation_Set_Input>;
  pk_columns: ZoomPresentation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomPresentation_ManyArgs = {
  updates: Array<ZoomPresentation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomSettingsArgs = {
  _append?: InputMaybe<ZoomSettings_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomSettings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomSettings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomSettings_Delete_Key_Input>;
  _inc?: InputMaybe<ZoomSettings_Inc_Input>;
  _prepend?: InputMaybe<ZoomSettings_Prepend_Input>;
  _set?: InputMaybe<ZoomSettings_Set_Input>;
  where: ZoomSettings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomSettings_By_PkArgs = {
  _append?: InputMaybe<ZoomSettings_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomSettings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomSettings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomSettings_Delete_Key_Input>;
  _inc?: InputMaybe<ZoomSettings_Inc_Input>;
  _prepend?: InputMaybe<ZoomSettings_Prepend_Input>;
  _set?: InputMaybe<ZoomSettings_Set_Input>;
  pk_columns: ZoomSettings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomSettings_ManyArgs = {
  updates: Array<ZoomSettings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomUserArgs = {
  _append?: InputMaybe<ZoomUser_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomUser_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomUser_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomUser_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomUser_Prepend_Input>;
  _set?: InputMaybe<ZoomUser_Set_Input>;
  where: ZoomUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomUserActionsArgs = {
  _set?: InputMaybe<ZoomUserActions_Set_Input>;
  where: ZoomUserActions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomUserActions_By_PkArgs = {
  _set?: InputMaybe<ZoomUserActions_Set_Input>;
  pk_columns: ZoomUserActions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomUserActions_ManyArgs = {
  updates: Array<ZoomUserActions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomUser_By_PkArgs = {
  _append?: InputMaybe<ZoomUser_Append_Input>;
  _delete_at_path?: InputMaybe<ZoomUser_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<ZoomUser_Delete_Elem_Input>;
  _delete_key?: InputMaybe<ZoomUser_Delete_Key_Input>;
  _prepend?: InputMaybe<ZoomUser_Prepend_Input>;
  _set?: InputMaybe<ZoomUser_Set_Input>;
  pk_columns: ZoomUser_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomUser_ManyArgs = {
  updates: Array<ZoomUser_Updates>;
};

/** mutation root */
export type Mutation_RootUploadPresentationArgs = {
  file: Scalars['Upload'];
};

/** mutation root */
export type Mutation_RootUpsertOrgSettingArgs = {
  enabled: Scalars['Boolean'];
  orgId: Scalars['String'];
  type: EnableSettingForOrgIdType;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "proxycrawlData" */
export type ProxycrawlData = {
  __typename?: 'proxycrawlData';
  contactDataId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  linkedinDataJson?: Maybe<Scalars['jsonb']>;
  linkedinUrl: Scalars['String'];
  proxycrawlCompletedAt?: Maybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: Maybe<Scalars['timestamptz']>;
  rid: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "proxycrawlData" */
export type ProxycrawlDataLinkedinDataJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "proxycrawlData" */
export type ProxycrawlData_Aggregate = {
  __typename?: 'proxycrawlData_aggregate';
  aggregate?: Maybe<ProxycrawlData_Aggregate_Fields>;
  nodes: Array<ProxycrawlData>;
};

/** aggregate fields of "proxycrawlData" */
export type ProxycrawlData_Aggregate_Fields = {
  __typename?: 'proxycrawlData_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProxycrawlData_Max_Fields>;
  min?: Maybe<ProxycrawlData_Min_Fields>;
};

/** aggregate fields of "proxycrawlData" */
export type ProxycrawlData_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProxycrawlData_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProxycrawlData_Append_Input = {
  linkedinDataJson?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "proxycrawlData". All fields are combined with a logical 'AND'. */
export type ProxycrawlData_Bool_Exp = {
  _and?: InputMaybe<Array<ProxycrawlData_Bool_Exp>>;
  _not?: InputMaybe<ProxycrawlData_Bool_Exp>;
  _or?: InputMaybe<Array<ProxycrawlData_Bool_Exp>>;
  contactDataId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  linkedinDataJson?: InputMaybe<Jsonb_Comparison_Exp>;
  linkedinUrl?: InputMaybe<String_Comparison_Exp>;
  proxycrawlCompletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  proxycrawlRequestSentAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  rid?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  statusDescription?: InputMaybe<String_Comparison_Exp>;
  statusUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "proxycrawlData" */
export enum ProxycrawlData_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProxycrawlDataPkey = 'proxycrawlData_pkey',
  /** unique or primary key constraint on columns "rid" */
  ProxycrawlDataRidKey = 'proxycrawlData_rid_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProxycrawlData_Delete_At_Path_Input = {
  linkedinDataJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ProxycrawlData_Delete_Elem_Input = {
  linkedinDataJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProxycrawlData_Delete_Key_Input = {
  linkedinDataJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "proxycrawlData" */
export type ProxycrawlData_Insert_Input = {
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  linkedinDataJson?: InputMaybe<Scalars['jsonb']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  proxycrawlCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: InputMaybe<Scalars['timestamptz']>;
  rid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  statusDescription?: InputMaybe<Scalars['String']>;
  statusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProxycrawlData_Max_Fields = {
  __typename?: 'proxycrawlData_max_fields';
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  proxycrawlCompletedAt?: Maybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: Maybe<Scalars['timestamptz']>;
  rid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ProxycrawlData_Min_Fields = {
  __typename?: 'proxycrawlData_min_fields';
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  proxycrawlCompletedAt?: Maybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: Maybe<Scalars['timestamptz']>;
  rid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "proxycrawlData" */
export type ProxycrawlData_Mutation_Response = {
  __typename?: 'proxycrawlData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProxycrawlData>;
};

/** input type for inserting object relation for remote table "proxycrawlData" */
export type ProxycrawlData_Obj_Rel_Insert_Input = {
  data: ProxycrawlData_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ProxycrawlData_On_Conflict>;
};

/** on_conflict condition type for table "proxycrawlData" */
export type ProxycrawlData_On_Conflict = {
  constraint: ProxycrawlData_Constraint;
  update_columns?: Array<ProxycrawlData_Update_Column>;
  where?: InputMaybe<ProxycrawlData_Bool_Exp>;
};

/** Ordering options when selecting data from "proxycrawlData". */
export type ProxycrawlData_Order_By = {
  contactDataId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  linkedinDataJson?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  proxycrawlCompletedAt?: InputMaybe<Order_By>;
  proxycrawlRequestSentAt?: InputMaybe<Order_By>;
  rid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  statusDescription?: InputMaybe<Order_By>;
  statusUpdatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: proxycrawlData */
export type ProxycrawlData_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProxycrawlData_Prepend_Input = {
  linkedinDataJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "proxycrawlData" */
export enum ProxycrawlData_Select_Column {
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinDataJson = 'linkedinDataJson',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  ProxycrawlCompletedAt = 'proxycrawlCompletedAt',
  /** column name */
  ProxycrawlRequestSentAt = 'proxycrawlRequestSentAt',
  /** column name */
  Rid = 'rid',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'statusDescription',
  /** column name */
  StatusUpdatedAt = 'statusUpdatedAt',
}

/** input type for updating data in table "proxycrawlData" */
export type ProxycrawlData_Set_Input = {
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  linkedinDataJson?: InputMaybe<Scalars['jsonb']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  proxycrawlCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: InputMaybe<Scalars['timestamptz']>;
  rid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  statusDescription?: InputMaybe<Scalars['String']>;
  statusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "proxycrawlData" */
export type ProxycrawlData_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProxycrawlData_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProxycrawlData_Stream_Cursor_Value_Input = {
  contactDataId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  linkedinDataJson?: InputMaybe<Scalars['jsonb']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  proxycrawlCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: InputMaybe<Scalars['timestamptz']>;
  rid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  statusDescription?: InputMaybe<Scalars['String']>;
  statusUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "proxycrawlData" */
export enum ProxycrawlData_Update_Column {
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinDataJson = 'linkedinDataJson',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  ProxycrawlCompletedAt = 'proxycrawlCompletedAt',
  /** column name */
  ProxycrawlRequestSentAt = 'proxycrawlRequestSentAt',
  /** column name */
  Rid = 'rid',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'statusDescription',
  /** column name */
  StatusUpdatedAt = 'statusUpdatedAt',
}

export type ProxycrawlData_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ProxycrawlData_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ProxycrawlData_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ProxycrawlData_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ProxycrawlData_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ProxycrawlData_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProxycrawlData_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProxycrawlData_Bool_Exp;
};

/** columns and relationships of "qaQualification" */
export type QaQualification = {
  __typename?: 'qaQualification';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  qualification: Scalars['String'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "qaQualification" */
export type QaQualification_Aggregate = {
  __typename?: 'qaQualification_aggregate';
  aggregate?: Maybe<QaQualification_Aggregate_Fields>;
  nodes: Array<QaQualification>;
};

export type QaQualification_Aggregate_Bool_Exp = {
  count?: InputMaybe<QaQualification_Aggregate_Bool_Exp_Count>;
};

export type QaQualification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<QaQualification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<QaQualification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "qaQualification" */
export type QaQualification_Aggregate_Fields = {
  __typename?: 'qaQualification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<QaQualification_Max_Fields>;
  min?: Maybe<QaQualification_Min_Fields>;
};

/** aggregate fields of "qaQualification" */
export type QaQualification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QaQualification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "qaQualification" */
export type QaQualification_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<QaQualification_Max_Order_By>;
  min?: InputMaybe<QaQualification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "qaQualification" */
export type QaQualification_Arr_Rel_Insert_Input = {
  data: Array<QaQualification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<QaQualification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "qaQualification". All fields are combined with a logical 'AND'. */
export type QaQualification_Bool_Exp = {
  _and?: InputMaybe<Array<QaQualification_Bool_Exp>>;
  _not?: InputMaybe<QaQualification_Bool_Exp>;
  _or?: InputMaybe<Array<QaQualification_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  qualification?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "qaQualification" */
export enum QaQualification_Constraint {
  /** unique or primary key constraint on columns "id" */
  QaQualificationPkey = 'qaQualification_pkey',
}

/** input type for inserting data into table "qaQualification" */
export type QaQualification_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  qualification?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type QaQualification_Max_Fields = {
  __typename?: 'qaQualification_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  qualification?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "qaQualification" */
export type QaQualification_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  qualification?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type QaQualification_Min_Fields = {
  __typename?: 'qaQualification_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  qualification?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "qaQualification" */
export type QaQualification_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  qualification?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "qaQualification" */
export type QaQualification_Mutation_Response = {
  __typename?: 'qaQualification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QaQualification>;
};

/** on_conflict condition type for table "qaQualification" */
export type QaQualification_On_Conflict = {
  constraint: QaQualification_Constraint;
  update_columns?: Array<QaQualification_Update_Column>;
  where?: InputMaybe<QaQualification_Bool_Exp>;
};

/** Ordering options when selecting data from "qaQualification". */
export type QaQualification_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  qualification?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: qaQualification */
export type QaQualification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "qaQualification" */
export enum QaQualification_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Qualification = 'qualification',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "qaQualification" */
export type QaQualification_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  qualification?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "qaQualification" */
export type QaQualification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QaQualification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QaQualification_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  qualification?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "qaQualification" */
export enum QaQualification_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Qualification = 'qualification',
  /** column name */
  UserId = 'userId',
}

export type QaQualification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QaQualification_Set_Input>;
  /** filter the rows which have to be updated */
  where: QaQualification_Bool_Exp;
};

/** columns and relationships of "qaTask" */
export type QaTask = {
  __typename?: 'qaTask';
  /** An object relationship */
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  clientContact?: Maybe<ClientContact>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  dueAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isEscalated?: Maybe<Scalars['Boolean']>;
  lastFetchedAt?: Maybe<Scalars['timestamptz']>;
  previousQaTaskId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  /** An array relationship */
  qaTaskActions: Array<QaTaskAction>;
  /** An aggregate relationship */
  qaTaskActions_aggregate: QaTaskAction_Aggregate;
  status: Scalars['String'];
  taskType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "qaTask" */
export type QaTaskQaTaskActionsArgs = {
  distinct_on?: InputMaybe<Array<QaTaskAction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTaskAction_Order_By>>;
  where?: InputMaybe<QaTaskAction_Bool_Exp>;
};

/** columns and relationships of "qaTask" */
export type QaTaskQaTaskActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QaTaskAction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTaskAction_Order_By>>;
  where?: InputMaybe<QaTaskAction_Bool_Exp>;
};

/** columns and relationships of "qaTaskAction" */
export type QaTaskAction = {
  __typename?: 'qaTaskAction';
  actionType?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  executionTime?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  jobChangeStatus?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  linkedinUrlStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  previousLinkedinJob?: Maybe<Scalars['String']>;
  previousQaTaskActionId?: Maybe<Scalars['uuid']>;
  qaTaskId: Scalars['uuid'];
  qaUserId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedEmail?: Maybe<Scalars['String']>;
  updatedLinkedinJob?: Maybe<Scalars['String']>;
};

/** aggregated selection of "qaTaskAction" */
export type QaTaskAction_Aggregate = {
  __typename?: 'qaTaskAction_aggregate';
  aggregate?: Maybe<QaTaskAction_Aggregate_Fields>;
  nodes: Array<QaTaskAction>;
};

export type QaTaskAction_Aggregate_Bool_Exp = {
  count?: InputMaybe<QaTaskAction_Aggregate_Bool_Exp_Count>;
};

export type QaTaskAction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<QaTaskAction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<QaTaskAction_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "qaTaskAction" */
export type QaTaskAction_Aggregate_Fields = {
  __typename?: 'qaTaskAction_aggregate_fields';
  avg?: Maybe<QaTaskAction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<QaTaskAction_Max_Fields>;
  min?: Maybe<QaTaskAction_Min_Fields>;
  stddev?: Maybe<QaTaskAction_Stddev_Fields>;
  stddev_pop?: Maybe<QaTaskAction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<QaTaskAction_Stddev_Samp_Fields>;
  sum?: Maybe<QaTaskAction_Sum_Fields>;
  var_pop?: Maybe<QaTaskAction_Var_Pop_Fields>;
  var_samp?: Maybe<QaTaskAction_Var_Samp_Fields>;
  variance?: Maybe<QaTaskAction_Variance_Fields>;
};

/** aggregate fields of "qaTaskAction" */
export type QaTaskAction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QaTaskAction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "qaTaskAction" */
export type QaTaskAction_Aggregate_Order_By = {
  avg?: InputMaybe<QaTaskAction_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<QaTaskAction_Max_Order_By>;
  min?: InputMaybe<QaTaskAction_Min_Order_By>;
  stddev?: InputMaybe<QaTaskAction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<QaTaskAction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<QaTaskAction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<QaTaskAction_Sum_Order_By>;
  var_pop?: InputMaybe<QaTaskAction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<QaTaskAction_Var_Samp_Order_By>;
  variance?: InputMaybe<QaTaskAction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "qaTaskAction" */
export type QaTaskAction_Arr_Rel_Insert_Input = {
  data: Array<QaTaskAction_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<QaTaskAction_On_Conflict>;
};

/** aggregate avg on columns */
export type QaTaskAction_Avg_Fields = {
  __typename?: 'qaTaskAction_avg_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "qaTaskAction" */
export type QaTaskAction_Avg_Order_By = {
  executionTime?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "qaTaskAction". All fields are combined with a logical 'AND'. */
export type QaTaskAction_Bool_Exp = {
  _and?: InputMaybe<Array<QaTaskAction_Bool_Exp>>;
  _not?: InputMaybe<QaTaskAction_Bool_Exp>;
  _or?: InputMaybe<Array<QaTaskAction_Bool_Exp>>;
  actionType?: InputMaybe<String_Comparison_Exp>;
  assessment?: InputMaybe<String_Comparison_Exp>;
  clientContactId?: InputMaybe<Uuid_Comparison_Exp>;
  completedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  executionTime?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  jobChangeStatus?: InputMaybe<String_Comparison_Exp>;
  linkedinUrl?: InputMaybe<String_Comparison_Exp>;
  linkedinUrlStatus?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  previousLinkedinJob?: InputMaybe<String_Comparison_Exp>;
  previousQaTaskActionId?: InputMaybe<Uuid_Comparison_Exp>;
  qaTaskId?: InputMaybe<Uuid_Comparison_Exp>;
  qaUserId?: InputMaybe<Uuid_Comparison_Exp>;
  startedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedEmail?: InputMaybe<String_Comparison_Exp>;
  updatedLinkedinJob?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "qaTaskAction" */
export enum QaTaskAction_Constraint {
  /** unique or primary key constraint on columns "id" */
  QaTaskActionPkey = 'qaTaskAction_pkey',
  /** unique or primary key constraint on columns "clientContactId", "qaTaskId", "qaUserId" */
  QaTaskActionQaTaskIdQaUserIdClientContactIdKey = 'qaTaskAction_qaTaskId_qaUserId_clientContactId_key',
}

/** input type for incrementing numeric columns in table "qaTaskAction" */
export type QaTaskAction_Inc_Input = {
  executionTime?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "qaTaskAction" */
export type QaTaskAction_Insert_Input = {
  actionType?: InputMaybe<Scalars['String']>;
  assessment?: InputMaybe<Scalars['String']>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  executionTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobChangeStatus?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  linkedinUrlStatus?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  previousLinkedinJob?: InputMaybe<Scalars['String']>;
  previousQaTaskActionId?: InputMaybe<Scalars['uuid']>;
  qaTaskId?: InputMaybe<Scalars['uuid']>;
  qaUserId?: InputMaybe<Scalars['uuid']>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedEmail?: InputMaybe<Scalars['String']>;
  updatedLinkedinJob?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type QaTaskAction_Max_Fields = {
  __typename?: 'qaTaskAction_max_fields';
  actionType?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  jobChangeStatus?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  linkedinUrlStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  previousLinkedinJob?: Maybe<Scalars['String']>;
  previousQaTaskActionId?: Maybe<Scalars['uuid']>;
  qaTaskId?: Maybe<Scalars['uuid']>;
  qaUserId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedEmail?: Maybe<Scalars['String']>;
  updatedLinkedinJob?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "qaTaskAction" */
export type QaTaskAction_Max_Order_By = {
  actionType?: InputMaybe<Order_By>;
  assessment?: InputMaybe<Order_By>;
  clientContactId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  executionTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobChangeStatus?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  linkedinUrlStatus?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  previousLinkedinJob?: InputMaybe<Order_By>;
  previousQaTaskActionId?: InputMaybe<Order_By>;
  qaTaskId?: InputMaybe<Order_By>;
  qaUserId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedEmail?: InputMaybe<Order_By>;
  updatedLinkedinJob?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type QaTaskAction_Min_Fields = {
  __typename?: 'qaTaskAction_min_fields';
  actionType?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  jobChangeStatus?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  linkedinUrlStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  previousLinkedinJob?: Maybe<Scalars['String']>;
  previousQaTaskActionId?: Maybe<Scalars['uuid']>;
  qaTaskId?: Maybe<Scalars['uuid']>;
  qaUserId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedEmail?: Maybe<Scalars['String']>;
  updatedLinkedinJob?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "qaTaskAction" */
export type QaTaskAction_Min_Order_By = {
  actionType?: InputMaybe<Order_By>;
  assessment?: InputMaybe<Order_By>;
  clientContactId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  executionTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobChangeStatus?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  linkedinUrlStatus?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  previousLinkedinJob?: InputMaybe<Order_By>;
  previousQaTaskActionId?: InputMaybe<Order_By>;
  qaTaskId?: InputMaybe<Order_By>;
  qaUserId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedEmail?: InputMaybe<Order_By>;
  updatedLinkedinJob?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "qaTaskAction" */
export type QaTaskAction_Mutation_Response = {
  __typename?: 'qaTaskAction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QaTaskAction>;
};

/** on_conflict condition type for table "qaTaskAction" */
export type QaTaskAction_On_Conflict = {
  constraint: QaTaskAction_Constraint;
  update_columns?: Array<QaTaskAction_Update_Column>;
  where?: InputMaybe<QaTaskAction_Bool_Exp>;
};

/** Ordering options when selecting data from "qaTaskAction". */
export type QaTaskAction_Order_By = {
  actionType?: InputMaybe<Order_By>;
  assessment?: InputMaybe<Order_By>;
  clientContactId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  executionTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobChangeStatus?: InputMaybe<Order_By>;
  linkedinUrl?: InputMaybe<Order_By>;
  linkedinUrlStatus?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  previousLinkedinJob?: InputMaybe<Order_By>;
  previousQaTaskActionId?: InputMaybe<Order_By>;
  qaTaskId?: InputMaybe<Order_By>;
  qaUserId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedEmail?: InputMaybe<Order_By>;
  updatedLinkedinJob?: InputMaybe<Order_By>;
};

/** primary key columns input for table: qaTaskAction */
export type QaTaskAction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "qaTaskAction" */
export enum QaTaskAction_Select_Column {
  /** column name */
  ActionType = 'actionType',
  /** column name */
  Assessment = 'assessment',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExecutionTime = 'executionTime',
  /** column name */
  Id = 'id',
  /** column name */
  JobChangeStatus = 'jobChangeStatus',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  LinkedinUrlStatus = 'linkedinUrlStatus',
  /** column name */
  Notes = 'notes',
  /** column name */
  PreviousLinkedinJob = 'previousLinkedinJob',
  /** column name */
  PreviousQaTaskActionId = 'previousQaTaskActionId',
  /** column name */
  QaTaskId = 'qaTaskId',
  /** column name */
  QaUserId = 'qaUserId',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  UpdatedEmail = 'updatedEmail',
  /** column name */
  UpdatedLinkedinJob = 'updatedLinkedinJob',
}

/** input type for updating data in table "qaTaskAction" */
export type QaTaskAction_Set_Input = {
  actionType?: InputMaybe<Scalars['String']>;
  assessment?: InputMaybe<Scalars['String']>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  executionTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobChangeStatus?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  linkedinUrlStatus?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  previousLinkedinJob?: InputMaybe<Scalars['String']>;
  previousQaTaskActionId?: InputMaybe<Scalars['uuid']>;
  qaTaskId?: InputMaybe<Scalars['uuid']>;
  qaUserId?: InputMaybe<Scalars['uuid']>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedEmail?: InputMaybe<Scalars['String']>;
  updatedLinkedinJob?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type QaTaskAction_Stddev_Fields = {
  __typename?: 'qaTaskAction_stddev_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "qaTaskAction" */
export type QaTaskAction_Stddev_Order_By = {
  executionTime?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type QaTaskAction_Stddev_Pop_Fields = {
  __typename?: 'qaTaskAction_stddev_pop_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "qaTaskAction" */
export type QaTaskAction_Stddev_Pop_Order_By = {
  executionTime?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type QaTaskAction_Stddev_Samp_Fields = {
  __typename?: 'qaTaskAction_stddev_samp_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "qaTaskAction" */
export type QaTaskAction_Stddev_Samp_Order_By = {
  executionTime?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "qaTaskAction" */
export type QaTaskAction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QaTaskAction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QaTaskAction_Stream_Cursor_Value_Input = {
  actionType?: InputMaybe<Scalars['String']>;
  assessment?: InputMaybe<Scalars['String']>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  executionTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobChangeStatus?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  linkedinUrlStatus?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  previousLinkedinJob?: InputMaybe<Scalars['String']>;
  previousQaTaskActionId?: InputMaybe<Scalars['uuid']>;
  qaTaskId?: InputMaybe<Scalars['uuid']>;
  qaUserId?: InputMaybe<Scalars['uuid']>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedEmail?: InputMaybe<Scalars['String']>;
  updatedLinkedinJob?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type QaTaskAction_Sum_Fields = {
  __typename?: 'qaTaskAction_sum_fields';
  executionTime?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "qaTaskAction" */
export type QaTaskAction_Sum_Order_By = {
  executionTime?: InputMaybe<Order_By>;
};

/** update columns of table "qaTaskAction" */
export enum QaTaskAction_Update_Column {
  /** column name */
  ActionType = 'actionType',
  /** column name */
  Assessment = 'assessment',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExecutionTime = 'executionTime',
  /** column name */
  Id = 'id',
  /** column name */
  JobChangeStatus = 'jobChangeStatus',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  LinkedinUrlStatus = 'linkedinUrlStatus',
  /** column name */
  Notes = 'notes',
  /** column name */
  PreviousLinkedinJob = 'previousLinkedinJob',
  /** column name */
  PreviousQaTaskActionId = 'previousQaTaskActionId',
  /** column name */
  QaTaskId = 'qaTaskId',
  /** column name */
  QaUserId = 'qaUserId',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  UpdatedEmail = 'updatedEmail',
  /** column name */
  UpdatedLinkedinJob = 'updatedLinkedinJob',
}

export type QaTaskAction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<QaTaskAction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QaTaskAction_Set_Input>;
  /** filter the rows which have to be updated */
  where: QaTaskAction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type QaTaskAction_Var_Pop_Fields = {
  __typename?: 'qaTaskAction_var_pop_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "qaTaskAction" */
export type QaTaskAction_Var_Pop_Order_By = {
  executionTime?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type QaTaskAction_Var_Samp_Fields = {
  __typename?: 'qaTaskAction_var_samp_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "qaTaskAction" */
export type QaTaskAction_Var_Samp_Order_By = {
  executionTime?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type QaTaskAction_Variance_Fields = {
  __typename?: 'qaTaskAction_variance_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "qaTaskAction" */
export type QaTaskAction_Variance_Order_By = {
  executionTime?: InputMaybe<Order_By>;
};

/** aggregated selection of "qaTask" */
export type QaTask_Aggregate = {
  __typename?: 'qaTask_aggregate';
  aggregate?: Maybe<QaTask_Aggregate_Fields>;
  nodes: Array<QaTask>;
};

/** aggregate fields of "qaTask" */
export type QaTask_Aggregate_Fields = {
  __typename?: 'qaTask_aggregate_fields';
  avg?: Maybe<QaTask_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<QaTask_Max_Fields>;
  min?: Maybe<QaTask_Min_Fields>;
  stddev?: Maybe<QaTask_Stddev_Fields>;
  stddev_pop?: Maybe<QaTask_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<QaTask_Stddev_Samp_Fields>;
  sum?: Maybe<QaTask_Sum_Fields>;
  var_pop?: Maybe<QaTask_Var_Pop_Fields>;
  var_samp?: Maybe<QaTask_Var_Samp_Fields>;
  variance?: Maybe<QaTask_Variance_Fields>;
};

/** aggregate fields of "qaTask" */
export type QaTask_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<QaTask_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type QaTask_Avg_Fields = {
  __typename?: 'qaTask_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "qaTask". All fields are combined with a logical 'AND'. */
export type QaTask_Bool_Exp = {
  _and?: InputMaybe<Array<QaTask_Bool_Exp>>;
  _not?: InputMaybe<QaTask_Bool_Exp>;
  _or?: InputMaybe<Array<QaTask_Bool_Exp>>;
  assignedUser?: InputMaybe<User_Bool_Exp>;
  assignedUserId?: InputMaybe<Uuid_Comparison_Exp>;
  clientContact?: InputMaybe<ClientContact_Bool_Exp>;
  clientContactId?: InputMaybe<Uuid_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  completedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dueAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  firestoreId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isEscalated?: InputMaybe<Boolean_Comparison_Exp>;
  lastFetchedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  previousQaTaskId?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  qaTaskActions?: InputMaybe<QaTaskAction_Bool_Exp>;
  qaTaskActions_aggregate?: InputMaybe<QaTaskAction_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  taskType?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "qaTask" */
export enum QaTask_Constraint {
  /** unique or primary key constraint on columns "firestoreId" */
  QaTaskFirestoreIdKey = 'qaTask_firestoreId_key',
  /** unique or primary key constraint on columns "id" */
  QaTaskPkey = 'qaTask_pkey',
}

/** input type for incrementing numeric columns in table "qaTask" */
export type QaTask_Inc_Input = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "qaTask" */
export type QaTask_Insert_Input = {
  assignedUser?: InputMaybe<User_Obj_Rel_Insert_Input>;
  assignedUserId?: InputMaybe<Scalars['uuid']>;
  clientContact?: InputMaybe<ClientContact_Obj_Rel_Insert_Input>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dueAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isEscalated?: InputMaybe<Scalars['Boolean']>;
  lastFetchedAt?: InputMaybe<Scalars['timestamptz']>;
  previousQaTaskId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  qaTaskActions?: InputMaybe<QaTaskAction_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  taskType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type QaTask_Max_Fields = {
  __typename?: 'qaTask_max_fields';
  assignedUserId?: Maybe<Scalars['uuid']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFetchedAt?: Maybe<Scalars['timestamptz']>;
  previousQaTaskId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type QaTask_Min_Fields = {
  __typename?: 'qaTask_min_fields';
  assignedUserId?: Maybe<Scalars['uuid']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFetchedAt?: Maybe<Scalars['timestamptz']>;
  previousQaTaskId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "qaTask" */
export type QaTask_Mutation_Response = {
  __typename?: 'qaTask_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QaTask>;
};

/** on_conflict condition type for table "qaTask" */
export type QaTask_On_Conflict = {
  constraint: QaTask_Constraint;
  update_columns?: Array<QaTask_Update_Column>;
  where?: InputMaybe<QaTask_Bool_Exp>;
};

/** Ordering options when selecting data from "qaTask". */
export type QaTask_Order_By = {
  assignedUser?: InputMaybe<User_Order_By>;
  assignedUserId?: InputMaybe<Order_By>;
  clientContact?: InputMaybe<ClientContact_Order_By>;
  clientContactId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dueAt?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isEscalated?: InputMaybe<Order_By>;
  lastFetchedAt?: InputMaybe<Order_By>;
  previousQaTaskId?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  qaTaskActions_aggregate?: InputMaybe<QaTaskAction_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  taskType?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: qaTask */
export type QaTask_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "qaTask" */
export enum QaTask_Select_Column {
  /** column name */
  AssignedUserId = 'assignedUserId',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DueAt = 'dueAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  IsEscalated = 'isEscalated',
  /** column name */
  LastFetchedAt = 'lastFetchedAt',
  /** column name */
  PreviousQaTaskId = 'previousQaTaskId',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'taskType',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "qaTask" */
export type QaTask_Set_Input = {
  assignedUserId?: InputMaybe<Scalars['uuid']>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dueAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isEscalated?: InputMaybe<Scalars['Boolean']>;
  lastFetchedAt?: InputMaybe<Scalars['timestamptz']>;
  previousQaTaskId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  taskType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type QaTask_Stddev_Fields = {
  __typename?: 'qaTask_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type QaTask_Stddev_Pop_Fields = {
  __typename?: 'qaTask_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type QaTask_Stddev_Samp_Fields = {
  __typename?: 'qaTask_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "qaTask" */
export type QaTask_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: QaTask_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type QaTask_Stream_Cursor_Value_Input = {
  assignedUserId?: InputMaybe<Scalars['uuid']>;
  clientContactId?: InputMaybe<Scalars['uuid']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dueAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isEscalated?: InputMaybe<Scalars['Boolean']>;
  lastFetchedAt?: InputMaybe<Scalars['timestamptz']>;
  previousQaTaskId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  taskType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type QaTask_Sum_Fields = {
  __typename?: 'qaTask_sum_fields';
  priority?: Maybe<Scalars['Int']>;
};

/** update columns of table "qaTask" */
export enum QaTask_Update_Column {
  /** column name */
  AssignedUserId = 'assignedUserId',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DueAt = 'dueAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  IsEscalated = 'isEscalated',
  /** column name */
  LastFetchedAt = 'lastFetchedAt',
  /** column name */
  PreviousQaTaskId = 'previousQaTaskId',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'taskType',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type QaTask_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<QaTask_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QaTask_Set_Input>;
  /** filter the rows which have to be updated */
  where: QaTask_Bool_Exp;
};

/** aggregate var_pop on columns */
export type QaTask_Var_Pop_Fields = {
  __typename?: 'qaTask_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type QaTask_Var_Samp_Fields = {
  __typename?: 'qaTask_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type QaTask_Variance_Fields = {
  __typename?: 'qaTask_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  canEditCompanyDetails: CanEditCompanyResponse;
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch data from the table: "clientContact" */
  clientContact: Array<ClientContact>;
  /** fetch data from the table: "clientContactCrmData" */
  clientContactCrmData: Array<ClientContactCrmData>;
  /** fetch aggregated fields from the table: "clientContactCrmData" */
  clientContactCrmData_aggregate: ClientContactCrmData_Aggregate;
  /** fetch data from the table: "clientContactCrmData" using primary key columns */
  clientContactCrmData_by_pk?: Maybe<ClientContactCrmData>;
  /** fetch data from the table: "clientContactFilter" */
  clientContactFilter: Array<ClientContactFilter>;
  /** fetch aggregated fields from the table: "clientContactFilter" */
  clientContactFilter_aggregate: ClientContactFilter_Aggregate;
  /** fetch data from the table: "clientContactFilter" using primary key columns */
  clientContactFilter_by_pk?: Maybe<ClientContactFilter>;
  /** fetch data from the table: "clientContactJobChange" */
  clientContactJobChange: Array<ClientContactJobChange>;
  /** fetch aggregated fields from the table: "clientContactJobChange" */
  clientContactJobChange_aggregate: ClientContactJobChange_Aggregate;
  /** fetch data from the table: "clientContactJobChange" using primary key columns */
  clientContactJobChange_by_pk?: Maybe<ClientContactJobChange>;
  /** fetch aggregated fields from the table: "clientContact" */
  clientContact_aggregate: ClientContact_Aggregate;
  /** fetch data from the table: "clientContact" using primary key columns */
  clientContact_by_pk?: Maybe<ClientContact>;
  /** fetch data from the table: "clientEmailReport" */
  clientEmailReport: Array<ClientEmailReport>;
  /** fetch aggregated fields from the table: "clientEmailReport" */
  clientEmailReport_aggregate: ClientEmailReport_Aggregate;
  /** fetch data from the table: "clientEmailReport" using primary key columns */
  clientEmailReport_by_pk?: Maybe<ClientEmailReport>;
  /** fetch data from the table: "clientNotificationEmail" */
  clientNotificationEmail: Array<ClientNotificationEmail>;
  /** fetch aggregated fields from the table: "clientNotificationEmail" */
  clientNotificationEmail_aggregate: ClientNotificationEmail_Aggregate;
  /** fetch data from the table: "clientNotificationEmail" using primary key columns */
  clientNotificationEmail_by_pk?: Maybe<ClientNotificationEmail>;
  /** fetch data from the table: "clientTerritory" */
  clientTerritory: Array<ClientTerritory>;
  /** fetch aggregated fields from the table: "clientTerritory" */
  clientTerritory_aggregate: ClientTerritory_Aggregate;
  /** fetch data from the table: "clientTerritory" using primary key columns */
  clientTerritory_by_pk?: Maybe<ClientTerritory>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "cloudTask" */
  cloudTask: Array<CloudTask>;
  /** fetch aggregated fields from the table: "cloudTask" */
  cloudTask_aggregate: CloudTask_Aggregate;
  /** fetch data from the table: "cloudTask" using primary key columns */
  cloudTask_by_pk?: Maybe<CloudTask>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch data from the table: "companyName" */
  companyName: Array<CompanyName>;
  /** fetch aggregated fields from the table: "companyName" */
  companyName_aggregate: CompanyName_Aggregate;
  /** fetch data from the table: "companyName" using primary key columns */
  companyName_by_pk?: Maybe<CompanyName>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** An array relationship */
  contactData: Array<ContactData>;
  /** An aggregate relationship */
  contactData_aggregate: ContactData_Aggregate;
  /** fetch data from the table: "contactData" using primary key columns */
  contactData_by_pk?: Maybe<ContactData>;
  /** fetch data from the table: "contactEmail" */
  contactEmail: Array<ContactEmail>;
  /** fetch aggregated fields from the table: "contactEmail" */
  contactEmail_aggregate: ContactEmail_Aggregate;
  /** fetch data from the table: "contactEmail" using primary key columns */
  contactEmail_by_pk?: Maybe<ContactEmail>;
  contactSuggestions: Array<ContactSuggestionResult>;
  /** fetch data from the table: "content" */
  content: Array<Content>;
  /** fetch aggregated fields from the table: "content" */
  content_aggregate: Content_Aggregate;
  /** fetch data from the table: "content" using primary key columns */
  content_by_pk?: Maybe<Content>;
  /** fetch data from the table: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory: Array<CrmContactEnrichmentHistory>;
  /** fetch aggregated fields from the table: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory_aggregate: CrmContactEnrichmentHistory_Aggregate;
  /** fetch data from the table: "crmContactEnrichmentHistory" using primary key columns */
  crmContactEnrichmentHistory_by_pk?: Maybe<CrmContactEnrichmentHistory>;
  /** fetch data from the table: "csvUpload" */
  csvUpload: Array<CsvUpload>;
  /** fetch aggregated fields from the table: "csvUpload" */
  csvUpload_aggregate: CsvUpload_Aggregate;
  /** fetch data from the table: "csvUpload" using primary key columns */
  csvUpload_by_pk?: Maybe<CsvUpload>;
  /** fetch data from the table: "deleteOldZoomEventsJob" */
  deleteOldZoomEventsJob: Array<DeleteOldZoomEventsJob>;
  /** fetch aggregated fields from the table: "deleteOldZoomEventsJob" */
  deleteOldZoomEventsJob_aggregate: DeleteOldZoomEventsJob_Aggregate;
  /** fetch data from the table: "deleteOldZoomEventsJob" using primary key columns */
  deleteOldZoomEventsJob_by_pk?: Maybe<DeleteOldZoomEventsJob>;
  /** fetch data from the table: "emailHistory" */
  emailHistory: Array<EmailHistory>;
  /** fetch aggregated fields from the table: "emailHistory" */
  emailHistory_aggregate: EmailHistory_Aggregate;
  /** fetch data from the table: "emailHistory" using primary key columns */
  emailHistory_by_pk?: Maybe<EmailHistory>;
  encodedIdBackfillStats: EncodedIdBackfillStats;
  getDeepLinkAction?: Maybe<Scalars['String']>;
  getPronunciation?: Maybe<Scalars['String']>;
  /** fetch data from the table: "googleChannel" */
  googleChannel: Array<GoogleChannel>;
  /** fetch aggregated fields from the table: "googleChannel" */
  googleChannel_aggregate: GoogleChannel_Aggregate;
  /** fetch data from the table: "googleChannel" using primary key columns */
  googleChannel_by_pk?: Maybe<GoogleChannel>;
  /** fetch data from the table: "growthIntraCompanyAdoptionContact" */
  growthIntraCompanyAdoptionContact: Array<GrowthIntraCompanyAdoptionContact>;
  /** fetch aggregated fields from the table: "growthIntraCompanyAdoptionContact" */
  growthIntraCompanyAdoptionContact_aggregate: GrowthIntraCompanyAdoptionContact_Aggregate;
  /** fetch data from the table: "growthIntraCompanyAdoptionContact" using primary key columns */
  growthIntraCompanyAdoptionContact_by_pk?: Maybe<GrowthIntraCompanyAdoptionContact>;
  /** fetch data from the table: "growthUserReferralContact" */
  growthUserReferralContact: Array<GrowthUserReferralContact>;
  /** fetch aggregated fields from the table: "growthUserReferralContact" */
  growthUserReferralContact_aggregate: GrowthUserReferralContact_Aggregate;
  /** fetch data from the table: "growthUserReferralContact" using primary key columns */
  growthUserReferralContact_by_pk?: Maybe<GrowthUserReferralContact>;
  hasAccessVerbose: FeatureAccess;
  /** fetch data from the table: "hubspotContactList" */
  hubspotContactList: Array<HubspotContactList>;
  /** fetch data from the table: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact: Array<HubspotContactListHubspotContact>;
  /** fetch aggregated fields from the table: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact_aggregate: HubspotContactListHubspotContact_Aggregate;
  /** fetch data from the table: "hubspotContactListHubspotContact" using primary key columns */
  hubspotContactListHubspotContact_by_pk?: Maybe<HubspotContactListHubspotContact>;
  /** fetch aggregated fields from the table: "hubspotContactList" */
  hubspotContactList_aggregate: HubspotContactList_Aggregate;
  /** fetch data from the table: "hubspotContactList" using primary key columns */
  hubspotContactList_by_pk?: Maybe<HubspotContactList>;
  /** fetch data from the table: "hubspotContactProperty" */
  hubspotContactProperty: Array<HubspotContactProperty>;
  /** fetch aggregated fields from the table: "hubspotContactProperty" */
  hubspotContactProperty_aggregate: HubspotContactProperty_Aggregate;
  /** fetch data from the table: "hubspotContactProperty" using primary key columns */
  hubspotContactProperty_by_pk?: Maybe<HubspotContactProperty>;
  /** fetch data from the table: "hubspotIntegration" */
  hubspotIntegration: Array<HubspotIntegration>;
  /** fetch aggregated fields from the table: "hubspotIntegration" */
  hubspotIntegration_aggregate: HubspotIntegration_Aggregate;
  /** fetch data from the table: "hubspotIntegration" using primary key columns */
  hubspotIntegration_by_pk?: Maybe<HubspotIntegration>;
  inviteSuggestions: Array<InviteSuggestionResult>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "microsoftSubscription" */
  microsoftSubscription: Array<MicrosoftSubscription>;
  /** fetch aggregated fields from the table: "microsoftSubscription" */
  microsoftSubscription_aggregate: MicrosoftSubscription_Aggregate;
  /** fetch data from the table: "microsoftSubscription" using primary key columns */
  microsoftSubscription_by_pk?: Maybe<MicrosoftSubscription>;
  msUsersWithoutCalendarCount: Scalars['Float'];
  onAppLoaded: AppStatus;
  plans?: Maybe<Array<Plan>>;
  /** fetch data from the table: "proxycrawlData" */
  proxycrawlData: Array<ProxycrawlData>;
  /** fetch aggregated fields from the table: "proxycrawlData" */
  proxycrawlData_aggregate: ProxycrawlData_Aggregate;
  /** fetch data from the table: "proxycrawlData" using primary key columns */
  proxycrawlData_by_pk?: Maybe<ProxycrawlData>;
  publicCompanyInfo?: Maybe<PublicCompanyInfo>;
  publicEvent?: Maybe<PublicEvent>;
  publicUser?: Maybe<PublicUser>;
  /** fetch data from the table: "qaQualification" */
  qaQualification: Array<QaQualification>;
  /** fetch aggregated fields from the table: "qaQualification" */
  qaQualification_aggregate: QaQualification_Aggregate;
  /** fetch data from the table: "qaQualification" using primary key columns */
  qaQualification_by_pk?: Maybe<QaQualification>;
  /** fetch data from the table: "qaTask" */
  qaTask: Array<QaTask>;
  /** fetch data from the table: "qaTaskAction" */
  qaTaskAction: Array<QaTaskAction>;
  /** fetch aggregated fields from the table: "qaTaskAction" */
  qaTaskAction_aggregate: QaTaskAction_Aggregate;
  /** fetch data from the table: "qaTaskAction" using primary key columns */
  qaTaskAction_by_pk?: Maybe<QaTaskAction>;
  /** fetch aggregated fields from the table: "qaTask" */
  qaTask_aggregate: QaTask_Aggregate;
  /** fetch data from the table: "qaTask" using primary key columns */
  qaTask_by_pk?: Maybe<QaTask>;
  /** fetch data from the table: "salesforceAccount" */
  salesforceAccount: Array<SalesforceAccount>;
  /** fetch aggregated fields from the table: "salesforceAccount" */
  salesforceAccount_aggregate: SalesforceAccount_Aggregate;
  /** fetch data from the table: "salesforceAccount" using primary key columns */
  salesforceAccount_by_pk?: Maybe<SalesforceAccount>;
  /** fetch data from the table: "salesforceContactField" */
  salesforceContactField: Array<SalesforceContactField>;
  /** fetch aggregated fields from the table: "salesforceContactField" */
  salesforceContactField_aggregate: SalesforceContactField_Aggregate;
  /** fetch data from the table: "salesforceContactField" using primary key columns */
  salesforceContactField_by_pk?: Maybe<SalesforceContactField>;
  /** fetch data from the table: "salesforceIntegration" */
  salesforceIntegration: Array<SalesforceIntegration>;
  /** fetch aggregated fields from the table: "salesforceIntegration" */
  salesforceIntegration_aggregate: SalesforceIntegration_Aggregate;
  /** fetch data from the table: "salesforceIntegration" using primary key columns */
  salesforceIntegration_by_pk?: Maybe<SalesforceIntegration>;
  /** fetch data from the table: "salesforceOpportunity" */
  salesforceOpportunity: Array<SalesforceOpportunity>;
  /** fetch aggregated fields from the table: "salesforceOpportunity" */
  salesforceOpportunity_aggregate: SalesforceOpportunity_Aggregate;
  /** fetch data from the table: "salesforceOpportunity" using primary key columns */
  salesforceOpportunity_by_pk?: Maybe<SalesforceOpportunity>;
  /** fetch data from the table: "sendgridEvent" */
  sendgridEvent: Array<SendgridEvent>;
  /** fetch aggregated fields from the table: "sendgridEvent" */
  sendgridEvent_aggregate: SendgridEvent_Aggregate;
  /** fetch data from the table: "sendgridEvent" using primary key columns */
  sendgridEvent_by_pk?: Maybe<SendgridEvent>;
  syncConnections: Scalars['String'];
  /** fetch data from the table: "syncZoomEventsJob" */
  syncZoomEventsJob: Array<SyncZoomEventsJob>;
  /** fetch aggregated fields from the table: "syncZoomEventsJob" */
  syncZoomEventsJob_aggregate: SyncZoomEventsJob_Aggregate;
  /** fetch data from the table: "syncZoomEventsJob" using primary key columns */
  syncZoomEventsJob_by_pk?: Maybe<SyncZoomEventsJob>;
  /** fetch data from the table: "territory" */
  territory: Array<Territory>;
  /** fetch data from the table: "territorySearchTerm" */
  territorySearchTerm: Array<TerritorySearchTerm>;
  /** fetch aggregated fields from the table: "territorySearchTerm" */
  territorySearchTerm_aggregate: TerritorySearchTerm_Aggregate;
  /** fetch data from the table: "territorySearchTerm" using primary key columns */
  territorySearchTerm_by_pk?: Maybe<TerritorySearchTerm>;
  /** fetch aggregated fields from the table: "territory" */
  territory_aggregate: Territory_Aggregate;
  /** fetch data from the table: "territory" using primary key columns */
  territory_by_pk?: Maybe<Territory>;
  test?: Maybe<Scalars['String']>;
  /** fetch data from the table: "theOrgSearchTerm" */
  theOrgSearchTerm: Array<TheOrgSearchTerm>;
  /** fetch aggregated fields from the table: "theOrgSearchTerm" */
  theOrgSearchTerm_aggregate: TheOrgSearchTerm_Aggregate;
  /** fetch data from the table: "theOrgSearchTerm" using primary key columns */
  theOrgSearchTerm_by_pk?: Maybe<TheOrgSearchTerm>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "userInvite" */
  userInvite: Array<UserInvite>;
  /** fetch aggregated fields from the table: "userInvite" */
  userInvite_aggregate: UserInvite_Aggregate;
  /** fetch data from the table: "userInvite" using primary key columns */
  userInvite_by_pk?: Maybe<UserInvite>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  usernameBackfillStats: UsernameBackfillStats;
  usernameIsValid?: Maybe<Scalars['Boolean']>;
  usernameSuggestion?: Maybe<Scalars['String']>;
  /** fetch data from the table: "warmCompany" */
  warmCompany: Array<WarmCompany>;
  /** fetch aggregated fields from the table: "warmCompany" */
  warmCompany_aggregate: WarmCompany_Aggregate;
  /** fetch data from the table: "workOSConnection" */
  workOSConnection: Array<WorkOsConnection>;
  /** fetch aggregated fields from the table: "workOSConnection" */
  workOSConnection_aggregate: WorkOsConnection_Aggregate;
  /** fetch data from the table: "workOSConnection" using primary key columns */
  workOSConnection_by_pk?: Maybe<WorkOsConnection>;
  /** fetch data from the table: "workOSDirectory" */
  workOSDirectory: Array<WorkOsDirectory>;
  /** fetch aggregated fields from the table: "workOSDirectory" */
  workOSDirectory_aggregate: WorkOsDirectory_Aggregate;
  /** fetch data from the table: "workOSDirectory" using primary key columns */
  workOSDirectory_by_pk?: Maybe<WorkOsDirectory>;
  /** fetch data from the table: "workOSGroup" */
  workOSGroup: Array<WorkOsGroup>;
  /** fetch aggregated fields from the table: "workOSGroup" */
  workOSGroup_aggregate: WorkOsGroup_Aggregate;
  /** fetch data from the table: "workOSGroup" using primary key columns */
  workOSGroup_by_pk?: Maybe<WorkOsGroup>;
  /** fetch data from the table: "workOSUser" */
  workOSUser: Array<WorkOsUser>;
  /** fetch aggregated fields from the table: "workOSUser" */
  workOSUser_aggregate: WorkOsUser_Aggregate;
  /** fetch data from the table: "workOSUser" using primary key columns */
  workOSUser_by_pk?: Maybe<WorkOsUser>;
  /** fetch data from the table: "zoomApprovedDomain" */
  zoomApprovedDomain: Array<ZoomApprovedDomain>;
  /** fetch aggregated fields from the table: "zoomApprovedDomain" */
  zoomApprovedDomain_aggregate: ZoomApprovedDomain_Aggregate;
  /** fetch data from the table: "zoomApprovedDomain" using primary key columns */
  zoomApprovedDomain_by_pk?: Maybe<ZoomApprovedDomain>;
  /** fetch data from the table: "zoomCompany" */
  zoomCompany: Array<ZoomCompany>;
  /** fetch data from the table: "zoomCompanyMergeResult" */
  zoomCompanyMergeResult: Array<ZoomCompanyMergeResult>;
  /** fetch aggregated fields from the table: "zoomCompanyMergeResult" */
  zoomCompanyMergeResult_aggregate: ZoomCompanyMergeResult_Aggregate;
  /** fetch data from the table: "zoomCompanyMergeResult" using primary key columns */
  zoomCompanyMergeResult_by_pk?: Maybe<ZoomCompanyMergeResult>;
  /** fetch aggregated fields from the table: "zoomCompany" */
  zoomCompany_aggregate: ZoomCompany_Aggregate;
  /** fetch data from the table: "zoomCompany" using primary key columns */
  zoomCompany_by_pk?: Maybe<ZoomCompany>;
  /** fetch data from the table: "zoomConnectionRequest" */
  zoomConnectionRequest: Array<ZoomConnectionRequest>;
  /** fetch data from the table: "zoomConnectionRequestReminder" */
  zoomConnectionRequestReminder: Array<ZoomConnectionRequestReminder>;
  /** fetch aggregated fields from the table: "zoomConnectionRequestReminder" */
  zoomConnectionRequestReminder_aggregate: ZoomConnectionRequestReminder_Aggregate;
  /** fetch data from the table: "zoomConnectionRequestReminder" using primary key columns */
  zoomConnectionRequestReminder_by_pk?: Maybe<ZoomConnectionRequestReminder>;
  /** fetch aggregated fields from the table: "zoomConnectionRequest" */
  zoomConnectionRequest_aggregate: ZoomConnectionRequest_Aggregate;
  /** fetch data from the table: "zoomConnectionRequest" using primary key columns */
  zoomConnectionRequest_by_pk?: Maybe<ZoomConnectionRequest>;
  /** fetch data from the table: "zoomContact" */
  zoomContact: Array<ZoomContact>;
  /** fetch data from the table: "zoomContactNotes" */
  zoomContactNotes: Array<ZoomContactNotes>;
  /** fetch aggregated fields from the table: "zoomContactNotes" */
  zoomContactNotes_aggregate: ZoomContactNotes_Aggregate;
  /** fetch data from the table: "zoomContactNotes" using primary key columns */
  zoomContactNotes_by_pk?: Maybe<ZoomContactNotes>;
  /** fetch aggregated fields from the table: "zoomContact" */
  zoomContact_aggregate: ZoomContact_Aggregate;
  /** fetch data from the table: "zoomContact" using primary key columns */
  zoomContact_by_pk?: Maybe<ZoomContact>;
  /** fetch data from the table: "zoomConversationStarterAnswer" */
  zoomConversationStarterAnswer: Array<ZoomConversationStarterAnswer>;
  /** fetch aggregated fields from the table: "zoomConversationStarterAnswer" */
  zoomConversationStarterAnswer_aggregate: ZoomConversationStarterAnswer_Aggregate;
  /** fetch data from the table: "zoomConversationStarterAnswer" using primary key columns */
  zoomConversationStarterAnswer_by_pk?: Maybe<ZoomConversationStarterAnswer>;
  /** fetch data from the table: "zoomConversationStarterPrompt" */
  zoomConversationStarterPrompt: Array<ZoomConversationStarterPrompt>;
  /** fetch aggregated fields from the table: "zoomConversationStarterPrompt" */
  zoomConversationStarterPrompt_aggregate: ZoomConversationStarterPrompt_Aggregate;
  /** fetch data from the table: "zoomConversationStarterPrompt" using primary key columns */
  zoomConversationStarterPrompt_by_pk?: Maybe<ZoomConversationStarterPrompt>;
  /** fetch data from the table: "zoomCustomBackground" */
  zoomCustomBackground: Array<ZoomCustomBackground>;
  /** fetch aggregated fields from the table: "zoomCustomBackground" */
  zoomCustomBackground_aggregate: ZoomCustomBackground_Aggregate;
  /** fetch data from the table: "zoomCustomBackground" using primary key columns */
  zoomCustomBackground_by_pk?: Maybe<ZoomCustomBackground>;
  /** fetch data from the table: "zoomEmailHistory" */
  zoomEmailHistory: Array<ZoomEmailHistory>;
  /** fetch aggregated fields from the table: "zoomEmailHistory" */
  zoomEmailHistory_aggregate: ZoomEmailHistory_Aggregate;
  /** fetch data from the table: "zoomEmailHistory" using primary key columns */
  zoomEmailHistory_by_pk?: Maybe<ZoomEmailHistory>;
  /** fetch data from the table: "zoomEvent" */
  zoomEvent: Array<ZoomEvent>;
  /** fetch data from the table: "zoomEventParticipant" */
  zoomEventParticipant: Array<ZoomEventParticipant>;
  /** fetch data from the table: "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts: Array<ZoomEventParticipantWithContacts>;
  /** fetch aggregated fields from the table: "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts_aggregate: ZoomEventParticipantWithContacts_Aggregate;
  /** execute function "zoomEventParticipantWithContacts_sorted" which returns "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts_sorted: Array<ZoomEventParticipantWithContacts>;
  /** execute function "zoomEventParticipantWithContacts_sorted" and query aggregates on result of table type "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts_sorted_aggregate: ZoomEventParticipantWithContacts_Aggregate;
  /** fetch aggregated fields from the table: "zoomEventParticipant" */
  zoomEventParticipant_aggregate: ZoomEventParticipant_Aggregate;
  /** fetch data from the table: "zoomEventParticipant" using primary key columns */
  zoomEventParticipant_by_pk?: Maybe<ZoomEventParticipant>;
  /** fetch aggregated fields from the table: "zoomEvent" */
  zoomEvent_aggregate: ZoomEvent_Aggregate;
  /** fetch data from the table: "zoomEvent" using primary key columns */
  zoomEvent_by_pk?: Maybe<ZoomEvent>;
  /** fetch data from the table: "zoomExternalAuthUser" */
  zoomExternalAuthUser: Array<ZoomExternalAuthUser>;
  /** fetch aggregated fields from the table: "zoomExternalAuthUser" */
  zoomExternalAuthUser_aggregate: ZoomExternalAuthUser_Aggregate;
  /** fetch data from the table: "zoomExternalAuthUser" using primary key columns */
  zoomExternalAuthUser_by_pk?: Maybe<ZoomExternalAuthUser>;
  /** fetch data from the table: "zoomGroup" */
  zoomGroup: Array<ZoomGroup>;
  /** fetch data from the table: "zoomGroupMembership" */
  zoomGroupMembership: Array<ZoomGroupMembership>;
  /** fetch aggregated fields from the table: "zoomGroupMembership" */
  zoomGroupMembership_aggregate: ZoomGroupMembership_Aggregate;
  /** fetch data from the table: "zoomGroupMembership" using primary key columns */
  zoomGroupMembership_by_pk?: Maybe<ZoomGroupMembership>;
  /** fetch aggregated fields from the table: "zoomGroup" */
  zoomGroup_aggregate: ZoomGroup_Aggregate;
  /** fetch data from the table: "zoomGroup" using primary key columns */
  zoomGroup_by_pk?: Maybe<ZoomGroup>;
  /** fetch data from the table: "zoomLayersEvents" */
  zoomLayersEvents: Array<ZoomLayersEvents>;
  /** fetch aggregated fields from the table: "zoomLayersEvents" */
  zoomLayersEvents_aggregate: ZoomLayersEvents_Aggregate;
  /** fetch data from the table: "zoomLayersEvents" using primary key columns */
  zoomLayersEvents_by_pk?: Maybe<ZoomLayersEvents>;
  /** fetch data from the table: "zoomOrganization" */
  zoomOrganization: Array<ZoomOrganization>;
  /** fetch data from the table: "zoomOrganizationBackfill" */
  zoomOrganizationBackfill: Array<ZoomOrganizationBackfill>;
  /** fetch data from the table: "zoomOrganizationBackfillResult" */
  zoomOrganizationBackfillResult: Array<ZoomOrganizationBackfillResult>;
  /** fetch aggregated fields from the table: "zoomOrganizationBackfillResult" */
  zoomOrganizationBackfillResult_aggregate: ZoomOrganizationBackfillResult_Aggregate;
  /** fetch data from the table: "zoomOrganizationBackfillResult" using primary key columns */
  zoomOrganizationBackfillResult_by_pk?: Maybe<ZoomOrganizationBackfillResult>;
  /** fetch aggregated fields from the table: "zoomOrganizationBackfill" */
  zoomOrganizationBackfill_aggregate: ZoomOrganizationBackfill_Aggregate;
  /** fetch data from the table: "zoomOrganizationBackfill" using primary key columns */
  zoomOrganizationBackfill_by_pk?: Maybe<ZoomOrganizationBackfill>;
  /** fetch data from the table: "zoomOrganizationMembership" */
  zoomOrganizationMembership: Array<ZoomOrganizationMembership>;
  /** fetch data from the table: "zoomOrganizationMembershipView" */
  zoomOrganizationMembershipView: Array<ZoomOrganizationMembershipView>;
  /** fetch aggregated fields from the table: "zoomOrganizationMembershipView" */
  zoomOrganizationMembershipView_aggregate: ZoomOrganizationMembershipView_Aggregate;
  /** fetch aggregated fields from the table: "zoomOrganizationMembership" */
  zoomOrganizationMembership_aggregate: ZoomOrganizationMembership_Aggregate;
  /** fetch data from the table: "zoomOrganizationMembership" using primary key columns */
  zoomOrganizationMembership_by_pk?: Maybe<ZoomOrganizationMembership>;
  /** fetch aggregated fields from the table: "zoomOrganization" */
  zoomOrganization_aggregate: ZoomOrganization_Aggregate;
  /** fetch data from the table: "zoomOrganization" using primary key columns */
  zoomOrganization_by_pk?: Maybe<ZoomOrganization>;
  /** fetch data from the table: "zoomPlan" */
  zoomPlan: Array<ZoomPlan>;
  /** fetch aggregated fields from the table: "zoomPlan" */
  zoomPlan_aggregate: ZoomPlan_Aggregate;
  /** fetch data from the table: "zoomPlan" using primary key columns */
  zoomPlan_by_pk?: Maybe<ZoomPlan>;
  /** fetch data from the table: "zoomPresentation" */
  zoomPresentation: Array<ZoomPresentation>;
  /** fetch aggregated fields from the table: "zoomPresentation" */
  zoomPresentation_aggregate: ZoomPresentation_Aggregate;
  /** fetch data from the table: "zoomPresentation" using primary key columns */
  zoomPresentation_by_pk?: Maybe<ZoomPresentation>;
  /** fetch data from the table: "zoomSettings" */
  zoomSettings: Array<ZoomSettings>;
  /** fetch aggregated fields from the table: "zoomSettings" */
  zoomSettings_aggregate: ZoomSettings_Aggregate;
  /** fetch data from the table: "zoomSettings" using primary key columns */
  zoomSettings_by_pk?: Maybe<ZoomSettings>;
  /** fetch data from the table: "zoomUser" */
  zoomUser: Array<ZoomUser>;
  /** An array relationship */
  zoomUserActions: Array<ZoomUserActions>;
  /** An aggregate relationship */
  zoomUserActions_aggregate: ZoomUserActions_Aggregate;
  /** fetch data from the table: "zoomUserActions" using primary key columns */
  zoomUserActions_by_pk?: Maybe<ZoomUserActions>;
  /** fetch data from the table: "zoomUserConnectionRequest" */
  zoomUserConnectionRequest: Array<ZoomUserConnectionRequest>;
  /** fetch aggregated fields from the table: "zoomUserConnectionRequest" */
  zoomUserConnectionRequest_aggregate: ZoomUserConnectionRequest_Aggregate;
  /** fetch data from the table: "zoomUserMeetingParticipants" */
  zoomUserMeetingParticipants: Array<ZoomUserMeetingParticipants>;
  /** fetch aggregated fields from the table: "zoomUserMeetingParticipants" */
  zoomUserMeetingParticipants_aggregate: ZoomUserMeetingParticipants_Aggregate;
  /** fetch data from the table: "zoomUserNetwork" */
  zoomUserNetwork: Array<ZoomUserNetwork>;
  /** fetch aggregated fields from the table: "zoomUserNetwork" */
  zoomUserNetwork_aggregate: ZoomUserNetwork_Aggregate;
  /** fetch aggregated fields from the table: "zoomUser" */
  zoomUser_aggregate: ZoomUser_Aggregate;
  /** fetch data from the table: "zoomUser" using primary key columns */
  zoomUser_by_pk?: Maybe<ZoomUser>;
  /** fetch data from the table: "zoomUsersAtCompany" */
  zoomUsersAtCompany: Array<ZoomUsersAtCompany>;
  /** fetch aggregated fields from the table: "zoomUsersAtCompany" */
  zoomUsersAtCompany_aggregate: ZoomUsersAtCompany_Aggregate;
};

export type Query_RootCanEditCompanyDetailsArgs = {
  companyId: Scalars['String'];
};

export type Query_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Query_RootClientContactArgs = {
  distinct_on?: InputMaybe<Array<ClientContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContact_Order_By>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

export type Query_RootClientContactCrmDataArgs = {
  distinct_on?: InputMaybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactCrmData_Order_By>>;
  where?: InputMaybe<ClientContactCrmData_Bool_Exp>;
};

export type Query_RootClientContactCrmData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactCrmData_Order_By>>;
  where?: InputMaybe<ClientContactCrmData_Bool_Exp>;
};

export type Query_RootClientContactCrmData_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClientContactFilterArgs = {
  distinct_on?: InputMaybe<Array<ClientContactFilter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactFilter_Order_By>>;
  where?: InputMaybe<ClientContactFilter_Bool_Exp>;
};

export type Query_RootClientContactFilter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContactFilter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactFilter_Order_By>>;
  where?: InputMaybe<ClientContactFilter_Bool_Exp>;
};

export type Query_RootClientContactFilter_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClientContactJobChangeArgs = {
  distinct_on?: InputMaybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactJobChange_Order_By>>;
  where?: InputMaybe<ClientContactJobChange_Bool_Exp>;
};

export type Query_RootClientContactJobChange_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactJobChange_Order_By>>;
  where?: InputMaybe<ClientContactJobChange_Bool_Exp>;
};

export type Query_RootClientContactJobChange_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClientContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContact_Order_By>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

export type Query_RootClientContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClientEmailReportArgs = {
  distinct_on?: InputMaybe<Array<ClientEmailReport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientEmailReport_Order_By>>;
  where?: InputMaybe<ClientEmailReport_Bool_Exp>;
};

export type Query_RootClientEmailReport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientEmailReport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientEmailReport_Order_By>>;
  where?: InputMaybe<ClientEmailReport_Bool_Exp>;
};

export type Query_RootClientEmailReport_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClientNotificationEmailArgs = {
  distinct_on?: InputMaybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientNotificationEmail_Order_By>>;
  where?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
};

export type Query_RootClientNotificationEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientNotificationEmail_Order_By>>;
  where?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
};

export type Query_RootClientNotificationEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClientTerritoryArgs = {
  distinct_on?: InputMaybe<Array<ClientTerritory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientTerritory_Order_By>>;
  where?: InputMaybe<ClientTerritory_Bool_Exp>;
};

export type Query_RootClientTerritory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientTerritory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientTerritory_Order_By>>;
  where?: InputMaybe<ClientTerritory_Bool_Exp>;
};

export type Query_RootClientTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Query_RootClient_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCloudTaskArgs = {
  distinct_on?: InputMaybe<Array<CloudTask_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CloudTask_Order_By>>;
  where?: InputMaybe<CloudTask_Bool_Exp>;
};

export type Query_RootCloudTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CloudTask_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CloudTask_Order_By>>;
  where?: InputMaybe<CloudTask_Bool_Exp>;
};

export type Query_RootCloudTask_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Query_RootCompanyNameArgs = {
  distinct_on?: InputMaybe<Array<CompanyName_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyName_Order_By>>;
  where?: InputMaybe<CompanyName_Bool_Exp>;
};

export type Query_RootCompanyName_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyName_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyName_Order_By>>;
  where?: InputMaybe<CompanyName_Bool_Exp>;
};

export type Query_RootCompanyName_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Query_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootContactDataArgs = {
  distinct_on?: InputMaybe<Array<ContactData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactData_Order_By>>;
  where?: InputMaybe<ContactData_Bool_Exp>;
};

export type Query_RootContactData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactData_Order_By>>;
  where?: InputMaybe<ContactData_Bool_Exp>;
};

export type Query_RootContactData_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootContactEmailArgs = {
  distinct_on?: InputMaybe<Array<ContactEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactEmail_Order_By>>;
  where?: InputMaybe<ContactEmail_Bool_Exp>;
};

export type Query_RootContactEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactEmail_Order_By>>;
  where?: InputMaybe<ContactEmail_Bool_Exp>;
};

export type Query_RootContactEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootContactSuggestionsArgs = {
  options?: InputMaybe<ContactSuggestionOptions>;
};

export type Query_RootContentArgs = {
  distinct_on?: InputMaybe<Array<Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Order_By>>;
  where?: InputMaybe<Content_Bool_Exp>;
};

export type Query_RootContent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Order_By>>;
  where?: InputMaybe<Content_Bool_Exp>;
};

export type Query_RootContent_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCrmContactEnrichmentHistoryArgs = {
  distinct_on?: InputMaybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

export type Query_RootCrmContactEnrichmentHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

export type Query_RootCrmContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCsvUploadArgs = {
  distinct_on?: InputMaybe<Array<CsvUpload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CsvUpload_Order_By>>;
  where?: InputMaybe<CsvUpload_Bool_Exp>;
};

export type Query_RootCsvUpload_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CsvUpload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CsvUpload_Order_By>>;
  where?: InputMaybe<CsvUpload_Bool_Exp>;
};

export type Query_RootCsvUpload_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDeleteOldZoomEventsJobArgs = {
  distinct_on?: InputMaybe<Array<DeleteOldZoomEventsJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeleteOldZoomEventsJob_Order_By>>;
  where?: InputMaybe<DeleteOldZoomEventsJob_Bool_Exp>;
};

export type Query_RootDeleteOldZoomEventsJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeleteOldZoomEventsJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeleteOldZoomEventsJob_Order_By>>;
  where?: InputMaybe<DeleteOldZoomEventsJob_Bool_Exp>;
};

export type Query_RootDeleteOldZoomEventsJob_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEmailHistoryArgs = {
  distinct_on?: InputMaybe<Array<EmailHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailHistory_Order_By>>;
  where?: InputMaybe<EmailHistory_Bool_Exp>;
};

export type Query_RootEmailHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailHistory_Order_By>>;
  where?: InputMaybe<EmailHistory_Bool_Exp>;
};

export type Query_RootEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGetPronunciationArgs = {
  input: Scalars['String'];
};

export type Query_RootGoogleChannelArgs = {
  distinct_on?: InputMaybe<Array<GoogleChannel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleChannel_Order_By>>;
  where?: InputMaybe<GoogleChannel_Bool_Exp>;
};

export type Query_RootGoogleChannel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleChannel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleChannel_Order_By>>;
  where?: InputMaybe<GoogleChannel_Bool_Exp>;
};

export type Query_RootGoogleChannel_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGrowthIntraCompanyAdoptionContactArgs = {
  distinct_on?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Order_By>>;
  where?: InputMaybe<GrowthIntraCompanyAdoptionContact_Bool_Exp>;
};

export type Query_RootGrowthIntraCompanyAdoptionContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Order_By>>;
  where?: InputMaybe<GrowthIntraCompanyAdoptionContact_Bool_Exp>;
};

export type Query_RootGrowthIntraCompanyAdoptionContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGrowthUserReferralContactArgs = {
  distinct_on?: InputMaybe<Array<GrowthUserReferralContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthUserReferralContact_Order_By>>;
  where?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
};

export type Query_RootGrowthUserReferralContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthUserReferralContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthUserReferralContact_Order_By>>;
  where?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
};

export type Query_RootGrowthUserReferralContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHasAccessVerboseArgs = {
  featureIdentifier: Scalars['String'];
};

export type Query_RootHubspotContactListArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactList_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactList_Order_By>>;
  where?: InputMaybe<HubspotContactList_Bool_Exp>;
};

export type Query_RootHubspotContactListHubspotContactArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactListHubspotContact_Order_By>>;
  where?: InputMaybe<HubspotContactListHubspotContact_Bool_Exp>;
};

export type Query_RootHubspotContactListHubspotContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactListHubspotContact_Order_By>>;
  where?: InputMaybe<HubspotContactListHubspotContact_Bool_Exp>;
};

export type Query_RootHubspotContactListHubspotContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspotContactList_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactList_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactList_Order_By>>;
  where?: InputMaybe<HubspotContactList_Bool_Exp>;
};

export type Query_RootHubspotContactList_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspotContactPropertyArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactProperty_Order_By>>;
  where?: InputMaybe<HubspotContactProperty_Bool_Exp>;
};

export type Query_RootHubspotContactProperty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactProperty_Order_By>>;
  where?: InputMaybe<HubspotContactProperty_Bool_Exp>;
};

export type Query_RootHubspotContactProperty_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHubspotIntegrationArgs = {
  distinct_on?: InputMaybe<Array<HubspotIntegration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotIntegration_Order_By>>;
  where?: InputMaybe<HubspotIntegration_Bool_Exp>;
};

export type Query_RootHubspotIntegration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotIntegration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotIntegration_Order_By>>;
  where?: InputMaybe<HubspotIntegration_Bool_Exp>;
};

export type Query_RootHubspotIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootInviteSuggestionsArgs = {
  options?: InputMaybe<InviteSuggestionOptions>;
};

export type Query_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Query_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Query_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMicrosoftSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<MicrosoftSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MicrosoftSubscription_Order_By>>;
  where?: InputMaybe<MicrosoftSubscription_Bool_Exp>;
};

export type Query_RootMicrosoftSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MicrosoftSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MicrosoftSubscription_Order_By>>;
  where?: InputMaybe<MicrosoftSubscription_Bool_Exp>;
};

export type Query_RootMicrosoftSubscription_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOnAppLoadedArgs = {
  context: Scalars['String'];
};

export type Query_RootProxycrawlDataArgs = {
  distinct_on?: InputMaybe<Array<ProxycrawlData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProxycrawlData_Order_By>>;
  where?: InputMaybe<ProxycrawlData_Bool_Exp>;
};

export type Query_RootProxycrawlData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProxycrawlData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProxycrawlData_Order_By>>;
  where?: InputMaybe<ProxycrawlData_Bool_Exp>;
};

export type Query_RootProxycrawlData_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPublicCompanyInfoArgs = {
  companyId: Scalars['String'];
};

export type Query_RootPublicEventArgs = {
  eventId?: InputMaybe<Scalars['String']>;
};

export type Query_RootPublicUserArgs = {
  contactId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type Query_RootQaQualificationArgs = {
  distinct_on?: InputMaybe<Array<QaQualification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaQualification_Order_By>>;
  where?: InputMaybe<QaQualification_Bool_Exp>;
};

export type Query_RootQaQualification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QaQualification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaQualification_Order_By>>;
  where?: InputMaybe<QaQualification_Bool_Exp>;
};

export type Query_RootQaQualification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootQaTaskArgs = {
  distinct_on?: InputMaybe<Array<QaTask_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTask_Order_By>>;
  where?: InputMaybe<QaTask_Bool_Exp>;
};

export type Query_RootQaTaskActionArgs = {
  distinct_on?: InputMaybe<Array<QaTaskAction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTaskAction_Order_By>>;
  where?: InputMaybe<QaTaskAction_Bool_Exp>;
};

export type Query_RootQaTaskAction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QaTaskAction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTaskAction_Order_By>>;
  where?: InputMaybe<QaTaskAction_Bool_Exp>;
};

export type Query_RootQaTaskAction_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootQaTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QaTask_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTask_Order_By>>;
  where?: InputMaybe<QaTask_Bool_Exp>;
};

export type Query_RootQaTask_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSalesforceAccountArgs = {
  distinct_on?: InputMaybe<Array<SalesforceAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceAccount_Order_By>>;
  where?: InputMaybe<SalesforceAccount_Bool_Exp>;
};

export type Query_RootSalesforceAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceAccount_Order_By>>;
  where?: InputMaybe<SalesforceAccount_Bool_Exp>;
};

export type Query_RootSalesforceAccount_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSalesforceContactFieldArgs = {
  distinct_on?: InputMaybe<Array<SalesforceContactField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceContactField_Order_By>>;
  where?: InputMaybe<SalesforceContactField_Bool_Exp>;
};

export type Query_RootSalesforceContactField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceContactField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceContactField_Order_By>>;
  where?: InputMaybe<SalesforceContactField_Bool_Exp>;
};

export type Query_RootSalesforceContactField_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSalesforceIntegrationArgs = {
  distinct_on?: InputMaybe<Array<SalesforceIntegration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceIntegration_Order_By>>;
  where?: InputMaybe<SalesforceIntegration_Bool_Exp>;
};

export type Query_RootSalesforceIntegration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceIntegration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceIntegration_Order_By>>;
  where?: InputMaybe<SalesforceIntegration_Bool_Exp>;
};

export type Query_RootSalesforceIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSalesforceOpportunityArgs = {
  distinct_on?: InputMaybe<Array<SalesforceOpportunity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceOpportunity_Order_By>>;
  where?: InputMaybe<SalesforceOpportunity_Bool_Exp>;
};

export type Query_RootSalesforceOpportunity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceOpportunity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceOpportunity_Order_By>>;
  where?: InputMaybe<SalesforceOpportunity_Bool_Exp>;
};

export type Query_RootSalesforceOpportunity_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSendgridEventArgs = {
  distinct_on?: InputMaybe<Array<SendgridEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SendgridEvent_Order_By>>;
  where?: InputMaybe<SendgridEvent_Bool_Exp>;
};

export type Query_RootSendgridEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SendgridEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SendgridEvent_Order_By>>;
  where?: InputMaybe<SendgridEvent_Bool_Exp>;
};

export type Query_RootSendgridEvent_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSyncZoomEventsJobArgs = {
  distinct_on?: InputMaybe<Array<SyncZoomEventsJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SyncZoomEventsJob_Order_By>>;
  where?: InputMaybe<SyncZoomEventsJob_Bool_Exp>;
};

export type Query_RootSyncZoomEventsJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SyncZoomEventsJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SyncZoomEventsJob_Order_By>>;
  where?: InputMaybe<SyncZoomEventsJob_Bool_Exp>;
};

export type Query_RootSyncZoomEventsJob_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootTerritoryArgs = {
  distinct_on?: InputMaybe<Array<Territory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Territory_Order_By>>;
  where?: InputMaybe<Territory_Bool_Exp>;
};

export type Query_RootTerritorySearchTermArgs = {
  distinct_on?: InputMaybe<Array<TerritorySearchTerm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TerritorySearchTerm_Order_By>>;
  where?: InputMaybe<TerritorySearchTerm_Bool_Exp>;
};

export type Query_RootTerritorySearchTerm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TerritorySearchTerm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TerritorySearchTerm_Order_By>>;
  where?: InputMaybe<TerritorySearchTerm_Bool_Exp>;
};

export type Query_RootTerritorySearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTerritory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Territory_Order_By>>;
  where?: InputMaybe<Territory_Bool_Exp>;
};

export type Query_RootTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTheOrgSearchTermArgs = {
  distinct_on?: InputMaybe<Array<TheOrgSearchTerm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TheOrgSearchTerm_Order_By>>;
  where?: InputMaybe<TheOrgSearchTerm_Bool_Exp>;
};

export type Query_RootTheOrgSearchTerm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TheOrgSearchTerm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TheOrgSearchTerm_Order_By>>;
  where?: InputMaybe<TheOrgSearchTerm_Bool_Exp>;
};

export type Query_RootTheOrgSearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUserInviteArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

export type Query_RootUserInvite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

export type Query_RootUserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUsernameIsValidArgs = {
  input: UsernameInput;
};

export type Query_RootWarmCompanyArgs = {
  distinct_on?: InputMaybe<Array<WarmCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WarmCompany_Order_By>>;
  where?: InputMaybe<WarmCompany_Bool_Exp>;
};

export type Query_RootWarmCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WarmCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WarmCompany_Order_By>>;
  where?: InputMaybe<WarmCompany_Bool_Exp>;
};

export type Query_RootWorkOsConnectionArgs = {
  distinct_on?: InputMaybe<Array<WorkOsConnection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsConnection_Order_By>>;
  where?: InputMaybe<WorkOsConnection_Bool_Exp>;
};

export type Query_RootWorkOsConnection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkOsConnection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsConnection_Order_By>>;
  where?: InputMaybe<WorkOsConnection_Bool_Exp>;
};

export type Query_RootWorkOsConnection_By_PkArgs = {
  connectionId: Scalars['String'];
};

export type Query_RootWorkOsDirectoryArgs = {
  distinct_on?: InputMaybe<Array<WorkOsDirectory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsDirectory_Order_By>>;
  where?: InputMaybe<WorkOsDirectory_Bool_Exp>;
};

export type Query_RootWorkOsDirectory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkOsDirectory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsDirectory_Order_By>>;
  where?: InputMaybe<WorkOsDirectory_Bool_Exp>;
};

export type Query_RootWorkOsDirectory_By_PkArgs = {
  directoryId: Scalars['String'];
};

export type Query_RootWorkOsGroupArgs = {
  distinct_on?: InputMaybe<Array<WorkOsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsGroup_Order_By>>;
  where?: InputMaybe<WorkOsGroup_Bool_Exp>;
};

export type Query_RootWorkOsGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkOsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsGroup_Order_By>>;
  where?: InputMaybe<WorkOsGroup_Bool_Exp>;
};

export type Query_RootWorkOsGroup_By_PkArgs = {
  groupId: Scalars['String'];
};

export type Query_RootWorkOsUserArgs = {
  distinct_on?: InputMaybe<Array<WorkOsUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsUser_Order_By>>;
  where?: InputMaybe<WorkOsUser_Bool_Exp>;
};

export type Query_RootWorkOsUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkOsUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsUser_Order_By>>;
  where?: InputMaybe<WorkOsUser_Bool_Exp>;
};

export type Query_RootWorkOsUser_By_PkArgs = {
  userId: Scalars['String'];
};

export type Query_RootZoomApprovedDomainArgs = {
  distinct_on?: InputMaybe<Array<ZoomApprovedDomain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomApprovedDomain_Order_By>>;
  where?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
};

export type Query_RootZoomApprovedDomain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomApprovedDomain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomApprovedDomain_Order_By>>;
  where?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
};

export type Query_RootZoomApprovedDomain_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomCompanyArgs = {
  distinct_on?: InputMaybe<Array<ZoomCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompany_Order_By>>;
  where?: InputMaybe<ZoomCompany_Bool_Exp>;
};

export type Query_RootZoomCompanyMergeResultArgs = {
  distinct_on?: InputMaybe<Array<ZoomCompanyMergeResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompanyMergeResult_Order_By>>;
  where?: InputMaybe<ZoomCompanyMergeResult_Bool_Exp>;
};

export type Query_RootZoomCompanyMergeResult_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomCompanyMergeResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompanyMergeResult_Order_By>>;
  where?: InputMaybe<ZoomCompanyMergeResult_Bool_Exp>;
};

export type Query_RootZoomCompanyMergeResult_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompany_Order_By>>;
  where?: InputMaybe<ZoomCompany_Bool_Exp>;
};

export type Query_RootZoomCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomConnectionRequestArgs = {
  distinct_on?: InputMaybe<Array<ZoomConnectionRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequest_Order_By>>;
  where?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
};

export type Query_RootZoomConnectionRequestReminderArgs = {
  distinct_on?: InputMaybe<Array<ZoomConnectionRequestReminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequestReminder_Order_By>>;
  where?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
};

export type Query_RootZoomConnectionRequestReminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomConnectionRequestReminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequestReminder_Order_By>>;
  where?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
};

export type Query_RootZoomConnectionRequestReminder_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomConnectionRequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomConnectionRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequest_Order_By>>;
  where?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
};

export type Query_RootZoomConnectionRequest_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomContactArgs = {
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By>>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
};

export type Query_RootZoomContactNotesArgs = {
  distinct_on?: InputMaybe<Array<ZoomContactNotes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContactNotes_Order_By>>;
  where?: InputMaybe<ZoomContactNotes_Bool_Exp>;
};

export type Query_RootZoomContactNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomContactNotes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContactNotes_Order_By>>;
  where?: InputMaybe<ZoomContactNotes_Bool_Exp>;
};

export type Query_RootZoomContactNotes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By>>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
};

export type Query_RootZoomContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomConversationStarterAnswerArgs = {
  distinct_on?: InputMaybe<Array<ZoomConversationStarterAnswer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterAnswer_Order_By>>;
  where?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
};

export type Query_RootZoomConversationStarterAnswer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomConversationStarterAnswer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterAnswer_Order_By>>;
  where?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
};

export type Query_RootZoomConversationStarterAnswer_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomConversationStarterPromptArgs = {
  distinct_on?: InputMaybe<Array<ZoomConversationStarterPrompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterPrompt_Order_By>>;
  where?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
};

export type Query_RootZoomConversationStarterPrompt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomConversationStarterPrompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterPrompt_Order_By>>;
  where?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
};

export type Query_RootZoomConversationStarterPrompt_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomCustomBackgroundArgs = {
  distinct_on?: InputMaybe<Array<ZoomCustomBackground_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCustomBackground_Order_By>>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
};

export type Query_RootZoomCustomBackground_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomCustomBackground_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCustomBackground_Order_By>>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
};

export type Query_RootZoomCustomBackground_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomEmailHistoryArgs = {
  distinct_on?: InputMaybe<Array<ZoomEmailHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEmailHistory_Order_By>>;
  where?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
};

export type Query_RootZoomEmailHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEmailHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEmailHistory_Order_By>>;
  where?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
};

export type Query_RootZoomEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomEventArgs = {
  distinct_on?: InputMaybe<Array<ZoomEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEvent_Order_By>>;
  where?: InputMaybe<ZoomEvent_Bool_Exp>;
};

export type Query_RootZoomEventParticipantArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

export type Query_RootZoomEventParticipantWithContactsArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Query_RootZoomEventParticipantWithContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Query_RootZoomEventParticipantWithContacts_SortedArgs = {
  args: ZoomEventParticipantWithContacts_Sorted_Args;
  distinct_on?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Query_RootZoomEventParticipantWithContacts_Sorted_AggregateArgs = {
  args: ZoomEventParticipantWithContacts_Sorted_Args;
  distinct_on?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Query_RootZoomEventParticipant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

export type Query_RootZoomEventParticipant_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEvent_Order_By>>;
  where?: InputMaybe<ZoomEvent_Bool_Exp>;
};

export type Query_RootZoomEvent_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootZoomExternalAuthUserArgs = {
  distinct_on?: InputMaybe<Array<ZoomExternalAuthUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomExternalAuthUser_Order_By>>;
  where?: InputMaybe<ZoomExternalAuthUser_Bool_Exp>;
};

export type Query_RootZoomExternalAuthUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomExternalAuthUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomExternalAuthUser_Order_By>>;
  where?: InputMaybe<ZoomExternalAuthUser_Bool_Exp>;
};

export type Query_RootZoomExternalAuthUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomGroupArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroup_Order_By>>;
  where?: InputMaybe<ZoomGroup_Bool_Exp>;
};

export type Query_RootZoomGroupMembershipArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

export type Query_RootZoomGroupMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

export type Query_RootZoomGroupMembership_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroup_Order_By>>;
  where?: InputMaybe<ZoomGroup_Bool_Exp>;
};

export type Query_RootZoomGroup_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomLayersEventsArgs = {
  distinct_on?: InputMaybe<Array<ZoomLayersEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomLayersEvents_Order_By>>;
  where?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
};

export type Query_RootZoomLayersEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomLayersEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomLayersEvents_Order_By>>;
  where?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
};

export type Query_RootZoomLayersEvents_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomOrganizationArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganization_Order_By>>;
  where?: InputMaybe<ZoomOrganization_Bool_Exp>;
};

export type Query_RootZoomOrganizationBackfillArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfill_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfill_Bool_Exp>;
};

export type Query_RootZoomOrganizationBackfillResultArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfillResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfillResult_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
};

export type Query_RootZoomOrganizationBackfillResult_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfillResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfillResult_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
};

export type Query_RootZoomOrganizationBackfillResult_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomOrganizationBackfill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfill_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfill_Bool_Exp>;
};

export type Query_RootZoomOrganizationBackfill_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomOrganizationMembershipArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembership_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
};

export type Query_RootZoomOrganizationMembershipViewArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembershipView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembershipView_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
};

export type Query_RootZoomOrganizationMembershipView_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembershipView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembershipView_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
};

export type Query_RootZoomOrganizationMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembership_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
};

export type Query_RootZoomOrganizationMembership_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganization_Order_By>>;
  where?: InputMaybe<ZoomOrganization_Bool_Exp>;
};

export type Query_RootZoomOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomPlanArgs = {
  distinct_on?: InputMaybe<Array<ZoomPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPlan_Order_By>>;
  where?: InputMaybe<ZoomPlan_Bool_Exp>;
};

export type Query_RootZoomPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPlan_Order_By>>;
  where?: InputMaybe<ZoomPlan_Bool_Exp>;
};

export type Query_RootZoomPlan_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomPresentationArgs = {
  distinct_on?: InputMaybe<Array<ZoomPresentation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPresentation_Order_By>>;
  where?: InputMaybe<ZoomPresentation_Bool_Exp>;
};

export type Query_RootZoomPresentation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomPresentation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPresentation_Order_By>>;
  where?: InputMaybe<ZoomPresentation_Bool_Exp>;
};

export type Query_RootZoomPresentation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomSettingsArgs = {
  distinct_on?: InputMaybe<Array<ZoomSettings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomSettings_Order_By>>;
  where?: InputMaybe<ZoomSettings_Bool_Exp>;
};

export type Query_RootZoomSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomSettings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomSettings_Order_By>>;
  where?: InputMaybe<ZoomSettings_Bool_Exp>;
};

export type Query_RootZoomSettings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootZoomUserArgs = {
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By>>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
};

export type Query_RootZoomUserActionsArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserActions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserActions_Order_By>>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
};

export type Query_RootZoomUserActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserActions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserActions_Order_By>>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
};

export type Query_RootZoomUserActions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomUserConnectionRequestArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserConnectionRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserConnectionRequest_Order_By>>;
  where?: InputMaybe<ZoomUserConnectionRequest_Bool_Exp>;
};

export type Query_RootZoomUserConnectionRequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserConnectionRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserConnectionRequest_Order_By>>;
  where?: InputMaybe<ZoomUserConnectionRequest_Bool_Exp>;
};

export type Query_RootZoomUserMeetingParticipantsArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserMeetingParticipants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserMeetingParticipants_Order_By>>;
  where?: InputMaybe<ZoomUserMeetingParticipants_Bool_Exp>;
};

export type Query_RootZoomUserMeetingParticipants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserMeetingParticipants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserMeetingParticipants_Order_By>>;
  where?: InputMaybe<ZoomUserMeetingParticipants_Bool_Exp>;
};

export type Query_RootZoomUserNetworkArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserNetwork_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserNetwork_Order_By>>;
  where?: InputMaybe<ZoomUserNetwork_Bool_Exp>;
};

export type Query_RootZoomUserNetwork_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserNetwork_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserNetwork_Order_By>>;
  where?: InputMaybe<ZoomUserNetwork_Bool_Exp>;
};

export type Query_RootZoomUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By>>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
};

export type Query_RootZoomUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootZoomUsersAtCompanyArgs = {
  distinct_on?: InputMaybe<Array<ZoomUsersAtCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUsersAtCompany_Order_By>>;
  where?: InputMaybe<ZoomUsersAtCompany_Bool_Exp>;
};

export type Query_RootZoomUsersAtCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUsersAtCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUsersAtCompany_Order_By>>;
  where?: InputMaybe<ZoomUsersAtCompany_Bool_Exp>;
};

/** columns and relationships of "salesforceAccount" */
export type SalesforceAccount = {
  __typename?: 'salesforceAccount';
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  salesforceId: Scalars['String'];
  salesforceIntegrationId: Scalars['uuid'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  website?: Maybe<Scalars['String']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
};

/** aggregated selection of "salesforceAccount" */
export type SalesforceAccount_Aggregate = {
  __typename?: 'salesforceAccount_aggregate';
  aggregate?: Maybe<SalesforceAccount_Aggregate_Fields>;
  nodes: Array<SalesforceAccount>;
};

/** aggregate fields of "salesforceAccount" */
export type SalesforceAccount_Aggregate_Fields = {
  __typename?: 'salesforceAccount_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SalesforceAccount_Max_Fields>;
  min?: Maybe<SalesforceAccount_Min_Fields>;
};

/** aggregate fields of "salesforceAccount" */
export type SalesforceAccount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SalesforceAccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "salesforceAccount". All fields are combined with a logical 'AND'. */
export type SalesforceAccount_Bool_Exp = {
  _and?: InputMaybe<Array<SalesforceAccount_Bool_Exp>>;
  _not?: InputMaybe<SalesforceAccount_Bool_Exp>;
  _or?: InputMaybe<Array<SalesforceAccount_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ownerId?: InputMaybe<String_Comparison_Exp>;
  salesforceId?: InputMaybe<String_Comparison_Exp>;
  salesforceIntegrationId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
  websiteDomain?: InputMaybe<String_Comparison_Exp>;
  websiteRootDomain?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforceAccount" */
export enum SalesforceAccount_Constraint {
  /** unique or primary key constraint on columns "id" */
  SalesforceAccountPkey = 'salesforceAccount_pkey',
  /** unique or primary key constraint on columns "salesforceId" */
  SalesforceAccountSalesforceIdKey = 'salesforceAccount_salesforceId_key',
}

/** input type for inserting data into table "salesforceAccount" */
export type SalesforceAccount_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteRootDomain?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SalesforceAccount_Max_Fields = {
  __typename?: 'salesforceAccount_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SalesforceAccount_Min_Fields = {
  __typename?: 'salesforceAccount_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforceAccount" */
export type SalesforceAccount_Mutation_Response = {
  __typename?: 'salesforceAccount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SalesforceAccount>;
};

/** on_conflict condition type for table "salesforceAccount" */
export type SalesforceAccount_On_Conflict = {
  constraint: SalesforceAccount_Constraint;
  update_columns?: Array<SalesforceAccount_Update_Column>;
  where?: InputMaybe<SalesforceAccount_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforceAccount". */
export type SalesforceAccount_Order_By = {
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  salesforceId?: InputMaybe<Order_By>;
  salesforceIntegrationId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
  websiteDomain?: InputMaybe<Order_By>;
  websiteRootDomain?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforceAccount */
export type SalesforceAccount_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "salesforceAccount" */
export enum SalesforceAccount_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteRootDomain = 'websiteRootDomain',
}

/** input type for updating data in table "salesforceAccount" */
export type SalesforceAccount_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteRootDomain?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "salesforceAccount" */
export type SalesforceAccount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SalesforceAccount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SalesforceAccount_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  website?: InputMaybe<Scalars['String']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteRootDomain?: InputMaybe<Scalars['String']>;
};

/** update columns of table "salesforceAccount" */
export enum SalesforceAccount_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteRootDomain = 'websiteRootDomain',
}

export type SalesforceAccount_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SalesforceAccount_Set_Input>;
  /** filter the rows which have to be updated */
  where: SalesforceAccount_Bool_Exp;
};

/** columns and relationships of "salesforceContactField" */
export type SalesforceContactField = {
  __typename?: 'salesforceContactField';
  clientId: Scalars['uuid'];
  id: Scalars['uuid'];
  json?: Maybe<Scalars['jsonb']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  salesforceIntegrationId: Scalars['uuid'];
};

/** columns and relationships of "salesforceContactField" */
export type SalesforceContactFieldJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "salesforceContactField" */
export type SalesforceContactField_Aggregate = {
  __typename?: 'salesforceContactField_aggregate';
  aggregate?: Maybe<SalesforceContactField_Aggregate_Fields>;
  nodes: Array<SalesforceContactField>;
};

export type SalesforceContactField_Aggregate_Bool_Exp = {
  count?: InputMaybe<SalesforceContactField_Aggregate_Bool_Exp_Count>;
};

export type SalesforceContactField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SalesforceContactField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SalesforceContactField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "salesforceContactField" */
export type SalesforceContactField_Aggregate_Fields = {
  __typename?: 'salesforceContactField_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SalesforceContactField_Max_Fields>;
  min?: Maybe<SalesforceContactField_Min_Fields>;
};

/** aggregate fields of "salesforceContactField" */
export type SalesforceContactField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SalesforceContactField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "salesforceContactField" */
export type SalesforceContactField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<SalesforceContactField_Max_Order_By>;
  min?: InputMaybe<SalesforceContactField_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SalesforceContactField_Append_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "salesforceContactField" */
export type SalesforceContactField_Arr_Rel_Insert_Input = {
  data: Array<SalesforceContactField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<SalesforceContactField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "salesforceContactField". All fields are combined with a logical 'AND'. */
export type SalesforceContactField_Bool_Exp = {
  _and?: InputMaybe<Array<SalesforceContactField_Bool_Exp>>;
  _not?: InputMaybe<SalesforceContactField_Bool_Exp>;
  _or?: InputMaybe<Array<SalesforceContactField_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  json?: InputMaybe<Jsonb_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  lastSyncedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  salesforceIntegrationId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforceContactField" */
export enum SalesforceContactField_Constraint {
  /** unique or primary key constraint on columns "id" */
  SalesforceContactFieldPkey = 'salesforceContactField_pkey',
  /** unique or primary key constraint on columns "salesforceIntegrationId", "name" */
  SalesforceContactFieldSalesforceIntegrationIdNameKey = 'salesforceContactField_salesforceIntegrationId_name_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SalesforceContactField_Delete_At_Path_Input = {
  json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SalesforceContactField_Delete_Elem_Input = {
  json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SalesforceContactField_Delete_Key_Input = {
  json?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "salesforceContactField" */
export type SalesforceContactField_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  label?: InputMaybe<Scalars['String']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SalesforceContactField_Max_Fields = {
  __typename?: 'salesforceContactField_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "salesforceContactField" */
export type SalesforceContactField_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  salesforceIntegrationId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type SalesforceContactField_Min_Fields = {
  __typename?: 'salesforceContactField_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "salesforceContactField" */
export type SalesforceContactField_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  salesforceIntegrationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "salesforceContactField" */
export type SalesforceContactField_Mutation_Response = {
  __typename?: 'salesforceContactField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SalesforceContactField>;
};

/** on_conflict condition type for table "salesforceContactField" */
export type SalesforceContactField_On_Conflict = {
  constraint: SalesforceContactField_Constraint;
  update_columns?: Array<SalesforceContactField_Update_Column>;
  where?: InputMaybe<SalesforceContactField_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforceContactField". */
export type SalesforceContactField_Order_By = {
  clientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  json?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  lastSyncedAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  salesforceIntegrationId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforceContactField */
export type SalesforceContactField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SalesforceContactField_Prepend_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "salesforceContactField" */
export enum SalesforceContactField_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Label = 'label',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  Name = 'name',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
}

/** input type for updating data in table "salesforceContactField" */
export type SalesforceContactField_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  label?: InputMaybe<Scalars['String']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "salesforceContactField" */
export type SalesforceContactField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SalesforceContactField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SalesforceContactField_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  label?: InputMaybe<Scalars['String']>;
  lastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "salesforceContactField" */
export enum SalesforceContactField_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Label = 'label',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  Name = 'name',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
}

export type SalesforceContactField_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SalesforceContactField_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<SalesforceContactField_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<SalesforceContactField_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<SalesforceContactField_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SalesforceContactField_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SalesforceContactField_Set_Input>;
  /** filter the rows which have to be updated */
  where: SalesforceContactField_Bool_Exp;
};

/** columns and relationships of "salesforceIntegration" */
export type SalesforceIntegration = {
  __typename?: 'salesforceIntegration';
  accessToken?: Maybe<Scalars['String']>;
  accountsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  clientId: Scalars['uuid'];
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  instanceUrl?: Maybe<Scalars['String']>;
  mapping?: Maybe<Scalars['jsonb']>;
  opportunitiesLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['String']>;
  /** An array relationship */
  salesforceContactFields: Array<SalesforceContactField>;
  /** An aggregate relationship */
  salesforceContactFields_aggregate: SalesforceContactField_Aggregate;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  warmlyContactFieldsCreatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: Maybe<Scalars['String']>;
};

/** columns and relationships of "salesforceIntegration" */
export type SalesforceIntegrationMappingArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "salesforceIntegration" */
export type SalesforceIntegrationSalesforceContactFieldsArgs = {
  distinct_on?: InputMaybe<Array<SalesforceContactField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceContactField_Order_By>>;
  where?: InputMaybe<SalesforceContactField_Bool_Exp>;
};

/** columns and relationships of "salesforceIntegration" */
export type SalesforceIntegrationSalesforceContactFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceContactField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceContactField_Order_By>>;
  where?: InputMaybe<SalesforceContactField_Bool_Exp>;
};

/** aggregated selection of "salesforceIntegration" */
export type SalesforceIntegration_Aggregate = {
  __typename?: 'salesforceIntegration_aggregate';
  aggregate?: Maybe<SalesforceIntegration_Aggregate_Fields>;
  nodes: Array<SalesforceIntegration>;
};

/** aggregate fields of "salesforceIntegration" */
export type SalesforceIntegration_Aggregate_Fields = {
  __typename?: 'salesforceIntegration_aggregate_fields';
  avg?: Maybe<SalesforceIntegration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SalesforceIntegration_Max_Fields>;
  min?: Maybe<SalesforceIntegration_Min_Fields>;
  stddev?: Maybe<SalesforceIntegration_Stddev_Fields>;
  stddev_pop?: Maybe<SalesforceIntegration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SalesforceIntegration_Stddev_Samp_Fields>;
  sum?: Maybe<SalesforceIntegration_Sum_Fields>;
  var_pop?: Maybe<SalesforceIntegration_Var_Pop_Fields>;
  var_samp?: Maybe<SalesforceIntegration_Var_Samp_Fields>;
  variance?: Maybe<SalesforceIntegration_Variance_Fields>;
};

/** aggregate fields of "salesforceIntegration" */
export type SalesforceIntegration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SalesforceIntegration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SalesforceIntegration_Append_Input = {
  mapping?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type SalesforceIntegration_Avg_Fields = {
  __typename?: 'salesforceIntegration_avg_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "salesforceIntegration". All fields are combined with a logical 'AND'. */
export type SalesforceIntegration_Bool_Exp = {
  _and?: InputMaybe<Array<SalesforceIntegration_Bool_Exp>>;
  _not?: InputMaybe<SalesforceIntegration_Bool_Exp>;
  _or?: InputMaybe<Array<SalesforceIntegration_Bool_Exp>>;
  accessToken?: InputMaybe<String_Comparison_Exp>;
  accountsLastSyncedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  contactsLastSyncedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  contactsLastSyncedCount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instanceUrl?: InputMaybe<String_Comparison_Exp>;
  mapping?: InputMaybe<Jsonb_Comparison_Exp>;
  opportunitiesLastSyncedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  refreshToken?: InputMaybe<String_Comparison_Exp>;
  salesforceContactFields?: InputMaybe<SalesforceContactField_Bool_Exp>;
  salesforceContactFields_aggregate?: InputMaybe<SalesforceContactField_Aggregate_Bool_Exp>;
  totalContactCount?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  warmlyContactFieldsCreatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  warmlyContactFieldsVersion?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforceIntegration" */
export enum SalesforceIntegration_Constraint {
  /** unique or primary key constraint on columns "clientId" */
  SalesforceIntegrationClientIdKey = 'salesforceIntegration_clientId_key',
  /** unique or primary key constraint on columns "id" */
  SalesforceIntegrationPkey = 'salesforceIntegration_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SalesforceIntegration_Delete_At_Path_Input = {
  mapping?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SalesforceIntegration_Delete_Elem_Input = {
  mapping?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SalesforceIntegration_Delete_Key_Input = {
  mapping?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "salesforceIntegration" */
export type SalesforceIntegration_Inc_Input = {
  contactsLastSyncedCount?: InputMaybe<Scalars['Int']>;
  totalContactCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "salesforceIntegration" */
export type SalesforceIntegration_Insert_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  accountsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  contactsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  instanceUrl?: InputMaybe<Scalars['String']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  opportunitiesLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  salesforceContactFields?: InputMaybe<SalesforceContactField_Arr_Rel_Insert_Input>;
  totalContactCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SalesforceIntegration_Max_Fields = {
  __typename?: 'salesforceIntegration_max_fields';
  accessToken?: Maybe<Scalars['String']>;
  accountsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instanceUrl?: Maybe<Scalars['String']>;
  opportunitiesLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SalesforceIntegration_Min_Fields = {
  __typename?: 'salesforceIntegration_min_fields';
  accessToken?: Maybe<Scalars['String']>;
  accountsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instanceUrl?: Maybe<Scalars['String']>;
  opportunitiesLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforceIntegration" */
export type SalesforceIntegration_Mutation_Response = {
  __typename?: 'salesforceIntegration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SalesforceIntegration>;
};

/** input type for inserting object relation for remote table "salesforceIntegration" */
export type SalesforceIntegration_Obj_Rel_Insert_Input = {
  data: SalesforceIntegration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<SalesforceIntegration_On_Conflict>;
};

/** on_conflict condition type for table "salesforceIntegration" */
export type SalesforceIntegration_On_Conflict = {
  constraint: SalesforceIntegration_Constraint;
  update_columns?: Array<SalesforceIntegration_Update_Column>;
  where?: InputMaybe<SalesforceIntegration_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforceIntegration". */
export type SalesforceIntegration_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  accountsLastSyncedAt?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  contactsLastSyncedAt?: InputMaybe<Order_By>;
  contactsLastSyncedCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instanceUrl?: InputMaybe<Order_By>;
  mapping?: InputMaybe<Order_By>;
  opportunitiesLastSyncedAt?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  salesforceContactFields_aggregate?: InputMaybe<SalesforceContactField_Aggregate_Order_By>;
  totalContactCount?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  warmlyContactFieldsCreatedAt?: InputMaybe<Order_By>;
  warmlyContactFieldsVersion?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforceIntegration */
export type SalesforceIntegration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SalesforceIntegration_Prepend_Input = {
  mapping?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "salesforceIntegration" */
export enum SalesforceIntegration_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccountsLastSyncedAt = 'accountsLastSyncedAt',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactsLastSyncedAt = 'contactsLastSyncedAt',
  /** column name */
  ContactsLastSyncedCount = 'contactsLastSyncedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceUrl = 'instanceUrl',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  OpportunitiesLastSyncedAt = 'opportunitiesLastSyncedAt',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  TotalContactCount = 'totalContactCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarmlyContactFieldsCreatedAt = 'warmlyContactFieldsCreatedAt',
  /** column name */
  WarmlyContactFieldsVersion = 'warmlyContactFieldsVersion',
}

/** input type for updating data in table "salesforceIntegration" */
export type SalesforceIntegration_Set_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  accountsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  contactsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  instanceUrl?: InputMaybe<Scalars['String']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  opportunitiesLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  totalContactCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SalesforceIntegration_Stddev_Fields = {
  __typename?: 'salesforceIntegration_stddev_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SalesforceIntegration_Stddev_Pop_Fields = {
  __typename?: 'salesforceIntegration_stddev_pop_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SalesforceIntegration_Stddev_Samp_Fields = {
  __typename?: 'salesforceIntegration_stddev_samp_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "salesforceIntegration" */
export type SalesforceIntegration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SalesforceIntegration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SalesforceIntegration_Stream_Cursor_Value_Input = {
  accessToken?: InputMaybe<Scalars['String']>;
  accountsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  contactsLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  instanceUrl?: InputMaybe<Scalars['String']>;
  mapping?: InputMaybe<Scalars['jsonb']>;
  opportunitiesLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  totalContactCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: InputMaybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type SalesforceIntegration_Sum_Fields = {
  __typename?: 'salesforceIntegration_sum_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  totalContactCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "salesforceIntegration" */
export enum SalesforceIntegration_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccountsLastSyncedAt = 'accountsLastSyncedAt',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactsLastSyncedAt = 'contactsLastSyncedAt',
  /** column name */
  ContactsLastSyncedCount = 'contactsLastSyncedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceUrl = 'instanceUrl',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  OpportunitiesLastSyncedAt = 'opportunitiesLastSyncedAt',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  TotalContactCount = 'totalContactCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarmlyContactFieldsCreatedAt = 'warmlyContactFieldsCreatedAt',
  /** column name */
  WarmlyContactFieldsVersion = 'warmlyContactFieldsVersion',
}

export type SalesforceIntegration_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SalesforceIntegration_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<SalesforceIntegration_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<SalesforceIntegration_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<SalesforceIntegration_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SalesforceIntegration_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SalesforceIntegration_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SalesforceIntegration_Set_Input>;
  /** filter the rows which have to be updated */
  where: SalesforceIntegration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SalesforceIntegration_Var_Pop_Fields = {
  __typename?: 'salesforceIntegration_var_pop_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SalesforceIntegration_Var_Samp_Fields = {
  __typename?: 'salesforceIntegration_var_samp_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SalesforceIntegration_Variance_Fields = {
  __typename?: 'salesforceIntegration_variance_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "salesforceOpportunity" */
export type SalesforceOpportunity = {
  __typename?: 'salesforceOpportunity';
  accountId?: Maybe<Scalars['String']>;
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  salesforceId: Scalars['String'];
  salesforceIntegrationId: Scalars['uuid'];
  stage: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "salesforceOpportunity" */
export type SalesforceOpportunity_Aggregate = {
  __typename?: 'salesforceOpportunity_aggregate';
  aggregate?: Maybe<SalesforceOpportunity_Aggregate_Fields>;
  nodes: Array<SalesforceOpportunity>;
};

/** aggregate fields of "salesforceOpportunity" */
export type SalesforceOpportunity_Aggregate_Fields = {
  __typename?: 'salesforceOpportunity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SalesforceOpportunity_Max_Fields>;
  min?: Maybe<SalesforceOpportunity_Min_Fields>;
};

/** aggregate fields of "salesforceOpportunity" */
export type SalesforceOpportunity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SalesforceOpportunity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "salesforceOpportunity". All fields are combined with a logical 'AND'. */
export type SalesforceOpportunity_Bool_Exp = {
  _and?: InputMaybe<Array<SalesforceOpportunity_Bool_Exp>>;
  _not?: InputMaybe<SalesforceOpportunity_Bool_Exp>;
  _or?: InputMaybe<Array<SalesforceOpportunity_Bool_Exp>>;
  accountId?: InputMaybe<String_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ownerId?: InputMaybe<String_Comparison_Exp>;
  salesforceId?: InputMaybe<String_Comparison_Exp>;
  salesforceIntegrationId?: InputMaybe<Uuid_Comparison_Exp>;
  stage?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforceOpportunity" */
export enum SalesforceOpportunity_Constraint {
  /** unique or primary key constraint on columns "id" */
  SalesforceOpportunityPkey = 'salesforceOpportunity_pkey',
  /** unique or primary key constraint on columns "salesforceId" */
  SalesforceOpportunitySalesforceIdKey = 'salesforceOpportunity_salesforceId_key',
}

/** input type for inserting data into table "salesforceOpportunity" */
export type SalesforceOpportunity_Insert_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  stage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SalesforceOpportunity_Max_Fields = {
  __typename?: 'salesforceOpportunity_max_fields';
  accountId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SalesforceOpportunity_Min_Fields = {
  __typename?: 'salesforceOpportunity_min_fields';
  accountId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "salesforceOpportunity" */
export type SalesforceOpportunity_Mutation_Response = {
  __typename?: 'salesforceOpportunity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SalesforceOpportunity>;
};

/** on_conflict condition type for table "salesforceOpportunity" */
export type SalesforceOpportunity_On_Conflict = {
  constraint: SalesforceOpportunity_Constraint;
  update_columns?: Array<SalesforceOpportunity_Update_Column>;
  where?: InputMaybe<SalesforceOpportunity_Bool_Exp>;
};

/** Ordering options when selecting data from "salesforceOpportunity". */
export type SalesforceOpportunity_Order_By = {
  accountId?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  salesforceId?: InputMaybe<Order_By>;
  salesforceIntegrationId?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salesforceOpportunity */
export type SalesforceOpportunity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "salesforceOpportunity" */
export enum SalesforceOpportunity_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  Stage = 'stage',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "salesforceOpportunity" */
export type SalesforceOpportunity_Set_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  stage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "salesforceOpportunity" */
export type SalesforceOpportunity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SalesforceOpportunity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SalesforceOpportunity_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  salesforceId?: InputMaybe<Scalars['String']>;
  salesforceIntegrationId?: InputMaybe<Scalars['uuid']>;
  stage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "salesforceOpportunity" */
export enum SalesforceOpportunity_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  Stage = 'stage',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SalesforceOpportunity_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SalesforceOpportunity_Set_Input>;
  /** filter the rows which have to be updated */
  where: SalesforceOpportunity_Bool_Exp;
};

/** columns and relationships of "sendgridEvent" */
export type SendgridEvent = {
  __typename?: 'sendgridEvent';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  emailHistoryId?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  eventJson?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  sgEventId?: Maybe<Scalars['String']>;
  sgMessageId?: Maybe<Scalars['String']>;
  sgTemplateId?: Maybe<Scalars['String']>;
  sgTemplateName?: Maybe<Scalars['String']>;
};

/** columns and relationships of "sendgridEvent" */
export type SendgridEventEventJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "sendgridEvent" */
export type SendgridEvent_Aggregate = {
  __typename?: 'sendgridEvent_aggregate';
  aggregate?: Maybe<SendgridEvent_Aggregate_Fields>;
  nodes: Array<SendgridEvent>;
};

/** aggregate fields of "sendgridEvent" */
export type SendgridEvent_Aggregate_Fields = {
  __typename?: 'sendgridEvent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SendgridEvent_Max_Fields>;
  min?: Maybe<SendgridEvent_Min_Fields>;
};

/** aggregate fields of "sendgridEvent" */
export type SendgridEvent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SendgridEvent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SendgridEvent_Append_Input = {
  eventJson?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "sendgridEvent". All fields are combined with a logical 'AND'. */
export type SendgridEvent_Bool_Exp = {
  _and?: InputMaybe<Array<SendgridEvent_Bool_Exp>>;
  _not?: InputMaybe<SendgridEvent_Bool_Exp>;
  _or?: InputMaybe<Array<SendgridEvent_Bool_Exp>>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  emailHistoryId?: InputMaybe<Uuid_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  eventJson?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  sgEventId?: InputMaybe<String_Comparison_Exp>;
  sgMessageId?: InputMaybe<String_Comparison_Exp>;
  sgTemplateId?: InputMaybe<String_Comparison_Exp>;
  sgTemplateName?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sendgridEvent" */
export enum SendgridEvent_Constraint {
  /** unique or primary key constraint on columns "id" */
  SendgridEventPkey = 'sendgridEvent_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SendgridEvent_Delete_At_Path_Input = {
  eventJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SendgridEvent_Delete_Elem_Input = {
  eventJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SendgridEvent_Delete_Key_Input = {
  eventJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "sendgridEvent" */
export type SendgridEvent_Insert_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  emailHistoryId?: InputMaybe<Scalars['uuid']>;
  event?: InputMaybe<Scalars['String']>;
  eventJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  sgEventId?: InputMaybe<Scalars['String']>;
  sgMessageId?: InputMaybe<Scalars['String']>;
  sgTemplateId?: InputMaybe<Scalars['String']>;
  sgTemplateName?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SendgridEvent_Max_Fields = {
  __typename?: 'sendgridEvent_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sgEventId?: Maybe<Scalars['String']>;
  sgMessageId?: Maybe<Scalars['String']>;
  sgTemplateId?: Maybe<Scalars['String']>;
  sgTemplateName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SendgridEvent_Min_Fields = {
  __typename?: 'sendgridEvent_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sgEventId?: Maybe<Scalars['String']>;
  sgMessageId?: Maybe<Scalars['String']>;
  sgTemplateId?: Maybe<Scalars['String']>;
  sgTemplateName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sendgridEvent" */
export type SendgridEvent_Mutation_Response = {
  __typename?: 'sendgridEvent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SendgridEvent>;
};

/** on_conflict condition type for table "sendgridEvent" */
export type SendgridEvent_On_Conflict = {
  constraint: SendgridEvent_Constraint;
  update_columns?: Array<SendgridEvent_Update_Column>;
  where?: InputMaybe<SendgridEvent_Bool_Exp>;
};

/** Ordering options when selecting data from "sendgridEvent". */
export type SendgridEvent_Order_By = {
  clientId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailHistoryId?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  eventJson?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sgEventId?: InputMaybe<Order_By>;
  sgMessageId?: InputMaybe<Order_By>;
  sgTemplateId?: InputMaybe<Order_By>;
  sgTemplateName?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sendgridEvent */
export type SendgridEvent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SendgridEvent_Prepend_Input = {
  eventJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "sendgridEvent" */
export enum SendgridEvent_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EmailHistoryId = 'emailHistoryId',
  /** column name */
  Event = 'event',
  /** column name */
  EventJson = 'eventJson',
  /** column name */
  Id = 'id',
  /** column name */
  SgEventId = 'sgEventId',
  /** column name */
  SgMessageId = 'sgMessageId',
  /** column name */
  SgTemplateId = 'sgTemplateId',
  /** column name */
  SgTemplateName = 'sgTemplateName',
}

/** input type for updating data in table "sendgridEvent" */
export type SendgridEvent_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  emailHistoryId?: InputMaybe<Scalars['uuid']>;
  event?: InputMaybe<Scalars['String']>;
  eventJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  sgEventId?: InputMaybe<Scalars['String']>;
  sgMessageId?: InputMaybe<Scalars['String']>;
  sgTemplateId?: InputMaybe<Scalars['String']>;
  sgTemplateName?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "sendgridEvent" */
export type SendgridEvent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SendgridEvent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SendgridEvent_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  emailHistoryId?: InputMaybe<Scalars['uuid']>;
  event?: InputMaybe<Scalars['String']>;
  eventJson?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  sgEventId?: InputMaybe<Scalars['String']>;
  sgMessageId?: InputMaybe<Scalars['String']>;
  sgTemplateId?: InputMaybe<Scalars['String']>;
  sgTemplateName?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sendgridEvent" */
export enum SendgridEvent_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EmailHistoryId = 'emailHistoryId',
  /** column name */
  Event = 'event',
  /** column name */
  EventJson = 'eventJson',
  /** column name */
  Id = 'id',
  /** column name */
  SgEventId = 'sgEventId',
  /** column name */
  SgMessageId = 'sgMessageId',
  /** column name */
  SgTemplateId = 'sgTemplateId',
  /** column name */
  SgTemplateName = 'sgTemplateName',
}

export type SendgridEvent_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SendgridEvent_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<SendgridEvent_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<SendgridEvent_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<SendgridEvent_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SendgridEvent_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SendgridEvent_Set_Input>;
  /** filter the rows which have to be updated */
  where: SendgridEvent_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch data from the table: "clientContact" */
  clientContact: Array<ClientContact>;
  /** fetch data from the table: "clientContactCrmData" */
  clientContactCrmData: Array<ClientContactCrmData>;
  /** fetch aggregated fields from the table: "clientContactCrmData" */
  clientContactCrmData_aggregate: ClientContactCrmData_Aggregate;
  /** fetch data from the table: "clientContactCrmData" using primary key columns */
  clientContactCrmData_by_pk?: Maybe<ClientContactCrmData>;
  /** fetch data from the table in a streaming manner: "clientContactCrmData" */
  clientContactCrmData_stream: Array<ClientContactCrmData>;
  /** fetch data from the table: "clientContactFilter" */
  clientContactFilter: Array<ClientContactFilter>;
  /** fetch aggregated fields from the table: "clientContactFilter" */
  clientContactFilter_aggregate: ClientContactFilter_Aggregate;
  /** fetch data from the table: "clientContactFilter" using primary key columns */
  clientContactFilter_by_pk?: Maybe<ClientContactFilter>;
  /** fetch data from the table in a streaming manner: "clientContactFilter" */
  clientContactFilter_stream: Array<ClientContactFilter>;
  /** fetch data from the table: "clientContactJobChange" */
  clientContactJobChange: Array<ClientContactJobChange>;
  /** fetch aggregated fields from the table: "clientContactJobChange" */
  clientContactJobChange_aggregate: ClientContactJobChange_Aggregate;
  /** fetch data from the table: "clientContactJobChange" using primary key columns */
  clientContactJobChange_by_pk?: Maybe<ClientContactJobChange>;
  /** fetch data from the table in a streaming manner: "clientContactJobChange" */
  clientContactJobChange_stream: Array<ClientContactJobChange>;
  /** fetch aggregated fields from the table: "clientContact" */
  clientContact_aggregate: ClientContact_Aggregate;
  /** fetch data from the table: "clientContact" using primary key columns */
  clientContact_by_pk?: Maybe<ClientContact>;
  /** fetch data from the table in a streaming manner: "clientContact" */
  clientContact_stream: Array<ClientContact>;
  /** fetch data from the table: "clientEmailReport" */
  clientEmailReport: Array<ClientEmailReport>;
  /** fetch aggregated fields from the table: "clientEmailReport" */
  clientEmailReport_aggregate: ClientEmailReport_Aggregate;
  /** fetch data from the table: "clientEmailReport" using primary key columns */
  clientEmailReport_by_pk?: Maybe<ClientEmailReport>;
  /** fetch data from the table in a streaming manner: "clientEmailReport" */
  clientEmailReport_stream: Array<ClientEmailReport>;
  /** fetch data from the table: "clientNotificationEmail" */
  clientNotificationEmail: Array<ClientNotificationEmail>;
  /** fetch aggregated fields from the table: "clientNotificationEmail" */
  clientNotificationEmail_aggregate: ClientNotificationEmail_Aggregate;
  /** fetch data from the table: "clientNotificationEmail" using primary key columns */
  clientNotificationEmail_by_pk?: Maybe<ClientNotificationEmail>;
  /** fetch data from the table in a streaming manner: "clientNotificationEmail" */
  clientNotificationEmail_stream: Array<ClientNotificationEmail>;
  /** fetch data from the table: "clientTerritory" */
  clientTerritory: Array<ClientTerritory>;
  /** fetch aggregated fields from the table: "clientTerritory" */
  clientTerritory_aggregate: ClientTerritory_Aggregate;
  /** fetch data from the table: "clientTerritory" using primary key columns */
  clientTerritory_by_pk?: Maybe<ClientTerritory>;
  /** fetch data from the table in a streaming manner: "clientTerritory" */
  clientTerritory_stream: Array<ClientTerritory>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table in a streaming manner: "client" */
  client_stream: Array<Client>;
  /** fetch data from the table: "cloudTask" */
  cloudTask: Array<CloudTask>;
  /** fetch aggregated fields from the table: "cloudTask" */
  cloudTask_aggregate: CloudTask_Aggregate;
  /** fetch data from the table: "cloudTask" using primary key columns */
  cloudTask_by_pk?: Maybe<CloudTask>;
  /** fetch data from the table in a streaming manner: "cloudTask" */
  cloudTask_stream: Array<CloudTask>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch data from the table: "companyName" */
  companyName: Array<CompanyName>;
  /** fetch aggregated fields from the table: "companyName" */
  companyName_aggregate: CompanyName_Aggregate;
  /** fetch data from the table: "companyName" using primary key columns */
  companyName_by_pk?: Maybe<CompanyName>;
  /** fetch data from the table in a streaming manner: "companyName" */
  companyName_stream: Array<CompanyName>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table in a streaming manner: "company" */
  company_stream: Array<Company>;
  /** An array relationship */
  contactData: Array<ContactData>;
  /** An aggregate relationship */
  contactData_aggregate: ContactData_Aggregate;
  /** fetch data from the table: "contactData" using primary key columns */
  contactData_by_pk?: Maybe<ContactData>;
  /** fetch data from the table in a streaming manner: "contactData" */
  contactData_stream: Array<ContactData>;
  /** fetch data from the table: "contactEmail" */
  contactEmail: Array<ContactEmail>;
  /** fetch aggregated fields from the table: "contactEmail" */
  contactEmail_aggregate: ContactEmail_Aggregate;
  /** fetch data from the table: "contactEmail" using primary key columns */
  contactEmail_by_pk?: Maybe<ContactEmail>;
  /** fetch data from the table in a streaming manner: "contactEmail" */
  contactEmail_stream: Array<ContactEmail>;
  /** fetch data from the table: "content" */
  content: Array<Content>;
  /** fetch aggregated fields from the table: "content" */
  content_aggregate: Content_Aggregate;
  /** fetch data from the table: "content" using primary key columns */
  content_by_pk?: Maybe<Content>;
  /** fetch data from the table in a streaming manner: "content" */
  content_stream: Array<Content>;
  /** fetch data from the table: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory: Array<CrmContactEnrichmentHistory>;
  /** fetch aggregated fields from the table: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory_aggregate: CrmContactEnrichmentHistory_Aggregate;
  /** fetch data from the table: "crmContactEnrichmentHistory" using primary key columns */
  crmContactEnrichmentHistory_by_pk?: Maybe<CrmContactEnrichmentHistory>;
  /** fetch data from the table in a streaming manner: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory_stream: Array<CrmContactEnrichmentHistory>;
  /** fetch data from the table: "csvUpload" */
  csvUpload: Array<CsvUpload>;
  /** fetch aggregated fields from the table: "csvUpload" */
  csvUpload_aggregate: CsvUpload_Aggregate;
  /** fetch data from the table: "csvUpload" using primary key columns */
  csvUpload_by_pk?: Maybe<CsvUpload>;
  /** fetch data from the table in a streaming manner: "csvUpload" */
  csvUpload_stream: Array<CsvUpload>;
  /** fetch data from the table: "deleteOldZoomEventsJob" */
  deleteOldZoomEventsJob: Array<DeleteOldZoomEventsJob>;
  /** fetch aggregated fields from the table: "deleteOldZoomEventsJob" */
  deleteOldZoomEventsJob_aggregate: DeleteOldZoomEventsJob_Aggregate;
  /** fetch data from the table: "deleteOldZoomEventsJob" using primary key columns */
  deleteOldZoomEventsJob_by_pk?: Maybe<DeleteOldZoomEventsJob>;
  /** fetch data from the table in a streaming manner: "deleteOldZoomEventsJob" */
  deleteOldZoomEventsJob_stream: Array<DeleteOldZoomEventsJob>;
  /** fetch data from the table: "emailHistory" */
  emailHistory: Array<EmailHistory>;
  /** fetch aggregated fields from the table: "emailHistory" */
  emailHistory_aggregate: EmailHistory_Aggregate;
  /** fetch data from the table: "emailHistory" using primary key columns */
  emailHistory_by_pk?: Maybe<EmailHistory>;
  /** fetch data from the table in a streaming manner: "emailHistory" */
  emailHistory_stream: Array<EmailHistory>;
  /** fetch data from the table: "googleChannel" */
  googleChannel: Array<GoogleChannel>;
  /** fetch aggregated fields from the table: "googleChannel" */
  googleChannel_aggregate: GoogleChannel_Aggregate;
  /** fetch data from the table: "googleChannel" using primary key columns */
  googleChannel_by_pk?: Maybe<GoogleChannel>;
  /** fetch data from the table in a streaming manner: "googleChannel" */
  googleChannel_stream: Array<GoogleChannel>;
  /** fetch data from the table: "growthIntraCompanyAdoptionContact" */
  growthIntraCompanyAdoptionContact: Array<GrowthIntraCompanyAdoptionContact>;
  /** fetch aggregated fields from the table: "growthIntraCompanyAdoptionContact" */
  growthIntraCompanyAdoptionContact_aggregate: GrowthIntraCompanyAdoptionContact_Aggregate;
  /** fetch data from the table: "growthIntraCompanyAdoptionContact" using primary key columns */
  growthIntraCompanyAdoptionContact_by_pk?: Maybe<GrowthIntraCompanyAdoptionContact>;
  /** fetch data from the table in a streaming manner: "growthIntraCompanyAdoptionContact" */
  growthIntraCompanyAdoptionContact_stream: Array<GrowthIntraCompanyAdoptionContact>;
  /** fetch data from the table: "growthUserReferralContact" */
  growthUserReferralContact: Array<GrowthUserReferralContact>;
  /** fetch aggregated fields from the table: "growthUserReferralContact" */
  growthUserReferralContact_aggregate: GrowthUserReferralContact_Aggregate;
  /** fetch data from the table: "growthUserReferralContact" using primary key columns */
  growthUserReferralContact_by_pk?: Maybe<GrowthUserReferralContact>;
  /** fetch data from the table in a streaming manner: "growthUserReferralContact" */
  growthUserReferralContact_stream: Array<GrowthUserReferralContact>;
  /** fetch data from the table: "hubspotContactList" */
  hubspotContactList: Array<HubspotContactList>;
  /** fetch data from the table: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact: Array<HubspotContactListHubspotContact>;
  /** fetch aggregated fields from the table: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact_aggregate: HubspotContactListHubspotContact_Aggregate;
  /** fetch data from the table: "hubspotContactListHubspotContact" using primary key columns */
  hubspotContactListHubspotContact_by_pk?: Maybe<HubspotContactListHubspotContact>;
  /** fetch data from the table in a streaming manner: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact_stream: Array<HubspotContactListHubspotContact>;
  /** fetch aggregated fields from the table: "hubspotContactList" */
  hubspotContactList_aggregate: HubspotContactList_Aggregate;
  /** fetch data from the table: "hubspotContactList" using primary key columns */
  hubspotContactList_by_pk?: Maybe<HubspotContactList>;
  /** fetch data from the table in a streaming manner: "hubspotContactList" */
  hubspotContactList_stream: Array<HubspotContactList>;
  /** fetch data from the table: "hubspotContactProperty" */
  hubspotContactProperty: Array<HubspotContactProperty>;
  /** fetch aggregated fields from the table: "hubspotContactProperty" */
  hubspotContactProperty_aggregate: HubspotContactProperty_Aggregate;
  /** fetch data from the table: "hubspotContactProperty" using primary key columns */
  hubspotContactProperty_by_pk?: Maybe<HubspotContactProperty>;
  /** fetch data from the table in a streaming manner: "hubspotContactProperty" */
  hubspotContactProperty_stream: Array<HubspotContactProperty>;
  /** fetch data from the table: "hubspotIntegration" */
  hubspotIntegration: Array<HubspotIntegration>;
  /** fetch aggregated fields from the table: "hubspotIntegration" */
  hubspotIntegration_aggregate: HubspotIntegration_Aggregate;
  /** fetch data from the table: "hubspotIntegration" using primary key columns */
  hubspotIntegration_by_pk?: Maybe<HubspotIntegration>;
  /** fetch data from the table in a streaming manner: "hubspotIntegration" */
  hubspotIntegration_stream: Array<HubspotIntegration>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table in a streaming manner: "job" */
  job_stream: Array<Job>;
  /** fetch data from the table: "microsoftSubscription" */
  microsoftSubscription: Array<MicrosoftSubscription>;
  /** fetch aggregated fields from the table: "microsoftSubscription" */
  microsoftSubscription_aggregate: MicrosoftSubscription_Aggregate;
  /** fetch data from the table: "microsoftSubscription" using primary key columns */
  microsoftSubscription_by_pk?: Maybe<MicrosoftSubscription>;
  /** fetch data from the table in a streaming manner: "microsoftSubscription" */
  microsoftSubscription_stream: Array<MicrosoftSubscription>;
  /** fetch data from the table: "proxycrawlData" */
  proxycrawlData: Array<ProxycrawlData>;
  /** fetch aggregated fields from the table: "proxycrawlData" */
  proxycrawlData_aggregate: ProxycrawlData_Aggregate;
  /** fetch data from the table: "proxycrawlData" using primary key columns */
  proxycrawlData_by_pk?: Maybe<ProxycrawlData>;
  /** fetch data from the table in a streaming manner: "proxycrawlData" */
  proxycrawlData_stream: Array<ProxycrawlData>;
  /** fetch data from the table: "qaQualification" */
  qaQualification: Array<QaQualification>;
  /** fetch aggregated fields from the table: "qaQualification" */
  qaQualification_aggregate: QaQualification_Aggregate;
  /** fetch data from the table: "qaQualification" using primary key columns */
  qaQualification_by_pk?: Maybe<QaQualification>;
  /** fetch data from the table in a streaming manner: "qaQualification" */
  qaQualification_stream: Array<QaQualification>;
  /** fetch data from the table: "qaTask" */
  qaTask: Array<QaTask>;
  /** fetch data from the table: "qaTaskAction" */
  qaTaskAction: Array<QaTaskAction>;
  /** fetch aggregated fields from the table: "qaTaskAction" */
  qaTaskAction_aggregate: QaTaskAction_Aggregate;
  /** fetch data from the table: "qaTaskAction" using primary key columns */
  qaTaskAction_by_pk?: Maybe<QaTaskAction>;
  /** fetch data from the table in a streaming manner: "qaTaskAction" */
  qaTaskAction_stream: Array<QaTaskAction>;
  /** fetch aggregated fields from the table: "qaTask" */
  qaTask_aggregate: QaTask_Aggregate;
  /** fetch data from the table: "qaTask" using primary key columns */
  qaTask_by_pk?: Maybe<QaTask>;
  /** fetch data from the table in a streaming manner: "qaTask" */
  qaTask_stream: Array<QaTask>;
  /** fetch data from the table: "salesforceAccount" */
  salesforceAccount: Array<SalesforceAccount>;
  /** fetch aggregated fields from the table: "salesforceAccount" */
  salesforceAccount_aggregate: SalesforceAccount_Aggregate;
  /** fetch data from the table: "salesforceAccount" using primary key columns */
  salesforceAccount_by_pk?: Maybe<SalesforceAccount>;
  /** fetch data from the table in a streaming manner: "salesforceAccount" */
  salesforceAccount_stream: Array<SalesforceAccount>;
  /** fetch data from the table: "salesforceContactField" */
  salesforceContactField: Array<SalesforceContactField>;
  /** fetch aggregated fields from the table: "salesforceContactField" */
  salesforceContactField_aggregate: SalesforceContactField_Aggregate;
  /** fetch data from the table: "salesforceContactField" using primary key columns */
  salesforceContactField_by_pk?: Maybe<SalesforceContactField>;
  /** fetch data from the table in a streaming manner: "salesforceContactField" */
  salesforceContactField_stream: Array<SalesforceContactField>;
  /** fetch data from the table: "salesforceIntegration" */
  salesforceIntegration: Array<SalesforceIntegration>;
  /** fetch aggregated fields from the table: "salesforceIntegration" */
  salesforceIntegration_aggregate: SalesforceIntegration_Aggregate;
  /** fetch data from the table: "salesforceIntegration" using primary key columns */
  salesforceIntegration_by_pk?: Maybe<SalesforceIntegration>;
  /** fetch data from the table in a streaming manner: "salesforceIntegration" */
  salesforceIntegration_stream: Array<SalesforceIntegration>;
  /** fetch data from the table: "salesforceOpportunity" */
  salesforceOpportunity: Array<SalesforceOpportunity>;
  /** fetch aggregated fields from the table: "salesforceOpportunity" */
  salesforceOpportunity_aggregate: SalesforceOpportunity_Aggregate;
  /** fetch data from the table: "salesforceOpportunity" using primary key columns */
  salesforceOpportunity_by_pk?: Maybe<SalesforceOpportunity>;
  /** fetch data from the table in a streaming manner: "salesforceOpportunity" */
  salesforceOpportunity_stream: Array<SalesforceOpportunity>;
  /** fetch data from the table: "sendgridEvent" */
  sendgridEvent: Array<SendgridEvent>;
  /** fetch aggregated fields from the table: "sendgridEvent" */
  sendgridEvent_aggregate: SendgridEvent_Aggregate;
  /** fetch data from the table: "sendgridEvent" using primary key columns */
  sendgridEvent_by_pk?: Maybe<SendgridEvent>;
  /** fetch data from the table in a streaming manner: "sendgridEvent" */
  sendgridEvent_stream: Array<SendgridEvent>;
  /** fetch data from the table: "syncZoomEventsJob" */
  syncZoomEventsJob: Array<SyncZoomEventsJob>;
  /** fetch aggregated fields from the table: "syncZoomEventsJob" */
  syncZoomEventsJob_aggregate: SyncZoomEventsJob_Aggregate;
  /** fetch data from the table: "syncZoomEventsJob" using primary key columns */
  syncZoomEventsJob_by_pk?: Maybe<SyncZoomEventsJob>;
  /** fetch data from the table in a streaming manner: "syncZoomEventsJob" */
  syncZoomEventsJob_stream: Array<SyncZoomEventsJob>;
  /** fetch data from the table: "territory" */
  territory: Array<Territory>;
  /** fetch data from the table: "territorySearchTerm" */
  territorySearchTerm: Array<TerritorySearchTerm>;
  /** fetch aggregated fields from the table: "territorySearchTerm" */
  territorySearchTerm_aggregate: TerritorySearchTerm_Aggregate;
  /** fetch data from the table: "territorySearchTerm" using primary key columns */
  territorySearchTerm_by_pk?: Maybe<TerritorySearchTerm>;
  /** fetch data from the table in a streaming manner: "territorySearchTerm" */
  territorySearchTerm_stream: Array<TerritorySearchTerm>;
  /** fetch aggregated fields from the table: "territory" */
  territory_aggregate: Territory_Aggregate;
  /** fetch data from the table: "territory" using primary key columns */
  territory_by_pk?: Maybe<Territory>;
  /** fetch data from the table in a streaming manner: "territory" */
  territory_stream: Array<Territory>;
  /** fetch data from the table: "theOrgSearchTerm" */
  theOrgSearchTerm: Array<TheOrgSearchTerm>;
  /** fetch aggregated fields from the table: "theOrgSearchTerm" */
  theOrgSearchTerm_aggregate: TheOrgSearchTerm_Aggregate;
  /** fetch data from the table: "theOrgSearchTerm" using primary key columns */
  theOrgSearchTerm_by_pk?: Maybe<TheOrgSearchTerm>;
  /** fetch data from the table in a streaming manner: "theOrgSearchTerm" */
  theOrgSearchTerm_stream: Array<TheOrgSearchTerm>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "userInvite" */
  userInvite: Array<UserInvite>;
  /** fetch aggregated fields from the table: "userInvite" */
  userInvite_aggregate: UserInvite_Aggregate;
  /** fetch data from the table: "userInvite" using primary key columns */
  userInvite_by_pk?: Maybe<UserInvite>;
  /** fetch data from the table in a streaming manner: "userInvite" */
  userInvite_stream: Array<UserInvite>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "warmCompany" */
  warmCompany: Array<WarmCompany>;
  /** fetch aggregated fields from the table: "warmCompany" */
  warmCompany_aggregate: WarmCompany_Aggregate;
  /** fetch data from the table in a streaming manner: "warmCompany" */
  warmCompany_stream: Array<WarmCompany>;
  /** fetch data from the table: "workOSConnection" */
  workOSConnection: Array<WorkOsConnection>;
  /** fetch aggregated fields from the table: "workOSConnection" */
  workOSConnection_aggregate: WorkOsConnection_Aggregate;
  /** fetch data from the table: "workOSConnection" using primary key columns */
  workOSConnection_by_pk?: Maybe<WorkOsConnection>;
  /** fetch data from the table in a streaming manner: "workOSConnection" */
  workOSConnection_stream: Array<WorkOsConnection>;
  /** fetch data from the table: "workOSDirectory" */
  workOSDirectory: Array<WorkOsDirectory>;
  /** fetch aggregated fields from the table: "workOSDirectory" */
  workOSDirectory_aggregate: WorkOsDirectory_Aggregate;
  /** fetch data from the table: "workOSDirectory" using primary key columns */
  workOSDirectory_by_pk?: Maybe<WorkOsDirectory>;
  /** fetch data from the table in a streaming manner: "workOSDirectory" */
  workOSDirectory_stream: Array<WorkOsDirectory>;
  /** fetch data from the table: "workOSGroup" */
  workOSGroup: Array<WorkOsGroup>;
  /** fetch aggregated fields from the table: "workOSGroup" */
  workOSGroup_aggregate: WorkOsGroup_Aggregate;
  /** fetch data from the table: "workOSGroup" using primary key columns */
  workOSGroup_by_pk?: Maybe<WorkOsGroup>;
  /** fetch data from the table in a streaming manner: "workOSGroup" */
  workOSGroup_stream: Array<WorkOsGroup>;
  /** fetch data from the table: "workOSUser" */
  workOSUser: Array<WorkOsUser>;
  /** fetch aggregated fields from the table: "workOSUser" */
  workOSUser_aggregate: WorkOsUser_Aggregate;
  /** fetch data from the table: "workOSUser" using primary key columns */
  workOSUser_by_pk?: Maybe<WorkOsUser>;
  /** fetch data from the table in a streaming manner: "workOSUser" */
  workOSUser_stream: Array<WorkOsUser>;
  /** fetch data from the table: "zoomApprovedDomain" */
  zoomApprovedDomain: Array<ZoomApprovedDomain>;
  /** fetch aggregated fields from the table: "zoomApprovedDomain" */
  zoomApprovedDomain_aggregate: ZoomApprovedDomain_Aggregate;
  /** fetch data from the table: "zoomApprovedDomain" using primary key columns */
  zoomApprovedDomain_by_pk?: Maybe<ZoomApprovedDomain>;
  /** fetch data from the table in a streaming manner: "zoomApprovedDomain" */
  zoomApprovedDomain_stream: Array<ZoomApprovedDomain>;
  /** fetch data from the table: "zoomCompany" */
  zoomCompany: Array<ZoomCompany>;
  /** fetch data from the table: "zoomCompanyMergeResult" */
  zoomCompanyMergeResult: Array<ZoomCompanyMergeResult>;
  /** fetch aggregated fields from the table: "zoomCompanyMergeResult" */
  zoomCompanyMergeResult_aggregate: ZoomCompanyMergeResult_Aggregate;
  /** fetch data from the table: "zoomCompanyMergeResult" using primary key columns */
  zoomCompanyMergeResult_by_pk?: Maybe<ZoomCompanyMergeResult>;
  /** fetch data from the table in a streaming manner: "zoomCompanyMergeResult" */
  zoomCompanyMergeResult_stream: Array<ZoomCompanyMergeResult>;
  /** fetch aggregated fields from the table: "zoomCompany" */
  zoomCompany_aggregate: ZoomCompany_Aggregate;
  /** fetch data from the table: "zoomCompany" using primary key columns */
  zoomCompany_by_pk?: Maybe<ZoomCompany>;
  /** fetch data from the table in a streaming manner: "zoomCompany" */
  zoomCompany_stream: Array<ZoomCompany>;
  /** fetch data from the table: "zoomConnectionRequest" */
  zoomConnectionRequest: Array<ZoomConnectionRequest>;
  /** fetch data from the table: "zoomConnectionRequestReminder" */
  zoomConnectionRequestReminder: Array<ZoomConnectionRequestReminder>;
  /** fetch aggregated fields from the table: "zoomConnectionRequestReminder" */
  zoomConnectionRequestReminder_aggregate: ZoomConnectionRequestReminder_Aggregate;
  /** fetch data from the table: "zoomConnectionRequestReminder" using primary key columns */
  zoomConnectionRequestReminder_by_pk?: Maybe<ZoomConnectionRequestReminder>;
  /** fetch data from the table in a streaming manner: "zoomConnectionRequestReminder" */
  zoomConnectionRequestReminder_stream: Array<ZoomConnectionRequestReminder>;
  /** fetch aggregated fields from the table: "zoomConnectionRequest" */
  zoomConnectionRequest_aggregate: ZoomConnectionRequest_Aggregate;
  /** fetch data from the table: "zoomConnectionRequest" using primary key columns */
  zoomConnectionRequest_by_pk?: Maybe<ZoomConnectionRequest>;
  /** fetch data from the table in a streaming manner: "zoomConnectionRequest" */
  zoomConnectionRequest_stream: Array<ZoomConnectionRequest>;
  /** fetch data from the table: "zoomContact" */
  zoomContact: Array<ZoomContact>;
  /** fetch data from the table: "zoomContactNotes" */
  zoomContactNotes: Array<ZoomContactNotes>;
  /** fetch aggregated fields from the table: "zoomContactNotes" */
  zoomContactNotes_aggregate: ZoomContactNotes_Aggregate;
  /** fetch data from the table: "zoomContactNotes" using primary key columns */
  zoomContactNotes_by_pk?: Maybe<ZoomContactNotes>;
  /** fetch data from the table in a streaming manner: "zoomContactNotes" */
  zoomContactNotes_stream: Array<ZoomContactNotes>;
  /** fetch aggregated fields from the table: "zoomContact" */
  zoomContact_aggregate: ZoomContact_Aggregate;
  /** fetch data from the table: "zoomContact" using primary key columns */
  zoomContact_by_pk?: Maybe<ZoomContact>;
  /** fetch data from the table in a streaming manner: "zoomContact" */
  zoomContact_stream: Array<ZoomContact>;
  /** fetch data from the table: "zoomConversationStarterAnswer" */
  zoomConversationStarterAnswer: Array<ZoomConversationStarterAnswer>;
  /** fetch aggregated fields from the table: "zoomConversationStarterAnswer" */
  zoomConversationStarterAnswer_aggregate: ZoomConversationStarterAnswer_Aggregate;
  /** fetch data from the table: "zoomConversationStarterAnswer" using primary key columns */
  zoomConversationStarterAnswer_by_pk?: Maybe<ZoomConversationStarterAnswer>;
  /** fetch data from the table in a streaming manner: "zoomConversationStarterAnswer" */
  zoomConversationStarterAnswer_stream: Array<ZoomConversationStarterAnswer>;
  /** fetch data from the table: "zoomConversationStarterPrompt" */
  zoomConversationStarterPrompt: Array<ZoomConversationStarterPrompt>;
  /** fetch aggregated fields from the table: "zoomConversationStarterPrompt" */
  zoomConversationStarterPrompt_aggregate: ZoomConversationStarterPrompt_Aggregate;
  /** fetch data from the table: "zoomConversationStarterPrompt" using primary key columns */
  zoomConversationStarterPrompt_by_pk?: Maybe<ZoomConversationStarterPrompt>;
  /** fetch data from the table in a streaming manner: "zoomConversationStarterPrompt" */
  zoomConversationStarterPrompt_stream: Array<ZoomConversationStarterPrompt>;
  /** fetch data from the table: "zoomCustomBackground" */
  zoomCustomBackground: Array<ZoomCustomBackground>;
  /** fetch aggregated fields from the table: "zoomCustomBackground" */
  zoomCustomBackground_aggregate: ZoomCustomBackground_Aggregate;
  /** fetch data from the table: "zoomCustomBackground" using primary key columns */
  zoomCustomBackground_by_pk?: Maybe<ZoomCustomBackground>;
  /** fetch data from the table in a streaming manner: "zoomCustomBackground" */
  zoomCustomBackground_stream: Array<ZoomCustomBackground>;
  /** fetch data from the table: "zoomEmailHistory" */
  zoomEmailHistory: Array<ZoomEmailHistory>;
  /** fetch aggregated fields from the table: "zoomEmailHistory" */
  zoomEmailHistory_aggregate: ZoomEmailHistory_Aggregate;
  /** fetch data from the table: "zoomEmailHistory" using primary key columns */
  zoomEmailHistory_by_pk?: Maybe<ZoomEmailHistory>;
  /** fetch data from the table in a streaming manner: "zoomEmailHistory" */
  zoomEmailHistory_stream: Array<ZoomEmailHistory>;
  /** fetch data from the table: "zoomEvent" */
  zoomEvent: Array<ZoomEvent>;
  /** fetch data from the table: "zoomEventParticipant" */
  zoomEventParticipant: Array<ZoomEventParticipant>;
  /** fetch data from the table: "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts: Array<ZoomEventParticipantWithContacts>;
  /** fetch aggregated fields from the table: "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts_aggregate: ZoomEventParticipantWithContacts_Aggregate;
  /** execute function "zoomEventParticipantWithContacts_sorted" which returns "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts_sorted: Array<ZoomEventParticipantWithContacts>;
  /** execute function "zoomEventParticipantWithContacts_sorted" and query aggregates on result of table type "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts_sorted_aggregate: ZoomEventParticipantWithContacts_Aggregate;
  /** fetch data from the table in a streaming manner: "zoomEventParticipantWithContacts" */
  zoomEventParticipantWithContacts_stream: Array<ZoomEventParticipantWithContacts>;
  /** fetch aggregated fields from the table: "zoomEventParticipant" */
  zoomEventParticipant_aggregate: ZoomEventParticipant_Aggregate;
  /** fetch data from the table: "zoomEventParticipant" using primary key columns */
  zoomEventParticipant_by_pk?: Maybe<ZoomEventParticipant>;
  /** fetch data from the table in a streaming manner: "zoomEventParticipant" */
  zoomEventParticipant_stream: Array<ZoomEventParticipant>;
  /** fetch aggregated fields from the table: "zoomEvent" */
  zoomEvent_aggregate: ZoomEvent_Aggregate;
  /** fetch data from the table: "zoomEvent" using primary key columns */
  zoomEvent_by_pk?: Maybe<ZoomEvent>;
  /** fetch data from the table in a streaming manner: "zoomEvent" */
  zoomEvent_stream: Array<ZoomEvent>;
  /** fetch data from the table: "zoomExternalAuthUser" */
  zoomExternalAuthUser: Array<ZoomExternalAuthUser>;
  /** fetch aggregated fields from the table: "zoomExternalAuthUser" */
  zoomExternalAuthUser_aggregate: ZoomExternalAuthUser_Aggregate;
  /** fetch data from the table: "zoomExternalAuthUser" using primary key columns */
  zoomExternalAuthUser_by_pk?: Maybe<ZoomExternalAuthUser>;
  /** fetch data from the table in a streaming manner: "zoomExternalAuthUser" */
  zoomExternalAuthUser_stream: Array<ZoomExternalAuthUser>;
  /** fetch data from the table: "zoomGroup" */
  zoomGroup: Array<ZoomGroup>;
  /** fetch data from the table: "zoomGroupMembership" */
  zoomGroupMembership: Array<ZoomGroupMembership>;
  /** fetch aggregated fields from the table: "zoomGroupMembership" */
  zoomGroupMembership_aggregate: ZoomGroupMembership_Aggregate;
  /** fetch data from the table: "zoomGroupMembership" using primary key columns */
  zoomGroupMembership_by_pk?: Maybe<ZoomGroupMembership>;
  /** fetch data from the table in a streaming manner: "zoomGroupMembership" */
  zoomGroupMembership_stream: Array<ZoomGroupMembership>;
  /** fetch aggregated fields from the table: "zoomGroup" */
  zoomGroup_aggregate: ZoomGroup_Aggregate;
  /** fetch data from the table: "zoomGroup" using primary key columns */
  zoomGroup_by_pk?: Maybe<ZoomGroup>;
  /** fetch data from the table in a streaming manner: "zoomGroup" */
  zoomGroup_stream: Array<ZoomGroup>;
  /** fetch data from the table: "zoomLayersEvents" */
  zoomLayersEvents: Array<ZoomLayersEvents>;
  /** fetch aggregated fields from the table: "zoomLayersEvents" */
  zoomLayersEvents_aggregate: ZoomLayersEvents_Aggregate;
  /** fetch data from the table: "zoomLayersEvents" using primary key columns */
  zoomLayersEvents_by_pk?: Maybe<ZoomLayersEvents>;
  /** fetch data from the table in a streaming manner: "zoomLayersEvents" */
  zoomLayersEvents_stream: Array<ZoomLayersEvents>;
  /** fetch data from the table: "zoomOrganization" */
  zoomOrganization: Array<ZoomOrganization>;
  /** fetch data from the table: "zoomOrganizationBackfill" */
  zoomOrganizationBackfill: Array<ZoomOrganizationBackfill>;
  /** fetch data from the table: "zoomOrganizationBackfillResult" */
  zoomOrganizationBackfillResult: Array<ZoomOrganizationBackfillResult>;
  /** fetch aggregated fields from the table: "zoomOrganizationBackfillResult" */
  zoomOrganizationBackfillResult_aggregate: ZoomOrganizationBackfillResult_Aggregate;
  /** fetch data from the table: "zoomOrganizationBackfillResult" using primary key columns */
  zoomOrganizationBackfillResult_by_pk?: Maybe<ZoomOrganizationBackfillResult>;
  /** fetch data from the table in a streaming manner: "zoomOrganizationBackfillResult" */
  zoomOrganizationBackfillResult_stream: Array<ZoomOrganizationBackfillResult>;
  /** fetch aggregated fields from the table: "zoomOrganizationBackfill" */
  zoomOrganizationBackfill_aggregate: ZoomOrganizationBackfill_Aggregate;
  /** fetch data from the table: "zoomOrganizationBackfill" using primary key columns */
  zoomOrganizationBackfill_by_pk?: Maybe<ZoomOrganizationBackfill>;
  /** fetch data from the table in a streaming manner: "zoomOrganizationBackfill" */
  zoomOrganizationBackfill_stream: Array<ZoomOrganizationBackfill>;
  /** fetch data from the table: "zoomOrganizationMembership" */
  zoomOrganizationMembership: Array<ZoomOrganizationMembership>;
  /** fetch data from the table: "zoomOrganizationMembershipView" */
  zoomOrganizationMembershipView: Array<ZoomOrganizationMembershipView>;
  /** fetch aggregated fields from the table: "zoomOrganizationMembershipView" */
  zoomOrganizationMembershipView_aggregate: ZoomOrganizationMembershipView_Aggregate;
  /** fetch data from the table in a streaming manner: "zoomOrganizationMembershipView" */
  zoomOrganizationMembershipView_stream: Array<ZoomOrganizationMembershipView>;
  /** fetch aggregated fields from the table: "zoomOrganizationMembership" */
  zoomOrganizationMembership_aggregate: ZoomOrganizationMembership_Aggregate;
  /** fetch data from the table: "zoomOrganizationMembership" using primary key columns */
  zoomOrganizationMembership_by_pk?: Maybe<ZoomOrganizationMembership>;
  /** fetch data from the table in a streaming manner: "zoomOrganizationMembership" */
  zoomOrganizationMembership_stream: Array<ZoomOrganizationMembership>;
  /** fetch aggregated fields from the table: "zoomOrganization" */
  zoomOrganization_aggregate: ZoomOrganization_Aggregate;
  /** fetch data from the table: "zoomOrganization" using primary key columns */
  zoomOrganization_by_pk?: Maybe<ZoomOrganization>;
  /** fetch data from the table in a streaming manner: "zoomOrganization" */
  zoomOrganization_stream: Array<ZoomOrganization>;
  /** fetch data from the table: "zoomPlan" */
  zoomPlan: Array<ZoomPlan>;
  /** fetch aggregated fields from the table: "zoomPlan" */
  zoomPlan_aggregate: ZoomPlan_Aggregate;
  /** fetch data from the table: "zoomPlan" using primary key columns */
  zoomPlan_by_pk?: Maybe<ZoomPlan>;
  /** fetch data from the table in a streaming manner: "zoomPlan" */
  zoomPlan_stream: Array<ZoomPlan>;
  /** fetch data from the table: "zoomPresentation" */
  zoomPresentation: Array<ZoomPresentation>;
  /** fetch aggregated fields from the table: "zoomPresentation" */
  zoomPresentation_aggregate: ZoomPresentation_Aggregate;
  /** fetch data from the table: "zoomPresentation" using primary key columns */
  zoomPresentation_by_pk?: Maybe<ZoomPresentation>;
  /** fetch data from the table in a streaming manner: "zoomPresentation" */
  zoomPresentation_stream: Array<ZoomPresentation>;
  /** fetch data from the table: "zoomSettings" */
  zoomSettings: Array<ZoomSettings>;
  /** fetch aggregated fields from the table: "zoomSettings" */
  zoomSettings_aggregate: ZoomSettings_Aggregate;
  /** fetch data from the table: "zoomSettings" using primary key columns */
  zoomSettings_by_pk?: Maybe<ZoomSettings>;
  /** fetch data from the table in a streaming manner: "zoomSettings" */
  zoomSettings_stream: Array<ZoomSettings>;
  /** fetch data from the table: "zoomUser" */
  zoomUser: Array<ZoomUser>;
  /** An array relationship */
  zoomUserActions: Array<ZoomUserActions>;
  /** An aggregate relationship */
  zoomUserActions_aggregate: ZoomUserActions_Aggregate;
  /** fetch data from the table: "zoomUserActions" using primary key columns */
  zoomUserActions_by_pk?: Maybe<ZoomUserActions>;
  /** fetch data from the table in a streaming manner: "zoomUserActions" */
  zoomUserActions_stream: Array<ZoomUserActions>;
  /** fetch data from the table: "zoomUserConnectionRequest" */
  zoomUserConnectionRequest: Array<ZoomUserConnectionRequest>;
  /** fetch aggregated fields from the table: "zoomUserConnectionRequest" */
  zoomUserConnectionRequest_aggregate: ZoomUserConnectionRequest_Aggregate;
  /** fetch data from the table in a streaming manner: "zoomUserConnectionRequest" */
  zoomUserConnectionRequest_stream: Array<ZoomUserConnectionRequest>;
  /** fetch data from the table: "zoomUserMeetingParticipants" */
  zoomUserMeetingParticipants: Array<ZoomUserMeetingParticipants>;
  /** fetch aggregated fields from the table: "zoomUserMeetingParticipants" */
  zoomUserMeetingParticipants_aggregate: ZoomUserMeetingParticipants_Aggregate;
  /** fetch data from the table in a streaming manner: "zoomUserMeetingParticipants" */
  zoomUserMeetingParticipants_stream: Array<ZoomUserMeetingParticipants>;
  /** fetch data from the table: "zoomUserNetwork" */
  zoomUserNetwork: Array<ZoomUserNetwork>;
  /** fetch aggregated fields from the table: "zoomUserNetwork" */
  zoomUserNetwork_aggregate: ZoomUserNetwork_Aggregate;
  /** fetch data from the table in a streaming manner: "zoomUserNetwork" */
  zoomUserNetwork_stream: Array<ZoomUserNetwork>;
  /** fetch aggregated fields from the table: "zoomUser" */
  zoomUser_aggregate: ZoomUser_Aggregate;
  /** fetch data from the table: "zoomUser" using primary key columns */
  zoomUser_by_pk?: Maybe<ZoomUser>;
  /** fetch data from the table in a streaming manner: "zoomUser" */
  zoomUser_stream: Array<ZoomUser>;
  /** fetch data from the table: "zoomUsersAtCompany" */
  zoomUsersAtCompany: Array<ZoomUsersAtCompany>;
  /** fetch aggregated fields from the table: "zoomUsersAtCompany" */
  zoomUsersAtCompany_aggregate: ZoomUsersAtCompany_Aggregate;
  /** fetch data from the table in a streaming manner: "zoomUsersAtCompany" */
  zoomUsersAtCompany_stream: Array<ZoomUsersAtCompany>;
};

export type Subscription_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Subscription_RootClientContactArgs = {
  distinct_on?: InputMaybe<Array<ClientContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContact_Order_By>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

export type Subscription_RootClientContactCrmDataArgs = {
  distinct_on?: InputMaybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactCrmData_Order_By>>;
  where?: InputMaybe<ClientContactCrmData_Bool_Exp>;
};

export type Subscription_RootClientContactCrmData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactCrmData_Order_By>>;
  where?: InputMaybe<ClientContactCrmData_Bool_Exp>;
};

export type Subscription_RootClientContactCrmData_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClientContactCrmData_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientContactCrmData_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientContactCrmData_Bool_Exp>;
};

export type Subscription_RootClientContactFilterArgs = {
  distinct_on?: InputMaybe<Array<ClientContactFilter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactFilter_Order_By>>;
  where?: InputMaybe<ClientContactFilter_Bool_Exp>;
};

export type Subscription_RootClientContactFilter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContactFilter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactFilter_Order_By>>;
  where?: InputMaybe<ClientContactFilter_Bool_Exp>;
};

export type Subscription_RootClientContactFilter_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClientContactFilter_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientContactFilter_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientContactFilter_Bool_Exp>;
};

export type Subscription_RootClientContactJobChangeArgs = {
  distinct_on?: InputMaybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactJobChange_Order_By>>;
  where?: InputMaybe<ClientContactJobChange_Bool_Exp>;
};

export type Subscription_RootClientContactJobChange_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContactJobChange_Order_By>>;
  where?: InputMaybe<ClientContactJobChange_Bool_Exp>;
};

export type Subscription_RootClientContactJobChange_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClientContactJobChange_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientContactJobChange_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientContactJobChange_Bool_Exp>;
};

export type Subscription_RootClientContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientContact_Order_By>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

export type Subscription_RootClientContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClientContact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientContact_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientContact_Bool_Exp>;
};

export type Subscription_RootClientEmailReportArgs = {
  distinct_on?: InputMaybe<Array<ClientEmailReport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientEmailReport_Order_By>>;
  where?: InputMaybe<ClientEmailReport_Bool_Exp>;
};

export type Subscription_RootClientEmailReport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientEmailReport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientEmailReport_Order_By>>;
  where?: InputMaybe<ClientEmailReport_Bool_Exp>;
};

export type Subscription_RootClientEmailReport_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClientEmailReport_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientEmailReport_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientEmailReport_Bool_Exp>;
};

export type Subscription_RootClientNotificationEmailArgs = {
  distinct_on?: InputMaybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientNotificationEmail_Order_By>>;
  where?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
};

export type Subscription_RootClientNotificationEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientNotificationEmail_Order_By>>;
  where?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
};

export type Subscription_RootClientNotificationEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClientNotificationEmail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientNotificationEmail_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientNotificationEmail_Bool_Exp>;
};

export type Subscription_RootClientTerritoryArgs = {
  distinct_on?: InputMaybe<Array<ClientTerritory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientTerritory_Order_By>>;
  where?: InputMaybe<ClientTerritory_Bool_Exp>;
};

export type Subscription_RootClientTerritory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientTerritory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientTerritory_Order_By>>;
  where?: InputMaybe<ClientTerritory_Bool_Exp>;
};

export type Subscription_RootClientTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClientTerritory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientTerritory_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientTerritory_Bool_Exp>;
};

export type Subscription_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Subscription_RootClient_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Bool_Exp>;
};

export type Subscription_RootCloudTaskArgs = {
  distinct_on?: InputMaybe<Array<CloudTask_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CloudTask_Order_By>>;
  where?: InputMaybe<CloudTask_Bool_Exp>;
};

export type Subscription_RootCloudTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CloudTask_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CloudTask_Order_By>>;
  where?: InputMaybe<CloudTask_Bool_Exp>;
};

export type Subscription_RootCloudTask_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCloudTask_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CloudTask_Stream_Cursor_Input>>;
  where?: InputMaybe<CloudTask_Bool_Exp>;
};

export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompanyNameArgs = {
  distinct_on?: InputMaybe<Array<CompanyName_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyName_Order_By>>;
  where?: InputMaybe<CompanyName_Bool_Exp>;
};

export type Subscription_RootCompanyName_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyName_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyName_Order_By>>;
  where?: InputMaybe<CompanyName_Bool_Exp>;
};

export type Subscription_RootCompanyName_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCompanyName_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompanyName_Stream_Cursor_Input>>;
  where?: InputMaybe<CompanyName_Bool_Exp>;
};

export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCompany_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Bool_Exp>;
};

export type Subscription_RootContactDataArgs = {
  distinct_on?: InputMaybe<Array<ContactData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactData_Order_By>>;
  where?: InputMaybe<ContactData_Bool_Exp>;
};

export type Subscription_RootContactData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactData_Order_By>>;
  where?: InputMaybe<ContactData_Bool_Exp>;
};

export type Subscription_RootContactData_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootContactData_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContactData_Stream_Cursor_Input>>;
  where?: InputMaybe<ContactData_Bool_Exp>;
};

export type Subscription_RootContactEmailArgs = {
  distinct_on?: InputMaybe<Array<ContactEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactEmail_Order_By>>;
  where?: InputMaybe<ContactEmail_Bool_Exp>;
};

export type Subscription_RootContactEmail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactEmail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactEmail_Order_By>>;
  where?: InputMaybe<ContactEmail_Bool_Exp>;
};

export type Subscription_RootContactEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootContactEmail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContactEmail_Stream_Cursor_Input>>;
  where?: InputMaybe<ContactEmail_Bool_Exp>;
};

export type Subscription_RootContentArgs = {
  distinct_on?: InputMaybe<Array<Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Order_By>>;
  where?: InputMaybe<Content_Bool_Exp>;
};

export type Subscription_RootContent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Order_By>>;
  where?: InputMaybe<Content_Bool_Exp>;
};

export type Subscription_RootContent_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootContent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Content_Stream_Cursor_Input>>;
  where?: InputMaybe<Content_Bool_Exp>;
};

export type Subscription_RootCrmContactEnrichmentHistoryArgs = {
  distinct_on?: InputMaybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

export type Subscription_RootCrmContactEnrichmentHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

export type Subscription_RootCrmContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCrmContactEnrichmentHistory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CrmContactEnrichmentHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

export type Subscription_RootCsvUploadArgs = {
  distinct_on?: InputMaybe<Array<CsvUpload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CsvUpload_Order_By>>;
  where?: InputMaybe<CsvUpload_Bool_Exp>;
};

export type Subscription_RootCsvUpload_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CsvUpload_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CsvUpload_Order_By>>;
  where?: InputMaybe<CsvUpload_Bool_Exp>;
};

export type Subscription_RootCsvUpload_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCsvUpload_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CsvUpload_Stream_Cursor_Input>>;
  where?: InputMaybe<CsvUpload_Bool_Exp>;
};

export type Subscription_RootDeleteOldZoomEventsJobArgs = {
  distinct_on?: InputMaybe<Array<DeleteOldZoomEventsJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeleteOldZoomEventsJob_Order_By>>;
  where?: InputMaybe<DeleteOldZoomEventsJob_Bool_Exp>;
};

export type Subscription_RootDeleteOldZoomEventsJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DeleteOldZoomEventsJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DeleteOldZoomEventsJob_Order_By>>;
  where?: InputMaybe<DeleteOldZoomEventsJob_Bool_Exp>;
};

export type Subscription_RootDeleteOldZoomEventsJob_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootDeleteOldZoomEventsJob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DeleteOldZoomEventsJob_Stream_Cursor_Input>>;
  where?: InputMaybe<DeleteOldZoomEventsJob_Bool_Exp>;
};

export type Subscription_RootEmailHistoryArgs = {
  distinct_on?: InputMaybe<Array<EmailHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailHistory_Order_By>>;
  where?: InputMaybe<EmailHistory_Bool_Exp>;
};

export type Subscription_RootEmailHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailHistory_Order_By>>;
  where?: InputMaybe<EmailHistory_Bool_Exp>;
};

export type Subscription_RootEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEmailHistory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<EmailHistory_Bool_Exp>;
};

export type Subscription_RootGoogleChannelArgs = {
  distinct_on?: InputMaybe<Array<GoogleChannel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleChannel_Order_By>>;
  where?: InputMaybe<GoogleChannel_Bool_Exp>;
};

export type Subscription_RootGoogleChannel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GoogleChannel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleChannel_Order_By>>;
  where?: InputMaybe<GoogleChannel_Bool_Exp>;
};

export type Subscription_RootGoogleChannel_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGoogleChannel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GoogleChannel_Stream_Cursor_Input>>;
  where?: InputMaybe<GoogleChannel_Bool_Exp>;
};

export type Subscription_RootGrowthIntraCompanyAdoptionContactArgs = {
  distinct_on?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Order_By>>;
  where?: InputMaybe<GrowthIntraCompanyAdoptionContact_Bool_Exp>;
};

export type Subscription_RootGrowthIntraCompanyAdoptionContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthIntraCompanyAdoptionContact_Order_By>>;
  where?: InputMaybe<GrowthIntraCompanyAdoptionContact_Bool_Exp>;
};

export type Subscription_RootGrowthIntraCompanyAdoptionContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGrowthIntraCompanyAdoptionContact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GrowthIntraCompanyAdoptionContact_Stream_Cursor_Input>>;
  where?: InputMaybe<GrowthIntraCompanyAdoptionContact_Bool_Exp>;
};

export type Subscription_RootGrowthUserReferralContactArgs = {
  distinct_on?: InputMaybe<Array<GrowthUserReferralContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthUserReferralContact_Order_By>>;
  where?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
};

export type Subscription_RootGrowthUserReferralContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthUserReferralContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthUserReferralContact_Order_By>>;
  where?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
};

export type Subscription_RootGrowthUserReferralContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGrowthUserReferralContact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GrowthUserReferralContact_Stream_Cursor_Input>>;
  where?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
};

export type Subscription_RootHubspotContactListArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactList_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactList_Order_By>>;
  where?: InputMaybe<HubspotContactList_Bool_Exp>;
};

export type Subscription_RootHubspotContactListHubspotContactArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactListHubspotContact_Order_By>>;
  where?: InputMaybe<HubspotContactListHubspotContact_Bool_Exp>;
};

export type Subscription_RootHubspotContactListHubspotContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactListHubspotContact_Order_By>>;
  where?: InputMaybe<HubspotContactListHubspotContact_Bool_Exp>;
};

export type Subscription_RootHubspotContactListHubspotContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspotContactListHubspotContact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<HubspotContactListHubspotContact_Stream_Cursor_Input>>;
  where?: InputMaybe<HubspotContactListHubspotContact_Bool_Exp>;
};

export type Subscription_RootHubspotContactList_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactList_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactList_Order_By>>;
  where?: InputMaybe<HubspotContactList_Bool_Exp>;
};

export type Subscription_RootHubspotContactList_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspotContactList_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<HubspotContactList_Stream_Cursor_Input>>;
  where?: InputMaybe<HubspotContactList_Bool_Exp>;
};

export type Subscription_RootHubspotContactPropertyArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactProperty_Order_By>>;
  where?: InputMaybe<HubspotContactProperty_Bool_Exp>;
};

export type Subscription_RootHubspotContactProperty_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotContactProperty_Order_By>>;
  where?: InputMaybe<HubspotContactProperty_Bool_Exp>;
};

export type Subscription_RootHubspotContactProperty_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspotContactProperty_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<HubspotContactProperty_Stream_Cursor_Input>>;
  where?: InputMaybe<HubspotContactProperty_Bool_Exp>;
};

export type Subscription_RootHubspotIntegrationArgs = {
  distinct_on?: InputMaybe<Array<HubspotIntegration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotIntegration_Order_By>>;
  where?: InputMaybe<HubspotIntegration_Bool_Exp>;
};

export type Subscription_RootHubspotIntegration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<HubspotIntegration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<HubspotIntegration_Order_By>>;
  where?: InputMaybe<HubspotIntegration_Bool_Exp>;
};

export type Subscription_RootHubspotIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHubspotIntegration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<HubspotIntegration_Stream_Cursor_Input>>;
  where?: InputMaybe<HubspotIntegration_Bool_Exp>;
};

export type Subscription_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Subscription_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Subscription_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootJob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Job_Bool_Exp>;
};

export type Subscription_RootMicrosoftSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<MicrosoftSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MicrosoftSubscription_Order_By>>;
  where?: InputMaybe<MicrosoftSubscription_Bool_Exp>;
};

export type Subscription_RootMicrosoftSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MicrosoftSubscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MicrosoftSubscription_Order_By>>;
  where?: InputMaybe<MicrosoftSubscription_Bool_Exp>;
};

export type Subscription_RootMicrosoftSubscription_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMicrosoftSubscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MicrosoftSubscription_Stream_Cursor_Input>>;
  where?: InputMaybe<MicrosoftSubscription_Bool_Exp>;
};

export type Subscription_RootProxycrawlDataArgs = {
  distinct_on?: InputMaybe<Array<ProxycrawlData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProxycrawlData_Order_By>>;
  where?: InputMaybe<ProxycrawlData_Bool_Exp>;
};

export type Subscription_RootProxycrawlData_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProxycrawlData_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProxycrawlData_Order_By>>;
  where?: InputMaybe<ProxycrawlData_Bool_Exp>;
};

export type Subscription_RootProxycrawlData_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProxycrawlData_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProxycrawlData_Stream_Cursor_Input>>;
  where?: InputMaybe<ProxycrawlData_Bool_Exp>;
};

export type Subscription_RootQaQualificationArgs = {
  distinct_on?: InputMaybe<Array<QaQualification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaQualification_Order_By>>;
  where?: InputMaybe<QaQualification_Bool_Exp>;
};

export type Subscription_RootQaQualification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QaQualification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaQualification_Order_By>>;
  where?: InputMaybe<QaQualification_Bool_Exp>;
};

export type Subscription_RootQaQualification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootQaQualification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QaQualification_Stream_Cursor_Input>>;
  where?: InputMaybe<QaQualification_Bool_Exp>;
};

export type Subscription_RootQaTaskArgs = {
  distinct_on?: InputMaybe<Array<QaTask_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTask_Order_By>>;
  where?: InputMaybe<QaTask_Bool_Exp>;
};

export type Subscription_RootQaTaskActionArgs = {
  distinct_on?: InputMaybe<Array<QaTaskAction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTaskAction_Order_By>>;
  where?: InputMaybe<QaTaskAction_Bool_Exp>;
};

export type Subscription_RootQaTaskAction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QaTaskAction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTaskAction_Order_By>>;
  where?: InputMaybe<QaTaskAction_Bool_Exp>;
};

export type Subscription_RootQaTaskAction_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootQaTaskAction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QaTaskAction_Stream_Cursor_Input>>;
  where?: InputMaybe<QaTaskAction_Bool_Exp>;
};

export type Subscription_RootQaTask_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QaTask_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaTask_Order_By>>;
  where?: InputMaybe<QaTask_Bool_Exp>;
};

export type Subscription_RootQaTask_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootQaTask_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QaTask_Stream_Cursor_Input>>;
  where?: InputMaybe<QaTask_Bool_Exp>;
};

export type Subscription_RootSalesforceAccountArgs = {
  distinct_on?: InputMaybe<Array<SalesforceAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceAccount_Order_By>>;
  where?: InputMaybe<SalesforceAccount_Bool_Exp>;
};

export type Subscription_RootSalesforceAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceAccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceAccount_Order_By>>;
  where?: InputMaybe<SalesforceAccount_Bool_Exp>;
};

export type Subscription_RootSalesforceAccount_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSalesforceAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SalesforceAccount_Stream_Cursor_Input>>;
  where?: InputMaybe<SalesforceAccount_Bool_Exp>;
};

export type Subscription_RootSalesforceContactFieldArgs = {
  distinct_on?: InputMaybe<Array<SalesforceContactField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceContactField_Order_By>>;
  where?: InputMaybe<SalesforceContactField_Bool_Exp>;
};

export type Subscription_RootSalesforceContactField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceContactField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceContactField_Order_By>>;
  where?: InputMaybe<SalesforceContactField_Bool_Exp>;
};

export type Subscription_RootSalesforceContactField_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSalesforceContactField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SalesforceContactField_Stream_Cursor_Input>>;
  where?: InputMaybe<SalesforceContactField_Bool_Exp>;
};

export type Subscription_RootSalesforceIntegrationArgs = {
  distinct_on?: InputMaybe<Array<SalesforceIntegration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceIntegration_Order_By>>;
  where?: InputMaybe<SalesforceIntegration_Bool_Exp>;
};

export type Subscription_RootSalesforceIntegration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceIntegration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceIntegration_Order_By>>;
  where?: InputMaybe<SalesforceIntegration_Bool_Exp>;
};

export type Subscription_RootSalesforceIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSalesforceIntegration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SalesforceIntegration_Stream_Cursor_Input>>;
  where?: InputMaybe<SalesforceIntegration_Bool_Exp>;
};

export type Subscription_RootSalesforceOpportunityArgs = {
  distinct_on?: InputMaybe<Array<SalesforceOpportunity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceOpportunity_Order_By>>;
  where?: InputMaybe<SalesforceOpportunity_Bool_Exp>;
};

export type Subscription_RootSalesforceOpportunity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceOpportunity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceOpportunity_Order_By>>;
  where?: InputMaybe<SalesforceOpportunity_Bool_Exp>;
};

export type Subscription_RootSalesforceOpportunity_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSalesforceOpportunity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SalesforceOpportunity_Stream_Cursor_Input>>;
  where?: InputMaybe<SalesforceOpportunity_Bool_Exp>;
};

export type Subscription_RootSendgridEventArgs = {
  distinct_on?: InputMaybe<Array<SendgridEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SendgridEvent_Order_By>>;
  where?: InputMaybe<SendgridEvent_Bool_Exp>;
};

export type Subscription_RootSendgridEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SendgridEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SendgridEvent_Order_By>>;
  where?: InputMaybe<SendgridEvent_Bool_Exp>;
};

export type Subscription_RootSendgridEvent_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSendgridEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SendgridEvent_Stream_Cursor_Input>>;
  where?: InputMaybe<SendgridEvent_Bool_Exp>;
};

export type Subscription_RootSyncZoomEventsJobArgs = {
  distinct_on?: InputMaybe<Array<SyncZoomEventsJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SyncZoomEventsJob_Order_By>>;
  where?: InputMaybe<SyncZoomEventsJob_Bool_Exp>;
};

export type Subscription_RootSyncZoomEventsJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SyncZoomEventsJob_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SyncZoomEventsJob_Order_By>>;
  where?: InputMaybe<SyncZoomEventsJob_Bool_Exp>;
};

export type Subscription_RootSyncZoomEventsJob_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSyncZoomEventsJob_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SyncZoomEventsJob_Stream_Cursor_Input>>;
  where?: InputMaybe<SyncZoomEventsJob_Bool_Exp>;
};

export type Subscription_RootTerritoryArgs = {
  distinct_on?: InputMaybe<Array<Territory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Territory_Order_By>>;
  where?: InputMaybe<Territory_Bool_Exp>;
};

export type Subscription_RootTerritorySearchTermArgs = {
  distinct_on?: InputMaybe<Array<TerritorySearchTerm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TerritorySearchTerm_Order_By>>;
  where?: InputMaybe<TerritorySearchTerm_Bool_Exp>;
};

export type Subscription_RootTerritorySearchTerm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TerritorySearchTerm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TerritorySearchTerm_Order_By>>;
  where?: InputMaybe<TerritorySearchTerm_Bool_Exp>;
};

export type Subscription_RootTerritorySearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTerritorySearchTerm_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TerritorySearchTerm_Stream_Cursor_Input>>;
  where?: InputMaybe<TerritorySearchTerm_Bool_Exp>;
};

export type Subscription_RootTerritory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Territory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Territory_Order_By>>;
  where?: InputMaybe<Territory_Bool_Exp>;
};

export type Subscription_RootTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTerritory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Territory_Stream_Cursor_Input>>;
  where?: InputMaybe<Territory_Bool_Exp>;
};

export type Subscription_RootTheOrgSearchTermArgs = {
  distinct_on?: InputMaybe<Array<TheOrgSearchTerm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TheOrgSearchTerm_Order_By>>;
  where?: InputMaybe<TheOrgSearchTerm_Bool_Exp>;
};

export type Subscription_RootTheOrgSearchTerm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TheOrgSearchTerm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TheOrgSearchTerm_Order_By>>;
  where?: InputMaybe<TheOrgSearchTerm_Bool_Exp>;
};

export type Subscription_RootTheOrgSearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTheOrgSearchTerm_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TheOrgSearchTerm_Stream_Cursor_Input>>;
  where?: InputMaybe<TheOrgSearchTerm_Bool_Exp>;
};

export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUserInviteArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

export type Subscription_RootUserInvite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserInvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserInvite_Order_By>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

export type Subscription_RootUserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUserInvite_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserInvite_Stream_Cursor_Input>>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};

export type Subscription_RootWarmCompanyArgs = {
  distinct_on?: InputMaybe<Array<WarmCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WarmCompany_Order_By>>;
  where?: InputMaybe<WarmCompany_Bool_Exp>;
};

export type Subscription_RootWarmCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WarmCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WarmCompany_Order_By>>;
  where?: InputMaybe<WarmCompany_Bool_Exp>;
};

export type Subscription_RootWarmCompany_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WarmCompany_Stream_Cursor_Input>>;
  where?: InputMaybe<WarmCompany_Bool_Exp>;
};

export type Subscription_RootWorkOsConnectionArgs = {
  distinct_on?: InputMaybe<Array<WorkOsConnection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsConnection_Order_By>>;
  where?: InputMaybe<WorkOsConnection_Bool_Exp>;
};

export type Subscription_RootWorkOsConnection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkOsConnection_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsConnection_Order_By>>;
  where?: InputMaybe<WorkOsConnection_Bool_Exp>;
};

export type Subscription_RootWorkOsConnection_By_PkArgs = {
  connectionId: Scalars['String'];
};

export type Subscription_RootWorkOsConnection_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WorkOsConnection_Stream_Cursor_Input>>;
  where?: InputMaybe<WorkOsConnection_Bool_Exp>;
};

export type Subscription_RootWorkOsDirectoryArgs = {
  distinct_on?: InputMaybe<Array<WorkOsDirectory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsDirectory_Order_By>>;
  where?: InputMaybe<WorkOsDirectory_Bool_Exp>;
};

export type Subscription_RootWorkOsDirectory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkOsDirectory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsDirectory_Order_By>>;
  where?: InputMaybe<WorkOsDirectory_Bool_Exp>;
};

export type Subscription_RootWorkOsDirectory_By_PkArgs = {
  directoryId: Scalars['String'];
};

export type Subscription_RootWorkOsDirectory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WorkOsDirectory_Stream_Cursor_Input>>;
  where?: InputMaybe<WorkOsDirectory_Bool_Exp>;
};

export type Subscription_RootWorkOsGroupArgs = {
  distinct_on?: InputMaybe<Array<WorkOsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsGroup_Order_By>>;
  where?: InputMaybe<WorkOsGroup_Bool_Exp>;
};

export type Subscription_RootWorkOsGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkOsGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsGroup_Order_By>>;
  where?: InputMaybe<WorkOsGroup_Bool_Exp>;
};

export type Subscription_RootWorkOsGroup_By_PkArgs = {
  groupId: Scalars['String'];
};

export type Subscription_RootWorkOsGroup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WorkOsGroup_Stream_Cursor_Input>>;
  where?: InputMaybe<WorkOsGroup_Bool_Exp>;
};

export type Subscription_RootWorkOsUserArgs = {
  distinct_on?: InputMaybe<Array<WorkOsUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsUser_Order_By>>;
  where?: InputMaybe<WorkOsUser_Bool_Exp>;
};

export type Subscription_RootWorkOsUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WorkOsUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WorkOsUser_Order_By>>;
  where?: InputMaybe<WorkOsUser_Bool_Exp>;
};

export type Subscription_RootWorkOsUser_By_PkArgs = {
  userId: Scalars['String'];
};

export type Subscription_RootWorkOsUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WorkOsUser_Stream_Cursor_Input>>;
  where?: InputMaybe<WorkOsUser_Bool_Exp>;
};

export type Subscription_RootZoomApprovedDomainArgs = {
  distinct_on?: InputMaybe<Array<ZoomApprovedDomain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomApprovedDomain_Order_By>>;
  where?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
};

export type Subscription_RootZoomApprovedDomain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomApprovedDomain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomApprovedDomain_Order_By>>;
  where?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
};

export type Subscription_RootZoomApprovedDomain_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomApprovedDomain_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomApprovedDomain_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
};

export type Subscription_RootZoomCompanyArgs = {
  distinct_on?: InputMaybe<Array<ZoomCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompany_Order_By>>;
  where?: InputMaybe<ZoomCompany_Bool_Exp>;
};

export type Subscription_RootZoomCompanyMergeResultArgs = {
  distinct_on?: InputMaybe<Array<ZoomCompanyMergeResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompanyMergeResult_Order_By>>;
  where?: InputMaybe<ZoomCompanyMergeResult_Bool_Exp>;
};

export type Subscription_RootZoomCompanyMergeResult_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomCompanyMergeResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompanyMergeResult_Order_By>>;
  where?: InputMaybe<ZoomCompanyMergeResult_Bool_Exp>;
};

export type Subscription_RootZoomCompanyMergeResult_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomCompanyMergeResult_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomCompanyMergeResult_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomCompanyMergeResult_Bool_Exp>;
};

export type Subscription_RootZoomCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompany_Order_By>>;
  where?: InputMaybe<ZoomCompany_Bool_Exp>;
};

export type Subscription_RootZoomCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomCompany_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomCompany_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomCompany_Bool_Exp>;
};

export type Subscription_RootZoomConnectionRequestArgs = {
  distinct_on?: InputMaybe<Array<ZoomConnectionRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequest_Order_By>>;
  where?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
};

export type Subscription_RootZoomConnectionRequestReminderArgs = {
  distinct_on?: InputMaybe<Array<ZoomConnectionRequestReminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequestReminder_Order_By>>;
  where?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
};

export type Subscription_RootZoomConnectionRequestReminder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomConnectionRequestReminder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequestReminder_Order_By>>;
  where?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
};

export type Subscription_RootZoomConnectionRequestReminder_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomConnectionRequestReminder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomConnectionRequestReminder_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
};

export type Subscription_RootZoomConnectionRequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomConnectionRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequest_Order_By>>;
  where?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
};

export type Subscription_RootZoomConnectionRequest_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomConnectionRequest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomConnectionRequest_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
};

export type Subscription_RootZoomContactArgs = {
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By>>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
};

export type Subscription_RootZoomContactNotesArgs = {
  distinct_on?: InputMaybe<Array<ZoomContactNotes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContactNotes_Order_By>>;
  where?: InputMaybe<ZoomContactNotes_Bool_Exp>;
};

export type Subscription_RootZoomContactNotes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomContactNotes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContactNotes_Order_By>>;
  where?: InputMaybe<ZoomContactNotes_Bool_Exp>;
};

export type Subscription_RootZoomContactNotes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomContactNotes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomContactNotes_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomContactNotes_Bool_Exp>;
};

export type Subscription_RootZoomContact_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By>>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
};

export type Subscription_RootZoomContact_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomContact_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomContact_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
};

export type Subscription_RootZoomConversationStarterAnswerArgs = {
  distinct_on?: InputMaybe<Array<ZoomConversationStarterAnswer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterAnswer_Order_By>>;
  where?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
};

export type Subscription_RootZoomConversationStarterAnswer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomConversationStarterAnswer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterAnswer_Order_By>>;
  where?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
};

export type Subscription_RootZoomConversationStarterAnswer_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomConversationStarterAnswer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomConversationStarterAnswer_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
};

export type Subscription_RootZoomConversationStarterPromptArgs = {
  distinct_on?: InputMaybe<Array<ZoomConversationStarterPrompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterPrompt_Order_By>>;
  where?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
};

export type Subscription_RootZoomConversationStarterPrompt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomConversationStarterPrompt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterPrompt_Order_By>>;
  where?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
};

export type Subscription_RootZoomConversationStarterPrompt_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomConversationStarterPrompt_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomConversationStarterPrompt_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
};

export type Subscription_RootZoomCustomBackgroundArgs = {
  distinct_on?: InputMaybe<Array<ZoomCustomBackground_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCustomBackground_Order_By>>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
};

export type Subscription_RootZoomCustomBackground_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomCustomBackground_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCustomBackground_Order_By>>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
};

export type Subscription_RootZoomCustomBackground_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomCustomBackground_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomCustomBackground_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
};

export type Subscription_RootZoomEmailHistoryArgs = {
  distinct_on?: InputMaybe<Array<ZoomEmailHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEmailHistory_Order_By>>;
  where?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
};

export type Subscription_RootZoomEmailHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEmailHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEmailHistory_Order_By>>;
  where?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
};

export type Subscription_RootZoomEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomEmailHistory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomEmailHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
};

export type Subscription_RootZoomEventArgs = {
  distinct_on?: InputMaybe<Array<ZoomEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEvent_Order_By>>;
  where?: InputMaybe<ZoomEvent_Bool_Exp>;
};

export type Subscription_RootZoomEventParticipantArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

export type Subscription_RootZoomEventParticipantWithContactsArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Subscription_RootZoomEventParticipantWithContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Subscription_RootZoomEventParticipantWithContacts_SortedArgs = {
  args: ZoomEventParticipantWithContacts_Sorted_Args;
  distinct_on?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Subscription_RootZoomEventParticipantWithContacts_Sorted_AggregateArgs = {
  args: ZoomEventParticipantWithContacts_Sorted_Args;
  distinct_on?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Subscription_RootZoomEventParticipantWithContacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomEventParticipantWithContacts_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
};

export type Subscription_RootZoomEventParticipant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

export type Subscription_RootZoomEventParticipant_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomEventParticipant_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomEventParticipant_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

export type Subscription_RootZoomEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEvent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEvent_Order_By>>;
  where?: InputMaybe<ZoomEvent_Bool_Exp>;
};

export type Subscription_RootZoomEvent_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootZoomEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomEvent_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomEvent_Bool_Exp>;
};

export type Subscription_RootZoomExternalAuthUserArgs = {
  distinct_on?: InputMaybe<Array<ZoomExternalAuthUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomExternalAuthUser_Order_By>>;
  where?: InputMaybe<ZoomExternalAuthUser_Bool_Exp>;
};

export type Subscription_RootZoomExternalAuthUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomExternalAuthUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomExternalAuthUser_Order_By>>;
  where?: InputMaybe<ZoomExternalAuthUser_Bool_Exp>;
};

export type Subscription_RootZoomExternalAuthUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomExternalAuthUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomExternalAuthUser_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomExternalAuthUser_Bool_Exp>;
};

export type Subscription_RootZoomGroupArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroup_Order_By>>;
  where?: InputMaybe<ZoomGroup_Bool_Exp>;
};

export type Subscription_RootZoomGroupMembershipArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

export type Subscription_RootZoomGroupMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

export type Subscription_RootZoomGroupMembership_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomGroupMembership_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomGroupMembership_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

export type Subscription_RootZoomGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroup_Order_By>>;
  where?: InputMaybe<ZoomGroup_Bool_Exp>;
};

export type Subscription_RootZoomGroup_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomGroup_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomGroup_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomGroup_Bool_Exp>;
};

export type Subscription_RootZoomLayersEventsArgs = {
  distinct_on?: InputMaybe<Array<ZoomLayersEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomLayersEvents_Order_By>>;
  where?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
};

export type Subscription_RootZoomLayersEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomLayersEvents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomLayersEvents_Order_By>>;
  where?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
};

export type Subscription_RootZoomLayersEvents_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomLayersEvents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomLayersEvents_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganization_Order_By>>;
  where?: InputMaybe<ZoomOrganization_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationBackfillArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfill_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfill_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationBackfillResultArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfillResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfillResult_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationBackfillResult_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfillResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfillResult_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationBackfillResult_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomOrganizationBackfillResult_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomOrganizationBackfillResult_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationBackfill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfill_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfill_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationBackfill_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomOrganizationBackfill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomOrganizationBackfill_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomOrganizationBackfill_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationMembershipArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembership_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationMembershipViewArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembershipView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembershipView_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationMembershipView_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembershipView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembershipView_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationMembershipView_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomOrganizationMembershipView_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationMembership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembership_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
};

export type Subscription_RootZoomOrganizationMembership_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomOrganizationMembership_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomOrganizationMembership_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
};

export type Subscription_RootZoomOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganization_Order_By>>;
  where?: InputMaybe<ZoomOrganization_Bool_Exp>;
};

export type Subscription_RootZoomOrganization_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomOrganization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomOrganization_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomOrganization_Bool_Exp>;
};

export type Subscription_RootZoomPlanArgs = {
  distinct_on?: InputMaybe<Array<ZoomPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPlan_Order_By>>;
  where?: InputMaybe<ZoomPlan_Bool_Exp>;
};

export type Subscription_RootZoomPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPlan_Order_By>>;
  where?: InputMaybe<ZoomPlan_Bool_Exp>;
};

export type Subscription_RootZoomPlan_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomPlan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomPlan_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomPlan_Bool_Exp>;
};

export type Subscription_RootZoomPresentationArgs = {
  distinct_on?: InputMaybe<Array<ZoomPresentation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPresentation_Order_By>>;
  where?: InputMaybe<ZoomPresentation_Bool_Exp>;
};

export type Subscription_RootZoomPresentation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomPresentation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPresentation_Order_By>>;
  where?: InputMaybe<ZoomPresentation_Bool_Exp>;
};

export type Subscription_RootZoomPresentation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomPresentation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomPresentation_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomPresentation_Bool_Exp>;
};

export type Subscription_RootZoomSettingsArgs = {
  distinct_on?: InputMaybe<Array<ZoomSettings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomSettings_Order_By>>;
  where?: InputMaybe<ZoomSettings_Bool_Exp>;
};

export type Subscription_RootZoomSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomSettings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomSettings_Order_By>>;
  where?: InputMaybe<ZoomSettings_Bool_Exp>;
};

export type Subscription_RootZoomSettings_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootZoomSettings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomSettings_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomSettings_Bool_Exp>;
};

export type Subscription_RootZoomUserArgs = {
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By>>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
};

export type Subscription_RootZoomUserActionsArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserActions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserActions_Order_By>>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
};

export type Subscription_RootZoomUserActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserActions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserActions_Order_By>>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
};

export type Subscription_RootZoomUserActions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomUserActions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomUserActions_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
};

export type Subscription_RootZoomUserConnectionRequestArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserConnectionRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserConnectionRequest_Order_By>>;
  where?: InputMaybe<ZoomUserConnectionRequest_Bool_Exp>;
};

export type Subscription_RootZoomUserConnectionRequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserConnectionRequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserConnectionRequest_Order_By>>;
  where?: InputMaybe<ZoomUserConnectionRequest_Bool_Exp>;
};

export type Subscription_RootZoomUserConnectionRequest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomUserConnectionRequest_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomUserConnectionRequest_Bool_Exp>;
};

export type Subscription_RootZoomUserMeetingParticipantsArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserMeetingParticipants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserMeetingParticipants_Order_By>>;
  where?: InputMaybe<ZoomUserMeetingParticipants_Bool_Exp>;
};

export type Subscription_RootZoomUserMeetingParticipants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserMeetingParticipants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserMeetingParticipants_Order_By>>;
  where?: InputMaybe<ZoomUserMeetingParticipants_Bool_Exp>;
};

export type Subscription_RootZoomUserMeetingParticipants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomUserMeetingParticipants_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomUserMeetingParticipants_Bool_Exp>;
};

export type Subscription_RootZoomUserNetworkArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserNetwork_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserNetwork_Order_By>>;
  where?: InputMaybe<ZoomUserNetwork_Bool_Exp>;
};

export type Subscription_RootZoomUserNetwork_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserNetwork_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserNetwork_Order_By>>;
  where?: InputMaybe<ZoomUserNetwork_Bool_Exp>;
};

export type Subscription_RootZoomUserNetwork_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomUserNetwork_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomUserNetwork_Bool_Exp>;
};

export type Subscription_RootZoomUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By>>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
};

export type Subscription_RootZoomUser_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZoomUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomUser_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
};

export type Subscription_RootZoomUsersAtCompanyArgs = {
  distinct_on?: InputMaybe<Array<ZoomUsersAtCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUsersAtCompany_Order_By>>;
  where?: InputMaybe<ZoomUsersAtCompany_Bool_Exp>;
};

export type Subscription_RootZoomUsersAtCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUsersAtCompany_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUsersAtCompany_Order_By>>;
  where?: InputMaybe<ZoomUsersAtCompany_Bool_Exp>;
};

export type Subscription_RootZoomUsersAtCompany_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ZoomUsersAtCompany_Stream_Cursor_Input>>;
  where?: InputMaybe<ZoomUsersAtCompany_Bool_Exp>;
};

/** Table to track jobs to sync zoomEvents to BigQuery */
export type SyncZoomEventsJob = {
  __typename?: 'syncZoomEventsJob';
  chunkSize: Scalars['Int'];
  cloudTaskName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  errorMessage?: Maybe<Scalars['String']>;
  eventsInIDRange: Scalars['Int'];
  from: Scalars['Int'];
  id: Scalars['Int'];
  runId: Scalars['uuid'];
  status: Scalars['String'];
  syncComplete?: Maybe<Scalars['Boolean']>;
  to: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "syncZoomEventsJob" */
export type SyncZoomEventsJob_Aggregate = {
  __typename?: 'syncZoomEventsJob_aggregate';
  aggregate?: Maybe<SyncZoomEventsJob_Aggregate_Fields>;
  nodes: Array<SyncZoomEventsJob>;
};

/** aggregate fields of "syncZoomEventsJob" */
export type SyncZoomEventsJob_Aggregate_Fields = {
  __typename?: 'syncZoomEventsJob_aggregate_fields';
  avg?: Maybe<SyncZoomEventsJob_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<SyncZoomEventsJob_Max_Fields>;
  min?: Maybe<SyncZoomEventsJob_Min_Fields>;
  stddev?: Maybe<SyncZoomEventsJob_Stddev_Fields>;
  stddev_pop?: Maybe<SyncZoomEventsJob_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SyncZoomEventsJob_Stddev_Samp_Fields>;
  sum?: Maybe<SyncZoomEventsJob_Sum_Fields>;
  var_pop?: Maybe<SyncZoomEventsJob_Var_Pop_Fields>;
  var_samp?: Maybe<SyncZoomEventsJob_Var_Samp_Fields>;
  variance?: Maybe<SyncZoomEventsJob_Variance_Fields>;
};

/** aggregate fields of "syncZoomEventsJob" */
export type SyncZoomEventsJob_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SyncZoomEventsJob_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SyncZoomEventsJob_Avg_Fields = {
  __typename?: 'syncZoomEventsJob_avg_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  eventsInIDRange?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "syncZoomEventsJob". All fields are combined with a logical 'AND'. */
export type SyncZoomEventsJob_Bool_Exp = {
  _and?: InputMaybe<Array<SyncZoomEventsJob_Bool_Exp>>;
  _not?: InputMaybe<SyncZoomEventsJob_Bool_Exp>;
  _or?: InputMaybe<Array<SyncZoomEventsJob_Bool_Exp>>;
  chunkSize?: InputMaybe<Int_Comparison_Exp>;
  cloudTaskName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  errorMessage?: InputMaybe<String_Comparison_Exp>;
  eventsInIDRange?: InputMaybe<Int_Comparison_Exp>;
  from?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  runId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  syncComplete?: InputMaybe<Boolean_Comparison_Exp>;
  to?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "syncZoomEventsJob" */
export enum SyncZoomEventsJob_Constraint {
  /** unique or primary key constraint on columns "id" */
  SyncZoomEventsJobPkey = 'syncZoomEventsJob_pkey',
}

/** input type for incrementing numeric columns in table "syncZoomEventsJob" */
export type SyncZoomEventsJob_Inc_Input = {
  chunkSize?: InputMaybe<Scalars['Int']>;
  eventsInIDRange?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "syncZoomEventsJob" */
export type SyncZoomEventsJob_Insert_Input = {
  chunkSize?: InputMaybe<Scalars['Int']>;
  cloudTaskName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  eventsInIDRange?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  runId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  syncComplete?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SyncZoomEventsJob_Max_Fields = {
  __typename?: 'syncZoomEventsJob_max_fields';
  chunkSize?: Maybe<Scalars['Int']>;
  cloudTaskName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  eventsInIDRange?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  runId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type SyncZoomEventsJob_Min_Fields = {
  __typename?: 'syncZoomEventsJob_min_fields';
  chunkSize?: Maybe<Scalars['Int']>;
  cloudTaskName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  eventsInIDRange?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  runId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "syncZoomEventsJob" */
export type SyncZoomEventsJob_Mutation_Response = {
  __typename?: 'syncZoomEventsJob_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SyncZoomEventsJob>;
};

/** on_conflict condition type for table "syncZoomEventsJob" */
export type SyncZoomEventsJob_On_Conflict = {
  constraint: SyncZoomEventsJob_Constraint;
  update_columns?: Array<SyncZoomEventsJob_Update_Column>;
  where?: InputMaybe<SyncZoomEventsJob_Bool_Exp>;
};

/** Ordering options when selecting data from "syncZoomEventsJob". */
export type SyncZoomEventsJob_Order_By = {
  chunkSize?: InputMaybe<Order_By>;
  cloudTaskName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  eventsInIDRange?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  runId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  syncComplete?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: syncZoomEventsJob */
export type SyncZoomEventsJob_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "syncZoomEventsJob" */
export enum SyncZoomEventsJob_Select_Column {
  /** column name */
  ChunkSize = 'chunkSize',
  /** column name */
  CloudTaskName = 'cloudTaskName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  EventsInIdRange = 'eventsInIDRange',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  RunId = 'runId',
  /** column name */
  Status = 'status',
  /** column name */
  SyncComplete = 'syncComplete',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "syncZoomEventsJob" */
export type SyncZoomEventsJob_Set_Input = {
  chunkSize?: InputMaybe<Scalars['Int']>;
  cloudTaskName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  eventsInIDRange?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  runId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  syncComplete?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type SyncZoomEventsJob_Stddev_Fields = {
  __typename?: 'syncZoomEventsJob_stddev_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  eventsInIDRange?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type SyncZoomEventsJob_Stddev_Pop_Fields = {
  __typename?: 'syncZoomEventsJob_stddev_pop_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  eventsInIDRange?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type SyncZoomEventsJob_Stddev_Samp_Fields = {
  __typename?: 'syncZoomEventsJob_stddev_samp_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  eventsInIDRange?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "syncZoomEventsJob" */
export type SyncZoomEventsJob_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SyncZoomEventsJob_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type SyncZoomEventsJob_Stream_Cursor_Value_Input = {
  chunkSize?: InputMaybe<Scalars['Int']>;
  cloudTaskName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  eventsInIDRange?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  runId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  syncComplete?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type SyncZoomEventsJob_Sum_Fields = {
  __typename?: 'syncZoomEventsJob_sum_fields';
  chunkSize?: Maybe<Scalars['Int']>;
  eventsInIDRange?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

/** update columns of table "syncZoomEventsJob" */
export enum SyncZoomEventsJob_Update_Column {
  /** column name */
  ChunkSize = 'chunkSize',
  /** column name */
  CloudTaskName = 'cloudTaskName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  EventsInIdRange = 'eventsInIDRange',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  RunId = 'runId',
  /** column name */
  Status = 'status',
  /** column name */
  SyncComplete = 'syncComplete',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SyncZoomEventsJob_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SyncZoomEventsJob_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SyncZoomEventsJob_Set_Input>;
  /** filter the rows which have to be updated */
  where: SyncZoomEventsJob_Bool_Exp;
};

/** aggregate var_pop on columns */
export type SyncZoomEventsJob_Var_Pop_Fields = {
  __typename?: 'syncZoomEventsJob_var_pop_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  eventsInIDRange?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type SyncZoomEventsJob_Var_Samp_Fields = {
  __typename?: 'syncZoomEventsJob_var_samp_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  eventsInIDRange?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type SyncZoomEventsJob_Variance_Fields = {
  __typename?: 'syncZoomEventsJob_variance_fields';
  chunkSize?: Maybe<Scalars['Float']>;
  eventsInIDRange?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "territory" */
export type Territory = {
  __typename?: 'territory';
  administrativeArea1?: Maybe<Scalars['String']>;
  administrativeArea2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  formattedAddress: Scalars['String'];
  id: Scalars['uuid'];
  json: Scalars['jsonb'];
  locality?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "territory" */
export type TerritoryJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "territorySearchTerm" */
export type TerritorySearchTerm = {
  __typename?: 'territorySearchTerm';
  id: Scalars['uuid'];
  searchTerm: Scalars['String'];
  territoryId: Scalars['uuid'];
};

/** aggregated selection of "territorySearchTerm" */
export type TerritorySearchTerm_Aggregate = {
  __typename?: 'territorySearchTerm_aggregate';
  aggregate?: Maybe<TerritorySearchTerm_Aggregate_Fields>;
  nodes: Array<TerritorySearchTerm>;
};

/** aggregate fields of "territorySearchTerm" */
export type TerritorySearchTerm_Aggregate_Fields = {
  __typename?: 'territorySearchTerm_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TerritorySearchTerm_Max_Fields>;
  min?: Maybe<TerritorySearchTerm_Min_Fields>;
};

/** aggregate fields of "territorySearchTerm" */
export type TerritorySearchTerm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TerritorySearchTerm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "territorySearchTerm". All fields are combined with a logical 'AND'. */
export type TerritorySearchTerm_Bool_Exp = {
  _and?: InputMaybe<Array<TerritorySearchTerm_Bool_Exp>>;
  _not?: InputMaybe<TerritorySearchTerm_Bool_Exp>;
  _or?: InputMaybe<Array<TerritorySearchTerm_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  searchTerm?: InputMaybe<String_Comparison_Exp>;
  territoryId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "territorySearchTerm" */
export enum TerritorySearchTerm_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritorySearchTermPkey = 'territorySearchTerm_pkey',
  /** unique or primary key constraint on columns "searchTerm", "territoryId" */
  TerritorySearchTermSearchTermTerritoryIdKey = 'territorySearchTerm_searchTerm_territoryId_key',
}

/** input type for inserting data into table "territorySearchTerm" */
export type TerritorySearchTerm_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  territoryId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TerritorySearchTerm_Max_Fields = {
  __typename?: 'territorySearchTerm_max_fields';
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  territoryId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type TerritorySearchTerm_Min_Fields = {
  __typename?: 'territorySearchTerm_min_fields';
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  territoryId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "territorySearchTerm" */
export type TerritorySearchTerm_Mutation_Response = {
  __typename?: 'territorySearchTerm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TerritorySearchTerm>;
};

/** on_conflict condition type for table "territorySearchTerm" */
export type TerritorySearchTerm_On_Conflict = {
  constraint: TerritorySearchTerm_Constraint;
  update_columns?: Array<TerritorySearchTerm_Update_Column>;
  where?: InputMaybe<TerritorySearchTerm_Bool_Exp>;
};

/** Ordering options when selecting data from "territorySearchTerm". */
export type TerritorySearchTerm_Order_By = {
  id?: InputMaybe<Order_By>;
  searchTerm?: InputMaybe<Order_By>;
  territoryId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territorySearchTerm */
export type TerritorySearchTerm_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "territorySearchTerm" */
export enum TerritorySearchTerm_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SearchTerm = 'searchTerm',
  /** column name */
  TerritoryId = 'territoryId',
}

/** input type for updating data in table "territorySearchTerm" */
export type TerritorySearchTerm_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  territoryId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "territorySearchTerm" */
export type TerritorySearchTerm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TerritorySearchTerm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TerritorySearchTerm_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  territoryId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "territorySearchTerm" */
export enum TerritorySearchTerm_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SearchTerm = 'searchTerm',
  /** column name */
  TerritoryId = 'territoryId',
}

export type TerritorySearchTerm_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TerritorySearchTerm_Set_Input>;
  /** filter the rows which have to be updated */
  where: TerritorySearchTerm_Bool_Exp;
};

/** aggregated selection of "territory" */
export type Territory_Aggregate = {
  __typename?: 'territory_aggregate';
  aggregate?: Maybe<Territory_Aggregate_Fields>;
  nodes: Array<Territory>;
};

/** aggregate fields of "territory" */
export type Territory_Aggregate_Fields = {
  __typename?: 'territory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Territory_Max_Fields>;
  min?: Maybe<Territory_Min_Fields>;
};

/** aggregate fields of "territory" */
export type Territory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Territory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Territory_Append_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "territory". All fields are combined with a logical 'AND'. */
export type Territory_Bool_Exp = {
  _and?: InputMaybe<Array<Territory_Bool_Exp>>;
  _not?: InputMaybe<Territory_Bool_Exp>;
  _or?: InputMaybe<Array<Territory_Bool_Exp>>;
  administrativeArea1?: InputMaybe<String_Comparison_Exp>;
  administrativeArea2?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  formattedAddress?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  json?: InputMaybe<Jsonb_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  placeId?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "territory" */
export enum Territory_Constraint {
  /** unique or primary key constraint on columns "id" */
  TerritoryPkey = 'territory_pkey',
  /** unique or primary key constraint on columns "placeId" */
  TerritoryPlaceIdKey = 'territory_placeId_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Territory_Delete_At_Path_Input = {
  json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Territory_Delete_Elem_Input = {
  json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Territory_Delete_Key_Input = {
  json?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "territory" */
export type Territory_Insert_Input = {
  administrativeArea1?: InputMaybe<Scalars['String']>;
  administrativeArea2?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  locality?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Territory_Max_Fields = {
  __typename?: 'territory_max_fields';
  administrativeArea1?: Maybe<Scalars['String']>;
  administrativeArea2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Territory_Min_Fields = {
  __typename?: 'territory_min_fields';
  administrativeArea1?: Maybe<Scalars['String']>;
  administrativeArea2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "territory" */
export type Territory_Mutation_Response = {
  __typename?: 'territory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Territory>;
};

/** input type for inserting object relation for remote table "territory" */
export type Territory_Obj_Rel_Insert_Input = {
  data: Territory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Territory_On_Conflict>;
};

/** on_conflict condition type for table "territory" */
export type Territory_On_Conflict = {
  constraint: Territory_Constraint;
  update_columns?: Array<Territory_Update_Column>;
  where?: InputMaybe<Territory_Bool_Exp>;
};

/** Ordering options when selecting data from "territory". */
export type Territory_Order_By = {
  administrativeArea1?: InputMaybe<Order_By>;
  administrativeArea2?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  formattedAddress?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  json?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  placeId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: territory */
export type Territory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Territory_Prepend_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "territory" */
export enum Territory_Select_Column {
  /** column name */
  AdministrativeArea1 = 'administrativeArea1',
  /** column name */
  AdministrativeArea2 = 'administrativeArea2',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormattedAddress = 'formattedAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Locality = 'locality',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "territory" */
export type Territory_Set_Input = {
  administrativeArea1?: InputMaybe<Scalars['String']>;
  administrativeArea2?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  locality?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "territory" */
export type Territory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Territory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Territory_Stream_Cursor_Value_Input = {
  administrativeArea1?: InputMaybe<Scalars['String']>;
  administrativeArea2?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  locality?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "territory" */
export enum Territory_Update_Column {
  /** column name */
  AdministrativeArea1 = 'administrativeArea1',
  /** column name */
  AdministrativeArea2 = 'administrativeArea2',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormattedAddress = 'formattedAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Locality = 'locality',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Territory_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Territory_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Territory_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Territory_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Territory_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Territory_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Territory_Set_Input>;
  /** filter the rows which have to be updated */
  where: Territory_Bool_Exp;
};

/** columns and relationships of "theOrgSearchTerm" */
export type TheOrgSearchTerm = {
  __typename?: 'theOrgSearchTerm';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  json: Scalars['jsonb'];
  searchTerm: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "theOrgSearchTerm" */
export type TheOrgSearchTermJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "theOrgSearchTerm" */
export type TheOrgSearchTerm_Aggregate = {
  __typename?: 'theOrgSearchTerm_aggregate';
  aggregate?: Maybe<TheOrgSearchTerm_Aggregate_Fields>;
  nodes: Array<TheOrgSearchTerm>;
};

/** aggregate fields of "theOrgSearchTerm" */
export type TheOrgSearchTerm_Aggregate_Fields = {
  __typename?: 'theOrgSearchTerm_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TheOrgSearchTerm_Max_Fields>;
  min?: Maybe<TheOrgSearchTerm_Min_Fields>;
};

/** aggregate fields of "theOrgSearchTerm" */
export type TheOrgSearchTerm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TheOrgSearchTerm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TheOrgSearchTerm_Append_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "theOrgSearchTerm". All fields are combined with a logical 'AND'. */
export type TheOrgSearchTerm_Bool_Exp = {
  _and?: InputMaybe<Array<TheOrgSearchTerm_Bool_Exp>>;
  _not?: InputMaybe<TheOrgSearchTerm_Bool_Exp>;
  _or?: InputMaybe<Array<TheOrgSearchTerm_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  json?: InputMaybe<Jsonb_Comparison_Exp>;
  searchTerm?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "theOrgSearchTerm" */
export enum TheOrgSearchTerm_Constraint {
  /** unique or primary key constraint on columns "id" */
  TheOrgSearchTermPkey = 'theOrgSearchTerm_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TheOrgSearchTerm_Delete_At_Path_Input = {
  json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TheOrgSearchTerm_Delete_Elem_Input = {
  json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TheOrgSearchTerm_Delete_Key_Input = {
  json?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TheOrgSearchTerm_Max_Fields = {
  __typename?: 'theOrgSearchTerm_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type TheOrgSearchTerm_Min_Fields = {
  __typename?: 'theOrgSearchTerm_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Mutation_Response = {
  __typename?: 'theOrgSearchTerm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TheOrgSearchTerm>;
};

/** on_conflict condition type for table "theOrgSearchTerm" */
export type TheOrgSearchTerm_On_Conflict = {
  constraint: TheOrgSearchTerm_Constraint;
  update_columns?: Array<TheOrgSearchTerm_Update_Column>;
  where?: InputMaybe<TheOrgSearchTerm_Bool_Exp>;
};

/** Ordering options when selecting data from "theOrgSearchTerm". */
export type TheOrgSearchTerm_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  json?: InputMaybe<Order_By>;
  searchTerm?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: theOrgSearchTerm */
export type TheOrgSearchTerm_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TheOrgSearchTerm_Prepend_Input = {
  json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "theOrgSearchTerm" */
export enum TheOrgSearchTerm_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  SearchTerm = 'searchTerm',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TheOrgSearchTerm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TheOrgSearchTerm_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  json?: InputMaybe<Scalars['jsonb']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "theOrgSearchTerm" */
export enum TheOrgSearchTerm_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  SearchTerm = 'searchTerm',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type TheOrgSearchTerm_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TheOrgSearchTerm_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TheOrgSearchTerm_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TheOrgSearchTerm_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TheOrgSearchTerm_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TheOrgSearchTerm_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TheOrgSearchTerm_Set_Input>;
  /** filter the rows which have to be updated */
  where: TheOrgSearchTerm_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  /** An object relationship */
  client: Client;
  clientId: Scalars['uuid'];
  clientPermission?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  firebaseUid: Scalars['String'];
  firestoreClientId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  jobChangeFilters?: Maybe<Scalars['jsonb']>;
  lastName: Scalars['String'];
  /** An array relationship */
  qaQualifications: Array<QaQualification>;
  /** An aggregate relationship */
  qaQualifications_aggregate: QaQualification_Aggregate;
  role: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "user" */
export type UserJobChangeFiltersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "user" */
export type UserQaQualificationsArgs = {
  distinct_on?: InputMaybe<Array<QaQualification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaQualification_Order_By>>;
  where?: InputMaybe<QaQualification_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserQaQualifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<QaQualification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QaQualification_Order_By>>;
  where?: InputMaybe<QaQualification_Bool_Exp>;
};

/** columns and relationships of "userInvite" */
export type UserInvite = {
  __typename?: 'userInvite';
  createdAt: Scalars['timestamptz'];
  expireAt: Scalars['timestamptz'];
  firestoreId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  inviteeClientPermission?: Maybe<Scalars['String']>;
  inviteeEmail: Scalars['String'];
  /** An object relationship */
  inviterClient: Client;
  inviterClientId: Scalars['uuid'];
  inviterClientName: Scalars['String'];
  /** An object relationship */
  inviterUser: User;
  inviterUserId: Scalars['uuid'];
  inviterUserName: Scalars['String'];
  status: Scalars['String'];
};

/** aggregated selection of "userInvite" */
export type UserInvite_Aggregate = {
  __typename?: 'userInvite_aggregate';
  aggregate?: Maybe<UserInvite_Aggregate_Fields>;
  nodes: Array<UserInvite>;
};

export type UserInvite_Aggregate_Bool_Exp = {
  count?: InputMaybe<UserInvite_Aggregate_Bool_Exp_Count>;
};

export type UserInvite_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserInvite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserInvite_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "userInvite" */
export type UserInvite_Aggregate_Fields = {
  __typename?: 'userInvite_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserInvite_Max_Fields>;
  min?: Maybe<UserInvite_Min_Fields>;
};

/** aggregate fields of "userInvite" */
export type UserInvite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserInvite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userInvite" */
export type UserInvite_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UserInvite_Max_Order_By>;
  min?: InputMaybe<UserInvite_Min_Order_By>;
};

/** input type for inserting array relation for remote table "userInvite" */
export type UserInvite_Arr_Rel_Insert_Input = {
  data: Array<UserInvite_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserInvite_On_Conflict>;
};

/** Boolean expression to filter rows from the table "userInvite". All fields are combined with a logical 'AND'. */
export type UserInvite_Bool_Exp = {
  _and?: InputMaybe<Array<UserInvite_Bool_Exp>>;
  _not?: InputMaybe<UserInvite_Bool_Exp>;
  _or?: InputMaybe<Array<UserInvite_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expireAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  firestoreId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inviteeClientPermission?: InputMaybe<String_Comparison_Exp>;
  inviteeEmail?: InputMaybe<String_Comparison_Exp>;
  inviterClient?: InputMaybe<Client_Bool_Exp>;
  inviterClientId?: InputMaybe<Uuid_Comparison_Exp>;
  inviterClientName?: InputMaybe<String_Comparison_Exp>;
  inviterUser?: InputMaybe<User_Bool_Exp>;
  inviterUserId?: InputMaybe<Uuid_Comparison_Exp>;
  inviterUserName?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "userInvite" */
export enum UserInvite_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserInvitePkey = 'userInvite_pkey',
}

/** input type for inserting data into table "userInvite" */
export type UserInvite_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expireAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteeClientPermission?: InputMaybe<Scalars['String']>;
  inviteeEmail?: InputMaybe<Scalars['String']>;
  inviterClient?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  inviterClientId?: InputMaybe<Scalars['uuid']>;
  inviterClientName?: InputMaybe<Scalars['String']>;
  inviterUser?: InputMaybe<User_Obj_Rel_Insert_Input>;
  inviterUserId?: InputMaybe<Scalars['uuid']>;
  inviterUserName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserInvite_Max_Fields = {
  __typename?: 'userInvite_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expireAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inviteeClientPermission?: Maybe<Scalars['String']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviterClientId?: Maybe<Scalars['uuid']>;
  inviterClientName?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['uuid']>;
  inviterUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userInvite" */
export type UserInvite_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expireAt?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inviteeClientPermission?: InputMaybe<Order_By>;
  inviteeEmail?: InputMaybe<Order_By>;
  inviterClientId?: InputMaybe<Order_By>;
  inviterClientName?: InputMaybe<Order_By>;
  inviterUserId?: InputMaybe<Order_By>;
  inviterUserName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UserInvite_Min_Fields = {
  __typename?: 'userInvite_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expireAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inviteeClientPermission?: Maybe<Scalars['String']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviterClientId?: Maybe<Scalars['uuid']>;
  inviterClientName?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['uuid']>;
  inviterUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userInvite" */
export type UserInvite_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expireAt?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inviteeClientPermission?: InputMaybe<Order_By>;
  inviteeEmail?: InputMaybe<Order_By>;
  inviterClientId?: InputMaybe<Order_By>;
  inviterClientName?: InputMaybe<Order_By>;
  inviterUserId?: InputMaybe<Order_By>;
  inviterUserName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "userInvite" */
export type UserInvite_Mutation_Response = {
  __typename?: 'userInvite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserInvite>;
};

/** on_conflict condition type for table "userInvite" */
export type UserInvite_On_Conflict = {
  constraint: UserInvite_Constraint;
  update_columns?: Array<UserInvite_Update_Column>;
  where?: InputMaybe<UserInvite_Bool_Exp>;
};

/** Ordering options when selecting data from "userInvite". */
export type UserInvite_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expireAt?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inviteeClientPermission?: InputMaybe<Order_By>;
  inviteeEmail?: InputMaybe<Order_By>;
  inviterClient?: InputMaybe<Client_Order_By>;
  inviterClientId?: InputMaybe<Order_By>;
  inviterClientName?: InputMaybe<Order_By>;
  inviterUser?: InputMaybe<User_Order_By>;
  inviterUserId?: InputMaybe<Order_By>;
  inviterUserName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: userInvite */
export type UserInvite_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userInvite" */
export enum UserInvite_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpireAt = 'expireAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeClientPermission = 'inviteeClientPermission',
  /** column name */
  InviteeEmail = 'inviteeEmail',
  /** column name */
  InviterClientId = 'inviterClientId',
  /** column name */
  InviterClientName = 'inviterClientName',
  /** column name */
  InviterUserId = 'inviterUserId',
  /** column name */
  InviterUserName = 'inviterUserName',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "userInvite" */
export type UserInvite_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expireAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteeClientPermission?: InputMaybe<Scalars['String']>;
  inviteeEmail?: InputMaybe<Scalars['String']>;
  inviterClientId?: InputMaybe<Scalars['uuid']>;
  inviterClientName?: InputMaybe<Scalars['String']>;
  inviterUserId?: InputMaybe<Scalars['uuid']>;
  inviterUserName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "userInvite" */
export type UserInvite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserInvite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserInvite_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expireAt?: InputMaybe<Scalars['timestamptz']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inviteeClientPermission?: InputMaybe<Scalars['String']>;
  inviteeEmail?: InputMaybe<Scalars['String']>;
  inviterClientId?: InputMaybe<Scalars['uuid']>;
  inviterClientName?: InputMaybe<Scalars['String']>;
  inviterUserId?: InputMaybe<Scalars['uuid']>;
  inviterUserName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "userInvite" */
export enum UserInvite_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpireAt = 'expireAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeClientPermission = 'inviteeClientPermission',
  /** column name */
  InviteeEmail = 'inviteeEmail',
  /** column name */
  InviterClientId = 'inviterClientId',
  /** column name */
  InviterClientName = 'inviterClientName',
  /** column name */
  InviterUserId = 'inviterUserId',
  /** column name */
  InviterUserName = 'inviterUserName',
  /** column name */
  Status = 'status',
}

export type UserInvite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserInvite_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserInvite_Bool_Exp;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  jobChangeFilters?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  client?: InputMaybe<Client_Bool_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  clientPermission?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firebaseUid?: InputMaybe<String_Comparison_Exp>;
  firestoreClientId?: InputMaybe<String_Comparison_Exp>;
  firestoreId?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  jobChangeFilters?: InputMaybe<Jsonb_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  qaQualifications?: InputMaybe<QaQualification_Bool_Exp>;
  qaQualifications_aggregate?: InputMaybe<QaQualification_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "firebaseUid" */
  UserFirebaseUidKey = 'user_firebaseUid_key',
  /** unique or primary key constraint on columns "firestoreId" */
  UserFirestoreIdKey = 'user_firestoreId_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  jobChangeFilters?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  jobChangeFilters?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  jobChangeFilters?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  clientId?: InputMaybe<Scalars['uuid']>;
  clientPermission?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firestoreClientId?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobChangeFilters?: InputMaybe<Scalars['jsonb']>;
  lastName?: InputMaybe<Scalars['String']>;
  qaQualifications?: InputMaybe<QaQualification_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  clientPermission?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firestoreClientId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  clientId?: InputMaybe<Order_By>;
  clientPermission?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebaseUid?: InputMaybe<Order_By>;
  firestoreClientId?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  clientPermission?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firestoreClientId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  clientId?: InputMaybe<Order_By>;
  clientPermission?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebaseUid?: InputMaybe<Order_By>;
  firestoreClientId?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  client?: InputMaybe<Client_Order_By>;
  clientId?: InputMaybe<Order_By>;
  clientPermission?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebaseUid?: InputMaybe<Order_By>;
  firestoreClientId?: InputMaybe<Order_By>;
  firestoreId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobChangeFilters?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  qaQualifications_aggregate?: InputMaybe<QaQualification_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  jobChangeFilters?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientPermission = 'clientPermission',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirestoreClientId = 'firestoreClientId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  JobChangeFilters = 'jobChangeFilters',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  clientPermission?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firestoreClientId?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobChangeFilters?: InputMaybe<Scalars['jsonb']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  clientId?: InputMaybe<Scalars['uuid']>;
  clientPermission?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firestoreClientId?: InputMaybe<Scalars['String']>;
  firestoreId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  jobChangeFilters?: InputMaybe<Scalars['jsonb']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientPermission = 'clientPermission',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirestoreClientId = 'firestoreClientId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  JobChangeFilters = 'jobChangeFilters',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type User_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "warmCompany" */
export type WarmCompany = {
  __typename?: 'warmCompany';
  clientContactCount?: Maybe<Scalars['bigint']>;
  clientId?: Maybe<Scalars['uuid']>;
  clientName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  companyLinkedinUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyPrimaryName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "warmCompany" */
export type WarmCompany_Aggregate = {
  __typename?: 'warmCompany_aggregate';
  aggregate?: Maybe<WarmCompany_Aggregate_Fields>;
  nodes: Array<WarmCompany>;
};

/** aggregate fields of "warmCompany" */
export type WarmCompany_Aggregate_Fields = {
  __typename?: 'warmCompany_aggregate_fields';
  avg?: Maybe<WarmCompany_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WarmCompany_Max_Fields>;
  min?: Maybe<WarmCompany_Min_Fields>;
  stddev?: Maybe<WarmCompany_Stddev_Fields>;
  stddev_pop?: Maybe<WarmCompany_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WarmCompany_Stddev_Samp_Fields>;
  sum?: Maybe<WarmCompany_Sum_Fields>;
  var_pop?: Maybe<WarmCompany_Var_Pop_Fields>;
  var_samp?: Maybe<WarmCompany_Var_Samp_Fields>;
  variance?: Maybe<WarmCompany_Variance_Fields>;
};

/** aggregate fields of "warmCompany" */
export type WarmCompany_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WarmCompany_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WarmCompany_Avg_Fields = {
  __typename?: 'warmCompany_avg_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "warmCompany". All fields are combined with a logical 'AND'. */
export type WarmCompany_Bool_Exp = {
  _and?: InputMaybe<Array<WarmCompany_Bool_Exp>>;
  _not?: InputMaybe<WarmCompany_Bool_Exp>;
  _or?: InputMaybe<Array<WarmCompany_Bool_Exp>>;
  clientContactCount?: InputMaybe<Bigint_Comparison_Exp>;
  clientId?: InputMaybe<Uuid_Comparison_Exp>;
  clientName?: InputMaybe<String_Comparison_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  companyLinkedinUrl?: InputMaybe<String_Comparison_Exp>;
  companyLogoUrl?: InputMaybe<String_Comparison_Exp>;
  companyPrimaryName?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type WarmCompany_Max_Fields = {
  __typename?: 'warmCompany_max_fields';
  clientContactCount?: Maybe<Scalars['bigint']>;
  clientId?: Maybe<Scalars['uuid']>;
  clientName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  companyLinkedinUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyPrimaryName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WarmCompany_Min_Fields = {
  __typename?: 'warmCompany_min_fields';
  clientContactCount?: Maybe<Scalars['bigint']>;
  clientId?: Maybe<Scalars['uuid']>;
  clientName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  companyLinkedinUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyPrimaryName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "warmCompany". */
export type WarmCompany_Order_By = {
  clientContactCount?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  clientName?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  companyLinkedinUrl?: InputMaybe<Order_By>;
  companyLogoUrl?: InputMaybe<Order_By>;
  companyPrimaryName?: InputMaybe<Order_By>;
};

/** select columns of table "warmCompany" */
export enum WarmCompany_Select_Column {
  /** column name */
  ClientContactCount = 'clientContactCount',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientName = 'clientName',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CompanyLinkedinUrl = 'companyLinkedinUrl',
  /** column name */
  CompanyLogoUrl = 'companyLogoUrl',
  /** column name */
  CompanyPrimaryName = 'companyPrimaryName',
}

/** aggregate stddev on columns */
export type WarmCompany_Stddev_Fields = {
  __typename?: 'warmCompany_stddev_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WarmCompany_Stddev_Pop_Fields = {
  __typename?: 'warmCompany_stddev_pop_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WarmCompany_Stddev_Samp_Fields = {
  __typename?: 'warmCompany_stddev_samp_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "warmCompany" */
export type WarmCompany_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WarmCompany_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WarmCompany_Stream_Cursor_Value_Input = {
  clientContactCount?: InputMaybe<Scalars['bigint']>;
  clientId?: InputMaybe<Scalars['uuid']>;
  clientName?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  companyLinkedinUrl?: InputMaybe<Scalars['String']>;
  companyLogoUrl?: InputMaybe<Scalars['String']>;
  companyPrimaryName?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type WarmCompany_Sum_Fields = {
  __typename?: 'warmCompany_sum_fields';
  clientContactCount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type WarmCompany_Var_Pop_Fields = {
  __typename?: 'warmCompany_var_pop_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WarmCompany_Var_Samp_Fields = {
  __typename?: 'warmCompany_var_samp_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WarmCompany_Variance_Fields = {
  __typename?: 'warmCompany_variance_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "workOSConnection" */
export type WorkOsConnection = {
  __typename?: 'workOSConnection';
  connectionId: Scalars['String'];
  connectionType: Scalars['String'];
  domains: Scalars['jsonb'];
  integrationType: Scalars['String'];
  name: Scalars['String'];
};

/** columns and relationships of "workOSConnection" */
export type WorkOsConnectionDomainsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "workOSConnection" */
export type WorkOsConnection_Aggregate = {
  __typename?: 'workOSConnection_aggregate';
  aggregate?: Maybe<WorkOsConnection_Aggregate_Fields>;
  nodes: Array<WorkOsConnection>;
};

/** aggregate fields of "workOSConnection" */
export type WorkOsConnection_Aggregate_Fields = {
  __typename?: 'workOSConnection_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WorkOsConnection_Max_Fields>;
  min?: Maybe<WorkOsConnection_Min_Fields>;
};

/** aggregate fields of "workOSConnection" */
export type WorkOsConnection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WorkOsConnection_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WorkOsConnection_Append_Input = {
  domains?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "workOSConnection". All fields are combined with a logical 'AND'. */
export type WorkOsConnection_Bool_Exp = {
  _and?: InputMaybe<Array<WorkOsConnection_Bool_Exp>>;
  _not?: InputMaybe<WorkOsConnection_Bool_Exp>;
  _or?: InputMaybe<Array<WorkOsConnection_Bool_Exp>>;
  connectionId?: InputMaybe<String_Comparison_Exp>;
  connectionType?: InputMaybe<String_Comparison_Exp>;
  domains?: InputMaybe<Jsonb_Comparison_Exp>;
  integrationType?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "workOSConnection" */
export enum WorkOsConnection_Constraint {
  /** unique or primary key constraint on columns "connectionId" */
  WorkOsConnectionPkey = 'workOSConnection_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WorkOsConnection_Delete_At_Path_Input = {
  domains?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WorkOsConnection_Delete_Elem_Input = {
  domains?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WorkOsConnection_Delete_Key_Input = {
  domains?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "workOSConnection" */
export type WorkOsConnection_Insert_Input = {
  connectionId?: InputMaybe<Scalars['String']>;
  connectionType?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Scalars['jsonb']>;
  integrationType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WorkOsConnection_Max_Fields = {
  __typename?: 'workOSConnection_max_fields';
  connectionId?: Maybe<Scalars['String']>;
  connectionType?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WorkOsConnection_Min_Fields = {
  __typename?: 'workOSConnection_min_fields';
  connectionId?: Maybe<Scalars['String']>;
  connectionType?: Maybe<Scalars['String']>;
  integrationType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "workOSConnection" */
export type WorkOsConnection_Mutation_Response = {
  __typename?: 'workOSConnection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WorkOsConnection>;
};

/** on_conflict condition type for table "workOSConnection" */
export type WorkOsConnection_On_Conflict = {
  constraint: WorkOsConnection_Constraint;
  update_columns?: Array<WorkOsConnection_Update_Column>;
  where?: InputMaybe<WorkOsConnection_Bool_Exp>;
};

/** Ordering options when selecting data from "workOSConnection". */
export type WorkOsConnection_Order_By = {
  connectionId?: InputMaybe<Order_By>;
  connectionType?: InputMaybe<Order_By>;
  domains?: InputMaybe<Order_By>;
  integrationType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workOSConnection */
export type WorkOsConnection_Pk_Columns_Input = {
  connectionId: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WorkOsConnection_Prepend_Input = {
  domains?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "workOSConnection" */
export enum WorkOsConnection_Select_Column {
  /** column name */
  ConnectionId = 'connectionId',
  /** column name */
  ConnectionType = 'connectionType',
  /** column name */
  Domains = 'domains',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "workOSConnection" */
export type WorkOsConnection_Set_Input = {
  connectionId?: InputMaybe<Scalars['String']>;
  connectionType?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Scalars['jsonb']>;
  integrationType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "workOSConnection" */
export type WorkOsConnection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WorkOsConnection_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkOsConnection_Stream_Cursor_Value_Input = {
  connectionId?: InputMaybe<Scalars['String']>;
  connectionType?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Scalars['jsonb']>;
  integrationType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "workOSConnection" */
export enum WorkOsConnection_Update_Column {
  /** column name */
  ConnectionId = 'connectionId',
  /** column name */
  ConnectionType = 'connectionType',
  /** column name */
  Domains = 'domains',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  Name = 'name',
}

export type WorkOsConnection_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WorkOsConnection_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WorkOsConnection_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WorkOsConnection_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WorkOsConnection_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WorkOsConnection_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkOsConnection_Set_Input>;
  /** filter the rows which have to be updated */
  where: WorkOsConnection_Bool_Exp;
};

/** columns and relationships of "workOSDirectory" */
export type WorkOsDirectory = {
  __typename?: 'workOSDirectory';
  directoryId: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  rawJson?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "workOSDirectory" */
export type WorkOsDirectoryRawJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "workOSDirectory" */
export type WorkOsDirectory_Aggregate = {
  __typename?: 'workOSDirectory_aggregate';
  aggregate?: Maybe<WorkOsDirectory_Aggregate_Fields>;
  nodes: Array<WorkOsDirectory>;
};

/** aggregate fields of "workOSDirectory" */
export type WorkOsDirectory_Aggregate_Fields = {
  __typename?: 'workOSDirectory_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WorkOsDirectory_Max_Fields>;
  min?: Maybe<WorkOsDirectory_Min_Fields>;
};

/** aggregate fields of "workOSDirectory" */
export type WorkOsDirectory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WorkOsDirectory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WorkOsDirectory_Append_Input = {
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "workOSDirectory". All fields are combined with a logical 'AND'. */
export type WorkOsDirectory_Bool_Exp = {
  _and?: InputMaybe<Array<WorkOsDirectory_Bool_Exp>>;
  _not?: InputMaybe<WorkOsDirectory_Bool_Exp>;
  _or?: InputMaybe<Array<WorkOsDirectory_Bool_Exp>>;
  directoryId?: InputMaybe<String_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  rawJson?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "workOSDirectory" */
export enum WorkOsDirectory_Constraint {
  /** unique or primary key constraint on columns "directoryId" */
  WorkOsDirectoryPkey = 'workOSDirectory_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WorkOsDirectory_Delete_At_Path_Input = {
  rawJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WorkOsDirectory_Delete_Elem_Input = {
  rawJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WorkOsDirectory_Delete_Key_Input = {
  rawJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "workOSDirectory" */
export type WorkOsDirectory_Insert_Input = {
  directoryId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type WorkOsDirectory_Max_Fields = {
  __typename?: 'workOSDirectory_max_fields';
  directoryId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WorkOsDirectory_Min_Fields = {
  __typename?: 'workOSDirectory_min_fields';
  directoryId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "workOSDirectory" */
export type WorkOsDirectory_Mutation_Response = {
  __typename?: 'workOSDirectory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WorkOsDirectory>;
};

/** on_conflict condition type for table "workOSDirectory" */
export type WorkOsDirectory_On_Conflict = {
  constraint: WorkOsDirectory_Constraint;
  update_columns?: Array<WorkOsDirectory_Update_Column>;
  where?: InputMaybe<WorkOsDirectory_Bool_Exp>;
};

/** Ordering options when selecting data from "workOSDirectory". */
export type WorkOsDirectory_Order_By = {
  directoryId?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  rawJson?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workOSDirectory */
export type WorkOsDirectory_Pk_Columns_Input = {
  directoryId: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WorkOsDirectory_Prepend_Input = {
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "workOSDirectory" */
export enum WorkOsDirectory_Select_Column {
  /** column name */
  DirectoryId = 'directoryId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  RawJson = 'rawJson',
}

/** input type for updating data in table "workOSDirectory" */
export type WorkOsDirectory_Set_Input = {
  directoryId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "workOSDirectory" */
export type WorkOsDirectory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WorkOsDirectory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkOsDirectory_Stream_Cursor_Value_Input = {
  directoryId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "workOSDirectory" */
export enum WorkOsDirectory_Update_Column {
  /** column name */
  DirectoryId = 'directoryId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  RawJson = 'rawJson',
}

export type WorkOsDirectory_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WorkOsDirectory_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WorkOsDirectory_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WorkOsDirectory_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WorkOsDirectory_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WorkOsDirectory_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkOsDirectory_Set_Input>;
  /** filter the rows which have to be updated */
  where: WorkOsDirectory_Bool_Exp;
};

/** columns and relationships of "workOSGroup" */
export type WorkOsGroup = {
  __typename?: 'workOSGroup';
  groupId: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  rawJson?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "workOSGroup" */
export type WorkOsGroupRawJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "workOSGroup" */
export type WorkOsGroup_Aggregate = {
  __typename?: 'workOSGroup_aggregate';
  aggregate?: Maybe<WorkOsGroup_Aggregate_Fields>;
  nodes: Array<WorkOsGroup>;
};

/** aggregate fields of "workOSGroup" */
export type WorkOsGroup_Aggregate_Fields = {
  __typename?: 'workOSGroup_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WorkOsGroup_Max_Fields>;
  min?: Maybe<WorkOsGroup_Min_Fields>;
};

/** aggregate fields of "workOSGroup" */
export type WorkOsGroup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WorkOsGroup_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WorkOsGroup_Append_Input = {
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "workOSGroup". All fields are combined with a logical 'AND'. */
export type WorkOsGroup_Bool_Exp = {
  _and?: InputMaybe<Array<WorkOsGroup_Bool_Exp>>;
  _not?: InputMaybe<WorkOsGroup_Bool_Exp>;
  _or?: InputMaybe<Array<WorkOsGroup_Bool_Exp>>;
  groupId?: InputMaybe<String_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  rawJson?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "workOSGroup" */
export enum WorkOsGroup_Constraint {
  /** unique or primary key constraint on columns "groupId" */
  WorkOsGroupPkey = 'workOSGroup_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WorkOsGroup_Delete_At_Path_Input = {
  rawJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WorkOsGroup_Delete_Elem_Input = {
  rawJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WorkOsGroup_Delete_Key_Input = {
  rawJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "workOSGroup" */
export type WorkOsGroup_Insert_Input = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type WorkOsGroup_Max_Fields = {
  __typename?: 'workOSGroup_max_fields';
  groupId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WorkOsGroup_Min_Fields = {
  __typename?: 'workOSGroup_min_fields';
  groupId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "workOSGroup" */
export type WorkOsGroup_Mutation_Response = {
  __typename?: 'workOSGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WorkOsGroup>;
};

/** on_conflict condition type for table "workOSGroup" */
export type WorkOsGroup_On_Conflict = {
  constraint: WorkOsGroup_Constraint;
  update_columns?: Array<WorkOsGroup_Update_Column>;
  where?: InputMaybe<WorkOsGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "workOSGroup". */
export type WorkOsGroup_Order_By = {
  groupId?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  rawJson?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workOSGroup */
export type WorkOsGroup_Pk_Columns_Input = {
  groupId: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WorkOsGroup_Prepend_Input = {
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "workOSGroup" */
export enum WorkOsGroup_Select_Column {
  /** column name */
  GroupId = 'groupId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  RawJson = 'rawJson',
}

/** input type for updating data in table "workOSGroup" */
export type WorkOsGroup_Set_Input = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "workOSGroup" */
export type WorkOsGroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WorkOsGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkOsGroup_Stream_Cursor_Value_Input = {
  groupId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "workOSGroup" */
export enum WorkOsGroup_Update_Column {
  /** column name */
  GroupId = 'groupId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  RawJson = 'rawJson',
}

export type WorkOsGroup_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WorkOsGroup_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WorkOsGroup_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WorkOsGroup_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WorkOsGroup_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WorkOsGroup_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkOsGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: WorkOsGroup_Bool_Exp;
};

/** columns and relationships of "workOSUser" */
export type WorkOsUser = {
  __typename?: 'workOSUser';
  directoryId?: Maybe<Scalars['String']>;
  rawJson?: Maybe<Scalars['jsonb']>;
  userId: Scalars['String'];
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "workOSUser" */
export type WorkOsUserRawJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "workOSUser" */
export type WorkOsUser_Aggregate = {
  __typename?: 'workOSUser_aggregate';
  aggregate?: Maybe<WorkOsUser_Aggregate_Fields>;
  nodes: Array<WorkOsUser>;
};

/** aggregate fields of "workOSUser" */
export type WorkOsUser_Aggregate_Fields = {
  __typename?: 'workOSUser_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WorkOsUser_Max_Fields>;
  min?: Maybe<WorkOsUser_Min_Fields>;
};

/** aggregate fields of "workOSUser" */
export type WorkOsUser_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WorkOsUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WorkOsUser_Append_Input = {
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "workOSUser". All fields are combined with a logical 'AND'. */
export type WorkOsUser_Bool_Exp = {
  _and?: InputMaybe<Array<WorkOsUser_Bool_Exp>>;
  _not?: InputMaybe<WorkOsUser_Bool_Exp>;
  _or?: InputMaybe<Array<WorkOsUser_Bool_Exp>>;
  directoryId?: InputMaybe<String_Comparison_Exp>;
  rawJson?: InputMaybe<Jsonb_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "workOSUser" */
export enum WorkOsUser_Constraint {
  /** unique or primary key constraint on columns "userId" */
  WorkOsUserPkey = 'workOSUser_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WorkOsUser_Delete_At_Path_Input = {
  rawJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WorkOsUser_Delete_Elem_Input = {
  rawJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WorkOsUser_Delete_Key_Input = {
  rawJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "workOSUser" */
export type WorkOsUser_Insert_Input = {
  directoryId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  userId?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type WorkOsUser_Max_Fields = {
  __typename?: 'workOSUser_max_fields';
  directoryId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type WorkOsUser_Min_Fields = {
  __typename?: 'workOSUser_min_fields';
  directoryId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "workOSUser" */
export type WorkOsUser_Mutation_Response = {
  __typename?: 'workOSUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WorkOsUser>;
};

/** on_conflict condition type for table "workOSUser" */
export type WorkOsUser_On_Conflict = {
  constraint: WorkOsUser_Constraint;
  update_columns?: Array<WorkOsUser_Update_Column>;
  where?: InputMaybe<WorkOsUser_Bool_Exp>;
};

/** Ordering options when selecting data from "workOSUser". */
export type WorkOsUser_Order_By = {
  directoryId?: InputMaybe<Order_By>;
  rawJson?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workOSUser */
export type WorkOsUser_Pk_Columns_Input = {
  userId: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WorkOsUser_Prepend_Input = {
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "workOSUser" */
export enum WorkOsUser_Select_Column {
  /** column name */
  DirectoryId = 'directoryId',
  /** column name */
  RawJson = 'rawJson',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "workOSUser" */
export type WorkOsUser_Set_Input = {
  directoryId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  userId?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "workOSUser" */
export type WorkOsUser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WorkOsUser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkOsUser_Stream_Cursor_Value_Input = {
  directoryId?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  userId?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "workOSUser" */
export enum WorkOsUser_Update_Column {
  /** column name */
  DirectoryId = 'directoryId',
  /** column name */
  RawJson = 'rawJson',
  /** column name */
  UserId = 'userId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type WorkOsUser_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WorkOsUser_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WorkOsUser_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WorkOsUser_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WorkOsUser_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WorkOsUser_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkOsUser_Set_Input>;
  /** filter the rows which have to be updated */
  where: WorkOsUser_Bool_Exp;
};

/** columns and relationships of "zoomApprovedDomain" */
export type ZoomApprovedDomain = {
  __typename?: 'zoomApprovedDomain';
  approvedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  domain: Scalars['String'];
  firstApprovedZoomContactId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  firstApprovedZoomUser?: Maybe<ZoomUser>;
  firstApprovedZoomUserId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  firstAttemptedZoomUser?: Maybe<ZoomUser>;
  firstAttemptedZoomUserId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  status: Scalars['String'];
  /** An object relationship */
  zoomCompany?: Maybe<ZoomCompany>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  zoomContact?: Maybe<ZoomContact>;
};

/** aggregated selection of "zoomApprovedDomain" */
export type ZoomApprovedDomain_Aggregate = {
  __typename?: 'zoomApprovedDomain_aggregate';
  aggregate?: Maybe<ZoomApprovedDomain_Aggregate_Fields>;
  nodes: Array<ZoomApprovedDomain>;
};

/** aggregate fields of "zoomApprovedDomain" */
export type ZoomApprovedDomain_Aggregate_Fields = {
  __typename?: 'zoomApprovedDomain_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomApprovedDomain_Max_Fields>;
  min?: Maybe<ZoomApprovedDomain_Min_Fields>;
};

/** aggregate fields of "zoomApprovedDomain" */
export type ZoomApprovedDomain_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomApprovedDomain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zoomApprovedDomain". All fields are combined with a logical 'AND'. */
export type ZoomApprovedDomain_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomApprovedDomain_Bool_Exp>>;
  _not?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomApprovedDomain_Bool_Exp>>;
  approvedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  firstApprovedZoomContactId?: InputMaybe<Uuid_Comparison_Exp>;
  firstApprovedZoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
  firstApprovedZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  firstAttemptedZoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
  firstAttemptedZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  zoomCompany?: InputMaybe<ZoomCompany_Bool_Exp>;
  zoomCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomContact?: InputMaybe<ZoomContact_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomApprovedDomain" */
export enum ZoomApprovedDomain_Constraint {
  /** unique or primary key constraint on columns "domain" */
  ZoomApprovedDomainDomainKey = 'zoomApprovedDomain_domain_key',
  /** unique or primary key constraint on columns "id" */
  ZoomApprovedDomainPkey = 'zoomApprovedDomain_pkey',
}

/** input type for inserting data into table "zoomApprovedDomain" */
export type ZoomApprovedDomain_Insert_Input = {
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  firstApprovedZoomContactId?: InputMaybe<Scalars['uuid']>;
  firstApprovedZoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  firstApprovedZoomUserId?: InputMaybe<Scalars['uuid']>;
  firstAttemptedZoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  firstAttemptedZoomUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  zoomCompany?: InputMaybe<ZoomCompany_Obj_Rel_Insert_Input>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
  zoomContact?: InputMaybe<ZoomContact_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomApprovedDomain_Max_Fields = {
  __typename?: 'zoomApprovedDomain_max_fields';
  approvedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  firstApprovedZoomContactId?: Maybe<Scalars['uuid']>;
  firstApprovedZoomUserId?: Maybe<Scalars['uuid']>;
  firstAttemptedZoomUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomApprovedDomain_Min_Fields = {
  __typename?: 'zoomApprovedDomain_min_fields';
  approvedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  firstApprovedZoomContactId?: Maybe<Scalars['uuid']>;
  firstApprovedZoomUserId?: Maybe<Scalars['uuid']>;
  firstAttemptedZoomUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomApprovedDomain" */
export type ZoomApprovedDomain_Mutation_Response = {
  __typename?: 'zoomApprovedDomain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomApprovedDomain>;
};

/** on_conflict condition type for table "zoomApprovedDomain" */
export type ZoomApprovedDomain_On_Conflict = {
  constraint: ZoomApprovedDomain_Constraint;
  update_columns?: Array<ZoomApprovedDomain_Update_Column>;
  where?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomApprovedDomain". */
export type ZoomApprovedDomain_Order_By = {
  approvedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  firstApprovedZoomContactId?: InputMaybe<Order_By>;
  firstApprovedZoomUser?: InputMaybe<ZoomUser_Order_By>;
  firstApprovedZoomUserId?: InputMaybe<Order_By>;
  firstAttemptedZoomUser?: InputMaybe<ZoomUser_Order_By>;
  firstAttemptedZoomUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  zoomCompany?: InputMaybe<ZoomCompany_Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
  zoomContact?: InputMaybe<ZoomContact_Order_By>;
};

/** primary key columns input for table: zoomApprovedDomain */
export type ZoomApprovedDomain_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomApprovedDomain" */
export enum ZoomApprovedDomain_Select_Column {
  /** column name */
  ApprovedAt = 'approvedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  FirstApprovedZoomContactId = 'firstApprovedZoomContactId',
  /** column name */
  FirstApprovedZoomUserId = 'firstApprovedZoomUserId',
  /** column name */
  FirstAttemptedZoomUserId = 'firstAttemptedZoomUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

/** input type for updating data in table "zoomApprovedDomain" */
export type ZoomApprovedDomain_Set_Input = {
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  firstApprovedZoomContactId?: InputMaybe<Scalars['uuid']>;
  firstApprovedZoomUserId?: InputMaybe<Scalars['uuid']>;
  firstAttemptedZoomUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomApprovedDomain" */
export type ZoomApprovedDomain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomApprovedDomain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomApprovedDomain_Stream_Cursor_Value_Input = {
  approvedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  firstApprovedZoomContactId?: InputMaybe<Scalars['uuid']>;
  firstApprovedZoomUserId?: InputMaybe<Scalars['uuid']>;
  firstAttemptedZoomUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomApprovedDomain" */
export enum ZoomApprovedDomain_Update_Column {
  /** column name */
  ApprovedAt = 'approvedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  FirstApprovedZoomContactId = 'firstApprovedZoomContactId',
  /** column name */
  FirstApprovedZoomUserId = 'firstApprovedZoomUserId',
  /** column name */
  FirstAttemptedZoomUserId = 'firstAttemptedZoomUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

export type ZoomApprovedDomain_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomApprovedDomain_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomApprovedDomain_Bool_Exp;
};

/** columns and relationships of "zoomCompany" */
export type ZoomCompany = {
  __typename?: 'zoomCompany';
  alexaRanking?: Maybe<Scalars['String']>;
  allowEnrichment: Scalars['Boolean'];
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  estimatedSize?: Maybe<Scalars['String']>;
  estimatedSizeUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "company_has_been_merged" */
  hasBeenMerged?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  rawJson?: Maybe<Scalars['jsonb']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
  /** An array relationship */
  zoomContacts: Array<ZoomContact>;
  /** An aggregate relationship */
  zoomContacts_aggregate: ZoomContact_Aggregate;
  /** An array relationship */
  zoomUsers: Array<ZoomUser>;
  /** An aggregate relationship */
  zoomUsers_aggregate: ZoomUser_Aggregate;
};

/** columns and relationships of "zoomCompany" */
export type ZoomCompanyRawJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomCompany" */
export type ZoomCompanyZoomContactsArgs = {
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By>>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
};

/** columns and relationships of "zoomCompany" */
export type ZoomCompanyZoomContacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By>>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
};

/** columns and relationships of "zoomCompany" */
export type ZoomCompanyZoomUsersArgs = {
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By>>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
};

/** columns and relationships of "zoomCompany" */
export type ZoomCompanyZoomUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By>>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
};

/** columns and relationships of "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult = {
  __typename?: 'zoomCompanyMergeResult';
  createdAt: Scalars['timestamptz'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  zoomCompanyIdToMerge: Scalars['uuid'];
  zoomCompanyIdToMergeInto: Scalars['uuid'];
  /** An object relationship */
  zoomCompanyToMerge: ZoomCompany;
  /** An object relationship */
  zoomCompanyToMergeInto: ZoomCompany;
};

/** aggregated selection of "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult_Aggregate = {
  __typename?: 'zoomCompanyMergeResult_aggregate';
  aggregate?: Maybe<ZoomCompanyMergeResult_Aggregate_Fields>;
  nodes: Array<ZoomCompanyMergeResult>;
};

/** aggregate fields of "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult_Aggregate_Fields = {
  __typename?: 'zoomCompanyMergeResult_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomCompanyMergeResult_Max_Fields>;
  min?: Maybe<ZoomCompanyMergeResult_Min_Fields>;
};

/** aggregate fields of "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomCompanyMergeResult_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zoomCompanyMergeResult". All fields are combined with a logical 'AND'. */
export type ZoomCompanyMergeResult_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomCompanyMergeResult_Bool_Exp>>;
  _not?: InputMaybe<ZoomCompanyMergeResult_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomCompanyMergeResult_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomCompanyIdToMerge?: InputMaybe<Uuid_Comparison_Exp>;
  zoomCompanyIdToMergeInto?: InputMaybe<Uuid_Comparison_Exp>;
  zoomCompanyToMerge?: InputMaybe<ZoomCompany_Bool_Exp>;
  zoomCompanyToMergeInto?: InputMaybe<ZoomCompany_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomCompanyMergeResult" */
export enum ZoomCompanyMergeResult_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomCompanyMergeResultPkey = 'zoomCompanyMergeResult_pkey',
}

/** input type for inserting data into table "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompanyIdToMerge?: InputMaybe<Scalars['uuid']>;
  zoomCompanyIdToMergeInto?: InputMaybe<Scalars['uuid']>;
  zoomCompanyToMerge?: InputMaybe<ZoomCompany_Obj_Rel_Insert_Input>;
  zoomCompanyToMergeInto?: InputMaybe<ZoomCompany_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomCompanyMergeResult_Max_Fields = {
  __typename?: 'zoomCompanyMergeResult_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyIdToMerge?: Maybe<Scalars['uuid']>;
  zoomCompanyIdToMergeInto?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomCompanyMergeResult_Min_Fields = {
  __typename?: 'zoomCompanyMergeResult_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyIdToMerge?: Maybe<Scalars['uuid']>;
  zoomCompanyIdToMergeInto?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult_Mutation_Response = {
  __typename?: 'zoomCompanyMergeResult_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomCompanyMergeResult>;
};

/** on_conflict condition type for table "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult_On_Conflict = {
  constraint: ZoomCompanyMergeResult_Constraint;
  update_columns?: Array<ZoomCompanyMergeResult_Update_Column>;
  where?: InputMaybe<ZoomCompanyMergeResult_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomCompanyMergeResult". */
export type ZoomCompanyMergeResult_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomCompanyIdToMerge?: InputMaybe<Order_By>;
  zoomCompanyIdToMergeInto?: InputMaybe<Order_By>;
  zoomCompanyToMerge?: InputMaybe<ZoomCompany_Order_By>;
  zoomCompanyToMergeInto?: InputMaybe<ZoomCompany_Order_By>;
};

/** primary key columns input for table: zoomCompanyMergeResult */
export type ZoomCompanyMergeResult_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomCompanyMergeResult" */
export enum ZoomCompanyMergeResult_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomCompanyIdToMerge = 'zoomCompanyIdToMerge',
  /** column name */
  ZoomCompanyIdToMergeInto = 'zoomCompanyIdToMergeInto',
}

/** input type for updating data in table "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompanyIdToMerge?: InputMaybe<Scalars['uuid']>;
  zoomCompanyIdToMergeInto?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomCompanyMergeResult" */
export type ZoomCompanyMergeResult_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomCompanyMergeResult_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomCompanyMergeResult_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompanyIdToMerge?: InputMaybe<Scalars['uuid']>;
  zoomCompanyIdToMergeInto?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomCompanyMergeResult" */
export enum ZoomCompanyMergeResult_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomCompanyIdToMerge = 'zoomCompanyIdToMerge',
  /** column name */
  ZoomCompanyIdToMergeInto = 'zoomCompanyIdToMergeInto',
}

export type ZoomCompanyMergeResult_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomCompanyMergeResult_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomCompanyMergeResult_Bool_Exp;
};

/** aggregated selection of "zoomCompany" */
export type ZoomCompany_Aggregate = {
  __typename?: 'zoomCompany_aggregate';
  aggregate?: Maybe<ZoomCompany_Aggregate_Fields>;
  nodes: Array<ZoomCompany>;
};

/** aggregate fields of "zoomCompany" */
export type ZoomCompany_Aggregate_Fields = {
  __typename?: 'zoomCompany_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomCompany_Max_Fields>;
  min?: Maybe<ZoomCompany_Min_Fields>;
};

/** aggregate fields of "zoomCompany" */
export type ZoomCompany_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomCompany_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomCompany_Append_Input = {
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "zoomCompany". All fields are combined with a logical 'AND'. */
export type ZoomCompany_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomCompany_Bool_Exp>>;
  _not?: InputMaybe<ZoomCompany_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomCompany_Bool_Exp>>;
  alexaRanking?: InputMaybe<String_Comparison_Exp>;
  allowEnrichment?: InputMaybe<Boolean_Comparison_Exp>;
  apolloOrganizationId?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  clearbitCompanyId?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  crunchbaseUrl?: InputMaybe<String_Comparison_Exp>;
  dataVendorName?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  descriptionUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  estimatedSize?: InputMaybe<String_Comparison_Exp>;
  estimatedSizeUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  hasBeenMerged?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  keywords?: InputMaybe<String_Comparison_Exp>;
  latestFundingRoundDate?: InputMaybe<String_Comparison_Exp>;
  latestFundingStage?: InputMaybe<String_Comparison_Exp>;
  linkedInUid?: InputMaybe<String_Comparison_Exp>;
  linkedInUrl?: InputMaybe<String_Comparison_Exp>;
  logoUrl?: InputMaybe<String_Comparison_Exp>;
  logoUrlUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nameUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  rawJson?: InputMaybe<Jsonb_Comparison_Exp>;
  seoDescription?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  technologyNames?: InputMaybe<String_Comparison_Exp>;
  totalFunding?: InputMaybe<String_Comparison_Exp>;
  totalFundingPrinted?: InputMaybe<String_Comparison_Exp>;
  twitterUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  websiteDomain?: InputMaybe<String_Comparison_Exp>;
  websiteDomainUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  websiteUrl?: InputMaybe<String_Comparison_Exp>;
  yearFounded?: InputMaybe<String_Comparison_Exp>;
  zoomContacts?: InputMaybe<ZoomContact_Bool_Exp>;
  zoomContacts_aggregate?: InputMaybe<ZoomContact_Aggregate_Bool_Exp>;
  zoomUsers?: InputMaybe<ZoomUser_Bool_Exp>;
  zoomUsers_aggregate?: InputMaybe<ZoomUser_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomCompany" */
export enum ZoomCompany_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomCompanyPkey = 'zoomCompany_pkey',
  /** unique or primary key constraint on columns "websiteDomain" */
  ZoomCompanyWebsiteDomainKey = 'zoomCompany_websiteDomain_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomCompany_Delete_At_Path_Input = {
  rawJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomCompany_Delete_Elem_Input = {
  rawJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomCompany_Delete_Key_Input = {
  rawJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "zoomCompany" */
export type ZoomCompany_Insert_Input = {
  alexaRanking?: InputMaybe<Scalars['String']>;
  allowEnrichment?: InputMaybe<Scalars['Boolean']>;
  apolloOrganizationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clearbitCompanyId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crunchbaseUrl?: InputMaybe<Scalars['String']>;
  dataVendorName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  estimatedSizeUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  latestFundingRoundDate?: InputMaybe<Scalars['String']>;
  latestFundingStage?: InputMaybe<Scalars['String']>;
  linkedInUid?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  technologyNames?: InputMaybe<Scalars['String']>;
  totalFunding?: InputMaybe<Scalars['String']>;
  totalFundingPrinted?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  yearFounded?: InputMaybe<Scalars['String']>;
  zoomContacts?: InputMaybe<ZoomContact_Arr_Rel_Insert_Input>;
  zoomUsers?: InputMaybe<ZoomUser_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomCompany_Max_Fields = {
  __typename?: 'zoomCompany_max_fields';
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  estimatedSize?: Maybe<Scalars['String']>;
  estimatedSizeUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ZoomCompany_Min_Fields = {
  __typename?: 'zoomCompany_min_fields';
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  estimatedSize?: Maybe<Scalars['String']>;
  estimatedSizeUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zoomCompany" */
export type ZoomCompany_Mutation_Response = {
  __typename?: 'zoomCompany_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomCompany>;
};

/** input type for inserting object relation for remote table "zoomCompany" */
export type ZoomCompany_Obj_Rel_Insert_Input = {
  data: ZoomCompany_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomCompany_On_Conflict>;
};

/** on_conflict condition type for table "zoomCompany" */
export type ZoomCompany_On_Conflict = {
  constraint: ZoomCompany_Constraint;
  update_columns?: Array<ZoomCompany_Update_Column>;
  where?: InputMaybe<ZoomCompany_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomCompany". */
export type ZoomCompany_Order_By = {
  alexaRanking?: InputMaybe<Order_By>;
  allowEnrichment?: InputMaybe<Order_By>;
  apolloOrganizationId?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clearbitCompanyId?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crunchbaseUrl?: InputMaybe<Order_By>;
  dataVendorName?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  descriptionUpdatedByZoomUserId?: InputMaybe<Order_By>;
  estimatedSize?: InputMaybe<Order_By>;
  estimatedSizeUpdatedByZoomUserId?: InputMaybe<Order_By>;
  hasBeenMerged?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  keywords?: InputMaybe<Order_By>;
  latestFundingRoundDate?: InputMaybe<Order_By>;
  latestFundingStage?: InputMaybe<Order_By>;
  linkedInUid?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  logoUrlUpdatedByZoomUserId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nameUpdatedByZoomUserId?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  rawJson?: InputMaybe<Order_By>;
  seoDescription?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  technologyNames?: InputMaybe<Order_By>;
  totalFunding?: InputMaybe<Order_By>;
  totalFundingPrinted?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteDomain?: InputMaybe<Order_By>;
  websiteDomainUpdatedByZoomUserId?: InputMaybe<Order_By>;
  websiteUrl?: InputMaybe<Order_By>;
  yearFounded?: InputMaybe<Order_By>;
  zoomContacts_aggregate?: InputMaybe<ZoomContact_Aggregate_Order_By>;
  zoomUsers_aggregate?: InputMaybe<ZoomUser_Aggregate_Order_By>;
};

/** primary key columns input for table: zoomCompany */
export type ZoomCompany_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomCompany_Prepend_Input = {
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomCompany" */
export enum ZoomCompany_Select_Column {
  /** column name */
  AlexaRanking = 'alexaRanking',
  /** column name */
  AllowEnrichment = 'allowEnrichment',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitCompanyId = 'clearbitCompanyId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrunchbaseUrl = 'crunchbaseUrl',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionUpdatedByZoomUserId = 'descriptionUpdatedByZoomUserId',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  EstimatedSizeUpdatedByZoomUserId = 'estimatedSizeUpdatedByZoomUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  LatestFundingRoundDate = 'latestFundingRoundDate',
  /** column name */
  LatestFundingStage = 'latestFundingStage',
  /** column name */
  LinkedInUid = 'linkedInUid',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  LogoUrlUpdatedByZoomUserId = 'logoUrlUpdatedByZoomUserId',
  /** column name */
  Name = 'name',
  /** column name */
  NameUpdatedByZoomUserId = 'nameUpdatedByZoomUserId',
  /** column name */
  Phone = 'phone',
  /** column name */
  RawJson = 'rawJson',
  /** column name */
  SeoDescription = 'seoDescription',
  /** column name */
  State = 'state',
  /** column name */
  TechnologyNames = 'technologyNames',
  /** column name */
  TotalFunding = 'totalFunding',
  /** column name */
  TotalFundingPrinted = 'totalFundingPrinted',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteDomainUpdatedByZoomUserId = 'websiteDomainUpdatedByZoomUserId',
  /** column name */
  WebsiteUrl = 'websiteUrl',
  /** column name */
  YearFounded = 'yearFounded',
}

/** input type for updating data in table "zoomCompany" */
export type ZoomCompany_Set_Input = {
  alexaRanking?: InputMaybe<Scalars['String']>;
  allowEnrichment?: InputMaybe<Scalars['Boolean']>;
  apolloOrganizationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clearbitCompanyId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crunchbaseUrl?: InputMaybe<Scalars['String']>;
  dataVendorName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  estimatedSizeUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  latestFundingRoundDate?: InputMaybe<Scalars['String']>;
  latestFundingStage?: InputMaybe<Scalars['String']>;
  linkedInUid?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  technologyNames?: InputMaybe<Scalars['String']>;
  totalFunding?: InputMaybe<Scalars['String']>;
  totalFundingPrinted?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  yearFounded?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zoomCompany" */
export type ZoomCompany_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomCompany_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomCompany_Stream_Cursor_Value_Input = {
  alexaRanking?: InputMaybe<Scalars['String']>;
  allowEnrichment?: InputMaybe<Scalars['Boolean']>;
  apolloOrganizationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clearbitCompanyId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crunchbaseUrl?: InputMaybe<Scalars['String']>;
  dataVendorName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  estimatedSizeUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  latestFundingRoundDate?: InputMaybe<Scalars['String']>;
  latestFundingStage?: InputMaybe<Scalars['String']>;
  linkedInUid?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  technologyNames?: InputMaybe<Scalars['String']>;
  totalFunding?: InputMaybe<Scalars['String']>;
  totalFundingPrinted?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  yearFounded?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zoomCompany" */
export enum ZoomCompany_Update_Column {
  /** column name */
  AlexaRanking = 'alexaRanking',
  /** column name */
  AllowEnrichment = 'allowEnrichment',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitCompanyId = 'clearbitCompanyId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrunchbaseUrl = 'crunchbaseUrl',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionUpdatedByZoomUserId = 'descriptionUpdatedByZoomUserId',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  EstimatedSizeUpdatedByZoomUserId = 'estimatedSizeUpdatedByZoomUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  LatestFundingRoundDate = 'latestFundingRoundDate',
  /** column name */
  LatestFundingStage = 'latestFundingStage',
  /** column name */
  LinkedInUid = 'linkedInUid',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  LogoUrlUpdatedByZoomUserId = 'logoUrlUpdatedByZoomUserId',
  /** column name */
  Name = 'name',
  /** column name */
  NameUpdatedByZoomUserId = 'nameUpdatedByZoomUserId',
  /** column name */
  Phone = 'phone',
  /** column name */
  RawJson = 'rawJson',
  /** column name */
  SeoDescription = 'seoDescription',
  /** column name */
  State = 'state',
  /** column name */
  TechnologyNames = 'technologyNames',
  /** column name */
  TotalFunding = 'totalFunding',
  /** column name */
  TotalFundingPrinted = 'totalFundingPrinted',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteDomainUpdatedByZoomUserId = 'websiteDomainUpdatedByZoomUserId',
  /** column name */
  WebsiteUrl = 'websiteUrl',
  /** column name */
  YearFounded = 'yearFounded',
}

export type ZoomCompany_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomCompany_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomCompany_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomCompany_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomCompany_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomCompany_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomCompany_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomCompany_Bool_Exp;
};

/** columns and relationships of "zoomConnectionRequest" */
export type ZoomConnectionRequest = {
  __typename?: 'zoomConnectionRequest';
  email: Scalars['String'];
  id: Scalars['uuid'];
  requestStatus: Scalars['String'];
  zoomUserId: Scalars['uuid'];
};

/** columns and relationships of "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder = {
  __typename?: 'zoomConnectionRequestReminder';
  cadence: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  zoomConnectionRequestId: Scalars['uuid'];
  zoomUserId: Scalars['uuid'];
};

/** aggregated selection of "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_Aggregate = {
  __typename?: 'zoomConnectionRequestReminder_aggregate';
  aggregate?: Maybe<ZoomConnectionRequestReminder_Aggregate_Fields>;
  nodes: Array<ZoomConnectionRequestReminder>;
};

/** aggregate fields of "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_Aggregate_Fields = {
  __typename?: 'zoomConnectionRequestReminder_aggregate_fields';
  avg?: Maybe<ZoomConnectionRequestReminder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomConnectionRequestReminder_Max_Fields>;
  min?: Maybe<ZoomConnectionRequestReminder_Min_Fields>;
  stddev?: Maybe<ZoomConnectionRequestReminder_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomConnectionRequestReminder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomConnectionRequestReminder_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomConnectionRequestReminder_Sum_Fields>;
  var_pop?: Maybe<ZoomConnectionRequestReminder_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomConnectionRequestReminder_Var_Samp_Fields>;
  variance?: Maybe<ZoomConnectionRequestReminder_Variance_Fields>;
};

/** aggregate fields of "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomConnectionRequestReminder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZoomConnectionRequestReminder_Avg_Fields = {
  __typename?: 'zoomConnectionRequestReminder_avg_fields';
  cadence?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomConnectionRequestReminder". All fields are combined with a logical 'AND'. */
export type ZoomConnectionRequestReminder_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomConnectionRequestReminder_Bool_Exp>>;
  _not?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomConnectionRequestReminder_Bool_Exp>>;
  cadence?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  zoomConnectionRequestId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomConnectionRequestReminder" */
export enum ZoomConnectionRequestReminder_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomConnectionRequestReminderPkey = 'zoomConnectionRequestReminder_pkey',
  /** unique or primary key constraint on columns "zoomUserId", "zoomConnectionRequestId" */
  ZoomConnectionRequestReminderZoomUserIdZoomConnectionRequKey = 'zoomConnectionRequestReminder_zoomUserId_zoomConnectionRequ_key',
}

/** input type for incrementing numeric columns in table "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_Inc_Input = {
  cadence?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_Insert_Input = {
  cadence?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomConnectionRequestId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomConnectionRequestReminder_Max_Fields = {
  __typename?: 'zoomConnectionRequestReminder_max_fields';
  cadence?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  zoomConnectionRequestId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomConnectionRequestReminder_Min_Fields = {
  __typename?: 'zoomConnectionRequestReminder_min_fields';
  cadence?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  zoomConnectionRequestId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_Mutation_Response = {
  __typename?: 'zoomConnectionRequestReminder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomConnectionRequestReminder>;
};

/** on_conflict condition type for table "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_On_Conflict = {
  constraint: ZoomConnectionRequestReminder_Constraint;
  update_columns?: Array<ZoomConnectionRequestReminder_Update_Column>;
  where?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomConnectionRequestReminder". */
export type ZoomConnectionRequestReminder_Order_By = {
  cadence?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  zoomConnectionRequestId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomConnectionRequestReminder */
export type ZoomConnectionRequestReminder_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomConnectionRequestReminder" */
export enum ZoomConnectionRequestReminder_Select_Column {
  /** column name */
  Cadence = 'cadence',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ZoomConnectionRequestId = 'zoomConnectionRequestId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_Set_Input = {
  cadence?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomConnectionRequestId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ZoomConnectionRequestReminder_Stddev_Fields = {
  __typename?: 'zoomConnectionRequestReminder_stddev_fields';
  cadence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomConnectionRequestReminder_Stddev_Pop_Fields = {
  __typename?: 'zoomConnectionRequestReminder_stddev_pop_fields';
  cadence?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomConnectionRequestReminder_Stddev_Samp_Fields = {
  __typename?: 'zoomConnectionRequestReminder_stddev_samp_fields';
  cadence?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomConnectionRequestReminder" */
export type ZoomConnectionRequestReminder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomConnectionRequestReminder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomConnectionRequestReminder_Stream_Cursor_Value_Input = {
  cadence?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomConnectionRequestId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ZoomConnectionRequestReminder_Sum_Fields = {
  __typename?: 'zoomConnectionRequestReminder_sum_fields';
  cadence?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoomConnectionRequestReminder" */
export enum ZoomConnectionRequestReminder_Update_Column {
  /** column name */
  Cadence = 'cadence',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ZoomConnectionRequestId = 'zoomConnectionRequestId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomConnectionRequestReminder_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomConnectionRequestReminder_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomConnectionRequestReminder_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomConnectionRequestReminder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomConnectionRequestReminder_Var_Pop_Fields = {
  __typename?: 'zoomConnectionRequestReminder_var_pop_fields';
  cadence?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomConnectionRequestReminder_Var_Samp_Fields = {
  __typename?: 'zoomConnectionRequestReminder_var_samp_fields';
  cadence?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomConnectionRequestReminder_Variance_Fields = {
  __typename?: 'zoomConnectionRequestReminder_variance_fields';
  cadence?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "zoomConnectionRequest" */
export type ZoomConnectionRequest_Aggregate = {
  __typename?: 'zoomConnectionRequest_aggregate';
  aggregate?: Maybe<ZoomConnectionRequest_Aggregate_Fields>;
  nodes: Array<ZoomConnectionRequest>;
};

/** aggregate fields of "zoomConnectionRequest" */
export type ZoomConnectionRequest_Aggregate_Fields = {
  __typename?: 'zoomConnectionRequest_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomConnectionRequest_Max_Fields>;
  min?: Maybe<ZoomConnectionRequest_Min_Fields>;
};

/** aggregate fields of "zoomConnectionRequest" */
export type ZoomConnectionRequest_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomConnectionRequest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zoomConnectionRequest". All fields are combined with a logical 'AND'. */
export type ZoomConnectionRequest_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomConnectionRequest_Bool_Exp>>;
  _not?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomConnectionRequest_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  requestStatus?: InputMaybe<String_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomConnectionRequest" */
export enum ZoomConnectionRequest_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomConnectionRequestPkey = 'zoomConnectionRequest_pkey',
  /** unique or primary key constraint on columns "zoomUserId", "email" */
  ZoomConnectionRequestZoomUserIdEmailKey = 'zoomConnectionRequest_zoomUserId_email_key',
}

/** input type for inserting data into table "zoomConnectionRequest" */
export type ZoomConnectionRequest_Insert_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  requestStatus?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomConnectionRequest_Max_Fields = {
  __typename?: 'zoomConnectionRequest_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  requestStatus?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomConnectionRequest_Min_Fields = {
  __typename?: 'zoomConnectionRequest_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  requestStatus?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomConnectionRequest" */
export type ZoomConnectionRequest_Mutation_Response = {
  __typename?: 'zoomConnectionRequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomConnectionRequest>;
};

/** on_conflict condition type for table "zoomConnectionRequest" */
export type ZoomConnectionRequest_On_Conflict = {
  constraint: ZoomConnectionRequest_Constraint;
  update_columns?: Array<ZoomConnectionRequest_Update_Column>;
  where?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomConnectionRequest". */
export type ZoomConnectionRequest_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestStatus?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomConnectionRequest */
export type ZoomConnectionRequest_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomConnectionRequest" */
export enum ZoomConnectionRequest_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  RequestStatus = 'requestStatus',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomConnectionRequest" */
export type ZoomConnectionRequest_Set_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  requestStatus?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomConnectionRequest" */
export type ZoomConnectionRequest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomConnectionRequest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomConnectionRequest_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  requestStatus?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomConnectionRequest" */
export enum ZoomConnectionRequest_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  RequestStatus = 'requestStatus',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomConnectionRequest_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomConnectionRequest_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomConnectionRequest_Bool_Exp;
};

/** columns and relationships of "zoomContact" */
export type ZoomContact = {
  __typename?: 'zoomContact';
  apolloContactId?: Maybe<Scalars['String']>;
  apolloId?: Maybe<Scalars['String']>;
  apolloLastUpdatedAt?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitPersonId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataVendorName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  encodedId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  gravityContactId?: Maybe<Scalars['String']>;
  gravityPhotoUrlUpdatedAt?: Maybe<Scalars['timestamptz']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** A computed field, executes function "check_contact_eu_country" */
  isEuCountry?: Maybe<Scalars['Boolean']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['jsonb']>;
  locationSearchString?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  publicProfileDisabledAt?: Maybe<Scalars['date']>;
  rawJson?: Maybe<Scalars['jsonb']>;
  roleBucket?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  zoomCompany?: Maybe<ZoomCompany>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  zoomEventParticipants: Array<ZoomEventParticipant>;
  /** An aggregate relationship */
  zoomEventParticipants_aggregate: ZoomEventParticipant_Aggregate;
};

/** columns and relationships of "zoomContact" */
export type ZoomContactLocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomContact" */
export type ZoomContactRawJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomContact" */
export type ZoomContactZoomEventParticipantsArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

/** columns and relationships of "zoomContact" */
export type ZoomContactZoomEventParticipants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

/** columns and relationships of "zoomContactNotes" */
export type ZoomContactNotes = {
  __typename?: 'zoomContactNotes';
  contactEmail: Scalars['String'];
  id: Scalars['uuid'];
  notes?: Maybe<Scalars['jsonb']>;
  notesText?: Maybe<Scalars['String']>;
  ownerEmail: Scalars['String'];
  ownerZoomUserId: Scalars['uuid'];
  zoomContactId: Scalars['uuid'];
};

/** columns and relationships of "zoomContactNotes" */
export type ZoomContactNotesNotesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomContactNotes" */
export type ZoomContactNotes_Aggregate = {
  __typename?: 'zoomContactNotes_aggregate';
  aggregate?: Maybe<ZoomContactNotes_Aggregate_Fields>;
  nodes: Array<ZoomContactNotes>;
};

/** aggregate fields of "zoomContactNotes" */
export type ZoomContactNotes_Aggregate_Fields = {
  __typename?: 'zoomContactNotes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomContactNotes_Max_Fields>;
  min?: Maybe<ZoomContactNotes_Min_Fields>;
};

/** aggregate fields of "zoomContactNotes" */
export type ZoomContactNotes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomContactNotes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomContactNotes_Append_Input = {
  notes?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "zoomContactNotes". All fields are combined with a logical 'AND'. */
export type ZoomContactNotes_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomContactNotes_Bool_Exp>>;
  _not?: InputMaybe<ZoomContactNotes_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomContactNotes_Bool_Exp>>;
  contactEmail?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notes?: InputMaybe<Jsonb_Comparison_Exp>;
  notesText?: InputMaybe<String_Comparison_Exp>;
  ownerEmail?: InputMaybe<String_Comparison_Exp>;
  ownerZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomContactId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomContactNotes" */
export enum ZoomContactNotes_Constraint {
  /** unique or primary key constraint on columns "zoomContactId", "ownerZoomUserId" */
  ZoomContactNotesOwnerZoomUserIdZoomContactIdKey = 'zoomContactNotes_ownerZoomUserId_zoomContactId_key',
  /** unique or primary key constraint on columns "id" */
  ZoomContactNotesPkey = 'zoomContactNotes_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomContactNotes_Delete_At_Path_Input = {
  notes?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomContactNotes_Delete_Elem_Input = {
  notes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomContactNotes_Delete_Key_Input = {
  notes?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "zoomContactNotes" */
export type ZoomContactNotes_Insert_Input = {
  contactEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['jsonb']>;
  notesText?: InputMaybe<Scalars['String']>;
  ownerEmail?: InputMaybe<Scalars['String']>;
  ownerZoomUserId?: InputMaybe<Scalars['uuid']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomContactNotes_Max_Fields = {
  __typename?: 'zoomContactNotes_max_fields';
  contactEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notesText?: Maybe<Scalars['String']>;
  ownerEmail?: Maybe<Scalars['String']>;
  ownerZoomUserId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomContactNotes_Min_Fields = {
  __typename?: 'zoomContactNotes_min_fields';
  contactEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notesText?: Maybe<Scalars['String']>;
  ownerEmail?: Maybe<Scalars['String']>;
  ownerZoomUserId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomContactNotes" */
export type ZoomContactNotes_Mutation_Response = {
  __typename?: 'zoomContactNotes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomContactNotes>;
};

/** on_conflict condition type for table "zoomContactNotes" */
export type ZoomContactNotes_On_Conflict = {
  constraint: ZoomContactNotes_Constraint;
  update_columns?: Array<ZoomContactNotes_Update_Column>;
  where?: InputMaybe<ZoomContactNotes_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomContactNotes". */
export type ZoomContactNotes_Order_By = {
  contactEmail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  notesText?: InputMaybe<Order_By>;
  ownerEmail?: InputMaybe<Order_By>;
  ownerZoomUserId?: InputMaybe<Order_By>;
  zoomContactId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomContactNotes */
export type ZoomContactNotes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomContactNotes_Prepend_Input = {
  notes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomContactNotes" */
export enum ZoomContactNotes_Select_Column {
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotesText = 'notesText',
  /** column name */
  OwnerEmail = 'ownerEmail',
  /** column name */
  OwnerZoomUserId = 'ownerZoomUserId',
  /** column name */
  ZoomContactId = 'zoomContactId',
}

/** input type for updating data in table "zoomContactNotes" */
export type ZoomContactNotes_Set_Input = {
  contactEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['jsonb']>;
  notesText?: InputMaybe<Scalars['String']>;
  ownerEmail?: InputMaybe<Scalars['String']>;
  ownerZoomUserId?: InputMaybe<Scalars['uuid']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomContactNotes" */
export type ZoomContactNotes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomContactNotes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomContactNotes_Stream_Cursor_Value_Input = {
  contactEmail?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Scalars['jsonb']>;
  notesText?: InputMaybe<Scalars['String']>;
  ownerEmail?: InputMaybe<Scalars['String']>;
  ownerZoomUserId?: InputMaybe<Scalars['uuid']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomContactNotes" */
export enum ZoomContactNotes_Update_Column {
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  NotesText = 'notesText',
  /** column name */
  OwnerEmail = 'ownerEmail',
  /** column name */
  OwnerZoomUserId = 'ownerZoomUserId',
  /** column name */
  ZoomContactId = 'zoomContactId',
}

export type ZoomContactNotes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomContactNotes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomContactNotes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomContactNotes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomContactNotes_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomContactNotes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomContactNotes_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomContactNotes_Bool_Exp;
};

/** aggregated selection of "zoomContact" */
export type ZoomContact_Aggregate = {
  __typename?: 'zoomContact_aggregate';
  aggregate?: Maybe<ZoomContact_Aggregate_Fields>;
  nodes: Array<ZoomContact>;
};

export type ZoomContact_Aggregate_Bool_Exp = {
  count?: InputMaybe<ZoomContact_Aggregate_Bool_Exp_Count>;
};

export type ZoomContact_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ZoomContact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomContact_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zoomContact" */
export type ZoomContact_Aggregate_Fields = {
  __typename?: 'zoomContact_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomContact_Max_Fields>;
  min?: Maybe<ZoomContact_Min_Fields>;
};

/** aggregate fields of "zoomContact" */
export type ZoomContact_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomContact_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomContact" */
export type ZoomContact_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ZoomContact_Max_Order_By>;
  min?: InputMaybe<ZoomContact_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomContact_Append_Input = {
  location?: InputMaybe<Scalars['jsonb']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "zoomContact" */
export type ZoomContact_Arr_Rel_Insert_Input = {
  data: Array<ZoomContact_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomContact_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomContact". All fields are combined with a logical 'AND'. */
export type ZoomContact_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomContact_Bool_Exp>>;
  _not?: InputMaybe<ZoomContact_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomContact_Bool_Exp>>;
  apolloContactId?: InputMaybe<String_Comparison_Exp>;
  apolloId?: InputMaybe<String_Comparison_Exp>;
  apolloLastUpdatedAt?: InputMaybe<String_Comparison_Exp>;
  apolloOrganizationId?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  clearbitPersonId?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dataVendorName?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encodedId?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  fullNameGenerated?: InputMaybe<String_Comparison_Exp>;
  gravityContactId?: InputMaybe<String_Comparison_Exp>;
  gravityPhotoUrlUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  headline?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isEuCountry?: InputMaybe<Boolean_Comparison_Exp>;
  lastEnrichedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  linkedInUrl?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  locationSearchString?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  photoUrl?: InputMaybe<String_Comparison_Exp>;
  publicProfileDisabledAt?: InputMaybe<Date_Comparison_Exp>;
  rawJson?: InputMaybe<Jsonb_Comparison_Exp>;
  roleBucket?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  twitterUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomCompany?: InputMaybe<ZoomCompany_Bool_Exp>;
  zoomCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomEventParticipants?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
  zoomEventParticipants_aggregate?: InputMaybe<ZoomEventParticipant_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomContact" */
export enum ZoomContact_Constraint {
  /** unique or primary key constraint on columns "email" */
  ZoomContactEmailKey = 'zoomContact_email_key',
  /** unique or primary key constraint on columns "id" */
  ZoomContactsPkey = 'zoomContacts_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomContact_Delete_At_Path_Input = {
  location?: InputMaybe<Array<Scalars['String']>>;
  rawJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomContact_Delete_Elem_Input = {
  location?: InputMaybe<Scalars['Int']>;
  rawJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomContact_Delete_Key_Input = {
  location?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "zoomContact" */
export type ZoomContact_Insert_Input = {
  apolloContactId?: InputMaybe<Scalars['String']>;
  apolloId?: InputMaybe<Scalars['String']>;
  apolloLastUpdatedAt?: InputMaybe<Scalars['String']>;
  apolloOrganizationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clearbitPersonId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dataVendorName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  encodedId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gravityContactId?: InputMaybe<Scalars['String']>;
  gravityPhotoUrlUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['jsonb']>;
  locationSearchString?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  publicProfileDisabledAt?: InputMaybe<Scalars['date']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  roleBucket?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompany?: InputMaybe<ZoomCompany_Obj_Rel_Insert_Input>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
  zoomEventParticipants?: InputMaybe<ZoomEventParticipant_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomContact_Max_Fields = {
  __typename?: 'zoomContact_max_fields';
  apolloContactId?: Maybe<Scalars['String']>;
  apolloId?: Maybe<Scalars['String']>;
  apolloLastUpdatedAt?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitPersonId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataVendorName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  encodedId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  gravityContactId?: Maybe<Scalars['String']>;
  gravityPhotoUrlUpdatedAt?: Maybe<Scalars['timestamptz']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  locationSearchString?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  publicProfileDisabledAt?: Maybe<Scalars['date']>;
  roleBucket?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomContact" */
export type ZoomContact_Max_Order_By = {
  apolloContactId?: InputMaybe<Order_By>;
  apolloId?: InputMaybe<Order_By>;
  apolloLastUpdatedAt?: InputMaybe<Order_By>;
  apolloOrganizationId?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clearbitPersonId?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dataVendorName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encodedId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullNameGenerated?: InputMaybe<Order_By>;
  gravityContactId?: InputMaybe<Order_By>;
  gravityPhotoUrlUpdatedAt?: InputMaybe<Order_By>;
  headline?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastEnrichedAt?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  locationSearchString?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  photoUrl?: InputMaybe<Order_By>;
  publicProfileDisabledAt?: InputMaybe<Order_By>;
  roleBucket?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomContact_Min_Fields = {
  __typename?: 'zoomContact_min_fields';
  apolloContactId?: Maybe<Scalars['String']>;
  apolloId?: Maybe<Scalars['String']>;
  apolloLastUpdatedAt?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitPersonId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataVendorName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  encodedId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  gravityContactId?: Maybe<Scalars['String']>;
  gravityPhotoUrlUpdatedAt?: Maybe<Scalars['timestamptz']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  locationSearchString?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  publicProfileDisabledAt?: Maybe<Scalars['date']>;
  roleBucket?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomContact" */
export type ZoomContact_Min_Order_By = {
  apolloContactId?: InputMaybe<Order_By>;
  apolloId?: InputMaybe<Order_By>;
  apolloLastUpdatedAt?: InputMaybe<Order_By>;
  apolloOrganizationId?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clearbitPersonId?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dataVendorName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encodedId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullNameGenerated?: InputMaybe<Order_By>;
  gravityContactId?: InputMaybe<Order_By>;
  gravityPhotoUrlUpdatedAt?: InputMaybe<Order_By>;
  headline?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastEnrichedAt?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  locationSearchString?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  photoUrl?: InputMaybe<Order_By>;
  publicProfileDisabledAt?: InputMaybe<Order_By>;
  roleBucket?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zoomContact" */
export type ZoomContact_Mutation_Response = {
  __typename?: 'zoomContact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomContact>;
};

/** input type for inserting object relation for remote table "zoomContact" */
export type ZoomContact_Obj_Rel_Insert_Input = {
  data: ZoomContact_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomContact_On_Conflict>;
};

/** on_conflict condition type for table "zoomContact" */
export type ZoomContact_On_Conflict = {
  constraint: ZoomContact_Constraint;
  update_columns?: Array<ZoomContact_Update_Column>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomContact". */
export type ZoomContact_Order_By = {
  apolloContactId?: InputMaybe<Order_By>;
  apolloId?: InputMaybe<Order_By>;
  apolloLastUpdatedAt?: InputMaybe<Order_By>;
  apolloOrganizationId?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clearbitPersonId?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dataVendorName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encodedId?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  fullNameGenerated?: InputMaybe<Order_By>;
  gravityContactId?: InputMaybe<Order_By>;
  gravityPhotoUrlUpdatedAt?: InputMaybe<Order_By>;
  headline?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isEuCountry?: InputMaybe<Order_By>;
  lastEnrichedAt?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  locationSearchString?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  photoUrl?: InputMaybe<Order_By>;
  publicProfileDisabledAt?: InputMaybe<Order_By>;
  rawJson?: InputMaybe<Order_By>;
  roleBucket?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomCompany?: InputMaybe<ZoomCompany_Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
  zoomEventParticipants_aggregate?: InputMaybe<ZoomEventParticipant_Aggregate_Order_By>;
};

/** primary key columns input for table: zoomContact */
export type ZoomContact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomContact_Prepend_Input = {
  location?: InputMaybe<Scalars['jsonb']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomContact" */
export enum ZoomContact_Select_Column {
  /** column name */
  ApolloContactId = 'apolloContactId',
  /** column name */
  ApolloId = 'apolloId',
  /** column name */
  ApolloLastUpdatedAt = 'apolloLastUpdatedAt',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitPersonId = 'clearbitPersonId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Email = 'email',
  /** column name */
  EncodedId = 'encodedId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullNameGenerated = 'fullNameGenerated',
  /** column name */
  GravityContactId = 'gravityContactId',
  /** column name */
  GravityPhotoUrlUpdatedAt = 'gravityPhotoUrlUpdatedAt',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Location = 'location',
  /** column name */
  LocationSearchString = 'locationSearchString',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  PublicProfileDisabledAt = 'publicProfileDisabledAt',
  /** column name */
  RawJson = 'rawJson',
  /** column name */
  RoleBucket = 'roleBucket',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

/** input type for updating data in table "zoomContact" */
export type ZoomContact_Set_Input = {
  apolloContactId?: InputMaybe<Scalars['String']>;
  apolloId?: InputMaybe<Scalars['String']>;
  apolloLastUpdatedAt?: InputMaybe<Scalars['String']>;
  apolloOrganizationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clearbitPersonId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dataVendorName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  encodedId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gravityContactId?: InputMaybe<Scalars['String']>;
  gravityPhotoUrlUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['jsonb']>;
  locationSearchString?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  publicProfileDisabledAt?: InputMaybe<Scalars['date']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  roleBucket?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomContact" */
export type ZoomContact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomContact_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomContact_Stream_Cursor_Value_Input = {
  apolloContactId?: InputMaybe<Scalars['String']>;
  apolloId?: InputMaybe<Scalars['String']>;
  apolloLastUpdatedAt?: InputMaybe<Scalars['String']>;
  apolloOrganizationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clearbitPersonId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dataVendorName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  encodedId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullNameGenerated?: InputMaybe<Scalars['String']>;
  gravityContactId?: InputMaybe<Scalars['String']>;
  gravityPhotoUrlUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['jsonb']>;
  locationSearchString?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  publicProfileDisabledAt?: InputMaybe<Scalars['date']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  roleBucket?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomContact" */
export enum ZoomContact_Update_Column {
  /** column name */
  ApolloContactId = 'apolloContactId',
  /** column name */
  ApolloId = 'apolloId',
  /** column name */
  ApolloLastUpdatedAt = 'apolloLastUpdatedAt',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitPersonId = 'clearbitPersonId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Email = 'email',
  /** column name */
  EncodedId = 'encodedId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GravityContactId = 'gravityContactId',
  /** column name */
  GravityPhotoUrlUpdatedAt = 'gravityPhotoUrlUpdatedAt',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Location = 'location',
  /** column name */
  LocationSearchString = 'locationSearchString',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  PublicProfileDisabledAt = 'publicProfileDisabledAt',
  /** column name */
  RawJson = 'rawJson',
  /** column name */
  RoleBucket = 'roleBucket',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

export type ZoomContact_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomContact_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomContact_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomContact_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomContact_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomContact_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomContact_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomContact_Bool_Exp;
};

/** columns and relationships of "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer = {
  __typename?: 'zoomConversationStarterAnswer';
  addedToVideoAt?: Maybe<Scalars['timestamptz']>;
  answer?: Maybe<Scalars['String']>;
  answeredByZoomUserId: Scalars['uuid'];
  cloudinaryPublicId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  zoomConversationStarterPromptId: Scalars['uuid'];
};

/** aggregated selection of "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer_Aggregate = {
  __typename?: 'zoomConversationStarterAnswer_aggregate';
  aggregate?: Maybe<ZoomConversationStarterAnswer_Aggregate_Fields>;
  nodes: Array<ZoomConversationStarterAnswer>;
};

/** aggregate fields of "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer_Aggregate_Fields = {
  __typename?: 'zoomConversationStarterAnswer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomConversationStarterAnswer_Max_Fields>;
  min?: Maybe<ZoomConversationStarterAnswer_Min_Fields>;
};

/** aggregate fields of "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomConversationStarterAnswer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zoomConversationStarterAnswer". All fields are combined with a logical 'AND'. */
export type ZoomConversationStarterAnswer_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomConversationStarterAnswer_Bool_Exp>>;
  _not?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomConversationStarterAnswer_Bool_Exp>>;
  addedToVideoAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  answer?: InputMaybe<String_Comparison_Exp>;
  answeredByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  cloudinaryPublicId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  imageUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomConversationStarterPromptId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomConversationStarterAnswer" */
export enum ZoomConversationStarterAnswer_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomConversationStarterAnswerPkey = 'zoomConversationStarterAnswer_pkey',
}

/** input type for inserting data into table "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer_Insert_Input = {
  addedToVideoAt?: InputMaybe<Scalars['timestamptz']>;
  answer?: InputMaybe<Scalars['String']>;
  answeredByZoomUserId?: InputMaybe<Scalars['uuid']>;
  cloudinaryPublicId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomConversationStarterPromptId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomConversationStarterAnswer_Max_Fields = {
  __typename?: 'zoomConversationStarterAnswer_max_fields';
  addedToVideoAt?: Maybe<Scalars['timestamptz']>;
  answer?: Maybe<Scalars['String']>;
  answeredByZoomUserId?: Maybe<Scalars['uuid']>;
  cloudinaryPublicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomConversationStarterPromptId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomConversationStarterAnswer_Min_Fields = {
  __typename?: 'zoomConversationStarterAnswer_min_fields';
  addedToVideoAt?: Maybe<Scalars['timestamptz']>;
  answer?: Maybe<Scalars['String']>;
  answeredByZoomUserId?: Maybe<Scalars['uuid']>;
  cloudinaryPublicId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomConversationStarterPromptId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer_Mutation_Response = {
  __typename?: 'zoomConversationStarterAnswer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomConversationStarterAnswer>;
};

/** on_conflict condition type for table "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer_On_Conflict = {
  constraint: ZoomConversationStarterAnswer_Constraint;
  update_columns?: Array<ZoomConversationStarterAnswer_Update_Column>;
  where?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomConversationStarterAnswer". */
export type ZoomConversationStarterAnswer_Order_By = {
  addedToVideoAt?: InputMaybe<Order_By>;
  answer?: InputMaybe<Order_By>;
  answeredByZoomUserId?: InputMaybe<Order_By>;
  cloudinaryPublicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomConversationStarterPromptId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomConversationStarterAnswer */
export type ZoomConversationStarterAnswer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomConversationStarterAnswer" */
export enum ZoomConversationStarterAnswer_Select_Column {
  /** column name */
  AddedToVideoAt = 'addedToVideoAt',
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByZoomUserId = 'answeredByZoomUserId',
  /** column name */
  CloudinaryPublicId = 'cloudinaryPublicId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomConversationStarterPromptId = 'zoomConversationStarterPromptId',
}

/** input type for updating data in table "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer_Set_Input = {
  addedToVideoAt?: InputMaybe<Scalars['timestamptz']>;
  answer?: InputMaybe<Scalars['String']>;
  answeredByZoomUserId?: InputMaybe<Scalars['uuid']>;
  cloudinaryPublicId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomConversationStarterPromptId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomConversationStarterAnswer" */
export type ZoomConversationStarterAnswer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomConversationStarterAnswer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomConversationStarterAnswer_Stream_Cursor_Value_Input = {
  addedToVideoAt?: InputMaybe<Scalars['timestamptz']>;
  answer?: InputMaybe<Scalars['String']>;
  answeredByZoomUserId?: InputMaybe<Scalars['uuid']>;
  cloudinaryPublicId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomConversationStarterPromptId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomConversationStarterAnswer" */
export enum ZoomConversationStarterAnswer_Update_Column {
  /** column name */
  AddedToVideoAt = 'addedToVideoAt',
  /** column name */
  Answer = 'answer',
  /** column name */
  AnsweredByZoomUserId = 'answeredByZoomUserId',
  /** column name */
  CloudinaryPublicId = 'cloudinaryPublicId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomConversationStarterPromptId = 'zoomConversationStarterPromptId',
}

export type ZoomConversationStarterAnswer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomConversationStarterAnswer_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomConversationStarterAnswer_Bool_Exp;
};

/** columns and relationships of "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt = {
  __typename?: 'zoomConversationStarterPrompt';
  answeredPrompt: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdByZoomUserId?: Maybe<Scalars['uuid']>;
  fromTag?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  placeholderAnswer?: Maybe<Scalars['String']>;
  placeholderImageUrl?: Maybe<Scalars['String']>;
  unansweredPrompt: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt_Aggregate = {
  __typename?: 'zoomConversationStarterPrompt_aggregate';
  aggregate?: Maybe<ZoomConversationStarterPrompt_Aggregate_Fields>;
  nodes: Array<ZoomConversationStarterPrompt>;
};

/** aggregate fields of "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt_Aggregate_Fields = {
  __typename?: 'zoomConversationStarterPrompt_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomConversationStarterPrompt_Max_Fields>;
  min?: Maybe<ZoomConversationStarterPrompt_Min_Fields>;
};

/** aggregate fields of "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomConversationStarterPrompt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zoomConversationStarterPrompt". All fields are combined with a logical 'AND'. */
export type ZoomConversationStarterPrompt_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomConversationStarterPrompt_Bool_Exp>>;
  _not?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomConversationStarterPrompt_Bool_Exp>>;
  answeredPrompt?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  fromTag?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  placeholderAnswer?: InputMaybe<String_Comparison_Exp>;
  placeholderImageUrl?: InputMaybe<String_Comparison_Exp>;
  unansweredPrompt?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomConversationStarterPrompt" */
export enum ZoomConversationStarterPrompt_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomConversationStarterPromptPkey = 'zoomConversationStarterPrompt_pkey',
}

/** input type for inserting data into table "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt_Insert_Input = {
  answeredPrompt?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByZoomUserId?: InputMaybe<Scalars['uuid']>;
  fromTag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  placeholderAnswer?: InputMaybe<Scalars['String']>;
  placeholderImageUrl?: InputMaybe<Scalars['String']>;
  unansweredPrompt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ZoomConversationStarterPrompt_Max_Fields = {
  __typename?: 'zoomConversationStarterPrompt_max_fields';
  answeredPrompt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByZoomUserId?: Maybe<Scalars['uuid']>;
  fromTag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  placeholderAnswer?: Maybe<Scalars['String']>;
  placeholderImageUrl?: Maybe<Scalars['String']>;
  unansweredPrompt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ZoomConversationStarterPrompt_Min_Fields = {
  __typename?: 'zoomConversationStarterPrompt_min_fields';
  answeredPrompt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByZoomUserId?: Maybe<Scalars['uuid']>;
  fromTag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  placeholderAnswer?: Maybe<Scalars['String']>;
  placeholderImageUrl?: Maybe<Scalars['String']>;
  unansweredPrompt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt_Mutation_Response = {
  __typename?: 'zoomConversationStarterPrompt_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomConversationStarterPrompt>;
};

/** on_conflict condition type for table "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt_On_Conflict = {
  constraint: ZoomConversationStarterPrompt_Constraint;
  update_columns?: Array<ZoomConversationStarterPrompt_Update_Column>;
  where?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomConversationStarterPrompt". */
export type ZoomConversationStarterPrompt_Order_By = {
  answeredPrompt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByZoomUserId?: InputMaybe<Order_By>;
  fromTag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  placeholderAnswer?: InputMaybe<Order_By>;
  placeholderImageUrl?: InputMaybe<Order_By>;
  unansweredPrompt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomConversationStarterPrompt */
export type ZoomConversationStarterPrompt_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomConversationStarterPrompt" */
export enum ZoomConversationStarterPrompt_Select_Column {
  /** column name */
  AnsweredPrompt = 'answeredPrompt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByZoomUserId = 'createdByZoomUserId',
  /** column name */
  FromTag = 'fromTag',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  PlaceholderAnswer = 'placeholderAnswer',
  /** column name */
  PlaceholderImageUrl = 'placeholderImageUrl',
  /** column name */
  UnansweredPrompt = 'unansweredPrompt',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt_Set_Input = {
  answeredPrompt?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByZoomUserId?: InputMaybe<Scalars['uuid']>;
  fromTag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  placeholderAnswer?: InputMaybe<Scalars['String']>;
  placeholderImageUrl?: InputMaybe<Scalars['String']>;
  unansweredPrompt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "zoomConversationStarterPrompt" */
export type ZoomConversationStarterPrompt_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomConversationStarterPrompt_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomConversationStarterPrompt_Stream_Cursor_Value_Input = {
  answeredPrompt?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByZoomUserId?: InputMaybe<Scalars['uuid']>;
  fromTag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  placeholderAnswer?: InputMaybe<Scalars['String']>;
  placeholderImageUrl?: InputMaybe<Scalars['String']>;
  unansweredPrompt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "zoomConversationStarterPrompt" */
export enum ZoomConversationStarterPrompt_Update_Column {
  /** column name */
  AnsweredPrompt = 'answeredPrompt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByZoomUserId = 'createdByZoomUserId',
  /** column name */
  FromTag = 'fromTag',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  PlaceholderAnswer = 'placeholderAnswer',
  /** column name */
  PlaceholderImageUrl = 'placeholderImageUrl',
  /** column name */
  UnansweredPrompt = 'unansweredPrompt',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ZoomConversationStarterPrompt_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomConversationStarterPrompt_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomConversationStarterPrompt_Bool_Exp;
};

/** columns and relationships of "zoomCustomBackground" */
export type ZoomCustomBackground = {
  __typename?: 'zoomCustomBackground';
  caption?: Maybe<Scalars['String']>;
  cloudinaryPublicId: Scalars['String'];
  companyDomain: Scalars['citext'];
  companyShared: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  downloadCount: Scalars['Int'];
  id: Scalars['uuid'];
  isGlobal: Scalars['Boolean'];
  isHoliday: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
  uploadedByZoomUserId?: Maybe<Scalars['uuid']>;
  url: Scalars['String'];
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
};

/** aggregated selection of "zoomCustomBackground" */
export type ZoomCustomBackground_Aggregate = {
  __typename?: 'zoomCustomBackground_aggregate';
  aggregate?: Maybe<ZoomCustomBackground_Aggregate_Fields>;
  nodes: Array<ZoomCustomBackground>;
};

/** aggregate fields of "zoomCustomBackground" */
export type ZoomCustomBackground_Aggregate_Fields = {
  __typename?: 'zoomCustomBackground_aggregate_fields';
  avg?: Maybe<ZoomCustomBackground_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomCustomBackground_Max_Fields>;
  min?: Maybe<ZoomCustomBackground_Min_Fields>;
  stddev?: Maybe<ZoomCustomBackground_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomCustomBackground_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomCustomBackground_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomCustomBackground_Sum_Fields>;
  var_pop?: Maybe<ZoomCustomBackground_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomCustomBackground_Var_Samp_Fields>;
  variance?: Maybe<ZoomCustomBackground_Variance_Fields>;
};

/** aggregate fields of "zoomCustomBackground" */
export type ZoomCustomBackground_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomCustomBackground_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZoomCustomBackground_Avg_Fields = {
  __typename?: 'zoomCustomBackground_avg_fields';
  downloadCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomCustomBackground". All fields are combined with a logical 'AND'. */
export type ZoomCustomBackground_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomCustomBackground_Bool_Exp>>;
  _not?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomCustomBackground_Bool_Exp>>;
  caption?: InputMaybe<String_Comparison_Exp>;
  cloudinaryPublicId?: InputMaybe<String_Comparison_Exp>;
  companyDomain?: InputMaybe<Citext_Comparison_Exp>;
  companyShared?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  downloadCount?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isGlobal?: InputMaybe<Boolean_Comparison_Exp>;
  isHoliday?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  zoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomCustomBackground" */
export enum ZoomCustomBackground_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomCustomBackgroundPkey = 'zoomCustomBackground_pkey',
}

/** input type for incrementing numeric columns in table "zoomCustomBackground" */
export type ZoomCustomBackground_Inc_Input = {
  downloadCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomCustomBackground" */
export type ZoomCustomBackground_Insert_Input = {
  caption?: InputMaybe<Scalars['String']>;
  cloudinaryPublicId?: InputMaybe<Scalars['String']>;
  companyDomain?: InputMaybe<Scalars['citext']>;
  companyShared?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  isHoliday?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  url?: InputMaybe<Scalars['String']>;
  zoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomCustomBackground_Max_Fields = {
  __typename?: 'zoomCustomBackground_max_fields';
  caption?: Maybe<Scalars['String']>;
  cloudinaryPublicId?: Maybe<Scalars['String']>;
  companyDomain?: Maybe<Scalars['citext']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  downloadCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploadedByZoomUserId?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ZoomCustomBackground_Min_Fields = {
  __typename?: 'zoomCustomBackground_min_fields';
  caption?: Maybe<Scalars['String']>;
  cloudinaryPublicId?: Maybe<Scalars['String']>;
  companyDomain?: Maybe<Scalars['citext']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  downloadCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  uploadedByZoomUserId?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zoomCustomBackground" */
export type ZoomCustomBackground_Mutation_Response = {
  __typename?: 'zoomCustomBackground_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomCustomBackground>;
};

/** on_conflict condition type for table "zoomCustomBackground" */
export type ZoomCustomBackground_On_Conflict = {
  constraint: ZoomCustomBackground_Constraint;
  update_columns?: Array<ZoomCustomBackground_Update_Column>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomCustomBackground". */
export type ZoomCustomBackground_Order_By = {
  caption?: InputMaybe<Order_By>;
  cloudinaryPublicId?: InputMaybe<Order_By>;
  companyDomain?: InputMaybe<Order_By>;
  companyShared?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  downloadCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isGlobal?: InputMaybe<Order_By>;
  isHoliday?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByZoomUserId?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  zoomUser?: InputMaybe<ZoomUser_Order_By>;
};

/** primary key columns input for table: zoomCustomBackground */
export type ZoomCustomBackground_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomCustomBackground" */
export enum ZoomCustomBackground_Select_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CloudinaryPublicId = 'cloudinaryPublicId',
  /** column name */
  CompanyDomain = 'companyDomain',
  /** column name */
  CompanyShared = 'companyShared',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadCount = 'downloadCount',
  /** column name */
  Id = 'id',
  /** column name */
  IsGlobal = 'isGlobal',
  /** column name */
  IsHoliday = 'isHoliday',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByZoomUserId = 'uploadedByZoomUserId',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "zoomCustomBackground" */
export type ZoomCustomBackground_Set_Input = {
  caption?: InputMaybe<Scalars['String']>;
  cloudinaryPublicId?: InputMaybe<Scalars['String']>;
  companyDomain?: InputMaybe<Scalars['citext']>;
  companyShared?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  isHoliday?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ZoomCustomBackground_Stddev_Fields = {
  __typename?: 'zoomCustomBackground_stddev_fields';
  downloadCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomCustomBackground_Stddev_Pop_Fields = {
  __typename?: 'zoomCustomBackground_stddev_pop_fields';
  downloadCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomCustomBackground_Stddev_Samp_Fields = {
  __typename?: 'zoomCustomBackground_stddev_samp_fields';
  downloadCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomCustomBackground" */
export type ZoomCustomBackground_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomCustomBackground_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomCustomBackground_Stream_Cursor_Value_Input = {
  caption?: InputMaybe<Scalars['String']>;
  cloudinaryPublicId?: InputMaybe<Scalars['String']>;
  companyDomain?: InputMaybe<Scalars['citext']>;
  companyShared?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  isHoliday?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  uploadedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ZoomCustomBackground_Sum_Fields = {
  __typename?: 'zoomCustomBackground_sum_fields';
  downloadCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoomCustomBackground" */
export enum ZoomCustomBackground_Update_Column {
  /** column name */
  Caption = 'caption',
  /** column name */
  CloudinaryPublicId = 'cloudinaryPublicId',
  /** column name */
  CompanyDomain = 'companyDomain',
  /** column name */
  CompanyShared = 'companyShared',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadCount = 'downloadCount',
  /** column name */
  Id = 'id',
  /** column name */
  IsGlobal = 'isGlobal',
  /** column name */
  IsHoliday = 'isHoliday',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByZoomUserId = 'uploadedByZoomUserId',
  /** column name */
  Url = 'url',
}

export type ZoomCustomBackground_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomCustomBackground_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomCustomBackground_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomCustomBackground_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomCustomBackground_Var_Pop_Fields = {
  __typename?: 'zoomCustomBackground_var_pop_fields';
  downloadCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomCustomBackground_Var_Samp_Fields = {
  __typename?: 'zoomCustomBackground_var_samp_fields';
  downloadCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomCustomBackground_Variance_Fields = {
  __typename?: 'zoomCustomBackground_variance_fields';
  downloadCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoomEmailHistory" */
export type ZoomEmailHistory = {
  __typename?: 'zoomEmailHistory';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  emailService: Scalars['String'];
  emailServiceMessageId?: Maybe<Scalars['String']>;
  emailServiceResponseJson?: Maybe<Scalars['jsonb']>;
  from: Scalars['String'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  templateData?: Maybe<Scalars['jsonb']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  zoomEvent?: Maybe<ZoomEvent>;
  zoomEventId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomEmailHistory" */
export type ZoomEmailHistoryEmailServiceResponseJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomEmailHistory" */
export type ZoomEmailHistoryTemplateDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomEmailHistory" */
export type ZoomEmailHistory_Aggregate = {
  __typename?: 'zoomEmailHistory_aggregate';
  aggregate?: Maybe<ZoomEmailHistory_Aggregate_Fields>;
  nodes: Array<ZoomEmailHistory>;
};

/** aggregate fields of "zoomEmailHistory" */
export type ZoomEmailHistory_Aggregate_Fields = {
  __typename?: 'zoomEmailHistory_aggregate_fields';
  avg?: Maybe<ZoomEmailHistory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomEmailHistory_Max_Fields>;
  min?: Maybe<ZoomEmailHistory_Min_Fields>;
  stddev?: Maybe<ZoomEmailHistory_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomEmailHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomEmailHistory_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomEmailHistory_Sum_Fields>;
  var_pop?: Maybe<ZoomEmailHistory_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomEmailHistory_Var_Samp_Fields>;
  variance?: Maybe<ZoomEmailHistory_Variance_Fields>;
};

/** aggregate fields of "zoomEmailHistory" */
export type ZoomEmailHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomEmailHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomEmailHistory_Append_Input = {
  emailServiceResponseJson?: InputMaybe<Scalars['jsonb']>;
  templateData?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ZoomEmailHistory_Avg_Fields = {
  __typename?: 'zoomEmailHistory_avg_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomEmailHistory". All fields are combined with a logical 'AND'. */
export type ZoomEmailHistory_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomEmailHistory_Bool_Exp>>;
  _not?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomEmailHistory_Bool_Exp>>;
  bcc?: InputMaybe<String_Comparison_Exp>;
  body?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  cc?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  emailService?: InputMaybe<String_Comparison_Exp>;
  emailServiceMessageId?: InputMaybe<String_Comparison_Exp>;
  emailServiceResponseJson?: InputMaybe<Jsonb_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  templateData?: InputMaybe<Jsonb_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomEvent?: InputMaybe<ZoomEvent_Bool_Exp>;
  zoomEventId?: InputMaybe<Int_Comparison_Exp>;
  zoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomEmailHistory" */
export enum ZoomEmailHistory_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomEmailHistoryPkey = 'zoomEmailHistory_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomEmailHistory_Delete_At_Path_Input = {
  emailServiceResponseJson?: InputMaybe<Array<Scalars['String']>>;
  templateData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomEmailHistory_Delete_Elem_Input = {
  emailServiceResponseJson?: InputMaybe<Scalars['Int']>;
  templateData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomEmailHistory_Delete_Key_Input = {
  emailServiceResponseJson?: InputMaybe<Scalars['String']>;
  templateData?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "zoomEmailHistory" */
export type ZoomEmailHistory_Inc_Input = {
  zoomEventId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomEmailHistory" */
export type ZoomEmailHistory_Insert_Input = {
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  emailService?: InputMaybe<Scalars['String']>;
  emailServiceMessageId?: InputMaybe<Scalars['String']>;
  emailServiceResponseJson?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  templateData?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomEvent?: InputMaybe<ZoomEvent_Obj_Rel_Insert_Input>;
  zoomEventId?: InputMaybe<Scalars['Int']>;
  zoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomEmailHistory_Max_Fields = {
  __typename?: 'zoomEmailHistory_max_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomEmailHistory_Min_Fields = {
  __typename?: 'zoomEmailHistory_min_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomEmailHistory" */
export type ZoomEmailHistory_Mutation_Response = {
  __typename?: 'zoomEmailHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomEmailHistory>;
};

/** on_conflict condition type for table "zoomEmailHistory" */
export type ZoomEmailHistory_On_Conflict = {
  constraint: ZoomEmailHistory_Constraint;
  update_columns?: Array<ZoomEmailHistory_Update_Column>;
  where?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomEmailHistory". */
export type ZoomEmailHistory_Order_By = {
  bcc?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  cc?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  emailService?: InputMaybe<Order_By>;
  emailServiceMessageId?: InputMaybe<Order_By>;
  emailServiceResponseJson?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  templateData?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomEvent?: InputMaybe<ZoomEvent_Order_By>;
  zoomEventId?: InputMaybe<Order_By>;
  zoomUser?: InputMaybe<ZoomUser_Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomEmailHistory */
export type ZoomEmailHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomEmailHistory_Prepend_Input = {
  emailServiceResponseJson?: InputMaybe<Scalars['jsonb']>;
  templateData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomEmailHistory" */
export enum ZoomEmailHistory_Select_Column {
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  Category = 'category',
  /** column name */
  Cc = 'cc',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EmailService = 'emailService',
  /** column name */
  EmailServiceMessageId = 'emailServiceMessageId',
  /** column name */
  EmailServiceResponseJson = 'emailServiceResponseJson',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  TemplateData = 'templateData',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomEmailHistory" */
export type ZoomEmailHistory_Set_Input = {
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  emailService?: InputMaybe<Scalars['String']>;
  emailServiceMessageId?: InputMaybe<Scalars['String']>;
  emailServiceResponseJson?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  templateData?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomEventId?: InputMaybe<Scalars['Int']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ZoomEmailHistory_Stddev_Fields = {
  __typename?: 'zoomEmailHistory_stddev_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomEmailHistory_Stddev_Pop_Fields = {
  __typename?: 'zoomEmailHistory_stddev_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomEmailHistory_Stddev_Samp_Fields = {
  __typename?: 'zoomEmailHistory_stddev_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomEmailHistory" */
export type ZoomEmailHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomEmailHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomEmailHistory_Stream_Cursor_Value_Input = {
  bcc?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  emailService?: InputMaybe<Scalars['String']>;
  emailServiceMessageId?: InputMaybe<Scalars['String']>;
  emailServiceResponseJson?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  templateData?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomEventId?: InputMaybe<Scalars['Int']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ZoomEmailHistory_Sum_Fields = {
  __typename?: 'zoomEmailHistory_sum_fields';
  zoomEventId?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoomEmailHistory" */
export enum ZoomEmailHistory_Update_Column {
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  Category = 'category',
  /** column name */
  Cc = 'cc',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EmailService = 'emailService',
  /** column name */
  EmailServiceMessageId = 'emailServiceMessageId',
  /** column name */
  EmailServiceResponseJson = 'emailServiceResponseJson',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  TemplateData = 'templateData',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomEmailHistory_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomEmailHistory_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomEmailHistory_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomEmailHistory_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomEmailHistory_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomEmailHistory_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomEmailHistory_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomEmailHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomEmailHistory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomEmailHistory_Var_Pop_Fields = {
  __typename?: 'zoomEmailHistory_var_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomEmailHistory_Var_Samp_Fields = {
  __typename?: 'zoomEmailHistory_var_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomEmailHistory_Variance_Fields = {
  __typename?: 'zoomEmailHistory_variance_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEvent = {
  __typename?: 'zoomEvent';
  conferenceData?: Maybe<Scalars['jsonb']>;
  created?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  eventId: Scalars['String'];
  eventJson?: Maybe<Scalars['jsonb']>;
  guestsCanInviteOthers: Scalars['Boolean'];
  guestsCanModify: Scalars['Boolean'];
  guestsCanSeeOtherGuests: Scalars['Boolean'];
  hangoutLink?: Maybe<Scalars['String']>;
  htmlLink?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  origin: Scalars['String'];
  seriesMasterId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  zoomEventParticipants: Array<ZoomEventParticipant>;
  /** An aggregate relationship */
  zoomEventParticipants_aggregate: ZoomEventParticipant_Aggregate;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEventConferenceDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEventEventJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEventZoomEventParticipantsArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEventZoomEventParticipants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

/** columns and relationships of "zoomEventParticipant" */
export type ZoomEventParticipant = {
  __typename?: 'zoomEventParticipant';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  eventCanceledAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  inMeetingStatus?: Maybe<Scalars['String']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  organizer?: Maybe<Scalars['Boolean']>;
  responseStatus: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  zoomContact: ZoomContact;
  zoomContactId: Scalars['uuid'];
  /** An object relationship */
  zoomEvent: ZoomEvent;
  zoomEventId: Scalars['Int'];
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomEventParticipantWithContacts" */
export type ZoomEventParticipantWithContacts = {
  __typename?: 'zoomEventParticipantWithContacts';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyCrunchbaseUrl?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyEstimatedSize?: Maybe<Scalars['String']>;
  companyLinkedInUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySeoDescription?: Maybe<Scalars['String']>;
  companyState?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  eventCanceledAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isWarmlyUser?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['jsonb']>;
  logoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  organizer?: Maybe<Scalars['Boolean']>;
  photoUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  rsvpStatus?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  zoomEvent?: Maybe<ZoomEvent>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomEventParticipantWithContacts" */
export type ZoomEventParticipantWithContactsLocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomEventParticipantWithContacts" */
export type ZoomEventParticipantWithContacts_Aggregate = {
  __typename?: 'zoomEventParticipantWithContacts_aggregate';
  aggregate?: Maybe<ZoomEventParticipantWithContacts_Aggregate_Fields>;
  nodes: Array<ZoomEventParticipantWithContacts>;
};

/** aggregate fields of "zoomEventParticipantWithContacts" */
export type ZoomEventParticipantWithContacts_Aggregate_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_aggregate_fields';
  avg?: Maybe<ZoomEventParticipantWithContacts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomEventParticipantWithContacts_Max_Fields>;
  min?: Maybe<ZoomEventParticipantWithContacts_Min_Fields>;
  stddev?: Maybe<ZoomEventParticipantWithContacts_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomEventParticipantWithContacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomEventParticipantWithContacts_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomEventParticipantWithContacts_Sum_Fields>;
  var_pop?: Maybe<ZoomEventParticipantWithContacts_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomEventParticipantWithContacts_Var_Samp_Fields>;
  variance?: Maybe<ZoomEventParticipantWithContacts_Variance_Fields>;
};

/** aggregate fields of "zoomEventParticipantWithContacts" */
export type ZoomEventParticipantWithContacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomEventParticipantWithContacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZoomEventParticipantWithContacts_Avg_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_avg_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomEventParticipantWithContacts". All fields are combined with a logical 'AND'. */
export type ZoomEventParticipantWithContacts_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomEventParticipantWithContacts_Bool_Exp>>;
  _not?: InputMaybe<ZoomEventParticipantWithContacts_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomEventParticipantWithContacts_Bool_Exp>>;
  ask?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  companyCity?: InputMaybe<String_Comparison_Exp>;
  companyCrunchbaseUrl?: InputMaybe<String_Comparison_Exp>;
  companyDescription?: InputMaybe<String_Comparison_Exp>;
  companyEstimatedSize?: InputMaybe<String_Comparison_Exp>;
  companyLinkedInUrl?: InputMaybe<String_Comparison_Exp>;
  companyName?: InputMaybe<String_Comparison_Exp>;
  companySeoDescription?: InputMaybe<String_Comparison_Exp>;
  companyState?: InputMaybe<String_Comparison_Exp>;
  companyWebsiteDomain?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  customBackgroundPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  eventCanceledAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  firstNamePronunciation?: InputMaybe<String_Comparison_Exp>;
  fullNameGenerated?: InputMaybe<String_Comparison_Exp>;
  headline?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isWarmlyUser?: InputMaybe<Boolean_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  lastNamePronunciation?: InputMaybe<String_Comparison_Exp>;
  linkedInUrl?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  logoUrl?: InputMaybe<String_Comparison_Exp>;
  organizationId?: InputMaybe<Uuid_Comparison_Exp>;
  organizer?: InputMaybe<Boolean_Comparison_Exp>;
  photoUrl?: InputMaybe<String_Comparison_Exp>;
  pronouns?: InputMaybe<String_Comparison_Exp>;
  rsvpStatus?: InputMaybe<String_Comparison_Exp>;
  scheduleMeetingUrl?: InputMaybe<String_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  strengths?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  twitterUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  zoomCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomContactId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomEvent?: InputMaybe<ZoomEvent_Bool_Exp>;
  zoomEventId?: InputMaybe<Int_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type ZoomEventParticipantWithContacts_Max_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_max_fields';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyCrunchbaseUrl?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyEstimatedSize?: Maybe<Scalars['String']>;
  companyLinkedInUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySeoDescription?: Maybe<Scalars['String']>;
  companyState?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  eventCanceledAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  rsvpStatus?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomEventParticipantWithContacts_Min_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_min_fields';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyCrunchbaseUrl?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyEstimatedSize?: Maybe<Scalars['String']>;
  companyLinkedInUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySeoDescription?: Maybe<Scalars['String']>;
  companyState?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  eventCanceledAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  photoUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  rsvpStatus?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "zoomEventParticipantWithContacts". */
export type ZoomEventParticipantWithContacts_Order_By = {
  ask?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  companyCity?: InputMaybe<Order_By>;
  companyCrunchbaseUrl?: InputMaybe<Order_By>;
  companyDescription?: InputMaybe<Order_By>;
  companyEstimatedSize?: InputMaybe<Order_By>;
  companyLinkedInUrl?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  companySeoDescription?: InputMaybe<Order_By>;
  companyState?: InputMaybe<Order_By>;
  companyWebsiteDomain?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  customBackgroundPhotoUrl?: InputMaybe<Order_By>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  eventCanceledAt?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  firstNamePronunciation?: InputMaybe<Order_By>;
  fullNameGenerated?: InputMaybe<Order_By>;
  headline?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isWarmlyUser?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastNamePronunciation?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  organizer?: InputMaybe<Order_By>;
  photoUrl?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  rsvpStatus?: InputMaybe<Order_By>;
  scheduleMeetingUrl?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  strengths?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
  zoomContactId?: InputMaybe<Order_By>;
  zoomEvent?: InputMaybe<ZoomEvent_Order_By>;
  zoomEventId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** select columns of table "zoomEventParticipantWithContacts" */
export enum ZoomEventParticipantWithContacts_Select_Column {
  /** column name */
  Ask = 'ask',
  /** column name */
  City = 'city',
  /** column name */
  CompanyCity = 'companyCity',
  /** column name */
  CompanyCrunchbaseUrl = 'companyCrunchbaseUrl',
  /** column name */
  CompanyDescription = 'companyDescription',
  /** column name */
  CompanyEstimatedSize = 'companyEstimatedSize',
  /** column name */
  CompanyLinkedInUrl = 'companyLinkedInUrl',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CompanySeoDescription = 'companySeoDescription',
  /** column name */
  CompanyState = 'companyState',
  /** column name */
  CompanyWebsiteDomain = 'companyWebsiteDomain',
  /** column name */
  Country = 'country',
  /** column name */
  CustomBackgroundPhotoUrl = 'customBackgroundPhotoUrl',
  /** column name */
  DefaultCustomBackgroundPhotoUrl = 'defaultCustomBackgroundPhotoUrl',
  /** column name */
  Email = 'email',
  /** column name */
  EventCanceledAt = 'eventCanceledAt',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FirstNamePronunciation = 'firstNamePronunciation',
  /** column name */
  FullNameGenerated = 'fullNameGenerated',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  IsWarmlyUser = 'isWarmlyUser',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastNamePronunciation = 'lastNamePronunciation',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Location = 'location',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  RsvpStatus = 'rsvpStatus',
  /** column name */
  ScheduleMeetingUrl = 'scheduleMeetingUrl',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  State = 'state',
  /** column name */
  Strengths = 'strengths',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomEventParticipantWithContacts_Sorted_Args = {
  company_id?: InputMaybe<Scalars['uuid']>;
  zoom_event_id?: InputMaybe<Scalars['Int']>;
  zoom_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ZoomEventParticipantWithContacts_Stddev_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_stddev_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomEventParticipantWithContacts_Stddev_Pop_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_stddev_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomEventParticipantWithContacts_Stddev_Samp_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_stddev_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomEventParticipantWithContacts" */
export type ZoomEventParticipantWithContacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomEventParticipantWithContacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomEventParticipantWithContacts_Stream_Cursor_Value_Input = {
  ask?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyCrunchbaseUrl?: InputMaybe<Scalars['String']>;
  companyDescription?: InputMaybe<Scalars['String']>;
  companyEstimatedSize?: InputMaybe<Scalars['String']>;
  companyLinkedInUrl?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companySeoDescription?: InputMaybe<Scalars['String']>;
  companyState?: InputMaybe<Scalars['String']>;
  companyWebsiteDomain?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  eventCanceledAt?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstNamePronunciation?: InputMaybe<Scalars['String']>;
  fullNameGenerated?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isWarmlyUser?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNamePronunciation?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['jsonb']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  organizer?: InputMaybe<Scalars['Boolean']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  rsvpStatus?: InputMaybe<Scalars['String']>;
  scheduleMeetingUrl?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  strengths?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomEventId?: InputMaybe<Scalars['Int']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ZoomEventParticipantWithContacts_Sum_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_sum_fields';
  zoomEventId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type ZoomEventParticipantWithContacts_Var_Pop_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_var_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomEventParticipantWithContacts_Var_Samp_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_var_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomEventParticipantWithContacts_Variance_Fields = {
  __typename?: 'zoomEventParticipantWithContacts_variance_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "zoomEventParticipant" */
export type ZoomEventParticipant_Aggregate = {
  __typename?: 'zoomEventParticipant_aggregate';
  aggregate?: Maybe<ZoomEventParticipant_Aggregate_Fields>;
  nodes: Array<ZoomEventParticipant>;
};

export type ZoomEventParticipant_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ZoomEventParticipant_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ZoomEventParticipant_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ZoomEventParticipant_Aggregate_Bool_Exp_Count>;
};

export type ZoomEventParticipant_Aggregate_Bool_Exp_Bool_And = {
  arguments: ZoomEventParticipant_Select_Column_ZoomEventParticipant_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ZoomEventParticipant_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ZoomEventParticipant_Select_Column_ZoomEventParticipant_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ZoomEventParticipant_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zoomEventParticipant" */
export type ZoomEventParticipant_Aggregate_Fields = {
  __typename?: 'zoomEventParticipant_aggregate_fields';
  avg?: Maybe<ZoomEventParticipant_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomEventParticipant_Max_Fields>;
  min?: Maybe<ZoomEventParticipant_Min_Fields>;
  stddev?: Maybe<ZoomEventParticipant_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomEventParticipant_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomEventParticipant_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomEventParticipant_Sum_Fields>;
  var_pop?: Maybe<ZoomEventParticipant_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomEventParticipant_Var_Samp_Fields>;
  variance?: Maybe<ZoomEventParticipant_Variance_Fields>;
};

/** aggregate fields of "zoomEventParticipant" */
export type ZoomEventParticipant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomEventParticipant" */
export type ZoomEventParticipant_Aggregate_Order_By = {
  avg?: InputMaybe<ZoomEventParticipant_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ZoomEventParticipant_Max_Order_By>;
  min?: InputMaybe<ZoomEventParticipant_Min_Order_By>;
  stddev?: InputMaybe<ZoomEventParticipant_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ZoomEventParticipant_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ZoomEventParticipant_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ZoomEventParticipant_Sum_Order_By>;
  var_pop?: InputMaybe<ZoomEventParticipant_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ZoomEventParticipant_Var_Samp_Order_By>;
  variance?: InputMaybe<ZoomEventParticipant_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "zoomEventParticipant" */
export type ZoomEventParticipant_Arr_Rel_Insert_Input = {
  data: Array<ZoomEventParticipant_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomEventParticipant_On_Conflict>;
};

/** aggregate avg on columns */
export type ZoomEventParticipant_Avg_Fields = {
  __typename?: 'zoomEventParticipant_avg_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Avg_Order_By = {
  zoomEventId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "zoomEventParticipant". All fields are combined with a logical 'AND'. */
export type ZoomEventParticipant_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomEventParticipant_Bool_Exp>>;
  _not?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomEventParticipant_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  eventCanceledAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inMeetingStatus?: InputMaybe<String_Comparison_Exp>;
  lastEnrichedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  organizer?: InputMaybe<Boolean_Comparison_Exp>;
  responseStatus?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomContact?: InputMaybe<ZoomContact_Bool_Exp>;
  zoomContactId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomEvent?: InputMaybe<ZoomEvent_Bool_Exp>;
  zoomEventId?: InputMaybe<Int_Comparison_Exp>;
  zoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomEventParticipant" */
export enum ZoomEventParticipant_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomEventParticipantPkey = 'zoomEventParticipant_pkey',
  /** unique or primary key constraint on columns "zoomEventId", "email" */
  ZoomEventParticipantZoomEventIdEmailKey = 'zoomEventParticipant_zoomEventId_email_key',
}

/** input type for incrementing numeric columns in table "zoomEventParticipant" */
export type ZoomEventParticipant_Inc_Input = {
  zoomEventId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomEventParticipant" */
export type ZoomEventParticipant_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  eventCanceledAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  inMeetingStatus?: InputMaybe<Scalars['String']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  organizer?: InputMaybe<Scalars['Boolean']>;
  responseStatus?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomContact?: InputMaybe<ZoomContact_Obj_Rel_Insert_Input>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomEvent?: InputMaybe<ZoomEvent_Obj_Rel_Insert_Input>;
  zoomEventId?: InputMaybe<Scalars['Int']>;
  zoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomEventParticipant_Max_Fields = {
  __typename?: 'zoomEventParticipant_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  eventCanceledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inMeetingStatus?: Maybe<Scalars['String']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  responseStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  eventCanceledAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inMeetingStatus?: InputMaybe<Order_By>;
  lastEnrichedAt?: InputMaybe<Order_By>;
  responseStatus?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomContactId?: InputMaybe<Order_By>;
  zoomEventId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomEventParticipant_Min_Fields = {
  __typename?: 'zoomEventParticipant_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  eventCanceledAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inMeetingStatus?: Maybe<Scalars['String']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  responseStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  eventCanceledAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inMeetingStatus?: InputMaybe<Order_By>;
  lastEnrichedAt?: InputMaybe<Order_By>;
  responseStatus?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomContactId?: InputMaybe<Order_By>;
  zoomEventId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zoomEventParticipant" */
export type ZoomEventParticipant_Mutation_Response = {
  __typename?: 'zoomEventParticipant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomEventParticipant>;
};

/** on_conflict condition type for table "zoomEventParticipant" */
export type ZoomEventParticipant_On_Conflict = {
  constraint: ZoomEventParticipant_Constraint;
  update_columns?: Array<ZoomEventParticipant_Update_Column>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomEventParticipant". */
export type ZoomEventParticipant_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  eventCanceledAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inMeetingStatus?: InputMaybe<Order_By>;
  lastEnrichedAt?: InputMaybe<Order_By>;
  organizer?: InputMaybe<Order_By>;
  responseStatus?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomContact?: InputMaybe<ZoomContact_Order_By>;
  zoomContactId?: InputMaybe<Order_By>;
  zoomEvent?: InputMaybe<ZoomEvent_Order_By>;
  zoomEventId?: InputMaybe<Order_By>;
  zoomUser?: InputMaybe<ZoomUser_Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomEventParticipant */
export type ZoomEventParticipant_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomEventParticipant" */
export enum ZoomEventParticipant_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EventCanceledAt = 'eventCanceledAt',
  /** column name */
  Id = 'id',
  /** column name */
  InMeetingStatus = 'inMeetingStatus',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  ResponseStatus = 'responseStatus',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** select "zoomEventParticipant_aggregate_bool_exp_bool_and_arguments_columns" columns of table "zoomEventParticipant" */
export enum ZoomEventParticipant_Select_Column_ZoomEventParticipant_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Organizer = 'organizer',
}

/** select "zoomEventParticipant_aggregate_bool_exp_bool_or_arguments_columns" columns of table "zoomEventParticipant" */
export enum ZoomEventParticipant_Select_Column_ZoomEventParticipant_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Organizer = 'organizer',
}

/** input type for updating data in table "zoomEventParticipant" */
export type ZoomEventParticipant_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  eventCanceledAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  inMeetingStatus?: InputMaybe<Scalars['String']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  organizer?: InputMaybe<Scalars['Boolean']>;
  responseStatus?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomEventId?: InputMaybe<Scalars['Int']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ZoomEventParticipant_Stddev_Fields = {
  __typename?: 'zoomEventParticipant_stddev_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Stddev_Order_By = {
  zoomEventId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ZoomEventParticipant_Stddev_Pop_Fields = {
  __typename?: 'zoomEventParticipant_stddev_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Stddev_Pop_Order_By = {
  zoomEventId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ZoomEventParticipant_Stddev_Samp_Fields = {
  __typename?: 'zoomEventParticipant_stddev_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Stddev_Samp_Order_By = {
  zoomEventId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "zoomEventParticipant" */
export type ZoomEventParticipant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomEventParticipant_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomEventParticipant_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  eventCanceledAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  inMeetingStatus?: InputMaybe<Scalars['String']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  organizer?: InputMaybe<Scalars['Boolean']>;
  responseStatus?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomEventId?: InputMaybe<Scalars['Int']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ZoomEventParticipant_Sum_Fields = {
  __typename?: 'zoomEventParticipant_sum_fields';
  zoomEventId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Sum_Order_By = {
  zoomEventId?: InputMaybe<Order_By>;
};

/** update columns of table "zoomEventParticipant" */
export enum ZoomEventParticipant_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EventCanceledAt = 'eventCanceledAt',
  /** column name */
  Id = 'id',
  /** column name */
  InMeetingStatus = 'inMeetingStatus',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  ResponseStatus = 'responseStatus',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomEventParticipant_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomEventParticipant_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomEventParticipant_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomEventParticipant_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomEventParticipant_Var_Pop_Fields = {
  __typename?: 'zoomEventParticipant_var_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Var_Pop_Order_By = {
  zoomEventId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ZoomEventParticipant_Var_Samp_Fields = {
  __typename?: 'zoomEventParticipant_var_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Var_Samp_Order_By = {
  zoomEventId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ZoomEventParticipant_Variance_Fields = {
  __typename?: 'zoomEventParticipant_variance_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Variance_Order_By = {
  zoomEventId?: InputMaybe<Order_By>;
};

/** aggregated selection of "zoomEvent" */
export type ZoomEvent_Aggregate = {
  __typename?: 'zoomEvent_aggregate';
  aggregate?: Maybe<ZoomEvent_Aggregate_Fields>;
  nodes: Array<ZoomEvent>;
};

/** aggregate fields of "zoomEvent" */
export type ZoomEvent_Aggregate_Fields = {
  __typename?: 'zoomEvent_aggregate_fields';
  avg?: Maybe<ZoomEvent_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomEvent_Max_Fields>;
  min?: Maybe<ZoomEvent_Min_Fields>;
  stddev?: Maybe<ZoomEvent_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomEvent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomEvent_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomEvent_Sum_Fields>;
  var_pop?: Maybe<ZoomEvent_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomEvent_Var_Samp_Fields>;
  variance?: Maybe<ZoomEvent_Variance_Fields>;
};

/** aggregate fields of "zoomEvent" */
export type ZoomEvent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomEvent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomEvent_Append_Input = {
  conferenceData?: InputMaybe<Scalars['jsonb']>;
  eventJson?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ZoomEvent_Avg_Fields = {
  __typename?: 'zoomEvent_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomEvent". All fields are combined with a logical 'AND'. */
export type ZoomEvent_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomEvent_Bool_Exp>>;
  _not?: InputMaybe<ZoomEvent_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomEvent_Bool_Exp>>;
  conferenceData?: InputMaybe<Jsonb_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  end?: InputMaybe<Timestamptz_Comparison_Exp>;
  eventId?: InputMaybe<String_Comparison_Exp>;
  eventJson?: InputMaybe<Jsonb_Comparison_Exp>;
  guestsCanInviteOthers?: InputMaybe<Boolean_Comparison_Exp>;
  guestsCanModify?: InputMaybe<Boolean_Comparison_Exp>;
  guestsCanSeeOtherGuests?: InputMaybe<Boolean_Comparison_Exp>;
  hangoutLink?: InputMaybe<String_Comparison_Exp>;
  htmlLink?: InputMaybe<String_Comparison_Exp>;
  iCalUID?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastEnrichedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  seriesMasterId?: InputMaybe<String_Comparison_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  summary?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomEventParticipants?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
  zoomEventParticipants_aggregate?: InputMaybe<ZoomEventParticipant_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomEvent" */
export enum ZoomEvent_Constraint {
  /** unique or primary key constraint on columns "eventId" */
  ZoomEventEventIdKey = 'zoomEvent_eventId_key',
  /** unique or primary key constraint on columns "id" */
  ZoomEventPkey = 'zoomEvent_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomEvent_Delete_At_Path_Input = {
  conferenceData?: InputMaybe<Array<Scalars['String']>>;
  eventJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomEvent_Delete_Elem_Input = {
  conferenceData?: InputMaybe<Scalars['Int']>;
  eventJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomEvent_Delete_Key_Input = {
  conferenceData?: InputMaybe<Scalars['String']>;
  eventJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "zoomEvent" */
export type ZoomEvent_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomEvent" */
export type ZoomEvent_Insert_Input = {
  conferenceData?: InputMaybe<Scalars['jsonb']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  eventId?: InputMaybe<Scalars['String']>;
  eventJson?: InputMaybe<Scalars['jsonb']>;
  guestsCanInviteOthers?: InputMaybe<Scalars['Boolean']>;
  guestsCanModify?: InputMaybe<Scalars['Boolean']>;
  guestsCanSeeOtherGuests?: InputMaybe<Scalars['Boolean']>;
  hangoutLink?: InputMaybe<Scalars['String']>;
  htmlLink?: InputMaybe<Scalars['String']>;
  iCalUID?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  location?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  seriesMasterId?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomEventParticipants?: InputMaybe<ZoomEventParticipant_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomEvent_Max_Fields = {
  __typename?: 'zoomEvent_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  eventId?: Maybe<Scalars['String']>;
  hangoutLink?: Maybe<Scalars['String']>;
  htmlLink?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  seriesMasterId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ZoomEvent_Min_Fields = {
  __typename?: 'zoomEvent_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  eventId?: Maybe<Scalars['String']>;
  hangoutLink?: Maybe<Scalars['String']>;
  htmlLink?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  seriesMasterId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "zoomEvent" */
export type ZoomEvent_Mutation_Response = {
  __typename?: 'zoomEvent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomEvent>;
};

/** input type for inserting object relation for remote table "zoomEvent" */
export type ZoomEvent_Obj_Rel_Insert_Input = {
  data: ZoomEvent_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomEvent_On_Conflict>;
};

/** on_conflict condition type for table "zoomEvent" */
export type ZoomEvent_On_Conflict = {
  constraint: ZoomEvent_Constraint;
  update_columns?: Array<ZoomEvent_Update_Column>;
  where?: InputMaybe<ZoomEvent_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomEvent". */
export type ZoomEvent_Order_By = {
  conferenceData?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  eventId?: InputMaybe<Order_By>;
  eventJson?: InputMaybe<Order_By>;
  guestsCanInviteOthers?: InputMaybe<Order_By>;
  guestsCanModify?: InputMaybe<Order_By>;
  guestsCanSeeOtherGuests?: InputMaybe<Order_By>;
  hangoutLink?: InputMaybe<Order_By>;
  htmlLink?: InputMaybe<Order_By>;
  iCalUID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastEnrichedAt?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  seriesMasterId?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomEventParticipants_aggregate?: InputMaybe<ZoomEventParticipant_Aggregate_Order_By>;
};

/** primary key columns input for table: zoomEvent */
export type ZoomEvent_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomEvent_Prepend_Input = {
  conferenceData?: InputMaybe<Scalars['jsonb']>;
  eventJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomEvent" */
export enum ZoomEvent_Select_Column {
  /** column name */
  ConferenceData = 'conferenceData',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  End = 'end',
  /** column name */
  EventId = 'eventId',
  /** column name */
  EventJson = 'eventJson',
  /** column name */
  GuestsCanInviteOthers = 'guestsCanInviteOthers',
  /** column name */
  GuestsCanModify = 'guestsCanModify',
  /** column name */
  GuestsCanSeeOtherGuests = 'guestsCanSeeOtherGuests',
  /** column name */
  HangoutLink = 'hangoutLink',
  /** column name */
  HtmlLink = 'htmlLink',
  /** column name */
  ICalUid = 'iCalUID',
  /** column name */
  Id = 'id',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  Location = 'location',
  /** column name */
  Origin = 'origin',
  /** column name */
  SeriesMasterId = 'seriesMasterId',
  /** column name */
  Start = 'start',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "zoomEvent" */
export type ZoomEvent_Set_Input = {
  conferenceData?: InputMaybe<Scalars['jsonb']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  eventId?: InputMaybe<Scalars['String']>;
  eventJson?: InputMaybe<Scalars['jsonb']>;
  guestsCanInviteOthers?: InputMaybe<Scalars['Boolean']>;
  guestsCanModify?: InputMaybe<Scalars['Boolean']>;
  guestsCanSeeOtherGuests?: InputMaybe<Scalars['Boolean']>;
  hangoutLink?: InputMaybe<Scalars['String']>;
  htmlLink?: InputMaybe<Scalars['String']>;
  iCalUID?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  location?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  seriesMasterId?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ZoomEvent_Stddev_Fields = {
  __typename?: 'zoomEvent_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomEvent_Stddev_Pop_Fields = {
  __typename?: 'zoomEvent_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomEvent_Stddev_Samp_Fields = {
  __typename?: 'zoomEvent_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomEvent" */
export type ZoomEvent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomEvent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomEvent_Stream_Cursor_Value_Input = {
  conferenceData?: InputMaybe<Scalars['jsonb']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  eventId?: InputMaybe<Scalars['String']>;
  eventJson?: InputMaybe<Scalars['jsonb']>;
  guestsCanInviteOthers?: InputMaybe<Scalars['Boolean']>;
  guestsCanModify?: InputMaybe<Scalars['Boolean']>;
  guestsCanSeeOtherGuests?: InputMaybe<Scalars['Boolean']>;
  hangoutLink?: InputMaybe<Scalars['String']>;
  htmlLink?: InputMaybe<Scalars['String']>;
  iCalUID?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastEnrichedAt?: InputMaybe<Scalars['timestamptz']>;
  location?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  seriesMasterId?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  updated?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ZoomEvent_Sum_Fields = {
  __typename?: 'zoomEvent_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoomEvent" */
export enum ZoomEvent_Update_Column {
  /** column name */
  ConferenceData = 'conferenceData',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  End = 'end',
  /** column name */
  EventId = 'eventId',
  /** column name */
  EventJson = 'eventJson',
  /** column name */
  GuestsCanInviteOthers = 'guestsCanInviteOthers',
  /** column name */
  GuestsCanModify = 'guestsCanModify',
  /** column name */
  GuestsCanSeeOtherGuests = 'guestsCanSeeOtherGuests',
  /** column name */
  HangoutLink = 'hangoutLink',
  /** column name */
  HtmlLink = 'htmlLink',
  /** column name */
  ICalUid = 'iCalUID',
  /** column name */
  Id = 'id',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  Location = 'location',
  /** column name */
  Origin = 'origin',
  /** column name */
  SeriesMasterId = 'seriesMasterId',
  /** column name */
  Start = 'start',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type ZoomEvent_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomEvent_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomEvent_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomEvent_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomEvent_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomEvent_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomEvent_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomEvent_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomEvent_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomEvent_Var_Pop_Fields = {
  __typename?: 'zoomEvent_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomEvent_Var_Samp_Fields = {
  __typename?: 'zoomEvent_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomEvent_Variance_Fields = {
  __typename?: 'zoomEvent_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoomExternalAuthUser" */
export type ZoomExternalAuthUser = {
  __typename?: 'zoomExternalAuthUser';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalUserId: Scalars['String'];
  id: Scalars['uuid'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomAccessToken: Scalars['String'];
  zoomAccountId?: Maybe<Scalars['String']>;
  zoomCreatedAt?: Maybe<Scalars['String']>;
  zoomDepartment?: Maybe<Scalars['String']>;
  zoomEmail?: Maybe<Scalars['String']>;
  zoomFirstName?: Maybe<Scalars['String']>;
  zoomJobTitle?: Maybe<Scalars['String']>;
  zoomLanguage?: Maybe<Scalars['String']>;
  zoomLastClientVersion?: Maybe<Scalars['String']>;
  zoomLastLoginTime?: Maybe<Scalars['String']>;
  zoomLastName?: Maybe<Scalars['String']>;
  zoomLocation?: Maybe<Scalars['String']>;
  zoomPersonalMeetingId?: Maybe<Scalars['String']>;
  zoomPersonalMeetingUrl?: Maybe<Scalars['String']>;
  zoomPhotoUrl?: Maybe<Scalars['String']>;
  zoomPlanType?: Maybe<Scalars['Int']>;
  zoomRefreshToken: Scalars['String'];
  zoomRole?: Maybe<Scalars['String']>;
  zoomRoleId?: Maybe<Scalars['String']>;
  zoomStatus?: Maybe<Scalars['String']>;
  zoomTimeZone?: Maybe<Scalars['String']>;
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
  zoomUserId?: Maybe<Scalars['uuid']>;
  zoomUserJson?: Maybe<Scalars['jsonb']>;
  zoomVerified?: Maybe<Scalars['Int']>;
  zoomVersionCheckedAt?: Maybe<Scalars['date']>;
};

/** columns and relationships of "zoomExternalAuthUser" */
export type ZoomExternalAuthUserZoomUserJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Aggregate = {
  __typename?: 'zoomExternalAuthUser_aggregate';
  aggregate?: Maybe<ZoomExternalAuthUser_Aggregate_Fields>;
  nodes: Array<ZoomExternalAuthUser>;
};

/** aggregate fields of "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Aggregate_Fields = {
  __typename?: 'zoomExternalAuthUser_aggregate_fields';
  avg?: Maybe<ZoomExternalAuthUser_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomExternalAuthUser_Max_Fields>;
  min?: Maybe<ZoomExternalAuthUser_Min_Fields>;
  stddev?: Maybe<ZoomExternalAuthUser_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomExternalAuthUser_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomExternalAuthUser_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomExternalAuthUser_Sum_Fields>;
  var_pop?: Maybe<ZoomExternalAuthUser_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomExternalAuthUser_Var_Samp_Fields>;
  variance?: Maybe<ZoomExternalAuthUser_Variance_Fields>;
};

/** aggregate fields of "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomExternalAuthUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomExternalAuthUser_Append_Input = {
  zoomUserJson?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ZoomExternalAuthUser_Avg_Fields = {
  __typename?: 'zoomExternalAuthUser_avg_fields';
  zoomPlanType?: Maybe<Scalars['Float']>;
  zoomVerified?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomExternalAuthUser". All fields are combined with a logical 'AND'. */
export type ZoomExternalAuthUser_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomExternalAuthUser_Bool_Exp>>;
  _not?: InputMaybe<ZoomExternalAuthUser_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomExternalAuthUser_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  externalUserId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomAccessToken?: InputMaybe<String_Comparison_Exp>;
  zoomAccountId?: InputMaybe<String_Comparison_Exp>;
  zoomCreatedAt?: InputMaybe<String_Comparison_Exp>;
  zoomDepartment?: InputMaybe<String_Comparison_Exp>;
  zoomEmail?: InputMaybe<String_Comparison_Exp>;
  zoomFirstName?: InputMaybe<String_Comparison_Exp>;
  zoomJobTitle?: InputMaybe<String_Comparison_Exp>;
  zoomLanguage?: InputMaybe<String_Comparison_Exp>;
  zoomLastClientVersion?: InputMaybe<String_Comparison_Exp>;
  zoomLastLoginTime?: InputMaybe<String_Comparison_Exp>;
  zoomLastName?: InputMaybe<String_Comparison_Exp>;
  zoomLocation?: InputMaybe<String_Comparison_Exp>;
  zoomPersonalMeetingId?: InputMaybe<String_Comparison_Exp>;
  zoomPersonalMeetingUrl?: InputMaybe<String_Comparison_Exp>;
  zoomPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  zoomPlanType?: InputMaybe<Int_Comparison_Exp>;
  zoomRefreshToken?: InputMaybe<String_Comparison_Exp>;
  zoomRole?: InputMaybe<String_Comparison_Exp>;
  zoomRoleId?: InputMaybe<String_Comparison_Exp>;
  zoomStatus?: InputMaybe<String_Comparison_Exp>;
  zoomTimeZone?: InputMaybe<String_Comparison_Exp>;
  zoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUserJson?: InputMaybe<Jsonb_Comparison_Exp>;
  zoomVerified?: InputMaybe<Int_Comparison_Exp>;
  zoomVersionCheckedAt?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomExternalAuthUser" */
export enum ZoomExternalAuthUser_Constraint {
  /** unique or primary key constraint on columns "externalUserId" */
  ZoomExternalAuthUserExternalUserIdKey = 'zoomExternalAuthUser_externalUserId_key',
  /** unique or primary key constraint on columns "id" */
  ZoomExternalAuthUserPkey = 'zoomExternalAuthUser_pkey',
  /** unique or primary key constraint on columns "zoomEmail" */
  ZoomExternalAuthUserZoomEmailKey = 'zoomExternalAuthUser_zoomEmail_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomExternalAuthUser_Delete_At_Path_Input = {
  zoomUserJson?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomExternalAuthUser_Delete_Elem_Input = {
  zoomUserJson?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomExternalAuthUser_Delete_Key_Input = {
  zoomUserJson?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Inc_Input = {
  zoomPlanType?: InputMaybe<Scalars['Int']>;
  zoomVerified?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomAccessToken?: InputMaybe<Scalars['String']>;
  zoomAccountId?: InputMaybe<Scalars['String']>;
  zoomCreatedAt?: InputMaybe<Scalars['String']>;
  zoomDepartment?: InputMaybe<Scalars['String']>;
  zoomEmail?: InputMaybe<Scalars['String']>;
  zoomFirstName?: InputMaybe<Scalars['String']>;
  zoomJobTitle?: InputMaybe<Scalars['String']>;
  zoomLanguage?: InputMaybe<Scalars['String']>;
  zoomLastClientVersion?: InputMaybe<Scalars['String']>;
  zoomLastLoginTime?: InputMaybe<Scalars['String']>;
  zoomLastName?: InputMaybe<Scalars['String']>;
  zoomLocation?: InputMaybe<Scalars['String']>;
  zoomPersonalMeetingId?: InputMaybe<Scalars['String']>;
  zoomPersonalMeetingUrl?: InputMaybe<Scalars['String']>;
  zoomPhotoUrl?: InputMaybe<Scalars['String']>;
  zoomPlanType?: InputMaybe<Scalars['Int']>;
  zoomRefreshToken?: InputMaybe<Scalars['String']>;
  zoomRole?: InputMaybe<Scalars['String']>;
  zoomRoleId?: InputMaybe<Scalars['String']>;
  zoomStatus?: InputMaybe<Scalars['String']>;
  zoomTimeZone?: InputMaybe<Scalars['String']>;
  zoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
  zoomUserJson?: InputMaybe<Scalars['jsonb']>;
  zoomVerified?: InputMaybe<Scalars['Int']>;
  zoomVersionCheckedAt?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type ZoomExternalAuthUser_Max_Fields = {
  __typename?: 'zoomExternalAuthUser_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomAccessToken?: Maybe<Scalars['String']>;
  zoomAccountId?: Maybe<Scalars['String']>;
  zoomCreatedAt?: Maybe<Scalars['String']>;
  zoomDepartment?: Maybe<Scalars['String']>;
  zoomEmail?: Maybe<Scalars['String']>;
  zoomFirstName?: Maybe<Scalars['String']>;
  zoomJobTitle?: Maybe<Scalars['String']>;
  zoomLanguage?: Maybe<Scalars['String']>;
  zoomLastClientVersion?: Maybe<Scalars['String']>;
  zoomLastLoginTime?: Maybe<Scalars['String']>;
  zoomLastName?: Maybe<Scalars['String']>;
  zoomLocation?: Maybe<Scalars['String']>;
  zoomPersonalMeetingId?: Maybe<Scalars['String']>;
  zoomPersonalMeetingUrl?: Maybe<Scalars['String']>;
  zoomPhotoUrl?: Maybe<Scalars['String']>;
  zoomPlanType?: Maybe<Scalars['Int']>;
  zoomRefreshToken?: Maybe<Scalars['String']>;
  zoomRole?: Maybe<Scalars['String']>;
  zoomRoleId?: Maybe<Scalars['String']>;
  zoomStatus?: Maybe<Scalars['String']>;
  zoomTimeZone?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
  zoomVerified?: Maybe<Scalars['Int']>;
  zoomVersionCheckedAt?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type ZoomExternalAuthUser_Min_Fields = {
  __typename?: 'zoomExternalAuthUser_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomAccessToken?: Maybe<Scalars['String']>;
  zoomAccountId?: Maybe<Scalars['String']>;
  zoomCreatedAt?: Maybe<Scalars['String']>;
  zoomDepartment?: Maybe<Scalars['String']>;
  zoomEmail?: Maybe<Scalars['String']>;
  zoomFirstName?: Maybe<Scalars['String']>;
  zoomJobTitle?: Maybe<Scalars['String']>;
  zoomLanguage?: Maybe<Scalars['String']>;
  zoomLastClientVersion?: Maybe<Scalars['String']>;
  zoomLastLoginTime?: Maybe<Scalars['String']>;
  zoomLastName?: Maybe<Scalars['String']>;
  zoomLocation?: Maybe<Scalars['String']>;
  zoomPersonalMeetingId?: Maybe<Scalars['String']>;
  zoomPersonalMeetingUrl?: Maybe<Scalars['String']>;
  zoomPhotoUrl?: Maybe<Scalars['String']>;
  zoomPlanType?: Maybe<Scalars['Int']>;
  zoomRefreshToken?: Maybe<Scalars['String']>;
  zoomRole?: Maybe<Scalars['String']>;
  zoomRoleId?: Maybe<Scalars['String']>;
  zoomStatus?: Maybe<Scalars['String']>;
  zoomTimeZone?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
  zoomVerified?: Maybe<Scalars['Int']>;
  zoomVersionCheckedAt?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Mutation_Response = {
  __typename?: 'zoomExternalAuthUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomExternalAuthUser>;
};

/** on_conflict condition type for table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_On_Conflict = {
  constraint: ZoomExternalAuthUser_Constraint;
  update_columns?: Array<ZoomExternalAuthUser_Update_Column>;
  where?: InputMaybe<ZoomExternalAuthUser_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomExternalAuthUser". */
export type ZoomExternalAuthUser_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  externalUserId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomAccessToken?: InputMaybe<Order_By>;
  zoomAccountId?: InputMaybe<Order_By>;
  zoomCreatedAt?: InputMaybe<Order_By>;
  zoomDepartment?: InputMaybe<Order_By>;
  zoomEmail?: InputMaybe<Order_By>;
  zoomFirstName?: InputMaybe<Order_By>;
  zoomJobTitle?: InputMaybe<Order_By>;
  zoomLanguage?: InputMaybe<Order_By>;
  zoomLastClientVersion?: InputMaybe<Order_By>;
  zoomLastLoginTime?: InputMaybe<Order_By>;
  zoomLastName?: InputMaybe<Order_By>;
  zoomLocation?: InputMaybe<Order_By>;
  zoomPersonalMeetingId?: InputMaybe<Order_By>;
  zoomPersonalMeetingUrl?: InputMaybe<Order_By>;
  zoomPhotoUrl?: InputMaybe<Order_By>;
  zoomPlanType?: InputMaybe<Order_By>;
  zoomRefreshToken?: InputMaybe<Order_By>;
  zoomRole?: InputMaybe<Order_By>;
  zoomRoleId?: InputMaybe<Order_By>;
  zoomStatus?: InputMaybe<Order_By>;
  zoomTimeZone?: InputMaybe<Order_By>;
  zoomUser?: InputMaybe<ZoomUser_Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
  zoomUserJson?: InputMaybe<Order_By>;
  zoomVerified?: InputMaybe<Order_By>;
  zoomVersionCheckedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomExternalAuthUser */
export type ZoomExternalAuthUser_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomExternalAuthUser_Prepend_Input = {
  zoomUserJson?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomExternalAuthUser" */
export enum ZoomExternalAuthUser_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalUserId = 'externalUserId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomAccessToken = 'zoomAccessToken',
  /** column name */
  ZoomAccountId = 'zoomAccountId',
  /** column name */
  ZoomCreatedAt = 'zoomCreatedAt',
  /** column name */
  ZoomDepartment = 'zoomDepartment',
  /** column name */
  ZoomEmail = 'zoomEmail',
  /** column name */
  ZoomFirstName = 'zoomFirstName',
  /** column name */
  ZoomJobTitle = 'zoomJobTitle',
  /** column name */
  ZoomLanguage = 'zoomLanguage',
  /** column name */
  ZoomLastClientVersion = 'zoomLastClientVersion',
  /** column name */
  ZoomLastLoginTime = 'zoomLastLoginTime',
  /** column name */
  ZoomLastName = 'zoomLastName',
  /** column name */
  ZoomLocation = 'zoomLocation',
  /** column name */
  ZoomPersonalMeetingId = 'zoomPersonalMeetingId',
  /** column name */
  ZoomPersonalMeetingUrl = 'zoomPersonalMeetingUrl',
  /** column name */
  ZoomPhotoUrl = 'zoomPhotoUrl',
  /** column name */
  ZoomPlanType = 'zoomPlanType',
  /** column name */
  ZoomRefreshToken = 'zoomRefreshToken',
  /** column name */
  ZoomRole = 'zoomRole',
  /** column name */
  ZoomRoleId = 'zoomRoleId',
  /** column name */
  ZoomStatus = 'zoomStatus',
  /** column name */
  ZoomTimeZone = 'zoomTimeZone',
  /** column name */
  ZoomUserId = 'zoomUserId',
  /** column name */
  ZoomUserJson = 'zoomUserJson',
  /** column name */
  ZoomVerified = 'zoomVerified',
  /** column name */
  ZoomVersionCheckedAt = 'zoomVersionCheckedAt',
}

/** input type for updating data in table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomAccessToken?: InputMaybe<Scalars['String']>;
  zoomAccountId?: InputMaybe<Scalars['String']>;
  zoomCreatedAt?: InputMaybe<Scalars['String']>;
  zoomDepartment?: InputMaybe<Scalars['String']>;
  zoomEmail?: InputMaybe<Scalars['String']>;
  zoomFirstName?: InputMaybe<Scalars['String']>;
  zoomJobTitle?: InputMaybe<Scalars['String']>;
  zoomLanguage?: InputMaybe<Scalars['String']>;
  zoomLastClientVersion?: InputMaybe<Scalars['String']>;
  zoomLastLoginTime?: InputMaybe<Scalars['String']>;
  zoomLastName?: InputMaybe<Scalars['String']>;
  zoomLocation?: InputMaybe<Scalars['String']>;
  zoomPersonalMeetingId?: InputMaybe<Scalars['String']>;
  zoomPersonalMeetingUrl?: InputMaybe<Scalars['String']>;
  zoomPhotoUrl?: InputMaybe<Scalars['String']>;
  zoomPlanType?: InputMaybe<Scalars['Int']>;
  zoomRefreshToken?: InputMaybe<Scalars['String']>;
  zoomRole?: InputMaybe<Scalars['String']>;
  zoomRoleId?: InputMaybe<Scalars['String']>;
  zoomStatus?: InputMaybe<Scalars['String']>;
  zoomTimeZone?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
  zoomUserJson?: InputMaybe<Scalars['jsonb']>;
  zoomVerified?: InputMaybe<Scalars['Int']>;
  zoomVersionCheckedAt?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type ZoomExternalAuthUser_Stddev_Fields = {
  __typename?: 'zoomExternalAuthUser_stddev_fields';
  zoomPlanType?: Maybe<Scalars['Float']>;
  zoomVerified?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomExternalAuthUser_Stddev_Pop_Fields = {
  __typename?: 'zoomExternalAuthUser_stddev_pop_fields';
  zoomPlanType?: Maybe<Scalars['Float']>;
  zoomVerified?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomExternalAuthUser_Stddev_Samp_Fields = {
  __typename?: 'zoomExternalAuthUser_stddev_samp_fields';
  zoomPlanType?: Maybe<Scalars['Float']>;
  zoomVerified?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomExternalAuthUser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomExternalAuthUser_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomAccessToken?: InputMaybe<Scalars['String']>;
  zoomAccountId?: InputMaybe<Scalars['String']>;
  zoomCreatedAt?: InputMaybe<Scalars['String']>;
  zoomDepartment?: InputMaybe<Scalars['String']>;
  zoomEmail?: InputMaybe<Scalars['String']>;
  zoomFirstName?: InputMaybe<Scalars['String']>;
  zoomJobTitle?: InputMaybe<Scalars['String']>;
  zoomLanguage?: InputMaybe<Scalars['String']>;
  zoomLastClientVersion?: InputMaybe<Scalars['String']>;
  zoomLastLoginTime?: InputMaybe<Scalars['String']>;
  zoomLastName?: InputMaybe<Scalars['String']>;
  zoomLocation?: InputMaybe<Scalars['String']>;
  zoomPersonalMeetingId?: InputMaybe<Scalars['String']>;
  zoomPersonalMeetingUrl?: InputMaybe<Scalars['String']>;
  zoomPhotoUrl?: InputMaybe<Scalars['String']>;
  zoomPlanType?: InputMaybe<Scalars['Int']>;
  zoomRefreshToken?: InputMaybe<Scalars['String']>;
  zoomRole?: InputMaybe<Scalars['String']>;
  zoomRoleId?: InputMaybe<Scalars['String']>;
  zoomStatus?: InputMaybe<Scalars['String']>;
  zoomTimeZone?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
  zoomUserJson?: InputMaybe<Scalars['jsonb']>;
  zoomVerified?: InputMaybe<Scalars['Int']>;
  zoomVersionCheckedAt?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type ZoomExternalAuthUser_Sum_Fields = {
  __typename?: 'zoomExternalAuthUser_sum_fields';
  zoomPlanType?: Maybe<Scalars['Int']>;
  zoomVerified?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoomExternalAuthUser" */
export enum ZoomExternalAuthUser_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExternalUserId = 'externalUserId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomAccessToken = 'zoomAccessToken',
  /** column name */
  ZoomAccountId = 'zoomAccountId',
  /** column name */
  ZoomCreatedAt = 'zoomCreatedAt',
  /** column name */
  ZoomDepartment = 'zoomDepartment',
  /** column name */
  ZoomEmail = 'zoomEmail',
  /** column name */
  ZoomFirstName = 'zoomFirstName',
  /** column name */
  ZoomJobTitle = 'zoomJobTitle',
  /** column name */
  ZoomLanguage = 'zoomLanguage',
  /** column name */
  ZoomLastClientVersion = 'zoomLastClientVersion',
  /** column name */
  ZoomLastLoginTime = 'zoomLastLoginTime',
  /** column name */
  ZoomLastName = 'zoomLastName',
  /** column name */
  ZoomLocation = 'zoomLocation',
  /** column name */
  ZoomPersonalMeetingId = 'zoomPersonalMeetingId',
  /** column name */
  ZoomPersonalMeetingUrl = 'zoomPersonalMeetingUrl',
  /** column name */
  ZoomPhotoUrl = 'zoomPhotoUrl',
  /** column name */
  ZoomPlanType = 'zoomPlanType',
  /** column name */
  ZoomRefreshToken = 'zoomRefreshToken',
  /** column name */
  ZoomRole = 'zoomRole',
  /** column name */
  ZoomRoleId = 'zoomRoleId',
  /** column name */
  ZoomStatus = 'zoomStatus',
  /** column name */
  ZoomTimeZone = 'zoomTimeZone',
  /** column name */
  ZoomUserId = 'zoomUserId',
  /** column name */
  ZoomUserJson = 'zoomUserJson',
  /** column name */
  ZoomVerified = 'zoomVerified',
  /** column name */
  ZoomVersionCheckedAt = 'zoomVersionCheckedAt',
}

export type ZoomExternalAuthUser_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomExternalAuthUser_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomExternalAuthUser_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomExternalAuthUser_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomExternalAuthUser_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomExternalAuthUser_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomExternalAuthUser_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomExternalAuthUser_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomExternalAuthUser_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomExternalAuthUser_Var_Pop_Fields = {
  __typename?: 'zoomExternalAuthUser_var_pop_fields';
  zoomPlanType?: Maybe<Scalars['Float']>;
  zoomVerified?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomExternalAuthUser_Var_Samp_Fields = {
  __typename?: 'zoomExternalAuthUser_var_samp_fields';
  zoomPlanType?: Maybe<Scalars['Float']>;
  zoomVerified?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomExternalAuthUser_Variance_Fields = {
  __typename?: 'zoomExternalAuthUser_variance_fields';
  zoomPlanType?: Maybe<Scalars['Float']>;
  zoomVerified?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoomGroup" */
export type ZoomGroup = {
  __typename?: 'zoomGroup';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  identifier?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  memberships: Array<ZoomGroupMembership>;
  /** An aggregate relationship */
  memberships_aggregate: ZoomGroupMembership_Aggregate;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_group_pk_as_text" */
  textId?: Maybe<Scalars['citext']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  workOSGroupId?: Maybe<Scalars['String']>;
  /** An object relationship */
  zoomCompany?: Maybe<ZoomCompany>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  zoomOrganization?: Maybe<ZoomOrganization>;
};

/** columns and relationships of "zoomGroup" */
export type ZoomGroupMembershipsArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

/** columns and relationships of "zoomGroup" */
export type ZoomGroupMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

/** columns and relationships of "zoomGroupMembership" */
export type ZoomGroupMembership = {
  __typename?: 'zoomGroupMembership';
  /** An object relationship */
  group: ZoomGroup;
  id: Scalars['uuid'];
  type: Scalars['String'];
  /** An object relationship */
  user: ZoomUser;
  zoomGroupId: Scalars['uuid'];
  zoomUserId: Scalars['uuid'];
};

/** aggregated selection of "zoomGroupMembership" */
export type ZoomGroupMembership_Aggregate = {
  __typename?: 'zoomGroupMembership_aggregate';
  aggregate?: Maybe<ZoomGroupMembership_Aggregate_Fields>;
  nodes: Array<ZoomGroupMembership>;
};

export type ZoomGroupMembership_Aggregate_Bool_Exp = {
  count?: InputMaybe<ZoomGroupMembership_Aggregate_Bool_Exp_Count>;
};

export type ZoomGroupMembership_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zoomGroupMembership" */
export type ZoomGroupMembership_Aggregate_Fields = {
  __typename?: 'zoomGroupMembership_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomGroupMembership_Max_Fields>;
  min?: Maybe<ZoomGroupMembership_Min_Fields>;
};

/** aggregate fields of "zoomGroupMembership" */
export type ZoomGroupMembership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomGroupMembership" */
export type ZoomGroupMembership_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ZoomGroupMembership_Max_Order_By>;
  min?: InputMaybe<ZoomGroupMembership_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomGroupMembership" */
export type ZoomGroupMembership_Arr_Rel_Insert_Input = {
  data: Array<ZoomGroupMembership_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomGroupMembership_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomGroupMembership". All fields are combined with a logical 'AND'. */
export type ZoomGroupMembership_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomGroupMembership_Bool_Exp>>;
  _not?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomGroupMembership_Bool_Exp>>;
  group?: InputMaybe<ZoomGroup_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<ZoomUser_Bool_Exp>;
  zoomGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomGroupMembership" */
export enum ZoomGroupMembership_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomGroupMembershipPkey = 'zoomGroupMembership_pkey',
}

/** input type for inserting data into table "zoomGroupMembership" */
export type ZoomGroupMembership_Insert_Input = {
  group?: InputMaybe<ZoomGroup_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomGroupId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomGroupMembership_Max_Fields = {
  __typename?: 'zoomGroupMembership_max_fields';
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  zoomGroupId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomGroupMembership" */
export type ZoomGroupMembership_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  zoomGroupId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomGroupMembership_Min_Fields = {
  __typename?: 'zoomGroupMembership_min_fields';
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  zoomGroupId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomGroupMembership" */
export type ZoomGroupMembership_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  zoomGroupId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zoomGroupMembership" */
export type ZoomGroupMembership_Mutation_Response = {
  __typename?: 'zoomGroupMembership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomGroupMembership>;
};

/** on_conflict condition type for table "zoomGroupMembership" */
export type ZoomGroupMembership_On_Conflict = {
  constraint: ZoomGroupMembership_Constraint;
  update_columns?: Array<ZoomGroupMembership_Update_Column>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomGroupMembership". */
export type ZoomGroupMembership_Order_By = {
  group?: InputMaybe<ZoomGroup_Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<ZoomUser_Order_By>;
  zoomGroupId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomGroupMembership */
export type ZoomGroupMembership_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomGroupMembership" */
export enum ZoomGroupMembership_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  ZoomGroupId = 'zoomGroupId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomGroupMembership" */
export type ZoomGroupMembership_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  zoomGroupId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomGroupMembership" */
export type ZoomGroupMembership_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomGroupMembership_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomGroupMembership_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  zoomGroupId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomGroupMembership" */
export enum ZoomGroupMembership_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  ZoomGroupId = 'zoomGroupId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomGroupMembership_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomGroupMembership_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomGroupMembership_Bool_Exp;
};

/** aggregated selection of "zoomGroup" */
export type ZoomGroup_Aggregate = {
  __typename?: 'zoomGroup_aggregate';
  aggregate?: Maybe<ZoomGroup_Aggregate_Fields>;
  nodes: Array<ZoomGroup>;
};

/** aggregate fields of "zoomGroup" */
export type ZoomGroup_Aggregate_Fields = {
  __typename?: 'zoomGroup_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomGroup_Max_Fields>;
  min?: Maybe<ZoomGroup_Min_Fields>;
};

/** aggregate fields of "zoomGroup" */
export type ZoomGroup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomGroup_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zoomGroup". All fields are combined with a logical 'AND'. */
export type ZoomGroup_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomGroup_Bool_Exp>>;
  _not?: InputMaybe<ZoomGroup_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomGroup_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  estimatedSize?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  logoUrl?: InputMaybe<String_Comparison_Exp>;
  memberships?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
  memberships_aggregate?: InputMaybe<ZoomGroupMembership_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  textId?: InputMaybe<Citext_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  websiteDomain?: InputMaybe<String_Comparison_Exp>;
  websiteUrl?: InputMaybe<String_Comparison_Exp>;
  workOSGroupId?: InputMaybe<String_Comparison_Exp>;
  zoomCompany?: InputMaybe<ZoomCompany_Bool_Exp>;
  zoomCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomOrganization?: InputMaybe<ZoomOrganization_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomGroup" */
export enum ZoomGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomGroupPkey = 'zoomGroup_pkey',
  /** unique or primary key constraint on columns "websiteDomain" */
  ZoomGroupWebsiteDomainKey = 'zoomGroup_websiteDomain_key',
}

/** input type for inserting data into table "zoomGroup" */
export type ZoomGroup_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  memberships?: InputMaybe<ZoomGroupMembership_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  workOSGroupId?: InputMaybe<Scalars['String']>;
  zoomCompany?: InputMaybe<ZoomCompany_Obj_Rel_Insert_Input>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
  zoomOrganization?: InputMaybe<ZoomOrganization_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomGroup_Max_Fields = {
  __typename?: 'zoomGroup_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_group_pk_as_text" */
  textId?: Maybe<Scalars['citext']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  workOSGroupId?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomGroup_Min_Fields = {
  __typename?: 'zoomGroup_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_group_pk_as_text" */
  textId?: Maybe<Scalars['citext']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  workOSGroupId?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomGroup" */
export type ZoomGroup_Mutation_Response = {
  __typename?: 'zoomGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomGroup>;
};

/** input type for inserting object relation for remote table "zoomGroup" */
export type ZoomGroup_Obj_Rel_Insert_Input = {
  data: ZoomGroup_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomGroup_On_Conflict>;
};

/** on_conflict condition type for table "zoomGroup" */
export type ZoomGroup_On_Conflict = {
  constraint: ZoomGroup_Constraint;
  update_columns?: Array<ZoomGroup_Update_Column>;
  where?: InputMaybe<ZoomGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomGroup". */
export type ZoomGroup_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  estimatedSize?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  memberships_aggregate?: InputMaybe<ZoomGroupMembership_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  textId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteDomain?: InputMaybe<Order_By>;
  websiteUrl?: InputMaybe<Order_By>;
  workOSGroupId?: InputMaybe<Order_By>;
  zoomCompany?: InputMaybe<ZoomCompany_Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
  zoomOrganization?: InputMaybe<ZoomOrganization_Order_By>;
};

/** primary key columns input for table: zoomGroup */
export type ZoomGroup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomGroup" */
export enum ZoomGroup_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteUrl = 'websiteUrl',
  /** column name */
  WorkOsGroupId = 'workOSGroupId',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

/** input type for updating data in table "zoomGroup" */
export type ZoomGroup_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  workOSGroupId?: InputMaybe<Scalars['String']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomGroup" */
export type ZoomGroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomGroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomGroup_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  workOSGroupId?: InputMaybe<Scalars['String']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomGroup" */
export enum ZoomGroup_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteUrl = 'websiteUrl',
  /** column name */
  WorkOsGroupId = 'workOSGroupId',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

export type ZoomGroup_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomGroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomGroup_Bool_Exp;
};

/** columns and relationships of "zoomLayersEvents" */
export type ZoomLayersEvents = {
  __typename?: 'zoomLayersEvents';
  createdAt: Scalars['timestamp'];
  eventData: Scalars['jsonb'];
  id: Scalars['uuid'];
  zoomMeetingID: Scalars['String'];
};

/** columns and relationships of "zoomLayersEvents" */
export type ZoomLayersEventsEventDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomLayersEvents" */
export type ZoomLayersEvents_Aggregate = {
  __typename?: 'zoomLayersEvents_aggregate';
  aggregate?: Maybe<ZoomLayersEvents_Aggregate_Fields>;
  nodes: Array<ZoomLayersEvents>;
};

/** aggregate fields of "zoomLayersEvents" */
export type ZoomLayersEvents_Aggregate_Fields = {
  __typename?: 'zoomLayersEvents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomLayersEvents_Max_Fields>;
  min?: Maybe<ZoomLayersEvents_Min_Fields>;
};

/** aggregate fields of "zoomLayersEvents" */
export type ZoomLayersEvents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomLayersEvents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomLayersEvents_Append_Input = {
  eventData?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "zoomLayersEvents". All fields are combined with a logical 'AND'. */
export type ZoomLayersEvents_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomLayersEvents_Bool_Exp>>;
  _not?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomLayersEvents_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  eventData?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  zoomMeetingID?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomLayersEvents" */
export enum ZoomLayersEvents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomLayersEventsPkey = 'zoomLayersEvents_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomLayersEvents_Delete_At_Path_Input = {
  eventData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomLayersEvents_Delete_Elem_Input = {
  eventData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomLayersEvents_Delete_Key_Input = {
  eventData?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "zoomLayersEvents" */
export type ZoomLayersEvents_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  eventData?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomMeetingID?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ZoomLayersEvents_Max_Fields = {
  __typename?: 'zoomLayersEvents_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  zoomMeetingID?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ZoomLayersEvents_Min_Fields = {
  __typename?: 'zoomLayersEvents_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  zoomMeetingID?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zoomLayersEvents" */
export type ZoomLayersEvents_Mutation_Response = {
  __typename?: 'zoomLayersEvents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomLayersEvents>;
};

/** on_conflict condition type for table "zoomLayersEvents" */
export type ZoomLayersEvents_On_Conflict = {
  constraint: ZoomLayersEvents_Constraint;
  update_columns?: Array<ZoomLayersEvents_Update_Column>;
  where?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomLayersEvents". */
export type ZoomLayersEvents_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  eventData?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  zoomMeetingID?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomLayersEvents */
export type ZoomLayersEvents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomLayersEvents_Prepend_Input = {
  eventData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomLayersEvents" */
export enum ZoomLayersEvents_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventData = 'eventData',
  /** column name */
  Id = 'id',
  /** column name */
  ZoomMeetingId = 'zoomMeetingID',
}

/** input type for updating data in table "zoomLayersEvents" */
export type ZoomLayersEvents_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  eventData?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomMeetingID?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "zoomLayersEvents" */
export type ZoomLayersEvents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomLayersEvents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomLayersEvents_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  eventData?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomMeetingID?: InputMaybe<Scalars['String']>;
};

/** update columns of table "zoomLayersEvents" */
export enum ZoomLayersEvents_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventData = 'eventData',
  /** column name */
  Id = 'id',
  /** column name */
  ZoomMeetingId = 'zoomMeetingID',
}

export type ZoomLayersEvents_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomLayersEvents_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomLayersEvents_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomLayersEvents_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomLayersEvents_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomLayersEvents_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomLayersEvents_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomLayersEvents_Bool_Exp;
};

/** columns and relationships of "zoomOrganization" */
export type ZoomOrganization = {
  __typename?: 'zoomOrganization';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isFreeEmailDomain: Scalars['Boolean'];
  /** An array relationship */
  memberships: Array<ZoomOrganizationMembership>;
  /** An aggregate relationship */
  memberships_aggregate: ZoomOrganizationMembership_Aggregate;
  name: Scalars['String'];
  /** A computed field, executes function "org_admin_exists" */
  orgAdminExists?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['String']>;
  otherDomains: Scalars['jsonb'];
  ssoDomains: Scalars['jsonb'];
  /** A computed field, executes function "get_org_pk_as_text" */
  textId?: Maybe<Scalars['citext']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  zoomCompany?: Maybe<ZoomCompany>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomOrganization" */
export type ZoomOrganizationMembershipsArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembership_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
};

/** columns and relationships of "zoomOrganization" */
export type ZoomOrganizationMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembership_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
};

/** columns and relationships of "zoomOrganization" */
export type ZoomOrganizationOtherDomainsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomOrganization" */
export type ZoomOrganizationSsoDomainsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill = {
  __typename?: 'zoomOrganizationBackfill';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  results: Array<ZoomOrganizationBackfillResult>;
  /** An aggregate relationship */
  results_aggregate: ZoomOrganizationBackfillResult_Aggregate;
  unassociatedUserCount: Scalars['Int'];
};

/** columns and relationships of "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfillResultsArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfillResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfillResult_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
};

/** columns and relationships of "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfillResults_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationBackfillResult_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationBackfillResult_Order_By>>;
  where?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
};

/** columns and relationships of "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult = {
  __typename?: 'zoomOrganizationBackfillResult';
  /** An object relationship */
  createdMembership?: Maybe<ZoomOrganizationMembership>;
  createdMembershipId?: Maybe<Scalars['uuid']>;
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  zoomOrganizationBackfill: ZoomOrganizationBackfill;
  zoomOrganizationBackfillId: Scalars['uuid'];
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
  zoomUserId: Scalars['uuid'];
};

/** aggregated selection of "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Aggregate = {
  __typename?: 'zoomOrganizationBackfillResult_aggregate';
  aggregate?: Maybe<ZoomOrganizationBackfillResult_Aggregate_Fields>;
  nodes: Array<ZoomOrganizationBackfillResult>;
};

export type ZoomOrganizationBackfillResult_Aggregate_Bool_Exp = {
  count?: InputMaybe<ZoomOrganizationBackfillResult_Aggregate_Bool_Exp_Count>;
};

export type ZoomOrganizationBackfillResult_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ZoomOrganizationBackfillResult_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Aggregate_Fields = {
  __typename?: 'zoomOrganizationBackfillResult_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomOrganizationBackfillResult_Max_Fields>;
  min?: Maybe<ZoomOrganizationBackfillResult_Min_Fields>;
};

/** aggregate fields of "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomOrganizationBackfillResult_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ZoomOrganizationBackfillResult_Max_Order_By>;
  min?: InputMaybe<ZoomOrganizationBackfillResult_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Arr_Rel_Insert_Input = {
  data: Array<ZoomOrganizationBackfillResult_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomOrganizationBackfillResult_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomOrganizationBackfillResult". All fields are combined with a logical 'AND'. */
export type ZoomOrganizationBackfillResult_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomOrganizationBackfillResult_Bool_Exp>>;
  _not?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomOrganizationBackfillResult_Bool_Exp>>;
  createdMembership?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
  createdMembershipId?: InputMaybe<Uuid_Comparison_Exp>;
  errorMessage?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  zoomOrganizationBackfill?: InputMaybe<ZoomOrganizationBackfill_Bool_Exp>;
  zoomOrganizationBackfillId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomOrganizationBackfillResult" */
export enum ZoomOrganizationBackfillResult_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomOrganizationBackfillResultPkey = 'zoomOrganizationBackfillResult_pkey',
}

/** input type for inserting data into table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Insert_Input = {
  createdMembership?: InputMaybe<ZoomOrganizationMembership_Obj_Rel_Insert_Input>;
  createdMembershipId?: InputMaybe<Scalars['uuid']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomOrganizationBackfill?: InputMaybe<ZoomOrganizationBackfill_Obj_Rel_Insert_Input>;
  zoomOrganizationBackfillId?: InputMaybe<Scalars['uuid']>;
  zoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomOrganizationBackfillResult_Max_Fields = {
  __typename?: 'zoomOrganizationBackfillResult_max_fields';
  createdMembershipId?: Maybe<Scalars['uuid']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  zoomOrganizationBackfillId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Max_Order_By = {
  createdMembershipId?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  zoomOrganizationBackfillId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomOrganizationBackfillResult_Min_Fields = {
  __typename?: 'zoomOrganizationBackfillResult_min_fields';
  createdMembershipId?: Maybe<Scalars['uuid']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  zoomOrganizationBackfillId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Min_Order_By = {
  createdMembershipId?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  zoomOrganizationBackfillId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Mutation_Response = {
  __typename?: 'zoomOrganizationBackfillResult_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomOrganizationBackfillResult>;
};

/** on_conflict condition type for table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_On_Conflict = {
  constraint: ZoomOrganizationBackfillResult_Constraint;
  update_columns?: Array<ZoomOrganizationBackfillResult_Update_Column>;
  where?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomOrganizationBackfillResult". */
export type ZoomOrganizationBackfillResult_Order_By = {
  createdMembership?: InputMaybe<ZoomOrganizationMembership_Order_By>;
  createdMembershipId?: InputMaybe<Order_By>;
  errorMessage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  zoomOrganizationBackfill?: InputMaybe<ZoomOrganizationBackfill_Order_By>;
  zoomOrganizationBackfillId?: InputMaybe<Order_By>;
  zoomUser?: InputMaybe<ZoomUser_Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomOrganizationBackfillResult */
export type ZoomOrganizationBackfillResult_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomOrganizationBackfillResult" */
export enum ZoomOrganizationBackfillResult_Select_Column {
  /** column name */
  CreatedMembershipId = 'createdMembershipId',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  Id = 'id',
  /** column name */
  ZoomOrganizationBackfillId = 'zoomOrganizationBackfillId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Set_Input = {
  createdMembershipId?: InputMaybe<Scalars['uuid']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomOrganizationBackfillId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomOrganizationBackfillResult" */
export type ZoomOrganizationBackfillResult_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomOrganizationBackfillResult_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomOrganizationBackfillResult_Stream_Cursor_Value_Input = {
  createdMembershipId?: InputMaybe<Scalars['uuid']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  zoomOrganizationBackfillId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomOrganizationBackfillResult" */
export enum ZoomOrganizationBackfillResult_Update_Column {
  /** column name */
  CreatedMembershipId = 'createdMembershipId',
  /** column name */
  ErrorMessage = 'errorMessage',
  /** column name */
  Id = 'id',
  /** column name */
  ZoomOrganizationBackfillId = 'zoomOrganizationBackfillId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomOrganizationBackfillResult_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomOrganizationBackfillResult_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomOrganizationBackfillResult_Bool_Exp;
};

/** aggregated selection of "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Aggregate = {
  __typename?: 'zoomOrganizationBackfill_aggregate';
  aggregate?: Maybe<ZoomOrganizationBackfill_Aggregate_Fields>;
  nodes: Array<ZoomOrganizationBackfill>;
};

/** aggregate fields of "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Aggregate_Fields = {
  __typename?: 'zoomOrganizationBackfill_aggregate_fields';
  avg?: Maybe<ZoomOrganizationBackfill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomOrganizationBackfill_Max_Fields>;
  min?: Maybe<ZoomOrganizationBackfill_Min_Fields>;
  stddev?: Maybe<ZoomOrganizationBackfill_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomOrganizationBackfill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomOrganizationBackfill_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomOrganizationBackfill_Sum_Fields>;
  var_pop?: Maybe<ZoomOrganizationBackfill_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomOrganizationBackfill_Var_Samp_Fields>;
  variance?: Maybe<ZoomOrganizationBackfill_Variance_Fields>;
};

/** aggregate fields of "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomOrganizationBackfill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZoomOrganizationBackfill_Avg_Fields = {
  __typename?: 'zoomOrganizationBackfill_avg_fields';
  unassociatedUserCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomOrganizationBackfill". All fields are combined with a logical 'AND'. */
export type ZoomOrganizationBackfill_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomOrganizationBackfill_Bool_Exp>>;
  _not?: InputMaybe<ZoomOrganizationBackfill_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomOrganizationBackfill_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  results?: InputMaybe<ZoomOrganizationBackfillResult_Bool_Exp>;
  results_aggregate?: InputMaybe<ZoomOrganizationBackfillResult_Aggregate_Bool_Exp>;
  unassociatedUserCount?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomOrganizationBackfill" */
export enum ZoomOrganizationBackfill_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomOrganizationBackfillPkey = 'zoomOrganizationBackfill_pkey',
}

/** input type for incrementing numeric columns in table "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Inc_Input = {
  unassociatedUserCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  results?: InputMaybe<ZoomOrganizationBackfillResult_Arr_Rel_Insert_Input>;
  unassociatedUserCount?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ZoomOrganizationBackfill_Max_Fields = {
  __typename?: 'zoomOrganizationBackfill_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  unassociatedUserCount?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ZoomOrganizationBackfill_Min_Fields = {
  __typename?: 'zoomOrganizationBackfill_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  unassociatedUserCount?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Mutation_Response = {
  __typename?: 'zoomOrganizationBackfill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomOrganizationBackfill>;
};

/** input type for inserting object relation for remote table "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Obj_Rel_Insert_Input = {
  data: ZoomOrganizationBackfill_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomOrganizationBackfill_On_Conflict>;
};

/** on_conflict condition type for table "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_On_Conflict = {
  constraint: ZoomOrganizationBackfill_Constraint;
  update_columns?: Array<ZoomOrganizationBackfill_Update_Column>;
  where?: InputMaybe<ZoomOrganizationBackfill_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomOrganizationBackfill". */
export type ZoomOrganizationBackfill_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  results_aggregate?: InputMaybe<ZoomOrganizationBackfillResult_Aggregate_Order_By>;
  unassociatedUserCount?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomOrganizationBackfill */
export type ZoomOrganizationBackfill_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomOrganizationBackfill" */
export enum ZoomOrganizationBackfill_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UnassociatedUserCount = 'unassociatedUserCount',
}

/** input type for updating data in table "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  unassociatedUserCount?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ZoomOrganizationBackfill_Stddev_Fields = {
  __typename?: 'zoomOrganizationBackfill_stddev_fields';
  unassociatedUserCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomOrganizationBackfill_Stddev_Pop_Fields = {
  __typename?: 'zoomOrganizationBackfill_stddev_pop_fields';
  unassociatedUserCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomOrganizationBackfill_Stddev_Samp_Fields = {
  __typename?: 'zoomOrganizationBackfill_stddev_samp_fields';
  unassociatedUserCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomOrganizationBackfill" */
export type ZoomOrganizationBackfill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomOrganizationBackfill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomOrganizationBackfill_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  unassociatedUserCount?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ZoomOrganizationBackfill_Sum_Fields = {
  __typename?: 'zoomOrganizationBackfill_sum_fields';
  unassociatedUserCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoomOrganizationBackfill" */
export enum ZoomOrganizationBackfill_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UnassociatedUserCount = 'unassociatedUserCount',
}

export type ZoomOrganizationBackfill_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomOrganizationBackfill_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomOrganizationBackfill_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomOrganizationBackfill_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomOrganizationBackfill_Var_Pop_Fields = {
  __typename?: 'zoomOrganizationBackfill_var_pop_fields';
  unassociatedUserCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomOrganizationBackfill_Var_Samp_Fields = {
  __typename?: 'zoomOrganizationBackfill_var_samp_fields';
  unassociatedUserCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomOrganizationBackfill_Variance_Fields = {
  __typename?: 'zoomOrganizationBackfill_variance_fields';
  unassociatedUserCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoomOrganizationMembership" */
export type ZoomOrganizationMembership = {
  __typename?: 'zoomOrganizationMembership';
  id: Scalars['uuid'];
  /** An object relationship */
  organization: ZoomOrganization;
  type: Scalars['String'];
  /** An object relationship */
  user: ZoomUser;
  zoomOrganizationId: Scalars['uuid'];
  zoomUserId: Scalars['uuid'];
};

/** columns and relationships of "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView = {
  __typename?: 'zoomOrganizationMembershipView';
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  zoomOrganizationId?: Maybe<Scalars['uuid']>;
  zoomOrganizationTextId?: Maybe<Scalars['citext']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Aggregate = {
  __typename?: 'zoomOrganizationMembershipView_aggregate';
  aggregate?: Maybe<ZoomOrganizationMembershipView_Aggregate_Fields>;
  nodes: Array<ZoomOrganizationMembershipView>;
};

export type ZoomOrganizationMembershipView_Aggregate_Bool_Exp = {
  count?: InputMaybe<ZoomOrganizationMembershipView_Aggregate_Bool_Exp_Count>;
};

export type ZoomOrganizationMembershipView_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ZoomOrganizationMembershipView_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Aggregate_Fields = {
  __typename?: 'zoomOrganizationMembershipView_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomOrganizationMembershipView_Max_Fields>;
  min?: Maybe<ZoomOrganizationMembershipView_Min_Fields>;
};

/** aggregate fields of "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomOrganizationMembershipView_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ZoomOrganizationMembershipView_Max_Order_By>;
  min?: InputMaybe<ZoomOrganizationMembershipView_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Arr_Rel_Insert_Input = {
  data: Array<ZoomOrganizationMembershipView_Insert_Input>;
};

/** Boolean expression to filter rows from the table "zoomOrganizationMembershipView". All fields are combined with a logical 'AND'. */
export type ZoomOrganizationMembershipView_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomOrganizationMembershipView_Bool_Exp>>;
  _not?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomOrganizationMembershipView_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  zoomOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomOrganizationTextId?: InputMaybe<Citext_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  zoomOrganizationId?: InputMaybe<Scalars['uuid']>;
  zoomOrganizationTextId?: InputMaybe<Scalars['citext']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomOrganizationMembershipView_Max_Fields = {
  __typename?: 'zoomOrganizationMembershipView_max_fields';
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  zoomOrganizationId?: Maybe<Scalars['uuid']>;
  zoomOrganizationTextId?: Maybe<Scalars['citext']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  zoomOrganizationId?: InputMaybe<Order_By>;
  zoomOrganizationTextId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomOrganizationMembershipView_Min_Fields = {
  __typename?: 'zoomOrganizationMembershipView_min_fields';
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  zoomOrganizationId?: Maybe<Scalars['uuid']>;
  zoomOrganizationTextId?: Maybe<Scalars['citext']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  zoomOrganizationId?: InputMaybe<Order_By>;
  zoomOrganizationTextId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Mutation_Response = {
  __typename?: 'zoomOrganizationMembershipView_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomOrganizationMembershipView>;
};

/** Ordering options when selecting data from "zoomOrganizationMembershipView". */
export type ZoomOrganizationMembershipView_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  zoomOrganizationId?: InputMaybe<Order_By>;
  zoomOrganizationTextId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** select columns of table "zoomOrganizationMembershipView" */
export enum ZoomOrganizationMembershipView_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  ZoomOrganizationId = 'zoomOrganizationId',
  /** column name */
  ZoomOrganizationTextId = 'zoomOrganizationTextId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  zoomOrganizationId?: InputMaybe<Scalars['uuid']>;
  zoomOrganizationTextId?: InputMaybe<Scalars['citext']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomOrganizationMembershipView" */
export type ZoomOrganizationMembershipView_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomOrganizationMembershipView_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomOrganizationMembershipView_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  zoomOrganizationId?: InputMaybe<Scalars['uuid']>;
  zoomOrganizationTextId?: InputMaybe<Scalars['citext']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

export type ZoomOrganizationMembershipView_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomOrganizationMembershipView_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomOrganizationMembershipView_Bool_Exp;
};

/** aggregated selection of "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Aggregate = {
  __typename?: 'zoomOrganizationMembership_aggregate';
  aggregate?: Maybe<ZoomOrganizationMembership_Aggregate_Fields>;
  nodes: Array<ZoomOrganizationMembership>;
};

export type ZoomOrganizationMembership_Aggregate_Bool_Exp = {
  count?: InputMaybe<ZoomOrganizationMembership_Aggregate_Bool_Exp_Count>;
};

export type ZoomOrganizationMembership_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Aggregate_Fields = {
  __typename?: 'zoomOrganizationMembership_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomOrganizationMembership_Max_Fields>;
  min?: Maybe<ZoomOrganizationMembership_Min_Fields>;
};

/** aggregate fields of "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ZoomOrganizationMembership_Max_Order_By>;
  min?: InputMaybe<ZoomOrganizationMembership_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Arr_Rel_Insert_Input = {
  data: Array<ZoomOrganizationMembership_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomOrganizationMembership_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomOrganizationMembership". All fields are combined with a logical 'AND'. */
export type ZoomOrganizationMembership_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomOrganizationMembership_Bool_Exp>>;
  _not?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomOrganizationMembership_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<ZoomOrganization_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<ZoomUser_Bool_Exp>;
  zoomOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomOrganizationMembership" */
export enum ZoomOrganizationMembership_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomOrganizationMembershipPkey = 'zoomOrganizationMembership_pkey',
  /** unique or primary key constraint on columns "zoomUserId" */
  ZoomOrganizationMembershipZoomUserIdKey = 'zoomOrganizationMembership_zoomUserId_key',
}

/** input type for inserting data into table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  organization?: InputMaybe<ZoomOrganization_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomOrganizationId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomOrganizationMembership_Max_Fields = {
  __typename?: 'zoomOrganizationMembership_max_fields';
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  zoomOrganizationId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  zoomOrganizationId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomOrganizationMembership_Min_Fields = {
  __typename?: 'zoomOrganizationMembership_min_fields';
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  zoomOrganizationId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  zoomOrganizationId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Mutation_Response = {
  __typename?: 'zoomOrganizationMembership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomOrganizationMembership>;
};

/** input type for inserting object relation for remote table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Obj_Rel_Insert_Input = {
  data: ZoomOrganizationMembership_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomOrganizationMembership_On_Conflict>;
};

/** on_conflict condition type for table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_On_Conflict = {
  constraint: ZoomOrganizationMembership_Constraint;
  update_columns?: Array<ZoomOrganizationMembership_Update_Column>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomOrganizationMembership". */
export type ZoomOrganizationMembership_Order_By = {
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<ZoomOrganization_Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<ZoomUser_Order_By>;
  zoomOrganizationId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomOrganizationMembership */
export type ZoomOrganizationMembership_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomOrganizationMembership" */
export enum ZoomOrganizationMembership_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  ZoomOrganizationId = 'zoomOrganizationId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  zoomOrganizationId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomOrganizationMembership" */
export type ZoomOrganizationMembership_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomOrganizationMembership_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomOrganizationMembership_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  zoomOrganizationId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomOrganizationMembership" */
export enum ZoomOrganizationMembership_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  ZoomOrganizationId = 'zoomOrganizationId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomOrganizationMembership_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomOrganizationMembership_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomOrganizationMembership_Bool_Exp;
};

/** aggregated selection of "zoomOrganization" */
export type ZoomOrganization_Aggregate = {
  __typename?: 'zoomOrganization_aggregate';
  aggregate?: Maybe<ZoomOrganization_Aggregate_Fields>;
  nodes: Array<ZoomOrganization>;
};

/** aggregate fields of "zoomOrganization" */
export type ZoomOrganization_Aggregate_Fields = {
  __typename?: 'zoomOrganization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomOrganization_Max_Fields>;
  min?: Maybe<ZoomOrganization_Min_Fields>;
};

/** aggregate fields of "zoomOrganization" */
export type ZoomOrganization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomOrganization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomOrganization_Append_Input = {
  otherDomains?: InputMaybe<Scalars['jsonb']>;
  ssoDomains?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "zoomOrganization". All fields are combined with a logical 'AND'. */
export type ZoomOrganization_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomOrganization_Bool_Exp>>;
  _not?: InputMaybe<ZoomOrganization_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomOrganization_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isFreeEmailDomain?: InputMaybe<Boolean_Comparison_Exp>;
  memberships?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
  memberships_aggregate?: InputMaybe<ZoomOrganizationMembership_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orgAdminExists?: InputMaybe<Boolean_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  otherDomains?: InputMaybe<Jsonb_Comparison_Exp>;
  ssoDomains?: InputMaybe<Jsonb_Comparison_Exp>;
  textId?: InputMaybe<Citext_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomCompany?: InputMaybe<ZoomCompany_Bool_Exp>;
  zoomCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomOrganization" */
export enum ZoomOrganization_Constraint {
  /** unique or primary key constraint on columns "organizationId" */
  ZoomOrganizationOrganizationIdKey = 'zoomOrganization_organizationId_key',
  /** unique or primary key constraint on columns "id" */
  ZoomOrganizationPkey = 'zoomOrganization_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomOrganization_Delete_At_Path_Input = {
  otherDomains?: InputMaybe<Array<Scalars['String']>>;
  ssoDomains?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomOrganization_Delete_Elem_Input = {
  otherDomains?: InputMaybe<Scalars['Int']>;
  ssoDomains?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomOrganization_Delete_Key_Input = {
  otherDomains?: InputMaybe<Scalars['String']>;
  ssoDomains?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "zoomOrganization" */
export type ZoomOrganization_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFreeEmailDomain?: InputMaybe<Scalars['Boolean']>;
  memberships?: InputMaybe<ZoomOrganizationMembership_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  otherDomains?: InputMaybe<Scalars['jsonb']>;
  ssoDomains?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompany?: InputMaybe<ZoomCompany_Obj_Rel_Insert_Input>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomOrganization_Max_Fields = {
  __typename?: 'zoomOrganization_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_org_pk_as_text" */
  textId?: Maybe<Scalars['citext']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomOrganization_Min_Fields = {
  __typename?: 'zoomOrganization_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_org_pk_as_text" */
  textId?: Maybe<Scalars['citext']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomOrganization" */
export type ZoomOrganization_Mutation_Response = {
  __typename?: 'zoomOrganization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomOrganization>;
};

/** input type for inserting object relation for remote table "zoomOrganization" */
export type ZoomOrganization_Obj_Rel_Insert_Input = {
  data: ZoomOrganization_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomOrganization_On_Conflict>;
};

/** on_conflict condition type for table "zoomOrganization" */
export type ZoomOrganization_On_Conflict = {
  constraint: ZoomOrganization_Constraint;
  update_columns?: Array<ZoomOrganization_Update_Column>;
  where?: InputMaybe<ZoomOrganization_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomOrganization". */
export type ZoomOrganization_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isFreeEmailDomain?: InputMaybe<Order_By>;
  memberships_aggregate?: InputMaybe<ZoomOrganizationMembership_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  orgAdminExists?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  otherDomains?: InputMaybe<Order_By>;
  ssoDomains?: InputMaybe<Order_By>;
  textId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomCompany?: InputMaybe<ZoomCompany_Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomOrganization */
export type ZoomOrganization_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomOrganization_Prepend_Input = {
  otherDomains?: InputMaybe<Scalars['jsonb']>;
  ssoDomains?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomOrganization" */
export enum ZoomOrganization_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsFreeEmailDomain = 'isFreeEmailDomain',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OtherDomains = 'otherDomains',
  /** column name */
  SsoDomains = 'ssoDomains',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

/** input type for updating data in table "zoomOrganization" */
export type ZoomOrganization_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFreeEmailDomain?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  otherDomains?: InputMaybe<Scalars['jsonb']>;
  ssoDomains?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomOrganization" */
export type ZoomOrganization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomOrganization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomOrganization_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isFreeEmailDomain?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  otherDomains?: InputMaybe<Scalars['jsonb']>;
  ssoDomains?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomOrganization" */
export enum ZoomOrganization_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsFreeEmailDomain = 'isFreeEmailDomain',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OtherDomains = 'otherDomains',
  /** column name */
  SsoDomains = 'ssoDomains',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

export type ZoomOrganization_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomOrganization_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomOrganization_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomOrganization_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomOrganization_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomOrganization_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomOrganization_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomOrganization_Bool_Exp;
};

/** columns and relationships of "zoomPlan" */
export type ZoomPlan = {
  __typename?: 'zoomPlan';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  stagePlanId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomEntitlements: Scalars['jsonb'];
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomPlan" */
export type ZoomPlanZoomEntitlementsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomPlan" */
export type ZoomPlan_Aggregate = {
  __typename?: 'zoomPlan_aggregate';
  aggregate?: Maybe<ZoomPlan_Aggregate_Fields>;
  nodes: Array<ZoomPlan>;
};

/** aggregate fields of "zoomPlan" */
export type ZoomPlan_Aggregate_Fields = {
  __typename?: 'zoomPlan_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomPlan_Max_Fields>;
  min?: Maybe<ZoomPlan_Min_Fields>;
};

/** aggregate fields of "zoomPlan" */
export type ZoomPlan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomPlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomPlan_Append_Input = {
  zoomEntitlements?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "zoomPlan". All fields are combined with a logical 'AND'. */
export type ZoomPlan_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomPlan_Bool_Exp>>;
  _not?: InputMaybe<ZoomPlan_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomPlan_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  stagePlanId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomEntitlements?: InputMaybe<Jsonb_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomPlan" */
export enum ZoomPlan_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomPlanPkey = 'zoomPlan_pkey',
  /** unique or primary key constraint on columns "zoomUserId" */
  ZoomPlanZoomUserIdKey = 'zoomPlan_zoomUserId_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomPlan_Delete_At_Path_Input = {
  zoomEntitlements?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomPlan_Delete_Elem_Input = {
  zoomEntitlements?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomPlan_Delete_Key_Input = {
  zoomEntitlements?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "zoomPlan" */
export type ZoomPlan_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  stagePlanId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomEntitlements?: InputMaybe<Scalars['jsonb']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomPlan_Max_Fields = {
  __typename?: 'zoomPlan_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  stagePlanId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomPlan_Min_Fields = {
  __typename?: 'zoomPlan_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  stagePlanId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomPlan" */
export type ZoomPlan_Mutation_Response = {
  __typename?: 'zoomPlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomPlan>;
};

/** input type for inserting object relation for remote table "zoomPlan" */
export type ZoomPlan_Obj_Rel_Insert_Input = {
  data: ZoomPlan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomPlan_On_Conflict>;
};

/** on_conflict condition type for table "zoomPlan" */
export type ZoomPlan_On_Conflict = {
  constraint: ZoomPlan_Constraint;
  update_columns?: Array<ZoomPlan_Update_Column>;
  where?: InputMaybe<ZoomPlan_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomPlan". */
export type ZoomPlan_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stagePlanId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomEntitlements?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomPlan */
export type ZoomPlan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomPlan_Prepend_Input = {
  zoomEntitlements?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomPlan" */
export enum ZoomPlan_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  StagePlanId = 'stagePlanId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomEntitlements = 'zoomEntitlements',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomPlan" */
export type ZoomPlan_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  stagePlanId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomEntitlements?: InputMaybe<Scalars['jsonb']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomPlan" */
export type ZoomPlan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomPlan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomPlan_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  stagePlanId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomEntitlements?: InputMaybe<Scalars['jsonb']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomPlan" */
export enum ZoomPlan_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  StagePlanId = 'stagePlanId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomEntitlements = 'zoomEntitlements',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomPlan_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomPlan_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomPlan_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomPlan_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomPlan_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomPlan_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomPlan_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomPlan_Bool_Exp;
};

/** columns and relationships of "zoomPresentation" */
export type ZoomPresentation = {
  __typename?: 'zoomPresentation';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  slidesCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "zoomPresentation" */
export type ZoomPresentation_Aggregate = {
  __typename?: 'zoomPresentation_aggregate';
  aggregate?: Maybe<ZoomPresentation_Aggregate_Fields>;
  nodes: Array<ZoomPresentation>;
};

/** aggregate fields of "zoomPresentation" */
export type ZoomPresentation_Aggregate_Fields = {
  __typename?: 'zoomPresentation_aggregate_fields';
  avg?: Maybe<ZoomPresentation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomPresentation_Max_Fields>;
  min?: Maybe<ZoomPresentation_Min_Fields>;
  stddev?: Maybe<ZoomPresentation_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomPresentation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomPresentation_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomPresentation_Sum_Fields>;
  var_pop?: Maybe<ZoomPresentation_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomPresentation_Var_Samp_Fields>;
  variance?: Maybe<ZoomPresentation_Variance_Fields>;
};

/** aggregate fields of "zoomPresentation" */
export type ZoomPresentation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomPresentation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZoomPresentation_Avg_Fields = {
  __typename?: 'zoomPresentation_avg_fields';
  slidesCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomPresentation". All fields are combined with a logical 'AND'. */
export type ZoomPresentation_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomPresentation_Bool_Exp>>;
  _not?: InputMaybe<ZoomPresentation_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomPresentation_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slidesCount?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomPresentation" */
export enum ZoomPresentation_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomPresentationPkey = 'zoomPresentation_pkey',
}

/** input type for incrementing numeric columns in table "zoomPresentation" */
export type ZoomPresentation_Inc_Input = {
  slidesCount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomPresentation" */
export type ZoomPresentation_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slidesCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomPresentation_Max_Fields = {
  __typename?: 'zoomPresentation_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slidesCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomPresentation_Min_Fields = {
  __typename?: 'zoomPresentation_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slidesCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zoomPresentation" */
export type ZoomPresentation_Mutation_Response = {
  __typename?: 'zoomPresentation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomPresentation>;
};

/** on_conflict condition type for table "zoomPresentation" */
export type ZoomPresentation_On_Conflict = {
  constraint: ZoomPresentation_Constraint;
  update_columns?: Array<ZoomPresentation_Update_Column>;
  where?: InputMaybe<ZoomPresentation_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomPresentation". */
export type ZoomPresentation_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slidesCount?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomPresentation */
export type ZoomPresentation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomPresentation" */
export enum ZoomPresentation_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SlidesCount = 'slidesCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomPresentation" */
export type ZoomPresentation_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slidesCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ZoomPresentation_Stddev_Fields = {
  __typename?: 'zoomPresentation_stddev_fields';
  slidesCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomPresentation_Stddev_Pop_Fields = {
  __typename?: 'zoomPresentation_stddev_pop_fields';
  slidesCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomPresentation_Stddev_Samp_Fields = {
  __typename?: 'zoomPresentation_stddev_samp_fields';
  slidesCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomPresentation" */
export type ZoomPresentation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomPresentation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomPresentation_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  slidesCount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ZoomPresentation_Sum_Fields = {
  __typename?: 'zoomPresentation_sum_fields';
  slidesCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoomPresentation" */
export enum ZoomPresentation_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SlidesCount = 'slidesCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomPresentation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomPresentation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomPresentation_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomPresentation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomPresentation_Var_Pop_Fields = {
  __typename?: 'zoomPresentation_var_pop_fields';
  slidesCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomPresentation_Var_Samp_Fields = {
  __typename?: 'zoomPresentation_var_samp_fields';
  slidesCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomPresentation_Variance_Fields = {
  __typename?: 'zoomPresentation_variance_fields';
  slidesCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoomSettings" */
export type ZoomSettings = {
  __typename?: 'zoomSettings';
  createdAt: Scalars['date'];
  createdByUserId?: Maybe<Scalars['uuid']>;
  defaultCalendarSignaturesOnForRecurringMeetings: Scalars['Boolean'];
  disableAiBackground: Scalars['Boolean'];
  disableBackgroundUpload: Scalars['Boolean'];
  disableCalendarIntegration: Scalars['Boolean'];
  disableCalendarPromptOnZoomLogin: Scalars['Boolean'];
  disableCalendarSignaturesByDefault: Scalars['Boolean'];
  disableImageUpload: Scalars['Boolean'];
  disableNonWarmlyCalendarSignaturesByDefault: Scalars['Boolean'];
  disablePublicProfiles: Scalars['Boolean'];
  id: Scalars['Int'];
  identifier: Scalars['citext'];
  identifierId?: Maybe<Scalars['citext']>;
  meetingBoostSettings: Scalars['jsonb'];
  skipExperiments: Scalars['Boolean'];
  updatedAt: Scalars['date'];
  userProfileSettings: Scalars['jsonb'];
  /** An array relationship */
  zoomOrganizationMemberships: Array<ZoomOrganizationMembershipView>;
  /** An aggregate relationship */
  zoomOrganizationMemberships_aggregate: ZoomOrganizationMembershipView_Aggregate;
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
};

/** columns and relationships of "zoomSettings" */
export type ZoomSettingsMeetingBoostSettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomSettings" */
export type ZoomSettingsUserProfileSettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomSettings" */
export type ZoomSettingsZoomOrganizationMembershipsArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembershipView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembershipView_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
};

/** columns and relationships of "zoomSettings" */
export type ZoomSettingsZoomOrganizationMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembershipView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembershipView_Order_By>>;
  where?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
};

/** aggregated selection of "zoomSettings" */
export type ZoomSettings_Aggregate = {
  __typename?: 'zoomSettings_aggregate';
  aggregate?: Maybe<ZoomSettings_Aggregate_Fields>;
  nodes: Array<ZoomSettings>;
};

/** aggregate fields of "zoomSettings" */
export type ZoomSettings_Aggregate_Fields = {
  __typename?: 'zoomSettings_aggregate_fields';
  avg?: Maybe<ZoomSettings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomSettings_Max_Fields>;
  min?: Maybe<ZoomSettings_Min_Fields>;
  stddev?: Maybe<ZoomSettings_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomSettings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomSettings_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomSettings_Sum_Fields>;
  var_pop?: Maybe<ZoomSettings_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomSettings_Var_Samp_Fields>;
  variance?: Maybe<ZoomSettings_Variance_Fields>;
};

/** aggregate fields of "zoomSettings" */
export type ZoomSettings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomSettings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomSettings_Append_Input = {
  meetingBoostSettings?: InputMaybe<Scalars['jsonb']>;
  userProfileSettings?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ZoomSettings_Avg_Fields = {
  __typename?: 'zoomSettings_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomSettings". All fields are combined with a logical 'AND'. */
export type ZoomSettings_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomSettings_Bool_Exp>>;
  _not?: InputMaybe<ZoomSettings_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomSettings_Bool_Exp>>;
  createdAt?: InputMaybe<Date_Comparison_Exp>;
  createdByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  defaultCalendarSignaturesOnForRecurringMeetings?: InputMaybe<Boolean_Comparison_Exp>;
  disableAiBackground?: InputMaybe<Boolean_Comparison_Exp>;
  disableBackgroundUpload?: InputMaybe<Boolean_Comparison_Exp>;
  disableCalendarIntegration?: InputMaybe<Boolean_Comparison_Exp>;
  disableCalendarPromptOnZoomLogin?: InputMaybe<Boolean_Comparison_Exp>;
  disableCalendarSignaturesByDefault?: InputMaybe<Boolean_Comparison_Exp>;
  disableImageUpload?: InputMaybe<Boolean_Comparison_Exp>;
  disableNonWarmlyCalendarSignaturesByDefault?: InputMaybe<Boolean_Comparison_Exp>;
  disablePublicProfiles?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identifier?: InputMaybe<Citext_Comparison_Exp>;
  identifierId?: InputMaybe<Citext_Comparison_Exp>;
  meetingBoostSettings?: InputMaybe<Jsonb_Comparison_Exp>;
  skipExperiments?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Date_Comparison_Exp>;
  userProfileSettings?: InputMaybe<Jsonb_Comparison_Exp>;
  zoomOrganizationMemberships?: InputMaybe<ZoomOrganizationMembershipView_Bool_Exp>;
  zoomOrganizationMemberships_aggregate?: InputMaybe<ZoomOrganizationMembershipView_Aggregate_Bool_Exp>;
  zoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomSettings" */
export enum ZoomSettings_Constraint {
  /** unique or primary key constraint on columns "identifierId", "identifier" */
  ZoomSettingsIdentifierIdIdentifierKey = 'zoomSettings_identifierId_identifier_key',
  /** unique or primary key constraint on columns "id" */
  ZoomSettingsPkey = 'zoomSettings_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomSettings_Delete_At_Path_Input = {
  meetingBoostSettings?: InputMaybe<Array<Scalars['String']>>;
  userProfileSettings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomSettings_Delete_Elem_Input = {
  meetingBoostSettings?: InputMaybe<Scalars['Int']>;
  userProfileSettings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomSettings_Delete_Key_Input = {
  meetingBoostSettings?: InputMaybe<Scalars['String']>;
  userProfileSettings?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "zoomSettings" */
export type ZoomSettings_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomSettings" */
export type ZoomSettings_Insert_Input = {
  createdAt?: InputMaybe<Scalars['date']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  defaultCalendarSignaturesOnForRecurringMeetings?: InputMaybe<Scalars['Boolean']>;
  disableAiBackground?: InputMaybe<Scalars['Boolean']>;
  disableBackgroundUpload?: InputMaybe<Scalars['Boolean']>;
  disableCalendarIntegration?: InputMaybe<Scalars['Boolean']>;
  disableCalendarPromptOnZoomLogin?: InputMaybe<Scalars['Boolean']>;
  disableCalendarSignaturesByDefault?: InputMaybe<Scalars['Boolean']>;
  disableImageUpload?: InputMaybe<Scalars['Boolean']>;
  disableNonWarmlyCalendarSignaturesByDefault?: InputMaybe<Scalars['Boolean']>;
  disablePublicProfiles?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identifier?: InputMaybe<Scalars['citext']>;
  identifierId?: InputMaybe<Scalars['citext']>;
  meetingBoostSettings?: InputMaybe<Scalars['jsonb']>;
  skipExperiments?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['date']>;
  userProfileSettings?: InputMaybe<Scalars['jsonb']>;
  zoomOrganizationMemberships?: InputMaybe<ZoomOrganizationMembershipView_Arr_Rel_Insert_Input>;
  zoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomSettings_Max_Fields = {
  __typename?: 'zoomSettings_max_fields';
  createdAt?: Maybe<Scalars['date']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['citext']>;
  identifierId?: Maybe<Scalars['citext']>;
  updatedAt?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type ZoomSettings_Min_Fields = {
  __typename?: 'zoomSettings_min_fields';
  createdAt?: Maybe<Scalars['date']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['citext']>;
  identifierId?: Maybe<Scalars['citext']>;
  updatedAt?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "zoomSettings" */
export type ZoomSettings_Mutation_Response = {
  __typename?: 'zoomSettings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomSettings>;
};

/** on_conflict condition type for table "zoomSettings" */
export type ZoomSettings_On_Conflict = {
  constraint: ZoomSettings_Constraint;
  update_columns?: Array<ZoomSettings_Update_Column>;
  where?: InputMaybe<ZoomSettings_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomSettings". */
export type ZoomSettings_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  defaultCalendarSignaturesOnForRecurringMeetings?: InputMaybe<Order_By>;
  disableAiBackground?: InputMaybe<Order_By>;
  disableBackgroundUpload?: InputMaybe<Order_By>;
  disableCalendarIntegration?: InputMaybe<Order_By>;
  disableCalendarPromptOnZoomLogin?: InputMaybe<Order_By>;
  disableCalendarSignaturesByDefault?: InputMaybe<Order_By>;
  disableImageUpload?: InputMaybe<Order_By>;
  disableNonWarmlyCalendarSignaturesByDefault?: InputMaybe<Order_By>;
  disablePublicProfiles?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  identifierId?: InputMaybe<Order_By>;
  meetingBoostSettings?: InputMaybe<Order_By>;
  skipExperiments?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userProfileSettings?: InputMaybe<Order_By>;
  zoomOrganizationMemberships_aggregate?: InputMaybe<ZoomOrganizationMembershipView_Aggregate_Order_By>;
  zoomUser?: InputMaybe<ZoomUser_Order_By>;
};

/** primary key columns input for table: zoomSettings */
export type ZoomSettings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomSettings_Prepend_Input = {
  meetingBoostSettings?: InputMaybe<Scalars['jsonb']>;
  userProfileSettings?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomSettings" */
export enum ZoomSettings_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByUserId = 'createdByUserId',
  /** column name */
  DefaultCalendarSignaturesOnForRecurringMeetings = 'defaultCalendarSignaturesOnForRecurringMeetings',
  /** column name */
  DisableAiBackground = 'disableAiBackground',
  /** column name */
  DisableBackgroundUpload = 'disableBackgroundUpload',
  /** column name */
  DisableCalendarIntegration = 'disableCalendarIntegration',
  /** column name */
  DisableCalendarPromptOnZoomLogin = 'disableCalendarPromptOnZoomLogin',
  /** column name */
  DisableCalendarSignaturesByDefault = 'disableCalendarSignaturesByDefault',
  /** column name */
  DisableImageUpload = 'disableImageUpload',
  /** column name */
  DisableNonWarmlyCalendarSignaturesByDefault = 'disableNonWarmlyCalendarSignaturesByDefault',
  /** column name */
  DisablePublicProfiles = 'disablePublicProfiles',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  IdentifierId = 'identifierId',
  /** column name */
  MeetingBoostSettings = 'meetingBoostSettings',
  /** column name */
  SkipExperiments = 'skipExperiments',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserProfileSettings = 'userProfileSettings',
}

/** input type for updating data in table "zoomSettings" */
export type ZoomSettings_Set_Input = {
  createdAt?: InputMaybe<Scalars['date']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  defaultCalendarSignaturesOnForRecurringMeetings?: InputMaybe<Scalars['Boolean']>;
  disableAiBackground?: InputMaybe<Scalars['Boolean']>;
  disableBackgroundUpload?: InputMaybe<Scalars['Boolean']>;
  disableCalendarIntegration?: InputMaybe<Scalars['Boolean']>;
  disableCalendarPromptOnZoomLogin?: InputMaybe<Scalars['Boolean']>;
  disableCalendarSignaturesByDefault?: InputMaybe<Scalars['Boolean']>;
  disableImageUpload?: InputMaybe<Scalars['Boolean']>;
  disableNonWarmlyCalendarSignaturesByDefault?: InputMaybe<Scalars['Boolean']>;
  disablePublicProfiles?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identifier?: InputMaybe<Scalars['citext']>;
  identifierId?: InputMaybe<Scalars['citext']>;
  meetingBoostSettings?: InputMaybe<Scalars['jsonb']>;
  skipExperiments?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['date']>;
  userProfileSettings?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type ZoomSettings_Stddev_Fields = {
  __typename?: 'zoomSettings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomSettings_Stddev_Pop_Fields = {
  __typename?: 'zoomSettings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomSettings_Stddev_Samp_Fields = {
  __typename?: 'zoomSettings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomSettings" */
export type ZoomSettings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomSettings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomSettings_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['date']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  defaultCalendarSignaturesOnForRecurringMeetings?: InputMaybe<Scalars['Boolean']>;
  disableAiBackground?: InputMaybe<Scalars['Boolean']>;
  disableBackgroundUpload?: InputMaybe<Scalars['Boolean']>;
  disableCalendarIntegration?: InputMaybe<Scalars['Boolean']>;
  disableCalendarPromptOnZoomLogin?: InputMaybe<Scalars['Boolean']>;
  disableCalendarSignaturesByDefault?: InputMaybe<Scalars['Boolean']>;
  disableImageUpload?: InputMaybe<Scalars['Boolean']>;
  disableNonWarmlyCalendarSignaturesByDefault?: InputMaybe<Scalars['Boolean']>;
  disablePublicProfiles?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  identifier?: InputMaybe<Scalars['citext']>;
  identifierId?: InputMaybe<Scalars['citext']>;
  meetingBoostSettings?: InputMaybe<Scalars['jsonb']>;
  skipExperiments?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['date']>;
  userProfileSettings?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type ZoomSettings_Sum_Fields = {
  __typename?: 'zoomSettings_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoomSettings" */
export enum ZoomSettings_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByUserId = 'createdByUserId',
  /** column name */
  DefaultCalendarSignaturesOnForRecurringMeetings = 'defaultCalendarSignaturesOnForRecurringMeetings',
  /** column name */
  DisableAiBackground = 'disableAiBackground',
  /** column name */
  DisableBackgroundUpload = 'disableBackgroundUpload',
  /** column name */
  DisableCalendarIntegration = 'disableCalendarIntegration',
  /** column name */
  DisableCalendarPromptOnZoomLogin = 'disableCalendarPromptOnZoomLogin',
  /** column name */
  DisableCalendarSignaturesByDefault = 'disableCalendarSignaturesByDefault',
  /** column name */
  DisableImageUpload = 'disableImageUpload',
  /** column name */
  DisableNonWarmlyCalendarSignaturesByDefault = 'disableNonWarmlyCalendarSignaturesByDefault',
  /** column name */
  DisablePublicProfiles = 'disablePublicProfiles',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  IdentifierId = 'identifierId',
  /** column name */
  MeetingBoostSettings = 'meetingBoostSettings',
  /** column name */
  SkipExperiments = 'skipExperiments',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserProfileSettings = 'userProfileSettings',
}

export type ZoomSettings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomSettings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomSettings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomSettings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomSettings_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoomSettings_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomSettings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomSettings_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomSettings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ZoomSettings_Var_Pop_Fields = {
  __typename?: 'zoomSettings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomSettings_Var_Samp_Fields = {
  __typename?: 'zoomSettings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomSettings_Variance_Fields = {
  __typename?: 'zoomSettings_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUser = {
  __typename?: 'zoomUser';
  /** An object relationship */
  actions?: Maybe<ZoomUserActions>;
  addedToVideoConversationStarterPromptId?: Maybe<Scalars['uuid']>;
  ask?: Maybe<Scalars['String']>;
  backgroundVisibility?: Maybe<Scalars['jsonb']>;
  calendarEnrichmentStatus?: Maybe<Scalars['String']>;
  calendarLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseUid: Scalars['String'];
  firstName: Scalars['String'];
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  googleAccessToken?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  googleRefreshTokenStatus?: Maybe<Scalars['String']>;
  googleScopes?: Maybe<Scalars['jsonb']>;
  googleSyncToken?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  integrationType?: Maybe<Scalars['String']>;
  intercomOnboardingStartedAt?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "is_group_admin" */
  isGroupAdmin?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_group_super" */
  isGroupSuper?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_org_admin" */
  isOrgAdmin?: Maybe<Scalars['Boolean']>;
  lastJoinedMeetingUuid?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['jsonb']>;
  locationUpdatedAt?: Maybe<Scalars['timestamptz']>;
  microsoftAccessToken?: Maybe<Scalars['String']>;
  microsoftAccountId?: Maybe<Scalars['String']>;
  microsoftDeltaToken?: Maybe<Scalars['String']>;
  microsoftIdToken?: Maybe<Scalars['String']>;
  microsoftScopes?: Maybe<Scalars['jsonb']>;
  nametagSettings?: Maybe<Scalars['jsonb']>;
  nylasAccessToken?: Maybe<Scalars['String']>;
  nylasAccountId?: Maybe<Scalars['String']>;
  nylasLinkedAt?: Maybe<Scalars['timestamptz']>;
  nylasSyncState?: Maybe<Scalars['String']>;
  onboardVersion?: Maybe<Scalars['String']>;
  onboardingType?: Maybe<Scalars['String']>;
  onlyInjectWarmlyUsers?: Maybe<Scalars['Boolean']>;
  photoUrl?: Maybe<Scalars['String']>;
  pmfSurveyDisplayedAt?: Maybe<Scalars['timestamptz']>;
  profileUpdatedAt?: Maybe<Scalars['timestamptz']>;
  profileVisibility: Scalars['jsonb'];
  pronouns?: Maybe<Scalars['String']>;
  recurringEventsInjectionEnabledAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  roleBucket?: Maybe<Scalars['String']>;
  runningLateTemplate?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  ssoDomain?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  syncGoogleCalendar: Scalars['Boolean'];
  temperatureScale?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  whereAreYouTemplate?: Maybe<Scalars['String']>;
  /** An object relationship */
  zoomCompany?: Maybe<ZoomCompany>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  zoomContact?: Maybe<ZoomContact>;
  /** An array relationship */
  zoomEventParticipants: Array<ZoomEventParticipant>;
  /** An aggregate relationship */
  zoomEventParticipants_aggregate: ZoomEventParticipant_Aggregate;
  zoomExternalAuthUserId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  zoomGroupMemberships: Array<ZoomGroupMembership>;
  /** An aggregate relationship */
  zoomGroupMemberships_aggregate: ZoomGroupMembership_Aggregate;
  /** An object relationship */
  zoomOrganizationMembership?: Maybe<ZoomOrganizationMembership>;
  /** An object relationship */
  zoomPlan?: Maybe<ZoomPlan>;
  /** An array relationship */
  zoomUserActions: Array<ZoomUserActions>;
  /** An aggregate relationship */
  zoomUserActions_aggregate: ZoomUserActions_Aggregate;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserBackgroundVisibilityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserGoogleScopesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserLocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserMicrosoftScopesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserNametagSettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserProfileVisibilityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserZoomEventParticipantsArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserZoomEventParticipants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipant_Order_By>>;
  where?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserZoomGroupMembershipsArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserZoomGroupMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By>>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserZoomUserActionsArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserActions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserActions_Order_By>>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
};

/** columns and relationships of "zoomUser" */
export type ZoomUserZoomUserActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoomUserActions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserActions_Order_By>>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
};

/** columns and relationships of "zoomUserActions" */
export type ZoomUserActions = {
  __typename?: 'zoomUserActions';
  addBioDismissedAt?: Maybe<Scalars['timestamptz']>;
  agendaCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  calSigDismissedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  enhanceNametagCompletedAt?: Maybe<Scalars['timestamptz']>;
  enhanceNametagDialogShownAt?: Maybe<Scalars['timestamptz']>;
  enhanceNametagDismissedAt?: Maybe<Scalars['timestamptz']>;
  excitingNewsAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  installZoomDismissedAt?: Maybe<Scalars['timestamptz']>;
  manageOrganizationAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  needZoomAccessAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomCompletedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomDismissedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomShownAt?: Maybe<Scalars['timestamptz']>;
  unmirrorCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt: Scalars['timestamptz'];
  upgradeZoomAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  videoOffCardCompletedAt?: Maybe<Scalars['timestamptz']>;
  videoOffCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  welcomeDialogDismissedAt?: Maybe<Scalars['timestamptz']>;
  welcomeSetupCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  zoomUser: ZoomUser;
  zoomUserId: Scalars['uuid'];
};

/** aggregated selection of "zoomUserActions" */
export type ZoomUserActions_Aggregate = {
  __typename?: 'zoomUserActions_aggregate';
  aggregate?: Maybe<ZoomUserActions_Aggregate_Fields>;
  nodes: Array<ZoomUserActions>;
};

export type ZoomUserActions_Aggregate_Bool_Exp = {
  count?: InputMaybe<ZoomUserActions_Aggregate_Bool_Exp_Count>;
};

export type ZoomUserActions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ZoomUserActions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomUserActions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zoomUserActions" */
export type ZoomUserActions_Aggregate_Fields = {
  __typename?: 'zoomUserActions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomUserActions_Max_Fields>;
  min?: Maybe<ZoomUserActions_Min_Fields>;
};

/** aggregate fields of "zoomUserActions" */
export type ZoomUserActions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomUserActions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomUserActions" */
export type ZoomUserActions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ZoomUserActions_Max_Order_By>;
  min?: InputMaybe<ZoomUserActions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomUserActions" */
export type ZoomUserActions_Arr_Rel_Insert_Input = {
  data: Array<ZoomUserActions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomUserActions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomUserActions". All fields are combined with a logical 'AND'. */
export type ZoomUserActions_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomUserActions_Bool_Exp>>;
  _not?: InputMaybe<ZoomUserActions_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomUserActions_Bool_Exp>>;
  addBioDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  agendaCardDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  calSigDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  enhanceNametagCompletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  enhanceNametagDialogShownAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  enhanceNametagDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  excitingNewsAlertDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  installZoomDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  manageOrganizationAlertDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  needZoomAccessAlertDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  practiceRoomCompletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  practiceRoomDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  practiceRoomShownAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  unmirrorCardDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  upgradeZoomAlertDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  videoOffCardCompletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  videoOffCardDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  welcomeDialogDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  welcomeSetupCardDismissedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomUser?: InputMaybe<ZoomUser_Bool_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomUserActions" */
export enum ZoomUserActions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZoomUserActionsPkey = 'zoomUserActions_pkey',
}

/** input type for inserting data into table "zoomUserActions" */
export type ZoomUserActions_Insert_Input = {
  addBioDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  agendaCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  calSigDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagDialogShownAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  excitingNewsAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  installZoomDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  manageOrganizationAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  needZoomAccessAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomShownAt?: InputMaybe<Scalars['timestamptz']>;
  unmirrorCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  upgradeZoomAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  videoOffCardCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  videoOffCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  welcomeDialogDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  welcomeSetupCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUser?: InputMaybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomUserActions_Max_Fields = {
  __typename?: 'zoomUserActions_max_fields';
  addBioDismissedAt?: Maybe<Scalars['timestamptz']>;
  agendaCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  calSigDismissedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  enhanceNametagCompletedAt?: Maybe<Scalars['timestamptz']>;
  enhanceNametagDialogShownAt?: Maybe<Scalars['timestamptz']>;
  enhanceNametagDismissedAt?: Maybe<Scalars['timestamptz']>;
  excitingNewsAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installZoomDismissedAt?: Maybe<Scalars['timestamptz']>;
  manageOrganizationAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  needZoomAccessAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomCompletedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomDismissedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomShownAt?: Maybe<Scalars['timestamptz']>;
  unmirrorCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  upgradeZoomAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  videoOffCardCompletedAt?: Maybe<Scalars['timestamptz']>;
  videoOffCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  welcomeDialogDismissedAt?: Maybe<Scalars['timestamptz']>;
  welcomeSetupCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomUserActions" */
export type ZoomUserActions_Max_Order_By = {
  addBioDismissedAt?: InputMaybe<Order_By>;
  agendaCardDismissedAt?: InputMaybe<Order_By>;
  calSigDismissedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  enhanceNametagCompletedAt?: InputMaybe<Order_By>;
  enhanceNametagDialogShownAt?: InputMaybe<Order_By>;
  enhanceNametagDismissedAt?: InputMaybe<Order_By>;
  excitingNewsAlertDismissedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  installZoomDismissedAt?: InputMaybe<Order_By>;
  manageOrganizationAlertDismissedAt?: InputMaybe<Order_By>;
  needZoomAccessAlertDismissedAt?: InputMaybe<Order_By>;
  practiceRoomCompletedAt?: InputMaybe<Order_By>;
  practiceRoomDismissedAt?: InputMaybe<Order_By>;
  practiceRoomShownAt?: InputMaybe<Order_By>;
  unmirrorCardDismissedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  upgradeZoomAlertDismissedAt?: InputMaybe<Order_By>;
  videoOffCardCompletedAt?: InputMaybe<Order_By>;
  videoOffCardDismissedAt?: InputMaybe<Order_By>;
  welcomeDialogDismissedAt?: InputMaybe<Order_By>;
  welcomeSetupCardDismissedAt?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomUserActions_Min_Fields = {
  __typename?: 'zoomUserActions_min_fields';
  addBioDismissedAt?: Maybe<Scalars['timestamptz']>;
  agendaCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  calSigDismissedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  enhanceNametagCompletedAt?: Maybe<Scalars['timestamptz']>;
  enhanceNametagDialogShownAt?: Maybe<Scalars['timestamptz']>;
  enhanceNametagDismissedAt?: Maybe<Scalars['timestamptz']>;
  excitingNewsAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  installZoomDismissedAt?: Maybe<Scalars['timestamptz']>;
  manageOrganizationAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  needZoomAccessAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomCompletedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomDismissedAt?: Maybe<Scalars['timestamptz']>;
  practiceRoomShownAt?: Maybe<Scalars['timestamptz']>;
  unmirrorCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  upgradeZoomAlertDismissedAt?: Maybe<Scalars['timestamptz']>;
  videoOffCardCompletedAt?: Maybe<Scalars['timestamptz']>;
  videoOffCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  welcomeDialogDismissedAt?: Maybe<Scalars['timestamptz']>;
  welcomeSetupCardDismissedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomUserActions" */
export type ZoomUserActions_Min_Order_By = {
  addBioDismissedAt?: InputMaybe<Order_By>;
  agendaCardDismissedAt?: InputMaybe<Order_By>;
  calSigDismissedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  enhanceNametagCompletedAt?: InputMaybe<Order_By>;
  enhanceNametagDialogShownAt?: InputMaybe<Order_By>;
  enhanceNametagDismissedAt?: InputMaybe<Order_By>;
  excitingNewsAlertDismissedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  installZoomDismissedAt?: InputMaybe<Order_By>;
  manageOrganizationAlertDismissedAt?: InputMaybe<Order_By>;
  needZoomAccessAlertDismissedAt?: InputMaybe<Order_By>;
  practiceRoomCompletedAt?: InputMaybe<Order_By>;
  practiceRoomDismissedAt?: InputMaybe<Order_By>;
  practiceRoomShownAt?: InputMaybe<Order_By>;
  unmirrorCardDismissedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  upgradeZoomAlertDismissedAt?: InputMaybe<Order_By>;
  videoOffCardCompletedAt?: InputMaybe<Order_By>;
  videoOffCardDismissedAt?: InputMaybe<Order_By>;
  welcomeDialogDismissedAt?: InputMaybe<Order_By>;
  welcomeSetupCardDismissedAt?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zoomUserActions" */
export type ZoomUserActions_Mutation_Response = {
  __typename?: 'zoomUserActions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomUserActions>;
};

/** input type for inserting object relation for remote table "zoomUserActions" */
export type ZoomUserActions_Obj_Rel_Insert_Input = {
  data: ZoomUserActions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomUserActions_On_Conflict>;
};

/** on_conflict condition type for table "zoomUserActions" */
export type ZoomUserActions_On_Conflict = {
  constraint: ZoomUserActions_Constraint;
  update_columns?: Array<ZoomUserActions_Update_Column>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomUserActions". */
export type ZoomUserActions_Order_By = {
  addBioDismissedAt?: InputMaybe<Order_By>;
  agendaCardDismissedAt?: InputMaybe<Order_By>;
  calSigDismissedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  enhanceNametagCompletedAt?: InputMaybe<Order_By>;
  enhanceNametagDialogShownAt?: InputMaybe<Order_By>;
  enhanceNametagDismissedAt?: InputMaybe<Order_By>;
  excitingNewsAlertDismissedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  installZoomDismissedAt?: InputMaybe<Order_By>;
  manageOrganizationAlertDismissedAt?: InputMaybe<Order_By>;
  needZoomAccessAlertDismissedAt?: InputMaybe<Order_By>;
  practiceRoomCompletedAt?: InputMaybe<Order_By>;
  practiceRoomDismissedAt?: InputMaybe<Order_By>;
  practiceRoomShownAt?: InputMaybe<Order_By>;
  unmirrorCardDismissedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  upgradeZoomAlertDismissedAt?: InputMaybe<Order_By>;
  videoOffCardCompletedAt?: InputMaybe<Order_By>;
  videoOffCardDismissedAt?: InputMaybe<Order_By>;
  welcomeDialogDismissedAt?: InputMaybe<Order_By>;
  welcomeSetupCardDismissedAt?: InputMaybe<Order_By>;
  zoomUser?: InputMaybe<ZoomUser_Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zoomUserActions */
export type ZoomUserActions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomUserActions" */
export enum ZoomUserActions_Select_Column {
  /** column name */
  AddBioDismissedAt = 'addBioDismissedAt',
  /** column name */
  AgendaCardDismissedAt = 'agendaCardDismissedAt',
  /** column name */
  CalSigDismissedAt = 'calSigDismissedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EnhanceNametagCompletedAt = 'enhanceNametagCompletedAt',
  /** column name */
  EnhanceNametagDialogShownAt = 'enhanceNametagDialogShownAt',
  /** column name */
  EnhanceNametagDismissedAt = 'enhanceNametagDismissedAt',
  /** column name */
  ExcitingNewsAlertDismissedAt = 'excitingNewsAlertDismissedAt',
  /** column name */
  Id = 'id',
  /** column name */
  InstallZoomDismissedAt = 'installZoomDismissedAt',
  /** column name */
  ManageOrganizationAlertDismissedAt = 'manageOrganizationAlertDismissedAt',
  /** column name */
  NeedZoomAccessAlertDismissedAt = 'needZoomAccessAlertDismissedAt',
  /** column name */
  PracticeRoomCompletedAt = 'practiceRoomCompletedAt',
  /** column name */
  PracticeRoomDismissedAt = 'practiceRoomDismissedAt',
  /** column name */
  PracticeRoomShownAt = 'practiceRoomShownAt',
  /** column name */
  UnmirrorCardDismissedAt = 'unmirrorCardDismissedAt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpgradeZoomAlertDismissedAt = 'upgradeZoomAlertDismissedAt',
  /** column name */
  VideoOffCardCompletedAt = 'videoOffCardCompletedAt',
  /** column name */
  VideoOffCardDismissedAt = 'videoOffCardDismissedAt',
  /** column name */
  WelcomeDialogDismissedAt = 'welcomeDialogDismissedAt',
  /** column name */
  WelcomeSetupCardDismissedAt = 'welcomeSetupCardDismissedAt',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomUserActions" */
export type ZoomUserActions_Set_Input = {
  addBioDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  agendaCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  calSigDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagDialogShownAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  excitingNewsAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  installZoomDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  manageOrganizationAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  needZoomAccessAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomShownAt?: InputMaybe<Scalars['timestamptz']>;
  unmirrorCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  upgradeZoomAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  videoOffCardCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  videoOffCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  welcomeDialogDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  welcomeSetupCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomUserActions" */
export type ZoomUserActions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomUserActions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomUserActions_Stream_Cursor_Value_Input = {
  addBioDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  agendaCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  calSigDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagDialogShownAt?: InputMaybe<Scalars['timestamptz']>;
  enhanceNametagDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  excitingNewsAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  installZoomDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  manageOrganizationAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  needZoomAccessAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  practiceRoomShownAt?: InputMaybe<Scalars['timestamptz']>;
  unmirrorCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  upgradeZoomAlertDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  videoOffCardCompletedAt?: InputMaybe<Scalars['timestamptz']>;
  videoOffCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  welcomeDialogDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  welcomeSetupCardDismissedAt?: InputMaybe<Scalars['timestamptz']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomUserActions" */
export enum ZoomUserActions_Update_Column {
  /** column name */
  AddBioDismissedAt = 'addBioDismissedAt',
  /** column name */
  AgendaCardDismissedAt = 'agendaCardDismissedAt',
  /** column name */
  CalSigDismissedAt = 'calSigDismissedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EnhanceNametagCompletedAt = 'enhanceNametagCompletedAt',
  /** column name */
  EnhanceNametagDialogShownAt = 'enhanceNametagDialogShownAt',
  /** column name */
  EnhanceNametagDismissedAt = 'enhanceNametagDismissedAt',
  /** column name */
  ExcitingNewsAlertDismissedAt = 'excitingNewsAlertDismissedAt',
  /** column name */
  Id = 'id',
  /** column name */
  InstallZoomDismissedAt = 'installZoomDismissedAt',
  /** column name */
  ManageOrganizationAlertDismissedAt = 'manageOrganizationAlertDismissedAt',
  /** column name */
  NeedZoomAccessAlertDismissedAt = 'needZoomAccessAlertDismissedAt',
  /** column name */
  PracticeRoomCompletedAt = 'practiceRoomCompletedAt',
  /** column name */
  PracticeRoomDismissedAt = 'practiceRoomDismissedAt',
  /** column name */
  PracticeRoomShownAt = 'practiceRoomShownAt',
  /** column name */
  UnmirrorCardDismissedAt = 'unmirrorCardDismissedAt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpgradeZoomAlertDismissedAt = 'upgradeZoomAlertDismissedAt',
  /** column name */
  VideoOffCardCompletedAt = 'videoOffCardCompletedAt',
  /** column name */
  VideoOffCardDismissedAt = 'videoOffCardDismissedAt',
  /** column name */
  WelcomeDialogDismissedAt = 'welcomeDialogDismissedAt',
  /** column name */
  WelcomeSetupCardDismissedAt = 'welcomeSetupCardDismissedAt',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

export type ZoomUserActions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomUserActions_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomUserActions_Bool_Exp;
};

/** columns and relationships of "zoomUserConnectionRequest" */
export type ZoomUserConnectionRequest = {
  __typename?: 'zoomUserConnectionRequest';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyCrunchbaseUrl?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyEstimatedSize?: Maybe<Scalars['String']>;
  companyLinkedInUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySeoDescription?: Maybe<Scalars['String']>;
  companyState?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  isWarmlyUser?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['jsonb']>;
  logoUrl?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  recipientEmail?: Maybe<Scalars['String']>;
  requestStatus?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  senderZoomUserId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomUserConnectionRequest" */
export type ZoomUserConnectionRequestLocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomUserConnectionRequest" */
export type ZoomUserConnectionRequest_Aggregate = {
  __typename?: 'zoomUserConnectionRequest_aggregate';
  aggregate?: Maybe<ZoomUserConnectionRequest_Aggregate_Fields>;
  nodes: Array<ZoomUserConnectionRequest>;
};

/** aggregate fields of "zoomUserConnectionRequest" */
export type ZoomUserConnectionRequest_Aggregate_Fields = {
  __typename?: 'zoomUserConnectionRequest_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomUserConnectionRequest_Max_Fields>;
  min?: Maybe<ZoomUserConnectionRequest_Min_Fields>;
};

/** aggregate fields of "zoomUserConnectionRequest" */
export type ZoomUserConnectionRequest_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomUserConnectionRequest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zoomUserConnectionRequest". All fields are combined with a logical 'AND'. */
export type ZoomUserConnectionRequest_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomUserConnectionRequest_Bool_Exp>>;
  _not?: InputMaybe<ZoomUserConnectionRequest_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomUserConnectionRequest_Bool_Exp>>;
  ask?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  companyCity?: InputMaybe<String_Comparison_Exp>;
  companyCrunchbaseUrl?: InputMaybe<String_Comparison_Exp>;
  companyDescription?: InputMaybe<String_Comparison_Exp>;
  companyEstimatedSize?: InputMaybe<String_Comparison_Exp>;
  companyLinkedInUrl?: InputMaybe<String_Comparison_Exp>;
  companyName?: InputMaybe<String_Comparison_Exp>;
  companySeoDescription?: InputMaybe<String_Comparison_Exp>;
  companyState?: InputMaybe<String_Comparison_Exp>;
  companyWebsiteDomain?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  customBackgroundPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  firstNamePronunciation?: InputMaybe<String_Comparison_Exp>;
  fullNameGenerated?: InputMaybe<String_Comparison_Exp>;
  headline?: InputMaybe<String_Comparison_Exp>;
  isWarmlyUser?: InputMaybe<Boolean_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  lastNamePronunciation?: InputMaybe<String_Comparison_Exp>;
  linkedInUrl?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  logoUrl?: InputMaybe<String_Comparison_Exp>;
  photoUrl?: InputMaybe<String_Comparison_Exp>;
  pronouns?: InputMaybe<String_Comparison_Exp>;
  recipientEmail?: InputMaybe<String_Comparison_Exp>;
  requestStatus?: InputMaybe<String_Comparison_Exp>;
  scheduleMeetingUrl?: InputMaybe<String_Comparison_Exp>;
  senderZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  strengths?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  twitterUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  zoomCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomContactId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type ZoomUserConnectionRequest_Max_Fields = {
  __typename?: 'zoomUserConnectionRequest_max_fields';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyCrunchbaseUrl?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyEstimatedSize?: Maybe<Scalars['String']>;
  companyLinkedInUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySeoDescription?: Maybe<Scalars['String']>;
  companyState?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  recipientEmail?: Maybe<Scalars['String']>;
  requestStatus?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  senderZoomUserId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomUserConnectionRequest_Min_Fields = {
  __typename?: 'zoomUserConnectionRequest_min_fields';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyCrunchbaseUrl?: Maybe<Scalars['String']>;
  companyDescription?: Maybe<Scalars['String']>;
  companyEstimatedSize?: Maybe<Scalars['String']>;
  companyLinkedInUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companySeoDescription?: Maybe<Scalars['String']>;
  companyState?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  recipientEmail?: Maybe<Scalars['String']>;
  requestStatus?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  senderZoomUserId?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "zoomUserConnectionRequest". */
export type ZoomUserConnectionRequest_Order_By = {
  ask?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  companyCity?: InputMaybe<Order_By>;
  companyCrunchbaseUrl?: InputMaybe<Order_By>;
  companyDescription?: InputMaybe<Order_By>;
  companyEstimatedSize?: InputMaybe<Order_By>;
  companyLinkedInUrl?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  companySeoDescription?: InputMaybe<Order_By>;
  companyState?: InputMaybe<Order_By>;
  companyWebsiteDomain?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  customBackgroundPhotoUrl?: InputMaybe<Order_By>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  firstNamePronunciation?: InputMaybe<Order_By>;
  fullNameGenerated?: InputMaybe<Order_By>;
  headline?: InputMaybe<Order_By>;
  isWarmlyUser?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastNamePronunciation?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  photoUrl?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  recipientEmail?: InputMaybe<Order_By>;
  requestStatus?: InputMaybe<Order_By>;
  scheduleMeetingUrl?: InputMaybe<Order_By>;
  senderZoomUserId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  strengths?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
  zoomContactId?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** select columns of table "zoomUserConnectionRequest" */
export enum ZoomUserConnectionRequest_Select_Column {
  /** column name */
  Ask = 'ask',
  /** column name */
  City = 'city',
  /** column name */
  CompanyCity = 'companyCity',
  /** column name */
  CompanyCrunchbaseUrl = 'companyCrunchbaseUrl',
  /** column name */
  CompanyDescription = 'companyDescription',
  /** column name */
  CompanyEstimatedSize = 'companyEstimatedSize',
  /** column name */
  CompanyLinkedInUrl = 'companyLinkedInUrl',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CompanySeoDescription = 'companySeoDescription',
  /** column name */
  CompanyState = 'companyState',
  /** column name */
  CompanyWebsiteDomain = 'companyWebsiteDomain',
  /** column name */
  Country = 'country',
  /** column name */
  CustomBackgroundPhotoUrl = 'customBackgroundPhotoUrl',
  /** column name */
  DefaultCustomBackgroundPhotoUrl = 'defaultCustomBackgroundPhotoUrl',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FirstNamePronunciation = 'firstNamePronunciation',
  /** column name */
  FullNameGenerated = 'fullNameGenerated',
  /** column name */
  Headline = 'headline',
  /** column name */
  IsWarmlyUser = 'isWarmlyUser',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastNamePronunciation = 'lastNamePronunciation',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Location = 'location',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  RecipientEmail = 'recipientEmail',
  /** column name */
  RequestStatus = 'requestStatus',
  /** column name */
  ScheduleMeetingUrl = 'scheduleMeetingUrl',
  /** column name */
  SenderZoomUserId = 'senderZoomUserId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  State = 'state',
  /** column name */
  Strengths = 'strengths',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** Streaming cursor of the table "zoomUserConnectionRequest" */
export type ZoomUserConnectionRequest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomUserConnectionRequest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomUserConnectionRequest_Stream_Cursor_Value_Input = {
  ask?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyCrunchbaseUrl?: InputMaybe<Scalars['String']>;
  companyDescription?: InputMaybe<Scalars['String']>;
  companyEstimatedSize?: InputMaybe<Scalars['String']>;
  companyLinkedInUrl?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companySeoDescription?: InputMaybe<Scalars['String']>;
  companyState?: InputMaybe<Scalars['String']>;
  companyWebsiteDomain?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstNamePronunciation?: InputMaybe<Scalars['String']>;
  fullNameGenerated?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  isWarmlyUser?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNamePronunciation?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['jsonb']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
  requestStatus?: InputMaybe<Scalars['String']>;
  scheduleMeetingUrl?: InputMaybe<Scalars['String']>;
  senderZoomUserId?: InputMaybe<Scalars['uuid']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  strengths?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomUserMeetingParticipants" */
export type ZoomUserMeetingParticipants = {
  __typename?: 'zoomUserMeetingParticipants';
  meetingEndAt?: Maybe<Scalars['timestamptz']>;
  meetingStartAt?: Maybe<Scalars['timestamptz']>;
  meetingTitle?: Maybe<Scalars['String']>;
  participantEmail?: Maybe<Scalars['String']>;
  zoomUserEmail?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "zoomUserMeetingParticipants" */
export type ZoomUserMeetingParticipants_Aggregate = {
  __typename?: 'zoomUserMeetingParticipants_aggregate';
  aggregate?: Maybe<ZoomUserMeetingParticipants_Aggregate_Fields>;
  nodes: Array<ZoomUserMeetingParticipants>;
};

/** aggregate fields of "zoomUserMeetingParticipants" */
export type ZoomUserMeetingParticipants_Aggregate_Fields = {
  __typename?: 'zoomUserMeetingParticipants_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomUserMeetingParticipants_Max_Fields>;
  min?: Maybe<ZoomUserMeetingParticipants_Min_Fields>;
};

/** aggregate fields of "zoomUserMeetingParticipants" */
export type ZoomUserMeetingParticipants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomUserMeetingParticipants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "zoomUserMeetingParticipants". All fields are combined with a logical 'AND'. */
export type ZoomUserMeetingParticipants_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomUserMeetingParticipants_Bool_Exp>>;
  _not?: InputMaybe<ZoomUserMeetingParticipants_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomUserMeetingParticipants_Bool_Exp>>;
  meetingEndAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  meetingStartAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  meetingTitle?: InputMaybe<String_Comparison_Exp>;
  participantEmail?: InputMaybe<String_Comparison_Exp>;
  zoomUserEmail?: InputMaybe<String_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type ZoomUserMeetingParticipants_Max_Fields = {
  __typename?: 'zoomUserMeetingParticipants_max_fields';
  meetingEndAt?: Maybe<Scalars['timestamptz']>;
  meetingStartAt?: Maybe<Scalars['timestamptz']>;
  meetingTitle?: Maybe<Scalars['String']>;
  participantEmail?: Maybe<Scalars['String']>;
  zoomUserEmail?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ZoomUserMeetingParticipants_Min_Fields = {
  __typename?: 'zoomUserMeetingParticipants_min_fields';
  meetingEndAt?: Maybe<Scalars['timestamptz']>;
  meetingStartAt?: Maybe<Scalars['timestamptz']>;
  meetingTitle?: Maybe<Scalars['String']>;
  participantEmail?: Maybe<Scalars['String']>;
  zoomUserEmail?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "zoomUserMeetingParticipants". */
export type ZoomUserMeetingParticipants_Order_By = {
  meetingEndAt?: InputMaybe<Order_By>;
  meetingStartAt?: InputMaybe<Order_By>;
  meetingTitle?: InputMaybe<Order_By>;
  participantEmail?: InputMaybe<Order_By>;
  zoomUserEmail?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
};

/** select columns of table "zoomUserMeetingParticipants" */
export enum ZoomUserMeetingParticipants_Select_Column {
  /** column name */
  MeetingEndAt = 'meetingEndAt',
  /** column name */
  MeetingStartAt = 'meetingStartAt',
  /** column name */
  MeetingTitle = 'meetingTitle',
  /** column name */
  ParticipantEmail = 'participantEmail',
  /** column name */
  ZoomUserEmail = 'zoomUserEmail',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** Streaming cursor of the table "zoomUserMeetingParticipants" */
export type ZoomUserMeetingParticipants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomUserMeetingParticipants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomUserMeetingParticipants_Stream_Cursor_Value_Input = {
  meetingEndAt?: InputMaybe<Scalars['timestamptz']>;
  meetingStartAt?: InputMaybe<Scalars['timestamptz']>;
  meetingTitle?: InputMaybe<Scalars['String']>;
  participantEmail?: InputMaybe<Scalars['String']>;
  zoomUserEmail?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomUserNetwork" */
export type ZoomUserNetwork = {
  __typename?: 'zoomUserNetwork';
  email?: Maybe<Scalars['String']>;
  eventCount?: Maybe<Scalars['bigint']>;
  lastEventStart?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomContactCity?: Maybe<Scalars['String']>;
  zoomContactCountry?: Maybe<Scalars['String']>;
  zoomContactFirstName?: Maybe<Scalars['String']>;
  zoomContactFullNameGenerated?: Maybe<Scalars['String']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomContactLastName?: Maybe<Scalars['String']>;
  zoomContactLinkedInUrl?: Maybe<Scalars['String']>;
  zoomContactLocation?: Maybe<Scalars['jsonb']>;
  zoomContactPhotoUrl?: Maybe<Scalars['String']>;
  zoomContactState?: Maybe<Scalars['String']>;
  zoomContactTitle?: Maybe<Scalars['String']>;
  zoomContactTwitterUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyCity?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyCrunchbaseUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyDescription?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyEstimatedSize?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyLinkedInUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyName?: Maybe<Scalars['String']>;
  zoomContactZoomCompanySeoDescription?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyState?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyWebsiteDomain?: Maybe<Scalars['String']>;
  zoomUserAsk?: Maybe<Scalars['String']>;
  zoomUserCity?: Maybe<Scalars['String']>;
  zoomUserCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomUserCompanyName?: Maybe<Scalars['String']>;
  zoomUserCompanyWebsiteDomain?: Maybe<Scalars['String']>;
  zoomUserCountry?: Maybe<Scalars['String']>;
  zoomUserFirstName?: Maybe<Scalars['String']>;
  zoomUserFirstNamePronunciation?: Maybe<Scalars['String']>;
  zoomUserFullNameGenerated?: Maybe<Scalars['String']>;
  zoomUserHeadline?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
  zoomUserLastName?: Maybe<Scalars['String']>;
  zoomUserLastNamePronunciation?: Maybe<Scalars['String']>;
  zoomUserLinkedInUrl?: Maybe<Scalars['String']>;
  zoomUserLocation?: Maybe<Scalars['jsonb']>;
  zoomUserNetworkZoomUserId?: Maybe<Scalars['uuid']>;
  zoomUserPhotoUrl?: Maybe<Scalars['String']>;
  zoomUserPronouns?: Maybe<Scalars['String']>;
  zoomUserScheduleMeetingUrl?: Maybe<Scalars['String']>;
  zoomUserStartDate?: Maybe<Scalars['timestamptz']>;
  zoomUserState?: Maybe<Scalars['String']>;
  zoomUserStrengths?: Maybe<Scalars['String']>;
  zoomUserTitle?: Maybe<Scalars['String']>;
  zoomUserTwitterUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyCity?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyCrunchbaseUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyDescription?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyEstimatedSize?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyLinkedInUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyName?: Maybe<Scalars['String']>;
  zoomUserZoomCompanySeoDescription?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyState?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyWebsiteDomain?: Maybe<Scalars['String']>;
};

/** columns and relationships of "zoomUserNetwork" */
export type ZoomUserNetworkZoomContactLocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "zoomUserNetwork" */
export type ZoomUserNetworkZoomUserLocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomUserNetwork" */
export type ZoomUserNetwork_Aggregate = {
  __typename?: 'zoomUserNetwork_aggregate';
  aggregate?: Maybe<ZoomUserNetwork_Aggregate_Fields>;
  nodes: Array<ZoomUserNetwork>;
};

/** aggregate fields of "zoomUserNetwork" */
export type ZoomUserNetwork_Aggregate_Fields = {
  __typename?: 'zoomUserNetwork_aggregate_fields';
  avg?: Maybe<ZoomUserNetwork_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomUserNetwork_Max_Fields>;
  min?: Maybe<ZoomUserNetwork_Min_Fields>;
  stddev?: Maybe<ZoomUserNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomUserNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomUserNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomUserNetwork_Sum_Fields>;
  var_pop?: Maybe<ZoomUserNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomUserNetwork_Var_Samp_Fields>;
  variance?: Maybe<ZoomUserNetwork_Variance_Fields>;
};

/** aggregate fields of "zoomUserNetwork" */
export type ZoomUserNetwork_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomUserNetwork_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZoomUserNetwork_Avg_Fields = {
  __typename?: 'zoomUserNetwork_avg_fields';
  eventCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomUserNetwork". All fields are combined with a logical 'AND'. */
export type ZoomUserNetwork_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomUserNetwork_Bool_Exp>>;
  _not?: InputMaybe<ZoomUserNetwork_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomUserNetwork_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  eventCount?: InputMaybe<Bigint_Comparison_Exp>;
  lastEventStart?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  zoomContactCity?: InputMaybe<String_Comparison_Exp>;
  zoomContactCountry?: InputMaybe<String_Comparison_Exp>;
  zoomContactFirstName?: InputMaybe<String_Comparison_Exp>;
  zoomContactFullNameGenerated?: InputMaybe<String_Comparison_Exp>;
  zoomContactId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomContactLastName?: InputMaybe<String_Comparison_Exp>;
  zoomContactLinkedInUrl?: InputMaybe<String_Comparison_Exp>;
  zoomContactLocation?: InputMaybe<Jsonb_Comparison_Exp>;
  zoomContactPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  zoomContactState?: InputMaybe<String_Comparison_Exp>;
  zoomContactTitle?: InputMaybe<String_Comparison_Exp>;
  zoomContactTwitterUrl?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyCity?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyCrunchbaseUrl?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyDescription?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyEstimatedSize?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyLinkedInUrl?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyLogoUrl?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyName?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanySeoDescription?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyState?: InputMaybe<String_Comparison_Exp>;
  zoomContactZoomCompanyWebsiteDomain?: InputMaybe<String_Comparison_Exp>;
  zoomUserAsk?: InputMaybe<String_Comparison_Exp>;
  zoomUserCity?: InputMaybe<String_Comparison_Exp>;
  zoomUserCompanyLogoUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserCompanyName?: InputMaybe<String_Comparison_Exp>;
  zoomUserCompanyWebsiteDomain?: InputMaybe<String_Comparison_Exp>;
  zoomUserCountry?: InputMaybe<String_Comparison_Exp>;
  zoomUserFirstName?: InputMaybe<String_Comparison_Exp>;
  zoomUserFirstNamePronunciation?: InputMaybe<String_Comparison_Exp>;
  zoomUserFullNameGenerated?: InputMaybe<String_Comparison_Exp>;
  zoomUserHeadline?: InputMaybe<String_Comparison_Exp>;
  zoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUserLastName?: InputMaybe<String_Comparison_Exp>;
  zoomUserLastNamePronunciation?: InputMaybe<String_Comparison_Exp>;
  zoomUserLinkedInUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserLocation?: InputMaybe<Jsonb_Comparison_Exp>;
  zoomUserNetworkZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomUserPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserPronouns?: InputMaybe<String_Comparison_Exp>;
  zoomUserScheduleMeetingUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserStartDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  zoomUserState?: InputMaybe<String_Comparison_Exp>;
  zoomUserStrengths?: InputMaybe<String_Comparison_Exp>;
  zoomUserTitle?: InputMaybe<String_Comparison_Exp>;
  zoomUserTwitterUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyCity?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyCrunchbaseUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyDescription?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyEstimatedSize?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyLinkedInUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyLogoUrl?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyName?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanySeoDescription?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyState?: InputMaybe<String_Comparison_Exp>;
  zoomUserZoomCompanyWebsiteDomain?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type ZoomUserNetwork_Max_Fields = {
  __typename?: 'zoomUserNetwork_max_fields';
  email?: Maybe<Scalars['String']>;
  eventCount?: Maybe<Scalars['bigint']>;
  lastEventStart?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomContactCity?: Maybe<Scalars['String']>;
  zoomContactCountry?: Maybe<Scalars['String']>;
  zoomContactFirstName?: Maybe<Scalars['String']>;
  zoomContactFullNameGenerated?: Maybe<Scalars['String']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomContactLastName?: Maybe<Scalars['String']>;
  zoomContactLinkedInUrl?: Maybe<Scalars['String']>;
  zoomContactPhotoUrl?: Maybe<Scalars['String']>;
  zoomContactState?: Maybe<Scalars['String']>;
  zoomContactTitle?: Maybe<Scalars['String']>;
  zoomContactTwitterUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyCity?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyCrunchbaseUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyDescription?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyEstimatedSize?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyLinkedInUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyName?: Maybe<Scalars['String']>;
  zoomContactZoomCompanySeoDescription?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyState?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyWebsiteDomain?: Maybe<Scalars['String']>;
  zoomUserAsk?: Maybe<Scalars['String']>;
  zoomUserCity?: Maybe<Scalars['String']>;
  zoomUserCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomUserCompanyName?: Maybe<Scalars['String']>;
  zoomUserCompanyWebsiteDomain?: Maybe<Scalars['String']>;
  zoomUserCountry?: Maybe<Scalars['String']>;
  zoomUserFirstName?: Maybe<Scalars['String']>;
  zoomUserFirstNamePronunciation?: Maybe<Scalars['String']>;
  zoomUserFullNameGenerated?: Maybe<Scalars['String']>;
  zoomUserHeadline?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
  zoomUserLastName?: Maybe<Scalars['String']>;
  zoomUserLastNamePronunciation?: Maybe<Scalars['String']>;
  zoomUserLinkedInUrl?: Maybe<Scalars['String']>;
  zoomUserNetworkZoomUserId?: Maybe<Scalars['uuid']>;
  zoomUserPhotoUrl?: Maybe<Scalars['String']>;
  zoomUserPronouns?: Maybe<Scalars['String']>;
  zoomUserScheduleMeetingUrl?: Maybe<Scalars['String']>;
  zoomUserStartDate?: Maybe<Scalars['timestamptz']>;
  zoomUserState?: Maybe<Scalars['String']>;
  zoomUserStrengths?: Maybe<Scalars['String']>;
  zoomUserTitle?: Maybe<Scalars['String']>;
  zoomUserTwitterUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyCity?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyCrunchbaseUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyDescription?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyEstimatedSize?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyLinkedInUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyName?: Maybe<Scalars['String']>;
  zoomUserZoomCompanySeoDescription?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyState?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyWebsiteDomain?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ZoomUserNetwork_Min_Fields = {
  __typename?: 'zoomUserNetwork_min_fields';
  email?: Maybe<Scalars['String']>;
  eventCount?: Maybe<Scalars['bigint']>;
  lastEventStart?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  zoomContactCity?: Maybe<Scalars['String']>;
  zoomContactCountry?: Maybe<Scalars['String']>;
  zoomContactFirstName?: Maybe<Scalars['String']>;
  zoomContactFullNameGenerated?: Maybe<Scalars['String']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomContactLastName?: Maybe<Scalars['String']>;
  zoomContactLinkedInUrl?: Maybe<Scalars['String']>;
  zoomContactPhotoUrl?: Maybe<Scalars['String']>;
  zoomContactState?: Maybe<Scalars['String']>;
  zoomContactTitle?: Maybe<Scalars['String']>;
  zoomContactTwitterUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyCity?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyCrunchbaseUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyDescription?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyEstimatedSize?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyLinkedInUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyName?: Maybe<Scalars['String']>;
  zoomContactZoomCompanySeoDescription?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyState?: Maybe<Scalars['String']>;
  zoomContactZoomCompanyWebsiteDomain?: Maybe<Scalars['String']>;
  zoomUserAsk?: Maybe<Scalars['String']>;
  zoomUserCity?: Maybe<Scalars['String']>;
  zoomUserCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomUserCompanyName?: Maybe<Scalars['String']>;
  zoomUserCompanyWebsiteDomain?: Maybe<Scalars['String']>;
  zoomUserCountry?: Maybe<Scalars['String']>;
  zoomUserFirstName?: Maybe<Scalars['String']>;
  zoomUserFirstNamePronunciation?: Maybe<Scalars['String']>;
  zoomUserFullNameGenerated?: Maybe<Scalars['String']>;
  zoomUserHeadline?: Maybe<Scalars['String']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
  zoomUserLastName?: Maybe<Scalars['String']>;
  zoomUserLastNamePronunciation?: Maybe<Scalars['String']>;
  zoomUserLinkedInUrl?: Maybe<Scalars['String']>;
  zoomUserNetworkZoomUserId?: Maybe<Scalars['uuid']>;
  zoomUserPhotoUrl?: Maybe<Scalars['String']>;
  zoomUserPronouns?: Maybe<Scalars['String']>;
  zoomUserScheduleMeetingUrl?: Maybe<Scalars['String']>;
  zoomUserStartDate?: Maybe<Scalars['timestamptz']>;
  zoomUserState?: Maybe<Scalars['String']>;
  zoomUserStrengths?: Maybe<Scalars['String']>;
  zoomUserTitle?: Maybe<Scalars['String']>;
  zoomUserTwitterUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyCity?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyCrunchbaseUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyDescription?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyEstimatedSize?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyLinkedInUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyLogoUrl?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyName?: Maybe<Scalars['String']>;
  zoomUserZoomCompanySeoDescription?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyState?: Maybe<Scalars['String']>;
  zoomUserZoomCompanyWebsiteDomain?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "zoomUserNetwork". */
export type ZoomUserNetwork_Order_By = {
  email?: InputMaybe<Order_By>;
  eventCount?: InputMaybe<Order_By>;
  lastEventStart?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  zoomContactCity?: InputMaybe<Order_By>;
  zoomContactCountry?: InputMaybe<Order_By>;
  zoomContactFirstName?: InputMaybe<Order_By>;
  zoomContactFullNameGenerated?: InputMaybe<Order_By>;
  zoomContactId?: InputMaybe<Order_By>;
  zoomContactLastName?: InputMaybe<Order_By>;
  zoomContactLinkedInUrl?: InputMaybe<Order_By>;
  zoomContactLocation?: InputMaybe<Order_By>;
  zoomContactPhotoUrl?: InputMaybe<Order_By>;
  zoomContactState?: InputMaybe<Order_By>;
  zoomContactTitle?: InputMaybe<Order_By>;
  zoomContactTwitterUrl?: InputMaybe<Order_By>;
  zoomContactZoomCompanyCity?: InputMaybe<Order_By>;
  zoomContactZoomCompanyCrunchbaseUrl?: InputMaybe<Order_By>;
  zoomContactZoomCompanyDescription?: InputMaybe<Order_By>;
  zoomContactZoomCompanyEstimatedSize?: InputMaybe<Order_By>;
  zoomContactZoomCompanyLinkedInUrl?: InputMaybe<Order_By>;
  zoomContactZoomCompanyLogoUrl?: InputMaybe<Order_By>;
  zoomContactZoomCompanyName?: InputMaybe<Order_By>;
  zoomContactZoomCompanySeoDescription?: InputMaybe<Order_By>;
  zoomContactZoomCompanyState?: InputMaybe<Order_By>;
  zoomContactZoomCompanyWebsiteDomain?: InputMaybe<Order_By>;
  zoomUserAsk?: InputMaybe<Order_By>;
  zoomUserCity?: InputMaybe<Order_By>;
  zoomUserCompanyLogoUrl?: InputMaybe<Order_By>;
  zoomUserCompanyName?: InputMaybe<Order_By>;
  zoomUserCompanyWebsiteDomain?: InputMaybe<Order_By>;
  zoomUserCountry?: InputMaybe<Order_By>;
  zoomUserFirstName?: InputMaybe<Order_By>;
  zoomUserFirstNamePronunciation?: InputMaybe<Order_By>;
  zoomUserFullNameGenerated?: InputMaybe<Order_By>;
  zoomUserHeadline?: InputMaybe<Order_By>;
  zoomUserId?: InputMaybe<Order_By>;
  zoomUserLastName?: InputMaybe<Order_By>;
  zoomUserLastNamePronunciation?: InputMaybe<Order_By>;
  zoomUserLinkedInUrl?: InputMaybe<Order_By>;
  zoomUserLocation?: InputMaybe<Order_By>;
  zoomUserNetworkZoomUserId?: InputMaybe<Order_By>;
  zoomUserPhotoUrl?: InputMaybe<Order_By>;
  zoomUserPronouns?: InputMaybe<Order_By>;
  zoomUserScheduleMeetingUrl?: InputMaybe<Order_By>;
  zoomUserStartDate?: InputMaybe<Order_By>;
  zoomUserState?: InputMaybe<Order_By>;
  zoomUserStrengths?: InputMaybe<Order_By>;
  zoomUserTitle?: InputMaybe<Order_By>;
  zoomUserTwitterUrl?: InputMaybe<Order_By>;
  zoomUserZoomCompanyCity?: InputMaybe<Order_By>;
  zoomUserZoomCompanyCrunchbaseUrl?: InputMaybe<Order_By>;
  zoomUserZoomCompanyDescription?: InputMaybe<Order_By>;
  zoomUserZoomCompanyEstimatedSize?: InputMaybe<Order_By>;
  zoomUserZoomCompanyLinkedInUrl?: InputMaybe<Order_By>;
  zoomUserZoomCompanyLogoUrl?: InputMaybe<Order_By>;
  zoomUserZoomCompanyName?: InputMaybe<Order_By>;
  zoomUserZoomCompanySeoDescription?: InputMaybe<Order_By>;
  zoomUserZoomCompanyState?: InputMaybe<Order_By>;
  zoomUserZoomCompanyWebsiteDomain?: InputMaybe<Order_By>;
};

/** select columns of table "zoomUserNetwork" */
export enum ZoomUserNetwork_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  EventCount = 'eventCount',
  /** column name */
  LastEventStart = 'lastEventStart',
  /** column name */
  Username = 'username',
  /** column name */
  ZoomContactCity = 'zoomContactCity',
  /** column name */
  ZoomContactCountry = 'zoomContactCountry',
  /** column name */
  ZoomContactFirstName = 'zoomContactFirstName',
  /** column name */
  ZoomContactFullNameGenerated = 'zoomContactFullNameGenerated',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomContactLastName = 'zoomContactLastName',
  /** column name */
  ZoomContactLinkedInUrl = 'zoomContactLinkedInUrl',
  /** column name */
  ZoomContactLocation = 'zoomContactLocation',
  /** column name */
  ZoomContactPhotoUrl = 'zoomContactPhotoUrl',
  /** column name */
  ZoomContactState = 'zoomContactState',
  /** column name */
  ZoomContactTitle = 'zoomContactTitle',
  /** column name */
  ZoomContactTwitterUrl = 'zoomContactTwitterUrl',
  /** column name */
  ZoomContactZoomCompanyCity = 'zoomContactZoomCompanyCity',
  /** column name */
  ZoomContactZoomCompanyCrunchbaseUrl = 'zoomContactZoomCompanyCrunchbaseUrl',
  /** column name */
  ZoomContactZoomCompanyDescription = 'zoomContactZoomCompanyDescription',
  /** column name */
  ZoomContactZoomCompanyEstimatedSize = 'zoomContactZoomCompanyEstimatedSize',
  /** column name */
  ZoomContactZoomCompanyLinkedInUrl = 'zoomContactZoomCompanyLinkedInUrl',
  /** column name */
  ZoomContactZoomCompanyLogoUrl = 'zoomContactZoomCompanyLogoUrl',
  /** column name */
  ZoomContactZoomCompanyName = 'zoomContactZoomCompanyName',
  /** column name */
  ZoomContactZoomCompanySeoDescription = 'zoomContactZoomCompanySeoDescription',
  /** column name */
  ZoomContactZoomCompanyState = 'zoomContactZoomCompanyState',
  /** column name */
  ZoomContactZoomCompanyWebsiteDomain = 'zoomContactZoomCompanyWebsiteDomain',
  /** column name */
  ZoomUserAsk = 'zoomUserAsk',
  /** column name */
  ZoomUserCity = 'zoomUserCity',
  /** column name */
  ZoomUserCompanyLogoUrl = 'zoomUserCompanyLogoUrl',
  /** column name */
  ZoomUserCompanyName = 'zoomUserCompanyName',
  /** column name */
  ZoomUserCompanyWebsiteDomain = 'zoomUserCompanyWebsiteDomain',
  /** column name */
  ZoomUserCountry = 'zoomUserCountry',
  /** column name */
  ZoomUserFirstName = 'zoomUserFirstName',
  /** column name */
  ZoomUserFirstNamePronunciation = 'zoomUserFirstNamePronunciation',
  /** column name */
  ZoomUserFullNameGenerated = 'zoomUserFullNameGenerated',
  /** column name */
  ZoomUserHeadline = 'zoomUserHeadline',
  /** column name */
  ZoomUserId = 'zoomUserId',
  /** column name */
  ZoomUserLastName = 'zoomUserLastName',
  /** column name */
  ZoomUserLastNamePronunciation = 'zoomUserLastNamePronunciation',
  /** column name */
  ZoomUserLinkedInUrl = 'zoomUserLinkedInUrl',
  /** column name */
  ZoomUserLocation = 'zoomUserLocation',
  /** column name */
  ZoomUserNetworkZoomUserId = 'zoomUserNetworkZoomUserId',
  /** column name */
  ZoomUserPhotoUrl = 'zoomUserPhotoUrl',
  /** column name */
  ZoomUserPronouns = 'zoomUserPronouns',
  /** column name */
  ZoomUserScheduleMeetingUrl = 'zoomUserScheduleMeetingUrl',
  /** column name */
  ZoomUserStartDate = 'zoomUserStartDate',
  /** column name */
  ZoomUserState = 'zoomUserState',
  /** column name */
  ZoomUserStrengths = 'zoomUserStrengths',
  /** column name */
  ZoomUserTitle = 'zoomUserTitle',
  /** column name */
  ZoomUserTwitterUrl = 'zoomUserTwitterUrl',
  /** column name */
  ZoomUserZoomCompanyCity = 'zoomUserZoomCompanyCity',
  /** column name */
  ZoomUserZoomCompanyCrunchbaseUrl = 'zoomUserZoomCompanyCrunchbaseUrl',
  /** column name */
  ZoomUserZoomCompanyDescription = 'zoomUserZoomCompanyDescription',
  /** column name */
  ZoomUserZoomCompanyEstimatedSize = 'zoomUserZoomCompanyEstimatedSize',
  /** column name */
  ZoomUserZoomCompanyLinkedInUrl = 'zoomUserZoomCompanyLinkedInUrl',
  /** column name */
  ZoomUserZoomCompanyLogoUrl = 'zoomUserZoomCompanyLogoUrl',
  /** column name */
  ZoomUserZoomCompanyName = 'zoomUserZoomCompanyName',
  /** column name */
  ZoomUserZoomCompanySeoDescription = 'zoomUserZoomCompanySeoDescription',
  /** column name */
  ZoomUserZoomCompanyState = 'zoomUserZoomCompanyState',
  /** column name */
  ZoomUserZoomCompanyWebsiteDomain = 'zoomUserZoomCompanyWebsiteDomain',
}

/** aggregate stddev on columns */
export type ZoomUserNetwork_Stddev_Fields = {
  __typename?: 'zoomUserNetwork_stddev_fields';
  eventCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomUserNetwork_Stddev_Pop_Fields = {
  __typename?: 'zoomUserNetwork_stddev_pop_fields';
  eventCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomUserNetwork_Stddev_Samp_Fields = {
  __typename?: 'zoomUserNetwork_stddev_samp_fields';
  eventCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomUserNetwork" */
export type ZoomUserNetwork_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomUserNetwork_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomUserNetwork_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  eventCount?: InputMaybe<Scalars['bigint']>;
  lastEventStart?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  zoomContactCity?: InputMaybe<Scalars['String']>;
  zoomContactCountry?: InputMaybe<Scalars['String']>;
  zoomContactFirstName?: InputMaybe<Scalars['String']>;
  zoomContactFullNameGenerated?: InputMaybe<Scalars['String']>;
  zoomContactId?: InputMaybe<Scalars['uuid']>;
  zoomContactLastName?: InputMaybe<Scalars['String']>;
  zoomContactLinkedInUrl?: InputMaybe<Scalars['String']>;
  zoomContactLocation?: InputMaybe<Scalars['jsonb']>;
  zoomContactPhotoUrl?: InputMaybe<Scalars['String']>;
  zoomContactState?: InputMaybe<Scalars['String']>;
  zoomContactTitle?: InputMaybe<Scalars['String']>;
  zoomContactTwitterUrl?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyCity?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyCrunchbaseUrl?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyDescription?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyEstimatedSize?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyLinkedInUrl?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyLogoUrl?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyName?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanySeoDescription?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyState?: InputMaybe<Scalars['String']>;
  zoomContactZoomCompanyWebsiteDomain?: InputMaybe<Scalars['String']>;
  zoomUserAsk?: InputMaybe<Scalars['String']>;
  zoomUserCity?: InputMaybe<Scalars['String']>;
  zoomUserCompanyLogoUrl?: InputMaybe<Scalars['String']>;
  zoomUserCompanyName?: InputMaybe<Scalars['String']>;
  zoomUserCompanyWebsiteDomain?: InputMaybe<Scalars['String']>;
  zoomUserCountry?: InputMaybe<Scalars['String']>;
  zoomUserFirstName?: InputMaybe<Scalars['String']>;
  zoomUserFirstNamePronunciation?: InputMaybe<Scalars['String']>;
  zoomUserFullNameGenerated?: InputMaybe<Scalars['String']>;
  zoomUserHeadline?: InputMaybe<Scalars['String']>;
  zoomUserId?: InputMaybe<Scalars['uuid']>;
  zoomUserLastName?: InputMaybe<Scalars['String']>;
  zoomUserLastNamePronunciation?: InputMaybe<Scalars['String']>;
  zoomUserLinkedInUrl?: InputMaybe<Scalars['String']>;
  zoomUserLocation?: InputMaybe<Scalars['jsonb']>;
  zoomUserNetworkZoomUserId?: InputMaybe<Scalars['uuid']>;
  zoomUserPhotoUrl?: InputMaybe<Scalars['String']>;
  zoomUserPronouns?: InputMaybe<Scalars['String']>;
  zoomUserScheduleMeetingUrl?: InputMaybe<Scalars['String']>;
  zoomUserStartDate?: InputMaybe<Scalars['timestamptz']>;
  zoomUserState?: InputMaybe<Scalars['String']>;
  zoomUserStrengths?: InputMaybe<Scalars['String']>;
  zoomUserTitle?: InputMaybe<Scalars['String']>;
  zoomUserTwitterUrl?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyCity?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyCrunchbaseUrl?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyDescription?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyEstimatedSize?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyLinkedInUrl?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyLogoUrl?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyName?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanySeoDescription?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyState?: InputMaybe<Scalars['String']>;
  zoomUserZoomCompanyWebsiteDomain?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ZoomUserNetwork_Sum_Fields = {
  __typename?: 'zoomUserNetwork_sum_fields';
  eventCount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type ZoomUserNetwork_Var_Pop_Fields = {
  __typename?: 'zoomUserNetwork_var_pop_fields';
  eventCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomUserNetwork_Var_Samp_Fields = {
  __typename?: 'zoomUserNetwork_var_samp_fields';
  eventCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomUserNetwork_Variance_Fields = {
  __typename?: 'zoomUserNetwork_variance_fields';
  eventCount?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "zoomUser" */
export type ZoomUser_Aggregate = {
  __typename?: 'zoomUser_aggregate';
  aggregate?: Maybe<ZoomUser_Aggregate_Fields>;
  nodes: Array<ZoomUser>;
};

export type ZoomUser_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ZoomUser_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ZoomUser_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ZoomUser_Aggregate_Bool_Exp_Count>;
};

export type ZoomUser_Aggregate_Bool_Exp_Bool_And = {
  arguments: ZoomUser_Select_Column_ZoomUser_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomUser_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ZoomUser_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ZoomUser_Select_Column_ZoomUser_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomUser_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ZoomUser_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ZoomUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ZoomUser_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "zoomUser" */
export type ZoomUser_Aggregate_Fields = {
  __typename?: 'zoomUser_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ZoomUser_Max_Fields>;
  min?: Maybe<ZoomUser_Min_Fields>;
};

/** aggregate fields of "zoomUser" */
export type ZoomUser_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomUser_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomUser" */
export type ZoomUser_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ZoomUser_Max_Order_By>;
  min?: InputMaybe<ZoomUser_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomUser_Append_Input = {
  backgroundVisibility?: InputMaybe<Scalars['jsonb']>;
  googleScopes?: InputMaybe<Scalars['jsonb']>;
  location?: InputMaybe<Scalars['jsonb']>;
  microsoftScopes?: InputMaybe<Scalars['jsonb']>;
  nametagSettings?: InputMaybe<Scalars['jsonb']>;
  profileVisibility?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "zoomUser" */
export type ZoomUser_Arr_Rel_Insert_Input = {
  data: Array<ZoomUser_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomUser_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomUser". All fields are combined with a logical 'AND'. */
export type ZoomUser_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomUser_Bool_Exp>>;
  _not?: InputMaybe<ZoomUser_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomUser_Bool_Exp>>;
  actions?: InputMaybe<ZoomUserActions_Bool_Exp>;
  addedToVideoConversationStarterPromptId?: InputMaybe<Uuid_Comparison_Exp>;
  ask?: InputMaybe<String_Comparison_Exp>;
  backgroundVisibility?: InputMaybe<Jsonb_Comparison_Exp>;
  calendarEnrichmentStatus?: InputMaybe<String_Comparison_Exp>;
  calendarLastSyncedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  companyLogoUrl?: InputMaybe<String_Comparison_Exp>;
  companyName?: InputMaybe<String_Comparison_Exp>;
  companyWebsiteDomain?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customBackgroundPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<String_Comparison_Exp>;
  department?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firebaseUid?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  firstNamePronunciation?: InputMaybe<String_Comparison_Exp>;
  fullNameGenerated?: InputMaybe<String_Comparison_Exp>;
  googleAccessToken?: InputMaybe<String_Comparison_Exp>;
  googleRefreshToken?: InputMaybe<String_Comparison_Exp>;
  googleRefreshTokenStatus?: InputMaybe<String_Comparison_Exp>;
  googleScopes?: InputMaybe<Jsonb_Comparison_Exp>;
  googleSyncToken?: InputMaybe<String_Comparison_Exp>;
  headline?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integrationType?: InputMaybe<String_Comparison_Exp>;
  intercomOnboardingStartedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isGroupAdmin?: InputMaybe<Boolean_Comparison_Exp>;
  isGroupSuper?: InputMaybe<Boolean_Comparison_Exp>;
  isOrgAdmin?: InputMaybe<Boolean_Comparison_Exp>;
  lastJoinedMeetingUuid?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  lastNamePronunciation?: InputMaybe<String_Comparison_Exp>;
  linkedInUrl?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  locationUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  microsoftAccessToken?: InputMaybe<String_Comparison_Exp>;
  microsoftAccountId?: InputMaybe<String_Comparison_Exp>;
  microsoftDeltaToken?: InputMaybe<String_Comparison_Exp>;
  microsoftIdToken?: InputMaybe<String_Comparison_Exp>;
  microsoftScopes?: InputMaybe<Jsonb_Comparison_Exp>;
  nametagSettings?: InputMaybe<Jsonb_Comparison_Exp>;
  nylasAccessToken?: InputMaybe<String_Comparison_Exp>;
  nylasAccountId?: InputMaybe<String_Comparison_Exp>;
  nylasLinkedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  nylasSyncState?: InputMaybe<String_Comparison_Exp>;
  onboardVersion?: InputMaybe<String_Comparison_Exp>;
  onboardingType?: InputMaybe<String_Comparison_Exp>;
  onlyInjectWarmlyUsers?: InputMaybe<Boolean_Comparison_Exp>;
  photoUrl?: InputMaybe<String_Comparison_Exp>;
  pmfSurveyDisplayedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  profileUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  profileVisibility?: InputMaybe<Jsonb_Comparison_Exp>;
  pronouns?: InputMaybe<String_Comparison_Exp>;
  recurringEventsInjectionEnabledAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  roleBucket?: InputMaybe<String_Comparison_Exp>;
  runningLateTemplate?: InputMaybe<String_Comparison_Exp>;
  scheduleMeetingUrl?: InputMaybe<String_Comparison_Exp>;
  ssoDomain?: InputMaybe<String_Comparison_Exp>;
  startDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  strengths?: InputMaybe<String_Comparison_Exp>;
  stripeCustomerId?: InputMaybe<String_Comparison_Exp>;
  syncGoogleCalendar?: InputMaybe<Boolean_Comparison_Exp>;
  temperatureScale?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  twitterUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  whereAreYouTemplate?: InputMaybe<String_Comparison_Exp>;
  zoomCompany?: InputMaybe<ZoomCompany_Bool_Exp>;
  zoomCompanyId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomContact?: InputMaybe<ZoomContact_Bool_Exp>;
  zoomEventParticipants?: InputMaybe<ZoomEventParticipant_Bool_Exp>;
  zoomEventParticipants_aggregate?: InputMaybe<ZoomEventParticipant_Aggregate_Bool_Exp>;
  zoomExternalAuthUserId?: InputMaybe<Uuid_Comparison_Exp>;
  zoomGroupMemberships?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
  zoomGroupMemberships_aggregate?: InputMaybe<ZoomGroupMembership_Aggregate_Bool_Exp>;
  zoomOrganizationMembership?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
  zoomPlan?: InputMaybe<ZoomPlan_Bool_Exp>;
  zoomUserActions?: InputMaybe<ZoomUserActions_Bool_Exp>;
  zoomUserActions_aggregate?: InputMaybe<ZoomUserActions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomUser" */
export enum ZoomUser_Constraint {
  /** unique or primary key constraint on columns "email" */
  ZoomUserEmailKey = 'zoomUser_email_key',
  /** unique or primary key constraint on columns "firebaseUid" */
  ZoomUserFirebaseUidKey = 'zoomUser_firebaseUid_key',
  /** unique or primary key constraint on columns "id" */
  ZoomUserPkey = 'zoomUser_pkey',
  /** unique or primary key constraint on columns "username" */
  ZoomUserUsernameKey = 'zoomUser_username_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomUser_Delete_At_Path_Input = {
  backgroundVisibility?: InputMaybe<Array<Scalars['String']>>;
  googleScopes?: InputMaybe<Array<Scalars['String']>>;
  location?: InputMaybe<Array<Scalars['String']>>;
  microsoftScopes?: InputMaybe<Array<Scalars['String']>>;
  nametagSettings?: InputMaybe<Array<Scalars['String']>>;
  profileVisibility?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomUser_Delete_Elem_Input = {
  backgroundVisibility?: InputMaybe<Scalars['Int']>;
  googleScopes?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['Int']>;
  microsoftScopes?: InputMaybe<Scalars['Int']>;
  nametagSettings?: InputMaybe<Scalars['Int']>;
  profileVisibility?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomUser_Delete_Key_Input = {
  backgroundVisibility?: InputMaybe<Scalars['String']>;
  googleScopes?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  microsoftScopes?: InputMaybe<Scalars['String']>;
  nametagSettings?: InputMaybe<Scalars['String']>;
  profileVisibility?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "zoomUser" */
export type ZoomUser_Insert_Input = {
  actions?: InputMaybe<ZoomUserActions_Obj_Rel_Insert_Input>;
  addedToVideoConversationStarterPromptId?: InputMaybe<Scalars['uuid']>;
  ask?: InputMaybe<Scalars['String']>;
  backgroundVisibility?: InputMaybe<Scalars['jsonb']>;
  calendarEnrichmentStatus?: InputMaybe<Scalars['String']>;
  calendarLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  city?: InputMaybe<Scalars['String']>;
  companyLogoUrl?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyWebsiteDomain?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstNamePronunciation?: InputMaybe<Scalars['String']>;
  googleAccessToken?: InputMaybe<Scalars['String']>;
  googleRefreshToken?: InputMaybe<Scalars['String']>;
  googleRefreshTokenStatus?: InputMaybe<Scalars['String']>;
  googleScopes?: InputMaybe<Scalars['jsonb']>;
  googleSyncToken?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Scalars['String']>;
  intercomOnboardingStartedAt?: InputMaybe<Scalars['timestamptz']>;
  lastJoinedMeetingUuid?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNamePronunciation?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['jsonb']>;
  locationUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  microsoftAccessToken?: InputMaybe<Scalars['String']>;
  microsoftAccountId?: InputMaybe<Scalars['String']>;
  microsoftDeltaToken?: InputMaybe<Scalars['String']>;
  microsoftIdToken?: InputMaybe<Scalars['String']>;
  microsoftScopes?: InputMaybe<Scalars['jsonb']>;
  nametagSettings?: InputMaybe<Scalars['jsonb']>;
  nylasAccessToken?: InputMaybe<Scalars['String']>;
  nylasAccountId?: InputMaybe<Scalars['String']>;
  nylasLinkedAt?: InputMaybe<Scalars['timestamptz']>;
  nylasSyncState?: InputMaybe<Scalars['String']>;
  onboardVersion?: InputMaybe<Scalars['String']>;
  onboardingType?: InputMaybe<Scalars['String']>;
  onlyInjectWarmlyUsers?: InputMaybe<Scalars['Boolean']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  pmfSurveyDisplayedAt?: InputMaybe<Scalars['timestamptz']>;
  profileUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  profileVisibility?: InputMaybe<Scalars['jsonb']>;
  pronouns?: InputMaybe<Scalars['String']>;
  recurringEventsInjectionEnabledAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  roleBucket?: InputMaybe<Scalars['String']>;
  runningLateTemplate?: InputMaybe<Scalars['String']>;
  scheduleMeetingUrl?: InputMaybe<Scalars['String']>;
  ssoDomain?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  strengths?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  syncGoogleCalendar?: InputMaybe<Scalars['Boolean']>;
  temperatureScale?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  whereAreYouTemplate?: InputMaybe<Scalars['String']>;
  zoomCompany?: InputMaybe<ZoomCompany_Obj_Rel_Insert_Input>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
  zoomContact?: InputMaybe<ZoomContact_Obj_Rel_Insert_Input>;
  zoomEventParticipants?: InputMaybe<ZoomEventParticipant_Arr_Rel_Insert_Input>;
  zoomExternalAuthUserId?: InputMaybe<Scalars['uuid']>;
  zoomGroupMemberships?: InputMaybe<ZoomGroupMembership_Arr_Rel_Insert_Input>;
  zoomOrganizationMembership?: InputMaybe<ZoomOrganizationMembership_Obj_Rel_Insert_Input>;
  zoomPlan?: InputMaybe<ZoomPlan_Obj_Rel_Insert_Input>;
  zoomUserActions?: InputMaybe<ZoomUserActions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomUser_Max_Fields = {
  __typename?: 'zoomUser_max_fields';
  addedToVideoConversationStarterPromptId?: Maybe<Scalars['uuid']>;
  ask?: Maybe<Scalars['String']>;
  calendarEnrichmentStatus?: Maybe<Scalars['String']>;
  calendarLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  googleAccessToken?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  googleRefreshTokenStatus?: Maybe<Scalars['String']>;
  googleSyncToken?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  intercomOnboardingStartedAt?: Maybe<Scalars['timestamptz']>;
  lastJoinedMeetingUuid?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  locationUpdatedAt?: Maybe<Scalars['timestamptz']>;
  microsoftAccessToken?: Maybe<Scalars['String']>;
  microsoftAccountId?: Maybe<Scalars['String']>;
  microsoftDeltaToken?: Maybe<Scalars['String']>;
  microsoftIdToken?: Maybe<Scalars['String']>;
  nylasAccessToken?: Maybe<Scalars['String']>;
  nylasAccountId?: Maybe<Scalars['String']>;
  nylasLinkedAt?: Maybe<Scalars['timestamptz']>;
  nylasSyncState?: Maybe<Scalars['String']>;
  onboardVersion?: Maybe<Scalars['String']>;
  onboardingType?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pmfSurveyDisplayedAt?: Maybe<Scalars['timestamptz']>;
  profileUpdatedAt?: Maybe<Scalars['timestamptz']>;
  pronouns?: Maybe<Scalars['String']>;
  recurringEventsInjectionEnabledAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  roleBucket?: Maybe<Scalars['String']>;
  runningLateTemplate?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  ssoDomain?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  temperatureScale?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  whereAreYouTemplate?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomExternalAuthUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomUser" */
export type ZoomUser_Max_Order_By = {
  addedToVideoConversationStarterPromptId?: InputMaybe<Order_By>;
  ask?: InputMaybe<Order_By>;
  calendarEnrichmentStatus?: InputMaybe<Order_By>;
  calendarLastSyncedAt?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  companyLogoUrl?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  companyWebsiteDomain?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customBackgroundPhotoUrl?: InputMaybe<Order_By>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Order_By>;
  department?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebaseUid?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  firstNamePronunciation?: InputMaybe<Order_By>;
  fullNameGenerated?: InputMaybe<Order_By>;
  googleAccessToken?: InputMaybe<Order_By>;
  googleRefreshToken?: InputMaybe<Order_By>;
  googleRefreshTokenStatus?: InputMaybe<Order_By>;
  googleSyncToken?: InputMaybe<Order_By>;
  headline?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integrationType?: InputMaybe<Order_By>;
  intercomOnboardingStartedAt?: InputMaybe<Order_By>;
  lastJoinedMeetingUuid?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastNamePronunciation?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  locationUpdatedAt?: InputMaybe<Order_By>;
  microsoftAccessToken?: InputMaybe<Order_By>;
  microsoftAccountId?: InputMaybe<Order_By>;
  microsoftDeltaToken?: InputMaybe<Order_By>;
  microsoftIdToken?: InputMaybe<Order_By>;
  nylasAccessToken?: InputMaybe<Order_By>;
  nylasAccountId?: InputMaybe<Order_By>;
  nylasLinkedAt?: InputMaybe<Order_By>;
  nylasSyncState?: InputMaybe<Order_By>;
  onboardVersion?: InputMaybe<Order_By>;
  onboardingType?: InputMaybe<Order_By>;
  photoUrl?: InputMaybe<Order_By>;
  pmfSurveyDisplayedAt?: InputMaybe<Order_By>;
  profileUpdatedAt?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  recurringEventsInjectionEnabledAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleBucket?: InputMaybe<Order_By>;
  runningLateTemplate?: InputMaybe<Order_By>;
  scheduleMeetingUrl?: InputMaybe<Order_By>;
  ssoDomain?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  strengths?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  temperatureScale?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  whereAreYouTemplate?: InputMaybe<Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
  zoomExternalAuthUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomUser_Min_Fields = {
  __typename?: 'zoomUser_min_fields';
  addedToVideoConversationStarterPromptId?: Maybe<Scalars['uuid']>;
  ask?: Maybe<Scalars['String']>;
  calendarEnrichmentStatus?: Maybe<Scalars['String']>;
  calendarLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyWebsiteDomain?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstNamePronunciation?: Maybe<Scalars['String']>;
  fullNameGenerated?: Maybe<Scalars['String']>;
  googleAccessToken?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  googleRefreshTokenStatus?: Maybe<Scalars['String']>;
  googleSyncToken?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  intercomOnboardingStartedAt?: Maybe<Scalars['timestamptz']>;
  lastJoinedMeetingUuid?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePronunciation?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  locationUpdatedAt?: Maybe<Scalars['timestamptz']>;
  microsoftAccessToken?: Maybe<Scalars['String']>;
  microsoftAccountId?: Maybe<Scalars['String']>;
  microsoftDeltaToken?: Maybe<Scalars['String']>;
  microsoftIdToken?: Maybe<Scalars['String']>;
  nylasAccessToken?: Maybe<Scalars['String']>;
  nylasAccountId?: Maybe<Scalars['String']>;
  nylasLinkedAt?: Maybe<Scalars['timestamptz']>;
  nylasSyncState?: Maybe<Scalars['String']>;
  onboardVersion?: Maybe<Scalars['String']>;
  onboardingType?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pmfSurveyDisplayedAt?: Maybe<Scalars['timestamptz']>;
  profileUpdatedAt?: Maybe<Scalars['timestamptz']>;
  pronouns?: Maybe<Scalars['String']>;
  recurringEventsInjectionEnabledAt?: Maybe<Scalars['timestamptz']>;
  role?: Maybe<Scalars['String']>;
  roleBucket?: Maybe<Scalars['String']>;
  runningLateTemplate?: Maybe<Scalars['String']>;
  scheduleMeetingUrl?: Maybe<Scalars['String']>;
  ssoDomain?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  state?: Maybe<Scalars['String']>;
  strengths?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  temperatureScale?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  whereAreYouTemplate?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomExternalAuthUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomUser" */
export type ZoomUser_Min_Order_By = {
  addedToVideoConversationStarterPromptId?: InputMaybe<Order_By>;
  ask?: InputMaybe<Order_By>;
  calendarEnrichmentStatus?: InputMaybe<Order_By>;
  calendarLastSyncedAt?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  companyLogoUrl?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  companyWebsiteDomain?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customBackgroundPhotoUrl?: InputMaybe<Order_By>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Order_By>;
  department?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebaseUid?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  firstNamePronunciation?: InputMaybe<Order_By>;
  fullNameGenerated?: InputMaybe<Order_By>;
  googleAccessToken?: InputMaybe<Order_By>;
  googleRefreshToken?: InputMaybe<Order_By>;
  googleRefreshTokenStatus?: InputMaybe<Order_By>;
  googleSyncToken?: InputMaybe<Order_By>;
  headline?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integrationType?: InputMaybe<Order_By>;
  intercomOnboardingStartedAt?: InputMaybe<Order_By>;
  lastJoinedMeetingUuid?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastNamePronunciation?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  locationUpdatedAt?: InputMaybe<Order_By>;
  microsoftAccessToken?: InputMaybe<Order_By>;
  microsoftAccountId?: InputMaybe<Order_By>;
  microsoftDeltaToken?: InputMaybe<Order_By>;
  microsoftIdToken?: InputMaybe<Order_By>;
  nylasAccessToken?: InputMaybe<Order_By>;
  nylasAccountId?: InputMaybe<Order_By>;
  nylasLinkedAt?: InputMaybe<Order_By>;
  nylasSyncState?: InputMaybe<Order_By>;
  onboardVersion?: InputMaybe<Order_By>;
  onboardingType?: InputMaybe<Order_By>;
  photoUrl?: InputMaybe<Order_By>;
  pmfSurveyDisplayedAt?: InputMaybe<Order_By>;
  profileUpdatedAt?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  recurringEventsInjectionEnabledAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleBucket?: InputMaybe<Order_By>;
  runningLateTemplate?: InputMaybe<Order_By>;
  scheduleMeetingUrl?: InputMaybe<Order_By>;
  ssoDomain?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  strengths?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  temperatureScale?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  whereAreYouTemplate?: InputMaybe<Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
  zoomExternalAuthUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "zoomUser" */
export type ZoomUser_Mutation_Response = {
  __typename?: 'zoomUser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZoomUser>;
};

/** input type for inserting object relation for remote table "zoomUser" */
export type ZoomUser_Obj_Rel_Insert_Input = {
  data: ZoomUser_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoomUser_On_Conflict>;
};

/** on_conflict condition type for table "zoomUser" */
export type ZoomUser_On_Conflict = {
  constraint: ZoomUser_Constraint;
  update_columns?: Array<ZoomUser_Update_Column>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
};

/** Ordering options when selecting data from "zoomUser". */
export type ZoomUser_Order_By = {
  actions?: InputMaybe<ZoomUserActions_Order_By>;
  addedToVideoConversationStarterPromptId?: InputMaybe<Order_By>;
  ask?: InputMaybe<Order_By>;
  backgroundVisibility?: InputMaybe<Order_By>;
  calendarEnrichmentStatus?: InputMaybe<Order_By>;
  calendarLastSyncedAt?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  companyLogoUrl?: InputMaybe<Order_By>;
  companyName?: InputMaybe<Order_By>;
  companyWebsiteDomain?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customBackgroundPhotoUrl?: InputMaybe<Order_By>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Order_By>;
  department?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firebaseUid?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  firstNamePronunciation?: InputMaybe<Order_By>;
  fullNameGenerated?: InputMaybe<Order_By>;
  googleAccessToken?: InputMaybe<Order_By>;
  googleRefreshToken?: InputMaybe<Order_By>;
  googleRefreshTokenStatus?: InputMaybe<Order_By>;
  googleScopes?: InputMaybe<Order_By>;
  googleSyncToken?: InputMaybe<Order_By>;
  headline?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integrationType?: InputMaybe<Order_By>;
  intercomOnboardingStartedAt?: InputMaybe<Order_By>;
  isGroupAdmin?: InputMaybe<Order_By>;
  isGroupSuper?: InputMaybe<Order_By>;
  isOrgAdmin?: InputMaybe<Order_By>;
  lastJoinedMeetingUuid?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastNamePronunciation?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  locationUpdatedAt?: InputMaybe<Order_By>;
  microsoftAccessToken?: InputMaybe<Order_By>;
  microsoftAccountId?: InputMaybe<Order_By>;
  microsoftDeltaToken?: InputMaybe<Order_By>;
  microsoftIdToken?: InputMaybe<Order_By>;
  microsoftScopes?: InputMaybe<Order_By>;
  nametagSettings?: InputMaybe<Order_By>;
  nylasAccessToken?: InputMaybe<Order_By>;
  nylasAccountId?: InputMaybe<Order_By>;
  nylasLinkedAt?: InputMaybe<Order_By>;
  nylasSyncState?: InputMaybe<Order_By>;
  onboardVersion?: InputMaybe<Order_By>;
  onboardingType?: InputMaybe<Order_By>;
  onlyInjectWarmlyUsers?: InputMaybe<Order_By>;
  photoUrl?: InputMaybe<Order_By>;
  pmfSurveyDisplayedAt?: InputMaybe<Order_By>;
  profileUpdatedAt?: InputMaybe<Order_By>;
  profileVisibility?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  recurringEventsInjectionEnabledAt?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleBucket?: InputMaybe<Order_By>;
  runningLateTemplate?: InputMaybe<Order_By>;
  scheduleMeetingUrl?: InputMaybe<Order_By>;
  ssoDomain?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  strengths?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  syncGoogleCalendar?: InputMaybe<Order_By>;
  temperatureScale?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  whereAreYouTemplate?: InputMaybe<Order_By>;
  zoomCompany?: InputMaybe<ZoomCompany_Order_By>;
  zoomCompanyId?: InputMaybe<Order_By>;
  zoomContact?: InputMaybe<ZoomContact_Order_By>;
  zoomEventParticipants_aggregate?: InputMaybe<ZoomEventParticipant_Aggregate_Order_By>;
  zoomExternalAuthUserId?: InputMaybe<Order_By>;
  zoomGroupMemberships_aggregate?: InputMaybe<ZoomGroupMembership_Aggregate_Order_By>;
  zoomOrganizationMembership?: InputMaybe<ZoomOrganizationMembership_Order_By>;
  zoomPlan?: InputMaybe<ZoomPlan_Order_By>;
  zoomUserActions_aggregate?: InputMaybe<ZoomUserActions_Aggregate_Order_By>;
};

/** primary key columns input for table: zoomUser */
export type ZoomUser_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomUser_Prepend_Input = {
  backgroundVisibility?: InputMaybe<Scalars['jsonb']>;
  googleScopes?: InputMaybe<Scalars['jsonb']>;
  location?: InputMaybe<Scalars['jsonb']>;
  microsoftScopes?: InputMaybe<Scalars['jsonb']>;
  nametagSettings?: InputMaybe<Scalars['jsonb']>;
  profileVisibility?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "zoomUser" */
export enum ZoomUser_Select_Column {
  /** column name */
  AddedToVideoConversationStarterPromptId = 'addedToVideoConversationStarterPromptId',
  /** column name */
  Ask = 'ask',
  /** column name */
  BackgroundVisibility = 'backgroundVisibility',
  /** column name */
  CalendarEnrichmentStatus = 'calendarEnrichmentStatus',
  /** column name */
  CalendarLastSyncedAt = 'calendarLastSyncedAt',
  /** column name */
  City = 'city',
  /** column name */
  CompanyLogoUrl = 'companyLogoUrl',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CompanyWebsiteDomain = 'companyWebsiteDomain',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomBackgroundPhotoUrl = 'customBackgroundPhotoUrl',
  /** column name */
  DefaultCustomBackgroundPhotoUrl = 'defaultCustomBackgroundPhotoUrl',
  /** column name */
  Department = 'department',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FirstNamePronunciation = 'firstNamePronunciation',
  /** column name */
  FullNameGenerated = 'fullNameGenerated',
  /** column name */
  GoogleAccessToken = 'googleAccessToken',
  /** column name */
  GoogleRefreshToken = 'googleRefreshToken',
  /** column name */
  GoogleRefreshTokenStatus = 'googleRefreshTokenStatus',
  /** column name */
  GoogleScopes = 'googleScopes',
  /** column name */
  GoogleSyncToken = 'googleSyncToken',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  IntercomOnboardingStartedAt = 'intercomOnboardingStartedAt',
  /** column name */
  LastJoinedMeetingUuid = 'lastJoinedMeetingUuid',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastNamePronunciation = 'lastNamePronunciation',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Location = 'location',
  /** column name */
  LocationUpdatedAt = 'locationUpdatedAt',
  /** column name */
  MicrosoftAccessToken = 'microsoftAccessToken',
  /** column name */
  MicrosoftAccountId = 'microsoftAccountId',
  /** column name */
  MicrosoftDeltaToken = 'microsoftDeltaToken',
  /** column name */
  MicrosoftIdToken = 'microsoftIdToken',
  /** column name */
  MicrosoftScopes = 'microsoftScopes',
  /** column name */
  NametagSettings = 'nametagSettings',
  /** column name */
  NylasAccessToken = 'nylasAccessToken',
  /** column name */
  NylasAccountId = 'nylasAccountId',
  /** column name */
  NylasLinkedAt = 'nylasLinkedAt',
  /** column name */
  NylasSyncState = 'nylasSyncState',
  /** column name */
  OnboardVersion = 'onboardVersion',
  /** column name */
  OnboardingType = 'onboardingType',
  /** column name */
  OnlyInjectWarmlyUsers = 'onlyInjectWarmlyUsers',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  PmfSurveyDisplayedAt = 'pmfSurveyDisplayedAt',
  /** column name */
  ProfileUpdatedAt = 'profileUpdatedAt',
  /** column name */
  ProfileVisibility = 'profileVisibility',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  RecurringEventsInjectionEnabledAt = 'recurringEventsInjectionEnabledAt',
  /** column name */
  Role = 'role',
  /** column name */
  RoleBucket = 'roleBucket',
  /** column name */
  RunningLateTemplate = 'runningLateTemplate',
  /** column name */
  ScheduleMeetingUrl = 'scheduleMeetingUrl',
  /** column name */
  SsoDomain = 'ssoDomain',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  State = 'state',
  /** column name */
  Strengths = 'strengths',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  SyncGoogleCalendar = 'syncGoogleCalendar',
  /** column name */
  TemperatureScale = 'temperatureScale',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username',
  /** column name */
  WhereAreYouTemplate = 'whereAreYouTemplate',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
  /** column name */
  ZoomExternalAuthUserId = 'zoomExternalAuthUserId',
}

/** select "zoomUser_aggregate_bool_exp_bool_and_arguments_columns" columns of table "zoomUser" */
export enum ZoomUser_Select_Column_ZoomUser_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  OnlyInjectWarmlyUsers = 'onlyInjectWarmlyUsers',
  /** column name */
  SyncGoogleCalendar = 'syncGoogleCalendar',
}

/** select "zoomUser_aggregate_bool_exp_bool_or_arguments_columns" columns of table "zoomUser" */
export enum ZoomUser_Select_Column_ZoomUser_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  OnlyInjectWarmlyUsers = 'onlyInjectWarmlyUsers',
  /** column name */
  SyncGoogleCalendar = 'syncGoogleCalendar',
}

/** input type for updating data in table "zoomUser" */
export type ZoomUser_Set_Input = {
  addedToVideoConversationStarterPromptId?: InputMaybe<Scalars['uuid']>;
  ask?: InputMaybe<Scalars['String']>;
  backgroundVisibility?: InputMaybe<Scalars['jsonb']>;
  calendarEnrichmentStatus?: InputMaybe<Scalars['String']>;
  calendarLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  city?: InputMaybe<Scalars['String']>;
  companyLogoUrl?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyWebsiteDomain?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstNamePronunciation?: InputMaybe<Scalars['String']>;
  googleAccessToken?: InputMaybe<Scalars['String']>;
  googleRefreshToken?: InputMaybe<Scalars['String']>;
  googleRefreshTokenStatus?: InputMaybe<Scalars['String']>;
  googleScopes?: InputMaybe<Scalars['jsonb']>;
  googleSyncToken?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Scalars['String']>;
  intercomOnboardingStartedAt?: InputMaybe<Scalars['timestamptz']>;
  lastJoinedMeetingUuid?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNamePronunciation?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['jsonb']>;
  locationUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  microsoftAccessToken?: InputMaybe<Scalars['String']>;
  microsoftAccountId?: InputMaybe<Scalars['String']>;
  microsoftDeltaToken?: InputMaybe<Scalars['String']>;
  microsoftIdToken?: InputMaybe<Scalars['String']>;
  microsoftScopes?: InputMaybe<Scalars['jsonb']>;
  nametagSettings?: InputMaybe<Scalars['jsonb']>;
  nylasAccessToken?: InputMaybe<Scalars['String']>;
  nylasAccountId?: InputMaybe<Scalars['String']>;
  nylasLinkedAt?: InputMaybe<Scalars['timestamptz']>;
  nylasSyncState?: InputMaybe<Scalars['String']>;
  onboardVersion?: InputMaybe<Scalars['String']>;
  onboardingType?: InputMaybe<Scalars['String']>;
  onlyInjectWarmlyUsers?: InputMaybe<Scalars['Boolean']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  pmfSurveyDisplayedAt?: InputMaybe<Scalars['timestamptz']>;
  profileUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  profileVisibility?: InputMaybe<Scalars['jsonb']>;
  pronouns?: InputMaybe<Scalars['String']>;
  recurringEventsInjectionEnabledAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  roleBucket?: InputMaybe<Scalars['String']>;
  runningLateTemplate?: InputMaybe<Scalars['String']>;
  scheduleMeetingUrl?: InputMaybe<Scalars['String']>;
  ssoDomain?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  strengths?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  syncGoogleCalendar?: InputMaybe<Scalars['Boolean']>;
  temperatureScale?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  whereAreYouTemplate?: InputMaybe<Scalars['String']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
  zoomExternalAuthUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "zoomUser" */
export type ZoomUser_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomUser_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomUser_Stream_Cursor_Value_Input = {
  addedToVideoConversationStarterPromptId?: InputMaybe<Scalars['uuid']>;
  ask?: InputMaybe<Scalars['String']>;
  backgroundVisibility?: InputMaybe<Scalars['jsonb']>;
  calendarEnrichmentStatus?: InputMaybe<Scalars['String']>;
  calendarLastSyncedAt?: InputMaybe<Scalars['timestamptz']>;
  city?: InputMaybe<Scalars['String']>;
  companyLogoUrl?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyWebsiteDomain?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  defaultCustomBackgroundPhotoUrl?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstNamePronunciation?: InputMaybe<Scalars['String']>;
  fullNameGenerated?: InputMaybe<Scalars['String']>;
  googleAccessToken?: InputMaybe<Scalars['String']>;
  googleRefreshToken?: InputMaybe<Scalars['String']>;
  googleRefreshTokenStatus?: InputMaybe<Scalars['String']>;
  googleScopes?: InputMaybe<Scalars['jsonb']>;
  googleSyncToken?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<Scalars['String']>;
  intercomOnboardingStartedAt?: InputMaybe<Scalars['timestamptz']>;
  lastJoinedMeetingUuid?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNamePronunciation?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['jsonb']>;
  locationUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  microsoftAccessToken?: InputMaybe<Scalars['String']>;
  microsoftAccountId?: InputMaybe<Scalars['String']>;
  microsoftDeltaToken?: InputMaybe<Scalars['String']>;
  microsoftIdToken?: InputMaybe<Scalars['String']>;
  microsoftScopes?: InputMaybe<Scalars['jsonb']>;
  nametagSettings?: InputMaybe<Scalars['jsonb']>;
  nylasAccessToken?: InputMaybe<Scalars['String']>;
  nylasAccountId?: InputMaybe<Scalars['String']>;
  nylasLinkedAt?: InputMaybe<Scalars['timestamptz']>;
  nylasSyncState?: InputMaybe<Scalars['String']>;
  onboardVersion?: InputMaybe<Scalars['String']>;
  onboardingType?: InputMaybe<Scalars['String']>;
  onlyInjectWarmlyUsers?: InputMaybe<Scalars['Boolean']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  pmfSurveyDisplayedAt?: InputMaybe<Scalars['timestamptz']>;
  profileUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
  profileVisibility?: InputMaybe<Scalars['jsonb']>;
  pronouns?: InputMaybe<Scalars['String']>;
  recurringEventsInjectionEnabledAt?: InputMaybe<Scalars['timestamptz']>;
  role?: InputMaybe<Scalars['String']>;
  roleBucket?: InputMaybe<Scalars['String']>;
  runningLateTemplate?: InputMaybe<Scalars['String']>;
  scheduleMeetingUrl?: InputMaybe<Scalars['String']>;
  ssoDomain?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamptz']>;
  state?: InputMaybe<Scalars['String']>;
  strengths?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  syncGoogleCalendar?: InputMaybe<Scalars['Boolean']>;
  temperatureScale?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  whereAreYouTemplate?: InputMaybe<Scalars['String']>;
  zoomCompanyId?: InputMaybe<Scalars['uuid']>;
  zoomExternalAuthUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "zoomUser" */
export enum ZoomUser_Update_Column {
  /** column name */
  AddedToVideoConversationStarterPromptId = 'addedToVideoConversationStarterPromptId',
  /** column name */
  Ask = 'ask',
  /** column name */
  BackgroundVisibility = 'backgroundVisibility',
  /** column name */
  CalendarEnrichmentStatus = 'calendarEnrichmentStatus',
  /** column name */
  CalendarLastSyncedAt = 'calendarLastSyncedAt',
  /** column name */
  City = 'city',
  /** column name */
  CompanyLogoUrl = 'companyLogoUrl',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CompanyWebsiteDomain = 'companyWebsiteDomain',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomBackgroundPhotoUrl = 'customBackgroundPhotoUrl',
  /** column name */
  DefaultCustomBackgroundPhotoUrl = 'defaultCustomBackgroundPhotoUrl',
  /** column name */
  Department = 'department',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FirstNamePronunciation = 'firstNamePronunciation',
  /** column name */
  GoogleAccessToken = 'googleAccessToken',
  /** column name */
  GoogleRefreshToken = 'googleRefreshToken',
  /** column name */
  GoogleRefreshTokenStatus = 'googleRefreshTokenStatus',
  /** column name */
  GoogleScopes = 'googleScopes',
  /** column name */
  GoogleSyncToken = 'googleSyncToken',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  IntercomOnboardingStartedAt = 'intercomOnboardingStartedAt',
  /** column name */
  LastJoinedMeetingUuid = 'lastJoinedMeetingUuid',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastNamePronunciation = 'lastNamePronunciation',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Location = 'location',
  /** column name */
  LocationUpdatedAt = 'locationUpdatedAt',
  /** column name */
  MicrosoftAccessToken = 'microsoftAccessToken',
  /** column name */
  MicrosoftAccountId = 'microsoftAccountId',
  /** column name */
  MicrosoftDeltaToken = 'microsoftDeltaToken',
  /** column name */
  MicrosoftIdToken = 'microsoftIdToken',
  /** column name */
  MicrosoftScopes = 'microsoftScopes',
  /** column name */
  NametagSettings = 'nametagSettings',
  /** column name */
  NylasAccessToken = 'nylasAccessToken',
  /** column name */
  NylasAccountId = 'nylasAccountId',
  /** column name */
  NylasLinkedAt = 'nylasLinkedAt',
  /** column name */
  NylasSyncState = 'nylasSyncState',
  /** column name */
  OnboardVersion = 'onboardVersion',
  /** column name */
  OnboardingType = 'onboardingType',
  /** column name */
  OnlyInjectWarmlyUsers = 'onlyInjectWarmlyUsers',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  PmfSurveyDisplayedAt = 'pmfSurveyDisplayedAt',
  /** column name */
  ProfileUpdatedAt = 'profileUpdatedAt',
  /** column name */
  ProfileVisibility = 'profileVisibility',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  RecurringEventsInjectionEnabledAt = 'recurringEventsInjectionEnabledAt',
  /** column name */
  Role = 'role',
  /** column name */
  RoleBucket = 'roleBucket',
  /** column name */
  RunningLateTemplate = 'runningLateTemplate',
  /** column name */
  ScheduleMeetingUrl = 'scheduleMeetingUrl',
  /** column name */
  SsoDomain = 'ssoDomain',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  State = 'state',
  /** column name */
  Strengths = 'strengths',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  SyncGoogleCalendar = 'syncGoogleCalendar',
  /** column name */
  TemperatureScale = 'temperatureScale',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username',
  /** column name */
  WhereAreYouTemplate = 'whereAreYouTemplate',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
  /** column name */
  ZoomExternalAuthUserId = 'zoomExternalAuthUserId',
}

export type ZoomUser_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoomUser_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoomUser_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoomUser_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoomUser_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZoomUser_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoomUser_Set_Input>;
  /** filter the rows which have to be updated */
  where: ZoomUser_Bool_Exp;
};

/** columns and relationships of "zoomUsersAtCompany" */
export type ZoomUsersAtCompany = {
  __typename?: 'zoomUsersAtCompany';
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  rawJson?: Maybe<Scalars['jsonb']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
  zoomUserCount?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "zoomUsersAtCompany" */
export type ZoomUsersAtCompanyRawJsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "zoomUsersAtCompany" */
export type ZoomUsersAtCompany_Aggregate = {
  __typename?: 'zoomUsersAtCompany_aggregate';
  aggregate?: Maybe<ZoomUsersAtCompany_Aggregate_Fields>;
  nodes: Array<ZoomUsersAtCompany>;
};

/** aggregate fields of "zoomUsersAtCompany" */
export type ZoomUsersAtCompany_Aggregate_Fields = {
  __typename?: 'zoomUsersAtCompany_aggregate_fields';
  avg?: Maybe<ZoomUsersAtCompany_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ZoomUsersAtCompany_Max_Fields>;
  min?: Maybe<ZoomUsersAtCompany_Min_Fields>;
  stddev?: Maybe<ZoomUsersAtCompany_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomUsersAtCompany_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomUsersAtCompany_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomUsersAtCompany_Sum_Fields>;
  var_pop?: Maybe<ZoomUsersAtCompany_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomUsersAtCompany_Var_Samp_Fields>;
  variance?: Maybe<ZoomUsersAtCompany_Variance_Fields>;
};

/** aggregate fields of "zoomUsersAtCompany" */
export type ZoomUsersAtCompany_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ZoomUsersAtCompany_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZoomUsersAtCompany_Avg_Fields = {
  __typename?: 'zoomUsersAtCompany_avg_fields';
  zoomUserCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoomUsersAtCompany". All fields are combined with a logical 'AND'. */
export type ZoomUsersAtCompany_Bool_Exp = {
  _and?: InputMaybe<Array<ZoomUsersAtCompany_Bool_Exp>>;
  _not?: InputMaybe<ZoomUsersAtCompany_Bool_Exp>;
  _or?: InputMaybe<Array<ZoomUsersAtCompany_Bool_Exp>>;
  alexaRanking?: InputMaybe<String_Comparison_Exp>;
  apolloOrganizationId?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  clearbitCompanyId?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  crunchbaseUrl?: InputMaybe<String_Comparison_Exp>;
  dataVendorName?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  descriptionUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  estimatedSize?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  keywords?: InputMaybe<String_Comparison_Exp>;
  latestFundingRoundDate?: InputMaybe<String_Comparison_Exp>;
  latestFundingStage?: InputMaybe<String_Comparison_Exp>;
  linkedInUid?: InputMaybe<String_Comparison_Exp>;
  linkedInUrl?: InputMaybe<String_Comparison_Exp>;
  logoUrl?: InputMaybe<String_Comparison_Exp>;
  logoUrlUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nameUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  rawJson?: InputMaybe<Jsonb_Comparison_Exp>;
  seoDescription?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  technologyNames?: InputMaybe<String_Comparison_Exp>;
  totalFunding?: InputMaybe<String_Comparison_Exp>;
  totalFundingPrinted?: InputMaybe<String_Comparison_Exp>;
  twitterUrl?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  websiteDomain?: InputMaybe<String_Comparison_Exp>;
  websiteDomainUpdatedByZoomUserId?: InputMaybe<Uuid_Comparison_Exp>;
  websiteUrl?: InputMaybe<String_Comparison_Exp>;
  yearFounded?: InputMaybe<String_Comparison_Exp>;
  zoomUserCount?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type ZoomUsersAtCompany_Max_Fields = {
  __typename?: 'zoomUsersAtCompany_max_fields';
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
  zoomUserCount?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type ZoomUsersAtCompany_Min_Fields = {
  __typename?: 'zoomUsersAtCompany_min_fields';
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: Maybe<Scalars['uuid']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
  zoomUserCount?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "zoomUsersAtCompany". */
export type ZoomUsersAtCompany_Order_By = {
  alexaRanking?: InputMaybe<Order_By>;
  apolloOrganizationId?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  clearbitCompanyId?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  crunchbaseUrl?: InputMaybe<Order_By>;
  dataVendorName?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  descriptionUpdatedByZoomUserId?: InputMaybe<Order_By>;
  estimatedSize?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  keywords?: InputMaybe<Order_By>;
  latestFundingRoundDate?: InputMaybe<Order_By>;
  latestFundingStage?: InputMaybe<Order_By>;
  linkedInUid?: InputMaybe<Order_By>;
  linkedInUrl?: InputMaybe<Order_By>;
  logoUrl?: InputMaybe<Order_By>;
  logoUrlUpdatedByZoomUserId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nameUpdatedByZoomUserId?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  rawJson?: InputMaybe<Order_By>;
  seoDescription?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  technologyNames?: InputMaybe<Order_By>;
  totalFunding?: InputMaybe<Order_By>;
  totalFundingPrinted?: InputMaybe<Order_By>;
  twitterUrl?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteDomain?: InputMaybe<Order_By>;
  websiteDomainUpdatedByZoomUserId?: InputMaybe<Order_By>;
  websiteUrl?: InputMaybe<Order_By>;
  yearFounded?: InputMaybe<Order_By>;
  zoomUserCount?: InputMaybe<Order_By>;
};

/** select columns of table "zoomUsersAtCompany" */
export enum ZoomUsersAtCompany_Select_Column {
  /** column name */
  AlexaRanking = 'alexaRanking',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitCompanyId = 'clearbitCompanyId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrunchbaseUrl = 'crunchbaseUrl',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionUpdatedByZoomUserId = 'descriptionUpdatedByZoomUserId',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  LatestFundingRoundDate = 'latestFundingRoundDate',
  /** column name */
  LatestFundingStage = 'latestFundingStage',
  /** column name */
  LinkedInUid = 'linkedInUid',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  LogoUrlUpdatedByZoomUserId = 'logoUrlUpdatedByZoomUserId',
  /** column name */
  Name = 'name',
  /** column name */
  NameUpdatedByZoomUserId = 'nameUpdatedByZoomUserId',
  /** column name */
  Phone = 'phone',
  /** column name */
  RawJson = 'rawJson',
  /** column name */
  SeoDescription = 'seoDescription',
  /** column name */
  State = 'state',
  /** column name */
  TechnologyNames = 'technologyNames',
  /** column name */
  TotalFunding = 'totalFunding',
  /** column name */
  TotalFundingPrinted = 'totalFundingPrinted',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteDomainUpdatedByZoomUserId = 'websiteDomainUpdatedByZoomUserId',
  /** column name */
  WebsiteUrl = 'websiteUrl',
  /** column name */
  YearFounded = 'yearFounded',
  /** column name */
  ZoomUserCount = 'zoomUserCount',
}

/** aggregate stddev on columns */
export type ZoomUsersAtCompany_Stddev_Fields = {
  __typename?: 'zoomUsersAtCompany_stddev_fields';
  zoomUserCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ZoomUsersAtCompany_Stddev_Pop_Fields = {
  __typename?: 'zoomUsersAtCompany_stddev_pop_fields';
  zoomUserCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ZoomUsersAtCompany_Stddev_Samp_Fields = {
  __typename?: 'zoomUsersAtCompany_stddev_samp_fields';
  zoomUserCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoomUsersAtCompany" */
export type ZoomUsersAtCompany_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ZoomUsersAtCompany_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoomUsersAtCompany_Stream_Cursor_Value_Input = {
  alexaRanking?: InputMaybe<Scalars['String']>;
  apolloOrganizationId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clearbitCompanyId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  crunchbaseUrl?: InputMaybe<Scalars['String']>;
  dataVendorName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  estimatedSize?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  industry?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Scalars['String']>;
  latestFundingRoundDate?: InputMaybe<Scalars['String']>;
  latestFundingStage?: InputMaybe<Scalars['String']>;
  linkedInUid?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  logoUrlUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  nameUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  rawJson?: InputMaybe<Scalars['jsonb']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  technologyNames?: InputMaybe<Scalars['String']>;
  totalFunding?: InputMaybe<Scalars['String']>;
  totalFundingPrinted?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteDomain?: InputMaybe<Scalars['String']>;
  websiteDomainUpdatedByZoomUserId?: InputMaybe<Scalars['uuid']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  yearFounded?: InputMaybe<Scalars['String']>;
  zoomUserCount?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type ZoomUsersAtCompany_Sum_Fields = {
  __typename?: 'zoomUsersAtCompany_sum_fields';
  zoomUserCount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type ZoomUsersAtCompany_Var_Pop_Fields = {
  __typename?: 'zoomUsersAtCompany_var_pop_fields';
  zoomUserCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ZoomUsersAtCompany_Var_Samp_Fields = {
  __typename?: 'zoomUsersAtCompany_var_samp_fields';
  zoomUserCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ZoomUsersAtCompany_Variance_Fields = {
  __typename?: 'zoomUsersAtCompany_variance_fields';
  zoomUserCount?: Maybe<Scalars['Float']>;
};

export type IncContentWeightMutationVariables = Exact<{
  increaseBy?: InputMaybe<Scalars['Int']>;
  where: Content_Bool_Exp;
}>;

export type IncContentWeightMutation = {
  __typename?: 'mutation_root';
  update_content?: {
    __typename?: 'content_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'content';
      id: string;
      name: string;
      type?: any | null;
      value: string;
      createdByUserId?: string | null;
      createdAt: string;
      updatedAt: string;
      identifier: any;
      identifierId?: any | null;
      thumbnail?: string | null;
      tags: { [key: string]: any };
      weight: number;
      zoomUser?: {
        __typename?: 'zoomUser';
        id: string;
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type QueryZoomContactByIdQueryVariables = Exact<{
  zoomContactId: Scalars['uuid'];
}>;

export type QueryZoomContactByIdQuery = {
  __typename?: 'query_root';
  zoomContact_by_pk?: {
    __typename?: 'zoomContact';
    id: string;
    apolloId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    email: string;
    linkedInUrl?: string | null;
    title?: string | null;
    photoUrl?: string | null;
    twitterUrl?: string | null;
    headline?: string | null;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    location?: { [key: string]: any } | null;
    apolloContactId?: string | null;
    apolloOrganizationId?: string | null;
    apolloLastUpdatedAt?: string | null;
    zoomCompanyId?: string | null;
    roleBucket?: string | null;
    isEuCountry?: boolean | null;
    publicProfileDisabledAt?: string | null;
  } | null;
};

export type QueryZoomContactObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column> | ZoomContact_Select_Column>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By> | ZoomContact_Order_By>;
}>;

export type QueryZoomContactObjectsQuery = {
  __typename?: 'query_root';
  zoomContact: Array<{
    __typename?: 'zoomContact';
    id: string;
    apolloId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    email: string;
    linkedInUrl?: string | null;
    title?: string | null;
    photoUrl?: string | null;
    twitterUrl?: string | null;
    headline?: string | null;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    location?: { [key: string]: any } | null;
    apolloContactId?: string | null;
    apolloOrganizationId?: string | null;
    apolloLastUpdatedAt?: string | null;
    zoomCompanyId?: string | null;
    roleBucket?: string | null;
    isEuCountry?: boolean | null;
    publicProfileDisabledAt?: string | null;
  }>;
};

export type SubscribeToZoomContactByIdSubscriptionVariables = Exact<{
  zoomContactId: Scalars['uuid'];
}>;

export type SubscribeToZoomContactByIdSubscription = {
  __typename?: 'subscription_root';
  zoomContact_by_pk?: {
    __typename?: 'zoomContact';
    id: string;
    apolloId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    email: string;
    linkedInUrl?: string | null;
    title?: string | null;
    photoUrl?: string | null;
    twitterUrl?: string | null;
    headline?: string | null;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    location?: { [key: string]: any } | null;
    apolloContactId?: string | null;
    apolloOrganizationId?: string | null;
    apolloLastUpdatedAt?: string | null;
    zoomCompanyId?: string | null;
    roleBucket?: string | null;
    isEuCountry?: boolean | null;
    publicProfileDisabledAt?: string | null;
  } | null;
};

export type SubscribeToZoomContactObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column> | ZoomContact_Select_Column>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By> | ZoomContact_Order_By>;
}>;

export type SubscribeToZoomContactObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomContact: Array<{
    __typename?: 'zoomContact';
    id: string;
    apolloId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    email: string;
    linkedInUrl?: string | null;
    title?: string | null;
    photoUrl?: string | null;
    twitterUrl?: string | null;
    headline?: string | null;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    location?: { [key: string]: any } | null;
    apolloContactId?: string | null;
    apolloOrganizationId?: string | null;
    apolloLastUpdatedAt?: string | null;
    zoomCompanyId?: string | null;
    roleBucket?: string | null;
    isEuCountry?: boolean | null;
    publicProfileDisabledAt?: string | null;
  }>;
};

export type InsertZoomContactMutationVariables = Exact<{
  objects: Array<ZoomContact_Insert_Input> | ZoomContact_Insert_Input;
}>;

export type InsertZoomContactMutation = {
  __typename?: 'mutation_root';
  insert_zoomContact?: {
    __typename?: 'zoomContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContact';
      id: string;
      apolloId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
      linkedInUrl?: string | null;
      title?: string | null;
      photoUrl?: string | null;
      twitterUrl?: string | null;
      headline?: string | null;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      location?: { [key: string]: any } | null;
      apolloContactId?: string | null;
      apolloOrganizationId?: string | null;
      apolloLastUpdatedAt?: string | null;
      zoomCompanyId?: string | null;
      roleBucket?: string | null;
      isEuCountry?: boolean | null;
      publicProfileDisabledAt?: string | null;
    }>;
  } | null;
};

export type InsertZoomContactWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomContact_Insert_Input> | ZoomContact_Insert_Input;
  onConflict?: InputMaybe<ZoomContact_On_Conflict>;
}>;

export type InsertZoomContactWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomContact?: {
    __typename?: 'zoomContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContact';
      id: string;
      apolloId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
      linkedInUrl?: string | null;
      title?: string | null;
      photoUrl?: string | null;
      twitterUrl?: string | null;
      headline?: string | null;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      location?: { [key: string]: any } | null;
      apolloContactId?: string | null;
      apolloOrganizationId?: string | null;
      apolloLastUpdatedAt?: string | null;
      zoomCompanyId?: string | null;
      roleBucket?: string | null;
      isEuCountry?: boolean | null;
      publicProfileDisabledAt?: string | null;
    }>;
  } | null;
};

export type UpdateZoomContactByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomContact_Set_Input>;
}>;

export type UpdateZoomContactByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomContact?: {
    __typename?: 'zoomContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContact';
      id: string;
      apolloId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
      linkedInUrl?: string | null;
      title?: string | null;
      photoUrl?: string | null;
      twitterUrl?: string | null;
      headline?: string | null;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      location?: { [key: string]: any } | null;
      apolloContactId?: string | null;
      apolloOrganizationId?: string | null;
      apolloLastUpdatedAt?: string | null;
      zoomCompanyId?: string | null;
      roleBucket?: string | null;
      isEuCountry?: boolean | null;
      publicProfileDisabledAt?: string | null;
    }>;
  } | null;
};

export type UpdateZoomContactMutationVariables = Exact<{
  set?: InputMaybe<ZoomContact_Set_Input>;
  where: ZoomContact_Bool_Exp;
}>;

export type UpdateZoomContactMutation = {
  __typename?: 'mutation_root';
  update_zoomContact?: {
    __typename?: 'zoomContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContact';
      id: string;
      apolloId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
      linkedInUrl?: string | null;
      title?: string | null;
      photoUrl?: string | null;
      twitterUrl?: string | null;
      headline?: string | null;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      location?: { [key: string]: any } | null;
      apolloContactId?: string | null;
      apolloOrganizationId?: string | null;
      apolloLastUpdatedAt?: string | null;
      zoomCompanyId?: string | null;
      roleBucket?: string | null;
      isEuCountry?: boolean | null;
      publicProfileDisabledAt?: string | null;
    }>;
  } | null;
};

export type RemoveZoomContactModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomContactModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomContact?: { __typename?: 'zoomContact_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomContactModelMutationVariables = Exact<{
  where: ZoomContact_Bool_Exp;
}>;

export type RemoveZoomContactModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomContact?: { __typename?: 'zoomContact_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomCompanyByIdQueryVariables = Exact<{
  zoomCompanyId: Scalars['uuid'];
}>;

export type QueryZoomCompanyByIdQuery = {
  __typename?: 'query_root';
  zoomCompany_by_pk?: {
    __typename?: 'zoomCompany';
    id: string;
    name?: string | null;
    websiteUrl?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    crunchbaseUrl?: string | null;
    phone?: string | null;
    websiteDomain?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    industry?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    description?: string | null;
    seoDescription?: string | null;
    totalFunding?: string | null;
    latestFundingStage?: string | null;
    latestFundingRoundDate?: string | null;
    totalFundingPrinted?: string | null;
    technologyNames?: string | null;
    keywords?: string | null;
    alexaRanking?: string | null;
    yearFounded?: string | null;
    hasBeenMerged?: boolean | null;
  } | null;
};

export type QueryZoomCompanyObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomCompany_Select_Column> | ZoomCompany_Select_Column>;
  where?: InputMaybe<ZoomCompany_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompany_Order_By> | ZoomCompany_Order_By>;
}>;

export type QueryZoomCompanyObjectsQuery = {
  __typename?: 'query_root';
  zoomCompany: Array<{
    __typename?: 'zoomCompany';
    id: string;
    name?: string | null;
    websiteUrl?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    crunchbaseUrl?: string | null;
    phone?: string | null;
    websiteDomain?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    industry?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    description?: string | null;
    seoDescription?: string | null;
    totalFunding?: string | null;
    latestFundingStage?: string | null;
    latestFundingRoundDate?: string | null;
    totalFundingPrinted?: string | null;
    technologyNames?: string | null;
    keywords?: string | null;
    alexaRanking?: string | null;
    yearFounded?: string | null;
    hasBeenMerged?: boolean | null;
  }>;
};

export type SubscribeToZoomCompanyByIdSubscriptionVariables = Exact<{
  zoomCompanyId: Scalars['uuid'];
}>;

export type SubscribeToZoomCompanyByIdSubscription = {
  __typename?: 'subscription_root';
  zoomCompany_by_pk?: {
    __typename?: 'zoomCompany';
    id: string;
    name?: string | null;
    websiteUrl?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    crunchbaseUrl?: string | null;
    phone?: string | null;
    websiteDomain?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    industry?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    description?: string | null;
    seoDescription?: string | null;
    totalFunding?: string | null;
    latestFundingStage?: string | null;
    latestFundingRoundDate?: string | null;
    totalFundingPrinted?: string | null;
    technologyNames?: string | null;
    keywords?: string | null;
    alexaRanking?: string | null;
    yearFounded?: string | null;
    hasBeenMerged?: boolean | null;
  } | null;
};

export type SubscribeToZoomCompanyObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomCompany_Select_Column> | ZoomCompany_Select_Column>;
  where?: InputMaybe<ZoomCompany_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCompany_Order_By> | ZoomCompany_Order_By>;
}>;

export type SubscribeToZoomCompanyObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomCompany: Array<{
    __typename?: 'zoomCompany';
    id: string;
    name?: string | null;
    websiteUrl?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    crunchbaseUrl?: string | null;
    phone?: string | null;
    websiteDomain?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    industry?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    description?: string | null;
    seoDescription?: string | null;
    totalFunding?: string | null;
    latestFundingStage?: string | null;
    latestFundingRoundDate?: string | null;
    totalFundingPrinted?: string | null;
    technologyNames?: string | null;
    keywords?: string | null;
    alexaRanking?: string | null;
    yearFounded?: string | null;
    hasBeenMerged?: boolean | null;
  }>;
};

export type InsertZoomCompanyMutationVariables = Exact<{
  objects: Array<ZoomCompany_Insert_Input> | ZoomCompany_Insert_Input;
}>;

export type InsertZoomCompanyMutation = {
  __typename?: 'mutation_root';
  insert_zoomCompany?: {
    __typename?: 'zoomCompany_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    }>;
  } | null;
};

export type InsertZoomCompanyWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomCompany_Insert_Input> | ZoomCompany_Insert_Input;
  onConflict?: InputMaybe<ZoomCompany_On_Conflict>;
}>;

export type InsertZoomCompanyWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomCompany?: {
    __typename?: 'zoomCompany_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    }>;
  } | null;
};

export type UpdateZoomCompanyByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomCompany_Set_Input>;
}>;

export type UpdateZoomCompanyByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomCompany?: {
    __typename?: 'zoomCompany_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    }>;
  } | null;
};

export type UpdateZoomCompanyMutationVariables = Exact<{
  set?: InputMaybe<ZoomCompany_Set_Input>;
  where: ZoomCompany_Bool_Exp;
}>;

export type UpdateZoomCompanyMutation = {
  __typename?: 'mutation_root';
  update_zoomCompany?: {
    __typename?: 'zoomCompany_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    }>;
  } | null;
};

export type RemoveZoomCompanyModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomCompanyModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomCompany?: { __typename?: 'zoomCompany_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomCompanyModelMutationVariables = Exact<{
  where: ZoomCompany_Bool_Exp;
}>;

export type RemoveZoomCompanyModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomCompany?: { __typename?: 'zoomCompany_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomGroupByIdQueryVariables = Exact<{
  zoomGroupId: Scalars['uuid'];
}>;

export type QueryZoomGroupByIdQuery = {
  __typename?: 'query_root';
  zoomGroup_by_pk?: {
    __typename?: 'zoomGroup';
    id: string;
    name?: string | null;
    description?: string | null;
    websiteDomain?: string | null;
    websiteUrl?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    zoomCompanyId?: string | null;
    type: string;
    displayName?: string | null;
  } | null;
};

export type QueryZoomGroupObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomGroup_Select_Column> | ZoomGroup_Select_Column>;
  where?: InputMaybe<ZoomGroup_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroup_Order_By> | ZoomGroup_Order_By>;
}>;

export type QueryZoomGroupObjectsQuery = {
  __typename?: 'query_root';
  zoomGroup: Array<{
    __typename?: 'zoomGroup';
    id: string;
    name?: string | null;
    description?: string | null;
    websiteDomain?: string | null;
    websiteUrl?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    zoomCompanyId?: string | null;
    type: string;
    displayName?: string | null;
  }>;
};

export type SubscribeToZoomGroupByIdSubscriptionVariables = Exact<{
  zoomGroupId: Scalars['uuid'];
}>;

export type SubscribeToZoomGroupByIdSubscription = {
  __typename?: 'subscription_root';
  zoomGroup_by_pk?: {
    __typename?: 'zoomGroup';
    id: string;
    name?: string | null;
    description?: string | null;
    websiteDomain?: string | null;
    websiteUrl?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    zoomCompanyId?: string | null;
    type: string;
    displayName?: string | null;
  } | null;
};

export type SubscribeToZoomGroupObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomGroup_Select_Column> | ZoomGroup_Select_Column>;
  where?: InputMaybe<ZoomGroup_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroup_Order_By> | ZoomGroup_Order_By>;
}>;

export type SubscribeToZoomGroupObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomGroup: Array<{
    __typename?: 'zoomGroup';
    id: string;
    name?: string | null;
    description?: string | null;
    websiteDomain?: string | null;
    websiteUrl?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    zoomCompanyId?: string | null;
    type: string;
    displayName?: string | null;
  }>;
};

export type InsertZoomGroupMutationVariables = Exact<{
  objects: Array<ZoomGroup_Insert_Input> | ZoomGroup_Insert_Input;
}>;

export type InsertZoomGroupMutation = {
  __typename?: 'mutation_root';
  insert_zoomGroup?: {
    __typename?: 'zoomGroup_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomGroup';
      id: string;
      name?: string | null;
      description?: string | null;
      websiteDomain?: string | null;
      websiteUrl?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      zoomCompanyId?: string | null;
      type: string;
      displayName?: string | null;
    }>;
  } | null;
};

export type InsertZoomGroupWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomGroup_Insert_Input> | ZoomGroup_Insert_Input;
  onConflict?: InputMaybe<ZoomGroup_On_Conflict>;
}>;

export type InsertZoomGroupWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomGroup?: {
    __typename?: 'zoomGroup_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomGroup';
      id: string;
      name?: string | null;
      description?: string | null;
      websiteDomain?: string | null;
      websiteUrl?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      zoomCompanyId?: string | null;
      type: string;
      displayName?: string | null;
    }>;
  } | null;
};

export type UpdateZoomGroupByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomGroup_Set_Input>;
}>;

export type UpdateZoomGroupByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomGroup?: {
    __typename?: 'zoomGroup_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomGroup';
      id: string;
      name?: string | null;
      description?: string | null;
      websiteDomain?: string | null;
      websiteUrl?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      zoomCompanyId?: string | null;
      type: string;
      displayName?: string | null;
    }>;
  } | null;
};

export type UpdateZoomGroupMutationVariables = Exact<{
  set?: InputMaybe<ZoomGroup_Set_Input>;
  where: ZoomGroup_Bool_Exp;
}>;

export type UpdateZoomGroupMutation = {
  __typename?: 'mutation_root';
  update_zoomGroup?: {
    __typename?: 'zoomGroup_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomGroup';
      id: string;
      name?: string | null;
      description?: string | null;
      websiteDomain?: string | null;
      websiteUrl?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      zoomCompanyId?: string | null;
      type: string;
      displayName?: string | null;
    }>;
  } | null;
};

export type RemoveZoomGroupModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomGroupModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomGroup?: { __typename?: 'zoomGroup_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomGroupModelMutationVariables = Exact<{
  where: ZoomGroup_Bool_Exp;
}>;

export type RemoveZoomGroupModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomGroup?: { __typename?: 'zoomGroup_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomContactWithCompanyByIdQueryVariables = Exact<{
  zoomContactId: Scalars['uuid'];
}>;

export type QueryZoomContactWithCompanyByIdQuery = {
  __typename?: 'query_root';
  zoomContact_by_pk?: {
    __typename?: 'zoomContact';
    id: string;
    apolloId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    email: string;
    linkedInUrl?: string | null;
    title?: string | null;
    photoUrl?: string | null;
    twitterUrl?: string | null;
    headline?: string | null;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    location?: { [key: string]: any } | null;
    apolloContactId?: string | null;
    apolloOrganizationId?: string | null;
    apolloLastUpdatedAt?: string | null;
    zoomCompanyId?: string | null;
    publicProfileDisabledAt?: string | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
    } | null;
  } | null;
};

export type QueryZoomContactWithCompanyObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column> | ZoomContact_Select_Column>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By> | ZoomContact_Order_By>;
}>;

export type QueryZoomContactWithCompanyObjectsQuery = {
  __typename?: 'query_root';
  zoomContact: Array<{
    __typename?: 'zoomContact';
    id: string;
    apolloId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    email: string;
    linkedInUrl?: string | null;
    title?: string | null;
    photoUrl?: string | null;
    twitterUrl?: string | null;
    headline?: string | null;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    location?: { [key: string]: any } | null;
    apolloContactId?: string | null;
    apolloOrganizationId?: string | null;
    apolloLastUpdatedAt?: string | null;
    zoomCompanyId?: string | null;
    publicProfileDisabledAt?: string | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
    } | null;
  }>;
};

export type SubscribeToZoomContactWithCompanyByIdSubscriptionVariables = Exact<{
  zoomContactId: Scalars['uuid'];
}>;

export type SubscribeToZoomContactWithCompanyByIdSubscription = {
  __typename?: 'subscription_root';
  zoomContact_by_pk?: {
    __typename?: 'zoomContact';
    id: string;
    apolloId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    email: string;
    linkedInUrl?: string | null;
    title?: string | null;
    photoUrl?: string | null;
    twitterUrl?: string | null;
    headline?: string | null;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    location?: { [key: string]: any } | null;
    apolloContactId?: string | null;
    apolloOrganizationId?: string | null;
    apolloLastUpdatedAt?: string | null;
    zoomCompanyId?: string | null;
    publicProfileDisabledAt?: string | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
    } | null;
  } | null;
};

export type SubscribeToZoomContactWithCompanyObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomContact_Select_Column> | ZoomContact_Select_Column>;
  where?: InputMaybe<ZoomContact_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContact_Order_By> | ZoomContact_Order_By>;
}>;

export type SubscribeToZoomContactWithCompanyObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomContact: Array<{
    __typename?: 'zoomContact';
    id: string;
    apolloId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    email: string;
    linkedInUrl?: string | null;
    title?: string | null;
    photoUrl?: string | null;
    twitterUrl?: string | null;
    headline?: string | null;
    country?: string | null;
    state?: string | null;
    city?: string | null;
    location?: { [key: string]: any } | null;
    apolloContactId?: string | null;
    apolloOrganizationId?: string | null;
    apolloLastUpdatedAt?: string | null;
    zoomCompanyId?: string | null;
    publicProfileDisabledAt?: string | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
    } | null;
  }>;
};

export type InsertZoomContactWithCompanyMutationVariables = Exact<{
  objects: Array<ZoomContact_Insert_Input> | ZoomContact_Insert_Input;
}>;

export type InsertZoomContactWithCompanyMutation = {
  __typename?: 'mutation_root';
  insert_zoomContact?: {
    __typename?: 'zoomContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContact';
      id: string;
      apolloId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
      linkedInUrl?: string | null;
      title?: string | null;
      photoUrl?: string | null;
      twitterUrl?: string | null;
      headline?: string | null;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      location?: { [key: string]: any } | null;
      apolloContactId?: string | null;
      apolloOrganizationId?: string | null;
      apolloLastUpdatedAt?: string | null;
      zoomCompanyId?: string | null;
      publicProfileDisabledAt?: string | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
      } | null;
    }>;
  } | null;
};

export type InsertZoomContactWithCompanyWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomContact_Insert_Input> | ZoomContact_Insert_Input;
  onConflict?: InputMaybe<ZoomContact_On_Conflict>;
}>;

export type InsertZoomContactWithCompanyWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomContact?: {
    __typename?: 'zoomContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContact';
      id: string;
      apolloId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
      linkedInUrl?: string | null;
      title?: string | null;
      photoUrl?: string | null;
      twitterUrl?: string | null;
      headline?: string | null;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      location?: { [key: string]: any } | null;
      apolloContactId?: string | null;
      apolloOrganizationId?: string | null;
      apolloLastUpdatedAt?: string | null;
      zoomCompanyId?: string | null;
      publicProfileDisabledAt?: string | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateZoomContactWithCompanyByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomContact_Set_Input>;
}>;

export type UpdateZoomContactWithCompanyByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomContact?: {
    __typename?: 'zoomContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContact';
      id: string;
      apolloId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
      linkedInUrl?: string | null;
      title?: string | null;
      photoUrl?: string | null;
      twitterUrl?: string | null;
      headline?: string | null;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      location?: { [key: string]: any } | null;
      apolloContactId?: string | null;
      apolloOrganizationId?: string | null;
      apolloLastUpdatedAt?: string | null;
      zoomCompanyId?: string | null;
      publicProfileDisabledAt?: string | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateZoomContactWithCompanyMutationVariables = Exact<{
  set?: InputMaybe<ZoomContact_Set_Input>;
  where: ZoomContact_Bool_Exp;
}>;

export type UpdateZoomContactWithCompanyMutation = {
  __typename?: 'mutation_root';
  update_zoomContact?: {
    __typename?: 'zoomContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContact';
      id: string;
      apolloId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
      linkedInUrl?: string | null;
      title?: string | null;
      photoUrl?: string | null;
      twitterUrl?: string | null;
      headline?: string | null;
      country?: string | null;
      state?: string | null;
      city?: string | null;
      location?: { [key: string]: any } | null;
      apolloContactId?: string | null;
      apolloOrganizationId?: string | null;
      apolloLastUpdatedAt?: string | null;
      zoomCompanyId?: string | null;
      publicProfileDisabledAt?: string | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
      } | null;
    }>;
  } | null;
};

export type QueryZoomUserActionsByIdQueryVariables = Exact<{
  zoomUserActionsId: Scalars['uuid'];
}>;

export type QueryZoomUserActionsByIdQuery = {
  __typename?: 'query_root';
  zoomUserActions_by_pk?: {
    __typename?: 'zoomUserActions';
    id: string;
    createdAt: string;
    updatedAt: string;
    zoomUserId: string;
    enhanceNametagCompletedAt?: string | null;
    enhanceNametagDismissedAt?: string | null;
    enhanceNametagDialogShownAt?: string | null;
    practiceRoomCompletedAt?: string | null;
    practiceRoomDismissedAt?: string | null;
    practiceRoomShownAt?: string | null;
    excitingNewsAlertDismissedAt?: string | null;
    manageOrganizationAlertDismissedAt?: string | null;
    needZoomAccessAlertDismissedAt?: string | null;
    welcomeSetupCardDismissedAt?: string | null;
    welcomeDialogDismissedAt?: string | null;
    unmirrorCardDismissedAt?: string | null;
    calSigDismissedAt?: string | null;
    installZoomDismissedAt?: string | null;
    upgradeZoomAlertDismissedAt?: string | null;
    agendaCardDismissedAt?: string | null;
    videoOffCardDismissedAt?: string | null;
    videoOffCardCompletedAt?: string | null;
    addBioDismissedAt?: string | null;
  } | null;
};

export type QueryZoomUserActionsObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomUserActions_Select_Column> | ZoomUserActions_Select_Column>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserActions_Order_By> | ZoomUserActions_Order_By>;
}>;

export type QueryZoomUserActionsObjectsQuery = {
  __typename?: 'query_root';
  zoomUserActions: Array<{
    __typename?: 'zoomUserActions';
    id: string;
    createdAt: string;
    updatedAt: string;
    zoomUserId: string;
    enhanceNametagCompletedAt?: string | null;
    enhanceNametagDismissedAt?: string | null;
    enhanceNametagDialogShownAt?: string | null;
    practiceRoomCompletedAt?: string | null;
    practiceRoomDismissedAt?: string | null;
    practiceRoomShownAt?: string | null;
    excitingNewsAlertDismissedAt?: string | null;
    manageOrganizationAlertDismissedAt?: string | null;
    needZoomAccessAlertDismissedAt?: string | null;
    welcomeSetupCardDismissedAt?: string | null;
    welcomeDialogDismissedAt?: string | null;
    unmirrorCardDismissedAt?: string | null;
    calSigDismissedAt?: string | null;
    installZoomDismissedAt?: string | null;
    upgradeZoomAlertDismissedAt?: string | null;
    agendaCardDismissedAt?: string | null;
    videoOffCardDismissedAt?: string | null;
    videoOffCardCompletedAt?: string | null;
    addBioDismissedAt?: string | null;
  }>;
};

export type SubscribeToZoomUserActionsByIdSubscriptionVariables = Exact<{
  zoomUserActionsId: Scalars['uuid'];
}>;

export type SubscribeToZoomUserActionsByIdSubscription = {
  __typename?: 'subscription_root';
  zoomUserActions_by_pk?: {
    __typename?: 'zoomUserActions';
    id: string;
    createdAt: string;
    updatedAt: string;
    zoomUserId: string;
    enhanceNametagCompletedAt?: string | null;
    enhanceNametagDismissedAt?: string | null;
    enhanceNametagDialogShownAt?: string | null;
    practiceRoomCompletedAt?: string | null;
    practiceRoomDismissedAt?: string | null;
    practiceRoomShownAt?: string | null;
    excitingNewsAlertDismissedAt?: string | null;
    manageOrganizationAlertDismissedAt?: string | null;
    needZoomAccessAlertDismissedAt?: string | null;
    welcomeSetupCardDismissedAt?: string | null;
    welcomeDialogDismissedAt?: string | null;
    unmirrorCardDismissedAt?: string | null;
    calSigDismissedAt?: string | null;
    installZoomDismissedAt?: string | null;
    upgradeZoomAlertDismissedAt?: string | null;
    agendaCardDismissedAt?: string | null;
    videoOffCardDismissedAt?: string | null;
    videoOffCardCompletedAt?: string | null;
    addBioDismissedAt?: string | null;
  } | null;
};

export type SubscribeToZoomUserActionsObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomUserActions_Select_Column> | ZoomUserActions_Select_Column>;
  where?: InputMaybe<ZoomUserActions_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserActions_Order_By> | ZoomUserActions_Order_By>;
}>;

export type SubscribeToZoomUserActionsObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomUserActions: Array<{
    __typename?: 'zoomUserActions';
    id: string;
    createdAt: string;
    updatedAt: string;
    zoomUserId: string;
    enhanceNametagCompletedAt?: string | null;
    enhanceNametagDismissedAt?: string | null;
    enhanceNametagDialogShownAt?: string | null;
    practiceRoomCompletedAt?: string | null;
    practiceRoomDismissedAt?: string | null;
    practiceRoomShownAt?: string | null;
    excitingNewsAlertDismissedAt?: string | null;
    manageOrganizationAlertDismissedAt?: string | null;
    needZoomAccessAlertDismissedAt?: string | null;
    welcomeSetupCardDismissedAt?: string | null;
    welcomeDialogDismissedAt?: string | null;
    unmirrorCardDismissedAt?: string | null;
    calSigDismissedAt?: string | null;
    installZoomDismissedAt?: string | null;
    upgradeZoomAlertDismissedAt?: string | null;
    agendaCardDismissedAt?: string | null;
    videoOffCardDismissedAt?: string | null;
    videoOffCardCompletedAt?: string | null;
    addBioDismissedAt?: string | null;
  }>;
};

export type InsertZoomUserActionsMutationVariables = Exact<{
  objects: Array<ZoomUserActions_Insert_Input> | ZoomUserActions_Insert_Input;
}>;

export type InsertZoomUserActionsMutation = {
  __typename?: 'mutation_root';
  insert_zoomUserActions?: {
    __typename?: 'zoomUserActions_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    }>;
  } | null;
};

export type InsertZoomUserActionsWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomUserActions_Insert_Input> | ZoomUserActions_Insert_Input;
  onConflict?: InputMaybe<ZoomUserActions_On_Conflict>;
}>;

export type InsertZoomUserActionsWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomUserActions?: {
    __typename?: 'zoomUserActions_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    }>;
  } | null;
};

export type UpdateZoomUserActionsByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomUserActions_Set_Input>;
}>;

export type UpdateZoomUserActionsByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomUserActions?: {
    __typename?: 'zoomUserActions_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    }>;
  } | null;
};

export type UpdateZoomUserActionsMutationVariables = Exact<{
  set?: InputMaybe<ZoomUserActions_Set_Input>;
  where: ZoomUserActions_Bool_Exp;
}>;

export type UpdateZoomUserActionsMutation = {
  __typename?: 'mutation_root';
  update_zoomUserActions?: {
    __typename?: 'zoomUserActions_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    }>;
  } | null;
};

export type RemoveZoomUserActionsModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomUserActionsModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomUserActions?: { __typename?: 'zoomUserActions_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomUserActionsModelMutationVariables = Exact<{
  where: ZoomUserActions_Bool_Exp;
}>;

export type RemoveZoomUserActionsModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomUserActions?: { __typename?: 'zoomUserActions_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomGroupMembershipByIdQueryVariables = Exact<{
  zoomGroupMembershipId: Scalars['uuid'];
}>;

export type QueryZoomGroupMembershipByIdQuery = {
  __typename?: 'query_root';
  zoomGroupMembership_by_pk?: {
    __typename?: 'zoomGroupMembership';
    id: string;
    zoomGroupId: string;
    type: string;
  } | null;
};

export type QueryZoomGroupMembershipObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column> | ZoomGroupMembership_Select_Column>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By> | ZoomGroupMembership_Order_By>;
}>;

export type QueryZoomGroupMembershipObjectsQuery = {
  __typename?: 'query_root';
  zoomGroupMembership: Array<{ __typename?: 'zoomGroupMembership'; id: string; zoomGroupId: string; type: string }>;
};

export type SubscribeToZoomGroupMembershipByIdSubscriptionVariables = Exact<{
  zoomGroupMembershipId: Scalars['uuid'];
}>;

export type SubscribeToZoomGroupMembershipByIdSubscription = {
  __typename?: 'subscription_root';
  zoomGroupMembership_by_pk?: {
    __typename?: 'zoomGroupMembership';
    id: string;
    zoomGroupId: string;
    type: string;
  } | null;
};

export type SubscribeToZoomGroupMembershipObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column> | ZoomGroupMembership_Select_Column>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By> | ZoomGroupMembership_Order_By>;
}>;

export type SubscribeToZoomGroupMembershipObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomGroupMembership: Array<{ __typename?: 'zoomGroupMembership'; id: string; zoomGroupId: string; type: string }>;
};

export type InsertZoomGroupMembershipMutationVariables = Exact<{
  objects: Array<ZoomGroupMembership_Insert_Input> | ZoomGroupMembership_Insert_Input;
}>;

export type InsertZoomGroupMembershipMutation = {
  __typename?: 'mutation_root';
  insert_zoomGroupMembership?: {
    __typename?: 'zoomGroupMembership_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'zoomGroupMembership'; id: string; zoomGroupId: string; type: string }>;
  } | null;
};

export type InsertZoomGroupMembershipWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomGroupMembership_Insert_Input> | ZoomGroupMembership_Insert_Input;
  onConflict?: InputMaybe<ZoomGroupMembership_On_Conflict>;
}>;

export type InsertZoomGroupMembershipWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomGroupMembership?: {
    __typename?: 'zoomGroupMembership_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'zoomGroupMembership'; id: string; zoomGroupId: string; type: string }>;
  } | null;
};

export type UpdateZoomGroupMembershipByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomGroupMembership_Set_Input>;
}>;

export type UpdateZoomGroupMembershipByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomGroupMembership?: {
    __typename?: 'zoomGroupMembership_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'zoomGroupMembership'; id: string; zoomGroupId: string; type: string }>;
  } | null;
};

export type UpdateZoomGroupMembershipMutationVariables = Exact<{
  set?: InputMaybe<ZoomGroupMembership_Set_Input>;
  where: ZoomGroupMembership_Bool_Exp;
}>;

export type UpdateZoomGroupMembershipMutation = {
  __typename?: 'mutation_root';
  update_zoomGroupMembership?: {
    __typename?: 'zoomGroupMembership_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'zoomGroupMembership'; id: string; zoomGroupId: string; type: string }>;
  } | null;
};

export type RemoveZoomGroupMembershipModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomGroupMembershipModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomGroupMembership?: { __typename?: 'zoomGroupMembership_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomGroupMembershipModelMutationVariables = Exact<{
  where: ZoomGroupMembership_Bool_Exp;
}>;

export type RemoveZoomGroupMembershipModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomGroupMembership?: { __typename?: 'zoomGroupMembership_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomUserByIdQueryVariables = Exact<{
  zoomUserId: Scalars['uuid'];
}>;

export type QueryZoomUserByIdQuery = {
  __typename?: 'query_root';
  zoomUser_by_pk?: {
    __typename?: 'zoomUser';
    id: string;
    email: string;
    username?: string | null;
    firebaseUid: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    updatedAt?: string | null;
    calendarEnrichmentStatus?: string | null;
    integrationType?: string | null;
    role?: string | null;
    onboardVersion?: string | null;
    photoUrl?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    locationUpdatedAt?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    companyWebsiteDomain?: string | null;
    companyName?: string | null;
    profileUpdatedAt?: string | null;
    recurringEventsInjectionEnabledAt?: string | null;
    googleScopes?: { [key: string]: any } | null;
    zoomCompanyId?: string | null;
    scheduleMeetingUrl?: string | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    calendarLastSyncedAt?: string | null;
    runningLateTemplate?: string | null;
    whereAreYouTemplate?: string | null;
    companyLogoUrl?: string | null;
    intercomOnboardingStartedAt?: string | null;
    zoomExternalAuthUserId?: string | null;
    roleBucket?: string | null;
    onlyInjectWarmlyUsers?: boolean | null;
    onboardingType?: string | null;
    profileVisibility: { [key: string]: any };
    microsoftScopes?: { [key: string]: any } | null;
    nametagSettings?: { [key: string]: any } | null;
    backgroundVisibility?: { [key: string]: any } | null;
    temperatureScale?: string | null;
    department?: string | null;
    startDate?: string | null;
    strengths?: string | null;
    addedToVideoConversationStarterPromptId?: string | null;
    lastJoinedMeetingUuid?: string | null;
    pmfSurveyDisplayedAt?: string | null;
    isGroupSuper?: boolean | null;
    isGroupAdmin?: boolean | null;
    isOrgAdmin?: boolean | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    } | null;
    zoomOrganizationMembership?: {
      __typename?: 'zoomOrganizationMembership';
      id: string;
      type: string;
      organization: {
        __typename?: 'zoomOrganization';
        id: string;
        orgAdminExists?: boolean | null;
        name: string;
        isFreeEmailDomain: boolean;
        otherDomains: { [key: string]: any };
        ssoDomains: { [key: string]: any };
        organizationId?: string | null;
        zoomCompany?: {
          __typename?: 'zoomCompany';
          id: string;
          name?: string | null;
          websiteUrl?: string | null;
          linkedInUrl?: string | null;
          twitterUrl?: string | null;
          crunchbaseUrl?: string | null;
          phone?: string | null;
          websiteDomain?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          industry?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          description?: string | null;
          seoDescription?: string | null;
          totalFunding?: string | null;
          latestFundingStage?: string | null;
          latestFundingRoundDate?: string | null;
          totalFundingPrinted?: string | null;
          technologyNames?: string | null;
          keywords?: string | null;
          alexaRanking?: string | null;
          yearFounded?: string | null;
          hasBeenMerged?: boolean | null;
        } | null;
      };
    } | null;
    zoomGroupMemberships: Array<{
      __typename?: 'zoomGroupMembership';
      id: string;
      type: string;
      group: {
        __typename?: 'zoomGroup';
        id: string;
        name?: string | null;
        description?: string | null;
        websiteDomain?: string | null;
        websiteUrl?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        zoomCompanyId?: string | null;
        type: string;
        displayName?: string | null;
      };
    }>;
    actions?: {
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    } | null;
  } | null;
};

export type QueryZoomUserObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column> | ZoomUser_Select_Column>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By> | ZoomUser_Order_By>;
}>;

export type QueryZoomUserObjectsQuery = {
  __typename?: 'query_root';
  zoomUser: Array<{
    __typename?: 'zoomUser';
    id: string;
    email: string;
    username?: string | null;
    firebaseUid: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    updatedAt?: string | null;
    calendarEnrichmentStatus?: string | null;
    integrationType?: string | null;
    role?: string | null;
    onboardVersion?: string | null;
    photoUrl?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    locationUpdatedAt?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    companyWebsiteDomain?: string | null;
    companyName?: string | null;
    profileUpdatedAt?: string | null;
    recurringEventsInjectionEnabledAt?: string | null;
    googleScopes?: { [key: string]: any } | null;
    zoomCompanyId?: string | null;
    scheduleMeetingUrl?: string | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    calendarLastSyncedAt?: string | null;
    runningLateTemplate?: string | null;
    whereAreYouTemplate?: string | null;
    companyLogoUrl?: string | null;
    intercomOnboardingStartedAt?: string | null;
    zoomExternalAuthUserId?: string | null;
    roleBucket?: string | null;
    onlyInjectWarmlyUsers?: boolean | null;
    onboardingType?: string | null;
    profileVisibility: { [key: string]: any };
    microsoftScopes?: { [key: string]: any } | null;
    nametagSettings?: { [key: string]: any } | null;
    backgroundVisibility?: { [key: string]: any } | null;
    temperatureScale?: string | null;
    department?: string | null;
    startDate?: string | null;
    strengths?: string | null;
    addedToVideoConversationStarterPromptId?: string | null;
    lastJoinedMeetingUuid?: string | null;
    pmfSurveyDisplayedAt?: string | null;
    isGroupSuper?: boolean | null;
    isGroupAdmin?: boolean | null;
    isOrgAdmin?: boolean | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    } | null;
    zoomOrganizationMembership?: {
      __typename?: 'zoomOrganizationMembership';
      id: string;
      type: string;
      organization: {
        __typename?: 'zoomOrganization';
        id: string;
        orgAdminExists?: boolean | null;
        name: string;
        isFreeEmailDomain: boolean;
        otherDomains: { [key: string]: any };
        ssoDomains: { [key: string]: any };
        organizationId?: string | null;
        zoomCompany?: {
          __typename?: 'zoomCompany';
          id: string;
          name?: string | null;
          websiteUrl?: string | null;
          linkedInUrl?: string | null;
          twitterUrl?: string | null;
          crunchbaseUrl?: string | null;
          phone?: string | null;
          websiteDomain?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          industry?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          description?: string | null;
          seoDescription?: string | null;
          totalFunding?: string | null;
          latestFundingStage?: string | null;
          latestFundingRoundDate?: string | null;
          totalFundingPrinted?: string | null;
          technologyNames?: string | null;
          keywords?: string | null;
          alexaRanking?: string | null;
          yearFounded?: string | null;
          hasBeenMerged?: boolean | null;
        } | null;
      };
    } | null;
    zoomGroupMemberships: Array<{
      __typename?: 'zoomGroupMembership';
      id: string;
      type: string;
      group: {
        __typename?: 'zoomGroup';
        id: string;
        name?: string | null;
        description?: string | null;
        websiteDomain?: string | null;
        websiteUrl?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        zoomCompanyId?: string | null;
        type: string;
        displayName?: string | null;
      };
    }>;
    actions?: {
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    } | null;
  }>;
};

export type SubscribeToZoomUserByIdSubscriptionVariables = Exact<{
  zoomUserId: Scalars['uuid'];
}>;

export type SubscribeToZoomUserByIdSubscription = {
  __typename?: 'subscription_root';
  zoomUser_by_pk?: {
    __typename?: 'zoomUser';
    id: string;
    email: string;
    username?: string | null;
    firebaseUid: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    updatedAt?: string | null;
    calendarEnrichmentStatus?: string | null;
    integrationType?: string | null;
    role?: string | null;
    onboardVersion?: string | null;
    photoUrl?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    locationUpdatedAt?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    companyWebsiteDomain?: string | null;
    companyName?: string | null;
    profileUpdatedAt?: string | null;
    recurringEventsInjectionEnabledAt?: string | null;
    googleScopes?: { [key: string]: any } | null;
    zoomCompanyId?: string | null;
    scheduleMeetingUrl?: string | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    calendarLastSyncedAt?: string | null;
    runningLateTemplate?: string | null;
    whereAreYouTemplate?: string | null;
    companyLogoUrl?: string | null;
    intercomOnboardingStartedAt?: string | null;
    zoomExternalAuthUserId?: string | null;
    roleBucket?: string | null;
    onlyInjectWarmlyUsers?: boolean | null;
    onboardingType?: string | null;
    profileVisibility: { [key: string]: any };
    microsoftScopes?: { [key: string]: any } | null;
    nametagSettings?: { [key: string]: any } | null;
    backgroundVisibility?: { [key: string]: any } | null;
    temperatureScale?: string | null;
    department?: string | null;
    startDate?: string | null;
    strengths?: string | null;
    addedToVideoConversationStarterPromptId?: string | null;
    lastJoinedMeetingUuid?: string | null;
    pmfSurveyDisplayedAt?: string | null;
    isGroupSuper?: boolean | null;
    isGroupAdmin?: boolean | null;
    isOrgAdmin?: boolean | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    } | null;
    zoomOrganizationMembership?: {
      __typename?: 'zoomOrganizationMembership';
      id: string;
      type: string;
      organization: {
        __typename?: 'zoomOrganization';
        id: string;
        orgAdminExists?: boolean | null;
        name: string;
        isFreeEmailDomain: boolean;
        otherDomains: { [key: string]: any };
        ssoDomains: { [key: string]: any };
        organizationId?: string | null;
        zoomCompany?: {
          __typename?: 'zoomCompany';
          id: string;
          name?: string | null;
          websiteUrl?: string | null;
          linkedInUrl?: string | null;
          twitterUrl?: string | null;
          crunchbaseUrl?: string | null;
          phone?: string | null;
          websiteDomain?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          industry?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          description?: string | null;
          seoDescription?: string | null;
          totalFunding?: string | null;
          latestFundingStage?: string | null;
          latestFundingRoundDate?: string | null;
          totalFundingPrinted?: string | null;
          technologyNames?: string | null;
          keywords?: string | null;
          alexaRanking?: string | null;
          yearFounded?: string | null;
          hasBeenMerged?: boolean | null;
        } | null;
      };
    } | null;
    zoomGroupMemberships: Array<{
      __typename?: 'zoomGroupMembership';
      id: string;
      type: string;
      group: {
        __typename?: 'zoomGroup';
        id: string;
        name?: string | null;
        description?: string | null;
        websiteDomain?: string | null;
        websiteUrl?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        zoomCompanyId?: string | null;
        type: string;
        displayName?: string | null;
      };
    }>;
    actions?: {
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    } | null;
  } | null;
};

export type SubscribeToZoomUserObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column> | ZoomUser_Select_Column>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By> | ZoomUser_Order_By>;
}>;

export type SubscribeToZoomUserObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomUser: Array<{
    __typename?: 'zoomUser';
    id: string;
    email: string;
    username?: string | null;
    firebaseUid: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    updatedAt?: string | null;
    calendarEnrichmentStatus?: string | null;
    integrationType?: string | null;
    role?: string | null;
    onboardVersion?: string | null;
    photoUrl?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    locationUpdatedAt?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    companyWebsiteDomain?: string | null;
    companyName?: string | null;
    profileUpdatedAt?: string | null;
    recurringEventsInjectionEnabledAt?: string | null;
    googleScopes?: { [key: string]: any } | null;
    zoomCompanyId?: string | null;
    scheduleMeetingUrl?: string | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    calendarLastSyncedAt?: string | null;
    runningLateTemplate?: string | null;
    whereAreYouTemplate?: string | null;
    companyLogoUrl?: string | null;
    intercomOnboardingStartedAt?: string | null;
    zoomExternalAuthUserId?: string | null;
    roleBucket?: string | null;
    onlyInjectWarmlyUsers?: boolean | null;
    onboardingType?: string | null;
    profileVisibility: { [key: string]: any };
    microsoftScopes?: { [key: string]: any } | null;
    nametagSettings?: { [key: string]: any } | null;
    backgroundVisibility?: { [key: string]: any } | null;
    temperatureScale?: string | null;
    department?: string | null;
    startDate?: string | null;
    strengths?: string | null;
    addedToVideoConversationStarterPromptId?: string | null;
    lastJoinedMeetingUuid?: string | null;
    pmfSurveyDisplayedAt?: string | null;
    isGroupSuper?: boolean | null;
    isGroupAdmin?: boolean | null;
    isOrgAdmin?: boolean | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    } | null;
    zoomOrganizationMembership?: {
      __typename?: 'zoomOrganizationMembership';
      id: string;
      type: string;
      organization: {
        __typename?: 'zoomOrganization';
        id: string;
        orgAdminExists?: boolean | null;
        name: string;
        isFreeEmailDomain: boolean;
        otherDomains: { [key: string]: any };
        ssoDomains: { [key: string]: any };
        organizationId?: string | null;
        zoomCompany?: {
          __typename?: 'zoomCompany';
          id: string;
          name?: string | null;
          websiteUrl?: string | null;
          linkedInUrl?: string | null;
          twitterUrl?: string | null;
          crunchbaseUrl?: string | null;
          phone?: string | null;
          websiteDomain?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          industry?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          description?: string | null;
          seoDescription?: string | null;
          totalFunding?: string | null;
          latestFundingStage?: string | null;
          latestFundingRoundDate?: string | null;
          totalFundingPrinted?: string | null;
          technologyNames?: string | null;
          keywords?: string | null;
          alexaRanking?: string | null;
          yearFounded?: string | null;
          hasBeenMerged?: boolean | null;
        } | null;
      };
    } | null;
    zoomGroupMemberships: Array<{
      __typename?: 'zoomGroupMembership';
      id: string;
      type: string;
      group: {
        __typename?: 'zoomGroup';
        id: string;
        name?: string | null;
        description?: string | null;
        websiteDomain?: string | null;
        websiteUrl?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        zoomCompanyId?: string | null;
        type: string;
        displayName?: string | null;
      };
    }>;
    actions?: {
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    } | null;
  }>;
};

export type InsertZoomUserMutationVariables = Exact<{
  objects: Array<ZoomUser_Insert_Input> | ZoomUser_Insert_Input;
}>;

export type InsertZoomUserMutation = {
  __typename?: 'mutation_root';
  insert_zoomUser?: {
    __typename?: 'zoomUser_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomUser';
      id: string;
      email: string;
      username?: string | null;
      firebaseUid: string;
      createdAt: string;
      firstName: string;
      lastName: string;
      firstNamePronunciation?: string | null;
      lastNamePronunciation?: string | null;
      pronouns?: string | null;
      updatedAt?: string | null;
      calendarEnrichmentStatus?: string | null;
      integrationType?: string | null;
      role?: string | null;
      onboardVersion?: string | null;
      photoUrl?: string | null;
      headline?: string | null;
      ask?: string | null;
      title?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      location?: { [key: string]: any } | null;
      locationUpdatedAt?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      companyWebsiteDomain?: string | null;
      companyName?: string | null;
      profileUpdatedAt?: string | null;
      recurringEventsInjectionEnabledAt?: string | null;
      googleScopes?: { [key: string]: any } | null;
      zoomCompanyId?: string | null;
      scheduleMeetingUrl?: string | null;
      customBackgroundPhotoUrl?: string | null;
      defaultCustomBackgroundPhotoUrl?: string | null;
      calendarLastSyncedAt?: string | null;
      runningLateTemplate?: string | null;
      whereAreYouTemplate?: string | null;
      companyLogoUrl?: string | null;
      intercomOnboardingStartedAt?: string | null;
      zoomExternalAuthUserId?: string | null;
      roleBucket?: string | null;
      onlyInjectWarmlyUsers?: boolean | null;
      onboardingType?: string | null;
      profileVisibility: { [key: string]: any };
      microsoftScopes?: { [key: string]: any } | null;
      nametagSettings?: { [key: string]: any } | null;
      backgroundVisibility?: { [key: string]: any } | null;
      temperatureScale?: string | null;
      department?: string | null;
      startDate?: string | null;
      strengths?: string | null;
      addedToVideoConversationStarterPromptId?: string | null;
      lastJoinedMeetingUuid?: string | null;
      pmfSurveyDisplayedAt?: string | null;
      isGroupSuper?: boolean | null;
      isGroupAdmin?: boolean | null;
      isOrgAdmin?: boolean | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
        alexaRanking?: string | null;
        yearFounded?: string | null;
        hasBeenMerged?: boolean | null;
      } | null;
      zoomOrganizationMembership?: {
        __typename?: 'zoomOrganizationMembership';
        id: string;
        type: string;
        organization: {
          __typename?: 'zoomOrganization';
          id: string;
          orgAdminExists?: boolean | null;
          name: string;
          isFreeEmailDomain: boolean;
          otherDomains: { [key: string]: any };
          ssoDomains: { [key: string]: any };
          organizationId?: string | null;
          zoomCompany?: {
            __typename?: 'zoomCompany';
            id: string;
            name?: string | null;
            websiteUrl?: string | null;
            linkedInUrl?: string | null;
            twitterUrl?: string | null;
            crunchbaseUrl?: string | null;
            phone?: string | null;
            websiteDomain?: string | null;
            logoUrl?: string | null;
            estimatedSize?: string | null;
            industry?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            description?: string | null;
            seoDescription?: string | null;
            totalFunding?: string | null;
            latestFundingStage?: string | null;
            latestFundingRoundDate?: string | null;
            totalFundingPrinted?: string | null;
            technologyNames?: string | null;
            keywords?: string | null;
            alexaRanking?: string | null;
            yearFounded?: string | null;
            hasBeenMerged?: boolean | null;
          } | null;
        };
      } | null;
      zoomGroupMemberships: Array<{
        __typename?: 'zoomGroupMembership';
        id: string;
        type: string;
        group: {
          __typename?: 'zoomGroup';
          id: string;
          name?: string | null;
          description?: string | null;
          websiteDomain?: string | null;
          websiteUrl?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          zoomCompanyId?: string | null;
          type: string;
          displayName?: string | null;
        };
      }>;
      actions?: {
        __typename?: 'zoomUserActions';
        id: string;
        createdAt: string;
        updatedAt: string;
        zoomUserId: string;
        enhanceNametagCompletedAt?: string | null;
        enhanceNametagDismissedAt?: string | null;
        enhanceNametagDialogShownAt?: string | null;
        practiceRoomCompletedAt?: string | null;
        practiceRoomDismissedAt?: string | null;
        practiceRoomShownAt?: string | null;
        excitingNewsAlertDismissedAt?: string | null;
        manageOrganizationAlertDismissedAt?: string | null;
        needZoomAccessAlertDismissedAt?: string | null;
        welcomeSetupCardDismissedAt?: string | null;
        welcomeDialogDismissedAt?: string | null;
        unmirrorCardDismissedAt?: string | null;
        calSigDismissedAt?: string | null;
        installZoomDismissedAt?: string | null;
        upgradeZoomAlertDismissedAt?: string | null;
        agendaCardDismissedAt?: string | null;
        videoOffCardDismissedAt?: string | null;
        videoOffCardCompletedAt?: string | null;
        addBioDismissedAt?: string | null;
      } | null;
    }>;
  } | null;
};

export type InsertZoomUserWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomUser_Insert_Input> | ZoomUser_Insert_Input;
  onConflict?: InputMaybe<ZoomUser_On_Conflict>;
}>;

export type InsertZoomUserWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomUser?: {
    __typename?: 'zoomUser_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomUser';
      id: string;
      email: string;
      username?: string | null;
      firebaseUid: string;
      createdAt: string;
      firstName: string;
      lastName: string;
      firstNamePronunciation?: string | null;
      lastNamePronunciation?: string | null;
      pronouns?: string | null;
      updatedAt?: string | null;
      calendarEnrichmentStatus?: string | null;
      integrationType?: string | null;
      role?: string | null;
      onboardVersion?: string | null;
      photoUrl?: string | null;
      headline?: string | null;
      ask?: string | null;
      title?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      location?: { [key: string]: any } | null;
      locationUpdatedAt?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      companyWebsiteDomain?: string | null;
      companyName?: string | null;
      profileUpdatedAt?: string | null;
      recurringEventsInjectionEnabledAt?: string | null;
      googleScopes?: { [key: string]: any } | null;
      zoomCompanyId?: string | null;
      scheduleMeetingUrl?: string | null;
      customBackgroundPhotoUrl?: string | null;
      defaultCustomBackgroundPhotoUrl?: string | null;
      calendarLastSyncedAt?: string | null;
      runningLateTemplate?: string | null;
      whereAreYouTemplate?: string | null;
      companyLogoUrl?: string | null;
      intercomOnboardingStartedAt?: string | null;
      zoomExternalAuthUserId?: string | null;
      roleBucket?: string | null;
      onlyInjectWarmlyUsers?: boolean | null;
      onboardingType?: string | null;
      profileVisibility: { [key: string]: any };
      microsoftScopes?: { [key: string]: any } | null;
      nametagSettings?: { [key: string]: any } | null;
      backgroundVisibility?: { [key: string]: any } | null;
      temperatureScale?: string | null;
      department?: string | null;
      startDate?: string | null;
      strengths?: string | null;
      addedToVideoConversationStarterPromptId?: string | null;
      lastJoinedMeetingUuid?: string | null;
      pmfSurveyDisplayedAt?: string | null;
      isGroupSuper?: boolean | null;
      isGroupAdmin?: boolean | null;
      isOrgAdmin?: boolean | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
        alexaRanking?: string | null;
        yearFounded?: string | null;
        hasBeenMerged?: boolean | null;
      } | null;
      zoomOrganizationMembership?: {
        __typename?: 'zoomOrganizationMembership';
        id: string;
        type: string;
        organization: {
          __typename?: 'zoomOrganization';
          id: string;
          orgAdminExists?: boolean | null;
          name: string;
          isFreeEmailDomain: boolean;
          otherDomains: { [key: string]: any };
          ssoDomains: { [key: string]: any };
          organizationId?: string | null;
          zoomCompany?: {
            __typename?: 'zoomCompany';
            id: string;
            name?: string | null;
            websiteUrl?: string | null;
            linkedInUrl?: string | null;
            twitterUrl?: string | null;
            crunchbaseUrl?: string | null;
            phone?: string | null;
            websiteDomain?: string | null;
            logoUrl?: string | null;
            estimatedSize?: string | null;
            industry?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            description?: string | null;
            seoDescription?: string | null;
            totalFunding?: string | null;
            latestFundingStage?: string | null;
            latestFundingRoundDate?: string | null;
            totalFundingPrinted?: string | null;
            technologyNames?: string | null;
            keywords?: string | null;
            alexaRanking?: string | null;
            yearFounded?: string | null;
            hasBeenMerged?: boolean | null;
          } | null;
        };
      } | null;
      zoomGroupMemberships: Array<{
        __typename?: 'zoomGroupMembership';
        id: string;
        type: string;
        group: {
          __typename?: 'zoomGroup';
          id: string;
          name?: string | null;
          description?: string | null;
          websiteDomain?: string | null;
          websiteUrl?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          zoomCompanyId?: string | null;
          type: string;
          displayName?: string | null;
        };
      }>;
      actions?: {
        __typename?: 'zoomUserActions';
        id: string;
        createdAt: string;
        updatedAt: string;
        zoomUserId: string;
        enhanceNametagCompletedAt?: string | null;
        enhanceNametagDismissedAt?: string | null;
        enhanceNametagDialogShownAt?: string | null;
        practiceRoomCompletedAt?: string | null;
        practiceRoomDismissedAt?: string | null;
        practiceRoomShownAt?: string | null;
        excitingNewsAlertDismissedAt?: string | null;
        manageOrganizationAlertDismissedAt?: string | null;
        needZoomAccessAlertDismissedAt?: string | null;
        welcomeSetupCardDismissedAt?: string | null;
        welcomeDialogDismissedAt?: string | null;
        unmirrorCardDismissedAt?: string | null;
        calSigDismissedAt?: string | null;
        installZoomDismissedAt?: string | null;
        upgradeZoomAlertDismissedAt?: string | null;
        agendaCardDismissedAt?: string | null;
        videoOffCardDismissedAt?: string | null;
        videoOffCardCompletedAt?: string | null;
        addBioDismissedAt?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateZoomUserByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomUser_Set_Input>;
}>;

export type UpdateZoomUserByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomUser?: {
    __typename?: 'zoomUser_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomUser';
      id: string;
      email: string;
      username?: string | null;
      firebaseUid: string;
      createdAt: string;
      firstName: string;
      lastName: string;
      firstNamePronunciation?: string | null;
      lastNamePronunciation?: string | null;
      pronouns?: string | null;
      updatedAt?: string | null;
      calendarEnrichmentStatus?: string | null;
      integrationType?: string | null;
      role?: string | null;
      onboardVersion?: string | null;
      photoUrl?: string | null;
      headline?: string | null;
      ask?: string | null;
      title?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      location?: { [key: string]: any } | null;
      locationUpdatedAt?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      companyWebsiteDomain?: string | null;
      companyName?: string | null;
      profileUpdatedAt?: string | null;
      recurringEventsInjectionEnabledAt?: string | null;
      googleScopes?: { [key: string]: any } | null;
      zoomCompanyId?: string | null;
      scheduleMeetingUrl?: string | null;
      customBackgroundPhotoUrl?: string | null;
      defaultCustomBackgroundPhotoUrl?: string | null;
      calendarLastSyncedAt?: string | null;
      runningLateTemplate?: string | null;
      whereAreYouTemplate?: string | null;
      companyLogoUrl?: string | null;
      intercomOnboardingStartedAt?: string | null;
      zoomExternalAuthUserId?: string | null;
      roleBucket?: string | null;
      onlyInjectWarmlyUsers?: boolean | null;
      onboardingType?: string | null;
      profileVisibility: { [key: string]: any };
      microsoftScopes?: { [key: string]: any } | null;
      nametagSettings?: { [key: string]: any } | null;
      backgroundVisibility?: { [key: string]: any } | null;
      temperatureScale?: string | null;
      department?: string | null;
      startDate?: string | null;
      strengths?: string | null;
      addedToVideoConversationStarterPromptId?: string | null;
      lastJoinedMeetingUuid?: string | null;
      pmfSurveyDisplayedAt?: string | null;
      isGroupSuper?: boolean | null;
      isGroupAdmin?: boolean | null;
      isOrgAdmin?: boolean | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
        alexaRanking?: string | null;
        yearFounded?: string | null;
        hasBeenMerged?: boolean | null;
      } | null;
      zoomOrganizationMembership?: {
        __typename?: 'zoomOrganizationMembership';
        id: string;
        type: string;
        organization: {
          __typename?: 'zoomOrganization';
          id: string;
          orgAdminExists?: boolean | null;
          name: string;
          isFreeEmailDomain: boolean;
          otherDomains: { [key: string]: any };
          ssoDomains: { [key: string]: any };
          organizationId?: string | null;
          zoomCompany?: {
            __typename?: 'zoomCompany';
            id: string;
            name?: string | null;
            websiteUrl?: string | null;
            linkedInUrl?: string | null;
            twitterUrl?: string | null;
            crunchbaseUrl?: string | null;
            phone?: string | null;
            websiteDomain?: string | null;
            logoUrl?: string | null;
            estimatedSize?: string | null;
            industry?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            description?: string | null;
            seoDescription?: string | null;
            totalFunding?: string | null;
            latestFundingStage?: string | null;
            latestFundingRoundDate?: string | null;
            totalFundingPrinted?: string | null;
            technologyNames?: string | null;
            keywords?: string | null;
            alexaRanking?: string | null;
            yearFounded?: string | null;
            hasBeenMerged?: boolean | null;
          } | null;
        };
      } | null;
      zoomGroupMemberships: Array<{
        __typename?: 'zoomGroupMembership';
        id: string;
        type: string;
        group: {
          __typename?: 'zoomGroup';
          id: string;
          name?: string | null;
          description?: string | null;
          websiteDomain?: string | null;
          websiteUrl?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          zoomCompanyId?: string | null;
          type: string;
          displayName?: string | null;
        };
      }>;
      actions?: {
        __typename?: 'zoomUserActions';
        id: string;
        createdAt: string;
        updatedAt: string;
        zoomUserId: string;
        enhanceNametagCompletedAt?: string | null;
        enhanceNametagDismissedAt?: string | null;
        enhanceNametagDialogShownAt?: string | null;
        practiceRoomCompletedAt?: string | null;
        practiceRoomDismissedAt?: string | null;
        practiceRoomShownAt?: string | null;
        excitingNewsAlertDismissedAt?: string | null;
        manageOrganizationAlertDismissedAt?: string | null;
        needZoomAccessAlertDismissedAt?: string | null;
        welcomeSetupCardDismissedAt?: string | null;
        welcomeDialogDismissedAt?: string | null;
        unmirrorCardDismissedAt?: string | null;
        calSigDismissedAt?: string | null;
        installZoomDismissedAt?: string | null;
        upgradeZoomAlertDismissedAt?: string | null;
        agendaCardDismissedAt?: string | null;
        videoOffCardDismissedAt?: string | null;
        videoOffCardCompletedAt?: string | null;
        addBioDismissedAt?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateZoomUserMutationVariables = Exact<{
  set?: InputMaybe<ZoomUser_Set_Input>;
  where: ZoomUser_Bool_Exp;
}>;

export type UpdateZoomUserMutation = {
  __typename?: 'mutation_root';
  update_zoomUser?: {
    __typename?: 'zoomUser_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomUser';
      id: string;
      email: string;
      username?: string | null;
      firebaseUid: string;
      createdAt: string;
      firstName: string;
      lastName: string;
      firstNamePronunciation?: string | null;
      lastNamePronunciation?: string | null;
      pronouns?: string | null;
      updatedAt?: string | null;
      calendarEnrichmentStatus?: string | null;
      integrationType?: string | null;
      role?: string | null;
      onboardVersion?: string | null;
      photoUrl?: string | null;
      headline?: string | null;
      ask?: string | null;
      title?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      location?: { [key: string]: any } | null;
      locationUpdatedAt?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      companyWebsiteDomain?: string | null;
      companyName?: string | null;
      profileUpdatedAt?: string | null;
      recurringEventsInjectionEnabledAt?: string | null;
      googleScopes?: { [key: string]: any } | null;
      zoomCompanyId?: string | null;
      scheduleMeetingUrl?: string | null;
      customBackgroundPhotoUrl?: string | null;
      defaultCustomBackgroundPhotoUrl?: string | null;
      calendarLastSyncedAt?: string | null;
      runningLateTemplate?: string | null;
      whereAreYouTemplate?: string | null;
      companyLogoUrl?: string | null;
      intercomOnboardingStartedAt?: string | null;
      zoomExternalAuthUserId?: string | null;
      roleBucket?: string | null;
      onlyInjectWarmlyUsers?: boolean | null;
      onboardingType?: string | null;
      profileVisibility: { [key: string]: any };
      microsoftScopes?: { [key: string]: any } | null;
      nametagSettings?: { [key: string]: any } | null;
      backgroundVisibility?: { [key: string]: any } | null;
      temperatureScale?: string | null;
      department?: string | null;
      startDate?: string | null;
      strengths?: string | null;
      addedToVideoConversationStarterPromptId?: string | null;
      lastJoinedMeetingUuid?: string | null;
      pmfSurveyDisplayedAt?: string | null;
      isGroupSuper?: boolean | null;
      isGroupAdmin?: boolean | null;
      isOrgAdmin?: boolean | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
        alexaRanking?: string | null;
        yearFounded?: string | null;
        hasBeenMerged?: boolean | null;
      } | null;
      zoomOrganizationMembership?: {
        __typename?: 'zoomOrganizationMembership';
        id: string;
        type: string;
        organization: {
          __typename?: 'zoomOrganization';
          id: string;
          orgAdminExists?: boolean | null;
          name: string;
          isFreeEmailDomain: boolean;
          otherDomains: { [key: string]: any };
          ssoDomains: { [key: string]: any };
          organizationId?: string | null;
          zoomCompany?: {
            __typename?: 'zoomCompany';
            id: string;
            name?: string | null;
            websiteUrl?: string | null;
            linkedInUrl?: string | null;
            twitterUrl?: string | null;
            crunchbaseUrl?: string | null;
            phone?: string | null;
            websiteDomain?: string | null;
            logoUrl?: string | null;
            estimatedSize?: string | null;
            industry?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            description?: string | null;
            seoDescription?: string | null;
            totalFunding?: string | null;
            latestFundingStage?: string | null;
            latestFundingRoundDate?: string | null;
            totalFundingPrinted?: string | null;
            technologyNames?: string | null;
            keywords?: string | null;
            alexaRanking?: string | null;
            yearFounded?: string | null;
            hasBeenMerged?: boolean | null;
          } | null;
        };
      } | null;
      zoomGroupMemberships: Array<{
        __typename?: 'zoomGroupMembership';
        id: string;
        type: string;
        group: {
          __typename?: 'zoomGroup';
          id: string;
          name?: string | null;
          description?: string | null;
          websiteDomain?: string | null;
          websiteUrl?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          zoomCompanyId?: string | null;
          type: string;
          displayName?: string | null;
        };
      }>;
      actions?: {
        __typename?: 'zoomUserActions';
        id: string;
        createdAt: string;
        updatedAt: string;
        zoomUserId: string;
        enhanceNametagCompletedAt?: string | null;
        enhanceNametagDismissedAt?: string | null;
        enhanceNametagDialogShownAt?: string | null;
        practiceRoomCompletedAt?: string | null;
        practiceRoomDismissedAt?: string | null;
        practiceRoomShownAt?: string | null;
        excitingNewsAlertDismissedAt?: string | null;
        manageOrganizationAlertDismissedAt?: string | null;
        needZoomAccessAlertDismissedAt?: string | null;
        welcomeSetupCardDismissedAt?: string | null;
        welcomeDialogDismissedAt?: string | null;
        unmirrorCardDismissedAt?: string | null;
        calSigDismissedAt?: string | null;
        installZoomDismissedAt?: string | null;
        upgradeZoomAlertDismissedAt?: string | null;
        agendaCardDismissedAt?: string | null;
        videoOffCardDismissedAt?: string | null;
        videoOffCardCompletedAt?: string | null;
        addBioDismissedAt?: string | null;
      } | null;
    }>;
  } | null;
};

export type RemoveZoomUserModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomUserModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomUser?: { __typename?: 'zoomUser_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomUserModelMutationVariables = Exact<{
  where: ZoomUser_Bool_Exp;
}>;

export type RemoveZoomUserModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomUser?: { __typename?: 'zoomUser_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomContactNotesByIdQueryVariables = Exact<{
  zoomContactNotesId: Scalars['uuid'];
}>;

export type QueryZoomContactNotesByIdQuery = {
  __typename?: 'query_root';
  zoomContactNotes_by_pk?: {
    __typename?: 'zoomContactNotes';
    id: string;
    ownerEmail: string;
    contactEmail: string;
    notes?: { [key: string]: any } | null;
    notesText?: string | null;
    ownerZoomUserId: string;
    zoomContactId: string;
  } | null;
};

export type QueryZoomContactNotesObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomContactNotes_Select_Column> | ZoomContactNotes_Select_Column>;
  where?: InputMaybe<ZoomContactNotes_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContactNotes_Order_By> | ZoomContactNotes_Order_By>;
}>;

export type QueryZoomContactNotesObjectsQuery = {
  __typename?: 'query_root';
  zoomContactNotes: Array<{
    __typename?: 'zoomContactNotes';
    id: string;
    ownerEmail: string;
    contactEmail: string;
    notes?: { [key: string]: any } | null;
    notesText?: string | null;
    ownerZoomUserId: string;
    zoomContactId: string;
  }>;
};

export type SubscribeToZoomContactNotesByIdSubscriptionVariables = Exact<{
  zoomContactNotesId: Scalars['uuid'];
}>;

export type SubscribeToZoomContactNotesByIdSubscription = {
  __typename?: 'subscription_root';
  zoomContactNotes_by_pk?: {
    __typename?: 'zoomContactNotes';
    id: string;
    ownerEmail: string;
    contactEmail: string;
    notes?: { [key: string]: any } | null;
    notesText?: string | null;
    ownerZoomUserId: string;
    zoomContactId: string;
  } | null;
};

export type SubscribeToZoomContactNotesObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomContactNotes_Select_Column> | ZoomContactNotes_Select_Column>;
  where?: InputMaybe<ZoomContactNotes_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomContactNotes_Order_By> | ZoomContactNotes_Order_By>;
}>;

export type SubscribeToZoomContactNotesObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomContactNotes: Array<{
    __typename?: 'zoomContactNotes';
    id: string;
    ownerEmail: string;
    contactEmail: string;
    notes?: { [key: string]: any } | null;
    notesText?: string | null;
    ownerZoomUserId: string;
    zoomContactId: string;
  }>;
};

export type InsertZoomContactNotesMutationVariables = Exact<{
  objects: Array<ZoomContactNotes_Insert_Input> | ZoomContactNotes_Insert_Input;
}>;

export type InsertZoomContactNotesMutation = {
  __typename?: 'mutation_root';
  insert_zoomContactNotes?: {
    __typename?: 'zoomContactNotes_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContactNotes';
      id: string;
      ownerEmail: string;
      contactEmail: string;
      notes?: { [key: string]: any } | null;
      notesText?: string | null;
      ownerZoomUserId: string;
      zoomContactId: string;
    }>;
  } | null;
};

export type InsertZoomContactNotesWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomContactNotes_Insert_Input> | ZoomContactNotes_Insert_Input;
  onConflict?: InputMaybe<ZoomContactNotes_On_Conflict>;
}>;

export type InsertZoomContactNotesWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomContactNotes?: {
    __typename?: 'zoomContactNotes_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContactNotes';
      id: string;
      ownerEmail: string;
      contactEmail: string;
      notes?: { [key: string]: any } | null;
      notesText?: string | null;
      ownerZoomUserId: string;
      zoomContactId: string;
    }>;
  } | null;
};

export type UpdateZoomContactNotesByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomContactNotes_Set_Input>;
}>;

export type UpdateZoomContactNotesByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomContactNotes?: {
    __typename?: 'zoomContactNotes_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContactNotes';
      id: string;
      ownerEmail: string;
      contactEmail: string;
      notes?: { [key: string]: any } | null;
      notesText?: string | null;
      ownerZoomUserId: string;
      zoomContactId: string;
    }>;
  } | null;
};

export type UpdateZoomContactNotesMutationVariables = Exact<{
  set?: InputMaybe<ZoomContactNotes_Set_Input>;
  where: ZoomContactNotes_Bool_Exp;
}>;

export type UpdateZoomContactNotesMutation = {
  __typename?: 'mutation_root';
  update_zoomContactNotes?: {
    __typename?: 'zoomContactNotes_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomContactNotes';
      id: string;
      ownerEmail: string;
      contactEmail: string;
      notes?: { [key: string]: any } | null;
      notesText?: string | null;
      ownerZoomUserId: string;
      zoomContactId: string;
    }>;
  } | null;
};

export type RemoveZoomContactNotesModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomContactNotesModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomContactNotes?: { __typename?: 'zoomContactNotes_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomContactNotesModelMutationVariables = Exact<{
  where: ZoomContactNotes_Bool_Exp;
}>;

export type RemoveZoomContactNotesModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomContactNotes?: { __typename?: 'zoomContactNotes_mutation_response'; affected_rows: number } | null;
};

export type QueryGoogleChannelByIdQueryVariables = Exact<{
  googleChannelId: Scalars['Int'];
}>;

export type QueryGoogleChannelByIdQuery = {
  __typename?: 'query_root';
  googleChannel_by_pk?: {
    __typename?: 'googleChannel';
    id: number;
    channelId: string;
    resourceId: string;
    zoomUserId: string;
    resourceUri?: string | null;
    expiration?: string | null;
    stoppedAt?: string | null;
  } | null;
};

export type QueryGoogleChannelObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<GoogleChannel_Select_Column> | GoogleChannel_Select_Column>;
  where?: InputMaybe<GoogleChannel_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleChannel_Order_By> | GoogleChannel_Order_By>;
}>;

export type QueryGoogleChannelObjectsQuery = {
  __typename?: 'query_root';
  googleChannel: Array<{
    __typename?: 'googleChannel';
    id: number;
    channelId: string;
    resourceId: string;
    zoomUserId: string;
    resourceUri?: string | null;
    expiration?: string | null;
    stoppedAt?: string | null;
  }>;
};

export type SubscribeToGoogleChannelByIdSubscriptionVariables = Exact<{
  googleChannelId: Scalars['Int'];
}>;

export type SubscribeToGoogleChannelByIdSubscription = {
  __typename?: 'subscription_root';
  googleChannel_by_pk?: {
    __typename?: 'googleChannel';
    id: number;
    channelId: string;
    resourceId: string;
    zoomUserId: string;
    resourceUri?: string | null;
    expiration?: string | null;
    stoppedAt?: string | null;
  } | null;
};

export type SubscribeToGoogleChannelObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<GoogleChannel_Select_Column> | GoogleChannel_Select_Column>;
  where?: InputMaybe<GoogleChannel_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GoogleChannel_Order_By> | GoogleChannel_Order_By>;
}>;

export type SubscribeToGoogleChannelObjectsSubscription = {
  __typename?: 'subscription_root';
  googleChannel: Array<{
    __typename?: 'googleChannel';
    id: number;
    channelId: string;
    resourceId: string;
    zoomUserId: string;
    resourceUri?: string | null;
    expiration?: string | null;
    stoppedAt?: string | null;
  }>;
};

export type InsertGoogleChannelMutationVariables = Exact<{
  objects: Array<GoogleChannel_Insert_Input> | GoogleChannel_Insert_Input;
}>;

export type InsertGoogleChannelMutation = {
  __typename?: 'mutation_root';
  insert_googleChannel?: {
    __typename?: 'googleChannel_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'googleChannel';
      id: number;
      channelId: string;
      resourceId: string;
      zoomUserId: string;
      resourceUri?: string | null;
      expiration?: string | null;
      stoppedAt?: string | null;
    }>;
  } | null;
};

export type InsertGoogleChannelWithOnConflictMutationVariables = Exact<{
  objects: Array<GoogleChannel_Insert_Input> | GoogleChannel_Insert_Input;
  onConflict?: InputMaybe<GoogleChannel_On_Conflict>;
}>;

export type InsertGoogleChannelWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_googleChannel?: {
    __typename?: 'googleChannel_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'googleChannel';
      id: number;
      channelId: string;
      resourceId: string;
      zoomUserId: string;
      resourceUri?: string | null;
      expiration?: string | null;
      stoppedAt?: string | null;
    }>;
  } | null;
};

export type UpdateGoogleChannelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<GoogleChannel_Set_Input>;
}>;

export type UpdateGoogleChannelByIdMutation = {
  __typename?: 'mutation_root';
  update_googleChannel?: {
    __typename?: 'googleChannel_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'googleChannel';
      id: number;
      channelId: string;
      resourceId: string;
      zoomUserId: string;
      resourceUri?: string | null;
      expiration?: string | null;
      stoppedAt?: string | null;
    }>;
  } | null;
};

export type UpdateGoogleChannelMutationVariables = Exact<{
  set?: InputMaybe<GoogleChannel_Set_Input>;
  where: GoogleChannel_Bool_Exp;
}>;

export type UpdateGoogleChannelMutation = {
  __typename?: 'mutation_root';
  update_googleChannel?: {
    __typename?: 'googleChannel_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'googleChannel';
      id: number;
      channelId: string;
      resourceId: string;
      zoomUserId: string;
      resourceUri?: string | null;
      expiration?: string | null;
      stoppedAt?: string | null;
    }>;
  } | null;
};

export type RemoveGoogleChannelModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type RemoveGoogleChannelModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_googleChannel?: { __typename?: 'googleChannel_mutation_response'; affected_rows: number } | null;
};

export type RemoveGoogleChannelModelMutationVariables = Exact<{
  where: GoogleChannel_Bool_Exp;
}>;

export type RemoveGoogleChannelModelMutation = {
  __typename?: 'mutation_root';
  delete_googleChannel?: { __typename?: 'googleChannel_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomEmailHistoryByIdQueryVariables = Exact<{
  zoomEmailHistoryId: Scalars['uuid'];
}>;

export type QueryZoomEmailHistoryByIdQuery = {
  __typename?: 'query_root';
  zoomEmailHistory_by_pk?: {
    __typename?: 'zoomEmailHistory';
    id: string;
    emailService: string;
    category: string;
    description: string;
    status: string;
    body?: string | null;
    subject?: string | null;
    from: string;
    to?: string | null;
    cc?: string | null;
    bcc?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    emailServiceMessageId?: string | null;
    zoomUserId?: string | null;
    zoomEventId?: number | null;
  } | null;
};

export type QueryZoomEmailHistoryObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomEmailHistory_Select_Column> | ZoomEmailHistory_Select_Column>;
  where?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEmailHistory_Order_By> | ZoomEmailHistory_Order_By>;
}>;

export type QueryZoomEmailHistoryObjectsQuery = {
  __typename?: 'query_root';
  zoomEmailHistory: Array<{
    __typename?: 'zoomEmailHistory';
    id: string;
    emailService: string;
    category: string;
    description: string;
    status: string;
    body?: string | null;
    subject?: string | null;
    from: string;
    to?: string | null;
    cc?: string | null;
    bcc?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    emailServiceMessageId?: string | null;
    zoomUserId?: string | null;
    zoomEventId?: number | null;
  }>;
};

export type SubscribeToZoomEmailHistoryByIdSubscriptionVariables = Exact<{
  zoomEmailHistoryId: Scalars['uuid'];
}>;

export type SubscribeToZoomEmailHistoryByIdSubscription = {
  __typename?: 'subscription_root';
  zoomEmailHistory_by_pk?: {
    __typename?: 'zoomEmailHistory';
    id: string;
    emailService: string;
    category: string;
    description: string;
    status: string;
    body?: string | null;
    subject?: string | null;
    from: string;
    to?: string | null;
    cc?: string | null;
    bcc?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    emailServiceMessageId?: string | null;
    zoomUserId?: string | null;
    zoomEventId?: number | null;
  } | null;
};

export type SubscribeToZoomEmailHistoryObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomEmailHistory_Select_Column> | ZoomEmailHistory_Select_Column>;
  where?: InputMaybe<ZoomEmailHistory_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEmailHistory_Order_By> | ZoomEmailHistory_Order_By>;
}>;

export type SubscribeToZoomEmailHistoryObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomEmailHistory: Array<{
    __typename?: 'zoomEmailHistory';
    id: string;
    emailService: string;
    category: string;
    description: string;
    status: string;
    body?: string | null;
    subject?: string | null;
    from: string;
    to?: string | null;
    cc?: string | null;
    bcc?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    emailServiceMessageId?: string | null;
    zoomUserId?: string | null;
    zoomEventId?: number | null;
  }>;
};

export type InsertZoomEmailHistoryMutationVariables = Exact<{
  objects: Array<ZoomEmailHistory_Insert_Input> | ZoomEmailHistory_Insert_Input;
}>;

export type InsertZoomEmailHistoryMutation = {
  __typename?: 'mutation_root';
  insert_zoomEmailHistory?: {
    __typename?: 'zoomEmailHistory_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomEmailHistory';
      id: string;
      emailService: string;
      category: string;
      description: string;
      status: string;
      body?: string | null;
      subject?: string | null;
      from: string;
      to?: string | null;
      cc?: string | null;
      bcc?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      emailServiceMessageId?: string | null;
      zoomUserId?: string | null;
      zoomEventId?: number | null;
    }>;
  } | null;
};

export type InsertZoomEmailHistoryWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomEmailHistory_Insert_Input> | ZoomEmailHistory_Insert_Input;
  onConflict?: InputMaybe<ZoomEmailHistory_On_Conflict>;
}>;

export type InsertZoomEmailHistoryWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomEmailHistory?: {
    __typename?: 'zoomEmailHistory_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomEmailHistory';
      id: string;
      emailService: string;
      category: string;
      description: string;
      status: string;
      body?: string | null;
      subject?: string | null;
      from: string;
      to?: string | null;
      cc?: string | null;
      bcc?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      emailServiceMessageId?: string | null;
      zoomUserId?: string | null;
      zoomEventId?: number | null;
    }>;
  } | null;
};

export type UpdateZoomEmailHistoryByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomEmailHistory_Set_Input>;
}>;

export type UpdateZoomEmailHistoryByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomEmailHistory?: {
    __typename?: 'zoomEmailHistory_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomEmailHistory';
      id: string;
      emailService: string;
      category: string;
      description: string;
      status: string;
      body?: string | null;
      subject?: string | null;
      from: string;
      to?: string | null;
      cc?: string | null;
      bcc?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      emailServiceMessageId?: string | null;
      zoomUserId?: string | null;
      zoomEventId?: number | null;
    }>;
  } | null;
};

export type UpdateZoomEmailHistoryMutationVariables = Exact<{
  set?: InputMaybe<ZoomEmailHistory_Set_Input>;
  where: ZoomEmailHistory_Bool_Exp;
}>;

export type UpdateZoomEmailHistoryMutation = {
  __typename?: 'mutation_root';
  update_zoomEmailHistory?: {
    __typename?: 'zoomEmailHistory_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomEmailHistory';
      id: string;
      emailService: string;
      category: string;
      description: string;
      status: string;
      body?: string | null;
      subject?: string | null;
      from: string;
      to?: string | null;
      cc?: string | null;
      bcc?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      emailServiceMessageId?: string | null;
      zoomUserId?: string | null;
      zoomEventId?: number | null;
    }>;
  } | null;
};

export type RemoveZoomEmailHistoryModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomEmailHistoryModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomEmailHistory?: { __typename?: 'zoomEmailHistory_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomEmailHistoryModelMutationVariables = Exact<{
  where: ZoomEmailHistory_Bool_Exp;
}>;

export type RemoveZoomEmailHistoryModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomEmailHistory?: { __typename?: 'zoomEmailHistory_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomCustomBackgroundByIdQueryVariables = Exact<{
  zoomCustomBackgroundId: Scalars['uuid'];
}>;

export type QueryZoomCustomBackgroundByIdQuery = {
  __typename?: 'query_root';
  zoomCustomBackground_by_pk?: {
    __typename?: 'zoomCustomBackground';
    id: string;
    url: string;
    uploadedByZoomUserId?: string | null;
    companyDomain: any;
    companyShared: boolean;
    downloadCount: number;
    createdAt: string;
    updatedAt: string;
    isGlobal: boolean;
    isHoliday: boolean;
    caption?: string | null;
    zoomUser?: {
      __typename?: 'zoomUser';
      firstName: string;
      lastName: string;
      fullNameGenerated?: string | null;
      email: string;
      photoUrl?: string | null;
    } | null;
  } | null;
};

export type QueryZoomCustomBackgroundObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomCustomBackground_Select_Column> | ZoomCustomBackground_Select_Column>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCustomBackground_Order_By> | ZoomCustomBackground_Order_By>;
}>;

export type QueryZoomCustomBackgroundObjectsQuery = {
  __typename?: 'query_root';
  zoomCustomBackground: Array<{
    __typename?: 'zoomCustomBackground';
    id: string;
    url: string;
    uploadedByZoomUserId?: string | null;
    companyDomain: any;
    companyShared: boolean;
    downloadCount: number;
    createdAt: string;
    updatedAt: string;
    isGlobal: boolean;
    isHoliday: boolean;
    caption?: string | null;
    zoomUser?: {
      __typename?: 'zoomUser';
      firstName: string;
      lastName: string;
      fullNameGenerated?: string | null;
      email: string;
      photoUrl?: string | null;
    } | null;
  }>;
};

export type SubscribeToZoomCustomBackgroundByIdSubscriptionVariables = Exact<{
  zoomCustomBackgroundId: Scalars['uuid'];
}>;

export type SubscribeToZoomCustomBackgroundByIdSubscription = {
  __typename?: 'subscription_root';
  zoomCustomBackground_by_pk?: {
    __typename?: 'zoomCustomBackground';
    id: string;
    url: string;
    uploadedByZoomUserId?: string | null;
    companyDomain: any;
    companyShared: boolean;
    downloadCount: number;
    createdAt: string;
    updatedAt: string;
    isGlobal: boolean;
    isHoliday: boolean;
    caption?: string | null;
    zoomUser?: {
      __typename?: 'zoomUser';
      firstName: string;
      lastName: string;
      fullNameGenerated?: string | null;
      email: string;
      photoUrl?: string | null;
    } | null;
  } | null;
};

export type SubscribeToZoomCustomBackgroundObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomCustomBackground_Select_Column> | ZoomCustomBackground_Select_Column>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCustomBackground_Order_By> | ZoomCustomBackground_Order_By>;
}>;

export type SubscribeToZoomCustomBackgroundObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomCustomBackground: Array<{
    __typename?: 'zoomCustomBackground';
    id: string;
    url: string;
    uploadedByZoomUserId?: string | null;
    companyDomain: any;
    companyShared: boolean;
    downloadCount: number;
    createdAt: string;
    updatedAt: string;
    isGlobal: boolean;
    isHoliday: boolean;
    caption?: string | null;
    zoomUser?: {
      __typename?: 'zoomUser';
      firstName: string;
      lastName: string;
      fullNameGenerated?: string | null;
      email: string;
      photoUrl?: string | null;
    } | null;
  }>;
};

export type InsertZoomCustomBackgroundMutationVariables = Exact<{
  objects: Array<ZoomCustomBackground_Insert_Input> | ZoomCustomBackground_Insert_Input;
}>;

export type InsertZoomCustomBackgroundMutation = {
  __typename?: 'mutation_root';
  insert_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      uploadedByZoomUserId?: string | null;
      companyDomain: any;
      companyShared: boolean;
      downloadCount: number;
      createdAt: string;
      updatedAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
      zoomUser?: {
        __typename?: 'zoomUser';
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type InsertZoomCustomBackgroundWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomCustomBackground_Insert_Input> | ZoomCustomBackground_Insert_Input;
  onConflict?: InputMaybe<ZoomCustomBackground_On_Conflict>;
}>;

export type InsertZoomCustomBackgroundWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      uploadedByZoomUserId?: string | null;
      companyDomain: any;
      companyShared: boolean;
      downloadCount: number;
      createdAt: string;
      updatedAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
      zoomUser?: {
        __typename?: 'zoomUser';
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateZoomCustomBackgroundByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomCustomBackground_Set_Input>;
}>;

export type UpdateZoomCustomBackgroundByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      uploadedByZoomUserId?: string | null;
      companyDomain: any;
      companyShared: boolean;
      downloadCount: number;
      createdAt: string;
      updatedAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
      zoomUser?: {
        __typename?: 'zoomUser';
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateZoomCustomBackgroundMutationVariables = Exact<{
  set?: InputMaybe<ZoomCustomBackground_Set_Input>;
  where: ZoomCustomBackground_Bool_Exp;
}>;

export type UpdateZoomCustomBackgroundMutation = {
  __typename?: 'mutation_root';
  update_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      uploadedByZoomUserId?: string | null;
      companyDomain: any;
      companyShared: boolean;
      downloadCount: number;
      createdAt: string;
      updatedAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
      zoomUser?: {
        __typename?: 'zoomUser';
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type RemoveZoomCustomBackgroundModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomCustomBackgroundModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomCustomBackground?: { __typename?: 'zoomCustomBackground_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomCustomBackgroundModelMutationVariables = Exact<{
  where: ZoomCustomBackground_Bool_Exp;
}>;

export type RemoveZoomCustomBackgroundModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomCustomBackground?: { __typename?: 'zoomCustomBackground_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomGlobalCustomBackgroundByIdQueryVariables = Exact<{
  zoomCustomBackgroundId: Scalars['uuid'];
}>;

export type QueryZoomGlobalCustomBackgroundByIdQuery = {
  __typename?: 'query_root';
  zoomCustomBackground_by_pk?: {
    __typename?: 'zoomCustomBackground';
    id: string;
    url: string;
    createdAt: string;
    isGlobal: boolean;
    isHoliday: boolean;
    caption?: string | null;
  } | null;
};

export type QueryZoomGlobalCustomBackgroundObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomCustomBackground_Select_Column> | ZoomCustomBackground_Select_Column>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCustomBackground_Order_By> | ZoomCustomBackground_Order_By>;
}>;

export type QueryZoomGlobalCustomBackgroundObjectsQuery = {
  __typename?: 'query_root';
  zoomCustomBackground: Array<{
    __typename?: 'zoomCustomBackground';
    id: string;
    url: string;
    createdAt: string;
    isGlobal: boolean;
    isHoliday: boolean;
    caption?: string | null;
  }>;
};

export type SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionVariables = Exact<{
  zoomCustomBackgroundId: Scalars['uuid'];
}>;

export type SubscribeToZoomGlobalCustomBackgroundByIdSubscription = {
  __typename?: 'subscription_root';
  zoomCustomBackground_by_pk?: {
    __typename?: 'zoomCustomBackground';
    id: string;
    url: string;
    createdAt: string;
    isGlobal: boolean;
    isHoliday: boolean;
    caption?: string | null;
  } | null;
};

export type SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomCustomBackground_Select_Column> | ZoomCustomBackground_Select_Column>;
  where?: InputMaybe<ZoomCustomBackground_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomCustomBackground_Order_By> | ZoomCustomBackground_Order_By>;
}>;

export type SubscribeToZoomGlobalCustomBackgroundObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomCustomBackground: Array<{
    __typename?: 'zoomCustomBackground';
    id: string;
    url: string;
    createdAt: string;
    isGlobal: boolean;
    isHoliday: boolean;
    caption?: string | null;
  }>;
};

export type InsertZoomGlobalCustomBackgroundMutationVariables = Exact<{
  objects: Array<ZoomCustomBackground_Insert_Input> | ZoomCustomBackground_Insert_Input;
}>;

export type InsertZoomGlobalCustomBackgroundMutation = {
  __typename?: 'mutation_root';
  insert_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      createdAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
    }>;
  } | null;
};

export type InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomCustomBackground_Insert_Input> | ZoomCustomBackground_Insert_Input;
  onConflict?: InputMaybe<ZoomCustomBackground_On_Conflict>;
}>;

export type InsertZoomGlobalCustomBackgroundWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      createdAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
    }>;
  } | null;
};

export type UpdateZoomGlobalCustomBackgroundByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomCustomBackground_Set_Input>;
}>;

export type UpdateZoomGlobalCustomBackgroundByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      createdAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
    }>;
  } | null;
};

export type UpdateZoomGlobalCustomBackgroundMutationVariables = Exact<{
  set?: InputMaybe<ZoomCustomBackground_Set_Input>;
  where: ZoomCustomBackground_Bool_Exp;
}>;

export type UpdateZoomGlobalCustomBackgroundMutation = {
  __typename?: 'mutation_root';
  update_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      createdAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
    }>;
  } | null;
};

export type QueryZoomConnectionRequestByIdQueryVariables = Exact<{
  zoomConnectionRequestId: Scalars['uuid'];
}>;

export type QueryZoomConnectionRequestByIdQuery = {
  __typename?: 'query_root';
  zoomConnectionRequest_by_pk?: {
    __typename?: 'zoomConnectionRequest';
    id: string;
    zoomUserId: string;
    email: string;
    requestStatus: string;
  } | null;
};

export type QueryZoomConnectionRequestObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomConnectionRequest_Select_Column> | ZoomConnectionRequest_Select_Column>;
  where?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequest_Order_By> | ZoomConnectionRequest_Order_By>;
}>;

export type QueryZoomConnectionRequestObjectsQuery = {
  __typename?: 'query_root';
  zoomConnectionRequest: Array<{
    __typename?: 'zoomConnectionRequest';
    id: string;
    zoomUserId: string;
    email: string;
    requestStatus: string;
  }>;
};

export type SubscribeToZoomConnectionRequestByIdSubscriptionVariables = Exact<{
  zoomConnectionRequestId: Scalars['uuid'];
}>;

export type SubscribeToZoomConnectionRequestByIdSubscription = {
  __typename?: 'subscription_root';
  zoomConnectionRequest_by_pk?: {
    __typename?: 'zoomConnectionRequest';
    id: string;
    zoomUserId: string;
    email: string;
    requestStatus: string;
  } | null;
};

export type SubscribeToZoomConnectionRequestObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomConnectionRequest_Select_Column> | ZoomConnectionRequest_Select_Column>;
  where?: InputMaybe<ZoomConnectionRequest_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequest_Order_By> | ZoomConnectionRequest_Order_By>;
}>;

export type SubscribeToZoomConnectionRequestObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomConnectionRequest: Array<{
    __typename?: 'zoomConnectionRequest';
    id: string;
    zoomUserId: string;
    email: string;
    requestStatus: string;
  }>;
};

export type InsertZoomConnectionRequestMutationVariables = Exact<{
  objects: Array<ZoomConnectionRequest_Insert_Input> | ZoomConnectionRequest_Insert_Input;
}>;

export type InsertZoomConnectionRequestMutation = {
  __typename?: 'mutation_root';
  insert_zoomConnectionRequest?: {
    __typename?: 'zoomConnectionRequest_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConnectionRequest';
      id: string;
      zoomUserId: string;
      email: string;
      requestStatus: string;
    }>;
  } | null;
};

export type InsertZoomConnectionRequestWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomConnectionRequest_Insert_Input> | ZoomConnectionRequest_Insert_Input;
  onConflict?: InputMaybe<ZoomConnectionRequest_On_Conflict>;
}>;

export type InsertZoomConnectionRequestWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomConnectionRequest?: {
    __typename?: 'zoomConnectionRequest_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConnectionRequest';
      id: string;
      zoomUserId: string;
      email: string;
      requestStatus: string;
    }>;
  } | null;
};

export type UpdateZoomConnectionRequestByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomConnectionRequest_Set_Input>;
}>;

export type UpdateZoomConnectionRequestByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomConnectionRequest?: {
    __typename?: 'zoomConnectionRequest_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConnectionRequest';
      id: string;
      zoomUserId: string;
      email: string;
      requestStatus: string;
    }>;
  } | null;
};

export type UpdateZoomConnectionRequestMutationVariables = Exact<{
  set?: InputMaybe<ZoomConnectionRequest_Set_Input>;
  where: ZoomConnectionRequest_Bool_Exp;
}>;

export type UpdateZoomConnectionRequestMutation = {
  __typename?: 'mutation_root';
  update_zoomConnectionRequest?: {
    __typename?: 'zoomConnectionRequest_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConnectionRequest';
      id: string;
      zoomUserId: string;
      email: string;
      requestStatus: string;
    }>;
  } | null;
};

export type RemoveZoomConnectionRequestModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomConnectionRequestModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomConnectionRequest?: {
    __typename?: 'zoomConnectionRequest_mutation_response';
    affected_rows: number;
  } | null;
};

export type RemoveZoomConnectionRequestModelMutationVariables = Exact<{
  where: ZoomConnectionRequest_Bool_Exp;
}>;

export type RemoveZoomConnectionRequestModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomConnectionRequest?: {
    __typename?: 'zoomConnectionRequest_mutation_response';
    affected_rows: number;
  } | null;
};

export type QueryZoomConnectionRequestReminderByIdQueryVariables = Exact<{
  zoomConnectionRequestReminderId: Scalars['uuid'];
}>;

export type QueryZoomConnectionRequestReminderByIdQuery = {
  __typename?: 'query_root';
  zoomConnectionRequestReminder_by_pk?: {
    __typename?: 'zoomConnectionRequestReminder';
    id: string;
    zoomUserId: string;
    zoomConnectionRequestId: string;
    cadence: number;
    createdAt: string;
  } | null;
};

export type QueryZoomConnectionRequestReminderObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<
    Array<ZoomConnectionRequestReminder_Select_Column> | ZoomConnectionRequestReminder_Select_Column
  >;
  where?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequestReminder_Order_By> | ZoomConnectionRequestReminder_Order_By>;
}>;

export type QueryZoomConnectionRequestReminderObjectsQuery = {
  __typename?: 'query_root';
  zoomConnectionRequestReminder: Array<{
    __typename?: 'zoomConnectionRequestReminder';
    id: string;
    zoomUserId: string;
    zoomConnectionRequestId: string;
    cadence: number;
    createdAt: string;
  }>;
};

export type SubscribeToZoomConnectionRequestReminderByIdSubscriptionVariables = Exact<{
  zoomConnectionRequestReminderId: Scalars['uuid'];
}>;

export type SubscribeToZoomConnectionRequestReminderByIdSubscription = {
  __typename?: 'subscription_root';
  zoomConnectionRequestReminder_by_pk?: {
    __typename?: 'zoomConnectionRequestReminder';
    id: string;
    zoomUserId: string;
    zoomConnectionRequestId: string;
    cadence: number;
    createdAt: string;
  } | null;
};

export type SubscribeToZoomConnectionRequestReminderObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<
    Array<ZoomConnectionRequestReminder_Select_Column> | ZoomConnectionRequestReminder_Select_Column
  >;
  where?: InputMaybe<ZoomConnectionRequestReminder_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConnectionRequestReminder_Order_By> | ZoomConnectionRequestReminder_Order_By>;
}>;

export type SubscribeToZoomConnectionRequestReminderObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomConnectionRequestReminder: Array<{
    __typename?: 'zoomConnectionRequestReminder';
    id: string;
    zoomUserId: string;
    zoomConnectionRequestId: string;
    cadence: number;
    createdAt: string;
  }>;
};

export type InsertZoomConnectionRequestReminderMutationVariables = Exact<{
  objects: Array<ZoomConnectionRequestReminder_Insert_Input> | ZoomConnectionRequestReminder_Insert_Input;
}>;

export type InsertZoomConnectionRequestReminderMutation = {
  __typename?: 'mutation_root';
  insert_zoomConnectionRequestReminder?: {
    __typename?: 'zoomConnectionRequestReminder_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConnectionRequestReminder';
      id: string;
      zoomUserId: string;
      zoomConnectionRequestId: string;
      cadence: number;
      createdAt: string;
    }>;
  } | null;
};

export type InsertZoomConnectionRequestReminderWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomConnectionRequestReminder_Insert_Input> | ZoomConnectionRequestReminder_Insert_Input;
  onConflict?: InputMaybe<ZoomConnectionRequestReminder_On_Conflict>;
}>;

export type InsertZoomConnectionRequestReminderWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomConnectionRequestReminder?: {
    __typename?: 'zoomConnectionRequestReminder_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConnectionRequestReminder';
      id: string;
      zoomUserId: string;
      zoomConnectionRequestId: string;
      cadence: number;
      createdAt: string;
    }>;
  } | null;
};

export type UpdateZoomConnectionRequestReminderByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomConnectionRequestReminder_Set_Input>;
}>;

export type UpdateZoomConnectionRequestReminderByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomConnectionRequestReminder?: {
    __typename?: 'zoomConnectionRequestReminder_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConnectionRequestReminder';
      id: string;
      zoomUserId: string;
      zoomConnectionRequestId: string;
      cadence: number;
      createdAt: string;
    }>;
  } | null;
};

export type UpdateZoomConnectionRequestReminderMutationVariables = Exact<{
  set?: InputMaybe<ZoomConnectionRequestReminder_Set_Input>;
  where: ZoomConnectionRequestReminder_Bool_Exp;
}>;

export type UpdateZoomConnectionRequestReminderMutation = {
  __typename?: 'mutation_root';
  update_zoomConnectionRequestReminder?: {
    __typename?: 'zoomConnectionRequestReminder_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConnectionRequestReminder';
      id: string;
      zoomUserId: string;
      zoomConnectionRequestId: string;
      cadence: number;
      createdAt: string;
    }>;
  } | null;
};

export type RemoveZoomConnectionRequestReminderModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomConnectionRequestReminderModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomConnectionRequestReminder?: {
    __typename?: 'zoomConnectionRequestReminder_mutation_response';
    affected_rows: number;
  } | null;
};

export type RemoveZoomConnectionRequestReminderModelMutationVariables = Exact<{
  where: ZoomConnectionRequestReminder_Bool_Exp;
}>;

export type RemoveZoomConnectionRequestReminderModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomConnectionRequestReminder?: {
    __typename?: 'zoomConnectionRequestReminder_mutation_response';
    affected_rows: number;
  } | null;
};

export type QueryZoomApprovedDomainByIdQueryVariables = Exact<{
  zoomApprovedDomainId: Scalars['uuid'];
}>;

export type QueryZoomApprovedDomainByIdQuery = {
  __typename?: 'query_root';
  zoomApprovedDomain_by_pk?: { __typename?: 'zoomApprovedDomain'; id: string; domain: string; status: string } | null;
};

export type QueryZoomApprovedDomainObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomApprovedDomain_Select_Column> | ZoomApprovedDomain_Select_Column>;
  where?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomApprovedDomain_Order_By> | ZoomApprovedDomain_Order_By>;
}>;

export type QueryZoomApprovedDomainObjectsQuery = {
  __typename?: 'query_root';
  zoomApprovedDomain: Array<{ __typename?: 'zoomApprovedDomain'; id: string; domain: string; status: string }>;
};

export type SubscribeToZoomApprovedDomainByIdSubscriptionVariables = Exact<{
  zoomApprovedDomainId: Scalars['uuid'];
}>;

export type SubscribeToZoomApprovedDomainByIdSubscription = {
  __typename?: 'subscription_root';
  zoomApprovedDomain_by_pk?: { __typename?: 'zoomApprovedDomain'; id: string; domain: string; status: string } | null;
};

export type SubscribeToZoomApprovedDomainObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomApprovedDomain_Select_Column> | ZoomApprovedDomain_Select_Column>;
  where?: InputMaybe<ZoomApprovedDomain_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomApprovedDomain_Order_By> | ZoomApprovedDomain_Order_By>;
}>;

export type SubscribeToZoomApprovedDomainObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomApprovedDomain: Array<{ __typename?: 'zoomApprovedDomain'; id: string; domain: string; status: string }>;
};

export type InsertZoomApprovedDomainMutationVariables = Exact<{
  objects: Array<ZoomApprovedDomain_Insert_Input> | ZoomApprovedDomain_Insert_Input;
}>;

export type InsertZoomApprovedDomainMutation = {
  __typename?: 'mutation_root';
  insert_zoomApprovedDomain?: {
    __typename?: 'zoomApprovedDomain_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'zoomApprovedDomain'; id: string; domain: string; status: string }>;
  } | null;
};

export type InsertZoomApprovedDomainWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomApprovedDomain_Insert_Input> | ZoomApprovedDomain_Insert_Input;
  onConflict?: InputMaybe<ZoomApprovedDomain_On_Conflict>;
}>;

export type InsertZoomApprovedDomainWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomApprovedDomain?: {
    __typename?: 'zoomApprovedDomain_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'zoomApprovedDomain'; id: string; domain: string; status: string }>;
  } | null;
};

export type UpdateZoomApprovedDomainByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomApprovedDomain_Set_Input>;
}>;

export type UpdateZoomApprovedDomainByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomApprovedDomain?: {
    __typename?: 'zoomApprovedDomain_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'zoomApprovedDomain'; id: string; domain: string; status: string }>;
  } | null;
};

export type UpdateZoomApprovedDomainMutationVariables = Exact<{
  set?: InputMaybe<ZoomApprovedDomain_Set_Input>;
  where: ZoomApprovedDomain_Bool_Exp;
}>;

export type UpdateZoomApprovedDomainMutation = {
  __typename?: 'mutation_root';
  update_zoomApprovedDomain?: {
    __typename?: 'zoomApprovedDomain_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'zoomApprovedDomain'; id: string; domain: string; status: string }>;
  } | null;
};

export type RemoveZoomApprovedDomainModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomApprovedDomainModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomApprovedDomain?: { __typename?: 'zoomApprovedDomain_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomApprovedDomainModelMutationVariables = Exact<{
  where: ZoomApprovedDomain_Bool_Exp;
}>;

export type RemoveZoomApprovedDomainModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomApprovedDomain?: { __typename?: 'zoomApprovedDomain_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomConversationStarterAnswerByIdQueryVariables = Exact<{
  zoomConversationStarterAnswerId: Scalars['uuid'];
}>;

export type QueryZoomConversationStarterAnswerByIdQuery = {
  __typename?: 'query_root';
  zoomConversationStarterAnswer_by_pk?: {
    __typename?: 'zoomConversationStarterAnswer';
    id: string;
    answer?: string | null;
    createdAt: string;
    updatedAt: string;
    answeredByZoomUserId: string;
    zoomConversationStarterPromptId: string;
    addedToVideoAt?: string | null;
    imageUrl?: string | null;
    cloudinaryPublicId?: string | null;
  } | null;
};

export type QueryZoomConversationStarterAnswerObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<
    Array<ZoomConversationStarterAnswer_Select_Column> | ZoomConversationStarterAnswer_Select_Column
  >;
  where?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterAnswer_Order_By> | ZoomConversationStarterAnswer_Order_By>;
}>;

export type QueryZoomConversationStarterAnswerObjectsQuery = {
  __typename?: 'query_root';
  zoomConversationStarterAnswer: Array<{
    __typename?: 'zoomConversationStarterAnswer';
    id: string;
    answer?: string | null;
    createdAt: string;
    updatedAt: string;
    answeredByZoomUserId: string;
    zoomConversationStarterPromptId: string;
    addedToVideoAt?: string | null;
    imageUrl?: string | null;
    cloudinaryPublicId?: string | null;
  }>;
};

export type SubscribeToZoomConversationStarterAnswerByIdSubscriptionVariables = Exact<{
  zoomConversationStarterAnswerId: Scalars['uuid'];
}>;

export type SubscribeToZoomConversationStarterAnswerByIdSubscription = {
  __typename?: 'subscription_root';
  zoomConversationStarterAnswer_by_pk?: {
    __typename?: 'zoomConversationStarterAnswer';
    id: string;
    answer?: string | null;
    createdAt: string;
    updatedAt: string;
    answeredByZoomUserId: string;
    zoomConversationStarterPromptId: string;
    addedToVideoAt?: string | null;
    imageUrl?: string | null;
    cloudinaryPublicId?: string | null;
  } | null;
};

export type SubscribeToZoomConversationStarterAnswerObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<
    Array<ZoomConversationStarterAnswer_Select_Column> | ZoomConversationStarterAnswer_Select_Column
  >;
  where?: InputMaybe<ZoomConversationStarterAnswer_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterAnswer_Order_By> | ZoomConversationStarterAnswer_Order_By>;
}>;

export type SubscribeToZoomConversationStarterAnswerObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomConversationStarterAnswer: Array<{
    __typename?: 'zoomConversationStarterAnswer';
    id: string;
    answer?: string | null;
    createdAt: string;
    updatedAt: string;
    answeredByZoomUserId: string;
    zoomConversationStarterPromptId: string;
    addedToVideoAt?: string | null;
    imageUrl?: string | null;
    cloudinaryPublicId?: string | null;
  }>;
};

export type InsertZoomConversationStarterAnswerMutationVariables = Exact<{
  objects: Array<ZoomConversationStarterAnswer_Insert_Input> | ZoomConversationStarterAnswer_Insert_Input;
}>;

export type InsertZoomConversationStarterAnswerMutation = {
  __typename?: 'mutation_root';
  insert_zoomConversationStarterAnswer?: {
    __typename?: 'zoomConversationStarterAnswer_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConversationStarterAnswer';
      id: string;
      answer?: string | null;
      createdAt: string;
      updatedAt: string;
      answeredByZoomUserId: string;
      zoomConversationStarterPromptId: string;
      addedToVideoAt?: string | null;
      imageUrl?: string | null;
      cloudinaryPublicId?: string | null;
    }>;
  } | null;
};

export type InsertZoomConversationStarterAnswerWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomConversationStarterAnswer_Insert_Input> | ZoomConversationStarterAnswer_Insert_Input;
  onConflict?: InputMaybe<ZoomConversationStarterAnswer_On_Conflict>;
}>;

export type InsertZoomConversationStarterAnswerWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomConversationStarterAnswer?: {
    __typename?: 'zoomConversationStarterAnswer_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConversationStarterAnswer';
      id: string;
      answer?: string | null;
      createdAt: string;
      updatedAt: string;
      answeredByZoomUserId: string;
      zoomConversationStarterPromptId: string;
      addedToVideoAt?: string | null;
      imageUrl?: string | null;
      cloudinaryPublicId?: string | null;
    }>;
  } | null;
};

export type UpdateZoomConversationStarterAnswerByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomConversationStarterAnswer_Set_Input>;
}>;

export type UpdateZoomConversationStarterAnswerByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomConversationStarterAnswer?: {
    __typename?: 'zoomConversationStarterAnswer_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConversationStarterAnswer';
      id: string;
      answer?: string | null;
      createdAt: string;
      updatedAt: string;
      answeredByZoomUserId: string;
      zoomConversationStarterPromptId: string;
      addedToVideoAt?: string | null;
      imageUrl?: string | null;
      cloudinaryPublicId?: string | null;
    }>;
  } | null;
};

export type UpdateZoomConversationStarterAnswerMutationVariables = Exact<{
  set?: InputMaybe<ZoomConversationStarterAnswer_Set_Input>;
  where: ZoomConversationStarterAnswer_Bool_Exp;
}>;

export type UpdateZoomConversationStarterAnswerMutation = {
  __typename?: 'mutation_root';
  update_zoomConversationStarterAnswer?: {
    __typename?: 'zoomConversationStarterAnswer_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConversationStarterAnswer';
      id: string;
      answer?: string | null;
      createdAt: string;
      updatedAt: string;
      answeredByZoomUserId: string;
      zoomConversationStarterPromptId: string;
      addedToVideoAt?: string | null;
      imageUrl?: string | null;
      cloudinaryPublicId?: string | null;
    }>;
  } | null;
};

export type RemoveZoomConversationStarterAnswerModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomConversationStarterAnswerModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomConversationStarterAnswer?: {
    __typename?: 'zoomConversationStarterAnswer_mutation_response';
    affected_rows: number;
  } | null;
};

export type RemoveZoomConversationStarterAnswerModelMutationVariables = Exact<{
  where: ZoomConversationStarterAnswer_Bool_Exp;
}>;

export type RemoveZoomConversationStarterAnswerModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomConversationStarterAnswer?: {
    __typename?: 'zoomConversationStarterAnswer_mutation_response';
    affected_rows: number;
  } | null;
};

export type QueryZoomConversationStarterPromptByIdQueryVariables = Exact<{
  zoomConversationStarterPromptId: Scalars['uuid'];
}>;

export type QueryZoomConversationStarterPromptByIdQuery = {
  __typename?: 'query_root';
  zoomConversationStarterPrompt_by_pk?: {
    __typename?: 'zoomConversationStarterPrompt';
    id: string;
    unansweredPrompt: string;
    answeredPrompt: string;
    placeholderAnswer?: string | null;
    placeholderImageUrl?: string | null;
    createdAt: string;
    updatedAt: string;
    createdByZoomUserId?: string | null;
    isActive: boolean;
    fromTag?: string | null;
  } | null;
};

export type QueryZoomConversationStarterPromptObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<
    Array<ZoomConversationStarterPrompt_Select_Column> | ZoomConversationStarterPrompt_Select_Column
  >;
  where?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterPrompt_Order_By> | ZoomConversationStarterPrompt_Order_By>;
}>;

export type QueryZoomConversationStarterPromptObjectsQuery = {
  __typename?: 'query_root';
  zoomConversationStarterPrompt: Array<{
    __typename?: 'zoomConversationStarterPrompt';
    id: string;
    unansweredPrompt: string;
    answeredPrompt: string;
    placeholderAnswer?: string | null;
    placeholderImageUrl?: string | null;
    createdAt: string;
    updatedAt: string;
    createdByZoomUserId?: string | null;
    isActive: boolean;
    fromTag?: string | null;
  }>;
};

export type SubscribeToZoomConversationStarterPromptByIdSubscriptionVariables = Exact<{
  zoomConversationStarterPromptId: Scalars['uuid'];
}>;

export type SubscribeToZoomConversationStarterPromptByIdSubscription = {
  __typename?: 'subscription_root';
  zoomConversationStarterPrompt_by_pk?: {
    __typename?: 'zoomConversationStarterPrompt';
    id: string;
    unansweredPrompt: string;
    answeredPrompt: string;
    placeholderAnswer?: string | null;
    placeholderImageUrl?: string | null;
    createdAt: string;
    updatedAt: string;
    createdByZoomUserId?: string | null;
    isActive: boolean;
    fromTag?: string | null;
  } | null;
};

export type SubscribeToZoomConversationStarterPromptObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<
    Array<ZoomConversationStarterPrompt_Select_Column> | ZoomConversationStarterPrompt_Select_Column
  >;
  where?: InputMaybe<ZoomConversationStarterPrompt_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomConversationStarterPrompt_Order_By> | ZoomConversationStarterPrompt_Order_By>;
}>;

export type SubscribeToZoomConversationStarterPromptObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomConversationStarterPrompt: Array<{
    __typename?: 'zoomConversationStarterPrompt';
    id: string;
    unansweredPrompt: string;
    answeredPrompt: string;
    placeholderAnswer?: string | null;
    placeholderImageUrl?: string | null;
    createdAt: string;
    updatedAt: string;
    createdByZoomUserId?: string | null;
    isActive: boolean;
    fromTag?: string | null;
  }>;
};

export type InsertZoomConversationStarterPromptMutationVariables = Exact<{
  objects: Array<ZoomConversationStarterPrompt_Insert_Input> | ZoomConversationStarterPrompt_Insert_Input;
}>;

export type InsertZoomConversationStarterPromptMutation = {
  __typename?: 'mutation_root';
  insert_zoomConversationStarterPrompt?: {
    __typename?: 'zoomConversationStarterPrompt_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConversationStarterPrompt';
      id: string;
      unansweredPrompt: string;
      answeredPrompt: string;
      placeholderAnswer?: string | null;
      placeholderImageUrl?: string | null;
      createdAt: string;
      updatedAt: string;
      createdByZoomUserId?: string | null;
      isActive: boolean;
      fromTag?: string | null;
    }>;
  } | null;
};

export type InsertZoomConversationStarterPromptWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomConversationStarterPrompt_Insert_Input> | ZoomConversationStarterPrompt_Insert_Input;
  onConflict?: InputMaybe<ZoomConversationStarterPrompt_On_Conflict>;
}>;

export type InsertZoomConversationStarterPromptWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomConversationStarterPrompt?: {
    __typename?: 'zoomConversationStarterPrompt_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConversationStarterPrompt';
      id: string;
      unansweredPrompt: string;
      answeredPrompt: string;
      placeholderAnswer?: string | null;
      placeholderImageUrl?: string | null;
      createdAt: string;
      updatedAt: string;
      createdByZoomUserId?: string | null;
      isActive: boolean;
      fromTag?: string | null;
    }>;
  } | null;
};

export type UpdateZoomConversationStarterPromptByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomConversationStarterPrompt_Set_Input>;
}>;

export type UpdateZoomConversationStarterPromptByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomConversationStarterPrompt?: {
    __typename?: 'zoomConversationStarterPrompt_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConversationStarterPrompt';
      id: string;
      unansweredPrompt: string;
      answeredPrompt: string;
      placeholderAnswer?: string | null;
      placeholderImageUrl?: string | null;
      createdAt: string;
      updatedAt: string;
      createdByZoomUserId?: string | null;
      isActive: boolean;
      fromTag?: string | null;
    }>;
  } | null;
};

export type UpdateZoomConversationStarterPromptMutationVariables = Exact<{
  set?: InputMaybe<ZoomConversationStarterPrompt_Set_Input>;
  where: ZoomConversationStarterPrompt_Bool_Exp;
}>;

export type UpdateZoomConversationStarterPromptMutation = {
  __typename?: 'mutation_root';
  update_zoomConversationStarterPrompt?: {
    __typename?: 'zoomConversationStarterPrompt_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomConversationStarterPrompt';
      id: string;
      unansweredPrompt: string;
      answeredPrompt: string;
      placeholderAnswer?: string | null;
      placeholderImageUrl?: string | null;
      createdAt: string;
      updatedAt: string;
      createdByZoomUserId?: string | null;
      isActive: boolean;
      fromTag?: string | null;
    }>;
  } | null;
};

export type RemoveZoomConversationStarterPromptModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomConversationStarterPromptModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomConversationStarterPrompt?: {
    __typename?: 'zoomConversationStarterPrompt_mutation_response';
    affected_rows: number;
  } | null;
};

export type RemoveZoomConversationStarterPromptModelMutationVariables = Exact<{
  where: ZoomConversationStarterPrompt_Bool_Exp;
}>;

export type RemoveZoomConversationStarterPromptModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomConversationStarterPrompt?: {
    __typename?: 'zoomConversationStarterPrompt_mutation_response';
    affected_rows: number;
  } | null;
};

export type QueryContentByIdQueryVariables = Exact<{
  contentId: Scalars['uuid'];
}>;

export type QueryContentByIdQuery = {
  __typename?: 'query_root';
  content_by_pk?: {
    __typename?: 'content';
    id: string;
    name: string;
    type?: any | null;
    value: string;
    createdByUserId?: string | null;
    createdAt: string;
    updatedAt: string;
    identifier: any;
    identifierId?: any | null;
    thumbnail?: string | null;
    tags: { [key: string]: any };
    weight: number;
    zoomUser?: {
      __typename?: 'zoomUser';
      id: string;
      firstName: string;
      lastName: string;
      fullNameGenerated?: string | null;
      email: string;
      photoUrl?: string | null;
    } | null;
  } | null;
};

export type QueryContentObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<Content_Select_Column> | Content_Select_Column>;
  where?: InputMaybe<Content_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Order_By> | Content_Order_By>;
}>;

export type QueryContentObjectsQuery = {
  __typename?: 'query_root';
  content: Array<{
    __typename?: 'content';
    id: string;
    name: string;
    type?: any | null;
    value: string;
    createdByUserId?: string | null;
    createdAt: string;
    updatedAt: string;
    identifier: any;
    identifierId?: any | null;
    thumbnail?: string | null;
    tags: { [key: string]: any };
    weight: number;
    zoomUser?: {
      __typename?: 'zoomUser';
      id: string;
      firstName: string;
      lastName: string;
      fullNameGenerated?: string | null;
      email: string;
      photoUrl?: string | null;
    } | null;
  }>;
};

export type SubscribeToContentByIdSubscriptionVariables = Exact<{
  contentId: Scalars['uuid'];
}>;

export type SubscribeToContentByIdSubscription = {
  __typename?: 'subscription_root';
  content_by_pk?: {
    __typename?: 'content';
    id: string;
    name: string;
    type?: any | null;
    value: string;
    createdByUserId?: string | null;
    createdAt: string;
    updatedAt: string;
    identifier: any;
    identifierId?: any | null;
    thumbnail?: string | null;
    tags: { [key: string]: any };
    weight: number;
    zoomUser?: {
      __typename?: 'zoomUser';
      id: string;
      firstName: string;
      lastName: string;
      fullNameGenerated?: string | null;
      email: string;
      photoUrl?: string | null;
    } | null;
  } | null;
};

export type SubscribeToContentObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<Content_Select_Column> | Content_Select_Column>;
  where?: InputMaybe<Content_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Content_Order_By> | Content_Order_By>;
}>;

export type SubscribeToContentObjectsSubscription = {
  __typename?: 'subscription_root';
  content: Array<{
    __typename?: 'content';
    id: string;
    name: string;
    type?: any | null;
    value: string;
    createdByUserId?: string | null;
    createdAt: string;
    updatedAt: string;
    identifier: any;
    identifierId?: any | null;
    thumbnail?: string | null;
    tags: { [key: string]: any };
    weight: number;
    zoomUser?: {
      __typename?: 'zoomUser';
      id: string;
      firstName: string;
      lastName: string;
      fullNameGenerated?: string | null;
      email: string;
      photoUrl?: string | null;
    } | null;
  }>;
};

export type InsertContentMutationVariables = Exact<{
  objects: Array<Content_Insert_Input> | Content_Insert_Input;
}>;

export type InsertContentMutation = {
  __typename?: 'mutation_root';
  insert_content?: {
    __typename?: 'content_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'content';
      id: string;
      name: string;
      type?: any | null;
      value: string;
      createdByUserId?: string | null;
      createdAt: string;
      updatedAt: string;
      identifier: any;
      identifierId?: any | null;
      thumbnail?: string | null;
      tags: { [key: string]: any };
      weight: number;
      zoomUser?: {
        __typename?: 'zoomUser';
        id: string;
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type InsertContentWithOnConflictMutationVariables = Exact<{
  objects: Array<Content_Insert_Input> | Content_Insert_Input;
  onConflict?: InputMaybe<Content_On_Conflict>;
}>;

export type InsertContentWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_content?: {
    __typename?: 'content_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'content';
      id: string;
      name: string;
      type?: any | null;
      value: string;
      createdByUserId?: string | null;
      createdAt: string;
      updatedAt: string;
      identifier: any;
      identifierId?: any | null;
      thumbnail?: string | null;
      tags: { [key: string]: any };
      weight: number;
      zoomUser?: {
        __typename?: 'zoomUser';
        id: string;
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateContentByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<Content_Set_Input>;
}>;

export type UpdateContentByIdMutation = {
  __typename?: 'mutation_root';
  update_content?: {
    __typename?: 'content_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'content';
      id: string;
      name: string;
      type?: any | null;
      value: string;
      createdByUserId?: string | null;
      createdAt: string;
      updatedAt: string;
      identifier: any;
      identifierId?: any | null;
      thumbnail?: string | null;
      tags: { [key: string]: any };
      weight: number;
      zoomUser?: {
        __typename?: 'zoomUser';
        id: string;
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateContentMutationVariables = Exact<{
  set?: InputMaybe<Content_Set_Input>;
  where: Content_Bool_Exp;
}>;

export type UpdateContentMutation = {
  __typename?: 'mutation_root';
  update_content?: {
    __typename?: 'content_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'content';
      id: string;
      name: string;
      type?: any | null;
      value: string;
      createdByUserId?: string | null;
      createdAt: string;
      updatedAt: string;
      identifier: any;
      identifierId?: any | null;
      thumbnail?: string | null;
      tags: { [key: string]: any };
      weight: number;
      zoomUser?: {
        __typename?: 'zoomUser';
        id: string;
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type RemoveContentModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveContentModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_content?: { __typename?: 'content_mutation_response'; affected_rows: number } | null;
};

export type RemoveContentModelMutationVariables = Exact<{
  where: Content_Bool_Exp;
}>;

export type RemoveContentModelMutation = {
  __typename?: 'mutation_root';
  delete_content?: { __typename?: 'content_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomSettingsByIdQueryVariables = Exact<{
  zoomSettingsId: Scalars['Int'];
}>;

export type QueryZoomSettingsByIdQuery = {
  __typename?: 'query_root';
  zoomSettings_by_pk?: {
    __typename?: 'zoomSettings';
    id: number;
    identifier: any;
    identifierId?: any | null;
    createdAt: string;
    updatedAt: string;
    defaultCalendarSignaturesOnForRecurringMeetings: boolean;
    disableBackgroundUpload: boolean;
    disableAiBackground: boolean;
    disableImageUpload: boolean;
    disableCalendarIntegration: boolean;
    disableCalendarSignaturesByDefault: boolean;
    disableNonWarmlyCalendarSignaturesByDefault: boolean;
    disablePublicProfiles: boolean;
    createdByUserId?: string | null;
    disableCalendarPromptOnZoomLogin: boolean;
    userProfileSettings: { [key: string]: any };
    meetingBoostSettings: { [key: string]: any };
    skipExperiments: boolean;
  } | null;
};

export type QueryZoomSettingsObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomSettings_Select_Column> | ZoomSettings_Select_Column>;
  where?: InputMaybe<ZoomSettings_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomSettings_Order_By> | ZoomSettings_Order_By>;
}>;

export type QueryZoomSettingsObjectsQuery = {
  __typename?: 'query_root';
  zoomSettings: Array<{
    __typename?: 'zoomSettings';
    id: number;
    identifier: any;
    identifierId?: any | null;
    createdAt: string;
    updatedAt: string;
    defaultCalendarSignaturesOnForRecurringMeetings: boolean;
    disableBackgroundUpload: boolean;
    disableAiBackground: boolean;
    disableImageUpload: boolean;
    disableCalendarIntegration: boolean;
    disableCalendarSignaturesByDefault: boolean;
    disableNonWarmlyCalendarSignaturesByDefault: boolean;
    disablePublicProfiles: boolean;
    createdByUserId?: string | null;
    disableCalendarPromptOnZoomLogin: boolean;
    userProfileSettings: { [key: string]: any };
    meetingBoostSettings: { [key: string]: any };
    skipExperiments: boolean;
  }>;
};

export type SubscribeToZoomSettingsByIdSubscriptionVariables = Exact<{
  zoomSettingsId: Scalars['Int'];
}>;

export type SubscribeToZoomSettingsByIdSubscription = {
  __typename?: 'subscription_root';
  zoomSettings_by_pk?: {
    __typename?: 'zoomSettings';
    id: number;
    identifier: any;
    identifierId?: any | null;
    createdAt: string;
    updatedAt: string;
    defaultCalendarSignaturesOnForRecurringMeetings: boolean;
    disableBackgroundUpload: boolean;
    disableAiBackground: boolean;
    disableImageUpload: boolean;
    disableCalendarIntegration: boolean;
    disableCalendarSignaturesByDefault: boolean;
    disableNonWarmlyCalendarSignaturesByDefault: boolean;
    disablePublicProfiles: boolean;
    createdByUserId?: string | null;
    disableCalendarPromptOnZoomLogin: boolean;
    userProfileSettings: { [key: string]: any };
    meetingBoostSettings: { [key: string]: any };
    skipExperiments: boolean;
  } | null;
};

export type SubscribeToZoomSettingsObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomSettings_Select_Column> | ZoomSettings_Select_Column>;
  where?: InputMaybe<ZoomSettings_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomSettings_Order_By> | ZoomSettings_Order_By>;
}>;

export type SubscribeToZoomSettingsObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomSettings: Array<{
    __typename?: 'zoomSettings';
    id: number;
    identifier: any;
    identifierId?: any | null;
    createdAt: string;
    updatedAt: string;
    defaultCalendarSignaturesOnForRecurringMeetings: boolean;
    disableBackgroundUpload: boolean;
    disableAiBackground: boolean;
    disableImageUpload: boolean;
    disableCalendarIntegration: boolean;
    disableCalendarSignaturesByDefault: boolean;
    disableNonWarmlyCalendarSignaturesByDefault: boolean;
    disablePublicProfiles: boolean;
    createdByUserId?: string | null;
    disableCalendarPromptOnZoomLogin: boolean;
    userProfileSettings: { [key: string]: any };
    meetingBoostSettings: { [key: string]: any };
    skipExperiments: boolean;
  }>;
};

export type InsertZoomSettingsMutationVariables = Exact<{
  objects: Array<ZoomSettings_Insert_Input> | ZoomSettings_Insert_Input;
}>;

export type InsertZoomSettingsMutation = {
  __typename?: 'mutation_root';
  insert_zoomSettings?: {
    __typename?: 'zoomSettings_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomSettings';
      id: number;
      identifier: any;
      identifierId?: any | null;
      createdAt: string;
      updatedAt: string;
      defaultCalendarSignaturesOnForRecurringMeetings: boolean;
      disableBackgroundUpload: boolean;
      disableAiBackground: boolean;
      disableImageUpload: boolean;
      disableCalendarIntegration: boolean;
      disableCalendarSignaturesByDefault: boolean;
      disableNonWarmlyCalendarSignaturesByDefault: boolean;
      disablePublicProfiles: boolean;
      createdByUserId?: string | null;
      disableCalendarPromptOnZoomLogin: boolean;
      userProfileSettings: { [key: string]: any };
      meetingBoostSettings: { [key: string]: any };
      skipExperiments: boolean;
    }>;
  } | null;
};

export type InsertZoomSettingsWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomSettings_Insert_Input> | ZoomSettings_Insert_Input;
  onConflict?: InputMaybe<ZoomSettings_On_Conflict>;
}>;

export type InsertZoomSettingsWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomSettings?: {
    __typename?: 'zoomSettings_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomSettings';
      id: number;
      identifier: any;
      identifierId?: any | null;
      createdAt: string;
      updatedAt: string;
      defaultCalendarSignaturesOnForRecurringMeetings: boolean;
      disableBackgroundUpload: boolean;
      disableAiBackground: boolean;
      disableImageUpload: boolean;
      disableCalendarIntegration: boolean;
      disableCalendarSignaturesByDefault: boolean;
      disableNonWarmlyCalendarSignaturesByDefault: boolean;
      disablePublicProfiles: boolean;
      createdByUserId?: string | null;
      disableCalendarPromptOnZoomLogin: boolean;
      userProfileSettings: { [key: string]: any };
      meetingBoostSettings: { [key: string]: any };
      skipExperiments: boolean;
    }>;
  } | null;
};

export type UpdateZoomSettingsByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<ZoomSettings_Set_Input>;
}>;

export type UpdateZoomSettingsByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomSettings?: {
    __typename?: 'zoomSettings_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomSettings';
      id: number;
      identifier: any;
      identifierId?: any | null;
      createdAt: string;
      updatedAt: string;
      defaultCalendarSignaturesOnForRecurringMeetings: boolean;
      disableBackgroundUpload: boolean;
      disableAiBackground: boolean;
      disableImageUpload: boolean;
      disableCalendarIntegration: boolean;
      disableCalendarSignaturesByDefault: boolean;
      disableNonWarmlyCalendarSignaturesByDefault: boolean;
      disablePublicProfiles: boolean;
      createdByUserId?: string | null;
      disableCalendarPromptOnZoomLogin: boolean;
      userProfileSettings: { [key: string]: any };
      meetingBoostSettings: { [key: string]: any };
      skipExperiments: boolean;
    }>;
  } | null;
};

export type UpdateZoomSettingsMutationVariables = Exact<{
  set?: InputMaybe<ZoomSettings_Set_Input>;
  where: ZoomSettings_Bool_Exp;
}>;

export type UpdateZoomSettingsMutation = {
  __typename?: 'mutation_root';
  update_zoomSettings?: {
    __typename?: 'zoomSettings_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomSettings';
      id: number;
      identifier: any;
      identifierId?: any | null;
      createdAt: string;
      updatedAt: string;
      defaultCalendarSignaturesOnForRecurringMeetings: boolean;
      disableBackgroundUpload: boolean;
      disableAiBackground: boolean;
      disableImageUpload: boolean;
      disableCalendarIntegration: boolean;
      disableCalendarSignaturesByDefault: boolean;
      disableNonWarmlyCalendarSignaturesByDefault: boolean;
      disablePublicProfiles: boolean;
      createdByUserId?: string | null;
      disableCalendarPromptOnZoomLogin: boolean;
      userProfileSettings: { [key: string]: any };
      meetingBoostSettings: { [key: string]: any };
      skipExperiments: boolean;
    }>;
  } | null;
};

export type RemoveZoomSettingsModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type RemoveZoomSettingsModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomSettings?: { __typename?: 'zoomSettings_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomSettingsModelMutationVariables = Exact<{
  where: ZoomSettings_Bool_Exp;
}>;

export type RemoveZoomSettingsModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomSettings?: { __typename?: 'zoomSettings_mutation_response'; affected_rows: number } | null;
};

export type QueryZoomPresentationByIdQueryVariables = Exact<{
  zoomPresentationId: Scalars['uuid'];
}>;

export type QueryZoomPresentationByIdQuery = {
  __typename?: 'query_root';
  zoomPresentation_by_pk?: {
    __typename?: 'zoomPresentation';
    id: string;
    zoomUserId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    slidesCount?: number | null;
    name?: string | null;
  } | null;
};

export type QueryZoomPresentationObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomPresentation_Select_Column> | ZoomPresentation_Select_Column>;
  where?: InputMaybe<ZoomPresentation_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPresentation_Order_By> | ZoomPresentation_Order_By>;
}>;

export type QueryZoomPresentationObjectsQuery = {
  __typename?: 'query_root';
  zoomPresentation: Array<{
    __typename?: 'zoomPresentation';
    id: string;
    zoomUserId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    slidesCount?: number | null;
    name?: string | null;
  }>;
};

export type SubscribeToZoomPresentationByIdSubscriptionVariables = Exact<{
  zoomPresentationId: Scalars['uuid'];
}>;

export type SubscribeToZoomPresentationByIdSubscription = {
  __typename?: 'subscription_root';
  zoomPresentation_by_pk?: {
    __typename?: 'zoomPresentation';
    id: string;
    zoomUserId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    slidesCount?: number | null;
    name?: string | null;
  } | null;
};

export type SubscribeToZoomPresentationObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomPresentation_Select_Column> | ZoomPresentation_Select_Column>;
  where?: InputMaybe<ZoomPresentation_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomPresentation_Order_By> | ZoomPresentation_Order_By>;
}>;

export type SubscribeToZoomPresentationObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomPresentation: Array<{
    __typename?: 'zoomPresentation';
    id: string;
    zoomUserId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    slidesCount?: number | null;
    name?: string | null;
  }>;
};

export type InsertZoomPresentationMutationVariables = Exact<{
  objects: Array<ZoomPresentation_Insert_Input> | ZoomPresentation_Insert_Input;
}>;

export type InsertZoomPresentationMutation = {
  __typename?: 'mutation_root';
  insert_zoomPresentation?: {
    __typename?: 'zoomPresentation_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomPresentation';
      id: string;
      zoomUserId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      slidesCount?: number | null;
      name?: string | null;
    }>;
  } | null;
};

export type InsertZoomPresentationWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomPresentation_Insert_Input> | ZoomPresentation_Insert_Input;
  onConflict?: InputMaybe<ZoomPresentation_On_Conflict>;
}>;

export type InsertZoomPresentationWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomPresentation?: {
    __typename?: 'zoomPresentation_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomPresentation';
      id: string;
      zoomUserId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      slidesCount?: number | null;
      name?: string | null;
    }>;
  } | null;
};

export type UpdateZoomPresentationByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomPresentation_Set_Input>;
}>;

export type UpdateZoomPresentationByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomPresentation?: {
    __typename?: 'zoomPresentation_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomPresentation';
      id: string;
      zoomUserId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      slidesCount?: number | null;
      name?: string | null;
    }>;
  } | null;
};

export type UpdateZoomPresentationMutationVariables = Exact<{
  set?: InputMaybe<ZoomPresentation_Set_Input>;
  where: ZoomPresentation_Bool_Exp;
}>;

export type UpdateZoomPresentationMutation = {
  __typename?: 'mutation_root';
  update_zoomPresentation?: {
    __typename?: 'zoomPresentation_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomPresentation';
      id: string;
      zoomUserId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      slidesCount?: number | null;
      name?: string | null;
    }>;
  } | null;
};

export type RemoveZoomPresentationModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomPresentationModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomPresentation?: { __typename?: 'zoomPresentation_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomPresentationModelMutationVariables = Exact<{
  where: ZoomPresentation_Bool_Exp;
}>;

export type RemoveZoomPresentationModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomPresentation?: { __typename?: 'zoomPresentation_mutation_response'; affected_rows: number } | null;
};

export type QueryGrowthUserReferralContactByIdQueryVariables = Exact<{
  growthUserReferralContactId: Scalars['uuid'];
}>;

export type QueryGrowthUserReferralContactByIdQuery = {
  __typename?: 'query_root';
  growthUserReferralContact_by_pk?: {
    __typename?: 'growthUserReferralContact';
    id: string;
    referrerId: string;
    referrerEmail: string;
    referrerFirstName?: string | null;
    referrerLastName?: string | null;
    refereeEmail: string;
    createdAt: string;
    referredOn: string;
  } | null;
};

export type QueryGrowthUserReferralContactObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<GrowthUserReferralContact_Select_Column> | GrowthUserReferralContact_Select_Column>;
  where?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthUserReferralContact_Order_By> | GrowthUserReferralContact_Order_By>;
}>;

export type QueryGrowthUserReferralContactObjectsQuery = {
  __typename?: 'query_root';
  growthUserReferralContact: Array<{
    __typename?: 'growthUserReferralContact';
    id: string;
    referrerId: string;
    referrerEmail: string;
    referrerFirstName?: string | null;
    referrerLastName?: string | null;
    refereeEmail: string;
    createdAt: string;
    referredOn: string;
  }>;
};

export type SubscribeToGrowthUserReferralContactByIdSubscriptionVariables = Exact<{
  growthUserReferralContactId: Scalars['uuid'];
}>;

export type SubscribeToGrowthUserReferralContactByIdSubscription = {
  __typename?: 'subscription_root';
  growthUserReferralContact_by_pk?: {
    __typename?: 'growthUserReferralContact';
    id: string;
    referrerId: string;
    referrerEmail: string;
    referrerFirstName?: string | null;
    referrerLastName?: string | null;
    refereeEmail: string;
    createdAt: string;
    referredOn: string;
  } | null;
};

export type SubscribeToGrowthUserReferralContactObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<GrowthUserReferralContact_Select_Column> | GrowthUserReferralContact_Select_Column>;
  where?: InputMaybe<GrowthUserReferralContact_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GrowthUserReferralContact_Order_By> | GrowthUserReferralContact_Order_By>;
}>;

export type SubscribeToGrowthUserReferralContactObjectsSubscription = {
  __typename?: 'subscription_root';
  growthUserReferralContact: Array<{
    __typename?: 'growthUserReferralContact';
    id: string;
    referrerId: string;
    referrerEmail: string;
    referrerFirstName?: string | null;
    referrerLastName?: string | null;
    refereeEmail: string;
    createdAt: string;
    referredOn: string;
  }>;
};

export type InsertGrowthUserReferralContactMutationVariables = Exact<{
  objects: Array<GrowthUserReferralContact_Insert_Input> | GrowthUserReferralContact_Insert_Input;
}>;

export type InsertGrowthUserReferralContactMutation = {
  __typename?: 'mutation_root';
  insert_growthUserReferralContact?: {
    __typename?: 'growthUserReferralContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'growthUserReferralContact';
      id: string;
      referrerId: string;
      referrerEmail: string;
      referrerFirstName?: string | null;
      referrerLastName?: string | null;
      refereeEmail: string;
      createdAt: string;
      referredOn: string;
    }>;
  } | null;
};

export type InsertGrowthUserReferralContactWithOnConflictMutationVariables = Exact<{
  objects: Array<GrowthUserReferralContact_Insert_Input> | GrowthUserReferralContact_Insert_Input;
  onConflict?: InputMaybe<GrowthUserReferralContact_On_Conflict>;
}>;

export type InsertGrowthUserReferralContactWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_growthUserReferralContact?: {
    __typename?: 'growthUserReferralContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'growthUserReferralContact';
      id: string;
      referrerId: string;
      referrerEmail: string;
      referrerFirstName?: string | null;
      referrerLastName?: string | null;
      refereeEmail: string;
      createdAt: string;
      referredOn: string;
    }>;
  } | null;
};

export type UpdateGrowthUserReferralContactByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<GrowthUserReferralContact_Set_Input>;
}>;

export type UpdateGrowthUserReferralContactByIdMutation = {
  __typename?: 'mutation_root';
  update_growthUserReferralContact?: {
    __typename?: 'growthUserReferralContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'growthUserReferralContact';
      id: string;
      referrerId: string;
      referrerEmail: string;
      referrerFirstName?: string | null;
      referrerLastName?: string | null;
      refereeEmail: string;
      createdAt: string;
      referredOn: string;
    }>;
  } | null;
};

export type UpdateGrowthUserReferralContactMutationVariables = Exact<{
  set?: InputMaybe<GrowthUserReferralContact_Set_Input>;
  where: GrowthUserReferralContact_Bool_Exp;
}>;

export type UpdateGrowthUserReferralContactMutation = {
  __typename?: 'mutation_root';
  update_growthUserReferralContact?: {
    __typename?: 'growthUserReferralContact_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'growthUserReferralContact';
      id: string;
      referrerId: string;
      referrerEmail: string;
      referrerFirstName?: string | null;
      referrerLastName?: string | null;
      refereeEmail: string;
      createdAt: string;
      referredOn: string;
    }>;
  } | null;
};

export type RemoveGrowthUserReferralContactModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveGrowthUserReferralContactModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_growthUserReferralContact?: {
    __typename?: 'growthUserReferralContact_mutation_response';
    affected_rows: number;
  } | null;
};

export type RemoveGrowthUserReferralContactModelMutationVariables = Exact<{
  where: GrowthUserReferralContact_Bool_Exp;
}>;

export type RemoveGrowthUserReferralContactModelMutation = {
  __typename?: 'mutation_root';
  delete_growthUserReferralContact?: {
    __typename?: 'growthUserReferralContact_mutation_response';
    affected_rows: number;
  } | null;
};

export type QueryLayersEventsByIdQueryVariables = Exact<{
  zoomLayersEventsId: Scalars['uuid'];
}>;

export type QueryLayersEventsByIdQuery = {
  __typename?: 'query_root';
  zoomLayersEvents_by_pk?: {
    __typename?: 'zoomLayersEvents';
    id: string;
    createdAt: any;
    eventData: { [key: string]: any };
    zoomMeetingID: string;
  } | null;
};

export type QueryLayersEventsObjectsQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomLayersEvents_Select_Column> | ZoomLayersEvents_Select_Column>;
  where?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomLayersEvents_Order_By> | ZoomLayersEvents_Order_By>;
}>;

export type QueryLayersEventsObjectsQuery = {
  __typename?: 'query_root';
  zoomLayersEvents: Array<{
    __typename?: 'zoomLayersEvents';
    id: string;
    createdAt: any;
    eventData: { [key: string]: any };
    zoomMeetingID: string;
  }>;
};

export type SubscribeToLayersEventsByIdSubscriptionVariables = Exact<{
  zoomLayersEventsId: Scalars['uuid'];
}>;

export type SubscribeToLayersEventsByIdSubscription = {
  __typename?: 'subscription_root';
  zoomLayersEvents_by_pk?: {
    __typename?: 'zoomLayersEvents';
    id: string;
    createdAt: any;
    eventData: { [key: string]: any };
    zoomMeetingID: string;
  } | null;
};

export type SubscribeToLayersEventsObjectsSubscriptionVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomLayersEvents_Select_Column> | ZoomLayersEvents_Select_Column>;
  where?: InputMaybe<ZoomLayersEvents_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomLayersEvents_Order_By> | ZoomLayersEvents_Order_By>;
}>;

export type SubscribeToLayersEventsObjectsSubscription = {
  __typename?: 'subscription_root';
  zoomLayersEvents: Array<{
    __typename?: 'zoomLayersEvents';
    id: string;
    createdAt: any;
    eventData: { [key: string]: any };
    zoomMeetingID: string;
  }>;
};

export type InsertLayersEventsMutationVariables = Exact<{
  objects: Array<ZoomLayersEvents_Insert_Input> | ZoomLayersEvents_Insert_Input;
}>;

export type InsertLayersEventsMutation = {
  __typename?: 'mutation_root';
  insert_zoomLayersEvents?: {
    __typename?: 'zoomLayersEvents_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomLayersEvents';
      id: string;
      createdAt: any;
      eventData: { [key: string]: any };
      zoomMeetingID: string;
    }>;
  } | null;
};

export type InsertLayersEventsWithOnConflictMutationVariables = Exact<{
  objects: Array<ZoomLayersEvents_Insert_Input> | ZoomLayersEvents_Insert_Input;
  onConflict?: InputMaybe<ZoomLayersEvents_On_Conflict>;
}>;

export type InsertLayersEventsWithOnConflictMutation = {
  __typename?: 'mutation_root';
  insert_zoomLayersEvents?: {
    __typename?: 'zoomLayersEvents_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomLayersEvents';
      id: string;
      createdAt: any;
      eventData: { [key: string]: any };
      zoomMeetingID: string;
    }>;
  } | null;
};

export type UpdateLayersEventsByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  set?: InputMaybe<ZoomLayersEvents_Set_Input>;
}>;

export type UpdateLayersEventsByIdMutation = {
  __typename?: 'mutation_root';
  update_zoomLayersEvents?: {
    __typename?: 'zoomLayersEvents_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomLayersEvents';
      id: string;
      createdAt: any;
      eventData: { [key: string]: any };
      zoomMeetingID: string;
    }>;
  } | null;
};

export type UpdateLayersEventsMutationVariables = Exact<{
  set?: InputMaybe<ZoomLayersEvents_Set_Input>;
  where: ZoomLayersEvents_Bool_Exp;
}>;

export type UpdateLayersEventsMutation = {
  __typename?: 'mutation_root';
  update_zoomLayersEvents?: {
    __typename?: 'zoomLayersEvents_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomLayersEvents';
      id: string;
      createdAt: any;
      eventData: { [key: string]: any };
      zoomMeetingID: string;
    }>;
  } | null;
};

export type RemoveZoomLayersEventsModelByIdMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type RemoveZoomLayersEventsModelByIdMutation = {
  __typename?: 'mutation_root';
  delete_zoomLayersEvents?: { __typename?: 'zoomLayersEvents_mutation_response'; affected_rows: number } | null;
};

export type RemoveZoomLayersEventsModelMutationVariables = Exact<{
  where: ZoomLayersEvents_Bool_Exp;
}>;

export type RemoveZoomLayersEventsModelMutation = {
  __typename?: 'mutation_root';
  delete_zoomLayersEvents?: { __typename?: 'zoomLayersEvents_mutation_response'; affected_rows: number } | null;
};

export type IncZoomCustomBackgroundDownloadCountMutationVariables = Exact<{
  increaseBy?: InputMaybe<Scalars['Int']>;
  where: ZoomCustomBackground_Bool_Exp;
}>;

export type IncZoomCustomBackgroundDownloadCountMutation = {
  __typename?: 'mutation_root';
  update_zoomCustomBackground?: {
    __typename?: 'zoomCustomBackground_mutation_response';
    affected_rows: number;
    returning: Array<{
      __typename?: 'zoomCustomBackground';
      id: string;
      url: string;
      uploadedByZoomUserId?: string | null;
      companyDomain: any;
      companyShared: boolean;
      downloadCount: number;
      createdAt: string;
      updatedAt: string;
      isGlobal: boolean;
      isHoliday: boolean;
      caption?: string | null;
      zoomUser?: {
        __typename?: 'zoomUser';
        firstName: string;
        lastName: string;
        fullNameGenerated?: string | null;
        email: string;
        photoUrl?: string | null;
      } | null;
    }>;
  } | null;
};

export type ZoomEventFragment = {
  __typename?: 'zoomEvent';
  id: number;
  eventId: string;
  status: string;
  htmlLink?: string | null;
  created?: string | null;
  updated?: string | null;
  summary?: string | null;
  description?: string | null;
  location?: string | null;
  start?: string | null;
  end?: string | null;
  guestsCanModify: boolean;
  guestsCanInviteOthers: boolean;
  guestsCanSeeOtherGuests: boolean;
  iCalUID?: string | null;
  hangoutLink?: string | null;
  conferenceData?: { [key: string]: any } | null;
  zoomEventParticipants_aggregate: {
    __typename?: 'zoomEventParticipant_aggregate';
    aggregate?: { __typename?: 'zoomEventParticipant_aggregate_fields'; count: number } | null;
  };
};

export type QueryZoomEventQueryVariables = Exact<{
  where: ZoomEvent_Bool_Exp;
  where_participant: ZoomEventParticipant_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEvent_Order_By> | ZoomEvent_Order_By>;
  distinct_on?: InputMaybe<Array<ZoomEvent_Select_Column> | ZoomEvent_Select_Column>;
}>;

export type QueryZoomEventQuery = {
  __typename?: 'query_root';
  zoomEvent: Array<{
    __typename?: 'zoomEvent';
    id: number;
    eventId: string;
    status: string;
    htmlLink?: string | null;
    created?: string | null;
    updated?: string | null;
    summary?: string | null;
    description?: string | null;
    location?: string | null;
    start?: string | null;
    end?: string | null;
    guestsCanModify: boolean;
    guestsCanInviteOthers: boolean;
    guestsCanSeeOtherGuests: boolean;
    iCalUID?: string | null;
    hangoutLink?: string | null;
    conferenceData?: { [key: string]: any } | null;
    zoomEventParticipants_aggregate: {
      __typename?: 'zoomEventParticipant_aggregate';
      aggregate?: { __typename?: 'zoomEventParticipant_aggregate_fields'; count: number } | null;
    };
  }>;
};

export type SubscribeToZoomEventSubscriptionVariables = Exact<{
  where: ZoomEvent_Bool_Exp;
  where_participant: ZoomEventParticipant_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEvent_Order_By> | ZoomEvent_Order_By>;
}>;

export type SubscribeToZoomEventSubscription = {
  __typename?: 'subscription_root';
  zoomEvent: Array<{
    __typename?: 'zoomEvent';
    id: number;
    eventId: string;
    status: string;
    htmlLink?: string | null;
    created?: string | null;
    updated?: string | null;
    summary?: string | null;
    description?: string | null;
    location?: string | null;
    start?: string | null;
    end?: string | null;
    guestsCanModify: boolean;
    guestsCanInviteOthers: boolean;
    guestsCanSeeOtherGuests: boolean;
    iCalUID?: string | null;
    hangoutLink?: string | null;
    conferenceData?: { [key: string]: any } | null;
    zoomEventParticipants_aggregate: {
      __typename?: 'zoomEventParticipant_aggregate';
      aggregate?: { __typename?: 'zoomEventParticipant_aggregate_fields'; count: number } | null;
    };
  }>;
};

export type ZoomEventParticipantWithContactsFragment = {
  __typename?: 'zoomEventParticipantWithContacts';
  id?: string | null;
  zoomEventId?: number | null;
  rsvpStatus?: string | null;
  organizer?: boolean | null;
  eventCanceledAt?: string | null;
  zoomUserId?: string | null;
  zoomContactId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullNameGenerated?: string | null;
  username?: string | null;
  firstNamePronunciation?: string | null;
  lastNamePronunciation?: string | null;
  pronouns?: string | null;
  email?: string | null;
  headline?: string | null;
  ask?: string | null;
  title?: string | null;
  startDate?: string | null;
  strengths?: string | null;
  photoUrl?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  location?: { [key: string]: any } | null;
  linkedInUrl?: string | null;
  twitterUrl?: string | null;
  scheduleMeetingUrl?: string | null;
  updatedAt?: string | null;
  zoomCompanyId?: string | null;
  companyName?: string | null;
  companyWebsiteDomain?: string | null;
  companyEstimatedSize?: string | null;
  companyCity?: string | null;
  companyState?: string | null;
  companyLinkedInUrl?: string | null;
  companyCrunchbaseUrl?: string | null;
  companySeoDescription?: string | null;
  companyDescription?: string | null;
  logoUrl?: string | null;
  isWarmlyUser?: boolean | null;
  customBackgroundPhotoUrl?: string | null;
  defaultCustomBackgroundPhotoUrl?: string | null;
  organizationId?: string | null;
};

export type QueryZoomEventParticipantWithContactsSortedQueryVariables = Exact<{
  args: ZoomEventParticipantWithContacts_Sorted_Args;
  where: ZoomEventParticipantWithContacts_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By> | ZoomEventParticipantWithContacts_Order_By>;
}>;

export type QueryZoomEventParticipantWithContactsSortedQuery = {
  __typename?: 'query_root';
  zoomEventParticipantWithContacts_sorted: Array<{
    __typename?: 'zoomEventParticipantWithContacts';
    id?: string | null;
    zoomEventId?: number | null;
    rsvpStatus?: string | null;
    organizer?: boolean | null;
    eventCanceledAt?: string | null;
    zoomUserId?: string | null;
    zoomContactId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullNameGenerated?: string | null;
    username?: string | null;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    email?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    startDate?: string | null;
    strengths?: string | null;
    photoUrl?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    scheduleMeetingUrl?: string | null;
    updatedAt?: string | null;
    zoomCompanyId?: string | null;
    companyName?: string | null;
    companyWebsiteDomain?: string | null;
    companyEstimatedSize?: string | null;
    companyCity?: string | null;
    companyState?: string | null;
    companyLinkedInUrl?: string | null;
    companyCrunchbaseUrl?: string | null;
    companySeoDescription?: string | null;
    companyDescription?: string | null;
    logoUrl?: string | null;
    isWarmlyUser?: boolean | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    organizationId?: string | null;
  }>;
};

export type SubscribeToZoomEventParticipantWithContactsSortedSubscriptionVariables = Exact<{
  args: ZoomEventParticipantWithContacts_Sorted_Args;
  where: ZoomEventParticipantWithContacts_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomEventParticipantWithContacts_Order_By> | ZoomEventParticipantWithContacts_Order_By>;
}>;

export type SubscribeToZoomEventParticipantWithContactsSortedSubscription = {
  __typename?: 'subscription_root';
  zoomEventParticipantWithContacts_sorted: Array<{
    __typename?: 'zoomEventParticipantWithContacts';
    id?: string | null;
    zoomEventId?: number | null;
    rsvpStatus?: string | null;
    organizer?: boolean | null;
    eventCanceledAt?: string | null;
    zoomUserId?: string | null;
    zoomContactId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullNameGenerated?: string | null;
    username?: string | null;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    email?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    startDate?: string | null;
    strengths?: string | null;
    photoUrl?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    scheduleMeetingUrl?: string | null;
    updatedAt?: string | null;
    zoomCompanyId?: string | null;
    companyName?: string | null;
    companyWebsiteDomain?: string | null;
    companyEstimatedSize?: string | null;
    companyCity?: string | null;
    companyState?: string | null;
    companyLinkedInUrl?: string | null;
    companyCrunchbaseUrl?: string | null;
    companySeoDescription?: string | null;
    companyDescription?: string | null;
    logoUrl?: string | null;
    isWarmlyUser?: boolean | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    organizationId?: string | null;
  }>;
};

export type ZoomContactFragment = {
  __typename?: 'zoomContact';
  id: string;
  apolloId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  email: string;
  linkedInUrl?: string | null;
  title?: string | null;
  photoUrl?: string | null;
  twitterUrl?: string | null;
  headline?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  location?: { [key: string]: any } | null;
  apolloContactId?: string | null;
  apolloOrganizationId?: string | null;
  apolloLastUpdatedAt?: string | null;
  zoomCompanyId?: string | null;
  roleBucket?: string | null;
  isEuCountry?: boolean | null;
  publicProfileDisabledAt?: string | null;
};

export type ZoomCompanyFragment = {
  __typename?: 'zoomCompany';
  id: string;
  name?: string | null;
  websiteUrl?: string | null;
  linkedInUrl?: string | null;
  twitterUrl?: string | null;
  crunchbaseUrl?: string | null;
  phone?: string | null;
  websiteDomain?: string | null;
  logoUrl?: string | null;
  estimatedSize?: string | null;
  industry?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  description?: string | null;
  seoDescription?: string | null;
  totalFunding?: string | null;
  latestFundingStage?: string | null;
  latestFundingRoundDate?: string | null;
  totalFundingPrinted?: string | null;
  technologyNames?: string | null;
  keywords?: string | null;
  alexaRanking?: string | null;
  yearFounded?: string | null;
  hasBeenMerged?: boolean | null;
};

export type ZoomGroupFragment = {
  __typename?: 'zoomGroup';
  id: string;
  name?: string | null;
  description?: string | null;
  websiteDomain?: string | null;
  websiteUrl?: string | null;
  logoUrl?: string | null;
  estimatedSize?: string | null;
  zoomCompanyId?: string | null;
  type: string;
  displayName?: string | null;
};

export type ZoomContactWithCompanyFragment = {
  __typename?: 'zoomContact';
  id: string;
  apolloId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  email: string;
  linkedInUrl?: string | null;
  title?: string | null;
  photoUrl?: string | null;
  twitterUrl?: string | null;
  headline?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  location?: { [key: string]: any } | null;
  apolloContactId?: string | null;
  apolloOrganizationId?: string | null;
  apolloLastUpdatedAt?: string | null;
  zoomCompanyId?: string | null;
  publicProfileDisabledAt?: string | null;
  zoomCompany?: {
    __typename?: 'zoomCompany';
    id: string;
    name?: string | null;
    websiteUrl?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    crunchbaseUrl?: string | null;
    phone?: string | null;
    websiteDomain?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    industry?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    description?: string | null;
    seoDescription?: string | null;
    totalFunding?: string | null;
    latestFundingStage?: string | null;
    latestFundingRoundDate?: string | null;
    totalFundingPrinted?: string | null;
    technologyNames?: string | null;
    keywords?: string | null;
  } | null;
};

export type ZoomUserActionsFragment = {
  __typename?: 'zoomUserActions';
  id: string;
  createdAt: string;
  updatedAt: string;
  zoomUserId: string;
  enhanceNametagCompletedAt?: string | null;
  enhanceNametagDismissedAt?: string | null;
  enhanceNametagDialogShownAt?: string | null;
  practiceRoomCompletedAt?: string | null;
  practiceRoomDismissedAt?: string | null;
  practiceRoomShownAt?: string | null;
  excitingNewsAlertDismissedAt?: string | null;
  manageOrganizationAlertDismissedAt?: string | null;
  needZoomAccessAlertDismissedAt?: string | null;
  welcomeSetupCardDismissedAt?: string | null;
  welcomeDialogDismissedAt?: string | null;
  unmirrorCardDismissedAt?: string | null;
  calSigDismissedAt?: string | null;
  installZoomDismissedAt?: string | null;
  upgradeZoomAlertDismissedAt?: string | null;
  agendaCardDismissedAt?: string | null;
  videoOffCardDismissedAt?: string | null;
  videoOffCardCompletedAt?: string | null;
  addBioDismissedAt?: string | null;
};

export type ZoomGroupMembershipFragment = {
  __typename?: 'zoomGroupMembership';
  id: string;
  zoomGroupId: string;
  type: string;
};

export type ZoomUserFragment = {
  __typename?: 'zoomUser';
  id: string;
  email: string;
  username?: string | null;
  firebaseUid: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  firstNamePronunciation?: string | null;
  lastNamePronunciation?: string | null;
  pronouns?: string | null;
  updatedAt?: string | null;
  calendarEnrichmentStatus?: string | null;
  integrationType?: string | null;
  role?: string | null;
  onboardVersion?: string | null;
  photoUrl?: string | null;
  headline?: string | null;
  ask?: string | null;
  title?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  location?: { [key: string]: any } | null;
  locationUpdatedAt?: string | null;
  linkedInUrl?: string | null;
  twitterUrl?: string | null;
  companyWebsiteDomain?: string | null;
  companyName?: string | null;
  profileUpdatedAt?: string | null;
  recurringEventsInjectionEnabledAt?: string | null;
  googleScopes?: { [key: string]: any } | null;
  zoomCompanyId?: string | null;
  scheduleMeetingUrl?: string | null;
  customBackgroundPhotoUrl?: string | null;
  defaultCustomBackgroundPhotoUrl?: string | null;
  calendarLastSyncedAt?: string | null;
  runningLateTemplate?: string | null;
  whereAreYouTemplate?: string | null;
  companyLogoUrl?: string | null;
  intercomOnboardingStartedAt?: string | null;
  zoomExternalAuthUserId?: string | null;
  roleBucket?: string | null;
  onlyInjectWarmlyUsers?: boolean | null;
  onboardingType?: string | null;
  profileVisibility: { [key: string]: any };
  microsoftScopes?: { [key: string]: any } | null;
  nametagSettings?: { [key: string]: any } | null;
  backgroundVisibility?: { [key: string]: any } | null;
  temperatureScale?: string | null;
  department?: string | null;
  startDate?: string | null;
  strengths?: string | null;
  addedToVideoConversationStarterPromptId?: string | null;
  lastJoinedMeetingUuid?: string | null;
  pmfSurveyDisplayedAt?: string | null;
  isGroupSuper?: boolean | null;
  isGroupAdmin?: boolean | null;
  isOrgAdmin?: boolean | null;
  zoomCompany?: {
    __typename?: 'zoomCompany';
    id: string;
    name?: string | null;
    websiteUrl?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    crunchbaseUrl?: string | null;
    phone?: string | null;
    websiteDomain?: string | null;
    logoUrl?: string | null;
    estimatedSize?: string | null;
    industry?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    description?: string | null;
    seoDescription?: string | null;
    totalFunding?: string | null;
    latestFundingStage?: string | null;
    latestFundingRoundDate?: string | null;
    totalFundingPrinted?: string | null;
    technologyNames?: string | null;
    keywords?: string | null;
    alexaRanking?: string | null;
    yearFounded?: string | null;
    hasBeenMerged?: boolean | null;
  } | null;
  zoomOrganizationMembership?: {
    __typename?: 'zoomOrganizationMembership';
    id: string;
    type: string;
    organization: {
      __typename?: 'zoomOrganization';
      id: string;
      orgAdminExists?: boolean | null;
      name: string;
      isFreeEmailDomain: boolean;
      otherDomains: { [key: string]: any };
      ssoDomains: { [key: string]: any };
      organizationId?: string | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
        alexaRanking?: string | null;
        yearFounded?: string | null;
        hasBeenMerged?: boolean | null;
      } | null;
    };
  } | null;
  zoomGroupMemberships: Array<{
    __typename?: 'zoomGroupMembership';
    id: string;
    type: string;
    group: {
      __typename?: 'zoomGroup';
      id: string;
      name?: string | null;
      description?: string | null;
      websiteDomain?: string | null;
      websiteUrl?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      zoomCompanyId?: string | null;
      type: string;
      displayName?: string | null;
    };
  }>;
  actions?: {
    __typename?: 'zoomUserActions';
    id: string;
    createdAt: string;
    updatedAt: string;
    zoomUserId: string;
    enhanceNametagCompletedAt?: string | null;
    enhanceNametagDismissedAt?: string | null;
    enhanceNametagDialogShownAt?: string | null;
    practiceRoomCompletedAt?: string | null;
    practiceRoomDismissedAt?: string | null;
    practiceRoomShownAt?: string | null;
    excitingNewsAlertDismissedAt?: string | null;
    manageOrganizationAlertDismissedAt?: string | null;
    needZoomAccessAlertDismissedAt?: string | null;
    welcomeSetupCardDismissedAt?: string | null;
    welcomeDialogDismissedAt?: string | null;
    unmirrorCardDismissedAt?: string | null;
    calSigDismissedAt?: string | null;
    installZoomDismissedAt?: string | null;
    upgradeZoomAlertDismissedAt?: string | null;
    agendaCardDismissedAt?: string | null;
    videoOffCardDismissedAt?: string | null;
    videoOffCardCompletedAt?: string | null;
    addBioDismissedAt?: string | null;
  } | null;
};

export type ZoomContactNotesFragment = {
  __typename?: 'zoomContactNotes';
  id: string;
  ownerEmail: string;
  contactEmail: string;
  notes?: { [key: string]: any } | null;
  notesText?: string | null;
  ownerZoomUserId: string;
  zoomContactId: string;
};

export type GoogleChannelFragment = {
  __typename?: 'googleChannel';
  id: number;
  channelId: string;
  resourceId: string;
  zoomUserId: string;
  resourceUri?: string | null;
  expiration?: string | null;
  stoppedAt?: string | null;
};

export type ZoomEmailHistoryFragment = {
  __typename?: 'zoomEmailHistory';
  id: string;
  emailService: string;
  category: string;
  description: string;
  status: string;
  body?: string | null;
  subject?: string | null;
  from: string;
  to?: string | null;
  cc?: string | null;
  bcc?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  emailServiceMessageId?: string | null;
  zoomUserId?: string | null;
  zoomEventId?: number | null;
};

export type ZoomCustomBackgroundFragment = {
  __typename?: 'zoomCustomBackground';
  id: string;
  url: string;
  uploadedByZoomUserId?: string | null;
  companyDomain: any;
  companyShared: boolean;
  downloadCount: number;
  createdAt: string;
  updatedAt: string;
  isGlobal: boolean;
  isHoliday: boolean;
  caption?: string | null;
  zoomUser?: {
    __typename?: 'zoomUser';
    firstName: string;
    lastName: string;
    fullNameGenerated?: string | null;
    email: string;
    photoUrl?: string | null;
  } | null;
};

export type ZoomGlobalCustomBackgroundFragment = {
  __typename?: 'zoomCustomBackground';
  id: string;
  url: string;
  createdAt: string;
  isGlobal: boolean;
  isHoliday: boolean;
  caption?: string | null;
};

export type ZoomConnectionRequestFragment = {
  __typename?: 'zoomConnectionRequest';
  id: string;
  zoomUserId: string;
  email: string;
  requestStatus: string;
};

export type ZoomConnectionRequestReminderFragment = {
  __typename?: 'zoomConnectionRequestReminder';
  id: string;
  zoomUserId: string;
  zoomConnectionRequestId: string;
  cadence: number;
  createdAt: string;
};

export type ZoomApprovedDomainFragment = {
  __typename?: 'zoomApprovedDomain';
  id: string;
  domain: string;
  status: string;
};

export type ZoomConversationStarterAnswerFragment = {
  __typename?: 'zoomConversationStarterAnswer';
  id: string;
  answer?: string | null;
  createdAt: string;
  updatedAt: string;
  answeredByZoomUserId: string;
  zoomConversationStarterPromptId: string;
  addedToVideoAt?: string | null;
  imageUrl?: string | null;
  cloudinaryPublicId?: string | null;
};

export type ZoomConversationStarterPromptFragment = {
  __typename?: 'zoomConversationStarterPrompt';
  id: string;
  unansweredPrompt: string;
  answeredPrompt: string;
  placeholderAnswer?: string | null;
  placeholderImageUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  createdByZoomUserId?: string | null;
  isActive: boolean;
  fromTag?: string | null;
};

export type ContentFragment = {
  __typename?: 'content';
  id: string;
  name: string;
  type?: any | null;
  value: string;
  createdByUserId?: string | null;
  createdAt: string;
  updatedAt: string;
  identifier: any;
  identifierId?: any | null;
  thumbnail?: string | null;
  tags: { [key: string]: any };
  weight: number;
  zoomUser?: {
    __typename?: 'zoomUser';
    id: string;
    firstName: string;
    lastName: string;
    fullNameGenerated?: string | null;
    email: string;
    photoUrl?: string | null;
  } | null;
};

export type ZoomSettingsFragment = {
  __typename?: 'zoomSettings';
  id: number;
  identifier: any;
  identifierId?: any | null;
  createdAt: string;
  updatedAt: string;
  defaultCalendarSignaturesOnForRecurringMeetings: boolean;
  disableBackgroundUpload: boolean;
  disableAiBackground: boolean;
  disableImageUpload: boolean;
  disableCalendarIntegration: boolean;
  disableCalendarSignaturesByDefault: boolean;
  disableNonWarmlyCalendarSignaturesByDefault: boolean;
  disablePublicProfiles: boolean;
  createdByUserId?: string | null;
  disableCalendarPromptOnZoomLogin: boolean;
  userProfileSettings: { [key: string]: any };
  meetingBoostSettings: { [key: string]: any };
  skipExperiments: boolean;
};

export type ZoomPresentationFragment = {
  __typename?: 'zoomPresentation';
  id: string;
  zoomUserId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  slidesCount?: number | null;
  name?: string | null;
};

export type GrowthUserReferralContactFragment = {
  __typename?: 'growthUserReferralContact';
  id: string;
  referrerId: string;
  referrerEmail: string;
  referrerFirstName?: string | null;
  referrerLastName?: string | null;
  refereeEmail: string;
  createdAt: string;
  referredOn: string;
};

export type LayersEventsFragment = {
  __typename?: 'zoomLayersEvents';
  id: string;
  createdAt: any;
  eventData: { [key: string]: any };
  zoomMeetingID: string;
};

export type ZoomGroupMembershipAggregateQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column> | ZoomGroupMembership_Select_Column>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By> | ZoomGroupMembership_Order_By>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
}>;

export type ZoomGroupMembershipAggregateQuery = {
  __typename?: 'query_root';
  aggregate: {
    __typename?: 'zoomGroupMembership_aggregate';
    aggregate?: { __typename?: 'zoomGroupMembership_aggregate_fields'; count: number } | null;
  };
  memberships: Array<{
    __typename?: 'zoomGroupMembership';
    type: string;
    user: {
      __typename?: 'zoomUser';
      id: string;
      email: string;
      username?: string | null;
      firebaseUid: string;
      createdAt: string;
      firstName: string;
      lastName: string;
      firstNamePronunciation?: string | null;
      lastNamePronunciation?: string | null;
      pronouns?: string | null;
      updatedAt?: string | null;
      calendarEnrichmentStatus?: string | null;
      integrationType?: string | null;
      role?: string | null;
      onboardVersion?: string | null;
      photoUrl?: string | null;
      headline?: string | null;
      ask?: string | null;
      title?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      location?: { [key: string]: any } | null;
      locationUpdatedAt?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      companyWebsiteDomain?: string | null;
      companyName?: string | null;
      profileUpdatedAt?: string | null;
      recurringEventsInjectionEnabledAt?: string | null;
      googleScopes?: { [key: string]: any } | null;
      zoomCompanyId?: string | null;
      scheduleMeetingUrl?: string | null;
      customBackgroundPhotoUrl?: string | null;
      defaultCustomBackgroundPhotoUrl?: string | null;
      calendarLastSyncedAt?: string | null;
      runningLateTemplate?: string | null;
      whereAreYouTemplate?: string | null;
      companyLogoUrl?: string | null;
      intercomOnboardingStartedAt?: string | null;
      zoomExternalAuthUserId?: string | null;
      roleBucket?: string | null;
      onlyInjectWarmlyUsers?: boolean | null;
      onboardingType?: string | null;
      profileVisibility: { [key: string]: any };
      microsoftScopes?: { [key: string]: any } | null;
      nametagSettings?: { [key: string]: any } | null;
      backgroundVisibility?: { [key: string]: any } | null;
      temperatureScale?: string | null;
      department?: string | null;
      startDate?: string | null;
      strengths?: string | null;
      addedToVideoConversationStarterPromptId?: string | null;
      lastJoinedMeetingUuid?: string | null;
      pmfSurveyDisplayedAt?: string | null;
      isGroupSuper?: boolean | null;
      isGroupAdmin?: boolean | null;
      isOrgAdmin?: boolean | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
        alexaRanking?: string | null;
        yearFounded?: string | null;
        hasBeenMerged?: boolean | null;
      } | null;
      zoomOrganizationMembership?: {
        __typename?: 'zoomOrganizationMembership';
        id: string;
        type: string;
        organization: {
          __typename?: 'zoomOrganization';
          id: string;
          orgAdminExists?: boolean | null;
          name: string;
          isFreeEmailDomain: boolean;
          otherDomains: { [key: string]: any };
          ssoDomains: { [key: string]: any };
          organizationId?: string | null;
          zoomCompany?: {
            __typename?: 'zoomCompany';
            id: string;
            name?: string | null;
            websiteUrl?: string | null;
            linkedInUrl?: string | null;
            twitterUrl?: string | null;
            crunchbaseUrl?: string | null;
            phone?: string | null;
            websiteDomain?: string | null;
            logoUrl?: string | null;
            estimatedSize?: string | null;
            industry?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            description?: string | null;
            seoDescription?: string | null;
            totalFunding?: string | null;
            latestFundingStage?: string | null;
            latestFundingRoundDate?: string | null;
            totalFundingPrinted?: string | null;
            technologyNames?: string | null;
            keywords?: string | null;
            alexaRanking?: string | null;
            yearFounded?: string | null;
            hasBeenMerged?: boolean | null;
          } | null;
        };
      } | null;
      zoomGroupMemberships: Array<{
        __typename?: 'zoomGroupMembership';
        id: string;
        type: string;
        group: {
          __typename?: 'zoomGroup';
          id: string;
          name?: string | null;
          description?: string | null;
          websiteDomain?: string | null;
          websiteUrl?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          zoomCompanyId?: string | null;
          type: string;
          displayName?: string | null;
        };
      }>;
      actions?: {
        __typename?: 'zoomUserActions';
        id: string;
        createdAt: string;
        updatedAt: string;
        zoomUserId: string;
        enhanceNametagCompletedAt?: string | null;
        enhanceNametagDismissedAt?: string | null;
        enhanceNametagDialogShownAt?: string | null;
        practiceRoomCompletedAt?: string | null;
        practiceRoomDismissedAt?: string | null;
        practiceRoomShownAt?: string | null;
        excitingNewsAlertDismissedAt?: string | null;
        manageOrganizationAlertDismissedAt?: string | null;
        needZoomAccessAlertDismissedAt?: string | null;
        welcomeSetupCardDismissedAt?: string | null;
        welcomeDialogDismissedAt?: string | null;
        unmirrorCardDismissedAt?: string | null;
        calSigDismissedAt?: string | null;
        installZoomDismissedAt?: string | null;
        upgradeZoomAlertDismissedAt?: string | null;
        agendaCardDismissedAt?: string | null;
        videoOffCardDismissedAt?: string | null;
        videoOffCardCompletedAt?: string | null;
        addBioDismissedAt?: string | null;
      } | null;
    };
  }>;
};

export type ZoomGroupMembershipCountQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomGroupMembership_Select_Column> | ZoomGroupMembership_Select_Column>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomGroupMembership_Order_By> | ZoomGroupMembership_Order_By>;
  where?: InputMaybe<ZoomGroupMembership_Bool_Exp>;
}>;

export type ZoomGroupMembershipCountQuery = {
  __typename?: 'query_root';
  aggregate: {
    __typename?: 'zoomGroupMembership_aggregate';
    aggregate?: { __typename?: 'zoomGroupMembership_aggregate_fields'; count: number } | null;
  };
};

export type ZoomOrganizationMembershipAggregateQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column> | ZoomOrganizationMembership_Select_Column>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembership_Order_By> | ZoomOrganizationMembership_Order_By>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
}>;

export type ZoomOrganizationMembershipAggregateQuery = {
  __typename?: 'query_root';
  aggregate: {
    __typename?: 'zoomOrganizationMembership_aggregate';
    aggregate?: { __typename?: 'zoomOrganizationMembership_aggregate_fields'; count: number } | null;
  };
  memberships: Array<{
    __typename?: 'zoomOrganizationMembership';
    type: string;
    user: {
      __typename?: 'zoomUser';
      id: string;
      email: string;
      username?: string | null;
      firebaseUid: string;
      createdAt: string;
      firstName: string;
      lastName: string;
      firstNamePronunciation?: string | null;
      lastNamePronunciation?: string | null;
      pronouns?: string | null;
      updatedAt?: string | null;
      calendarEnrichmentStatus?: string | null;
      integrationType?: string | null;
      role?: string | null;
      onboardVersion?: string | null;
      photoUrl?: string | null;
      headline?: string | null;
      ask?: string | null;
      title?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      location?: { [key: string]: any } | null;
      locationUpdatedAt?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      companyWebsiteDomain?: string | null;
      companyName?: string | null;
      profileUpdatedAt?: string | null;
      recurringEventsInjectionEnabledAt?: string | null;
      googleScopes?: { [key: string]: any } | null;
      zoomCompanyId?: string | null;
      scheduleMeetingUrl?: string | null;
      customBackgroundPhotoUrl?: string | null;
      defaultCustomBackgroundPhotoUrl?: string | null;
      calendarLastSyncedAt?: string | null;
      runningLateTemplate?: string | null;
      whereAreYouTemplate?: string | null;
      companyLogoUrl?: string | null;
      intercomOnboardingStartedAt?: string | null;
      zoomExternalAuthUserId?: string | null;
      roleBucket?: string | null;
      onlyInjectWarmlyUsers?: boolean | null;
      onboardingType?: string | null;
      profileVisibility: { [key: string]: any };
      microsoftScopes?: { [key: string]: any } | null;
      nametagSettings?: { [key: string]: any } | null;
      backgroundVisibility?: { [key: string]: any } | null;
      temperatureScale?: string | null;
      department?: string | null;
      startDate?: string | null;
      strengths?: string | null;
      addedToVideoConversationStarterPromptId?: string | null;
      lastJoinedMeetingUuid?: string | null;
      pmfSurveyDisplayedAt?: string | null;
      isGroupSuper?: boolean | null;
      isGroupAdmin?: boolean | null;
      isOrgAdmin?: boolean | null;
      zoomCompany?: {
        __typename?: 'zoomCompany';
        id: string;
        name?: string | null;
        websiteUrl?: string | null;
        linkedInUrl?: string | null;
        twitterUrl?: string | null;
        crunchbaseUrl?: string | null;
        phone?: string | null;
        websiteDomain?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        industry?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        description?: string | null;
        seoDescription?: string | null;
        totalFunding?: string | null;
        latestFundingStage?: string | null;
        latestFundingRoundDate?: string | null;
        totalFundingPrinted?: string | null;
        technologyNames?: string | null;
        keywords?: string | null;
        alexaRanking?: string | null;
        yearFounded?: string | null;
        hasBeenMerged?: boolean | null;
      } | null;
      zoomOrganizationMembership?: {
        __typename?: 'zoomOrganizationMembership';
        id: string;
        type: string;
        organization: {
          __typename?: 'zoomOrganization';
          id: string;
          orgAdminExists?: boolean | null;
          name: string;
          isFreeEmailDomain: boolean;
          otherDomains: { [key: string]: any };
          ssoDomains: { [key: string]: any };
          organizationId?: string | null;
          zoomCompany?: {
            __typename?: 'zoomCompany';
            id: string;
            name?: string | null;
            websiteUrl?: string | null;
            linkedInUrl?: string | null;
            twitterUrl?: string | null;
            crunchbaseUrl?: string | null;
            phone?: string | null;
            websiteDomain?: string | null;
            logoUrl?: string | null;
            estimatedSize?: string | null;
            industry?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            description?: string | null;
            seoDescription?: string | null;
            totalFunding?: string | null;
            latestFundingStage?: string | null;
            latestFundingRoundDate?: string | null;
            totalFundingPrinted?: string | null;
            technologyNames?: string | null;
            keywords?: string | null;
            alexaRanking?: string | null;
            yearFounded?: string | null;
            hasBeenMerged?: boolean | null;
          } | null;
        };
      } | null;
      zoomGroupMemberships: Array<{
        __typename?: 'zoomGroupMembership';
        id: string;
        type: string;
        group: {
          __typename?: 'zoomGroup';
          id: string;
          name?: string | null;
          description?: string | null;
          websiteDomain?: string | null;
          websiteUrl?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          zoomCompanyId?: string | null;
          type: string;
          displayName?: string | null;
        };
      }>;
      actions?: {
        __typename?: 'zoomUserActions';
        id: string;
        createdAt: string;
        updatedAt: string;
        zoomUserId: string;
        enhanceNametagCompletedAt?: string | null;
        enhanceNametagDismissedAt?: string | null;
        enhanceNametagDialogShownAt?: string | null;
        practiceRoomCompletedAt?: string | null;
        practiceRoomDismissedAt?: string | null;
        practiceRoomShownAt?: string | null;
        excitingNewsAlertDismissedAt?: string | null;
        manageOrganizationAlertDismissedAt?: string | null;
        needZoomAccessAlertDismissedAt?: string | null;
        welcomeSetupCardDismissedAt?: string | null;
        welcomeDialogDismissedAt?: string | null;
        unmirrorCardDismissedAt?: string | null;
        calSigDismissedAt?: string | null;
        installZoomDismissedAt?: string | null;
        upgradeZoomAlertDismissedAt?: string | null;
        agendaCardDismissedAt?: string | null;
        videoOffCardDismissedAt?: string | null;
        videoOffCardCompletedAt?: string | null;
        addBioDismissedAt?: string | null;
      } | null;
    };
  }>;
};

export type ZoomOrganizationMembershipCountQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomOrganizationMembership_Select_Column> | ZoomOrganizationMembership_Select_Column>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomOrganizationMembership_Order_By> | ZoomOrganizationMembership_Order_By>;
  where?: InputMaybe<ZoomOrganizationMembership_Bool_Exp>;
}>;

export type ZoomOrganizationMembershipCountQuery = {
  __typename?: 'query_root';
  aggregate: {
    __typename?: 'zoomOrganizationMembership_aggregate';
    aggregate?: { __typename?: 'zoomOrganizationMembership_aggregate_fields'; count: number } | null;
  };
};

export type ZoomUserAggregateQueryVariables = Exact<{
  distinct_on?: InputMaybe<Array<ZoomUser_Select_Column> | ZoomUser_Select_Column>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUser_Order_By> | ZoomUser_Order_By>;
  where?: InputMaybe<ZoomUser_Bool_Exp>;
}>;

export type ZoomUserAggregateQuery = {
  __typename?: 'query_root';
  aggregate: {
    __typename?: 'zoomUser_aggregate';
    aggregate?: { __typename?: 'zoomUser_aggregate_fields'; count: number } | null;
  };
  zoomUser: Array<{
    __typename?: 'zoomUser';
    id: string;
    email: string;
    username?: string | null;
    firebaseUid: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    updatedAt?: string | null;
    calendarEnrichmentStatus?: string | null;
    integrationType?: string | null;
    role?: string | null;
    onboardVersion?: string | null;
    photoUrl?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    locationUpdatedAt?: string | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    companyWebsiteDomain?: string | null;
    companyName?: string | null;
    profileUpdatedAt?: string | null;
    recurringEventsInjectionEnabledAt?: string | null;
    googleScopes?: { [key: string]: any } | null;
    zoomCompanyId?: string | null;
    scheduleMeetingUrl?: string | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    calendarLastSyncedAt?: string | null;
    runningLateTemplate?: string | null;
    whereAreYouTemplate?: string | null;
    companyLogoUrl?: string | null;
    intercomOnboardingStartedAt?: string | null;
    zoomExternalAuthUserId?: string | null;
    roleBucket?: string | null;
    onlyInjectWarmlyUsers?: boolean | null;
    onboardingType?: string | null;
    profileVisibility: { [key: string]: any };
    microsoftScopes?: { [key: string]: any } | null;
    nametagSettings?: { [key: string]: any } | null;
    backgroundVisibility?: { [key: string]: any } | null;
    temperatureScale?: string | null;
    department?: string | null;
    startDate?: string | null;
    strengths?: string | null;
    addedToVideoConversationStarterPromptId?: string | null;
    lastJoinedMeetingUuid?: string | null;
    pmfSurveyDisplayedAt?: string | null;
    isGroupSuper?: boolean | null;
    isGroupAdmin?: boolean | null;
    isOrgAdmin?: boolean | null;
    zoomCompany?: {
      __typename?: 'zoomCompany';
      id: string;
      name?: string | null;
      websiteUrl?: string | null;
      linkedInUrl?: string | null;
      twitterUrl?: string | null;
      crunchbaseUrl?: string | null;
      phone?: string | null;
      websiteDomain?: string | null;
      logoUrl?: string | null;
      estimatedSize?: string | null;
      industry?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      description?: string | null;
      seoDescription?: string | null;
      totalFunding?: string | null;
      latestFundingStage?: string | null;
      latestFundingRoundDate?: string | null;
      totalFundingPrinted?: string | null;
      technologyNames?: string | null;
      keywords?: string | null;
      alexaRanking?: string | null;
      yearFounded?: string | null;
      hasBeenMerged?: boolean | null;
    } | null;
    zoomOrganizationMembership?: {
      __typename?: 'zoomOrganizationMembership';
      id: string;
      type: string;
      organization: {
        __typename?: 'zoomOrganization';
        id: string;
        orgAdminExists?: boolean | null;
        name: string;
        isFreeEmailDomain: boolean;
        otherDomains: { [key: string]: any };
        ssoDomains: { [key: string]: any };
        organizationId?: string | null;
        zoomCompany?: {
          __typename?: 'zoomCompany';
          id: string;
          name?: string | null;
          websiteUrl?: string | null;
          linkedInUrl?: string | null;
          twitterUrl?: string | null;
          crunchbaseUrl?: string | null;
          phone?: string | null;
          websiteDomain?: string | null;
          logoUrl?: string | null;
          estimatedSize?: string | null;
          industry?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          description?: string | null;
          seoDescription?: string | null;
          totalFunding?: string | null;
          latestFundingStage?: string | null;
          latestFundingRoundDate?: string | null;
          totalFundingPrinted?: string | null;
          technologyNames?: string | null;
          keywords?: string | null;
          alexaRanking?: string | null;
          yearFounded?: string | null;
          hasBeenMerged?: boolean | null;
        } | null;
      };
    } | null;
    zoomGroupMemberships: Array<{
      __typename?: 'zoomGroupMembership';
      id: string;
      type: string;
      group: {
        __typename?: 'zoomGroup';
        id: string;
        name?: string | null;
        description?: string | null;
        websiteDomain?: string | null;
        websiteUrl?: string | null;
        logoUrl?: string | null;
        estimatedSize?: string | null;
        zoomCompanyId?: string | null;
        type: string;
        displayName?: string | null;
      };
    }>;
    actions?: {
      __typename?: 'zoomUserActions';
      id: string;
      createdAt: string;
      updatedAt: string;
      zoomUserId: string;
      enhanceNametagCompletedAt?: string | null;
      enhanceNametagDismissedAt?: string | null;
      enhanceNametagDialogShownAt?: string | null;
      practiceRoomCompletedAt?: string | null;
      practiceRoomDismissedAt?: string | null;
      practiceRoomShownAt?: string | null;
      excitingNewsAlertDismissedAt?: string | null;
      manageOrganizationAlertDismissedAt?: string | null;
      needZoomAccessAlertDismissedAt?: string | null;
      welcomeSetupCardDismissedAt?: string | null;
      welcomeDialogDismissedAt?: string | null;
      unmirrorCardDismissedAt?: string | null;
      calSigDismissedAt?: string | null;
      installZoomDismissedAt?: string | null;
      upgradeZoomAlertDismissedAt?: string | null;
      agendaCardDismissedAt?: string | null;
      videoOffCardDismissedAt?: string | null;
      videoOffCardCompletedAt?: string | null;
      addBioDismissedAt?: string | null;
    } | null;
  }>;
};

export type ZoomUserConnectionRequestFragment = {
  __typename?: 'zoomUserConnectionRequest';
  zoomUserId?: string | null;
  senderZoomUserId?: string | null;
  zoomContactId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullNameGenerated?: string | null;
  username?: string | null;
  firstNamePronunciation?: string | null;
  lastNamePronunciation?: string | null;
  pronouns?: string | null;
  email?: string | null;
  recipientEmail?: string | null;
  headline?: string | null;
  ask?: string | null;
  title?: string | null;
  startDate?: string | null;
  photoUrl?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  location?: { [key: string]: any } | null;
  linkedInUrl?: string | null;
  twitterUrl?: string | null;
  scheduleMeetingUrl?: string | null;
  updatedAt?: string | null;
  zoomCompanyId?: string | null;
  companyName?: string | null;
  companyWebsiteDomain?: string | null;
  companyEstimatedSize?: string | null;
  companyCity?: string | null;
  companyState?: string | null;
  companyLinkedInUrl?: string | null;
  companyCrunchbaseUrl?: string | null;
  companySeoDescription?: string | null;
  companyDescription?: string | null;
  logoUrl?: string | null;
  isWarmlyUser?: boolean | null;
  customBackgroundPhotoUrl?: string | null;
  defaultCustomBackgroundPhotoUrl?: string | null;
  requestStatus?: string | null;
};

export type QueryZoomUserConnectionRequestQueryVariables = Exact<{
  where: ZoomUserConnectionRequest_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserConnectionRequest_Order_By> | ZoomUserConnectionRequest_Order_By>;
}>;

export type QueryZoomUserConnectionRequestQuery = {
  __typename?: 'query_root';
  zoomUserConnectionRequest: Array<{
    __typename?: 'zoomUserConnectionRequest';
    zoomUserId?: string | null;
    senderZoomUserId?: string | null;
    zoomContactId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullNameGenerated?: string | null;
    username?: string | null;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    email?: string | null;
    recipientEmail?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    startDate?: string | null;
    photoUrl?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    scheduleMeetingUrl?: string | null;
    updatedAt?: string | null;
    zoomCompanyId?: string | null;
    companyName?: string | null;
    companyWebsiteDomain?: string | null;
    companyEstimatedSize?: string | null;
    companyCity?: string | null;
    companyState?: string | null;
    companyLinkedInUrl?: string | null;
    companyCrunchbaseUrl?: string | null;
    companySeoDescription?: string | null;
    companyDescription?: string | null;
    logoUrl?: string | null;
    isWarmlyUser?: boolean | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    requestStatus?: string | null;
  }>;
};

export type SubscribeToZoomUserConnectionRequestSubscriptionVariables = Exact<{
  where: ZoomUserConnectionRequest_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserConnectionRequest_Order_By> | ZoomUserConnectionRequest_Order_By>;
}>;

export type SubscribeToZoomUserConnectionRequestSubscription = {
  __typename?: 'subscription_root';
  zoomUserConnectionRequest: Array<{
    __typename?: 'zoomUserConnectionRequest';
    zoomUserId?: string | null;
    senderZoomUserId?: string | null;
    zoomContactId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    fullNameGenerated?: string | null;
    username?: string | null;
    firstNamePronunciation?: string | null;
    lastNamePronunciation?: string | null;
    pronouns?: string | null;
    email?: string | null;
    recipientEmail?: string | null;
    headline?: string | null;
    ask?: string | null;
    title?: string | null;
    startDate?: string | null;
    photoUrl?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    location?: { [key: string]: any } | null;
    linkedInUrl?: string | null;
    twitterUrl?: string | null;
    scheduleMeetingUrl?: string | null;
    updatedAt?: string | null;
    zoomCompanyId?: string | null;
    companyName?: string | null;
    companyWebsiteDomain?: string | null;
    companyEstimatedSize?: string | null;
    companyCity?: string | null;
    companyState?: string | null;
    companyLinkedInUrl?: string | null;
    companyCrunchbaseUrl?: string | null;
    companySeoDescription?: string | null;
    companyDescription?: string | null;
    logoUrl?: string | null;
    isWarmlyUser?: boolean | null;
    customBackgroundPhotoUrl?: string | null;
    defaultCustomBackgroundPhotoUrl?: string | null;
    requestStatus?: string | null;
  }>;
};

export type SubscribeToZoomUserConnectionRequestCountSubscriptionVariables = Exact<{
  where: ZoomUserConnectionRequest_Bool_Exp;
}>;

export type SubscribeToZoomUserConnectionRequestCountSubscription = {
  __typename?: 'subscription_root';
  zoomUserConnectionRequest_aggregate: {
    __typename?: 'zoomUserConnectionRequest_aggregate';
    aggregate?: { __typename?: 'zoomUserConnectionRequest_aggregate_fields'; count: number } | null;
  };
};

export type QueryZoomUserMeetingParticipantsQueryVariables = Exact<{
  where: ZoomUserMeetingParticipants_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserMeetingParticipants_Order_By> | ZoomUserMeetingParticipants_Order_By>;
}>;

export type QueryZoomUserMeetingParticipantsQuery = {
  __typename?: 'query_root';
  zoomUserMeetingParticipants: Array<{
    __typename?: 'zoomUserMeetingParticipants';
    zoomUserId?: string | null;
    zoomUserEmail?: string | null;
    meetingTitle?: string | null;
    meetingStartAt?: string | null;
    meetingEndAt?: string | null;
  }>;
};

export type ZoomUserNetworkFragment = {
  __typename?: 'zoomUserNetwork';
  zoomUserId?: string | null;
  email?: string | null;
  username?: string | null;
  zoomContactFirstName?: string | null;
  zoomContactLastName?: string | null;
  zoomContactFullNameGenerated?: string | null;
  zoomContactTitle?: string | null;
  zoomContactLinkedInUrl?: string | null;
  zoomContactPhotoUrl?: string | null;
  zoomContactCountry?: string | null;
  zoomContactState?: string | null;
  zoomContactCity?: string | null;
  zoomContactLocation?: { [key: string]: any } | null;
  zoomContactZoomCompanyName?: string | null;
  zoomUserFirstName?: string | null;
  zoomUserLastName?: string | null;
  zoomUserFullNameGenerated?: string | null;
  zoomUserLinkedInUrl?: string | null;
  zoomUserPhotoUrl?: string | null;
  zoomUserTitle?: string | null;
  zoomUserStartDate?: string | null;
  zoomUserStrengths?: string | null;
  zoomUserCountry?: string | null;
  zoomUserState?: string | null;
  zoomUserCity?: string | null;
  zoomUserLocation?: { [key: string]: any } | null;
  zoomUserCompanyName?: string | null;
  zoomUserFirstNamePronunciation?: string | null;
  zoomUserLastNamePronunciation?: string | null;
  zoomUserPronouns?: string | null;
  zoomUserZoomCompanyName?: string | null;
  lastEventStart?: string | null;
  eventCount?: number | null;
  zoomUserScheduleMeetingUrl?: string | null;
  zoomContactId?: string | null;
  zoomUserNetworkZoomUserId?: string | null;
  zoomUserHeadline?: string | null;
  zoomUserCompanyWebsiteDomain?: string | null;
  zoomUserZoomCompanyWebsiteDomain?: string | null;
  zoomContactZoomCompanyWebsiteDomain?: string | null;
  zoomUserZoomCompanyEstimatedSize?: string | null;
  zoomContactZoomCompanyEstimatedSize?: string | null;
  zoomUserZoomCompanyCity?: string | null;
  zoomContactZoomCompanyCity?: string | null;
  zoomUserZoomCompanyState?: string | null;
  zoomContactZoomCompanyState?: string | null;
  zoomUserZoomCompanyLinkedInUrl?: string | null;
  zoomContactZoomCompanyLinkedInUrl?: string | null;
  zoomUserZoomCompanyCrunchbaseUrl?: string | null;
  zoomContactZoomCompanyCrunchbaseUrl?: string | null;
  zoomUserZoomCompanyLogoUrl?: string | null;
  zoomContactZoomCompanyLogoUrl?: string | null;
  zoomUserCompanyLogoUrl?: string | null;
  zoomContactZoomCompanyDescription?: string | null;
  zoomUserZoomCompanyDescription?: string | null;
  zoomContactZoomCompanySeoDescription?: string | null;
  zoomUserZoomCompanySeoDescription?: string | null;
  zoomContactTwitterUrl?: string | null;
  zoomUserTwitterUrl?: string | null;
};

export type QueryZoomUserNetworkQueryVariables = Exact<{
  where: ZoomUserNetwork_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserNetwork_Order_By> | ZoomUserNetwork_Order_By>;
}>;

export type QueryZoomUserNetworkQuery = {
  __typename?: 'query_root';
  zoomUserNetwork: Array<{
    __typename?: 'zoomUserNetwork';
    zoomUserId?: string | null;
    email?: string | null;
    username?: string | null;
    zoomContactFirstName?: string | null;
    zoomContactLastName?: string | null;
    zoomContactFullNameGenerated?: string | null;
    zoomContactTitle?: string | null;
    zoomContactLinkedInUrl?: string | null;
    zoomContactPhotoUrl?: string | null;
    zoomContactCountry?: string | null;
    zoomContactState?: string | null;
    zoomContactCity?: string | null;
    zoomContactLocation?: { [key: string]: any } | null;
    zoomContactZoomCompanyName?: string | null;
    zoomUserFirstName?: string | null;
    zoomUserLastName?: string | null;
    zoomUserFullNameGenerated?: string | null;
    zoomUserLinkedInUrl?: string | null;
    zoomUserPhotoUrl?: string | null;
    zoomUserTitle?: string | null;
    zoomUserStartDate?: string | null;
    zoomUserStrengths?: string | null;
    zoomUserCountry?: string | null;
    zoomUserState?: string | null;
    zoomUserCity?: string | null;
    zoomUserLocation?: { [key: string]: any } | null;
    zoomUserCompanyName?: string | null;
    zoomUserFirstNamePronunciation?: string | null;
    zoomUserLastNamePronunciation?: string | null;
    zoomUserPronouns?: string | null;
    zoomUserZoomCompanyName?: string | null;
    lastEventStart?: string | null;
    eventCount?: number | null;
    zoomUserScheduleMeetingUrl?: string | null;
    zoomContactId?: string | null;
    zoomUserNetworkZoomUserId?: string | null;
    zoomUserHeadline?: string | null;
    zoomUserCompanyWebsiteDomain?: string | null;
    zoomUserZoomCompanyWebsiteDomain?: string | null;
    zoomContactZoomCompanyWebsiteDomain?: string | null;
    zoomUserZoomCompanyEstimatedSize?: string | null;
    zoomContactZoomCompanyEstimatedSize?: string | null;
    zoomUserZoomCompanyCity?: string | null;
    zoomContactZoomCompanyCity?: string | null;
    zoomUserZoomCompanyState?: string | null;
    zoomContactZoomCompanyState?: string | null;
    zoomUserZoomCompanyLinkedInUrl?: string | null;
    zoomContactZoomCompanyLinkedInUrl?: string | null;
    zoomUserZoomCompanyCrunchbaseUrl?: string | null;
    zoomContactZoomCompanyCrunchbaseUrl?: string | null;
    zoomUserZoomCompanyLogoUrl?: string | null;
    zoomContactZoomCompanyLogoUrl?: string | null;
    zoomUserCompanyLogoUrl?: string | null;
    zoomContactZoomCompanyDescription?: string | null;
    zoomUserZoomCompanyDescription?: string | null;
    zoomContactZoomCompanySeoDescription?: string | null;
    zoomUserZoomCompanySeoDescription?: string | null;
    zoomContactTwitterUrl?: string | null;
    zoomUserTwitterUrl?: string | null;
  }>;
};

export type SubscribeToZoomUserNetworkSubscriptionVariables = Exact<{
  where: ZoomUserNetwork_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUserNetwork_Order_By> | ZoomUserNetwork_Order_By>;
}>;

export type SubscribeToZoomUserNetworkSubscription = {
  __typename?: 'subscription_root';
  zoomUserNetwork: Array<{
    __typename?: 'zoomUserNetwork';
    zoomUserId?: string | null;
    email?: string | null;
    username?: string | null;
    zoomContactFirstName?: string | null;
    zoomContactLastName?: string | null;
    zoomContactFullNameGenerated?: string | null;
    zoomContactTitle?: string | null;
    zoomContactLinkedInUrl?: string | null;
    zoomContactPhotoUrl?: string | null;
    zoomContactCountry?: string | null;
    zoomContactState?: string | null;
    zoomContactCity?: string | null;
    zoomContactLocation?: { [key: string]: any } | null;
    zoomContactZoomCompanyName?: string | null;
    zoomUserFirstName?: string | null;
    zoomUserLastName?: string | null;
    zoomUserFullNameGenerated?: string | null;
    zoomUserLinkedInUrl?: string | null;
    zoomUserPhotoUrl?: string | null;
    zoomUserTitle?: string | null;
    zoomUserStartDate?: string | null;
    zoomUserStrengths?: string | null;
    zoomUserCountry?: string | null;
    zoomUserState?: string | null;
    zoomUserCity?: string | null;
    zoomUserLocation?: { [key: string]: any } | null;
    zoomUserCompanyName?: string | null;
    zoomUserFirstNamePronunciation?: string | null;
    zoomUserLastNamePronunciation?: string | null;
    zoomUserPronouns?: string | null;
    zoomUserZoomCompanyName?: string | null;
    lastEventStart?: string | null;
    eventCount?: number | null;
    zoomUserScheduleMeetingUrl?: string | null;
    zoomContactId?: string | null;
    zoomUserNetworkZoomUserId?: string | null;
    zoomUserHeadline?: string | null;
    zoomUserCompanyWebsiteDomain?: string | null;
    zoomUserZoomCompanyWebsiteDomain?: string | null;
    zoomContactZoomCompanyWebsiteDomain?: string | null;
    zoomUserZoomCompanyEstimatedSize?: string | null;
    zoomContactZoomCompanyEstimatedSize?: string | null;
    zoomUserZoomCompanyCity?: string | null;
    zoomContactZoomCompanyCity?: string | null;
    zoomUserZoomCompanyState?: string | null;
    zoomContactZoomCompanyState?: string | null;
    zoomUserZoomCompanyLinkedInUrl?: string | null;
    zoomContactZoomCompanyLinkedInUrl?: string | null;
    zoomUserZoomCompanyCrunchbaseUrl?: string | null;
    zoomContactZoomCompanyCrunchbaseUrl?: string | null;
    zoomUserZoomCompanyLogoUrl?: string | null;
    zoomContactZoomCompanyLogoUrl?: string | null;
    zoomUserCompanyLogoUrl?: string | null;
    zoomContactZoomCompanyDescription?: string | null;
    zoomUserZoomCompanyDescription?: string | null;
    zoomContactZoomCompanySeoDescription?: string | null;
    zoomUserZoomCompanySeoDescription?: string | null;
    zoomContactTwitterUrl?: string | null;
    zoomUserTwitterUrl?: string | null;
  }>;
};

export type SubscribeToZoomUserNetworkCountSubscriptionVariables = Exact<{
  where: ZoomUserNetwork_Bool_Exp;
}>;

export type SubscribeToZoomUserNetworkCountSubscription = {
  __typename?: 'subscription_root';
  zoomUserNetwork_aggregate: {
    __typename?: 'zoomUserNetwork_aggregate';
    aggregate?: { __typename?: 'zoomUserNetwork_aggregate_fields'; count: number } | null;
  };
};

export type ZoomUsersAtCompanyFragment = {
  __typename?: 'zoomUsersAtCompany';
  id?: string | null;
  name?: string | null;
  websiteUrl?: string | null;
  logoUrl?: string | null;
  industry?: string | null;
  zoomUserCount?: number | null;
};

export type ZoomUsersAtCompanyQueryVariables = Exact<{
  where: ZoomUsersAtCompany_Bool_Exp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ZoomUsersAtCompany_Order_By> | ZoomUsersAtCompany_Order_By>;
}>;

export type ZoomUsersAtCompanyQuery = {
  __typename?: 'query_root';
  zoomUsersAtCompany: Array<{
    __typename?: 'zoomUsersAtCompany';
    id?: string | null;
    name?: string | null;
    websiteUrl?: string | null;
    logoUrl?: string | null;
    industry?: string | null;
    zoomUserCount?: number | null;
  }>;
};

export const ZoomEventFragmentDoc = gql`
  fragment ZoomEvent on zoomEvent {
    id
    eventId
    status
    htmlLink
    created
    updated
    summary
    description
    location
    start
    end
    guestsCanModify
    guestsCanInviteOthers
    guestsCanSeeOtherGuests
    iCalUID
    hangoutLink
    conferenceData
    zoomEventParticipants_aggregate(where: $where_participant) {
      aggregate {
        count
      }
    }
  }
`;
export const ZoomEventParticipantWithContactsFragmentDoc = gql`
  fragment ZoomEventParticipantWithContacts on zoomEventParticipantWithContacts {
    id
    zoomEventId
    rsvpStatus
    organizer
    eventCanceledAt
    zoomUserId
    zoomContactId
    firstName
    lastName
    fullNameGenerated
    username
    firstNamePronunciation
    lastNamePronunciation
    pronouns
    email
    headline
    ask
    title
    startDate
    strengths
    photoUrl
    city
    state
    country
    location
    linkedInUrl
    twitterUrl
    scheduleMeetingUrl
    updatedAt
    zoomCompanyId
    companyName
    companyWebsiteDomain
    companyEstimatedSize
    companyCity
    companyState
    companyLinkedInUrl
    companyCrunchbaseUrl
    companySeoDescription
    companyDescription
    logoUrl
    isWarmlyUser
    customBackgroundPhotoUrl
    defaultCustomBackgroundPhotoUrl
    organizationId
  }
`;
export const ZoomContactFragmentDoc = gql`
  fragment ZoomContact on zoomContact {
    id
    apolloId
    firstName
    lastName
    name
    email
    linkedInUrl
    title
    photoUrl
    twitterUrl
    headline
    country
    state
    city
    location
    apolloContactId
    apolloOrganizationId
    apolloLastUpdatedAt
    zoomCompanyId
    roleBucket
    isEuCountry
    publicProfileDisabledAt
  }
`;
export const ZoomContactWithCompanyFragmentDoc = gql`
  fragment ZoomContactWithCompany on zoomContact {
    id
    apolloId
    firstName
    lastName
    name
    email
    linkedInUrl
    title
    photoUrl
    twitterUrl
    headline
    country
    state
    city
    location
    apolloContactId
    apolloOrganizationId
    apolloLastUpdatedAt
    zoomCompanyId
    publicProfileDisabledAt
    zoomCompany {
      id
      name
      websiteUrl
      linkedInUrl
      twitterUrl
      crunchbaseUrl
      phone
      websiteDomain
      logoUrl
      estimatedSize
      industry
      city
      state
      country
      description
      seoDescription
      totalFunding
      latestFundingStage
      latestFundingRoundDate
      totalFundingPrinted
      technologyNames
      keywords
    }
  }
`;
export const ZoomGroupMembershipFragmentDoc = gql`
  fragment ZoomGroupMembership on zoomGroupMembership {
    id
    zoomGroupId
    type
  }
`;
export const ZoomCompanyFragmentDoc = gql`
  fragment ZoomCompany on zoomCompany {
    id
    name
    websiteUrl
    linkedInUrl
    twitterUrl
    crunchbaseUrl
    phone
    websiteDomain
    logoUrl
    estimatedSize
    industry
    city
    state
    country
    description
    seoDescription
    totalFunding
    latestFundingStage
    latestFundingRoundDate
    totalFundingPrinted
    technologyNames
    keywords
    alexaRanking
    yearFounded
    hasBeenMerged
  }
`;
export const ZoomGroupFragmentDoc = gql`
  fragment ZoomGroup on zoomGroup {
    id
    name
    description
    websiteDomain
    websiteUrl
    logoUrl
    estimatedSize
    zoomCompanyId
    type
    displayName
  }
`;
export const ZoomUserActionsFragmentDoc = gql`
  fragment ZoomUserActions on zoomUserActions {
    id
    createdAt
    updatedAt
    zoomUserId
    enhanceNametagCompletedAt
    enhanceNametagDismissedAt
    enhanceNametagDialogShownAt
    practiceRoomCompletedAt
    practiceRoomDismissedAt
    practiceRoomShownAt
    excitingNewsAlertDismissedAt
    manageOrganizationAlertDismissedAt
    needZoomAccessAlertDismissedAt
    welcomeSetupCardDismissedAt
    welcomeDialogDismissedAt
    unmirrorCardDismissedAt
    calSigDismissedAt
    installZoomDismissedAt
    upgradeZoomAlertDismissedAt
    agendaCardDismissedAt
    videoOffCardDismissedAt
    videoOffCardCompletedAt
    addBioDismissedAt
  }
`;
export const ZoomUserFragmentDoc = gql`
  fragment ZoomUser on zoomUser {
    id
    email
    username
    firebaseUid
    createdAt
    firstName
    lastName
    firstNamePronunciation
    lastNamePronunciation
    pronouns
    updatedAt
    calendarEnrichmentStatus
    integrationType
    role
    onboardVersion
    photoUrl
    headline
    ask
    title
    city
    state
    country
    location
    locationUpdatedAt
    linkedInUrl
    twitterUrl
    companyWebsiteDomain
    companyName
    profileUpdatedAt
    recurringEventsInjectionEnabledAt
    googleScopes
    zoomCompanyId
    scheduleMeetingUrl
    customBackgroundPhotoUrl
    defaultCustomBackgroundPhotoUrl
    calendarLastSyncedAt
    runningLateTemplate
    whereAreYouTemplate
    companyLogoUrl
    runningLateTemplate
    intercomOnboardingStartedAt
    zoomExternalAuthUserId
    roleBucket
    onlyInjectWarmlyUsers
    onboardingType
    profileVisibility
    microsoftScopes
    nametagSettings
    backgroundVisibility
    temperatureScale
    zoomCompany {
      ...ZoomCompany
    }
    department
    startDate
    strengths
    addedToVideoConversationStarterPromptId
    lastJoinedMeetingUuid
    pmfSurveyDisplayedAt
    isGroupSuper
    isGroupAdmin
    isOrgAdmin
    zoomOrganizationMembership {
      id
      type
      organization {
        id
        orgAdminExists
        name
        isFreeEmailDomain
        otherDomains
        ssoDomains
        organizationId
        zoomCompany {
          ...ZoomCompany
        }
      }
    }
    zoomGroupMemberships {
      id
      type
      group {
        ...ZoomGroup
      }
    }
    actions {
      ...ZoomUserActions
    }
  }
  ${ZoomCompanyFragmentDoc}
  ${ZoomGroupFragmentDoc}
  ${ZoomUserActionsFragmentDoc}
`;
export const ZoomContactNotesFragmentDoc = gql`
  fragment ZoomContactNotes on zoomContactNotes {
    id
    ownerEmail
    contactEmail
    notes
    notesText
    ownerZoomUserId
    zoomContactId
  }
`;
export const GoogleChannelFragmentDoc = gql`
  fragment GoogleChannel on googleChannel {
    id
    channelId
    resourceId
    zoomUserId
    resourceUri
    expiration
    stoppedAt
  }
`;
export const ZoomEmailHistoryFragmentDoc = gql`
  fragment ZoomEmailHistory on zoomEmailHistory {
    id
    emailService
    category
    description
    status
    body
    subject
    from
    to
    cc
    bcc
    createdAt
    updatedAt
    emailServiceMessageId
    zoomUserId
    zoomEventId
  }
`;
export const ZoomCustomBackgroundFragmentDoc = gql`
  fragment ZoomCustomBackground on zoomCustomBackground {
    id
    url
    uploadedByZoomUserId
    companyDomain
    companyShared
    downloadCount
    createdAt
    updatedAt
    isGlobal
    isHoliday
    caption
    zoomUser {
      firstName
      lastName
      fullNameGenerated
      email
      photoUrl
    }
  }
`;
export const ZoomGlobalCustomBackgroundFragmentDoc = gql`
  fragment ZoomGlobalCustomBackground on zoomCustomBackground {
    id
    url
    createdAt
    isGlobal
    isHoliday
    caption
  }
`;
export const ZoomConnectionRequestFragmentDoc = gql`
  fragment ZoomConnectionRequest on zoomConnectionRequest {
    id
    zoomUserId
    email
    requestStatus
  }
`;
export const ZoomConnectionRequestReminderFragmentDoc = gql`
  fragment ZoomConnectionRequestReminder on zoomConnectionRequestReminder {
    id
    zoomUserId
    zoomConnectionRequestId
    cadence
    createdAt
  }
`;
export const ZoomApprovedDomainFragmentDoc = gql`
  fragment ZoomApprovedDomain on zoomApprovedDomain {
    id
    domain
    status
  }
`;
export const ZoomConversationStarterAnswerFragmentDoc = gql`
  fragment ZoomConversationStarterAnswer on zoomConversationStarterAnswer {
    id
    answer
    createdAt
    updatedAt
    answeredByZoomUserId
    zoomConversationStarterPromptId
    addedToVideoAt
    imageUrl
    cloudinaryPublicId
  }
`;
export const ZoomConversationStarterPromptFragmentDoc = gql`
  fragment ZoomConversationStarterPrompt on zoomConversationStarterPrompt {
    id
    unansweredPrompt
    answeredPrompt
    placeholderAnswer
    placeholderImageUrl
    createdAt
    updatedAt
    createdByZoomUserId
    isActive
    fromTag
  }
`;
export const ContentFragmentDoc = gql`
  fragment Content on content {
    id
    name
    type
    value
    createdByUserId
    createdAt
    updatedAt
    identifier
    identifierId
    thumbnail
    tags
    weight
    zoomUser {
      id
      firstName
      lastName
      fullNameGenerated
      email
      photoUrl
    }
  }
`;
export const ZoomSettingsFragmentDoc = gql`
  fragment ZoomSettings on zoomSettings {
    id
    identifier
    identifierId
    createdAt
    updatedAt
    defaultCalendarSignaturesOnForRecurringMeetings
    disableBackgroundUpload
    disableAiBackground
    disableImageUpload
    disableCalendarIntegration
    disableCalendarSignaturesByDefault
    disableNonWarmlyCalendarSignaturesByDefault
    disablePublicProfiles
    createdByUserId
    disableCalendarPromptOnZoomLogin
    userProfileSettings
    meetingBoostSettings
    skipExperiments
  }
`;
export const ZoomPresentationFragmentDoc = gql`
  fragment ZoomPresentation on zoomPresentation {
    id
    zoomUserId
    createdAt
    updatedAt
    slidesCount
    name
  }
`;
export const GrowthUserReferralContactFragmentDoc = gql`
  fragment GrowthUserReferralContact on growthUserReferralContact {
    id
    referrerId
    referrerEmail
    referrerFirstName
    referrerLastName
    refereeEmail
    createdAt
    referredOn
  }
`;
export const LayersEventsFragmentDoc = gql`
  fragment LayersEvents on zoomLayersEvents {
    id
    createdAt
    eventData
    zoomMeetingID
  }
`;
export const ZoomUserConnectionRequestFragmentDoc = gql`
  fragment ZoomUserConnectionRequest on zoomUserConnectionRequest {
    zoomUserId
    senderZoomUserId
    zoomContactId
    firstName
    lastName
    fullNameGenerated
    username
    firstNamePronunciation
    lastNamePronunciation
    pronouns
    email
    recipientEmail
    headline
    ask
    title
    startDate
    photoUrl
    city
    state
    country
    location
    linkedInUrl
    twitterUrl
    scheduleMeetingUrl
    updatedAt
    zoomCompanyId
    companyName
    companyWebsiteDomain
    companyEstimatedSize
    companyCity
    companyState
    companyLinkedInUrl
    companyCrunchbaseUrl
    companySeoDescription
    companyDescription
    logoUrl
    isWarmlyUser
    customBackgroundPhotoUrl
    defaultCustomBackgroundPhotoUrl
    requestStatus
  }
`;
export const ZoomUserNetworkFragmentDoc = gql`
  fragment ZoomUserNetwork on zoomUserNetwork {
    zoomUserId
    email
    username
    zoomContactFirstName
    zoomContactLastName
    zoomContactFullNameGenerated
    zoomContactTitle
    zoomContactLinkedInUrl
    zoomContactPhotoUrl
    zoomContactCountry
    zoomContactState
    zoomContactCity
    zoomContactLocation
    zoomContactZoomCompanyName
    zoomUserFirstName
    zoomUserLastName
    zoomUserFullNameGenerated
    zoomUserLinkedInUrl
    zoomUserPhotoUrl
    zoomUserTitle
    zoomUserStartDate
    zoomUserStrengths
    zoomUserCountry
    zoomUserState
    zoomUserCity
    zoomUserLocation
    zoomUserCompanyName
    zoomUserFirstNamePronunciation
    zoomUserLastNamePronunciation
    zoomUserPronouns
    zoomUserZoomCompanyName
    lastEventStart
    eventCount
    zoomUserScheduleMeetingUrl
    zoomContactId
    zoomUserNetworkZoomUserId
    zoomUserHeadline
    zoomUserCompanyWebsiteDomain
    zoomUserZoomCompanyWebsiteDomain
    zoomContactZoomCompanyWebsiteDomain
    zoomUserZoomCompanyEstimatedSize
    zoomContactZoomCompanyEstimatedSize
    zoomUserZoomCompanyCity
    zoomContactZoomCompanyCity
    zoomUserZoomCompanyState
    zoomContactZoomCompanyState
    zoomUserZoomCompanyLinkedInUrl
    zoomContactZoomCompanyLinkedInUrl
    zoomUserZoomCompanyCrunchbaseUrl
    zoomContactZoomCompanyCrunchbaseUrl
    zoomUserZoomCompanyLogoUrl
    zoomContactZoomCompanyLogoUrl
    zoomUserCompanyLogoUrl
    zoomContactZoomCompanyDescription
    zoomUserZoomCompanyDescription
    zoomContactZoomCompanySeoDescription
    zoomUserZoomCompanySeoDescription
    zoomContactTwitterUrl
    zoomUserTwitterUrl
  }
`;
export const ZoomUsersAtCompanyFragmentDoc = gql`
  fragment ZoomUsersAtCompany on zoomUsersAtCompany {
    id
    name
    websiteUrl
    logoUrl
    industry
    zoomUserCount
  }
`;
export const IncContentWeightDocument = gql`
  mutation incContentWeight($increaseBy: Int, $where: content_bool_exp!) {
    update_content(_inc: { weight: $increaseBy }, where: $where) {
      affected_rows
      returning {
        ...Content
      }
    }
  }
  ${ContentFragmentDoc}
`;
export type IncContentWeightMutationFn = Apollo.MutationFunction<
  IncContentWeightMutation,
  IncContentWeightMutationVariables
>;

/**
 * __useIncContentWeightMutation__
 *
 * To run a mutation, you first call `useIncContentWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncContentWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incContentWeightMutation, { data, loading, error }] = useIncContentWeightMutation({
 *   variables: {
 *      increaseBy: // value for 'increaseBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIncContentWeightMutation(
  baseOptions?: Apollo.MutationHookOptions<IncContentWeightMutation, IncContentWeightMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IncContentWeightMutation, IncContentWeightMutationVariables>(
    IncContentWeightDocument,
    options
  );
}
export type IncContentWeightMutationHookResult = ReturnType<typeof useIncContentWeightMutation>;
export type IncContentWeightMutationResult = Apollo.MutationResult<IncContentWeightMutation>;
export type IncContentWeightMutationOptions = Apollo.BaseMutationOptions<
  IncContentWeightMutation,
  IncContentWeightMutationVariables
>;
export const QueryZoomContactByIdDocument = gql`
  query queryZoomContactById($zoomContactId: uuid!) {
    zoomContact_by_pk(id: $zoomContactId) {
      ...ZoomContact
    }
  }
  ${ZoomContactFragmentDoc}
`;

/**
 * __useQueryZoomContactByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomContactByIdQuery({
 *   variables: {
 *      zoomContactId: // value for 'zoomContactId'
 *   },
 * });
 */
export function useQueryZoomContactByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomContactByIdQuery, QueryZoomContactByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomContactByIdQuery, QueryZoomContactByIdQueryVariables>(
    QueryZoomContactByIdDocument,
    options
  );
}
export function useQueryZoomContactByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomContactByIdQuery, QueryZoomContactByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomContactByIdQuery, QueryZoomContactByIdQueryVariables>(
    QueryZoomContactByIdDocument,
    options
  );
}
export type QueryZoomContactByIdQueryHookResult = ReturnType<typeof useQueryZoomContactByIdQuery>;
export type QueryZoomContactByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomContactByIdLazyQuery>;
export type QueryZoomContactByIdQueryResult = Apollo.QueryResult<
  QueryZoomContactByIdQuery,
  QueryZoomContactByIdQueryVariables
>;
export const QueryZoomContactObjectsDocument = gql`
  query queryZoomContactObjects(
    $distinct_on: [zoomContact_select_column!]
    $where: zoomContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomContact_order_by!]
  ) {
    zoomContact(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomContact
    }
  }
  ${ZoomContactFragmentDoc}
`;

/**
 * __useQueryZoomContactObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomContactObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomContactObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomContactObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomContactObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomContactObjectsQuery, QueryZoomContactObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomContactObjectsQuery, QueryZoomContactObjectsQueryVariables>(
    QueryZoomContactObjectsDocument,
    options
  );
}
export function useQueryZoomContactObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomContactObjectsQuery, QueryZoomContactObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomContactObjectsQuery, QueryZoomContactObjectsQueryVariables>(
    QueryZoomContactObjectsDocument,
    options
  );
}
export type QueryZoomContactObjectsQueryHookResult = ReturnType<typeof useQueryZoomContactObjectsQuery>;
export type QueryZoomContactObjectsLazyQueryHookResult = ReturnType<typeof useQueryZoomContactObjectsLazyQuery>;
export type QueryZoomContactObjectsQueryResult = Apollo.QueryResult<
  QueryZoomContactObjectsQuery,
  QueryZoomContactObjectsQueryVariables
>;
export const SubscribeToZoomContactByIdDocument = gql`
  subscription subscribeToZoomContactById($zoomContactId: uuid!) {
    zoomContact_by_pk(id: $zoomContactId) {
      ...ZoomContact
    }
  }
  ${ZoomContactFragmentDoc}
`;

/**
 * __useSubscribeToZoomContactByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomContactByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomContactByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomContactByIdSubscription({
 *   variables: {
 *      zoomContactId: // value for 'zoomContactId'
 *   },
 * });
 */
export function useSubscribeToZoomContactByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomContactByIdSubscription,
    SubscribeToZoomContactByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomContactByIdSubscription,
    SubscribeToZoomContactByIdSubscriptionVariables
  >(SubscribeToZoomContactByIdDocument, options);
}
export type SubscribeToZoomContactByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomContactByIdSubscription
>;
export type SubscribeToZoomContactByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomContactByIdSubscription>;
export const SubscribeToZoomContactObjectsDocument = gql`
  subscription subscribeToZoomContactObjects(
    $distinct_on: [zoomContact_select_column!]
    $where: zoomContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomContact_order_by!]
  ) {
    zoomContact(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomContact
    }
  }
  ${ZoomContactFragmentDoc}
`;

/**
 * __useSubscribeToZoomContactObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomContactObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomContactObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomContactObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomContactObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomContactObjectsSubscription,
    SubscribeToZoomContactObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomContactObjectsSubscription,
    SubscribeToZoomContactObjectsSubscriptionVariables
  >(SubscribeToZoomContactObjectsDocument, options);
}
export type SubscribeToZoomContactObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomContactObjectsSubscription
>;
export type SubscribeToZoomContactObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomContactObjectsSubscription>;
export const InsertZoomContactDocument = gql`
  mutation insertZoomContact($objects: [zoomContact_insert_input!]!) {
    insert_zoomContact(objects: $objects) {
      affected_rows
      returning {
        ...ZoomContact
      }
    }
  }
  ${ZoomContactFragmentDoc}
`;
export type InsertZoomContactMutationFn = Apollo.MutationFunction<
  InsertZoomContactMutation,
  InsertZoomContactMutationVariables
>;

/**
 * __useInsertZoomContactMutation__
 *
 * To run a mutation, you first call `useInsertZoomContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomContactMutation, { data, loading, error }] = useInsertZoomContactMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomContactMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomContactMutation, InsertZoomContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomContactMutation, InsertZoomContactMutationVariables>(
    InsertZoomContactDocument,
    options
  );
}
export type InsertZoomContactMutationHookResult = ReturnType<typeof useInsertZoomContactMutation>;
export type InsertZoomContactMutationResult = Apollo.MutationResult<InsertZoomContactMutation>;
export type InsertZoomContactMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomContactMutation,
  InsertZoomContactMutationVariables
>;
export const InsertZoomContactWithOnConflictDocument = gql`
  mutation insertZoomContactWithOnConflict(
    $objects: [zoomContact_insert_input!]!
    $onConflict: zoomContact_on_conflict
  ) {
    insert_zoomContact(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomContact
      }
    }
  }
  ${ZoomContactFragmentDoc}
`;
export type InsertZoomContactWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomContactWithOnConflictMutation,
  InsertZoomContactWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomContactWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomContactWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomContactWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomContactWithOnConflictMutation, { data, loading, error }] = useInsertZoomContactWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomContactWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomContactWithOnConflictMutation,
    InsertZoomContactWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomContactWithOnConflictMutation, InsertZoomContactWithOnConflictMutationVariables>(
    InsertZoomContactWithOnConflictDocument,
    options
  );
}
export type InsertZoomContactWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomContactWithOnConflictMutation
>;
export type InsertZoomContactWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomContactWithOnConflictMutation>;
export type InsertZoomContactWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomContactWithOnConflictMutation,
  InsertZoomContactWithOnConflictMutationVariables
>;
export const UpdateZoomContactByIdDocument = gql`
  mutation updateZoomContactById($id: uuid, $set: zoomContact_set_input) {
    update_zoomContact(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomContact
      }
    }
  }
  ${ZoomContactFragmentDoc}
`;
export type UpdateZoomContactByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomContactByIdMutation,
  UpdateZoomContactByIdMutationVariables
>;

/**
 * __useUpdateZoomContactByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomContactByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomContactByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomContactByIdMutation, { data, loading, error }] = useUpdateZoomContactByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomContactByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomContactByIdMutation, UpdateZoomContactByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomContactByIdMutation, UpdateZoomContactByIdMutationVariables>(
    UpdateZoomContactByIdDocument,
    options
  );
}
export type UpdateZoomContactByIdMutationHookResult = ReturnType<typeof useUpdateZoomContactByIdMutation>;
export type UpdateZoomContactByIdMutationResult = Apollo.MutationResult<UpdateZoomContactByIdMutation>;
export type UpdateZoomContactByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomContactByIdMutation,
  UpdateZoomContactByIdMutationVariables
>;
export const UpdateZoomContactDocument = gql`
  mutation updateZoomContact($set: zoomContact_set_input, $where: zoomContact_bool_exp!) {
    update_zoomContact(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomContact
      }
    }
  }
  ${ZoomContactFragmentDoc}
`;
export type UpdateZoomContactMutationFn = Apollo.MutationFunction<
  UpdateZoomContactMutation,
  UpdateZoomContactMutationVariables
>;

/**
 * __useUpdateZoomContactMutation__
 *
 * To run a mutation, you first call `useUpdateZoomContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomContactMutation, { data, loading, error }] = useUpdateZoomContactMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomContactMutation, UpdateZoomContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomContactMutation, UpdateZoomContactMutationVariables>(
    UpdateZoomContactDocument,
    options
  );
}
export type UpdateZoomContactMutationHookResult = ReturnType<typeof useUpdateZoomContactMutation>;
export type UpdateZoomContactMutationResult = Apollo.MutationResult<UpdateZoomContactMutation>;
export type UpdateZoomContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomContactMutation,
  UpdateZoomContactMutationVariables
>;
export const RemoveZoomContactModelByIdDocument = gql`
  mutation removeZoomContactModelById($id: uuid) {
    delete_zoomContact(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomContactModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomContactModelByIdMutation,
  RemoveZoomContactModelByIdMutationVariables
>;

/**
 * __useRemoveZoomContactModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomContactModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomContactModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomContactModelByIdMutation, { data, loading, error }] = useRemoveZoomContactModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomContactModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomContactModelByIdMutation,
    RemoveZoomContactModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomContactModelByIdMutation, RemoveZoomContactModelByIdMutationVariables>(
    RemoveZoomContactModelByIdDocument,
    options
  );
}
export type RemoveZoomContactModelByIdMutationHookResult = ReturnType<typeof useRemoveZoomContactModelByIdMutation>;
export type RemoveZoomContactModelByIdMutationResult = Apollo.MutationResult<RemoveZoomContactModelByIdMutation>;
export type RemoveZoomContactModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomContactModelByIdMutation,
  RemoveZoomContactModelByIdMutationVariables
>;
export const RemoveZoomContactModelDocument = gql`
  mutation removeZoomContactModel($where: zoomContact_bool_exp!) {
    delete_zoomContact(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomContactModelMutationFn = Apollo.MutationFunction<
  RemoveZoomContactModelMutation,
  RemoveZoomContactModelMutationVariables
>;

/**
 * __useRemoveZoomContactModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomContactModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomContactModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomContactModelMutation, { data, loading, error }] = useRemoveZoomContactModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomContactModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveZoomContactModelMutation, RemoveZoomContactModelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomContactModelMutation, RemoveZoomContactModelMutationVariables>(
    RemoveZoomContactModelDocument,
    options
  );
}
export type RemoveZoomContactModelMutationHookResult = ReturnType<typeof useRemoveZoomContactModelMutation>;
export type RemoveZoomContactModelMutationResult = Apollo.MutationResult<RemoveZoomContactModelMutation>;
export type RemoveZoomContactModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomContactModelMutation,
  RemoveZoomContactModelMutationVariables
>;
export const QueryZoomCompanyByIdDocument = gql`
  query queryZoomCompanyById($zoomCompanyId: uuid!) {
    zoomCompany_by_pk(id: $zoomCompanyId) {
      ...ZoomCompany
    }
  }
  ${ZoomCompanyFragmentDoc}
`;

/**
 * __useQueryZoomCompanyByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomCompanyByIdQuery({
 *   variables: {
 *      zoomCompanyId: // value for 'zoomCompanyId'
 *   },
 * });
 */
export function useQueryZoomCompanyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomCompanyByIdQuery, QueryZoomCompanyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomCompanyByIdQuery, QueryZoomCompanyByIdQueryVariables>(
    QueryZoomCompanyByIdDocument,
    options
  );
}
export function useQueryZoomCompanyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomCompanyByIdQuery, QueryZoomCompanyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomCompanyByIdQuery, QueryZoomCompanyByIdQueryVariables>(
    QueryZoomCompanyByIdDocument,
    options
  );
}
export type QueryZoomCompanyByIdQueryHookResult = ReturnType<typeof useQueryZoomCompanyByIdQuery>;
export type QueryZoomCompanyByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomCompanyByIdLazyQuery>;
export type QueryZoomCompanyByIdQueryResult = Apollo.QueryResult<
  QueryZoomCompanyByIdQuery,
  QueryZoomCompanyByIdQueryVariables
>;
export const QueryZoomCompanyObjectsDocument = gql`
  query queryZoomCompanyObjects(
    $distinct_on: [zoomCompany_select_column!]
    $where: zoomCompany_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomCompany_order_by!]
  ) {
    zoomCompany(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomCompany
    }
  }
  ${ZoomCompanyFragmentDoc}
`;

/**
 * __useQueryZoomCompanyObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomCompanyObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomCompanyObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomCompanyObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomCompanyObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomCompanyObjectsQuery, QueryZoomCompanyObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomCompanyObjectsQuery, QueryZoomCompanyObjectsQueryVariables>(
    QueryZoomCompanyObjectsDocument,
    options
  );
}
export function useQueryZoomCompanyObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomCompanyObjectsQuery, QueryZoomCompanyObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomCompanyObjectsQuery, QueryZoomCompanyObjectsQueryVariables>(
    QueryZoomCompanyObjectsDocument,
    options
  );
}
export type QueryZoomCompanyObjectsQueryHookResult = ReturnType<typeof useQueryZoomCompanyObjectsQuery>;
export type QueryZoomCompanyObjectsLazyQueryHookResult = ReturnType<typeof useQueryZoomCompanyObjectsLazyQuery>;
export type QueryZoomCompanyObjectsQueryResult = Apollo.QueryResult<
  QueryZoomCompanyObjectsQuery,
  QueryZoomCompanyObjectsQueryVariables
>;
export const SubscribeToZoomCompanyByIdDocument = gql`
  subscription subscribeToZoomCompanyById($zoomCompanyId: uuid!) {
    zoomCompany_by_pk(id: $zoomCompanyId) {
      ...ZoomCompany
    }
  }
  ${ZoomCompanyFragmentDoc}
`;

/**
 * __useSubscribeToZoomCompanyByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomCompanyByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomCompanyByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomCompanyByIdSubscription({
 *   variables: {
 *      zoomCompanyId: // value for 'zoomCompanyId'
 *   },
 * });
 */
export function useSubscribeToZoomCompanyByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomCompanyByIdSubscription,
    SubscribeToZoomCompanyByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomCompanyByIdSubscription,
    SubscribeToZoomCompanyByIdSubscriptionVariables
  >(SubscribeToZoomCompanyByIdDocument, options);
}
export type SubscribeToZoomCompanyByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomCompanyByIdSubscription
>;
export type SubscribeToZoomCompanyByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomCompanyByIdSubscription>;
export const SubscribeToZoomCompanyObjectsDocument = gql`
  subscription subscribeToZoomCompanyObjects(
    $distinct_on: [zoomCompany_select_column!]
    $where: zoomCompany_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomCompany_order_by!]
  ) {
    zoomCompany(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomCompany
    }
  }
  ${ZoomCompanyFragmentDoc}
`;

/**
 * __useSubscribeToZoomCompanyObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomCompanyObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomCompanyObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomCompanyObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomCompanyObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomCompanyObjectsSubscription,
    SubscribeToZoomCompanyObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomCompanyObjectsSubscription,
    SubscribeToZoomCompanyObjectsSubscriptionVariables
  >(SubscribeToZoomCompanyObjectsDocument, options);
}
export type SubscribeToZoomCompanyObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomCompanyObjectsSubscription
>;
export type SubscribeToZoomCompanyObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomCompanyObjectsSubscription>;
export const InsertZoomCompanyDocument = gql`
  mutation insertZoomCompany($objects: [zoomCompany_insert_input!]!) {
    insert_zoomCompany(objects: $objects) {
      affected_rows
      returning {
        ...ZoomCompany
      }
    }
  }
  ${ZoomCompanyFragmentDoc}
`;
export type InsertZoomCompanyMutationFn = Apollo.MutationFunction<
  InsertZoomCompanyMutation,
  InsertZoomCompanyMutationVariables
>;

/**
 * __useInsertZoomCompanyMutation__
 *
 * To run a mutation, you first call `useInsertZoomCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomCompanyMutation, { data, loading, error }] = useInsertZoomCompanyMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomCompanyMutation, InsertZoomCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomCompanyMutation, InsertZoomCompanyMutationVariables>(
    InsertZoomCompanyDocument,
    options
  );
}
export type InsertZoomCompanyMutationHookResult = ReturnType<typeof useInsertZoomCompanyMutation>;
export type InsertZoomCompanyMutationResult = Apollo.MutationResult<InsertZoomCompanyMutation>;
export type InsertZoomCompanyMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomCompanyMutation,
  InsertZoomCompanyMutationVariables
>;
export const InsertZoomCompanyWithOnConflictDocument = gql`
  mutation insertZoomCompanyWithOnConflict(
    $objects: [zoomCompany_insert_input!]!
    $onConflict: zoomCompany_on_conflict
  ) {
    insert_zoomCompany(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomCompany
      }
    }
  }
  ${ZoomCompanyFragmentDoc}
`;
export type InsertZoomCompanyWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomCompanyWithOnConflictMutation,
  InsertZoomCompanyWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomCompanyWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomCompanyWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomCompanyWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomCompanyWithOnConflictMutation, { data, loading, error }] = useInsertZoomCompanyWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomCompanyWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomCompanyWithOnConflictMutation,
    InsertZoomCompanyWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomCompanyWithOnConflictMutation, InsertZoomCompanyWithOnConflictMutationVariables>(
    InsertZoomCompanyWithOnConflictDocument,
    options
  );
}
export type InsertZoomCompanyWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomCompanyWithOnConflictMutation
>;
export type InsertZoomCompanyWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomCompanyWithOnConflictMutation>;
export type InsertZoomCompanyWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomCompanyWithOnConflictMutation,
  InsertZoomCompanyWithOnConflictMutationVariables
>;
export const UpdateZoomCompanyByIdDocument = gql`
  mutation updateZoomCompanyById($id: uuid, $set: zoomCompany_set_input) {
    update_zoomCompany(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomCompany
      }
    }
  }
  ${ZoomCompanyFragmentDoc}
`;
export type UpdateZoomCompanyByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomCompanyByIdMutation,
  UpdateZoomCompanyByIdMutationVariables
>;

/**
 * __useUpdateZoomCompanyByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomCompanyByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomCompanyByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomCompanyByIdMutation, { data, loading, error }] = useUpdateZoomCompanyByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomCompanyByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomCompanyByIdMutation, UpdateZoomCompanyByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomCompanyByIdMutation, UpdateZoomCompanyByIdMutationVariables>(
    UpdateZoomCompanyByIdDocument,
    options
  );
}
export type UpdateZoomCompanyByIdMutationHookResult = ReturnType<typeof useUpdateZoomCompanyByIdMutation>;
export type UpdateZoomCompanyByIdMutationResult = Apollo.MutationResult<UpdateZoomCompanyByIdMutation>;
export type UpdateZoomCompanyByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomCompanyByIdMutation,
  UpdateZoomCompanyByIdMutationVariables
>;
export const UpdateZoomCompanyDocument = gql`
  mutation updateZoomCompany($set: zoomCompany_set_input, $where: zoomCompany_bool_exp!) {
    update_zoomCompany(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomCompany
      }
    }
  }
  ${ZoomCompanyFragmentDoc}
`;
export type UpdateZoomCompanyMutationFn = Apollo.MutationFunction<
  UpdateZoomCompanyMutation,
  UpdateZoomCompanyMutationVariables
>;

/**
 * __useUpdateZoomCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateZoomCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomCompanyMutation, { data, loading, error }] = useUpdateZoomCompanyMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomCompanyMutation, UpdateZoomCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomCompanyMutation, UpdateZoomCompanyMutationVariables>(
    UpdateZoomCompanyDocument,
    options
  );
}
export type UpdateZoomCompanyMutationHookResult = ReturnType<typeof useUpdateZoomCompanyMutation>;
export type UpdateZoomCompanyMutationResult = Apollo.MutationResult<UpdateZoomCompanyMutation>;
export type UpdateZoomCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomCompanyMutation,
  UpdateZoomCompanyMutationVariables
>;
export const RemoveZoomCompanyModelByIdDocument = gql`
  mutation removeZoomCompanyModelById($id: uuid) {
    delete_zoomCompany(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomCompanyModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomCompanyModelByIdMutation,
  RemoveZoomCompanyModelByIdMutationVariables
>;

/**
 * __useRemoveZoomCompanyModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomCompanyModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomCompanyModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomCompanyModelByIdMutation, { data, loading, error }] = useRemoveZoomCompanyModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomCompanyModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomCompanyModelByIdMutation,
    RemoveZoomCompanyModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomCompanyModelByIdMutation, RemoveZoomCompanyModelByIdMutationVariables>(
    RemoveZoomCompanyModelByIdDocument,
    options
  );
}
export type RemoveZoomCompanyModelByIdMutationHookResult = ReturnType<typeof useRemoveZoomCompanyModelByIdMutation>;
export type RemoveZoomCompanyModelByIdMutationResult = Apollo.MutationResult<RemoveZoomCompanyModelByIdMutation>;
export type RemoveZoomCompanyModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomCompanyModelByIdMutation,
  RemoveZoomCompanyModelByIdMutationVariables
>;
export const RemoveZoomCompanyModelDocument = gql`
  mutation removeZoomCompanyModel($where: zoomCompany_bool_exp!) {
    delete_zoomCompany(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomCompanyModelMutationFn = Apollo.MutationFunction<
  RemoveZoomCompanyModelMutation,
  RemoveZoomCompanyModelMutationVariables
>;

/**
 * __useRemoveZoomCompanyModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomCompanyModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomCompanyModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomCompanyModelMutation, { data, loading, error }] = useRemoveZoomCompanyModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomCompanyModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveZoomCompanyModelMutation, RemoveZoomCompanyModelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomCompanyModelMutation, RemoveZoomCompanyModelMutationVariables>(
    RemoveZoomCompanyModelDocument,
    options
  );
}
export type RemoveZoomCompanyModelMutationHookResult = ReturnType<typeof useRemoveZoomCompanyModelMutation>;
export type RemoveZoomCompanyModelMutationResult = Apollo.MutationResult<RemoveZoomCompanyModelMutation>;
export type RemoveZoomCompanyModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomCompanyModelMutation,
  RemoveZoomCompanyModelMutationVariables
>;
export const QueryZoomGroupByIdDocument = gql`
  query queryZoomGroupById($zoomGroupId: uuid!) {
    zoomGroup_by_pk(id: $zoomGroupId) {
      ...ZoomGroup
    }
  }
  ${ZoomGroupFragmentDoc}
`;

/**
 * __useQueryZoomGroupByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomGroupByIdQuery({
 *   variables: {
 *      zoomGroupId: // value for 'zoomGroupId'
 *   },
 * });
 */
export function useQueryZoomGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>(
    QueryZoomGroupByIdDocument,
    options
  );
}
export function useQueryZoomGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomGroupByIdQuery, QueryZoomGroupByIdQueryVariables>(
    QueryZoomGroupByIdDocument,
    options
  );
}
export type QueryZoomGroupByIdQueryHookResult = ReturnType<typeof useQueryZoomGroupByIdQuery>;
export type QueryZoomGroupByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomGroupByIdLazyQuery>;
export type QueryZoomGroupByIdQueryResult = Apollo.QueryResult<
  QueryZoomGroupByIdQuery,
  QueryZoomGroupByIdQueryVariables
>;
export const QueryZoomGroupObjectsDocument = gql`
  query queryZoomGroupObjects(
    $distinct_on: [zoomGroup_select_column!]
    $where: zoomGroup_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomGroup_order_by!]
  ) {
    zoomGroup(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomGroup
    }
  }
  ${ZoomGroupFragmentDoc}
`;

/**
 * __useQueryZoomGroupObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomGroupObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomGroupObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomGroupObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomGroupObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomGroupObjectsQuery, QueryZoomGroupObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomGroupObjectsQuery, QueryZoomGroupObjectsQueryVariables>(
    QueryZoomGroupObjectsDocument,
    options
  );
}
export function useQueryZoomGroupObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomGroupObjectsQuery, QueryZoomGroupObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomGroupObjectsQuery, QueryZoomGroupObjectsQueryVariables>(
    QueryZoomGroupObjectsDocument,
    options
  );
}
export type QueryZoomGroupObjectsQueryHookResult = ReturnType<typeof useQueryZoomGroupObjectsQuery>;
export type QueryZoomGroupObjectsLazyQueryHookResult = ReturnType<typeof useQueryZoomGroupObjectsLazyQuery>;
export type QueryZoomGroupObjectsQueryResult = Apollo.QueryResult<
  QueryZoomGroupObjectsQuery,
  QueryZoomGroupObjectsQueryVariables
>;
export const SubscribeToZoomGroupByIdDocument = gql`
  subscription subscribeToZoomGroupById($zoomGroupId: uuid!) {
    zoomGroup_by_pk(id: $zoomGroupId) {
      ...ZoomGroup
    }
  }
  ${ZoomGroupFragmentDoc}
`;

/**
 * __useSubscribeToZoomGroupByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomGroupByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomGroupByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomGroupByIdSubscription({
 *   variables: {
 *      zoomGroupId: // value for 'zoomGroupId'
 *   },
 * });
 */
export function useSubscribeToZoomGroupByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomGroupByIdSubscription,
    SubscribeToZoomGroupByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SubscribeToZoomGroupByIdSubscription, SubscribeToZoomGroupByIdSubscriptionVariables>(
    SubscribeToZoomGroupByIdDocument,
    options
  );
}
export type SubscribeToZoomGroupByIdSubscriptionHookResult = ReturnType<typeof useSubscribeToZoomGroupByIdSubscription>;
export type SubscribeToZoomGroupByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomGroupByIdSubscription>;
export const SubscribeToZoomGroupObjectsDocument = gql`
  subscription subscribeToZoomGroupObjects(
    $distinct_on: [zoomGroup_select_column!]
    $where: zoomGroup_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomGroup_order_by!]
  ) {
    zoomGroup(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomGroup
    }
  }
  ${ZoomGroupFragmentDoc}
`;

/**
 * __useSubscribeToZoomGroupObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomGroupObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomGroupObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomGroupObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomGroupObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomGroupObjectsSubscription,
    SubscribeToZoomGroupObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomGroupObjectsSubscription,
    SubscribeToZoomGroupObjectsSubscriptionVariables
  >(SubscribeToZoomGroupObjectsDocument, options);
}
export type SubscribeToZoomGroupObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomGroupObjectsSubscription
>;
export type SubscribeToZoomGroupObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomGroupObjectsSubscription>;
export const InsertZoomGroupDocument = gql`
  mutation insertZoomGroup($objects: [zoomGroup_insert_input!]!) {
    insert_zoomGroup(objects: $objects) {
      affected_rows
      returning {
        ...ZoomGroup
      }
    }
  }
  ${ZoomGroupFragmentDoc}
`;
export type InsertZoomGroupMutationFn = Apollo.MutationFunction<
  InsertZoomGroupMutation,
  InsertZoomGroupMutationVariables
>;

/**
 * __useInsertZoomGroupMutation__
 *
 * To run a mutation, you first call `useInsertZoomGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomGroupMutation, { data, loading, error }] = useInsertZoomGroupMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomGroupMutation, InsertZoomGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomGroupMutation, InsertZoomGroupMutationVariables>(
    InsertZoomGroupDocument,
    options
  );
}
export type InsertZoomGroupMutationHookResult = ReturnType<typeof useInsertZoomGroupMutation>;
export type InsertZoomGroupMutationResult = Apollo.MutationResult<InsertZoomGroupMutation>;
export type InsertZoomGroupMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomGroupMutation,
  InsertZoomGroupMutationVariables
>;
export const InsertZoomGroupWithOnConflictDocument = gql`
  mutation insertZoomGroupWithOnConflict($objects: [zoomGroup_insert_input!]!, $onConflict: zoomGroup_on_conflict) {
    insert_zoomGroup(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomGroup
      }
    }
  }
  ${ZoomGroupFragmentDoc}
`;
export type InsertZoomGroupWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomGroupWithOnConflictMutation,
  InsertZoomGroupWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomGroupWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomGroupWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomGroupWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomGroupWithOnConflictMutation, { data, loading, error }] = useInsertZoomGroupWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomGroupWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomGroupWithOnConflictMutation,
    InsertZoomGroupWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomGroupWithOnConflictMutation, InsertZoomGroupWithOnConflictMutationVariables>(
    InsertZoomGroupWithOnConflictDocument,
    options
  );
}
export type InsertZoomGroupWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomGroupWithOnConflictMutation
>;
export type InsertZoomGroupWithOnConflictMutationResult = Apollo.MutationResult<InsertZoomGroupWithOnConflictMutation>;
export type InsertZoomGroupWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomGroupWithOnConflictMutation,
  InsertZoomGroupWithOnConflictMutationVariables
>;
export const UpdateZoomGroupByIdDocument = gql`
  mutation updateZoomGroupById($id: uuid, $set: zoomGroup_set_input) {
    update_zoomGroup(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomGroup
      }
    }
  }
  ${ZoomGroupFragmentDoc}
`;
export type UpdateZoomGroupByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomGroupByIdMutation,
  UpdateZoomGroupByIdMutationVariables
>;

/**
 * __useUpdateZoomGroupByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomGroupByIdMutation, { data, loading, error }] = useUpdateZoomGroupByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomGroupByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomGroupByIdMutation, UpdateZoomGroupByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomGroupByIdMutation, UpdateZoomGroupByIdMutationVariables>(
    UpdateZoomGroupByIdDocument,
    options
  );
}
export type UpdateZoomGroupByIdMutationHookResult = ReturnType<typeof useUpdateZoomGroupByIdMutation>;
export type UpdateZoomGroupByIdMutationResult = Apollo.MutationResult<UpdateZoomGroupByIdMutation>;
export type UpdateZoomGroupByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomGroupByIdMutation,
  UpdateZoomGroupByIdMutationVariables
>;
export const UpdateZoomGroupDocument = gql`
  mutation updateZoomGroup($set: zoomGroup_set_input, $where: zoomGroup_bool_exp!) {
    update_zoomGroup(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomGroup
      }
    }
  }
  ${ZoomGroupFragmentDoc}
`;
export type UpdateZoomGroupMutationFn = Apollo.MutationFunction<
  UpdateZoomGroupMutation,
  UpdateZoomGroupMutationVariables
>;

/**
 * __useUpdateZoomGroupMutation__
 *
 * To run a mutation, you first call `useUpdateZoomGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomGroupMutation, { data, loading, error }] = useUpdateZoomGroupMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomGroupMutation, UpdateZoomGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomGroupMutation, UpdateZoomGroupMutationVariables>(
    UpdateZoomGroupDocument,
    options
  );
}
export type UpdateZoomGroupMutationHookResult = ReturnType<typeof useUpdateZoomGroupMutation>;
export type UpdateZoomGroupMutationResult = Apollo.MutationResult<UpdateZoomGroupMutation>;
export type UpdateZoomGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomGroupMutation,
  UpdateZoomGroupMutationVariables
>;
export const RemoveZoomGroupModelByIdDocument = gql`
  mutation removeZoomGroupModelById($id: uuid) {
    delete_zoomGroup(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomGroupModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomGroupModelByIdMutation,
  RemoveZoomGroupModelByIdMutationVariables
>;

/**
 * __useRemoveZoomGroupModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomGroupModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomGroupModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomGroupModelByIdMutation, { data, loading, error }] = useRemoveZoomGroupModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomGroupModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveZoomGroupModelByIdMutation, RemoveZoomGroupModelByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomGroupModelByIdMutation, RemoveZoomGroupModelByIdMutationVariables>(
    RemoveZoomGroupModelByIdDocument,
    options
  );
}
export type RemoveZoomGroupModelByIdMutationHookResult = ReturnType<typeof useRemoveZoomGroupModelByIdMutation>;
export type RemoveZoomGroupModelByIdMutationResult = Apollo.MutationResult<RemoveZoomGroupModelByIdMutation>;
export type RemoveZoomGroupModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomGroupModelByIdMutation,
  RemoveZoomGroupModelByIdMutationVariables
>;
export const RemoveZoomGroupModelDocument = gql`
  mutation removeZoomGroupModel($where: zoomGroup_bool_exp!) {
    delete_zoomGroup(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomGroupModelMutationFn = Apollo.MutationFunction<
  RemoveZoomGroupModelMutation,
  RemoveZoomGroupModelMutationVariables
>;

/**
 * __useRemoveZoomGroupModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomGroupModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomGroupModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomGroupModelMutation, { data, loading, error }] = useRemoveZoomGroupModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomGroupModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveZoomGroupModelMutation, RemoveZoomGroupModelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomGroupModelMutation, RemoveZoomGroupModelMutationVariables>(
    RemoveZoomGroupModelDocument,
    options
  );
}
export type RemoveZoomGroupModelMutationHookResult = ReturnType<typeof useRemoveZoomGroupModelMutation>;
export type RemoveZoomGroupModelMutationResult = Apollo.MutationResult<RemoveZoomGroupModelMutation>;
export type RemoveZoomGroupModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomGroupModelMutation,
  RemoveZoomGroupModelMutationVariables
>;
export const QueryZoomContactWithCompanyByIdDocument = gql`
  query queryZoomContactWithCompanyById($zoomContactId: uuid!) {
    zoomContact_by_pk(id: $zoomContactId) {
      ...ZoomContactWithCompany
    }
  }
  ${ZoomContactWithCompanyFragmentDoc}
`;

/**
 * __useQueryZoomContactWithCompanyByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomContactWithCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomContactWithCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomContactWithCompanyByIdQuery({
 *   variables: {
 *      zoomContactId: // value for 'zoomContactId'
 *   },
 * });
 */
export function useQueryZoomContactWithCompanyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomContactWithCompanyByIdQuery,
    QueryZoomContactWithCompanyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomContactWithCompanyByIdQuery, QueryZoomContactWithCompanyByIdQueryVariables>(
    QueryZoomContactWithCompanyByIdDocument,
    options
  );
}
export function useQueryZoomContactWithCompanyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomContactWithCompanyByIdQuery,
    QueryZoomContactWithCompanyByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomContactWithCompanyByIdQuery, QueryZoomContactWithCompanyByIdQueryVariables>(
    QueryZoomContactWithCompanyByIdDocument,
    options
  );
}
export type QueryZoomContactWithCompanyByIdQueryHookResult = ReturnType<typeof useQueryZoomContactWithCompanyByIdQuery>;
export type QueryZoomContactWithCompanyByIdLazyQueryHookResult = ReturnType<
  typeof useQueryZoomContactWithCompanyByIdLazyQuery
>;
export type QueryZoomContactWithCompanyByIdQueryResult = Apollo.QueryResult<
  QueryZoomContactWithCompanyByIdQuery,
  QueryZoomContactWithCompanyByIdQueryVariables
>;
export const QueryZoomContactWithCompanyObjectsDocument = gql`
  query queryZoomContactWithCompanyObjects(
    $distinct_on: [zoomContact_select_column!]
    $where: zoomContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomContact_order_by!]
  ) {
    zoomContact(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomContactWithCompany
    }
  }
  ${ZoomContactWithCompanyFragmentDoc}
`;

/**
 * __useQueryZoomContactWithCompanyObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomContactWithCompanyObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomContactWithCompanyObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomContactWithCompanyObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomContactWithCompanyObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomContactWithCompanyObjectsQuery,
    QueryZoomContactWithCompanyObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomContactWithCompanyObjectsQuery, QueryZoomContactWithCompanyObjectsQueryVariables>(
    QueryZoomContactWithCompanyObjectsDocument,
    options
  );
}
export function useQueryZoomContactWithCompanyObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomContactWithCompanyObjectsQuery,
    QueryZoomContactWithCompanyObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomContactWithCompanyObjectsQuery, QueryZoomContactWithCompanyObjectsQueryVariables>(
    QueryZoomContactWithCompanyObjectsDocument,
    options
  );
}
export type QueryZoomContactWithCompanyObjectsQueryHookResult = ReturnType<
  typeof useQueryZoomContactWithCompanyObjectsQuery
>;
export type QueryZoomContactWithCompanyObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomContactWithCompanyObjectsLazyQuery
>;
export type QueryZoomContactWithCompanyObjectsQueryResult = Apollo.QueryResult<
  QueryZoomContactWithCompanyObjectsQuery,
  QueryZoomContactWithCompanyObjectsQueryVariables
>;
export const SubscribeToZoomContactWithCompanyByIdDocument = gql`
  subscription subscribeToZoomContactWithCompanyById($zoomContactId: uuid!) {
    zoomContact_by_pk(id: $zoomContactId) {
      ...ZoomContactWithCompany
    }
  }
  ${ZoomContactWithCompanyFragmentDoc}
`;

/**
 * __useSubscribeToZoomContactWithCompanyByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomContactWithCompanyByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomContactWithCompanyByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomContactWithCompanyByIdSubscription({
 *   variables: {
 *      zoomContactId: // value for 'zoomContactId'
 *   },
 * });
 */
export function useSubscribeToZoomContactWithCompanyByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomContactWithCompanyByIdSubscription,
    SubscribeToZoomContactWithCompanyByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomContactWithCompanyByIdSubscription,
    SubscribeToZoomContactWithCompanyByIdSubscriptionVariables
  >(SubscribeToZoomContactWithCompanyByIdDocument, options);
}
export type SubscribeToZoomContactWithCompanyByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomContactWithCompanyByIdSubscription
>;
export type SubscribeToZoomContactWithCompanyByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomContactWithCompanyByIdSubscription>;
export const SubscribeToZoomContactWithCompanyObjectsDocument = gql`
  subscription subscribeToZoomContactWithCompanyObjects(
    $distinct_on: [zoomContact_select_column!]
    $where: zoomContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomContact_order_by!]
  ) {
    zoomContact(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomContactWithCompany
    }
  }
  ${ZoomContactWithCompanyFragmentDoc}
`;

/**
 * __useSubscribeToZoomContactWithCompanyObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomContactWithCompanyObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomContactWithCompanyObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomContactWithCompanyObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomContactWithCompanyObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomContactWithCompanyObjectsSubscription,
    SubscribeToZoomContactWithCompanyObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomContactWithCompanyObjectsSubscription,
    SubscribeToZoomContactWithCompanyObjectsSubscriptionVariables
  >(SubscribeToZoomContactWithCompanyObjectsDocument, options);
}
export type SubscribeToZoomContactWithCompanyObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomContactWithCompanyObjectsSubscription
>;
export type SubscribeToZoomContactWithCompanyObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomContactWithCompanyObjectsSubscription>;
export const InsertZoomContactWithCompanyDocument = gql`
  mutation insertZoomContactWithCompany($objects: [zoomContact_insert_input!]!) {
    insert_zoomContact(objects: $objects) {
      affected_rows
      returning {
        ...ZoomContactWithCompany
      }
    }
  }
  ${ZoomContactWithCompanyFragmentDoc}
`;
export type InsertZoomContactWithCompanyMutationFn = Apollo.MutationFunction<
  InsertZoomContactWithCompanyMutation,
  InsertZoomContactWithCompanyMutationVariables
>;

/**
 * __useInsertZoomContactWithCompanyMutation__
 *
 * To run a mutation, you first call `useInsertZoomContactWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomContactWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomContactWithCompanyMutation, { data, loading, error }] = useInsertZoomContactWithCompanyMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomContactWithCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomContactWithCompanyMutation,
    InsertZoomContactWithCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomContactWithCompanyMutation, InsertZoomContactWithCompanyMutationVariables>(
    InsertZoomContactWithCompanyDocument,
    options
  );
}
export type InsertZoomContactWithCompanyMutationHookResult = ReturnType<typeof useInsertZoomContactWithCompanyMutation>;
export type InsertZoomContactWithCompanyMutationResult = Apollo.MutationResult<InsertZoomContactWithCompanyMutation>;
export type InsertZoomContactWithCompanyMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomContactWithCompanyMutation,
  InsertZoomContactWithCompanyMutationVariables
>;
export const InsertZoomContactWithCompanyWithOnConflictDocument = gql`
  mutation insertZoomContactWithCompanyWithOnConflict(
    $objects: [zoomContact_insert_input!]!
    $onConflict: zoomContact_on_conflict
  ) {
    insert_zoomContact(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomContactWithCompany
      }
    }
  }
  ${ZoomContactWithCompanyFragmentDoc}
`;
export type InsertZoomContactWithCompanyWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomContactWithCompanyWithOnConflictMutation,
  InsertZoomContactWithCompanyWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomContactWithCompanyWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomContactWithCompanyWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomContactWithCompanyWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomContactWithCompanyWithOnConflictMutation, { data, loading, error }] = useInsertZoomContactWithCompanyWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomContactWithCompanyWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomContactWithCompanyWithOnConflictMutation,
    InsertZoomContactWithCompanyWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomContactWithCompanyWithOnConflictMutation,
    InsertZoomContactWithCompanyWithOnConflictMutationVariables
  >(InsertZoomContactWithCompanyWithOnConflictDocument, options);
}
export type InsertZoomContactWithCompanyWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomContactWithCompanyWithOnConflictMutation
>;
export type InsertZoomContactWithCompanyWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomContactWithCompanyWithOnConflictMutation>;
export type InsertZoomContactWithCompanyWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomContactWithCompanyWithOnConflictMutation,
  InsertZoomContactWithCompanyWithOnConflictMutationVariables
>;
export const UpdateZoomContactWithCompanyByIdDocument = gql`
  mutation updateZoomContactWithCompanyById($id: uuid, $set: zoomContact_set_input) {
    update_zoomContact(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomContactWithCompany
      }
    }
  }
  ${ZoomContactWithCompanyFragmentDoc}
`;
export type UpdateZoomContactWithCompanyByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomContactWithCompanyByIdMutation,
  UpdateZoomContactWithCompanyByIdMutationVariables
>;

/**
 * __useUpdateZoomContactWithCompanyByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomContactWithCompanyByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomContactWithCompanyByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomContactWithCompanyByIdMutation, { data, loading, error }] = useUpdateZoomContactWithCompanyByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomContactWithCompanyByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomContactWithCompanyByIdMutation,
    UpdateZoomContactWithCompanyByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomContactWithCompanyByIdMutation,
    UpdateZoomContactWithCompanyByIdMutationVariables
  >(UpdateZoomContactWithCompanyByIdDocument, options);
}
export type UpdateZoomContactWithCompanyByIdMutationHookResult = ReturnType<
  typeof useUpdateZoomContactWithCompanyByIdMutation
>;
export type UpdateZoomContactWithCompanyByIdMutationResult =
  Apollo.MutationResult<UpdateZoomContactWithCompanyByIdMutation>;
export type UpdateZoomContactWithCompanyByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomContactWithCompanyByIdMutation,
  UpdateZoomContactWithCompanyByIdMutationVariables
>;
export const UpdateZoomContactWithCompanyDocument = gql`
  mutation updateZoomContactWithCompany($set: zoomContact_set_input, $where: zoomContact_bool_exp!) {
    update_zoomContact(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomContactWithCompany
      }
    }
  }
  ${ZoomContactWithCompanyFragmentDoc}
`;
export type UpdateZoomContactWithCompanyMutationFn = Apollo.MutationFunction<
  UpdateZoomContactWithCompanyMutation,
  UpdateZoomContactWithCompanyMutationVariables
>;

/**
 * __useUpdateZoomContactWithCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateZoomContactWithCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomContactWithCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomContactWithCompanyMutation, { data, loading, error }] = useUpdateZoomContactWithCompanyMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomContactWithCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomContactWithCompanyMutation,
    UpdateZoomContactWithCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomContactWithCompanyMutation, UpdateZoomContactWithCompanyMutationVariables>(
    UpdateZoomContactWithCompanyDocument,
    options
  );
}
export type UpdateZoomContactWithCompanyMutationHookResult = ReturnType<typeof useUpdateZoomContactWithCompanyMutation>;
export type UpdateZoomContactWithCompanyMutationResult = Apollo.MutationResult<UpdateZoomContactWithCompanyMutation>;
export type UpdateZoomContactWithCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomContactWithCompanyMutation,
  UpdateZoomContactWithCompanyMutationVariables
>;
export const QueryZoomUserActionsByIdDocument = gql`
  query queryZoomUserActionsById($zoomUserActionsId: uuid!) {
    zoomUserActions_by_pk(id: $zoomUserActionsId) {
      ...ZoomUserActions
    }
  }
  ${ZoomUserActionsFragmentDoc}
`;

/**
 * __useQueryZoomUserActionsByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomUserActionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomUserActionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomUserActionsByIdQuery({
 *   variables: {
 *      zoomUserActionsId: // value for 'zoomUserActionsId'
 *   },
 * });
 */
export function useQueryZoomUserActionsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomUserActionsByIdQuery, QueryZoomUserActionsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomUserActionsByIdQuery, QueryZoomUserActionsByIdQueryVariables>(
    QueryZoomUserActionsByIdDocument,
    options
  );
}
export function useQueryZoomUserActionsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomUserActionsByIdQuery, QueryZoomUserActionsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomUserActionsByIdQuery, QueryZoomUserActionsByIdQueryVariables>(
    QueryZoomUserActionsByIdDocument,
    options
  );
}
export type QueryZoomUserActionsByIdQueryHookResult = ReturnType<typeof useQueryZoomUserActionsByIdQuery>;
export type QueryZoomUserActionsByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomUserActionsByIdLazyQuery>;
export type QueryZoomUserActionsByIdQueryResult = Apollo.QueryResult<
  QueryZoomUserActionsByIdQuery,
  QueryZoomUserActionsByIdQueryVariables
>;
export const QueryZoomUserActionsObjectsDocument = gql`
  query queryZoomUserActionsObjects(
    $distinct_on: [zoomUserActions_select_column!]
    $where: zoomUserActions_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomUserActions_order_by!]
  ) {
    zoomUserActions(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUserActions
    }
  }
  ${ZoomUserActionsFragmentDoc}
`;

/**
 * __useQueryZoomUserActionsObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomUserActionsObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomUserActionsObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomUserActionsObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomUserActionsObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomUserActionsObjectsQuery, QueryZoomUserActionsObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomUserActionsObjectsQuery, QueryZoomUserActionsObjectsQueryVariables>(
    QueryZoomUserActionsObjectsDocument,
    options
  );
}
export function useQueryZoomUserActionsObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomUserActionsObjectsQuery, QueryZoomUserActionsObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomUserActionsObjectsQuery, QueryZoomUserActionsObjectsQueryVariables>(
    QueryZoomUserActionsObjectsDocument,
    options
  );
}
export type QueryZoomUserActionsObjectsQueryHookResult = ReturnType<typeof useQueryZoomUserActionsObjectsQuery>;
export type QueryZoomUserActionsObjectsLazyQueryHookResult = ReturnType<typeof useQueryZoomUserActionsObjectsLazyQuery>;
export type QueryZoomUserActionsObjectsQueryResult = Apollo.QueryResult<
  QueryZoomUserActionsObjectsQuery,
  QueryZoomUserActionsObjectsQueryVariables
>;
export const SubscribeToZoomUserActionsByIdDocument = gql`
  subscription subscribeToZoomUserActionsById($zoomUserActionsId: uuid!) {
    zoomUserActions_by_pk(id: $zoomUserActionsId) {
      ...ZoomUserActions
    }
  }
  ${ZoomUserActionsFragmentDoc}
`;

/**
 * __useSubscribeToZoomUserActionsByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomUserActionsByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomUserActionsByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomUserActionsByIdSubscription({
 *   variables: {
 *      zoomUserActionsId: // value for 'zoomUserActionsId'
 *   },
 * });
 */
export function useSubscribeToZoomUserActionsByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomUserActionsByIdSubscription,
    SubscribeToZoomUserActionsByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomUserActionsByIdSubscription,
    SubscribeToZoomUserActionsByIdSubscriptionVariables
  >(SubscribeToZoomUserActionsByIdDocument, options);
}
export type SubscribeToZoomUserActionsByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomUserActionsByIdSubscription
>;
export type SubscribeToZoomUserActionsByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomUserActionsByIdSubscription>;
export const SubscribeToZoomUserActionsObjectsDocument = gql`
  subscription subscribeToZoomUserActionsObjects(
    $distinct_on: [zoomUserActions_select_column!]
    $where: zoomUserActions_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomUserActions_order_by!]
  ) {
    zoomUserActions(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUserActions
    }
  }
  ${ZoomUserActionsFragmentDoc}
`;

/**
 * __useSubscribeToZoomUserActionsObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomUserActionsObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomUserActionsObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomUserActionsObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomUserActionsObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomUserActionsObjectsSubscription,
    SubscribeToZoomUserActionsObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomUserActionsObjectsSubscription,
    SubscribeToZoomUserActionsObjectsSubscriptionVariables
  >(SubscribeToZoomUserActionsObjectsDocument, options);
}
export type SubscribeToZoomUserActionsObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomUserActionsObjectsSubscription
>;
export type SubscribeToZoomUserActionsObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomUserActionsObjectsSubscription>;
export const InsertZoomUserActionsDocument = gql`
  mutation insertZoomUserActions($objects: [zoomUserActions_insert_input!]!) {
    insert_zoomUserActions(objects: $objects) {
      affected_rows
      returning {
        ...ZoomUserActions
      }
    }
  }
  ${ZoomUserActionsFragmentDoc}
`;
export type InsertZoomUserActionsMutationFn = Apollo.MutationFunction<
  InsertZoomUserActionsMutation,
  InsertZoomUserActionsMutationVariables
>;

/**
 * __useInsertZoomUserActionsMutation__
 *
 * To run a mutation, you first call `useInsertZoomUserActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomUserActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomUserActionsMutation, { data, loading, error }] = useInsertZoomUserActionsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomUserActionsMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomUserActionsMutation, InsertZoomUserActionsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomUserActionsMutation, InsertZoomUserActionsMutationVariables>(
    InsertZoomUserActionsDocument,
    options
  );
}
export type InsertZoomUserActionsMutationHookResult = ReturnType<typeof useInsertZoomUserActionsMutation>;
export type InsertZoomUserActionsMutationResult = Apollo.MutationResult<InsertZoomUserActionsMutation>;
export type InsertZoomUserActionsMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomUserActionsMutation,
  InsertZoomUserActionsMutationVariables
>;
export const InsertZoomUserActionsWithOnConflictDocument = gql`
  mutation insertZoomUserActionsWithOnConflict(
    $objects: [zoomUserActions_insert_input!]!
    $onConflict: zoomUserActions_on_conflict
  ) {
    insert_zoomUserActions(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomUserActions
      }
    }
  }
  ${ZoomUserActionsFragmentDoc}
`;
export type InsertZoomUserActionsWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomUserActionsWithOnConflictMutation,
  InsertZoomUserActionsWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomUserActionsWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomUserActionsWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomUserActionsWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomUserActionsWithOnConflictMutation, { data, loading, error }] = useInsertZoomUserActionsWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomUserActionsWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomUserActionsWithOnConflictMutation,
    InsertZoomUserActionsWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomUserActionsWithOnConflictMutation,
    InsertZoomUserActionsWithOnConflictMutationVariables
  >(InsertZoomUserActionsWithOnConflictDocument, options);
}
export type InsertZoomUserActionsWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomUserActionsWithOnConflictMutation
>;
export type InsertZoomUserActionsWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomUserActionsWithOnConflictMutation>;
export type InsertZoomUserActionsWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomUserActionsWithOnConflictMutation,
  InsertZoomUserActionsWithOnConflictMutationVariables
>;
export const UpdateZoomUserActionsByIdDocument = gql`
  mutation updateZoomUserActionsById($id: uuid, $set: zoomUserActions_set_input) {
    update_zoomUserActions(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomUserActions
      }
    }
  }
  ${ZoomUserActionsFragmentDoc}
`;
export type UpdateZoomUserActionsByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomUserActionsByIdMutation,
  UpdateZoomUserActionsByIdMutationVariables
>;

/**
 * __useUpdateZoomUserActionsByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomUserActionsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomUserActionsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomUserActionsByIdMutation, { data, loading, error }] = useUpdateZoomUserActionsByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomUserActionsByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomUserActionsByIdMutation,
    UpdateZoomUserActionsByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomUserActionsByIdMutation, UpdateZoomUserActionsByIdMutationVariables>(
    UpdateZoomUserActionsByIdDocument,
    options
  );
}
export type UpdateZoomUserActionsByIdMutationHookResult = ReturnType<typeof useUpdateZoomUserActionsByIdMutation>;
export type UpdateZoomUserActionsByIdMutationResult = Apollo.MutationResult<UpdateZoomUserActionsByIdMutation>;
export type UpdateZoomUserActionsByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomUserActionsByIdMutation,
  UpdateZoomUserActionsByIdMutationVariables
>;
export const UpdateZoomUserActionsDocument = gql`
  mutation updateZoomUserActions($set: zoomUserActions_set_input, $where: zoomUserActions_bool_exp!) {
    update_zoomUserActions(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomUserActions
      }
    }
  }
  ${ZoomUserActionsFragmentDoc}
`;
export type UpdateZoomUserActionsMutationFn = Apollo.MutationFunction<
  UpdateZoomUserActionsMutation,
  UpdateZoomUserActionsMutationVariables
>;

/**
 * __useUpdateZoomUserActionsMutation__
 *
 * To run a mutation, you first call `useUpdateZoomUserActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomUserActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomUserActionsMutation, { data, loading, error }] = useUpdateZoomUserActionsMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomUserActionsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomUserActionsMutation, UpdateZoomUserActionsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomUserActionsMutation, UpdateZoomUserActionsMutationVariables>(
    UpdateZoomUserActionsDocument,
    options
  );
}
export type UpdateZoomUserActionsMutationHookResult = ReturnType<typeof useUpdateZoomUserActionsMutation>;
export type UpdateZoomUserActionsMutationResult = Apollo.MutationResult<UpdateZoomUserActionsMutation>;
export type UpdateZoomUserActionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomUserActionsMutation,
  UpdateZoomUserActionsMutationVariables
>;
export const RemoveZoomUserActionsModelByIdDocument = gql`
  mutation removeZoomUserActionsModelById($id: uuid) {
    delete_zoomUserActions(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomUserActionsModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomUserActionsModelByIdMutation,
  RemoveZoomUserActionsModelByIdMutationVariables
>;

/**
 * __useRemoveZoomUserActionsModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomUserActionsModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomUserActionsModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomUserActionsModelByIdMutation, { data, loading, error }] = useRemoveZoomUserActionsModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomUserActionsModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomUserActionsModelByIdMutation,
    RemoveZoomUserActionsModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomUserActionsModelByIdMutation, RemoveZoomUserActionsModelByIdMutationVariables>(
    RemoveZoomUserActionsModelByIdDocument,
    options
  );
}
export type RemoveZoomUserActionsModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomUserActionsModelByIdMutation
>;
export type RemoveZoomUserActionsModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomUserActionsModelByIdMutation>;
export type RemoveZoomUserActionsModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomUserActionsModelByIdMutation,
  RemoveZoomUserActionsModelByIdMutationVariables
>;
export const RemoveZoomUserActionsModelDocument = gql`
  mutation removeZoomUserActionsModel($where: zoomUserActions_bool_exp!) {
    delete_zoomUserActions(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomUserActionsModelMutationFn = Apollo.MutationFunction<
  RemoveZoomUserActionsModelMutation,
  RemoveZoomUserActionsModelMutationVariables
>;

/**
 * __useRemoveZoomUserActionsModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomUserActionsModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomUserActionsModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomUserActionsModelMutation, { data, loading, error }] = useRemoveZoomUserActionsModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomUserActionsModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomUserActionsModelMutation,
    RemoveZoomUserActionsModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomUserActionsModelMutation, RemoveZoomUserActionsModelMutationVariables>(
    RemoveZoomUserActionsModelDocument,
    options
  );
}
export type RemoveZoomUserActionsModelMutationHookResult = ReturnType<typeof useRemoveZoomUserActionsModelMutation>;
export type RemoveZoomUserActionsModelMutationResult = Apollo.MutationResult<RemoveZoomUserActionsModelMutation>;
export type RemoveZoomUserActionsModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomUserActionsModelMutation,
  RemoveZoomUserActionsModelMutationVariables
>;
export const QueryZoomGroupMembershipByIdDocument = gql`
  query queryZoomGroupMembershipById($zoomGroupMembershipId: uuid!) {
    zoomGroupMembership_by_pk(id: $zoomGroupMembershipId) {
      ...ZoomGroupMembership
    }
  }
  ${ZoomGroupMembershipFragmentDoc}
`;

/**
 * __useQueryZoomGroupMembershipByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomGroupMembershipByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomGroupMembershipByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomGroupMembershipByIdQuery({
 *   variables: {
 *      zoomGroupMembershipId: // value for 'zoomGroupMembershipId'
 *   },
 * });
 */
export function useQueryZoomGroupMembershipByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomGroupMembershipByIdQuery, QueryZoomGroupMembershipByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomGroupMembershipByIdQuery, QueryZoomGroupMembershipByIdQueryVariables>(
    QueryZoomGroupMembershipByIdDocument,
    options
  );
}
export function useQueryZoomGroupMembershipByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomGroupMembershipByIdQuery,
    QueryZoomGroupMembershipByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomGroupMembershipByIdQuery, QueryZoomGroupMembershipByIdQueryVariables>(
    QueryZoomGroupMembershipByIdDocument,
    options
  );
}
export type QueryZoomGroupMembershipByIdQueryHookResult = ReturnType<typeof useQueryZoomGroupMembershipByIdQuery>;
export type QueryZoomGroupMembershipByIdLazyQueryHookResult = ReturnType<
  typeof useQueryZoomGroupMembershipByIdLazyQuery
>;
export type QueryZoomGroupMembershipByIdQueryResult = Apollo.QueryResult<
  QueryZoomGroupMembershipByIdQuery,
  QueryZoomGroupMembershipByIdQueryVariables
>;
export const QueryZoomGroupMembershipObjectsDocument = gql`
  query queryZoomGroupMembershipObjects(
    $distinct_on: [zoomGroupMembership_select_column!]
    $where: zoomGroupMembership_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomGroupMembership_order_by!]
  ) {
    zoomGroupMembership(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomGroupMembership
    }
  }
  ${ZoomGroupMembershipFragmentDoc}
`;

/**
 * __useQueryZoomGroupMembershipObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomGroupMembershipObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomGroupMembershipObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomGroupMembershipObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomGroupMembershipObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomGroupMembershipObjectsQuery,
    QueryZoomGroupMembershipObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomGroupMembershipObjectsQuery, QueryZoomGroupMembershipObjectsQueryVariables>(
    QueryZoomGroupMembershipObjectsDocument,
    options
  );
}
export function useQueryZoomGroupMembershipObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomGroupMembershipObjectsQuery,
    QueryZoomGroupMembershipObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomGroupMembershipObjectsQuery, QueryZoomGroupMembershipObjectsQueryVariables>(
    QueryZoomGroupMembershipObjectsDocument,
    options
  );
}
export type QueryZoomGroupMembershipObjectsQueryHookResult = ReturnType<typeof useQueryZoomGroupMembershipObjectsQuery>;
export type QueryZoomGroupMembershipObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomGroupMembershipObjectsLazyQuery
>;
export type QueryZoomGroupMembershipObjectsQueryResult = Apollo.QueryResult<
  QueryZoomGroupMembershipObjectsQuery,
  QueryZoomGroupMembershipObjectsQueryVariables
>;
export const SubscribeToZoomGroupMembershipByIdDocument = gql`
  subscription subscribeToZoomGroupMembershipById($zoomGroupMembershipId: uuid!) {
    zoomGroupMembership_by_pk(id: $zoomGroupMembershipId) {
      ...ZoomGroupMembership
    }
  }
  ${ZoomGroupMembershipFragmentDoc}
`;

/**
 * __useSubscribeToZoomGroupMembershipByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomGroupMembershipByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomGroupMembershipByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomGroupMembershipByIdSubscription({
 *   variables: {
 *      zoomGroupMembershipId: // value for 'zoomGroupMembershipId'
 *   },
 * });
 */
export function useSubscribeToZoomGroupMembershipByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomGroupMembershipByIdSubscription,
    SubscribeToZoomGroupMembershipByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomGroupMembershipByIdSubscription,
    SubscribeToZoomGroupMembershipByIdSubscriptionVariables
  >(SubscribeToZoomGroupMembershipByIdDocument, options);
}
export type SubscribeToZoomGroupMembershipByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomGroupMembershipByIdSubscription
>;
export type SubscribeToZoomGroupMembershipByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomGroupMembershipByIdSubscription>;
export const SubscribeToZoomGroupMembershipObjectsDocument = gql`
  subscription subscribeToZoomGroupMembershipObjects(
    $distinct_on: [zoomGroupMembership_select_column!]
    $where: zoomGroupMembership_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomGroupMembership_order_by!]
  ) {
    zoomGroupMembership(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomGroupMembership
    }
  }
  ${ZoomGroupMembershipFragmentDoc}
`;

/**
 * __useSubscribeToZoomGroupMembershipObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomGroupMembershipObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomGroupMembershipObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomGroupMembershipObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomGroupMembershipObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomGroupMembershipObjectsSubscription,
    SubscribeToZoomGroupMembershipObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomGroupMembershipObjectsSubscription,
    SubscribeToZoomGroupMembershipObjectsSubscriptionVariables
  >(SubscribeToZoomGroupMembershipObjectsDocument, options);
}
export type SubscribeToZoomGroupMembershipObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomGroupMembershipObjectsSubscription
>;
export type SubscribeToZoomGroupMembershipObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomGroupMembershipObjectsSubscription>;
export const InsertZoomGroupMembershipDocument = gql`
  mutation insertZoomGroupMembership($objects: [zoomGroupMembership_insert_input!]!) {
    insert_zoomGroupMembership(objects: $objects) {
      affected_rows
      returning {
        ...ZoomGroupMembership
      }
    }
  }
  ${ZoomGroupMembershipFragmentDoc}
`;
export type InsertZoomGroupMembershipMutationFn = Apollo.MutationFunction<
  InsertZoomGroupMembershipMutation,
  InsertZoomGroupMembershipMutationVariables
>;

/**
 * __useInsertZoomGroupMembershipMutation__
 *
 * To run a mutation, you first call `useInsertZoomGroupMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomGroupMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomGroupMembershipMutation, { data, loading, error }] = useInsertZoomGroupMembershipMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomGroupMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomGroupMembershipMutation,
    InsertZoomGroupMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomGroupMembershipMutation, InsertZoomGroupMembershipMutationVariables>(
    InsertZoomGroupMembershipDocument,
    options
  );
}
export type InsertZoomGroupMembershipMutationHookResult = ReturnType<typeof useInsertZoomGroupMembershipMutation>;
export type InsertZoomGroupMembershipMutationResult = Apollo.MutationResult<InsertZoomGroupMembershipMutation>;
export type InsertZoomGroupMembershipMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomGroupMembershipMutation,
  InsertZoomGroupMembershipMutationVariables
>;
export const InsertZoomGroupMembershipWithOnConflictDocument = gql`
  mutation insertZoomGroupMembershipWithOnConflict(
    $objects: [zoomGroupMembership_insert_input!]!
    $onConflict: zoomGroupMembership_on_conflict
  ) {
    insert_zoomGroupMembership(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomGroupMembership
      }
    }
  }
  ${ZoomGroupMembershipFragmentDoc}
`;
export type InsertZoomGroupMembershipWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomGroupMembershipWithOnConflictMutation,
  InsertZoomGroupMembershipWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomGroupMembershipWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomGroupMembershipWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomGroupMembershipWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomGroupMembershipWithOnConflictMutation, { data, loading, error }] = useInsertZoomGroupMembershipWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomGroupMembershipWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomGroupMembershipWithOnConflictMutation,
    InsertZoomGroupMembershipWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomGroupMembershipWithOnConflictMutation,
    InsertZoomGroupMembershipWithOnConflictMutationVariables
  >(InsertZoomGroupMembershipWithOnConflictDocument, options);
}
export type InsertZoomGroupMembershipWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomGroupMembershipWithOnConflictMutation
>;
export type InsertZoomGroupMembershipWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomGroupMembershipWithOnConflictMutation>;
export type InsertZoomGroupMembershipWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomGroupMembershipWithOnConflictMutation,
  InsertZoomGroupMembershipWithOnConflictMutationVariables
>;
export const UpdateZoomGroupMembershipByIdDocument = gql`
  mutation updateZoomGroupMembershipById($id: uuid, $set: zoomGroupMembership_set_input) {
    update_zoomGroupMembership(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomGroupMembership
      }
    }
  }
  ${ZoomGroupMembershipFragmentDoc}
`;
export type UpdateZoomGroupMembershipByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomGroupMembershipByIdMutation,
  UpdateZoomGroupMembershipByIdMutationVariables
>;

/**
 * __useUpdateZoomGroupMembershipByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomGroupMembershipByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomGroupMembershipByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomGroupMembershipByIdMutation, { data, loading, error }] = useUpdateZoomGroupMembershipByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomGroupMembershipByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomGroupMembershipByIdMutation,
    UpdateZoomGroupMembershipByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomGroupMembershipByIdMutation, UpdateZoomGroupMembershipByIdMutationVariables>(
    UpdateZoomGroupMembershipByIdDocument,
    options
  );
}
export type UpdateZoomGroupMembershipByIdMutationHookResult = ReturnType<
  typeof useUpdateZoomGroupMembershipByIdMutation
>;
export type UpdateZoomGroupMembershipByIdMutationResult = Apollo.MutationResult<UpdateZoomGroupMembershipByIdMutation>;
export type UpdateZoomGroupMembershipByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomGroupMembershipByIdMutation,
  UpdateZoomGroupMembershipByIdMutationVariables
>;
export const UpdateZoomGroupMembershipDocument = gql`
  mutation updateZoomGroupMembership($set: zoomGroupMembership_set_input, $where: zoomGroupMembership_bool_exp!) {
    update_zoomGroupMembership(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomGroupMembership
      }
    }
  }
  ${ZoomGroupMembershipFragmentDoc}
`;
export type UpdateZoomGroupMembershipMutationFn = Apollo.MutationFunction<
  UpdateZoomGroupMembershipMutation,
  UpdateZoomGroupMembershipMutationVariables
>;

/**
 * __useUpdateZoomGroupMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateZoomGroupMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomGroupMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomGroupMembershipMutation, { data, loading, error }] = useUpdateZoomGroupMembershipMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomGroupMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomGroupMembershipMutation,
    UpdateZoomGroupMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomGroupMembershipMutation, UpdateZoomGroupMembershipMutationVariables>(
    UpdateZoomGroupMembershipDocument,
    options
  );
}
export type UpdateZoomGroupMembershipMutationHookResult = ReturnType<typeof useUpdateZoomGroupMembershipMutation>;
export type UpdateZoomGroupMembershipMutationResult = Apollo.MutationResult<UpdateZoomGroupMembershipMutation>;
export type UpdateZoomGroupMembershipMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomGroupMembershipMutation,
  UpdateZoomGroupMembershipMutationVariables
>;
export const RemoveZoomGroupMembershipModelByIdDocument = gql`
  mutation removeZoomGroupMembershipModelById($id: uuid) {
    delete_zoomGroupMembership(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomGroupMembershipModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomGroupMembershipModelByIdMutation,
  RemoveZoomGroupMembershipModelByIdMutationVariables
>;

/**
 * __useRemoveZoomGroupMembershipModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomGroupMembershipModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomGroupMembershipModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomGroupMembershipModelByIdMutation, { data, loading, error }] = useRemoveZoomGroupMembershipModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomGroupMembershipModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomGroupMembershipModelByIdMutation,
    RemoveZoomGroupMembershipModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomGroupMembershipModelByIdMutation,
    RemoveZoomGroupMembershipModelByIdMutationVariables
  >(RemoveZoomGroupMembershipModelByIdDocument, options);
}
export type RemoveZoomGroupMembershipModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomGroupMembershipModelByIdMutation
>;
export type RemoveZoomGroupMembershipModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomGroupMembershipModelByIdMutation>;
export type RemoveZoomGroupMembershipModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomGroupMembershipModelByIdMutation,
  RemoveZoomGroupMembershipModelByIdMutationVariables
>;
export const RemoveZoomGroupMembershipModelDocument = gql`
  mutation removeZoomGroupMembershipModel($where: zoomGroupMembership_bool_exp!) {
    delete_zoomGroupMembership(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomGroupMembershipModelMutationFn = Apollo.MutationFunction<
  RemoveZoomGroupMembershipModelMutation,
  RemoveZoomGroupMembershipModelMutationVariables
>;

/**
 * __useRemoveZoomGroupMembershipModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomGroupMembershipModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomGroupMembershipModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomGroupMembershipModelMutation, { data, loading, error }] = useRemoveZoomGroupMembershipModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomGroupMembershipModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomGroupMembershipModelMutation,
    RemoveZoomGroupMembershipModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomGroupMembershipModelMutation, RemoveZoomGroupMembershipModelMutationVariables>(
    RemoveZoomGroupMembershipModelDocument,
    options
  );
}
export type RemoveZoomGroupMembershipModelMutationHookResult = ReturnType<
  typeof useRemoveZoomGroupMembershipModelMutation
>;
export type RemoveZoomGroupMembershipModelMutationResult =
  Apollo.MutationResult<RemoveZoomGroupMembershipModelMutation>;
export type RemoveZoomGroupMembershipModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomGroupMembershipModelMutation,
  RemoveZoomGroupMembershipModelMutationVariables
>;
export const QueryZoomUserByIdDocument = gql`
  query queryZoomUserById($zoomUserId: uuid!) {
    zoomUser_by_pk(id: $zoomUserId) {
      ...ZoomUser
    }
  }
  ${ZoomUserFragmentDoc}
`;

/**
 * __useQueryZoomUserByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomUserByIdQuery({
 *   variables: {
 *      zoomUserId: // value for 'zoomUserId'
 *   },
 * });
 */
export function useQueryZoomUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>(QueryZoomUserByIdDocument, options);
}
export function useQueryZoomUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>(
    QueryZoomUserByIdDocument,
    options
  );
}
export type QueryZoomUserByIdQueryHookResult = ReturnType<typeof useQueryZoomUserByIdQuery>;
export type QueryZoomUserByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomUserByIdLazyQuery>;
export type QueryZoomUserByIdQueryResult = Apollo.QueryResult<QueryZoomUserByIdQuery, QueryZoomUserByIdQueryVariables>;
export const QueryZoomUserObjectsDocument = gql`
  query queryZoomUserObjects(
    $distinct_on: [zoomUser_select_column!]
    $where: zoomUser_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomUser_order_by!]
  ) {
    zoomUser(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUser
    }
  }
  ${ZoomUserFragmentDoc}
`;

/**
 * __useQueryZoomUserObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomUserObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomUserObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomUserObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomUserObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomUserObjectsQuery, QueryZoomUserObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomUserObjectsQuery, QueryZoomUserObjectsQueryVariables>(
    QueryZoomUserObjectsDocument,
    options
  );
}
export function useQueryZoomUserObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomUserObjectsQuery, QueryZoomUserObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomUserObjectsQuery, QueryZoomUserObjectsQueryVariables>(
    QueryZoomUserObjectsDocument,
    options
  );
}
export type QueryZoomUserObjectsQueryHookResult = ReturnType<typeof useQueryZoomUserObjectsQuery>;
export type QueryZoomUserObjectsLazyQueryHookResult = ReturnType<typeof useQueryZoomUserObjectsLazyQuery>;
export type QueryZoomUserObjectsQueryResult = Apollo.QueryResult<
  QueryZoomUserObjectsQuery,
  QueryZoomUserObjectsQueryVariables
>;
export const SubscribeToZoomUserByIdDocument = gql`
  subscription subscribeToZoomUserById($zoomUserId: uuid!) {
    zoomUser_by_pk(id: $zoomUserId) {
      ...ZoomUser
    }
  }
  ${ZoomUserFragmentDoc}
`;

/**
 * __useSubscribeToZoomUserByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomUserByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomUserByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomUserByIdSubscription({
 *   variables: {
 *      zoomUserId: // value for 'zoomUserId'
 *   },
 * });
 */
export function useSubscribeToZoomUserByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomUserByIdSubscription,
    SubscribeToZoomUserByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SubscribeToZoomUserByIdSubscription, SubscribeToZoomUserByIdSubscriptionVariables>(
    SubscribeToZoomUserByIdDocument,
    options
  );
}
export type SubscribeToZoomUserByIdSubscriptionHookResult = ReturnType<typeof useSubscribeToZoomUserByIdSubscription>;
export type SubscribeToZoomUserByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToZoomUserByIdSubscription>;
export const SubscribeToZoomUserObjectsDocument = gql`
  subscription subscribeToZoomUserObjects(
    $distinct_on: [zoomUser_select_column!]
    $where: zoomUser_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomUser_order_by!]
  ) {
    zoomUser(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUser
    }
  }
  ${ZoomUserFragmentDoc}
`;

/**
 * __useSubscribeToZoomUserObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomUserObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomUserObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomUserObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomUserObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomUserObjectsSubscription,
    SubscribeToZoomUserObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomUserObjectsSubscription,
    SubscribeToZoomUserObjectsSubscriptionVariables
  >(SubscribeToZoomUserObjectsDocument, options);
}
export type SubscribeToZoomUserObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomUserObjectsSubscription
>;
export type SubscribeToZoomUserObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomUserObjectsSubscription>;
export const InsertZoomUserDocument = gql`
  mutation insertZoomUser($objects: [zoomUser_insert_input!]!) {
    insert_zoomUser(objects: $objects) {
      affected_rows
      returning {
        ...ZoomUser
      }
    }
  }
  ${ZoomUserFragmentDoc}
`;
export type InsertZoomUserMutationFn = Apollo.MutationFunction<InsertZoomUserMutation, InsertZoomUserMutationVariables>;

/**
 * __useInsertZoomUserMutation__
 *
 * To run a mutation, you first call `useInsertZoomUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomUserMutation, { data, loading, error }] = useInsertZoomUserMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomUserMutation, InsertZoomUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomUserMutation, InsertZoomUserMutationVariables>(InsertZoomUserDocument, options);
}
export type InsertZoomUserMutationHookResult = ReturnType<typeof useInsertZoomUserMutation>;
export type InsertZoomUserMutationResult = Apollo.MutationResult<InsertZoomUserMutation>;
export type InsertZoomUserMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomUserMutation,
  InsertZoomUserMutationVariables
>;
export const InsertZoomUserWithOnConflictDocument = gql`
  mutation insertZoomUserWithOnConflict($objects: [zoomUser_insert_input!]!, $onConflict: zoomUser_on_conflict) {
    insert_zoomUser(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomUser
      }
    }
  }
  ${ZoomUserFragmentDoc}
`;
export type InsertZoomUserWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomUserWithOnConflictMutation,
  InsertZoomUserWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomUserWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomUserWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomUserWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomUserWithOnConflictMutation, { data, loading, error }] = useInsertZoomUserWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomUserWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomUserWithOnConflictMutation,
    InsertZoomUserWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomUserWithOnConflictMutation, InsertZoomUserWithOnConflictMutationVariables>(
    InsertZoomUserWithOnConflictDocument,
    options
  );
}
export type InsertZoomUserWithOnConflictMutationHookResult = ReturnType<typeof useInsertZoomUserWithOnConflictMutation>;
export type InsertZoomUserWithOnConflictMutationResult = Apollo.MutationResult<InsertZoomUserWithOnConflictMutation>;
export type InsertZoomUserWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomUserWithOnConflictMutation,
  InsertZoomUserWithOnConflictMutationVariables
>;
export const UpdateZoomUserByIdDocument = gql`
  mutation updateZoomUserById($id: uuid, $set: zoomUser_set_input) {
    update_zoomUser(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomUser
      }
    }
  }
  ${ZoomUserFragmentDoc}
`;
export type UpdateZoomUserByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomUserByIdMutation,
  UpdateZoomUserByIdMutationVariables
>;

/**
 * __useUpdateZoomUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomUserByIdMutation, { data, loading, error }] = useUpdateZoomUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomUserByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomUserByIdMutation, UpdateZoomUserByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomUserByIdMutation, UpdateZoomUserByIdMutationVariables>(
    UpdateZoomUserByIdDocument,
    options
  );
}
export type UpdateZoomUserByIdMutationHookResult = ReturnType<typeof useUpdateZoomUserByIdMutation>;
export type UpdateZoomUserByIdMutationResult = Apollo.MutationResult<UpdateZoomUserByIdMutation>;
export type UpdateZoomUserByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomUserByIdMutation,
  UpdateZoomUserByIdMutationVariables
>;
export const UpdateZoomUserDocument = gql`
  mutation updateZoomUser($set: zoomUser_set_input, $where: zoomUser_bool_exp!) {
    update_zoomUser(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomUser
      }
    }
  }
  ${ZoomUserFragmentDoc}
`;
export type UpdateZoomUserMutationFn = Apollo.MutationFunction<UpdateZoomUserMutation, UpdateZoomUserMutationVariables>;

/**
 * __useUpdateZoomUserMutation__
 *
 * To run a mutation, you first call `useUpdateZoomUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomUserMutation, { data, loading, error }] = useUpdateZoomUserMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomUserMutation, UpdateZoomUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomUserMutation, UpdateZoomUserMutationVariables>(UpdateZoomUserDocument, options);
}
export type UpdateZoomUserMutationHookResult = ReturnType<typeof useUpdateZoomUserMutation>;
export type UpdateZoomUserMutationResult = Apollo.MutationResult<UpdateZoomUserMutation>;
export type UpdateZoomUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomUserMutation,
  UpdateZoomUserMutationVariables
>;
export const RemoveZoomUserModelByIdDocument = gql`
  mutation removeZoomUserModelById($id: uuid) {
    delete_zoomUser(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomUserModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomUserModelByIdMutation,
  RemoveZoomUserModelByIdMutationVariables
>;

/**
 * __useRemoveZoomUserModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomUserModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomUserModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomUserModelByIdMutation, { data, loading, error }] = useRemoveZoomUserModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomUserModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveZoomUserModelByIdMutation, RemoveZoomUserModelByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomUserModelByIdMutation, RemoveZoomUserModelByIdMutationVariables>(
    RemoveZoomUserModelByIdDocument,
    options
  );
}
export type RemoveZoomUserModelByIdMutationHookResult = ReturnType<typeof useRemoveZoomUserModelByIdMutation>;
export type RemoveZoomUserModelByIdMutationResult = Apollo.MutationResult<RemoveZoomUserModelByIdMutation>;
export type RemoveZoomUserModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomUserModelByIdMutation,
  RemoveZoomUserModelByIdMutationVariables
>;
export const RemoveZoomUserModelDocument = gql`
  mutation removeZoomUserModel($where: zoomUser_bool_exp!) {
    delete_zoomUser(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomUserModelMutationFn = Apollo.MutationFunction<
  RemoveZoomUserModelMutation,
  RemoveZoomUserModelMutationVariables
>;

/**
 * __useRemoveZoomUserModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomUserModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomUserModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomUserModelMutation, { data, loading, error }] = useRemoveZoomUserModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomUserModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveZoomUserModelMutation, RemoveZoomUserModelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomUserModelMutation, RemoveZoomUserModelMutationVariables>(
    RemoveZoomUserModelDocument,
    options
  );
}
export type RemoveZoomUserModelMutationHookResult = ReturnType<typeof useRemoveZoomUserModelMutation>;
export type RemoveZoomUserModelMutationResult = Apollo.MutationResult<RemoveZoomUserModelMutation>;
export type RemoveZoomUserModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomUserModelMutation,
  RemoveZoomUserModelMutationVariables
>;
export const QueryZoomContactNotesByIdDocument = gql`
  query queryZoomContactNotesById($zoomContactNotesId: uuid!) {
    zoomContactNotes_by_pk(id: $zoomContactNotesId) {
      ...ZoomContactNotes
    }
  }
  ${ZoomContactNotesFragmentDoc}
`;

/**
 * __useQueryZoomContactNotesByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomContactNotesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomContactNotesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomContactNotesByIdQuery({
 *   variables: {
 *      zoomContactNotesId: // value for 'zoomContactNotesId'
 *   },
 * });
 */
export function useQueryZoomContactNotesByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomContactNotesByIdQuery, QueryZoomContactNotesByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomContactNotesByIdQuery, QueryZoomContactNotesByIdQueryVariables>(
    QueryZoomContactNotesByIdDocument,
    options
  );
}
export function useQueryZoomContactNotesByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomContactNotesByIdQuery, QueryZoomContactNotesByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomContactNotesByIdQuery, QueryZoomContactNotesByIdQueryVariables>(
    QueryZoomContactNotesByIdDocument,
    options
  );
}
export type QueryZoomContactNotesByIdQueryHookResult = ReturnType<typeof useQueryZoomContactNotesByIdQuery>;
export type QueryZoomContactNotesByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomContactNotesByIdLazyQuery>;
export type QueryZoomContactNotesByIdQueryResult = Apollo.QueryResult<
  QueryZoomContactNotesByIdQuery,
  QueryZoomContactNotesByIdQueryVariables
>;
export const QueryZoomContactNotesObjectsDocument = gql`
  query queryZoomContactNotesObjects(
    $distinct_on: [zoomContactNotes_select_column!]
    $where: zoomContactNotes_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomContactNotes_order_by!]
  ) {
    zoomContactNotes(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomContactNotes
    }
  }
  ${ZoomContactNotesFragmentDoc}
`;

/**
 * __useQueryZoomContactNotesObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomContactNotesObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomContactNotesObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomContactNotesObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomContactNotesObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomContactNotesObjectsQuery, QueryZoomContactNotesObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomContactNotesObjectsQuery, QueryZoomContactNotesObjectsQueryVariables>(
    QueryZoomContactNotesObjectsDocument,
    options
  );
}
export function useQueryZoomContactNotesObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomContactNotesObjectsQuery,
    QueryZoomContactNotesObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomContactNotesObjectsQuery, QueryZoomContactNotesObjectsQueryVariables>(
    QueryZoomContactNotesObjectsDocument,
    options
  );
}
export type QueryZoomContactNotesObjectsQueryHookResult = ReturnType<typeof useQueryZoomContactNotesObjectsQuery>;
export type QueryZoomContactNotesObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomContactNotesObjectsLazyQuery
>;
export type QueryZoomContactNotesObjectsQueryResult = Apollo.QueryResult<
  QueryZoomContactNotesObjectsQuery,
  QueryZoomContactNotesObjectsQueryVariables
>;
export const SubscribeToZoomContactNotesByIdDocument = gql`
  subscription subscribeToZoomContactNotesById($zoomContactNotesId: uuid!) {
    zoomContactNotes_by_pk(id: $zoomContactNotesId) {
      ...ZoomContactNotes
    }
  }
  ${ZoomContactNotesFragmentDoc}
`;

/**
 * __useSubscribeToZoomContactNotesByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomContactNotesByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomContactNotesByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomContactNotesByIdSubscription({
 *   variables: {
 *      zoomContactNotesId: // value for 'zoomContactNotesId'
 *   },
 * });
 */
export function useSubscribeToZoomContactNotesByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomContactNotesByIdSubscription,
    SubscribeToZoomContactNotesByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomContactNotesByIdSubscription,
    SubscribeToZoomContactNotesByIdSubscriptionVariables
  >(SubscribeToZoomContactNotesByIdDocument, options);
}
export type SubscribeToZoomContactNotesByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomContactNotesByIdSubscription
>;
export type SubscribeToZoomContactNotesByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomContactNotesByIdSubscription>;
export const SubscribeToZoomContactNotesObjectsDocument = gql`
  subscription subscribeToZoomContactNotesObjects(
    $distinct_on: [zoomContactNotes_select_column!]
    $where: zoomContactNotes_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomContactNotes_order_by!]
  ) {
    zoomContactNotes(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomContactNotes
    }
  }
  ${ZoomContactNotesFragmentDoc}
`;

/**
 * __useSubscribeToZoomContactNotesObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomContactNotesObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomContactNotesObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomContactNotesObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomContactNotesObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomContactNotesObjectsSubscription,
    SubscribeToZoomContactNotesObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomContactNotesObjectsSubscription,
    SubscribeToZoomContactNotesObjectsSubscriptionVariables
  >(SubscribeToZoomContactNotesObjectsDocument, options);
}
export type SubscribeToZoomContactNotesObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomContactNotesObjectsSubscription
>;
export type SubscribeToZoomContactNotesObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomContactNotesObjectsSubscription>;
export const InsertZoomContactNotesDocument = gql`
  mutation insertZoomContactNotes($objects: [zoomContactNotes_insert_input!]!) {
    insert_zoomContactNotes(objects: $objects) {
      affected_rows
      returning {
        ...ZoomContactNotes
      }
    }
  }
  ${ZoomContactNotesFragmentDoc}
`;
export type InsertZoomContactNotesMutationFn = Apollo.MutationFunction<
  InsertZoomContactNotesMutation,
  InsertZoomContactNotesMutationVariables
>;

/**
 * __useInsertZoomContactNotesMutation__
 *
 * To run a mutation, you first call `useInsertZoomContactNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomContactNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomContactNotesMutation, { data, loading, error }] = useInsertZoomContactNotesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomContactNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomContactNotesMutation, InsertZoomContactNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomContactNotesMutation, InsertZoomContactNotesMutationVariables>(
    InsertZoomContactNotesDocument,
    options
  );
}
export type InsertZoomContactNotesMutationHookResult = ReturnType<typeof useInsertZoomContactNotesMutation>;
export type InsertZoomContactNotesMutationResult = Apollo.MutationResult<InsertZoomContactNotesMutation>;
export type InsertZoomContactNotesMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomContactNotesMutation,
  InsertZoomContactNotesMutationVariables
>;
export const InsertZoomContactNotesWithOnConflictDocument = gql`
  mutation insertZoomContactNotesWithOnConflict(
    $objects: [zoomContactNotes_insert_input!]!
    $onConflict: zoomContactNotes_on_conflict
  ) {
    insert_zoomContactNotes(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomContactNotes
      }
    }
  }
  ${ZoomContactNotesFragmentDoc}
`;
export type InsertZoomContactNotesWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomContactNotesWithOnConflictMutation,
  InsertZoomContactNotesWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomContactNotesWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomContactNotesWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomContactNotesWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomContactNotesWithOnConflictMutation, { data, loading, error }] = useInsertZoomContactNotesWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomContactNotesWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomContactNotesWithOnConflictMutation,
    InsertZoomContactNotesWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomContactNotesWithOnConflictMutation,
    InsertZoomContactNotesWithOnConflictMutationVariables
  >(InsertZoomContactNotesWithOnConflictDocument, options);
}
export type InsertZoomContactNotesWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomContactNotesWithOnConflictMutation
>;
export type InsertZoomContactNotesWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomContactNotesWithOnConflictMutation>;
export type InsertZoomContactNotesWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomContactNotesWithOnConflictMutation,
  InsertZoomContactNotesWithOnConflictMutationVariables
>;
export const UpdateZoomContactNotesByIdDocument = gql`
  mutation updateZoomContactNotesById($id: uuid, $set: zoomContactNotes_set_input) {
    update_zoomContactNotes(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomContactNotes
      }
    }
  }
  ${ZoomContactNotesFragmentDoc}
`;
export type UpdateZoomContactNotesByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomContactNotesByIdMutation,
  UpdateZoomContactNotesByIdMutationVariables
>;

/**
 * __useUpdateZoomContactNotesByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomContactNotesByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomContactNotesByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomContactNotesByIdMutation, { data, loading, error }] = useUpdateZoomContactNotesByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomContactNotesByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomContactNotesByIdMutation,
    UpdateZoomContactNotesByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomContactNotesByIdMutation, UpdateZoomContactNotesByIdMutationVariables>(
    UpdateZoomContactNotesByIdDocument,
    options
  );
}
export type UpdateZoomContactNotesByIdMutationHookResult = ReturnType<typeof useUpdateZoomContactNotesByIdMutation>;
export type UpdateZoomContactNotesByIdMutationResult = Apollo.MutationResult<UpdateZoomContactNotesByIdMutation>;
export type UpdateZoomContactNotesByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomContactNotesByIdMutation,
  UpdateZoomContactNotesByIdMutationVariables
>;
export const UpdateZoomContactNotesDocument = gql`
  mutation updateZoomContactNotes($set: zoomContactNotes_set_input, $where: zoomContactNotes_bool_exp!) {
    update_zoomContactNotes(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomContactNotes
      }
    }
  }
  ${ZoomContactNotesFragmentDoc}
`;
export type UpdateZoomContactNotesMutationFn = Apollo.MutationFunction<
  UpdateZoomContactNotesMutation,
  UpdateZoomContactNotesMutationVariables
>;

/**
 * __useUpdateZoomContactNotesMutation__
 *
 * To run a mutation, you first call `useUpdateZoomContactNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomContactNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomContactNotesMutation, { data, loading, error }] = useUpdateZoomContactNotesMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomContactNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomContactNotesMutation, UpdateZoomContactNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomContactNotesMutation, UpdateZoomContactNotesMutationVariables>(
    UpdateZoomContactNotesDocument,
    options
  );
}
export type UpdateZoomContactNotesMutationHookResult = ReturnType<typeof useUpdateZoomContactNotesMutation>;
export type UpdateZoomContactNotesMutationResult = Apollo.MutationResult<UpdateZoomContactNotesMutation>;
export type UpdateZoomContactNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomContactNotesMutation,
  UpdateZoomContactNotesMutationVariables
>;
export const RemoveZoomContactNotesModelByIdDocument = gql`
  mutation removeZoomContactNotesModelById($id: uuid) {
    delete_zoomContactNotes(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomContactNotesModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomContactNotesModelByIdMutation,
  RemoveZoomContactNotesModelByIdMutationVariables
>;

/**
 * __useRemoveZoomContactNotesModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomContactNotesModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomContactNotesModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomContactNotesModelByIdMutation, { data, loading, error }] = useRemoveZoomContactNotesModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomContactNotesModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomContactNotesModelByIdMutation,
    RemoveZoomContactNotesModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomContactNotesModelByIdMutation, RemoveZoomContactNotesModelByIdMutationVariables>(
    RemoveZoomContactNotesModelByIdDocument,
    options
  );
}
export type RemoveZoomContactNotesModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomContactNotesModelByIdMutation
>;
export type RemoveZoomContactNotesModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomContactNotesModelByIdMutation>;
export type RemoveZoomContactNotesModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomContactNotesModelByIdMutation,
  RemoveZoomContactNotesModelByIdMutationVariables
>;
export const RemoveZoomContactNotesModelDocument = gql`
  mutation removeZoomContactNotesModel($where: zoomContactNotes_bool_exp!) {
    delete_zoomContactNotes(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomContactNotesModelMutationFn = Apollo.MutationFunction<
  RemoveZoomContactNotesModelMutation,
  RemoveZoomContactNotesModelMutationVariables
>;

/**
 * __useRemoveZoomContactNotesModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomContactNotesModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomContactNotesModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomContactNotesModelMutation, { data, loading, error }] = useRemoveZoomContactNotesModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomContactNotesModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomContactNotesModelMutation,
    RemoveZoomContactNotesModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomContactNotesModelMutation, RemoveZoomContactNotesModelMutationVariables>(
    RemoveZoomContactNotesModelDocument,
    options
  );
}
export type RemoveZoomContactNotesModelMutationHookResult = ReturnType<typeof useRemoveZoomContactNotesModelMutation>;
export type RemoveZoomContactNotesModelMutationResult = Apollo.MutationResult<RemoveZoomContactNotesModelMutation>;
export type RemoveZoomContactNotesModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomContactNotesModelMutation,
  RemoveZoomContactNotesModelMutationVariables
>;
export const QueryGoogleChannelByIdDocument = gql`
  query queryGoogleChannelById($googleChannelId: Int!) {
    googleChannel_by_pk(id: $googleChannelId) {
      ...GoogleChannel
    }
  }
  ${GoogleChannelFragmentDoc}
`;

/**
 * __useQueryGoogleChannelByIdQuery__
 *
 * To run a query within a React component, call `useQueryGoogleChannelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGoogleChannelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGoogleChannelByIdQuery({
 *   variables: {
 *      googleChannelId: // value for 'googleChannelId'
 *   },
 * });
 */
export function useQueryGoogleChannelByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryGoogleChannelByIdQuery, QueryGoogleChannelByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGoogleChannelByIdQuery, QueryGoogleChannelByIdQueryVariables>(
    QueryGoogleChannelByIdDocument,
    options
  );
}
export function useQueryGoogleChannelByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryGoogleChannelByIdQuery, QueryGoogleChannelByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGoogleChannelByIdQuery, QueryGoogleChannelByIdQueryVariables>(
    QueryGoogleChannelByIdDocument,
    options
  );
}
export type QueryGoogleChannelByIdQueryHookResult = ReturnType<typeof useQueryGoogleChannelByIdQuery>;
export type QueryGoogleChannelByIdLazyQueryHookResult = ReturnType<typeof useQueryGoogleChannelByIdLazyQuery>;
export type QueryGoogleChannelByIdQueryResult = Apollo.QueryResult<
  QueryGoogleChannelByIdQuery,
  QueryGoogleChannelByIdQueryVariables
>;
export const QueryGoogleChannelObjectsDocument = gql`
  query queryGoogleChannelObjects(
    $distinct_on: [googleChannel_select_column!]
    $where: googleChannel_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [googleChannel_order_by!]
  ) {
    googleChannel(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...GoogleChannel
    }
  }
  ${GoogleChannelFragmentDoc}
`;

/**
 * __useQueryGoogleChannelObjectsQuery__
 *
 * To run a query within a React component, call `useQueryGoogleChannelObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGoogleChannelObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGoogleChannelObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryGoogleChannelObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryGoogleChannelObjectsQuery, QueryGoogleChannelObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGoogleChannelObjectsQuery, QueryGoogleChannelObjectsQueryVariables>(
    QueryGoogleChannelObjectsDocument,
    options
  );
}
export function useQueryGoogleChannelObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryGoogleChannelObjectsQuery, QueryGoogleChannelObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGoogleChannelObjectsQuery, QueryGoogleChannelObjectsQueryVariables>(
    QueryGoogleChannelObjectsDocument,
    options
  );
}
export type QueryGoogleChannelObjectsQueryHookResult = ReturnType<typeof useQueryGoogleChannelObjectsQuery>;
export type QueryGoogleChannelObjectsLazyQueryHookResult = ReturnType<typeof useQueryGoogleChannelObjectsLazyQuery>;
export type QueryGoogleChannelObjectsQueryResult = Apollo.QueryResult<
  QueryGoogleChannelObjectsQuery,
  QueryGoogleChannelObjectsQueryVariables
>;
export const SubscribeToGoogleChannelByIdDocument = gql`
  subscription subscribeToGoogleChannelById($googleChannelId: Int!) {
    googleChannel_by_pk(id: $googleChannelId) {
      ...GoogleChannel
    }
  }
  ${GoogleChannelFragmentDoc}
`;

/**
 * __useSubscribeToGoogleChannelByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToGoogleChannelByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToGoogleChannelByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToGoogleChannelByIdSubscription({
 *   variables: {
 *      googleChannelId: // value for 'googleChannelId'
 *   },
 * });
 */
export function useSubscribeToGoogleChannelByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToGoogleChannelByIdSubscription,
    SubscribeToGoogleChannelByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToGoogleChannelByIdSubscription,
    SubscribeToGoogleChannelByIdSubscriptionVariables
  >(SubscribeToGoogleChannelByIdDocument, options);
}
export type SubscribeToGoogleChannelByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToGoogleChannelByIdSubscription
>;
export type SubscribeToGoogleChannelByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToGoogleChannelByIdSubscription>;
export const SubscribeToGoogleChannelObjectsDocument = gql`
  subscription subscribeToGoogleChannelObjects(
    $distinct_on: [googleChannel_select_column!]
    $where: googleChannel_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [googleChannel_order_by!]
  ) {
    googleChannel(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...GoogleChannel
    }
  }
  ${GoogleChannelFragmentDoc}
`;

/**
 * __useSubscribeToGoogleChannelObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToGoogleChannelObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToGoogleChannelObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToGoogleChannelObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToGoogleChannelObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToGoogleChannelObjectsSubscription,
    SubscribeToGoogleChannelObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToGoogleChannelObjectsSubscription,
    SubscribeToGoogleChannelObjectsSubscriptionVariables
  >(SubscribeToGoogleChannelObjectsDocument, options);
}
export type SubscribeToGoogleChannelObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToGoogleChannelObjectsSubscription
>;
export type SubscribeToGoogleChannelObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToGoogleChannelObjectsSubscription>;
export const InsertGoogleChannelDocument = gql`
  mutation insertGoogleChannel($objects: [googleChannel_insert_input!]!) {
    insert_googleChannel(objects: $objects) {
      affected_rows
      returning {
        ...GoogleChannel
      }
    }
  }
  ${GoogleChannelFragmentDoc}
`;
export type InsertGoogleChannelMutationFn = Apollo.MutationFunction<
  InsertGoogleChannelMutation,
  InsertGoogleChannelMutationVariables
>;

/**
 * __useInsertGoogleChannelMutation__
 *
 * To run a mutation, you first call `useInsertGoogleChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGoogleChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGoogleChannelMutation, { data, loading, error }] = useInsertGoogleChannelMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertGoogleChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertGoogleChannelMutation, InsertGoogleChannelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertGoogleChannelMutation, InsertGoogleChannelMutationVariables>(
    InsertGoogleChannelDocument,
    options
  );
}
export type InsertGoogleChannelMutationHookResult = ReturnType<typeof useInsertGoogleChannelMutation>;
export type InsertGoogleChannelMutationResult = Apollo.MutationResult<InsertGoogleChannelMutation>;
export type InsertGoogleChannelMutationOptions = Apollo.BaseMutationOptions<
  InsertGoogleChannelMutation,
  InsertGoogleChannelMutationVariables
>;
export const InsertGoogleChannelWithOnConflictDocument = gql`
  mutation insertGoogleChannelWithOnConflict(
    $objects: [googleChannel_insert_input!]!
    $onConflict: googleChannel_on_conflict
  ) {
    insert_googleChannel(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...GoogleChannel
      }
    }
  }
  ${GoogleChannelFragmentDoc}
`;
export type InsertGoogleChannelWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertGoogleChannelWithOnConflictMutation,
  InsertGoogleChannelWithOnConflictMutationVariables
>;

/**
 * __useInsertGoogleChannelWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertGoogleChannelWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGoogleChannelWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGoogleChannelWithOnConflictMutation, { data, loading, error }] = useInsertGoogleChannelWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertGoogleChannelWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertGoogleChannelWithOnConflictMutation,
    InsertGoogleChannelWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertGoogleChannelWithOnConflictMutation,
    InsertGoogleChannelWithOnConflictMutationVariables
  >(InsertGoogleChannelWithOnConflictDocument, options);
}
export type InsertGoogleChannelWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertGoogleChannelWithOnConflictMutation
>;
export type InsertGoogleChannelWithOnConflictMutationResult =
  Apollo.MutationResult<InsertGoogleChannelWithOnConflictMutation>;
export type InsertGoogleChannelWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertGoogleChannelWithOnConflictMutation,
  InsertGoogleChannelWithOnConflictMutationVariables
>;
export const UpdateGoogleChannelByIdDocument = gql`
  mutation updateGoogleChannelById($id: Int, $set: googleChannel_set_input) {
    update_googleChannel(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...GoogleChannel
      }
    }
  }
  ${GoogleChannelFragmentDoc}
`;
export type UpdateGoogleChannelByIdMutationFn = Apollo.MutationFunction<
  UpdateGoogleChannelByIdMutation,
  UpdateGoogleChannelByIdMutationVariables
>;

/**
 * __useUpdateGoogleChannelByIdMutation__
 *
 * To run a mutation, you first call `useUpdateGoogleChannelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoogleChannelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoogleChannelByIdMutation, { data, loading, error }] = useUpdateGoogleChannelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateGoogleChannelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateGoogleChannelByIdMutation, UpdateGoogleChannelByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGoogleChannelByIdMutation, UpdateGoogleChannelByIdMutationVariables>(
    UpdateGoogleChannelByIdDocument,
    options
  );
}
export type UpdateGoogleChannelByIdMutationHookResult = ReturnType<typeof useUpdateGoogleChannelByIdMutation>;
export type UpdateGoogleChannelByIdMutationResult = Apollo.MutationResult<UpdateGoogleChannelByIdMutation>;
export type UpdateGoogleChannelByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateGoogleChannelByIdMutation,
  UpdateGoogleChannelByIdMutationVariables
>;
export const UpdateGoogleChannelDocument = gql`
  mutation updateGoogleChannel($set: googleChannel_set_input, $where: googleChannel_bool_exp!) {
    update_googleChannel(_set: $set, where: $where) {
      affected_rows
      returning {
        ...GoogleChannel
      }
    }
  }
  ${GoogleChannelFragmentDoc}
`;
export type UpdateGoogleChannelMutationFn = Apollo.MutationFunction<
  UpdateGoogleChannelMutation,
  UpdateGoogleChannelMutationVariables
>;

/**
 * __useUpdateGoogleChannelMutation__
 *
 * To run a mutation, you first call `useUpdateGoogleChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoogleChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoogleChannelMutation, { data, loading, error }] = useUpdateGoogleChannelMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateGoogleChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateGoogleChannelMutation, UpdateGoogleChannelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGoogleChannelMutation, UpdateGoogleChannelMutationVariables>(
    UpdateGoogleChannelDocument,
    options
  );
}
export type UpdateGoogleChannelMutationHookResult = ReturnType<typeof useUpdateGoogleChannelMutation>;
export type UpdateGoogleChannelMutationResult = Apollo.MutationResult<UpdateGoogleChannelMutation>;
export type UpdateGoogleChannelMutationOptions = Apollo.BaseMutationOptions<
  UpdateGoogleChannelMutation,
  UpdateGoogleChannelMutationVariables
>;
export const RemoveGoogleChannelModelByIdDocument = gql`
  mutation removeGoogleChannelModelById($id: Int) {
    delete_googleChannel(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveGoogleChannelModelByIdMutationFn = Apollo.MutationFunction<
  RemoveGoogleChannelModelByIdMutation,
  RemoveGoogleChannelModelByIdMutationVariables
>;

/**
 * __useRemoveGoogleChannelModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveGoogleChannelModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoogleChannelModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoogleChannelModelByIdMutation, { data, loading, error }] = useRemoveGoogleChannelModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGoogleChannelModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGoogleChannelModelByIdMutation,
    RemoveGoogleChannelModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveGoogleChannelModelByIdMutation, RemoveGoogleChannelModelByIdMutationVariables>(
    RemoveGoogleChannelModelByIdDocument,
    options
  );
}
export type RemoveGoogleChannelModelByIdMutationHookResult = ReturnType<typeof useRemoveGoogleChannelModelByIdMutation>;
export type RemoveGoogleChannelModelByIdMutationResult = Apollo.MutationResult<RemoveGoogleChannelModelByIdMutation>;
export type RemoveGoogleChannelModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveGoogleChannelModelByIdMutation,
  RemoveGoogleChannelModelByIdMutationVariables
>;
export const RemoveGoogleChannelModelDocument = gql`
  mutation removeGoogleChannelModel($where: googleChannel_bool_exp!) {
    delete_googleChannel(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveGoogleChannelModelMutationFn = Apollo.MutationFunction<
  RemoveGoogleChannelModelMutation,
  RemoveGoogleChannelModelMutationVariables
>;

/**
 * __useRemoveGoogleChannelModelMutation__
 *
 * To run a mutation, you first call `useRemoveGoogleChannelModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoogleChannelModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoogleChannelModelMutation, { data, loading, error }] = useRemoveGoogleChannelModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveGoogleChannelModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveGoogleChannelModelMutation, RemoveGoogleChannelModelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveGoogleChannelModelMutation, RemoveGoogleChannelModelMutationVariables>(
    RemoveGoogleChannelModelDocument,
    options
  );
}
export type RemoveGoogleChannelModelMutationHookResult = ReturnType<typeof useRemoveGoogleChannelModelMutation>;
export type RemoveGoogleChannelModelMutationResult = Apollo.MutationResult<RemoveGoogleChannelModelMutation>;
export type RemoveGoogleChannelModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveGoogleChannelModelMutation,
  RemoveGoogleChannelModelMutationVariables
>;
export const QueryZoomEmailHistoryByIdDocument = gql`
  query queryZoomEmailHistoryById($zoomEmailHistoryId: uuid!) {
    zoomEmailHistory_by_pk(id: $zoomEmailHistoryId) {
      ...ZoomEmailHistory
    }
  }
  ${ZoomEmailHistoryFragmentDoc}
`;

/**
 * __useQueryZoomEmailHistoryByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomEmailHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomEmailHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomEmailHistoryByIdQuery({
 *   variables: {
 *      zoomEmailHistoryId: // value for 'zoomEmailHistoryId'
 *   },
 * });
 */
export function useQueryZoomEmailHistoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomEmailHistoryByIdQuery, QueryZoomEmailHistoryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomEmailHistoryByIdQuery, QueryZoomEmailHistoryByIdQueryVariables>(
    QueryZoomEmailHistoryByIdDocument,
    options
  );
}
export function useQueryZoomEmailHistoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomEmailHistoryByIdQuery, QueryZoomEmailHistoryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomEmailHistoryByIdQuery, QueryZoomEmailHistoryByIdQueryVariables>(
    QueryZoomEmailHistoryByIdDocument,
    options
  );
}
export type QueryZoomEmailHistoryByIdQueryHookResult = ReturnType<typeof useQueryZoomEmailHistoryByIdQuery>;
export type QueryZoomEmailHistoryByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomEmailHistoryByIdLazyQuery>;
export type QueryZoomEmailHistoryByIdQueryResult = Apollo.QueryResult<
  QueryZoomEmailHistoryByIdQuery,
  QueryZoomEmailHistoryByIdQueryVariables
>;
export const QueryZoomEmailHistoryObjectsDocument = gql`
  query queryZoomEmailHistoryObjects(
    $distinct_on: [zoomEmailHistory_select_column!]
    $where: zoomEmailHistory_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomEmailHistory_order_by!]
  ) {
    zoomEmailHistory(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomEmailHistory
    }
  }
  ${ZoomEmailHistoryFragmentDoc}
`;

/**
 * __useQueryZoomEmailHistoryObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomEmailHistoryObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomEmailHistoryObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomEmailHistoryObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomEmailHistoryObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomEmailHistoryObjectsQuery, QueryZoomEmailHistoryObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomEmailHistoryObjectsQuery, QueryZoomEmailHistoryObjectsQueryVariables>(
    QueryZoomEmailHistoryObjectsDocument,
    options
  );
}
export function useQueryZoomEmailHistoryObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomEmailHistoryObjectsQuery,
    QueryZoomEmailHistoryObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomEmailHistoryObjectsQuery, QueryZoomEmailHistoryObjectsQueryVariables>(
    QueryZoomEmailHistoryObjectsDocument,
    options
  );
}
export type QueryZoomEmailHistoryObjectsQueryHookResult = ReturnType<typeof useQueryZoomEmailHistoryObjectsQuery>;
export type QueryZoomEmailHistoryObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomEmailHistoryObjectsLazyQuery
>;
export type QueryZoomEmailHistoryObjectsQueryResult = Apollo.QueryResult<
  QueryZoomEmailHistoryObjectsQuery,
  QueryZoomEmailHistoryObjectsQueryVariables
>;
export const SubscribeToZoomEmailHistoryByIdDocument = gql`
  subscription subscribeToZoomEmailHistoryById($zoomEmailHistoryId: uuid!) {
    zoomEmailHistory_by_pk(id: $zoomEmailHistoryId) {
      ...ZoomEmailHistory
    }
  }
  ${ZoomEmailHistoryFragmentDoc}
`;

/**
 * __useSubscribeToZoomEmailHistoryByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomEmailHistoryByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomEmailHistoryByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomEmailHistoryByIdSubscription({
 *   variables: {
 *      zoomEmailHistoryId: // value for 'zoomEmailHistoryId'
 *   },
 * });
 */
export function useSubscribeToZoomEmailHistoryByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomEmailHistoryByIdSubscription,
    SubscribeToZoomEmailHistoryByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomEmailHistoryByIdSubscription,
    SubscribeToZoomEmailHistoryByIdSubscriptionVariables
  >(SubscribeToZoomEmailHistoryByIdDocument, options);
}
export type SubscribeToZoomEmailHistoryByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomEmailHistoryByIdSubscription
>;
export type SubscribeToZoomEmailHistoryByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomEmailHistoryByIdSubscription>;
export const SubscribeToZoomEmailHistoryObjectsDocument = gql`
  subscription subscribeToZoomEmailHistoryObjects(
    $distinct_on: [zoomEmailHistory_select_column!]
    $where: zoomEmailHistory_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomEmailHistory_order_by!]
  ) {
    zoomEmailHistory(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomEmailHistory
    }
  }
  ${ZoomEmailHistoryFragmentDoc}
`;

/**
 * __useSubscribeToZoomEmailHistoryObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomEmailHistoryObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomEmailHistoryObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomEmailHistoryObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomEmailHistoryObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomEmailHistoryObjectsSubscription,
    SubscribeToZoomEmailHistoryObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomEmailHistoryObjectsSubscription,
    SubscribeToZoomEmailHistoryObjectsSubscriptionVariables
  >(SubscribeToZoomEmailHistoryObjectsDocument, options);
}
export type SubscribeToZoomEmailHistoryObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomEmailHistoryObjectsSubscription
>;
export type SubscribeToZoomEmailHistoryObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomEmailHistoryObjectsSubscription>;
export const InsertZoomEmailHistoryDocument = gql`
  mutation insertZoomEmailHistory($objects: [zoomEmailHistory_insert_input!]!) {
    insert_zoomEmailHistory(objects: $objects) {
      affected_rows
      returning {
        ...ZoomEmailHistory
      }
    }
  }
  ${ZoomEmailHistoryFragmentDoc}
`;
export type InsertZoomEmailHistoryMutationFn = Apollo.MutationFunction<
  InsertZoomEmailHistoryMutation,
  InsertZoomEmailHistoryMutationVariables
>;

/**
 * __useInsertZoomEmailHistoryMutation__
 *
 * To run a mutation, you first call `useInsertZoomEmailHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomEmailHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomEmailHistoryMutation, { data, loading, error }] = useInsertZoomEmailHistoryMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomEmailHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomEmailHistoryMutation, InsertZoomEmailHistoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomEmailHistoryMutation, InsertZoomEmailHistoryMutationVariables>(
    InsertZoomEmailHistoryDocument,
    options
  );
}
export type InsertZoomEmailHistoryMutationHookResult = ReturnType<typeof useInsertZoomEmailHistoryMutation>;
export type InsertZoomEmailHistoryMutationResult = Apollo.MutationResult<InsertZoomEmailHistoryMutation>;
export type InsertZoomEmailHistoryMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomEmailHistoryMutation,
  InsertZoomEmailHistoryMutationVariables
>;
export const InsertZoomEmailHistoryWithOnConflictDocument = gql`
  mutation insertZoomEmailHistoryWithOnConflict(
    $objects: [zoomEmailHistory_insert_input!]!
    $onConflict: zoomEmailHistory_on_conflict
  ) {
    insert_zoomEmailHistory(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomEmailHistory
      }
    }
  }
  ${ZoomEmailHistoryFragmentDoc}
`;
export type InsertZoomEmailHistoryWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomEmailHistoryWithOnConflictMutation,
  InsertZoomEmailHistoryWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomEmailHistoryWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomEmailHistoryWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomEmailHistoryWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomEmailHistoryWithOnConflictMutation, { data, loading, error }] = useInsertZoomEmailHistoryWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomEmailHistoryWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomEmailHistoryWithOnConflictMutation,
    InsertZoomEmailHistoryWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomEmailHistoryWithOnConflictMutation,
    InsertZoomEmailHistoryWithOnConflictMutationVariables
  >(InsertZoomEmailHistoryWithOnConflictDocument, options);
}
export type InsertZoomEmailHistoryWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomEmailHistoryWithOnConflictMutation
>;
export type InsertZoomEmailHistoryWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomEmailHistoryWithOnConflictMutation>;
export type InsertZoomEmailHistoryWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomEmailHistoryWithOnConflictMutation,
  InsertZoomEmailHistoryWithOnConflictMutationVariables
>;
export const UpdateZoomEmailHistoryByIdDocument = gql`
  mutation updateZoomEmailHistoryById($id: uuid, $set: zoomEmailHistory_set_input) {
    update_zoomEmailHistory(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomEmailHistory
      }
    }
  }
  ${ZoomEmailHistoryFragmentDoc}
`;
export type UpdateZoomEmailHistoryByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomEmailHistoryByIdMutation,
  UpdateZoomEmailHistoryByIdMutationVariables
>;

/**
 * __useUpdateZoomEmailHistoryByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomEmailHistoryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomEmailHistoryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomEmailHistoryByIdMutation, { data, loading, error }] = useUpdateZoomEmailHistoryByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomEmailHistoryByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomEmailHistoryByIdMutation,
    UpdateZoomEmailHistoryByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomEmailHistoryByIdMutation, UpdateZoomEmailHistoryByIdMutationVariables>(
    UpdateZoomEmailHistoryByIdDocument,
    options
  );
}
export type UpdateZoomEmailHistoryByIdMutationHookResult = ReturnType<typeof useUpdateZoomEmailHistoryByIdMutation>;
export type UpdateZoomEmailHistoryByIdMutationResult = Apollo.MutationResult<UpdateZoomEmailHistoryByIdMutation>;
export type UpdateZoomEmailHistoryByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomEmailHistoryByIdMutation,
  UpdateZoomEmailHistoryByIdMutationVariables
>;
export const UpdateZoomEmailHistoryDocument = gql`
  mutation updateZoomEmailHistory($set: zoomEmailHistory_set_input, $where: zoomEmailHistory_bool_exp!) {
    update_zoomEmailHistory(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomEmailHistory
      }
    }
  }
  ${ZoomEmailHistoryFragmentDoc}
`;
export type UpdateZoomEmailHistoryMutationFn = Apollo.MutationFunction<
  UpdateZoomEmailHistoryMutation,
  UpdateZoomEmailHistoryMutationVariables
>;

/**
 * __useUpdateZoomEmailHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateZoomEmailHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomEmailHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomEmailHistoryMutation, { data, loading, error }] = useUpdateZoomEmailHistoryMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomEmailHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomEmailHistoryMutation, UpdateZoomEmailHistoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomEmailHistoryMutation, UpdateZoomEmailHistoryMutationVariables>(
    UpdateZoomEmailHistoryDocument,
    options
  );
}
export type UpdateZoomEmailHistoryMutationHookResult = ReturnType<typeof useUpdateZoomEmailHistoryMutation>;
export type UpdateZoomEmailHistoryMutationResult = Apollo.MutationResult<UpdateZoomEmailHistoryMutation>;
export type UpdateZoomEmailHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomEmailHistoryMutation,
  UpdateZoomEmailHistoryMutationVariables
>;
export const RemoveZoomEmailHistoryModelByIdDocument = gql`
  mutation removeZoomEmailHistoryModelById($id: uuid) {
    delete_zoomEmailHistory(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomEmailHistoryModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomEmailHistoryModelByIdMutation,
  RemoveZoomEmailHistoryModelByIdMutationVariables
>;

/**
 * __useRemoveZoomEmailHistoryModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomEmailHistoryModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomEmailHistoryModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomEmailHistoryModelByIdMutation, { data, loading, error }] = useRemoveZoomEmailHistoryModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomEmailHistoryModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomEmailHistoryModelByIdMutation,
    RemoveZoomEmailHistoryModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomEmailHistoryModelByIdMutation, RemoveZoomEmailHistoryModelByIdMutationVariables>(
    RemoveZoomEmailHistoryModelByIdDocument,
    options
  );
}
export type RemoveZoomEmailHistoryModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomEmailHistoryModelByIdMutation
>;
export type RemoveZoomEmailHistoryModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomEmailHistoryModelByIdMutation>;
export type RemoveZoomEmailHistoryModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomEmailHistoryModelByIdMutation,
  RemoveZoomEmailHistoryModelByIdMutationVariables
>;
export const RemoveZoomEmailHistoryModelDocument = gql`
  mutation removeZoomEmailHistoryModel($where: zoomEmailHistory_bool_exp!) {
    delete_zoomEmailHistory(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomEmailHistoryModelMutationFn = Apollo.MutationFunction<
  RemoveZoomEmailHistoryModelMutation,
  RemoveZoomEmailHistoryModelMutationVariables
>;

/**
 * __useRemoveZoomEmailHistoryModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomEmailHistoryModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomEmailHistoryModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomEmailHistoryModelMutation, { data, loading, error }] = useRemoveZoomEmailHistoryModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomEmailHistoryModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomEmailHistoryModelMutation,
    RemoveZoomEmailHistoryModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomEmailHistoryModelMutation, RemoveZoomEmailHistoryModelMutationVariables>(
    RemoveZoomEmailHistoryModelDocument,
    options
  );
}
export type RemoveZoomEmailHistoryModelMutationHookResult = ReturnType<typeof useRemoveZoomEmailHistoryModelMutation>;
export type RemoveZoomEmailHistoryModelMutationResult = Apollo.MutationResult<RemoveZoomEmailHistoryModelMutation>;
export type RemoveZoomEmailHistoryModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomEmailHistoryModelMutation,
  RemoveZoomEmailHistoryModelMutationVariables
>;
export const QueryZoomCustomBackgroundByIdDocument = gql`
  query queryZoomCustomBackgroundById($zoomCustomBackgroundId: uuid!) {
    zoomCustomBackground_by_pk(id: $zoomCustomBackgroundId) {
      ...ZoomCustomBackground
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;

/**
 * __useQueryZoomCustomBackgroundByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomCustomBackgroundByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomCustomBackgroundByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomCustomBackgroundByIdQuery({
 *   variables: {
 *      zoomCustomBackgroundId: // value for 'zoomCustomBackgroundId'
 *   },
 * });
 */
export function useQueryZoomCustomBackgroundByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomCustomBackgroundByIdQuery, QueryZoomCustomBackgroundByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomCustomBackgroundByIdQuery, QueryZoomCustomBackgroundByIdQueryVariables>(
    QueryZoomCustomBackgroundByIdDocument,
    options
  );
}
export function useQueryZoomCustomBackgroundByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomCustomBackgroundByIdQuery,
    QueryZoomCustomBackgroundByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomCustomBackgroundByIdQuery, QueryZoomCustomBackgroundByIdQueryVariables>(
    QueryZoomCustomBackgroundByIdDocument,
    options
  );
}
export type QueryZoomCustomBackgroundByIdQueryHookResult = ReturnType<typeof useQueryZoomCustomBackgroundByIdQuery>;
export type QueryZoomCustomBackgroundByIdLazyQueryHookResult = ReturnType<
  typeof useQueryZoomCustomBackgroundByIdLazyQuery
>;
export type QueryZoomCustomBackgroundByIdQueryResult = Apollo.QueryResult<
  QueryZoomCustomBackgroundByIdQuery,
  QueryZoomCustomBackgroundByIdQueryVariables
>;
export const QueryZoomCustomBackgroundObjectsDocument = gql`
  query queryZoomCustomBackgroundObjects(
    $distinct_on: [zoomCustomBackground_select_column!]
    $where: zoomCustomBackground_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomCustomBackground_order_by!]
  ) {
    zoomCustomBackground(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomCustomBackground
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;

/**
 * __useQueryZoomCustomBackgroundObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomCustomBackgroundObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomCustomBackgroundObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomCustomBackgroundObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomCustomBackgroundObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomCustomBackgroundObjectsQuery,
    QueryZoomCustomBackgroundObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomCustomBackgroundObjectsQuery, QueryZoomCustomBackgroundObjectsQueryVariables>(
    QueryZoomCustomBackgroundObjectsDocument,
    options
  );
}
export function useQueryZoomCustomBackgroundObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomCustomBackgroundObjectsQuery,
    QueryZoomCustomBackgroundObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomCustomBackgroundObjectsQuery, QueryZoomCustomBackgroundObjectsQueryVariables>(
    QueryZoomCustomBackgroundObjectsDocument,
    options
  );
}
export type QueryZoomCustomBackgroundObjectsQueryHookResult = ReturnType<
  typeof useQueryZoomCustomBackgroundObjectsQuery
>;
export type QueryZoomCustomBackgroundObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomCustomBackgroundObjectsLazyQuery
>;
export type QueryZoomCustomBackgroundObjectsQueryResult = Apollo.QueryResult<
  QueryZoomCustomBackgroundObjectsQuery,
  QueryZoomCustomBackgroundObjectsQueryVariables
>;
export const SubscribeToZoomCustomBackgroundByIdDocument = gql`
  subscription subscribeToZoomCustomBackgroundById($zoomCustomBackgroundId: uuid!) {
    zoomCustomBackground_by_pk(id: $zoomCustomBackgroundId) {
      ...ZoomCustomBackground
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;

/**
 * __useSubscribeToZoomCustomBackgroundByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomCustomBackgroundByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomCustomBackgroundByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomCustomBackgroundByIdSubscription({
 *   variables: {
 *      zoomCustomBackgroundId: // value for 'zoomCustomBackgroundId'
 *   },
 * });
 */
export function useSubscribeToZoomCustomBackgroundByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomCustomBackgroundByIdSubscription,
    SubscribeToZoomCustomBackgroundByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomCustomBackgroundByIdSubscription,
    SubscribeToZoomCustomBackgroundByIdSubscriptionVariables
  >(SubscribeToZoomCustomBackgroundByIdDocument, options);
}
export type SubscribeToZoomCustomBackgroundByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomCustomBackgroundByIdSubscription
>;
export type SubscribeToZoomCustomBackgroundByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomCustomBackgroundByIdSubscription>;
export const SubscribeToZoomCustomBackgroundObjectsDocument = gql`
  subscription subscribeToZoomCustomBackgroundObjects(
    $distinct_on: [zoomCustomBackground_select_column!]
    $where: zoomCustomBackground_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomCustomBackground_order_by!]
  ) {
    zoomCustomBackground(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomCustomBackground
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;

/**
 * __useSubscribeToZoomCustomBackgroundObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomCustomBackgroundObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomCustomBackgroundObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomCustomBackgroundObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomCustomBackgroundObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomCustomBackgroundObjectsSubscription,
    SubscribeToZoomCustomBackgroundObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomCustomBackgroundObjectsSubscription,
    SubscribeToZoomCustomBackgroundObjectsSubscriptionVariables
  >(SubscribeToZoomCustomBackgroundObjectsDocument, options);
}
export type SubscribeToZoomCustomBackgroundObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomCustomBackgroundObjectsSubscription
>;
export type SubscribeToZoomCustomBackgroundObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomCustomBackgroundObjectsSubscription>;
export const InsertZoomCustomBackgroundDocument = gql`
  mutation insertZoomCustomBackground($objects: [zoomCustomBackground_insert_input!]!) {
    insert_zoomCustomBackground(objects: $objects) {
      affected_rows
      returning {
        ...ZoomCustomBackground
      }
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;
export type InsertZoomCustomBackgroundMutationFn = Apollo.MutationFunction<
  InsertZoomCustomBackgroundMutation,
  InsertZoomCustomBackgroundMutationVariables
>;

/**
 * __useInsertZoomCustomBackgroundMutation__
 *
 * To run a mutation, you first call `useInsertZoomCustomBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomCustomBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomCustomBackgroundMutation, { data, loading, error }] = useInsertZoomCustomBackgroundMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomCustomBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomCustomBackgroundMutation,
    InsertZoomCustomBackgroundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomCustomBackgroundMutation, InsertZoomCustomBackgroundMutationVariables>(
    InsertZoomCustomBackgroundDocument,
    options
  );
}
export type InsertZoomCustomBackgroundMutationHookResult = ReturnType<typeof useInsertZoomCustomBackgroundMutation>;
export type InsertZoomCustomBackgroundMutationResult = Apollo.MutationResult<InsertZoomCustomBackgroundMutation>;
export type InsertZoomCustomBackgroundMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomCustomBackgroundMutation,
  InsertZoomCustomBackgroundMutationVariables
>;
export const InsertZoomCustomBackgroundWithOnConflictDocument = gql`
  mutation insertZoomCustomBackgroundWithOnConflict(
    $objects: [zoomCustomBackground_insert_input!]!
    $onConflict: zoomCustomBackground_on_conflict
  ) {
    insert_zoomCustomBackground(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomCustomBackground
      }
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;
export type InsertZoomCustomBackgroundWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomCustomBackgroundWithOnConflictMutation,
  InsertZoomCustomBackgroundWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomCustomBackgroundWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomCustomBackgroundWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomCustomBackgroundWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomCustomBackgroundWithOnConflictMutation, { data, loading, error }] = useInsertZoomCustomBackgroundWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomCustomBackgroundWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomCustomBackgroundWithOnConflictMutation,
    InsertZoomCustomBackgroundWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomCustomBackgroundWithOnConflictMutation,
    InsertZoomCustomBackgroundWithOnConflictMutationVariables
  >(InsertZoomCustomBackgroundWithOnConflictDocument, options);
}
export type InsertZoomCustomBackgroundWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomCustomBackgroundWithOnConflictMutation
>;
export type InsertZoomCustomBackgroundWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomCustomBackgroundWithOnConflictMutation>;
export type InsertZoomCustomBackgroundWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomCustomBackgroundWithOnConflictMutation,
  InsertZoomCustomBackgroundWithOnConflictMutationVariables
>;
export const UpdateZoomCustomBackgroundByIdDocument = gql`
  mutation updateZoomCustomBackgroundById($id: uuid, $set: zoomCustomBackground_set_input) {
    update_zoomCustomBackground(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomCustomBackground
      }
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;
export type UpdateZoomCustomBackgroundByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomCustomBackgroundByIdMutation,
  UpdateZoomCustomBackgroundByIdMutationVariables
>;

/**
 * __useUpdateZoomCustomBackgroundByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomCustomBackgroundByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomCustomBackgroundByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomCustomBackgroundByIdMutation, { data, loading, error }] = useUpdateZoomCustomBackgroundByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomCustomBackgroundByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomCustomBackgroundByIdMutation,
    UpdateZoomCustomBackgroundByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomCustomBackgroundByIdMutation, UpdateZoomCustomBackgroundByIdMutationVariables>(
    UpdateZoomCustomBackgroundByIdDocument,
    options
  );
}
export type UpdateZoomCustomBackgroundByIdMutationHookResult = ReturnType<
  typeof useUpdateZoomCustomBackgroundByIdMutation
>;
export type UpdateZoomCustomBackgroundByIdMutationResult =
  Apollo.MutationResult<UpdateZoomCustomBackgroundByIdMutation>;
export type UpdateZoomCustomBackgroundByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomCustomBackgroundByIdMutation,
  UpdateZoomCustomBackgroundByIdMutationVariables
>;
export const UpdateZoomCustomBackgroundDocument = gql`
  mutation updateZoomCustomBackground($set: zoomCustomBackground_set_input, $where: zoomCustomBackground_bool_exp!) {
    update_zoomCustomBackground(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomCustomBackground
      }
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;
export type UpdateZoomCustomBackgroundMutationFn = Apollo.MutationFunction<
  UpdateZoomCustomBackgroundMutation,
  UpdateZoomCustomBackgroundMutationVariables
>;

/**
 * __useUpdateZoomCustomBackgroundMutation__
 *
 * To run a mutation, you first call `useUpdateZoomCustomBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomCustomBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomCustomBackgroundMutation, { data, loading, error }] = useUpdateZoomCustomBackgroundMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomCustomBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomCustomBackgroundMutation,
    UpdateZoomCustomBackgroundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomCustomBackgroundMutation, UpdateZoomCustomBackgroundMutationVariables>(
    UpdateZoomCustomBackgroundDocument,
    options
  );
}
export type UpdateZoomCustomBackgroundMutationHookResult = ReturnType<typeof useUpdateZoomCustomBackgroundMutation>;
export type UpdateZoomCustomBackgroundMutationResult = Apollo.MutationResult<UpdateZoomCustomBackgroundMutation>;
export type UpdateZoomCustomBackgroundMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomCustomBackgroundMutation,
  UpdateZoomCustomBackgroundMutationVariables
>;
export const RemoveZoomCustomBackgroundModelByIdDocument = gql`
  mutation removeZoomCustomBackgroundModelById($id: uuid) {
    delete_zoomCustomBackground(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomCustomBackgroundModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomCustomBackgroundModelByIdMutation,
  RemoveZoomCustomBackgroundModelByIdMutationVariables
>;

/**
 * __useRemoveZoomCustomBackgroundModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomCustomBackgroundModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomCustomBackgroundModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomCustomBackgroundModelByIdMutation, { data, loading, error }] = useRemoveZoomCustomBackgroundModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomCustomBackgroundModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomCustomBackgroundModelByIdMutation,
    RemoveZoomCustomBackgroundModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomCustomBackgroundModelByIdMutation,
    RemoveZoomCustomBackgroundModelByIdMutationVariables
  >(RemoveZoomCustomBackgroundModelByIdDocument, options);
}
export type RemoveZoomCustomBackgroundModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomCustomBackgroundModelByIdMutation
>;
export type RemoveZoomCustomBackgroundModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomCustomBackgroundModelByIdMutation>;
export type RemoveZoomCustomBackgroundModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomCustomBackgroundModelByIdMutation,
  RemoveZoomCustomBackgroundModelByIdMutationVariables
>;
export const RemoveZoomCustomBackgroundModelDocument = gql`
  mutation removeZoomCustomBackgroundModel($where: zoomCustomBackground_bool_exp!) {
    delete_zoomCustomBackground(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomCustomBackgroundModelMutationFn = Apollo.MutationFunction<
  RemoveZoomCustomBackgroundModelMutation,
  RemoveZoomCustomBackgroundModelMutationVariables
>;

/**
 * __useRemoveZoomCustomBackgroundModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomCustomBackgroundModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomCustomBackgroundModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomCustomBackgroundModelMutation, { data, loading, error }] = useRemoveZoomCustomBackgroundModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomCustomBackgroundModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomCustomBackgroundModelMutation,
    RemoveZoomCustomBackgroundModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomCustomBackgroundModelMutation, RemoveZoomCustomBackgroundModelMutationVariables>(
    RemoveZoomCustomBackgroundModelDocument,
    options
  );
}
export type RemoveZoomCustomBackgroundModelMutationHookResult = ReturnType<
  typeof useRemoveZoomCustomBackgroundModelMutation
>;
export type RemoveZoomCustomBackgroundModelMutationResult =
  Apollo.MutationResult<RemoveZoomCustomBackgroundModelMutation>;
export type RemoveZoomCustomBackgroundModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomCustomBackgroundModelMutation,
  RemoveZoomCustomBackgroundModelMutationVariables
>;
export const QueryZoomGlobalCustomBackgroundByIdDocument = gql`
  query queryZoomGlobalCustomBackgroundById($zoomCustomBackgroundId: uuid!) {
    zoomCustomBackground_by_pk(id: $zoomCustomBackgroundId) {
      ...ZoomGlobalCustomBackground
    }
  }
  ${ZoomGlobalCustomBackgroundFragmentDoc}
`;

/**
 * __useQueryZoomGlobalCustomBackgroundByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomGlobalCustomBackgroundByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomGlobalCustomBackgroundByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomGlobalCustomBackgroundByIdQuery({
 *   variables: {
 *      zoomCustomBackgroundId: // value for 'zoomCustomBackgroundId'
 *   },
 * });
 */
export function useQueryZoomGlobalCustomBackgroundByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomGlobalCustomBackgroundByIdQuery,
    QueryZoomGlobalCustomBackgroundByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomGlobalCustomBackgroundByIdQuery, QueryZoomGlobalCustomBackgroundByIdQueryVariables>(
    QueryZoomGlobalCustomBackgroundByIdDocument,
    options
  );
}
export function useQueryZoomGlobalCustomBackgroundByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomGlobalCustomBackgroundByIdQuery,
    QueryZoomGlobalCustomBackgroundByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomGlobalCustomBackgroundByIdQuery,
    QueryZoomGlobalCustomBackgroundByIdQueryVariables
  >(QueryZoomGlobalCustomBackgroundByIdDocument, options);
}
export type QueryZoomGlobalCustomBackgroundByIdQueryHookResult = ReturnType<
  typeof useQueryZoomGlobalCustomBackgroundByIdQuery
>;
export type QueryZoomGlobalCustomBackgroundByIdLazyQueryHookResult = ReturnType<
  typeof useQueryZoomGlobalCustomBackgroundByIdLazyQuery
>;
export type QueryZoomGlobalCustomBackgroundByIdQueryResult = Apollo.QueryResult<
  QueryZoomGlobalCustomBackgroundByIdQuery,
  QueryZoomGlobalCustomBackgroundByIdQueryVariables
>;
export const QueryZoomGlobalCustomBackgroundObjectsDocument = gql`
  query queryZoomGlobalCustomBackgroundObjects(
    $distinct_on: [zoomCustomBackground_select_column!]
    $where: zoomCustomBackground_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomCustomBackground_order_by!]
  ) {
    zoomCustomBackground(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomGlobalCustomBackground
    }
  }
  ${ZoomGlobalCustomBackgroundFragmentDoc}
`;

/**
 * __useQueryZoomGlobalCustomBackgroundObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomGlobalCustomBackgroundObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomGlobalCustomBackgroundObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomGlobalCustomBackgroundObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomGlobalCustomBackgroundObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomGlobalCustomBackgroundObjectsQuery,
    QueryZoomGlobalCustomBackgroundObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryZoomGlobalCustomBackgroundObjectsQuery,
    QueryZoomGlobalCustomBackgroundObjectsQueryVariables
  >(QueryZoomGlobalCustomBackgroundObjectsDocument, options);
}
export function useQueryZoomGlobalCustomBackgroundObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomGlobalCustomBackgroundObjectsQuery,
    QueryZoomGlobalCustomBackgroundObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomGlobalCustomBackgroundObjectsQuery,
    QueryZoomGlobalCustomBackgroundObjectsQueryVariables
  >(QueryZoomGlobalCustomBackgroundObjectsDocument, options);
}
export type QueryZoomGlobalCustomBackgroundObjectsQueryHookResult = ReturnType<
  typeof useQueryZoomGlobalCustomBackgroundObjectsQuery
>;
export type QueryZoomGlobalCustomBackgroundObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomGlobalCustomBackgroundObjectsLazyQuery
>;
export type QueryZoomGlobalCustomBackgroundObjectsQueryResult = Apollo.QueryResult<
  QueryZoomGlobalCustomBackgroundObjectsQuery,
  QueryZoomGlobalCustomBackgroundObjectsQueryVariables
>;
export const SubscribeToZoomGlobalCustomBackgroundByIdDocument = gql`
  subscription subscribeToZoomGlobalCustomBackgroundById($zoomCustomBackgroundId: uuid!) {
    zoomCustomBackground_by_pk(id: $zoomCustomBackgroundId) {
      ...ZoomGlobalCustomBackground
    }
  }
  ${ZoomGlobalCustomBackgroundFragmentDoc}
`;

/**
 * __useSubscribeToZoomGlobalCustomBackgroundByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomGlobalCustomBackgroundByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomGlobalCustomBackgroundByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomGlobalCustomBackgroundByIdSubscription({
 *   variables: {
 *      zoomCustomBackgroundId: // value for 'zoomCustomBackgroundId'
 *   },
 * });
 */
export function useSubscribeToZoomGlobalCustomBackgroundByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomGlobalCustomBackgroundByIdSubscription,
    SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomGlobalCustomBackgroundByIdSubscription,
    SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionVariables
  >(SubscribeToZoomGlobalCustomBackgroundByIdDocument, options);
}
export type SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomGlobalCustomBackgroundByIdSubscription
>;
export type SubscribeToZoomGlobalCustomBackgroundByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomGlobalCustomBackgroundByIdSubscription>;
export const SubscribeToZoomGlobalCustomBackgroundObjectsDocument = gql`
  subscription subscribeToZoomGlobalCustomBackgroundObjects(
    $distinct_on: [zoomCustomBackground_select_column!]
    $where: zoomCustomBackground_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomCustomBackground_order_by!]
  ) {
    zoomCustomBackground(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomGlobalCustomBackground
    }
  }
  ${ZoomGlobalCustomBackgroundFragmentDoc}
`;

/**
 * __useSubscribeToZoomGlobalCustomBackgroundObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomGlobalCustomBackgroundObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomGlobalCustomBackgroundObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomGlobalCustomBackgroundObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomGlobalCustomBackgroundObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomGlobalCustomBackgroundObjectsSubscription,
    SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomGlobalCustomBackgroundObjectsSubscription,
    SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionVariables
  >(SubscribeToZoomGlobalCustomBackgroundObjectsDocument, options);
}
export type SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomGlobalCustomBackgroundObjectsSubscription
>;
export type SubscribeToZoomGlobalCustomBackgroundObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomGlobalCustomBackgroundObjectsSubscription>;
export const InsertZoomGlobalCustomBackgroundDocument = gql`
  mutation insertZoomGlobalCustomBackground($objects: [zoomCustomBackground_insert_input!]!) {
    insert_zoomCustomBackground(objects: $objects) {
      affected_rows
      returning {
        ...ZoomGlobalCustomBackground
      }
    }
  }
  ${ZoomGlobalCustomBackgroundFragmentDoc}
`;
export type InsertZoomGlobalCustomBackgroundMutationFn = Apollo.MutationFunction<
  InsertZoomGlobalCustomBackgroundMutation,
  InsertZoomGlobalCustomBackgroundMutationVariables
>;

/**
 * __useInsertZoomGlobalCustomBackgroundMutation__
 *
 * To run a mutation, you first call `useInsertZoomGlobalCustomBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomGlobalCustomBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomGlobalCustomBackgroundMutation, { data, loading, error }] = useInsertZoomGlobalCustomBackgroundMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomGlobalCustomBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomGlobalCustomBackgroundMutation,
    InsertZoomGlobalCustomBackgroundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomGlobalCustomBackgroundMutation,
    InsertZoomGlobalCustomBackgroundMutationVariables
  >(InsertZoomGlobalCustomBackgroundDocument, options);
}
export type InsertZoomGlobalCustomBackgroundMutationHookResult = ReturnType<
  typeof useInsertZoomGlobalCustomBackgroundMutation
>;
export type InsertZoomGlobalCustomBackgroundMutationResult =
  Apollo.MutationResult<InsertZoomGlobalCustomBackgroundMutation>;
export type InsertZoomGlobalCustomBackgroundMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomGlobalCustomBackgroundMutation,
  InsertZoomGlobalCustomBackgroundMutationVariables
>;
export const InsertZoomGlobalCustomBackgroundWithOnConflictDocument = gql`
  mutation insertZoomGlobalCustomBackgroundWithOnConflict(
    $objects: [zoomCustomBackground_insert_input!]!
    $onConflict: zoomCustomBackground_on_conflict
  ) {
    insert_zoomCustomBackground(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomGlobalCustomBackground
      }
    }
  }
  ${ZoomGlobalCustomBackgroundFragmentDoc}
`;
export type InsertZoomGlobalCustomBackgroundWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
  InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomGlobalCustomBackgroundWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomGlobalCustomBackgroundWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomGlobalCustomBackgroundWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomGlobalCustomBackgroundWithOnConflictMutation, { data, loading, error }] = useInsertZoomGlobalCustomBackgroundWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomGlobalCustomBackgroundWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
    InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
    InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
  >(InsertZoomGlobalCustomBackgroundWithOnConflictDocument, options);
}
export type InsertZoomGlobalCustomBackgroundWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomGlobalCustomBackgroundWithOnConflictMutation
>;
export type InsertZoomGlobalCustomBackgroundWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomGlobalCustomBackgroundWithOnConflictMutation>;
export type InsertZoomGlobalCustomBackgroundWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomGlobalCustomBackgroundWithOnConflictMutation,
  InsertZoomGlobalCustomBackgroundWithOnConflictMutationVariables
>;
export const UpdateZoomGlobalCustomBackgroundByIdDocument = gql`
  mutation updateZoomGlobalCustomBackgroundById($id: uuid, $set: zoomCustomBackground_set_input) {
    update_zoomCustomBackground(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomGlobalCustomBackground
      }
    }
  }
  ${ZoomGlobalCustomBackgroundFragmentDoc}
`;
export type UpdateZoomGlobalCustomBackgroundByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomGlobalCustomBackgroundByIdMutation,
  UpdateZoomGlobalCustomBackgroundByIdMutationVariables
>;

/**
 * __useUpdateZoomGlobalCustomBackgroundByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomGlobalCustomBackgroundByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomGlobalCustomBackgroundByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomGlobalCustomBackgroundByIdMutation, { data, loading, error }] = useUpdateZoomGlobalCustomBackgroundByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomGlobalCustomBackgroundByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomGlobalCustomBackgroundByIdMutation,
    UpdateZoomGlobalCustomBackgroundByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomGlobalCustomBackgroundByIdMutation,
    UpdateZoomGlobalCustomBackgroundByIdMutationVariables
  >(UpdateZoomGlobalCustomBackgroundByIdDocument, options);
}
export type UpdateZoomGlobalCustomBackgroundByIdMutationHookResult = ReturnType<
  typeof useUpdateZoomGlobalCustomBackgroundByIdMutation
>;
export type UpdateZoomGlobalCustomBackgroundByIdMutationResult =
  Apollo.MutationResult<UpdateZoomGlobalCustomBackgroundByIdMutation>;
export type UpdateZoomGlobalCustomBackgroundByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomGlobalCustomBackgroundByIdMutation,
  UpdateZoomGlobalCustomBackgroundByIdMutationVariables
>;
export const UpdateZoomGlobalCustomBackgroundDocument = gql`
  mutation updateZoomGlobalCustomBackground(
    $set: zoomCustomBackground_set_input
    $where: zoomCustomBackground_bool_exp!
  ) {
    update_zoomCustomBackground(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomGlobalCustomBackground
      }
    }
  }
  ${ZoomGlobalCustomBackgroundFragmentDoc}
`;
export type UpdateZoomGlobalCustomBackgroundMutationFn = Apollo.MutationFunction<
  UpdateZoomGlobalCustomBackgroundMutation,
  UpdateZoomGlobalCustomBackgroundMutationVariables
>;

/**
 * __useUpdateZoomGlobalCustomBackgroundMutation__
 *
 * To run a mutation, you first call `useUpdateZoomGlobalCustomBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomGlobalCustomBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomGlobalCustomBackgroundMutation, { data, loading, error }] = useUpdateZoomGlobalCustomBackgroundMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomGlobalCustomBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomGlobalCustomBackgroundMutation,
    UpdateZoomGlobalCustomBackgroundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomGlobalCustomBackgroundMutation,
    UpdateZoomGlobalCustomBackgroundMutationVariables
  >(UpdateZoomGlobalCustomBackgroundDocument, options);
}
export type UpdateZoomGlobalCustomBackgroundMutationHookResult = ReturnType<
  typeof useUpdateZoomGlobalCustomBackgroundMutation
>;
export type UpdateZoomGlobalCustomBackgroundMutationResult =
  Apollo.MutationResult<UpdateZoomGlobalCustomBackgroundMutation>;
export type UpdateZoomGlobalCustomBackgroundMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomGlobalCustomBackgroundMutation,
  UpdateZoomGlobalCustomBackgroundMutationVariables
>;
export const QueryZoomConnectionRequestByIdDocument = gql`
  query queryZoomConnectionRequestById($zoomConnectionRequestId: uuid!) {
    zoomConnectionRequest_by_pk(id: $zoomConnectionRequestId) {
      ...ZoomConnectionRequest
    }
  }
  ${ZoomConnectionRequestFragmentDoc}
`;

/**
 * __useQueryZoomConnectionRequestByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomConnectionRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomConnectionRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomConnectionRequestByIdQuery({
 *   variables: {
 *      zoomConnectionRequestId: // value for 'zoomConnectionRequestId'
 *   },
 * });
 */
export function useQueryZoomConnectionRequestByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomConnectionRequestByIdQuery,
    QueryZoomConnectionRequestByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomConnectionRequestByIdQuery, QueryZoomConnectionRequestByIdQueryVariables>(
    QueryZoomConnectionRequestByIdDocument,
    options
  );
}
export function useQueryZoomConnectionRequestByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomConnectionRequestByIdQuery,
    QueryZoomConnectionRequestByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomConnectionRequestByIdQuery, QueryZoomConnectionRequestByIdQueryVariables>(
    QueryZoomConnectionRequestByIdDocument,
    options
  );
}
export type QueryZoomConnectionRequestByIdQueryHookResult = ReturnType<typeof useQueryZoomConnectionRequestByIdQuery>;
export type QueryZoomConnectionRequestByIdLazyQueryHookResult = ReturnType<
  typeof useQueryZoomConnectionRequestByIdLazyQuery
>;
export type QueryZoomConnectionRequestByIdQueryResult = Apollo.QueryResult<
  QueryZoomConnectionRequestByIdQuery,
  QueryZoomConnectionRequestByIdQueryVariables
>;
export const QueryZoomConnectionRequestObjectsDocument = gql`
  query queryZoomConnectionRequestObjects(
    $distinct_on: [zoomConnectionRequest_select_column!]
    $where: zoomConnectionRequest_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomConnectionRequest_order_by!]
  ) {
    zoomConnectionRequest(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomConnectionRequest
    }
  }
  ${ZoomConnectionRequestFragmentDoc}
`;

/**
 * __useQueryZoomConnectionRequestObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomConnectionRequestObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomConnectionRequestObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomConnectionRequestObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomConnectionRequestObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomConnectionRequestObjectsQuery,
    QueryZoomConnectionRequestObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomConnectionRequestObjectsQuery, QueryZoomConnectionRequestObjectsQueryVariables>(
    QueryZoomConnectionRequestObjectsDocument,
    options
  );
}
export function useQueryZoomConnectionRequestObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomConnectionRequestObjectsQuery,
    QueryZoomConnectionRequestObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomConnectionRequestObjectsQuery, QueryZoomConnectionRequestObjectsQueryVariables>(
    QueryZoomConnectionRequestObjectsDocument,
    options
  );
}
export type QueryZoomConnectionRequestObjectsQueryHookResult = ReturnType<
  typeof useQueryZoomConnectionRequestObjectsQuery
>;
export type QueryZoomConnectionRequestObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomConnectionRequestObjectsLazyQuery
>;
export type QueryZoomConnectionRequestObjectsQueryResult = Apollo.QueryResult<
  QueryZoomConnectionRequestObjectsQuery,
  QueryZoomConnectionRequestObjectsQueryVariables
>;
export const SubscribeToZoomConnectionRequestByIdDocument = gql`
  subscription subscribeToZoomConnectionRequestById($zoomConnectionRequestId: uuid!) {
    zoomConnectionRequest_by_pk(id: $zoomConnectionRequestId) {
      ...ZoomConnectionRequest
    }
  }
  ${ZoomConnectionRequestFragmentDoc}
`;

/**
 * __useSubscribeToZoomConnectionRequestByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomConnectionRequestByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomConnectionRequestByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomConnectionRequestByIdSubscription({
 *   variables: {
 *      zoomConnectionRequestId: // value for 'zoomConnectionRequestId'
 *   },
 * });
 */
export function useSubscribeToZoomConnectionRequestByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomConnectionRequestByIdSubscription,
    SubscribeToZoomConnectionRequestByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomConnectionRequestByIdSubscription,
    SubscribeToZoomConnectionRequestByIdSubscriptionVariables
  >(SubscribeToZoomConnectionRequestByIdDocument, options);
}
export type SubscribeToZoomConnectionRequestByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomConnectionRequestByIdSubscription
>;
export type SubscribeToZoomConnectionRequestByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomConnectionRequestByIdSubscription>;
export const SubscribeToZoomConnectionRequestObjectsDocument = gql`
  subscription subscribeToZoomConnectionRequestObjects(
    $distinct_on: [zoomConnectionRequest_select_column!]
    $where: zoomConnectionRequest_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomConnectionRequest_order_by!]
  ) {
    zoomConnectionRequest(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomConnectionRequest
    }
  }
  ${ZoomConnectionRequestFragmentDoc}
`;

/**
 * __useSubscribeToZoomConnectionRequestObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomConnectionRequestObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomConnectionRequestObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomConnectionRequestObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomConnectionRequestObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomConnectionRequestObjectsSubscription,
    SubscribeToZoomConnectionRequestObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomConnectionRequestObjectsSubscription,
    SubscribeToZoomConnectionRequestObjectsSubscriptionVariables
  >(SubscribeToZoomConnectionRequestObjectsDocument, options);
}
export type SubscribeToZoomConnectionRequestObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomConnectionRequestObjectsSubscription
>;
export type SubscribeToZoomConnectionRequestObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomConnectionRequestObjectsSubscription>;
export const InsertZoomConnectionRequestDocument = gql`
  mutation insertZoomConnectionRequest($objects: [zoomConnectionRequest_insert_input!]!) {
    insert_zoomConnectionRequest(objects: $objects) {
      affected_rows
      returning {
        ...ZoomConnectionRequest
      }
    }
  }
  ${ZoomConnectionRequestFragmentDoc}
`;
export type InsertZoomConnectionRequestMutationFn = Apollo.MutationFunction<
  InsertZoomConnectionRequestMutation,
  InsertZoomConnectionRequestMutationVariables
>;

/**
 * __useInsertZoomConnectionRequestMutation__
 *
 * To run a mutation, you first call `useInsertZoomConnectionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomConnectionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomConnectionRequestMutation, { data, loading, error }] = useInsertZoomConnectionRequestMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomConnectionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomConnectionRequestMutation,
    InsertZoomConnectionRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomConnectionRequestMutation, InsertZoomConnectionRequestMutationVariables>(
    InsertZoomConnectionRequestDocument,
    options
  );
}
export type InsertZoomConnectionRequestMutationHookResult = ReturnType<typeof useInsertZoomConnectionRequestMutation>;
export type InsertZoomConnectionRequestMutationResult = Apollo.MutationResult<InsertZoomConnectionRequestMutation>;
export type InsertZoomConnectionRequestMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomConnectionRequestMutation,
  InsertZoomConnectionRequestMutationVariables
>;
export const InsertZoomConnectionRequestWithOnConflictDocument = gql`
  mutation insertZoomConnectionRequestWithOnConflict(
    $objects: [zoomConnectionRequest_insert_input!]!
    $onConflict: zoomConnectionRequest_on_conflict
  ) {
    insert_zoomConnectionRequest(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomConnectionRequest
      }
    }
  }
  ${ZoomConnectionRequestFragmentDoc}
`;
export type InsertZoomConnectionRequestWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomConnectionRequestWithOnConflictMutation,
  InsertZoomConnectionRequestWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomConnectionRequestWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomConnectionRequestWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomConnectionRequestWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomConnectionRequestWithOnConflictMutation, { data, loading, error }] = useInsertZoomConnectionRequestWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomConnectionRequestWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomConnectionRequestWithOnConflictMutation,
    InsertZoomConnectionRequestWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomConnectionRequestWithOnConflictMutation,
    InsertZoomConnectionRequestWithOnConflictMutationVariables
  >(InsertZoomConnectionRequestWithOnConflictDocument, options);
}
export type InsertZoomConnectionRequestWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomConnectionRequestWithOnConflictMutation
>;
export type InsertZoomConnectionRequestWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomConnectionRequestWithOnConflictMutation>;
export type InsertZoomConnectionRequestWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomConnectionRequestWithOnConflictMutation,
  InsertZoomConnectionRequestWithOnConflictMutationVariables
>;
export const UpdateZoomConnectionRequestByIdDocument = gql`
  mutation updateZoomConnectionRequestById($id: uuid, $set: zoomConnectionRequest_set_input) {
    update_zoomConnectionRequest(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomConnectionRequest
      }
    }
  }
  ${ZoomConnectionRequestFragmentDoc}
`;
export type UpdateZoomConnectionRequestByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomConnectionRequestByIdMutation,
  UpdateZoomConnectionRequestByIdMutationVariables
>;

/**
 * __useUpdateZoomConnectionRequestByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomConnectionRequestByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomConnectionRequestByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomConnectionRequestByIdMutation, { data, loading, error }] = useUpdateZoomConnectionRequestByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomConnectionRequestByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomConnectionRequestByIdMutation,
    UpdateZoomConnectionRequestByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomConnectionRequestByIdMutation, UpdateZoomConnectionRequestByIdMutationVariables>(
    UpdateZoomConnectionRequestByIdDocument,
    options
  );
}
export type UpdateZoomConnectionRequestByIdMutationHookResult = ReturnType<
  typeof useUpdateZoomConnectionRequestByIdMutation
>;
export type UpdateZoomConnectionRequestByIdMutationResult =
  Apollo.MutationResult<UpdateZoomConnectionRequestByIdMutation>;
export type UpdateZoomConnectionRequestByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomConnectionRequestByIdMutation,
  UpdateZoomConnectionRequestByIdMutationVariables
>;
export const UpdateZoomConnectionRequestDocument = gql`
  mutation updateZoomConnectionRequest($set: zoomConnectionRequest_set_input, $where: zoomConnectionRequest_bool_exp!) {
    update_zoomConnectionRequest(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomConnectionRequest
      }
    }
  }
  ${ZoomConnectionRequestFragmentDoc}
`;
export type UpdateZoomConnectionRequestMutationFn = Apollo.MutationFunction<
  UpdateZoomConnectionRequestMutation,
  UpdateZoomConnectionRequestMutationVariables
>;

/**
 * __useUpdateZoomConnectionRequestMutation__
 *
 * To run a mutation, you first call `useUpdateZoomConnectionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomConnectionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomConnectionRequestMutation, { data, loading, error }] = useUpdateZoomConnectionRequestMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomConnectionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomConnectionRequestMutation,
    UpdateZoomConnectionRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomConnectionRequestMutation, UpdateZoomConnectionRequestMutationVariables>(
    UpdateZoomConnectionRequestDocument,
    options
  );
}
export type UpdateZoomConnectionRequestMutationHookResult = ReturnType<typeof useUpdateZoomConnectionRequestMutation>;
export type UpdateZoomConnectionRequestMutationResult = Apollo.MutationResult<UpdateZoomConnectionRequestMutation>;
export type UpdateZoomConnectionRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomConnectionRequestMutation,
  UpdateZoomConnectionRequestMutationVariables
>;
export const RemoveZoomConnectionRequestModelByIdDocument = gql`
  mutation removeZoomConnectionRequestModelById($id: uuid) {
    delete_zoomConnectionRequest(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomConnectionRequestModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomConnectionRequestModelByIdMutation,
  RemoveZoomConnectionRequestModelByIdMutationVariables
>;

/**
 * __useRemoveZoomConnectionRequestModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomConnectionRequestModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomConnectionRequestModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomConnectionRequestModelByIdMutation, { data, loading, error }] = useRemoveZoomConnectionRequestModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomConnectionRequestModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomConnectionRequestModelByIdMutation,
    RemoveZoomConnectionRequestModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomConnectionRequestModelByIdMutation,
    RemoveZoomConnectionRequestModelByIdMutationVariables
  >(RemoveZoomConnectionRequestModelByIdDocument, options);
}
export type RemoveZoomConnectionRequestModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomConnectionRequestModelByIdMutation
>;
export type RemoveZoomConnectionRequestModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomConnectionRequestModelByIdMutation>;
export type RemoveZoomConnectionRequestModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomConnectionRequestModelByIdMutation,
  RemoveZoomConnectionRequestModelByIdMutationVariables
>;
export const RemoveZoomConnectionRequestModelDocument = gql`
  mutation removeZoomConnectionRequestModel($where: zoomConnectionRequest_bool_exp!) {
    delete_zoomConnectionRequest(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomConnectionRequestModelMutationFn = Apollo.MutationFunction<
  RemoveZoomConnectionRequestModelMutation,
  RemoveZoomConnectionRequestModelMutationVariables
>;

/**
 * __useRemoveZoomConnectionRequestModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomConnectionRequestModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomConnectionRequestModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomConnectionRequestModelMutation, { data, loading, error }] = useRemoveZoomConnectionRequestModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomConnectionRequestModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomConnectionRequestModelMutation,
    RemoveZoomConnectionRequestModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomConnectionRequestModelMutation,
    RemoveZoomConnectionRequestModelMutationVariables
  >(RemoveZoomConnectionRequestModelDocument, options);
}
export type RemoveZoomConnectionRequestModelMutationHookResult = ReturnType<
  typeof useRemoveZoomConnectionRequestModelMutation
>;
export type RemoveZoomConnectionRequestModelMutationResult =
  Apollo.MutationResult<RemoveZoomConnectionRequestModelMutation>;
export type RemoveZoomConnectionRequestModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomConnectionRequestModelMutation,
  RemoveZoomConnectionRequestModelMutationVariables
>;
export const QueryZoomConnectionRequestReminderByIdDocument = gql`
  query queryZoomConnectionRequestReminderById($zoomConnectionRequestReminderId: uuid!) {
    zoomConnectionRequestReminder_by_pk(id: $zoomConnectionRequestReminderId) {
      ...ZoomConnectionRequestReminder
    }
  }
  ${ZoomConnectionRequestReminderFragmentDoc}
`;

/**
 * __useQueryZoomConnectionRequestReminderByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomConnectionRequestReminderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomConnectionRequestReminderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomConnectionRequestReminderByIdQuery({
 *   variables: {
 *      zoomConnectionRequestReminderId: // value for 'zoomConnectionRequestReminderId'
 *   },
 * });
 */
export function useQueryZoomConnectionRequestReminderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomConnectionRequestReminderByIdQuery,
    QueryZoomConnectionRequestReminderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryZoomConnectionRequestReminderByIdQuery,
    QueryZoomConnectionRequestReminderByIdQueryVariables
  >(QueryZoomConnectionRequestReminderByIdDocument, options);
}
export function useQueryZoomConnectionRequestReminderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomConnectionRequestReminderByIdQuery,
    QueryZoomConnectionRequestReminderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomConnectionRequestReminderByIdQuery,
    QueryZoomConnectionRequestReminderByIdQueryVariables
  >(QueryZoomConnectionRequestReminderByIdDocument, options);
}
export type QueryZoomConnectionRequestReminderByIdQueryHookResult = ReturnType<
  typeof useQueryZoomConnectionRequestReminderByIdQuery
>;
export type QueryZoomConnectionRequestReminderByIdLazyQueryHookResult = ReturnType<
  typeof useQueryZoomConnectionRequestReminderByIdLazyQuery
>;
export type QueryZoomConnectionRequestReminderByIdQueryResult = Apollo.QueryResult<
  QueryZoomConnectionRequestReminderByIdQuery,
  QueryZoomConnectionRequestReminderByIdQueryVariables
>;
export const QueryZoomConnectionRequestReminderObjectsDocument = gql`
  query queryZoomConnectionRequestReminderObjects(
    $distinct_on: [zoomConnectionRequestReminder_select_column!]
    $where: zoomConnectionRequestReminder_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomConnectionRequestReminder_order_by!]
  ) {
    zoomConnectionRequestReminder(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomConnectionRequestReminder
    }
  }
  ${ZoomConnectionRequestReminderFragmentDoc}
`;

/**
 * __useQueryZoomConnectionRequestReminderObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomConnectionRequestReminderObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomConnectionRequestReminderObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomConnectionRequestReminderObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomConnectionRequestReminderObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomConnectionRequestReminderObjectsQuery,
    QueryZoomConnectionRequestReminderObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryZoomConnectionRequestReminderObjectsQuery,
    QueryZoomConnectionRequestReminderObjectsQueryVariables
  >(QueryZoomConnectionRequestReminderObjectsDocument, options);
}
export function useQueryZoomConnectionRequestReminderObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomConnectionRequestReminderObjectsQuery,
    QueryZoomConnectionRequestReminderObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomConnectionRequestReminderObjectsQuery,
    QueryZoomConnectionRequestReminderObjectsQueryVariables
  >(QueryZoomConnectionRequestReminderObjectsDocument, options);
}
export type QueryZoomConnectionRequestReminderObjectsQueryHookResult = ReturnType<
  typeof useQueryZoomConnectionRequestReminderObjectsQuery
>;
export type QueryZoomConnectionRequestReminderObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomConnectionRequestReminderObjectsLazyQuery
>;
export type QueryZoomConnectionRequestReminderObjectsQueryResult = Apollo.QueryResult<
  QueryZoomConnectionRequestReminderObjectsQuery,
  QueryZoomConnectionRequestReminderObjectsQueryVariables
>;
export const SubscribeToZoomConnectionRequestReminderByIdDocument = gql`
  subscription subscribeToZoomConnectionRequestReminderById($zoomConnectionRequestReminderId: uuid!) {
    zoomConnectionRequestReminder_by_pk(id: $zoomConnectionRequestReminderId) {
      ...ZoomConnectionRequestReminder
    }
  }
  ${ZoomConnectionRequestReminderFragmentDoc}
`;

/**
 * __useSubscribeToZoomConnectionRequestReminderByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomConnectionRequestReminderByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomConnectionRequestReminderByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomConnectionRequestReminderByIdSubscription({
 *   variables: {
 *      zoomConnectionRequestReminderId: // value for 'zoomConnectionRequestReminderId'
 *   },
 * });
 */
export function useSubscribeToZoomConnectionRequestReminderByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomConnectionRequestReminderByIdSubscription,
    SubscribeToZoomConnectionRequestReminderByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomConnectionRequestReminderByIdSubscription,
    SubscribeToZoomConnectionRequestReminderByIdSubscriptionVariables
  >(SubscribeToZoomConnectionRequestReminderByIdDocument, options);
}
export type SubscribeToZoomConnectionRequestReminderByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomConnectionRequestReminderByIdSubscription
>;
export type SubscribeToZoomConnectionRequestReminderByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomConnectionRequestReminderByIdSubscription>;
export const SubscribeToZoomConnectionRequestReminderObjectsDocument = gql`
  subscription subscribeToZoomConnectionRequestReminderObjects(
    $distinct_on: [zoomConnectionRequestReminder_select_column!]
    $where: zoomConnectionRequestReminder_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomConnectionRequestReminder_order_by!]
  ) {
    zoomConnectionRequestReminder(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomConnectionRequestReminder
    }
  }
  ${ZoomConnectionRequestReminderFragmentDoc}
`;

/**
 * __useSubscribeToZoomConnectionRequestReminderObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomConnectionRequestReminderObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomConnectionRequestReminderObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomConnectionRequestReminderObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomConnectionRequestReminderObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomConnectionRequestReminderObjectsSubscription,
    SubscribeToZoomConnectionRequestReminderObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomConnectionRequestReminderObjectsSubscription,
    SubscribeToZoomConnectionRequestReminderObjectsSubscriptionVariables
  >(SubscribeToZoomConnectionRequestReminderObjectsDocument, options);
}
export type SubscribeToZoomConnectionRequestReminderObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomConnectionRequestReminderObjectsSubscription
>;
export type SubscribeToZoomConnectionRequestReminderObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomConnectionRequestReminderObjectsSubscription>;
export const InsertZoomConnectionRequestReminderDocument = gql`
  mutation insertZoomConnectionRequestReminder($objects: [zoomConnectionRequestReminder_insert_input!]!) {
    insert_zoomConnectionRequestReminder(objects: $objects) {
      affected_rows
      returning {
        ...ZoomConnectionRequestReminder
      }
    }
  }
  ${ZoomConnectionRequestReminderFragmentDoc}
`;
export type InsertZoomConnectionRequestReminderMutationFn = Apollo.MutationFunction<
  InsertZoomConnectionRequestReminderMutation,
  InsertZoomConnectionRequestReminderMutationVariables
>;

/**
 * __useInsertZoomConnectionRequestReminderMutation__
 *
 * To run a mutation, you first call `useInsertZoomConnectionRequestReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomConnectionRequestReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomConnectionRequestReminderMutation, { data, loading, error }] = useInsertZoomConnectionRequestReminderMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomConnectionRequestReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomConnectionRequestReminderMutation,
    InsertZoomConnectionRequestReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomConnectionRequestReminderMutation,
    InsertZoomConnectionRequestReminderMutationVariables
  >(InsertZoomConnectionRequestReminderDocument, options);
}
export type InsertZoomConnectionRequestReminderMutationHookResult = ReturnType<
  typeof useInsertZoomConnectionRequestReminderMutation
>;
export type InsertZoomConnectionRequestReminderMutationResult =
  Apollo.MutationResult<InsertZoomConnectionRequestReminderMutation>;
export type InsertZoomConnectionRequestReminderMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomConnectionRequestReminderMutation,
  InsertZoomConnectionRequestReminderMutationVariables
>;
export const InsertZoomConnectionRequestReminderWithOnConflictDocument = gql`
  mutation insertZoomConnectionRequestReminderWithOnConflict(
    $objects: [zoomConnectionRequestReminder_insert_input!]!
    $onConflict: zoomConnectionRequestReminder_on_conflict
  ) {
    insert_zoomConnectionRequestReminder(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomConnectionRequestReminder
      }
    }
  }
  ${ZoomConnectionRequestReminderFragmentDoc}
`;
export type InsertZoomConnectionRequestReminderWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomConnectionRequestReminderWithOnConflictMutation,
  InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomConnectionRequestReminderWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomConnectionRequestReminderWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomConnectionRequestReminderWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomConnectionRequestReminderWithOnConflictMutation, { data, loading, error }] = useInsertZoomConnectionRequestReminderWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomConnectionRequestReminderWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomConnectionRequestReminderWithOnConflictMutation,
    InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomConnectionRequestReminderWithOnConflictMutation,
    InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
  >(InsertZoomConnectionRequestReminderWithOnConflictDocument, options);
}
export type InsertZoomConnectionRequestReminderWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomConnectionRequestReminderWithOnConflictMutation
>;
export type InsertZoomConnectionRequestReminderWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomConnectionRequestReminderWithOnConflictMutation>;
export type InsertZoomConnectionRequestReminderWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomConnectionRequestReminderWithOnConflictMutation,
  InsertZoomConnectionRequestReminderWithOnConflictMutationVariables
>;
export const UpdateZoomConnectionRequestReminderByIdDocument = gql`
  mutation updateZoomConnectionRequestReminderById($id: uuid, $set: zoomConnectionRequestReminder_set_input) {
    update_zoomConnectionRequestReminder(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomConnectionRequestReminder
      }
    }
  }
  ${ZoomConnectionRequestReminderFragmentDoc}
`;
export type UpdateZoomConnectionRequestReminderByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomConnectionRequestReminderByIdMutation,
  UpdateZoomConnectionRequestReminderByIdMutationVariables
>;

/**
 * __useUpdateZoomConnectionRequestReminderByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomConnectionRequestReminderByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomConnectionRequestReminderByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomConnectionRequestReminderByIdMutation, { data, loading, error }] = useUpdateZoomConnectionRequestReminderByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomConnectionRequestReminderByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomConnectionRequestReminderByIdMutation,
    UpdateZoomConnectionRequestReminderByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomConnectionRequestReminderByIdMutation,
    UpdateZoomConnectionRequestReminderByIdMutationVariables
  >(UpdateZoomConnectionRequestReminderByIdDocument, options);
}
export type UpdateZoomConnectionRequestReminderByIdMutationHookResult = ReturnType<
  typeof useUpdateZoomConnectionRequestReminderByIdMutation
>;
export type UpdateZoomConnectionRequestReminderByIdMutationResult =
  Apollo.MutationResult<UpdateZoomConnectionRequestReminderByIdMutation>;
export type UpdateZoomConnectionRequestReminderByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomConnectionRequestReminderByIdMutation,
  UpdateZoomConnectionRequestReminderByIdMutationVariables
>;
export const UpdateZoomConnectionRequestReminderDocument = gql`
  mutation updateZoomConnectionRequestReminder(
    $set: zoomConnectionRequestReminder_set_input
    $where: zoomConnectionRequestReminder_bool_exp!
  ) {
    update_zoomConnectionRequestReminder(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomConnectionRequestReminder
      }
    }
  }
  ${ZoomConnectionRequestReminderFragmentDoc}
`;
export type UpdateZoomConnectionRequestReminderMutationFn = Apollo.MutationFunction<
  UpdateZoomConnectionRequestReminderMutation,
  UpdateZoomConnectionRequestReminderMutationVariables
>;

/**
 * __useUpdateZoomConnectionRequestReminderMutation__
 *
 * To run a mutation, you first call `useUpdateZoomConnectionRequestReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomConnectionRequestReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomConnectionRequestReminderMutation, { data, loading, error }] = useUpdateZoomConnectionRequestReminderMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomConnectionRequestReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomConnectionRequestReminderMutation,
    UpdateZoomConnectionRequestReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomConnectionRequestReminderMutation,
    UpdateZoomConnectionRequestReminderMutationVariables
  >(UpdateZoomConnectionRequestReminderDocument, options);
}
export type UpdateZoomConnectionRequestReminderMutationHookResult = ReturnType<
  typeof useUpdateZoomConnectionRequestReminderMutation
>;
export type UpdateZoomConnectionRequestReminderMutationResult =
  Apollo.MutationResult<UpdateZoomConnectionRequestReminderMutation>;
export type UpdateZoomConnectionRequestReminderMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomConnectionRequestReminderMutation,
  UpdateZoomConnectionRequestReminderMutationVariables
>;
export const RemoveZoomConnectionRequestReminderModelByIdDocument = gql`
  mutation removeZoomConnectionRequestReminderModelById($id: uuid) {
    delete_zoomConnectionRequestReminder(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomConnectionRequestReminderModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomConnectionRequestReminderModelByIdMutation,
  RemoveZoomConnectionRequestReminderModelByIdMutationVariables
>;

/**
 * __useRemoveZoomConnectionRequestReminderModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomConnectionRequestReminderModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomConnectionRequestReminderModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomConnectionRequestReminderModelByIdMutation, { data, loading, error }] = useRemoveZoomConnectionRequestReminderModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomConnectionRequestReminderModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomConnectionRequestReminderModelByIdMutation,
    RemoveZoomConnectionRequestReminderModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomConnectionRequestReminderModelByIdMutation,
    RemoveZoomConnectionRequestReminderModelByIdMutationVariables
  >(RemoveZoomConnectionRequestReminderModelByIdDocument, options);
}
export type RemoveZoomConnectionRequestReminderModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomConnectionRequestReminderModelByIdMutation
>;
export type RemoveZoomConnectionRequestReminderModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomConnectionRequestReminderModelByIdMutation>;
export type RemoveZoomConnectionRequestReminderModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomConnectionRequestReminderModelByIdMutation,
  RemoveZoomConnectionRequestReminderModelByIdMutationVariables
>;
export const RemoveZoomConnectionRequestReminderModelDocument = gql`
  mutation removeZoomConnectionRequestReminderModel($where: zoomConnectionRequestReminder_bool_exp!) {
    delete_zoomConnectionRequestReminder(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomConnectionRequestReminderModelMutationFn = Apollo.MutationFunction<
  RemoveZoomConnectionRequestReminderModelMutation,
  RemoveZoomConnectionRequestReminderModelMutationVariables
>;

/**
 * __useRemoveZoomConnectionRequestReminderModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomConnectionRequestReminderModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomConnectionRequestReminderModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomConnectionRequestReminderModelMutation, { data, loading, error }] = useRemoveZoomConnectionRequestReminderModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomConnectionRequestReminderModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomConnectionRequestReminderModelMutation,
    RemoveZoomConnectionRequestReminderModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomConnectionRequestReminderModelMutation,
    RemoveZoomConnectionRequestReminderModelMutationVariables
  >(RemoveZoomConnectionRequestReminderModelDocument, options);
}
export type RemoveZoomConnectionRequestReminderModelMutationHookResult = ReturnType<
  typeof useRemoveZoomConnectionRequestReminderModelMutation
>;
export type RemoveZoomConnectionRequestReminderModelMutationResult =
  Apollo.MutationResult<RemoveZoomConnectionRequestReminderModelMutation>;
export type RemoveZoomConnectionRequestReminderModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomConnectionRequestReminderModelMutation,
  RemoveZoomConnectionRequestReminderModelMutationVariables
>;
export const QueryZoomApprovedDomainByIdDocument = gql`
  query queryZoomApprovedDomainById($zoomApprovedDomainId: uuid!) {
    zoomApprovedDomain_by_pk(id: $zoomApprovedDomainId) {
      ...ZoomApprovedDomain
    }
  }
  ${ZoomApprovedDomainFragmentDoc}
`;

/**
 * __useQueryZoomApprovedDomainByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomApprovedDomainByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomApprovedDomainByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomApprovedDomainByIdQuery({
 *   variables: {
 *      zoomApprovedDomainId: // value for 'zoomApprovedDomainId'
 *   },
 * });
 */
export function useQueryZoomApprovedDomainByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomApprovedDomainByIdQuery, QueryZoomApprovedDomainByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomApprovedDomainByIdQuery, QueryZoomApprovedDomainByIdQueryVariables>(
    QueryZoomApprovedDomainByIdDocument,
    options
  );
}
export function useQueryZoomApprovedDomainByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomApprovedDomainByIdQuery, QueryZoomApprovedDomainByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomApprovedDomainByIdQuery, QueryZoomApprovedDomainByIdQueryVariables>(
    QueryZoomApprovedDomainByIdDocument,
    options
  );
}
export type QueryZoomApprovedDomainByIdQueryHookResult = ReturnType<typeof useQueryZoomApprovedDomainByIdQuery>;
export type QueryZoomApprovedDomainByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomApprovedDomainByIdLazyQuery>;
export type QueryZoomApprovedDomainByIdQueryResult = Apollo.QueryResult<
  QueryZoomApprovedDomainByIdQuery,
  QueryZoomApprovedDomainByIdQueryVariables
>;
export const QueryZoomApprovedDomainObjectsDocument = gql`
  query queryZoomApprovedDomainObjects(
    $distinct_on: [zoomApprovedDomain_select_column!]
    $where: zoomApprovedDomain_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomApprovedDomain_order_by!]
  ) {
    zoomApprovedDomain(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomApprovedDomain
    }
  }
  ${ZoomApprovedDomainFragmentDoc}
`;

/**
 * __useQueryZoomApprovedDomainObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomApprovedDomainObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomApprovedDomainObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomApprovedDomainObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomApprovedDomainObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomApprovedDomainObjectsQuery,
    QueryZoomApprovedDomainObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomApprovedDomainObjectsQuery, QueryZoomApprovedDomainObjectsQueryVariables>(
    QueryZoomApprovedDomainObjectsDocument,
    options
  );
}
export function useQueryZoomApprovedDomainObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomApprovedDomainObjectsQuery,
    QueryZoomApprovedDomainObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomApprovedDomainObjectsQuery, QueryZoomApprovedDomainObjectsQueryVariables>(
    QueryZoomApprovedDomainObjectsDocument,
    options
  );
}
export type QueryZoomApprovedDomainObjectsQueryHookResult = ReturnType<typeof useQueryZoomApprovedDomainObjectsQuery>;
export type QueryZoomApprovedDomainObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomApprovedDomainObjectsLazyQuery
>;
export type QueryZoomApprovedDomainObjectsQueryResult = Apollo.QueryResult<
  QueryZoomApprovedDomainObjectsQuery,
  QueryZoomApprovedDomainObjectsQueryVariables
>;
export const SubscribeToZoomApprovedDomainByIdDocument = gql`
  subscription subscribeToZoomApprovedDomainById($zoomApprovedDomainId: uuid!) {
    zoomApprovedDomain_by_pk(id: $zoomApprovedDomainId) {
      ...ZoomApprovedDomain
    }
  }
  ${ZoomApprovedDomainFragmentDoc}
`;

/**
 * __useSubscribeToZoomApprovedDomainByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomApprovedDomainByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomApprovedDomainByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomApprovedDomainByIdSubscription({
 *   variables: {
 *      zoomApprovedDomainId: // value for 'zoomApprovedDomainId'
 *   },
 * });
 */
export function useSubscribeToZoomApprovedDomainByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomApprovedDomainByIdSubscription,
    SubscribeToZoomApprovedDomainByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomApprovedDomainByIdSubscription,
    SubscribeToZoomApprovedDomainByIdSubscriptionVariables
  >(SubscribeToZoomApprovedDomainByIdDocument, options);
}
export type SubscribeToZoomApprovedDomainByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomApprovedDomainByIdSubscription
>;
export type SubscribeToZoomApprovedDomainByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomApprovedDomainByIdSubscription>;
export const SubscribeToZoomApprovedDomainObjectsDocument = gql`
  subscription subscribeToZoomApprovedDomainObjects(
    $distinct_on: [zoomApprovedDomain_select_column!]
    $where: zoomApprovedDomain_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomApprovedDomain_order_by!]
  ) {
    zoomApprovedDomain(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomApprovedDomain
    }
  }
  ${ZoomApprovedDomainFragmentDoc}
`;

/**
 * __useSubscribeToZoomApprovedDomainObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomApprovedDomainObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomApprovedDomainObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomApprovedDomainObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomApprovedDomainObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomApprovedDomainObjectsSubscription,
    SubscribeToZoomApprovedDomainObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomApprovedDomainObjectsSubscription,
    SubscribeToZoomApprovedDomainObjectsSubscriptionVariables
  >(SubscribeToZoomApprovedDomainObjectsDocument, options);
}
export type SubscribeToZoomApprovedDomainObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomApprovedDomainObjectsSubscription
>;
export type SubscribeToZoomApprovedDomainObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomApprovedDomainObjectsSubscription>;
export const InsertZoomApprovedDomainDocument = gql`
  mutation insertZoomApprovedDomain($objects: [zoomApprovedDomain_insert_input!]!) {
    insert_zoomApprovedDomain(objects: $objects) {
      affected_rows
      returning {
        ...ZoomApprovedDomain
      }
    }
  }
  ${ZoomApprovedDomainFragmentDoc}
`;
export type InsertZoomApprovedDomainMutationFn = Apollo.MutationFunction<
  InsertZoomApprovedDomainMutation,
  InsertZoomApprovedDomainMutationVariables
>;

/**
 * __useInsertZoomApprovedDomainMutation__
 *
 * To run a mutation, you first call `useInsertZoomApprovedDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomApprovedDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomApprovedDomainMutation, { data, loading, error }] = useInsertZoomApprovedDomainMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomApprovedDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomApprovedDomainMutation, InsertZoomApprovedDomainMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomApprovedDomainMutation, InsertZoomApprovedDomainMutationVariables>(
    InsertZoomApprovedDomainDocument,
    options
  );
}
export type InsertZoomApprovedDomainMutationHookResult = ReturnType<typeof useInsertZoomApprovedDomainMutation>;
export type InsertZoomApprovedDomainMutationResult = Apollo.MutationResult<InsertZoomApprovedDomainMutation>;
export type InsertZoomApprovedDomainMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomApprovedDomainMutation,
  InsertZoomApprovedDomainMutationVariables
>;
export const InsertZoomApprovedDomainWithOnConflictDocument = gql`
  mutation insertZoomApprovedDomainWithOnConflict(
    $objects: [zoomApprovedDomain_insert_input!]!
    $onConflict: zoomApprovedDomain_on_conflict
  ) {
    insert_zoomApprovedDomain(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomApprovedDomain
      }
    }
  }
  ${ZoomApprovedDomainFragmentDoc}
`;
export type InsertZoomApprovedDomainWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomApprovedDomainWithOnConflictMutation,
  InsertZoomApprovedDomainWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomApprovedDomainWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomApprovedDomainWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomApprovedDomainWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomApprovedDomainWithOnConflictMutation, { data, loading, error }] = useInsertZoomApprovedDomainWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomApprovedDomainWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomApprovedDomainWithOnConflictMutation,
    InsertZoomApprovedDomainWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomApprovedDomainWithOnConflictMutation,
    InsertZoomApprovedDomainWithOnConflictMutationVariables
  >(InsertZoomApprovedDomainWithOnConflictDocument, options);
}
export type InsertZoomApprovedDomainWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomApprovedDomainWithOnConflictMutation
>;
export type InsertZoomApprovedDomainWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomApprovedDomainWithOnConflictMutation>;
export type InsertZoomApprovedDomainWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomApprovedDomainWithOnConflictMutation,
  InsertZoomApprovedDomainWithOnConflictMutationVariables
>;
export const UpdateZoomApprovedDomainByIdDocument = gql`
  mutation updateZoomApprovedDomainById($id: uuid, $set: zoomApprovedDomain_set_input) {
    update_zoomApprovedDomain(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomApprovedDomain
      }
    }
  }
  ${ZoomApprovedDomainFragmentDoc}
`;
export type UpdateZoomApprovedDomainByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomApprovedDomainByIdMutation,
  UpdateZoomApprovedDomainByIdMutationVariables
>;

/**
 * __useUpdateZoomApprovedDomainByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomApprovedDomainByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomApprovedDomainByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomApprovedDomainByIdMutation, { data, loading, error }] = useUpdateZoomApprovedDomainByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomApprovedDomainByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomApprovedDomainByIdMutation,
    UpdateZoomApprovedDomainByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomApprovedDomainByIdMutation, UpdateZoomApprovedDomainByIdMutationVariables>(
    UpdateZoomApprovedDomainByIdDocument,
    options
  );
}
export type UpdateZoomApprovedDomainByIdMutationHookResult = ReturnType<typeof useUpdateZoomApprovedDomainByIdMutation>;
export type UpdateZoomApprovedDomainByIdMutationResult = Apollo.MutationResult<UpdateZoomApprovedDomainByIdMutation>;
export type UpdateZoomApprovedDomainByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomApprovedDomainByIdMutation,
  UpdateZoomApprovedDomainByIdMutationVariables
>;
export const UpdateZoomApprovedDomainDocument = gql`
  mutation updateZoomApprovedDomain($set: zoomApprovedDomain_set_input, $where: zoomApprovedDomain_bool_exp!) {
    update_zoomApprovedDomain(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomApprovedDomain
      }
    }
  }
  ${ZoomApprovedDomainFragmentDoc}
`;
export type UpdateZoomApprovedDomainMutationFn = Apollo.MutationFunction<
  UpdateZoomApprovedDomainMutation,
  UpdateZoomApprovedDomainMutationVariables
>;

/**
 * __useUpdateZoomApprovedDomainMutation__
 *
 * To run a mutation, you first call `useUpdateZoomApprovedDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomApprovedDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomApprovedDomainMutation, { data, loading, error }] = useUpdateZoomApprovedDomainMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomApprovedDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomApprovedDomainMutation, UpdateZoomApprovedDomainMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomApprovedDomainMutation, UpdateZoomApprovedDomainMutationVariables>(
    UpdateZoomApprovedDomainDocument,
    options
  );
}
export type UpdateZoomApprovedDomainMutationHookResult = ReturnType<typeof useUpdateZoomApprovedDomainMutation>;
export type UpdateZoomApprovedDomainMutationResult = Apollo.MutationResult<UpdateZoomApprovedDomainMutation>;
export type UpdateZoomApprovedDomainMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomApprovedDomainMutation,
  UpdateZoomApprovedDomainMutationVariables
>;
export const RemoveZoomApprovedDomainModelByIdDocument = gql`
  mutation removeZoomApprovedDomainModelById($id: uuid) {
    delete_zoomApprovedDomain(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomApprovedDomainModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomApprovedDomainModelByIdMutation,
  RemoveZoomApprovedDomainModelByIdMutationVariables
>;

/**
 * __useRemoveZoomApprovedDomainModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomApprovedDomainModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomApprovedDomainModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomApprovedDomainModelByIdMutation, { data, loading, error }] = useRemoveZoomApprovedDomainModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomApprovedDomainModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomApprovedDomainModelByIdMutation,
    RemoveZoomApprovedDomainModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomApprovedDomainModelByIdMutation,
    RemoveZoomApprovedDomainModelByIdMutationVariables
  >(RemoveZoomApprovedDomainModelByIdDocument, options);
}
export type RemoveZoomApprovedDomainModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomApprovedDomainModelByIdMutation
>;
export type RemoveZoomApprovedDomainModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomApprovedDomainModelByIdMutation>;
export type RemoveZoomApprovedDomainModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomApprovedDomainModelByIdMutation,
  RemoveZoomApprovedDomainModelByIdMutationVariables
>;
export const RemoveZoomApprovedDomainModelDocument = gql`
  mutation removeZoomApprovedDomainModel($where: zoomApprovedDomain_bool_exp!) {
    delete_zoomApprovedDomain(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomApprovedDomainModelMutationFn = Apollo.MutationFunction<
  RemoveZoomApprovedDomainModelMutation,
  RemoveZoomApprovedDomainModelMutationVariables
>;

/**
 * __useRemoveZoomApprovedDomainModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomApprovedDomainModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomApprovedDomainModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomApprovedDomainModelMutation, { data, loading, error }] = useRemoveZoomApprovedDomainModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomApprovedDomainModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomApprovedDomainModelMutation,
    RemoveZoomApprovedDomainModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomApprovedDomainModelMutation, RemoveZoomApprovedDomainModelMutationVariables>(
    RemoveZoomApprovedDomainModelDocument,
    options
  );
}
export type RemoveZoomApprovedDomainModelMutationHookResult = ReturnType<
  typeof useRemoveZoomApprovedDomainModelMutation
>;
export type RemoveZoomApprovedDomainModelMutationResult = Apollo.MutationResult<RemoveZoomApprovedDomainModelMutation>;
export type RemoveZoomApprovedDomainModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomApprovedDomainModelMutation,
  RemoveZoomApprovedDomainModelMutationVariables
>;
export const QueryZoomConversationStarterAnswerByIdDocument = gql`
  query queryZoomConversationStarterAnswerById($zoomConversationStarterAnswerId: uuid!) {
    zoomConversationStarterAnswer_by_pk(id: $zoomConversationStarterAnswerId) {
      ...ZoomConversationStarterAnswer
    }
  }
  ${ZoomConversationStarterAnswerFragmentDoc}
`;

/**
 * __useQueryZoomConversationStarterAnswerByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomConversationStarterAnswerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomConversationStarterAnswerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomConversationStarterAnswerByIdQuery({
 *   variables: {
 *      zoomConversationStarterAnswerId: // value for 'zoomConversationStarterAnswerId'
 *   },
 * });
 */
export function useQueryZoomConversationStarterAnswerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomConversationStarterAnswerByIdQuery,
    QueryZoomConversationStarterAnswerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryZoomConversationStarterAnswerByIdQuery,
    QueryZoomConversationStarterAnswerByIdQueryVariables
  >(QueryZoomConversationStarterAnswerByIdDocument, options);
}
export function useQueryZoomConversationStarterAnswerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomConversationStarterAnswerByIdQuery,
    QueryZoomConversationStarterAnswerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomConversationStarterAnswerByIdQuery,
    QueryZoomConversationStarterAnswerByIdQueryVariables
  >(QueryZoomConversationStarterAnswerByIdDocument, options);
}
export type QueryZoomConversationStarterAnswerByIdQueryHookResult = ReturnType<
  typeof useQueryZoomConversationStarterAnswerByIdQuery
>;
export type QueryZoomConversationStarterAnswerByIdLazyQueryHookResult = ReturnType<
  typeof useQueryZoomConversationStarterAnswerByIdLazyQuery
>;
export type QueryZoomConversationStarterAnswerByIdQueryResult = Apollo.QueryResult<
  QueryZoomConversationStarterAnswerByIdQuery,
  QueryZoomConversationStarterAnswerByIdQueryVariables
>;
export const QueryZoomConversationStarterAnswerObjectsDocument = gql`
  query queryZoomConversationStarterAnswerObjects(
    $distinct_on: [zoomConversationStarterAnswer_select_column!]
    $where: zoomConversationStarterAnswer_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomConversationStarterAnswer_order_by!]
  ) {
    zoomConversationStarterAnswer(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomConversationStarterAnswer
    }
  }
  ${ZoomConversationStarterAnswerFragmentDoc}
`;

/**
 * __useQueryZoomConversationStarterAnswerObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomConversationStarterAnswerObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomConversationStarterAnswerObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomConversationStarterAnswerObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomConversationStarterAnswerObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomConversationStarterAnswerObjectsQuery,
    QueryZoomConversationStarterAnswerObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryZoomConversationStarterAnswerObjectsQuery,
    QueryZoomConversationStarterAnswerObjectsQueryVariables
  >(QueryZoomConversationStarterAnswerObjectsDocument, options);
}
export function useQueryZoomConversationStarterAnswerObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomConversationStarterAnswerObjectsQuery,
    QueryZoomConversationStarterAnswerObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomConversationStarterAnswerObjectsQuery,
    QueryZoomConversationStarterAnswerObjectsQueryVariables
  >(QueryZoomConversationStarterAnswerObjectsDocument, options);
}
export type QueryZoomConversationStarterAnswerObjectsQueryHookResult = ReturnType<
  typeof useQueryZoomConversationStarterAnswerObjectsQuery
>;
export type QueryZoomConversationStarterAnswerObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomConversationStarterAnswerObjectsLazyQuery
>;
export type QueryZoomConversationStarterAnswerObjectsQueryResult = Apollo.QueryResult<
  QueryZoomConversationStarterAnswerObjectsQuery,
  QueryZoomConversationStarterAnswerObjectsQueryVariables
>;
export const SubscribeToZoomConversationStarterAnswerByIdDocument = gql`
  subscription subscribeToZoomConversationStarterAnswerById($zoomConversationStarterAnswerId: uuid!) {
    zoomConversationStarterAnswer_by_pk(id: $zoomConversationStarterAnswerId) {
      ...ZoomConversationStarterAnswer
    }
  }
  ${ZoomConversationStarterAnswerFragmentDoc}
`;

/**
 * __useSubscribeToZoomConversationStarterAnswerByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomConversationStarterAnswerByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomConversationStarterAnswerByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomConversationStarterAnswerByIdSubscription({
 *   variables: {
 *      zoomConversationStarterAnswerId: // value for 'zoomConversationStarterAnswerId'
 *   },
 * });
 */
export function useSubscribeToZoomConversationStarterAnswerByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomConversationStarterAnswerByIdSubscription,
    SubscribeToZoomConversationStarterAnswerByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomConversationStarterAnswerByIdSubscription,
    SubscribeToZoomConversationStarterAnswerByIdSubscriptionVariables
  >(SubscribeToZoomConversationStarterAnswerByIdDocument, options);
}
export type SubscribeToZoomConversationStarterAnswerByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomConversationStarterAnswerByIdSubscription
>;
export type SubscribeToZoomConversationStarterAnswerByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomConversationStarterAnswerByIdSubscription>;
export const SubscribeToZoomConversationStarterAnswerObjectsDocument = gql`
  subscription subscribeToZoomConversationStarterAnswerObjects(
    $distinct_on: [zoomConversationStarterAnswer_select_column!]
    $where: zoomConversationStarterAnswer_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomConversationStarterAnswer_order_by!]
  ) {
    zoomConversationStarterAnswer(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomConversationStarterAnswer
    }
  }
  ${ZoomConversationStarterAnswerFragmentDoc}
`;

/**
 * __useSubscribeToZoomConversationStarterAnswerObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomConversationStarterAnswerObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomConversationStarterAnswerObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomConversationStarterAnswerObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomConversationStarterAnswerObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomConversationStarterAnswerObjectsSubscription,
    SubscribeToZoomConversationStarterAnswerObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomConversationStarterAnswerObjectsSubscription,
    SubscribeToZoomConversationStarterAnswerObjectsSubscriptionVariables
  >(SubscribeToZoomConversationStarterAnswerObjectsDocument, options);
}
export type SubscribeToZoomConversationStarterAnswerObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomConversationStarterAnswerObjectsSubscription
>;
export type SubscribeToZoomConversationStarterAnswerObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomConversationStarterAnswerObjectsSubscription>;
export const InsertZoomConversationStarterAnswerDocument = gql`
  mutation insertZoomConversationStarterAnswer($objects: [zoomConversationStarterAnswer_insert_input!]!) {
    insert_zoomConversationStarterAnswer(objects: $objects) {
      affected_rows
      returning {
        ...ZoomConversationStarterAnswer
      }
    }
  }
  ${ZoomConversationStarterAnswerFragmentDoc}
`;
export type InsertZoomConversationStarterAnswerMutationFn = Apollo.MutationFunction<
  InsertZoomConversationStarterAnswerMutation,
  InsertZoomConversationStarterAnswerMutationVariables
>;

/**
 * __useInsertZoomConversationStarterAnswerMutation__
 *
 * To run a mutation, you first call `useInsertZoomConversationStarterAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomConversationStarterAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomConversationStarterAnswerMutation, { data, loading, error }] = useInsertZoomConversationStarterAnswerMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomConversationStarterAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomConversationStarterAnswerMutation,
    InsertZoomConversationStarterAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomConversationStarterAnswerMutation,
    InsertZoomConversationStarterAnswerMutationVariables
  >(InsertZoomConversationStarterAnswerDocument, options);
}
export type InsertZoomConversationStarterAnswerMutationHookResult = ReturnType<
  typeof useInsertZoomConversationStarterAnswerMutation
>;
export type InsertZoomConversationStarterAnswerMutationResult =
  Apollo.MutationResult<InsertZoomConversationStarterAnswerMutation>;
export type InsertZoomConversationStarterAnswerMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomConversationStarterAnswerMutation,
  InsertZoomConversationStarterAnswerMutationVariables
>;
export const InsertZoomConversationStarterAnswerWithOnConflictDocument = gql`
  mutation insertZoomConversationStarterAnswerWithOnConflict(
    $objects: [zoomConversationStarterAnswer_insert_input!]!
    $onConflict: zoomConversationStarterAnswer_on_conflict
  ) {
    insert_zoomConversationStarterAnswer(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomConversationStarterAnswer
      }
    }
  }
  ${ZoomConversationStarterAnswerFragmentDoc}
`;
export type InsertZoomConversationStarterAnswerWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomConversationStarterAnswerWithOnConflictMutation,
  InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomConversationStarterAnswerWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomConversationStarterAnswerWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomConversationStarterAnswerWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomConversationStarterAnswerWithOnConflictMutation, { data, loading, error }] = useInsertZoomConversationStarterAnswerWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomConversationStarterAnswerWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomConversationStarterAnswerWithOnConflictMutation,
    InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomConversationStarterAnswerWithOnConflictMutation,
    InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
  >(InsertZoomConversationStarterAnswerWithOnConflictDocument, options);
}
export type InsertZoomConversationStarterAnswerWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomConversationStarterAnswerWithOnConflictMutation
>;
export type InsertZoomConversationStarterAnswerWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomConversationStarterAnswerWithOnConflictMutation>;
export type InsertZoomConversationStarterAnswerWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomConversationStarterAnswerWithOnConflictMutation,
  InsertZoomConversationStarterAnswerWithOnConflictMutationVariables
>;
export const UpdateZoomConversationStarterAnswerByIdDocument = gql`
  mutation updateZoomConversationStarterAnswerById($id: uuid, $set: zoomConversationStarterAnswer_set_input) {
    update_zoomConversationStarterAnswer(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomConversationStarterAnswer
      }
    }
  }
  ${ZoomConversationStarterAnswerFragmentDoc}
`;
export type UpdateZoomConversationStarterAnswerByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomConversationStarterAnswerByIdMutation,
  UpdateZoomConversationStarterAnswerByIdMutationVariables
>;

/**
 * __useUpdateZoomConversationStarterAnswerByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomConversationStarterAnswerByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomConversationStarterAnswerByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomConversationStarterAnswerByIdMutation, { data, loading, error }] = useUpdateZoomConversationStarterAnswerByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomConversationStarterAnswerByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomConversationStarterAnswerByIdMutation,
    UpdateZoomConversationStarterAnswerByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomConversationStarterAnswerByIdMutation,
    UpdateZoomConversationStarterAnswerByIdMutationVariables
  >(UpdateZoomConversationStarterAnswerByIdDocument, options);
}
export type UpdateZoomConversationStarterAnswerByIdMutationHookResult = ReturnType<
  typeof useUpdateZoomConversationStarterAnswerByIdMutation
>;
export type UpdateZoomConversationStarterAnswerByIdMutationResult =
  Apollo.MutationResult<UpdateZoomConversationStarterAnswerByIdMutation>;
export type UpdateZoomConversationStarterAnswerByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomConversationStarterAnswerByIdMutation,
  UpdateZoomConversationStarterAnswerByIdMutationVariables
>;
export const UpdateZoomConversationStarterAnswerDocument = gql`
  mutation updateZoomConversationStarterAnswer(
    $set: zoomConversationStarterAnswer_set_input
    $where: zoomConversationStarterAnswer_bool_exp!
  ) {
    update_zoomConversationStarterAnswer(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomConversationStarterAnswer
      }
    }
  }
  ${ZoomConversationStarterAnswerFragmentDoc}
`;
export type UpdateZoomConversationStarterAnswerMutationFn = Apollo.MutationFunction<
  UpdateZoomConversationStarterAnswerMutation,
  UpdateZoomConversationStarterAnswerMutationVariables
>;

/**
 * __useUpdateZoomConversationStarterAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateZoomConversationStarterAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomConversationStarterAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomConversationStarterAnswerMutation, { data, loading, error }] = useUpdateZoomConversationStarterAnswerMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomConversationStarterAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomConversationStarterAnswerMutation,
    UpdateZoomConversationStarterAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomConversationStarterAnswerMutation,
    UpdateZoomConversationStarterAnswerMutationVariables
  >(UpdateZoomConversationStarterAnswerDocument, options);
}
export type UpdateZoomConversationStarterAnswerMutationHookResult = ReturnType<
  typeof useUpdateZoomConversationStarterAnswerMutation
>;
export type UpdateZoomConversationStarterAnswerMutationResult =
  Apollo.MutationResult<UpdateZoomConversationStarterAnswerMutation>;
export type UpdateZoomConversationStarterAnswerMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomConversationStarterAnswerMutation,
  UpdateZoomConversationStarterAnswerMutationVariables
>;
export const RemoveZoomConversationStarterAnswerModelByIdDocument = gql`
  mutation removeZoomConversationStarterAnswerModelById($id: uuid) {
    delete_zoomConversationStarterAnswer(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomConversationStarterAnswerModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomConversationStarterAnswerModelByIdMutation,
  RemoveZoomConversationStarterAnswerModelByIdMutationVariables
>;

/**
 * __useRemoveZoomConversationStarterAnswerModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomConversationStarterAnswerModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomConversationStarterAnswerModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomConversationStarterAnswerModelByIdMutation, { data, loading, error }] = useRemoveZoomConversationStarterAnswerModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomConversationStarterAnswerModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomConversationStarterAnswerModelByIdMutation,
    RemoveZoomConversationStarterAnswerModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomConversationStarterAnswerModelByIdMutation,
    RemoveZoomConversationStarterAnswerModelByIdMutationVariables
  >(RemoveZoomConversationStarterAnswerModelByIdDocument, options);
}
export type RemoveZoomConversationStarterAnswerModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomConversationStarterAnswerModelByIdMutation
>;
export type RemoveZoomConversationStarterAnswerModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomConversationStarterAnswerModelByIdMutation>;
export type RemoveZoomConversationStarterAnswerModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomConversationStarterAnswerModelByIdMutation,
  RemoveZoomConversationStarterAnswerModelByIdMutationVariables
>;
export const RemoveZoomConversationStarterAnswerModelDocument = gql`
  mutation removeZoomConversationStarterAnswerModel($where: zoomConversationStarterAnswer_bool_exp!) {
    delete_zoomConversationStarterAnswer(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomConversationStarterAnswerModelMutationFn = Apollo.MutationFunction<
  RemoveZoomConversationStarterAnswerModelMutation,
  RemoveZoomConversationStarterAnswerModelMutationVariables
>;

/**
 * __useRemoveZoomConversationStarterAnswerModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomConversationStarterAnswerModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomConversationStarterAnswerModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomConversationStarterAnswerModelMutation, { data, loading, error }] = useRemoveZoomConversationStarterAnswerModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomConversationStarterAnswerModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomConversationStarterAnswerModelMutation,
    RemoveZoomConversationStarterAnswerModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomConversationStarterAnswerModelMutation,
    RemoveZoomConversationStarterAnswerModelMutationVariables
  >(RemoveZoomConversationStarterAnswerModelDocument, options);
}
export type RemoveZoomConversationStarterAnswerModelMutationHookResult = ReturnType<
  typeof useRemoveZoomConversationStarterAnswerModelMutation
>;
export type RemoveZoomConversationStarterAnswerModelMutationResult =
  Apollo.MutationResult<RemoveZoomConversationStarterAnswerModelMutation>;
export type RemoveZoomConversationStarterAnswerModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomConversationStarterAnswerModelMutation,
  RemoveZoomConversationStarterAnswerModelMutationVariables
>;
export const QueryZoomConversationStarterPromptByIdDocument = gql`
  query queryZoomConversationStarterPromptById($zoomConversationStarterPromptId: uuid!) {
    zoomConversationStarterPrompt_by_pk(id: $zoomConversationStarterPromptId) {
      ...ZoomConversationStarterPrompt
    }
  }
  ${ZoomConversationStarterPromptFragmentDoc}
`;

/**
 * __useQueryZoomConversationStarterPromptByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomConversationStarterPromptByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomConversationStarterPromptByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomConversationStarterPromptByIdQuery({
 *   variables: {
 *      zoomConversationStarterPromptId: // value for 'zoomConversationStarterPromptId'
 *   },
 * });
 */
export function useQueryZoomConversationStarterPromptByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomConversationStarterPromptByIdQuery,
    QueryZoomConversationStarterPromptByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryZoomConversationStarterPromptByIdQuery,
    QueryZoomConversationStarterPromptByIdQueryVariables
  >(QueryZoomConversationStarterPromptByIdDocument, options);
}
export function useQueryZoomConversationStarterPromptByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomConversationStarterPromptByIdQuery,
    QueryZoomConversationStarterPromptByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomConversationStarterPromptByIdQuery,
    QueryZoomConversationStarterPromptByIdQueryVariables
  >(QueryZoomConversationStarterPromptByIdDocument, options);
}
export type QueryZoomConversationStarterPromptByIdQueryHookResult = ReturnType<
  typeof useQueryZoomConversationStarterPromptByIdQuery
>;
export type QueryZoomConversationStarterPromptByIdLazyQueryHookResult = ReturnType<
  typeof useQueryZoomConversationStarterPromptByIdLazyQuery
>;
export type QueryZoomConversationStarterPromptByIdQueryResult = Apollo.QueryResult<
  QueryZoomConversationStarterPromptByIdQuery,
  QueryZoomConversationStarterPromptByIdQueryVariables
>;
export const QueryZoomConversationStarterPromptObjectsDocument = gql`
  query queryZoomConversationStarterPromptObjects(
    $distinct_on: [zoomConversationStarterPrompt_select_column!]
    $where: zoomConversationStarterPrompt_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomConversationStarterPrompt_order_by!]
  ) {
    zoomConversationStarterPrompt(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomConversationStarterPrompt
    }
  }
  ${ZoomConversationStarterPromptFragmentDoc}
`;

/**
 * __useQueryZoomConversationStarterPromptObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomConversationStarterPromptObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomConversationStarterPromptObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomConversationStarterPromptObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomConversationStarterPromptObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryZoomConversationStarterPromptObjectsQuery,
    QueryZoomConversationStarterPromptObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryZoomConversationStarterPromptObjectsQuery,
    QueryZoomConversationStarterPromptObjectsQueryVariables
  >(QueryZoomConversationStarterPromptObjectsDocument, options);
}
export function useQueryZoomConversationStarterPromptObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomConversationStarterPromptObjectsQuery,
    QueryZoomConversationStarterPromptObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomConversationStarterPromptObjectsQuery,
    QueryZoomConversationStarterPromptObjectsQueryVariables
  >(QueryZoomConversationStarterPromptObjectsDocument, options);
}
export type QueryZoomConversationStarterPromptObjectsQueryHookResult = ReturnType<
  typeof useQueryZoomConversationStarterPromptObjectsQuery
>;
export type QueryZoomConversationStarterPromptObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomConversationStarterPromptObjectsLazyQuery
>;
export type QueryZoomConversationStarterPromptObjectsQueryResult = Apollo.QueryResult<
  QueryZoomConversationStarterPromptObjectsQuery,
  QueryZoomConversationStarterPromptObjectsQueryVariables
>;
export const SubscribeToZoomConversationStarterPromptByIdDocument = gql`
  subscription subscribeToZoomConversationStarterPromptById($zoomConversationStarterPromptId: uuid!) {
    zoomConversationStarterPrompt_by_pk(id: $zoomConversationStarterPromptId) {
      ...ZoomConversationStarterPrompt
    }
  }
  ${ZoomConversationStarterPromptFragmentDoc}
`;

/**
 * __useSubscribeToZoomConversationStarterPromptByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomConversationStarterPromptByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomConversationStarterPromptByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomConversationStarterPromptByIdSubscription({
 *   variables: {
 *      zoomConversationStarterPromptId: // value for 'zoomConversationStarterPromptId'
 *   },
 * });
 */
export function useSubscribeToZoomConversationStarterPromptByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomConversationStarterPromptByIdSubscription,
    SubscribeToZoomConversationStarterPromptByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomConversationStarterPromptByIdSubscription,
    SubscribeToZoomConversationStarterPromptByIdSubscriptionVariables
  >(SubscribeToZoomConversationStarterPromptByIdDocument, options);
}
export type SubscribeToZoomConversationStarterPromptByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomConversationStarterPromptByIdSubscription
>;
export type SubscribeToZoomConversationStarterPromptByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomConversationStarterPromptByIdSubscription>;
export const SubscribeToZoomConversationStarterPromptObjectsDocument = gql`
  subscription subscribeToZoomConversationStarterPromptObjects(
    $distinct_on: [zoomConversationStarterPrompt_select_column!]
    $where: zoomConversationStarterPrompt_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomConversationStarterPrompt_order_by!]
  ) {
    zoomConversationStarterPrompt(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomConversationStarterPrompt
    }
  }
  ${ZoomConversationStarterPromptFragmentDoc}
`;

/**
 * __useSubscribeToZoomConversationStarterPromptObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomConversationStarterPromptObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomConversationStarterPromptObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomConversationStarterPromptObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomConversationStarterPromptObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomConversationStarterPromptObjectsSubscription,
    SubscribeToZoomConversationStarterPromptObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomConversationStarterPromptObjectsSubscription,
    SubscribeToZoomConversationStarterPromptObjectsSubscriptionVariables
  >(SubscribeToZoomConversationStarterPromptObjectsDocument, options);
}
export type SubscribeToZoomConversationStarterPromptObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomConversationStarterPromptObjectsSubscription
>;
export type SubscribeToZoomConversationStarterPromptObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomConversationStarterPromptObjectsSubscription>;
export const InsertZoomConversationStarterPromptDocument = gql`
  mutation insertZoomConversationStarterPrompt($objects: [zoomConversationStarterPrompt_insert_input!]!) {
    insert_zoomConversationStarterPrompt(objects: $objects) {
      affected_rows
      returning {
        ...ZoomConversationStarterPrompt
      }
    }
  }
  ${ZoomConversationStarterPromptFragmentDoc}
`;
export type InsertZoomConversationStarterPromptMutationFn = Apollo.MutationFunction<
  InsertZoomConversationStarterPromptMutation,
  InsertZoomConversationStarterPromptMutationVariables
>;

/**
 * __useInsertZoomConversationStarterPromptMutation__
 *
 * To run a mutation, you first call `useInsertZoomConversationStarterPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomConversationStarterPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomConversationStarterPromptMutation, { data, loading, error }] = useInsertZoomConversationStarterPromptMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomConversationStarterPromptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomConversationStarterPromptMutation,
    InsertZoomConversationStarterPromptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomConversationStarterPromptMutation,
    InsertZoomConversationStarterPromptMutationVariables
  >(InsertZoomConversationStarterPromptDocument, options);
}
export type InsertZoomConversationStarterPromptMutationHookResult = ReturnType<
  typeof useInsertZoomConversationStarterPromptMutation
>;
export type InsertZoomConversationStarterPromptMutationResult =
  Apollo.MutationResult<InsertZoomConversationStarterPromptMutation>;
export type InsertZoomConversationStarterPromptMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomConversationStarterPromptMutation,
  InsertZoomConversationStarterPromptMutationVariables
>;
export const InsertZoomConversationStarterPromptWithOnConflictDocument = gql`
  mutation insertZoomConversationStarterPromptWithOnConflict(
    $objects: [zoomConversationStarterPrompt_insert_input!]!
    $onConflict: zoomConversationStarterPrompt_on_conflict
  ) {
    insert_zoomConversationStarterPrompt(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomConversationStarterPrompt
      }
    }
  }
  ${ZoomConversationStarterPromptFragmentDoc}
`;
export type InsertZoomConversationStarterPromptWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomConversationStarterPromptWithOnConflictMutation,
  InsertZoomConversationStarterPromptWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomConversationStarterPromptWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomConversationStarterPromptWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomConversationStarterPromptWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomConversationStarterPromptWithOnConflictMutation, { data, loading, error }] = useInsertZoomConversationStarterPromptWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomConversationStarterPromptWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomConversationStarterPromptWithOnConflictMutation,
    InsertZoomConversationStarterPromptWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomConversationStarterPromptWithOnConflictMutation,
    InsertZoomConversationStarterPromptWithOnConflictMutationVariables
  >(InsertZoomConversationStarterPromptWithOnConflictDocument, options);
}
export type InsertZoomConversationStarterPromptWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomConversationStarterPromptWithOnConflictMutation
>;
export type InsertZoomConversationStarterPromptWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomConversationStarterPromptWithOnConflictMutation>;
export type InsertZoomConversationStarterPromptWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomConversationStarterPromptWithOnConflictMutation,
  InsertZoomConversationStarterPromptWithOnConflictMutationVariables
>;
export const UpdateZoomConversationStarterPromptByIdDocument = gql`
  mutation updateZoomConversationStarterPromptById($id: uuid, $set: zoomConversationStarterPrompt_set_input) {
    update_zoomConversationStarterPrompt(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomConversationStarterPrompt
      }
    }
  }
  ${ZoomConversationStarterPromptFragmentDoc}
`;
export type UpdateZoomConversationStarterPromptByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomConversationStarterPromptByIdMutation,
  UpdateZoomConversationStarterPromptByIdMutationVariables
>;

/**
 * __useUpdateZoomConversationStarterPromptByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomConversationStarterPromptByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomConversationStarterPromptByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomConversationStarterPromptByIdMutation, { data, loading, error }] = useUpdateZoomConversationStarterPromptByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomConversationStarterPromptByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomConversationStarterPromptByIdMutation,
    UpdateZoomConversationStarterPromptByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomConversationStarterPromptByIdMutation,
    UpdateZoomConversationStarterPromptByIdMutationVariables
  >(UpdateZoomConversationStarterPromptByIdDocument, options);
}
export type UpdateZoomConversationStarterPromptByIdMutationHookResult = ReturnType<
  typeof useUpdateZoomConversationStarterPromptByIdMutation
>;
export type UpdateZoomConversationStarterPromptByIdMutationResult =
  Apollo.MutationResult<UpdateZoomConversationStarterPromptByIdMutation>;
export type UpdateZoomConversationStarterPromptByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomConversationStarterPromptByIdMutation,
  UpdateZoomConversationStarterPromptByIdMutationVariables
>;
export const UpdateZoomConversationStarterPromptDocument = gql`
  mutation updateZoomConversationStarterPrompt(
    $set: zoomConversationStarterPrompt_set_input
    $where: zoomConversationStarterPrompt_bool_exp!
  ) {
    update_zoomConversationStarterPrompt(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomConversationStarterPrompt
      }
    }
  }
  ${ZoomConversationStarterPromptFragmentDoc}
`;
export type UpdateZoomConversationStarterPromptMutationFn = Apollo.MutationFunction<
  UpdateZoomConversationStarterPromptMutation,
  UpdateZoomConversationStarterPromptMutationVariables
>;

/**
 * __useUpdateZoomConversationStarterPromptMutation__
 *
 * To run a mutation, you first call `useUpdateZoomConversationStarterPromptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomConversationStarterPromptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomConversationStarterPromptMutation, { data, loading, error }] = useUpdateZoomConversationStarterPromptMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomConversationStarterPromptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomConversationStarterPromptMutation,
    UpdateZoomConversationStarterPromptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomConversationStarterPromptMutation,
    UpdateZoomConversationStarterPromptMutationVariables
  >(UpdateZoomConversationStarterPromptDocument, options);
}
export type UpdateZoomConversationStarterPromptMutationHookResult = ReturnType<
  typeof useUpdateZoomConversationStarterPromptMutation
>;
export type UpdateZoomConversationStarterPromptMutationResult =
  Apollo.MutationResult<UpdateZoomConversationStarterPromptMutation>;
export type UpdateZoomConversationStarterPromptMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomConversationStarterPromptMutation,
  UpdateZoomConversationStarterPromptMutationVariables
>;
export const RemoveZoomConversationStarterPromptModelByIdDocument = gql`
  mutation removeZoomConversationStarterPromptModelById($id: uuid) {
    delete_zoomConversationStarterPrompt(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomConversationStarterPromptModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomConversationStarterPromptModelByIdMutation,
  RemoveZoomConversationStarterPromptModelByIdMutationVariables
>;

/**
 * __useRemoveZoomConversationStarterPromptModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomConversationStarterPromptModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomConversationStarterPromptModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomConversationStarterPromptModelByIdMutation, { data, loading, error }] = useRemoveZoomConversationStarterPromptModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomConversationStarterPromptModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomConversationStarterPromptModelByIdMutation,
    RemoveZoomConversationStarterPromptModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomConversationStarterPromptModelByIdMutation,
    RemoveZoomConversationStarterPromptModelByIdMutationVariables
  >(RemoveZoomConversationStarterPromptModelByIdDocument, options);
}
export type RemoveZoomConversationStarterPromptModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomConversationStarterPromptModelByIdMutation
>;
export type RemoveZoomConversationStarterPromptModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomConversationStarterPromptModelByIdMutation>;
export type RemoveZoomConversationStarterPromptModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomConversationStarterPromptModelByIdMutation,
  RemoveZoomConversationStarterPromptModelByIdMutationVariables
>;
export const RemoveZoomConversationStarterPromptModelDocument = gql`
  mutation removeZoomConversationStarterPromptModel($where: zoomConversationStarterPrompt_bool_exp!) {
    delete_zoomConversationStarterPrompt(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomConversationStarterPromptModelMutationFn = Apollo.MutationFunction<
  RemoveZoomConversationStarterPromptModelMutation,
  RemoveZoomConversationStarterPromptModelMutationVariables
>;

/**
 * __useRemoveZoomConversationStarterPromptModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomConversationStarterPromptModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomConversationStarterPromptModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomConversationStarterPromptModelMutation, { data, loading, error }] = useRemoveZoomConversationStarterPromptModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomConversationStarterPromptModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomConversationStarterPromptModelMutation,
    RemoveZoomConversationStarterPromptModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveZoomConversationStarterPromptModelMutation,
    RemoveZoomConversationStarterPromptModelMutationVariables
  >(RemoveZoomConversationStarterPromptModelDocument, options);
}
export type RemoveZoomConversationStarterPromptModelMutationHookResult = ReturnType<
  typeof useRemoveZoomConversationStarterPromptModelMutation
>;
export type RemoveZoomConversationStarterPromptModelMutationResult =
  Apollo.MutationResult<RemoveZoomConversationStarterPromptModelMutation>;
export type RemoveZoomConversationStarterPromptModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomConversationStarterPromptModelMutation,
  RemoveZoomConversationStarterPromptModelMutationVariables
>;
export const QueryContentByIdDocument = gql`
  query queryContentById($contentId: uuid!) {
    content_by_pk(id: $contentId) {
      ...Content
    }
  }
  ${ContentFragmentDoc}
`;

/**
 * __useQueryContentByIdQuery__
 *
 * To run a query within a React component, call `useQueryContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryContentByIdQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useQueryContentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryContentByIdQuery, QueryContentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryContentByIdQuery, QueryContentByIdQueryVariables>(QueryContentByIdDocument, options);
}
export function useQueryContentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryContentByIdQuery, QueryContentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryContentByIdQuery, QueryContentByIdQueryVariables>(QueryContentByIdDocument, options);
}
export type QueryContentByIdQueryHookResult = ReturnType<typeof useQueryContentByIdQuery>;
export type QueryContentByIdLazyQueryHookResult = ReturnType<typeof useQueryContentByIdLazyQuery>;
export type QueryContentByIdQueryResult = Apollo.QueryResult<QueryContentByIdQuery, QueryContentByIdQueryVariables>;
export const QueryContentObjectsDocument = gql`
  query queryContentObjects(
    $distinct_on: [content_select_column!]
    $where: content_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [content_order_by!]
  ) {
    content(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...Content
    }
  }
  ${ContentFragmentDoc}
`;

/**
 * __useQueryContentObjectsQuery__
 *
 * To run a query within a React component, call `useQueryContentObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryContentObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryContentObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryContentObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryContentObjectsQuery, QueryContentObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryContentObjectsQuery, QueryContentObjectsQueryVariables>(
    QueryContentObjectsDocument,
    options
  );
}
export function useQueryContentObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryContentObjectsQuery, QueryContentObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryContentObjectsQuery, QueryContentObjectsQueryVariables>(
    QueryContentObjectsDocument,
    options
  );
}
export type QueryContentObjectsQueryHookResult = ReturnType<typeof useQueryContentObjectsQuery>;
export type QueryContentObjectsLazyQueryHookResult = ReturnType<typeof useQueryContentObjectsLazyQuery>;
export type QueryContentObjectsQueryResult = Apollo.QueryResult<
  QueryContentObjectsQuery,
  QueryContentObjectsQueryVariables
>;
export const SubscribeToContentByIdDocument = gql`
  subscription subscribeToContentById($contentId: uuid!) {
    content_by_pk(id: $contentId) {
      ...Content
    }
  }
  ${ContentFragmentDoc}
`;

/**
 * __useSubscribeToContentByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToContentByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToContentByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToContentByIdSubscription({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useSubscribeToContentByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToContentByIdSubscription,
    SubscribeToContentByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SubscribeToContentByIdSubscription, SubscribeToContentByIdSubscriptionVariables>(
    SubscribeToContentByIdDocument,
    options
  );
}
export type SubscribeToContentByIdSubscriptionHookResult = ReturnType<typeof useSubscribeToContentByIdSubscription>;
export type SubscribeToContentByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToContentByIdSubscription>;
export const SubscribeToContentObjectsDocument = gql`
  subscription subscribeToContentObjects(
    $distinct_on: [content_select_column!]
    $where: content_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [content_order_by!]
  ) {
    content(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...Content
    }
  }
  ${ContentFragmentDoc}
`;

/**
 * __useSubscribeToContentObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToContentObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToContentObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToContentObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToContentObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToContentObjectsSubscription,
    SubscribeToContentObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SubscribeToContentObjectsSubscription, SubscribeToContentObjectsSubscriptionVariables>(
    SubscribeToContentObjectsDocument,
    options
  );
}
export type SubscribeToContentObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToContentObjectsSubscription
>;
export type SubscribeToContentObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToContentObjectsSubscription>;
export const InsertContentDocument = gql`
  mutation insertContent($objects: [content_insert_input!]!) {
    insert_content(objects: $objects) {
      affected_rows
      returning {
        ...Content
      }
    }
  }
  ${ContentFragmentDoc}
`;
export type InsertContentMutationFn = Apollo.MutationFunction<InsertContentMutation, InsertContentMutationVariables>;

/**
 * __useInsertContentMutation__
 *
 * To run a mutation, you first call `useInsertContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContentMutation, { data, loading, error }] = useInsertContentMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertContentMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertContentMutation, InsertContentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertContentMutation, InsertContentMutationVariables>(InsertContentDocument, options);
}
export type InsertContentMutationHookResult = ReturnType<typeof useInsertContentMutation>;
export type InsertContentMutationResult = Apollo.MutationResult<InsertContentMutation>;
export type InsertContentMutationOptions = Apollo.BaseMutationOptions<
  InsertContentMutation,
  InsertContentMutationVariables
>;
export const InsertContentWithOnConflictDocument = gql`
  mutation insertContentWithOnConflict($objects: [content_insert_input!]!, $onConflict: content_on_conflict) {
    insert_content(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...Content
      }
    }
  }
  ${ContentFragmentDoc}
`;
export type InsertContentWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertContentWithOnConflictMutation,
  InsertContentWithOnConflictMutationVariables
>;

/**
 * __useInsertContentWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertContentWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContentWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContentWithOnConflictMutation, { data, loading, error }] = useInsertContentWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertContentWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertContentWithOnConflictMutation,
    InsertContentWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertContentWithOnConflictMutation, InsertContentWithOnConflictMutationVariables>(
    InsertContentWithOnConflictDocument,
    options
  );
}
export type InsertContentWithOnConflictMutationHookResult = ReturnType<typeof useInsertContentWithOnConflictMutation>;
export type InsertContentWithOnConflictMutationResult = Apollo.MutationResult<InsertContentWithOnConflictMutation>;
export type InsertContentWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertContentWithOnConflictMutation,
  InsertContentWithOnConflictMutationVariables
>;
export const UpdateContentByIdDocument = gql`
  mutation updateContentById($id: uuid, $set: content_set_input) {
    update_content(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...Content
      }
    }
  }
  ${ContentFragmentDoc}
`;
export type UpdateContentByIdMutationFn = Apollo.MutationFunction<
  UpdateContentByIdMutation,
  UpdateContentByIdMutationVariables
>;

/**
 * __useUpdateContentByIdMutation__
 *
 * To run a mutation, you first call `useUpdateContentByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentByIdMutation, { data, loading, error }] = useUpdateContentByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateContentByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContentByIdMutation, UpdateContentByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContentByIdMutation, UpdateContentByIdMutationVariables>(
    UpdateContentByIdDocument,
    options
  );
}
export type UpdateContentByIdMutationHookResult = ReturnType<typeof useUpdateContentByIdMutation>;
export type UpdateContentByIdMutationResult = Apollo.MutationResult<UpdateContentByIdMutation>;
export type UpdateContentByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateContentByIdMutation,
  UpdateContentByIdMutationVariables
>;
export const UpdateContentDocument = gql`
  mutation updateContent($set: content_set_input, $where: content_bool_exp!) {
    update_content(_set: $set, where: $where) {
      affected_rows
      returning {
        ...Content
      }
    }
  }
  ${ContentFragmentDoc}
`;
export type UpdateContentMutationFn = Apollo.MutationFunction<UpdateContentMutation, UpdateContentMutationVariables>;

/**
 * __useUpdateContentMutation__
 *
 * To run a mutation, you first call `useUpdateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentMutation, { data, loading, error }] = useUpdateContentMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateContentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContentMutation, UpdateContentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContentMutation, UpdateContentMutationVariables>(UpdateContentDocument, options);
}
export type UpdateContentMutationHookResult = ReturnType<typeof useUpdateContentMutation>;
export type UpdateContentMutationResult = Apollo.MutationResult<UpdateContentMutation>;
export type UpdateContentMutationOptions = Apollo.BaseMutationOptions<
  UpdateContentMutation,
  UpdateContentMutationVariables
>;
export const RemoveContentModelByIdDocument = gql`
  mutation removeContentModelById($id: uuid) {
    delete_content(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveContentModelByIdMutationFn = Apollo.MutationFunction<
  RemoveContentModelByIdMutation,
  RemoveContentModelByIdMutationVariables
>;

/**
 * __useRemoveContentModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveContentModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContentModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContentModelByIdMutation, { data, loading, error }] = useRemoveContentModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContentModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveContentModelByIdMutation, RemoveContentModelByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContentModelByIdMutation, RemoveContentModelByIdMutationVariables>(
    RemoveContentModelByIdDocument,
    options
  );
}
export type RemoveContentModelByIdMutationHookResult = ReturnType<typeof useRemoveContentModelByIdMutation>;
export type RemoveContentModelByIdMutationResult = Apollo.MutationResult<RemoveContentModelByIdMutation>;
export type RemoveContentModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveContentModelByIdMutation,
  RemoveContentModelByIdMutationVariables
>;
export const RemoveContentModelDocument = gql`
  mutation removeContentModel($where: content_bool_exp!) {
    delete_content(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveContentModelMutationFn = Apollo.MutationFunction<
  RemoveContentModelMutation,
  RemoveContentModelMutationVariables
>;

/**
 * __useRemoveContentModelMutation__
 *
 * To run a mutation, you first call `useRemoveContentModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContentModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContentModelMutation, { data, loading, error }] = useRemoveContentModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveContentModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveContentModelMutation, RemoveContentModelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContentModelMutation, RemoveContentModelMutationVariables>(
    RemoveContentModelDocument,
    options
  );
}
export type RemoveContentModelMutationHookResult = ReturnType<typeof useRemoveContentModelMutation>;
export type RemoveContentModelMutationResult = Apollo.MutationResult<RemoveContentModelMutation>;
export type RemoveContentModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveContentModelMutation,
  RemoveContentModelMutationVariables
>;
export const QueryZoomSettingsByIdDocument = gql`
  query queryZoomSettingsById($zoomSettingsId: Int!) {
    zoomSettings_by_pk(id: $zoomSettingsId) {
      ...ZoomSettings
    }
  }
  ${ZoomSettingsFragmentDoc}
`;

/**
 * __useQueryZoomSettingsByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomSettingsByIdQuery({
 *   variables: {
 *      zoomSettingsId: // value for 'zoomSettingsId'
 *   },
 * });
 */
export function useQueryZoomSettingsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomSettingsByIdQuery, QueryZoomSettingsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomSettingsByIdQuery, QueryZoomSettingsByIdQueryVariables>(
    QueryZoomSettingsByIdDocument,
    options
  );
}
export function useQueryZoomSettingsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomSettingsByIdQuery, QueryZoomSettingsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomSettingsByIdQuery, QueryZoomSettingsByIdQueryVariables>(
    QueryZoomSettingsByIdDocument,
    options
  );
}
export type QueryZoomSettingsByIdQueryHookResult = ReturnType<typeof useQueryZoomSettingsByIdQuery>;
export type QueryZoomSettingsByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomSettingsByIdLazyQuery>;
export type QueryZoomSettingsByIdQueryResult = Apollo.QueryResult<
  QueryZoomSettingsByIdQuery,
  QueryZoomSettingsByIdQueryVariables
>;
export const QueryZoomSettingsObjectsDocument = gql`
  query queryZoomSettingsObjects(
    $distinct_on: [zoomSettings_select_column!]
    $where: zoomSettings_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomSettings_order_by!]
  ) {
    zoomSettings(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomSettings
    }
  }
  ${ZoomSettingsFragmentDoc}
`;

/**
 * __useQueryZoomSettingsObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomSettingsObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomSettingsObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomSettingsObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomSettingsObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomSettingsObjectsQuery, QueryZoomSettingsObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomSettingsObjectsQuery, QueryZoomSettingsObjectsQueryVariables>(
    QueryZoomSettingsObjectsDocument,
    options
  );
}
export function useQueryZoomSettingsObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomSettingsObjectsQuery, QueryZoomSettingsObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomSettingsObjectsQuery, QueryZoomSettingsObjectsQueryVariables>(
    QueryZoomSettingsObjectsDocument,
    options
  );
}
export type QueryZoomSettingsObjectsQueryHookResult = ReturnType<typeof useQueryZoomSettingsObjectsQuery>;
export type QueryZoomSettingsObjectsLazyQueryHookResult = ReturnType<typeof useQueryZoomSettingsObjectsLazyQuery>;
export type QueryZoomSettingsObjectsQueryResult = Apollo.QueryResult<
  QueryZoomSettingsObjectsQuery,
  QueryZoomSettingsObjectsQueryVariables
>;
export const SubscribeToZoomSettingsByIdDocument = gql`
  subscription subscribeToZoomSettingsById($zoomSettingsId: Int!) {
    zoomSettings_by_pk(id: $zoomSettingsId) {
      ...ZoomSettings
    }
  }
  ${ZoomSettingsFragmentDoc}
`;

/**
 * __useSubscribeToZoomSettingsByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomSettingsByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomSettingsByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomSettingsByIdSubscription({
 *   variables: {
 *      zoomSettingsId: // value for 'zoomSettingsId'
 *   },
 * });
 */
export function useSubscribeToZoomSettingsByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomSettingsByIdSubscription,
    SubscribeToZoomSettingsByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomSettingsByIdSubscription,
    SubscribeToZoomSettingsByIdSubscriptionVariables
  >(SubscribeToZoomSettingsByIdDocument, options);
}
export type SubscribeToZoomSettingsByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomSettingsByIdSubscription
>;
export type SubscribeToZoomSettingsByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomSettingsByIdSubscription>;
export const SubscribeToZoomSettingsObjectsDocument = gql`
  subscription subscribeToZoomSettingsObjects(
    $distinct_on: [zoomSettings_select_column!]
    $where: zoomSettings_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomSettings_order_by!]
  ) {
    zoomSettings(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomSettings
    }
  }
  ${ZoomSettingsFragmentDoc}
`;

/**
 * __useSubscribeToZoomSettingsObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomSettingsObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomSettingsObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomSettingsObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomSettingsObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomSettingsObjectsSubscription,
    SubscribeToZoomSettingsObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomSettingsObjectsSubscription,
    SubscribeToZoomSettingsObjectsSubscriptionVariables
  >(SubscribeToZoomSettingsObjectsDocument, options);
}
export type SubscribeToZoomSettingsObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomSettingsObjectsSubscription
>;
export type SubscribeToZoomSettingsObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomSettingsObjectsSubscription>;
export const InsertZoomSettingsDocument = gql`
  mutation insertZoomSettings($objects: [zoomSettings_insert_input!]!) {
    insert_zoomSettings(objects: $objects) {
      affected_rows
      returning {
        ...ZoomSettings
      }
    }
  }
  ${ZoomSettingsFragmentDoc}
`;
export type InsertZoomSettingsMutationFn = Apollo.MutationFunction<
  InsertZoomSettingsMutation,
  InsertZoomSettingsMutationVariables
>;

/**
 * __useInsertZoomSettingsMutation__
 *
 * To run a mutation, you first call `useInsertZoomSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomSettingsMutation, { data, loading, error }] = useInsertZoomSettingsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomSettingsMutation, InsertZoomSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomSettingsMutation, InsertZoomSettingsMutationVariables>(
    InsertZoomSettingsDocument,
    options
  );
}
export type InsertZoomSettingsMutationHookResult = ReturnType<typeof useInsertZoomSettingsMutation>;
export type InsertZoomSettingsMutationResult = Apollo.MutationResult<InsertZoomSettingsMutation>;
export type InsertZoomSettingsMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomSettingsMutation,
  InsertZoomSettingsMutationVariables
>;
export const InsertZoomSettingsWithOnConflictDocument = gql`
  mutation insertZoomSettingsWithOnConflict(
    $objects: [zoomSettings_insert_input!]!
    $onConflict: zoomSettings_on_conflict
  ) {
    insert_zoomSettings(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomSettings
      }
    }
  }
  ${ZoomSettingsFragmentDoc}
`;
export type InsertZoomSettingsWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomSettingsWithOnConflictMutation,
  InsertZoomSettingsWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomSettingsWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomSettingsWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomSettingsWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomSettingsWithOnConflictMutation, { data, loading, error }] = useInsertZoomSettingsWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomSettingsWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomSettingsWithOnConflictMutation,
    InsertZoomSettingsWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomSettingsWithOnConflictMutation,
    InsertZoomSettingsWithOnConflictMutationVariables
  >(InsertZoomSettingsWithOnConflictDocument, options);
}
export type InsertZoomSettingsWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomSettingsWithOnConflictMutation
>;
export type InsertZoomSettingsWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomSettingsWithOnConflictMutation>;
export type InsertZoomSettingsWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomSettingsWithOnConflictMutation,
  InsertZoomSettingsWithOnConflictMutationVariables
>;
export const UpdateZoomSettingsByIdDocument = gql`
  mutation updateZoomSettingsById($id: Int, $set: zoomSettings_set_input) {
    update_zoomSettings(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomSettings
      }
    }
  }
  ${ZoomSettingsFragmentDoc}
`;
export type UpdateZoomSettingsByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomSettingsByIdMutation,
  UpdateZoomSettingsByIdMutationVariables
>;

/**
 * __useUpdateZoomSettingsByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomSettingsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomSettingsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomSettingsByIdMutation, { data, loading, error }] = useUpdateZoomSettingsByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomSettingsByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomSettingsByIdMutation, UpdateZoomSettingsByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomSettingsByIdMutation, UpdateZoomSettingsByIdMutationVariables>(
    UpdateZoomSettingsByIdDocument,
    options
  );
}
export type UpdateZoomSettingsByIdMutationHookResult = ReturnType<typeof useUpdateZoomSettingsByIdMutation>;
export type UpdateZoomSettingsByIdMutationResult = Apollo.MutationResult<UpdateZoomSettingsByIdMutation>;
export type UpdateZoomSettingsByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomSettingsByIdMutation,
  UpdateZoomSettingsByIdMutationVariables
>;
export const UpdateZoomSettingsDocument = gql`
  mutation updateZoomSettings($set: zoomSettings_set_input, $where: zoomSettings_bool_exp!) {
    update_zoomSettings(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomSettings
      }
    }
  }
  ${ZoomSettingsFragmentDoc}
`;
export type UpdateZoomSettingsMutationFn = Apollo.MutationFunction<
  UpdateZoomSettingsMutation,
  UpdateZoomSettingsMutationVariables
>;

/**
 * __useUpdateZoomSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateZoomSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomSettingsMutation, { data, loading, error }] = useUpdateZoomSettingsMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomSettingsMutation, UpdateZoomSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomSettingsMutation, UpdateZoomSettingsMutationVariables>(
    UpdateZoomSettingsDocument,
    options
  );
}
export type UpdateZoomSettingsMutationHookResult = ReturnType<typeof useUpdateZoomSettingsMutation>;
export type UpdateZoomSettingsMutationResult = Apollo.MutationResult<UpdateZoomSettingsMutation>;
export type UpdateZoomSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomSettingsMutation,
  UpdateZoomSettingsMutationVariables
>;
export const RemoveZoomSettingsModelByIdDocument = gql`
  mutation removeZoomSettingsModelById($id: Int) {
    delete_zoomSettings(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomSettingsModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomSettingsModelByIdMutation,
  RemoveZoomSettingsModelByIdMutationVariables
>;

/**
 * __useRemoveZoomSettingsModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomSettingsModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomSettingsModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomSettingsModelByIdMutation, { data, loading, error }] = useRemoveZoomSettingsModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomSettingsModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomSettingsModelByIdMutation,
    RemoveZoomSettingsModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomSettingsModelByIdMutation, RemoveZoomSettingsModelByIdMutationVariables>(
    RemoveZoomSettingsModelByIdDocument,
    options
  );
}
export type RemoveZoomSettingsModelByIdMutationHookResult = ReturnType<typeof useRemoveZoomSettingsModelByIdMutation>;
export type RemoveZoomSettingsModelByIdMutationResult = Apollo.MutationResult<RemoveZoomSettingsModelByIdMutation>;
export type RemoveZoomSettingsModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomSettingsModelByIdMutation,
  RemoveZoomSettingsModelByIdMutationVariables
>;
export const RemoveZoomSettingsModelDocument = gql`
  mutation removeZoomSettingsModel($where: zoomSettings_bool_exp!) {
    delete_zoomSettings(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomSettingsModelMutationFn = Apollo.MutationFunction<
  RemoveZoomSettingsModelMutation,
  RemoveZoomSettingsModelMutationVariables
>;

/**
 * __useRemoveZoomSettingsModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomSettingsModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomSettingsModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomSettingsModelMutation, { data, loading, error }] = useRemoveZoomSettingsModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomSettingsModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveZoomSettingsModelMutation, RemoveZoomSettingsModelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomSettingsModelMutation, RemoveZoomSettingsModelMutationVariables>(
    RemoveZoomSettingsModelDocument,
    options
  );
}
export type RemoveZoomSettingsModelMutationHookResult = ReturnType<typeof useRemoveZoomSettingsModelMutation>;
export type RemoveZoomSettingsModelMutationResult = Apollo.MutationResult<RemoveZoomSettingsModelMutation>;
export type RemoveZoomSettingsModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomSettingsModelMutation,
  RemoveZoomSettingsModelMutationVariables
>;
export const QueryZoomPresentationByIdDocument = gql`
  query queryZoomPresentationById($zoomPresentationId: uuid!) {
    zoomPresentation_by_pk(id: $zoomPresentationId) {
      ...ZoomPresentation
    }
  }
  ${ZoomPresentationFragmentDoc}
`;

/**
 * __useQueryZoomPresentationByIdQuery__
 *
 * To run a query within a React component, call `useQueryZoomPresentationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomPresentationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomPresentationByIdQuery({
 *   variables: {
 *      zoomPresentationId: // value for 'zoomPresentationId'
 *   },
 * });
 */
export function useQueryZoomPresentationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomPresentationByIdQuery, QueryZoomPresentationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomPresentationByIdQuery, QueryZoomPresentationByIdQueryVariables>(
    QueryZoomPresentationByIdDocument,
    options
  );
}
export function useQueryZoomPresentationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomPresentationByIdQuery, QueryZoomPresentationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomPresentationByIdQuery, QueryZoomPresentationByIdQueryVariables>(
    QueryZoomPresentationByIdDocument,
    options
  );
}
export type QueryZoomPresentationByIdQueryHookResult = ReturnType<typeof useQueryZoomPresentationByIdQuery>;
export type QueryZoomPresentationByIdLazyQueryHookResult = ReturnType<typeof useQueryZoomPresentationByIdLazyQuery>;
export type QueryZoomPresentationByIdQueryResult = Apollo.QueryResult<
  QueryZoomPresentationByIdQuery,
  QueryZoomPresentationByIdQueryVariables
>;
export const QueryZoomPresentationObjectsDocument = gql`
  query queryZoomPresentationObjects(
    $distinct_on: [zoomPresentation_select_column!]
    $where: zoomPresentation_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomPresentation_order_by!]
  ) {
    zoomPresentation(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomPresentation
    }
  }
  ${ZoomPresentationFragmentDoc}
`;

/**
 * __useQueryZoomPresentationObjectsQuery__
 *
 * To run a query within a React component, call `useQueryZoomPresentationObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomPresentationObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomPresentationObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomPresentationObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryZoomPresentationObjectsQuery, QueryZoomPresentationObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomPresentationObjectsQuery, QueryZoomPresentationObjectsQueryVariables>(
    QueryZoomPresentationObjectsDocument,
    options
  );
}
export function useQueryZoomPresentationObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomPresentationObjectsQuery,
    QueryZoomPresentationObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomPresentationObjectsQuery, QueryZoomPresentationObjectsQueryVariables>(
    QueryZoomPresentationObjectsDocument,
    options
  );
}
export type QueryZoomPresentationObjectsQueryHookResult = ReturnType<typeof useQueryZoomPresentationObjectsQuery>;
export type QueryZoomPresentationObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomPresentationObjectsLazyQuery
>;
export type QueryZoomPresentationObjectsQueryResult = Apollo.QueryResult<
  QueryZoomPresentationObjectsQuery,
  QueryZoomPresentationObjectsQueryVariables
>;
export const SubscribeToZoomPresentationByIdDocument = gql`
  subscription subscribeToZoomPresentationById($zoomPresentationId: uuid!) {
    zoomPresentation_by_pk(id: $zoomPresentationId) {
      ...ZoomPresentation
    }
  }
  ${ZoomPresentationFragmentDoc}
`;

/**
 * __useSubscribeToZoomPresentationByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomPresentationByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomPresentationByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomPresentationByIdSubscription({
 *   variables: {
 *      zoomPresentationId: // value for 'zoomPresentationId'
 *   },
 * });
 */
export function useSubscribeToZoomPresentationByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomPresentationByIdSubscription,
    SubscribeToZoomPresentationByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomPresentationByIdSubscription,
    SubscribeToZoomPresentationByIdSubscriptionVariables
  >(SubscribeToZoomPresentationByIdDocument, options);
}
export type SubscribeToZoomPresentationByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomPresentationByIdSubscription
>;
export type SubscribeToZoomPresentationByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomPresentationByIdSubscription>;
export const SubscribeToZoomPresentationObjectsDocument = gql`
  subscription subscribeToZoomPresentationObjects(
    $distinct_on: [zoomPresentation_select_column!]
    $where: zoomPresentation_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomPresentation_order_by!]
  ) {
    zoomPresentation(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomPresentation
    }
  }
  ${ZoomPresentationFragmentDoc}
`;

/**
 * __useSubscribeToZoomPresentationObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomPresentationObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomPresentationObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomPresentationObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomPresentationObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToZoomPresentationObjectsSubscription,
    SubscribeToZoomPresentationObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomPresentationObjectsSubscription,
    SubscribeToZoomPresentationObjectsSubscriptionVariables
  >(SubscribeToZoomPresentationObjectsDocument, options);
}
export type SubscribeToZoomPresentationObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomPresentationObjectsSubscription
>;
export type SubscribeToZoomPresentationObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomPresentationObjectsSubscription>;
export const InsertZoomPresentationDocument = gql`
  mutation insertZoomPresentation($objects: [zoomPresentation_insert_input!]!) {
    insert_zoomPresentation(objects: $objects) {
      affected_rows
      returning {
        ...ZoomPresentation
      }
    }
  }
  ${ZoomPresentationFragmentDoc}
`;
export type InsertZoomPresentationMutationFn = Apollo.MutationFunction<
  InsertZoomPresentationMutation,
  InsertZoomPresentationMutationVariables
>;

/**
 * __useInsertZoomPresentationMutation__
 *
 * To run a mutation, you first call `useInsertZoomPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomPresentationMutation, { data, loading, error }] = useInsertZoomPresentationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertZoomPresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertZoomPresentationMutation, InsertZoomPresentationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertZoomPresentationMutation, InsertZoomPresentationMutationVariables>(
    InsertZoomPresentationDocument,
    options
  );
}
export type InsertZoomPresentationMutationHookResult = ReturnType<typeof useInsertZoomPresentationMutation>;
export type InsertZoomPresentationMutationResult = Apollo.MutationResult<InsertZoomPresentationMutation>;
export type InsertZoomPresentationMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomPresentationMutation,
  InsertZoomPresentationMutationVariables
>;
export const InsertZoomPresentationWithOnConflictDocument = gql`
  mutation insertZoomPresentationWithOnConflict(
    $objects: [zoomPresentation_insert_input!]!
    $onConflict: zoomPresentation_on_conflict
  ) {
    insert_zoomPresentation(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ZoomPresentation
      }
    }
  }
  ${ZoomPresentationFragmentDoc}
`;
export type InsertZoomPresentationWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertZoomPresentationWithOnConflictMutation,
  InsertZoomPresentationWithOnConflictMutationVariables
>;

/**
 * __useInsertZoomPresentationWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertZoomPresentationWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertZoomPresentationWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertZoomPresentationWithOnConflictMutation, { data, loading, error }] = useInsertZoomPresentationWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertZoomPresentationWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertZoomPresentationWithOnConflictMutation,
    InsertZoomPresentationWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertZoomPresentationWithOnConflictMutation,
    InsertZoomPresentationWithOnConflictMutationVariables
  >(InsertZoomPresentationWithOnConflictDocument, options);
}
export type InsertZoomPresentationWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertZoomPresentationWithOnConflictMutation
>;
export type InsertZoomPresentationWithOnConflictMutationResult =
  Apollo.MutationResult<InsertZoomPresentationWithOnConflictMutation>;
export type InsertZoomPresentationWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertZoomPresentationWithOnConflictMutation,
  InsertZoomPresentationWithOnConflictMutationVariables
>;
export const UpdateZoomPresentationByIdDocument = gql`
  mutation updateZoomPresentationById($id: uuid, $set: zoomPresentation_set_input) {
    update_zoomPresentation(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ZoomPresentation
      }
    }
  }
  ${ZoomPresentationFragmentDoc}
`;
export type UpdateZoomPresentationByIdMutationFn = Apollo.MutationFunction<
  UpdateZoomPresentationByIdMutation,
  UpdateZoomPresentationByIdMutationVariables
>;

/**
 * __useUpdateZoomPresentationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateZoomPresentationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomPresentationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomPresentationByIdMutation, { data, loading, error }] = useUpdateZoomPresentationByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateZoomPresentationByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomPresentationByIdMutation,
    UpdateZoomPresentationByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomPresentationByIdMutation, UpdateZoomPresentationByIdMutationVariables>(
    UpdateZoomPresentationByIdDocument,
    options
  );
}
export type UpdateZoomPresentationByIdMutationHookResult = ReturnType<typeof useUpdateZoomPresentationByIdMutation>;
export type UpdateZoomPresentationByIdMutationResult = Apollo.MutationResult<UpdateZoomPresentationByIdMutation>;
export type UpdateZoomPresentationByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomPresentationByIdMutation,
  UpdateZoomPresentationByIdMutationVariables
>;
export const UpdateZoomPresentationDocument = gql`
  mutation updateZoomPresentation($set: zoomPresentation_set_input, $where: zoomPresentation_bool_exp!) {
    update_zoomPresentation(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ZoomPresentation
      }
    }
  }
  ${ZoomPresentationFragmentDoc}
`;
export type UpdateZoomPresentationMutationFn = Apollo.MutationFunction<
  UpdateZoomPresentationMutation,
  UpdateZoomPresentationMutationVariables
>;

/**
 * __useUpdateZoomPresentationMutation__
 *
 * To run a mutation, you first call `useUpdateZoomPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomPresentationMutation, { data, loading, error }] = useUpdateZoomPresentationMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateZoomPresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateZoomPresentationMutation, UpdateZoomPresentationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateZoomPresentationMutation, UpdateZoomPresentationMutationVariables>(
    UpdateZoomPresentationDocument,
    options
  );
}
export type UpdateZoomPresentationMutationHookResult = ReturnType<typeof useUpdateZoomPresentationMutation>;
export type UpdateZoomPresentationMutationResult = Apollo.MutationResult<UpdateZoomPresentationMutation>;
export type UpdateZoomPresentationMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomPresentationMutation,
  UpdateZoomPresentationMutationVariables
>;
export const RemoveZoomPresentationModelByIdDocument = gql`
  mutation removeZoomPresentationModelById($id: uuid) {
    delete_zoomPresentation(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomPresentationModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomPresentationModelByIdMutation,
  RemoveZoomPresentationModelByIdMutationVariables
>;

/**
 * __useRemoveZoomPresentationModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomPresentationModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomPresentationModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomPresentationModelByIdMutation, { data, loading, error }] = useRemoveZoomPresentationModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomPresentationModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomPresentationModelByIdMutation,
    RemoveZoomPresentationModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomPresentationModelByIdMutation, RemoveZoomPresentationModelByIdMutationVariables>(
    RemoveZoomPresentationModelByIdDocument,
    options
  );
}
export type RemoveZoomPresentationModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomPresentationModelByIdMutation
>;
export type RemoveZoomPresentationModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomPresentationModelByIdMutation>;
export type RemoveZoomPresentationModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomPresentationModelByIdMutation,
  RemoveZoomPresentationModelByIdMutationVariables
>;
export const RemoveZoomPresentationModelDocument = gql`
  mutation removeZoomPresentationModel($where: zoomPresentation_bool_exp!) {
    delete_zoomPresentation(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomPresentationModelMutationFn = Apollo.MutationFunction<
  RemoveZoomPresentationModelMutation,
  RemoveZoomPresentationModelMutationVariables
>;

/**
 * __useRemoveZoomPresentationModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomPresentationModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomPresentationModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomPresentationModelMutation, { data, loading, error }] = useRemoveZoomPresentationModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomPresentationModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomPresentationModelMutation,
    RemoveZoomPresentationModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomPresentationModelMutation, RemoveZoomPresentationModelMutationVariables>(
    RemoveZoomPresentationModelDocument,
    options
  );
}
export type RemoveZoomPresentationModelMutationHookResult = ReturnType<typeof useRemoveZoomPresentationModelMutation>;
export type RemoveZoomPresentationModelMutationResult = Apollo.MutationResult<RemoveZoomPresentationModelMutation>;
export type RemoveZoomPresentationModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomPresentationModelMutation,
  RemoveZoomPresentationModelMutationVariables
>;
export const QueryGrowthUserReferralContactByIdDocument = gql`
  query queryGrowthUserReferralContactById($growthUserReferralContactId: uuid!) {
    growthUserReferralContact_by_pk(id: $growthUserReferralContactId) {
      ...GrowthUserReferralContact
    }
  }
  ${GrowthUserReferralContactFragmentDoc}
`;

/**
 * __useQueryGrowthUserReferralContactByIdQuery__
 *
 * To run a query within a React component, call `useQueryGrowthUserReferralContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGrowthUserReferralContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGrowthUserReferralContactByIdQuery({
 *   variables: {
 *      growthUserReferralContactId: // value for 'growthUserReferralContactId'
 *   },
 * });
 */
export function useQueryGrowthUserReferralContactByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryGrowthUserReferralContactByIdQuery,
    QueryGrowthUserReferralContactByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGrowthUserReferralContactByIdQuery, QueryGrowthUserReferralContactByIdQueryVariables>(
    QueryGrowthUserReferralContactByIdDocument,
    options
  );
}
export function useQueryGrowthUserReferralContactByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryGrowthUserReferralContactByIdQuery,
    QueryGrowthUserReferralContactByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGrowthUserReferralContactByIdQuery, QueryGrowthUserReferralContactByIdQueryVariables>(
    QueryGrowthUserReferralContactByIdDocument,
    options
  );
}
export type QueryGrowthUserReferralContactByIdQueryHookResult = ReturnType<
  typeof useQueryGrowthUserReferralContactByIdQuery
>;
export type QueryGrowthUserReferralContactByIdLazyQueryHookResult = ReturnType<
  typeof useQueryGrowthUserReferralContactByIdLazyQuery
>;
export type QueryGrowthUserReferralContactByIdQueryResult = Apollo.QueryResult<
  QueryGrowthUserReferralContactByIdQuery,
  QueryGrowthUserReferralContactByIdQueryVariables
>;
export const QueryGrowthUserReferralContactObjectsDocument = gql`
  query queryGrowthUserReferralContactObjects(
    $distinct_on: [growthUserReferralContact_select_column!]
    $where: growthUserReferralContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [growthUserReferralContact_order_by!]
  ) {
    growthUserReferralContact(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...GrowthUserReferralContact
    }
  }
  ${GrowthUserReferralContactFragmentDoc}
`;

/**
 * __useQueryGrowthUserReferralContactObjectsQuery__
 *
 * To run a query within a React component, call `useQueryGrowthUserReferralContactObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGrowthUserReferralContactObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGrowthUserReferralContactObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryGrowthUserReferralContactObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryGrowthUserReferralContactObjectsQuery,
    QueryGrowthUserReferralContactObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryGrowthUserReferralContactObjectsQuery,
    QueryGrowthUserReferralContactObjectsQueryVariables
  >(QueryGrowthUserReferralContactObjectsDocument, options);
}
export function useQueryGrowthUserReferralContactObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryGrowthUserReferralContactObjectsQuery,
    QueryGrowthUserReferralContactObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryGrowthUserReferralContactObjectsQuery,
    QueryGrowthUserReferralContactObjectsQueryVariables
  >(QueryGrowthUserReferralContactObjectsDocument, options);
}
export type QueryGrowthUserReferralContactObjectsQueryHookResult = ReturnType<
  typeof useQueryGrowthUserReferralContactObjectsQuery
>;
export type QueryGrowthUserReferralContactObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryGrowthUserReferralContactObjectsLazyQuery
>;
export type QueryGrowthUserReferralContactObjectsQueryResult = Apollo.QueryResult<
  QueryGrowthUserReferralContactObjectsQuery,
  QueryGrowthUserReferralContactObjectsQueryVariables
>;
export const SubscribeToGrowthUserReferralContactByIdDocument = gql`
  subscription subscribeToGrowthUserReferralContactById($growthUserReferralContactId: uuid!) {
    growthUserReferralContact_by_pk(id: $growthUserReferralContactId) {
      ...GrowthUserReferralContact
    }
  }
  ${GrowthUserReferralContactFragmentDoc}
`;

/**
 * __useSubscribeToGrowthUserReferralContactByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToGrowthUserReferralContactByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToGrowthUserReferralContactByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToGrowthUserReferralContactByIdSubscription({
 *   variables: {
 *      growthUserReferralContactId: // value for 'growthUserReferralContactId'
 *   },
 * });
 */
export function useSubscribeToGrowthUserReferralContactByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToGrowthUserReferralContactByIdSubscription,
    SubscribeToGrowthUserReferralContactByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToGrowthUserReferralContactByIdSubscription,
    SubscribeToGrowthUserReferralContactByIdSubscriptionVariables
  >(SubscribeToGrowthUserReferralContactByIdDocument, options);
}
export type SubscribeToGrowthUserReferralContactByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToGrowthUserReferralContactByIdSubscription
>;
export type SubscribeToGrowthUserReferralContactByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToGrowthUserReferralContactByIdSubscription>;
export const SubscribeToGrowthUserReferralContactObjectsDocument = gql`
  subscription subscribeToGrowthUserReferralContactObjects(
    $distinct_on: [growthUserReferralContact_select_column!]
    $where: growthUserReferralContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [growthUserReferralContact_order_by!]
  ) {
    growthUserReferralContact(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...GrowthUserReferralContact
    }
  }
  ${GrowthUserReferralContactFragmentDoc}
`;

/**
 * __useSubscribeToGrowthUserReferralContactObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToGrowthUserReferralContactObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToGrowthUserReferralContactObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToGrowthUserReferralContactObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToGrowthUserReferralContactObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToGrowthUserReferralContactObjectsSubscription,
    SubscribeToGrowthUserReferralContactObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToGrowthUserReferralContactObjectsSubscription,
    SubscribeToGrowthUserReferralContactObjectsSubscriptionVariables
  >(SubscribeToGrowthUserReferralContactObjectsDocument, options);
}
export type SubscribeToGrowthUserReferralContactObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToGrowthUserReferralContactObjectsSubscription
>;
export type SubscribeToGrowthUserReferralContactObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToGrowthUserReferralContactObjectsSubscription>;
export const InsertGrowthUserReferralContactDocument = gql`
  mutation insertGrowthUserReferralContact($objects: [growthUserReferralContact_insert_input!]!) {
    insert_growthUserReferralContact(objects: $objects) {
      affected_rows
      returning {
        ...GrowthUserReferralContact
      }
    }
  }
  ${GrowthUserReferralContactFragmentDoc}
`;
export type InsertGrowthUserReferralContactMutationFn = Apollo.MutationFunction<
  InsertGrowthUserReferralContactMutation,
  InsertGrowthUserReferralContactMutationVariables
>;

/**
 * __useInsertGrowthUserReferralContactMutation__
 *
 * To run a mutation, you first call `useInsertGrowthUserReferralContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGrowthUserReferralContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGrowthUserReferralContactMutation, { data, loading, error }] = useInsertGrowthUserReferralContactMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertGrowthUserReferralContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertGrowthUserReferralContactMutation,
    InsertGrowthUserReferralContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertGrowthUserReferralContactMutation, InsertGrowthUserReferralContactMutationVariables>(
    InsertGrowthUserReferralContactDocument,
    options
  );
}
export type InsertGrowthUserReferralContactMutationHookResult = ReturnType<
  typeof useInsertGrowthUserReferralContactMutation
>;
export type InsertGrowthUserReferralContactMutationResult =
  Apollo.MutationResult<InsertGrowthUserReferralContactMutation>;
export type InsertGrowthUserReferralContactMutationOptions = Apollo.BaseMutationOptions<
  InsertGrowthUserReferralContactMutation,
  InsertGrowthUserReferralContactMutationVariables
>;
export const InsertGrowthUserReferralContactWithOnConflictDocument = gql`
  mutation insertGrowthUserReferralContactWithOnConflict(
    $objects: [growthUserReferralContact_insert_input!]!
    $onConflict: growthUserReferralContact_on_conflict
  ) {
    insert_growthUserReferralContact(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...GrowthUserReferralContact
      }
    }
  }
  ${GrowthUserReferralContactFragmentDoc}
`;
export type InsertGrowthUserReferralContactWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertGrowthUserReferralContactWithOnConflictMutation,
  InsertGrowthUserReferralContactWithOnConflictMutationVariables
>;

/**
 * __useInsertGrowthUserReferralContactWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertGrowthUserReferralContactWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGrowthUserReferralContactWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGrowthUserReferralContactWithOnConflictMutation, { data, loading, error }] = useInsertGrowthUserReferralContactWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertGrowthUserReferralContactWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertGrowthUserReferralContactWithOnConflictMutation,
    InsertGrowthUserReferralContactWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertGrowthUserReferralContactWithOnConflictMutation,
    InsertGrowthUserReferralContactWithOnConflictMutationVariables
  >(InsertGrowthUserReferralContactWithOnConflictDocument, options);
}
export type InsertGrowthUserReferralContactWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertGrowthUserReferralContactWithOnConflictMutation
>;
export type InsertGrowthUserReferralContactWithOnConflictMutationResult =
  Apollo.MutationResult<InsertGrowthUserReferralContactWithOnConflictMutation>;
export type InsertGrowthUserReferralContactWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertGrowthUserReferralContactWithOnConflictMutation,
  InsertGrowthUserReferralContactWithOnConflictMutationVariables
>;
export const UpdateGrowthUserReferralContactByIdDocument = gql`
  mutation updateGrowthUserReferralContactById($id: uuid, $set: growthUserReferralContact_set_input) {
    update_growthUserReferralContact(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...GrowthUserReferralContact
      }
    }
  }
  ${GrowthUserReferralContactFragmentDoc}
`;
export type UpdateGrowthUserReferralContactByIdMutationFn = Apollo.MutationFunction<
  UpdateGrowthUserReferralContactByIdMutation,
  UpdateGrowthUserReferralContactByIdMutationVariables
>;

/**
 * __useUpdateGrowthUserReferralContactByIdMutation__
 *
 * To run a mutation, you first call `useUpdateGrowthUserReferralContactByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrowthUserReferralContactByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrowthUserReferralContactByIdMutation, { data, loading, error }] = useUpdateGrowthUserReferralContactByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateGrowthUserReferralContactByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGrowthUserReferralContactByIdMutation,
    UpdateGrowthUserReferralContactByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGrowthUserReferralContactByIdMutation,
    UpdateGrowthUserReferralContactByIdMutationVariables
  >(UpdateGrowthUserReferralContactByIdDocument, options);
}
export type UpdateGrowthUserReferralContactByIdMutationHookResult = ReturnType<
  typeof useUpdateGrowthUserReferralContactByIdMutation
>;
export type UpdateGrowthUserReferralContactByIdMutationResult =
  Apollo.MutationResult<UpdateGrowthUserReferralContactByIdMutation>;
export type UpdateGrowthUserReferralContactByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateGrowthUserReferralContactByIdMutation,
  UpdateGrowthUserReferralContactByIdMutationVariables
>;
export const UpdateGrowthUserReferralContactDocument = gql`
  mutation updateGrowthUserReferralContact(
    $set: growthUserReferralContact_set_input
    $where: growthUserReferralContact_bool_exp!
  ) {
    update_growthUserReferralContact(_set: $set, where: $where) {
      affected_rows
      returning {
        ...GrowthUserReferralContact
      }
    }
  }
  ${GrowthUserReferralContactFragmentDoc}
`;
export type UpdateGrowthUserReferralContactMutationFn = Apollo.MutationFunction<
  UpdateGrowthUserReferralContactMutation,
  UpdateGrowthUserReferralContactMutationVariables
>;

/**
 * __useUpdateGrowthUserReferralContactMutation__
 *
 * To run a mutation, you first call `useUpdateGrowthUserReferralContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrowthUserReferralContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrowthUserReferralContactMutation, { data, loading, error }] = useUpdateGrowthUserReferralContactMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateGrowthUserReferralContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGrowthUserReferralContactMutation,
    UpdateGrowthUserReferralContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGrowthUserReferralContactMutation, UpdateGrowthUserReferralContactMutationVariables>(
    UpdateGrowthUserReferralContactDocument,
    options
  );
}
export type UpdateGrowthUserReferralContactMutationHookResult = ReturnType<
  typeof useUpdateGrowthUserReferralContactMutation
>;
export type UpdateGrowthUserReferralContactMutationResult =
  Apollo.MutationResult<UpdateGrowthUserReferralContactMutation>;
export type UpdateGrowthUserReferralContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateGrowthUserReferralContactMutation,
  UpdateGrowthUserReferralContactMutationVariables
>;
export const RemoveGrowthUserReferralContactModelByIdDocument = gql`
  mutation removeGrowthUserReferralContactModelById($id: uuid) {
    delete_growthUserReferralContact(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveGrowthUserReferralContactModelByIdMutationFn = Apollo.MutationFunction<
  RemoveGrowthUserReferralContactModelByIdMutation,
  RemoveGrowthUserReferralContactModelByIdMutationVariables
>;

/**
 * __useRemoveGrowthUserReferralContactModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveGrowthUserReferralContactModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGrowthUserReferralContactModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGrowthUserReferralContactModelByIdMutation, { data, loading, error }] = useRemoveGrowthUserReferralContactModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveGrowthUserReferralContactModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGrowthUserReferralContactModelByIdMutation,
    RemoveGrowthUserReferralContactModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGrowthUserReferralContactModelByIdMutation,
    RemoveGrowthUserReferralContactModelByIdMutationVariables
  >(RemoveGrowthUserReferralContactModelByIdDocument, options);
}
export type RemoveGrowthUserReferralContactModelByIdMutationHookResult = ReturnType<
  typeof useRemoveGrowthUserReferralContactModelByIdMutation
>;
export type RemoveGrowthUserReferralContactModelByIdMutationResult =
  Apollo.MutationResult<RemoveGrowthUserReferralContactModelByIdMutation>;
export type RemoveGrowthUserReferralContactModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveGrowthUserReferralContactModelByIdMutation,
  RemoveGrowthUserReferralContactModelByIdMutationVariables
>;
export const RemoveGrowthUserReferralContactModelDocument = gql`
  mutation removeGrowthUserReferralContactModel($where: growthUserReferralContact_bool_exp!) {
    delete_growthUserReferralContact(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveGrowthUserReferralContactModelMutationFn = Apollo.MutationFunction<
  RemoveGrowthUserReferralContactModelMutation,
  RemoveGrowthUserReferralContactModelMutationVariables
>;

/**
 * __useRemoveGrowthUserReferralContactModelMutation__
 *
 * To run a mutation, you first call `useRemoveGrowthUserReferralContactModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGrowthUserReferralContactModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGrowthUserReferralContactModelMutation, { data, loading, error }] = useRemoveGrowthUserReferralContactModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveGrowthUserReferralContactModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGrowthUserReferralContactModelMutation,
    RemoveGrowthUserReferralContactModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGrowthUserReferralContactModelMutation,
    RemoveGrowthUserReferralContactModelMutationVariables
  >(RemoveGrowthUserReferralContactModelDocument, options);
}
export type RemoveGrowthUserReferralContactModelMutationHookResult = ReturnType<
  typeof useRemoveGrowthUserReferralContactModelMutation
>;
export type RemoveGrowthUserReferralContactModelMutationResult =
  Apollo.MutationResult<RemoveGrowthUserReferralContactModelMutation>;
export type RemoveGrowthUserReferralContactModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveGrowthUserReferralContactModelMutation,
  RemoveGrowthUserReferralContactModelMutationVariables
>;
export const QueryLayersEventsByIdDocument = gql`
  query queryLayersEventsById($zoomLayersEventsId: uuid!) {
    zoomLayersEvents_by_pk(id: $zoomLayersEventsId) {
      ...LayersEvents
    }
  }
  ${LayersEventsFragmentDoc}
`;

/**
 * __useQueryLayersEventsByIdQuery__
 *
 * To run a query within a React component, call `useQueryLayersEventsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryLayersEventsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryLayersEventsByIdQuery({
 *   variables: {
 *      zoomLayersEventsId: // value for 'zoomLayersEventsId'
 *   },
 * });
 */
export function useQueryLayersEventsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryLayersEventsByIdQuery, QueryLayersEventsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryLayersEventsByIdQuery, QueryLayersEventsByIdQueryVariables>(
    QueryLayersEventsByIdDocument,
    options
  );
}
export function useQueryLayersEventsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryLayersEventsByIdQuery, QueryLayersEventsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryLayersEventsByIdQuery, QueryLayersEventsByIdQueryVariables>(
    QueryLayersEventsByIdDocument,
    options
  );
}
export type QueryLayersEventsByIdQueryHookResult = ReturnType<typeof useQueryLayersEventsByIdQuery>;
export type QueryLayersEventsByIdLazyQueryHookResult = ReturnType<typeof useQueryLayersEventsByIdLazyQuery>;
export type QueryLayersEventsByIdQueryResult = Apollo.QueryResult<
  QueryLayersEventsByIdQuery,
  QueryLayersEventsByIdQueryVariables
>;
export const QueryLayersEventsObjectsDocument = gql`
  query queryLayersEventsObjects(
    $distinct_on: [zoomLayersEvents_select_column!]
    $where: zoomLayersEvents_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomLayersEvents_order_by!]
  ) {
    zoomLayersEvents(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...LayersEvents
    }
  }
  ${LayersEventsFragmentDoc}
`;

/**
 * __useQueryLayersEventsObjectsQuery__
 *
 * To run a query within a React component, call `useQueryLayersEventsObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryLayersEventsObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryLayersEventsObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryLayersEventsObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryLayersEventsObjectsQuery, QueryLayersEventsObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryLayersEventsObjectsQuery, QueryLayersEventsObjectsQueryVariables>(
    QueryLayersEventsObjectsDocument,
    options
  );
}
export function useQueryLayersEventsObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryLayersEventsObjectsQuery, QueryLayersEventsObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryLayersEventsObjectsQuery, QueryLayersEventsObjectsQueryVariables>(
    QueryLayersEventsObjectsDocument,
    options
  );
}
export type QueryLayersEventsObjectsQueryHookResult = ReturnType<typeof useQueryLayersEventsObjectsQuery>;
export type QueryLayersEventsObjectsLazyQueryHookResult = ReturnType<typeof useQueryLayersEventsObjectsLazyQuery>;
export type QueryLayersEventsObjectsQueryResult = Apollo.QueryResult<
  QueryLayersEventsObjectsQuery,
  QueryLayersEventsObjectsQueryVariables
>;
export const SubscribeToLayersEventsByIdDocument = gql`
  subscription subscribeToLayersEventsById($zoomLayersEventsId: uuid!) {
    zoomLayersEvents_by_pk(id: $zoomLayersEventsId) {
      ...LayersEvents
    }
  }
  ${LayersEventsFragmentDoc}
`;

/**
 * __useSubscribeToLayersEventsByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToLayersEventsByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToLayersEventsByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToLayersEventsByIdSubscription({
 *   variables: {
 *      zoomLayersEventsId: // value for 'zoomLayersEventsId'
 *   },
 * });
 */
export function useSubscribeToLayersEventsByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToLayersEventsByIdSubscription,
    SubscribeToLayersEventsByIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToLayersEventsByIdSubscription,
    SubscribeToLayersEventsByIdSubscriptionVariables
  >(SubscribeToLayersEventsByIdDocument, options);
}
export type SubscribeToLayersEventsByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToLayersEventsByIdSubscription
>;
export type SubscribeToLayersEventsByIdSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToLayersEventsByIdSubscription>;
export const SubscribeToLayersEventsObjectsDocument = gql`
  subscription subscribeToLayersEventsObjects(
    $distinct_on: [zoomLayersEvents_select_column!]
    $where: zoomLayersEvents_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [zoomLayersEvents_order_by!]
  ) {
    zoomLayersEvents(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...LayersEvents
    }
  }
  ${LayersEventsFragmentDoc}
`;

/**
 * __useSubscribeToLayersEventsObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToLayersEventsObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToLayersEventsObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToLayersEventsObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToLayersEventsObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToLayersEventsObjectsSubscription,
    SubscribeToLayersEventsObjectsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToLayersEventsObjectsSubscription,
    SubscribeToLayersEventsObjectsSubscriptionVariables
  >(SubscribeToLayersEventsObjectsDocument, options);
}
export type SubscribeToLayersEventsObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToLayersEventsObjectsSubscription
>;
export type SubscribeToLayersEventsObjectsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToLayersEventsObjectsSubscription>;
export const InsertLayersEventsDocument = gql`
  mutation insertLayersEvents($objects: [zoomLayersEvents_insert_input!]!) {
    insert_zoomLayersEvents(objects: $objects) {
      affected_rows
      returning {
        ...LayersEvents
      }
    }
  }
  ${LayersEventsFragmentDoc}
`;
export type InsertLayersEventsMutationFn = Apollo.MutationFunction<
  InsertLayersEventsMutation,
  InsertLayersEventsMutationVariables
>;

/**
 * __useInsertLayersEventsMutation__
 *
 * To run a mutation, you first call `useInsertLayersEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLayersEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLayersEventsMutation, { data, loading, error }] = useInsertLayersEventsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertLayersEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertLayersEventsMutation, InsertLayersEventsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertLayersEventsMutation, InsertLayersEventsMutationVariables>(
    InsertLayersEventsDocument,
    options
  );
}
export type InsertLayersEventsMutationHookResult = ReturnType<typeof useInsertLayersEventsMutation>;
export type InsertLayersEventsMutationResult = Apollo.MutationResult<InsertLayersEventsMutation>;
export type InsertLayersEventsMutationOptions = Apollo.BaseMutationOptions<
  InsertLayersEventsMutation,
  InsertLayersEventsMutationVariables
>;
export const InsertLayersEventsWithOnConflictDocument = gql`
  mutation insertLayersEventsWithOnConflict(
    $objects: [zoomLayersEvents_insert_input!]!
    $onConflict: zoomLayersEvents_on_conflict
  ) {
    insert_zoomLayersEvents(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...LayersEvents
      }
    }
  }
  ${LayersEventsFragmentDoc}
`;
export type InsertLayersEventsWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertLayersEventsWithOnConflictMutation,
  InsertLayersEventsWithOnConflictMutationVariables
>;

/**
 * __useInsertLayersEventsWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertLayersEventsWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLayersEventsWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLayersEventsWithOnConflictMutation, { data, loading, error }] = useInsertLayersEventsWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertLayersEventsWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertLayersEventsWithOnConflictMutation,
    InsertLayersEventsWithOnConflictMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertLayersEventsWithOnConflictMutation,
    InsertLayersEventsWithOnConflictMutationVariables
  >(InsertLayersEventsWithOnConflictDocument, options);
}
export type InsertLayersEventsWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertLayersEventsWithOnConflictMutation
>;
export type InsertLayersEventsWithOnConflictMutationResult =
  Apollo.MutationResult<InsertLayersEventsWithOnConflictMutation>;
export type InsertLayersEventsWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertLayersEventsWithOnConflictMutation,
  InsertLayersEventsWithOnConflictMutationVariables
>;
export const UpdateLayersEventsByIdDocument = gql`
  mutation updateLayersEventsById($id: uuid, $set: zoomLayersEvents_set_input) {
    update_zoomLayersEvents(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...LayersEvents
      }
    }
  }
  ${LayersEventsFragmentDoc}
`;
export type UpdateLayersEventsByIdMutationFn = Apollo.MutationFunction<
  UpdateLayersEventsByIdMutation,
  UpdateLayersEventsByIdMutationVariables
>;

/**
 * __useUpdateLayersEventsByIdMutation__
 *
 * To run a mutation, you first call `useUpdateLayersEventsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayersEventsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayersEventsByIdMutation, { data, loading, error }] = useUpdateLayersEventsByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateLayersEventsByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLayersEventsByIdMutation, UpdateLayersEventsByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLayersEventsByIdMutation, UpdateLayersEventsByIdMutationVariables>(
    UpdateLayersEventsByIdDocument,
    options
  );
}
export type UpdateLayersEventsByIdMutationHookResult = ReturnType<typeof useUpdateLayersEventsByIdMutation>;
export type UpdateLayersEventsByIdMutationResult = Apollo.MutationResult<UpdateLayersEventsByIdMutation>;
export type UpdateLayersEventsByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateLayersEventsByIdMutation,
  UpdateLayersEventsByIdMutationVariables
>;
export const UpdateLayersEventsDocument = gql`
  mutation updateLayersEvents($set: zoomLayersEvents_set_input, $where: zoomLayersEvents_bool_exp!) {
    update_zoomLayersEvents(_set: $set, where: $where) {
      affected_rows
      returning {
        ...LayersEvents
      }
    }
  }
  ${LayersEventsFragmentDoc}
`;
export type UpdateLayersEventsMutationFn = Apollo.MutationFunction<
  UpdateLayersEventsMutation,
  UpdateLayersEventsMutationVariables
>;

/**
 * __useUpdateLayersEventsMutation__
 *
 * To run a mutation, you first call `useUpdateLayersEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayersEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayersEventsMutation, { data, loading, error }] = useUpdateLayersEventsMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateLayersEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLayersEventsMutation, UpdateLayersEventsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLayersEventsMutation, UpdateLayersEventsMutationVariables>(
    UpdateLayersEventsDocument,
    options
  );
}
export type UpdateLayersEventsMutationHookResult = ReturnType<typeof useUpdateLayersEventsMutation>;
export type UpdateLayersEventsMutationResult = Apollo.MutationResult<UpdateLayersEventsMutation>;
export type UpdateLayersEventsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLayersEventsMutation,
  UpdateLayersEventsMutationVariables
>;
export const RemoveZoomLayersEventsModelByIdDocument = gql`
  mutation removeZoomLayersEventsModelById($id: uuid) {
    delete_zoomLayersEvents(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveZoomLayersEventsModelByIdMutationFn = Apollo.MutationFunction<
  RemoveZoomLayersEventsModelByIdMutation,
  RemoveZoomLayersEventsModelByIdMutationVariables
>;

/**
 * __useRemoveZoomLayersEventsModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveZoomLayersEventsModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomLayersEventsModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomLayersEventsModelByIdMutation, { data, loading, error }] = useRemoveZoomLayersEventsModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveZoomLayersEventsModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomLayersEventsModelByIdMutation,
    RemoveZoomLayersEventsModelByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomLayersEventsModelByIdMutation, RemoveZoomLayersEventsModelByIdMutationVariables>(
    RemoveZoomLayersEventsModelByIdDocument,
    options
  );
}
export type RemoveZoomLayersEventsModelByIdMutationHookResult = ReturnType<
  typeof useRemoveZoomLayersEventsModelByIdMutation
>;
export type RemoveZoomLayersEventsModelByIdMutationResult =
  Apollo.MutationResult<RemoveZoomLayersEventsModelByIdMutation>;
export type RemoveZoomLayersEventsModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomLayersEventsModelByIdMutation,
  RemoveZoomLayersEventsModelByIdMutationVariables
>;
export const RemoveZoomLayersEventsModelDocument = gql`
  mutation removeZoomLayersEventsModel($where: zoomLayersEvents_bool_exp!) {
    delete_zoomLayersEvents(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveZoomLayersEventsModelMutationFn = Apollo.MutationFunction<
  RemoveZoomLayersEventsModelMutation,
  RemoveZoomLayersEventsModelMutationVariables
>;

/**
 * __useRemoveZoomLayersEventsModelMutation__
 *
 * To run a mutation, you first call `useRemoveZoomLayersEventsModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveZoomLayersEventsModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeZoomLayersEventsModelMutation, { data, loading, error }] = useRemoveZoomLayersEventsModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveZoomLayersEventsModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveZoomLayersEventsModelMutation,
    RemoveZoomLayersEventsModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveZoomLayersEventsModelMutation, RemoveZoomLayersEventsModelMutationVariables>(
    RemoveZoomLayersEventsModelDocument,
    options
  );
}
export type RemoveZoomLayersEventsModelMutationHookResult = ReturnType<typeof useRemoveZoomLayersEventsModelMutation>;
export type RemoveZoomLayersEventsModelMutationResult = Apollo.MutationResult<RemoveZoomLayersEventsModelMutation>;
export type RemoveZoomLayersEventsModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveZoomLayersEventsModelMutation,
  RemoveZoomLayersEventsModelMutationVariables
>;
export const IncZoomCustomBackgroundDownloadCountDocument = gql`
  mutation incZoomCustomBackgroundDownloadCount($increaseBy: Int, $where: zoomCustomBackground_bool_exp!) {
    update_zoomCustomBackground(_inc: { downloadCount: $increaseBy }, where: $where) {
      affected_rows
      returning {
        ...ZoomCustomBackground
      }
    }
  }
  ${ZoomCustomBackgroundFragmentDoc}
`;
export type IncZoomCustomBackgroundDownloadCountMutationFn = Apollo.MutationFunction<
  IncZoomCustomBackgroundDownloadCountMutation,
  IncZoomCustomBackgroundDownloadCountMutationVariables
>;

/**
 * __useIncZoomCustomBackgroundDownloadCountMutation__
 *
 * To run a mutation, you first call `useIncZoomCustomBackgroundDownloadCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncZoomCustomBackgroundDownloadCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incZoomCustomBackgroundDownloadCountMutation, { data, loading, error }] = useIncZoomCustomBackgroundDownloadCountMutation({
 *   variables: {
 *      increaseBy: // value for 'increaseBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIncZoomCustomBackgroundDownloadCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncZoomCustomBackgroundDownloadCountMutation,
    IncZoomCustomBackgroundDownloadCountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IncZoomCustomBackgroundDownloadCountMutation,
    IncZoomCustomBackgroundDownloadCountMutationVariables
  >(IncZoomCustomBackgroundDownloadCountDocument, options);
}
export type IncZoomCustomBackgroundDownloadCountMutationHookResult = ReturnType<
  typeof useIncZoomCustomBackgroundDownloadCountMutation
>;
export type IncZoomCustomBackgroundDownloadCountMutationResult =
  Apollo.MutationResult<IncZoomCustomBackgroundDownloadCountMutation>;
export type IncZoomCustomBackgroundDownloadCountMutationOptions = Apollo.BaseMutationOptions<
  IncZoomCustomBackgroundDownloadCountMutation,
  IncZoomCustomBackgroundDownloadCountMutationVariables
>;
export const QueryZoomEventDocument = gql`
  query queryZoomEvent(
    $where: zoomEvent_bool_exp!
    $where_participant: zoomEventParticipant_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomEvent_order_by!]
    $distinct_on: [zoomEvent_select_column!]
  ) {
    zoomEvent(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
      ...ZoomEvent
    }
  }
  ${ZoomEventFragmentDoc}
`;

/**
 * __useQueryZoomEventQuery__
 *
 * To run a query within a React component, call `useQueryZoomEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomEventQuery({
 *   variables: {
 *      where: // value for 'where'
 *      where_participant: // value for 'where_participant'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      distinct_on: // value for 'distinct_on'
 *   },
 * });
 */
export function useQueryZoomEventQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomEventQuery, QueryZoomEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomEventQuery, QueryZoomEventQueryVariables>(QueryZoomEventDocument, options);
}
export function useQueryZoomEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomEventQuery, QueryZoomEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomEventQuery, QueryZoomEventQueryVariables>(QueryZoomEventDocument, options);
}
export type QueryZoomEventQueryHookResult = ReturnType<typeof useQueryZoomEventQuery>;
export type QueryZoomEventLazyQueryHookResult = ReturnType<typeof useQueryZoomEventLazyQuery>;
export type QueryZoomEventQueryResult = Apollo.QueryResult<QueryZoomEventQuery, QueryZoomEventQueryVariables>;
export const SubscribeToZoomEventDocument = gql`
  subscription subscribeToZoomEvent(
    $where: zoomEvent_bool_exp!
    $where_participant: zoomEventParticipant_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomEvent_order_by!]
  ) {
    zoomEvent(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomEvent
    }
  }
  ${ZoomEventFragmentDoc}
`;

/**
 * __useSubscribeToZoomEventSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomEventSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      where_participant: // value for 'where_participant'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomEventSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomEventSubscription,
    SubscribeToZoomEventSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SubscribeToZoomEventSubscription, SubscribeToZoomEventSubscriptionVariables>(
    SubscribeToZoomEventDocument,
    options
  );
}
export type SubscribeToZoomEventSubscriptionHookResult = ReturnType<typeof useSubscribeToZoomEventSubscription>;
export type SubscribeToZoomEventSubscriptionResult = Apollo.SubscriptionResult<SubscribeToZoomEventSubscription>;
export const QueryZoomEventParticipantWithContactsSortedDocument = gql`
  query queryZoomEventParticipantWithContactsSorted(
    $args: zoomEventParticipantWithContacts_sorted_args!
    $where: zoomEventParticipantWithContacts_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomEventParticipantWithContacts_order_by!]
  ) {
    zoomEventParticipantWithContacts_sorted(
      args: $args
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomEventParticipantWithContacts
    }
  }
  ${ZoomEventParticipantWithContactsFragmentDoc}
`;

/**
 * __useQueryZoomEventParticipantWithContactsSortedQuery__
 *
 * To run a query within a React component, call `useQueryZoomEventParticipantWithContactsSortedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomEventParticipantWithContactsSortedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomEventParticipantWithContactsSortedQuery({
 *   variables: {
 *      args: // value for 'args'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomEventParticipantWithContactsSortedQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomEventParticipantWithContactsSortedQuery,
    QueryZoomEventParticipantWithContactsSortedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryZoomEventParticipantWithContactsSortedQuery,
    QueryZoomEventParticipantWithContactsSortedQueryVariables
  >(QueryZoomEventParticipantWithContactsSortedDocument, options);
}
export function useQueryZoomEventParticipantWithContactsSortedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomEventParticipantWithContactsSortedQuery,
    QueryZoomEventParticipantWithContactsSortedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryZoomEventParticipantWithContactsSortedQuery,
    QueryZoomEventParticipantWithContactsSortedQueryVariables
  >(QueryZoomEventParticipantWithContactsSortedDocument, options);
}
export type QueryZoomEventParticipantWithContactsSortedQueryHookResult = ReturnType<
  typeof useQueryZoomEventParticipantWithContactsSortedQuery
>;
export type QueryZoomEventParticipantWithContactsSortedLazyQueryHookResult = ReturnType<
  typeof useQueryZoomEventParticipantWithContactsSortedLazyQuery
>;
export type QueryZoomEventParticipantWithContactsSortedQueryResult = Apollo.QueryResult<
  QueryZoomEventParticipantWithContactsSortedQuery,
  QueryZoomEventParticipantWithContactsSortedQueryVariables
>;
export const SubscribeToZoomEventParticipantWithContactsSortedDocument = gql`
  subscription subscribeToZoomEventParticipantWithContactsSorted(
    $args: zoomEventParticipantWithContacts_sorted_args!
    $where: zoomEventParticipantWithContacts_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomEventParticipantWithContacts_order_by!]
  ) {
    zoomEventParticipantWithContacts_sorted(
      args: $args
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ZoomEventParticipantWithContacts
    }
  }
  ${ZoomEventParticipantWithContactsFragmentDoc}
`;

/**
 * __useSubscribeToZoomEventParticipantWithContactsSortedSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomEventParticipantWithContactsSortedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomEventParticipantWithContactsSortedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomEventParticipantWithContactsSortedSubscription({
 *   variables: {
 *      args: // value for 'args'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomEventParticipantWithContactsSortedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomEventParticipantWithContactsSortedSubscription,
    SubscribeToZoomEventParticipantWithContactsSortedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomEventParticipantWithContactsSortedSubscription,
    SubscribeToZoomEventParticipantWithContactsSortedSubscriptionVariables
  >(SubscribeToZoomEventParticipantWithContactsSortedDocument, options);
}
export type SubscribeToZoomEventParticipantWithContactsSortedSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomEventParticipantWithContactsSortedSubscription
>;
export type SubscribeToZoomEventParticipantWithContactsSortedSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomEventParticipantWithContactsSortedSubscription>;
export const ZoomGroupMembershipAggregateDocument = gql`
  query ZoomGroupMembershipAggregate(
    $distinct_on: [zoomGroupMembership_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [zoomGroupMembership_order_by!]
    $where: zoomGroupMembership_bool_exp
  ) {
    aggregate: zoomGroupMembership_aggregate(distinct_on: $distinct_on, where: $where) {
      aggregate {
        count
      }
    }
    memberships: zoomGroupMembership(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      type
      user {
        ...ZoomUser
      }
    }
  }
  ${ZoomUserFragmentDoc}
`;

/**
 * __useZoomGroupMembershipAggregateQuery__
 *
 * To run a query within a React component, call `useZoomGroupMembershipAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomGroupMembershipAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomGroupMembershipAggregateQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useZoomGroupMembershipAggregateQuery(
  baseOptions?: Apollo.QueryHookOptions<ZoomGroupMembershipAggregateQuery, ZoomGroupMembershipAggregateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomGroupMembershipAggregateQuery, ZoomGroupMembershipAggregateQueryVariables>(
    ZoomGroupMembershipAggregateDocument,
    options
  );
}
export function useZoomGroupMembershipAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomGroupMembershipAggregateQuery,
    ZoomGroupMembershipAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomGroupMembershipAggregateQuery, ZoomGroupMembershipAggregateQueryVariables>(
    ZoomGroupMembershipAggregateDocument,
    options
  );
}
export type ZoomGroupMembershipAggregateQueryHookResult = ReturnType<typeof useZoomGroupMembershipAggregateQuery>;
export type ZoomGroupMembershipAggregateLazyQueryHookResult = ReturnType<
  typeof useZoomGroupMembershipAggregateLazyQuery
>;
export type ZoomGroupMembershipAggregateQueryResult = Apollo.QueryResult<
  ZoomGroupMembershipAggregateQuery,
  ZoomGroupMembershipAggregateQueryVariables
>;
export const ZoomGroupMembershipCountDocument = gql`
  query ZoomGroupMembershipCount(
    $distinct_on: [zoomGroupMembership_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [zoomGroupMembership_order_by!]
    $where: zoomGroupMembership_bool_exp
  ) {
    aggregate: zoomGroupMembership_aggregate(distinct_on: $distinct_on, where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useZoomGroupMembershipCountQuery__
 *
 * To run a query within a React component, call `useZoomGroupMembershipCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomGroupMembershipCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomGroupMembershipCountQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useZoomGroupMembershipCountQuery(
  baseOptions?: Apollo.QueryHookOptions<ZoomGroupMembershipCountQuery, ZoomGroupMembershipCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomGroupMembershipCountQuery, ZoomGroupMembershipCountQueryVariables>(
    ZoomGroupMembershipCountDocument,
    options
  );
}
export function useZoomGroupMembershipCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZoomGroupMembershipCountQuery, ZoomGroupMembershipCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomGroupMembershipCountQuery, ZoomGroupMembershipCountQueryVariables>(
    ZoomGroupMembershipCountDocument,
    options
  );
}
export type ZoomGroupMembershipCountQueryHookResult = ReturnType<typeof useZoomGroupMembershipCountQuery>;
export type ZoomGroupMembershipCountLazyQueryHookResult = ReturnType<typeof useZoomGroupMembershipCountLazyQuery>;
export type ZoomGroupMembershipCountQueryResult = Apollo.QueryResult<
  ZoomGroupMembershipCountQuery,
  ZoomGroupMembershipCountQueryVariables
>;
export const ZoomOrganizationMembershipAggregateDocument = gql`
  query ZoomOrganizationMembershipAggregate(
    $distinct_on: [zoomOrganizationMembership_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [zoomOrganizationMembership_order_by!]
    $where: zoomOrganizationMembership_bool_exp
  ) {
    aggregate: zoomOrganizationMembership_aggregate(distinct_on: $distinct_on, where: $where) {
      aggregate {
        count
      }
    }
    memberships: zoomOrganizationMembership(
      distinct_on: $distinct_on
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: $where
    ) {
      type
      user {
        ...ZoomUser
      }
    }
  }
  ${ZoomUserFragmentDoc}
`;

/**
 * __useZoomOrganizationMembershipAggregateQuery__
 *
 * To run a query within a React component, call `useZoomOrganizationMembershipAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomOrganizationMembershipAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomOrganizationMembershipAggregateQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useZoomOrganizationMembershipAggregateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ZoomOrganizationMembershipAggregateQuery,
    ZoomOrganizationMembershipAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomOrganizationMembershipAggregateQuery, ZoomOrganizationMembershipAggregateQueryVariables>(
    ZoomOrganizationMembershipAggregateDocument,
    options
  );
}
export function useZoomOrganizationMembershipAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomOrganizationMembershipAggregateQuery,
    ZoomOrganizationMembershipAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ZoomOrganizationMembershipAggregateQuery,
    ZoomOrganizationMembershipAggregateQueryVariables
  >(ZoomOrganizationMembershipAggregateDocument, options);
}
export type ZoomOrganizationMembershipAggregateQueryHookResult = ReturnType<
  typeof useZoomOrganizationMembershipAggregateQuery
>;
export type ZoomOrganizationMembershipAggregateLazyQueryHookResult = ReturnType<
  typeof useZoomOrganizationMembershipAggregateLazyQuery
>;
export type ZoomOrganizationMembershipAggregateQueryResult = Apollo.QueryResult<
  ZoomOrganizationMembershipAggregateQuery,
  ZoomOrganizationMembershipAggregateQueryVariables
>;
export const ZoomOrganizationMembershipCountDocument = gql`
  query ZoomOrganizationMembershipCount(
    $distinct_on: [zoomOrganizationMembership_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [zoomOrganizationMembership_order_by!]
    $where: zoomOrganizationMembership_bool_exp
  ) {
    aggregate: zoomOrganizationMembership_aggregate(distinct_on: $distinct_on, where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useZoomOrganizationMembershipCountQuery__
 *
 * To run a query within a React component, call `useZoomOrganizationMembershipCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomOrganizationMembershipCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomOrganizationMembershipCountQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useZoomOrganizationMembershipCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ZoomOrganizationMembershipCountQuery,
    ZoomOrganizationMembershipCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomOrganizationMembershipCountQuery, ZoomOrganizationMembershipCountQueryVariables>(
    ZoomOrganizationMembershipCountDocument,
    options
  );
}
export function useZoomOrganizationMembershipCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomOrganizationMembershipCountQuery,
    ZoomOrganizationMembershipCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomOrganizationMembershipCountQuery, ZoomOrganizationMembershipCountQueryVariables>(
    ZoomOrganizationMembershipCountDocument,
    options
  );
}
export type ZoomOrganizationMembershipCountQueryHookResult = ReturnType<typeof useZoomOrganizationMembershipCountQuery>;
export type ZoomOrganizationMembershipCountLazyQueryHookResult = ReturnType<
  typeof useZoomOrganizationMembershipCountLazyQuery
>;
export type ZoomOrganizationMembershipCountQueryResult = Apollo.QueryResult<
  ZoomOrganizationMembershipCountQuery,
  ZoomOrganizationMembershipCountQueryVariables
>;
export const ZoomUserAggregateDocument = gql`
  query ZoomUserAggregate(
    $distinct_on: [zoomUser_select_column!]
    $limit: Int
    $offset: Int
    $order_by: [zoomUser_order_by!]
    $where: zoomUser_bool_exp
  ) {
    aggregate: zoomUser_aggregate(distinct_on: $distinct_on, where: $where) {
      aggregate {
        count
      }
    }
    zoomUser(distinct_on: $distinct_on, limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      ...ZoomUser
    }
  }
  ${ZoomUserFragmentDoc}
`;

/**
 * __useZoomUserAggregateQuery__
 *
 * To run a query within a React component, call `useZoomUserAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomUserAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomUserAggregateQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useZoomUserAggregateQuery(
  baseOptions?: Apollo.QueryHookOptions<ZoomUserAggregateQuery, ZoomUserAggregateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomUserAggregateQuery, ZoomUserAggregateQueryVariables>(ZoomUserAggregateDocument, options);
}
export function useZoomUserAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZoomUserAggregateQuery, ZoomUserAggregateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomUserAggregateQuery, ZoomUserAggregateQueryVariables>(
    ZoomUserAggregateDocument,
    options
  );
}
export type ZoomUserAggregateQueryHookResult = ReturnType<typeof useZoomUserAggregateQuery>;
export type ZoomUserAggregateLazyQueryHookResult = ReturnType<typeof useZoomUserAggregateLazyQuery>;
export type ZoomUserAggregateQueryResult = Apollo.QueryResult<ZoomUserAggregateQuery, ZoomUserAggregateQueryVariables>;
export const QueryZoomUserConnectionRequestDocument = gql`
  query queryZoomUserConnectionRequest(
    $where: zoomUserConnectionRequest_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomUserConnectionRequest_order_by!]
  ) {
    zoomUserConnectionRequest(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUserConnectionRequest
    }
  }
  ${ZoomUserConnectionRequestFragmentDoc}
`;

/**
 * __useQueryZoomUserConnectionRequestQuery__
 *
 * To run a query within a React component, call `useQueryZoomUserConnectionRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomUserConnectionRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomUserConnectionRequestQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomUserConnectionRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomUserConnectionRequestQuery,
    QueryZoomUserConnectionRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomUserConnectionRequestQuery, QueryZoomUserConnectionRequestQueryVariables>(
    QueryZoomUserConnectionRequestDocument,
    options
  );
}
export function useQueryZoomUserConnectionRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomUserConnectionRequestQuery,
    QueryZoomUserConnectionRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomUserConnectionRequestQuery, QueryZoomUserConnectionRequestQueryVariables>(
    QueryZoomUserConnectionRequestDocument,
    options
  );
}
export type QueryZoomUserConnectionRequestQueryHookResult = ReturnType<typeof useQueryZoomUserConnectionRequestQuery>;
export type QueryZoomUserConnectionRequestLazyQueryHookResult = ReturnType<
  typeof useQueryZoomUserConnectionRequestLazyQuery
>;
export type QueryZoomUserConnectionRequestQueryResult = Apollo.QueryResult<
  QueryZoomUserConnectionRequestQuery,
  QueryZoomUserConnectionRequestQueryVariables
>;
export const SubscribeToZoomUserConnectionRequestDocument = gql`
  subscription subscribeToZoomUserConnectionRequest(
    $where: zoomUserConnectionRequest_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomUserConnectionRequest_order_by!]
  ) {
    zoomUserConnectionRequest(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUserConnectionRequest
    }
  }
  ${ZoomUserConnectionRequestFragmentDoc}
`;

/**
 * __useSubscribeToZoomUserConnectionRequestSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomUserConnectionRequestSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomUserConnectionRequestSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomUserConnectionRequestSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomUserConnectionRequestSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomUserConnectionRequestSubscription,
    SubscribeToZoomUserConnectionRequestSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomUserConnectionRequestSubscription,
    SubscribeToZoomUserConnectionRequestSubscriptionVariables
  >(SubscribeToZoomUserConnectionRequestDocument, options);
}
export type SubscribeToZoomUserConnectionRequestSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomUserConnectionRequestSubscription
>;
export type SubscribeToZoomUserConnectionRequestSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomUserConnectionRequestSubscription>;
export const SubscribeToZoomUserConnectionRequestCountDocument = gql`
  subscription subscribeToZoomUserConnectionRequestCount($where: zoomUserConnectionRequest_bool_exp!) {
    zoomUserConnectionRequest_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useSubscribeToZoomUserConnectionRequestCountSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomUserConnectionRequestCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomUserConnectionRequestCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomUserConnectionRequestCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToZoomUserConnectionRequestCountSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomUserConnectionRequestCountSubscription,
    SubscribeToZoomUserConnectionRequestCountSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomUserConnectionRequestCountSubscription,
    SubscribeToZoomUserConnectionRequestCountSubscriptionVariables
  >(SubscribeToZoomUserConnectionRequestCountDocument, options);
}
export type SubscribeToZoomUserConnectionRequestCountSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomUserConnectionRequestCountSubscription
>;
export type SubscribeToZoomUserConnectionRequestCountSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomUserConnectionRequestCountSubscription>;
export const QueryZoomUserMeetingParticipantsDocument = gql`
  query queryZoomUserMeetingParticipants(
    $where: zoomUserMeetingParticipants_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomUserMeetingParticipants_order_by!]
  ) {
    zoomUserMeetingParticipants(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      zoomUserId
      zoomUserEmail
      meetingTitle
      meetingStartAt
      meetingEndAt
    }
  }
`;

/**
 * __useQueryZoomUserMeetingParticipantsQuery__
 *
 * To run a query within a React component, call `useQueryZoomUserMeetingParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomUserMeetingParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomUserMeetingParticipantsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomUserMeetingParticipantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryZoomUserMeetingParticipantsQuery,
    QueryZoomUserMeetingParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomUserMeetingParticipantsQuery, QueryZoomUserMeetingParticipantsQueryVariables>(
    QueryZoomUserMeetingParticipantsDocument,
    options
  );
}
export function useQueryZoomUserMeetingParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryZoomUserMeetingParticipantsQuery,
    QueryZoomUserMeetingParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomUserMeetingParticipantsQuery, QueryZoomUserMeetingParticipantsQueryVariables>(
    QueryZoomUserMeetingParticipantsDocument,
    options
  );
}
export type QueryZoomUserMeetingParticipantsQueryHookResult = ReturnType<
  typeof useQueryZoomUserMeetingParticipantsQuery
>;
export type QueryZoomUserMeetingParticipantsLazyQueryHookResult = ReturnType<
  typeof useQueryZoomUserMeetingParticipantsLazyQuery
>;
export type QueryZoomUserMeetingParticipantsQueryResult = Apollo.QueryResult<
  QueryZoomUserMeetingParticipantsQuery,
  QueryZoomUserMeetingParticipantsQueryVariables
>;
export const QueryZoomUserNetworkDocument = gql`
  query queryZoomUserNetwork(
    $where: zoomUserNetwork_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomUserNetwork_order_by!]
  ) {
    zoomUserNetwork(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUserNetwork
    }
  }
  ${ZoomUserNetworkFragmentDoc}
`;

/**
 * __useQueryZoomUserNetworkQuery__
 *
 * To run a query within a React component, call `useQueryZoomUserNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryZoomUserNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryZoomUserNetworkQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryZoomUserNetworkQuery(
  baseOptions: Apollo.QueryHookOptions<QueryZoomUserNetworkQuery, QueryZoomUserNetworkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryZoomUserNetworkQuery, QueryZoomUserNetworkQueryVariables>(
    QueryZoomUserNetworkDocument,
    options
  );
}
export function useQueryZoomUserNetworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryZoomUserNetworkQuery, QueryZoomUserNetworkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryZoomUserNetworkQuery, QueryZoomUserNetworkQueryVariables>(
    QueryZoomUserNetworkDocument,
    options
  );
}
export type QueryZoomUserNetworkQueryHookResult = ReturnType<typeof useQueryZoomUserNetworkQuery>;
export type QueryZoomUserNetworkLazyQueryHookResult = ReturnType<typeof useQueryZoomUserNetworkLazyQuery>;
export type QueryZoomUserNetworkQueryResult = Apollo.QueryResult<
  QueryZoomUserNetworkQuery,
  QueryZoomUserNetworkQueryVariables
>;
export const SubscribeToZoomUserNetworkDocument = gql`
  subscription subscribeToZoomUserNetwork(
    $where: zoomUserNetwork_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomUserNetwork_order_by!]
  ) {
    zoomUserNetwork(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUserNetwork
    }
  }
  ${ZoomUserNetworkFragmentDoc}
`;

/**
 * __useSubscribeToZoomUserNetworkSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomUserNetworkSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomUserNetworkSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomUserNetworkSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToZoomUserNetworkSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomUserNetworkSubscription,
    SubscribeToZoomUserNetworkSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomUserNetworkSubscription,
    SubscribeToZoomUserNetworkSubscriptionVariables
  >(SubscribeToZoomUserNetworkDocument, options);
}
export type SubscribeToZoomUserNetworkSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomUserNetworkSubscription
>;
export type SubscribeToZoomUserNetworkSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomUserNetworkSubscription>;
export const SubscribeToZoomUserNetworkCountDocument = gql`
  subscription subscribeToZoomUserNetworkCount($where: zoomUserNetwork_bool_exp!) {
    zoomUserNetwork_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useSubscribeToZoomUserNetworkCountSubscription__
 *
 * To run a query within a React component, call `useSubscribeToZoomUserNetworkCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToZoomUserNetworkCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToZoomUserNetworkCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSubscribeToZoomUserNetworkCountSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToZoomUserNetworkCountSubscription,
    SubscribeToZoomUserNetworkCountSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeToZoomUserNetworkCountSubscription,
    SubscribeToZoomUserNetworkCountSubscriptionVariables
  >(SubscribeToZoomUserNetworkCountDocument, options);
}
export type SubscribeToZoomUserNetworkCountSubscriptionHookResult = ReturnType<
  typeof useSubscribeToZoomUserNetworkCountSubscription
>;
export type SubscribeToZoomUserNetworkCountSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeToZoomUserNetworkCountSubscription>;
export const ZoomUsersAtCompanyDocument = gql`
  query ZoomUsersAtCompany(
    $where: zoomUsersAtCompany_bool_exp!
    $limit: Int
    $offset: Int
    $order_by: [zoomUsersAtCompany_order_by!]
  ) {
    zoomUsersAtCompany(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ZoomUsersAtCompany
    }
  }
  ${ZoomUsersAtCompanyFragmentDoc}
`;

/**
 * __useZoomUsersAtCompanyQuery__
 *
 * To run a query within a React component, call `useZoomUsersAtCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomUsersAtCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomUsersAtCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useZoomUsersAtCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<ZoomUsersAtCompanyQuery, ZoomUsersAtCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomUsersAtCompanyQuery, ZoomUsersAtCompanyQueryVariables>(
    ZoomUsersAtCompanyDocument,
    options
  );
}
export function useZoomUsersAtCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZoomUsersAtCompanyQuery, ZoomUsersAtCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomUsersAtCompanyQuery, ZoomUsersAtCompanyQueryVariables>(
    ZoomUsersAtCompanyDocument,
    options
  );
}
export type ZoomUsersAtCompanyQueryHookResult = ReturnType<typeof useZoomUsersAtCompanyQuery>;
export type ZoomUsersAtCompanyLazyQueryHookResult = ReturnType<typeof useZoomUsersAtCompanyLazyQuery>;
export type ZoomUsersAtCompanyQueryResult = Apollo.QueryResult<
  ZoomUsersAtCompanyQuery,
  ZoomUsersAtCompanyQueryVariables
>;
