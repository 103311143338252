/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { useNavigate } from 'react-router-dom';

/* --------------------------------- CUSTOM --------------------------------- */
import { usePosthogEvents } from 'src/hooks/usePosthogEvents';
import { useStores } from 'src/hooks/useStores';
import { isZoom } from 'src/modules/zoomSdk';
import {
  CURRENT_ENVIRONMENT,
  Environment,
  LocalStorageItem,
  NavigationPath,
  PosthogEventName,
  WarmlyUrl,
} from 'src/utils/constants';

export const useLogout = () => {
  const { capture } = usePosthogEvents();

  const { userStore } = useStores();

  const navigate = useNavigate();

  const logOut = async () => {
    localStorage.removeItem(LocalStorageItem.LastVisitedTab);

    await userStore.logout();

    capture(PosthogEventName.SignedOut);

    if (isZoom) {
      if (localStorage.getItem(LocalStorageItem.LogoutParams)) {
        navigate(NavigationPath.Login + localStorage.getItem(LocalStorageItem.LogoutParams));
      } else {
        navigate(NavigationPath.Login);
      }
    } else {
      if (CURRENT_ENVIRONMENT !== Environment.Production) {
        navigate(NavigationPath.Login);
      } else {
        window.location.assign(WarmlyUrl.Nametags);
      }
    }
  };

  return {
    logOut,
  };
};
