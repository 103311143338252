import { DEBUG_LOGGING_ENABLED } from 'src/utils/constants';

/**
 * Logs to console if enabled
 * @param data console.log data param
 *
 * @example
 * // To enable debug logging in the browser
 * window.localStorage.setItem('DebugLoggingEnabled', 'true')
 */
export const logDebug: Console['log'] = (...data) => {
  // eslint-disable-next-line no-console
  DEBUG_LOGGING_ENABLED && console.log(...data);
};
