/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { Box, Button, Container, Typography } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { GlobalStyles } from 'tss-react';

/* --------------------------------- CUSTOM --------------------------------- */
import { useStores } from 'src/hooks/useStores';
import { ZoomRunningContext } from 'src/modules/zoomSdk';
import { ERROR_CAT } from 'src/utils/constants';

/* -------------------------------------------------------------------------- */
/*                            COMPONENT DEFINITION                            */
/* -------------------------------------------------------------------------- */
const ErrorBoundaryLogger: React.FC = ({ children }) => {
  const {
    userStore: { zoomSdkConfig },
  } = useStores();

  const runningContext = window.localStorage.getItem('runningContext') as ZoomRunningContext | null;

  const zoomRunningContext = zoomSdkConfig?.runningContext || runningContext;

  const isLayers =
    !zoomRunningContext ||
    zoomRunningContext === ZoomRunningContext.InCamera ||
    zoomRunningContext === ZoomRunningContext.InImmersive;

  const fallback = isLayers ? (
    <div>
      <GlobalStyles styles={{ body: { backgroundColor: 'transparent' } }} />
    </div>
  ) : (
    <div>
      <GlobalStyles styles={{ body: { backgroundColor: 'transparent' } }} />
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" flexDirection="column">
          <Typography variant="h3" textAlign="center">
            Something went wrong
          </Typography>
          <Button variant="outlined" onClick={() => window.location.reload()}>
            Reload
          </Button>
          <img style={{ height: '100%', width: '100%', objectFit: 'contain' }} src={ERROR_CAT} alt="Error Cat" />
        </Box>
      </Container>
    </div>
  );

  return <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryLogger;
