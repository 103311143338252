export enum RoleTitle {
  NoTitle = 'No Title',
  FounderCeo = 'Founder/CEO',
  BusinessDevelopmentSales = 'BD/Sales',
  Executive = 'Exec',
  Recruiting = 'Recruiting',
  Engineer = 'Engineer',
  Operations = 'Ops',
  ProjectManager = 'Program/Project Manager',
  Marketing = 'Marketing',
  Product = 'Product',
  Student = 'Student/Intern',
  Investor = 'VC/Investor',
  CustomerSuccess = 'customer success',
  Event = 'Events',
  Other = 'Other',
}

export const RoleBucket = {
  founderCeo: ['founder', 'cofounder', 'co-founder', 'ceo'],
  businessDevelopmentSales: ['business', 'sales', 'account', 'partnerships', 'bdr', 'sdr', 'corporate development'],
  executive: ['vp', 'president', 'vice president', 'chief', 'director', 'general manager', 'cpo', 'cco'],
  recruiting: ['talent', 'recruit', 'people'],
  engineer: ['engineer', 'developer', 'software', 'programmer', 'tech lead'],
  operations: ['ops', 'operation', 'analytics'],
  projectManager: ['program', 'project'],
  marketer: ['marketing', 'marketer', 'growth', 'brand', 'content', 'copywriter', 'social media', 'pmm'],
  product: ['product', 'ux', 'designer', 'pm'],
  student: ['intern', 'student'],
  investor: ['vc', 'invest', 'principal'],
  customerSuccess: ['customer', 'community', 'success', 'client', 'customer service', 'csm'],
  events: ['event'],
};

export const RoleBucketCaseSensitive = {
  businessDevelopmentSales: ['AM', 'AE', 'BD'],
  executive: ['CTO', 'CIO', 'CMO', 'COO', 'CFO', 'CSO', 'CRO', 'CISO'],
};

export const RoleBucketEmailDomain = {
  investor: ['ventures', 'vc'],
};
