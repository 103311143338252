export const WarmlyUrl = {
  MarketingHome: 'https://warmly.ai',
  Dashboard: 'https://rapport.warmly.ai',
  Help: 'https://help.warmly.ai',
  HelpNametags: 'https://help.warmly.ai/en/collections/3497555-warmly-nametags',
  ToS: 'https://warmly.ai/p/terms-of-service',
  CalendarPrivacyNotice: 'https://warmly.ai/p/privacy-policy-calendar',
  PrivacyPolicy: 'https://warmly.ai/p/privacy-policy',
  OnboardingNoGoogleCalendarTypeformLink: 'https://getwarmly.typeform.com/to/G7JQqjFk',
  RequestAccess: 'https://warmly.ai/requestaccess',
  ZoomAppStoreAppInfoPage: 'https://marketplace.zoom.us/apps/vkBX54brQ0-iiSGtEx33vg',
  Pricing: 'https://warmly.ai/p/pricing',
  AdminEnablement: 'https://warmly.ai/p/admin-enablement',
  AccountDeleted: 'https://warmly.ai/p/account-deleted',
  Remove: 'https://warmly.ai/p/remove',
  Nametags: 'https://getnametags.com',
};

export const ZoomUrl = {
  Profile: 'https://zoom.us/profile',
  Pricing: 'https://zoom.us/pricing',
};

export const OnboardingUrl = {
  JoinWarmlyNetwork: 'https://www.loom.com/share/b9cb85f2e6b4474e88a8057dc7d3eece',
  CalendarSignatureDemo: 'https://www.loom.com/embed/e95f708e8e8e45d495d010a9fa02641b',
  EnableCalendarSignatures: 'https://help.warmly.ai/en/articles/5598605-what-are-calendar-signatures#h_06d25340d5',
  SkipNonWarmlyProfiles: 'https://help.warmly.ai/en/articles/5598605-what-are-calendar-signatures#h_5437dbb2d0',
  IncludeRecurringMeetings: 'https://help.warmly.ai/en/articles/5598605-what-are-calendar-signatures#h_767c2ce709',
  HowToAddEmailSignatureInGmail: 'https://support.google.com/mail/answer/8395?hl=en&co=GENIE.Platform%3DDesktop',
  EmailSignaturesExplainerVideo: 'https://www.loom.com/share/a9dde444016b47a588a13c60869d014f',
  WarmlyJoinJuly2022: 'https://www.loom.com/share/270ff94eb61b468aadd73e0c6da01f4a',
};

export const LoomVideoUrl = {
  MeetingBoosts: 'https://www.loom.com/share/405440de40234221a724df9b7efd2120',
  WatchDemoVideo: 'https://www.loom.com/share/51b39636e4cf442fae3b866b7697a7ce?t=4',
};

export const HelpUrl = {
  CreateCompany: 'https://help.warmly.ai/en/articles/5706034-create-or-edit-a-company#h_8dd23e2380',
  CreateGroup: 'https://help.warmly.ai/en/articles/5706034-create-or-edit-a-company#h_8dd23e2380',
  ZoomAdminApproval: 'https://help.warmly.ai/en/articles/5628917-troubleshooting-warmly-for-zoom',
  ForwardableEmail:
    'https://help.warmly.ai/en/articles/5628907-speed-up-your-approval-from-your-zoom-admin#h_1442aa04e6',
};

export enum QueryParam {
  ShowPracticeRoom = 'show-practice-room',
  ZoomUserId = 'zoom-user-id',
  Action = 'act',
  FirstName = 'first-name',
  LastName = 'last-name',
  Background = 'bg',
  SharedBackgroundZoomUserId = 'sharedBgZoomUserId',
  InvitedBy = 'invitedBy',
  Message = 'message',
  CustomToken = 'custom-token',
  PromptForCalendar = 'prompt-for-calendar',
  SkipCalendarPromptOnZoomLogin = 'skip-calendar-prompt-on-zoom-login',
  ErrorMessage = 'error-message',
  OnboardingType = 'onboarding-type',
  PageType = 'page-type',
  Email = 'e',
  ActiveTab = 'activeTab',
  SubPage = 'sub-page',
  ContactId = 'c',
  Widget = 'widget',
  CompanyId = 'company',
}
