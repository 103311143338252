export const CloudName = 'warmly';

// https://cloudinary.com/documentation/upload_presets
// Presets allow us to define a set of asset upload options instead of specifying them in each
// upload call
export enum Preset {
  Signed = 'ml_default',
}

export enum ResourceType {
  Auto = 'auto',
  Image = 'image',
  Video = 'video',
  Raw = 'raw',
}

export enum SourceType {
  Camera = 'camera',
  ImageSearch = 'image_search',
  GoogleDrive = 'google_drive',
  Facebook = 'facebook',
  Dropbox = 'dropbox',
  Instagram = 'instagram',
  Shutterstock = 'shutterstock',
  Local = 'local',
  Url = 'url',
}

/**
 * Aspect ratio of an image is the ratio of its width to its height.
 */
export const ImageAspectRatio = {
  // For profile pictures enforce square aspect ratio
  ProfilePicture: 1,
  // Zoom recommended dimensions: 1920px by 1080px (16:9 aspect ratio)
  ZoomBackground: 16 / 9,
  /**
   * Shoutouts can be cropped at any ratio.
   */
  Shoutout: undefined,
  /**
   * Conversation Starters use a 3:2 aspect ratio
   */
  ConversationStarter: 3 / 2,
};
