/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { QueryHookOptions } from '@apollo/client';
import { assign, omit } from 'lodash-es';
import { useEffect } from 'react';

/* --------------------------------- CUSTOM --------------------------------- */
import {
  Order_By,
  QueryZoomSettingsObjectsQuery,
  QueryZoomSettingsObjectsQueryVariables,
  ZoomSettings,
  ZoomSettings_Bool_Exp,
  ZoomSettings_Insert_Input,
} from 'src/graphql';
import { GQLHooks } from 'src/graphql/hasura/react';
import { useStores } from 'src/hooks/useStores';
import { AllZoomSettingsOmits, localDefaultZoomSettings, SettingsStoreState } from 'src/stores/Settings.store';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
type UseZoomSettingsOptions = Omit<
  QueryHookOptions<QueryZoomSettingsObjectsQuery, QueryZoomSettingsObjectsQueryVariables>,
  'query'
>;
type ZoomSettingsIdentifier = 'user' | 'group' | 'org' | 'domain' | 'default';

type ZoomSettingsWithoutMembership = Omit<
  ZoomSettings,
  'zoomOrganizationMemberships' | 'zoomOrganizationMemberships_aggregate'
>;
interface InheritedSettingsProps {
  userZoomSettings?: ZoomSettingsWithoutMembership;
  groupZoomSettings?: ZoomSettingsWithoutMembership;
  orgZoomSettings?: ZoomSettingsWithoutMembership;
  domainZoomSettings?: ZoomSettingsWithoutMembership;
  defaultZoomSettings?: ZoomSettingsWithoutMembership;
}

/* -------------------------------------------------------------------------- */
/*                               HOOK DEFINITION                              */
/* -------------------------------------------------------------------------- */
export const useSettings = (
  identifier: ZoomSettingsIdentifier,
  identifierId: string | string[] | undefined | null,
  options?: UseZoomSettingsOptions
) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const {
    userStore: { user },
  } = useStores();

  const [insert] = GQLHooks.Fragments.ZoomSettings.useInsert();

  let identifierIdWhere;
  if (Array.isArray(identifierId)) {
    identifierIdWhere = { _in: identifierId };
  } else if (identifierId === null) {
    identifierIdWhere = { _is_null: true };
  } else {
    identifierIdWhere = { _eq: identifierId };
  }

  const { data, loading, refetch } = GQLHooks.Fragments.ZoomSettings.useQueryObjects({
    ...options,
    skip: !user?.id,
    variables: {
      ...options?.variables,
      where: {
        ...options?.variables?.where,
        identifier: { _eq: identifier },
        identifierId: identifierIdWhere,
      },
      order_by: { createdAt: Order_By.Desc, ...options?.variables?.order_by },
    },
  });

  /* -------------------------------- FUNCTIONS ------------------------------- */
  const insertSettings = async (zoomSettings: ZoomSettings_Insert_Input) => {
    const result = await insert({ zoomSettings });
    refetch?.();
    return result;
  };

  return {
    insertSettings,
    zoomSettings: data?.zoomSettings[0],
    loading,
    refetch,
  };
};

export const useAllSettings = (options?: UseZoomSettingsOptions) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const {
    userStore: { user, organization, groups, userCompanyDomain },
  } = useStores();

  const zoomSettingsExp: ZoomSettings_Bool_Exp[] = [
    {
      identifier: { _eq: 'default' },
      identifierId: { _is_null: true },
    },
  ];

  if (user?.id) {
    zoomSettingsExp.push({
      identifier: { _eq: 'user' },
      identifierId: { _eq: user.id },
    });
  }
  if (organization?.id) {
    zoomSettingsExp.push({
      identifier: { _eq: 'org' },
      identifierId: { _eq: organization.id },
    });
  }
  if (userCompanyDomain) {
    zoomSettingsExp.push({
      identifier: { _eq: 'domain' },
      identifierId: { _eq: userCompanyDomain },
    });
  }
  if (groups?.length > 0) {
    zoomSettingsExp.push({
      identifier: { _eq: 'group' },
      identifierId: { _in: groups.map((x) => x.id) },
    });
  }

  const { data, loading, refetch, called } = GQLHooks.Fragments.ZoomSettings.useQueryObjects({
    ...options,
    skip: !user?.id,
    variables: {
      ...options?.variables,
      where: {
        ...options?.variables?.where,
        _or: zoomSettingsExp,
      },
      order_by: { createdAt: Order_By.Desc, ...options?.variables?.order_by },
    },
  });

  return {
    data,
    loading,
    refetch,
    called,
  };
};

export const useAdminSettings = (options?: UseZoomSettingsOptions) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const {
    adminStore: { groups },
    userStore: { organization },
  } = useStores();

  const zoomSettingsExp: ZoomSettings_Bool_Exp[] = [
    {
      identifier: { _eq: 'default' },
      identifierId: { _is_null: true },
    },
  ];

  if (groups?.length > 0) {
    zoomSettingsExp.push({
      identifier: { _eq: 'group' },
      identifierId: { _in: groups.map((x) => x.id) },
    });
  }

  if (organization?.id) {
    zoomSettingsExp.push({
      identifier: { _eq: 'org' },
      identifierId: { _eq: organization.id },
    });
  }

  const { loading, error, data } = GQLHooks.Fragments.ZoomSettings.useQueryObjects({
    ...options,
    variables: {
      ...options?.variables,
      where: {
        ...options?.variables?.where,
        _or: zoomSettingsExp,
      },
      order_by: { createdAt: Order_By.Desc, ...options?.variables?.order_by },
    },
  });

  return {
    data,
    loading,
    error,
  };
};

export const useUserZoomSettings = (options?: UseZoomSettingsOptions) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const {
    userStore: { user },
  } = useStores();
  const identifier = 'user';
  const identifierId = user?.id;
  const { insertSettings, zoomSettings, loading, refetch } = useSettings(identifier, identifierId, options);

  return {
    insertUserZoomSettings: insertSettings,
    userZoomSettings: zoomSettings,
    loading,
    refetch,
  };
};

export const useOrgZoomSettings = (options?: UseZoomSettingsOptions) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const {
    userStore: { organization },
  } = useStores();
  const identifier = 'org';
  const identifierId = organization?.id;
  const { insertSettings, zoomSettings, loading, refetch } = useSettings(identifier, identifierId, options);

  return {
    insertOrgZoomSettings: insertSettings,
    orgZoomSettings: zoomSettings,
    loading,
    refetch,
  };
};

export const useGroupZoomSettings = (options?: UseZoomSettingsOptions) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const identifier = 'group';
  const identifierId = ['directory_group_00000000000000000000000000']; // TODO: Update this to use group id after org updates and update Hasura permissions to include group queries.
  const { insertSettings, zoomSettings, loading, refetch } = useSettings(identifier, identifierId, options);

  return {
    insertGroupZoomSettings: insertSettings,
    groupZoomSettings: zoomSettings,
    loading,
    refetch,
  };
};

export const useDomainZoomSettings = (options?: UseZoomSettingsOptions) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const identifier = 'domain';
  const {
    userStore: { userCompanyDomain },
  } = useStores();

  const identifierId = userCompanyDomain;
  const { insertSettings, zoomSettings, loading, refetch } = useSettings(identifier, identifierId, options);

  return {
    insertDomainZoomSettings: insertSettings,
    domainZoomSettings: zoomSettings,
    loading,
    refetch,
  };
};

export const useDefaultZoomSettings = (options?: UseZoomSettingsOptions) => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const identifier = 'default';
  const identifierId = null;
  const { insertSettings, zoomSettings, loading, refetch } = useSettings(identifier, identifierId, options);

  return {
    insertDefaultZoomSettings: insertSettings,
    defaultZoomSettings: zoomSettings,
    loading,
    refetch,
  };
};

export const useUpdateZoomSettings = () => {
  /* ---------------------------------- HOOKS --------------------------------- */
  const {
    settingsStore: { setZoomSettings },
  } = useStores();

  const { data, refetch, called } = useAllSettings();

  useEffect(() => {
    if (!data) return;
    setZoomSettings(data);
  }, [setZoomSettings, data]);

  return {
    refetchAllSettings: refetch,
    called,
  };
};

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
export const getInheritedSettings = ({
  userZoomSettings,
  groupZoomSettings,
  orgZoomSettings,
  domainZoomSettings,
  defaultZoomSettings,
}: InheritedSettingsProps) => {
  const pickedDefaultZoomSettings = omit(defaultZoomSettings, AllZoomSettingsOmits) as SettingsStoreState;
  const pickedUserZoomSettings = omit(userZoomSettings, AllZoomSettingsOmits) as SettingsStoreState;
  const pickedDomainZoomSettings = omit(domainZoomSettings, AllZoomSettingsOmits) as SettingsStoreState;
  const pickedOrgZoomSettings = omit(orgZoomSettings, AllZoomSettingsOmits) as SettingsStoreState;
  const pickedGroupZoomSettings = omit(groupZoomSettings, AllZoomSettingsOmits) as SettingsStoreState;

  return assign(
    localDefaultZoomSettings,
    pickedDefaultZoomSettings,
    pickedUserZoomSettings,
    pickedDomainZoomSettings,
    pickedOrgZoomSettings,
    pickedGroupZoomSettings
  );
};
