/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import type { User } from 'firebase/auth';
import { merge } from 'lodash-es';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomSdkConfig, ZoomSdkMedia } from 'src/@types/zoomSdk';
import { firebaseAuth } from 'src/config/firebase';
import { ZoomContactWithCompanyFragment, ZoomUserFragment } from 'src/graphql';
import { Plan } from 'src/graphql/main';
import { FutureZoomSdkApiMethod, ZoomSdkApiMethod, ZoomSdkApiMethodAll } from 'src/modules/zoomSdk';
import RootStore from 'src/stores/Root.store';
import { AllWidgetKeys, UserRole, WIDGET_KEY } from 'src/utils/constants';
import { isFreeEmailDomain } from 'src/utils/functions/email';
import { parseDomainFromEmail } from 'src/utils/functions/functions';
import { getProfileViewData } from 'src/utils/profile/profile';
import { getProfileFormData } from 'src/utils/profile/profileForm';
import { isZoomEmployee } from 'src/utils/user';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

export interface UserStoreState {
  firebaseAuthUser?: User;
  user?: ZoomUserFragment & {
    integrationType: IntegrationType;
  };
  plansForSale?: Plan[];
  plans?: Plan[];
  currentPlan?: Plan;
  clipboard?: string[];
  userZoomContact?: ZoomContactWithCompanyFragment;
  zoomSdkConfig?: ZoomSdkConfig;
  zoomSdkSupportedApis?: ZoomSdkApiMethodAll[];
  pkse: PKSE;
  isVirtualForegroundSupported: boolean;
  isLaunchAppInMeetingSupported: boolean;
  isVideoSettingsSupported: boolean;
  showPreloader: boolean;
  isLayersSupported: boolean;
  isZoomSdkInitialized: boolean;
  appExpanded: boolean;
  shouldInitializeZoomSdk: boolean;
  shouldReauthenticate: boolean;
  hasAcknowledgedAutoOpenPrimer: boolean;
  meetingCount: number;

  pinnedMeetingBoosts: WIDGET_KEY[];
}

const defaultUserStoreState: UserStoreState = {
  firebaseAuthUser: undefined,
  user: undefined,
  plansForSale: undefined,
  plans: undefined,
  currentPlan: undefined,
  clipboard: undefined,
  userZoomContact: undefined,
  zoomSdkConfig: undefined,
  zoomSdkSupportedApis: undefined,
  pkse: {
    codeChallenge: '',
    codeVerifier: '',
  },
  isVirtualForegroundSupported: false,
  isLaunchAppInMeetingSupported: false,
  isVideoSettingsSupported: false,
  showPreloader: false,
  isLayersSupported: false,
  isZoomSdkInitialized: false,
  appExpanded: false,
  shouldInitializeZoomSdk: true,
  shouldReauthenticate: false,
  hasAcknowledgedAutoOpenPrimer: false,
  meetingCount: 0,

  pinnedMeetingBoosts: ['clockWidget', 'meetingAlarmWidget', 'weatherWidget'],
};

/* -------------------------------------------------------------------------- */
/*                              STORE DEFINITION                              */
/* -------------------------------------------------------------------------- */
/**
 * User and auth related data and methods
 */
class UserStore implements UserStoreState {
  /* ---------------------------------- PROPS --------------------------------- */
  firebaseAuthUser = defaultUserStoreState.firebaseAuthUser;
  user = defaultUserStoreState.user;
  plansForSale = defaultUserStoreState.plansForSale;
  currentPlan = defaultUserStoreState.currentPlan;
  plans = defaultUserStoreState.plans;
  clipboard = defaultUserStoreState.clipboard;
  userZoomContact = defaultUserStoreState.userZoomContact;
  zoomSdkConfig = defaultUserStoreState.zoomSdkConfig;
  zoomSdkSupportedApis = defaultUserStoreState.zoomSdkSupportedApis;
  pkse = defaultUserStoreState.pkse;
  isVirtualForegroundSupported = defaultUserStoreState.isVirtualForegroundSupported;
  isLaunchAppInMeetingSupported = defaultUserStoreState.isLaunchAppInMeetingSupported;
  isVideoSettingsSupported = defaultUserStoreState.isVideoSettingsSupported;
  showPreloader = defaultUserStoreState.showPreloader;
  isLayersSupported = defaultUserStoreState.isLayersSupported;
  isZoomSdkInitialized = defaultUserStoreState.isZoomSdkInitialized;
  appExpanded = defaultUserStoreState.appExpanded;
  shouldInitializeZoomSdk = defaultUserStoreState.shouldInitializeZoomSdk;
  shouldReauthenticate = defaultUserStoreState.shouldReauthenticate;

  hasAcknowledgedAutoOpenPrimer = defaultUserStoreState.hasAcknowledgedAutoOpenPrimer;

  meetingCount = defaultUserStoreState.meetingCount;

  pinnedMeetingBoosts = defaultUserStoreState.pinnedMeetingBoosts;

  private rootStore: RootStore;
  private firebaseAuth = firebaseAuth;

  /* ------------------------------- CONSTRUCTOR ------------------------------ */
  /**
   * @param rootStore The root store for the app, allows this store to access all other stores
   */
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UserStore',
      properties: ['user', 'pkse', 'clipboard', 'hasAcknowledgedAutoOpenPrimer', 'meetingCount', 'pinnedMeetingBoosts'],
      storage: window.localStorage,
    });
  }

  /* -------------------------------- FUNCTIONS ------------------------------- */
  /**
   * Sets the current logged in user object from the zoomUser table
   * @param loggedInUser The current logged in user
   */
  setUser = (loggedInUser: ZoomUserFragment) => {
    this.user = {
      ...loggedInUser,
      integrationType: loggedInUser.integrationType as IntegrationType,
    };
    this.rootStore.homePageStore.setDefaultsForUser(loggedInUser.actions);
    this.rootStore.backgroundStore.setDefaultBackgroundType(loggedInUser);
  };

  setPlans = (plans: Plan[]) => {
    this.plans = plans;
  };

  setCurrentPlan = (plan: Plan | undefined) => {
    this.currentPlan = plan;
  };

  setPlansForSale = (plans: Plan[]) => {
    this.plansForSale = plans;
  };

  setPKSE = (value: PKSE) => {
    this.pkse = { ...value };
  };

  setClipboard = (items: string[]) => {
    this.clipboard = items;
  };

  setShowPreloader = (showPreloader: boolean) => {
    runInAction(() => (this.showPreloader = showPreloader));
  };

  setAppExpanded = (appExpanded: boolean) => (this.appExpanded = appExpanded);

  /**
   * Sets the current logged in Firebase Auth user
   * @param authUser The logged in Firebase Auth user
   */
  setFirebaseAuthUser = (authUser: User) => {
    this.firebaseAuthUser = authUser;
  };

  /**
   * Sets the current logged in user enriched data from the zoomContact table
   * @param userZoomContact The current logged in user enriched data
   */
  setUserZoomContact = (userZoomContact: ZoomContactWithCompanyFragment) => {
    this.userZoomContact = userZoomContact;
  };

  /**
   * Sets the response from the Zoom SDK configuration
   * @param zoomSdkConfig The response from the Zoom SDK config method
   */
  setZoomSdkConfig = (zoomSdkConfig: ZoomSdkConfig) => {
    this.zoomSdkConfig = zoomSdkConfig;
  };

  /**
   * Sets the response from the Zoom SDK media change event
   * @param media The media object from the media change event
   * @param isInitialize Whether the media change event is from the initialization of the SDK
   */
  setZoomSdkConfigMedia = (media?: ZoomSdkMedia, isInitialize?: boolean) => {
    if (!media) return;

    const mediaConfig = media;

    if (!this.zoomSdkConfig) return;

    if (!isInitialize && mediaConfig.video) {
      mediaConfig.video.time = Date.now();
    }

    this.zoomSdkConfig.media = merge(this.zoomSdkConfig.media, mediaConfig);
  };

  /**
   * Sets the supported Zoom SDK API methods for the user's Zoom client
   * @param zoomSdkSupportedApis The supported methods
   */
  setZoomSdkSupportedApis = async (zoomSdkSupportedApis?: ZoomSdkApiMethodAll[]) => {
    if (!zoomSdkSupportedApis) return;
    this.zoomSdkSupportedApis = zoomSdkSupportedApis;

    this.isVirtualForegroundSupported = Boolean(zoomSdkSupportedApis.includes(ZoomSdkApiMethod.SetVirtualForeground));
    this.isLaunchAppInMeetingSupported = Boolean(zoomSdkSupportedApis?.includes(ZoomSdkApiMethod.LaunchAppInMeeting));
    this.isVideoSettingsSupported = Boolean(
      zoomSdkSupportedApis?.includes(ZoomSdkApiMethod.GetVideoSettings) &&
        zoomSdkSupportedApis?.includes(ZoomSdkApiMethod.SetVideoSettings)
    );

    this.isLayersSupported = Boolean(
      zoomSdkSupportedApis?.includes(ZoomSdkApiMethod.RunRenderingContext) &&
        zoomSdkSupportedApis?.includes(FutureZoomSdkApiMethod.DrawWebView)
    );
  };

  /**
   * Sets the Zoom SDK initialization status
   * @param isZoomSdkInitialized whether the zoom sdk is initialized
   */
  setIsZoomSdkInitialized = (isZoomSdkInitialized: boolean) => {
    this.isZoomSdkInitialized = isZoomSdkInitialized;
  };

  setShouldInitializeZoomSdk = (shouldInitializeZoomSdk: boolean) => {
    this.shouldInitializeZoomSdk = shouldInitializeZoomSdk;
  };

  setShouldReauthenticate = (shouldReauthenticate: boolean) => {
    this.shouldReauthenticate = shouldReauthenticate;
  };

  setHasAcknowledgedAutoOpenPrimer = (flag: boolean) => {
    this.hasAcknowledgedAutoOpenPrimer = flag;
  };

  incrementMeetingCount = () => {
    this.meetingCount += 1;
  };

  pinMeetingBoost = (key: WIDGET_KEY) => {
    if (!this.pinnedMeetingBoosts.includes(key)) {
      this.pinnedMeetingBoosts.push(key);
      this.pinnedMeetingBoosts = this.pinnedMeetingBoosts.filter((n) => n);
    }
  };

  unpinMeetingBoost = (key: WIDGET_KEY) => {
    this.pinnedMeetingBoosts = this.pinnedMeetingBoosts.filter((k) => k !== key);
  };

  /**
   * Logs out the user and reset the store's user data
   */
  logout = async () => {
    this.firebaseAuthUser = defaultUserStoreState.firebaseAuthUser;
    this.user = undefined;

    // Reset meetingsStore upon logout
    this.rootStore.meetingsStore.resetMeetingsStoreState();
    await this.rootStore.backgroundStore.resetBackgroundStore();
    await this.rootStore.conversationStarterStore.resetConversationStarterStore();
    this.rootStore.liveUpdateStore.resetStoreState();

    for (const key of AllWidgetKeys) {
      if (key !== 'none') {
        await this.rootStore.widgetsStore[key]?.resetWidgetStore();
      }
    }

    return this.firebaseAuth.signOut();
  };

  delete = async () => {
    this.pkse = defaultUserStoreState.pkse;
    this.meetingCount = defaultUserStoreState.meetingCount;
    this.hasAcknowledgedAutoOpenPrimer = defaultUserStoreState.hasAcknowledgedAutoOpenPrimer;
    this.pinnedMeetingBoosts = defaultUserStoreState.pinnedMeetingBoosts;

    this.rootStore.ssoLoginStore.resetSSOLoginStore();
    this.logout();
  };

  get isLoggedIn() {
    return !!this.user;
  }

  /**
   * Gets the profile view data based on the current user and enriched data
   */
  get profileViewData() {
    if (!this.user && !this.userZoomContact) return undefined;

    return getProfileViewData({
      zoomUser: this.user,
      zoomContact: this.userZoomContact,
      zoomGroup: this.groups?.[0],
    });
  }

  /**
   * Gets the profile form data based on the current user and enriched data
   */
  get profileFormData() {
    if (!this.user) return undefined;
    return getProfileFormData({
      zoomUser: this.user,
      zoomContact: this.userZoomContact,
      userProfileSettings: this.rootStore.settingsStore.userProfileSettings,
    });
  }

  /**
   * Gets the domain of the current user
   */
  get userCompanyDomain() {
    return parseDomainFromEmail(this.user?.email || '');
  }

  /**
   * Gets the organization of the current user
   */
  get organization() {
    return this.user?.zoomOrganizationMembership?.organization;
  }

  /**
   * Gets the domains associated with the organization of the current user
   */
  get organizationDomains() {
    const ssoDomains = (this.organization?.ssoDomains || []) as string[];
    const otherDomains = (this.organization?.otherDomains || []) as string[];
    const allDomains = [...ssoDomains, ...otherDomains];
    return { ssoDomains, otherDomains, allDomains };
  }

  /**
   * Gets the groups of the current user
   */
  get groups() {
    return this.user?.zoomGroupMemberships?.map((membership) => membership.group) || [];
  }

  /**
   * Gets whether the current user has a free email domain
   */
  get isFreeDomain() {
    return this.organization ? this.organization?.isFreeEmailDomain : isFreeEmailDomain(this.userCompanyDomain);
  }

  /**
   * Gets whether the current user is a Zoom employee
   */
  get isZoomEmployee() {
    return isZoomEmployee(this.user?.email || '');
  }

  get backgroundImageType() {
    return this.isFreeDomain ? 'personal' : 'shared';
  }

  get isGroupSuper() {
    return !!this.user?.isGroupSuper;
  }

  get isGroupAdmin() {
    return !!this.user?.isGroupAdmin;
  }

  get isOrgAdmin() {
    return !!this.user?.isOrgAdmin;
  }

  get isInternal() {
    return this.user?.role === UserRole.INTERNAL;
  }

  /**
   * Returns the currently logged in user (based on the `user` property, which would be undefined for a non-logged in user).
   *
   * This getter should only be accessed by components that are guaranteed to be only accessible by logged-in users, since
   * non-logged in users calling this getter will result in an error.
   *
   * This getter is needed because we want to ensure (via Typescript typing) that any component only accessible by logged-in users
   * should be guaranteed to be able to access a non-undefined `user`.
   */
  get loggedInUser() {
    if (!this.user) {
      throw new Error('No logged in user found');
    } else {
      return this.user;
    }
  }
}

export default UserStore;
