import { ZoomEventFragment } from 'src/graphql';
import { MeetingDetailsByWeekday } from 'src/utils/constants';
import { getMeetingDetails } from 'src/utils/meeting';
import { getDayName } from 'src/utils/time/time';

/**
 * Conform array of zoomEvents to MeetingSchedule
 *
 * Note that this function does NOT set the current meeting
 * @param zoomEvents The zoomEvents to be converted to meeting details
 * @returns The meetings schedule containing the current meeting and other days
 */
export const conformSchedule = (zoomEvents?: ZoomEventFragment[]): MeetingDetailsByWeekday => {
  const schedule: MeetingDetailsByWeekday = {} as MeetingDetailsByWeekday;

  if (!zoomEvents) {
    return schedule;
  }

  for (const zoomEvent of zoomEvents) {
    const eventStartDate = zoomEvent.start ? new Date(zoomEvent.start) : undefined;

    if (!eventStartDate) {
      continue;
    }

    const dayName = getDayName(eventStartDate);
    schedule[dayName] = schedule[dayName] || [];

    const meeting = getMeetingDetails(zoomEvent);

    schedule[dayName] = [...(schedule[dayName] || []), meeting];
  }

  return schedule;
};
