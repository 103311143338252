/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* --------------------------------- CUSTOM --------------------------------- */
import { MeetingUuidResponse, ZoomOnAuthorizedEvent, zoomSdk, ZoomSdkConfig, ZoomSdkMedia } from 'src/@types/zoomSdk';
import { convertQuery } from 'src/hooks/useDeepLink';
import { logError } from 'src/modules/analytics';
import { DEBUG_LOGGING_ENABLED, DeepLinkActions, LocalStorageItem } from 'src/utils/constants';
import { logDebug } from 'src/utils/functions/debug';
import { wait } from 'src/utils/functions/functions';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
type GeneralMessageResponse = { message: 'Success' | 'Failure' };
export type ExpandAction = 'expand' | 'collapse';

/**
 * The current Zoom running context
 */
export enum ZoomRunningContext {
  InMeeting = 'inMeeting',
  InMainClient = 'inMainClient',
  InImmersive = 'inImmersive',
  InWebinar = 'inWebinar',
  InPhone = 'inPhone',
  InCollaborate = 'inCollaborate',
  InCamera = 'inCamera',
  /**
   * If not in zoom running context will be None
   */
  None = 'none',
}

/**
 * The Zoom SDK API methods that have been approved
 */
export enum ZoomSdkApiMethod {
  OpenUrl = 'openUrl',
  SetVirtualBackground = 'setVirtualBackground',
  GetMeetingUUID = 'getMeetingUUID',
  Connect = 'connect',
  CallZoomApi = 'callZoomApi',
  RemoveVirtualBackground = 'removeVirtualBackground',
  GetSupportedJsApis = 'getSupportedJsApis',
  SetVideoMirrorEffect = 'setVideoMirrorEffect',
  SetVirtualForeground = 'setVirtualForeground',
  RemoveVirtualForeground = 'removeVirtualForeground',
  LaunchAppInMeeting = 'launchAppInMeeting',
  // Layers API capabilities
  GetRunningContext = 'getRunningContext',
  RunRenderingContext = 'runRenderingContext',
  CloseRenderingContext = 'closeRenderingContext',
  DrawParticipant = 'drawParticipant',
  ClearParticipant = 'clearParticipant',
  DrawImage = 'drawImage',
  ClearImage = 'clearImage',
  GetMeetingParticipants = 'getMeetingParticipants',
  GetUserContext = 'getUserContext',
  PostMessage = 'postMessage',
  SendAppInvitationToAllParticipants = 'sendAppInvitationToAllParticipants',
  GetMeetingContext = 'getMeetingContext',
  ExpandApp = 'expandApp',
  GetAppContext = 'getAppContext',

  //
  SetVideoFilter = 'setVideoFilter',
  DeleteVideoFilter = 'deleteVideoFilter',

  GetVideoSettings = 'getVideoSettings',
  SetVideoSettings = 'setVideoSettings',

  // In-Client OAuth
  Authorize = 'authorize',
  OnAuthorized = 'onAuthorized',

  //
  SetScreenName = 'setScreenName',
}

export enum EventZoomSdkApiMethod {
  OnMessage = 'onMessage',
  OnMyReaction = 'onMyReaction',
  OnMyMediaChange = 'onMyMediaChange',
  OnExpandApp = 'onExpandApp',
  OnSendAppInvitation = 'onSendAppInvitation',
  OnShareApp = 'onShareApp',
  OnParticipantChange = 'onParticipantChange',
  OnRunningContextChange = 'onRunningContextChange',
  OnAuthorized = 'onAuthorized',
  OnMyUserContextChange = 'onMyUserContextChange',
}

export enum FutureEventZoomSdkApiMethod {
  OnRenderedAppOpened = 'onRenderedAppOpened',
}

/**
 * The Zoom SDK API methods that need to be approved. Allows unapproved methods
 * to be pushed and unused. Keep for methods added in the future.
 */
export enum FutureZoomSdkApiMethod {
  GetVideoState = 'getVideoState',
  SetVideoState = 'setVideoState',
  DrawWebView = 'drawWebView',
  ClearWebView = 'clearWebView',
  PromptAuthorize = 'promptAuthorize',
}

/**
 * Includes approved and unapproved (i.e., "future") Zoom SDK API methods used by Warmly
 */
export type ZoomSdkApiMethodAll =
  | ZoomSdkApiMethod
  | EventZoomSdkApiMethod
  | FutureZoomSdkApiMethod
  | FutureEventZoomSdkApiMethod;

/**
 * Generic responses from Zoom SDK
 */

type ZoomGeneralMessage = 'Success' | 'Failure';

interface ZoomGeneralMessageResponse {
  message?: ZoomGeneralMessage;
  code?: string;
  error?: ZoomApiError;
}

interface ZoomDrawImageResult {
  imageId: string;
}
interface ZoomSdkSupportApiResponse {
  supportedApis: ZoomSdkApiMethodAll[];
}

type ViewMode = 'immersive' | 'camera';

interface ZoomDrawImageResult {
  imageId: string;
}

type ZoomShape = 'rectangle' | 'person' | 'standard' | 'circle' | 'square' | 'verticalRectangle';

type ZoomRole = 'host' | 'cohost' | 'attendee' | 'panelist';

export interface ZoomParticipant {
  screenName: string;
  participantUUID: string;
  role: ZoomRole;
}

export interface ZoomUserContext {
  screenName: string;
  role: ZoomRole;
  participantUUID: string;
  status: 'unauthenticated' | 'authenticated' | 'authorized';
}

interface DrawParticipantOptions {
  participantUUID?: ZoomParticipant['participantUUID'];
  x: string;
  y: string;
  width: string;
  height: string;
  zIndex: string;
  cutout?: ZoomShape;
  cameraModeMirroring?: boolean;
}

interface GetVideoSettingsResponse {
  hdVideo: boolean;
  displayParticipantNames: boolean;
  hideNonVideoParticipants: boolean;
  mirrorMyVideo: boolean;
  originalRatio: boolean;
  cameraDevices: { cameraDeviceId: string; cameraDeviceName: string; isSelected: boolean }[];
}

interface SetVideoSettingsOptions {
  hdVideo?: boolean;
  displayParticipantNames?: boolean;
  hideNonVideoParticipants?: boolean;
  mirrorMyVideo?: boolean;
  originalRatio?: boolean;
  cameraDeviceId?: string;
}

interface ClearParticipantOptions {
  participantUUID?: ZoomParticipant['participantUUID'];
}

interface DrawWebViewOptions {
  x: string;
  y: string;
  width: string;
  height: string;
  zIndex: string;
}

interface DrawImageOptions {
  imageData: ImageData;
  x: string;
  y: string;
  zIndex: string;
}

export interface ZoomMessage<T> {
  payload?: T;
  timestamp?: Date;
}

export interface ZoomReactionEvent extends Record<string, unknown> {
  timestamp: Date;
  type: 'clap' | 'joy' | 'tada' | 'thumbsup' | 'heart' | 'openmouth' | 'other';
  unicode: string;
}

export interface ZoomParticipantChangeEvent {
  timestamp: Date;
  participants: ZoomParticipant[];
}

export interface ZoomRenderedAppOpenedEvent {
  byInvitation: 'false' | 'true';
  defaultCutout: ZoomShape;
  timestamp: Date;
  view: ViewMode;
}

export interface ZoomMeetingContext {
  meetingID: string;
  meetingTopic: string;
}

export interface ZoomGetAppContextResponse {
  context: string;
}

export type ZoomPersistence = 'save' | 'app' | 'meeting';

/**
 * Zoom API Status Codes
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/status-codes}
 */
export enum ZoomApiStatusCodes {
  Success = '0',
  /**
   * The Zoom client encountered an error while processing the request.
   */
  UnspecifiedError = '10001',
  /**
   * Authorization not found, please call zoomSdk.config to authorize the use of APIs before calling them.
   */
  AuthNotFound = '10004',
  /**
   * Authorization expired or invalid, please call zoomSdk.config to renew.[Error from web: xxxx]
   */
  AuthExpired = '10005',
  /**
   * The Zoom client failed to send a request to the server.
   */
  RequestFailed = '10006',
  UserDenied = '10017',

  /**
   * Your device can't support to set virtual background in current settings, please have a check.
   */
  VirtualBackgroundNotSupported = '10030',
  /**
   * Failed to post message to connect app.
   * For API postMessage
   */
  PostMessageFailed = '10038',
  /**
   * 	Failed to post message because it's not connected app.
   */
  PostMessageConnectAppFailed = '10041',
  /**
   * No Permission for this API. [code: xxx, reason: xxxx]
   */
  PermissionDenied = '10047',
  /**
   * The app needs to call render Js-api first
   */
  CallRenderJsApiFirst = '10062',
  /**
   * The app already called render Js-api first.
   */
  AlreadyCalledRenderJsApi = '10063',
  /**
   * Video is already close
   */
  VideoAlreadyClosed = '10080',

  VideoFilterFeatureDisabled = '10198',
}

/**
 * Error interface for Zoom API
 */
interface ZoomApiError {
  /**
   * Status code.
   */
  code: ZoomApiStatusCodes;
  /**
   * Error message.
   */
  message: string;
}

type DetectBrowserWindow = typeof window & {
  android?: unknown;
  chrome?: {
    webview?: unknown;
  };
  webkit?: unknown;
};

export type OnMyUserContextChangeEvent = { timestamp: number; role: ZoomRole; screenName: string };

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
const getZoomSdk = () => {
  return window.zoomSdk;
};

/**
 * Whether the app is loaded in the Zoom client
 * Found by checking the user agent:
 * "Mozilla/5.0 ZoomWebKit/537.36 (KHTML, like Gecko) ZoomApps/1.0"
 * mockZoomSdk localStorage used by e2e tests.
 */
export const isZoom =
  window.localStorage.getItem('mockZoomSdk') === 'true' || navigator.userAgent.toLowerCase().includes('zoomapps');

let ignoreZoomRequests = false;

/**
 * The enum values of the live Zoom SDK features
 * This list gets updated during initialization if there's an unapproved SDK method
 */
let liveFeatures = [
  ...Object.values(ZoomSdkApiMethod),
  ...Object.values(EventZoomSdkApiMethod),
  ...Object.values(FutureZoomSdkApiMethod),
  ...Object.values(FutureEventZoomSdkApiMethod),
];

/**
 * The native client error occurs sometimes when calling a Zoom SDK method (see
 * the SDK JS code). Zoom mentioned that the error should be avoided if the app
 * ensures config is called when the config expires after 2 hours. We already call
 * config if/when an SDK method fails, and other Zoom App developers mentioned
 * that this error can occur. We encountered this error internally, and we verified
 * that the error wasn't noticeable, so we can ignore for now.
 * Example: {@link https://sentry.io/organizations/warmly/issues/2633041497/events/19d3a8c5cb954f86ada4ec07bc9730e6/?project=5588495}
 */
const ZOOM_CONFIG_NATIVE_CLIENT_ERROR_STRING = 'The native client did not provide a response to the API call';

/**
 * This is part of the error message for the Zoom SDK's authorization expired or invalid error.
 * Example: {@link https://sentry.io/organizations/warmly/issues/2504156133/events/3c574851b89c4a97a3c0f8fbca34d79b/?project=5588495}
 */
const ZOOM_AUTH_EXPIRED_OR_INVALID_ERROR_STRING = 'Authorization expired or invalid';

/**
 * This is part of the error message for the Zoom SDK's API request exceeded server rate limit error.
 * Example: {@link https://sentry.io/organizations/warmly/issues/2504156133/events/19c9ee4968bf4fdf82d437949fe24d9e/?project=5588495}
 */
const ZOOM_API_REQUEST_EXCEEDED_ERROR_STRING = 'The API request has exceeded the server rate limit';

const RETRY_ZOOM_STATUS_CODES = [
  ZoomApiStatusCodes.AuthNotFound,
  ZoomApiStatusCodes.AuthExpired,
  ZoomApiStatusCodes.RequestFailed,
];

const ZOOM_PROMPT_TIMEOUT = 120000;

/* -------------------------------------------------------------------------- */
/*                                  FUNCTIONS                                 */
/* -------------------------------------------------------------------------- */
export const detectBrowser = () => {
  if ((window as DetectBrowserWindow).android) {
    return 'android';
  }
  if ((window as DetectBrowserWindow).chrome?.webview) {
    return 'chrome';
  }
  if ((window as DetectBrowserWindow).webkit) {
    return 'webkit';
  }
  if (!isZoom) {
    return 'web';
  }
  return 'unknown';
};

/**
 * Initialize the Zoom SDK
 * @param shouldFallback Whether initialization should fallback and try again without unapproved SDK methods
 * @returns Whether initialization was successful
 */
export const initializeZoomSdk = async (
  shouldFallback = true
): Promise<{ config: ZoomSdkConfig; supportedApis: ZoomSdkApiMethodAll[] | undefined } | undefined> => {
  logDebug('⚙️ initializeZoomSdk');

  try {
    const zoomSdk = getZoomSdk();
    if (typeof zoomSdk?.config === 'function') {
      let config;
      try {
        config = await zoomSdk?.config({
          version: '0.16.15',
          popoutSize: { width: 500, height: 750 },
          capabilities: liveFeatures,
        });
      } catch (ex) {
        if (detectBrowser() === 'web') {
          ignoreZoomRequests = true;
        } else {
          logError(ex);
        }
        return;
      }

      const supportedApis = await getSupportedJsApis();

      return {
        config,
        supportedApis,
      };
    }
  } catch (e) {
    // For "Configuration was denied" error messages, we do not need
    // to explicitly record it in Sentry, as we know that some users will probably
    // have failed to properly enable Zoom Apps
    if ((e as Error).message?.includes('Configuration was denied')) {
      if (shouldFallback) {
        // Run initialization again without the unapproved SDK methods
        liveFeatures = Object.values(ZoomSdkApiMethod);
        return initializeZoomSdk(false);
      }
    } else {
      logError(e as Error, {
        zoomSdkError: 'Encountered error configuring Zoom SDK',
      });
    }
  }
};

const runZoomSdkMethod = <T>(_zoomSdk: zoomSdk, method: ZoomSdkApiMethodAll, ...args: unknown[]): Promise<T> => {
  if (typeof _zoomSdk[method] === 'function') return _zoomSdk[method](...args);
  return _zoomSdk.callZoomApi(method, ...args) as Promise<T>;
};
/**
 * Run Zoom SDK method
 *
 * Has two retries - if the first attempt fails,
 * will try to re-initialize the SDK and try again.
 *
 * If the first retry fails, it will wait 1 second and try to re-initialize the SDK
 * and run the method again
 * @param method The name of the method
 * @param args Optional arguments to pass to the method
 * @returns The return value of the method
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const runMethod = async <T>(method: ZoomSdkApiMethodAll, ...args: unknown[]): Promise<T> => {
  const zoomSdk = getZoomSdk();
  if (!zoomSdk || ignoreZoomRequests) {
    logDebug('⚙️ zoomSdk does not exist or is ignored', { method, zoomSdk, ignoreZoomRequests });
    return { err: { message: 'Zoom SDK not initialized' } } as unknown as T;
  }
  try {
    logDebug('⚙️ zoomSdk runMethod', method, args);
    // First try running the method without initializing
    return await runZoomSdkMethod(zoomSdk, method, ...args);
  } catch (e) {
    const handleError = (error: ZoomApiError) => {
      if (
        error.code === ZoomApiStatusCodes.PostMessageFailed ||
        error.code === ZoomApiStatusCodes.PostMessageConnectAppFailed
      ) {
        /**
         * Don't re-throw or log the postMessage errors.
         */
        return { error } as unknown as T;
      }

      if (error.code === ZoomApiStatusCodes.UserDenied) {
        return { message: 'Failure', error } as unknown as T;
      }

      if (
        error.code === ZoomApiStatusCodes.AlreadyCalledRenderJsApi &&
        method === ZoomSdkApiMethod.RunRenderingContext
      ) {
        /**
         * Assume success if already called the rendering context
         */
        return { message: 'Success' } as unknown as T;
      }

      if (error.code === ZoomApiStatusCodes.CallRenderJsApiFirst && method === ZoomSdkApiMethod.CloseRenderingContext) {
        /**
         * Assume success if trying to close rendering context but it is not running
         */
        return { message: 'Success' } as unknown as T;
      }

      if (error.code === ZoomApiStatusCodes.VideoAlreadyClosed && method === ZoomSdkApiMethod.RunRenderingContext) {
        /**
         * Assume success if trying to run rendering context but it is already closed
         */
        return { message: 'Success' } as unknown as T;
      }

      if (error.code === ZoomApiStatusCodes.UnspecifiedError && method === ZoomSdkApiMethod.RunRenderingContext) {
        /**
         * Don't re-throw or log unspecified errors to the rendering context API.
         */
        return { error } as unknown as T;
      }

      if (error.code === ZoomApiStatusCodes.VirtualBackgroundNotSupported) {
        /**
         * Don't re-throw or log Virtual Background not supported errors.
         */
        return { error } as unknown as T;
      }

      if (error.message?.includes(ZOOM_AUTH_EXPIRED_OR_INVALID_ERROR_STRING)) {
        /**
         * Do not re-throw the "Authorization expired or invalid, please call zoomSdk.config to renew" error
         * This error occurs often due to zoomSdk's buggy issues, so we just record a posthog event instead
         */
        return { error } as unknown as T;
      }

      if (error.message?.includes(ZOOM_API_REQUEST_EXCEEDED_ERROR_STRING)) {
        /**
         * Do not re-throw the rate limit error, which occurs often due to zoomSdk's apparent rate limit
         */
        return { error } as unknown as T;
      }

      if (error.message?.includes(ZOOM_CONFIG_NATIVE_CLIENT_ERROR_STRING)) {
        return { error } as unknown as T;
      }

      let zoomSdkError = `Encountered error calling Zoom SDK ${method} with following args: ${JSON.stringify(args)}`;

      if (
        method === ZoomSdkApiMethod.SetVideoFilter ||
        method === ZoomSdkApiMethod.SetVirtualForeground ||
        method === ZoomSdkApiMethod.SetVirtualBackground
      ) {
        zoomSdkError = `Encountered error calling Zoom SDK ${method}`;
      }

      logError(error, {
        zoomSdkError,
        extra: { zoomStatusCode: error.code },
      });

      return { error } as unknown as T;
    };

    /**
     * Block below enables retry for selected error codes.
     * See codes: {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/status-codes}
     */
    if (!RETRY_ZOOM_STATUS_CODES.includes((e as ZoomApiError).code)) {
      // If the app has not been re-authorized after adding the new permissions fall back to success.
      if (
        (e as ZoomApiError).code === ZoomApiStatusCodes.PermissionDenied &&
        method === FutureZoomSdkApiMethod.GetVideoState
      ) {
        return { video: true } as unknown as T;
      }
      return handleError(e as ZoomApiError);
    }

    try {
      // If the first attempt errors, wait one second before trying to initialize sdk and trying again
      await wait(1000);
      const initialized = await initializeZoomSdk();
      if (initialized) {
        return await runZoomSdkMethod(zoomSdk, method, ...args);
      } else {
        // If the second attempt initialization fails,
        // wait 2 sec before trying to initialize sdk again
        await wait(2000);
        const nextInitialized = await initializeZoomSdk();
        if (nextInitialized) {
          return await runZoomSdkMethod(zoomSdk, method, ...args);
        }
      }
    } catch (err) {
      return handleError(err as ZoomApiError);
    }
    return { error: e } as unknown as T;
  }
};

/**
 * Returns an array of APIs and events supported by the current running context
 *
 * {@link https://marketplace.zoom.us/docs/zoom-apps/js-sdk/reference/#getSupportedJsApis}
 * @returns Array of APIs and events supported by the current running context
 */
const getSupportedJsApis = async (): Promise<ZoomSdkSupportApiResponse['supportedApis'] | undefined> => {
  const supportedApis = await runMethod<ZoomSdkSupportApiResponse>(ZoomSdkApiMethod.GetSupportedJsApis);
  return supportedApis?.supportedApis;
};

/**
 * Returns whether the current Zoom user is in a meeting or webinar
 * @returns Whether in a meeting
 */
export const getIsInMeeting = async (): Promise<boolean> => {
  const runningContext = await runMethod<ZoomRunningContext>(ZoomSdkApiMethod.GetRunningContext);
  return runningContext === ZoomRunningContext.InMeeting || runningContext === ZoomRunningContext.InWebinar;
};

/**
 * Returns the current Zoom meeting UUID
 * @returns The UUID of the meeting
 */
export const getMeetingUuid = async (): Promise<string | undefined> => {
  const result = await runMethod<MeetingUuidResponse>(ZoomSdkApiMethod.GetMeetingUUID);
  return result?.meetingUUID;
};

/**
 * Sets the virtual background when in an active Zoom meeting
 * @param imageData The ImageData generated by canvas
 * @returns Whether the background was successfully set
 */
export const setVirtualBackground = async (imageData?: ImageData, blur?: boolean): Promise<boolean> => {
  const response = await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.SetVirtualBackground, {
    imageData,
    blur,
  });

  return response?.message === 'Success';
};

/**
 * Removes the virtual background when in an active Zoom meeting
 */
export const removeVirtualBackground = async (): Promise<void> => {
  await runMethod(ZoomSdkApiMethod.RemoveVirtualBackground);
};

/**
 * Sets the video filter when in an active Zoom meeting
 * @param imageData The ImageData generated by canvas
 * @param applyToAllFutureMeetings Whether to apply the filter to all future meetings
 * @returns Whether the filter was successfully set
 */
export const setVideoFilter = async (
  imageData: ImageData,
  applyToAllFutureMeetings?: boolean
): Promise<ZoomGeneralMessageResponse> => {
  // return {
  //   message: 'Failure',
  //   error: { code: ZoomApiStatusCodes.VideoFilterFeatureDisabled, message: '' },
  // };
  return await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.SetVideoFilter, {
    imageData,
    applyToAllFutureMeetings,
  });
};

/**
 * Removes the video filter when in an active Zoom meeting
 */
export const deleteVideoFilter = async (): Promise<ZoomGeneralMessageResponse> => {
  return await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.DeleteVideoFilter);
};

/**
 * Set the screen name of the user
 */
export const setScreenName = async (options: { screenName: string }): Promise<ZoomGeneralMessageResponse> => {
  return await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.SetScreenName, options);
};

/**
 * Sets the virtual foreground when in an active Zoom meeting
 * @param imageData The ImageData generated by canvas (limited to 15MB after encoding. An aspect ratio of 16:9 and image resolution of 1080p are recommended)
 * @param ZoomPersistence The persistence of the foreground image
 * @returns Whether the foreground was successfully set
 */
export const setVirtualForeground = async (
  imageData: ImageData,
  persistence: ZoomPersistence = 'save'
): Promise<ZoomGeneralMessageResponse> => {
  return await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.SetVirtualForeground, {
    imageData,
    persistence,
  });
};
/**
 * Removes the virtual foreground when in an active Zoom meeting
 */
export const removeVirtualForeground = async (): Promise<boolean> => {
  const response = await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.RemoveVirtualForeground);
  return response?.message === 'Success';
};

/**
 * Open URL using the system browser
 * @params The URL to open
 */
export const openUrl = async (url: string): Promise<void> => {
  await runMethod(ZoomSdkApiMethod.OpenUrl, { url });
};

/**
 * Runs callback function when the user changes video settings
 * @param callback The callback function
 */
export const onMyMediaChange = async (callback: (event: { media: ZoomSdkMedia }) => void) => {
  await runMethod(EventZoomSdkApiMethod.OnMyMediaChange, callback);
};

/**
 * Runs callback function when the user changes video settings
 * @param callback The callback function
 */
export const onExpandAppChange = async (callback: (event: { action: ExpandAction }) => void) => {
  await runMethod(EventZoomSdkApiMethod.OnExpandApp, callback);
};

/**
 * Run callback function when user clicks the invite icon from the Zoom App sidebar during a meeting
 * @param callback The callback function that is run upon user clicking invite icon within Zoom meeting
 */
export const onSendAppInvitation = async (callback: () => void): Promise<void> => {
  await runMethod(EventZoomSdkApiMethod.OnSendAppInvitation, callback);
};

/**
 * Run callback function when user clicks the share icon from the Zoom App sidebar during a meeting
 * @param callback The callback function that is run upon user clicking share icon within Zoom meeting
 */
export const onShareApp = async (callback: () => void): Promise<void> => {
  await runMethod(EventZoomSdkApiMethod.OnShareApp, callback);
};

/**
 * Prompts the user to mirror/un-mirror their video
 * @param mirrorMyVideo The boolean value for whether video/virtual background is mirrored
 * @returns Whether setting the mirror effect was successful
 */
export const onSetVideoMirrorEffect = async (mirrorMyVideo: boolean) => {
  const response = await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.SetVideoMirrorEffect, {
    mirrorMyVideo,
  });
  return response?.message === 'Success';
};

/**
 * Gets user's video settings.
 * @returns Current video settings for the user.
 */
export const getVideoSettings = async () => {
  return runMethod<GetVideoSettingsResponse>(ZoomSdkApiMethod.GetVideoSettings);
};

/**
 * Prompts the user to turn on/off hd video
 * @param hdVideo The boolean value for whether to turn HD video on/off
 * @returns Whether setting the video settings was successful
 */
export const setVideoSettings = async (settings: SetVideoSettingsOptions) => {
  const response = await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.SetVideoSettings, settings);
  return response?.message === 'Success';
};

/**
 * Runs callback function when the user is authorized via in-client oauth
 * @param callback The callback function
 */
export const onAuthorized = async (callback: (event: ZoomOnAuthorizedEvent) => void) => {
  await runMethod(ZoomSdkApiMethod.OnAuthorized, callback);
};

/**
 * Calls the `connect` API, which can only be called in meeting.
 * Allows the App to communicate with the instance of the app running on the main client.
 *
 * For Warmly, the useful part of `connect` is that once connected, Warmly will auto pop-up in a new window
 * if a user leaves a meeting or the meeting ends
 */
export const zoomSdkConnect = async (): Promise<void> => {
  if (liveFeatures.indexOf(ZoomSdkApiMethod.Connect) === -1) {
    return;
  }
  const isInMeeting = await getIsInMeeting();
  if (isInMeeting) {
    await runMethod(ZoomSdkApiMethod.Connect);
  }
};

/**
 * Initiates an OAuth authorization request from the Zoom Client - Zoom Apps tab - to the Zoom marketplace.
 * @param codeChallenge: required string provided by developer
 * @param state: optional string provided by developer
 */
export const authorize = async (codeChallenge: string, state?: string): Promise<boolean> => {
  const response = await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.Authorize, {
    codeChallenge,
    state,
  });
  return response?.message === 'Success';
};

/**
 * Starts a new meeting or joins an existing meeting and launches the app in the meeting
 * @param joinURL URL of the meeting to join
 */
export const launchAppInMeeting = async (options?: {
  joinURL?: string;
  actions?: DeepLinkActions;
}): Promise<boolean> => {
  let actionString = '';
  if (options?.actions) {
    const { query, ...actions } = options.actions;
    actionString = Object.keys(actions)
      .map((key) => `${key}:${actions?.[key] || ''}`)
      .join(',');

    if (query && Object.keys(query).length) actionString = actionString + ',' + convertQuery(query);
  }

  if (actionString) window.localStorage.setItem(LocalStorageItem.DeepLinkAction, actionString);
  const response = await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.LaunchAppInMeeting, {
    joinURL: options?.joinURL,
  });

  const success = response?.message === 'Success';

  if (!success) window.localStorage.removeItem(LocalStorageItem.DeepLinkAction);

  return success;
};

/**
 * Gets the on or off status of the primary video.
 * @returns boolean Whether the video is on or off.
 */
export const getVideoState = async () => {
  return runMethod<{ video: boolean }>(FutureZoomSdkApiMethod.GetVideoState);
};

/**
 * Prompts the user to turn on/off their video
 * @param video Whether to turn on the video
 * @returns Whether the video was successfully toggled
 */
export const setVideoState = async (video = true) => {
  const response = await runMethod<ZoomGeneralMessageResponse>(
    FutureZoomSdkApiMethod.SetVideoState,
    { video },
    ZOOM_PROMPT_TIMEOUT
  );
  return response?.message === 'Success';
};

/**
 * Changes the app's rendering context from the meeting sidebar to the main meeting window. You define the behavior with the specified view option.
 * Only meeting hosts can invoke an immersive runRenderingContext.
 * To transition other meeting participants to an immersive view, the meeting host’s app must use the sendAppInvitationToAllParticipants API.
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/apis/#runrenderingcontext runRenderingContext}
 * @param view Use "immersive" to fill the entire meeting canvas. Use "myVideo" to affect only the user's video stream.
 * @param defaultCutout Available shapes: rectangle or person.
 * @returns None on success. If the ‘then’ clause is executed, it indicates that the rendering context was created. An Error message on failure.
 * Warning: Only one app instance can create an "immersive" rendering context at a time. If another attempts to, it will fail with an error.
 */
export const runRenderingContext = async (view: ViewMode, defaultCutout?: ZoomShape) => {
  const response = await runMethod<ZoomGeneralMessageResponse>(
    ZoomSdkApiMethod.RunRenderingContext,
    { view, defaultCutout },
    ZOOM_PROMPT_TIMEOUT
  );
  return response?.message === 'Success';
};

/**
 * Closes the rendering context
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/apis/#closerenderingcontext closeRenderingContext}
 * @returns Returns the rendering context of the app to the sidebar.
 */
export const closeRenderingContext = async () => {
  const response = await runMethod<ZoomGeneralMessageResponse>(ZoomSdkApiMethod.CloseRenderingContext);
  return response?.message === 'Success';
};

/**
 * Returns the context in which the Zoom App is launched: inMeeting, inWebinar, inMainClient, inPhone, inCollaborate.
 * This is useful for controlling your app's behavior based on the presence of a single user or multiple collaborative users.
 * {@link https://marketplace.zoom.us/docs/zoom-apps/js-sdk/reference/#getRunningContext getRunningContext}
 */
export const getRunningContext = async () => {
  const result = await runMethod<{ context: ZoomRunningContext }>(ZoomSdkApiMethod.GetRunningContext);

  const { context } = result || { context: ZoomRunningContext.None };

  return context;
};

/**
 * This API returns app context token that contains signed app context data for secure backend validation.
 * {@link https://marketplace.zoom.us/docs/zoom-apps/js-sdk/reference/#getAppContext getAppContext}
 */
export const getAppContext = async () => {
  const result = await runMethod<ZoomGetAppContextResponse>(ZoomSdkApiMethod.GetAppContext);

  const { context } = result || { context: null };
  return context;
};

/**
 * Draws an image in the rendering context's canvas.
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/apis/#drawimage drawImage}
 * @param imageData A standard JavaScript imageData object, containing width, height, and pixel bytes.
 * The image width and height should be in device pixels (scaled by window.devicePixelRatio) to accommodate HiDPI devices.
 * @param x 'xxxpx' The horizontal position within the App’s window specified as a CSS value. (Default: “0px”)
 * @param y 'xxxpx' The vertical position within the App’s window specified as a CSS value. (Default: “0px”)
 * @param zIndex 'xxx' The relative z-ordering of the item (Default: 1).
 * @returns On success, this returns a dictionary with an ‘imageId’ field that uniquely identifies the image. On error,
 *  the catch() error contains errorCode and errorMessage properties.
 */
export const drawImage = async (opts: DrawImageOptions) => {
  return runMethod<ZoomDrawImageResult>(ZoomSdkApiMethod.DrawImage, opts);
};

export const expandApp = async () => {
  const action: ExpandAction = 'expand';
  return runMethod<GeneralMessageResponse>(ZoomSdkApiMethod.ExpandApp, { action });
};

export const collapseApp = async () => {
  const action: ExpandAction = 'collapse';
  return runMethod<GeneralMessageResponse>(ZoomSdkApiMethod.ExpandApp, { action });
};

/**
 * Get information of the participants in the current meeting. Note that for breakout rooms,
 *  the participants in the current room will be returned, not those of the parent meeting.
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/apis/#getmeetingparticipants getMeetingParticipants}
 */
export const getMeetingParticipants = async () => {
  return runMethod<{ participants: ZoomParticipant[] }>(ZoomSdkApiMethod.GetMeetingParticipants);
};

/**
 * Draws participant videos and static images on top of the background.
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/apis/#drawparticipant drawParticipant}
 * @param participantUUID Meeting-specific participant identifier.
 * @param x The horizontal position within the App’s window specified as a CSS value. (Default: “0px”)
 * @param y The vertical position within the App’s window specified as a CSS value. (Default: “0px”)
 * @param width The width of the participant’s video (aspect ratio will be maintained). (Default: “100%”)
 * @param height 	The height of the participant’s video (aspect ratio will be maintained). (Default: “100%”)
 * @param zIndex The relative z-ordering of the item (Default: 1).
 * @param cutout Available shapes: rectangle or person.
 * @returns
 */
export const drawParticipant = async (opts: DrawParticipantOptions) => {
  return runMethod(ZoomSdkApiMethod.DrawParticipant, opts);
};

/**
 * Clears the content set by drawParticipants
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/apis#clearparticipant clearParticipant}
 * @param participantUUID Meeting-specific participant identifier.
 * @returns
 */

export const clearParticipant = async (opts: ClearParticipantOptions) => {
  return runMethod(ZoomSdkApiMethod.ClearParticipant, opts);
};

/**
 * This API endpoint is only available in meetings. It returns basic information about the meeting participant while in a meeting.
 * @returns zoom user context
 */
export const getUserContext = async () => {
  return runMethod<ZoomUserContext>(ZoomSdkApiMethod.GetUserContext);
};

/**
 * Send a message with the current state the mirrored app. The structure of the payload depends on the needs of the app.
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/apis/#postmessagepayload}
 * @param eventInfo The message payload.
 * @returns unknown
 */
export const postZoomMessage = async <T>(eventInfo: T) => {
  if (DEBUG_LOGGING_ENABLED) {
    // eslint-disable-next-line no-console
    console.info({ postZoomMessage: eventInfo });
  }
  return runMethod(ZoomSdkApiMethod.PostMessage, eventInfo);
};

export const parseZoomMessage = <T>(callback: (data: { payload?: T; timestamp?: Date }) => void) => {
  return (message: { payload?: string; timestamp?: Date }) => {
    try {
      if (!message?.payload) {
        return;
      }

      const payload = JSON.parse(message?.payload) as T;
      if (typeof callback === 'function') {
        callback({ payload, timestamp: message.timestamp });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
};

/**
 * Receive a sent message from the mirrored app. The structure of the payload depends on the needs of the app.
 * {@link https://marketplace.zoom.us/docs/beta-docs/zoom-apps/js-sdk/events/#onmessage}
 * @param callback onMessage callback function
 * @returns void
 */
export const onZoomMessage = (callback: ReturnType<typeof parseZoomMessage>) => {
  return runMethod(EventZoomSdkApiMethod.OnMessage, callback);
};

/**
 * Removes zoom callback listener.
 * @param event {@link EventZoomSdkApiMethod} The event name
 * @param callback callback function
 */
export const removeZoomEventListener = (
  callback: (...args: never[]) => void,
  event: EventZoomSdkApiMethod | FutureEventZoomSdkApiMethod = EventZoomSdkApiMethod.OnMessage
) => {
  getZoomSdk()?.removeEventListener(event, callback);
};

export const onMyReaction = (callback: (event: ZoomReactionEvent) => void) => {
  return runMethod(EventZoomSdkApiMethod.OnMyReaction, callback);
};

export const sendAppInvitationToAllParticipants = () => {
  return runMethod(ZoomSdkApiMethod.SendAppInvitationToAllParticipants);
};

export const getMeetingContext = async () => {
  return runMethod<ZoomMeetingContext>(ZoomSdkApiMethod.GetMeetingContext);
};

export const onParticipantChange = (callback: (event: ZoomParticipantChangeEvent) => void) => {
  return runMethod(EventZoomSdkApiMethod.OnParticipantChange, callback);
};

export const onRunningContextChange = async (callback: (event: unknown) => void): Promise<void> => {
  await runMethod(EventZoomSdkApiMethod.OnRunningContextChange, callback);
};

export const drawWebView = async (opts: DrawWebViewOptions) => {
  return runMethod(FutureZoomSdkApiMethod.DrawWebView, opts);
};

export const clearWebView = async (webviewId: string) => {
  return runMethod(FutureZoomSdkApiMethod.ClearWebView, { webviewId });
};

export const onRenderedAppOpened = async (callback: (event: ZoomRenderedAppOpenedEvent) => void): Promise<void> => {
  await runMethod(FutureEventZoomSdkApiMethod.OnRenderedAppOpened, callback);
};

/**
 * Runs callback function when the user changes context
 * @param callback The callback function
 */
export const onMyUserContextChange = async (callback: (event: OnMyUserContextChangeEvent) => void) => {
  await runMethod(EventZoomSdkApiMethod.OnMyUserContextChange, callback);
};

export const promptAuthorize = async () => {
  await runMethod(FutureZoomSdkApiMethod.PromptAuthorize);
};
