import { convertQuery } from 'src/hooks/useDeepLink';
import { isZoom, openUrl as zoomSdkOpenUrl } from 'src/modules/zoomSdk';
import {
  NavigationPath,
  QueryParam,
  ZOOM_DEEPLINK_URL,
  ZOOM_FULL_PUBLISHABLE_URL,
  zoomApiUrl,
} from 'src/utils/constants';
import { openPopup, OpenUrl } from 'src/utils/popup/popup';

export const getDateTimeStringFromTimestampString = (timestampString?: string | null): string | undefined => {
  if (!timestampString?.trim()) {
    return undefined;
  } else {
    return new Date(timestampString).toLocaleString();
  }
};

export const capitalizeFirstLetters = (string: string): string => {
  return string
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

/**
 * Remove everything except alphanumeric characters + whitespace and collapse multiple adjacent characters to single spaces
 * @param text The text from which remove non-alphanumeric characters
 */
export const removePunctuation = (text: string): string => {
  return text.replace(/[^\w\s]|_/g, '').replace(/\s+/g, ' ');
};

/**
 * Conforms a URL to start with https://
 * @param url The URL to add https:// to (if it doesn't already have it)
 */
export const conformUrlHttps = (url?: string | null): string | undefined | null => {
  if (!url) {
    return url;
  }
  return url.includes('localhost') ? url : `https://${url.replace(/^https?:\/\//i, '')}`;
};

/**
 * Opens a URL with configurations
 * @param params.url The URL to open
 * @param params.newWindow Whether to open in a new window (instead of a new tab). Default `true`
 * @param params.height Height of window (used w/ newWindow)
 * @param params.width Width of window (used w/ newWindow)
 * @param params.isResizable Whether new window is resizable (used w/ newWindow)
 * @param params.proxy Whether to proxy the url through our backend server. Default `true`
 * @param params.popup The async popup to use if needed
 * @param params.isDownload Whether to auto-close as download popup
 */
export const openUrl = ({
  url,
  newWindow = true,
  height,
  width,
  isResizable,
  proxy = true,
  popup,
  isDownload,
}: OpenUrl): void => {
  if (!url) {
    return;
  }

  const secureUrl = url.startsWith('mailto') ? url : conformUrlHttps(url);

  if (isZoom) {
    // We aren't able to whitelist all domains in our zoom app so we pass the url to open to our zoom backend which serves as a forward proxy server
    // to redirect the url. This works because our backend server domain is whitelisted
    const urlToOpen = proxy ? `${zoomApiUrl.proxy.forwardProxy}?url_to_redirect=${secureUrl}` : secureUrl;
    if (urlToOpen) {
      zoomSdkOpenUrl(urlToOpen);
    }
  } else {
    if (newWindow) {
      openPopup({ url: secureUrl, height, width, isResizable, popup, isDownload });
    } else {
      if (secureUrl) {
        window.location.href = secureUrl;
      }
    }
  }
};

export const authenticate = () => {
  let url = `${ZOOM_FULL_PUBLISHABLE_URL}?act=goto:/home`;

  url = url.replace('https://', '');

  openUrl({
    url: encodeURIComponent(url),
  });
};

/**
 * Handles the deeplink redirect from the web to the Zoom app
 */
export const goToZoom = (goTo?: NavigationPath, query?: Partial<Record<QueryParam, string>>) => {
  const url = `${ZOOM_FULL_PUBLISHABLE_URL}${goTo ? `?act=goto:${goTo}${query ? `,${convertQuery(query)}` : ''}` : ''}`;
  openUrl({
    url,
  });
};

/**
 * Handles the deeplink redirect from the web to the Zoom app
 */
export const startZoom = () => {
  openUrl({
    url: `${ZOOM_DEEPLINK_URL}`,
  });
};

/**
 * Creates a promise that resolves to undefined after a preset period of time
 *
 * This function relies on `setTimeout` and should not be relied on for 100% time accuracy
 * due to the way Javascript event loop works (i.e., the waiting time could exceed
 * the intended wait time if the event loop is held up by other tasks)
 * @param milliseconds Number of milliseconds until the promise resolves
 */
export const wait = (milliseconds: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

/**
 * Extracts twitter handle from the user's twitter url.
 * @param url Twitter url linking to the user's twitter profile
 * @returns twitter handle (lowercase) if found, null if not found or if the url is not a valid twitter profile url
 */
export const extractTwitterHandleFromTwitterUrl = (url: string | undefined | null): string | null => {
  if (!url?.trim()) {
    return null;
  }

  const match = url.match(/(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:#!\/)?@?([^/?\s]*)/);
  if (!match) {
    return null;
  }

  const [, twitterHandle] = match;

  if (twitterHandle?.trim()) {
    return twitterHandle.trim();
  } else {
    return null;
  }
};

/**
 * Gets the name initials used in the avatar circle
 * @param firstName The first name of the user
 * @param lastName The last name of the user
 * @param email The email of the user
 * @returns The initials of the user
 */
export const getNameInitials = (firstName?: string, lastName?: string, email?: string): string => {
  if (firstName || lastName) {
    const firstNameInitial = firstName ? firstName[0].toUpperCase() : '';
    const lastNameInitial = lastName ? lastName[0].toUpperCase() : '';
    return `${firstNameInitial}${lastNameInitial}`;
  } else if (email) {
    return email.substring(0, 2).toUpperCase() || '';
  } else {
    return '';
  }
};

/**
 * Takes an email address and returns the domain for the email in lower case
 * @param email The email from which to extract the domain
 * @returns The email's domain
 */
export const parseDomainFromEmail = (email: string): string => {
  if (email.split('@').length < 2) {
    return '';
  }
  return email.split('@')[1].toLowerCase();
};

/**
 * Returns the gmail history shared context url between zoom user and a participant
 * @param zoomUserEmail zoom user's email
 * @param participantEmail participant user's email
 * @returns the gmail history shared context url
 */
export const getUserGmailHistoryWithParticipantUrl = (zoomUserEmail: string, participantEmail: string): string => {
  return `https://mail.google.com/mail/?authuser=${zoomUserEmail}#search/${participantEmail}`;
};

/**
 * Returns the user's gmail url to compose a new message
 * @param zoomUserEmail The zoom user's email
 * @param to The receiver of the email
 * @param subject The subject of the email
 * @returns The user's gmail url to compose a new message
 */
export const getUserGmailComposeUrl = (zoomUserEmail: string, to: string, subject: string): string => {
  return `https://mail.google.com/mail/?view=cm&fs=1&to=${to}&authuser=${zoomUserEmail}&su=${subject}`;
};

/**
 * Returns an array without duplicated elements based on an elements value
 * @param arr array to dedupe
 * @param key key which to dedupe by
 * @returns the deduped array
 */
export const removeDuplicates = <T>(arr: T[], key: keyof T) => [
  ...new Map(arr.map((item) => [item[key], item])).values(),
];

/**
 * Checks whether a string contains any white space using Regex
 *
 * This function checks for any whitespace character including space, tab, carriage return and form feed
 *
 * Taken from: https://stackoverflow.com/questions/1731190/check-if-a-string-has-white-space
 * @param s The string to check for white space
 * @returns Whether the string contains any white space characters
 */
export const hasWhiteSpace = (s: string): boolean => {
  return /\s/.test(s);
};
