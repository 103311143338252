export enum EmailServiceProvider {
  Gmail = 'Gmail',
  SendGrid = 'SendGrid',
}

export enum EmailCategory {
  RunningLate = 'RUNNING_LATE',
  UserInvite = 'USER_INVITE',
  WhereAreYou = 'WHERE_ARE_YOU',
  ShareCustomBackground = 'SHARE_CUSTOM_BACKGROUND',
  KeepInTouchRequestSent = 'KEEP_IN_TOUCH_REQUEST_SENT',
  SendUserReferralInvite = 'SEND_USER_REFERRAL_INVITE',
  WarmlyFollowUp = 'WARMLY_FOLLOW_UP',
  CustomWidget = 'CUSTOM_WIDGET',
}

export enum EmailStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  Error = 'ERROR',
}
