/**
 * 🚨 This enum is also used in zoom-backend
 * Please ensure that they are in sync
 * All available fields:
 * {@link https://developers.google.com/maps/documentation/places/web-service/details#fields}
 */
export enum GooglePlacesDetailField {
  Name = 'name',
  AddressComponents = 'address_components',
  PlaceId = 'place_id',
  UtcOffsetMinutes = 'utc_offset_minutes',
  Geometry = 'geometry',
}
