/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import * as Sentry from '@sentry/react';
import { CaptureContext, Extras } from '@sentry/types';

/* --------------------------------- CUSTOM --------------------------------- */
import { isMobile } from 'src/modules/mobile';
import { isZoom } from 'src/modules/zoomSdk';
import {
  CURRENT_ENVIRONMENT,
  DEBUG_LOGGING_ENABLED,
  Environment,
  PosthogAppContext,
  SENTRY_DSN,
} from 'src/utils/constants';

export const initializeLogging = (): void => {
  if (CURRENT_ENVIRONMENT === Environment.Development) {
    // eslint-disable-next-line no-console
    console.log('Dev environment detected - errors are not reported to Sentry');
  } else {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: CURRENT_ENVIRONMENT,
      // Note that the SENTRY_RELEASE env variable is set in package.json, it is NOT in .env and therefore
      // is always undefined in dev environment
      release: process.env.REACT_APP_SENTRY_RELEASE,
      ignoreErrors: [
        /**
         * A very generic common error that could occur outside of our control
         * (e.g., LaunchDarkly or other external services). In vast majority of
         * the time, such errors do not impact the user. Therefore, we filter
         * these out so we don't get inundated by such error reports in Sentry.
         */
        'Network Error',
        /**
         * An error specific to LaunchDarkly. LD throws an error whenever it
         * has issues  with its subscription, but 99% of the time it doesn't
         * have any material impact.
         */
        'network error (Error)',
        /**
         * A timeout error (possibility occurs
         * due to user's wifi stopped working/slow internet). It does not have any
         * major impact on user (i.e., should auto-refresh after a while anyway).
         */
        'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
        /**
         * Similar to above
         */
        'timeout of 500000ms exceeded',
        /**
         * An error that occurs occasionally in the Zoom Client webview. There
         * isn't much context on the error and no visible issues in FullStory.
         */
        "Cannot read properties of undefined (reading 'postMessage')",
        /**
         * An error that occurs occasionally and can be safely ignored
         * @link https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
         */
        'ResizeObserver loop limit exceeded',
        /**
         * An error that occurs occasionally and can be safely ignored
         * @link https://blog.elantha.com/resizeobserver-loop-limit-exceeded
         */
        'ResizeObserver loop completed with undelivered notifications',
      ],
    });
  }
};

/**
 * Gets the user's app context (Web, Zoom, Mobile)
 * @returns The app context from which the user is interfacing with in the app (Web, Zoom, Mobile)
 */
export const getAppContext = (): string => {
  if (isZoom) {
    return PosthogAppContext.Zoom;
  } else if (isMobile) {
    return PosthogAppContext.Mobile;
  } else {
    return PosthogAppContext.Web;
  }
};

export const getOs = () => {
  let OSName = 'Unknown';
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (userAgent.indexOf('Windows') !== -1 || windowsPlatforms.includes(platform)) OSName = 'Windows';
  if (userAgent.indexOf('Mac') !== -1 || macosPlatforms.includes(platform) || iosPlatforms.includes(platform))
    OSName = 'Mac/iOS';
  if (userAgent.indexOf('X11') !== -1 || platform.indexOf('Linux') !== -1) OSName = 'UNIX';
  return OSName;
};

export const setLogUser = (user: Sentry.User): void => Sentry.setUser(user);

/**
 * Logs JS error to sentry
 * @param error The error to log
 * @param extra Extra data to be included in the Sentry error
 */
export const logError = (error: Error | unknown, extra?: Extras): void => {
  if (CURRENT_ENVIRONMENT === Environment.Development) {
    // eslint-disable-next-line no-console
    console.error(error, { extra });
  } else {
    const captureContext: CaptureContext = { extra };
    Sentry.captureException(error, captureContext);
    if (DEBUG_LOGGING_ENABLED) {
      // eslint-disable-next-line no-console
      console.error(error, { extra });
    }
  }
};
