import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { adaptV4Theme, createTheme } from '@mui/material';
import { CSSProperties } from 'react';

import { WarmlyColor } from 'src/utils/constants';

export const SIZES = {
  COLLAPSE_NAMETAG_BUILDER: 720,
  SMALL_SCREEN_MAX: 960,
  SIDEBAR: 112,
  BOTTOM_BAR: 60,
  PANEL_WIDTH: 320,
  MOBILE_SCREEN: 600,
  BUTTON_RADIUS: 16,
  NAMETAG_BUILDER_THUMBNAIL_HEIGHT: 90,
  NAMETAG_BUILDER_THUMBNAIL_WIDTH: 160,
};

export const COLORS = {
  GRAY_50: '#FAFAFA',
  GRAY_100: '#F5F5F5',
  GRAY_01: '#E0E0E0',
  GRAY_02: '#C0C0C0',
  GRAY_03: '#A1A1A1',
  GRAY_04: '#818181',
  GRAY_05: '#616161',
  GRAY_06: '#414141',
  GRAY_07: '#E5E5E5',
  GRAY_08: '#424242',
  GRAY_BORDER: '#F3F2F5',
  BRAND_WHITE: '#FFFFFF',

  /**
   * Note that BRAND_BLACK is actually a very dark purple
   */
  BRAND_BLACK: '#1B0438',
  BRAND_BG: '#F7F5FA',
  BRAND_BG_ACCENT: '#F7F7F7',
  BRAND_PRIMARY: '#741CE5',
  BRAND_PRIMARY_HOVER: '#7000FF',
  BRAND_SECONDARY_HOVER: 'rgba(129, 31, 255, 0.1)',
  BRAND_GRADIENT: 'linear-gradient(90deg, #005BD7 0%, #AA00FF 100%)',
  BRAND_RUST: '#BF2200',
  BRAND_RUST_TINT: '#F7D9D2',
  BRAND_BLUEBELL: '#2F80ED',
  BRAND_BLUEBELL_TINT: '#E7EEF8',
  BRAND_GOLDENROD: '#8A6B00',
  BRAND_GOLDENROD_TINT: '#FFF4CC',
  BRAND_FIR: '#117A00',
  BRAND_FIR_TINT: '#D3F5CE',
  BRAND_GULF: '#007A82',
  BRAND_GULF_TINT: '#E1F8FA',
  BRAND_ROYAL: '#741CE5',
  BRAND_ROYAL_TINT: '#E2CCFF',
  BRAND_BERRY: '#B5008D',
  BRAND_BERRY_TINT: '#FFCCF3',
  BRAND_CHARCOAL: '#6A6F73',
  SIDEBAR_BG: '#1B0438',
  BRAND_LIGHT_HOVER: '#C6A0EB',
  BRAND_LIGHT_BLUE: '#2D8CFF',
  BRAND_COMPANY_BUTTON: '#DAD1E6',
  BUTTON_BLUE: '#5A31F4',
  // Background Colors
  BAR_GRAY: '#F2F4F7',
  BAR_DARK_GRAY: '#475467',
};

export const CONFETTI_COLORS = [
  COLORS.BRAND_PRIMARY,
  COLORS.BRAND_LIGHT_HOVER,
  COLORS.BRAND_BLACK,
  COLORS.BRAND_BERRY,
  '#005BD7',
  '#AA00FF',
  COLORS.BRAND_BLUEBELL,
  COLORS.BRAND_ROYAL,
  COLORS.BRAND_LIGHT_BLUE,
  COLORS.BUTTON_BLUE,
];

export const CLASSES = {
  NO_WRAP: 'no-wrap',
  PURPLE_LINK: 'purple-link',
};

const styleOverrides = {
  html: {
    height: '100%',
    fontSize: '14px',
  },
  body: {
    height: '100%',
    backgroundColor: COLORS.BRAND_WHITE,
    // Prevents elements like MUI tooltips from causing the body scrollbar
    // to appear in the Zoom client
    overflow: 'hidden',

    // from https://mui.com/guides/migration-v4/#cssbaseline
    // fontSize: '0.875rem',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 500,
    letterSpacing: '0.03rem',
  },
  '#root': {
    height: '100%',
    overflowY: 'auto',
  },
  [`.${CLASSES.NO_WRAP}`]: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [`.${CLASSES.PURPLE_LINK}`]: {
    cursor: 'pointer',
    color: COLORS.BRAND_PRIMARY,
    textDecoration: 'underline',
  },
};

const adaptedTheme = adaptV4Theme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ...styleOverrides,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        lineHeight: '20px',
      },
      containedPrimary: {
        backgroundColor: COLORS.BRAND_PRIMARY,
        padding: '6px 10px',
        '&:hover': {
          backgroundColor: COLORS.BRAND_PRIMARY_HOVER,
        },
      },
      outlinedPrimary: {
        color: COLORS.BRAND_PRIMARY,
        borderColor: COLORS.BRAND_PRIMARY,
        '&:hover': {
          borderColor: COLORS.BRAND_PRIMARY_HOVER,
        },
      },
      text: {
        '&:hover': {
          backgroundColor: COLORS.BRAND_BG,
        },
      },
    },
    MuiTableRow: {
      root: {
        height: 'auto !important',
      },
    },
    MuiTableCell: {
      root: {
        height: 49,
      },
    },
    // Required for Safari rounding bug
    // https://github.com/mui-org/material-ui/issues/17142
    MuiGrid: {
      'spacing-xs-1': {
        width: 'auto',
      },
      'spacing-xs-2': {
        width: 'auto',
      },
      'spacing-xs-3': {
        width: 'auto',
      },
    },
    MuiAvatar: {
      img: {
        objectFit: 'contain',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
      tooltipPlacementTop: {
        margin: '4px 0',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': { color: COLORS.BRAND_PRIMARY_HOVER },
      },
    },
  },
  palette: {
    primary: { main: COLORS.BRAND_PRIMARY_HOVER, contrastText: WarmlyColor.WHITE },
    secondary: { main: WarmlyColor.SALMON, contrastText: WarmlyColor.WHITE },
    tonalOffset: {
      light: 0.85,
      dark: 0.4,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 872,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'inherit',
    fontSize: 14,
    h5: {
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: '32px',
      letterSpacing: '0.02rem',
      color: COLORS.BRAND_BLACK,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.02rem',
      color: COLORS.BRAND_BLACK,
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.02rem',
      color: COLORS.GRAY_06,
    },
    body1: {
      fontSize: '1.2rem',
      fontWeight: 400,
      letterSpacing: '0.02rem',
      color: COLORS.GRAY_05,
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.03rem',
      color: COLORS.GRAY_05,
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.03rem',
      color: COLORS.GRAY_05,
    },
    caption: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '18px',
      color: COLORS.GRAY_05,
    },
    // TODO: remove below once rebuild is complete (old typography)
    h1: {
      fontSize: '2.25rem',
      fontWeight: 600,
      color: WarmlyColor.BLACK,
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    subtitle1: {
      fontSize: '0.95rem',
      color: WarmlyColor.GRAY,
      fontWeight: 500,
    },
  },
});

const theme = createTheme({
  ...adaptedTheme,
  components: {
    ...adaptedTheme.components,
    MuiCssBaseline: {
      styleOverrides,
    },
  },
});

export interface AvatarDefaultColorAccentStyles {
  backgroundColor: string;
  color: string;
}

/**
 * Randomly generates brand color accent styles to override the colorDefault of the
 * of the MUI Avatar component. Will only show up for empty profile pictures
 * @returns returns a randomly generated brand color accent styles
 */
export const getRandomAvatarDefaultColorAccentStyles = (): AvatarDefaultColorAccentStyles => {
  const colorAccents = {
    blueBell: {
      backgroundColor: COLORS.BRAND_BLUEBELL_TINT,
      color: COLORS.BRAND_BLUEBELL,
    },
    rust: {
      backgroundColor: COLORS.BRAND_RUST_TINT,
      color: COLORS.BRAND_RUST,
    },
    goldenRod: {
      backgroundColor: COLORS.BRAND_GOLDENROD_TINT,
      color: COLORS.BRAND_GOLDENROD,
    },
    fir: {
      backgroundColor: COLORS.BRAND_FIR_TINT,
      color: COLORS.BRAND_FIR,
    },
    gulf: {
      backgroundColor: COLORS.BRAND_GULF_TINT,
      color: COLORS.BRAND_GULF,
    },
    royal: {
      backgroundColor: COLORS.BRAND_ROYAL_TINT,
      color: COLORS.BRAND_ROYAL,
    },
    berry: {
      backgroundColor: COLORS.BRAND_BERRY_TINT,
      color: COLORS.BRAND_BERRY,
    },
  };

  const colorAccentKeys = Object.keys(colorAccents);
  const randomlyGeneratedColorAccentKey = colorAccentKeys[Math.floor(Math.random() * colorAccentKeys.length)];

  return colorAccents[randomlyGeneratedColorAccentKey];
};

/**
 * Gets CSS for clamped text
 * @param num The number of lines to clamp
 * @returns The CSS properties to be applied
 */
export const getClamped = (num?: number): CSSProperties => {
  if (typeof num !== 'number') {
    return {};
  }

  return {
    display: '-webkit-box',
    WebkitLineClamp: num,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };
};

export default theme;
