/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
/* ------------------------------- THIRD PARTY ------------------------------ */
import { makeVar } from '@apollo/client';

/* --------------------------------- CUSTOM --------------------------------- */
import { ZoomRunningContext, ZoomUserContext } from 'src/modules/zoomSdk';

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
interface ZoomStatus {
  /**
   * Whether the user is currently in a Zoom meeting
   */
  isInMeeting?: boolean;
  /**
   * The UUID of the current meeting
   */
  meetingUuid?: string;
  /**
   * The Zoom running context
   */
  runningContext?: ZoomRunningContext;
  /**
   * The zoom user context
   */
  userContext?: ZoomUserContext;
  /**
   * If runningContext is InCamera or InImmersive
   */
  isLayersContext?: boolean;
  /**
   * Current version of the Zoom client
   */
  clientVersion?: string;
  /**
   * The version of the Zoom client browser
   */
  browserVersion?: string;
}

/**
 * Store Zoom status in order to avoid Zoom API rate limits
 * Only update the status on initial loading of the app
 */
export const zoomStatus = makeVar<ZoomStatus | undefined>(undefined);
